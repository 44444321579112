import { useLocation } from 'react-router-dom';

export type UseReadQueryParamsResult = {
  [name: string]: string | undefined;
};

const useReadQueryParams = (props: string[]): UseReadQueryParamsResult => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  return props.reduce(
    (result, key) => ({
      ...result,
      [key]: params.get(key) ?? undefined
    }),
    {}
  );
};

export default useReadQueryParams;
