import { IndicatorStatusDefinition, StatusTag } from 'common-components';
import { InvoiceStatus } from 'types/invoice';

export const statusMap: {
  [K in 'open' | 'closed' | 'upcoming' | 'overdue' | 'unknown']: IndicatorStatusDefinition;
} = {
  open: {
    i18nKey: 'invoices.status.open',
    variant: 'information'
  },
  upcoming: {
    i18nKey: 'invoices.status.upcoming',
    variant: 'warning'
  },
  overdue: {
    i18nKey: 'invoices.status.overdue',
    variant: 'error'
  },
  closed: {
    i18nKey: 'invoices.status.closed',
    variant: 'success'
  },
  unknown: {
    i18nKey: 'invoices.status.other',
    variant: 'neutral',
    icon: 'question'
  }
};

const InvoiceStatusTag: React.FC<{
  total: number;
  status: InvoiceStatus;
}> = ({ status, total }) => {
  if (total < 0) {
    return null;
  }

  return <StatusTag status={statusMap[status]} />;
};

export default InvoiceStatusTag;
