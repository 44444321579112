import { Panel } from '@gaiads/telia-react-component-library';
import { DefinitionList, ListView } from 'common-components';
import { ListViewLayoutProps } from 'common-components/ListView/ListViewLayout/ListViewLayoutProvider';
import { EN_DASH } from 'doings/dash/dash';
import { DateFormats } from 'doings/formatDatetime';
import { multiplex } from 'doings/multiplex/multiplex';
import { SortState, getSort } from 'hooks/useSortableHeadings/useSortableHeadings';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnVisibility } from 'types/listConfig';
import { ProductNewsFeedback } from 'types/productNews';

import { NewsRatingsVoteIcon } from './NewsRatingsVoteIcon';

const NewsRatingsListView: React.FC<{
  loading: boolean;
  feedback: ProductNewsFeedback[];
  sortState: SortState;
  sortClick: (field: string) => void;
}> = ({ loading, feedback, sortState, sortClick }) => {
  const [visibleFeedbackId, setVisibleFeedbackId] = useState<number>(0);
  const toggleVisibleFeedback = (id: number) => {
    setVisibleFeedbackId(visibleFeedbackId !== id ? id : 0);
  };

  const { t } = useTranslation();
  const columns: ListViewLayoutProps['columns'] = {
    updated: {
      baseVisibility: ColumnVisibility.SHOW,
      heading: t('productNewsRatings.list.lastModified'),
      width: '0.75x',
      sort: getSort(sortState, sortClick, 'updatedAt')
    },
    userId: {
      baseVisibility: ColumnVisibility.SHOW,
      heading: t('productNewsRatings.list.user'),
      width: '0.5x',
      sort: getSort(sortState, sortClick, 'userId')
    },
    vote: {
      baseVisibility: ColumnVisibility.SHOW,
      heading: t('productNewsRatings.list.vote'),
      width: '0.5x',
      sort: getSort(sortState, sortClick, 'vote')
    },
    feedback: {
      baseVisibility: ColumnVisibility.SHOW,
      heading: t('productNewsRatings.list.feedback'),
      width: '2x'
    }
  };

  return (
    <ListView
      columns={columns}
      mobileLayout={{
        titleRow: { col: 'userId' },
        rows: [
          { col: 'updated' },
          { colsJoined: ['vote', 'feedback'], noLabel: true, padding: 'xs' }
        ]
      }}
      listData={feedback}
      loading={loading}
      noItemsNotice={t('productNewsRatings.list.noItemsFound')}
      data-testid="news-ratings-list"
    >
      {({ id, updatedAt, vote, feedback, userId }) => (
        <ListView.Row
          data-testid={`news-ratings-row-${id}`}
          usesExpandableArrow
          sublistProps={{
            component: (
              <Panel
                tagName={'li' as 'div'}
                margin={{ bottom: 'xs' }}
                padding={{ vertical: 'xs' }}
                backgroundColor="gray200"
              >
                <ListView.Row listType="sublist">
                  <ListView.Cell>
                    <DefinitionList addDividers directionColumn>
                      <DefinitionList.Item
                        heading={t('productNewsRatings.list.feedback')}
                        data-testid="news-ratings-item-feedback-full"
                      >
                        {feedback ?? EN_DASH}
                      </DefinitionList.Item>
                    </DefinitionList>
                  </ListView.Cell>
                </ListView.Row>
              </Panel>
            ),
            visible: visibleFeedbackId === id
          }}
          onClick={() => toggleVisibleFeedback(id)}
        >
          <ListView.RowShape
            cells={{
              updated: {
                data: DateFormats.DATE_NATIONAL.formatter(new Date(Number(updatedAt)))
              },
              userId: {
                data: String(userId)
              },
              vote: {
                data: (
                  <NewsRatingsVoteIcon
                    vote={vote}
                    aria-label={t(
                      multiplex([
                        'productNewsRatings.votes.absent',
                        [vote === 'upvote', 'productNewsRatings.votes.upvote'],
                        [vote === 'downvote', 'productNewsRatings.votes.downvote']
                      ])
                    )}
                  />
                ),
                props: { dataTestId: `news-ratings-item-vote-${vote}` }
              },
              feedback: {
                data: truncate(feedback, FEEDBACK_EXCERPT_LENGTH),
                props: { dataTestId: 'news-ratings-item-feedback-excerpt' }
              }
            }}
          />
        </ListView.Row>
      )}
    </ListView>
  );
};

const FEEDBACK_EXCERPT_LENGTH = 150;

const truncate = (entry: string | undefined, maxLength: number) => {
  if (!entry) {
    return EN_DASH;
  }

  return entry.length > maxLength ? `${entry.substring(0, maxLength).trimEnd()}…` : entry;
};

export { NewsRatingsListView };
