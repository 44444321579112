import { BodyText, Div, Flex, Icon, List } from '@gaiads/telia-react-component-library';
import { Authorize, CollapsibleBlock, TeliaLink } from 'common-components';
import { NavigationalLink } from 'doings/getNavigationalLinks/getNavigationalLinks';
import isCorporatePortalOrUlmUrl from 'doings/isCorporatePortalOrUlmUrl/isCorporatePortalOrUlmUrl';
import { dashboardLinks } from 'doings/track/analyticsEvents';
import trackEvent from 'doings/track/trackEvent';
import { useTranslation } from 'react-i18next';

import DashboardNavigationB2BQuickLinksSkeleton from './DashboardNavigationB2BQuickLinksSkeleton';
import sectionStyles from './DashboardSections/DashboardSection.module.scss';
import { DashboardSectionLink } from './DashboardSections/DashboardSectionLink';

export const DashboardNavigationB2BQuickLinks = ({
  links,
  loading,
  openModal
}: {
  links: NavigationalLink[];
  loading: boolean;
  openModal: VoidFunction;
}) => {
  const { t } = useTranslation();
  const hasLinks = !!links.length;
  const openModalAndTrackEvent = () => {
    trackEvent(dashboardLinks.customizeQuickLinks);
    openModal();
  };

  return (
    <CollapsibleBlock
      blockIcon="link"
      blockTitle={t('dashboard.sections.titles.QuickLinks')}
      aria-label={t('aria.dashboard.showQuickLinksModal')}
      largeTitle
      className={sectionStyles.collapsibleBlock}
      expanded
      padding={{ size: 'sm' }}
      onClick={openModalAndTrackEvent}
      chevronIconOverride="settings"
      tourStopDataAttributeForPanel="dashboard-quicklinks"
      tourStopDataAttributeForIcon="dashboard-quicklinks-edit-button"
    >
      <CollapsibleBlock.Content padding={{ size: 'sm', top: 'xxxs' }}>
        {!hasLinks && (
          <BodyText data-testid="no-quick-links-notice" tagName="div">
            {t('modal.editLinks.text.noQuickLinks')}

            <TeliaLink.Click
              variant="standalone"
              label={t('modal.editLinks.text.addQuickLinks')}
              onClick={openModalAndTrackEvent}
            />
          </BodyText>
        )}

        <List data-testid="quick-links-list">
          {loading && <DashboardNavigationB2BQuickLinksSkeleton />}

          {!loading &&
            hasLinks &&
            links.map((link) => (
              <List.Item
                key={`dashboard-quick-link--${link.id}`}
                className={sectionStyles.listItem}
              >
                <Authorize
                  enabledWhen={link.visibleWhen}
                  element={
                    <DashboardSectionLink sectionLink={link} data-testid={`section-${link.id}`}>
                      <Flex centeredVertically>
                        <BodyText className={sectionStyles.listItem_linkText} tagName="div">
                          {t(link.textI18nKey)}

                          {link.external && !isCorporatePortalOrUlmUrl(link.to) && (
                            <Div
                              style={{
                                display: 'inline-block',
                                verticalAlign: 'bottom'
                              }}
                              margin={{ left: 'xs' }}
                            >
                              <Icon
                                color="purple500"
                                name="external"
                                size="sm"
                                className={sectionStyles.listItem_linkIcon}
                              />
                            </Div>
                          )}
                        </BodyText>
                      </Flex>
                    </DashboardSectionLink>
                  }
                />
              </List.Item>
            ))}
        </List>
      </CollapsibleBlock.Content>
    </CollapsibleBlock>
  );
};
