import { SubscriptionsResponse } from './subscriptionsQuery';

type FetchMoreResultsType = {
  fetchMoreResult?: SubscriptionsResponse;
};

export default (previousResult: SubscriptionsResponse, { fetchMoreResult }: FetchMoreResultsType) =>
  !fetchMoreResult
    ? previousResult
    : {
        subscriptions: {
          ...fetchMoreResult.subscriptions,
          items: [...previousResult.subscriptions.items, ...fetchMoreResult.subscriptions.items]
        }
      };
