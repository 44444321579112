import getClassNames from 'classnames';
import { Skeleton } from 'common-components';
import { Flex } from 'core-components';

import styles from './TileButtonGrid.module.scss';

export type SupportedColumnCount = 1 | 2 | 3 | 4;

export const TileButtonGrid: React.FC<{
  children: (JSX.Element | boolean | undefined)[];
  columns: { desktop: SupportedColumnCount; mobile: SupportedColumnCount };
  loading?: boolean;
  loadingTiles?: number;
}> = ({
  children,
  columns: { desktop: desktopCols, mobile: mobileCols },
  loading = false,
  loadingTiles = 0
}) => {
  const tiles =
    loading && loadingTiles > 0
      ? Array.from({ length: loadingTiles }, () => <TileButtonSkeleton />)
      : children.filter(isTilePresent);

  return (
    <ul
      className={getClassNames([
        styles.grid,
        COL_COUNT_GRID_STYLES[desktopCols].desktop,
        COL_COUNT_GRID_STYLES[mobileCols].mobile
      ])}
    >
      {tiles.map((tile, index) => (
        <li key={`tile-${index}`} className={styles.gridItem}>
          {tile}
        </li>
      ))}
    </ul>
  );
};

const TileButtonSkeleton: React.FC = () => {
  return (
    <Flex
      data-testid="tile-button-skeleton"
      className={styles.gridItemSkeleton}
      centered
      directionColumn
      occupyHorizontalSpace
    >
      <Flex padding={{ vertical: 'xs' }}>
        <Skeleton.Image squared width="40px" height="40px" />
      </Flex>

      <Skeleton.TextRow width="90%" />
    </Flex>
  );
};

const isTilePresent = (child: JSX.Element | boolean | undefined): child is JSX.Element => {
  return !!child;
};

const COL_COUNT_GRID_STYLES: Record<SupportedColumnCount, { desktop: string; mobile: string }> = {
  1: { desktop: styles.grid_desk1cols, mobile: styles.grid_mob1cols },
  2: { desktop: styles.grid_desk2cols, mobile: styles.grid_mob2cols },
  3: { desktop: styles.grid_desk3cols, mobile: styles.grid_mob3cols },
  4: { desktop: styles.grid_desk4cols, mobile: styles.grid_mob4cols }
};
