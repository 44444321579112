import { TileButtonProps } from 'common-components';
import { useFeatureFlagsData } from 'contexts/FeatureFlagContext/FeatureFlagContext';
import getEnvironmentVariable from 'doings/getEnvironmentVariable/getEnvironmentVariable';
import { otherServices } from 'doings/track/analyticsEvents';
import userHasPermission from 'doings/userHasPermission/userHasPermission';
import { TFunction } from 'i18next';

export type OtherServiceLink = {
  anyPermission?: UserPermission[];
  visibleFor?: ActiveGroupType;
  id: string;
  isAvailable?: boolean;
} & Omit<TileButtonProps, 'data-testid'>;

export default ({ t, user }: { t: TFunction; user: User }): OtherServiceLink[] => {
  const { hasFeature } = useFeatureFlagsData();
  const hasPermission = userHasPermission(user);

  return (
    [
      {
        id: 'link-to-customer-support',
        label: t('dashboard.other.customerSupport'),
        iconName: 'support',
        to: t('dashboard.other.customerSupportUrl'),
        whenClicked: otherServices.support,
        visibleFor: 'b2b'
      },
      {
        id: 'link-to-cid-manager',
        label: t('dashboard.other.cidManager'),
        iconName: 'address-book',
        to: getEnvironmentVariable('REACT_APP_LINK_TO_CID_MANAGER'),
        whenClicked: otherServices.cidManager,
        anyPermission: ['CID_SUBSCRIPTIONS'],
        visibleFor: 'b2b'
      },
      {
        id: 'link-to-mobile-company-network',
        label: t('dashboard.other.mobileCompanyNetwork'),
        iconName: 'data-transfer',
        to: getEnvironmentVariable('REACT_APP_LINK_TO_MOBILE_COMPANY_NETWORK'),
        whenClicked: otherServices.mobileCompanyNetwork,
        anyPermission: ['MOBILE_COMPANY_NETWORK']
      },
      {
        id: 'link-to-ecommerce',
        label: t('dashboard.other.ecommerce'),
        iconName: 'bag',
        to: getEnvironmentVariable('REACT_APP_LINK_TO_ECOMMERCE'),
        whenClicked: otherServices.ecommerce
      },
      {
        id: 'link-to-surf-manager',
        label: t('dashboard.other.surfManager'),
        iconName: 'user-admin',
        to: t('dashboard.other.surfManagerUrl'),
        whenClicked: otherServices.surfManager,
        visibleFor: 'b2b'
      },
      {
        id: 'link-to-disturbance',
        label: t('dashboard.other.disturbance'),
        iconName: 'alert',
        to: getEnvironmentVariable('REACT_APP_LINK_TO_DISTURBANCE'),
        whenClicked: otherServices.disturbance
      },
      {
        id: 'link-to-disturbance-notifications-settings',
        label: t('dashboard.other.disturbanceNotificationsSettings'),
        iconName: 'settings',
        to: getEnvironmentVariable('REACT_APP_LINK_TO_DISTURBANCE_NOTIFICATION_SETTINGS'),
        anyPermission: ['ADMIN'],
        whenClicked: otherServices.disturbanceNotificationsSettings
      },
      {
        id: 'link-to-vip-portal',
        label: t('dashboard.other.vipPortal'),
        iconName: 'people-hub',
        to: getEnvironmentVariable('REACT_APP_LINK_TO_VIP_PORTAL'),
        whenClicked: otherServices.vipPortal,
        visibleFor: 'b2b'
      },
      {
        id: 'link-to-vip-plus-admin-portal',
        label: t('dashboard.other.vipPlusAdminPortal'),
        iconName: 'people-hub',
        to: getEnvironmentVariable('REACT_APP_LINK_TO_VIP_PLUS_ADMIN_PORTAL'),
        tooltip: t('dashboard.other.vipPlusAdminPortalTooltip'),
        whenClicked: otherServices.vipPlusAdminPortal,
        visibleFor: 'b2b',
        isAvailable: !hasFeature('EART-5481')
      },
      {
        id: 'link-to-sd-wan',
        label: t('dashboard.other.sdWan'),
        iconName: 'network',
        to: getEnvironmentVariable('REACT_APP_LINK_TO_SD_WAN'),
        whenClicked: otherServices.sdWan,
        anyPermission: ['DATACOM_SERVICES', 'DATACOM_SERVICES_VIEW']
      },
      {
        id: 'link-to-colocation-portal',
        label: t('dashboard.other.colocationPortal'),
        iconName: 'premises-datacenter',
        to: getEnvironmentVariable('REACT_APP_LINK_TO_COLOCATION_PORTAL'),
        whenClicked: otherServices.colocationPortal,
        anyPermission: ['COLOCATION_PORTAL']
      },
      {
        id: 'link-to-smart-connect',
        label: t('dashboard.other.smartConnect'),
        iconName: 'mobile-broadband',
        to: getEnvironmentVariable('REACT_APP_LINK_TO_SMART_CONNECT_ADMIN'),
        whenClicked: otherServices.smartConnect
      },
      {
        id: 'link-to-secure-net',
        label: t('dashboard.other.secureNet'),
        iconName: 'secured-1',
        to: getEnvironmentVariable('REACT_APP_LINK_TO_SECURE_NET'),
        whenClicked: otherServices.secureNet
      },
      {
        id: 'link-to-mnc-reports',
        label: t('dashboard.other.mncReports'),
        iconName: 'report',
        to: getEnvironmentVariable('REACT_APP_LINK_TO_MNC_REPORTS'),
        whenClicked: otherServices.mncReports,
        anyPermission: ['REPORTS_MNC']
      },
      {
        id: 'link-to-telia-sign',
        label: t('dashboard.other.teliaSign'),
        iconName: 'signature',
        to: getEnvironmentVariable('REACT_APP_LINK_TO_TELIA_SIGN'),
        whenClicked: otherServices.teliaSign
      },
      {
        id: 'link-to-managed-iot-connectivity-portal',
        label: t('dashboard.other.managedIotConnectivityPortal'),
        iconName: 'cloud-connect',
        to: getEnvironmentVariable('REACT_APP_LINK_TO_MANAGED_IOT_CONNECTIVITY_PORTAL'),
        whenClicked: otherServices.managedIotConnectivityPortal
      },
      {
        id: 'link-to-iot-portal',
        label: t('dashboard.other.iotPortal'),
        iconName: 'industrial-iot',
        to: getEnvironmentVariable('REACT_APP_LINK_TO_IOT_PORTAL'),
        whenClicked: otherServices.iotPortal
      },
      {
        id: 'link-to-iot-status-portal',
        label: t('dashboard.other.iotStatusPortal'),
        iconName: 'blood-pressure',
        to: getEnvironmentVariable('REACT_APP_LINK_TO_IOT_STATUS_PORTAL'),
        whenClicked: otherServices.iotStatusPortal
      },
      {
        id: 'link-to-telia-salesportal',
        label: t('dashboard.other.salesPortal'),
        iconName: 'cart',
        to: getEnvironmentVariable('REACT_APP_SALESPORTAL_URL'),
        whenClicked: otherServices.salesPortal,
        anyPermission: ['B2O_SHOP']
      },
      {
        id: 'link-to-my-nebula',
        label: t('dashboard.other.myNebula'),
        iconName: 'portal',
        to: getEnvironmentVariable('REACT_APP_LINK_TO_TIN_MY_NEBULA'),
        whenClicked: otherServices.myNebula,
        'data-testid': 'link-to-my-nebula'
      },
      {
        id: 'link-to-tin-shop',
        label: t('dashboard.other.teliaTinShop'),
        iconName: 'devices',
        to: getEnvironmentVariable('REACT_APP_LINK_TO_TIN_SHOP'),
        whenClicked: otherServices.tinShop
      },
      {
        id: 'link-to-cygate-service-portal',
        label: t('dashboard.other.teliaCygate'),
        iconName: 'rack',
        to: getEnvironmentVariable('REACT_APP_LINK_TO_TELIA_CYGATE'),
        whenClicked: otherServices.cygateServicePortal
      },
      {
        id: 'link-to-developer-portal',
        label: t('dashboard.other.developerPortal'),
        iconName: 'internet',
        to: getEnvironmentVariable('REACT_APP_LINK_TO_DEVELOPER_PORTAL'),
        whenClicked: otherServices.developerPortal
      }
    ] as OtherServiceLink[]
  )
    .filter(({ isAvailable }) => !isAvailable)
    .filter(({ visibleFor }) => (visibleFor ? visibleFor === user.activeGroupType : true))
    .filter(({ anyPermission }) => hasPermission({ anyPermission }));
};
