import { gql } from '@apollo/client';

export default gql`
  query GetMobileSubscriptionPukCodes(
    $query: SubscriptionsPukCodeSearchQuery!
    $offset: Int!
    $pageSize: Int!
    $sort: String
    $order: String
  ) {
    subscriptionsPukCodeSearch(
      offset: $offset
      pageSize: $pageSize
      query: $query
      sort: $sort
      order: $order
    ) {
      total
      items {
        id
        phoneNumber
        phoneNumberHash
        name
      }
    }
  }
`;

export type QueriedSubscriptionPukCodeSearch = {
  id: string;
  phoneNumber: string;
  phoneNumberHash: string;
  name: string | null;
};

export type SubscriptionPukCodeSearchResponse = {
  subscriptionsPukCodeSearch: {
    total: number;
    items: QueriedSubscriptionPukCodeSearch[];
  };
};
