import { ErrorPolicy, NetworkStatus, WatchQueryFetchPolicy, useLazyQuery } from '@apollo/client';
import isLoginError from 'doings/isLoginError/isLoginError';
import { DocumentNode } from 'graphql';
import useAccessControlErrorHandler from 'hooks/useAccessControlErrorHandler/useAccessControlErrorHandler';

type QueryVariables = Record<string, unknown>;

type CallBackendArgs = {
  query: DocumentNode;
  queryVariables?: QueryVariables;
  errorPolicy?: ErrorPolicy;
  fetchPolicy?: WatchQueryFetchPolicy;
};

export default <T = unknown>({
  query,
  queryVariables,
  errorPolicy,
  fetchPolicy = 'cache-first'
}: CallBackendArgs) => {
  const accessControlErrorHandler = useAccessControlErrorHandler();

  const [executeQuery, { data, error, loading, fetchMore, networkStatus }] = useLazyQuery<T>(
    query,
    {
      variables: queryVariables,
      errorPolicy,
      notifyOnNetworkStatusChange: true,
      onError: accessControlErrorHandler,
      fetchPolicy
    }
  );

  return {
    executeQuery,
    data,
    error,
    loading: isLoginError(error) || loading,
    loadingMore: networkStatus === NetworkStatus.fetchMore,
    loaded: data !== undefined || error !== undefined,
    fetchMore
  };
};
