import { Analytics } from 'common-components';
import ButtonLink from 'common-components/Link/ButtonLink/ButtonLink';
import { messages } from 'doings/track/analyticsEvents';
import { useTranslation } from 'react-i18next';

export const NewTicketButton: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Analytics whenClicked={messages.createTroubleTicket}>
      <ButtonLink
        text={t('tickets.createNew')}
        to="/tickets/new"
        data-testid="create-ticket-link"
        enabledWhen={{
          onePermission: 'INCIDENT_TICKETS'
        }}
      />
    </Analytics>
  );
};
