import gql from 'graphql-tag';
import useCallBackendMutation from 'hooks/useCallBackend/useCallBackendMutation';

export const clearConversationMutation = gql`
  mutation ClearConversation {
    clearAskForHelpHistory
  }
`;

export const useClearConversation = () => {
  const { runMutation } = useCallBackendMutation(clearConversationMutation);

  const clearConversation = () => {
    runMutation({
      fetchPolicy: 'no-cache'
    });
  };

  return { clearConversation };
};
