import { EditableListModal } from 'B2XApp/Modals/EditableListModal/EditableListModal';
import { EligibleEditableListState } from 'contexts/EditableListContext/EditableListContext';

export const EditDialog: React.FC<{
  listConfig: EligibleEditableListState;
  isOpen: boolean;
  onClose: VoidFunction;
}> = ({ listConfig, isOpen, onClose }) => {
  return (
    <EditableListModal
      isOpen={isOpen}
      configurableListId={listConfig.listId}
      configurableColumns={listConfig.columns}
      onClose={onClose}
    />
  );
};
