import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { ProductNewsCategory as ActiveTab } from 'types/productNews';

import NewsDrawerTab from './NewsDrawerTab';
import NewsDrawerTabCounter from './NewsDrawerTabCounter';
import styles from './NewsDrawerTabs.module.scss';

const NewsDrawerTabs: React.FC<{
  activeTab: ActiveTab;
  onChange: (activeTab: ActiveTab) => void;
}> = ({ activeTab, onChange }) => {
  const { t } = useTranslation();
  const { unseenNews, unseenTips } = useCounters();

  return (
    <div className={styles.container} role="tablist">
      <NewsDrawerTab
        isActive={activeTab === 'productUpdates'}
        onClick={() => onChange('productUpdates')}
        data-testid="news-drawer-tab-product-updates"
        iconName="news"
        label={t('newsDrawer.productUpdates')}
        aria-controls="productUpdates"
      >
        <NewsDrawerTabCounter
          count={unseenNews}
          aria-label={t('aria.newsFeed.unseenInContext', { count: unseenNews })}
        />
      </NewsDrawerTab>

      <NewsDrawerTab
        isActive={activeTab === 'productTips'}
        onClick={() => onChange('productTips')}
        data-testid="news-drawer-tab-product-tips"
        iconName="bulb"
        label={t('newsDrawer.tips')}
        aria-controls="productTips"
      >
        <NewsDrawerTabCounter
          count={unseenTips}
          aria-label={t('aria.newsFeed.unseenInContext', { count: unseenTips })}
        />
      </NewsDrawerTab>
    </div>
  );
};

const useCounters = () => {
  const unseenCounts = useSelector((state: RootState) => state.newsDrawer.unseenCounts);
  const unseenCountsMax = useRef({ news: unseenCounts.news, tips: unseenCounts.tips });
  useEffect(() => {
    unseenCountsMax.current = {
      news: Math.max(unseenCounts.news, unseenCountsMax.current.news),
      tips: Math.max(unseenCounts.tips, unseenCountsMax.current.tips)
    };
  }, [unseenCounts]);

  return {
    unseenNews: unseenCountsMax.current.news,
    unseenTips: unseenCountsMax.current.tips
  };
};

export default NewsDrawerTabs;
