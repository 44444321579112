import { Gutter } from '@gaiads/telia-react-component-library';
import { InlineNotification, TeliaLink } from 'common-components';
import { useUserData } from 'contexts/UserContext/UserContext';
import createCorporatePortalLink from 'doings/links/createCorporatePortalLink/createCorporatePortalLink';

const BillingGroupsInfoNotices: React.FC = () => {
  const { hasAMCodes } = useUserData();

  return (
    <>
      <InlineNotification
        data-testid="billing-info-change-notification"
        variant="information"
        content={{ key: 'invoices.billingGroup.list.notifications.billingInfoChange' }}
      />

      {hasAMCodes && (
        <>
          <Gutter size="sm" />

          <InlineNotification
            data-testid="billing-info-change-for-am-companies-notification"
            variant="information"
            content={{
              key: 'invoices.billingGroup.list.notifications.billingInfoChangeForAMCompanies',
              components: [
                <TeliaLink.Translatable.External
                  key="billing-info-change-for-am-companies-notification-link"
                  data-testid="billing-info-change-for-am-companies-notification-link"
                  variant="text"
                  to={createCorporatePortalLink('billing/accounts')}
                />
              ]
            }}
          />
        </>
      )}
    </>
  );
};

export default BillingGroupsInfoNotices;
