import { BodyText, Gutter, Panel, Title } from '@gaiads/telia-react-component-library';
import { Icon, IconName } from '@teliafi/fi-ds';
import { Flex } from 'core-components';
import formatDatetime from 'doings/formatDatetime/formatDatetime';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityLogEntry } from 'types/activityLog';

import ActivityLogItemContent from './ActivityLogItemContent';
import ActivityLogItemDetails from './ActivityLogItemDetails';
import styles from './ActivityLogPage.module.scss';

type Variant = { ariaLabelKey: string; iconName: IconName };
const VARIANTS: Record<ActivityLogEntry['baseType'], Variant> = {
  call: { ariaLabelKey: 'aria.activityLog.entry.call', iconName: 'phone' },
  chat: { ariaLabelKey: 'aria.activityLog.entry.chat', iconName: 'chat' }
};

const ActivityLogItem: React.FC<{
  entry: ActivityLogEntry;
  allExpanded: boolean;
  cardsExpanded: number;
  onHandleExpanded: (expand: boolean) => void;
  showContactName?: boolean;
  showCompanyName?: boolean;
}> = ({
  entry,
  allExpanded,
  cardsExpanded,
  onHandleExpanded,
  showContactName,
  showCompanyName
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const { creationDate, baseType, reason, description, contactName, companyName } = entry;

  if (open && cardsExpanded === 0) {
    setOpen(false);
  }
  if (!open && allExpanded) {
    setOpen(true);
  }

  const handleOpen = () => {
    onHandleExpanded(!open);
    setOpen(!open);
  };

  const variant = VARIANTS[baseType];
  const contactNameOrBlank = showContactName ? `. ${contactName}` : '';
  const companyNameOrBlank = showCompanyName ? `. ${companyName}` : '';

  return (
    <ActivityLogItemContent>
      <Panel
        margin={{ bottom: 'md' }}
        occupyHorizontalSpace
        showOverflow
        data-testid={`activity-log-item${open ? '-expanded' : ''}`}
      >
        <Panel.Content
          className={styles.activityCardContent}
          role="button"
          aria-label={t(variant.ariaLabelKey)}
          aria-expanded={open}
          onClick={handleOpen}
          clickableAppearance
          tabIndex={0}
          onKeyUp={(e: React.KeyboardEvent<HTMLDivElement>) => {
            e.key === 'Enter' && handleOpen();
          }}
          data-testid={`activity-log-item-content-${baseType}`}
        >
          <BodyText fontWeight="normal">
            {`${formatDatetime(creationDate)}${contactNameOrBlank}${companyNameOrBlank}`}
          </BodyText>

          <Gutter size="sm" />

          <Flex centeredVertically occupyHorizontalSpace>
            <Icon name={variant.iconName} className={styles.activityCardContent__hoverPurple} />

            <Gutter size="sm" />

            <Title
              tagName="h3"
              occupyHorizontalSpace
              size="sm"
              className={styles.activityCardContent__hoverPurple}
            >
              {description}
            </Title>

            <BodyText>
              <Icon
                name={open ? 'chevron-up' : 'chevron-down'}
                className={styles.activityCardContent__hoverPurple}
              />
            </BodyText>
          </Flex>

          <Gutter size="sm" />

          <Title size="xxs" tagName="h4">
            {t(`activityLog.item.${baseType === 'chat' ? 'title' : 'comments'}`)}
          </Title>

          <Gutter size="xxs" />

          {reason?.split('\n').map((section) => (
            <BodyText margin={{ bottom: 'xxs' }}>{section}</BodyText>
          ))}
        </Panel.Content>

        <Panel.Content padding={{ left: 'md', right: 'md' }}>
          {open && <ActivityLogItemDetails entry={entry} />}
        </Panel.Content>
      </Panel>
    </ActivityLogItemContent>
  );
};

export default ActivityLogItem;
