import { Icon } from '@gaiads/telia-react-component-library';
import getClassNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './TooltipIcon.module.scss';

const sizeClassNames = {
  sm: styles.tooltip_sizeSm,
  md: styles.tooltip_sizeMd,
  lg: styles.tooltip_sizelg
};

const spacingClassNames = {
  zero: undefined,
  xs: styles.tooltip_spacingXs,
  sm: styles.tooltip_spacingSm,
  md: styles.tooltip_spacingMd
};

export const TooltipIcon = React.forwardRef<
  HTMLDivElement,
  {
    size: keyof typeof sizeClassNames;
    spacing?: keyof typeof spacingClassNames;
    'data-testid'?: string;
  }
>(({ size, spacing = 'zero', 'data-testid': dataTestId, ...rest }, forwardedRef) => {
  const { t } = useTranslation();
  return (
    <Icon
      refElement={forwardedRef}
      className={getClassNames([styles.tooltip, sizeClassNames[size], spacingClassNames[spacing]])}
      size={size}
      name="info"
      tagName="button"
      tabIndex={0}
      aria-label={t('aria.tooltip.label')}
      data-testid={dataTestId}
      {...rest}
    />
  );
});
