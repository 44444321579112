import { SectionDefinition } from 'B2XApp/Dashboard/DashboardNavigation/DashboardSections/sections/sectionDefinition';
import { DashboardSectionId } from 'B2XApp/Dashboard/sections';
import { LinkTarget, NavigationalLinks } from 'doings/getNavigationalLinks/getNavigationalLinks';

export const subscriptionsAndDevicesSection: SectionDefinition = {
  id: DashboardSectionId.SubscriptionsAndDevices,
  icon: 'simcard',
  titleTranslationKey: 'dashboard.sections.titles.SubscriptionsAndDevices',
  getLinks(allLinks: NavigationalLinks) {
    return [
      allLinks[LinkTarget.PRODUCTS__MOBILE_SUBSCRIPTIONS],
      allLinks[LinkTarget.PRODUCTS__MOBILE_SUBSCRIPTIONS__CREATE_EMPLOYEE],
      allLinks[LinkTarget.PRODUCTS__MOBILE_SUBSCRIPTIONS__MASS_CHANGE],
      allLinks[LinkTarget.PRODUCTS__MOBILE_SUBSCRIPTIONS__OWNERSHIP_CHANGE],
      allLinks[LinkTarget.PRODUCTS__MOBILE_SUBSCRIPTIONS__ORDER_SIM_CARDS],
      allLinks[LinkTarget.PRODUCTS__INTERNET_SUBSCRIPTIONS],
      allLinks[LinkTarget.PRODUCTS__DEVICES],
      allLinks[LinkTarget.PRODUCTS__OTHER_PRODUCTS_AND_SERVICES],
      allLinks[LinkTarget.PRODUCTS__OTHER_PRODUCTS_AND_SERVICES__VIP],
      allLinks[LinkTarget.PRODUCTS__OTHER_PRODUCTS_AND_SERVICES__CID],
      allLinks[LinkTarget.PRODUCTS__OTHER_PRODUCTS_AND_SERVICES__TOUCHPOINT],
      allLinks[LinkTarget.PRODUCTS__OTHER_PRODUCTS_AND_SERVICES__SMART_CONNECT]
    ];
  }
};
