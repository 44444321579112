import { easter } from 'date-easter';
import { addDays, formatISO, nextSaturday, subDays } from 'date-fns';

const GOOD_FRIDAY_INTERVAL = 2;
const ASCENSION_DAY_INTERVAL = 39;
const PENTECOST_SUNDAY_INTERVAL = 49;

export const toISODateString = (date: Date): string => formatISO(date, { representation: 'date' });

export const getFinnishHolidays = (year: number): string[] => {
  const easterDate = new Date(easter(year).toString());
  const midsummerDate = nextSaturday(new Date(`${year}-06-18`));

  return [
    `${year}-01-01`, // Uudenvuodenpäivä
    `${year}-01-06`, // Loppiainen
    toISODateString(subDays(easterDate, GOOD_FRIDAY_INTERVAL)), // Pitkäperjantai
    toISODateString(easterDate), // Pääsiäissunnuntai
    toISODateString(addDays(easterDate, 1)), // Pääsiäismaanantai
    `${year}-05-01`, // Vappu
    toISODateString(addDays(easterDate, ASCENSION_DAY_INTERVAL)), // Helatorstai
    toISODateString(addDays(easterDate, PENTECOST_SUNDAY_INTERVAL)), // Helluntaipäivä
    toISODateString(subDays(midsummerDate, 1)), // Juhannusaatto
    toISODateString(midsummerDate), // Juhannuspäivä
    toISODateString(nextSaturday(new Date(`${year}-10-30`))), // Pyhäinpäivä
    `${year}-12-06`, // Itsenäisyyspäivä
    `${year}-12-24`, // Jouluaatto
    `${year}-12-25`, // Joulupäivä
    `${year}-12-26` // Tapaninpäivä
  ];
};
