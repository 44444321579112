import routeAccessGroups from 'B2XApp/appRouteAccessGroups';
import { ModalDialog, TileButton, TileButtonGrid, TileButtonProps } from 'common-components';
import { SupportedColumnCount } from 'common-components/TileButtonGrid';
import { useUserData } from 'contexts/UserContext/UserContext';
import { isWholesaleBusiness } from 'doings/isWholesaleBusiness/isWholesaleBusiness';
import createCorporatePortalLink from 'doings/links/createCorporatePortalLink/createCorporatePortalLink';
import { invoicing } from 'doings/track/analyticsEvents';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { showBillingInfoChangeModal } from 'redux/slices/billingInfoChangeSlice';
import { RootState } from 'redux/store';

export const BillingInfoChangeModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isModalOpen = useSelector((state: RootState) => state.billingInfoChangeModal.isModalOpen);
  const isMassChange = useSelector((state: RootState) => state.billingInfoChangeModal.isMassChange);
  const user = useUserData();
  const isB2OCustomer = isWholesaleBusiness(user);

  const closeModal = () => dispatch(showBillingInfoChangeModal({ show: false }));

  const links: TileButtonProps[] = isMassChange
    ? [
        {
          iconName: 'invoice',
          label: t('modal.billingInfoChange.links.serviceRequest.title'),
          tooltip: t('modal.billingInfoChange.links.serviceRequest.description'),
          to: '/service-requests/new?t=billing_info_change',
          whenClicked: invoicing.changeCompanyBillingInfo,
          enabledWhen: routeAccessGroups.SERVICE_REQUESTS_NEW,
          'data-testid': 'billing-info-mass-change-modal-service-request'
        },
        {
          iconName: 'invoice',
          label: t('modal.billingInfoChange.links.mobileSubscription.title'),
          tooltip: t('modal.billingInfoChange.links.mobileSubscription.description'),
          to: createCorporatePortalLink('massOperations/massChange'),
          whenClicked: invoicing.changeMobileSubscriptionsBillingInfo,
          enabledWhen: routeAccessGroups.B2B_QUICKLINKS_MOBILE,
          'data-testid': 'billing-info-mass-change-modal-mobile-subscription'
        },
        ...(!isB2OCustomer
          ? [
              {
                iconName: 'excel',
                label: t('invoices.billingGroup.list.links.billingInfoMassChangeExcel'),
                tooltip: t('invoices.billingGroup.list.links.billingInfoMassChangeExcelTooltip'),
                to: '/billing-accounts?action=mass-change',
                whenClicked: invoicing.changeBillingInfoWithExcel,
                enabledWhen: routeAccessGroups.BILLING,
                'data-testid': 'billing-info-mass-change-modal-excel'
              } as const
            ]
          : [])
      ]
    : [
        {
          iconName: 'invoice',
          label: t('invoices.billingGroup.list.links.changeCompanyBillingInfo'),
          tooltip: t('modal.billingInfoChange.links.serviceRequest.description'),
          to: '/service-requests/new?t=billing_info',
          whenClicked: invoicing.changeCompanyBillingInfo,
          enabledWhen: routeAccessGroups.SERVICE_REQUESTS_NEW,
          'data-testid': 'billing-info-change-modal-service-request'
        },
        {
          iconName: 'invoice',
          label: t('modal.billingInfoChange.links.billingAccounts.title'),
          to: '/billing-accounts',
          whenClicked: invoicing.changeBillingAccounts,
          enabledWhen: routeAccessGroups.BILLING,
          'data-testid': 'billing-info-change-modal-billing-accounts'
        },
        {
          iconName: 'invoice',
          label: t('invoices.billingGroup.list.links.changeSubscriptionBillingInfo'),
          tooltip: t('invoices.billingGroup.list.links.changeSubscriptionBillingInfoTooltip'),
          to: user?.isSmallBusiness
            ? '/subscriptions'
            : createCorporatePortalLink('search/subscriptionSearch'),
          whenClicked: invoicing.changeSubscriptionBillingInfo,
          enabledWhen: routeAccessGroups.MOBILE_SUBSCRIPTIONS,
          'data-testid': 'billing-info-change-modal-subscriptions'
        }
      ];

  return (
    <ModalDialog
      isOpen={isModalOpen}
      title={t('modal.billingInfoChange.title')}
      onClose={closeModal}
      data-testid="billingInfoChange-modal"
    >
      <TileButtonGrid columns={{ desktop: links.length as SupportedColumnCount, mobile: 1 }}>
        {links.map((link) => (
          <TileButton key={link['data-testid']} {...link} onClick={closeModal} />
        ))}
      </TileButtonGrid>
    </ModalDialog>
  );
};
