import { Div, Flex, Gutter } from '@gaiads/telia-react-component-library';
import { Skeleton } from 'common-components';

const SubscriptionAssetSkeleton: React.FC = () => (
  <Skeleton.Panel data-testid="subscription-asset-skeleton">
    <Flex spaceBetween>
      <Div flexItem>
        <Skeleton.Title width="50%" />

        <Skeleton.Title width="50%" />

        <Gutter />

        <Skeleton.TextRow width="60%" />

        <Gutter />

        <Skeleton.TextRow width="60%" />

        <Skeleton.TextRow width="60%" />

        <Skeleton.TextRow width="60%" />

        <Gutter />

        <Skeleton.Button width="45%" />
      </Div>

      <Flex responsiveHidden={{ thinnerThan: 'sm' }} alignRight>
        <Skeleton.Image width="78px" height="80px" />
      </Flex>
    </Flex>
  </Skeleton.Panel>
);

export default SubscriptionAssetSkeleton;
