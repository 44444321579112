import { Gutter } from '@gaiads/telia-react-component-library';
import { Icon } from '@teliafi/fi-ds';
import { InlineNotification, Optional } from 'common-components';
import ModelTextInput from 'common-components/ModelTextInput/ModelTextInput';
import { Div } from 'core-components';
import { useProductNewsFeedback } from 'hooks/productNews/useProductNewsFeedback';
import useProductNewsFeedbackForm from 'hooks/productNews/useProductNewsFeedbackForm';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import newsDrawerStyles from './NewsDrawerContent.module.scss';
import styles from './NewsDrawerFeedback.module.scss';

const NewsDrawerFeedbackForm: React.FC<{
  productUpdateId: string;
  feedbackGiven: boolean;
}> = ({ productUpdateId, feedbackGiven }) => {
  const { t } = useTranslation();
  const { isValid, fields } = useProductNewsFeedbackForm();
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const { sendFeedback } = useProductNewsFeedback();

  const onFeedbackSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (fields.feedback.value) {
      setFeedbackSubmitted(true);
      sendFeedback({ id: productUpdateId, feedback: fields.feedback.value });
    }
  };

  return (
    <Div data-testid="news-drawer-feedback">
      <Optional if={!feedbackSubmitted}>
        <Gutter size="sm" />

        <form
          className={styles.feedbackContainer}
          onSubmit={onFeedbackSubmit}
          data-testid="news-drawer-feedback-form"
        >
          <ModelTextInput
            className={styles.feedbackInput}
            data-testid="news-drawer-feedback-input"
            label={feedbackGiven ? t('newsDrawer.updateFeedback') : t('newsDrawer.sendUsFeedback')}
            placeholder={t('newsDrawer.yourFeatureFeedback')}
            inputModel={fields.feedback}
            hardMaxLength
          />

          <button
            aria-label={t('newsDrawer.send')}
            type="submit"
            className={styles.sendButton}
            disabled={!fields.feedback.value || !isValid}
            data-testid="news-drawer-feedback-form-submit"
          >
            <Icon name="send" />
          </button>
        </form>
      </Optional>

      <Optional if={feedbackSubmitted}>
        <Gutter size="sm" />

        <output className={newsDrawerStyles.slideIn}>
          <InlineNotification variant="success" content={{ key: 'newsDrawer.thanksForFeedback' }} />
        </output>
      </Optional>
    </Div>
  );
};

export default NewsDrawerFeedbackForm;
