import { TeliaLink } from 'common-components';
import isCorporatePortalOrUlmUrl from 'doings/isCorporatePortalOrUlmUrl/isCorporatePortalOrUlmUrl';
import { GuardedEvent, useConfirmationGuard } from 'hooks/useCustomModal/useConfirmationGuard';
import { useHistory } from 'react-router';

const BackButton: React.FC<{
  /** The text to display as the back button. */
  label: string;
  /** The path to return the user to. Goes back in history if absent. */
  path?: string;
  /** Determines whether to ask for confirmation to leave a page with user-provided input. */
  guarded?: boolean;
}> = ({ label, path, guarded }) => {
  const { goBack, push } = useHistory();
  const { onGuardedClick } = useConfirmationGuard();

  const onClick = (e: GuardedEvent) => {
    if (guarded && onGuardedClick(e, path || ':back')) {
      return;
    }

    if (path) {
      if (isCorporatePortalOrUlmUrl(path) || path.startsWith('https://') || path.startsWith('//')) {
        window.open(path, '_self');
      } else {
        push(path);
      }
    } else {
      goBack();
    }
  };

  return (
    <TeliaLink.Click
      data-testid="back-button"
      variant="standalone"
      onClick={onClick}
      label={label}
      iconLeft="chevron-left"
    />
  );
};

export default BackButton;
