import subscriptionDetailsQuery from 'hooks/mobileSubscriptions/useSubscriptionDetails/subscriptionDetailsQuery';
import useCallBackendMutation from 'hooks/useCallBackend/useCallBackendMutation';
import useCorporatePortalParam from 'hooks/useCorporatePortalParam/useCorporatePortalParam';

import {
  CreateOwnershipChangeTokenData,
  TokenCreationResponse,
  createOwnershipChangeTokenMutation
} from './ownershipChangeTokenQueries';

const useCreateOwnershipChangeToken = (subscriptionId: string) => {
  const corporatePortalParam = useCorporatePortalParam();

  const { runMutation, data, loading, error } = useCallBackendMutation<TokenCreationResponse>(
    createOwnershipChangeTokenMutation,
    [
      {
        query: subscriptionDetailsQuery,
        variables: {
          subscriptionId,
          shouldFetchOmsu: true,
          shouldFetchBalance: false,
          ...corporatePortalParam
        }
      }
    ]
  );

  const createOwnershipChangeToken = async (omsuTokenData: CreateOwnershipChangeTokenData) => {
    runMutation({
      variables: {
        omsuTokenData
      }
    });
  };

  return {
    createOwnershipChangeToken,
    data,
    loading,
    error
  };
};

export default useCreateOwnershipChangeToken;
