import { DynamicForms } from 'types/dynamicForms';

import { check } from './dynamicFormFieldCheck';

export const getDynamicFormMetainfoOverride = ({
  form,
  values
}: {
  form: DynamicForms.DynamicForm;
  values: Record<string, unknown>;
}) => {
  return form?.metainfo?.overrides?.find(({ match }) => {
    const partials = match.split('=');
    return form.fields.find(
      (f) => check.isValidatable(f) && f.name === partials[0] && values[f.id] === partials[1]
    );
  });
};
