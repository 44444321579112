import { useFeatureFlagsData } from 'contexts/FeatureFlagContext/FeatureFlagContext';
import { datesAreOlderThanTodayInMonths } from 'doings/datetime/datesAreOlderThanTodayInMonths';
import { mapTimestampsToDates } from 'doings/datetime/mapTimestampsToDates';
import { getCookie } from 'doings/getCookie/getCookie';
import { B2XCookie } from 'types/clientCookie';
import { B2XSessionStorage } from 'types/sessionStorage';

export const useShouldShowExpiredContactsVerification = (user: User) => {
  const { hasFeature } = useFeatureFlagsData();

  if (!hasFeature('EAT-41349')) {
    return false;
  }

  const hasVerifiedContacts = getCookie(B2XCookie.EXPIRED_CONTACTS_VERIFICATION);

  const hasClosedNotification = sessionStorage.getItem(
    B2XSessionStorage.CLOSED_EXPIRED_CONTACTS_VERIFICATION
  );

  if (!!hasVerifiedContacts || !!hasClosedNotification) {
    return false;
  }

  const {
    verificationTimestamps: { email, businessPhone, groupContactTypes }
  } = user;

  const dates = mapTimestampsToDates([email, businessPhone, ...groupContactTypes]);

  const SIX_MONTHS = 6;
  const contactsVerificationIsExpired = datesAreOlderThanTodayInMonths({
    dates,
    difference: SIX_MONTHS
  });

  return contactsVerificationIsExpired;
};
