import { gql } from '@apollo/client';
import { useFeatureFlagsData } from 'contexts/FeatureFlagContext/FeatureFlagContext';
import createCorporatePortalLink from 'doings/links/createCorporatePortalLink/createCorporatePortalLink';
import openUrl from 'doings/openUrl/openUrl';
import useCallBackendMutation from 'hooks/useCallBackend/useCallBackendMutation';

export const clearNewMessagesNotificationMutation = gql`
  mutation ClearNewCustomerSpecificMessagesNotification {
    clearNewCustomerSpecificMessagesNotification
  }
`;

const useClearNewMessagesNotification = () => {
  const { hasFeature } = useFeatureFlagsData();
  const canClearNotification = hasFeature('EART-5391');

  const { runMutation, error, loading } = useCallBackendMutation(
    clearNewMessagesNotificationMutation
  );

  const clearNewCustomerSpecificMessagesNotification = async (args?: {
    skipRedirect?: boolean;
  }) => {
    if (canClearNotification) {
      await runMutation();
    }

    if (!args?.skipRedirect) {
      openUrl(
        createCorporatePortalLink('messageCenter', {
          show: 'customer-specific-messages'
        })
      );
    }
  };

  return {
    clearNewCustomerSpecificMessagesNotification,
    loading,
    error
  };
};

export default useClearNewMessagesNotification;
