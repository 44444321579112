import { FormikProps, FormikValues } from 'formik';
import { DynamicForms } from 'types/dynamicForms';

import { check } from './dynamicFormFieldCheck';

export const updateActiveCheckboxReferences = (
  activeReferences: Set<string>,
  field: DynamicForms.CheckboxField,
  isChecked: boolean
) => {
  if (isChecked) {
    activeReferences.add(field.id);
  } else {
    activeReferences.delete(field.id);
  }
};

export const updateActiveCheckgroupOptionReferences = (
  activeReferences: Set<string>,
  field: DynamicForms.CheckgroupField,
  selections: string[]
) => {
  const isOptionActive: (o: DynamicForms.CheckgroupFieldOption) => boolean = (o) =>
    selections.includes(o.id);

  activeReferences.add(field.id);
  field.options.forEach((o) => {
    if (isOptionActive(o)) {
      activeReferences.add(o.id);
    } else {
      activeReferences.delete(o.id);
    }
  });
};

export const updateActiveDropdownOptionReferences = (
  activeReferences: Set<string>,
  field: DynamicForms.DropdownField,
  selections: string | string[]
) => {
  const isOptionActive: (o: DynamicForms.DropdownFieldOption) => boolean = Array.isArray(selections)
    ? (o) => selections.includes(o.value)
    : (o) => selections === o.value;

  activeReferences.add(field.id);
  field.options.forEach((o) => {
    if (isOptionActive(o)) {
      activeReferences.add(o.id);
    } else {
      activeReferences.delete(o.id);
    }
  });
};

export const filterFields = (
  formRef: React.RefObject<FormikProps<FormikValues>>,
  fields: readonly DynamicForms.Field[],
  activeReferences: Set<string>
): DynamicForms.Field[] => {
  const filteredFields = filterFieldsByActiveReferences(fields, activeReferences);

  if (formRef.current) {
    unregisterInvisibleFieldsInForm(formRef.current, fields, filteredFields);
  }

  return filteredFields;
};

const filterFieldsByActiveReferences = (
  fields: readonly DynamicForms.Field[],
  activeReferences: Set<string>
) => {
  const filterByShowWhen = createShowWhenFilter(activeReferences);
  const filterByHideWhen = createHideWhenFilter(activeReferences);

  let count = 0;
  let filteredFields = fields.map((field) => field);
  while (count !== filteredFields.length) {
    count = filteredFields.length;
    filteredFields = filteredFields.reduce(filterByShowWhen, []).reduce(filterByHideWhen, []);
  }

  return filteredFields;
};

const createShowWhenFilter = (activeReferences: Set<string>) => {
  return (acc: DynamicForms.Field[], field: DynamicForms.Field): DynamicForms.Field[] => {
    if (!field.showWhen || activeReferences.has(field.showWhen)) {
      return [...acc, field];
    }

    removeInvisibleFieldActiveReferences(field, activeReferences);
    return acc;
  };
};

const createHideWhenFilter = (activeReferences: Set<string>) => {
  return (acc: DynamicForms.Field[], field: DynamicForms.Field): DynamicForms.Field[] => {
    if (!field.hideWhen?.some((ref) => activeReferences.has(ref))) {
      return [...acc, field];
    }

    removeInvisibleFieldActiveReferences(field, activeReferences);
    return acc;
  };
};

const removeInvisibleFieldActiveReferences = (
  field: DynamicForms.Field,
  activeReferences: Set<string>
) => {
  if (activeReferences.has(field.id)) {
    activeReferences.delete(field.id);
    if (check.isCheckgroup(field) || check.isDropdown(field)) {
      field.options
        .filter((o) => activeReferences.has(o.id))
        .forEach((o) => activeReferences.delete(o.id));
    }
  }
};

const unregisterInvisibleFieldsInForm = (
  currentForm: FormikProps<FormikValues>,
  fields: readonly DynamicForms.Field[],
  filteredFields: DynamicForms.Field[]
) => {
  const filteredFieldIds = new Set<string>(filteredFields.map((field) => field.id));

  fields
    .filter((field) => !filteredFieldIds.has(field.id) && check.isValidatable(field))
    .forEach((field) => {
      unregisterInvisibleField(currentForm, field.id);
      if (check.isDropdown(field)) {
        unregisterInvisibleField(currentForm, `${field.id}-input`);
      }

      if (check.isDate(field) && field.dateWithTimeSelection) {
        unregisterInvisibleField(currentForm, `${field.id}-h`);
        unregisterInvisibleField(currentForm, `${field.id}-h-input`);
        unregisterInvisibleField(currentForm, `${field.id}-m`);
        unregisterInvisibleField(currentForm, `${field.id}-m-input`);
      }
    });
};

const unregisterInvisibleField = (currentForm: FormikProps<FormikValues>, fieldId: string) => {
  if (typeof currentForm.values[fieldId] !== 'undefined') {
    currentForm.setFieldValue(fieldId, undefined, false);
    currentForm.setFieldTouched(fieldId, false, false);
    currentForm.unregisterField(fieldId);
  }
};
