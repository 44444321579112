import { DashboardSectionId } from 'B2XApp/Dashboard/sections';
import {
  NavigationalLink,
  NavigationalLinks
} from 'doings/getNavigationalLinks/getNavigationalLinks';
import userHasPermission from 'doings/userHasPermission/userHasPermission';

import { sections } from './definitions';
import { SectionDefinition } from './sectionDefinition';

function validateSectionLink(user: User, sectionLink: NavigationalLink) {
  return sectionLink.isAvailable && userHasPermission(user)(sectionLink.visibleWhen);
}

function addLinkValidationFilter(definition: SectionDefinition): SectionDefinition {
  return {
    ...definition,
    getLinks(allLinks: NavigationalLinks, user: User) {
      return definition.getLinks(allLinks, user).filter((item) => validateSectionLink(user, item));
    }
  };
}

const sectionDefinitions: readonly SectionDefinition[] = Object.freeze(
  sections.map(addLinkValidationFilter)
);

export const getSectionDefinition = (sectionId: DashboardSectionId) => {
  return sectionDefinitions.find((definition) => definition.id === sectionId);
};
