import { gql } from '@apollo/client';
import useApiStatus, { ViewStatus } from 'hooks/useApiStatus/useApiStatus';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { InternetConnectionsItem } from 'types/internetConnections';
import { SortParameter } from 'types/sort';

export const assetsQuery = gql`
  query GetAssets(
    $query: AssetsQuery
    $allCompanies: Boolean
    $sort: AssetSortColumn
    $order: AssetSortOrder
  ) {
    assets(query: $query, allCompanies: $allCompanies, sort: $sort, order: $order) {
      total
      assets {
        id
        name
        displayId
        address {
          streetAddress
          floor
          postalAddress
          country
        }
        isDNSAsset
        domainName
        companyCode
        companyName
      }
    }
  }
`;

export type AssetsResponse = {
  assets: {
    total: number;
    assets: InternetConnectionsItem[];
  };
};

export type AssetsQueryParameters = {
  displayId?: string;
  installationAddress?: string;
};

const useAssets = (
  queryParameters: AssetsQueryParameters,
  sortParameter: SortParameter,
  skip: boolean,
  allCompanies: boolean
) => {
  const { displayId, installationAddress } = queryParameters;

  const { data, error, loading, loadingMore, loaded } = useCallBackend<AssetsResponse>({
    query: assetsQuery,
    queryVariables: {
      query: {
        displayId,
        installationAddress
      },
      allCompanies,
      ...sortParameter
    },
    skip
  });

  const status = useApiStatus({
    loading,
    loadingMore,
    loaded,
    error,
    items: data?.assets.assets ?? [],
    query: queryParameters
  });

  return {
    assets: { total: data?.assets.total ?? 0, items: data?.assets.assets },
    error,
    status: skip ? ViewStatus.Loaded : status,
    hasError: !!error
  };
};

export default useAssets;
