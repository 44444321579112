import { Flex, Gutter } from '@gaiads/telia-react-component-library';
import { DataPanel, Skeleton } from 'common-components';
import Spacer from 'common-components/Spacer/Spacer';
import join from 'doings/join/join';
import multiplyElement from 'doings/multiplyElement/multiplyElement';

const NUMBER_OF_BILLING_INFO_ATTRIBUTES = 6;

const FieldSkeleton: React.FC = () => (
  <Flex spaceBetween>
    <Skeleton.TextRow width="20%" />

    <Skeleton.TextRow width="20%" />
  </Flex>
);

const InvoiceBillingInfoSkeleton: React.FC = () => {
  return (
    <DataPanel>
      <Skeleton.Title width="50%" />

      <Gutter />

      {join(multiplyElement(<FieldSkeleton />, NUMBER_OF_BILLING_INFO_ATTRIBUTES), <Spacer />)}
    </DataPanel>
  );
};

export default InvoiceBillingInfoSkeleton;
