import { IndicatorStatusDefinition, StatusTag } from 'common-components';
import { OrderLineItemStatus } from 'types/orders';

import { statusDefaultValue } from './OrderStatusTag';

export const statusMap: { [K in OrderLineItemStatus]: IndicatorStatusDefinition } = {
  [OrderLineItemStatus.Activated]: {
    i18nKey: 'orders.statuses.activated',
    variant: 'success'
  },
  [OrderLineItemStatus.Cancelled]: {
    i18nKey: 'orders.statuses.cancelled',
    variant: 'neutral',
    icon: 'close-circle'
  },
  [OrderLineItemStatus.InProgress]: {
    i18nKey: 'orders.statuses.inProgress',
    variant: 'information'
  },
  [OrderLineItemStatus.Pending]: {
    i18nKey: 'orders.statuses.pending',
    variant: 'information'
  },
  [OrderLineItemStatus.Superseded]: {
    i18nKey: 'orders.statuses.superseded',
    variant: 'neutral',
    icon: 'close-circle'
  },
  [OrderLineItemStatus.Terminated]: {
    i18nKey: 'orders.statuses.terminated',
    variant: 'neutral',
    icon: 'close-circle'
  }
};

const OrderLineItemStatusTag: React.FC<{
  status: OrderLineItemStatus;
}> = ({ status }) => <StatusTag status={statusMap[status] || statusDefaultValue} />;

export default OrderLineItemStatusTag;
