import { gql } from '@apollo/client';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { ProductNewsStats } from 'types/productNews';

export const productNewsStatsQuery = gql`
  query GetProductNewsStats {
    productNewsStats {
      newsId
      counters {
        total
        upvotes
        downvotes
        comments
      }
      period {
        start
        end
      }
    }
  }
`;

interface QueryResponse {
  productNewsStats: ProductNewsStats[];
}

const useProductNewsStats = () => {
  const { data, error, loading } = useCallBackend<QueryResponse>({
    query: productNewsStatsQuery,
    fetchPolicy: 'cache-first'
  });

  return {
    data: data?.productNewsStats || [],
    loading,
    error
  };
};

export { useProductNewsStats };
