import { OptionListOption } from '@gaiads/telia-react-component-library/build/types/components/OptionList/OptionList';
import isValidDropdown from 'doings/isValidDropdown/isValidDropdown';
import { TextInputModel } from 'hooks/inputModels/useTextInputModel/useTextInputModel';
import useValidatableTextInputModel from 'hooks/inputModels/useValidatableTextInputModel/useValidatableTextInputModel';
import { useEffect } from 'react';
import { DropdownField } from 'types/form';

export type DateTypeInputModel = TextInputModel & {
  options: OptionListOption[];
  valueLabel: string;
};

export type ValidationCallbackFunction = (isValid: boolean) => void;
export type OnValidationStatusChanged = (fieldName: string, isValid: boolean) => void;

export type UseDateTypeInputModelParams = {
  options: OptionListOption[];
  initialValue?: string;
  required?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  onChange?: (value: string) => void;
  onValidationStatusChanged?: ValidationCallbackFunction;
};

export const useDateTypeInputModel = ({
  options: optionsAvailable,
  initialValue = '',
  required = false,
  disabled = false,
  hidden = false,
  onChange,
  onValidationStatusChanged
}: UseDateTypeInputModelParams): DateTypeInputModel => {
  const inputModel = useValidatableTextInputModel({
    initialValue,
    required,
    disabled,
    onChange: (value: string) => {
      if (onChange && (!required || isValidDropdown({ value, options } as DropdownField))) {
        onChange(value);
      }
    },
    onValidationStatusChanged
  });

  const { value: currentValue } = inputModel;
  const isCurrentValueAvailableInOptions = !!optionsAvailable.find(
    ({ value }) => value === currentValue
  );

  const options = optionsAvailable;

  useEffect(() => {
    if (currentValue && !isCurrentValueAvailableInOptions) {
      inputModel.setValue('');
    }
  }, [isCurrentValueAvailableInOptions, inputModel, currentValue]);

  return {
    ...inputModel,
    hidden,
    options,
    valueLabel: options.find(({ value }) => value === currentValue)?.label || ''
  };
};
