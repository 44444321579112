import { SectionDefinition } from 'B2XApp/Dashboard/DashboardNavigation/DashboardSections/sections/sectionDefinition';
import { DashboardSectionId } from 'B2XApp/Dashboard/sections';
import { LinkTarget, NavigationalLinks } from 'doings/getNavigationalLinks/getNavigationalLinks';

export const teliaAccountManagementSection: SectionDefinition = {
  id: DashboardSectionId.TeliaAccountManagement,
  icon: 'new-group',
  titleTranslationKey: 'dashboard.sections.titles.TeliaAccountManagement',
  getLinks(allLinks: NavigationalLinks) {
    return [
      allLinks[LinkTarget.ACCOUNT__COMPANY_INFO],
      allLinks[LinkTarget.ACCOUNT__COMPANY_USERS],
      allLinks[LinkTarget.ACCOUNT__SERVICE_ORDER_PERMISSIONS]
    ];
  }
};
