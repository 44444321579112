import { OptionListOption } from '@gaiads/telia-react-component-library';
import { useUserData } from 'contexts/UserContext/UserContext';
import { EN_DASH } from 'doings/dash/dash';
import userCompanyHasPermission from 'doings/userCompanyHasPermission/userCompanyHasPermission';
import { AuthorizationParams } from 'types/authorization';

export default (limitToCompaniesWith: AuthorizationParams, initialValue?: string) => {
  const { activeGroupId, allCompanies } = useUserData();

  const options: OptionListOption[] = allCompanies
    .filter((c) => userCompanyHasPermission(c, limitToCompaniesWith))
    .map((c) => ({
      label: [c.companyName, c.companyCode].filter(Boolean).join(` ${EN_DASH} `),
      value: c.groupId
    }));

  const selectedGroupId =
    initialValue &&
    options.find((availableCompany) => availableCompany.value === initialValue)?.value;

  return {
    activeGroupId,
    selectedGroupId,
    availableCompanies: options
  };
};
