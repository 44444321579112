import getNavigationItems from 'B2XApp/TopNavigation/Navigation/useNavigationItems';
import getPathAt from 'doings/navigation/getPathAt/getPathAt';
import { useLocation } from 'react-router-dom';
import { NavigationItem } from 'types/navigation';

export default (): NavigationItem[] | undefined => {
  const { pathname } = useLocation();
  const path = getPathAt(pathname);
  return getNavigationItems()?.find(({ additionalActiveMatches }) =>
    additionalActiveMatches?.includes(`/${path}`)
  )?.menuItems;
};
