import { BillingGroupPageType } from 'B2XApp/Invoicing/BillingGroups/BillingGroupPage';
import ConfirmationPanelError from 'common-components/ConfirmationPanel/ConfirmationPanelError';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import BillingGroupFormPageLayout from './BillingGroupFormPageLayout';

const BillingGroupPageError: React.FC<{
  collectionGroupId?: string;
  pageType: BillingGroupPageType;
}> = ({ collectionGroupId, pageType }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const backToLabelEnd = collectionGroupId ? 'backToBillingGroup' : 'backToForm';

  return (
    <BillingGroupFormPageLayout
      title={t(`invoices.billingGroup.form.${pageType}.title`, { collectionGroupId })}
    >
      <ConfirmationPanelError
        title={t(`invoices.billingGroup.form.${pageType}.notification.failedToLoadTitle`, {
          collectionGroupId
        })}
        description={t(
          `invoices.billingGroup.form.${pageType}.notification.failedToLoadDescription`
        )}
        backToInitialStepOnClick={() =>
          history.push(`/billing-accounts/${collectionGroupId ?? ''}`)
        }
        backToLabel={t(`invoices.billingGroup.form.${pageType}.notification.${backToLabelEnd}`)}
        data-testid={`collection-group-${pageType}__failure`}
      />
    </BillingGroupFormPageLayout>
  );
};

export default BillingGroupPageError;
