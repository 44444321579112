import { useRemBreakpoint } from 'hooks/useBreakpoint/useRemBreakpoint';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useLocation } from 'react-router';

type SubmenuState = {
  openMenuId: string;
  setOpenMenuId: Dispatch<SetStateAction<string>>;
  isMobileMenuOpen: boolean;
  setMobileMenuOpen: Dispatch<SetStateAction<boolean>>;
  closeMenus: VoidFunction;
  toggleMenu: VoidFunction;
};

export const SubmenuContext = createContext<SubmenuState | undefined>(undefined);

const SubmenuProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [openMenuId, setOpenMenuId] = useState<string>('');
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);

  const closeMenus = () => {
    setOpenMenuId('');
    setMobileMenuOpen(false);
  };

  const toggleMenu = () => {
    setOpenMenuId('');
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const shouldListenToEscape = openMenuId || mobileMenuOpen;
  const escListener = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      setOpenMenuId('');
      setMobileMenuOpen(false);
    }
  }, []);

  useEffect(() => {
    if (shouldListenToEscape) {
      document.addEventListener('keyup', escListener);

      return () => {
        document.removeEventListener('keyup', escListener);
      };
    }

    return () => {
      // Do nothing
    };
  }, [shouldListenToEscape, escListener]);

  const { isActive: isMobile } = useRemBreakpoint({ narrowerThan: 'md', at: 'md' });
  const forceMenuToClose = !openMenuId && !isMobile;
  const forceMenuToOpen =
    openMenuId &&
    openMenuId !== 'content-search' &&
    openMenuId !== 'user-dropdown' &&
    !mobileMenuOpen;

  useEffect(() => {
    if (forceMenuToClose) {
      setMobileMenuOpen(false);
    } else if (forceMenuToOpen) {
      setMobileMenuOpen(true);
    }
  }, [forceMenuToClose, forceMenuToOpen, setMobileMenuOpen]);

  const location = useLocation();

  useEffect(() => {
    closeMenus();
  }, [location]);

  return (
    <SubmenuContext.Provider
      value={{
        openMenuId,
        setOpenMenuId,
        isMobileMenuOpen: isMobile && mobileMenuOpen,
        setMobileMenuOpen,
        closeMenus,
        toggleMenu
      }}
    >
      {children}
    </SubmenuContext.Provider>
  );
};

const useSubmenuState = () => {
  const context = useContext(SubmenuContext);

  if (context === undefined) {
    throw new Error('useSubmenuState must be used with SubmenuContext');
  }

  return context;
};

export { SubmenuProvider, useSubmenuState };
