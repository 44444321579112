import { BodyText, Flex, Gutter, Icon, Panel } from '@gaiads/telia-react-component-library';
import DashboardNotificationRow from 'B2XApp/Dashboard/DashboardNavigation/DashboardNotifications/DashboardNotificationRow/DashboardNotificationRow';
import { Heading } from 'common-components';
import { ViewStatus } from 'hooks/useApiStatus/useApiStatus';
import { useTranslation } from 'react-i18next';

import DashboardNotificationsList from './DashboardNotificationList/DashboardNotificationsList';
import DashboardNotificationsLoader from './DashboardNotificationsLoader';
import useGetDashboardNotificationRows, {
  DashboardNotificationRowItem
} from './useGetDashboardNotificationRows';

const isDisplayable = (row: DashboardNotificationRowItem) =>
  (row.loadingState === ViewStatus.Loaded && row.total > 0) ||
  ![ViewStatus.Loading, ViewStatus.Loaded].includes(row.loadingState);

const DashboardNotifications: React.FC = () => {
  const { t } = useTranslation();
  const notificationRows = useGetDashboardNotificationRows().sort(
    ({ position: a }, { position: b }) => a - b
  );

  const allLoaded = notificationRows.every((row) => row.loadingState !== ViewStatus.Loading);
  const displayableRows = notificationRows.filter(isDisplayable);

  return (
    <Panel
      data-testid="dashboard-notifications-panel"
      margin={{ bottom: 'sm' }}
      data-tour-stop="dashboard-notifications"
    >
      <Panel.Content>
        <Flex centeredVertically>
          <Icon name="alarm" size="md" />

          <Gutter size="sm" />

          <Heading.H4>{t('dashboard.notifications.title')}</Heading.H4>
        </Flex>

        <Gutter size="sm" />

        {!allLoaded && <DashboardNotificationsLoader loadingItems={notificationRows.length} />}

        {allLoaded && displayableRows.length === 0 && (
          <BodyText data-testid="no-dashboard-notifications-available" padding={{ vertical: 'sm' }}>
            {t('dashboard.notifications.noNotificationsToShow')}
          </BodyText>
        )}

        {allLoaded && displayableRows.length > 0 && (
          <DashboardNotificationsList>
            {displayableRows.map((row) => {
              return (
                <DashboardNotificationRow
                  key={`dashboard-notification-for-${row.dataIdSuffix}`}
                  data-testid={`dashboard-notification-for-${row.dataIdSuffix}`}
                  analyticsEvent={row.analyticsEvent}
                  total={row.total}
                  label={row.label}
                  tooltip={row.tooltip}
                  errorLabel={row.errorLabel}
                  isError={row.loadingState !== ViewStatus.Loaded}
                  navigationLink={row.navigationLink}
                />
              );
            })}
          </DashboardNotificationsList>
        )}
      </Panel.Content>
    </Panel>
  );
};

export default DashboardNotifications;
