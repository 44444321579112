import { gql } from '@apollo/client';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { InternetConnectionDetailsItem } from 'types/internetConnections';

export const assetQuery = gql`
  query GetAssetDetails($assetId: ID!) {
    asset(id: $assetId) {
      assetType
      isYritysnetti
      id
      productId
      name
      status
      displayId
      recurringTotal
      activationDate
      contractEndDate
      terminationDate
      productType
      order {
        projectId
        groupBillingId
        customerReferenceNumber
      }
      attributes {
        label
        value
      }
      childAssets {
        id
        parentId
        name
        status
        displayId
        address {
          streetAddress
          floor
          postalAddress
        }
        activationDate
        payer
        billingAccount
        attributes {
          label
          value
        }
      }
      address {
        streetAddress
        streetAddressSpecifier
        floor
        postalAddress
        country
      }
      isDNSAsset
      domainName
      companyCode
      companyName
    }
  }
`;

const useAsset = (assetId: string) => {
  const { data, error, loading } = useCallBackend<{
    asset: InternetConnectionDetailsItem;
  }>({
    query: assetQuery,
    queryVariables: {
      assetId
    }
  });

  return {
    asset: data?.asset,
    error,
    loading,
    hasError: !!error
  };
};

export default useAsset;
