import { gql } from '@apollo/client';
import getServiceRequestsOnFetchMore from 'hooks/serviceRequests/useServiceRequests/getServiceRequestsOnFetchMore';
import useApiStatus from 'hooks/useApiStatus/useApiStatus';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import {
  ServiceRequestDateParameters,
  ServiceRequestSearchParameters,
  ServiceRequestsInResults
} from 'types/serviceRequest';

import { getDefaultDateParams } from './getDefaultDateParams';

export const serviceRequestsQuery = gql`
  query GetServiceRequests(
    $status: String
    $searchParameters: ServiceRequestSearchParameters
    $dateParameters: ServiceRequestDateParameters
    $offset: Int!
    $limit: Int!
    $allCompanies: Boolean
  ) {
    serviceRequests(
      status: $status
      searchParameters: $searchParameters
      dateParameters: $dateParameters
      offset: $offset
      limit: $limit
      allCompanies: $allCompanies
    ) {
      total
      items {
        id
        caseNumber
        name
        status
        creationDate
        lastUpdate
        subscription
        companyName
        companyCode
      }
    }
  }
`;

const useServiceRequests = ({
  status,
  searchParameters,
  dateParameters,
  allCompanies
}: {
  status?: string;
  searchParameters?: ServiceRequestSearchParameters;
  dateParameters?: ServiceRequestDateParameters;
  allCompanies?: boolean;
}) => {
  const defaultDateParams = getDefaultDateParams(dateParameters?.filterBy);
  const hasDefaultParams = !dateParameters?.dateFrom && !dateParameters?.dateTo;
  const newDateParams = hasDefaultParams ? defaultDateParams : dateParameters;

  const {
    data,
    error,
    loading,
    loaded,
    loadingMore,
    fetchMore: fetchMoreFromQuery
  } = useCallBackend<ServiceRequestsInResults>({
    query: serviceRequestsQuery,
    queryVariables: {
      status,
      searchParameters,
      dateParameters: newDateParams,
      allCompanies,
      offset: 0,
      limit: 20
    },
    fetchPolicy: 'network-only'
  });

  const apiState = useApiStatus({
    loading,
    loadingMore,
    loaded,
    items: data?.serviceRequests?.items ?? [],
    error,
    query: {
      status,
      searchParameters,
      dateParameters: newDateParams
    }
  });

  return {
    serviceRequests: {
      total: data?.serviceRequests?.total ?? 0,
      items: data?.serviceRequests?.items
    },
    error,
    loading,
    hasError: !!error,
    fetchMore: () =>
      fetchMoreFromQuery({
        variables: { offset: data?.serviceRequests?.items?.length },
        updateQuery: getServiceRequestsOnFetchMore
      }),
    hasMoreResults: data
      ? (data?.serviceRequests?.items ?? []).length < data?.serviceRequests?.total
      : true,
    apiState: apiState
  };
};

export default useServiceRequests;
