import { useUserData } from 'contexts/UserContext/UserContext';
import { isWholesaleBusiness } from 'doings/isWholesaleBusiness/isWholesaleBusiness';
import userHasPermission from 'doings/userHasPermission/userHasPermission';
import { AuthorizationParams } from 'types/authorization';

import { DashboardNavigationB2B } from './DashboardNavigationB2B';
import { DashboardNavigationB2O } from './DashboardNavigationB2O';

type Condition = {
  permissions: AuthorizationParams;
  feature?: string;
};

export const notificationVisibilityConditions: Condition[] = [
  {
    permissions: {
      anyPermission: ['BILLING_INVOICES', 'BILLING_INVOICES_VIEW']
    }
  },
  {
    permissions: {
      anyPermission: ['SERVICE_REQUESTS', 'SERVICE_REQUESTS_VIEW']
    }
  },
  {
    permissions: {
      anyPermission: ['INCIDENT_TICKETS', 'INCIDENT_TICKETS_VIEW']
    }
  },
  {
    permissions: {
      onePermission: 'MESSAGE_ARCHIVE'
    },
    feature: 'DEO-3122'
  }
];

export const canShowFeature = (
  user: User,
  hasFeature: (feature: string) => boolean,
  conditions: Condition[]
) => {
  const hasPermission = userHasPermission(user);
  return conditions.some(
    (args) => (!args.feature || hasFeature(args.feature)) && hasPermission(args.permissions)
  );
};

export const mobileNetworkQualityVisibilityConditions: Condition[] = [
  {
    permissions: {
      onePermission: 'MOBILE_COMPANY_NETWORK'
    }
  }
];

export const pukCodeSearchVisibilityConditions: Condition[] = [
  {
    permissions: {
      anyPermission: ['MOBILE_SUBSCRIPTIONS', 'MOBILE_SUBSCRIPTIONS_VIEW']
    }
  }
];

export const DashboardNavigation: React.FC = () => {
  const user = useUserData();

  return isWholesaleBusiness(user) ? <DashboardNavigationB2O /> : <DashboardNavigationB2B />;
};
