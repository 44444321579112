import { ApolloError, gql } from '@apollo/client';
import { DATE_NATIONAL } from 'doings/formatDatetime/DateFormats';
import removeEmptyFields from 'doings/removeEmptyFields/removeEmptyFields';
import useApiStatus, { ViewStatus } from 'hooks/useApiStatus/useApiStatus';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { MutableRefObject, useCallback, useRef } from 'react';
import { CaseStatus, CompanyCaseListResult } from 'types/companyCaseList';
import { SortParameter } from 'types/sort';

import useCompanyCaseListSorting, {
  UseCompanyCaseListSortingResult
} from './useCompanyCaseListSorting';

export type CompanyCaseListParams = {
  allCompanies?: boolean;
  searchTerm: string;
  status?: CaseStatus;
  reportedDate?: {
    fromDate?: DATE_NATIONAL;
    toDate?: DATE_NATIONAL;
  };
};

export const companyCaseListQuery = gql`
  query GetCompanyCases($requestData: GetCompanyCaseListParams!) {
    companyCaseList(requestData: $requestData) {
      total
      paging {
        page
        pageSize
        pageCount
      }
      items {
        caseId
        multibellaGui
        reportedDate
        updatedDate
        title
        status
        caseTypeId
        closedDate
        reporter
        unread
        companyName
        companyCode
      }
      available
    }
  }
`;

const marshallQueryParams = (
  params: CompanyCaseListParams,
  page: number,
  sorting: { currentSort: SortParameter }
) => ({
  requestData: {
    ...removeEmptyFields(params),
    page: page,
    sort: {
      column: sorting.currentSort.sort,
      order: sorting.currentSort.order
    }
  }
});

export type FetchResult = { companyCaseList: CompanyCaseListResult };
export const createJoinItemsCallback =
  (pageRef: MutableRefObject<number>) =>
  (previousResult: FetchResult, result: { fetchMoreResult?: FetchResult }) => {
    if (!result.fetchMoreResult) {
      return previousResult;
    }

    const { companyCaseList: prevCaseList } = previousResult;
    const { companyCaseList: nextCaseList } = result.fetchMoreResult;
    pageRef.current = nextCaseList.paging.page;

    return {
      companyCaseList: {
        ...nextCaseList,
        items: [...prevCaseList.items, ...nextCaseList.items]
      }
    };
  };

export const createResetPageAndSearchCallback =
  (pageRef: MutableRefObject<number>, setSearchParams: (params: CompanyCaseListParams) => void) =>
  (params: CompanyCaseListParams) => {
    pageRef.current = 1;
    setSearchParams(params);
  };

export type UseCompanyCaseListResult = {
  error?: ApolloError;
  companyCases: CompanyCaseListResult;
  sorting: UseCompanyCaseListSortingResult;
  fetchMore: VoidFunction;
  apiState: ViewStatus;
};

/**
 * Retrieves a list of the active company's Multibella service requests, a.k.a
 * the company's Multibella cases. Exposes actions to sort, filter and fetch
 * more cases.
 */
const useCompanyCaseList = (initParams?: CompanyCaseListParams): UseCompanyCaseListResult => {
  const pageRef = useRef(1);
  const sorting = useCompanyCaseListSorting(pageRef);
  const searchParams = initParams || {
    searchTerm: ''
  };

  const { data, error, loading, loaded, loadingMore, fetchMore } = useCallBackend<FetchResult>({
    query: companyCaseListQuery,
    queryVariables: marshallQueryParams(searchParams, 1, sorting),
    fetchPolicy: 'network-only'
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getCompanyCasesOnFetchMore = useCallback(createJoinItemsCallback(pageRef), []);

  const doFetchMore = () =>
    fetchMore({
      variables: marshallQueryParams(searchParams, pageRef.current + 1, sorting),
      updateQuery: getCompanyCasesOnFetchMore
    });

  return {
    error,
    companyCases: data?.companyCaseList as CompanyCaseListResult,
    sorting,
    fetchMore: doFetchMore,
    apiState: useApiStatus({
      loading,
      loadingMore,
      loaded,
      items: data?.companyCaseList?.items || [],
      error,
      query: searchParams
    })
  };
};

export default useCompanyCaseList;
