import { MultiselectField } from 'types/form';

export default ({ values, options, required }: MultiselectField): boolean => {
  if (!required) {
    return true;
  }

  const optionValues = options.map(({ value }) => value);
  return values.length > 0 && values.every((value) => optionValues.includes(value));
};
