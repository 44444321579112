import routeAccessGroups from 'B2XApp/appRouteAccessGroups';
import useTickets from 'hooks/tickets/useTickets/useTickets';
import { useTicketsFilters } from 'hooks/tickets/useTicketsFilters/useTicketsFilters';
import useInitialLoadStatus from 'hooks/useInitialLoadStatus/useInitialLoadStatus';
import useUserHasMultipleCompaniesWithGivenPermission from 'hooks/useUser/useUserHasMultipleCompaniesWithGivenPermission';

import TicketsList from './TicketsList';

const Tickets = () => {
  const multipleCompanySearchEnabled = useUserHasMultipleCompaniesWithGivenPermission(
    routeAccessGroups.INCIDENT_TICKETS
  );
  const filters = useTicketsFilters(multipleCompanySearchEnabled);
  const { queryParameters } = filters;

  const { tickets, total, error, fetchMore, apiState } = useTickets({
    queryParameters,
    allCompanies: filters.allCompanySearch.showFromAllCompanies
  });

  const status = useInitialLoadStatus(apiState);

  return (
    <TicketsList
      ticketsFilter={filters}
      tickets={{ total, items: tickets }}
      status={status}
      error={error}
      fetchMore={fetchMore}
      multipleCompanySearchEnabled={multipleCompanySearchEnabled}
    />
  );
};

export default Tickets;
