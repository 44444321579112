import formatDatetime from 'doings/formatDatetime/formatDatetime';
import { DateFields } from 'types/ticket';

export const mapTicketDates = <T>(data: T & DateFields): T & DateFields => ({
  ...data,
  creationDate: formatDatetime(data.creationDate),
  lastUpdate: formatDatetime(data.lastUpdate)
});

export default mapTicketDates;
