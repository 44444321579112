import { ArticleType } from 'types/articles';

export type SearchResultItem = {
  title: string;
  url: string;
  pageType: ArticleType;
  scoreType: ScoreType;
};

export type ContentEntry = {
  title: string;
  keywords: string[];
  keywordWeightRate?: number;
  target: ContentTarget;
};

export type ScoredContentEntry = ContentEntry & { score: ContentEntryScore };

type ContentTarget = {
  url: string;
  targetPortal: TargetPortal;
};

export type ContentEntryScore = {
  value: number;
  type: ScoreType;
};

type TargetPortal = 'b2b' | 'b2x' | 'external';

export enum ScoreType {
  TITLE_EXACT = 'TITLE_EXACT',
  TITLE_PARTIAL = 'TITLE_PARTIAL',
  KEYWORD_MATCH = 'KEYWORD_MATCH',
  CLOSE_TITLE = 'CLOSE_TITLE',
  CLOSE_KEYWORD = 'CLOSE_KEYWORD',
  NO_MATCH = 'NO_MATCH'
}
