import { gql } from '@apollo/client';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';

export const paymentBanksQuery = gql`
  query GetInvoicePaymentBanks {
    paymentBanks {
      bankId
      bankName
    }
  }
`;

export type Bank = {
  bankId: string;
  bankName: string;
};

interface PaymentBanks {
  paymentBanks: Bank[];
}

export default () => {
  const { data } = useCallBackend<PaymentBanks>({
    query: paymentBanksQuery
  });

  return {
    banks: data?.paymentBanks ?? []
  };
};
