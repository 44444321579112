import { Div, Flex, Gutter } from '@gaiads/telia-react-component-library';

const WIDER = 0.6;

const SingleColumnLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Flex pileVerticallyTo="md" data-testid="single-column-layout">
    <Div flexItem={{ sizeMultiplier: WIDER }}>{children}</Div>

    <Gutter size="sm" responsiveHidden={{ thinnerThan: 'md', at: 'md' }} />
  </Flex>
);

export default SingleColumnLayout;
