import getEnvironmentVariable from 'doings/getEnvironmentVariable/getEnvironmentVariable';
import { AnalyticsEvent } from 'doings/track/analyticsEvents';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showInfoModal } from 'redux/slices/infoModalSlice';

interface Props {
  whenClicked: AnalyticsEvent;
}

export default ({ whenClicked } = {} as Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const showEcomMobileFlowModal = () => {
    dispatch(
      showInfoModal({
        title: t('subscriptions.firstTimePurchaser.redirectionTitle'),
        text: t('subscriptions.firstTimePurchaser.redirectionDescription'),
        link: getEnvironmentVariable('REACT_APP_LINK_TO_ECOMM_MOBILE_FLOW'),
        ctaText: t('subscriptions.firstTimePurchaser.redirectAction'),
        whenClicked
      })
    );
  };

  return { showEcomMobileFlowModal };
};
