import { BodyText, Flex, Icon, List } from '@gaiads/telia-react-component-library';
import { AnalyticsEvent } from 'doings/track/analyticsEvents';
import {
  Service,
  SubscriptionStatus
} from 'hooks/mobileSubscriptions/useSubscriptionDetails/subscriptionDetailsQuery';

import SubscriptionAccordion from '../SubscriptionAccordion/SubscriptionAccordion';
import ServiceParameter from './ServiceParameter';

export type ServiceList = {
  services: Service[];
  phoneNumberHash?: string;
  status?: SubscriptionStatus;
  loading: boolean;
  expanded?: boolean;
  disabled?: boolean;
};

const SubscriptionServices: React.FC<{
  services: Service[];
  phoneNumberHash?: string;
  status?: SubscriptionStatus;
  corporatePortalUrl: string;
  accordionHeading: string;
  editButtonText: string;
  innerHeading: string;
  emptyInnerHeading: string;
  loading: boolean;
  testId: string;
  analyticsEventForExpand: AnalyticsEvent;
  analyticsEventForEdit: AnalyticsEvent;
  expanded?: boolean;
  disabled?: boolean;
}> = ({
  corporatePortalUrl,
  services,
  phoneNumberHash,
  status,
  accordionHeading,
  innerHeading,
  emptyInnerHeading,
  editButtonText,
  loading,
  testId,
  analyticsEventForExpand,
  analyticsEventForEdit,
  disabled = false,
  expanded = false
}) => {
  const hasServices = !!services.length;

  return (
    <SubscriptionAccordion
      heading={{
        label: accordionHeading,
        testId,
        trackOnExpand: analyticsEventForExpand
      }}
      actionButton={{
        visible: !disabled,
        label: editButtonText,
        corporatePortalUrl,
        testId: 'change-subscription-services',
        trackOnClick: analyticsEventForEdit
      }}
      phoneNumberHash={phoneNumberHash}
      status={status}
      loading={loading}
      initiallyExpanded={expanded}
      data-testid="subscription-services-accordion"
    >
      {!hasServices && <BodyText fontWeight="normal">{emptyInnerHeading}</BodyText>}

      {hasServices && (
        <>
          <BodyText fontWeight="bold" margin={{ bottom: 'sm' }}>
            {innerHeading}
          </BodyText>

          <List>
            {services.map((service) => (
              <List.Item key={service.code}>
                <Flex centeredVertically>
                  <Icon name="checkmark" size="sm" margin={{ right: 'xs' }} />

                  <BodyText>
                    {service.name}

                    {service.parameter && (
                      <ServiceParameter code={service.code} parameter={service.parameter} />
                    )}
                  </BodyText>
                </Flex>
              </List.Item>
            ))}
          </List>
        </>
      )}
    </SubscriptionAccordion>
  );
};

export default SubscriptionServices;
