import useDevice from 'hooks/devices/useDevice/useDevice';
import { useParams } from 'react-router';

import DeviceDetailsPage from './DeviceDetailsPage';

interface DeviceDetailsParams {
  deviceId: string;
}

const DeviceDetails: React.FC = () => {
  const { deviceId } = useParams<DeviceDetailsParams>();
  const { device, loading, hasError } = useDevice(deviceId);

  return (
    <DeviceDetailsPage
      data-testid="device-details-page"
      device={device}
      loading={loading}
      hasError={hasError}
    />
  );
};

export default DeviceDetails;
