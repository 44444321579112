import { gql } from '@apollo/client';
import { mapOrderListItem } from 'hooks/orders/mapOrders';
import useCallBackend, { CallBackendResponse } from 'hooks/useCallBackend/useCallBackend';
import { OrderListItem } from 'types/orders';

export type Status = 'error' | 'loading' | 'loaded';

export const orderQuery = gql`
  query GetOrder($orderId: ID!) {
    order(orderId: $orderId) {
      id
      displayId
      companyCode
      companyName
      createdDate
      status
      estimatedTimeOfCompletion
      completionPercentage
      isEcommerce
      externalId
      lineItems {
        productName
        status
        estimatedTimeOfCompletion
        completionPercentage
        displayAddress
      }
    }
  }
`;

type OrderResponse = {
  order: OrderListItem;
};

const getStatus = ({ loading, data }: CallBackendResponse<OrderResponse>): Status => {
  if (loading) {
    return 'loading';
  }

  if (data?.order) {
    return 'loaded';
  }

  return 'error';
};

export default (orderId: string) => {
  const result = useCallBackend<OrderResponse>({
    query: orderQuery,
    queryVariables: {
      orderId
    }
  });

  const order = result?.data?.order ? mapOrderListItem(result.data.order) : undefined;
  const status = getStatus(result);

  return {
    status,
    order
  };
};
