import BillingGroupForm from 'B2XApp/Invoicing/BillingGroups/BillingGroupForm/BillingGroupForm';
import { BillingGroupFormData } from 'B2XApp/Invoicing/BillingGroups/BillingGroupForm/useBillingGroupForm';
import { TeliaLink } from 'common-components';
import ConfirmationPanelError from 'common-components/ConfirmationPanel/ConfirmationPanelError';
import ConfirmationPanelSuccess from 'common-components/ConfirmationPanel/ConfirmationPanelSuccess';
import { invoicing } from 'doings/track/analyticsEvents';
import useSearchParams from 'hooks/useSearchParams/useSearchParams';
import { Trans, useTranslation } from 'react-i18next';

import { CopyBillingGroupPrerequisites } from './useCopyBillingGroupForm';

const CopyBillingGroupPageForm: React.FC<{
  form: BillingGroupFormData;
  prerequisites: CopyBillingGroupPrerequisites;
}> = ({ form, prerequisites }) => {
  const { t } = useTranslation();
  const { preserveBackPathState } = useSearchParams();

  const { collectionGroup } = prerequisites;
  const collectionGroupId = collectionGroup.collectionGroup;
  const { step } = form;

  switch (step) {
    case 'unavailable':
    case 'prechecking':
    case 'precheck-failure':
      return (
        <ConfirmationPanelError
          title={t('invoices.billingGroup.form.copy.notification.cannotCopyTitle', {
            collectionGroupId
          })}
          description={t('invoices.billingGroup.form.copy.notification.cannotCopyDescription')}
          backToInitialStepOnClick={form.onCancel}
          backToLabel={t('invoices.billingGroup.form.copy.notification.backToBillingGroup')}
          data-testid="collection-group-copy__unavailable"
        />
      );

    case 'initial':
    case 'persisting':
    case 'failure':
      return (
        <BillingGroupForm
          collectionGroup={collectionGroup}
          form={form}
          failureNoticeOnPersisting={{
            title: t('invoices.billingGroup.form.copy.notification.failedToCopyTitle'),
            content: t('invoices.billingGroup.form.copy.notification.failedToCopyDescription')
          }}
          analyticsEvent={invoicing.copyCollectionGroup}
          data-testid="collection-group-copy__form"
          pageType="copy"
        />
      );

    case 'success':
      return (
        <ConfirmationPanelSuccess
          title={t('invoices.billingGroup.form.copy.notification.successTitle', {
            collectionGroupId
          })}
          description={
            <Trans
              i18nKey={t(
                'invoices.billingGroup.form.copy.notification.successDescriptionWithLink.description',
                { collectionGroupId: form.data?.newCollectionGroupId }
              )}
              components={[
                <TeliaLink.Internal
                  key="link-to-billing-accounts-list"
                  variant="text"
                  to={preserveBackPathState('/billing-accounts/', true)}
                  label={t(
                    'invoices.billingGroup.form.copy.notification.successDescriptionWithLink.link'
                  )}
                />
              ]}
            />
          }
          backToPath={preserveBackPathState(
            `/billing-accounts/${collectionGroup.collectionGroup}`,
            true
          )}
          backToLabel={t('invoices.billingGroup.form.copy.notification.backToBillingGroup')}
          data-testid="collection-group-copy__success"
        />
      );

    case 'partial-success':
      return (
        <ConfirmationPanelSuccess
          title={t('invoices.billingGroup.form.copy.notification.successTitle', {
            collectionGroupId
          })}
          description={
            <Trans
              i18nKey="serviceRequests.newServiceRequest.successDescriptionWithLink.description"
              components={[
                <TeliaLink.Internal
                  key="link-to-service-requests-list"
                  variant="text"
                  to="/service-requests/"
                  label={t('serviceRequests.newServiceRequest.successDescriptionWithLink.link')}
                />
              ]}
            />
          }
          backToPath={`/billing-accounts/${collectionGroup.collectionGroup}`}
          backToLabel={t('invoices.billingGroup.form.copy.notification.backToBillingGroup')}
          data-testid="collection-group-copy__partial-success"
        />
      );
  }
};

export default CopyBillingGroupPageForm;
