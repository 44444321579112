import createCorporatePortalLink from 'doings/links/createCorporatePortalLink/createCorporatePortalLink';
import { mobile } from 'doings/track/analyticsEvents';
import { useTranslation } from 'react-i18next';

import SubscriptionServices, { ServiceList } from './SubscriptionServices';

const RestrictionServices: React.FC<ServiceList> = ({
  services,
  status,
  loading,
  phoneNumberHash,
  disabled,
  expanded = false
}) => {
  const { t } = useTranslation();
  const corporatePortalUrl = createCorporatePortalLink(
    'mobileSubscriptionRestrictions',
    { msisdn: phoneNumberHash },
    { section: 'restrictions' }
  );

  return (
    <SubscriptionServices
      testId="barrings-and-restrictions-accordion"
      data-testid="barrings-and-restrictions-accordion"
      accordionHeading={t('subscriptions.subscriptionDetails.restrictionServices', {
        count: services.length
      })}
      innerHeading={t('subscriptions.subscriptionDetails.activeRestrictionServices')}
      emptyInnerHeading={t('subscriptions.subscriptionDetails.noActiveRestrictionServices')}
      editButtonText={t('subscriptions.subscriptionDetails.editRestrictionServices')}
      corporatePortalUrl={corporatePortalUrl}
      services={services}
      phoneNumberHash={phoneNumberHash}
      status={status}
      loading={loading}
      analyticsEventForEdit={mobile.changeRestrictions}
      analyticsEventForExpand={mobile.viewRestrictions}
      expanded={expanded}
      disabled={disabled}
    />
  );
};

export default RestrictionServices;
