import BillMillAccountForm from 'B2XApp/Invoicing/BillingGroups/BillMillAccountForm/BillMillAccountForm';
import {
  BillMillAccountFormData,
  BillMillAccountFormStep
} from 'B2XApp/Invoicing/BillingGroups/BillMillAccountForm/useBillMillAccountForm';
import { TeliaLink } from 'common-components';
import ConfirmationPanelSuccess from 'common-components/ConfirmationPanel/ConfirmationPanelSuccess';
import { invoicing } from 'doings/track/analyticsEvents';
import useSearchParams from 'hooks/useSearchParams/useSearchParams';
import { Trans, useTranslation } from 'react-i18next';

import { EditBillMillAccountPrerequisites } from './useEditBillMillAccountForm';

const EditBillMillAccountPageForm: React.FC<{
  form: BillMillAccountFormData;
  prerequisites: EditBillMillAccountPrerequisites;
}> = ({ form, prerequisites }) => {
  const { t } = useTranslation();
  const { preserveBackPathState } = useSearchParams();

  const { billMillAccount } = prerequisites;
  const accountId = billMillAccount.accountId;
  const step = form.step as BillMillAccountFormStep;

  switch (step) {
    case 'initial':
    case 'persisting':
    case 'failure':
      return (
        <BillMillAccountForm
          billMillAccount={billMillAccount}
          form={form}
          noticeOnFailureTitle={t(
            'invoices.billingGroup.form.edit.notification.failedToUpdateTitle',
            { collectionGroupId: accountId }
          )}
          noticeOnFailureDescription={t(
            'invoices.billingGroup.form.edit.notification.failedToUpdateDescription'
          )}
          analyticsEvent={invoicing.updateBillMillAccount}
          data-testid="billing-account-edit__form"
        />
      );

    case 'success':
      return (
        <ConfirmationPanelSuccess
          title={t('invoices.billingGroup.form.edit.notification.successTitle', {
            collectionGroupId: accountId
          })}
          description={
            <Trans
              i18nKey="invoices.billingGroup.form.edit.notification.billmillSuccessDescription"
              components={[
                <TeliaLink.Internal
                  key="link-to-service-requests-list"
                  variant="text"
                  to="/service-requests"
                  label={t(
                    'invoices.billingGroup.form.edit.notification.billmillSuccessDescriptionLink'
                  )}
                />
              ]}
            />
          }
          backToPath={preserveBackPathState(`/billing-accounts/${accountId}`, true)}
          backToLabel={t('invoices.billingGroup.form.edit.notification.backToBillingGroup')}
          data-testid="billing-account-edit__success"
        />
      );
  }
};

export default EditBillMillAccountPageForm;
