import useOrders from 'hooks/orders/useOrders/useOrders';
import useOrdersFilters from 'hooks/orders/useOrders/useOrdersFilters';
import useInitialLoadStatus from 'hooks/useInitialLoadStatus/useInitialLoadStatus';
import useUserHasMultipleCompaniesWithGivenPermission from 'hooks/useUser/useUserHasMultipleCompaniesWithGivenPermission';

import OrdersListPage from './OrdersListPage';

const Orders: React.FC = () => {
  const multipleCompanySearchEnabled = useUserHasMultipleCompaniesWithGivenPermission({
    anyPermission: ['B2O_ORDERS', 'B2O_ORDERS_VIEW', 'B2B_ORDERS', 'B2B_ORDERS_VIEW']
  });

  const orderFilters = useOrdersFilters(multipleCompanySearchEnabled);
  const { orders, hasMoreResults, fetchMore, status, error } = useOrders(
    orderFilters.queryParameters,
    orderFilters.allCompanySearch.showFromAllCompanies,
    !orderFilters.searchTermValid
  );

  const apiStatus = useInitialLoadStatus(status);

  return (
    <OrdersListPage
      orderFilters={orderFilters}
      orders={orders}
      multipleCompanySearch={multipleCompanySearchEnabled}
      hasMoreResults={hasMoreResults}
      fetchMore={fetchMore}
      status={apiStatus}
      error={error}
      data-testid="orders-list-page"
    />
  );
};

export default Orders;
