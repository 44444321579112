import { ListView } from 'common-components';
import { useTranslation } from 'react-i18next';
import { Device } from 'types/device';

const DevicesList: React.FC<{
  devices: Device[];
}> = ({ devices }) => {
  const { t } = useTranslation();

  return (
    <ListView
      columns={{
        productName: {
          heading: t('daas.device.productName')
        },
        deviceUser: {
          heading: t('daas.device.user')
        }
      }}
      mobileLayout={{
        titleRow: { col: 'productName' },
        rows: [{ col: 'deviceUser' }]
      }}
      listData={devices}
      data-testid="device-list"
    >
      {({ id, deviceUser, productName }) => (
        <ListView.Row data-testid="devices-list-row-item" linkTo={`/devices/${id}`}>
          <ListView.RowShape
            cells={{
              productName: {
                data: productName,
                props: {}
              },
              deviceUser: {
                data: deviceUser,
                props: {}
              }
            }}
          />
        </ListView.Row>
      )}
    </ListView>
  );
};

export default DevicesList;
