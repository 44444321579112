import { InlineNotification } from 'common-components';

export const OngoingOwnershipChangeNotice: React.FC = () => (
  <InlineNotification
    title={{ key: 'subscriptions.subscriptionDetails.status.ownershipChange.ongoing' }}
    content={{ key: 'subscriptions.subscriptionDetails.status.ownershipChange.ongoingMessage' }}
    variant="information"
    data-testid="ownership-change-ongoing-notification"
  />
);
