import { GrowlNotification } from '@gaiads/telia-react-component-library';
import styles from 'B2XApp/TopNotices/FixedBar.module.scss';
import { useFeatureFlagsData } from 'contexts/FeatureFlagContext/FeatureFlagContext';
import { useTranslation } from 'react-i18next';

const FeaturesUnavailableBar: React.FC = () => {
  const { t } = useTranslation();
  const { showFeaturesUnavailableNotice, hideFeaturesUnavailableNotice } = useFeatureFlagsData();
  if (!showFeaturesUnavailableNotice) {
    return null;
  }

  return (
    <GrowlNotification
      data-testid="features-unavailable-notification-bar"
      className={styles.fixedBar}
      notification={t('notifications.featureFlagsFailedToLoad')}
      variant="error"
      closeOnClick={hideFeaturesUnavailableNotice}
    />
  );
};

export default FeaturesUnavailableBar;
