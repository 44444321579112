import { useReducer } from 'react';
import { Sort, SortAction, SortDirection, SortOrder, SortParameter } from 'types/sort';

enum Action {
  CLICK = 'CLICK',
  SORT = 'SORT'
}

type Event = ClickEvent | SortEvent;

type ClickEvent = {
  type: Action.CLICK;
  field: string;
};

type SortEvent = {
  type: Action.SORT;
  sort: string;
  order: SortOrder;
};

export type SortState = Record<string, SortDirection>;

const getInitialState = (fields: string[], initialSort: SortParameter): SortState =>
  fields.reduce(
    (state, field) => ({
      ...state,
      [field]: field === initialSort.sort ? initialSort.order : SortDirection.DEFAULT
    }),
    {}
  );

const nextSort = (clickedField: string, field: string, direction: SortDirection): SortDirection => {
  if (clickedField !== field) {
    return SortDirection.DEFAULT;
  }

  return direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
};

const clicked = (state: SortState, clickedField: string): SortState =>
  Object.keys(state).reduce(
    (newState, field) => ({
      ...newState,
      [field]: nextSort(clickedField, field, state[field])
    }),
    {}
  );

const sorted = (state: SortState, sort: string, order: SortOrder): SortState =>
  Object.keys(state).reduce(
    (newState, field) => ({
      ...newState,
      [field]: sort === field ? order : SortDirection.DEFAULT
    }),
    {}
  );

const reducer = (state: SortState, action: Event) => {
  switch (action.type) {
    case Action.CLICK:
      return clicked(state, action.field);
    case Action.SORT:
      return sorted(state, action.sort, action.order);
  }
};

export type ClickAction = (field: string) => void;

export type OnSortChange = (event: { field: string; direction: SortOrder }) => void;

const convertSortDirection = (direction: SortDirection): SortOrder | undefined => {
  switch (direction) {
    case SortDirection.ASC:
      return SortOrder.ASC;
    case SortDirection.DESC:
      return SortOrder.DESC;
    default:
      return undefined;
  }
};

export const getSort = (
  state: SortState,
  onClick: (field: string) => void,
  field: string
): Sort => ({
  direction: state[field],
  onClick: () => onClick(field)
});

const useSortableHeadings = (fields: string[], initialSort: SortParameter) => {
  const [state, dispatch] = useReducer(reducer, getInitialState(fields, initialSort));

  const click: ClickAction = (field) =>
    dispatch({
      type: Action.CLICK,
      field
    });

  const sort: SortAction = (sort, order) =>
    dispatch({
      type: Action.SORT,
      sort,
      order
    });

  const sortKey = Object.keys(state).find((key) => state[key] !== SortDirection.DEFAULT);

  const currentSort: SortParameter = sortKey
    ? { sort: sortKey, order: convertSortDirection(state[sortKey]) }
    : {
        sort: undefined,
        order: undefined
      };

  const onSortChange: OnSortChange = ({
    field,
    direction
  }: {
    field: string;
    direction: SortOrder;
  }) => sort(field, direction);

  return {
    sortState: state,
    sortClick: click,
    doSort: sort,
    currentSort,
    onSortChange
  };
};

export default useSortableHeadings;
