import { DatePickerWithRange } from '@gaiads/telia-react-component-library';
import { useGaiaDatePickerKeyboardA11yFix } from 'hooks/gaiaUX';
import { DateInputModel } from 'hooks/inputModels/useDateInputModel/useDateInputModel';
import { useGenerateId } from 'hooks/useGenerateId/useGenerateId';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ModelDateFilter.module.scss';

const DAY_PICKER_MAX_WIDTH_IN_PX = 760;

const useSpaciousDayPickerLayoutWhenThereIsRoom = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const onResize = useCallback(() => {
    const input = containerRef.current;
    /* istanbul ignore next */
    if (!input) {
      return;
    }

    input.classList.toggle(
      styles.dateFilter__spacious,
      window.innerWidth - input.getBoundingClientRect().left >= DAY_PICKER_MAX_WIDTH_IN_PX
    );
  }, [containerRef]);

  useEffect(() => {
    onResize();
  }, [onResize]);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  return { containerRef };
};

const ModelDateFilter: React.FC<{
  dateFromInputModel: DateInputModel;
  dateToInputModel: DateInputModel;
  label: string;
  'data-testid': string;
}> = ({ dateFromInputModel, dateToInputModel, label, 'data-testid': testId }) => {
  const { i18n, t } = useTranslation();
  const { containerRef } = useSpaciousDayPickerLayoutWhenThereIsRoom();
  const fromDayPickerFix = useGaiaDatePickerKeyboardA11yFix(containerRef);
  const tillDayPickerFix = useGaiaDatePickerKeyboardA11yFix(containerRef);
  const { generatedId: dateFromId } = useGenerateId('date-from');
  const { generatedId: dateToId } = useGenerateId('date-to');

  return (
    <div ref={containerRef}>
      <DatePickerWithRange
        data-testid={testId}
        className={styles.dateFilter}
        label={label}
        fromDatePicker={{
          id: dateFromId,
          value: dateFromInputModel.value,
          placeholder: t('common.dateFilter.fromDatePlaceholder'),
          format: 'D.M.YYYY',
          onChange: dateFromInputModel.setValue,
          locale: i18n.language,
          onDayPickerShow: () => {
            fromDayPickerFix.connect();
          },
          onDayPickerHide: () => {
            fromDayPickerFix.disconnect();
          }
        }}
        toDatePicker={{
          id: dateToId,
          value: dateToInputModel.value,
          placeholder: t('common.dateFilter.toDatePlaceholder'),
          format: 'D.M.YYYY',
          onChange: dateToInputModel.setValue,
          locale: i18n.language,
          onDayPickerShow: () => {
            tillDayPickerFix.connect();
          },
          onDayPickerHide: () => {
            tillDayPickerFix.disconnect();
          }
        }}
      />
    </div>
  );
};

export default ModelDateFilter;
