import { createCorporatePortalLinkWithFragment } from 'doings/links/createCorporatePortalLink/createCorporatePortalLink';
import createLinkWithPublicUrl from 'doings/links/createLinkWithPublicUrl/createLinkWithPublicUrl';
import useSubscriptionDetails from 'hooks/mobileSubscriptions/useSubscriptionDetails/useSubscriptionDetails';
import useSearchParams from 'hooks/useSearchParams/useSearchParams';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import TransferSubscriptionPage from './TransferSubscriptionPage';

interface TransferSubscriptionParams {
  phoneNumberHash: string;
}

export const TransferSubscription: React.FC = () => {
  const { t } = useTranslation();
  const { phoneNumberHash } = useParams<TransferSubscriptionParams>();
  const { subscription, status } = useSubscriptionDetails(phoneNumberHash, { fetchOmsu: true });

  return (
    <TransferSubscriptionPage
      subscription={subscription}
      loading={status === 'loading'}
      hasError={status === 'error'}
      backButtonLabel={t('common.backToListButton.label')}
      backButtonPath={createCorporatePortalLinkWithFragment(
        'search/subscriptionSearch',
        'mobilesubscription',
        { originator: createLinkWithPublicUrl('/') }
      )}
    />
  );
};

export const TransferSubscriptionViaDetails: React.FC = () => {
  const { t } = useTranslation();
  const { preserveBackPathState } = useSearchParams();
  const { phoneNumberHash } = useParams<TransferSubscriptionParams>();
  const { subscription, status } = useSubscriptionDetails(phoneNumberHash, { fetchOmsu: true });

  return (
    <TransferSubscriptionPage
      subscription={subscription}
      loading={status === 'loading'}
      hasError={status === 'error'}
      backButtonLabel={t('common.backButton.label')}
      backButtonPath={preserveBackPathState(`/subscriptions/${phoneNumberHash}`, true)}
    />
  );
};

export const TransferSubscriptionViaList: React.FC = () => {
  const { t } = useTranslation();
  const { phoneNumberHash } = useParams<TransferSubscriptionParams>();
  const { subscription, status } = useSubscriptionDetails(phoneNumberHash, { fetchOmsu: true });

  return (
    <TransferSubscriptionPage
      subscription={subscription}
      loading={status === 'loading'}
      hasError={status === 'error'}
      backButtonLabel={t('common.backToListButton.label')}
      backButtonPath="/subscriptions/"
    />
  );
};

export default TransferSubscription;
