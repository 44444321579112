import { OrdersResponse } from './useOrders';

export default (
  previousResult: OrdersResponse,
  { fetchMoreResult }: { fetchMoreResult?: OrdersResponse }
) =>
  !fetchMoreResult
    ? previousResult
    : {
        orders: {
          ...fetchMoreResult.orders,
          orders: [...previousResult.orders.orders, ...fetchMoreResult.orders.orders]
        }
      };
