import { getCookie } from 'doings/getCookie/getCookie';
import { B2XCookie } from 'types/clientCookie';

/**
 * Subtract 10 seconds from the actual expiration epoch timestamp
 * to give a safe grace period to the user to renew the session safely
 * before it expires.
 */
const GRACE_PERIOD = 10;
const MILLIS_IN_SECOND = 1000;

export default () => {
  const expiresAt = getCookie(B2XCookie.SESSION_EXPIRES_AT);
  return expiresAt
    ? Number(expiresAt) - new Date().getTime() / MILLIS_IN_SECOND - GRACE_PERIOD
    : undefined;
};
