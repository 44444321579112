import { TeliaLink } from 'common-components';
import { useUserData } from 'contexts/UserContext/UserContext';
import getNavigationalLinks, { LinkTarget } from 'doings/getNavigationalLinks/getNavigationalLinks';
import { isSmallBusiness } from 'doings/isSmallBusiness/isSmallBusiness';
import { assets } from 'doings/track/analyticsEvents';
import { useTranslation } from 'react-i18next';

export function FullProductOfferingLink() {
  const { t } = useTranslation();
  const userData = useUserData();
  const url =
    getNavigationalLinks(userData).navigationalLinks[LinkTarget.OFFERINGS__BUSINESS_OFFERING].to ||
    '';
  const analyticsEvent = isSmallBusiness(userData)
    ? assets.fullProductOfferingSmallBusinessLink
    : assets.fullProductOfferingLargeBusinessLink;
  return (
    <TeliaLink.External
      data-testid="empty-service-list-full-product-offering-link"
      variant="text"
      to={url}
      label={t('internetConnections.list.servicesNotice.fullProductOfferingLink')}
      iconRight="external"
      track={analyticsEvent}
    />
  );
}
