import { InlineNotification } from 'common-components';
import { Div } from 'core-components';
import { ApiStatus } from 'types/api';

const PaymentReminderNotification: React.FC<{
  status: ApiStatus;
  available: boolean;
}> = ({ status, available }) => {
  if (status === ApiStatus.Loaded && !available) {
    return (
      <Div padding={{ top: 'lg' }}>
        <InlineNotification
          title={{ key: 'invoices.paymentReminder.notification.unavailableTitle' }}
          content={{ key: 'invoices.paymentReminder.notification.unavailableMessage' }}
          variant="information"
          data-testid="payment-reminder-notification__unavailable"
        />
      </Div>
    );
  }

  if (status === ApiStatus.Error) {
    return (
      <Div padding={{ top: 'lg' }}>
        <InlineNotification
          title={{ key: 'invoices.paymentReminder.notification.technicalErrorTitle' }}
          content={{ key: 'invoices.paymentReminder.notification.technicalErrorMessage' }}
          variant="warning"
          data-testid="payment-reminder-notification__error"
        />
      </Div>
    );
  }

  return null;
};

export default PaymentReminderNotification;
