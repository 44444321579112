import { AdditionalTicketInfoSkeleton } from 'B2XApp/Messages/common';
import NewServiceRequestSkeleton from 'B2XApp/Messages/ServiceRequests/NewServiceRequest/NewServiceRequestSkeleton';

import CreateTicketPageContainer from './CreateTicketPageContainer';

export const CreateTicketPageSkeleton = () => (
  <CreateTicketPageContainer
    additionalInfo={<AdditionalTicketInfoSkeleton />}
    backLinkVisible
    loading
  >
    <NewServiceRequestSkeleton />
  </CreateTicketPageContainer>
);
