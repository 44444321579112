import { BodyText, Div } from '@gaiads/telia-react-component-library';
import { SmartTooltip } from 'common-components';
import useIsSmallerBreakpointActive from 'hooks/useIsSmallerBreakpointActive/useIsSmallerBreakpointActive';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';

import styles from './MobileNetworkQuality.module.scss';
import { NetworkQualityTooltipContent } from './NetworkQualityTooltipContent';

export const NetworkQualityItem: React.FC<{
  label: string;
  quality?: number;
  details?: string[];
}> = ({ label, quality, details }) => {
  return (
    <Div
      flexItem
      className={styles.mobileNetworkQualityItem}
      data-testid="dashboard-mobile-network-quality-item"
    >
      <SmartTooltip
        tooltipContent={<NetworkQualityTooltipContent details={details} />}
        arrangement="top"
        wrapper="div"
      >
        <SmartTooltip.Trigger wrapper="div">
          <Div
            data-testid="dashboard-mobile-network-quality-item--content"
            className={styles.circularMeter}
            clickableAppearance
            tabIndex={0}
            role="meter"
            aria-valuemin={0}
            aria-valuenow={quality ?? 0}
            aria-valuemax={100}
            aria-label={label}
          >
            <CircularProgressbarWithChildren
              value={quality ?? 0}
              strokeWidth={3}
              styles={buildStyles({
                trailColor: '#f0f0f0',
                pathColor: '#990ae3'
              })}
            >
              <NetworkQualityItemContent label={label} quality={quality} />
            </CircularProgressbarWithChildren>
          </Div>
        </SmartTooltip.Trigger>
      </SmartTooltip>
    </Div>
  );
};

const NetworkQualityItemContent: React.FC<{
  label: string;
  quality?: number;
}> = ({ label, quality }) => {
  const isMobileLayout = useIsSmallerBreakpointActive('sm');
  const contentSize = isMobileLayout ? 'sm' : 'md';
  const { t } = useTranslation();

  return (
    <>
      <BodyText
        data-testid="dashboard-mobile-network-quality-item--content-label"
        size={contentSize}
      >
        {label}
      </BodyText>

      <BodyText
        data-testid="dashboard-mobile-network-quality-item--content-value"
        size={contentSize}
      >
        {quality ? `${quality} %` : t('dashboard.mobileNetworkQuality.NA')}
      </BodyText>
    </>
  );
};
