import getRelatedCompaniesForDropdown from 'doings/getRelatedCompaniesForDropdown/getRelatedCompaniesForDropdown';
import usePaymentReminders from 'hooks/invoicing/paymentReminders/usePaymentReminders/usePaymentReminders';
import { useState } from 'react';

import PaymentRemindersPage from './PaymentRemindersPage';

const PaymentReminders: React.FC = () => {
  const { activeGroupId, availableCompanies } = getRelatedCompaniesForDropdown({
    onePermission: 'BILLING',
    activeGroup: 'b2b'
  });
  const [companyId, setCompanyId] = useState<string | undefined>(activeGroupId);
  const { status, available, reminders, refetch } = usePaymentReminders(companyId);

  return (
    <PaymentRemindersPage
      status={status}
      available={available}
      reminders={reminders}
      refetch={refetch}
      companyId={companyId}
      setCompanyId={setCompanyId}
      availableCompanies={availableCompanies}
      data-testid="payment-reminders-page"
    />
  );
};

export default PaymentReminders;
