import { BodyText, Flex } from '@gaiads/telia-react-component-library';
import { Analytics, SmartTooltip } from 'common-components';
import TextLink from 'common-components/Link/TextLink/TextLink';
import { invoicing } from 'doings/track/analyticsEvents';
import { useTranslation } from 'react-i18next';

const DueDate: React.FC<{
  invoiceKey: string;
  dueDate: string;
  paymentPlanActive?: boolean;
  firstPayDate?: string | null;
}> = ({ invoiceKey, dueDate, paymentPlanActive, firstPayDate }) => {
  const { t } = useTranslation();

  const showApplyForExtension = !!firstPayDate && !paymentPlanActive;

  const tooltipContent = paymentPlanActive
    ? t('invoice.paymentPlan.alreadyExtended')
    : t('invoice.paymentPlan.cannotApply');

  return (
    <Flex
      wrapContent
      centeredVertically
      occupyVerticalSpace
      spaceBetween={!!showApplyForExtension}
      data-testid="due-date"
    >
      <BodyText padding={{ right: 'sm' }}>{dueDate}</BodyText>

      {showApplyForExtension ? (
        <Analytics whenClicked={invoicing.applyPaymentPlan}>
          <TextLink
            data-testid="invoice-paymentplan-link"
            to={`/invoices/${invoiceKey}/paymentplan`}
            label={t('invoice.details.applyForExtension')}
            fontWeight="normal"
            enabledWhen={{
              onePermission: 'BILLING_INVOICES'
            }}
          />
        </Analytics>
      ) : (
        <Flex
          data-testid={
            paymentPlanActive ? 'invoice-paymentplan-exists' : 'invoice-paymentplan-cannot-apply'
          }
        >
          <SmartTooltip tooltipContent={tooltipContent} arrangement="top">
            <SmartTooltip.InfoIcon size="md" />
          </SmartTooltip>
        </Flex>
      )}
    </Flex>
  );
};

export default DueDate;
