import { Div, GridBag } from '@gaiads/telia-react-component-library';
import DetailsPage from 'common-components/DetailsPage/DetailsPage';
import { SubscriptionDetails } from 'hooks/mobileSubscriptions/useSubscriptionDetails/subscriptionDetailsQuery';
import { useTranslation } from 'react-i18next';

import TransferSubscriptionDetails from './TransferSubscriptionDetails';
import TransferSubscriptionDetailsSkeleton from './TransferSubscriptionDetailsSkeleton';
import TransferSubscriptionOptions from './TransferSubscriptionOptions';
import TransferSubscriptionOptionsSkeleton from './TransferSubscriptionOptionsSkeleton';

const TransferSubscriptionPage: React.FC<{
  subscription?: SubscriptionDetails;
  loading: boolean;
  hasError?: boolean;
  backButtonLabel?: string;
  backButtonPath?: string;
}> = ({ subscription, loading, hasError, backButtonLabel, backButtonPath }) => {
  const { t } = useTranslation();

  return (
    <DetailsPage
      hasError={!loading && (hasError || !subscription)}
      loading={loading}
      backButtonLabel={backButtonLabel}
      backButtonPath={backButtonPath}
      headline={t('subscriptions.actions.transfer.title')}
      data-testid="transfer-subscription-title"
    >
      <Div data-testid="transfer-subscription">
        <GridBag>
          <GridBag.Item md={12} lg={6}>
            {loading && <TransferSubscriptionOptionsSkeleton />}

            {!loading && subscription && (
              <TransferSubscriptionOptions subscription={subscription} />
            )}
          </GridBag.Item>

          <GridBag.Item md={12} lg={6}>
            {loading && <TransferSubscriptionDetailsSkeleton />}

            {!loading && subscription && (
              <TransferSubscriptionDetails subscription={subscription} />
            )}
          </GridBag.Item>
        </GridBag>
      </Div>
    </DetailsPage>
  );
};

export default TransferSubscriptionPage;
