import { FetchMoreResultsType, TicketsInResults } from 'types/ticket';

const getTicketsOnFetchMore = (
  previousResult: TicketsInResults,
  { fetchMoreResult }: FetchMoreResultsType
): TicketsInResults =>
  !fetchMoreResult
    ? previousResult
    : {
        tickets: {
          ...fetchMoreResult.tickets,
          items: [...(previousResult.tickets.items || []), ...(fetchMoreResult.tickets.items || [])]
        }
      };

export default getTicketsOnFetchMore;
