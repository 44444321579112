import { TeliaLink } from 'common-components';
import formatDatetime, { DateFormats } from 'doings/formatDatetime/formatDatetime';
import isDemoEnvironment from 'doings/isDemoEnvironment/isDemoEnvironment';
import { joinURLSearchParams } from 'doings/links/joinURLSearchParams/joinURLSearchParams';
import removeEmptyFields from 'doings/removeEmptyFields/removeEmptyFields';
import { getDefaultDateParams } from 'hooks/serviceRequests/useServiceRequests/getDefaultDateParams';
import { UseReadQueryParamsResult } from 'hooks/useQueryParams/useReadQueryParams';
import { useTranslation } from 'react-i18next';
import { EXCEL_MAX_ITEMS_VALUE } from 'types/excelDownload';
import { FilterBy } from 'types/serviceRequest';

export type ServiceRequestExcelDownloadLinkProps = {
  totalItems: number;
  disabled: boolean;
  queryParameters: UseReadQueryParamsResult;
  allCompanies: boolean;
};

export const ExcelDownloadLink: React.FC<ServiceRequestExcelDownloadLinkProps> = ({
  totalItems,
  disabled,
  queryParameters,
  allCompanies
}) => {
  const { t } = useTranslation();
  const isDemoEnv = isDemoEnvironment();

  const dateParams =
    !queryParameters.from && !queryParameters.to
      ? getDefaultDateParams(queryParameters.dateType as FilterBy)
      : {
          dateFrom: formatDatetime(queryParameters.from, DateFormats.DATE),
          dateTo: formatDatetime(queryParameters.to, DateFormats.DATE),
          filterBy: queryParameters.dateType
        };

  const urlSearchParams = new URLSearchParams(
    removeEmptyFields({
      status: queryParameters.status,
      scope: queryParameters.scope,
      term: queryParameters.search,
      ...dateParams
    })
  );

  const queryParams = joinURLSearchParams('?', {
    columns: [
      t('serviceRequests.list.columns.creationDate'),
      t('serviceRequests.list.columns.updatedDate'),
      t('serviceRequests.list.columns.name'),
      t('serviceRequests.list.columns.subscription'),
      t('serviceRequests.list.columns.status'),
      t('serviceRequests.list.columns.id'),
      t('common.companyCode.label'),
      t('common.company.label')
    ].join(','),
    filter: urlSearchParams.toString(),
    showFromAllCompanies: allCompanies,
    sheetTitle: t('serviceRequests.title')
  });

  return (
    <TeliaLink.Download
      label={t('common.excelDownload.label')}
      to={`/api/serviceRequests/listAsExcel${queryParams}`}
      iconLeft="download"
      data-testid="service-requests-list-as-excel-link"
      disabled={isDemoEnv || disabled || totalItems > EXCEL_MAX_ITEMS_VALUE}
      disabledTooltipKey={
        totalItems > EXCEL_MAX_ITEMS_VALUE ? 'common.excelDownload.tooManyItems' : undefined
      }
      downloadWithFetch
      useFileNameFromResponse
    />
  );
};
