import { Page } from '@gaiads/telia-react-component-library';
import { ExcelDownloadLink } from 'B2XApp/Invoicing/Invoices/InvoiceList/ExcelDownloadLink';
import { Memo, PageWithSubnavigation } from 'common-components';
import EmptyListContent from 'common-components/EmptyListContent/EmptyListContent';
import ListHeader from 'common-components/ListHeader/ListHeader';
import ShowMoreResults from 'common-components/ShowMoreResults/ShowMoreResults';
import { EditableListProvider } from 'contexts/EditableListContext/EditableListContext';
import { useFeatureFlagsData } from 'contexts/FeatureFlagContext/FeatureFlagContext';
import useInvoices from 'hooks/invoicing/invoices/useInvoices/useInvoices';
import useInvoicesFilter from 'hooks/invoicing/invoices/useInvoices/useInvoicesFilters/useInvoicesFilter';
import { ViewStatus } from 'hooks/useApiStatus/useApiStatus';
import useInitialLoadStatus from 'hooks/useInitialLoadStatus/useInitialLoadStatus';
import usePersistedSortableHeadings from 'hooks/useSortableHeadings/usePersistedSortableHeadings';
import useUserHasMultipleCompaniesWithGivenPermission from 'hooks/useUser/useUserHasMultipleCompaniesWithGivenPermission';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'types/sort';

import InvoiceListSortDropdown from './InvoiceListSortDropdown/InvoiceListSortDropdown';
import { InvoiceListInfoNotices } from './InvoiceNotices/InvoiceListInfoNotices';
import InvoicesListView, { InvoicesListViewColumnSetup } from './InvoicesListView';

const Invoices: React.FC = () => {
  const { t } = useTranslation();
  const { hasFeature } = useFeatureFlagsData();

  const isMultipleCompanySupportEnabled = hasFeature('EART-1404');
  const multipleCompanySearchEnabled = useUserHasMultipleCompaniesWithGivenPermission({
    anyPermission: ['BILLING_INVOICES', 'BILLING_INVOICES_VIEW']
  });
  const showCompanyInfo = isMultipleCompanySupportEnabled && multipleCompanySearchEnabled;

  const {
    searchFieldOptions,
    queryParameters,
    queryParametersAbsent,
    invoiceTypeOptions,
    invoiceStatusOptions,
    allCompanySearch
  } = useInvoicesFilter(multipleCompanySearchEnabled);

  const { sortState, sortClick, doSort, currentSort } = usePersistedSortableHeadings(
    ['invoiceDate', 'dueDate', 'id', 'status'],
    {
      sort: 'invoiceDate',
      order: SortOrder.DESC
    }
  );

  const {
    invoices: { total, items },
    apiState: apiStatus,
    error,
    fetchMore,
    hasMoreResults
  } = useInvoices(currentSort, queryParameters, allCompanySearch.showFromAllCompanies);

  const apiState = useInitialLoadStatus(apiStatus);

  return (
    <EditableListProvider
      columnSetup={InvoicesListViewColumnSetup}
      invocationArgs={[t, sortState, sortClick, showCompanyInfo]}
      changeableArgs={[undefined, sortState, undefined, showCompanyInfo]}
    >
      <PageWithSubnavigation
        loading={apiState === ViewStatus.LoadingInitial || apiState === ViewStatus.Initial}
        error={apiState === ViewStatus.Error ? error : undefined}
        emptyContentPlaceholder={
          queryParametersAbsent &&
          apiState === ViewStatus.Empty && (
            <EmptyListContent
              title={t('invoices.emptyList.title')}
              description={t('invoices.emptyList.description')}
            />
          )
        }
        showSubnavigationForCurrentPath
      >
        <ListHeader
          withMultiCompanyFeature={showCompanyInfo}
          multipleCompaniesCheckboxParams={{
            checked: allCompanySearch.showFromAllCompanies,
            label: t('invoices.searchAllLabel'),
            testId: 'invoices-search-all-companies',
            onChange: allCompanySearch.toggleAllCompanies
          }}
          searchScopeOptions={searchFieldOptions}
          dateRangeParams={{
            label: t('invoices.dateFiltersLabel'),
            helperText: t('invoices.dateFilterHelperText'),
            helperTooltip: t('invoices.dateFilterDefaultRangeTooltip'),
            withDefaultDatePlaceholder: true
          }}
          statusFilterParams={{
            label: t('invoices.invoiceStatusFilterLabel'),
            options: invoiceStatusOptions,
            'data-testid': 'status-filter-dropdown'
          }}
          typeFilterParams={{
            label: t('invoices.invoiceTypeFilterLabel'),
            options: invoiceTypeOptions,
            'data-testid': 'type-filter-dropdown'
          }}
          labels={{
            searchInMobileView: t('invoices.searchLabel'),
            sortInMobileView: t('invoices.sortLabel')
          }}
          sortDropdown={<InvoiceListSortDropdown currentSort={currentSort} doSort={doSort} />}
          infoNotice={<InvoiceListInfoNotices />}
          excelDownloadLink={
            <ExcelDownloadLink
              sortParameters={currentSort}
              queryParameters={queryParameters}
              showFromAllCompanies={allCompanySearch.showFromAllCompanies}
              disabled={total === 0 || apiState === ViewStatus.Loading}
            />
          }
        >
          {t('invoices.list.numberOfInvoices', {
            numberOfInvoices: total
          })}
        </ListHeader>

        <Page.Row responsiveFullWidth>
          <Memo comparables={['loading', 'invoices', 'sortState']}>
            <InvoicesListView loading={apiState === ViewStatus.Loading} invoices={items} />
          </Memo>
        </Page.Row>

        <Page.Row>
          {apiState !== ViewStatus.Loading && (
            <ShowMoreResults
              data-testid="show-more-invoices"
              loading={apiState === ViewStatus.LoadingMore}
              hasMoreResults={hasMoreResults}
              hasResults={!!items.length}
              fetchMore={fetchMore}
            />
          )}
        </Page.Row>
      </PageWithSubnavigation>
    </EditableListProvider>
  );
};

export default Invoices;
