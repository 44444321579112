import { Dropdown } from '@gaiads/telia-react-component-library';
import { useTranslation } from 'react-i18next';
import { SortAction, SortOrder, SortParameter } from 'types/sort';

type SortDropdownOptions = { label: string; value: string };

const BillingGroupsSortDropdown: React.FC<{
  currentSort: SortParameter;
  doSort: SortAction;
  'data-testid'?: string;
}> = ({ currentSort, doSort, 'data-testid': testId }) => {
  const { t } = useTranslation();

  const options = [
    {
      label: t('billingGroups.sort.collectionGroupAsc'),
      value: 'collectionGroup,asc'
    },
    {
      label: t('billingGroups.sort.collectionGroupDesc'),
      value: 'collectionGroup,desc'
    },
    {
      label: t('billingGroups.sort.nameAsc'),
      value: 'name,asc'
    },
    {
      label: t('billingGroups.sort.nameDesc'),
      value: 'name,desc'
    },
    {
      label: t('billingGroups.sort.referenceAsc'),
      value: 'reference,asc'
    },
    {
      label: t('billingGroups.sort.referenceDesc'),
      value: 'reference,desc'
    },
    {
      label: t('billingGroups.sort.billingAddressAsc'),
      value: 'billingAddress,asc'
    },
    {
      label: t('billingGroups.sort.billingAddressDesc'),
      value: 'billingAddress,desc'
    },
    {
      label: t('billingGroups.sort.billingTypeAsc'),
      value: 'type.displayedDeliveryMethod,asc'
    },
    {
      label: t('billingGroups.sort.billingTypeDesc'),
      value: 'type.displayedDeliveryMethod,desc'
    }
  ] as SortDropdownOptions[];

  return (
    <Dropdown
      data-testid={testId}
      label={t('billingGroups.sort.order')}
      aria-label={t('billingGroups.sort.order')}
      options={options}
      onChange={(_event, { value }) =>
        doSort(value.split(',')[0], value.split(',')[1] as SortOrder)
      }
      selectedOption={options.find(
        ({ value }) => value === `${currentSort.sort},${currentSort.order}`
      )}
    />
  );
};

export default BillingGroupsSortDropdown;
