import useBillingGroupForm, {
  UseBillingGroupFormResult
} from 'B2XApp/Invoicing/BillingGroups/BillingGroupForm/useBillingGroupForm';
import { useUserData } from 'contexts/UserContext/UserContext';
import useCreateCollectionGroup from 'hooks/invoicing/billingGroups/useCreateCollectionGroup/useCreateCollectionGroup';
import useDefaultCollectionGroup from 'hooks/invoicing/billingGroups/useDefaultCollectionGroup/useDefaultCollectionGroup';
import { useConfirmationModal } from 'hooks/useCustomModal/useConfirmationModal';
import useSearchParams from 'hooks/useSearchParams/useSearchParams';
import { AvailableBank, AvailableOperator } from 'types/collection-group';

import useNewBillingGroupSteps from './useNewBillingGroupSteps';

export type NewBillingGroupPrerequisites = {
  availableBanks: AvailableBank[];
  availableOperators: AvailableOperator[];
};

const useNewBillingGroupForm = ({
  availableBanks,
  availableOperators
}: NewBillingGroupPrerequisites): UseBillingGroupFormResult => {
  const { onClick } = useConfirmationModal();
  const { preserveBackPathState } = useSearchParams();
  const {
    newCollectionGroupCompanyId,
    newCollectionGroupId,
    createCollectionGroupApiStatus: createApiStatus,
    createCollectionGroup
  } = useCreateCollectionGroup();

  const finvoiceAvailable = false;
  const billingTypeChangeAvailable = true;
  const companySelectionAvailable = true;
  const { fields, isValid, marshallRequestData } = useBillingGroupForm({
    availableBanks,
    availableOperators,
    finvoiceAvailable,
    billingTypeChangeAvailable,
    companySelectionAvailable
  });

  const { allCompanies } = useUserData();
  const defaultCollectionGroupQueryResult = useDefaultCollectionGroup({
    companyId:
      allCompanies.find((c) => c.groupId === fields.relatedCompany.value)?.companyCode || ''
  });
  const { step, setStep, precheckNotice } = useNewBillingGroupSteps({
    defaultCollectionGroupQueryResult: defaultCollectionGroupQueryResult,
    createApiStatus
  });

  return {
    form: {
      step,
      setStep,
      fields,
      finvoiceAvailable,
      isValid,
      data: { newCollectionGroupCompanyId, newCollectionGroupId },
      precheckNotice: precheckNotice,
      onSave: () => {
        if (isValid) {
          createCollectionGroup({
            variables: {
              requestData: marshallRequestData()
            }
          });
        }
      },
      onCancel: () => {
        onClick(preserveBackPathState(`/billing-accounts`, true));
      }
    }
  };
};

export default useNewBillingGroupForm;
