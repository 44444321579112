export type SupportedSizes = 'sm' | 'md' | 'lg' | 'xl';

export const getPileVerticallyToSupportedSizes = (): SupportedSizes[] => ['sm', 'md', 'lg', 'xl'];

export type PileVerticallyToProps = {
  pileVerticallyTo?: SupportedSizes;
};

const getPileVerticallyToClasses = ({ pileVerticallyTo }: PileVerticallyToProps): string[] => {
  return pileVerticallyTo ? [`pileVerticallyTo_${pileVerticallyTo}`] : [];
};

export default getPileVerticallyToClasses;
