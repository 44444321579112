import { BodyText } from '@gaiads/telia-react-component-library';
import { TeliaLink } from 'common-components';
import { multiplex } from 'doings/multiplex/multiplex';
import { mobile } from 'doings/track/analyticsEvents';
import { SubscriptionReference } from 'hooks/mobileSubscriptions/useSubscriptionDetails/subscriptionDetailsQuery';
import { useTranslation } from 'react-i18next';

const SubscriptionReferenceLink: React.FC<{ subscriptionReference: SubscriptionReference }> = ({
  subscriptionReference: ref
}) => {
  const { t } = useTranslation();
  const analyticsEvent = multiplex([
    undefined,
    [ref.type === 'POOL_PARENT', mobile.viewSurfCardParentDetails],
    [ref.type === 'MULTISIM_PARENT', mobile.viewMultiSimParentDetails]
  ]);

  return (
    <>
      <BodyText block margin={{ bottom: 'xs' }} tagName="div">
        {t('subscriptions.subscriptionDetails.mainSubscription')}
      </BodyText>

      <TeliaLink.Internal
        variant="standalone"
        to={`/subscriptions/${ref.phoneNumberHash}`}
        label={`${ref.phoneNumber} ${ref.displayName || t('subscriptions.noUserName')}`}
        track={analyticsEvent}
        data-testid="surf-card-parent-link"
        data-dd-action-name="Show Surf card parent"
      />
    </>
  );
};

export default SubscriptionReferenceLink;
