import { DateFormats } from 'doings/formatDatetime/DateFormats';
import toDate from 'doings/formatDatetime/toDate';
import { mapToOptionList } from 'doings/mapToOptionList/mapToOptionList';
import { TicketsQueryParameters } from 'hooks/tickets/useTickets/useFetchTicketList';
import useReadQueryParams from 'hooks/useQueryParams/useReadQueryParams';
import { useToggleStateWithQueryParameter } from 'hooks/useToggleState/useToggleState';
import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownOptionDefinition, SearchOptionDefinition } from 'types/listFilters';
import { TicketStatus } from 'types/ticket';

export type TicketsFilters = ReturnType<typeof useTicketsFilters>;

export enum FilterDateType {
  BY_DATE_CREATED = 'created',
  BY_DATE_UPDATED = 'updated'
}

const ticketStatuses: TicketStatus[] = [
  'New',
  'Assigned',
  'In Progress',
  'On Hold',
  'Pending',
  'Waiting for information',
  'Resolved',
  'Completed',
  'Closed',
  'Cancelled'
];

const useSearchFieldOptions = (): SearchOptionDefinition[] => {
  const { t } = useTranslation();

  return [
    {
      label: t('tickets.list.searchScopes.subject'),
      value: 'subject'
    },
    {
      label: t('tickets.list.searchScopes.description'),
      value: 'description'
    },
    {
      label: t('tickets.list.searchScopes.caseNumber'),
      value: 'caseNumber'
    },
    {
      label: t('tickets.list.searchScopes.subscriptionId'),
      value: 'subscriptionId'
    }
  ];
};

const getDateTypeOptions = (t: TFunction): DropdownOptionDefinition[] => [
  {
    label: t('tickets.list.filters.modified'),
    value: FilterDateType.BY_DATE_UPDATED
  },
  {
    label: t('tickets.list.filters.creationDate'),
    value: FilterDateType.BY_DATE_CREATED
  }
];

const getStatusOptions = (t: TFunction): DropdownOptionDefinition[] =>
  mapToOptionList<TicketStatus>({
    New: t('tickets.status.new'),
    Assigned: t('tickets.status.assigned'),
    'In Progress': t('tickets.status.inProgress'),
    Pending: t('tickets.status.pending'),
    Resolved: t('tickets.status.resolved'),
    Completed: t('tickets.status.completed'),
    Closed: t('tickets.status.closed'),
    Cancelled: t('tickets.status.cancelled')
  });

const inputIsStatus = (input: string): input is TicketStatus =>
  ticketStatuses.includes(input as TicketStatus);

const inputAsStatus = (input: string): TicketStatus | undefined =>
  inputIsStatus(input) ? input : undefined;

export const useTicketsFilters = (initialAllCompanies = false) => {
  const { t } = useTranslation();

  const queryParameters = useReadQueryParams([
    'scope',
    'search',
    'status',
    'dateType',
    'from',
    'to',
    'allCompanies'
  ]);

  const { search, status, dateType, from, to, scope, allCompanies } = queryParameters;
  const queryParametersAbsent = !(
    !!search ||
    !!status ||
    !!dateType ||
    !!from ||
    !!to ||
    !!allCompanies
  );
  const searchFieldOptions = useSearchFieldOptions();
  const dateTypeOptions = useMemo(() => getDateTypeOptions(t), [t]);
  const statusOptions = useMemo(() => getStatusOptions(t), [t]);

  const [showFromAllCompanies, toggleAllCompanies] = useToggleStateWithQueryParameter(
    initialAllCompanies,
    'allCompanies'
  );

  const ticketsQueryParameters: TicketsQueryParameters = {
    subscriptionId: scope === 'subscriptionId' ? search : '',
    ticketId: scope === 'caseNumber' ? search : '',
    subject: scope === 'subject' ? search : '',
    description: scope === 'description' ? search : '',
    status: inputAsStatus(status || ''),
    dateParameters: {
      dateFrom: toDate(DateFormats.DATE_NATIONAL)(from),
      dateTo: toDate(DateFormats.DATE_NATIONAL)(to),
      filterBy: dateType as FilterDateType
    }
  };

  return {
    searchFieldOptions,
    dateTypeOptions,
    statusOptions,
    queryParameters: ticketsQueryParameters,
    queryParametersAbsent,
    allCompanySearch: {
      showFromAllCompanies,
      toggleAllCompanies
    }
  };
};
