import { Invoice } from 'types/invoice';

export type InvoicesInResultsType = {
  invoices: { items: Invoice[]; total: number };
};

type FetchMoreResultsType = {
  fetchMoreResult?: InvoicesInResultsType;
};

export default (previousResult: InvoicesInResultsType, { fetchMoreResult }: FetchMoreResultsType) =>
  !fetchMoreResult
    ? previousResult
    : {
        invoices: {
          ...fetchMoreResult.invoices,
          items: [...previousResult.invoices.items, ...fetchMoreResult.invoices.items]
        }
      };
