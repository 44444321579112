import { Flex, Gutter } from '@gaiads/telia-react-component-library';

import styles from './ActivityLogPage.module.scss';
import Bullet from './Bullet';

const ActivityLogItemContent: React.FC<{
  title?: boolean;
  showMore?: boolean;
  children: React.ReactNode;
}> = ({ title, showMore, children }) => {
  return (
    <Flex className={title ? styles.activityLogItem_title : styles.activityLogItem}>
      <Bullet large={title} showMore={showMore} />

      <Gutter size="md" />

      {children}
    </Flex>
  );
};

export default ActivityLogItemContent;
