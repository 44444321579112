import { subscriptionStatusMap } from 'B2XApp/Subscriptions/SubscriptionsList/SubscriptionStatusTag/SubscriptionStatusTag';
import { TeliaLink } from 'common-components';
import isDemoEnvironment from 'doings/isDemoEnvironment/isDemoEnvironment';
import { joinURLSearchParams } from 'doings/links/joinURLSearchParams/joinURLSearchParams';
import removeEmptyFields from 'doings/removeEmptyFields/removeEmptyFields';
import { UseReadQueryParamsResult } from 'hooks/useQueryParams/useReadQueryParams';
import { useTranslation } from 'react-i18next';
import { SortParameter } from 'types/sort';

export type SubscriptionExcelDownloadLinkProps = {
  queryParameters: UseReadQueryParamsResult;
  sortParameter: SortParameter;
  disabled: boolean;
};

export const ExcelDownloadLink: React.FC<SubscriptionExcelDownloadLinkProps> = ({
  queryParameters,
  sortParameter,
  disabled
}) => {
  const { t } = useTranslation();
  const isDemoEnv = isDemoEnvironment();
  const { sort, order } = sortParameter;
  const { search, type, scope } = queryParameters;
  const urlSearchParams = new URLSearchParams(
    removeEmptyFields({ [scope ?? '']: search, subscriptionTypeName: type })
  );

  const statuses = (Object.keys(subscriptionStatusMap) as (keyof typeof subscriptionStatusMap)[])
    .map((status) => `${status}:${t(subscriptionStatusMap[status].i18nKey)}`)
    .join(',');

  const columns = [
    t('subscriptions.user'),
    t('common.phone.label'),
    t('subscriptions.type'),
    t('subscriptions.packages.feature.label'),
    t('subscriptions.packages.voice.label'),
    t('subscriptions.packages.sms.label'),
    t('subscriptions.packages.data.label'),
    t('subscriptions.status')
  ];

  const queryParams = joinURLSearchParams('?', {
    columns: columns.join(','),
    filter: urlSearchParams.toString(),
    sheetTitle: t('subscriptions.title'),
    sort,
    order,
    subscriptionsNamePlaceholder: t('subscriptions.noUserName'),
    subscriptionStatuses: statuses
  });

  return (
    <TeliaLink.Download
      label={t('common.excelDownload.label')}
      to={`/api/subscriptions/listAsExcel${queryParams}`}
      iconLeft="download"
      data-testid="subscriptions-list-as-excel-link"
      disabled={isDemoEnv || disabled}
      downloadWithFetch
      useFileNameFromResponse
    />
  );
};
