import { Flex, Gutter, Panel } from '@gaiads/telia-react-component-library';
import NoPukCodesMessage from 'B2XApp/Subscriptions/SubscriptionDetails/NoPukCodesMessage/NoPukCodesMessage';
import PukCode from 'B2XApp/Subscriptions/SubscriptionDetails/PukCode/PukCode';
import { DefinitionList, Skeleton } from 'common-components';
import DetailsLoadingErrorPanel from 'common-components/DataPanel/DetailsLoadingErrorPanel/DetailsLoadingErrorPanel';
import createCorporatePortalLink from 'doings/links/createCorporatePortalLink/createCorporatePortalLink';
import { mobile } from 'doings/track/analyticsEvents';
import {
  SimCard,
  SubscriptionStatus
} from 'hooks/mobileSubscriptions/useSubscriptionDetails/subscriptionDetailsQuery';
import { useTranslation } from 'react-i18next';

import SubscriptionAccordion from '../SubscriptionAccordion/SubscriptionAccordion';

const SIM_CARD_NUMBER_SLICE_INDEX = 7;

const simplifySIMCardNumber = (icc: string | null) => icc?.slice(SIM_CARD_NUMBER_SLICE_INDEX) ?? '';

const SIMCard: React.FC<{
  phoneNumberHash?: string;
  simCard?: SimCard | null;
  status?: SubscriptionStatus;
  loading: boolean;
  expanded: boolean;
  disabled?: boolean;
}> = ({ phoneNumberHash, simCard, status, loading, expanded, disabled = false }) => {
  const { t, i18n } = useTranslation();

  if (loading) {
    return <Skeleton.Accordion />;
  }

  if (!phoneNumberHash || !simCard || !status) {
    return (
      <Panel>
        <Panel.Content>
          <DetailsLoadingErrorPanel />
        </Panel.Content>
      </Panel>
    );
  }

  const simCardTypeKey = `subscriptions.simCardTypes.${simCard?.type}`;
  const simCardSizeKey = `subscriptions.simCardSizes.${simCard?.size}`;
  const corporatePortalUrl = createCorporatePortalLink(
    'mobileChangeSIMCard',
    { msisdn: phoneNumberHash },
    { section: 'simCard' }
  );

  return (
    <SubscriptionAccordion
      heading={{
        label: t('subscriptions.actions.SIMCard.heading'),
        testId: 'sim-card-accordion',
        trackOnExpand: mobile.expandSIMCard
      }}
      actionButton={{
        visible: !disabled,
        label: t('subscriptions.actions.SIMCard.change'),
        corporatePortalUrl,
        testId: 'action-sim-change',
        trackOnClick: mobile.orderSIM
      }}
      phoneNumberHash={phoneNumberHash}
      status={status}
      loading={loading}
      initiallyExpanded={expanded}
    >
      <DefinitionList addDividers fiftyFifty trailingDivider>
        <DefinitionList.MultilineItem
          heading={t('subscriptions.actions.SIMCard.number')}
          values={[simplifySIMCardNumber(simCard?.icc)]}
          data-testid="sim-card-short-icc-number"
        />

        <DefinitionList.MultilineItem
          heading={t('subscriptions.actions.SIMCard.type')}
          values={[simCard?.type && i18n.exists(simCardTypeKey) ? t(simCardTypeKey) : '']}
          data-testid="sim-card-type"
        />

        <DefinitionList.MultilineItem
          heading={t('subscriptions.actions.SIMCard.size')}
          values={[simCard?.size && i18n.exists(simCardSizeKey) ? t(simCardSizeKey) : '']}
          data-testid="sim-card-size"
        />
      </DefinitionList>

      {simCard?.pukCodes?.puk1 && simCard?.pukCodes?.puk2 && (
        <Flex pileVerticallyTo="md" margin={{ top: 'xs' }}>
          <PukCode
            title={t('subscriptions.subscriptionDetails.puk1')}
            code={simCard.pukCodes.puk1}
            data-testid="sim-card-puk-1"
          />

          <Gutter size="xs" />

          <PukCode
            title={t('subscriptions.subscriptionDetails.puk2')}
            code={simCard.pukCodes.puk2}
            data-testid="sim-card-puk-2"
            tooltipKey="subscriptions.subscriptionDetails.puk2Tooltip"
          />
        </Flex>
      )}

      {!(simCard?.pukCodes?.puk1 && simCard?.pukCodes?.puk2) && <NoPukCodesMessage />}
    </SubscriptionAccordion>
  );
};

export default SIMCard;
