import getEnvironmentVariable from 'doings/getEnvironmentVariable/getEnvironmentVariable';
import { joinURLSearchParams } from 'doings/links/joinURLSearchParams/joinURLSearchParams';
import { urlWithBackToService } from 'doings/links/urlBackToService/urlBackToService';
import urlJoin from 'url-join';

export default (path: string, args?: Record<string, string>) => {
  const queryDelimiter = window.location.href.includes('?') ? '&' : '?';
  const queryParams = joinURLSearchParams(queryDelimiter, args);

  return urlWithBackToService(
    urlJoin(getEnvironmentVariable('REACT_APP_ULM_URL'), path),
    window.location.href,
    queryParams
  );
};
