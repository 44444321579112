import { useState } from 'react';

import {
  DynamicFormObservers,
  DynamicFormOnLoadCallback,
  DynamicFormOnValidateCallback,
  DynamicFormState
} from './DynamicForm/DynamicForm';
import DynamicFormContainer from './DynamicForm/DynamicFormContainer';
import DynamicFormSelectorContainer from './DynamicFormSelector/DynamicFormSelectorContainer';

/*
 * Export underlying types so all types could be imported from one place and
 * code wouldn't include types directly from components which are details
 * specific to the dynamic form UI implementation.
 */
export type {
  DynamicFormObservers,
  DynamicFormOnLoadCallback,
  DynamicFormOnPresubmitObserver,
  DynamicFormOnSubmitObserver,
  DynamicFormOnValidateCallback,
  DynamicFormSubmitResponse,
  DynamicFormState
} from './DynamicForm/DynamicForm';

/*
 * Export exposed constants so all constants could be imported from one place.
 */
export { DYNFORM_SELECTOR_CONTAINER_NAME } from './DynamicFormSelector/DynamicFormSelector';

/**
 * Renders a self-contained dynamic form queried under the specified group. If
 * the group contains multiple forms, selectors are rendered for the user to
 * make a choice from; otherwise, the selectors are omitted and the sole dynamic
 * form is rendered automatically.
 *
 * Note that:
 * * The group must exist in the dynamic form service.
 * * The user must have sufficient permissions to access dynamic forms.
 *
 * Additionally, supports the following single-letter query parameters for
 * preselecting a form via URL (mnemonics have been provided in [brackets] for
 * development and usage purposes):
 * * `t[ype]` — The ID of the form to preselect when the group contains multiple
 *   forms. Case-sensitive. End with an exclamation mark to disable selecting
 *   other otherwise available forms. If no such form is found, skips
 *   preselection.
 *   Example value: `t=other_services!`
 * * `d[eeplink]` — The ID of a deep link for the form which populates fields
 *   based on the dynamic form's underlying metainfo. Case-sensitive.
 *   Example value: `d=DataNet`
 *
 * Related reserved query parameters include:
 * * `p[refill]` — The ID of a subject prefill setting for new service requests.
 *   Example value: `p=other_products`
 */
const DynamicFormMain: React.FC<{
  /** The reference name of the underlying dynamic form group. */
  groupName: string;
  /** A callback function called when the dynamic form is loaded. */
  onLoad: DynamicFormOnLoadCallback;
  /** A callback function called when the dynamic form validates. */
  onValidate: DynamicFormOnValidateCallback;
  /** Observers for validating and extracting the form's user input. */
  register?: DynamicFormObservers;
  /** State for rendering an initial form from stored data. */
  formStateRef: React.MutableRefObject<DynamicFormState | undefined>;
  /** A map of named fields to values that the end-user should not be able to change (of type INPUT or TEXTAREA). */
  fixedValues?: Record<string, string | undefined>;
}> = ({ groupName, onLoad, onValidate, register, formStateRef, fixedValues }) => {
  const [selectedFormName, setSelectedFormName] = useState<string | undefined>(
    formStateRef.current?.form.name
  );

  const onSelectedHandler = (formName: string) => {
    setSelectedFormName(formName);
    onValidate(false);
    formStateRef.current = undefined;
  };

  return (
    <>
      <DynamicFormSelectorContainer
        groupName={groupName}
        onSelected={(formName) => onSelectedHandler(formName)}
        initialSelectedValue={formStateRef.current?.form.name}
      />

      {!!selectedFormName && (
        <DynamicFormContainer
          groupName={groupName}
          formName={selectedFormName}
          onLoad={onLoad}
          onValidate={onValidate}
          register={register}
          formState={formStateRef.current}
          fixedValues={fixedValues}
          data-testid="dynamic-form-container"
        />
      )}
    </>
  );
};

export default DynamicFormMain;
