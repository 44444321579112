import { SectionDefinition } from 'B2XApp/Dashboard/DashboardNavigation/DashboardSections/sections/sectionDefinition';
import { DashboardSectionId } from 'B2XApp/Dashboard/sections';
import { LinkTarget, NavigationalLinks } from 'doings/getNavigationalLinks/getNavigationalLinks';

export const businessServicesSection: SectionDefinition = {
  id: DashboardSectionId.BusinessServices,
  icon: 'connected-building',
  titleTranslationKey: 'dashboard.sections.titles.BusinessServices',
  getLinks(allLinks: NavigationalLinks) {
    return [
      allLinks[LinkTarget.PRODUCTS__MOBILE_SUBSCRIPTIONS],
      allLinks[LinkTarget.PRODUCTS__MOBILE_SUBSCRIPTIONS__CREATE_EMPLOYEE],
      allLinks[LinkTarget.PRODUCTS__MOBILE_SUBSCRIPTIONS__OWNERSHIP_CHANGE],
      allLinks[LinkTarget.PRODUCTS__DEVICES],
      allLinks[LinkTarget.PRODUCTS__INTERNET_SUBSCRIPTIONS],
      allLinks[LinkTarget.B2B_PORTAL],
      allLinks[LinkTarget.OFFERINGS__BUSINESS_OFFERING]
    ];
  }
};
