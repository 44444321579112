import FeatureFlagForm from 'B2XApp/FeatureFlags/FeatureFlagForm';
import { PageWithFormLayout } from 'common-components';
import useFeatureFlags from 'hooks/featureFlags/useFeatureFlags/useFeatureFlags';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

const EditFeatureFlag: React.FC = () => {
  const { t } = useTranslation();
  const { flagName } = useParams<{ flagName: string }>();
  const { featureFlags, persistable } = useFeatureFlags();

  const flag = featureFlags.find(({ name }) => name === flagName);

  return (
    <PageWithFormLayout title={t('featureFlags.form.titleEdit')}>
      <FeatureFlagForm flag={flag} persistable={persistable} />
    </PageWithFormLayout>
  );
};

export default EditFeatureFlag;
