import { DefinitionList } from 'common-components';
import { EN_DASH } from 'doings/dash/dash';
import usePileVerticallyToMany from 'hooks/usePileVerticallyToMany/usePileVerticallyToMany';
import { useTranslation } from 'react-i18next';
import { BillMillBillingAccount } from 'types/billMillAccount';

const BillMillAccountPayerInformation: React.FC<{
  account: BillMillBillingAccount;
}> = ({ account: { customerCode, payer } }) => {
  const { t } = useTranslation();
  const pileVerticallyTo = usePileVerticallyToMany(['xxxs', 'xxs', 'md', 'lg']);

  return (
    <DefinitionList
      pileVerticallyTo={pileVerticallyTo}
      addDividers
      fiftyFifty
      data-testid="billmill-account-payer-information"
    >
      {[
        {
          heading: 'billingInformation.company',
          values: [customerCode]
        },
        {
          heading: 'billingInformation.payerName',
          values: [payer.name]
        },
        {
          heading: 'billingInformation.nameSpecifier',
          values: [payer.nameSupplement]
        },
        {
          heading: 'billingInformation.billingAddress',
          values: [
            payer.address.address,
            [payer.address.postalCode, payer.address.postalOffice].join(' ')
          ]
        },
        {
          heading: 'billingInformation.addressSupplement',
          values: [payer.address.addressSupplement || EN_DASH]
        }
      ].map(({ heading, values }) => (
        <DefinitionList.MultilineItem key={heading} heading={t(heading)} values={values} />
      ))}
    </DefinitionList>
  );
};

export default BillMillAccountPayerInformation;
