import { subMonths } from 'date-fns';
import formatDatetime, { DateFormats } from 'doings/formatDatetime/formatDatetime';
import { FilterBy } from 'types/serviceRequest';

export const DEFAULT_MONTHS_BEFORE = 6;

export const getDefaultDateParams = (filterBy: FilterBy = 'updated') => ({
  dateFrom: formatDatetime(subMonths(new Date(), DEFAULT_MONTHS_BEFORE), DateFormats.DATE),
  dateTo: formatDatetime(new Date(), DateFormats.DATE),
  filterBy
});
