import { Args } from 'doings/links/createCorporatePortalLink/createCorporatePortalLink';

export const joinURLSearchParams = <T extends Args>(
  /** Delimiter to precede the resulting query string with if not empty. */
  queryDelimiter: '' | '?' | '&',
  /** URL search parameters to join. */
  args: T | undefined,
  /** URL search parameters to leave unecoded if already encoded, avoiding double encoding. */
  unencoded: (keyof T)[] = []
) => {
  if (!args) {
    return '';
  }

  const filteredArgs = collect(args, ([_key, value]) => typeof value !== 'undefined');
  if (Object.entries(filteredArgs).length <= 0) {
    return '';
  }

  const unencodedArgs = collect(filteredArgs, ([key]) => unencoded.includes(key));
  const unencodedQueryString = joinUnecodedQueryParams(unencodedArgs);

  const encodedArgs = collect(filteredArgs, ([key]) => !(key in unencodedArgs));
  const encodedQueryString = joinEncodedQueryParams(encodedArgs);

  const midDelimiter = unencodedQueryString && encodedQueryString ? '&' : '';
  return `${queryDelimiter}${unencodedQueryString}${midDelimiter}${encodedQueryString}`;
};

const collect = (
  args: Args,
  predicate: (entry: [string, string | boolean | undefined]) => boolean
): Record<string, string> => {
  return Object.entries(args)
    .filter((entry) => predicate(entry))
    .reduce((agg, [key, value]) => {
      agg[key] = String(value);
      return agg;
    }, {} as Record<string, string>);
};

const joinUnecodedQueryParams = (args: Record<string, string>) =>
  Object.entries(args)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

const joinEncodedQueryParams = (args: Record<string, string>) =>
  new URLSearchParams(args).toString();
