import { BodyText, Flex, Icon } from '@gaiads/telia-react-component-library';
import getClassNames from 'classnames';

import styles from './BreadcrumbItem.module.scss';

const BreadcrumbItem: React.FC<{
  label: string;
  home?: boolean;
  showArrow?: boolean;
}> = ({ label, home = false, showArrow = false }) => {
  if (showArrow) {
    return (
      <Flex
        data-testid="breadcrumb-item-navigable"
        className={getClassNames(styles.breadcrumbItem, styles.breadcrumbItem_navigable)}
        centeredVertically
      >
        {home && <Icon name="home" size="sm" data-testid="home-icon" />}

        <BodyText size="sm" tagName="span">
          {label}
        </BodyText>

        <Icon name="chevron-right" size="xs" data-testid="arrow-icon" />
      </Flex>
    );
  }

  return (
    <Flex className={styles.breadcrumbItem}>
      <BodyText size="sm" fontWeight="medium" tagName="span">
        {label}
      </BodyText>
    </Flex>
  );
};

export default BreadcrumbItem;
