import { useHistory } from 'react-router-dom';
import urlJoin from 'url-join';

const useUrlWithBasename = (pathname: string) => {
  const history = useHistory();

  const {
    location: { origin }
  } = window;

  const pathnameWithBasename = history.createHref({
    pathname
  });

  return urlJoin(origin, pathnameWithBasename);
};

export default useUrlWithBasename;
