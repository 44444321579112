import { InlineNotification, Notices } from 'common-components';
import { ServiceRequestStatus, ServiceRequestStatusV1 } from 'types/serviceRequest';

const ServiceRequestNotices: React.FC<{
  status?: ServiceRequestStatus | ServiceRequestStatusV1;
}> = ({ status }) => {
  return (
    <Notices data-testid="service-request-notices">
      {['Waiting for information', 'Pending customer'].includes(status || '') && (
        <InlineNotification
          content={{ key: 'serviceRequests.details.notices.pending' }}
          variant="information"
          data-testid="service-request-notice-pending"
        />
      )}

      {status === 'Resolved' && (
        <InlineNotification
          content={{ key: 'serviceRequests.details.notices.resolved' }}
          variant="information"
          data-testid="service-request-notice-resolved"
        />
      )}
    </Notices>
  );
};

export default ServiceRequestNotices;
