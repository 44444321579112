import { Panel } from '@gaiads/telia-react-component-library';
import { ListView, TextWithInlineHtml } from 'common-components';
import join from 'doings/join/join';
import { AnalyticsEvent } from 'doings/track/analyticsEvents';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnVisibility } from 'types/listConfig';

import { DefinedEvent, ListEvent } from './AnalyticsEvents';
import styles from './AnalyticsEventsListView.module.scss';

const AnalyticsEventsListView: React.FC<{
  events: ListEvent[];
  onToggleExpandedEvent: (eventKey: DefinedEvent) => void;
  expandedEvent: DefinedEvent | '';
}> = ({ events, onToggleExpandedEvent, expandedEvent }) => {
  return (
    <ListView
      listData={events}
      data-testid="analytics-events-list"
      hasExpandableItems
      columns={{
        event: {
          baseVisibility: ColumnVisibility.REQUIRE,
          heading: ''
        }
      }}
      mobileLayout={{
        titleRow: { col: 'event' },
        rows: []
      }}
    >
      {({ key, label, events }) => (
        <ListView.Row
          usesExpandableArrow
          onClick={() => onToggleExpandedEvent(key)}
          data-testid={`analytics-events--${key}`}
          data-expanded={expandedEvent === key}
          sublistProps={{
            component: <AnalyticsEventsListViewSublist eventKey={key} events={events} />,
            visible: expandedEvent === key
          }}
        >
          <ListView.RowShape
            cells={{
              event: {
                data: label
              }
            }}
          />
        </ListView.Row>
      )}
    </ListView>
  );
};

const AnalyticsEventsListViewSublist: React.FC<{
  eventKey: DefinedEvent;
  events: AnalyticsEvent[];
}> = ({ eventKey, events }) => {
  const { t } = useTranslation();

  return (
    <Panel
      tagName={'li' as 'div'}
      margin={{ bottom: 'xs' }}
      padding={{ vertical: 'xs' }}
      backgroundColor="gray200"
    >
      <ListView.Row hideArrow listType="sublist" useChildPadding>
        <ListView.Cell isHeading>{t('analyticsEvents.list.columns.eventAction')}</ListView.Cell>
      </ListView.Row>

      <ListView.Divider />

      {join(
        events.map(({ eventAction }) => (
          <ListView.Row
            listType="sublist"
            key={`analytics-event-definitions--${eventKey}`}
            data-testid={`analytics-event-definitions--${eventKey}`}
            useChildPadding
          >
            <ListView.Cell cellSize="1x" wordWrap="break-word">
              <TextWithInlineHtml
                className={styles.sublistCell}
                text={eventAction.split(':').join('<strong>:</strong>')}
              />
            </ListView.Cell>
          </ListView.Row>
        )),
        <ListView.Divider />
      )}
    </Panel>
  );
};

export default AnalyticsEventsListView;
