import ErrorPage from 'B2XApp/Errors/Error/ErrorPage/ErrorPage';
import { FullPageLoader } from 'common-components';
import { useReplayHttpRequest } from 'hooks/replay/useReplayHttpRequest';

const Replay: React.FC = () => {
  const { status, error } = useReplayHttpRequest();
  if (status === 'error') {
    return (
      <ErrorPage
        data-testid="error"
        defaultType={error === 'outdated' ? 'replayOutdated' : 'replayFault'}
        hideBreadcrumb
      />
    );
  }

  return <FullPageLoader loading />;
};

export default Replay;
