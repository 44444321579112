import { gql } from '@apollo/client';
import { useUserData } from 'contexts/UserContext/UserContext';
import useCallBackendMutation from 'hooks/useCallBackend/useCallBackendMutation';

import { productNewsQuery } from './useProductNews';

const mutation = gql`
  mutation MarkProductNewsAsSeen($input: MarkProductNewsAsSeenInput!) {
    markProductNewsAsSeen(input: $input)
  }
`;

const useMarkProductNewsAsSeen = () => {
  const user = useUserData();
  const { runMutation, error, loading } = useCallBackendMutation(mutation, [
    { query: productNewsQuery, variables: { lang: user.language } }
  ]);

  const markProductNewsAsSeen = async (ids: string[]) => {
    if (ids?.length) {
      await runMutation({ variables: { input: { ids } } });
    }
  };

  return { markProductNewsAsSeen, loading, error };
};

export { useMarkProductNewsAsSeen };
