import styles from 'B2XApp/Dashboard/Dashboard.module.scss';
import {
  EditableLinksModal,
  OTHER_SERVICES_MODAL
} from 'B2XApp/Modals/EditableLinksModal/EditableLinksModal';
import { Heading, IconButton } from 'common-components';
import { useUserData } from 'contexts/UserContext/UserContext';
import { Div, Flex } from 'core-components';
import { isWholesaleBusiness } from 'doings/isWholesaleBusiness/isWholesaleBusiness';
import { dashboardLinks } from 'doings/track/analyticsEvents';
import trackEvent from 'doings/track/trackEvent';
import useEditableSectionItems, {
  SectionItem
} from 'hooks/editableSections/useEditableSectionItems';
import useCustomModal from 'hooks/useCustomModal/useCustomModal';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DashboardOtherServicesLinks from './DashboardOtherServicesLinks';
import DashboardOtherServicesNoLinks from './DashboardOtherServicesNoLinks';
import useOtherServices, { OtherServiceLink } from './useOtherServices';

const DashboardOtherServices: React.FC = () => {
  const { t } = useTranslation();
  const { items, loading } = useEditableSectionItems('other-services');
  const user = useUserData();
  const otherServiceLinks = useOtherServices({ t, user });
  const [showAll, setShowAll] = React.useState<boolean>(false);

  const isB2BUser = !isWholesaleBusiness(user);
  const { sortedOtherServiceLinks, isEnabled, isNew, hasVisibleLinks } = useMemo(
    () => determineDisplayedOtherServices({ otherServiceLinks, items, isB2BUser }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items, isB2BUser]
  );

  const otherServicesModal = useCustomModal(OTHER_SERVICES_MODAL);
  const openModal = () => {
    trackEvent(dashboardLinks.customizeOtherServices);
    otherServicesModal.openModal();
  };

  return (
    <div data-tour-stop="dashboard-other-services">
      <Div margin={{ bottom: 'md' }}>
        <Heading.H2>
          <Flex centeredVertically>
            {t('dashboard.other.title')}

            {isB2BUser && (
              <IconButton
                data-testid="edit-other-services"
                data-tour-stop="dashboard-other-services-edit-button"
                aria-label={t('aria.dashboard.showOtherServicesModal')}
                className={[styles.settingsIcon, styles.settingsIcon_otherServices]}
                name="settings"
                size="md"
                onClick={openModal}
              />
            )}
          </Flex>
        </Heading.H2>
      </Div>

      {hasVisibleLinks ? (
        <DashboardOtherServicesLinks
          links={sortedOtherServiceLinks}
          displayedWhen={(link) => isEnabled(link.id)}
          showAll={showAll}
          setShowAll={setShowAll}
          loading={loading}
        />
      ) : (
        <DashboardOtherServicesNoLinks openModal={openModal} />
      )}

      {isB2BUser && (
        <EditableLinksModal
          section="other-services"
          onCloseClick={() => {
            if (showAll) {
              setShowAll(false);
            }
            otherServicesModal.closeModal();
          }}
          isOpen={otherServicesModal.isOpen}
          items={sortedOtherServiceLinks.map((link) => ({
            id: link.id,
            enabled: isEnabled(link.id),
            label: link.label,
            isNew: isNew(link.id)
          }))}
        />
      )}
    </div>
  );
};

const determineDisplayedOtherServices = ({
  otherServiceLinks,
  items,
  isB2BUser
}: {
  otherServiceLinks: OtherServiceLink[];
  items: SectionItem[];
  isB2BUser: boolean;
}) => {
  const defaultOtherServiceLinks = otherServiceLinks.toSorted((a, b) =>
    a.label.localeCompare(b.label)
  );

  const hasCustomLinks = isB2BUser && !!items.length;
  if (!hasCustomLinks) {
    return {
      sortedOtherServiceLinks: defaultOtherServiceLinks,
      isEnabled: () => true,
      isNew: () => false,
      hasVisibleLinks: true
    };
  }

  const { enabledLinks, itemOrder } = items.reduce(
    (acc, item, index) => {
      if (item.enabled) {
        acc.enabledLinks.add(item.id);
      }
      acc.itemOrder.set(item.id, index);
      return acc;
    },
    { enabledLinks: new Set<string>(), itemOrder: new Map<string, number>() }
  );

  const sortedOtherServiceLinks = defaultOtherServiceLinks.toSorted((a, b) => {
    const itemOrderA = itemOrder.get(a.id) ?? defaultOtherServiceLinks.length;
    const itemOrderB = itemOrder.get(b.id) ?? defaultOtherServiceLinks.length;
    const result = itemOrderA - itemOrderB;
    return result !== 0 ? result : a.label.localeCompare(b.label);
  });

  const isEnabled = (id: string) => enabledLinks.has(id);
  const isNew = (id: string) => !itemOrder.has(id);
  const hasVisibleLinks = !!sortedOtherServiceLinks.filter(({ id }) => isEnabled(id)).length;

  return {
    sortedOtherServiceLinks,
    isEnabled,
    isNew,
    hasVisibleLinks
  };
};

export default DashboardOtherServices;
