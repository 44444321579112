import { StaticFormPrefillType } from 'B2XApp/DynamicForms/useStaticSubjectPrefill';
import { ModalDialog, TileButton, TileButtonGrid, TileButtonProps } from 'common-components';
import { useFeatureFlagsData } from 'contexts/FeatureFlagContext/FeatureFlagContext';
import createCorporatePortalLink from 'doings/links/createCorporatePortalLink/createCorporatePortalLink';
import { invoicing } from 'doings/track/analyticsEvents';
import useSearchParams from 'hooks/useSearchParams/useSearchParams';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { showBillingAccountTypeModal } from 'redux/slices/billingAccountTypeSlice';
import { RootState } from 'redux/store';

export const BillingAccountTypeModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isModalOpen = useSelector((state: RootState) => state.billingAccountTypeModal.isModalOpen);
  const { hasFeature } = useFeatureFlagsData();
  const { createPathWithState } = useSearchParams();

  const closeModal = () => dispatch(showBillingAccountTypeModal(false));

  const links: TileButtonProps[] = [
    {
      iconName: 'simcard',
      to: hasFeature('DEO-2424')
        ? createPathWithState('/billing-accounts/new')
        : createCorporatePortalLink('billing/accounts/collectionGroup/new', { action: 'new' }),
      label: t('modal.billingAccountType.links.mobileSubscriptions.title'),
      whenClicked: invoicing.openCreateCollectionGroup,
      'data-testid': 'billing-account-type-modal-mobile-subscriptions',
      onClick: closeModal
    },
    {
      iconName: 'summary',
      label: t('modal.billingAccountType.links.otherProductsAndServices.title'),
      tooltip: t('modal.billingAccountType.links.otherProductsAndServices.description'),
      to: `/service-requests/new?p=${StaticFormPrefillType.OtherProducts}`,
      whenClicked: invoicing.openCreateOtherBillingAccount,
      'data-testid': 'billing-account-type-modal-other-products-and-services',
      onClick: closeModal
    }
  ];

  return (
    <ModalDialog
      isOpen={isModalOpen}
      title={t('modal.billingAccountType.title')}
      onClose={closeModal}
      data-testid="billingAccountType-modal"
    >
      <TileButtonGrid columns={{ desktop: 2, mobile: 1 }}>
        {links.map((link) => (
          <TileButton key={link['data-testid']} {...link} />
        ))}
      </TileButtonGrid>
    </ModalDialog>
  );
};
