import { ApolloError } from '@apollo/client';
import { multiplex } from 'doings/multiplex/multiplex';
import { CreateServiceRequestResult } from 'hooks/serviceRequests/useCreateServiceRequest/useCreateServiceRequest';
import { CreateTicketResponse } from 'hooks/tickets/useCreateTicket/useCreateTicket';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

type CreateMessageStep = 'loading' | 'initial' | 'confirmed' | 'error';

export type SetStep = Dispatch<SetStateAction<CreateMessageStep>>;

export type CreateMessageSavingStatus = 'no' | 'yes' | 'failure';

const useCreateTicketSteps = (
  loading: boolean,
  data?: CreateTicketResponse | CreateServiceRequestResult,
  error?: ApolloError
) => {
  const [step, setStep] = useState<CreateMessageStep>('initial');
  const targetStep: CreateMessageStep = multiplex([
    'initial',
    [!!loading, 'loading'],
    [!!error, 'error'],
    [!!data, 'confirmed']
  ]);

  useEffect(() => {
    setStep(targetStep);
  }, [targetStep]);

  const savingStatus: CreateMessageSavingStatus = multiplex([
    'no',
    [step === 'loading', 'yes'],
    [step === 'error', 'failure']
  ]);

  return { step, setStep, savingStatus };
};

export default useCreateTicketSteps;
