import { gql } from '@apollo/client';
import { notificationsQuery } from 'hooks/notifications/useNotifications/useNotifications';
import useCallBackendMutation from 'hooks/useCallBackend/useCallBackendMutation';

type CloseNotificationResponse = {
  closeNotification: boolean;
};

export const closeNotificationMutation = gql`
  mutation CloseNotification($hash: String!) {
    closeNotification(hash: $hash)
  }
`;

export const useCloseNotification = () => {
  const { runMutation, data, loading, error } = useCallBackendMutation<CloseNotificationResponse>(
    closeNotificationMutation,
    [
      {
        query: notificationsQuery
      }
    ]
  );

  const closeNotification = (hash: string) => {
    runMutation({
      variables: {
        hash
      }
    });
  };

  return {
    closeNotification,
    data,
    loading,
    error
  };
};
