import { Gutter } from '@gaiads/telia-react-component-library';
import { setRequiredFields } from 'B2XApp/Invoicing/BillingGroups/BillingGroupForm/BillingGroupForm';
import BillingGroupFormButtons from 'B2XApp/Invoicing/BillingGroups/Common/BillingGroupFormButtons';
import BillingGroupFormFinvoiceBlock from 'B2XApp/Invoicing/BillingGroups/Common/BillingGroupFormFinvoiceBlock/BillingGroupFormFinvoiceBlock';
import {
  DataPanel,
  DetailsLoadingOverlay,
  Forms,
  Heading,
  InlineNotification
} from 'common-components';
import ModelTextInput from 'common-components/ModelTextInput/ModelTextInput';
import { AnalyticsEvent } from 'doings/track/analyticsEvents';
import useIsSubscriptionInCompany from 'hooks/mobileSubscriptions/useIsSubscriptionInCompany/useIsSubscriptionInCompany';
import { useConfirmationModal } from 'hooks/useCustomModal/useConfirmationModal';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BillMillBillingAccount } from 'types/billMillAccount';

import BillingGroupFormContactInfo from '../Common/BillingGroupFormContactInfo';
import BillMillAccountFormBillingInfo from './BillMillAccountFormBillingInfo';
import BillMillAccountFormPayerInfo from './BillMillAccountFormPayerInfo';
import { BillMillAccountFormData } from './useBillMillAccountForm';

const BillMillAccountForm: React.FC<{
  form: BillMillAccountFormData;
  noticeOnFailureTitle: string;
  noticeOnFailureDescription: string;
  analyticsEvent: AnalyticsEvent;
  billMillAccount?: BillMillBillingAccount;
  'data-testid'?: string;
}> = ({
  form,
  noticeOnFailureTitle,
  noticeOnFailureDescription,
  analyticsEvent,
  billMillAccount,
  'data-testid': testId
}) => {
  const { t } = useTranslation();
  const disallowMutation = form.step === 'persisting';
  const { setConfirmationModal, isChanged } = useConfirmationModal();
  const canSave = form.isValid && isChanged;
  const canCancel = form.step !== 'persisting';
  const billingType = form.fields.billingType.value;
  const selectedOperator = form.fields.elmaOperator.value.toUpperCase();

  const smsInvoicePhonenumberField = form.fields.smsInvoicePhoneNumber;
  const { subscriptionInCompany, loading: validatingSmsInvoiceMsisdn } = useIsSubscriptionInCompany(
    {
      subscriptionId: smsInvoicePhonenumberField.value,
      companyCode: form.fields.companyCode.value,
      skip: !smsInvoicePhonenumberField.showConfirmation
    }
  );

  const finvoiceFields = form.finvoiceFields;
  const hasFinvoiceFields = !!finvoiceFields;
  const requiresFinvoice = billingType === 'ELMA' && selectedOperator === 'PEPPOL';

  useEffect(() => {
    if (hasFinvoiceFields) {
      setRequiredFields(
        [finvoiceFields?.finvoiceOrderId, finvoiceFields?.finvoiceBuyerReference],
        !requiresFinvoice
      );
    }
  }, [
    hasFinvoiceFields,
    finvoiceFields?.finvoiceOrderId,
    finvoiceFields?.finvoiceBuyerReference,
    requiresFinvoice
  ]);

  useEffect(() => {
    const values = Object.entries(form.fields).reduce((object, [key, value]) => {
      if (value.value !== value.initialValue) {
        object[key] = String(value.value);
      }
      return object;
    }, {} as Record<string, string>);
    setConfirmationModal(values);
  }, [form.fields, setConfirmationModal]);

  return (
    <DataPanel loading={false} data-testid={testId}>
      <DetailsLoadingOverlay showLoader={form.step === 'persisting'}>
        <Forms.RequiredFieldsLabel float />

        <BillMillAccountFormPayerInfo fields={form.fields} disallowMutation={disallowMutation} />

        <Gutter size="xlg" />

        <BillMillAccountFormBillingInfo
          fields={form.fields}
          disallowMutation={disallowMutation}
          invoicingType={billMillAccount?.billing?.invoicingType}
        />

        <Gutter size="xlg" />

        <Heading.H4>{t('invoices.billingGroup.form.smsInvoice.title')}</Heading.H4>

        <Gutter size="sm" />

        <ModelTextInput
          data-testid="billing-account-form__sms-invoice"
          label={t('invoices.billingGroup.form.smsInvoice.smsInvoiceLabel')}
          placeholder={t('invoices.billingGroup.form.smsInvoice.smsInvoicePlaceholder')}
          inputModel={{
            ...smsInvoicePhonenumberField,
            isValid: !validatingSmsInvoiceMsisdn && form.fields.smsInvoicePhoneNumber.isValid,
            errorMessage:
              !smsInvoicePhonenumberField.errorMessage &&
              !validatingSmsInvoiceMsisdn &&
              subscriptionInCompany === false
                ? t('invoices.billingGroup.form.smsInvoice.notInCompany')
                : smsInvoicePhonenumberField.errorMessage
          }}
          disabled={disallowMutation}
          hardMaxLength
          type="tel"
        />

        {form.finvoiceAvailable && finvoiceFields && billingType !== 'EMAIL' && (
          <>
            <Gutter size="xlg" />

            <Heading.H4>{t('invoices.billingGroup.form.finvoice.title')}</Heading.H4>

            <Gutter size="sm" />

            <BillingGroupFormFinvoiceBlock
              finvoice={finvoiceFields}
              disallowMutation={disallowMutation}
              data-testid="billing-account-form__finvoice-block"
            />
          </>
        )}

        <Gutter size="xlg" />

        <BillingGroupFormContactInfo fields={form.fields} disallowMutation={disallowMutation} />

        <Gutter size="xlg" />

        {form.step === 'failure' && (
          <>
            <InlineNotification
              variant="error"
              title={noticeOnFailureTitle}
              content={noticeOnFailureDescription}
              data-testid="billing-account-form__failure-notice"
            />

            <Gutter />
          </>
        )}

        <BillingGroupFormButtons
          dataTestIdPrefix="billing-account"
          trackOnSave={analyticsEvent}
          saveEnabledWhen={{ onePermission: 'BILLING' }}
          cancelEnabledWhen={{ onePermission: 'BILLING' }}
          onSave={form.onSave}
          onCancel={form.onCancel}
          canSave={canSave && form.step !== 'persisting'}
          canCancel={canCancel && form.step !== 'persisting'}
        />
      </DetailsLoadingOverlay>
    </DataPanel>
  );
};

export default BillMillAccountForm;
