import { initial, isArray, last } from 'lodash/fp';

import styles from './FooterContainer.module.scss';

const FooterContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  let content: React.ReactNode | undefined;
  let footer: React.ReactNode | undefined;

  if (isArray(children)) {
    content = initial(children);
    footer = last(children);
  }

  return (
    <div id="application" className={styles.container}>
      <main id="application-body" className={styles.body}>
        {content}
      </main>
      <div id="application-footer" className={styles.footer}>
        {footer}
      </div>
    </div>
  );
};

export default FooterContainer;
