import { isWholesaleBusiness } from 'doings/isWholesaleBusiness/isWholesaleBusiness';

import { DashboardSectionId } from './sections';

const wholesaleBusinessLinkOrder = [
  DashboardSectionId.WholesaleServices,
  DashboardSectionId.BusinessServices,
  DashboardSectionId.Communication,
  DashboardSectionId.Invoicing,
  DashboardSectionId.TeliaAccountManagement
];

export function getDashboardSections(user: User) {
  return isWholesaleBusiness(user) ? wholesaleBusinessLinkOrder : [DashboardSectionId.QuickLinks];
}
