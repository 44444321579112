import { gql } from '@apollo/client';
import { useUserData } from 'contexts/UserContext/UserContext';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { ProductNewsItem, ProductTip, ProductUpdate } from 'types/productNews';

export const productNewsTitlesQuery = gql`
  query GetProductNewsTitles($lang: String!) {
    productUpdates(lang: $lang) {
      id
      title
    }
    productTips(lang: $lang) {
      id
      title
    }
  }
`;

interface QueryResponse {
  productUpdates: ProductUpdate[];
  productTips: ProductTip[];
}

const useProductNewsTitle = ({ newsId }: { newsId: string }) => {
  const user = useUserData();
  const { data, error, loading } = useCallBackend<QueryResponse>({
    query: productNewsTitlesQuery,
    queryVariables: { lang: user.language }
  });

  const productUpdates = data?.productUpdates || [];
  const productTips = data?.productTips || [];
  const title = findTitle(newsId, productUpdates) || findTitle(newsId, productTips);
  return { title, loading, error };
};

const findTitle = (newsId: string, newsItems: ProductNewsItem[]) => {
  return newsItems.find((item) => item.id === newsId)?.title;
};
export { useProductNewsTitle };
