import useCollectionGroup from 'hooks/invoicing/billingGroups/useCollectionGroup/useCollectionGroup';
import useSubscriptions from 'hooks/mobileSubscriptions/useSubscriptions/useSubscriptions';
import { useParams } from 'react-router';

import BillingGroupDetailsPage from './BillingGroupDetailsPage';

interface BillingGroupDetailsParams {
  collectionGroup: string;
}

const BillingGroupDetails: React.FC = () => {
  const { collectionGroup } = useParams<BillingGroupDetailsParams>();
  const { item, status: groupStatus } = useCollectionGroup({
    collectionGroup
  });

  const {
    subscriptions: { total: totalSubscriptions, items: subscriptionItems },
    apiStatus: subscriptionsStatus
  } = useSubscriptions({}, { collectionGroup });

  return (
    <BillingGroupDetailsPage
      collectionGroup={item}
      subscriptions={subscriptionItems}
      totalSubscriptions={totalSubscriptions}
      groupStatus={groupStatus}
      subscriptionStatus={subscriptionsStatus}
    />
  );
};

export default BillingGroupDetails;
