import { Div, List, MarginSpace } from '@gaiads/telia-react-component-library';
import TopBar from 'B2XApp/TopBar/TopBar';
import { MenuItemShape } from 'B2XApp/TopNavigation/Navigation/useNavigationItems';
import { useSubmenuState } from 'contexts/SubmenuContext/SubmenuContext';

import styles from './MobileNavigation.module.scss';
import MobileLanguageSelector from './MobileNavigationItem/MobileLanguageSelector';
import MobileNavigationItem from './MobileNavigationItem/MobileNavigationItem';

const MobileNavigation: React.FC<{
  navigationItems: MenuItemShape[];
  selectedLanguage: Language;
  selectLanguage: (language: Language) => void;
}> = ({ navigationItems, selectedLanguage, selectLanguage }) => {
  const { openMenuId, setOpenMenuId } = useSubmenuState();

  return (
    <Div className={styles.mobileNavigation} backgroundColor="white" boxShadow="md">
      <List>
        <List.Item margin={{ bottom: 'zero' }} className={styles.mobileNavigation_topBar}>
          <MarginSpace>
            <TopBar
              hideLanguageSelection
              selectedLanguage={selectedLanguage}
              selectLanguage={selectLanguage}
            />
          </MarginSpace>
        </List.Item>

        {navigationItems.map(({ id, labelI18nKey, to, external, menuItems }) => (
          <List.Item
            backgroundColor={openMenuId === id ? 'white' : 'gray200'}
            key={id}
            className={styles.mobileNavigation_item}
            margin={{ size: 'zero' }}
          >
            <MobileNavigationItem
              id={id}
              data-testid={`navigation-menu-item-${id}`}
              labelI18nKey={labelI18nKey}
              isMenu={!!menuItems && Boolean(menuItems.length)}
              setOpenMenuId={() => setOpenMenuId(openMenuId === id ? '' : id)}
              isCurrentMenuOpen={openMenuId === id}
              itemProps={{
                to,
                external
              }}
              menuProps={{
                menuItems
              }}
            />
          </List.Item>
        ))}
        <List.Item
          key="language-selector"
          backgroundColor="gray200"
          className={styles.mobileNavigation_item}
          margin={{ size: 'zero' }}
        >
          <MobileLanguageSelector
            selectedLanguage={selectedLanguage}
            selectLanguage={selectLanguage}
          />
        </List.Item>
      </List>
    </Div>
  );
};

export default MobileNavigation;
