import { GridBag, Gutter } from '@gaiads/telia-react-component-library';
import { Forms, Heading, InlineNotification } from 'common-components';
import ModelCheckbox from 'common-components/ModelCheckbox/ModelCheckbox';
import ModelDropdown from 'common-components/ModelDropdown/ModelDropdown';
import ModelTextInput from 'common-components/ModelTextInput/ModelTextInput';
import { useTranslation } from 'react-i18next';

import { BillMillAccountFormFields } from './useBillMillAccountForm';

const BillMillAccountFormBillingInfo: React.FC<{
  fields: BillMillAccountFormFields;
  disallowMutation: boolean;
  invoicingType?: string;
}> = ({ fields, disallowMutation, invoicingType }) => {
  const { i18n, t } = useTranslation();

  const billingType = fields.billingType.value;
  const paymentMethod = fields.suoramaksuPaymentMethod.value;
  const isEmailDirectPaymentBillingType = billingType === 'SUORAMAKSU' && paymentMethod === 'EMAIL';
  const selectedOperator = fields.elmaOperator.value.toUpperCase();
  const selectedOperatorNoticeKey = `invoices.billingGroup.form.billingInfo.operatorNotice.${selectedOperator}`;

  return (
    <>
      <Heading.H4>{t('invoices.billingGroup.form.billingInfo.title')}</Heading.H4>

      <Gutter size="sm" />

      <GridBag>
        <GridBag.Item sm={12} md={12} key="billingLanguage">
          <ModelDropdown
            label={t('invoices.billingGroup.form.billingInfo.languageLabel')}
            inputModel={fields.billingLanguage}
            placeholder={t('invoices.billingGroup.form.billingInfo.languagePlaceholder')}
            disabled={disallowMutation}
            data-testid="billing-account-form__billing-language"
          />
        </GridBag.Item>

        <GridBag.Item sm={12} md={12} key="billingType">
          <ModelDropdown
            label={t('invoices.billingGroup.form.billingInfo.billingTypeLabel')}
            inputModel={fields.billingType}
            placeholder={t('invoices.billingGroup.form.billingInfo.billingTypePlaceholder')}
            disabled={disallowMutation || fields.billingType.disabled}
            data-testid="billing-account-form__billing-type"
          />
        </GridBag.Item>

        {[
          ...(billingType === 'SUORAMAKSU'
            ? [
                <GridBag.Item sm={12} md={12} key="paymentMethod">
                  <ModelDropdown
                    label={t('invoices.billingGroup.form.billingInfo.paymentMethod')}
                    inputModel={fields.suoramaksuPaymentMethod}
                    placeholder={t(
                      'invoices.billingGroup.form.billingInfo.paymentMethodPlaceholder'
                    )}
                    disabled={disallowMutation || fields.suoramaksuPaymentMethod.disabled}
                    data-testid="billing-account-form__SUORAMAKSU-payment-method"
                  />
                </GridBag.Item>
              ]
            : []),

          // prettier-ignore
          ...billingType === 'EMAIL' || isEmailDirectPaymentBillingType ? [
          <GridBag.Item sm={12} md={12} key="email1">
            <ModelTextInput
              data-testid="billing-account-form__EMAIL-email-1"
              label={t('invoices.billingGroup.form.billingInfo.email1Label')}
              inputModel={fields.email1}
              disabled={disallowMutation || (fields.email1.disabled && !isEmailDirectPaymentBillingType)}
              hardMaxLength
              type="email"
            />
          </GridBag.Item>,
          <GridBag.Item sm={12} md={12} key="email2">
            <ModelTextInput
              data-testid="billing-account-form__EMAIL-email-2"
              label={t('invoices.billingGroup.form.billingInfo.email2Label')}
              inputModel={fields.email2}
              disabled={disallowMutation || (fields.email2.disabled && !isEmailDirectPaymentBillingType)}
              hardMaxLength
              type="email"
            />

            <Forms.FieldHelperText
              helperText={t('invoices.billingGroup.form.billingInfo.email2AdditionalInfo')}
            />
          </GridBag.Item>] : [],

          // prettier-ignore
          ...billingType === 'ELASKU' ? [
          <GridBag.Item sm={12} md={12} key="elaskuIban">
            <ModelTextInput
              data-testid="billing-account-form__ELASKU-iban"
              label={t('invoices.billingGroup.form.billingInfo.ibanLabel')}
              placeholder={t('invoices.billingGroup.form.billingInfo.ibanPlaceholder')}
              inputModel={fields.elaskuIban}
              disabled={disallowMutation || fields.elaskuIban.disabled}
              hardMaxLength
            />
          </GridBag.Item>,
          <GridBag.Item sm={12} md={12} key="elaskuBic">
            <ModelDropdown
              data-testid="billing-account-form__ELASKU-bic-code"
              label={t('invoices.billingGroup.form.billingInfo.bicCodeLabel')}
              inputModel={fields.elaskuBic}
              placeholder={t('invoices.billingGroup.form.billingInfo.bicCodePlaceholder')}
              disabled={disallowMutation  || fields.elaskuBic.disabled}
            />
          </GridBag.Item>,
          ] : [],

          // prettier-ignore
          ...(billingType === 'ELASKU' && invoicingType !== 'ELASKU') ? [
          <GridBag.Item sm={12} md={12} key="hasEbillAgreement">
            <ModelCheckbox
              label={t('invoices.billingGroup.form.billingInfo.hasEbillAgreement')}
              helperText={t('invoices.billingGroup.form.billingInfo.hasEbillAgreementTooltip')}
              checkboxModel={fields.hasEbillAgreement}
              disabled={disallowMutation  || fields.hasEbillAgreement.disabled}
              data-testid="billing-account-form__ELASKU-has-ebill-agreement"
            />
          </GridBag.Item>] : [],

          // prettier-ignore
          ...billingType === 'ELMA' ? [
          <GridBag.Item sm={12} md={12} key="elmaOvt">
            <ModelTextInput
              data-testid="billing-account-form__ELMA-ovt"
              label={t('invoices.billingGroup.form.billingInfo.ovtLabel')}
              placeholder={t('invoices.billingGroup.form.billingInfo.ovtPlaceholder')}
              inputModel={fields.elmaOvtCode}
              disabled={disallowMutation  || fields.elmaOvtCode.disabled}
              hardMaxLength
            />
          </GridBag.Item>,
          <GridBag.Item sm={12} md={12} key="elmaOperator">
            <ModelDropdown
              label={t('invoices.billingGroup.form.billingInfo.operatorLabel')}
              inputModel={fields.elmaOperator}
              placeholder={t('invoices.billingGroup.form.billingInfo.operatorPlaceholder')}
              disabled={disallowMutation || fields.elmaOperator.disabled}
              data-testid="billing-account-form__ELMA-operator"
            />
          </GridBag.Item>] : [],

          // prettier-ignore
          ...billingType === 'ELMA' && i18n.exists(selectedOperatorNoticeKey) ? [
          <GridBag.Item sm={12} md={12} key="netOperatorNotice">
            <InlineNotification
              variant='information'
              content={{html: t(selectedOperatorNoticeKey)}}
              data-testid='billing-account-form__ELMA-operator-notice'
            />
          </GridBag.Item>] : []
        ]}
      </GridBag>
    </>
  );
};

export default BillMillAccountFormBillingInfo;
