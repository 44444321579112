import { ViewStatus } from 'hooks/useApiStatus/useApiStatus';
import { useEffect, useState } from 'react';

export default (apiStatus: ViewStatus) => {
  const [isInitial, setIsInitial] = useState(true);

  useEffect(() => {
    if (isInitial && apiStatus === ViewStatus.Loaded) {
      setIsInitial(false);
    }
  }, [isInitial, apiStatus]);

  if (isInitial && apiStatus === ViewStatus.Loading) {
    return ViewStatus.LoadingInitial;
  }

  return apiStatus;
};
