import {
  OverflowMenuProvider,
  useOverflowMenuState
} from 'contexts/OverflowMenuContext/OverflowMenuContext';

const OverflowMenuContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { containerRef } = useOverflowMenuState();
  return (
    <div ref={containerRef} data-testid="quicklinks-menu-container">
      {children}
    </div>
  );
};

const OverflowMenuWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <OverflowMenuProvider>
      <OverflowMenuContainer>{children}</OverflowMenuContainer>
    </OverflowMenuProvider>
  );
};

export default OverflowMenuWrapper;
