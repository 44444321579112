import {
  SubscriptionAttribute,
  SubscriptionStatus
} from 'hooks/mobileSubscriptions/useSubscriptionDetails/subscriptionDetailsQuery';
import { SubscriptionPackages } from 'hooks/mobileSubscriptions/useSubscriptions/subscriptionsQuery';

export type SubscriptionListItem = {
  id: string;
  phoneNumber: string;
  phoneNumberHash: string;
  attributes: SubscriptionAttribute[];
  status: SubscriptionStatus;
  type: string;
  packages: SubscriptionPackages;
  user: string;
  isSurfCard: boolean;
  isInTransfer?: boolean;
  hasFixedTermContract?: boolean;
  hasDuettoBundle?: boolean;
};

export type SubscriptionPukCodeSearchItem = {
  phoneNumber: string;
  phoneNumberHash: string;
  name: string;
};

export enum SortableColumns {
  id = 'id',
  subscriptionTypeName = 'subscriptionTypeName',
  userName = 'userName'
}
