import { mapToOptionList } from 'doings/mapToOptionList/mapToOptionList';
import { useToggleStateWithQueryParameter } from 'hooks/useToggleState/useToggleState';
import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownOptionDefinition, SearchOptionDefinition } from 'types/listFilters';
import { ServiceRequestStatus } from 'types/serviceRequest';

export enum ServiceRequestFilterDateType {
  BY_DATE_CREATED = 'created',
  BY_DATE_UPDATED = 'updated'
}

const getSearchFieldOptions = (t: TFunction): SearchOptionDefinition[] => [
  {
    label: t('serviceRequests.list.searchScopes.subject'),
    value: 'subject'
  },
  {
    label: t('serviceRequests.list.searchScopes.description'),
    value: 'description'
  },
  {
    label: t('serviceRequests.list.searchScopes.caseNumber'),
    value: 'ticketNumber'
  },
  {
    label: t('serviceRequests.list.searchScopes.subscription'),
    value: 'subscriptionNumber'
  }
];

const getDateTypeOptions = (t: TFunction): DropdownOptionDefinition[] => [
  {
    label: t('tickets.list.filters.modified'),
    value: ServiceRequestFilterDateType.BY_DATE_UPDATED
  },
  {
    label: t('tickets.list.filters.creationDate'),
    value: ServiceRequestFilterDateType.BY_DATE_CREATED
  }
];

const getStatusOptions = (t: TFunction): DropdownOptionDefinition[] =>
  mapToOptionList<ServiceRequestStatus>({
    Open: t('tickets.status.open'),
    'In Progress': t('tickets.status.inProgress'),
    'Waiting for information': t('tickets.status.waitingForInformation'),
    Resolved: t('tickets.status.resolved'),
    Closed: t('tickets.status.closed'),
    Cancelled: t('tickets.status.cancelled')
  });

export type UseServiceRequestsFiltersResult = ReturnType<typeof useServiceRequestListFilter>;

export const useServiceRequestListFilter = (initialAllCompanies: boolean) => {
  const { t } = useTranslation();

  const searchFieldOptions = useMemo(() => getSearchFieldOptions(t), [t]);
  const dateTypeOptions = useMemo(() => getDateTypeOptions(t), [t]);
  const statusOptions = useMemo(() => getStatusOptions(t), [t]);

  const [showFromAllCompanies, toggleAllCompanies] = useToggleStateWithQueryParameter(
    initialAllCompanies,
    'allCompanies'
  );

  return {
    searchFieldOptions,
    dateTypeOptions,
    statusOptions,
    allCompanySearch: {
      showFromAllCompanies,
      toggleAllCompanies
    }
  };
};
