import { List } from '@gaiads/telia-react-component-library';
import { Paragraph } from '@teliafi/fi-ds';
import { TeliaLink } from 'common-components';
import { AskForHelpAdditionalLink } from 'hooks/askForHelp/useAskForHelp';
import { useTranslation } from 'react-i18next';

import styles from './AdditionalLinks.module.scss';

const AdditionalLinks: React.FC<{ links: AskForHelpAdditionalLink[]; 'data-testid': string }> = ({
  links,
  'data-testid': testId
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Paragraph className={styles.label} variant="paragraph-100-bold">
        {t('askForHelp.drawer.relatedLinks')}:
      </Paragraph>

      <List className={styles.links} data-testid={testId}>
        {links.map(({ title, url, targetPortal }, index) => (
          <List.Item key={`${testId}--link-${index}`} margin={{ bottom: 'zero' }}>
            <TeliaLink.Portal
              variant="standalone"
              iconLeft="chevron-right"
              label={title}
              to={url}
              toCorporatePortal={url}
              useRouterLink={targetPortal === 'b2x'}
            />
          </List.Item>
        ))}
      </List>
    </>
  );
};

export default AdditionalLinks;
