import { Gutter } from '@gaiads/telia-react-component-library';
import { Repeat, Skeleton } from 'common-components';

import styles from './NewsDrawerContentSkeleton.module.scss';

export const NewsDrawerContentSkeleton: React.FC = () => (
  <div className={styles.skeleton} data-testid="news-drawer-content-skeleton">
    <Repeat times={2}>
      <Skeleton.Panel>
        <Skeleton.TextRow width="60%" />

        <Gutter size="sm" />

        <Skeleton.Image width="100%" height="160px" />

        <Gutter size="sm" />

        <Skeleton.TextRow width="100%" />

        <Skeleton.TextRow width="100%" />

        <Gutter size="sm" />

        <Skeleton.Button width="20%" />
      </Skeleton.Panel>
    </Repeat>
  </div>
);
