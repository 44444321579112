import { ApolloClient, ApolloLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { SetCSRFTokenFunction } from 'contexts/CSRFToken/CSRFToken';

import { getCSRFTokenLink } from './getCSRFTokenLink';
import { getExtendUrlLink } from './getExtendUrlLink';
import { getMockLink } from './getMockLink';

export default (
  uri: string,
  setCSRFToken: SetCSRFTokenFunction
): ApolloClient<NormalizedCacheObject> => {
  const cache = new InMemoryCache({
    possibleTypes: {
      DynamicFormField: [
        'DynamicFormCheckboxField',
        'DynamicFormCheckgroupField',
        'DynamicFormDateField',
        'DynamicFormDropdownField',
        'DynamicFormInfotextField',
        'DynamicFormInputField',
        'DynamicFormSinkField',
        'DynamicFormSubheaderField',
        'DynamicFormTextareaField'
      ]
    }
  });

  const mockLink = getMockLink();
  const csrfLink = getCSRFTokenLink(setCSRFToken);
  const extendUrlLink = getExtendUrlLink();
  const uploadLink = createUploadLink({
    uri,
    credentials: 'include',
    headers: { 'Apollo-Require-Preflight': 'true' }
  });

  return new ApolloClient({
    cache,
    link: ApolloLink.from([mockLink, csrfLink, extendUrlLink, uploadLink as unknown as ApolloLink])
  });
};
