import { Div, Flex, Icon } from '@gaiads/telia-react-component-library';
import { SupportedTextColor } from '@gaiads/telia-react-component-library/build/types/components/Typography/Typography';
import { SmartTooltip } from 'common-components';
import ExternalLink from 'common-components/Link/ExternalLink/ExternalLink';
import InternalLink from 'common-components/Link/Link';
import { NavigationalLink } from 'doings/getNavigationalLinks/getNavigationalLinks';
import trackEvent from 'doings/track/trackEvent';
import { useTranslation } from 'react-i18next';

import styles from './DashboardSectionLink.module.scss';

export const DashboardSectionLink = ({
  sectionLink: {
    to,
    external,
    openExternalLinkInSameTab,
    tooltipI18nKey,
    trackForDashboard,
    onClick,
    disabled
  },
  children,
  color = 'black',
  disabled: explicitlyDisabled,
  isNested,
  isStandalone,
  'data-testid': testId
}: {
  sectionLink: NavigationalLink;
  children: React.ReactNode;
  color?: SupportedTextColor;
  disabled?: boolean;
  isNested?: boolean;
  isStandalone?: boolean;
  'data-testid'?: string;
}) => {
  const { t } = useTranslation();
  const Link = external ? ExternalLink : InternalLink;

  const isDisabled = explicitlyDisabled || disabled;
  const clickHandler = () => {
    trackEvent(trackForDashboard);
    onClick?.();
  };

  return (
    <Div
      className={{ [styles.link]: true, [styles.link__disabled]: isDisabled }}
      onClick={clickHandler}
    >
      <Link
        color={color}
        to={to}
        data-testid={testId}
        disabled={isDisabled}
        openInNewWindow={external && !openExternalLinkInSameTab}
      >
        {isStandalone && <>{children}</>}

        {!isStandalone && (
          <Flex
            className={styles.link_background}
            centeredVertically
            data-testid="dashboard-section-link-content-wrapper"
          >
            <Flex data-testid="dashboard-section-link-content">
              {isNested && (
                <Icon
                  data-testid="dashboard-section-link-icon"
                  name="chevron-right"
                  className={styles.link_icon}
                  size="sm"
                  margin={{ right: 'xxs', top: 'xxs' }}
                />
              )}
              {children}
            </Flex>

            {!!tooltipI18nKey && (
              <SmartTooltip tooltipContent={t(tooltipI18nKey)} arrangement="top" wrapper="div">
                <SmartTooltip.InfoIcon
                  size="md"
                  spacing="sm"
                  data-testid="dashboard-section-link-tooltip"
                />
              </SmartTooltip>
            )}
          </Flex>
        )}
      </Link>
    </Div>
  );
};
