import { ApolloError, gql } from '@apollo/client';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { ApiStatus } from 'types/api';
import {
  PaymentReminder,
  PaymentReminderStatus,
  PaymentReminderType,
  PaymentRemindersResponse
} from 'types/paymentReminder';

export const paymentRemindersQuery = gql`
  query GetPaymentReminders($companyId: String) {
    paymentReminders(companyId: $companyId) {
      reminders {
        type
        status
        emailAddress
        subscriptionNumber
      }
    }
  }
`;

const reminderTypeMappings = {
  MOBILE: PaymentReminderType.Mobile,
  BROADBAND_AND_FIXED: PaymentReminderType.BroadbandAndFixed
};

const reminderStatusMappings = {
  ON: PaymentReminderStatus.On,
  OFF: PaymentReminderStatus.Off
};

const mapApiStatus = (loading: boolean, data?: Response, error?: ApolloError): ApiStatus => {
  const status = error || !data ? ApiStatus.Error : ApiStatus.Loaded;
  return loading ? ApiStatus.Loading : status;
};

const mapAvailability = (data: Response): boolean => data.paymentReminders.reminders.length > 0;

const mapUnavailableReminders = (): PaymentRemindersResponse => ({
  [PaymentReminderType.Mobile]: mapUnavailableReminder(PaymentReminderType.Mobile),
  [PaymentReminderType.BroadbandAndFixed]: mapUnavailableReminder(
    PaymentReminderType.BroadbandAndFixed
  )
});

const mapAvailableReminders = (data: Response): PaymentRemindersResponse => ({
  [PaymentReminderType.Mobile]: mapPotentialReminder(data, PaymentReminderType.Mobile),
  [PaymentReminderType.BroadbandAndFixed]: mapPotentialReminder(
    data,
    PaymentReminderType.BroadbandAndFixed
  )
});

const mapPotentialReminder = (data: Response, givenType: PaymentReminderType): PaymentReminder => {
  const responseReminder = data.paymentReminders.reminders.find(({ type }) => type === givenType);
  return responseReminder
    ? mapAvailableReminder(responseReminder)
    : mapUnavailableReminder(givenType);
};

const mapAvailableReminder = ({
  type,
  status,
  emailAddress,
  subscriptionNumber
}: ResponseReminder): PaymentReminder => ({
  type: reminderTypeMappings[type],
  status: reminderStatusMappings[status],
  email: emailAddress,
  phoneNumber: subscriptionNumber
});

const mapUnavailableReminder = (givenType: PaymentReminderType): PaymentReminder => ({
  type: givenType,
  status: PaymentReminderStatus.Unavailable
});

interface Response {
  paymentReminders: {
    reminders: ResponseReminder[];
  };
}

interface ResponseReminder {
  type: 'MOBILE' | 'BROADBAND_AND_FIXED';
  status: 'ON' | 'OFF';
  emailAddress?: string;
  subscriptionNumber?: string;
}

interface UsePaymentRemindersResult {
  status: ApiStatus;
  available: boolean;
  reminders: PaymentRemindersResponse;
  error?: ApolloError;
  refetch: VoidFunction;
}

const usePaymentReminders = (companyId?: string): UsePaymentRemindersResult => {
  const { loading, data, error, refetch } = useCallBackend<Response>({
    query: paymentRemindersQuery,
    queryVariables: {
      companyId
    },
    fetchPolicy: 'network-only'
  });

  const status = mapApiStatus(loading, data, error);
  return status === ApiStatus.Loaded && data
    ? {
        status: status,
        available: mapAvailability(data),
        reminders: mapAvailableReminders(data),
        error: error,
        refetch
      }
    : {
        status: status,
        available: false,
        reminders: mapUnavailableReminders(),
        error: error,
        refetch
      };
};

export default usePaymentReminders;
