import { TeliaLink } from 'common-components';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setShowConfirmationModal } from 'redux/slices/confirmationModalSlice';

import ConfirmationPanelLayout from './ConfirmationPanelLayout';

const ConfirmationPanelSuccess: React.FC<{
  title: string;
  description: string | React.ReactElement;
  backToPath?: string;
  backToLabel?: string;
  children?: React.ReactNode;
  partialSuccess?: boolean;
  'data-testid'?: string;
}> = ({
  title,
  description,
  backToPath,
  backToLabel,
  children,
  partialSuccess = false,
  'data-testid': testId
}) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setShowConfirmationModal(false));
  }, [dispatch]);

  const hasInternalPath = backToPath && !isExternal(backToPath);
  const hasExternalPath = backToPath && isExternal(backToPath);

  return (
    <ConfirmationPanelLayout
      title={title}
      description={description}
      variant={partialSuccess ? 'partial-success' : 'success'}
      data-testid={testId}
    >
      {hasInternalPath && (
        <TeliaLink.Internal
          data-testid="back-to-link"
          variant="standalone"
          to={backToPath}
          label={backToLabel ?? ''}
          iconRight="chevron-right"
        />
      )}

      {hasExternalPath && (
        <TeliaLink.External
          data-testid="back-to-link"
          variant="standalone"
          to={backToPath}
          label={backToLabel ?? ''}
          iconRight="chevron-right"
          openInNewTab={false}
        />
      )}

      {children}
    </ConfirmationPanelLayout>
  );
};

const isExternal = (path: string) => path.startsWith('https://') || path.startsWith('//');

export default ConfirmationPanelSuccess;
