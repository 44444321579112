import { useTicketAttachments } from 'B2XApp/Messages/common';
import { ServiceRequestFields } from 'B2XApp/Messages/ServiceRequests/NewServiceRequest/getServiceRequestFields';
import getTicketContactMethodOptions from 'B2XApp/Messages/Tickets/NewTicket/NewTicketForm/getTicketContactMethodOptions';
import getRelatedCompaniesForDropdown from 'doings/getRelatedCompaniesForDropdown/getRelatedCompaniesForDropdown';
import { SUPPORTED_FILE_TYPES } from 'doings/validateMessageAttachment/validateMessageAttachment';
import useDropdownInputModel from 'hooks/inputModels/useDropdownInputModel/useDropdownInputModel';
import useMultiselectInputModel from 'hooks/inputModels/useMultiselectInputModel/useMultiselectInputModel';
import useTextInputModel from 'hooks/inputModels/useTextInputModel/useTextInputModel';
import { CreateServiceRequestData } from 'hooks/serviceRequests/useCreateServiceRequest/useCreateServiceRequest';
import { TFunction } from 'i18next';
import { every } from 'lodash/fp';

export default ({
  fields: { subscriptionNumber, invoiceNumber, company },
  initialData,
  activeGroupType = 'b2b',
  allowedAttachmentTypes = SUPPORTED_FILE_TYPES,
  t
}: {
  fields: ServiceRequestFields;
  initialData?: CreateServiceRequestData;
  activeGroupType?: ActiveGroupType;
  allowedAttachmentTypes?: string[];
  t: TFunction;
}) => {
  const subscriptionIdInputModel = useTextInputModel({
    ...subscriptionNumber,
    ...(initialData ? { initialValue: initialData?.subscriptionNumber } : {}),
    maxLength: 255
  });

  const invoiceNumberInputModel = useTextInputModel({
    ...invoiceNumber,
    ...(initialData ? { initialValue: initialData?.invoiceNumber } : {}),
    maxLength: 100
  });

  const subjectInputModel = useTextInputModel({
    ...(initialData ? { initialValue: initialData?.subject } : {}),
    required: true,
    maxLength: 90
  });

  const { activeGroupId, selectedGroupId, availableCompanies } = getRelatedCompaniesForDropdown(
    {
      onePermission: 'SERVICE_REQUESTS',
      activeGroup: company?.groupType || activeGroupType
    },
    company?.initialValue
  );

  const relatedCompanyInputModel = useDropdownInputModel({
    options: availableCompanies,
    initialValue: selectedGroupId || activeGroupId,
    required: true,
    excludeNoneSelected: true,
    disabled: (!!selectedGroupId && company?.disabled) ?? availableCompanies.length <= 1
  });

  const contactMethodsInputModel = useMultiselectInputModel({
    options: getTicketContactMethodOptions(t),
    initialValue: 'email',
    required: true
  });

  const attachmentsModel = useTicketAttachments(initialData?.attachments, allowedAttachmentTypes);

  return {
    relatedCompanyInputModel,
    subjectInputModel,
    subscriptionIdInputModel,
    invoiceNumberInputModel,
    contactMethodsInputModel,
    attachmentsModel,
    isValid: every('isValid', [
      relatedCompanyInputModel,
      subjectInputModel,
      subscriptionIdInputModel,
      invoiceNumberInputModel,
      contactMethodsInputModel,
      attachmentsModel
    ])
  };
};
