import { Icon, IconProps } from '@teliafi/fi-ds';
import getClassNames from 'classnames';
import { noOp } from 'doings/noOp/noOp';

import styles from './IconButton.module.scss';

export const IconButton: React.FC<{
  'data-testid': string;
  'data-tour-stop'?: string;
  'data-dd-action-name'?: string;
  'aria-label': string;
  'aria-describedby'?: string;
  'aria-expanded'?: boolean;
  'aria-pressed'?: boolean;
  className?: getClassNames.ArgumentArray;
  name: IconProps['name'];
  size: 'sm' | 'md' | 'lg';
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  focusable?: boolean;
  disabled?: boolean;
}> = ({
  'data-testid': htmlDataTestId,
  'data-tour-stop': htmlDataTourStop,
  'data-dd-action-name': htmlDataDatadogActionName,
  'aria-label': htmlAriaLabel,
  'aria-describedby': htmlAriaDescribedBy,
  'aria-expanded': htmlAriaExpanded,
  'aria-pressed': htmlAriaPressed,
  className,
  name,
  size,
  onClick,
  focusable = true,
  disabled = false
}) => {
  return (
    <button
      data-testid={htmlDataTestId}
      data-tour-stop={htmlDataTourStop}
      data-dd-action-name={htmlDataDatadogActionName}
      type="button"
      aria-label={htmlAriaLabel}
      aria-describedby={htmlAriaDescribedBy}
      aria-expanded={htmlAriaExpanded}
      aria-pressed={htmlAriaPressed}
      className={getClassNames(styles.iconButton, className)}
      onClick={disabled ? noOp : onClick}
      tabIndex={focusable ? undefined : -1}
      aria-hidden={!focusable}
      aria-disabled={disabled}
    >
      <Icon name={name} size={size} />
    </button>
  );
};
