declare global {
  interface Window {
    KAMPYLE_ONSITE_SDK?: {
      updatePageView: VoidFunction;
    };

    medalliaData?: {
      businessSegment?: BusinessSegment;
      companyID?: string;
      ulmUserID: string;
      salesSubSegment?: string;
      salesChannel?: string;
      responsibleOrg?: string;
      channel: 'B2X portal';
      surveySource: 'B2X portal';
    };
  }
}

/**
 * Refreshes Medallia's script to show Medallia's feedback form in the application's
 * current local instead of the base locale, and to ensure Medallia picks up any
 * globally set user or customer properties when user or customer information changes
 * within the single page application.
 */
export const refreshMedalliaScript = () => {
  if (window.KAMPYLE_ONSITE_SDK && typeof window.KAMPYLE_ONSITE_SDK.updatePageView === 'function') {
    window.KAMPYLE_ONSITE_SDK.updatePageView();
  }
};

export const updateMedalliaData = (data: typeof window.medalliaData) => {
  window.medalliaData = data;
  refreshMedalliaScript();
};
