import { AnalyticsEvent, reauthAndReplayFlow as ga } from 'doings/track/analyticsEvents';
import { useReducer } from 'react';

type ReplayState =
  | {
      status: 'idle' | 'retrievingReplay' | 'replayPending' | 'replaying';
      track?: AnalyticsEvent;
      error?: never;
      redirectTo?: never;
    }
  | {
      status: 'error';
      track?: AnalyticsEvent;
      error: 'outdated' | 'replayFault';
      redirectTo?: never;
    }
  | {
      status: 'replayed';
      track?: AnalyticsEvent;
      error?: never;
      redirectTo: string;
    };

export enum ReplayActionType {
  RETRIEVE_REPLAY,
  RETRIEVE_REPLAY_FAILED,
  RETRIEVE_REPLAY_DONE,
  REPLAYING,
  REPLAYING_FAILED,
  REPLAYING_DONE
}

export type ReplayAction =
  | { type: Exclude<ReplayActionType, ReplayActionType.REPLAYING_DONE> }
  | { type: ReplayActionType.REPLAYING_DONE; redirectTo: string };

export const useReplayHttpRequestReducer = () =>
  useReducer(
    (_: ReplayState, action: ReplayAction): ReplayState => {
      switch (action.type) {
        case ReplayActionType.RETRIEVE_REPLAY:
          return { status: 'retrievingReplay', track: ga.replayEntryRetrievalStarted };

        case ReplayActionType.RETRIEVE_REPLAY_FAILED:
          return { status: 'error', track: ga.replayEntryRetrievalFailure, error: 'outdated' };

        case ReplayActionType.RETRIEVE_REPLAY_DONE:
          return { status: 'replayPending', track: ga.replayEntryRetrievalSuccess };

        case ReplayActionType.REPLAYING:
          return { status: 'replaying', track: undefined };

        case ReplayActionType.REPLAYING_FAILED:
          return { status: 'error', track: ga.replayFailure, error: 'replayFault' };

        case ReplayActionType.REPLAYING_DONE:
          return { status: 'replayed', track: ga.replaySuccess, redirectTo: action.redirectTo };
      }
    },
    { status: 'idle' }
  );
