import { GrowlNotification } from '@gaiads/telia-react-component-library';
import styles from 'B2XApp/TopNotices/FixedBar.module.scss';

const MockedApiBar: React.FC<{
  allowMocks: boolean;
  toggleMocksOff: VoidFunction;
}> = ({ allowMocks, toggleMocksOff }) => {
  if (!allowMocks) {
    return null;
  }

  return (
    <GrowlNotification
      data-testid="mocks-enabled-notification-bar"
      className={styles.fixedBar}
      notification="Mocks are enabled"
      variant="warning"
      closeOnClick={toggleMocksOff}
    />
  );
};

export default MockedApiBar;
