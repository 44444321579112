import { ApolloError, gql } from '@apollo/client';
import formatDatetime, { DateFormats } from 'doings/formatDatetime/formatDatetime';
import { noOp } from 'doings/noOp/noOp';
import removeEmptyFields from 'doings/removeEmptyFields/removeEmptyFields';
import { mapOrderListItems } from 'hooks/orders/mapOrders';
import useApiStatus, { ViewStatus } from 'hooks/useApiStatus/useApiStatus';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { OrderListItem } from 'types/orders';

import getOrdersOnFetchMore from './getOrdersOnFetchMore';
import { OrderQueryParameters } from './useOrdersFilters';

export const ordersQuery = gql`
  query GetOrders($query: OrdersQuery) {
    orders(query: $query) {
      total
      orders {
        id
        displayId
        companyCode
        companyName
        createdDate
        status
        estimatedTimeOfCompletion
        completionPercentage
        isEcommerce
        externalId
        lineItems {
          productName
          status
          estimatedTimeOfCompletion
          completionPercentage
          displayAddress
        }
      }
    }
  }
`;

interface UseOrdersResult {
  orders: {
    items: OrderListItem[];
    total: number;
  };
  fetchMore: VoidFunction;
  hasMoreResults: boolean;
  status: ViewStatus;
  error?: ApolloError;
}

export interface OrdersResponse {
  orders: {
    total: number;
    orders: OrderListItem[];
  };
}

const staticQueryVariables = {
  limit: 20,
  getWithPagination: true
};

export const prepareQueryParameters = (
  queryParameters: OrderQueryParameters,
  allCompanies: boolean
) =>
  removeEmptyFields({
    orderDateFrom: formatDatetime(queryParameters?.orderDateFrom, DateFormats.DATE),
    orderDateTo: formatDatetime(queryParameters?.orderDateTo, DateFormats.DATE),
    orderNumber: queryParameters.orderNumber,
    orderStatus: queryParameters.orderStatus,
    allCompanies
  });

const useOrders = (
  queryParameters: OrderQueryParameters,
  allCompanies: boolean,
  skip?: boolean
): UseOrdersResult => {
  const filteredQueryParameters = prepareQueryParameters(queryParameters, allCompanies);

  const {
    data,
    error,
    loading,
    loaded,
    loadingMore,
    fetchMore: fetchMoreFromQuery
  } = useCallBackend<OrdersResponse>({
    query: ordersQuery,
    queryVariables: {
      query: {
        ...filteredQueryParameters,
        ...staticQueryVariables,
        offset: 0
      }
    },
    fetchPolicy: 'network-only',
    skip
  });

  const mappedOrders = mapOrderListItems(data?.orders?.orders ?? []);

  const status = useApiStatus({
    loading,
    error,
    loadingMore,
    loaded,
    items: mappedOrders,
    query: queryParameters
  });

  return !skip
    ? {
        orders: {
          total: data?.orders?.total || 0,
          items: mappedOrders
        },
        fetchMore: () =>
          fetchMoreFromQuery({
            variables: {
              query: {
                offset: mappedOrders.length,
                ...filteredQueryParameters,
                ...staticQueryVariables
              }
            },
            updateQuery: getOrdersOnFetchMore
          }),
        hasMoreResults: data ? mappedOrders.length < data?.orders?.total : true,
        status,
        error
      }
    : {
        orders: {
          total: 0,
          items: []
        },
        error: undefined,
        status: ViewStatus.Loaded,
        fetchMore: noOp,
        hasMoreResults: false
      };
};

export default useOrders;
