import { gql } from '@apollo/client';
import useCallBackendMutation from 'hooks/useCallBackend/useCallBackendMutation';
import { EditableListId } from 'types/listConfig';

import { editableListQuery } from './useEditableList';

export const resetEditableListMutation = gql`
  mutation ResetEditableList($listId: EditableListId!) {
    removeListConfiguration(listId: $listId)
  }
`;

type ResetEditableSectionItemsResponse = {
  resetEditableSectionItems: boolean;
};

const useResetEditableList = (listId: EditableListId) => {
  const { runMutation, loading, error } = useCallBackendMutation<ResetEditableSectionItemsResponse>(
    resetEditableListMutation,
    [{ query: editableListQuery, variables: { listId } }]
  );

  const resetEditableList = () => {
    runMutation({
      variables: { listId }
    });
  };

  return {
    resetEditableList,
    resetting: loading,
    error
  };
};

export default useResetEditableList;
