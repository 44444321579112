import { useMemo } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

export const DragAndDropProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const isTouchScreen = useMemo(() => window.matchMedia('(pointer: coarse)').matches, []);
  return (
    <DndProvider backend={isTouchScreen ? TouchBackend : HTML5Backend}>{children}</DndProvider>
  );
};
