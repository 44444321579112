import { configureStore } from '@reduxjs/toolkit';
import askForHelpSlice from 'redux/slices/askForHelpSlice';

import activeFocusTrapsSlice from './slices/activeFocusTrapsSlice';
import billingAccountTypeSlice from './slices/billingAccountTypeSlice';
import billingInfoChangeSlice from './slices/billingInfoChangeSlice';
import confirmationModalSlice from './slices/confirmationModalSlice';
import infoModalSlice from './slices/infoModalSlice';
import newsDrawerSice from './slices/newsDrawerSlice';
import watchedTourTriggersSlice from './slices/watchedTourTriggersSlice';

export const store = configureStore({
  reducer: {
    activeFocusTraps: activeFocusTrapsSlice,
    askForHelpDrawer: askForHelpSlice,
    billingAccountTypeModal: billingAccountTypeSlice,
    billingInfoChangeModal: billingInfoChangeSlice,
    confirmationModal: confirmationModalSlice,
    infoModal: infoModalSlice,
    newsDrawer: newsDrawerSice,
    watchedTourTriggers: watchedTourTriggersSlice
  }
});

export type RootState = ReturnType<typeof store.getState>;
