import { useHistory, useLocation } from 'react-router';

const useCustomModal = (modalId: string) => {
  const { push, goBack } = useHistory();
  const { pathname, search, state } = useLocation<{
    modalId: string;
  }>();

  return {
    openModal: (event?: React.SyntheticEvent) => {
      event?.preventDefault();
      push({ pathname, search }, { ...state, modalId });
    },
    isOpen: state && state.modalId === modalId,
    closeModal: (event?: React.SyntheticEvent) => {
      if (event) {
        event.stopPropagation();
      }

      return goBack();
    }
  };
};

export default useCustomModal;
