import { useBillingGroupsHelper } from 'B2XApp/Invoicing/BillingGroups/Common/useBillingGroupsHelper';
import { Memo, OverflowMenu, OverflowMenuToggle } from 'common-components';
import { useOverflowMenuState } from 'contexts/OverflowMenuContext/OverflowMenuContext';
import isDemoEnvironment from 'doings/isDemoEnvironment/isDemoEnvironment';
import { Args } from 'doings/links/createCorporatePortalLink/createCorporatePortalLink';
import { AnalyticsEvent, mobile } from 'doings/track/analyticsEvents';
import { useRouting } from 'hooks/useRouting/useRouting';
import { useTranslation } from 'react-i18next';
import { AuthorizationParams } from 'types/authorization';
import { CollectionGroup } from 'types/collection-group';

import { editBillingEnabledWhen, getChangeBillingInfoDisabled } from './BillingGroupsHelper';

type OnClickProps = {
  group: CollectionGroup;
  goto: (props: {
    to: string;
    toCorporatePortal: string;
    toCorporatePortalArgs: Args;
    useB2XLink: boolean;
    analyticsEvent: AnalyticsEvent;
  }) => void;
  goToEditBillingGroupPage: (collectionGroup: string, customerCode: string) => void;
  goToCopyBillingGroupPage: (collectionGroup: string, customerCode: string) => void;
};

interface QuicklinkDescriptor {
  key: string;
  labelI18nKey: string;
  onClick: (props: OnClickProps) => void;
  enabledWhen?: AuthorizationParams;
  isHidden?: (group: CollectionGroup) => boolean;
  disabled?: boolean;
}

const isDemoEnv = isDemoEnvironment();

const ITEMS: QuicklinkDescriptor[] = [
  {
    key: 'view',
    labelI18nKey: 'billingInformation.list.quicklinks.view',
    onClick: ({ group, goto }) =>
      goto({
        to: `/billing-accounts/${group.collectionGroup}`,
        toCorporatePortal: 'billing/accounts/collectionGroup/edit',
        toCorporatePortalArgs: {},
        useB2XLink: true,
        analyticsEvent: mobile.viewBillingAccount
      })
  },
  {
    key: 'edit',
    labelI18nKey: 'billingInformation.list.quicklinks.edit',
    enabledWhen: editBillingEnabledWhen,
    onClick: ({ group, goToEditBillingGroupPage }) =>
      goToEditBillingGroupPage(group.collectionGroup, group.customerCode),
    isHidden: (group) => getChangeBillingInfoDisabled(group.type.changeability),
    disabled: isDemoEnv
  },
  {
    key: 'copy',
    labelI18nKey: 'billingInformation.copyBillingAccount',
    enabledWhen: editBillingEnabledWhen,
    onClick: ({ group, goToCopyBillingGroupPage }) =>
      goToCopyBillingGroupPage(group.collectionGroup, group.customerCode),
    isHidden: (group) => !group.canCopy,
    disabled: isDemoEnv
  }
];

const BillingGroupQuicklinksMenu: React.FC<{ group: CollectionGroup }> = ({ group }) => {
  const { openMenu, closeMenus, toggleMenu } = useOverflowMenuState();
  const isOpen = openMenu === group.collectionGroup;

  return (
    <Memo comparables={['isOpen']}>
      <BillingGroupQuicklinksMenuInner
        group={group}
        isOpen={isOpen}
        closeMenus={closeMenus}
        toggleMenu={toggleMenu}
      />
    </Memo>
  );
};

const BillingGroupQuicklinksMenuInner: React.FC<{
  group: CollectionGroup;
  isOpen: boolean;
  closeMenus: VoidFunction;
  toggleMenu: (id: string) => void;
}> = ({ group, isOpen, closeMenus, toggleMenu }) => {
  const { t } = useTranslation();
  const { goto } = useRouting();
  const { goToEditBillingGroupPage, goToCopyBillingGroupPage } = useBillingGroupsHelper();
  const menuContainer = document.querySelector(
    `[data-quicklink-container="billing-account-${group.collectionGroup}"] > a`
  );

  return (
    <OverflowMenuToggle
      menuContainer={menuContainer}
      menuId={group.collectionGroup}
      isOpen={isOpen}
      closeMenus={closeMenus}
      toggleMenu={toggleMenu}
    >
      {isOpen && (
        <OverflowMenu>
          {ITEMS.filter((item) => (item.isHidden ? !item.isHidden(group) : true)).map((item) => (
            <OverflowMenu.Item
              key={item.key}
              dataTestid={`overflow-menu-item-${item.key}`}
              label={t(item.labelI18nKey)}
              enabledWhen={item.enabledWhen}
              disabled={item.disabled}
              onClick={() =>
                item.onClick({ group, goto, goToEditBillingGroupPage, goToCopyBillingGroupPage })
              }
            />
          ))}
        </OverflowMenu>
      )}
    </OverflowMenuToggle>
  );
};

export default BillingGroupQuicklinksMenu;
