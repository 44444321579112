import {
  BillingGroupFormStep,
  UseBillingGroupStepsResult
} from 'B2XApp/Invoicing/BillingGroups/BillingGroupForm/useBillingGroupForm';
import { useEffect, useState } from 'react';
import { CollectionGroup, CollectionGroupApiStatus } from 'types/collection-group';

const useCopyBillingGroupSteps = ({
  collectionGroup,
  copyApiStatus: updateApiStatus
}: {
  collectionGroup: CollectionGroup;
  copyApiStatus: CollectionGroupApiStatus;
}): UseBillingGroupStepsResult => {
  const [step, setStep] = useState<BillingGroupFormStep>(() =>
    !collectionGroup.canCopy ? 'unavailable' : 'initial'
  );

  useEffect(() => {
    if (['persisting', 'success', 'partial-success', 'failure'].includes(updateApiStatus)) {
      setStep(updateApiStatus);
    }
  }, [updateApiStatus, setStep]);

  return {
    step,
    setStep
  };
};

export default useCopyBillingGroupSteps;
