import { OptionListOption } from '@gaiads/telia-react-component-library/build/types/components/OptionList/OptionList';
import { ServiceRequestOrigin } from 'B2XApp/Messages/ServiceRequests/NewServiceRequest/useServiceRequestOrigin';
import { RelatedRecord } from 'B2XApp/Messages/ServiceRequests/NewServiceRequest/useServiceRequestRelatedRecord';

interface ServiceRequestField {
  disabled?: boolean;
  required?: boolean;
  hidden?: boolean;
  initialValue?: string;
  options?: OptionListOption[];
}

interface CompanyServiceRequestField extends ServiceRequestField {
  groupType?: ActiveGroupType;
}

export type ServiceRequestFields = {
  subscriptionNumber: ServiceRequestField;
  invoiceNumber: ServiceRequestField;
  company: CompanyServiceRequestField;
};

const getRecordsOriginFields = (record: RelatedRecord): ServiceRequestFields => {
  if (record.type === 'invoice') {
    return {
      subscriptionNumber: { required: false },
      invoiceNumber: {
        required: true,
        disabled: true,
        initialValue: record.invoiceNumber
      },
      company: { disabled: true, initialValue: record.groupId, groupType: record.groupType }
    };
  }

  if (record.type === 'order') {
    return {
      subscriptionNumber: { required: true, disabled: true, initialValue: record.orderNumber },
      invoiceNumber: { required: false, hidden: true },
      company: { disabled: true, initialValue: record.groupId, groupType: record.groupType }
    };
  }

  return {
    subscriptionNumber: {
      required: true,
      disabled: true,
      initialValue: record.subscriptionNumber
    },
    invoiceNumber: { required: false },
    company: { disabled: true, initialValue: record.groupId, groupType: record.groupType }
  };
};

const VALID_SERVICE_REQUEST_ORIGINS = ['b2b', 'b2b-b2o', 'billing-info-mass-change'];
const SERVICE_REQUEST_FIELDS = {
  subscriptionNumber: { required: false },
  invoiceNumber: { required: false },
  company: { disabled: false }
};

export default (origin: ServiceRequestOrigin, record?: RelatedRecord): ServiceRequestFields => {
  if (record) {
    return getRecordsOriginFields(record);
  }

  if (origin.type && VALID_SERVICE_REQUEST_ORIGINS.includes(origin.type)) {
    return SERVICE_REQUEST_FIELDS;
  }

  throw new Error('Unhandled service request origin.');
};
