import { getCookie } from 'doings/getCookie/getCookie';
import { LoginStatusType } from 'hooks/useUser/useUser';
import { B2XCookie } from 'types/clientCookie';

import { AnalyticsEvent, userSession } from './analyticsEvents';
import trackEvent from './trackEvent';

type LastSeenAttributes = {
  analyticsSessionId?: string;
  userId?: number;
  activeCompanyId?: string;
};

export const STORAGE_KEY = 'b2xPortal::lastSeenAttributes';

const retrieveLastSeenAttributes = (): LastSeenAttributes => {
  return JSON.parse(window.localStorage.getItem(STORAGE_KEY) ?? '{}');
};

const saveLastSeenAttributes = (attributes: LastSeenAttributes) => {
  window.localStorage.setItem(STORAGE_KEY, JSON.stringify(attributes));
};

/**
 * Tracks user login and active company changes for a logged-in user.
 *
 * An analytics session ID is generated in the server upon the user's
 * first authorisation when a user navigates to B2X portal. Uses that
 * ID to determine whether we're dealing with the user having just
 * logged in, or we're dealing with page refreshes and navigation
 * between B2X portal and other Telia applications.
 *
 * If the cookie is missing at this point, the user has either blocked
 * or disabled cookies in one's browser, and we cannot be certain
 * whether the user just logged in or continues an existing session.
 * Refrain from further tracking of user session events.
 *
 * @param status The user's login status.
 * @param user A logged-in user's data from the GraphQL query response.
 */
export const trackUserSessionEvents = (status: LoginStatusType, user?: User) => {
  if (status !== 'logged in') {
    return;
  }

  const currSessionId = getCookie(B2XCookie.ANALYTICS_SESSION_ID);
  if (!currSessionId) {
    return;
  }

  if (!user) {
    return;
  }

  trackLogin(user, currSessionId);
  trackActiveCompanyChange(user);
};

const trackLogin = (user: User, currSessionId: string) => {
  const attributes = retrieveLastSeenAttributes();
  const lastUlmUserId = attributes.userId;
  const lastSessionId = attributes.analyticsSessionId;
  const currUlmUserId = user.ulmUserId;

  if (currUlmUserId !== lastUlmUserId || currSessionId !== lastSessionId) {
    saveLastSeenAttributes({
      analyticsSessionId: currSessionId,
      userId: currUlmUserId,
      activeCompanyId: user.activeCompanyAidaId
    });

    trackUserSessionEvent(userSession.loginSuccessful, user);
  } else {
    trackUserSessionEvent(userSession.sessionContinued, user);
  }
};

const trackActiveCompanyChange = (user: User) => {
  const attributes = retrieveLastSeenAttributes();
  const lastCompanyId = attributes.activeCompanyId;
  const currCompanyId = user.activeCompanyAidaId;

  if (!!currCompanyId && currCompanyId !== lastCompanyId) {
    saveLastSeenAttributes({
      analyticsSessionId: attributes.analyticsSessionId,
      userId: user.ulmUserId,
      activeCompanyId: user.activeCompanyAidaId
    });

    trackUserSessionEvent(userSession.activeCompanyChanged, user);
  }
};

const trackUserSessionEvent = (baseEvent: AnalyticsEvent, user: User) => {
  const { businessSegment, activeCompanyAidaId, ulmUserId, ulmUserIdForBC } = user;
  trackEvent({
    ...baseEvent,
    BusinessSegment: businessSegment,
    CompanyID: activeCompanyAidaId,
    ULMUserID: ulmUserId,
    ULMUserIDForBC: ulmUserIdForBC !== '<unknown>' ? ulmUserIdForBC : undefined
  } as AnalyticsEvent);
};
