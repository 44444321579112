import { useFeatureFlagsData } from 'contexts/FeatureFlagContext/FeatureFlagContext';
import { useUserData } from 'contexts/UserContext/UserContext';
import { isWholesaleBusiness } from 'doings/isWholesaleBusiness/isWholesaleBusiness';

const useIsAskForHelpAvailable = () => {
  const { hasFeature } = useFeatureFlagsData();
  const user = useUserData();
  const isB2OCompany = isWholesaleBusiness(user);

  return {
    askForHelpAvailable: !isB2OCompany && !user.impersonatedBy && hasFeature('EART-4898')
  };
};

export default useIsAskForHelpAvailable;
