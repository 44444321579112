import { ListView } from 'common-components';
import OverflowMenuWrapper from 'common-components/OverflowMenu/OverflowMenuWrapper/OverflowMenuWrapper';
import { useEditableListData } from 'contexts/EditableListContext/EditableListContext';
import { EN_DASH } from 'doings/dash/dash';
import { SortState, getSort } from 'hooks/useSortableHeadings/useSortableHeadings';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { CollectionGroup } from 'types/collection-group';
import { ColumnVisibility, ListViewColumnSetup } from 'types/listConfig';

import BillingGroupQuicklinksMenu from './BillingGroupQuicklinksMenu/BillingGroupQuicklinksMenu';

export enum BillingGroupListSortField {
  AddressSupplement = 'addressSupplement',
  CollectionGroup = 'collectionGroup',
  Name = 'name',
  Reference = 'reference',
  BillingAddress = 'billingAddress',
  BillingType = 'type.displayedDeliveryMethod'
}

export const BillingGroupListViewColumnSetup: ListViewColumnSetup<
  [TFunction, SortState, (field: string) => void]
> = {
  listId: 'billingAccounts',
  getColumns: (t: TFunction, sortState: SortState, sortClick: (field: string) => void) => ({
    billingAccountId: {
      width: '0.75x',
      heading: t('billingInformation.collectionGroup'),
      testidSuffix: 'billing-account',
      sort: getSort(sortState, sortClick, BillingGroupListSortField.CollectionGroup),
      baseVisibility: ColumnVisibility.REQUIRE
    },
    payerName: {
      heading: t('billingInformation.payerName'),
      testidSuffix: 'payer-name',
      sort: getSort(sortState, sortClick, BillingGroupListSortField.Name),
      baseVisibility: ColumnVisibility.SHOW
    },
    reference: {
      heading: t('billingInformation.billingReferenceListView'),
      testidSuffix: 'reference',
      sort: getSort(sortState, sortClick, BillingGroupListSortField.Reference),
      baseVisibility: ColumnVisibility.SHOW
    },
    billingAddress: {
      width: '1.5x',
      heading: t('billingInformation.billingAddress'),
      testidSuffix: 'billing-address',
      sort: getSort(sortState, sortClick, BillingGroupListSortField.BillingAddress),
      baseVisibility: ColumnVisibility.SHOW
    },
    addressSupplement: {
      heading: t('billingInformation.addressSupplement'),
      testidSuffix: 'address-supplement',
      sort: getSort(sortState, sortClick, BillingGroupListSortField.AddressSupplement),
      baseVisibility: ColumnVisibility.HIDE
    },
    billingType: {
      width: '0.75x',
      heading: t('billingInformation.billingMethod'),
      testidSuffix: 'billing-type',
      sort: getSort(sortState, sortClick, BillingGroupListSortField.BillingType),
      baseVisibility: ColumnVisibility.SHOW
    }
  })
};

const BillingGroupListView: React.FC<{
  groups: CollectionGroup[];
  loading: boolean;
}> = ({ groups, loading }) => {
  const { t } = useTranslation();
  const { columns } = useEditableListData();

  const linkTo = (collectionGroup: string) => `/billing-accounts/${collectionGroup}`;

  return (
    <OverflowMenuWrapper>
      <ListView
        columns={columns}
        mobileLayout={{
          titleRow: { col: 'billingAccountId' },
          rows: [
            { col: 'payerName' },
            { col: 'reference' },
            { col: 'billingAddress' },
            { col: 'addressSupplement' },
            { col: 'billingType' }
          ]
        }}
        loading={loading}
        listData={groups}
        noItemsNotice={t('billingGroups.noItemsFound')}
        data-testid={`billing-group-list-${loading ? 'loading' : 'loaded'}`}
        usesQuicklinks
      >
        {(group) => (
          <ListView.Row
            data-testid={`billing-group-list-row-item-${group.collectionGroup}`}
            data-quicklink-container={`billing-account-${group.collectionGroup}`}
            linkTo={linkTo(group.collectionGroup)}
            quicklinksMenu={<BillingGroupQuicklinksMenu group={group} />}
          >
            <ListView.RowShape
              cells={{
                billingAccountId: {
                  data: group.collectionGroup,
                  props: {}
                },
                payerName: {
                  data: group.name
                },
                reference: {
                  data: group.reference || EN_DASH,
                  props: {}
                },
                billingAddress: {
                  data: group.billingAddress,
                  props: {}
                },
                addressSupplement: {
                  data: group.addressSupplement || EN_DASH,
                  props: {}
                },
                billingType: {
                  data: group.type.displayedDeliveryMethod,
                  props: {}
                }
              }}
            />
          </ListView.Row>
        )}
      </ListView>
    </OverflowMenuWrapper>
  );
};

export default BillingGroupListView;
