import { BodyText } from '@gaiads/telia-react-component-library';
import { Accordion, Skeleton } from 'common-components';
import { devices } from 'doings/track/analyticsEvents';
import { useTranslation } from 'react-i18next';
import { Device } from 'types/device';

import ServiceList from './ServiceList';

const AdditionalServices: React.FC<{
  device?: Device;
  loading: boolean;
}> = ({ device, loading }) => {
  const { t } = useTranslation();
  const services = (device?.additionalServices ?? []).filter(
    ({ id, productName }) => !!id && !!productName
  );

  if (loading) {
    return <Skeleton.Accordion data-testid="additional-services-skeleton" />;
  }

  return (
    <Accordion
      data-testid="device-details-extra-services-accordion"
      className="additional-services-accordion"
      heading={t('daas.details.additionalServices', { count: services.length })}
      analyticsEvent={devices.viewAdditionalServices}
    >
      {services.length ? (
        <ServiceList services={services} />
      ) : (
        <BodyText>{t('daas.details.noAttachedAdditionalServices')}</BodyText>
      )}
    </Accordion>
  );
};

export default AdditionalServices;
