import {
  featureFlagsQuery,
  toggleFeatureFlagMutation
} from 'hooks/featureFlags/featureFlagQueries';
import useCallBackendMutation from 'hooks/useCallBackend/useCallBackendMutation';

type ToggleFeatureFlagResponse = {
  toggleFeatureFlag: boolean;
};

const useToggleFeatureFlag = () => {
  const { runMutation, data, loading, error } = useCallBackendMutation<ToggleFeatureFlagResponse>(
    toggleFeatureFlagMutation,
    [
      {
        query: featureFlagsQuery
      }
    ]
  );

  const toggleFeatureFlag = (name: string) => {
    runMutation({
      variables: {
        name
      }
    });
  };

  return {
    toggleFeatureFlag,
    data,
    loading,
    error
  };
};

export default useToggleFeatureFlag;
