import { gql } from '@apollo/client';
import { useUserData } from 'contexts/UserContext/UserContext';
import useCallBackendMutation from 'hooks/useCallBackend/useCallBackendMutation';
import { ProductNewsVote } from 'types/productNews';

import { productNewsQuery } from './useProductNews';

const mutation = gql`
  mutation updateProductNewsRating($input: UpdateProductNewsRatingInput!) {
    updateProductNewsRating(input: $input)
  }
`;

interface UpdateRatingInput {
  id: string;
  rating: ProductNewsVote | null;
}

const useProductNewsRating = () => {
  const user = useUserData();
  const { runMutation, error, loading } = useCallBackendMutation(mutation, [
    { query: productNewsQuery, variables: { lang: user.language } }
  ]);

  const updateRating = async ({ id, rating }: UpdateRatingInput) => {
    await runMutation({ variables: { input: { id, rating } } });
  };

  return { updateRating, loading, error };
};

export { useProductNewsRating };
