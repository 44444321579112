import { Page } from '@gaiads/telia-react-component-library';
import routeAccessGroups from 'B2XApp/appRouteAccessGroups';
import { FullPageLoader, ListView, PageWithSubnavigation } from 'common-components';
import EmptyListContent from 'common-components/EmptyListContent/EmptyListContent';
import ListHeader from 'common-components/ListHeader/ListHeader';
import { useUserData } from 'contexts/UserContext/UserContext';
import { Div } from 'core-components';
import { isSameMonth } from 'date-fns';
import userHasPermission from 'doings/userHasPermission/userHasPermission';
import useActivityLog from 'hooks/activityLog/useActivityLog';
import { useActivityLogFilters } from 'hooks/activityLog/useActivityLogFilters';
import { ViewStatus } from 'hooks/useApiStatus/useApiStatus';
import useInitialLoadStatus from 'hooks/useInitialLoadStatus/useInitialLoadStatus';
import useIsSmallerBreakpointActive from 'hooks/useIsSmallerBreakpointActive/useIsSmallerBreakpointActive';
import useUserHasMultipleCompaniesWithGivenPermission from 'hooks/useUser/useUserHasMultipleCompaniesWithGivenPermission';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityLogEntry } from 'types/activityLog';

import ActivityLogItem from './ActivityLogItem';
import styles from './ActivityLogPage.module.scss';
import EntrySeparatorLabel from './EntrySeparatorLabel';
import ShowMoreActivities from './ShowMoreActivities';

const ActivityLogPage: React.FC = () => {
  const { t } = useTranslation();

  const user = useUserData();
  const isActivityLogAdmin = userHasPermission(user)({ onePermission: 'ACTIVITY_LOG_ADMIN' });

  const multipleCompanySearchEnabled = useUserHasMultipleCompaniesWithGivenPermission(
    routeAccessGroups.ACTIVITY_LOG
  );

  const {
    queryParameters,
    queryParametersAbsent,
    defaultDateFromPlaceholder,
    defaultDateToPlaceholder,
    allCompanySearch
  } = useActivityLogFilters(multipleCompanySearchEnabled, isActivityLogAdmin);

  const { activityLogEntries, total, error, fetchMore, apiState } = useActivityLog(
    queryParameters,
    defaultDateFromPlaceholder,
    defaultDateToPlaceholder,
    allCompanySearch.showFromAllCompanies
  );

  const isMobileView = useIsSmallerBreakpointActive('lg');

  const needsToShowSeparator = (previousItemDate: string, date: string) =>
    !isSameMonth(new Date(previousItemDate), new Date(date));

  const status = useInitialLoadStatus(apiState);
  const hasItems = !!activityLogEntries.length || !!error;
  const [cardsExpanded, setCardsExpanded] = useState(0);
  const allExpanded = cardsExpanded !== 0 && cardsExpanded === total;

  const onExpandAll = () => {
    if (total && cardsExpanded !== total) {
      setCardsExpanded(total);
    }
    if (allExpanded) {
      setCardsExpanded(0);
    }
  };

  const expandCard = () => setCardsExpanded(cardsExpanded + 1);
  const collapseCard = () => setCardsExpanded(cardsExpanded - 1);

  return (
    <PageWithSubnavigation
      loading={status === ViewStatus.LoadingInitial}
      error={error}
      emptyContentPlaceholder={
        !hasItems &&
        queryParametersAbsent &&
        (!multipleCompanySearchEnabled || allCompanySearch.showFromAllCompanies) &&
        status !== ViewStatus.Loading && (
          <EmptyListContent
            title={t('activityLog.emptyList.title')}
            description={t('activityLog.emptyList.description')}
          />
        )
      }
      showSubnavigationForCurrentPath
    >
      <ListHeader
        withMultiCompanyFeature={multipleCompanySearchEnabled}
        multipleCompaniesCheckboxParams={{
          checked: allCompanySearch.showFromAllCompanies,
          label: t('activityLog.filters.allCompaniesLabel'),
          testId: 'activitylog-toggle-all-companies',
          onChange: allCompanySearch.toggleAllCompanies
        }}
        withSearchActivationThreshold
        dateRangeParams={{
          label: t('tickets.list.filters.dateRange'),
          helperText: t('common.filterSettings.dateHelperText'),
          helperTooltip: t('serviceRequests.list.dateFilterDefaultRangeTooltip'),
          withDefaultDatePlaceholder: true,
          'data-testid': 'activity-log-date-filter'
        }}
        searchScopeOptions={[
          { value: 'invoice', label: t('activityLog.searchOptions.invoiceNumber') },
          { value: 'subscription', label: t('activityLog.searchOptions.subscriptionNumber') },
          { value: 'subject', label: t('activityLog.searchOptions.subject') }
        ]}
        typeFilterParams={{
          noNoneOption: true,
          label: t('activityLog.filters.type.label'),
          options: [
            {
              value: '',
              label: t('activityLog.filters.type.all')
            },
            {
              value: 'calls',
              label: t('activityLog.filters.type.calls')
            },
            {
              value: 'chats',
              label: t('activityLog.filters.type.chats')
            }
          ],
          'data-testid': 'subscription-types-dropdown'
        }}
        customFilterParams={
          isActivityLogAdmin
            ? [
                {
                  noNoneOption: true,
                  label: t('activityLog.filters.user.label'),
                  param: 'allUsers',
                  'data-testid': 'activity-log-user-filter',
                  options: [
                    {
                      value: '',
                      label: t('activityLog.filters.user.all')
                    },
                    {
                      value: 'false',
                      label: t('activityLog.filters.user.myActivity')
                    }
                  ]
                }
              ]
            : undefined
        }
        labels={{
          searchInMobileView: t('subscriptions.searchSubscriptions'),
          sortInMobileView: t('subscriptions.sortSubscriptions')
        }}
      >
        {t('activityLog.title')}
      </ListHeader>

      <FullPageLoader loading={status === ViewStatus.Loading}>
        {!(status === ViewStatus.Loading || status === ViewStatus.LoadingInitial) &&
          activityLogEntries.length === 0 && (
            <Page.Row margin={isMobileView ? { top: 'md' } : null}>
              <ListView.NoItemsNotice message={t('activityLog.noItemsFound')} />
            </Page.Row>
          )}

        {activityLogEntries && (
          <Page.Row className={styles.sidebar}>
            <Div className={styles.activityLog} data-testid="activity-log">
              {activityLogEntries.map(
                (entry: ActivityLogEntry, index: number, items: ActivityLogEntry[]) => {
                  const shouldRenderSeparator =
                    index === 0
                      ? true
                      : needsToShowSeparator(items[index - 1].creationDate, entry.creationDate);

                  return (
                    <React.Fragment key={`activity-log-entry--${entry.id}`}>
                      {shouldRenderSeparator && (
                        <EntrySeparatorLabel
                          entry={entry}
                          allExpanded={allExpanded}
                          onExpandAll={onExpandAll}
                          showExpandAllCheckbox={index === 0}
                        />
                      )}

                      <ActivityLogItem
                        entry={entry}
                        allExpanded={allExpanded}
                        cardsExpanded={cardsExpanded}
                        onHandleExpanded={(expand) => (expand ? expandCard() : collapseCard())}
                        showContactName={queryParameters?.allUsers}
                        showCompanyName={allCompanySearch.showFromAllCompanies}
                      />
                    </React.Fragment>
                  );
                }
              )}

              <ShowMoreActivities
                showMore
                loading={status === ViewStatus.LoadingMore}
                hasMoreItems={activityLogEntries.length < (total || 0)}
                fetchMore={fetchMore}
              />
            </Div>
          </Page.Row>
        )}
      </FullPageLoader>
    </PageWithSubnavigation>
  );
};

export default ActivityLogPage;
