import { SortedChecklistItem } from 'common-components/SortableCheckboxes/SortableCheckboxesProvider';
import { modals } from 'doings/track/analyticsEvents';
import trackEvent from 'doings/track/trackEvent';
import useResetEditableList from 'hooks/editableLists/useResetEditableList';
import useUpdateEditableList from 'hooks/editableLists/useUpdateEditableList';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react';
import { ColumnVisibility, EditableColumns, EditableListId } from 'types/listConfig';

export const EditableListModalContext = createContext<EditableListModalData | undefined>(undefined);

export type EditableListModalProps = {
  /** List view's identifier supported and expected in the API. */
  readonly apiId: EditableListId;
  /** Named columns → configuration. */
  readonly columns: EditableColumns;
  /** Function called to close the modal. */
  readonly onClose: (guarded?: boolean) => void;
  readonly children: React.ReactNode;
};

export type EditableListModalData = {
  readonly items: SortedChecklistItem[];
  readonly itemCount: number;
  readonly busy: boolean;
  readonly actions: {
    readonly errorMessage?: ModalState['error'];
    readonly updateColumns: (columnsInOrder: SortedChecklistItem[]) => void;
    readonly resetColumns: VoidFunction;
    readonly close: VoidFunction;
  };
};

export enum ModalActionType {
  UPDATE,
  UPDATE_IN_PROGRESS,
  UPDATE_DONE,
  UPDATE_FAILED,
  RESET,
  RESET_IN_PROGRESS,
  RESET_DONE,
  RESET_FAILED
}

export type ModalAction =
  | {
      type: Exclude<ModalActionType, ModalActionType.UPDATE>;
    }
  | {
      type: ModalActionType.UPDATE;
      items: SortedChecklistItem[];
    };

type ModalState = {
  status: 'idle' | 'updatePending' | 'updating' | 'resetPending' | 'resetting' | 'closing';
  items?: SortedChecklistItem[];
  error?: 'update' | 'reset';
};

const EditableListModalProvider: React.FC<EditableListModalProps> = ({
  apiId,
  columns,
  onClose,
  children
}) => {
  const [itemsInOrder] = useState<SortedChecklistItem[]>(() =>
    Object.entries(columns).map(([id, column]) => ({
      id,
      enabled: column.baseVisibility !== ColumnVisibility.HIDE,
      label: column.heading,
      isRequired: column.baseVisibility === ColumnVisibility.REQUIRE
    }))
  );

  const updateHook = useUpdateEditableList(apiId);
  const resetHook = useResetEditableList(apiId);
  const [state, dispatch] = useReducer(
    (_: ModalState, action: ModalAction): ModalState => {
      switch (action.type) {
        case ModalActionType.UPDATE:
          return { status: 'updatePending', items: action.items };

        case ModalActionType.UPDATE_IN_PROGRESS:
          return { status: 'updating' };

        case ModalActionType.UPDATE_DONE:
          return { status: 'closing' };

        case ModalActionType.UPDATE_FAILED:
          return { status: 'idle', error: 'update' };

        case ModalActionType.RESET:
          return { status: 'resetPending' };

        case ModalActionType.RESET_IN_PROGRESS:
          return { status: 'resetting' };

        case ModalActionType.RESET_DONE:
          return { status: 'closing' };

        case ModalActionType.RESET_FAILED:
          return { status: 'idle', error: 'reset' };
      }
    },
    { status: 'idle', items: undefined }
  );

  useEffect(() => {
    if (state.status === 'updatePending' && !updateHook.updating) {
      trackEvent(modals.listConfigSave);
      updateHook.updateEditableList(state.items || []);
      dispatch({ type: ModalActionType.UPDATE_IN_PROGRESS });
    } else if (state.status === 'updating' && updateHook.error) {
      trackEvent(modals.listConfigSaveFailure);
      dispatch({ type: ModalActionType.UPDATE_FAILED });
    } else if (state.status === 'updating' && !updateHook.updating) {
      trackEvent(modals.listConfigSaveSuccess);
      dispatch({ type: ModalActionType.UPDATE_DONE });
    } else if (state.status === 'resetPending' && !resetHook.resetting) {
      trackEvent(modals.listConfigReset);
      resetHook.resetEditableList();
      dispatch({ type: ModalActionType.RESET_IN_PROGRESS });
    } else if (state.status === 'resetting' && resetHook.error) {
      trackEvent(modals.listConfigResetFailure);
      dispatch({ type: ModalActionType.RESET_FAILED });
    } else if (state.status === 'resetting' && !resetHook.resetting) {
      trackEvent(modals.listConfigResetSuccess);
      dispatch({ type: ModalActionType.RESET_DONE });
    } else if (state.status === 'closing') {
      onClose(false);
    }
  }, [state, onClose, updateHook, resetHook]);

  const updateColumns = useCallback((items: SortedChecklistItem[]) => {
    dispatch({ type: ModalActionType.UPDATE, items });
  }, []);

  const resetColumns = useCallback(() => {
    dispatch({ type: ModalActionType.RESET });
  }, []);

  return (
    <EditableListModalContext.Provider
      value={{
        items: itemsInOrder,
        itemCount: itemsInOrder.length,
        busy: state.status !== 'idle',
        actions: {
          errorMessage: state.error,
          updateColumns,
          resetColumns,
          close: () => onClose(true)
        }
      }}
    >
      {children}
    </EditableListModalContext.Provider>
  );
};

const useEditableListModal = () => {
  const context = useContext(EditableListModalContext);
  if (!context) {
    throw new Error('useEditableListModal must be used with EditableListModalContext');
  }

  return context;
};

export { EditableListModalProvider, useEditableListModal };
