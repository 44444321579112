import { List } from '@gaiads/telia-react-component-library';
import { PaddingProps } from '@gaiads/telia-react-component-library/build/types/components/Element/getPaddingClasses';
import ExternalLink from 'common-components/Link/ExternalLink/ExternalLink';
import Link from 'common-components/Link/Link';
import chainEvents from 'doings/events/chainEvents/chainEvents';
import enableTextSelectionInLink from 'doings/events/enableTextSelectionInLink/enableTextSelectionInLink';
import { multiplex } from 'doings/multiplex/multiplex';
import useSearchParams from 'hooks/useSearchParams/useSearchParams';

import styles from './ListViewRow.module.scss';
import ListViewRowContent from './ListViewRowContent';

export type SublistProps = {
  component: JSX.Element;
  visible?: boolean;
};

export type ListViewRowProps = {
  'data-testid'?: string;
  'data-quicklink-container'?: string;
  loading?: boolean;
  linkTo?: string;
  external?: boolean;
  onClick?: VoidFunction;
  transparent?: boolean;
  padding?: PaddingProps;
  quicklinksMenu?: React.ReactNode;
  highlighted?: boolean;
  hideArrow?: boolean;
  usesExpandableArrow?: boolean;
  usesQuicklinks?: boolean;
  children: React.ReactNode;
  listType?: 'normal' | 'sublist';
  useChildPadding?: boolean;
  sublistProps?: SublistProps;
  highlightLabel?: string;
};

const getListRowPadding = (transparent: boolean, useChildPadding?: boolean): PaddingProps => {
  return { horizontal: 'md', vertical: transparent || useChildPadding ? 'zero' : 'sm' };
};

const ListViewRow: React.FC<ListViewRowProps> = ({
  'data-testid': dataTestid,
  'data-quicklink-container': dataQuicklinkContainer,
  linkTo,
  external,
  onClick,
  transparent = false,
  listType,
  useChildPadding,
  padding = getListRowPadding(transparent, useChildPadding),
  hideArrow = false,
  usesExpandableArrow: useExpandableArrow,
  loading = false,
  quicklinksMenu,
  usesQuicklinks = !!quicklinksMenu,
  highlighted = false,
  children,
  sublistProps,
  highlightLabel
}) => {
  const { createPathWithState } = useSearchParams();
  const RowContent = () => (
    <>
      {!!highlightLabel && (
        <div data-testid="list-view-row-highlight" className={styles.row_ribbon}>
          {highlightLabel}
        </div>
      )}

      <ListViewRowContent
        transparent={transparent}
        padding={padding}
        hideArrow={hideArrow}
        usesExpandableArrow={useExpandableArrow}
        isExpanded={sublistProps?.visible}
        clickableAppearance={!loading && (!!linkTo || !!onClick)}
        quicklinksMenu={quicklinksMenu}
        usesQuicklinks={usesQuicklinks}
        loading={loading}
        listType={listType}
        highlighted={highlighted}
        data-testid="list-view-row-content"
      >
        {children}
      </ListViewRowContent>
    </>
  );

  const renderRowContents = () => {
    if (loading) {
      return <RowContent />;
    }

    if (linkTo && !external) {
      return (
        <Link
          className={styles.row}
          to={createPathWithState(linkTo)}
          external={external}
          allowTextSelection
        >
          <RowContent />
        </Link>
      );
    } else if (linkTo && external) {
      return (
        <ExternalLink
          className={styles.row}
          to={linkTo}
          color="black"
          hoverColor="black"
          allowTextSelection
        >
          <RowContent />
        </ExternalLink>
      );
    } else if (onClick) {
      return (
        <div
          className={styles.row}
          onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            chainEvents([enableTextSelectionInLink, onClick])(e);
          }}
          tabIndex={0}
          onKeyUp={(e: React.KeyboardEvent<HTMLDivElement>) => {
            e.key === 'Enter' && onClick();
          }}
        >
          <RowContent />
        </div>
      );
    } else {
      return <RowContent />;
    }
  };

  // A `li` element cannot directly contain another `li` element, so need to
  // render a div to wrap sublist contents
  if (listType === 'sublist') {
    return <div>{renderRowContents()}</div>;
  }

  return (
    <>
      <List.Item
        margin={sublistProps?.visible ? { bottom: 'zero' } : undefined}
        data-quicklink-container={dataQuicklinkContainer}
        data-testid={multiplex([
          'list-view-content-row',
          [!!dataTestid, dataTestid],
          [transparent, 'list-view-header-row']
        ])}
      >
        {renderRowContents()}
      </List.Item>

      {sublistProps?.visible && sublistProps.component}
    </>
  );
};

export default ListViewRow;
