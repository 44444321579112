import { isWeekend, subDays } from 'date-fns';
import isHoliday from 'doings/isHoliday/isHoliday';

export default (date: Date): Date => {
  if (isBusinessDay(date)) {
    return date;
  }

  let lastBusinessDay: Date = date;
  while (!isBusinessDay(lastBusinessDay)) {
    lastBusinessDay = subDays(lastBusinessDay, 1);
  }

  return lastBusinessDay;
};

const isBusinessDay = (date: Date): boolean => !isHoliday(date) && !isWeekend(date);
