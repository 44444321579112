import useBillingGroups from 'hooks/invoicing/billingGroups/useBillingGroups/useBillingGroups';

import BillingGroupPage from './BillingGroupPage';
import { useBillingGroupListFilter } from './useBillingGroupListFilter';

const BillingGroups: React.FC = () => {
  const filters = useBillingGroupListFilter();

  const { loading, billingGroups, error, loadingInitial } = useBillingGroups(
    filters.queryParameters
  );

  return (
    <BillingGroupPage
      groups={billingGroups}
      filters={filters}
      loading={loading}
      loadingInitial={loadingInitial}
      error={error}
    />
  );
};

export default BillingGroups;
