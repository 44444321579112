import ModelDropdown from 'common-components/ModelDropdown/ModelDropdown';
import { noOp } from 'doings/noOp/noOp';
import useDropdownInputModel from 'hooks/inputModels/useDropdownInputModel/useDropdownInputModel';
import useWriteQueryParams from 'hooks/useQueryParams/useWriteQueryParams';
import React, { useState } from 'react';
import { DropdownFilterDefinition, RegisterOnClearListenerFn } from 'types/listFilters';

export const DropdownFilter: React.FC<{
  filter: DropdownFilterDefinition;
  initialValue?: string;
  registerOnClearListener: RegisterOnClearListenerFn;
}> = ({ filter: dropdown, initialValue, registerOnClearListener }) => {
  const { write: writeQueryParams } = useWriteQueryParams();
  const [initialNormalizedValue] = useState(
    () =>
      dropdown.options.find((o) => o.value === initialValue)?.value ||
      (dropdown.hasNoneOption ? '' : dropdown.options[0].value)
  );

  const input = useDropdownInputModel({
    excludeNoneSelected: !dropdown.hasNoneOption,
    options: dropdown.options.map((option) => ({
      label: option.label,
      value: option.value
    })),
    initialValue: initialNormalizedValue,
    onChange: (value: string) =>
      writeQueryParams({
        [dropdown.param]: value
      })
  });

  React.useEffect(() => {
    registerOnClearListener(dropdown.param, () => {
      const value = input.options[0].value;
      input.setValue(value);
      return {
        [dropdown.param]: value
      };
    });

    // Does nothing when unmounted, because this component becomes
    // unmounted alongside `ListFilters` which store the on clear
    // listeners.
    /* istanbul ignore next */
    return noOp;
  }, [registerOnClearListener, dropdown.param, dropdown.options, input]);

  return (
    <ModelDropdown
      data-testid={dropdown['data-testid']}
      label={dropdown.label}
      inputModel={input}
    />
  );
};
