import { Gutter } from '@gaiads/telia-react-component-library';

const DetailsPageHeadlineTag: React.FC<{ headlineTag?: React.ReactNode }> = ({ headlineTag }) => {
  if (!headlineTag) {
    return null;
  }

  return (
    <>
      <Gutter size="sm" />

      {headlineTag}
    </>
  );
};

export default DetailsPageHeadlineTag;
