import BillingGroupMassChange from 'B2XApp/Invoicing/BillingGroups/BillingGroupMassChange';
import ButtonLink from 'common-components/Link/ButtonLink/ButtonLink';
import ListViewActionButtons, {
  MoreActionsLinkDescriptor
} from 'common-components/ListViewActions/ListViewActionButtons/ListViewActionButtons';
import { useUserData } from 'contexts/UserContext/UserContext';
import { isSmallBusiness } from 'doings/isSmallBusiness/isSmallBusiness';
import { isWholesaleBusiness } from 'doings/isWholesaleBusiness/isWholesaleBusiness';
import userHasPermission from 'doings/userHasPermission/userHasPermission';
import useCustomModal from 'hooks/useCustomModal/useCustomModal';
import useReadQueryParams from 'hooks/useQueryParams/useReadQueryParams';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showBillingAccountTypeModal } from 'redux/slices/billingAccountTypeSlice';

const BillingGroupActionButtons: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useUserData();
  const shouldLinkToB2BPortal =
    !isSmallBusiness(user) && userHasPermission(user)({ onePermission: 'B2B_PORTAL' });

  const supportsMassChange = !isWholesaleBusiness(user);
  const billingInfoMassChangeModal = useCustomModal('BILLING_INFO_MASS_CHANGE_EXCEL_MODAL');
  const { action } = useReadQueryParams(['action']);
  const [modalOpened, setModalOpened] = useState<boolean>(false);

  useEffect(() => {
    if (
      supportsMassChange &&
      action === 'mass-change' &&
      !billingInfoMassChangeModal.isOpen &&
      !modalOpened
    ) {
      billingInfoMassChangeModal.openModal();
      setModalOpened(true);
    }
  }, [billingInfoMassChangeModal, modalOpened, action, supportsMassChange]);

  const moreActionsLinks: MoreActionsLinkDescriptor[] = [
    {
      key: 'change-company-billing-info-link',
      to: '/service-requests/new?t=billing_info',
      label: t('invoices.billingGroup.list.links.changeCompanyBillingInfo'),
      tooltip: t('invoices.billingGroup.list.links.changeCompanyBillingInfoTooltip'),
      enabledWhen: { onePermission: 'SERVICE_REQUESTS' },
      type: 'internal'
    },
    {
      key: 'change-subscription-billing-info-link',
      to: '/subscriptions',
      toCorporatePortal: 'search/subscriptionSearch',
      useRouterLink: !shouldLinkToB2BPortal,
      label: t('invoices.billingGroup.list.links.changeSubscriptionBillingInfo'),
      tooltip: t('invoices.billingGroup.list.links.changeSubscriptionBillingInfoTooltip'),
      enabledWhen: { onePermission: 'MOBILE_SUBSCRIPTIONS' },
      type: 'portal'
    },
    ...(supportsMassChange
      ? [
          {
            key: 'billing-info-mass-change-link',
            to: '',
            label: t('invoices.billingGroup.list.links.billingInfoMassChangeExcel'),
            tooltip: t('invoices.billingGroup.list.links.billingInfoMassChangeExcelTooltip'),
            type: 'click',
            onClick: billingInfoMassChangeModal.openModal
          } as MoreActionsLinkDescriptor
        ]
      : [])
  ];

  return (
    <>
      <ListViewActionButtons
        mainActionButton={
          <ButtonLink
            data-testid="new-billing-group-link"
            text={t('invoices.billingGroup.list.links.createBillingAccount')}
            fallbackClickAction={() => dispatch(showBillingAccountTypeModal(true))}
            enabledWhen={{ onePermission: 'BILLING' }}
          />
        }
        moreActionsLinks={moreActionsLinks}
      />

      {supportsMassChange && (
        <BillingGroupMassChange
          isOpen={billingInfoMassChangeModal.isOpen}
          onClose={billingInfoMassChangeModal.closeModal}
        />
      )}
    </>
  );
};

export default BillingGroupActionButtons;
