import { ApolloError } from '@apollo/client';
import { get, getOr } from 'lodash/fp';

const isPermissionError = (errorObject?: ApolloError): boolean => {
  const errors = getOr([], 'graphQLErrors', errorObject);

  return errors.some((error) => get(['extensions', 'code'], error) === 'FORBIDDEN');
};

export default isPermissionError;
