import { Div } from '@gaiads/telia-react-component-library';
import ExternalLink from 'common-components/Link/ExternalLink/ExternalLink';
import TextLink from 'common-components/Link/TextLink/TextLink';
import styles from 'common-components/Subnavigation/Subnavigation.module.scss';
import { AnalyticsEvent } from 'doings/track/analyticsEvents';
import { trackEventIfPresent } from 'doings/track/trackEvent';
import { head } from 'lodash/fp';
import { useEffect } from 'react';
import { AuthorizationParams } from 'types/authorization';

export const useScrollToActiveLink = (elementClassName: string, isActiveLink: boolean) => {
  useEffect(() => {
    const element = head(document.getElementsByClassName(elementClassName));
    if (element && isActiveLink) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center'
      });
    }
  }, [isActiveLink, elementClassName]);
};

const SubnavigationItem: React.FC<{
  itemId: string;
  label: string;
  to?: string;
  onClick?: VoidFunction;
  external?: boolean;
  isPathMatch: (paths: string[]) => boolean;
  additionalActiveMatches?: string[];
  enabledWhen?: AuthorizationParams;
  analyticsEvent?: AnalyticsEvent;
  disabled?: boolean;
}> = ({
  itemId,
  label,
  to = '',
  onClick,
  isPathMatch,
  external = false,
  additionalActiveMatches = [],
  enabledWhen,
  analyticsEvent,
  disabled
}) => {
  const elementClassName = `subnavigation-item-${itemId}`;
  const isActiveLink = isPathMatch([...additionalActiveMatches, to]);
  useScrollToActiveLink(elementClassName, isActiveLink);

  return (
    <Div
      className={{
        [elementClassName]: true,
        [styles.subnavigation_link]: true,
        [styles.subnavigation_link__active]: isActiveLink
      }}
      onClick={() => !isActiveLink && trackEventIfPresent(analyticsEvent)}
      data-testid="subnavigation-item"
    >
      {external ? (
        <ExternalLink
          to={to}
          label={label}
          color="black"
          hoverColor="purple500"
          fontWeight="normal"
          singleLine
          enabledWhen={enabledWhen}
          block
          onClick={onClick}
          disabled={disabled}
          data-testid="subnavigation-item-link-external"
        />
      ) : (
        <TextLink
          to={to}
          label={label}
          color="black"
          hoverColor="purple500"
          fontWeight="normal"
          singleLine
          enabledWhen={enabledWhen}
          block
          onClick={onClick}
          disabled={disabled}
          data-testid="subnavigation-item-link-internal"
        />
      )}
    </Div>
  );
};

export default SubnavigationItem;
