import getClassNames from 'classnames';
import getResponsiveHiddenClasses, {
  ResponsiveHiddenProps
} from 'core-components/responsive/responsiveHidden/getResponsiveHiddenClasses';
import getSpacingClasses, { SpacingProps } from 'core-components/spacing/getSpacingClasses';

import styles from './Div.module.scss';

const Div: React.FC<
  SpacingProps &
    ResponsiveHiddenProps & {
      id?: string;
      role?: string;
      className?: string;
      'data-testid'?: string;
      'data-tour-stop'?: string;
      children?: React.ReactNode;
    }
> = ({
  id,
  role,
  children,
  padding,
  margin,
  responsiveHidden,
  className,

  'data-testid': dataTestId,
  'data-tour-stop': dataTourStop
}) => {
  return (
    <div
      id={id}
      role={role}
      data-testid={dataTestId}
      data-tour-stop={dataTourStop}
      className={getClassNames([
        className,
        ...getResponsiveHiddenClasses({ responsiveHidden }).map((className) => styles[className]),
        ...getSpacingClasses({ padding, margin }).map((className) => styles[className])
      ])}
    >
      {children}
    </div>
  );
};

export default Div;
