import { Flex, Gutter } from '@gaiads/telia-react-component-library';
import { Skeleton } from 'common-components';
import { Div } from 'core-components';

const PaymentReminderSettingsSkeleton: React.FC = () => {
  return (
    <Div padding={{ top: 'lg' }}>
      <Skeleton.Title width="50%" />

      <Gutter />

      <Skeleton.TextRow width="50%" />

      <Gutter size="lg" />

      <Skeleton.Title width="40%" />

      <Gutter size="md" />

      <Skeleton.TextRow width="50%" />

      <Gutter size="xs" />

      <Skeleton.TextRow width="50%" />

      <Gutter size="lg" />

      <Skeleton.Title width="40%" />

      <Gutter size="md" />

      <Skeleton.TextRow width="50%" />

      <Gutter size="xs" />

      <Skeleton.TextRow width="50%" />

      <Gutter />

      <Flex centeredVertically>
        <Skeleton.Button width="20%" />

        <Gutter />

        <Skeleton.Button width="20%" />
      </Flex>
    </Div>
  );
};

export default PaymentReminderSettingsSkeleton;
