import { DynamicFormState } from 'B2XApp/DynamicForms/DynamicFormMain';
import { AdditionalTicketInfoSkeleton, AdditionalTicketInformation } from 'B2XApp/Messages/common';
import { TicketAdditionalInfo } from 'B2XApp/Messages/common/AdditionalTicketInfo/AdditionalTicketInformation';
import NewServiceRequestSkeleton from 'B2XApp/Messages/ServiceRequests/NewServiceRequest/NewServiceRequestSkeleton';
import { useUserData } from 'contexts/UserContext/UserContext';
import useCreateTicket from 'hooks/tickets/useCreateTicket/useCreateTicket';
import useCreateTicketSteps from 'hooks/tickets/useCreateTicketSteps/useCreateTicketSteps';
import useProducts from 'hooks/useProducts/useProducts';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import CreateTicketPageContainer from './CreateTicketPageContainer';
import CreateTicketPageSuccess from './CreateTicketPageSuccess';
import { CreateTicketFormValues } from './NewTicketForm/getTicketFormValidationSchema';
import NewTicketFormDynamic, { TicketOrigin } from './NewTicketForm/NewTicketFormDynamic';

const CreateTicket: React.FC<{
  backToLabel?: string;
  backToPath?: string;
  caseOrigin?: TicketOrigin;
  additionalInfo: TicketAdditionalInfo;
}> = ({ caseOrigin, additionalInfo, backToLabel, backToPath }) => {
  const { t } = useTranslation();
  const { createTicket, data, error, loading } = useCreateTicket();
  const { activeGroupType } = useUserData();
  const { data: productList, loading: loadingProductList } = useProducts();
  const { step, setStep, savingStatus } = useCreateTicketSteps(loading, data, error);
  const goToInitialStep = () => setStep('initial');

  const dynamicFormStateRef = useRef<DynamicFormState | undefined>();
  const staticFormStateRef = useRef<CreateTicketFormValues | undefined>();
  if (step === 'confirmed') {
    return (
      <CreateTicketPageSuccess
        backToLabel={backToLabel ?? t('tickets.newTicket.backToMessages')}
        backToPath={backToPath ?? '/tickets'}
      />
    );
  }

  const newTicketForm = (
    <NewTicketFormDynamic
      productList={productList}
      caseOrigin={caseOrigin}
      onSubmit={createTicket}
      dynamicFormStateRef={dynamicFormStateRef}
      staticFormStateRef={staticFormStateRef}
      saving={savingStatus}
      savingReset={goToInitialStep}
      activeGroupType={activeGroupType}
    />
  );

  return (
    <CreateTicketPageContainer
      backLinkVisible
      backLinkTo={backToPath}
      loading={loadingProductList}
      additionalInfo={
        loadingProductList ? (
          <AdditionalTicketInfoSkeleton />
        ) : (
          additionalInfo.rows.length > 0 && (
            <AdditionalTicketInformation additionalInfo={additionalInfo} />
          )
        )
      }
    >
      {loadingProductList ? <NewServiceRequestSkeleton /> : newTicketForm}
    </CreateTicketPageContainer>
  );
};

export default CreateTicket;
