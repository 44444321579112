import DashboardContentSearch from 'B2XApp/Dashboard/DashboardNavigation/DashboardSearch/DashboardContentSearch/DashboardContentSearch';
import DashboardContentSearchResults from 'B2XApp/Dashboard/DashboardNavigation/DashboardSearch/DashboardContentSearch/DashboardContentSearchResults';
import { DashboardSearchProvider } from 'contexts/SearchContext/DashboardSearchContext';

const ContactUsSearch: React.FC = () => (
  <DashboardSearchProvider>
    <DashboardContentSearch
      searchType="helpAndSupport"
      roundedSearchBar
      data-testid="contact-us-search-field"
    />

    <DashboardContentSearchResults data-testid="contact-us-search-content-results" />
  </DashboardSearchProvider>
);

export default ContactUsSearch;
