import FullPageLoader from 'common-components/FullPageLoader/FullPageLoader';
import useFeatureFlags from 'hooks/featureFlags/useFeatureFlags/useFeatureFlags';
import { ViewStatus } from 'hooks/useApiStatus/useApiStatus';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { FeatureFlag } from 'types/featureFlags';

type FeatureSet = Set<string>;
export type FeatureFlagState = {
  featureFlags: FeatureFlag[];
  hasFeature: (feature: string) => boolean;
  showFeaturesUnavailableNotice: boolean;
  hideFeaturesUnavailableNotice?: VoidFunction;
};

export const FeatureFlagContext = createContext<FeatureFlagState | undefined>(undefined);

const NO_CONTEXT_STATE: FeatureFlagState = {
  featureFlags: [],
  hasFeature: () => false,
  showFeaturesUnavailableNotice: true,
  hideFeaturesUnavailableNotice: undefined
};

const FeatureFlagProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isErrorNoticeClosed, setIsErrorNoticeClosed] = useState(false);
  const { featureFlags = [], status } = useFeatureFlags();
  const activeFlags: FeatureSet = useMemo(
    () => new Set(featureFlags.filter((f) => f.active).map((f) => f.name)),
    [featureFlags]
  );

  const hasFeature = useCallback((feature: string) => activeFlags.has(feature), [activeFlags]);

  const showFeaturesUnavailableNotice = !isErrorNoticeClosed && status === ViewStatus.Error;
  const hideFeaturesUnavailableNotice = useCallback(() => {
    setIsErrorNoticeClosed(true);
  }, [setIsErrorNoticeClosed]);

  if (status === ViewStatus.Loading) {
    return <FullPageLoader loading />;
  }

  return (
    <FeatureFlagContext.Provider
      value={{
        featureFlags,
        hasFeature,
        showFeaturesUnavailableNotice,
        hideFeaturesUnavailableNotice
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
};

const useFeatureFlagsData = () => {
  const context = useContext(FeatureFlagContext);
  if (!context) {
    return NO_CONTEXT_STATE;
  }

  return context;
};

export { FeatureFlagProvider, useFeatureFlagsData };
