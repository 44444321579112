import { SectionDefinition } from 'B2XApp/Dashboard/DashboardNavigation/DashboardSections/sections/sectionDefinition';
import { DashboardSectionId } from 'B2XApp/Dashboard/sections';
import { LinkTarget, NavigationalLinks } from 'doings/getNavigationalLinks/getNavigationalLinks';

export const ordersAndRfqsSection: SectionDefinition = {
  id: DashboardSectionId.OrdersAndRfqs,
  icon: 'reverse',
  titleTranslationKey: 'dashboard.sections.titles.Orders',
  getLinks(allLinks: NavigationalLinks) {
    return [allLinks[LinkTarget.ORDERS__B2B_ORDERS]];
  }
};
