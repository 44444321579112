import { DeviceResponse } from 'types/device';

export default (
  { priceWithoutVat, pricePerMonthWithoutVat }: DeviceResponse = {} as DeviceResponse
): boolean => {
  if (!priceWithoutVat && !pricePerMonthWithoutVat) {
    return true;
  }

  return !!priceWithoutVat;
};
