import { Gutter } from '@gaiads/telia-react-component-library';
import { InlineNotification, TeliaLink } from 'common-components';
import { dashboardLinks } from 'doings/track/analyticsEvents';
import { useTriggerableToursQuery } from 'hooks/tours/useTriggerableTours';
import { useHistory } from 'react-router';

const TOUR_ID = 'new-user-welcome';

/**
 * A dashboard notification which allows users who have not seen the new user
 * welcome tour to start it for up to 30 calendar days after they first trigger
 * the tour.
 */
export const DashboardNewUserTourNotice: React.FC = () => {
  const { triggerableTours } = useTriggerableToursQuery();
  const { replace } = useHistory();
  if (!triggerableTours?.includes(TOUR_ID)) {
    return null;
  }

  return (
    <>
      <InlineNotification
        data-testid="new-user-tour-notice"
        variant="information"
        icon="megaphone"
        title={{ key: 'tours.new-user-welcome.dashboardNoticeTitle' }}
        content={{
          key: 'tours.new-user-welcome.dashboardNoticeDescription',
          components: [
            <TeliaLink.Translatable.Click
              key="new-user-tour-link"
              data-testid="new-user-tour-link"
              onClick={() => replace(`/?tour=${TOUR_ID}`)}
              variant="text"
              track={dashboardLinks.presentationTour}
            />
          ]
        }}
      />

      <Gutter size="sm" />
    </>
  );
};
