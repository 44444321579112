import { BodyText, Div } from '@gaiads/telia-react-component-library';
import styles from 'B2XApp/TopNavigation/Navigation/ContentSearch/ContentSearch.module.scss';
import ContentSearchConversation from 'B2XApp/TopNavigation/Navigation/ContentSearch/ContentSearchConversation';
import ContentSearchResultItem from 'B2XApp/TopNavigation/Navigation/ContentSearch/ContentSearchResultItem';
import ContentSearchSectionTitle from 'B2XApp/TopNavigation/Navigation/ContentSearch/ContentSearchSectionTitle';
import getClassNames from 'classnames';
import { useContentSearchState } from 'contexts/SearchContext/ContentSearchContext';
import { useSubmenuState } from 'contexts/SubmenuContext/SubmenuContext';
import useContentSearch from 'hooks/contentSearch/useContentSearch';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { Article, CategorizedArticles } from 'types/articles';
import { ScoreType } from 'types/contentSearch';

const ContentSearchResults: React.FC = () => {
  const { openMenuId } = useSubmenuState();
  const isSearchFieldOpen = openMenuId === 'content-search';

  return <ContentSearchResultsInner isSearchFieldOpen={isSearchFieldOpen} />;
};

const CLOSE_MATCH_SCORE_TYPES = [ScoreType.CLOSE_TITLE, ScoreType.CLOSE_KEYWORD];

export const ContentSearchResultsInner: React.FC<{
  isSearchFieldOpen: boolean;
  forDashboard?: boolean;
}> = ({ isSearchFieldOpen, forDashboard }) => {
  const { t } = useTranslation();
  const { searchTerm, resultsRef, reposition } = useContentSearchState(forDashboard);
  const { results: contentResults, queryTerm } = useContentSearch(searchTerm, forDashboard);
  const lastQuestion = useSelector((state: RootState) => state.askForHelpDrawer.lastQuestion);

  const hasContentSearchResults = contentResults.length > 0;
  const shouldDisplayResults = isSearchFieldOpen && (queryTerm.length > 0 || !!lastQuestion);

  useEffect(() => {
    reposition();
  }, [reposition, shouldDisplayResults]);

  if (!shouldDisplayResults) {
    return null;
  }

  return (
    <Div
      refElement={resultsRef}
      className={getClassNames({
        [styles.articleSearchResults]: true,
        [styles.articleSearchResults_dashboard]: forDashboard
      })}
      data-testid="content-search-results-inner"
    >
      {hasContentSearchResults && (
        <>
          <ContentSearchSectionTitle
            section={t('topNavigation.articleSearch.contentSearchSectionTitle')}
          />

          <ContentSearchResultItems
            forDashboard={forDashboard}
            keyword={searchTerm}
            articles={
              [
                {
                  articles: contentResults?.map(
                    (entry) =>
                      ({
                        id: entry.title.toLowerCase().split(' ').join('_'),
                        title: entry.title,
                        url: entry.url,
                        articleType: entry.pageType,
                        fuzzy: CLOSE_MATCH_SCORE_TYPES.includes(entry.scoreType)
                      } as Article)
                  )
                }
              ] as CategorizedArticles[]
            }
          />
        </>
      )}

      {!hasContentSearchResults && !!queryTerm && (
        <BodyText margin={{ top: 'zero' }} data-testid="content-search-no-results">
          {t('topNavigation.articleSearch.noResults')} <b>{queryTerm}</b>
        </BodyText>
      )}

      <ContentSearchConversation searchTerm={queryTerm} />
    </Div>
  );
};

const ContentSearchResultItems: React.FC<{
  forDashboard?: boolean;
  keyword: string;
  articles: CategorizedArticles[];
}> = ({ forDashboard, keyword, articles }) => (
  <>
    {articles.map((result, index) => (
      <ContentSearchResultItem
        forDashboard={forDashboard}
        key={`result-${result.category}`}
        category={result.category}
        articles={result.articles}
        keyword={keyword}
        padBottom={index < articles.length - 1}
      />
    ))}
  </>
);

export default ContentSearchResults;
