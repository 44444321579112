import { BodyText, Flex, SmallText } from '@gaiads/telia-react-component-library';
import { Icon } from '@teliafi/fi-ds';
import getClassNames from 'classnames';
import { useSubmenuState } from 'contexts/SubmenuContext/SubmenuContext';
import { useUserData } from 'contexts/UserContext/UserContext';
import { useTranslation } from 'react-i18next';

import styles from './UserDropdown.module.scss';
import UserMenu from './UserMenu/UserMenu';

const UserDropdown: React.FC = () => {
  const { t } = useTranslation();
  const { openMenuId, setOpenMenuId, setMobileMenuOpen } = useSubmenuState();
  const { name, firstName, lastName, activeCompanyName } = useUserData();
  const isUserDropdownOpen = openMenuId === 'user-dropdown';
  const openOrCloseMenu = () => {
    if (!isUserDropdownOpen) {
      setMobileMenuOpen(false);
    }

    setOpenMenuId(isUserDropdownOpen ? '' : 'user-dropdown');
  };

  return (
    <div className={styles.userDropdown_container}>
      <button
        data-dd-action-name="User dropdown"
        data-testid="user-dropdown"
        type="button"
        aria-label={t('aria.header.userProfileToggle')}
        aria-expanded={isUserDropdownOpen}
        className={styles.userDropdown_menuToggle}
        onClick={openOrCloseMenu}
      >
        <Flex centeredVertically>
          <Flex className={styles.userDropdown_nameAndCompany} directionColumn>
            <BodyText
              className={styles.userDropdown_nameAndCompany_name}
              data-testid="active-user-name"
              size="xs"
              singleLine
            >
              {name}
            </BodyText>

            <SmallText
              className={styles.userDropdown_nameAndCompany_company}
              data-testid="active-company-name"
              size="md"
              singleLine
            >
              {activeCompanyName}
            </SmallText>
          </Flex>

          <div
            data-testid="open-login"
            className={getClassNames(styles.userDropdown_userInitials, {
              [styles.userDropdown_userInitials__open]: isUserDropdownOpen
            })}
          >
            {isUserDropdownOpen ? (
              <Icon name="close" size="sm" />
            ) : (
              <span>{`${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase()}</span>
            )}
          </div>
        </Flex>
      </button>

      {isUserDropdownOpen && (
        <div className={styles.userDropdown_itemContainer}>
          <UserMenu />
        </div>
      )}
    </div>
  );
};

export default UserDropdown;
