import {
  Div,
  Flex,
  Icon,
  IconName,
  SupportedFontWeight
} from '@gaiads/telia-react-component-library';
import { VisuallyHidden } from '@teliafi/fi-ds';
import getClassNames from 'classnames';
import Skeleton from 'common-components/Skeleton/Skeleton';
import { SmartTooltip } from 'common-components/SmartTooltip/SmartTooltip';
import { multiplex } from 'doings/multiplex/multiplex';
import { useTranslation } from 'react-i18next';
import { Sort, SortDirection } from 'types/sort';

import { ColumnWidth, WordWrap } from '../ListViewLayout/ListViewLayoutProvider';
import { ListViewTooltip } from '../ListViewTooltip/ListViewTooltip';
import styles from './ListViewCell.module.scss';

export type CellProps = {
  isHeading?: boolean;
  tooltip?: string;
  wordWrap?: WordWrap;
  fontWeight?: SupportedFontWeight;
  dataTestId?: string;
};

type ListViewCellProps = CellProps & {
  cellSize?: ColumnWidth;
  sort?: Sort;
  loading?: boolean;
  children: React.ReactNode;
};

const sizeMultipliers: Record<ColumnWidth, number> = {
  '0.5x': 0.5,
  '0.75x': 0.75,
  '1x': 1,
  '1.5x': 1.5,
  '2x': 2
};

const sortIconNames: { [key: string]: IconName } = {
  [SortDirection.DEFAULT]: 'sorter',
  [SortDirection.ASC]: 'chevron-up',
  [SortDirection.DESC]: 'chevron-down'
};

const wordWrapClassNames: { [key in WordWrap]: string | undefined } = {
  anywhere: styles.wrappedAnywhere,
  'break-word': styles.wrappedByBreakingWord,
  ellipsis: styles.singleLine,
  'ellipsis-two-lines': styles.twoLines,
  normal: undefined
};

const ListViewCell: React.FC<ListViewCellProps> = ({
  isHeading = false,
  cellSize = '1x',
  children,
  sort,
  tooltip,
  wordWrap,
  loading,
  fontWeight,
  dataTestId
}) => {
  const isContentCell = !isHeading && !loading;
  const isLoadingCell = !isHeading && loading;
  const cellContent = (
    <>
      {isHeading && !sort && (
        <HeadingCell tooltip={tooltip} dataTestId={dataTestId}>
          {children}
        </HeadingCell>
      )}

      {isHeading && sort && (
        <HeadingCellSortable sort={sort} tooltip={tooltip} dataTestId={dataTestId}>
          {children}
        </HeadingCellSortable>
      )}

      {isLoadingCell && <Skeleton.TextCell width="100%" data-testid="list-view-cell-skeleton" />}

      {isContentCell && <>{children}</>}
    </>
  );

  return (
    <Div
      flexItem={{ sizeMultiplier: sizeMultipliers[cellSize] }}
      fontWeight={fontWeight}
      className={getClassNames(styles.cell, wordWrap ? wordWrapClassNames[wordWrap] : undefined)}
      data-testid={dataTestId}
    >
      {tooltip && isContentCell ? (
        <SmartTooltip tooltipContent={tooltip} arrangement="top" wrapper="span">
          <SmartTooltip.Trigger wrapper="span">{cellContent}</SmartTooltip.Trigger>
        </SmartTooltip>
      ) : (
        cellContent
      )}
    </Div>
  );
};

type HeadingCellProps = Pick<ListViewCellProps, 'tooltip' | 'children' | 'dataTestId'>;
type HeadingCellPropsSortable = HeadingCellProps & Required<Pick<ListViewCellProps, 'sort'>>;

const HeadingCell: React.FC<HeadingCellProps> = ({ tooltip, children }) => {
  return (
    <Flex className={styles.heading} centeredVertically>
      <div>{children}</div>

      {tooltip && <ListViewTooltip content={tooltip} />}
    </Flex>
  );
};

const HeadingCellSortable: React.FC<HeadingCellPropsSortable> = ({ sort, tooltip, children }) => {
  const { t } = useTranslation();
  return (
    <Flex
      className={getClassNames(styles.heading, { [styles.heading_clickable]: !!sort?.onClick })}
      centeredVertically
      role="button"
      tabIndex={0}
      onClick={sort.onClick}
      onKeyUp={(event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
          sort.onClick();
        }
      }}
    >
      <div aria-live="polite">
        {children}

        {sort.direction && (
          <VisuallyHidden>
            {multiplex([
              t('aria.list.sort.order.none'),
              [sort.direction === SortDirection.ASC, t('aria.list.sort.order.asc')],
              [sort.direction === SortDirection.DESC, t('aria.list.sort.order.desc')]
            ])}
          </VisuallyHidden>
        )}
      </div>

      {tooltip && <ListViewTooltip content={tooltip} />}

      <Icon
        name={sortIconNames[sort.direction] || 'sorter'}
        margin={{ left: 'xs' }}
        size="sm"
        aria-hidden
      />
    </Flex>
  );
};

export default ListViewCell;
