/**
 * Client-side cookies which aren't `HttpOnly` (only sent alongside
 * requests to the application server; unavailable in client-side
 * scripts, such as authentication and authorisation cookies, to
 * mitigate potential XSS).
 *
 * Keep in sync with `applicationCookie.ts` in the API layer. Do not
 * include cookies which client-side scripts do not or cannot refer
 * to.
 */
export enum B2XCookie {
  ACTIVE_COMPANY_ID = 'activeCompanyId',
  ANALYTICS_SESSION_ID = 'b2x-analyticsSessionId',
  SESSION_EXPIRES_AT = 'b2x-sessionExpiresAt',
  NOTIFICATION_PREFIX = 'b2x-notification-',
  /** Identifies if user was taken to ULM profile page for outdated contacts verification */
  EXPIRED_CONTACTS_VERIFICATION = 'b2x-expiredContactsVerification'
}
