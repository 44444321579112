import { Flex } from '@gaiads/telia-react-component-library';
import { FieldErrorText } from '@teliafi/fi-ds';
import { DragAndDropProvider } from 'common-components/DragAndDropProvider/DragAndDropProvider';
import Memo from 'common-components/Memo/Memo';
import { useCallback } from 'react';

import styles from './SortableCheckboxesList.module.scss';
import { SortableCheckboxesListItem } from './SortableCheckboxesListItem';
import { useSortableCheckboxes } from './SortableCheckboxesProvider';

export const SortableCheckboxesList: React.FC = () => {
  const { selectMoreItemsLabel, minItemsEnabled, maxItemsEnabled, listItems, setListItems } =
    useSortableCheckboxes();
  const toggleItem = useCallback(
    (id: string) => {
      setListItems((items) => {
        return items.map((item) => (item.id === id ? { ...item, enabled: !item.enabled } : item));
      });
    },
    [setListItems]
  );

  const dragItem = useCallback(
    (draggedIndex: number, hoveredIndex: number) => {
      setListItems((items) => {
        const draggedItem = items[draggedIndex];
        const updatedItems = [...items];
        updatedItems.splice(draggedIndex, 1);
        updatedItems.splice(hoveredIndex, 0, draggedItem);
        return updatedItems;
      });
    },
    [setListItems]
  );

  return (
    <DragAndDropProvider>
      <ol className={styles.items} data-testid="sortable-cbs">
        {listItems.map((item, index) => (
          <li key={`item-${item.id}`}>
            <Memo comparables={['item', 'index', 'disabled']}>
              <SortableCheckboxesListItem
                item={item}
                index={index}
                dragItem={dragItem}
                toggleItem={toggleItem}
                disabled={(item.isRequired && item.enabled) || (maxItemsEnabled && !item.enabled)}
              />
            </Memo>
          </li>
        ))}
      </ol>

      {!minItemsEnabled && (
        <Flex margin={{ top: 'xs' }} alignRight occupyVerticalSpace={false}>
          <FieldErrorText data-testid="select-more-items-notice">
            {selectMoreItemsLabel}
          </FieldErrorText>
        </Flex>
      )}
    </DragAndDropProvider>
  );
};
