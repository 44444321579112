import { Div } from 'core-components';
import { useProductNewsRating } from 'hooks/productNews/useProductNewsRating';
import { useState } from 'react';
import { ProductNewsVote } from 'types/productNews';

import styles from './NewsDrawerRating.module.scss';
import NewsDrawerRatingButton from './NewsDrawerRatingButton';

const NewsDrawerRating: React.FC<{
  productUpdateId: string;
  rating?: ProductNewsVote;
}> = ({ productUpdateId, rating: initialRating }) => {
  const [rating, setRating] = useState<ProductNewsVote | undefined | null>(initialRating);
  const { updateRating } = useProductNewsRating();

  const toggleRating = (newRating: ProductNewsVote) => {
    const value = rating === newRating ? null : newRating;
    setRating(value);
    updateRating({ id: productUpdateId, rating: value });
  };

  return (
    <Div className={styles.ratingContainer} data-testid="news-drawer-rating">
      <NewsDrawerRatingButton
        active={rating === 'upvote'}
        onClick={() => toggleRating('upvote')}
        type="upvote"
      />

      <NewsDrawerRatingButton
        active={rating === 'downvote'}
        onClick={() => toggleRating('downvote')}
        type="downvote"
      />
    </Div>
  );
};

export default NewsDrawerRating;
