import { FilterChip, GutterBag } from '@gaiads/telia-react-component-library';
import { SmartTooltip } from 'common-components/SmartTooltip/SmartTooltip';
import { noOp } from 'doings/noOp/noOp';
import { ActiveFilter, ActiveFilterType } from 'types/listFilters';

import { ClearButton } from '../ClearButton/ClearButton';
import styles from './ActiveFilters.module.scss';

type DateChipFilterPartProps = {
  param: string;
  label: string;
  inputElementLabel: string;
  implicit: boolean;
  implicitTooltip?: string;
};

type DateChipFilterProps = {
  [key in ActiveFilterType]?: DateChipFilterPartProps;
};

type OptionalFn = (() => void) | undefined;
type ChipFilter = {
  label: string;
  tooltip?: string;
  clearFilter: OptionalFn;
  defaultFilter?: boolean;
};

const YEAR_DIGIT_COUNT = 4;

/**
 * Renders filter chips for currently active filter criteria. Expects
 * filter criteria in the following format:
 *
 * ```
 * [
 *   {
 *     param: "date-from",
 *     paramType: "DATE_FROM",
 *     value: "2022-01-31",
 *     label: "31.01.2022",
 *     inputElementLabel: "Date range",
 *     implicit: false,
 *     implicitTooltip: "Tooltip shown for implicit filter chip only"
 *   },
 *   {
 *     param: "date-till",
 *     paramType: "DATE_TILL",
 *     value: "2022-12-31",
 *     label: "31.12.2022",
 *     inputElementLabel: "Date range",
 *     implicit: false,
 *     implicitTooltip: "Tooltip shown for implicit filter chip only"
 *   },
 *   {
 *     param: "date-type",
 *     paramType: "DATE_TYPE",
 *     value: "created",
 *     label: "Created",
 *     inputElementLabel: "Date type",
 *     implicit: false
 *   },
 *   {
 *     param: "status",
 *     paramType: "DROPDOWN",
 *     value: "new",
 *     label: "Fresh out of the oven",
 *     inputElementLabel: "Status",
 *     implicit: false
 *   }
 * ]
 * ```
 *
 * Converts each "DROPDOWN" criterion to a separate filter chip,
 * e.g. [Fresh out of the oven], and consolidates "DATE_TYPE",
 * "DATE_FROM" and "DATE_TO" filter criteria into a single filter
 * chip, e.g. [Created 31.01. – 31.12.2022]. Clicking on a filter
 * chip clears all associated filters.
 *
 * If the `implicit` property is set for consolidated "DATE_TYPE",
 * "DATE_FROM" and "DATE_TO" filter criteria, then the filter chip
 * is considered a default date range that the user cannot and should
 * not be able to remove, so interacting with the resulting filter
 * chip will instead toggle the filter dialog.
 *
 * Properties:
 * 1. `activeFilters` – Active filter criteria.
 * 2. `clearFilter` – Called to clear a specific filter by parameter.
 * 3. `clearAllFilters` – Called to clear all filters.
 * 4. `toggleFilterDialog` – Called to open the filter dialog when
 * clicking an implicit filter.
 */
export const ActiveFilters: React.FC<{
  activeFilters: Array<ActiveFilter>;
  clearFilter: (filter: string) => void;
  clearAllFilters: VoidFunction;
  toggleFilterDialog: VoidFunction;
}> = ({ activeFilters, clearFilter, clearAllFilters, toggleFilterDialog }) => {
  const chipFilters = mapToChipFilters(activeFilters, clearFilter);
  const contents: React.ReactElement[] = chipFilters.map(
    ({ label, tooltip, clearFilter, defaultFilter }) => {
      const FilterChipWrapper = ({ children }: { children: React.ReactElement }) => (
        <div className={clearFilter ? '' : styles.implicitFilter}>
          {defaultFilter && tooltip ? (
            <SmartTooltip tooltipContent={tooltip} arrangement="top" wrapper="div">
              <SmartTooltip.Trigger wrapper="div">{children}</SmartTooltip.Trigger>
            </SmartTooltip>
          ) : (
            children
          )}
        </div>
      );

      return (
        <FilterChipWrapper key={`filter-chip-wrapper-${label}`}>
          <FilterChip
            key={`filter-chip-${label}`}
            text={label}
            onClick={defaultFilter ? toggleFilterDialog : clearFilter || noOp}
            tabIndex={defaultFilter || clearFilter ? 0 : -1}
            data-testid="filter-chip"
          />
        </FilterChipWrapper>
      );
    }
  );

  if (chipFilters.filter(({ clearFilter }) => !!clearFilter).length > 1) {
    contents.push(<ClearButton key="clear-filters-button" clearFilters={clearAllFilters} />);
  }

  return <GutterBag className={styles.activeFilters} contents={contents} showOverflow />;
};

function mapToChipFilters(
  activeFilters: ActiveFilter[],
  clearFilter: (filter: string) => void
): ChipFilter[] {
  const dateFilter: DateChipFilterProps = {};
  const chipFilters: ChipFilter[] = [];
  activeFilters.forEach(
    ({ param, paramType, label, inputElementLabel, implicit, implicitTooltip }) => {
      switch (paramType) {
        case 'DROPDOWN':
          if (!implicit) {
            chipFilters.push({
              label,
              clearFilter: () => clearFilter(param)
            });
          }
          break;

        case 'DATE_FROM':
        case 'DATE_TILL':
        case 'DATE_TYPE':
          dateFilter[paramType] = { label, inputElementLabel, param, implicit, implicitTooltip };
          break;
      }
    }
  );

  const { DATE_TYPE: dateType, DATE_FROM: dateFrom, DATE_TILL: dateTill } = dateFilter;
  if (dateFrom && dateTill) {
    const dateTypeLabel = dateType ? dateType.label : dateFrom.inputElementLabel;
    const dateTypeImplicit = dateType?.implicit ?? true;
    dateFrom.label = normalizeDateFromLabelIfSameYear(dateFrom.label, dateTill.label);
    chipFilters.unshift({
      label: `${dateTypeLabel} ${dateFrom.label} – ${dateTill.label}`,
      tooltip: dateFrom.implicitTooltip,
      clearFilter: createClearFilterFunction(dateType, dateFrom, dateTill, clearFilter),
      defaultFilter: dateTypeImplicit && dateFrom.implicit && dateTill.implicit
    });
  }

  return chipFilters;
}

/**
 * Removes the year from a from date if the from and till dates have
 * a matching year. Keeps the years as is otherwise.
 *
 * Examples:
 * * 2.8.2022 – 4.8.2022 &rarr; 2.8. – 4.8.2022
 * * 2.8.2021 – 4.8.2022 &rarr; 2.8.2021 – 4.8.2022
 */
function normalizeDateFromLabelIfSameYear(dateFromLabel: string, dateTillLabel: string) {
  if (dateFromLabel.slice(-YEAR_DIGIT_COUNT) === dateTillLabel.slice(-YEAR_DIGIT_COUNT)) {
    return dateFromLabel.substring(0, dateFromLabel.length - YEAR_DIGIT_COUNT);
  }

  return dateFromLabel;
}

/**
 * Creates a clear filter function for an explicitly set date filter
 * if any parts of a date filter are user-provided. If all parts are
 * implicit, the filter chip cannot be cleared.
 */
function createClearFilterFunction(
  dateType: DateChipFilterPartProps | undefined,
  dateFrom: DateChipFilterPartProps,
  dateTill: DateChipFilterPartProps,
  clearFilter: (filter: string) => void
) {
  if ((dateType?.implicit ?? true) && dateFrom.implicit && dateTill.implicit) {
    return undefined;
  }

  return () => {
    if (dateType) {
      clearFilter(dateType.param);
    }
    clearFilter(`${dateFrom.param}:${dateTill.param}`);
  };
}
