import useFetchTicketList, { TicketsQueryParameters } from './useFetchTicketList';

type UseTicketsProps = {
  queryParameters: TicketsQueryParameters;
  allCompanies?: boolean;
};

const useTickets = ({ queryParameters, allCompanies }: UseTicketsProps) => {
  const ticketList = useFetchTicketList({
    skip: false,
    queryParameters,
    allCompanies
  });

  const output = ticketList;

  return {
    ...output,
    total: output?.total ?? 0
  };
};

export default useTickets;
