import { gql } from '@apollo/client';

export default gql`
  query GetMobileSubscriptionPukCodesForSubscription($subscriptionId: ID!, $force: Boolean) {
    subscription(subscriptionId: $subscriptionId, force: $force) {
      subscription {
        phoneNumber
        user {
          displayName
        }
        simCard {
          pukCodes {
            puk1
            puk2
          }
        }
      }
    }
  }
`;
