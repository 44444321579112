import BillingGroupPageError from 'B2XApp/Invoicing/BillingGroups/Common/BillingGroupPageError';
import BillingGroupPageLoading from 'B2XApp/Invoicing/BillingGroups/Common/BillingGroupPageLoading';
import { useParams } from 'react-router-dom';

import CopyBillingGroupPage from './CopyBillingGroupPage';
import useCopyBillingGroupData from './useCopyBillingGroupData';

interface CopyBillingGroupParams {
  collectionGroup: string;
}

const CopyBillingGroup: React.FC = () => {
  const { collectionGroup: collectionGroupId } = useParams<CopyBillingGroupParams>();
  const { status, collectionGroup, availableBanks, availableOperators } =
    useCopyBillingGroupData(collectionGroupId);

  switch (status) {
    case 'loaded':
      if (collectionGroup) {
        return (
          <CopyBillingGroupPage
            collectionGroupId={collectionGroupId}
            collectionGroup={collectionGroup}
            availableBanks={availableBanks}
            availableOperators={availableOperators}
          />
        );
      } else {
        return <BillingGroupPageError collectionGroupId={collectionGroupId} pageType="copy" />;
      }
    case 'loading':
      return <BillingGroupPageLoading collectionGroupId={collectionGroupId} pageType="copy" />;
    case 'error':
      return <BillingGroupPageError collectionGroupId={collectionGroupId} pageType="copy" />;
  }
};

export default CopyBillingGroup;
