import { BodyText, Div, Span } from '@gaiads/telia-react-component-library';
import { FieldErrorText } from '@teliafi/fi-ds';
import { Heading } from 'common-components';
import FileUploader from 'common-components/FileUploader/FileUploader';
import { multiplex } from 'doings/multiplex/multiplex';
import { useTranslation } from 'react-i18next';

import { TicketFileUploaderTooltip } from './TicketFileUploaderTooltip';
import { TicketAttachments } from './useTicketAttachments';

const TicketFileUploader: React.FC<{
  supportedFileTypesForMessages: string[];
  withCustomFileTypes?: boolean;
  attachmentsModel: TicketAttachments;
  replyToTicket?: boolean;
}> = ({
  supportedFileTypesForMessages,
  withCustomFileTypes = false,
  attachmentsModel,
  replyToTicket = false
}) => {
  const { t } = useTranslation();
  const numberOfFilesMessageKeySuffix = multiplex([
    'numberOfFilesMessageOneAttachment',
    [withCustomFileTypes, 'supportedFileTypesCustom'],
    [!replyToTicket, 'numberOfFilesMessageMultipleAttachments']
  ]);

  const numberOfFilesMessageKey = `tickets.replyToTicket.attachment.${numberOfFilesMessageKeySuffix}`;

  const showError =
    attachmentsModel.touched && attachmentsModel.required && !attachmentsModel.isValid;
  const fileCount = attachmentsModel.fileItems.length;

  return (
    <Div
      margin={{ top: 'sm', bottom: multiplex(['zero', [showError, 'md'], [fileCount > 0, 'xs']]) }}
    >
      <Heading.H4>{t('tickets.replyToTicket.attachment.title')}</Heading.H4>

      <BodyText margin={{ top: 'sm', bottom: 'xs' }}>
        {t('tickets.replyToTicket.attachment.maxFileSizeMessage')}
      </BodyText>

      <BodyText margin={{ bottom: 'sm' }}>
        <Span padding={{ right: 'xs' }}>
          {t(
            numberOfFilesMessageKey,
            withCustomFileTypes
              ? {
                  fileTypes: supportedFileTypesForMessages.join(', ')
                }
              : {}
          )}
        </Span>

        {!withCustomFileTypes && <TicketFileUploaderTooltip />}
      </BodyText>

      <FileUploader
        buttonText={t('tickets.replyToTicket.attachment.add')}
        validTypes={supportedFileTypesForMessages}
        maximumAmountOfFiles={1}
        files={attachmentsModel.fileItems}
        onChange={attachmentsModel.attachFiles}
        data-testid="ticket-file-uploader"
      />

      {showError && (
        <FieldErrorText>
          {t('tickets.replyToTicket.attachment.requiredAttachmentMessage')}
        </FieldErrorText>
      )}
    </Div>
  );
};

export default TicketFileUploader;
