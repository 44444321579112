import routeAccessGroups from 'B2XApp/appRouteAccessGroups';
import ContactUsPanel from 'B2XApp/ContactUs/ContactUsPanel';
import useGetDashboardNotificationRows from 'B2XApp/Dashboard/DashboardNavigation/DashboardNotifications/useGetDashboardNotificationRows';
import { contactUs } from 'doings/track/analyticsEvents';
import useClearNewMessagesNotification from 'hooks/customerSpecificMessages/useClearNewMessagesNotification';
import { useTranslation } from 'react-i18next';

import ContactUsMessageLink from './ContactUsMessageLink';

const CustomerSpecificMessagesPanel: React.FC = () => {
  const { t } = useTranslation();
  const { clearNewCustomerSpecificMessagesNotification } = useClearNewMessagesNotification();
  const messages = useGetDashboardNotificationRows({ onlyContacts: true }).find(
    ({ dataIdSuffix }) => dataIdSuffix === 'customer-specific-messages'
  );

  return (
    <ContactUsPanel
      title={t('contactUs.panel.title.customerSpecificMessages')}
      titleIcon="customer-dialogue"
      content={t('contactUs.panel.text.customerSpecificMessages')}
      data-tour-stop="contact-us-customer-specific-message-panel"
      data-testid="contact-us-customer-specific-message-panel"
    >
      <ContactUsMessageLink
        data-testid="link-to-customer-specific-messages"
        label={t('navigationalLink.title.customerSpecificMessages')}
        onClick={() => clearNewCustomerSpecificMessagesNotification()}
        analyticsEvent={contactUs.customerSpecificMessages}
        item={messages}
        enabledWhen={routeAccessGroups.CUSTOMER_SPECIFIC_MESSAGES}
      />
    </ContactUsPanel>
  );
};

export default CustomerSpecificMessagesPanel;
