import { TeliaLink } from 'common-components';
import getEnvironmentVariable from 'doings/getEnvironmentVariable/getEnvironmentVariable';
import { otherServices } from 'doings/track/analyticsEvents';
import { useTranslation } from 'react-i18next';

const MobileNetworkQualityLinks: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <TeliaLink.External
        data-testid="mobile-network-coverage-map"
        variant="standalone"
        to={getEnvironmentVariable('REACT_APP_LINK_TO_MOBILE_NETWORK_COVERAGE_MAP')}
        label={t('dashboard.mobileNetworkQuality.coverageMap')}
        iconRight="external"
        track={otherServices.mobileNetworkCoverageMap}
      />

      <TeliaLink.External
        data-testid="mobile-network-disturbances-map"
        variant="standalone"
        to={getEnvironmentVariable('REACT_APP_LINK_TO_MOBILE_NETWORK_DISTURBANCES_MAP')}
        label={t('dashboard.mobileNetworkQuality.disturbancesMap')}
        iconRight="external"
        track={otherServices.mobileNetworkDisturbancesMap}
      />

      <TeliaLink.External
        data-testid="mobile-network-disturbance-notification-settings"
        variant="standalone"
        to={getEnvironmentVariable(
          'REACT_APP_LINK_TO_MOBILE_NETWORK_DISTURBANCE_NOTIFICATION_SETTINGS'
        )}
        label={t('dashboard.mobileNetworkQuality.disturbanceNotificationSettings')}
        iconRight="external"
        track={otherServices.mobileNetworkDisturbanceNotificationSettings}
      />
    </>
  );
};

export default MobileNetworkQualityLinks;
