import { TextInputModel } from 'hooks/inputModels/useTextInputModel/useTextInputModel';
import useValidatableTextInputModel from 'hooks/inputModels/useValidatableTextInputModel/useValidatableTextInputModel';
import { Finvoice } from 'types/collection-group';

export type FinvoiceFields = {
  finvoiceOrderId: TextInputModel;
  finvoiceBuyerReference: TextInputModel;
  finvoiceAgreementId: TextInputModel;
  finvoiceAccountDimension: TextInputModel;
  finvoiceTenderReference: TextInputModel;
  finvoiceCostAccount: TextInputModel;
  finvoiceAccountProposal: TextInputModel;
  finvoiceProjectNumber: TextInputModel;
};

export default (finvoice: Finvoice | undefined): FinvoiceFields => ({
  finvoiceOrderId: useValidatableTextInputModel({
    initialValue: finvoice?.orderIdentifier || '',
    required: false,
    expecting: {
      a: 'billingFinvoiceLenientValue',
      maxLength: 70
    }
  }),
  finvoiceBuyerReference: useValidatableTextInputModel({
    initialValue: finvoice?.buyerReferenceIdentifier || '',
    required: false,
    expecting: {
      a: 'billingFinvoiceLenientValue',
      maxLength: 70
    }
  }),
  finvoiceAgreementId: useValidatableTextInputModel({
    initialValue: finvoice?.agreementIdentifier || '',
    required: false,
    expecting: {
      a: 'billingFinvoiceLenientValue',
      maxLength: 70
    }
  }),
  finvoiceAccountDimension: useValidatableTextInputModel({
    initialValue: finvoice?.accountDimensionText || '',
    required: false,
    expecting: {
      a: 'billingFinvoiceLenientValue',
      maxLength: 35
    }
  }),
  finvoiceTenderReference: useValidatableTextInputModel({
    initialValue: finvoice?.tenderReference || '',
    required: false,
    expecting: {
      a: 'billingFinvoiceLenientValue',
      maxLength: 70
    }
  }),
  finvoiceCostAccount: useValidatableTextInputModel({
    initialValue: finvoice?.proposedAccountText || '',
    required: false,
    expecting: {
      a: 'billingFinvoiceLenientValue',
      maxLength: 35
    }
  }),
  finvoiceAccountProposal: useValidatableTextInputModel({
    initialValue: finvoice?.normalProposedAccountIdent || '',
    required: false,
    expecting: {
      a: 'billingFinvoiceAccountProposal',
      maxLength: 4
    }
  }),
  finvoiceProjectNumber: useValidatableTextInputModel({
    initialValue: finvoice?.projectReferenceIdentifier || '',
    required: false,
    expecting: {
      a: 'billingFinvoiceLenientValue',
      maxLength: 70
    }
  })
});
