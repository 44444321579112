import { isBefore, isSameDay } from 'date-fns';
import { DateFormat } from 'doings/formatDatetime/DateFormats';
import formatDatetime from 'doings/formatDatetime/formatDatetime';
import { multiplex } from 'doings/multiplex/multiplex';
import { TFunction } from 'i18next';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

type DateRounding = 'START_OF_DAY' | 'END_OF_DAY';

const END_OF_DAY_HOUR = 23;
const END_OF_DAY_MIN = 59;
const END_OF_DAY_SEC = 59;
const END_OF_DAY_MS = 999;

const roundDate = (value?: Date, roundTo?: DateRounding) => {
  if (!value || !roundTo) {
    return value;
  }

  const newValue = new Date(value);
  if (roundTo === 'START_OF_DAY') {
    newValue.setHours(0);
    newValue.setMinutes(0);
    newValue.setSeconds(0);
    newValue.setMilliseconds(0);
  } else if (roundTo === 'END_OF_DAY') {
    newValue.setHours(END_OF_DAY_HOUR);
    newValue.setMinutes(END_OF_DAY_MIN);
    newValue.setSeconds(END_OF_DAY_SEC);
    newValue.setMilliseconds(END_OF_DAY_MS);
  }

  return newValue;
};

export type DateInputModel = {
  value?: Date;
  setValue: (value?: Date) => void;
  required?: boolean;
  hidden?: boolean;
  setRequired: (required: boolean) => void;
  isValid: boolean;
  disabledDays?: { disabledDays: { before: Date }[] };
  errorText: string;
  getFormattedValue: (format: DateFormat) => string;
};

const isValueValid = (
  value: Date | undefined,
  isRequired: boolean,
  isDisablePastDays: boolean,
  t: TFunction
) => {
  const now = new Date();
  const missingValue = isRequired && !value;
  const dateInPast = isDisablePastDays && value && !isSameDay(value, now) && isBefore(value, now);

  return multiplex([
    { isValid: true, errorText: '' },
    [missingValue, { isValid: false, errorText: t('validators.valueIsRequired') }],
    [!!dateInPast, { isValid: false, errorText: t('validators.dateIsInPast') }]
  ]);
};

export default ({
  initialValue = undefined,
  required: isRequired = false,
  hidden = false,
  disablePastDays = false,
  roundTo = undefined,
  onChange
}: {
  initialValue?: Date;
  required?: boolean;
  hidden?: boolean;
  disablePastDays?: boolean;
  roundTo?: DateRounding;
  onChange?: (value?: Date) => void;
} = {}): DateInputModel => {
  const { t } = useTranslation();
  const [value, setValue] = useState<Date | undefined>(roundDate(initialValue, roundTo));
  const [required, setRequired] = useState<boolean>(isRequired);

  const setValueRounded = useCallback(
    (value?: Date) => {
      const rounded = roundDate(value, roundTo);
      if (onChange) {
        onChange(rounded);
      }

      setValue(rounded);
    },
    [roundTo, setValue, onChange]
  );

  const { isValid, errorText } = isValueValid(value, required, disablePastDays, t);
  const disabledDays = disablePastDays ? { disabledDays: [{ before: new Date() }] } : undefined;

  return {
    value,
    setValue: setValueRounded,
    required,
    setRequired,
    isValid,
    hidden,
    disabledDays,
    errorText,
    getFormattedValue: (format: DateFormat) => formatDatetime(value, format)
  };
};
