import useAvailableBanks from 'hooks/invoicing/billingGroups/useAvailableBanks/useAvailableBanks';
import useAvailableOperators from 'hooks/invoicing/billingGroups/useAvailableOperators/useAvailableOperators';
import useBillMillAccount from 'hooks/invoicing/billingGroups/useBillMillAccount/useBillMillAccount';
import { LoadingStatus } from 'hooks/invoicing/billingGroups/useCollectionGroup/useCollectionGroup';

const mapStatus = (
  banks: ReturnType<typeof useAvailableBanks>,
  operators: ReturnType<typeof useAvailableOperators>,
  account: ReturnType<typeof useBillMillAccount>
): LoadingStatus => {
  if (banks.error || operators.error || account.status === 'error') {
    return 'error';
  } else if (banks.loading || operators.loading || account.status === 'loading') {
    return 'loading';
  } else {
    return 'loaded';
  }
};

/**
 * Retrieves information required to render an edit billing account
 * form: available banks and operators for electronic invoices, and
 * the BillMill account to be edited. If any information cannot be
 * retrieved, fails gracefully by returning an error status.
 *
 * @param accountId The identifier of the BillMill account to be edited.
 */
const useEditBillMillAccountData = (accountId: string) => {
  const banksResult = useAvailableBanks();
  const operatorsResult = useAvailableOperators();
  const accountResult = useBillMillAccount(accountId);
  const status = mapStatus(banksResult, operatorsResult, accountResult);

  return {
    status,
    availableBanks: banksResult.availableBanks,
    availableOperators: operatorsResult.availableOperators,
    account: accountResult.account
  };
};

export default useEditBillMillAccountData;
