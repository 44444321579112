import { Div, List } from '@gaiads/telia-react-component-library';
import React from 'react';

import styles from './MoreActionsDialog.module.scss';

const MoreActionsDialog: React.FC<{
  isOpen: boolean;
  items: React.ReactElement[];
  refElement: React.RefObject<HTMLDivElement>;
}> = ({ isOpen, items, refElement }) => {
  return (
    <Div
      hidden={!isOpen}
      className={styles.moreActionsDialogWrapper}
      refElement={refElement}
      data-testid="more-actions-dialog-wrapper"
      margin={{ top: 'xs' }}
    >
      <Div className={styles.moreActionsDialog} boxShadow="md">
        <List>
          {items.map((item, index) => {
            return (
              <List.Item key={index} margin={{ size: 'zero' }}>
                {item}
              </List.Item>
            );
          })}
        </List>
      </Div>
    </Div>
  );
};

export default MoreActionsDialog;
