import { BillingGroupFormFinalSteps } from 'B2XApp/Invoicing/BillingGroups/BillingGroupForm/useBillingGroupForm';
import BillingGroupFormPageLayout from 'B2XApp/Invoicing/BillingGroups/Common/BillingGroupFormPageLayout';
import { useTranslation } from 'react-i18next';
import { AvailableBank, AvailableOperator } from 'types/collection-group';

import NewBillingGroupPageForm from './NewBillingGroupPageForm';
import useNewBillingGroupForm from './useNewBillingGroupForm';

const NewBillingGroupPage: React.FC<{
  availableBanks: AvailableBank[];
  availableOperators: AvailableOperator[];
}> = ({ availableBanks, availableOperators }) => {
  const { t } = useTranslation();
  const { form } = useNewBillingGroupForm({ availableBanks, availableOperators });
  const { step } = form;

  return (
    <BillingGroupFormPageLayout
      backLinkVisible={!BillingGroupFormFinalSteps.includes(step)}
      title={t('invoices.billingGroup.form.creation.title')}
    >
      <NewBillingGroupPageForm form={form} />
    </BillingGroupFormPageLayout>
  );
};

export default NewBillingGroupPage;
