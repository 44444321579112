import { Div, Flex, Gutter } from '@gaiads/telia-react-component-library';
import { Button, Icon } from '@teliafi/fi-ds';
import {
  ReplyForm,
  TicketDetailsBox,
  TicketDetailsTimeline,
  TicketStatusTag
} from 'B2XApp/Messages/common';
import {
  getTicketDetails,
  getTicketInfo,
  ticketToMessages
} from 'B2XApp/Messages/Tickets/TicketDetailsPage/ticketDetailsHelpers';
import { Authorize, MobilePadding } from 'common-components';
import DetailsPage from 'common-components/DetailsPage/DetailsPage';
import { useFeatureFlagsData } from 'contexts/FeatureFlagContext/FeatureFlagContext';
import { Divider } from 'core-components';
import { messages } from 'doings/track/analyticsEvents';
import { SendTicketMessageParameters } from 'hooks/tickets/useSendTicketMessage/useSendTicketMessage';
import { useTicketMessagesOrder } from 'hooks/tickets/useTicketMessagesOrder.ts/useTicketMessagesOrder';
import { head } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { Ticket } from 'types/ticket';

import styles from './TicketDetailsPage.module.scss';

export type ReplyToTicketParams = {
  sendMessage: (variables: SendTicketMessageParameters) => void;
  replyToTicketVisible: boolean;
  setReplyToTicketVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setReplySentMessageVisible: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  hasError: boolean;
  replySent: boolean;
};

const TicketDetailsPage: React.FC<{
  ticket?: Ticket;
  loading: boolean;
  hasError: boolean;
  replyToTicket: ReplyToTicketParams;
}> = ({ ticket, loading, hasError, replyToTicket }) => {
  const { t } = useTranslation();
  const {
    order: messageOrder,
    isOrderAsc: isMessageOrderAsc,
    toggleOrder: toggleMessageOrder
  } = useTicketMessagesOrder();
  const {
    sendMessage,
    replyToTicketVisible,
    setReplyToTicketVisible,
    setReplySentMessageVisible,
    loading: replyLoading,
    hasError: hasReplyError,
    replySent
  } = replyToTicket;

  const ticketIsOpen = !['Closed', 'Cancelled'].includes(ticket?.status || '');
  const canShowReplyForm = ticket && replyToTicketVisible;
  const canShowReplyButton = !loading && ticketIsOpen && !replyToTicketVisible;
  const ticketInfo = getTicketInfo(ticket);
  const { hasFeature } = useFeatureFlagsData();
  const isChatViewEnabled = hasFeature('EAT-55306');

  return (
    <DetailsPage
      hasError={hasError || (!ticket && !loading)}
      data-testid="ticket-details-page"
      loading={loading}
      headline={ticketInfo?.title || ''}
      headlineTag={ticketInfo && <TicketStatusTag status={ticketInfo.status} />}
    >
      <MobilePadding>
        <Flex pileVerticallyTo="md">
          <Div flexItem={{ sizeMultiplier: 2 }}>
            {canShowReplyButton && (
              <Div>
                <Div className={styles.controls}>
                  <Authorize
                    enabledWhen={{ onePermission: 'INCIDENT_TICKETS' }}
                    element={
                      <Button
                        onClick={() => setReplyToTicketVisible(true)}
                        className={styles.reply_now_btn}
                        data-testid="ticket-details-reply-button"
                      >
                        <Icon name="plus" size="sm" />
                        {t('tickets.details.reply')}
                      </Button>
                    }
                  />

                  <Button variant="text" onClick={toggleMessageOrder} className={styles.sorter}>
                    <Icon name={`arrow-${isMessageOrderAsc ? 'down' : 'up'}`} size="sm" />
                    {t(`tickets.details.messageOrder${isMessageOrderAsc ? 'Asc' : 'Desc'}`)}
                  </Button>
                </Div>

                <Gutter />

                <Divider />
              </Div>
            )}
            {canShowReplyForm && (
              <ReplyForm
                maxNoteLength={50000}
                replyAnalyticsEvent={messages.commentTroubleTicket}
                loading={replyLoading}
                loadingText={t('tickets.replyToTicket.delaysNotice')}
                error={hasReplyError}
                onReply={(note, attachments) =>
                  sendMessage({
                    id: ticket.id,
                    note,
                    attachment: head(attachments)
                  })
                }
                replySent={replySent}
                setShowReplySentMessage={setReplySentMessageVisible}
                setShowReplyForm={setReplyToTicketVisible}
              />
            )}
            {!hasError && (
              <TicketDetailsTimeline
                footer="tickets.details.created"
                messages={ticket ? ticketToMessages(ticket, messageOrder, t) : []}
                showChatView={isChatViewEnabled}
                loading={loading}
              />
            )}
          </Div>

          <Gutter />

          <Div flexItem={{ sizeMultiplier: 1 }}>
            {!hasError && (
              <TicketDetailsBox
                listItems={
                  ticket ? getTicketDetails({ ticket, splitAttachments: isChatViewEnabled, t }) : []
                }
                loading={loading}
              />
            )}
          </Div>
        </Flex>
      </MobilePadding>

      <Gutter size="lg" />
    </DetailsPage>
  );
};

export default TicketDetailsPage;
