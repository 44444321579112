import { BodyText, Div, Flex } from '@gaiads/telia-react-component-library';
import { Icon, IconName } from '@teliafi/fi-ds';
import { Analytics } from 'common-components';
import ExternalLink from 'common-components/Link/ExternalLink/ExternalLink';
import LinkToEndpoint from 'common-components/Link/LinkToEndpoint/LinkToEndpoint';
import { useUserData } from 'contexts/UserContext/UserContext';
import { Divider } from 'core-components';
import getEnvironmentVariable from 'doings/getEnvironmentVariable/getEnvironmentVariable';
import isDemoEnvironment from 'doings/isDemoEnvironment/isDemoEnvironment';
import { urlWithBackToService } from 'doings/links/urlBackToService/urlBackToService';
import { AnalyticsEvent, userMenu } from 'doings/track/analyticsEvents';
import useCurrentHref from 'hooks/useCurrentHref/useCurrentHref';
import { GuardedEvent, useConfirmationGuard } from 'hooks/useCustomModal/useConfirmationGuard';
import useUrlWithBasename from 'hooks/useUrlWithBasename/useUrlWithBasename';
import { useTranslation } from 'react-i18next';
import urlJoin from 'url-join';

import styles from './UserMenu.module.scss';

export type UserMenuItem = {
  testId: string;
  link: string;
  label: string;
  icon: IconName;
  active?: boolean;
  analyticsEvent: AnalyticsEvent;
  isLastItem?: boolean;
};

const UserMenu: React.FC = () => {
  const currentHref = useCurrentHref();
  const { name, activeCompanyName, hasMultipleCompanies } = useUserData();

  const ulmUrl = getEnvironmentVariable('REACT_APP_ULM_URL');
  const profileUrl = urlWithBackToService(urlJoin(ulmUrl, 'profile'), currentHref);
  const companyUrl = urlWithBackToService(urlJoin(ulmUrl, 'companies'), currentHref);
  const { onGuardedClick } = useConfirmationGuard();

  const { t } = useTranslation();
  const homeUrl = useUrlWithBasename('/');
  const links: UserMenuItem[] = [
    {
      testId: 'to-profile',
      link: profileUrl,
      label: t('topNavigation.myProfile'),
      icon: 'end-user',
      active: true,
      analyticsEvent: userMenu.profile
    } as UserMenuItem,
    {
      testId: 'to-companies',
      link: companyUrl,
      label: t('topNavigation.companies'),
      icon: 'premises',
      active: true,
      analyticsEvent: userMenu.companies
    } as UserMenuItem,
    {
      testId: 'to-change-company',
      link: urlJoin(ulmUrl, `select-company?redirectUrl=${homeUrl}`),
      label: t('topNavigation.changeCompany'),
      icon: 'switch-arrows',
      active: hasMultipleCompanies,
      analyticsEvent: userMenu.changeCompany
    } as UserMenuItem
  ].filter(({ active }) => active);

  const logOutUrl = '/api/logout';

  return (
    <Div className={styles.userMenu} data-testid="logged-in">
      <Div
        className={styles.userMenu_nameAndCompany}
        padding={{ horizontal: 'md', vertical: 'xs' }}
      >
        <BodyText
          className={styles.userMenu_nameAndCompany_name}
          data-testid="logged-in-username"
          size="sm"
        >
          {name}
        </BodyText>

        <BodyText
          className={styles.userMenu_nameAndCompany_company}
          data-testid="logged-in-user-company"
          size="sm"
        >
          {activeCompanyName}
        </BodyText>
      </Div>

      {links.map((link, index) => (
        <MenuLink key={link.label} {...link} isLastItem={index === links.length - 1} />
      ))}

      <Divider />

      <Analytics whenClicked={userMenu.logout}>
        <LinkToEndpoint
          className={styles.userMenu_link}
          data-testid="link-to-logout"
          to={logOutUrl}
          color="black"
          disabled={isDemoEnvironment()}
        >
          <Flex
            padding={{ horizontal: 'md', vertical: 'sm' }}
            centeredVertically
            data-testid="logout-link"
            onClick={(e: GuardedEvent) =>
              onGuardedClick(e, `${process.env.PUBLIC_URL}${logOutUrl}`)
            }
          >
            <Icon name="logout" size="xs" />

            <BodyText margin={{ left: 'sm' }} tagName="span">
              {t('topNavigation.logOut')}
            </BodyText>
          </Flex>
        </LinkToEndpoint>
      </Analytics>
    </Div>
  );
};

const MenuLink: React.FC<UserMenuItem> = ({
  testId,
  link,
  label,
  icon,
  analyticsEvent,
  isLastItem = false
}) => {
  const { onGuardedClick } = useConfirmationGuard();
  return (
    <Analytics whenClicked={analyticsEvent}>
      <ExternalLink data-testid={testId} to={link} block>
        <Flex
          padding={{
            horizontal: 'md',
            top: 'xs',
            bottom: isLastItem ? 'sm' : 'xs'
          }}
          centeredVertically
          onClick={(e: GuardedEvent) => onGuardedClick(e, link)}
        >
          <Icon name={icon} size="xs" />

          <BodyText margin={{ left: 'sm' }} tagName="span">
            {label}
          </BodyText>
        </Flex>
      </ExternalLink>
    </Analytics>
  );
};

export default UserMenu;
