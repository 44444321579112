import { TeliaLink } from 'common-components';
import { EligibleEditableListState } from 'contexts/EditableListContext/EditableListContext';
import { multiplex } from 'doings/multiplex/multiplex';
import { useTriggerWatchableTour } from 'hooks/tours/useWatchableTours';
import { useTranslation } from 'react-i18next';

export const EditDialogToggle: React.FC<{
  listConfig: EligibleEditableListState;
  onOpen: VoidFunction;
}> = ({ listConfig: { status, columns }, onOpen }) => {
  const { t } = useTranslation();
  useTriggerWatchableTour({ condition: status === 'available', tourName: 'list-customisation' });

  return (
    <TeliaLink.Click
      variant="standalone"
      iconLeft={multiplex(['settings', [status === 'err', 'error']])}
      label={t('modal.editList.button.showModal')}
      disabled={status === 'err' || (status === 'available' && Object.keys(columns).length <= 0)}
      disabledTooltipKey={t('modal.editList.button.unavailable')}
      loading={status === 'loading'}
      onClick={onOpen}
      data-testid="open-edit-list-modal"
      data-tour-stop="list-customisation-button"
    />
  );
};
