import copy from 'copy-to-clipboard';
import { useTimeoutedState } from 'hooks/useTimeoutedState/useTimeoutedState';
import { useCallback, useMemo } from 'react';

interface VersionAttribute {
  heading: string;
  value: string;
}

export const mapToCopiableVersionInfo = (versionAttributes: VersionAttribute[]) => {
  return [
    '{code:none}',
    versionAttributes.map(({ heading, value }) => `${heading}: ${value}`).join('\n'),
    '{code}\n'
  ].join('\n');
};

export default (versionAttributes: VersionAttribute[]) => {
  const [copied, setCopied] = useTimeoutedState(false);
  const copiableVersionInfo = useMemo(
    () => mapToCopiableVersionInfo(versionAttributes),
    [versionAttributes]
  );

  const copyVersionInfo = useCallback(
    (e: React.ClipboardEvent | React.MouseEvent) => {
      e.preventDefault();
      copy(copiableVersionInfo);
      setCopied(true);
    },
    [copiableVersionInfo, setCopied]
  );

  return {
    copyVersionInfo,
    copied
  };
};
