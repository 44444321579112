import { Gutter } from '@gaiads/telia-react-component-library';
import { ProgressBar } from 'common-components/ProgressBar/ProgressBar';
import { Divider } from 'core-components';
import { useTranslation } from 'react-i18next';

const BalanceDetailRow: React.FC<{
  usageLabel: string;
  usageDescription?: string;
  used?: number;
  capacity?: number;
  unlimited: boolean;
  units?: string;
}> = ({ usageLabel, usageDescription, used, capacity, unlimited, units = '' }) => {
  const { t } = useTranslation();
  const infoAvailable = typeof used === 'number' && typeof capacity === 'number';
  if (!infoAvailable) {
    return null;
  }

  return (
    <>
      <ProgressBar
        title={t(usageLabel)}
        titleTooltip={usageDescription ? t(usageDescription) : undefined}
        unlimited={unlimited}
        unlimitedLabel={t('subscriptions.usage.unlimited')}
        values={{ variant: 'calculated', current: used, max: capacity }}
        labelLeft={<b>{t('subscriptions.usage.used', { amount: used, unit: units })}</b>}
        labelRight={`${capacity} ${units}`}
        data-testid="balance-detail-row-progress-bar"
      />

      <Gutter size="sm" />

      <Divider light />

      <Gutter size="sm" />
    </>
  );
};

export default BalanceDetailRow;
