import { History, Path } from 'history';
import { RedirectState } from 'types/navigation';

const redirectToAccessDenied = (
  replace: (path: Path, state?: History.LocationState) => void,
  currentLocation: string,
  pathname: string
): void => {
  replace('/access-denied', {
    referrer: currentLocation,
    pathname
  } as RedirectState);
};

export default redirectToAccessDenied;
