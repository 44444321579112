import { useCallback } from 'react';

export type CookieConsentLanguage = 'en' | 'fi' | 'sv';

const useCookieConsent = () => {
  const initiateConsent = useCallback(() => {
    const element = document.getElementById('onetrust-consent-sdk');
    if (element && !element.classList.contains('active')) {
      element.classList.add('active');
    }
  }, []);

  const changeLanguage = useCallback(
    (lang: CookieConsentLanguage) => {
      window.OneTrust?.changeLanguage(lang);
      initiateConsent();
    },
    [initiateConsent]
  );

  const onConsentChange = useCallback(
    (callback: VoidFunction) => window.OneTrust?.OnConsentChanged(callback),
    []
  );

  const isBannerVisible = useCallback(() => {
    const el = document.getElementById('onetrust-banner-sdk');
    return !!el && el.style.display !== 'none';
  }, []);

  return { initiateConsent, changeLanguage, onConsentChange, isBannerVisible };
};

export { useCookieConsent };
