import { Div, GridBag, Gutter, RadioButton } from '@gaiads/telia-react-component-library';
import { Forms, Heading } from 'common-components';
import ModelTextInput from 'common-components/ModelTextInput/ModelTextInput';
import { useTranslation } from 'react-i18next';

import { BillingGroupFormFields } from './useBillingGroupForm';

const BillingGroupFormPayerInfo: React.FC<{
  fields: BillingGroupFormFields;
  disallowMutation: boolean;
  hasForeignAddress?: boolean;
}> = ({ fields, disallowMutation, hasForeignAddress }) => {
  const { t } = useTranslation();

  return (
    <>
      <GridBag>
        <GridBag.Item md={12}>
          <ModelTextInput
            data-testid="collection-group-form__payer-name"
            label={t('invoices.billingGroup.form.payerInfo.payerNameLabel')}
            inputModel={fields.payerName}
            disabled={disallowMutation}
            hardMaxLength
          />
        </GridBag.Item>

        <GridBag.Item md={12}>
          <ModelTextInput
            data-testid="collection-group-form__reference"
            label={t('invoices.billingGroup.form.payerInfo.referenceLabel')}
            inputModel={fields.reference}
            disabled={disallowMutation}
            hardMaxLength
          />
        </GridBag.Item>
      </GridBag>

      <Gutter size="xlg" />

      <Heading.H4>{t('billingInformation.billingAddress')}</Heading.H4>

      <Gutter size="sm" />

      <GridBag>
        <GridBag.Item md={12} key="collection-group-form__foreign-adress-radio">
          <Div>
            <RadioButton
              data-testid="collection-group-form__foreign-adress-radio-false"
              name="isForeignAddress"
              label={t('invoices.billingGroup.form.payerInfo.adressInFinlandLabel')}
              onChange={() => fields.isForeignAddress.setChecked(false)}
              checked={!fields.isForeignAddress.checked}
            />
          </Div>
          <Div margin={{ top: 'sm' }}>
            <RadioButton
              data-testid="collection-group-form__foreign-adress-radio-true"
              name="isForeignAddress"
              label={t('invoices.billingGroup.form.payerInfo.addressAbroadLabel')}
              onChange={() => fields.isForeignAddress.setChecked(true)}
              checked={fields.isForeignAddress.checked}
            />
          </Div>
        </GridBag.Item>

        {!fields.isForeignAddress.checked && [
          <GridBag.Item md={12} key="collection-group-form__domestic-address-street">
            <ModelTextInput
              data-testid="collection-group-form__domestic-address-street"
              label={t('invoices.billingGroup.form.payerInfo.streetAddressLabel')}
              inputModel={fields.domesticStreetAddress}
              disabled={disallowMutation}
              hardMaxLength
            />
          </GridBag.Item>,

          <GridBag.Item xs={12} sm={5} md={5} key="collection-group-form__domestic-address-zip">
            <ModelTextInput
              data-testid="collection-group-form__domestic-address-zip"
              label={t('invoices.billingGroup.form.payerInfo.postalCodeLabel')}
              inputModel={fields.domesticPostalCode}
              disabled={disallowMutation}
              hardMaxLength
            />
          </GridBag.Item>,

          <GridBag.Item xs={12} sm={7} md={7} key="collection-group-form__domestic-address-city">
            <ModelTextInput
              data-testid="collection-group-form__domestic-address-city"
              label={t('invoices.billingGroup.form.payerInfo.postalOfficeLabel')}
              inputModel={fields.domesticPostalOffice}
              disabled={disallowMutation}
              hardMaxLength
            />
          </GridBag.Item>
        ]}

        {fields.isForeignAddress.checked && [
          !hasForeignAddress && (
            <GridBag.Item md={12} key="collection-group-form__foreign-address-country">
              <ModelTextInput
                data-testid="collection-group-form__foreign-address-country"
                label={t('invoices.billingGroup.form.payerInfo.countryLabel')}
                inputModel={fields.foreignCountry}
                disabled={disallowMutation}
                hardMaxLength
              />
            </GridBag.Item>
          ),

          <GridBag.Item md={12} key="collection-group-form__foreign-address-street">
            <ModelTextInput
              data-testid="collection-group-form__foreign-address-street"
              label={
                hasForeignAddress
                  ? t('invoices.billingGroup.form.payerInfo.foreignStreetAndCityLabel')
                  : t('invoices.billingGroup.form.payerInfo.streetAddressLabel')
              }
              inputModel={fields.foreignStreetAddress}
              disabled={disallowMutation}
              hardMaxLength
            />

            {hasForeignAddress && (
              <Forms.FieldHelperText
                data-testid="collection-group-form__foreign-address-street-helper"
                helperText={t('invoices.billingGroup.form.payerInfo.foreignStreetAndCityHelper')}
              />
            )}
          </GridBag.Item>,
          !hasForeignAddress && [
            <GridBag.Item xs={12} sm={5} md={5} key="collection-group-form__foreign-address-zip">
              <ModelTextInput
                data-testid="collection-group-form__foreign-address-zip"
                label={t('invoices.billingGroup.form.payerInfo.postalCodeLabel')}
                inputModel={fields.foreignPostalCode}
                disabled={disallowMutation}
                hardMaxLength
              />
            </GridBag.Item>,

            <GridBag.Item xs={12} sm={7} md={7} key="collection-group-form__foreign-address-city">
              <ModelTextInput
                data-testid="collection-group-form__foreign-address-city"
                label={t('invoices.billingGroup.form.payerInfo.postalOfficeLabel')}
                inputModel={fields.foreignPostalOffice}
                disabled={disallowMutation}
                hardMaxLength
              />
            </GridBag.Item>
          ]
        ]}
      </GridBag>
    </>
  );
};

export default BillingGroupFormPayerInfo;
