import createCorporatePortalLink from 'doings/links/createCorporatePortalLink/createCorporatePortalLink';
import { mobile } from 'doings/track/analyticsEvents';
import { useTranslation } from 'react-i18next';

import SubscriptionServices, { ServiceList } from './SubscriptionServices';

const AdditionalSubscriptionServices: React.FC<ServiceList> = ({
  services,
  status,
  loading,
  phoneNumberHash,
  disabled = false,
  expanded = false
}) => {
  const { t } = useTranslation();
  const corporatePortalUrl = createCorporatePortalLink(
    'mobileSubscriptionServices',
    { msisdn: phoneNumberHash },
    { section: 'services' }
  );

  return (
    <SubscriptionServices
      testId="additional-subscription-services-accordion"
      data-testid="additional-subscription-services-accordion"
      accordionHeading={t('subscriptions.subscriptionDetails.additionalServices', {
        count: services.length
      })}
      innerHeading={t('subscriptions.subscriptionDetails.activeAdditionalServices')}
      emptyInnerHeading={t('subscriptions.subscriptionDetails.noActiveAdditionalServices')}
      editButtonText={t('subscriptions.subscriptionDetails.editAdditionalServices')}
      corporatePortalUrl={corporatePortalUrl}
      services={services}
      phoneNumberHash={phoneNumberHash}
      status={status}
      loading={loading}
      analyticsEventForEdit={mobile.changeAdditionalServices}
      analyticsEventForExpand={mobile.viewAdditionalServices}
      expanded={expanded}
      disabled={disabled}
    />
  );
};

export default AdditionalSubscriptionServices;
