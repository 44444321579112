import { Flex, Icon, Panel } from '@gaiads/telia-react-component-library';
import getClassNames from 'classnames';
import { Skeleton } from 'common-components';

import { ListViewRowProps } from './ListViewRow';
import styles from './ListViewRowContent.module.scss';

const ListViewRowContent: React.FC<
  ListViewRowProps & {
    clickableAppearance?: boolean;
    isExpanded?: boolean;
  }
> = ({
  transparent,
  padding,
  clickableAppearance,
  hideArrow,
  usesExpandableArrow,
  isExpanded,
  usesQuicklinks,
  quicklinksMenu,
  loading,
  highlighted,
  listType,
  children
}) => {
  if (listType === 'sublist') {
    return (
      <Flex padding={{ ...padding, vertical: 'sm' }}>
        <Flex flexItem pileVerticallyTo="md" centeredVertically>
          {children}
        </Flex>

        <div className={styles.icon} style={{ width: 24, height: 24 }} />
      </Flex>
    );
  }

  const expandableArrow = isExpanded ? 'up' : 'down';
  return (
    <Panel transparent={transparent} clickableAppearance={clickableAppearance}>
      <Panel.Content
        className={getClassNames(styles.content, {
          [styles.content__highlighted]: highlighted
        })}
        padding={padding}
      >
        <Flex
          className={styles.contentInner}
          flexItem
          pileVerticallyTo="md"
          centeredVertically={!transparent}
        >
          {children}
        </Flex>

        <Flex
          className={getClassNames(styles.icon, {
            [styles.icon__quicklinkSpacing]: usesQuicklinks
          })}
        >
          {!usesQuicklinks && (
            <Icon
              data-testid="list-view-row-arrow-right"
              name={`chevron-${usesExpandableArrow ? expandableArrow : 'right'}`}
              color={hideArrow ? 'transparent' : 'purple500'}
              size={usesExpandableArrow ? 'md' : 'sm'}
            />
          )}

          {usesQuicklinks && loading && (
            <Skeleton.Image
              width="32px"
              height="32px"
              rounded
              data-testid="list-view-row-content-image-skeleton"
            />
          )}

          {usesQuicklinks && !loading && quicklinksMenu}
        </Flex>
      </Panel.Content>
    </Panel>
  );
};

export default ListViewRowContent;
