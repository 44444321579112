import { ApolloError } from '@apollo/client';
import ErrorPage from 'B2XApp/Errors/Error/ErrorPage/ErrorPage';
import FullPageLoader from 'common-components/FullPageLoader/FullPageLoader';
import useUser, { LoginStatusType } from 'hooks/useUser/useUser';
import { createContext, useContext, useEffect, useState } from 'react';

export const UserContext = createContext<User | undefined>(undefined);

const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, error, status } = useUser();
  const [currentUser, setCurrentUser] = useState<{
    user?: User;
    error?: ApolloError;
    status: LoginStatusType;
  }>({
    user: undefined,
    error: undefined,
    status: 'pending'
  });

  useEffect(() => {
    if (user) {
      setCurrentUser({ user, status });
    } else if (error) {
      setCurrentUser({ error, status });
    }
  }, [user, error, status]);

  if (currentUser.status === 'pending') {
    return <FullPageLoader loading />;
  }

  if (currentUser.status === 'not logged in') {
    return <ErrorPage error={currentUser.error} defaultType="userContextFault" />;
  }

  return <UserContext.Provider value={currentUser.user}>{children}</UserContext.Provider>;
};

const useUserData = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUserData must be used with UserContext');
  }

  return context;
};

export { UserProvider, useUserData };
