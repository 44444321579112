import { GrowlNotification } from '@gaiads/telia-react-component-library';
import styles from 'B2XApp/TopNotices/FixedBar.module.scss';
import { useUserData } from 'contexts/UserContext/UserContext';
import { useTranslation } from 'react-i18next';

const SNSCustomerBar: React.FC = () => {
  const { t } = useTranslation();
  const { isSNSCompany } = useUserData();
  if (!isSNSCompany) {
    return null;
  }

  return (
    <GrowlNotification
      data-testid="data-handled-in-public-cloud-notification-bar"
      className={styles.fixedBar}
      notification={t('notifications.dataHandledInPublicCloud')}
      position="relative"
      variant="info"
    />
  );
};

export default SNSCustomerBar;
