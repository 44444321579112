import getEnvironmentVariable from 'doings/getEnvironmentVariable/getEnvironmentVariable';
import urlJoin from 'url-join';

const redirectToCompanySelection = (): void => {
  window.location.replace(
    urlJoin(
      getEnvironmentVariable('REACT_APP_ULM_URL'),
      `select-company?redirectUrl=${window.location.href}`
    )
  );
};

export default redirectToCompanySelection;
