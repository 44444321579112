import { GridBag, Gutter, Page } from '@gaiads/telia-react-component-library';
import { Icon } from '@teliafi/fi-ds';
import {
  Button,
  DataPanel,
  DefinitionList,
  Heading,
  InlineNotification,
  PageWithSubnavigation
} from 'common-components';
import { Flex } from 'core-components';
import versionInfo from 'manifest.json';
import { useTranslation } from 'react-i18next';

import useCopyVersionInfo from './useCopyVersionInfo';

const Version: React.FC = () => {
  const { t } = useTranslation();
  const info = [
    {
      i18nKey: 'version.branchName',
      testId: 'version-info-branch-name',
      value: versionInfo.branchName
    },
    {
      i18nKey: 'version.buildDate',
      testId: 'version-info-build-date',
      value: versionInfo.buildDate
    },
    {
      i18nKey: 'version.commitHash',
      testId: 'version-info-commit-hash',
      value: versionInfo.commitHash
    },
    {
      i18nKey: 'version.commitDate',
      testId: 'version-info-commit-date',
      value: versionInfo.commitDate
    },
    {
      i18nKey: 'version.packageVersion',
      testId: 'version-info-package-version',
      value: versionInfo.packageVersion
    }
  ].map(({ i18nKey, testId, value }) => ({ heading: t(i18nKey), testId, value }));
  const { copyVersionInfo, copied } = useCopyVersionInfo(info);

  return (
    <PageWithSubnavigation withoutCompanySelector data-testid="version-page">
      <Page.Row margin={{ bottom: 'md' }}>
        <InlineNotification
          data-testid="version-info-copy-notice"
          variant={copied ? 'success' : 'information'}
          content={
            copied
              ? t('version.clipboard.copyNoticeOnCopied')
              : t('version.clipboard.copyNoticeInitial')
          }
        />
      </Page.Row>

      <Page.Row onCopy={copyVersionInfo} data-testid="version-page--content">
        <GridBag>
          <GridBag.Item lg={6} md={12}>
            <DataPanel>
              <Flex occupyHorizontalSpace spaceBetween pileVerticallyTo="sm">
                <Heading.H2>{t('version.pageHeader')}</Heading.H2>

                <Gutter size="xs" />

                <Button
                  data-testid="version-page--content--copy-button"
                  size="sm"
                  variant="secondary"
                  onClick={copyVersionInfo}
                >
                  <Icon name="copy" size="xs" />

                  {t('version.clipboard.copyButton')}
                </Button>
              </Flex>

              <Gutter />

              <DefinitionList addDividers alignRight>
                {info.map(({ heading, testId, value }) => (
                  <DefinitionList.Item key={testId} heading={heading} data-testid={testId}>
                    <span>{value}</span>
                  </DefinitionList.Item>
                ))}
              </DefinitionList>
            </DataPanel>
          </GridBag.Item>
        </GridBag>
      </Page.Row>
    </PageWithSubnavigation>
  );
};

export default Version;
