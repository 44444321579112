import { TicketStatusTag } from 'B2XApp/Messages/common';
import { ListView } from 'common-components';
import { useEditableListData } from 'contexts/EditableListContext/EditableListContext';
import { EN_DASH } from 'doings/dash/dash';
import formatDatetime from 'doings/formatDatetime/formatDatetime';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { ColumnVisibility, ListViewColumnSetup } from 'types/listConfig';
import { ServiceRequestsListItem } from 'types/serviceRequest';

export const ServiceRequestsListViewColumnSetup: ListViewColumnSetup<[TFunction, boolean]> = {
  listId: 'serviceRequests',
  getColumns: (t: TFunction, showCompanyByDefault: boolean) => ({
    created: {
      width: '0.75x',
      heading: t('serviceRequests.list.columns.creationDate'),
      testidSuffix: 'created',
      baseVisibility: ColumnVisibility.SHOW
    },
    updated: {
      width: '0.75x',
      heading: t('serviceRequests.list.columns.updatedDate'),
      testidSuffix: 'updated',
      baseVisibility: ColumnVisibility.SHOW
    },
    subject: {
      width: '2x',
      heading: t('serviceRequests.list.columns.name'),
      testidSuffix: 'subject',
      baseVisibility: ColumnVisibility.REQUIRE
    },
    subscription: {
      heading: t('serviceRequests.list.columns.subscription'),
      testidSuffix: 'subscription',
      baseVisibility: ColumnVisibility.SHOW
    },
    status: {
      heading: t('serviceRequests.list.columns.status'),
      testidSuffix: 'status',
      baseVisibility: ColumnVisibility.SHOW
    },
    id: {
      heading: t('serviceRequests.list.columns.id'),
      testidSuffix: 'id',
      baseVisibility: ColumnVisibility.REQUIRE
    },
    company: {
      heading: t('common.company.label'),
      tooltip: t('common.company.tooltip'),
      testidSuffix: 'company',
      baseVisibility: showCompanyByDefault ? ColumnVisibility.SHOW : ColumnVisibility.HIDE
    }
  })
};

const ServiceRequestsListView: React.FC<{
  serviceRequests?: readonly ServiceRequestsListItem[];
  loading?: boolean;
}> = ({ serviceRequests, loading }) => {
  const { t } = useTranslation();
  const { columns } = useEditableListData();

  return (
    <ListView
      data-testid={`service-requests-list-${loading ? 'loading' : 'loaded'}`}
      columns={columns}
      mobileLayout={{
        titleRow: { col: 'subject' },
        rows: [
          { col: 'created' },
          { col: 'updated' },
          { col: 'id' },
          { col: 'subscription' },
          { col: 'company' },
          { col: 'status', noLabel: true, padding: 'xs' }
        ]
      }}
      loading={loading}
      listData={serviceRequests ?? []}
      noItemsNotice={t('serviceRequests.list.noItemsFound')}
    >
      {({
        id,
        caseNumber,
        creationDate,
        lastUpdate,
        status,
        name,
        subscription,
        companyCode,
        companyName
      }) => (
        <ListView.Row
          data-testid={`service-requests-list-row-item-${status}`}
          linkTo={`/service-requests/${id}`}
        >
          <ListView.RowShape
            cells={{
              created: {
                data: formatDatetime(creationDate),
                props: {}
              },
              updated: {
                data: formatDatetime(lastUpdate),
                props: {}
              },
              subject: {
                data: name,
                props: { wordWrap: 'break-word' }
              },
              subscription: {
                data: subscription || EN_DASH,
                props: { wordWrap: 'anywhere' }
              },
              status: {
                data: <TicketStatusTag status={status} />,
                props: {}
              },
              id: {
                data: caseNumber,
                props: { wordWrap: 'anywhere' }
              },
              company: {
                data: companyCode || companyName,
                props: { tooltip: `${companyCode} ${companyName}`, wordWrap: 'ellipsis' }
              }
            }}
          />
        </ListView.Row>
      )}
    </ListView>
  );
};

export default ServiceRequestsListView;
