import { InlineNotification } from 'common-components';
import { DateFormats, formatDatetime } from 'doings/formatDatetime';

export const OngoingTerminatedOwnershipChangeNotice: React.FC<{ terminationDate: string }> = ({
  terminationDate
}) => (
  <InlineNotification
    title={{ key: 'subscriptions.subscriptionDetails.status.ownershipChange.ongoingTerminated' }}
    content={{
      key: 'subscriptions.subscriptionDetails.status.ownershipChange.ongoingTerminatedMessage',
      args: { terminationDate: formatDatetime(terminationDate, DateFormats.DATE_NATIONAL_SHORT) }
    }}
    variant="information"
    data-testid="ownership-change-ongoing-terminated-notification"
  />
);
