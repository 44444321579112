import { ApolloError } from '@apollo/client';
import { filter, sort, uniq } from 'fp-ts/lib/Array';
import { flow } from 'fp-ts/lib/function';
import { Eq, Ord } from 'fp-ts/lib/string';
import { GraphQLFormattedError } from 'graphql';

const defaultTo = (def: string) => (str: string) => str.length > 0 ? str : def;

const isNotEmpty = (str: string): boolean => str.length > 0;

const isString = (value: unknown): value is string => typeof value === 'string';

const join = (delimiter: string) => (strs: string[]) => strs.join(delimiter);

const joinProcessed: (strs: string[]) => string = flow(
  filter(isNotEmpty),
  uniq(Eq),
  sort(Ord),
  join(',')
);

const graphQLErrorToCodes = (error: GraphQLFormattedError): string =>
  (isString(error.extensions?.code) && error.extensions?.code) || '';

const getApolloErrorCodes = (error: ApolloError): string =>
  joinProcessed(error.graphQLErrors.map(graphQLErrorToCodes));

const getCodes = (error: ApolloError): string => defaultTo('no-code')(getApolloErrorCodes(error));

const getEventAction = (error: ApolloError): string => `${getCodes(error)}`;

export default getEventAction;
