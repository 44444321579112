/**
 * Chains two to many event handlers. If any event handler in the
 * chain stops event propagation, the rest of the event handlers will
 * not fire.
 *
 * Useful in situations where there is a need to check for some
 * precondition before we enable further event handlers to fire, e.g.
 * checking whether user selection exists in order to avoid tracking
 * and following a link, should the user want to copy text inside the
 * link instead.
 *
 * @param eventHandlers Event handlers to chain together.
 * @returns A decorated event handler.
 */
export default (eventHandlers: React.EventHandler<React.UIEvent>[]) => (e: React.UIEvent) => {
  eventHandlers.forEach((eventHandler) => {
    if (!e.isPropagationStopped()) {
      eventHandler(e);
    }
  });
};
