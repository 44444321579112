import { Div, Gutter } from '@gaiads/telia-react-component-library';
import { Divider } from 'core-components';
import { AskForHelpAdditionalLink } from 'hooks/askForHelp/useAskForHelp';
import Markdown from 'markdown-to-jsx';
import React from 'react';

import AdditionalLinks from './AdditionalLinks';
import styles from './Answer.module.scss';

const Answer: React.FC<{
  answer: string;
  additionalLinks?: AskForHelpAdditionalLink[];
  'data-testid': string;
}> = ({ answer, additionalLinks, 'data-testid': testId }) => {
  return (
    <Div padding={{ bottom: 'lg' }} data-testid={testId} className={styles.answer}>
      <Markdown
        options={{
          wrapper: React.Fragment,
          forceBlock: true,
          disableParsingRawHTML: true,
          overrides: {
            p: {
              props: {
                className: styles.answer_paragraph
              }
            },
            a: {
              props: {
                target: '_blank'
              }
            }
          }
        }}
      >
        {answer}
      </Markdown>

      {additionalLinks && additionalLinks.length > 0 && (
        <AdditionalLinks links={additionalLinks} data-testid={`${testId}--additional-links`} />
      )}

      <Gutter />

      <Divider />
    </Div>
  );
};

export default Answer;
