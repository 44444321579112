import isInteger from 'lodash/isInteger';

const FRACTION_DIGITS = 2;

const dropInsignificantFractionDigits = (price: number) =>
  parseFloat(price.toFixed(FRACTION_DIGITS));

const digitsFor = (price: number) =>
  isInteger(dropInsignificantFractionDigits(price)) ? 0 : FRACTION_DIGITS;

const formatNumberPrice = (price: number) =>
  price.toLocaleString('fi', {
    minimumFractionDigits: digitsFor(price),
    maximumFractionDigits: digitsFor(price)
  });

const formatPrice = (price: number | null | undefined) => formatNumberPrice(price ?? 0);

export default formatPrice;
