import { getSectionDefinition } from 'B2XApp/Dashboard/DashboardNavigation/DashboardSections/sections/getSectionDefinition';
import { UserSectionDefinition } from 'B2XApp/Dashboard/DashboardNavigation/DashboardSections/sections/sectionDefinition';
import { getDashboardSections } from 'B2XApp/Dashboard/getDashboardSections';
import { DashboardSectionId } from 'B2XApp/Dashboard/sections';
import getNavigationalLinks, {
  NavigationalLinks
} from 'doings/getNavigationalLinks/getNavigationalLinks';

const shouldExpand = (
  section: UserSectionDefinition,
  sectionParam: string | null,
  firstSectionToExpand?: UserSectionDefinition
) => {
  if (section.links.length <= 1) {
    return false;
  }

  if (sectionParam) {
    return section.id === sectionParam;
  }

  if (section.id === DashboardSectionId.TeliaAccountManagement) {
    return false;
  }

  return section.id === firstSectionToExpand?.id;
};

const resolveUserSection = (
  section: DashboardSectionId,
  allLinks: NavigationalLinks,
  user: User
): Omit<UserSectionDefinition, 'expandable' | 'expanded' | 'shouldScroll'> | undefined => {
  const resolvedSection = getSectionDefinition(section);
  if (!resolvedSection) {
    return undefined;
  }

  const { getLinks, ...rest } = resolvedSection;
  return {
    ...rest,
    links: resolvedSection?.getLinks(allLinks, user)
  };
};

export const getOrderedAndExpandedSections = (
  user: User,
  sectionParam: string | null,
  notificationsLoaded: boolean
): UserSectionDefinition[] => {
  const { navigationalLinks } = getNavigationalLinks(user, true);
  const sectionsWithLinks = getDashboardSections(user)
    .map((section) => resolveUserSection(section, navigationalLinks, user))
    .filter((section) => !!section && section.links.length > 0) as UserSectionDefinition[];

  return sectionsWithLinks.map((section) => ({
    ...section,
    expandable: section.links.length > 1,
    expanded: shouldExpand(
      section,
      sectionParam,
      sectionsWithLinks.length > 0 ? sectionsWithLinks[0] : undefined
    ),
    shouldScroll: notificationsLoaded && section.id === sectionParam
  }));
};
