import { DateFormats } from 'doings/formatDatetime/DateFormats';
import toDate from 'doings/formatDatetime/toDate';
import isWordLetterAndNumbers from 'doings/isWordLetterAndNumbers/isWordLetterAndNumbers';
import useReadQueryParams from 'hooks/useQueryParams/useReadQueryParams';
import { useToggleStateWithQueryParameter } from 'hooks/useToggleState/useToggleState';
import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownOptionDefinition, SearchOptionDefinition } from 'types/listFilters';
import { FilterableOrderStatus } from 'types/orders';

export type OrderQueryParameters = {
  limit?: number;
  offset?: number;
  orderDateFrom?: Date;
  orderDateTo?: Date;
  orderNumber?: string;
  orderStatus?: string;
  allCompanies?: boolean;
};

export type OrderFilters = {
  queryParameters: OrderQueryParameters;
  queryParametersAbsent: boolean;
  searchFieldOptions: SearchOptionDefinition[];
  statusFilterOptions: DropdownOptionDefinition[];
  searchTermValid: boolean;
  allCompanySearch: {
    showFromAllCompanies: boolean;
    toggleAllCompanies: VoidFunction;
  };
};

const getSearchFieldOptions = (t: TFunction): SearchOptionDefinition[] => [
  {
    value: 'orderNumber',
    label: t('orders.list.columns.orderId'),
    tooltip: t('orders.searchOptions.orderNumberInstruction')
  }
];

const validateSearchTerm = (scope?: string, search?: string) => {
  if (scope === 'orderNumber' && !!search) {
    return [isWordLetterAndNumbers].every((predicate) => predicate(search));
  }

  return true;
};

const removeSpaces = (value: string) => value.replace(/\s/g, '');

const mapTranslationKey = (key: string) => key.charAt(0).toLowerCase() + key.slice(1);

const getStatusFilterOptions = (t: TFunction): DropdownOptionDefinition[] =>
  FilterableOrderStatus.map((status) => ({
    label: t(`orders.statuses.${mapTranslationKey(removeSpaces(status))}`),
    value: status
  }));

export default (initialAllCompanies = false) => {
  const { t } = useTranslation();
  const searchFieldOptions = useMemo(() => getSearchFieldOptions(t), [t]);
  const statusFilterOptions = useMemo(() => getStatusFilterOptions(t), [t]);

  const queryParams = useReadQueryParams([
    'from',
    'to',
    'scope',
    'search',
    'status',
    'allCompanies'
  ]);
  const queryParametersAbsent = !(
    !!queryParams.from ||
    !!queryParams.to ||
    !!queryParams.search ||
    !!queryParams.status ||
    !!queryParams.allCompanies
  );

  const [showFromAllCompanies, toggleAllCompanies] = useToggleStateWithQueryParameter(
    initialAllCompanies,
    'allCompanies'
  );

  const searchTermValid = validateSearchTerm(queryParams.scope, queryParams.search);

  return {
    queryParameters: {
      [queryParams.scope ?? '']: queryParams.search,
      orderDateFrom: toDate(DateFormats.DATE_NATIONAL)(queryParams.from),
      orderDateTo: toDate(DateFormats.DATE_NATIONAL)(queryParams.to),
      orderStatus: queryParams.status
    },
    searchFieldOptions,
    statusFilterOptions,
    searchTermValid,
    queryParametersAbsent,
    allCompanySearch: {
      showFromAllCompanies,
      toggleAllCompanies
    }
  };
};
