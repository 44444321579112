import ConfirmationPanelSuccess from 'common-components/ConfirmationPanel/ConfirmationPanelSuccess';
import { useTranslation } from 'react-i18next';

import CreateTicketPageContainer from './CreateTicketPageContainer';

const CreateTicketPageSuccess: React.FC<{
  backToPath: string;
  backToLabel: string;
}> = ({ backToLabel, backToPath }) => {
  const { t } = useTranslation();

  return (
    <CreateTicketPageContainer>
      <ConfirmationPanelSuccess
        data-testid="send-trouble-ticket-success"
        title={t('tickets.newTicket.success')}
        description={t('tickets.newTicket.successDescription')}
        backToPath={backToPath}
        backToLabel={backToLabel}
      />
    </CreateTicketPageContainer>
  );
};

export default CreateTicketPageSuccess;
