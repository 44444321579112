import { GrowlNotification } from '@gaiads/telia-react-component-library';
import styles from 'B2XApp/TopNotices/FixedBar.module.scss';
import { useUserData } from 'contexts/UserContext/UserContext';
import { useTranslation } from 'react-i18next';

const ImpersonationBar: React.FC = () => {
  const { t } = useTranslation();
  const { name, impersonatedBy } = useUserData();
  if (!impersonatedBy) {
    return null;
  }

  return (
    <GrowlNotification
      data-testid="impersonation-notification-bar"
      className={styles.fixedBar}
      notification={t('impersonating', { name })}
      ctaText={t('stopImpersonating')}
      ctaTo={`${process.env.PUBLIC_URL}/api/logout?stopImpersonating=true`}
      position="relative"
      icon="register"
      variant="info"
    />
  );
};

export default ImpersonationBar;
