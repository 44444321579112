import { ActivityLogResponse } from 'types/activityLog';

export default (
  previousResult: ActivityLogResponse,
  { fetchMoreResult }: { fetchMoreResult?: ActivityLogResponse }
): ActivityLogResponse =>
  !fetchMoreResult
    ? previousResult
    : {
        activityLog: {
          total: fetchMoreResult.activityLog.total,
          interactions: [
            ...previousResult.activityLog.interactions,
            ...fetchMoreResult.activityLog.interactions
          ]
        }
      };
