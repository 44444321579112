import { Div, Flex, Icon } from '@gaiads/telia-react-component-library';
import { DashboardSectionLink } from 'B2XApp/Dashboard/DashboardNavigation/DashboardSections/DashboardSectionLink';
import { Heading } from 'common-components';
import blockStyles from 'common-components/CollapsibleBlock/CollapsibleBlock.module.scss';
import { NavigationalLink } from 'doings/getNavigationalLinks/getNavigationalLinks';
import isCorporatePortalOrUlmUrl from 'doings/isCorporatePortalOrUlmUrl/isCorporatePortalOrUlmUrl';
import { useTranslation } from 'react-i18next';

import styles from './DashboardSectionStandalone.module.scss';
import { UserSectionDefinition } from './sections/sectionDefinition';

export const DashboardSectionStandalone = ({ section }: { section: UserSectionDefinition }) => {
  const { t } = useTranslation();

  return (
    <Div className={[styles.standaloneLink, blockStyles.blockHeader]}>
      {section.links.map((sectionLink: NavigationalLink) => (
        <DashboardSectionLink
          data-testid={`section-${sectionLink.id}`}
          color="black"
          sectionLink={sectionLink}
          key={sectionLink.id}
          isStandalone
        >
          <Flex
            className={styles.link_background}
            centeredVertically
            spaceBetween
            padding={{ size: 'sm' }}
          >
            <Flex centeredVertically flexItem>
              <Icon
                className={[styles.link_icon, blockStyles.blockWithIcon_icon]}
                name={section.icon}
                size="md"
                block
                margin={{ right: 'sm' }}
              />

              <Heading.H4>{t(sectionLink.textI18nKey)}</Heading.H4>
            </Flex>

            {sectionLink.external && !isCorporatePortalOrUlmUrl(sectionLink.to) && (
              <Icon name="external" size="md" margin={{ left: 'xs' }} />
            )}
          </Flex>
        </DashboardSectionLink>
      ))}
    </Div>
  );
};
