import { Divider, Flex } from 'core-components';

import styles from './ListViewDivider.module.scss';

const ListViewDivider: React.FC = () => {
  return (
    <Flex className={styles.divider}>
      <Divider />
    </Flex>
  );
};

export default ListViewDivider;
