import { Image } from '@gaiads/telia-react-component-library';
import { useTranslation } from 'react-i18next';

import noDataFetchIcon from './no-data-fetch-icon.svg';

const LoadingErrorImage: React.FC<{
  smallSize?: boolean;
}> = ({ smallSize = false }) => {
  const { t } = useTranslation();

  return (
    <Image
      data-testid="loading-error-image"
      src={noDataFetchIcon}
      style={smallSize ? { width: '33px', height: '33px' } : { width: '86px', height: '86px' }}
      alt={t('dataLoadError.imageAltDescription')}
    />
  );
};

export default LoadingErrorImage;
