import { useState } from 'react';
import { SortOrder } from 'types/sort';

const useTicketMessagesOrder = () => {
  const [order, setOrder] = useState<SortOrder>(SortOrder.DESC);
  const isOrderAsc = order === SortOrder.ASC;

  const toggleOrder = () => {
    setOrder(isOrderAsc ? SortOrder.DESC : SortOrder.ASC);
  };

  return { order, isOrderAsc, toggleOrder };
};

export { useTicketMessagesOrder };
