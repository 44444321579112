import useBillingAccountEditContactFields, {
  BillingGroupFormContactFields
} from 'B2XApp/Invoicing/BillingGroups/Common/useBillingAccountEditContactFields';
import useBillingAccountEditFinvoiceFields, {
  FinvoiceFields
} from 'B2XApp/Invoicing/BillingGroups/Common/useBillingAccountEditFinvoiceFields';
import { useUserData } from 'contexts/UserContext/UserContext';
import deriveBicCodeFromIban from 'doings/deriveBicCodeFromIban/deriveBicCodeFromIban';
import { multiplex } from 'doings/multiplex/multiplex';
import useCheckboxModel, {
  CheckboxModel
} from 'hooks/inputModels/useCheckboxModel/useCheckboxModel';
import useDropdownInputModel, {
  DropdownInputModel
} from 'hooks/inputModels/useDropdownInputModel/useDropdownInputModel';
import useTextInputModel, {
  TextInputModel
} from 'hooks/inputModels/useTextInputModel/useTextInputModel';
import useValidatableTextInputModel from 'hooks/inputModels/useValidatableTextInputModel/useValidatableTextInputModel';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BillMillBillingAccount } from 'types/billMillAccount';
import { AvailableBank, AvailableOperator } from 'types/collection-group';

export type BillMillAccountFormStep = 'initial' | 'persisting' | 'success' | 'failure';

export type UseBillMillAccountStepsResult = {
  step: BillMillAccountFormStep;
  setStep: Dispatch<SetStateAction<BillMillAccountFormStep>>;
};

export type UseBillMillAccountFormResult = {
  form: BillMillAccountFormData;
};

export type BillMillAccountFormData = {
  step: BillMillAccountFormStep;
  setStep: Dispatch<SetStateAction<BillMillAccountFormStep>>;
  fields: BillMillAccountFormFields;
  finvoiceFields?: FinvoiceFields;
  finvoiceAvailable: boolean;
  isValid: boolean;
  onSave: VoidFunction;
  onCancel: VoidFunction;
};

export type UseBillMillAccountFormFieldsResult = {
  fields: BillMillAccountFormFields;
  finvoiceAvailable: boolean;
  finvoiceFields?: FinvoiceFields;
  isValid: boolean;
  marshallRequestData: () => ReturnType<typeof marshallRequestData>;
};

export type BillMillAccountFormFields = {
  companyCode: TextInputModel;
  payerName: TextInputModel;
  reference: TextInputModel;
  address: TextInputModel;
  addressSupplement: TextInputModel;
  postalCode: TextInputModel;
  postalOffice: TextInputModel;
  billingLanguage: DropdownInputModel;
  billingType: DropdownInputModel;
  email1: TextInputModel;
  email2: TextInputModel;
  elaskuIban: TextInputModel;
  elaskuBic: DropdownInputModel;
  hasEbillAgreement: CheckboxModel;
  elmaOvtCode: TextInputModel;
  elmaOperator: DropdownInputModel;
  suoramaksuPaymentMethod: DropdownInputModel;
  smsInvoicePhoneNumber: TextInputModel;
  finvoiceOrderId: TextInputModel;
  finvoiceBuyerReference: TextInputModel;
  finvoiceAgreementId: TextInputModel;
  finvoiceAccountDimension: TextInputModel;
  finvoiceTenderReference: TextInputModel;
  finvoiceCostAccount: TextInputModel;
  finvoiceAccountProposal: TextInputModel;
  finvoiceProjectNumber: TextInputModel;
} & BillingGroupFormContactFields;

const validateFields = (fields: BillMillAccountFormFields): boolean => {
  const billingType = fields.billingType.value;
  return (
    validateBillingFields(fields, billingType) &&
    allFieldsValid([
      fields.payerName,
      fields.reference,
      fields.address,
      fields.addressSupplement,
      fields.postalCode,
      fields.postalOffice,
      fields.billingLanguage,
      fields.billingType,
      fields.smsInvoicePhoneNumber,
      fields.contactFirstName,
      fields.contactLastName,
      fields.contactEmail,
      fields.contactPhone,
      fields.finvoiceOrderId,
      fields.finvoiceBuyerReference,
      fields.finvoiceAgreementId,
      fields.finvoiceAccountDimension,
      fields.finvoiceTenderReference,
      fields.finvoiceCostAccount,
      fields.finvoiceAccountProposal,
      fields.finvoiceProjectNumber
    ])
  );
};

const validateBillingFields = (fields: BillMillAccountFormFields, billingType: string) => {
  return (
    (billingType !== 'EMAIL' || fields.email1.isValid) &&
    (billingType !== 'EMAIL' || fields.email2.isValid) &&
    (billingType !== 'ELASKU' || fields.elaskuBic.isValid) &&
    (billingType !== 'ELASKU' || fields.elaskuIban.isValid) &&
    (billingType !== 'ELASKU' || fields.hasEbillAgreement.isValid) &&
    (billingType !== 'ELMA' || fields.elmaOperator.isValid) &&
    (billingType !== 'ELMA' || fields.elmaOvtCode.isValid) &&
    (billingType !== 'SUORAMAKSU' || fields.suoramaksuPaymentMethod.isValid)
  );
};

const allFieldsValid = (fields: TextInputModel[]) => fields.every((field) => field.isValid);

const marshallRequestData = ({
  contactLanguage,
  fields,
  finvoiceAvailable,
  billingTypeChangeAvailable
}: {
  contactLanguage: string;
  fields: BillMillAccountFormFields;
  finvoiceAvailable: boolean;
  billingTypeChangeAvailable: boolean;
}) => {
  const billingType = fields.billingType.value;
  return {
    payerName: fields.payerName.value,
    reference: fields.reference.value,
    address: {
      address: fields.address.value,
      addressSupplement: fields.addressSupplement.value,
      postalOffice: fields.postalOffice.value,
      postalCode: fields.postalCode.value
    },
    language: fields.billingLanguage.value,
    billingInfo: {
      ...(billingTypeChangeAvailable
        ? {
            billingType,
            ...(billingType === 'EMAIL'
              ? {
                  email1: fields.email1.value,
                  email2: fields.email2.value
                }
              : {}),
            ...(billingType === 'ELASKU'
              ? {
                  iban: fields.elaskuIban.value,
                  bic: fields.elaskuBic.value
                }
              : {}),
            ...(billingType === 'ELMA'
              ? {
                  operator: fields.elmaOperator.value,
                  ovt: fields.elmaOvtCode.value
                }
              : {})
          }
        : {
            ...(billingType === 'SUORAMAKSU'
              ? {
                  billingType,
                  paymentMethod: fields.suoramaksuPaymentMethod.value
                }
              : {})
          }),
      phoneNumber: fields.smsInvoicePhoneNumber.value
    },
    changeRequestContact: {
      firstName: fields.contactFirstName.value,
      lastName: fields.contactLastName.value,
      phone: fields.contactPhone.value,
      email: fields.contactEmail.value,
      language: contactLanguage.toUpperCase()
    },
    ...(finvoiceAvailable
      ? {
          finvoice: {
            orderIdentifier: fields.finvoiceOrderId.value,
            buyerReferenceIdentifier: fields.finvoiceBuyerReference.value,
            agreementIdentifier: fields.finvoiceAgreementId.value,
            accountDimensionText: fields.finvoiceAccountDimension.value,
            tenderReference: fields.finvoiceTenderReference.value,
            proposedAccountText: fields.finvoiceCostAccount.value,
            normalProposedAccountIdent: fields.finvoiceAccountProposal.value,
            projectReferenceIdentifier: fields.finvoiceProjectNumber.value
          }
        }
      : {})
  };
};

const switchableBillingTypes = ['EMAIL', 'ELASKU', 'ELMA'];

const useBillMillAccountForm = ({
  billMillAccount,
  availableBanks,
  availableOperators,
  finvoiceAvailable
}: {
  billMillAccount?: BillMillBillingAccount;
  availableBanks: AvailableBank[];
  availableOperators: AvailableOperator[];
  finvoiceAvailable: boolean;
}): UseBillMillAccountFormFieldsResult => {
  const { t } = useTranslation();
  const { language: contactLanguage } = useUserData();

  const billingType = billMillAccount?.billing.invoicingType;
  const paymentMethod = billMillAccount?.billing.paymentMethod;
  const billingTypeChangeAvailable = billingType !== 'SUORAMAKSU';

  const billingTypes = [
    ...(billingType && !switchableBillingTypes.includes(billingType) ? [billingType] : []),
    ...switchableBillingTypes
  ];

  const suoramaksuPaymentMethod = multiplex([
    '',
    [billingType === 'SUORAMAKSU' && paymentMethod === 'EMAIL', 'EMAIL'],
    [billingType === 'SUORAMAKSU' && paymentMethod !== 'EMAIL', 'EPL']
  ]);

  const fields: BillMillAccountFormFields = {
    companyCode: useTextInputModel({
      initialValue: billMillAccount?.customerCode,
      hidden: true
    }),
    payerName: useValidatableTextInputModel({
      initialValue: billMillAccount?.payer.name,
      required: false,
      expecting: {
        maxLength: 100
      }
    }),
    reference: useValidatableTextInputModel({
      initialValue: billMillAccount?.payer.nameSupplement,
      required: false,
      expecting: {
        maxLength: 100
      }
    }),
    address: useValidatableTextInputModel({
      initialValue: billMillAccount?.payer.address.address,
      required: false,
      expecting: {
        maxLength: 100
      }
    }),
    addressSupplement: useValidatableTextInputModel({
      initialValue: billMillAccount?.payer.address.addressSupplement,
      required: false,
      expecting: {
        maxLength: 100
      }
    }),
    postalCode: useValidatableTextInputModel({
      initialValue: billMillAccount?.payer.address.postalCode,
      required: false,
      expecting: {
        a: 'nakPostalCode',
        maxLength: 5
      }
    }),
    postalOffice: useValidatableTextInputModel({
      initialValue: billMillAccount?.payer.address.postalOffice,
      required: false,
      expecting: {
        maxLength: 100
      }
    }),
    billingLanguage: useDropdownInputModel({
      initialValue:
        billMillAccount?.billing.language?.toUpperCase() || contactLanguage.toUpperCase(),
      required: true,
      options: [
        {
          label: t('languages.fi'),
          value: 'FI'
        },
        {
          label: t('languages.sv'),
          value: 'SV'
        },
        {
          label: t('languages.en'),
          value: 'EN'
        }
      ]
    }),
    billingType: useDropdownInputModel({
      initialValue: billingType,
      required: true,
      disabled: !billingTypeChangeAvailable,
      options: [
        ...billingTypes.map((type) => ({
          label: t(`invoices.billingGroup.billingType.${type}`),
          value: type
        }))
      ]
    }),

    email1: useValidatableTextInputModel({
      initialValue: billMillAccount?.billing.email,
      required: true,
      disabled: billingType === 'SUORAMAKSU' && paymentMethod !== 'EMAIL',
      expecting: {
        a: 'emailAddress',
        maxLength: 50
      }
    }),
    email2: useValidatableTextInputModel({
      initialValue: billMillAccount?.billing.email2,
      required: false,
      disabled: billingType === 'SUORAMAKSU' && paymentMethod !== 'EMAIL',
      expecting: {
        a: 'emailAddress',
        maxLength: 50
      }
    }),

    elaskuIban: useValidatableTextInputModel({
      initialValue: billingType === 'ELASKU' ? billMillAccount?.billing.iban : '',
      required: true,
      disabled: !billingTypeChangeAvailable,
      expecting: {
        a: 'billingFinnishIBAN',
        maxLength: 18
      }
    }),
    elaskuBic: useDropdownInputModel({
      initialValue: billingType === 'ELASKU' ? billMillAccount?.billing.bic : '',
      required: true,
      disabled: !billingTypeChangeAvailable,
      options: availableBanks.map((bank) => ({
        label: `${bank.name} (${bank.bic})`,
        value: bank.bic
      }))
    }),
    hasEbillAgreement: useCheckboxModel({
      initialValue: billingType === 'ELASKU',
      required: billingType !== 'ELASKU',
      disabled: !billingTypeChangeAvailable
    }),

    elmaOvtCode: useValidatableTextInputModel({
      initialValue: billingType === 'ELMA' ? billMillAccount?.billing.ovt : '',
      required: true,
      disabled: !billingTypeChangeAvailable,
      expecting: {
        a: 'billingFinnishOvtCode',
        maxLength: 17
      }
    }),
    elmaOperator: useDropdownInputModel({
      initialValue: billingType === 'ELMA' ? billMillAccount?.billing.operator : '',
      required: true,
      disabled: !billingTypeChangeAvailable,
      options: availableOperators.map((operator) => ({
        label: `${operator.name} (${operator.code})`,
        value: operator.name
      }))
    }),

    suoramaksuPaymentMethod: useDropdownInputModel({
      initialValue: suoramaksuPaymentMethod,
      required: true,
      disabled: billingType !== 'SUORAMAKSU',
      options: [
        ...['EPL', 'EMAIL'].map((type) => ({
          label: t(`billingInformation.billingMethods.${type}`),
          value: type
        }))
      ]
    }),

    smsInvoicePhoneNumber: useValidatableTextInputModel({
      initialValue: billMillAccount?.billing.smsReminderMsisdn,
      required: false,
      expecting: {
        a: 'msisdn',
        maxLength: 25
      }
    }),

    ...useBillingAccountEditContactFields(),
    ...useBillingAccountEditFinvoiceFields(billMillAccount?.finvoice)
  };

  const { payerName, address, postalCode } = fields;
  const payerFields = [payerName, address, postalCode];
  const payerRequired = payerFields.some((field) => !!field.value);
  const fieldsRequiredParamHasChanged = payerFields.some(
    (field) => field.required !== payerRequired
  );
  useEffect(() => {
    if (fieldsRequiredParamHasChanged) {
      payerFields.forEach((field) => field.setRequired(payerRequired));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payerRequired]);

  useEffect(() => {
    const bicCode = deriveBicCodeFromIban(fields.elaskuIban.value);
    if (bicCode !== null) {
      fields.elaskuBic.setValue(bicCode);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.elaskuIban.value]);

  const isValid = validateFields(fields);

  return {
    fields,
    finvoiceAvailable,
    finvoiceFields: {
      finvoiceOrderId: fields.finvoiceOrderId,
      finvoiceBuyerReference: fields.finvoiceBuyerReference,
      finvoiceAgreementId: fields.finvoiceAgreementId,
      finvoiceAccountDimension: fields.finvoiceAccountDimension,
      finvoiceTenderReference: fields.finvoiceTenderReference,
      finvoiceCostAccount: fields.finvoiceCostAccount,
      finvoiceAccountProposal: fields.finvoiceAccountProposal,
      finvoiceProjectNumber: fields.finvoiceProjectNumber
    },
    isValid,
    marshallRequestData: () => {
      return marshallRequestData({
        contactLanguage,
        fields,
        finvoiceAvailable,
        billingTypeChangeAvailable
      });
    }
  };
};

export default useBillMillAccountForm;
