import { PageWithFormLayout } from 'common-components';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

const GenerateOwnershipChangeTokenPage: React.FC<{
  backLinkVisible?: boolean;
  loading?: boolean;
  children: React.ReactNode;
}> = ({ backLinkVisible, loading, children }) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const isPrivateFlow = path.endsWith('private');
  const title = isPrivateFlow
    ? t('subscriptions.generateToken.titlePrivate')
    : t('subscriptions.generateToken.titleCompany');

  return (
    <PageWithFormLayout
      title={title}
      backLinkVisible={backLinkVisible}
      loading={loading}
      data-testid="generate-ownership-change-token-form"
    >
      {children}
    </PageWithFormLayout>
  );
};

export default GenerateOwnershipChangeTokenPage;
