import { gql } from '@apollo/client';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { EditableListId } from 'types/listConfig';

export const editableListQuery = gql`
  query GetEditableList($listId: EditableListId!) {
    editableList(listId: $listId) {
      id
      columns {
        id
        enabled
        __typename @skip(if: true)
      }
    }
  }
`;

type QueriedEditableList = {
  editableList: EditableList | null;
};

export type EditableList = {
  id: EditableListId;
  columns: EditableListColumn[];
};

export type EditableListColumn = {
  id: string;
  enabled: boolean;
};

const useEditableList = (listId: EditableListId) => {
  const { data, error, loading } = useCallBackend<QueriedEditableList>({
    query: editableListQuery,
    queryVariables: { listId },
    fetchPolicy: 'cache-first'
  });

  return {
    enabled: true,
    columns: data?.editableList?.columns || [],
    loading,
    error
  };
};

export default useEditableList;
