import { Autocomplete, Div } from '@gaiads/telia-react-component-library';
import { SearchType } from 'B2XApp/Dashboard/DashboardNavigation/DashboardSearch/DashboardSearchPanel';
import getClassNames from 'classnames';
import { Forms } from 'common-components';
import { useDashboardSearchState } from 'contexts/SearchContext/DashboardSearchContext';
import { useUserData } from 'contexts/UserContext/UserContext';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { EXTENDED_SEARCH_TEXT_MAX_LENGTH } from 'types/form';

import styles from './DashboardContentSearch.module.scss';

const DashboardContentSearch: React.FC<{ searchType: SearchType; roundedSearchBar?: boolean }> = ({
  searchType,
  roundedSearchBar = false
}) => {
  const { t } = useTranslation();
  const { hasMultipleCompanies } = useUserData();
  const { searchTerm, setSearchTerm, searchFieldRef, searchFieldActive, setSearchFieldActive } =
    useDashboardSearchState();

  const onFocus = () => setSearchFieldActive?.(true);
  const onBlur = () =>
    searchType === 'pukCodes' && searchTerm.length <= 0 && setSearchFieldActive?.(false);

  return (
    <Div margin={{ bottom: 'sm' }}>
      <Div refElement={searchFieldRef} id="dashboard-search" data-testid="dashboard-search">
        <Autocomplete
          data-testid="content-search-field"
          className={getClassNames({
            [styles.regularCursor]: true,
            [styles.roundedSearchBar]: roundedSearchBar
          })}
          value={searchTerm}
          iconName={searchTerm ? 'close' : 'search'}
          onIconClick={searchTerm ? () => setSearchTerm('') : undefined}
          options={[]}
          placeholder={
            searchType === 'pukCodes'
              ? t('dashboard.pukCodeSearch.msisdnOrName')
              : t('topNavigation.articleSearch.placeHolder')
          }
          onChange={(_event, value: string) => setSearchTerm(value)}
          onFocus={onFocus}
          onBlur={onBlur}
          maxLength={EXTENDED_SEARCH_TEXT_MAX_LENGTH}
        />
      </Div>

      {searchType === 'pukCodes' && (
        <CSSTransition
          in={searchFieldActive}
          timeout={0}
          classNames={{
            enterDone: styles.helperText_enterDone,
            enterActive: styles.helperText_enterActive,
            exitDone: styles.helperText_exitDone,
            exitActive: styles.helperText_exitActive
          }}
        >
          <Div className={styles.helperText} data-testid="dashboard-search-puk-codes-info-text">
            <Forms.FieldHelperText
              helperText={getPukCodeSearchHelperText(t, hasMultipleCompanies)}
            />
          </Div>
        </CSSTransition>
      )}
    </Div>
  );
};

const getPukCodeSearchHelperText = (t: TFunction, multicompanyUser: boolean) => {
  const nameNote = t('dashboard.search.pukCodesDisclaimer1Name');
  const companyNote = multicompanyUser ? t('dashboard.search.pukCodesDisclaimer2Company') : '';
  return `${nameNote} ${companyNote}`.trimEnd();
};

export default DashboardContentSearch;
