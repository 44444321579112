import { Gutter } from '@gaiads/telia-react-component-library';
import { TeliaLink } from 'common-components';
import { Flex } from 'core-components';
import { useTranslation } from 'react-i18next';

const TopBarLinks: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Flex centeredVertically occupyVerticalSpace padding={{ right: 'md' }}>
      <TopBarLink
        to={t('topBar.personal.url')}
        data-testid="top-bar-link-personal"
        label={t('topBar.personal.label')}
      />

      <Gutter size="sm" />

      <TopBarLink
        to={t('topBar.business.url')}
        data-testid="top-bar-link-business"
        label={t('topBar.business.label')}
      />

      <Gutter size="sm" />

      <TopBarLink
        to={t('topBar.wholesale.url')}
        data-testid="top-bar-link-wholesale"
        label={t('topBar.wholesale.label')}
      />

      <Gutter size="sm" />

      <TopBarLink
        to={t('topBar.teliaFinland.url')}
        data-testid="top-bar-link-telia-finland"
        label={t('topBar.teliaFinland.label')}
      />
    </Flex>
  );
};

const TopBarLink: React.FC<{
  label: string;
  to: string;
  'data-testid': string;
}> = ({ label, to, 'data-testid': testId }) => (
  <TeliaLink.External
    to={to}
    aria-label={label}
    variant="standalone-topbar"
    label={label.toUpperCase()}
    openInNewTab
    data-testid={testId}
  />
);

export default TopBarLinks;
