import TicketDetailsPage from 'B2XApp/Messages/Tickets/TicketDetailsPage/TicketDetailsPage';
import useSendTicketMessage from 'hooks/tickets/useSendTicketMessage/useSendTicketMessage';
import useTicket from 'hooks/tickets/useTicket/useTicket';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

const TicketDetails: React.FC = () => {
  const { ticketId } = useParams<{ ticketId: string }>();
  const { ticket, loading, error } = useTicket(ticketId);
  const [replyToTicketVisible, setReplyToTicketVisible] = useState(false);
  const [replySentMessageVisible, setReplySentMessageVisible] = useState(false);

  const {
    sendTicketMessage,
    loading: replyLoading,
    replySent,
    hasError: hasReplyError
  } = useSendTicketMessage();

  useEffect(() => {
    setReplySentMessageVisible(replySent);
  }, [replySent]);

  return (
    <TicketDetailsPage
      ticket={ticket}
      loading={!ticket || loading}
      hasError={!!error}
      replyToTicket={{
        replyToTicketVisible,
        setReplyToTicketVisible,
        loading: replyLoading,
        hasError: hasReplyError,
        replySent: replySentMessageVisible,
        sendMessage: sendTicketMessage,
        setReplySentMessageVisible
      }}
      data-testid="ticket-details"
    />
  );
};

export default TicketDetails;
