import { Gutter, Page } from '@gaiads/telia-react-component-library';
import { Icon } from '@teliafi/fi-ds';

const ChatbotPageContent: React.FC = () => (
  <Page
    padding={{
      top: 'lg',
      bottom: 'xxlg'
    }}
  >
    <Page.Row alignTextToCenter>
      <Icon name="chat-robot" size="lg" />

      <Gutter size="xs" />

      <div id="inpagebot">
        <div id="inpagebot-loading">
          <img
            style={{
              maxWidth: '100%',
              height: 'auto'
            }}
            src="#"
            alt="chatbot"
          />
        </div>
      </div>
    </Page.Row>
  </Page>
);

export default ChatbotPageContent;
