import { BodyText } from '@gaiads/telia-react-component-library';
import { DataPanel, DefinitionList, TeliaLink } from 'common-components';
import { Div } from 'core-components';
import { formatDatetime } from 'doings/formatDatetime';
import getEnvironmentVariable from 'doings/getEnvironmentVariable/getEnvironmentVariable';
import { devices } from 'doings/track/analyticsEvents';
import useDeviceImageUrl from 'hooks/devices/useDevice/useDeviceImageUrl';
import { getOr } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { Device } from 'types/device';

import placeholder from './device-placeholder.svg';
import MainInfoSkeleton from './MainInfoSkeleton';

const MainInfo: React.FC<{
  device?: Device;
  loading: boolean;
  hasError?: boolean;
}> = ({ device, loading, hasError }) => {
  const { t } = useTranslation();
  const { deviceImageUrl, loading: imageUrlLoading } = useDeviceImageUrl(device?.productCode);

  return (
    <DataPanel
      skeletonLoader={<MainInfoSkeleton />}
      data-testid="device-details-name-panel"
      loading={loading}
      hasError={hasError}
      title={device?.productName}
      imageSrc={deviceImageUrl ?? placeholder}
      imageSrcLoading={imageUrlLoading}
      imageAlt={t('daas.details.deviceImagePlaceholder')}
      clearFloatAfterImage
      mainPanel
    >
      <DefinitionList addDividers fiftyFifty>
        <DefinitionList.Item heading={t('daas.details.owner')}>
          <BodyText>{getOr('', 'companyName', device)}</BodyText>
        </DefinitionList.Item>

        <DefinitionList.Item heading={t('daas.details.user')}>
          <BodyText>{getOr('', 'deviceUser', device)}</BodyText>
        </DefinitionList.Item>

        <DefinitionList.Item heading={t('daas.details.productCode')}>
          <BodyText>{getOr('', 'productCode', device)}</BodyText>
        </DefinitionList.Item>

        <DefinitionList.Item heading={t('daas.details.imei')}>
          <BodyText>{getOr('', 'imeiSerialNumber', device)}</BodyText>
        </DefinitionList.Item>

        <DefinitionList.Item heading={t('daas.details.orderDate')}>
          <BodyText data-testid="order-date">
            {!loading && formatDatetime(device?.orderCreationTime)}
          </BodyText>
        </DefinitionList.Item>
      </DefinitionList>

      {device && !device.isOneItemPaymentDevice && (
        <Div margin={{ top: 'md' }}>
          <TeliaLink.External
            data-testid="inmics-nebula-portal-link"
            variant="standalone"
            to={getEnvironmentVariable('REACT_APP_LINK_TO_TIN_MY_NEBULA')}
            label={t('daas.details.manageDeviceInInmicsNebula')}
            iconRight="external"
            track={devices.manageDeviceDetailsInMyNebula}
          />
        </Div>
      )}
    </DataPanel>
  );
};

export default MainInfo;
