import { gql } from '@apollo/client';

export default gql`
  fragment subscriptionPackageMeasure on SubscriptionPackageMeasure {
    unlimited
    value
    unit
    text
    area
    billingType
  }

  query GetMobileSubscriptionDetails(
    $subscriptionId: ID!
    $shouldFetchBalance: Boolean!
    $shouldFetchOmsu: Boolean!
    $force: Boolean
  ) {
    subscription(subscriptionId: $subscriptionId, force: $force) {
      subscription {
        id
        phoneNumber
        phoneNumberHash
        productId
        owner {
          companyCode
          companyName
          partyId
        }
        user {
          address {
            street
            postalAddress
            postalOffice
            zip
          }
          name
          additionalInfo
          displayName
          email
        }
        packages {
          feature {
            code
            name
          }
          voice {
            code
            name
            capacity {
              ...subscriptionPackageMeasure
            }
          }
          sms {
            code
            name
            capacity {
              ...subscriptionPackageMeasure
            }
          }
          data {
            code
            name
            capacity {
              ...subscriptionPackageMeasure
            }
            capacityEU {
              ...subscriptionPackageMeasure
            }
            speed {
              ...subscriptionPackageMeasure
            }
            uplinkSpeed {
              ...subscriptionPackageMeasure
            }
          }
          prices {
            monthlyVAT0
          }
        }
        additionalServices {
          name
          code
          parameter
        }
        restrictionServices {
          name
          code
          parameter
        }
        attributes
        status
        activeFrom
        type
        simCard {
          icc
          pukCodes {
            puk1
            puk2
          }
          type
          size
        }
        references {
          type
          resolved
          phoneNumber
          phoneNumberHash
          displayName
        }
        billing {
          costCenter
          collectionGroup
        }
        nopaCode
        termination
        terminationDate
        omsuToken @include(if: $shouldFetchOmsu) {
          tokenStatus
          expiryDate
          terminationDate
        }
        omsuBlockingServices @include(if: $shouldFetchOmsu)
        fixedTermContractEndDate
        lastTimeInService
        activationDeadlineCode
        hasDuettoBundle
      }
      balance @include(if: $shouldFetchBalance) {
        grandBalance
        monthlyPayment
        billingStartDate
        billingEndDate
        domesticDataUsed
        domesticDataCapacity
        domesticDataUnlimited
        domesticDataUnit
        euDataUsed
        euDataCapacity
        euDataUnlimited
        euDataUnit
        speakDataUsed
        speakDataCapacity
        speakDataUnlimited
        smsPrice
      }
    }
  }
`;

export type SubscriptionStatus =
  | 'PREOPENED'
  | 'CLOSED'
  | 'SUSPENDED'
  | 'ENABLED'
  | 'BAD_CREDIT'
  | 'DISABLED';

export type SubscriptionAttribute =
  | 'EXTRA_SIM_MASTER'
  | 'EXTRA_SIM_MEMBER'
  | 'POTENTIAL_EXTRA_SIM_MASTER'
  | 'MULTI_SIM_MASTER'
  | 'MULTI_SIM_MEMBER';

export type SubscriptionUserAddress = {
  postalAddress: string;
  street: string | null;
  postalOffice: string | null;
  zip: string | null;
};

export type SubscriptionUser = {
  address: SubscriptionUserAddress;
  name: string | null;
  additionalInfo: string | null;
  displayName: string | null;
  email: string | null;
};

export type PukCodes = {
  puk1?: string;
  puk2?: string;
};

export type SimCardType =
  | 'COMBO'
  | 'COMBO_NO_PIN'
  | 'ENOPN'
  | 'EPN'
  | 'NANO'
  | 'NOPN'
  | 'OTA2'
  | 'SEID'
  | 'TEID'
  | 'TFED'
  | 'TFID'
  | 'TNOP'
  | 'TNPE'
  | 'UPK2'
  | 'VMAC'
  | 'VECC';

export type SimCardSize = 'E_SIM' | 'FULL_SIZE' | 'MINI' | 'MICRO' | 'NANO' | 'TRIO';

export type SimCard = {
  icc: string | null;
  pukCodes: PukCodes | null;
  type: SimCardType | null;
  size: SimCardSize | null;
};

export type Service = {
  name: string;
  code: string;
  parameter: string | null;
};

export type Balance = {
  grandBalance: string;
  monthlyPayment: string;
  billingStartDate: string;
  billingEndDate: string;
  smsPrice: string;
  domesticDataUsed?: number;
  domesticDataCapacity?: number;
  domesticDataUnlimited: boolean;
  domesticDataUnit?: string;
  euDataUsed?: number;
  euDataCapacity?: number;
  euDataUnlimited: boolean;
  euDataUnit?: string;
  speakDataUsed?: number;
  speakDataCapacity?: number;
  speakDataUnlimited: boolean;
};

export type QueriedBilling = {
  costCenter: string | null;
  collectionGroup: string | null;
};

export type QueriedSubscriptionDetails = {
  id: string;
  productId: string;
  type: string;
  phoneNumber: string;
  phoneNumberHash: string;
  attributes: SubscriptionAttribute[];
  status: SubscriptionStatus;
  activeFrom: string;
  user: SubscriptionUser | null;
  simCard: SimCard | null;
  owner: Owner | null;
  nopaCode: string | null;
  billing: QueriedBilling | null;
  packages: Packages;
  additionalServices: Service[];
  restrictionServices: Service[];
  termination: boolean;
  terminationDate?: string;
  references: SubscriptionReference[];
  omsuToken?: OmsuTokenDetails;
  omsuBlockingServices?: boolean;
  fixedTermContractEndDate?: string;
  hasDuettoBundle?: boolean;
  lastTimeInService?: string;
  activationDeadlineCode?: string;
};

export type OmsuTokenDetails = {
  tokenStatus: string;
  expiryDate: string;
  terminationDate?: string;
};

const is = (attribute: SubscriptionAttribute) => (subscription: QueriedSubscriptionDetails) =>
  subscription.attributes.includes(attribute);

export const isDataPoolMaster = is('EXTRA_SIM_MASTER');

export const isPotentialDataPoolMaster = is('POTENTIAL_EXTRA_SIM_MASTER');

export const isDataPoolMember = is('EXTRA_SIM_MEMBER');

export const isMultiSimMaster = is('MULTI_SIM_MASTER');

export const isMultiSimMember = is('MULTI_SIM_MEMBER');

export type SubscriptionReferenceType =
  | 'POOL_PARENT'
  | 'POOL_MEMBER'
  | 'MULTISIM_PARENT'
  | 'MULTISIM_MEMBER';

export type SubscriptionReference = {
  type: SubscriptionReferenceType;
  resolved: boolean;
  phoneNumber: string;
  phoneNumberHash: string | null;
  displayName: string | null;
};

export const filterReference = (
  subscription: QueriedSubscriptionDetails,
  refType: SubscriptionReferenceType
) => subscription.references.find(({ type }) => type === refType);

export const filterMultipleReferences = (
  subscription: QueriedSubscriptionDetails,
  refType: SubscriptionReferenceType
) => subscription.references.filter(({ type }) => type === refType);

export type SubscriptionDetails = {
  userName: string;
} & QueriedSubscriptionDetails;

export interface Packages {
  readonly feature?: PackageFeature;
  readonly voice?: PackageVoice;
  readonly sms?: PackageSms;
  readonly data?: PackageData;
  readonly prices?: PackagePrices;
}

interface PackageBase {
  readonly code: string;
  readonly name: string;
}

export type PackageFeature = PackageBase;

export interface PackageVoice extends PackageBase {
  readonly capacity: PackageMeasure;
}

export interface PackageSms extends PackageBase {
  readonly capacity: PackageMeasure;
}

export interface PackageData extends PackageBase {
  readonly capacity?: PackageMeasure;
  readonly capacityEU?: PackageMeasure;
  readonly speed?: PackageMeasure;
  readonly uplinkSpeed?: PackageMeasure;
}

export type PackageMeasure = MeasureLimited | MeasureUnlimited | MeasurePAYG;

interface MeasureLimited {
  readonly unlimited?: false;
  readonly value: number;
  readonly unit: string;
  readonly text: string;
  readonly area?: PackageArea;
  readonly billingType?: PackageBillingType;
}

interface MeasureUnlimited {
  readonly unlimited: true;
  readonly area?: PackageArea;
  readonly billingType?: PackageBillingType;
}

interface MeasurePAYG {
  readonly unlimited?: boolean;
  readonly area?: PackageArea;
  readonly billingType: 'PAYG';
}

export type PackageArea = 'DOM' | 'DOM_EU' | 'EU';

export type PackageBillingType = 'PACKAGE' | 'DAY' | 'HOUR' | 'MB' | 'PAYG';

export interface PackagePrices {
  monthlyVAT0: number;
}

export type Owner = {
  companyCode: string;
  companyName: string;
  partyId: string;
};

export type SubscriptionDetailsResponse = {
  subscription: {
    subscription: QueriedSubscriptionDetails | null;
    balance: Balance | null;
  };
};
