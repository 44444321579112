import { Paragraph } from '@teliafi/fi-ds';
import { Heading } from 'common-components';
import { Flex } from 'core-components';
import formatPrice from 'doings/formatPrice/formatPrice';

export const Price: React.FC<{
  label?: string;
  price?: number;
  currency?: string;
  size?: 'lg' | 'md';
  'data-testid'?: string;
}> = ({ label, price, currency, size = 'lg', 'data-testid': dataTestId }) => (
  <Flex directionColumn data-testid={`${dataTestId}-wrapper`}>
    {label && <Paragraph variant="paragraph-100">{label}</Paragraph>}

    {size === 'lg' && (
      <Heading.Price data-testid={dataTestId}>
        {formatPrice(price)} {formatCurrency(currency)}
      </Heading.Price>
    )}

    {size === 'md' && (
      <Paragraph variant="paragraph-100" data-testid={dataTestId}>
        {formatPrice(price)} {formatCurrency(currency)}
      </Paragraph>
    )}
  </Flex>
);

function formatCurrency(currency = '€') {
  const upper = currency.toLocaleUpperCase();
  return upper === 'EUR' ? '€' : upper;
}
