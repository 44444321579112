import { Checkbox, Div, Flex } from '@gaiads/telia-react-component-library';
import { Icon } from '@teliafi/fi-ds';
import getClassNames from 'classnames';
import type { Identifier, XYCoord } from 'dnd-core';
import { noOp } from 'doings/noOp/noOp';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  ConnectDragPreview,
  ConnectDragSource,
  ConnectDropTarget,
  useDrag,
  useDrop
} from 'react-dnd';

import styles from './SortableCheckboxesListItem.module.scss';
import { SortableCheckboxesListItemLabel } from './SortableCheckboxesListItemLabel';
import { SortedChecklistItem } from './SortableCheckboxesProvider';

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const HOVER_VERTICAL_DIVIDER = 4;

export const SortableCheckboxesListItem: React.FC<{
  item: SortedChecklistItem;
  index: number;
  dragItem: (draggedIndex: number, hoveredIndex: number) => void;
  toggleItem: (id: string) => void;
  disabled: boolean;
}> = ({ item, index, dragItem, toggleItem, disabled }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [{ isDragging }, connectDrag] = useDragSupport(item, index);
  const [{ handlerId }, connectDrop] = useDropSupport(ref, index, dragItem);

  connectDrag(connectDrop(ref));
  useKeyboardArrangementSupport(isFocused, index, dragItem);

  return (
    <Flex
      refElement={ref}
      data-testid={`sortable-cb-${item.id}`}
      data-handler-id={handlerId}
      occupyHorizontalSpace
      padding={{ vertical: 'xs' }}
      className={getClassNames(styles.item, {
        [styles.dragHandle]: true,
        [styles.isDragging]: isDragging
      })}
    >
      <Div className={styles.dragIcon}>
        <Icon name="services" size="md" />
      </Div>

      <Checkbox
        data-testid={`sortable-cb-${item.id}-checkbox`}
        checked={item.enabled}
        onClick={(event) => event.stopPropagation()}
        onChange={() => !disabled && toggleItem(item.id)}
        onBlur={() => setIsFocused(false)}
        onFocus={() => setIsFocused(true)}
        label={
          <SortableCheckboxesListItemLabel
            label={item.label}
            indicateRequiredItem={item.isRequired}
            indicateNewItem={item.isNew}
          />
        }
        className={getClassNames(styles.item, { [styles.item__disabled]: disabled })}
        aria-disabled={disabled}
      />
    </Flex>
  );
};

const useDragSupport = (
  item: SortedChecklistItem,
  index: number
): [{ isDragging: boolean }, ConnectDragSource, ConnectDragPreview] =>
  useDrag({
    type: 'item',
    item: () => ({ id: item.id, index }),
    collect: (monitor) => ({ isDragging: monitor.isDragging() })
  });

const useDropSupport = (
  ref: React.RefObject<HTMLDivElement>,
  index: number,
  dragItem: (draggedIndex: number, hoveredIndex: number) => void
): [{ handlerId: Identifier | null }, ConnectDropTarget] =>
  useDrop<DragItem, void, { handlerId: Identifier | null; dropPoint: number | undefined }>({
    accept: 'item',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        dropPoint: monitor.getItem()?.index
      };
    },
    hover(draggedItem: DragItem, monitor) {
      if (!ref.current || draggedItem.index === index) {
        return;
      }

      const itemBounds = ref.current.getBoundingClientRect();
      const itemThreshold = itemBounds.height / HOVER_VERTICAL_DIVIDER;
      const itemTopY = itemBounds.top + itemThreshold;
      const itemBottomY = itemBounds.bottom - itemThreshold;
      const draggedItemHoverClientY = (monitor.getClientOffset() as XYCoord).y;

      if (
        (draggedItem.index > index && draggedItemHoverClientY < itemBottomY) ||
        (draggedItem.index < index && draggedItemHoverClientY > itemTopY)
      ) {
        dragItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    }
  });

const useKeyboardArrangementSupport = (
  isFocused: boolean,
  index: number,
  dragItem: (draggedIndex: number, hoveredIndex: number) => void
) => {
  // Need to use key down to prevent page scrolling on arrow keys,
  // which occurs on key down, not on key up.
  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (!e.ctrlKey && !e.metaKey) {
        return;
      }
      if (e.key === 'ArrowDown') {
        dragItem(index, index + 1);
        e.preventDefault();
      } else if (e.key === 'ArrowUp') {
        dragItem(index, index - 1);
        e.preventDefault();
      }
    },
    [dragItem, index]
  );

  useEffect(() => {
    if (isFocused) {
      document.addEventListener('keydown', onKeyDown);
      return () => document.removeEventListener('keydown', onKeyDown);
    }

    return noOp;
  }, [isFocused, onKeyDown]);
};
