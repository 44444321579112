import { AuthorizationParams } from 'types/authorization';

const hasActiveGroup = (userGroupType: ActiveGroupType, activeGroup?: ActiveGroupType): boolean => {
  return !activeGroup || userGroupType === activeGroup;
};

const hasOnePermission = (
  userPermissions: UserPermission[],
  permission?: UserPermission
): boolean => {
  return !permission || userPermissions.includes(permission);
};

const hasAnyPermission = (
  userPermissions: UserPermission[],
  permissions?: UserPermission[]
): boolean => {
  return !permissions || permissions.some((permission) => userPermissions.includes(permission));
};

const userHasPermission = (user: User) => (params: AuthorizationParams) =>
  hasActiveGroup(user.activeGroupType, params.activeGroup) &&
  hasOnePermission(user.permissions, params.onePermission) &&
  hasAnyPermission(user.permissions, params.anyPermission);

export default userHasPermission;
