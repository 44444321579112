import { Div } from '@gaiads/telia-react-component-library';
import { AskForHelpHistory } from 'hooks/askForHelp/useAskForHelp';

import Answer from './Answer';
import AskForHelpConversationLoader from './AskForHelpConversationLoader';
import AskForHelpConversationNotice from './AskForHelpConversationNotice';
import Question from './Question';

export type AskForHelpConversationProps = {
  lastQuestion?: string;
  currentQuestion?: string;
  history?: AskForHelpHistory[];
  answerLoading?: boolean;
  answerLoadingFailed?: boolean;
};

const AskForHelpConversation: React.FC<AskForHelpConversationProps> = ({
  lastQuestion,
  currentQuestion,
  history = [],
  answerLoading = false,
  answerLoadingFailed = false
}) => {
  if (!lastQuestion) {
    return null;
  }

  const hasSessionHistory = () => !!history?.length;

  return (
    <Div data-testid="ask-for-help--conversation">
      <Div padding={{ right: 'lg' }}>
        {!hasSessionHistory() && (
          <Question
            question={lastQuestion}
            firstQuestion
            data-testid="ask-for-help--conversation--initial-question"
          />
        )}

        {hasSessionHistory() &&
          history.map(({ role, content, links }, index) => {
            const itemRole = role === 'user' ? 'question' : 'answer';
            const testId = `ask-for-help--conversation--${itemRole}-${index}`;

            return role === 'user' ? (
              <Question
                question={content}
                firstQuestion={index === 0}
                data-testid={testId}
                key={testId}
              />
            ) : (
              <Answer answer={content} additionalLinks={links} data-testid={testId} key={testId} />
            );
          })}
      </Div>

      {(answerLoading || answerLoadingFailed) && (
        <>
          {hasSessionHistory() && !!currentQuestion && (
            <Question
              question={currentQuestion}
              data-testid="ask-for-help--conversation--pending-question"
            />
          )}

          <Div occupyHorizontalSpace padding={{ bottom: 'sm', right: 'lg' }}>
            {answerLoading && <AskForHelpConversationLoader />}

            <AskForHelpConversationNotice
              variant={answerLoadingFailed ? 'answerUnavailable' : 'answerLoading'}
            />
          </Div>
        </>
      )}
    </Div>
  );
};

export default AskForHelpConversation;
