import { Icon, IconName, Notification } from '@teliafi/fi-ds';
import getClassNames from 'classnames';
import TextWithInlineHtml from 'common-components/TextWithInlineHtml/TextWithInlineHtml';
import { TextWithInlineHtmlExtended } from 'common-components/TextWithInlineHtml/TextWithInlineHtmlExtended';
import { TFunction, TOptions } from 'i18next';
import React, { useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import styles from './InlineNotification.module.scss';

export type InlineNotificationType = 'success' | 'information' | 'warning' | 'error';

/**
 * Wrapper around Purpur's `Notification` React component for showing
 * inline notifications with links and other translatable components
 * within content, or with custom icons.
 */
const InlineNotification: React.FC<{
  variant: InlineNotificationType;
  title?: TitleText;
  content: ContentText;
  disableLinks?: boolean;
  icon?: IconName;
  onClose?: VoidFunction;
  'aria-label'?: string;
  'data-testid'?: string;
}> = ({
  variant,
  title,
  content,
  disableLinks,
  icon,
  onClose,
  'aria-label': htmlAriaLabel,
  'data-testid': testId
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const hiddenLabel = htmlAriaLabel ?? t(`aria.notification.variant.${variant}`);

  useEffect(() => {
    const container = ref.current;
    if (disableLinks && container) {
      const links: NodeListOf<HTMLAnchorElement> = container.querySelectorAll('a[target="_blank"]');
      links?.forEach((link) => {
        link.tabIndex = -1;
        link.setAttribute('aria-disabled', 'true');
        link.removeAttribute('href');
      });
    }
  }, [disableLinks]);

  return (
    <div className={styles.container} ref={ref}>
      <Notification
        data-testid={testId}
        className={getClassNames(styles.notification, {
          [styles.notification__titleless]: !title,
          [styles.notification__customIcon]: !!icon
        })}
        headingTag="h6"
        heading={title ? renderTitle(title, t) : undefined}
        status={variant === 'information' ? 'info' : variant}
        aria-live={variant !== 'information' ? 'polite' : undefined}
        aria-label={hiddenLabel}
        closeButtonAriaLabel={t('aria.notification.close')}
        onClose={onClose}
      >
        {renderContent(content, t)}
      </Notification>

      {icon && (
        <Icon
          data-testid={`notification-icon-${icon}`}
          className={styles.customIcon}
          name={icon}
          size="lg"
        />
      )}
    </div>
  );
};

type TextType =
  | PlainText
  | HtmlText
  | ExtendedHtmlText
  | TranslationKey
  | TranslationKeyWithArgs
  | NotTextAtAll;

export type TitleText = PlainText | TranslationKey;
export type ContentText = TextType; //NOSONAR

type PlainText = string; //NOSONAR
const isPlainText = (text: TextType): text is PlainText => {
  return typeof text === 'string';
};

type HtmlText = { html: string };
const isHtmlText = (text: TextType): text is HtmlText => {
  return typeof text === 'object' && 'html' in text;
};

type ExtendedHtmlText = { extendedHtml: string };
const isExtendedHtmlText = (text: TextType): text is ExtendedHtmlText => {
  return typeof text === 'object' && 'extendedHtml' in text;
};

type TranslationKey = { key: string; args?: TOptions };
const isTranslationKey = (text: TextType): text is TranslationKey => {
  return typeof text === 'object' && 'key' in text && !('components' in text);
};

type TranslationKeyWithArgs = { key: string; components: JSX.Element[] };
const isTranslationKeyWithArgs = (text: TextType): text is TranslationKeyWithArgs => {
  return typeof text === 'object' && 'key' in text && 'components' in text;
};

type NotTextAtAll = { jsx: JSX.Element };
const isNotTextAtAll = (text: TextType): text is NotTextAtAll => {
  return typeof text === 'object' && 'jsx' in text;
};

const renderTitle = (text: TitleText, t: TFunction): string => {
  return isPlainText(text) ? text : t(text.key, text.args);
};

const renderContent = (text: ContentText, t: TFunction): JSX.Element => {
  return (
    <>
      {isPlainText(text) && text}

      {isHtmlText(text) && <TextWithInlineHtml text={text.html} />}

      {isExtendedHtmlText(text) && <TextWithInlineHtmlExtended text={text.extendedHtml} />}

      {isTranslationKey(text) && <>{t(text.key, text.args)}</>}

      {isTranslationKeyWithArgs(text) && (
        <Trans i18nKey={text.key}>
          {React.Children.map(text.components, (c, index) =>
            c.key ? c : React.cloneElement(c, { key: `arg-${String(index)}` })
          )}
        </Trans>
      )}

      {isNotTextAtAll(text) && <>{text.jsx}</>}
    </>
  );
};

export default InlineNotification;
