import { Badge, Icon } from '@teliafi/fi-ds';
import getClassNames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './StatusTag.module.scss';

export type IndicatorStatusDefinition =
  | {
      i18nKey: string;
      variant: 'success' | 'warning' | 'error' | 'information';
      icon?: never;
    }
  | {
      i18nKey: string;
      variant: 'neutral';
      icon: 'close-circle' | 'stop' | 'question';
    };

const StatusTag: React.FC<{ status: IndicatorStatusDefinition; bold?: boolean }> = ({
  status,
  bold
}) => {
  const { t } = useTranslation();
  const hasCustomIcon = !!status.icon;

  return (
    <Badge
      data-testid={`${status.variant}-badge`}
      variant={status.variant}
      showIcon={!hasCustomIcon}
      className={getClassNames({ [styles.bold]: bold })}
    >
      {hasCustomIcon ? (
        <Icon
          data-testid={`${status.variant}-badge-custom-icon`}
          data-icon={status.icon}
          name={status.icon}
          size="xs"
        />
      ) : null}

      {t(status.i18nKey)}
    </Badge>
  );
};

export default StatusTag;
