import { ApolloError, gql } from '@apollo/client';
import { multiplex } from 'doings/multiplex/multiplex';
import { billingGroupsQuery } from 'hooks/invoicing/billingGroups/useBillingGroups/useBillingGroups';
import useCallBackendMutation from 'hooks/useCallBackend/useCallBackendMutation';
import { CollectionGroupApiStatus, CollectionGroupHandlingType } from 'types/collection-group';

export const copyCollectionGroupMutation = gql`
  mutation CopyBillingAccount(
    $collectionGroupId: String!
    $requestData: InputCollectionGroupCopyRequest!
  ) {
    copyCollectionGroup(collectionGroupId: $collectionGroupId, requestData: $requestData) {
      handlingType
      collectionGroupId
    }
  }
`;

const isAutomaticHandling = (copyCollectionGroup: CopyCollectionGroupResult) =>
  copyCollectionGroup.handlingType === CollectionGroupHandlingType.Automatic;

const mapApiStatus = (
  loading: boolean,
  data?: { copyCollectionGroup: CopyCollectionGroupResult },
  error?: ApolloError
): CollectionGroupApiStatus => {
  return multiplex([
    'initial',
    [!!loading, 'persisting'],
    [!!error, 'failure'],
    [!!data && isAutomaticHandling(data.copyCollectionGroup), 'success'],
    [!!data && !isAutomaticHandling(data.copyCollectionGroup), 'partial-success']
  ]);
};

interface CopyCollectionGroupResult {
  handlingType: CollectionGroupHandlingType;
  collectionGroupId?: string;
}

interface UseCopyCollectionGroupResult {
  copyCollectionGroup: CopyCollectionGroupResult;
}

export default () => {
  const { runMutation, loading, data, error } =
    useCallBackendMutation<UseCopyCollectionGroupResult>(copyCollectionGroupMutation, [
      {
        query: billingGroupsQuery,
        variables: {
          requestParams: {}
        }
      }
    ]);

  return {
    copyCollectionGroup: runMutation,
    copyCollectionGroupApiStatus: mapApiStatus(loading, data, error),
    newCollectionGroupId: data?.copyCollectionGroup?.collectionGroupId
  };
};
