import { Div } from '@gaiads/telia-react-component-library';
import useIsSmallerBreakpointActive from 'hooks/useIsSmallerBreakpointActive/useIsSmallerBreakpointActive';

const DetailsPageHeadlineSupplement: React.FC<{
  headlineSupplement?: React.ReactNode;
}> = ({ headlineSupplement }) => {
  const marginlessSupplement = useIsSmallerBreakpointActive('xlg');

  if (!headlineSupplement) {
    return null;
  }

  return (
    <Div
      flexItem={{ sizeMultiplier: 0.5 }}
      margin={{ left: marginlessSupplement ? 'zero' : 'md', bottom: 'md' }}
    >
      {headlineSupplement}
    </Div>
  );
};

export default DetailsPageHeadlineSupplement;
