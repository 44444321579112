import routes from 'B2XApp/appRoutes';
import { useFeatureFlagsData } from 'contexts/FeatureFlagContext/FeatureFlagContext';

export default () => {
  const { hasFeature } = useFeatureFlagsData();

  return routes.filter(
    ({ featureFlags }) => !featureFlags || featureFlags.every((flag) => hasFeature(flag))
  );
};
