import useAvailableBanks from 'hooks/invoicing/billingGroups/useAvailableBanks/useAvailableBanks';
import useAvailableOperators from 'hooks/invoicing/billingGroups/useAvailableOperators/useAvailableOperators';
import useCollectionGroup, {
  LoadingStatus
} from 'hooks/invoicing/billingGroups/useCollectionGroup/useCollectionGroup';

const mapStatus = (
  banks: ReturnType<typeof useAvailableBanks>,
  operators: ReturnType<typeof useAvailableOperators>,
  group: ReturnType<typeof useCollectionGroup>
): LoadingStatus => {
  if (banks.error || operators.error || group.status === 'error') {
    return 'error';
  } else if (banks.loading || operators.loading || group.status === 'loading') {
    return 'loading';
  } else {
    return 'loaded';
  }
};

/**
 * Retrieves information required to render an copy billing account
 * form: available banks and operators for electronic invoices, and
 * the collection group to be copied. If any information cannot be
 * retrieved, fails gracefully by returning an error status.
 *
 * @param collectionGroupId The identifier of the collection group to be edited.
 */
const useCopyBillingGroupData = (collectionGroupId: string) => {
  const banksResult = useAvailableBanks();
  const operatorsResult = useAvailableOperators();
  const collectionGroupResult = useCollectionGroup({
    collectionGroup: collectionGroupId
  });
  const status = mapStatus(banksResult, operatorsResult, collectionGroupResult);

  return {
    status,
    availableBanks: banksResult.availableBanks,
    availableOperators: operatorsResult.availableOperators,
    collectionGroup: collectionGroupResult.item
  };
};

export default useCopyBillingGroupData;
