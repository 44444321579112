import gql from 'graphql-tag';
import useCallBackendMutation from 'hooks/useCallBackend/useCallBackendMutation';
import { useCallback } from 'react';

export const askForHelpMutation = gql`
  mutation AskForHelp($question: String) {
    askForHelp(question: $question) {
      role
      content
      links {
        title
        url
        targetPortal
      }
    }
  }
`;

type AskForHelpResponse = {
  askForHelp: AskForHelpHistory[];
};

export type AskForHelpHistory = {
  role: AskForHelpHistoryRole;
  content: string;
  links?: AskForHelpAdditionalLink[];
};

export type AskForHelpHistoryRole = 'user' | 'assistant';

export type AskForHelpAdditionalLink = {
  title: string;
  url: string;
  targetPortal: AdditionalLinkTargetPortal;
};

export type AdditionalLinkTargetPortal = 'b2b' | 'b2x';

const useAskForHelp = () => {
  const { runMutation, data, loading, error } =
    useCallBackendMutation<AskForHelpResponse>(askForHelpMutation);

  const askForHelp = useCallback(
    (question?: string) => {
      runMutation({
        variables: {
          question
        },
        fetchPolicy: 'no-cache'
      });
    },
    [runMutation]
  );

  return {
    askForHelp,
    response: data?.askForHelp,
    loading,
    hasError: !!error
  };
};

export default useAskForHelp;
