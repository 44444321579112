/**
 * Enables users to select text in links.
 *
 * By default, browser anchor tags prevent text selection within
 * themselves, and always fire when the user clicks within a link
 * even if an anchor tag has the property `draggable="false"`. We
 * need to explicitly override the `onMouseClick` event to prevent
 * its default action when a user selects any text in a link.
 *
 * @param e Mouse click event invoked on the anchor tag.
 */
export default (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
  const selection = window.getSelection();
  if (selection?.toString() !== '') {
    e.preventDefault();
    e.stopPropagation();
  }
};
