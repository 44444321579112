import { Flex, Loader, Page } from '@gaiads/telia-react-component-library';

const FullPageLoader: React.FC<{
  loading?: boolean;
  children?: React.ReactNode;
}> = ({ loading = false, children }) => {
  if (loading) {
    return (
      <Page padding={{ vertical: 'lg' }}>
        <Flex
          centeredHorizontally
          centeredVertically
          occupyVerticalSpace
          data-testid="full-page-loader"
        >
          <Loader />
        </Flex>
      </Page>
    );
  }

  return children;
};

export default FullPageLoader;
