import useAskForHelp from 'hooks/askForHelp/useAskForHelp';
import useIsAskForHelpAvailable from 'hooks/askForHelp/useIsAskForHelpAvailable';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setConversationHistory } from 'redux/slices/askForHelpSlice';

const AskForHelpProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { askForHelpAvailable } = useIsAskForHelpAvailable();

  if (!askForHelpAvailable) {
    return <>{children}</>;
  }

  return <AskForHelpProviderActive>{children}</AskForHelpProviderActive>;
};

const AskForHelpProviderActive: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { askForHelp, response: history, loading } = useAskForHelp();
  const dispatch = useDispatch();

  useEffect(() => {
    askForHelp();
  }, [askForHelp]);

  useEffect(() => {
    if (!loading && !!history) {
      dispatch(setConversationHistory(history));
    }
  }, [dispatch, history, loading]);

  return <>{children}</>;
};

export default AskForHelpProvider;
