import { FetchMoreResults, ServiceRequestsInResults } from 'types/serviceRequest';

export default (
  previousResult: ServiceRequestsInResults,
  { fetchMoreResult }: FetchMoreResults
): ServiceRequestsInResults =>
  !fetchMoreResult
    ? previousResult
    : {
        serviceRequests: {
          ...fetchMoreResult.serviceRequests,
          items: [
            ...(previousResult.serviceRequests.items || []),
            ...(fetchMoreResult.serviceRequests.items || [])
          ]
        }
      };
