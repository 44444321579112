import { Panel } from '@gaiads/telia-react-component-library';
import useScrollElementIntoView from 'hooks/useScrollElementIntoView/useScrollElementIntoView';
import React from 'react';

import styles from './DashboardSection.module.scss';
import { DashboardSectionCollapsible } from './DashboardSectionCollapsible';
import { DashboardSectionStandalone } from './DashboardSectionStandalone';
import { UserSectionDefinition } from './sections/sectionDefinition';

export const DashboardSection: React.FC<{ section: UserSectionDefinition }> = ({ section }) => {
  const panelRef: React.RefObject<HTMLDivElement> = React.useRef(null);

  useScrollElementIntoView({
    elementRef: panelRef,
    shouldScroll: section.shouldScroll,
    queryParamsToClear: ['section']
  });

  return (
    <Panel className={styles.panel} margin={{ bottom: 'sm' }} refElement={panelRef}>
      <Panel.Content
        padding={{ size: 'xxs' }}
        className={styles.panel_content}
        data-testid={`section-${section.id}`}
      >
        {section.expandable ? (
          <DashboardSectionCollapsible section={section} />
        ) : (
          <DashboardSectionStandalone section={section} />
        )}
      </Panel.Content>
    </Panel>
  );
};
