import useAsset from 'hooks/assets/useAsset/useAsset';
import { useParams } from 'react-router-dom';

import InternetConnectionDetailsPage from './InternetConnectionDetailsPage';

const InternetConnectionDetails: React.FC = () => {
  const { assetId } = useParams<{ assetId: string }>();
  const { asset, loading, hasError } = useAsset(assetId);

  return <InternetConnectionDetailsPage asset={asset} loading={loading} hasError={hasError} />;
};

export default InternetConnectionDetails;
