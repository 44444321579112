import 'react-modern-drawer/dist/index.css';

import { Div } from '@gaiads/telia-react-component-library';
import { Badge, Icon } from '@teliafi/fi-ds';
import { default as getClassNames } from 'classnames';
import { Heading, IconButton, ModalButtons, Optional } from 'common-components';
import { AcceptButtonProps, ModalButtonProps } from 'common-components/ModalButtons/ModalButtons';
import { noOp } from 'doings/noOp/noOp';
import FocusTrap from 'focus-trap-react';
import { useFocusTrap } from 'hooks/useFocusTrap/useFocusTrap';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Drawer from 'react-modern-drawer';

import styles from './ModalDrawer.module.scss';

type DrawerDirection = 'top' | 'bottom' | 'left' | 'right';

type TagProps = {
  labelKey: string;
  variant: keyof typeof TAG_VARIANTS;
};

const TAG_VARIANTS = {
  beta: {
    dsBadgeVariant: 'warning' as React.ComponentProps<typeof Badge>['variant'],
    iconName: 'star' as React.ComponentProps<typeof Icon>['name']
  }
} as const;

type ButtonsType = CustomButtonsType | StandardButtonsType;

type CustomButtonsType = {
  component: React.ReactNode;
  modalButtons?: never;
};

type StandardButtonsType = {
  component?: never;
  modalButtons: {
    accept?: AcceptButtonProps;
    close?: ModalButtonProps;
    auxiliary?: ModalButtonProps;
    closeIsDefault?: boolean;
  };
};

const isCustomButtons = (buttons: ButtonsType): buttons is CustomButtonsType => {
  return 'component' in buttons;
};

interface ModalDrawerProps {
  isOpen?: boolean;
  title: string;
  tag?: TagProps;
  direction?: DrawerDirection;
  onClose: VoidFunction;
  hideCloseIcon?: boolean;
  disableOutsideClick?: boolean;
  buttons?: ButtonsType;
  contentRef?: React.RefObject<HTMLDivElement>;
  'data-testid'?: string;
  children: React.ReactNode;
}

const ModalDrawer: React.FC<ModalDrawerProps> = ({
  isOpen = false,
  title,
  tag,
  direction = 'right',
  onClose,
  hideCloseIcon = false,
  disableOutsideClick = false,
  buttons,
  contentRef,
  'data-testid': testId,
  children
}) => {
  const { t } = useTranslation();
  const { isFocusTrapActive } = useFocusTrap({
    lockScroll: true,
    open: isOpen,
    zIndex: 650
  });

  useEffect(() => {
    document.addEventListener('keyup', closeKeyHandler);
    return () => {
      document.removeEventListener('keyup', closeKeyHandler);
    };
  });

  function closeKeyHandler({ key }: { key: string }) {
    if (key === 'Escape' && isFocusTrapActive && onClose) {
      onClose();
    }
  }

  return (
    <div data-testid="modal-drawer-wrapper">
      <FocusTrap active={isFocusTrapActive} focusTrapOptions={{ allowOutsideClick: true }}>
        <div>
          <Div
            data-testid="modal-drawer-backdrop"
            className={getClassNames({
              [styles.drawer_overlay]: isOpen
            })}
            onClick={isOpen && !disableOutsideClick ? onClose : noOp}
            aria-hidden="true"
          />

          <Div
            className={styles.drawer_hiddenFocus}
            tabIndex={isOpen ? 0 : -1}
            data-focusable="true"
          />

          <Drawer
            open={isOpen}
            onClose={onClose}
            direction={direction}
            zIndex={650}
            className={styles.drawer}
            enableOverlay={false}
          >
            {isOpen && (
              <Div
                data-testid={testId}
                role="dialog"
                aria-modal="true"
                aria-label={title}
                backgroundColor="white"
                padding={{ size: 'xlg' }}
                className={getClassNames(styles.drawer_container)}
              >
                <Div className={getClassNames(styles.drawer_header)}>
                  <Heading.H3 data-testid="modal-drawer-title">
                    {title}

                    {!!tag && (
                      <Badge
                        data-testid="modal-drawer-tag"
                        className={styles.drawer_tag}
                        variant={TAG_VARIANTS[tag.variant].dsBadgeVariant}
                        showIcon={false}
                      >
                        <Icon name={TAG_VARIANTS[tag.variant].iconName} size="xs" />

                        {t(tag.labelKey)}
                      </Badge>
                    )}
                  </Heading.H3>

                  <Optional if={!hideCloseIcon}>
                    <IconButton
                      name="close"
                      size="md"
                      aria-label={t('common.closeButton.label')}
                      data-testid="modal-drawer--close-icon"
                      onClick={onClose}
                    />
                  </Optional>
                </Div>

                <Div refElement={contentRef} className={styles.drawer_content}>
                  {children}
                </Div>

                <ModalDrawerButtons buttons={buttons} />
              </Div>
            )}
          </Drawer>
        </div>
      </FocusTrap>
    </div>
  );
};

const ModalDrawerButtons: React.FC<{ buttons?: ButtonsType }> = ({ buttons }) => {
  if (!buttons) {
    return null;
  }

  if (isCustomButtons(buttons)) {
    return <>{buttons.component}</>;
  }

  const { accept, close, auxiliary, closeIsDefault } = buttons.modalButtons;
  return (
    <ModalButtons
      accept={accept}
      close={close}
      auxiliary={auxiliary}
      closeIsDefault={closeIsDefault}
      forDrawer
    />
  );
};

export default ModalDrawer;
