import { matchPath, useLocation } from 'react-router';

const removeSuffix = (to: string, char: string) => {
  const indexOfChar = to.indexOf(char);
  return indexOfChar > -1 ? to.substring(0, indexOfChar) : to;
};

const toUrlWithoutQueryOrFragment = (to: string) => {
  return removeSuffix(removeSuffix(to, '?'), '#');
};

const isPathMatch = (currentPathname: string, paths: string[]) =>
  paths.map(toUrlWithoutQueryOrFragment).some(
    (toUrl) =>
      !!matchPath(currentPathname, {
        path: toUrl,
        exact: toUrl === '/',
        strict: false
      })?.url
  );

export default () => {
  const location = useLocation();

  return (paths: string[]) => isPathMatch(location.pathname, paths);
};
