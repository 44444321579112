import { gql } from '@apollo/client';

export const searchableContentQuery = gql`
  query GetSearchableContent {
    searchableContent {
      title
      keywords
      keywordWeightRate
      target {
        url
        targetPortal
      }
    }
  }
`;
