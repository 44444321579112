import { Checkbox, Div, Flex, Gutter } from '@gaiads/telia-react-component-library';
import useIsSmallerBreakpointActive from 'hooks/useIsSmallerBreakpointActive/useIsSmallerBreakpointActive';

import styles from './ListViewHeader.module.scss';

export type MultipleCompaniesCheckboxProps = {
  checked?: boolean;
  label: string;
  testId?: string;
  onChange: VoidFunction;
};

const ListViewHeader: React.FC<{
  withMultipleCompanySupport?: boolean;
  multipleCompaniesCheckbox?: MultipleCompaniesCheckboxProps;
  children?: React.ReactNode;
}> = ({ withMultipleCompanySupport, multipleCompaniesCheckbox, children }) => {
  const isMobileView = useIsSmallerBreakpointActive('md');
  const hasChildren = !!children;

  return (
    <Flex pileVerticallyTo="sm" reverseDirection={!isMobileView} data-testid="list-view-header">
      <Div flexItem={{ sizeMultiplier: 4 }} className={styles.listViewHeader_activeCompanySelector}>
        {withMultipleCompanySupport && !!multipleCompaniesCheckbox && (
          <Flex
            data-testid="multiple-company-checkbox-container"
            alignRight={!isMobileView}
            margin={{ top: 'sm', bottom: isMobileView ? 'zero' : 'xs' }}
          >
            <Checkbox
              data-testid={multipleCompaniesCheckbox.testId}
              checked={multipleCompaniesCheckbox.checked}
              label={multipleCompaniesCheckbox.label}
              onChange={multipleCompaniesCheckbox.onChange}
            />
          </Flex>
        )}
      </Div>

      {!hasChildren && (
        <Div data-testid="list-view-header-spacer" flexItem={{ sizeMultiplier: 5 }} />
      )}

      {hasChildren && (
        <>
          <Gutter responsiveHidden={{ widerThan: 'md' }} />

          <Div data-testid="list-view-children" flexItem={{ sizeMultiplier: 5 }}>
            {children}
          </Div>
        </>
      )}
    </Flex>
  );
};

export default ListViewHeader;
