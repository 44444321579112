import BillingGroupPageError from 'B2XApp/Invoicing/BillingGroups/Common/BillingGroupPageError';
import BillingGroupPageLoading from 'B2XApp/Invoicing/BillingGroups/Common/BillingGroupPageLoading';

import NewBillingGroupPage from './NewBillingGroupPage';
import useNewBillingGroupData from './useNewBillingGroupData';

const NewBillingGroup: React.FC = () => {
  const { status, availableBanks, availableOperators } = useNewBillingGroupData();

  switch (status) {
    case 'loaded':
      return (
        <NewBillingGroupPage
          availableBanks={availableBanks}
          availableOperators={availableOperators}
        />
      );
    case 'loading':
      return <BillingGroupPageLoading pageType="creation" />;
    case 'error':
      return <BillingGroupPageError pageType="creation" />;
  }
};

export default NewBillingGroup;
