import { ApolloError } from '@apollo/client';
import subscriptionPukCodeSearchQuery from 'hooks/mobileSubscriptions/useSubscriptionDetails/subscriptionPukCodeSearchQuery';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import useCorporatePortalParam from 'hooks/useCorporatePortalParam/useCorporatePortalParam';
import { useTranslation } from 'react-i18next';

import mapSubscriptionDetails from './mapSubscriptionDetails';
import mapSubscriptionOwnershipChange from './mapSubscriptionOwnershipChange';
import subscriptionDetailsQuery, {
  Balance,
  SubscriptionDetails,
  SubscriptionDetailsResponse
} from './subscriptionDetailsQuery';

export type LoadingStatus = 'loading' | 'loaded' | 'error';

export type OwnershipChangeDetails = {
  msisdn: string;
  completed: boolean;
  tokenExpiryDate: string;
  tokenTerminationDate?: string;
};

export type UseSubscriptionDetailsResponse = {
  subscription?: SubscriptionDetails;
  balance?: Balance;
  error?: ApolloError;
  ownershipChange?: OwnershipChangeDetails;
  status: LoadingStatus;
};

const useSubscriptionDetails = (
  subscriptionId: string,
  {
    fetchBalance = false,
    fetchOmsu = false,
    fetchOnlyPukCodes = false
  }: { fetchBalance?: boolean; fetchOmsu?: boolean; fetchOnlyPukCodes?: boolean } = {}
): UseSubscriptionDetailsResponse => {
  const corporatePortalParam = useCorporatePortalParam();
  const { loading, data, error } = useCallBackend<SubscriptionDetailsResponse>({
    query: fetchOnlyPukCodes ? subscriptionPukCodeSearchQuery : subscriptionDetailsQuery,
    queryVariables: {
      subscriptionId,
      shouldFetchBalance: fetchBalance,
      shouldFetchOmsu: fetchOmsu,
      ...corporatePortalParam
    },
    fetchPolicy: 'network-only',
    errorPolicy: 'all'
  });
  const { t } = useTranslation();
  const namePlaceholder = t('subscriptions.noUserName');
  const subscriptionStatus = data?.subscription?.subscription ? 'loaded' : 'error';

  return {
    subscription:
      (data?.subscription?.subscription &&
        mapSubscriptionDetails(namePlaceholder, data.subscription.subscription)) ||
      undefined,
    balance: data?.subscription?.balance || undefined,
    ownershipChange:
      (data?.subscription?.subscription?.omsuToken &&
        mapSubscriptionOwnershipChange(
          data.subscription.subscription.id,
          data.subscription.subscription.omsuToken
        )) ||
      undefined,
    error,
    status: loading ? 'loading' : subscriptionStatus
  };
};

export default useSubscriptionDetails;
