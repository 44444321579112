import { Paragraph } from '@teliafi/fi-ds';
import { ProgressBarWithoutLabel } from 'common-components/ProgressBar/ProgressBarWithoutLabel';
import { Flex } from 'core-components';

const OrderProgressBar: React.FC<{ progress: number }> = ({ progress }) => (
  <Flex centeredVertically>
    <Paragraph style={{ minWidth: '2.6em' }}>{`${progress}%`}</Paragraph>

    <ProgressBarWithoutLabel
      padding={{ left: 'xs' }}
      values={{
        variant: 'calculated',
        current: progress,
        max: 100
      }}
    />
  </Flex>
);

export default OrderProgressBar;
