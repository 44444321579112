import removeEmptyFields from 'doings/removeEmptyFields/removeEmptyFields';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export type UseWriteQueryParamsProps = {
  [key: string]: string | undefined;
};

const searchToObject = (str: string) => {
  const result: UseWriteQueryParamsProps = {};
  new URLSearchParams(str).forEach((value, key) => {
    if (value) {
      result[key] = value;
    }
  });
  return result;
};

const useWriteQueryParams = () => {
  const history = useHistory();

  return {
    write: useCallback(
      (props: UseWriteQueryParamsProps) => {
        const original = history.location.search;
        const search = `?${new URLSearchParams(
          removeEmptyFields({
            ...searchToObject(original),
            ...props
          })
        ).toString()}`;

        const hasChanged = search !== original;
        if (hasChanged) {
          history.replace({
            pathname: history.location.pathname,
            search
          });
        }
      },
      [history]
    )
  };
};

export default useWriteQueryParams;
