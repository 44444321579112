import { Div, SearchInput, SearchInputWithScope } from '@gaiads/telia-react-component-library';
import { OptionListOption } from '@gaiads/telia-react-component-library/build/types/components/OptionList/OptionList';
import { SmartTooltip } from 'common-components';
import { focusHtmlElementOnNextFrame } from 'doings/focus/focusHtmlElement';
import { toProperCaseMidSentence } from 'doings/string/toProperCaseMidSentence';
import useDebounce from 'hooks/useDebounce/useDebounce';
import useReadQueryParams from 'hooks/useQueryParams/useReadQueryParams';
import useWriteQueryParams from 'hooks/useQueryParams/useWriteQueryParams';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SEARCH_TEXT_MAX_LENGTH } from 'types/form';
import { SearchDefinition, SearchOptionDefinition } from 'types/listFilters';

import styles from './Searchfield.module.scss';

const DEBOUNCE_LENGTH: Time.Milliseconds = 750;

export const Searchfield: React.FC<{
  search: SearchDefinition;
  initialScope?: string;
  initialTerm?: string;
  label?: string;
}> = ({
  search: {
    param,
    placeholder,
    scopeParam,
    scopeOptions,
    queryActivationThresholdLength,
    hasNoneOption
  },
  initialScope,
  initialTerm,
  label
}) => {
  if (!!scopeParam && !!scopeOptions && scopeOptions?.length > 0) {
    return (
      <SearchfieldWithScope
        param={param}
        placeholder={placeholder}
        scopeParam={scopeParam}
        scopeOptions={scopeOptions}
        initialScope={initialScope}
        initialTerm={initialTerm}
        queryActivationThresholdLength={queryActivationThresholdLength}
        label={label}
        hasNoneOption={hasNoneOption}
      />
    );
  } else {
    return (
      <SearchfieldWithoutScope
        param={param}
        placeholder={placeholder}
        initialTerm={initialTerm}
        queryActivationThresholdLength={queryActivationThresholdLength}
        label={label}
      />
    );
  }
};

export const shouldSkipWriteQueryParams = (
  value: string,
  activationThresholdLength?: number
): boolean => {
  if (!activationThresholdLength) {
    return false;
  }

  const length = value.length;
  return length > 0 && length < activationThresholdLength;
};

export const shouldShowScopePrompt = (
  scope: string,
  value: string,
  activationThresholdLength?: number
): boolean => {
  const threshold = activationThresholdLength ?? 1;
  return value.length >= threshold && scope === '';
};

const SearchfieldWithScope: React.FC<{
  param: string;
  placeholder?: string;
  scopeParam: string;
  scopeOptions: Array<SearchOptionDefinition>;
  initialScope?: string;
  initialTerm?: string;
  queryActivationThresholdLength?: number;
  label?: string;
  hasNoneOption?: boolean;
}> = ({
  param,
  placeholder,
  scopeParam,
  scopeOptions,
  initialScope,
  initialTerm,
  queryActivationThresholdLength,
  label,
  hasNoneOption
}) => {
  const { t } = useTranslation();
  const scopeFromUrlParams = useReadQueryParams(['scope']).scope;
  const [scope, setScope] = useState<OptionListOption>(
    () =>
      scopeOptions.find((o) => o.value === scopeFromUrlParams) ||
      (hasNoneOption && { label: t('common.searchInput.noScopeSelected'), value: '' }) ||
      scopeOptions.find((o) => o.value === initialScope) ||
      scopeOptions[0]
  );

  const searchRef: React.RefObject<HTMLDivElement> = React.useRef(null);
  const [value, setValue] = useState<string>(() => initialTerm ?? '');
  const debouncedValue = useDebounce<string>(value.trim(), DEBOUNCE_LENGTH);

  const { write: writeQueryParams } = useWriteQueryParams();
  const skipWrite = shouldSkipWriteQueryParams(debouncedValue, queryActivationThresholdLength);
  const showPrompt = shouldShowScopePrompt(scope.value, value, queryActivationThresholdLength);
  React.useEffect(() => {
    if (skipWrite || scope.value === '') {
      return;
    }

    writeQueryParams({
      [scopeParam]: scope.value,
      [param]: debouncedValue
    });
  }, [writeQueryParams, param, scopeParam, scope, debouncedValue, skipWrite]);

  const tooltip = scopeOptions.find((o) => o.value === scope.value)?.tooltip;
  const scopeInPlaceholder = useMemo(() => toProperCaseMidSentence(scope.label), [scope.label]);

  return (
    <Div data-testid="list-search-with-scope" refElement={searchRef}>
      {label && (
        <label htmlFor="list-search-input" className={styles.label}>
          {label}
        </label>
      )}

      <SmartTooltip
        data-testid="list-search-tooltip"
        tooltipContent={tooltip}
        arrangement="top"
        wrapper="div"
      >
        <SmartTooltip.Trigger wrapper="div">
          <SearchInputWithScope
            data-id="list-search-input"
            data-testid="list-search-input-with-scope"
            id="list-search-input"
            showCustomLabel
            autoFocus={value.length > 0}
            searchValue={value}
            maxLength={SEARCH_TEXT_MAX_LENGTH}
            onSearchChange={(_event, newValue: string) => setValue(newValue)}
            scopeOptions={scopeOptions}
            selectedScope={scope}
            onScopeChange={(_event, newScope: OptionListOption) => {
              setScope(newScope);

              const container = searchRef.current;
              /* istanbul ignore next */
              if (!container) {
                return;
              }

              // Delay to the next frame so that a visible tooltip could be cleared naturally
              // on dropdown blur before the search input field receives focus. Otherwise, an
              // empty tooltip will persist.
              focusHtmlElementOnNextFrame(() =>
                container.querySelector('[data-id="list-search-input"]')
              );
            }}
            placeholder={
              scope.value !== ''
                ? t('common.searchInput.placeholderWithScope', { scopeLabel: scopeInPlaceholder })
                : placeholder ?? t('common.searchInput.placeholder')
            }
            className={styles.searchfield}
            errorText={showPrompt ? t('common.searchInput.missingScopePrompt') : undefined}
          />
        </SmartTooltip.Trigger>
      </SmartTooltip>
    </Div>
  );
};

const SearchfieldWithoutScope: React.FC<{
  param: string;
  placeholder?: string;
  initialTerm?: string;
  queryActivationThresholdLength?: number;
  label?: string;
}> = ({ param, placeholder, initialTerm, queryActivationThresholdLength, label }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string>(() => initialTerm ?? '');
  const debouncedValue = useDebounce<string>(value.trim(), DEBOUNCE_LENGTH);

  const { write: writeQueryParams } = useWriteQueryParams();
  const skipWrite = shouldSkipWriteQueryParams(debouncedValue, queryActivationThresholdLength);

  React.useEffect(() => {
    if (skipWrite) {
      return;
    }

    writeQueryParams({
      [param]: debouncedValue
    });
  }, [writeQueryParams, param, debouncedValue, skipWrite]);

  return (
    <Div data-testid="list-search-without-scope">
      {label && (
        <label htmlFor="list-search-input" className={styles.label}>
          {label}
        </label>
      )}

      <SearchInput
        data-testid="list-search-input-without-scope"
        id="list-search-input"
        autoFocus={value.length > 0}
        value={value}
        maxLength={SEARCH_TEXT_MAX_LENGTH}
        onChange={(_event, newValue: string) => setValue(newValue)}
        placeholder={placeholder ?? t('common.searchInput.placeholder')}
        className={styles.searchfield}
      />
    </Div>
  );
};
