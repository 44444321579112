import { Div } from '@gaiads/telia-react-component-library';
import Banners from 'B2XApp/Notifications/Banners';
import getClassNames from 'classnames';
import { Optional } from 'common-components';
import { useFeatureFlagsData } from 'contexts/FeatureFlagContext/FeatureFlagContext';
import { useUserData } from 'contexts/UserContext/UserContext';
import { useNotifications } from 'hooks/notifications/useNotifications/useNotifications';

import { canShowFeature, notificationVisibilityConditions } from './DashboardNavigation';
import styles from './DashboardNavigation.module.scss';
import DashboardNotifications from './DashboardNotifications/DashboardNotifications';
import { DashboardSection } from './DashboardSections/DashboardSection';
import { getOrderedAndExpandedSections } from './DashboardSections/sections/getOrderedAndExpandedSections';

const PARITY_MOD = 2;
const odd = (_value: unknown, index: number) => index % PARITY_MOD !== 0;
const even = (_value: unknown, index: number) => index % PARITY_MOD === 0;

export const DashboardNavigationB2O: React.FC = () => {
  const user = useUserData();
  const { hasFeature } = useFeatureFlagsData();
  const { banners, loaded: notificationsLoaded } = useNotifications();
  const search = new URLSearchParams(window.location.search);
  const sectionParam = search.get('section');
  const dashboardLinkSections = getOrderedAndExpandedSections(
    user,
    sectionParam,
    notificationsLoaded
  );

  const canShowNotifications = canShowFeature(user, hasFeature, notificationVisibilityConditions);
  const showLinksOnly = !canShowNotifications && !banners.length;

  if (showLinksOnly) {
    return (
      <Div className={styles.dashboardItems}>
        <Div className={styles.column}>
          {dashboardLinkSections.filter(even).map((section, index) => (
            <Div
              key={`dashboard-item--${section.id}`}
              className={getClassNames(
                styles.dashboardItem,
                styles[`dashboardItem_index_${index * PARITY_MOD}`]
              )}
            >
              <DashboardSection section={section} key={section.id} />
            </Div>
          ))}
        </Div>

        <Div className={styles.column}>
          {dashboardLinkSections.filter(odd).map((section, index) => (
            <Div
              key={`dashboard-item--${section.id}`}
              className={getClassNames(
                styles.dashboardItem,
                styles[`dashboardItem_index_${PARITY_MOD * index + 1}`]
              )}
            >
              <DashboardSection section={section} key={section.id} />
            </Div>
          ))}
        </Div>
      </Div>
    );
  }

  return (
    <Div className={styles.dashboardItems}>
      <Div className={styles.column}>
        <Div
          className={getClassNames([styles.dashboardItem, styles.dashboardItem_index_3])}
          data-testid="dashboard-links"
        >
          {dashboardLinkSections.map((section) => (
            <DashboardSection key={section.id} section={section} />
          ))}
        </Div>
      </Div>

      <Div className={styles.column}>
        {canShowNotifications && (
          <Div
            className={getClassNames([styles.dashboardItem, styles.dashboardItem_index_1])}
            data-testid="dashboard-notifications"
          >
            <DashboardNotifications />
          </Div>
        )}

        <Optional data={banners}>
          <Div
            className={getClassNames([styles.dashboardItem, styles.dashboardItem_index_2])}
            data-testid="dashboard-promo-banners"
          >
            <Banners banners={banners} />
          </Div>
        </Optional>
      </Div>
    </Div>
  );
};
