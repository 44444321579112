import { ApolloError, MutationFunctionOptions, OperationVariables, gql } from '@apollo/client';
import { FormattedExecutionResult } from 'graphql';
import { invoiceQuery } from 'hooks/invoicing/invoices/useInvoice/useInvoice';
import useCallBackendMutation from 'hooks/useCallBackend/useCallBackendMutation';

export const applyNewPaymentPlanMutation = gql`
  mutation ApplyNewInvoicePaymentPlan($key: String, $notificationTo: String) {
    applyNewPaymentPlan(key: $key, notificationTo: $notificationTo) {
      type
      firstPaymentDate
      firstPaymentAmount
      totalAmount
      notificationTo
    }
  }
`;

type ApplyNewPaymentPlanResult = {
  applyNewPaymentPlan: {
    type: string;
    firstPaymentDate: string;
    firstPaymentAmount: number;
    totalAmount: number;
    notificationTo: string;
  };
};

export type ApplyPaymentPlan = (
  options?: MutationFunctionOptions<ApplyNewPaymentPlanResult, OperationVariables>
) => Promise<FormattedExecutionResult<ApplyNewPaymentPlanResult>>;

type UseApplyNewPaymentPlanHook = {
  applyPaymentPlan: ApplyPaymentPlan;
  data: ApplyNewPaymentPlanResult | undefined;
  loading: boolean;
  error: ApolloError | undefined;
};

const useApplyNewPaymentPlan = (key: string): UseApplyNewPaymentPlanHook => {
  const { runMutation, data, loading, error } = useCallBackendMutation<ApplyNewPaymentPlanResult>(
    applyNewPaymentPlanMutation,
    [
      {
        query: invoiceQuery,
        variables: {
          key
        }
      }
    ]
  );

  return {
    applyPaymentPlan: runMutation,
    data,
    loading,
    error
  };
};

export default useApplyNewPaymentPlan;
