import routeAccessGroups from 'B2XApp/appRouteAccessGroups';
import ListViewActionButtons, {
  MoreActionsLinkDescriptor
} from 'common-components/ListViewActions/ListViewActionButtons/ListViewActionButtons';
import createCorporatePortalLink from 'doings/links/createCorporatePortalLink/createCorporatePortalLink';
import { mobile } from 'doings/track/analyticsEvents';
import { useTranslation } from 'react-i18next';

import { NewSubscriptionButton } from '../NewSubscriptionButton/NewSubscriptionButton';

const SubscriptionsListActionButtons: React.FC = () => {
  const { t } = useTranslation();

  const moreActionLinks: MoreActionsLinkDescriptor[] = [
    {
      key: 'link-to-mobile-subscriptions-mass-change',
      to: createCorporatePortalLink('massOperations/massChange'),
      label: t('navigationalLink.title.massChange'),
      enabledWhen: routeAccessGroups.B2B_QUICKLINKS_MOBILE,
      track: mobile.navigateToMassChange,
      type: 'external'
    },
    {
      key: 'link-to-mobile-subscriptions-ownership-change',
      to: createCorporatePortalLink('ownershipChange'),
      label: t('navigationalLink.title.ownershipChange'),
      enabledWhen: routeAccessGroups.B2B_QUICKLINKS_MOBILE,
      track: mobile.navigateToOwnershipChange,
      type: 'external'
    },
    {
      key: 'link-to-order-permissions',
      to: createCorporatePortalLink('admin/orderPermission'),
      label: t('navigationalLink.title.serviceOrderPermissions'),
      enabledWhen: routeAccessGroups.B2B_QUICKLINKS_ADMIN,
      track: mobile.navigateToServiceOrderPermissions,
      type: 'external'
    },
    {
      key: 'link-to-order-sim-cards',
      to: createCorporatePortalLink('orderSimCards'),
      label: t('navigationalLink.title.orderSim'),
      enabledWhen: routeAccessGroups.B2B_QUICKLINKS_MOBILE,
      track: mobile.navigateToOrderSimCard,
      type: 'external'
    },
    {
      key: 'link-to-mobile-subs-report',
      to: createCorporatePortalLink('reports/mobilesubs'),
      label: t('navigationalLink.title.mobileSubscriptionsReport'),
      enabledWhen: routeAccessGroups.B2B_QUICKLINKS_REPORTS,
      track: mobile.navigateToMobileSubsReport,
      type: 'external'
    }
  ];

  return (
    <ListViewActionButtons
      mainActionButton={<NewSubscriptionButton />}
      moreActionsLinks={moreActionLinks}
    />
  );
};

export default SubscriptionsListActionButtons;
