import { CollapsibleBlock } from 'common-components';
import { useTranslation } from 'react-i18next';
import { Finvoice } from 'types/collection-group';

import FinvoiceDetails from './FinvoiceDetails';
import styles from './FinvoiceDetailsBlock.module.scss';

type Key = keyof Finvoice;
const FINVOICE_ATTRIBUTES: Key[] = [
  'orderIdentifier',
  'buyerReferenceIdentifier',
  'agreementIdentifier',
  'accountDimensionText',
  'tenderReference',
  'proposedAccountText',
  'normalProposedAccountIdent',
  'projectReferenceIdentifier'
];

const hasAnyAttribute = (finvoice: Finvoice) =>
  FINVOICE_ATTRIBUTES.map((key) => finvoice[key]).some((value) => !!value);

const FinvoiceDetailsBlock = ({ finvoice }: { finvoice?: Finvoice }) => {
  const { t } = useTranslation();

  return (
    <CollapsibleBlock
      blockTitle={t('billingInformation.finvoice.accordionShow')}
      blockTitleExpanded={t('billingInformation.finvoice.accordionHide')}
      className={styles.collapsibleBlock}
      expanded={!!finvoice && hasAnyAttribute(finvoice)}
    >
      <CollapsibleBlock.Content padding={{ vertical: 'xs', horizontal: 'zero' }}>
        <FinvoiceDetails finvoice={finvoice ?? {}} />
      </CollapsibleBlock.Content>
    </CollapsibleBlock>
  );
};

export default FinvoiceDetailsBlock;
