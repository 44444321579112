import { Div } from '@gaiads/telia-react-component-library';
import { Icon, VisuallyHidden } from '@teliafi/fi-ds';
import getClassNames from 'classnames';
import { Button } from 'common-components';
import useIsSmallerBreakpointActive from 'hooks/useIsSmallerBreakpointActive/useIsSmallerBreakpointActive';
import { useTranslation } from 'react-i18next';

import styles from './FilterDialogToggle.module.scss';

const FilterDialogToggle: React.FC<{
  toggle: VoidFunction;
  hasSearch: boolean;
  selected: boolean;
  disabled?: boolean;
  refElement?: React.RefObject<HTMLDivElement>;
}> = ({ toggle, hasSearch, selected, disabled, refElement }) => {
  const { t } = useTranslation();
  const isMobileView = useIsSmallerBreakpointActive('md');

  return (
    <Div refElement={refElement} data-testid="filter-dialog-toggle-wrapper">
      <Button
        id="toggle-filter"
        data-testid="filter-dialog-toggle"
        className={getClassNames(styles.filterDialogToggle, {
          [styles.filterDialogToggle_selected]: selected
        })}
        variant="secondary"
        onClick={toggle}
        disabled={disabled}
      >
        <Icon name="filter" size="xs" data-testid="filter-dialog-toggle-filter-icon" />

        {!isMobileView && (
          <Div padding={{ left: 'xs' }}>{t('common.filterSettings.toggleLabel')}</Div>
        )}

        {isMobileView && <VisuallyHidden>{t('common.filterSettings.toggleLabel')}</VisuallyHidden>}

        {hasSearch && isMobileView && (
          <Icon name="search" size="sm" data-testid="filter-dialog-toggle-search-icon" />
        )}
      </Button>
    </Div>
  );
};

export default FilterDialogToggle;
