import { SearchInput } from '@gaiads/telia-react-component-library';
import styles from 'B2XApp/TopNavigation/Navigation/ContentSearch/ContentSearch.module.scss';
import { useContentSearchState } from 'contexts/SearchContext/ContentSearchContext';
import { useTranslation } from 'react-i18next';
import { EXTENDED_SEARCH_TEXT_MAX_LENGTH } from 'types/form';

const ContentSearchField: React.FC = () => {
  const { t } = useTranslation();
  const { searchTerm, setSearchTerm } = useContentSearchState();

  return (
    <SearchInput
      autoFocus
      className={styles.articleSearchField}
      value={searchTerm}
      data-testid="content-search-field"
      placeholder={t('topNavigation.articleSearch.placeHolder')}
      onChange={(_event: React.SyntheticEvent<HTMLDivElement, Event>, value: string) =>
        setSearchTerm(value)
      }
      maxLength={EXTENDED_SEARCH_TEXT_MAX_LENGTH}
      role="searchbox"
    />
  );
};

export default ContentSearchField;
