import { ApolloProvider } from '@apollo/client';
import { useCSRFTokenContext } from 'contexts/CSRFToken/CSRFToken';
import useActiveCompanyCookie from 'hooks/useActiveCompanyCookie/useActiveCompanyCookie';
import { useMemo } from 'react';

import getApolloClient from './getApolloClient';

const ApiProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { setCSRFToken } = useCSRFTokenContext();
  useActiveCompanyCookie(document);

  const apolloClient = useMemo(
    () => getApolloClient(`${process.env.PUBLIC_URL}/api/graphql`, setCSRFToken),
    // eslint-disable-next-line
    []
  );

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default ApiProvider;
