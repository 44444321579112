import { TeliaLink } from 'common-components';

import ConfirmationPanelLayout from './ConfirmationPanelLayout';

const ConfirmationPanelError: React.FC<{
  title: string;
  description: string;
  backToInitialStepOnClick: VoidFunction;
  backToLabel: string;
  'data-testid'?: string;
}> = ({ title, description, backToInitialStepOnClick, backToLabel, 'data-testid': testId }) => {
  return (
    <ConfirmationPanelLayout
      title={title}
      description={description}
      variant="failure"
      data-testid={testId}
    >
      <TeliaLink.Click
        data-testid="back-to-initial-step-button"
        variant="standalone"
        onClick={backToInitialStepOnClick}
        label={backToLabel || ''}
        iconRight="chevron-right"
      />
    </ConfirmationPanelLayout>
  );
};

export default ConfirmationPanelError;
