import { ApolloError } from '@apollo/client';
import { Page } from '@gaiads/telia-react-component-library';
import BillingGroupsSortDropdown from 'B2XApp/Invoicing/BillingGroups/BillingGroupsSortDropdown';
import { Memo, PageWithSubnavigation } from 'common-components';
import EmptyListContent from 'common-components/EmptyListContent/EmptyListContent';
import ListHeader from 'common-components/ListHeader/ListHeader';
import ShowMoreResults from 'common-components/ShowMoreResults/ShowMoreResults';
import { EditableListProvider } from 'contexts/EditableListContext/EditableListContext';
import { sortCaseInsensitive } from 'doings/sort/sort';
import { useOfflineLimit } from 'hooks/useOfflineLimit/useOfflineLimit';
import usePersistedSortableHeadings from 'hooks/useSortableHeadings/usePersistedSortableHeadings';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CollectionGroup } from 'types/collection-group';
import { SortOrder } from 'types/sort';

import BillingGroupActionButtons from './BillingGroupActionButtons';
import BillingGroupList, {
  BillingGroupListSortField,
  BillingGroupListViewColumnSetup
} from './BillingGroupListView';
import BillingGroupsInfoNotices from './BillingGroupsInfoNotices';
import { ExcelDownloadLink } from './ExcelDownloadLink/ExcelDownloadLink';
import { UseBillingGroupFilterResult } from './useBillingGroupListFilter';

export type BillingGroupPageType = 'creation' | 'edit' | 'copy';

const BillingGroupPage: React.FC<{
  groups: { items: CollectionGroup[]; total: number };
  filters: UseBillingGroupFilterResult;
  loading: boolean;
  loadingInitial: boolean;
  error?: ApolloError;
}> = ({ groups, filters, loading, loadingInitial, error }) => {
  const { t } = useTranslation();
  const [total, setTotal] = useState<number>(0);
  const { accountTypeOptions, billingMethodOptions, queryParameters, queryParametersAbsent } =
    filters;

  useEffect(() => {
    if (!loading) {
      setTotal(groups.total);
    }
  }, [groups.total, loading]);

  const sortableColumns = [
    BillingGroupListSortField.CollectionGroup,
    BillingGroupListSortField.Name,
    BillingGroupListSortField.Reference,
    BillingGroupListSortField.BillingAddress,
    BillingGroupListSortField.AddressSupplement,
    BillingGroupListSortField.BillingType
  ];
  const { sortState, sortClick, doSort, currentSort } = usePersistedSortableHeadings(
    sortableColumns,
    {
      sort: BillingGroupListSortField.CollectionGroup,
      order: SortOrder.ASC
    }
  );

  const sortedGroups = sortCaseInsensitive(groups.items, currentSort);

  const { limit, showMore } = useOfflineLimit([loading, sortedGroups.length, sortState]);
  const limitedGroups = sortedGroups.slice(0, limit);

  return (
    <EditableListProvider
      columnSetup={BillingGroupListViewColumnSetup}
      invocationArgs={[t, sortState, sortClick]}
      changeableArgs={[undefined, sortState, undefined]}
    >
      <PageWithSubnavigation
        loading={loadingInitial}
        error={error}
        emptyContentPlaceholder={
          !groups.total &&
          !loading &&
          queryParametersAbsent && (
            <EmptyListContent
              title={t('invoices.billingGroup.emptyList.title')}
              description={t('invoices.billingGroup.emptyList.description')}
              footerContent={<BillingGroupActionButtons />}
            />
          )
        }
        showSubnavigationForCurrentPath
      >
        <ListHeader
          titleTestid="billing-groups-count"
          actionButtons={<BillingGroupActionButtons />}
          customFilterParams={[
            {
              label: t('billingGroups.filter.accountType.label'),
              param: 'accountType',
              noNoneOption: true,
              options: accountTypeOptions,
              'data-testid': 'billing-accounts-account-type-dropdown'
            },
            {
              label: t('billingGroups.filter.billingMethod.label'),
              param: 'billingMethod',
              noNoneOption: true,
              options: billingMethodOptions,
              'data-testid': 'billing-accounts-billing-method-dropdown'
            }
          ]}
          labels={{ searchInMobileView: t('billingGroups.searchBillingGroups') }}
          sortDropdown={
            <BillingGroupsSortDropdown
              data-testid="billing-group-list-sort-dropdown"
              currentSort={currentSort}
              doSort={doSort}
            />
          }
          infoNotice={<BillingGroupsInfoNotices />}
          excelDownloadLink={
            <ExcelDownloadLink
              queryParameters={queryParameters}
              disabled={total === 0 || loading || loadingInitial}
              sortParameter={currentSort}
            />
          }
        >
          {t('invoices.billingGroup.list.numberOfBillingGroups', {
            numberOfBillingGroups: total
          })}
        </ListHeader>

        <Page.Row responsiveFullWidth>
          <Memo comparables={['loading', 'groups']}>
            <BillingGroupList groups={limitedGroups} loading={loading} />
          </Memo>
        </Page.Row>

        {!loading && (
          <Page.Row>
            <ShowMoreResults
              data-testid="show-more-results"
              loading={false}
              hasMoreResults={limit < total}
              hasResults={total > 0}
              fetchMore={showMore}
            />
          </Page.Row>
        )}
      </PageWithSubnavigation>
    </EditableListProvider>
  );
};

export default BillingGroupPage;
