import { gql } from '@apollo/client';
import { multiplex } from 'doings/multiplex/multiplex';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { ApiStatus } from 'types/api';

type TriggerableToursResult = { triggerableTours: string[] };

export const queryTriggerableTours = gql`
  query GetTriggerableTours {
    triggerableTours
  }
`;

export const useTriggerableToursQuery = () => {
  const query = useCallBackend<TriggerableToursResult>({
    query: queryTriggerableTours,
    fetchPolicy: 'cache-first',
    skip: !!window['__TF_B2X_AUTOMATION'] && window['__TF_B2X_AUTOMATION']
  });

  const queryStatus = multiplex([
    ApiStatus.Loading,
    [!!query.error, ApiStatus.Error],
    [query.loading, ApiStatus.Loading],
    [query.loaded, ApiStatus.Loaded]
  ]);

  return {
    triggerableTours: query.data?.triggerableTours,
    triggerableToursQueryStatus: queryStatus
  };
};
