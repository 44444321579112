import { gql } from '@apollo/client';
import { getDefaultDateParams } from 'hooks/serviceRequests/useServiceRequests/getDefaultDateParams';
import useApiStatus from 'hooks/useApiStatus/useApiStatus';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';

export const ticketsAggregateQuery = gql`
  query GetFaultReportsTotal(
    $offset: Int!
    $limit: Int!
    $status: String
    $allCompanies: Boolean
    $dateParameters: TicketDateParameters
  ) {
    tickets(
      offset: $offset
      limit: $limit
      status: $status
      allCompanies: $allCompanies
      dateParameters: $dateParameters
    ) {
      total
    }
  }
`;

type TicketsListResponse = {
  tickets: {
    total: number;
  };
};

const useTicketsAggregate = ({ skip }: { skip?: boolean }) => {
  const status = 'Pending';
  const { data, error, loading, loadingMore, loaded } = useCallBackend<TicketsListResponse>({
    query: ticketsAggregateQuery,
    queryVariables: {
      offset: 0,
      limit: 1,
      status,
      dateParameters: getDefaultDateParams(),
      allCompanies: true
    },
    fetchPolicy: 'cache-first',
    skip
  });

  return {
    totalWaitingForInformation:
      data?.tickets?.total || data?.tickets?.total === 0 ? data.tickets.total : -1,
    error,
    apiState: useApiStatus({
      loading,
      loadingMore,
      loaded,
      items: [],
      error,
      query: {
        offset: 0,
        limit: 1,
        status,
        dateParameters: getDefaultDateParams()
      }
    })
  };
};

export default useTicketsAggregate;
