import { ApolloError } from '@apollo/client';
import isLoginError from 'doings/isLoginError/isLoginError';
import isMissingActiveCompanyError from 'doings/isMissingActiveCompanyError/isMissingActiveCompanyError';
import isPermissionError from 'doings/isPermissionError/isPermissionError';
import redirectToAccessDenied from 'doings/navigation/redirectToAccessDenied/redirectToAccessDenied';
import redirectToCompanySelection from 'doings/redirectToCompanySelection/redirectToCompanySelection';
import redirectToLogin from 'doings/redirectToLogin/redirectToLogin';
import { login } from 'doings/track/analyticsEvents';
import trackEvent from 'doings/track/trackEvent';
import { History, Path } from 'history';

export type AccessControlHandlerUrlDetails = {
  pathname: string;
  currentLocation: string;
  queryString?: string;
  urlFragment?: string;
};

const constructRedirectUrl = (urlDetails: AccessControlHandlerUrlDetails): string => {
  return `${urlDetails.pathname}${urlDetails.queryString ?? ''}${urlDetails.urlFragment ?? ''}`;
};

export default (
  error: ApolloError,
  replaceFunction: (path: Path, state?: History.LocationState) => void,
  urlDetails: AccessControlHandlerUrlDetails
) => {
  if (isMissingActiveCompanyError(error)) {
    redirectToCompanySelection();
  } else if (isLoginError(error)) {
    redirectToLogin(constructRedirectUrl(urlDetails));
  } else if (isPermissionError(error)) {
    trackEvent(login.insufficientPermission);
    redirectToAccessDenied(replaceFunction, urlDetails.currentLocation, urlDetails.pathname);
  }
};
