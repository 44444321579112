import { multiplex } from 'doings/multiplex/multiplex';
import useEditableList from 'hooks/editableLists/useEditableList';
import { createContext, useContext, useMemo } from 'react';
import {
  ColumnVisibility,
  EditableColumnConfig,
  EditableListId,
  ListViewColumnSetup,
  ListViewColumns
} from 'types/listConfig';

export type EditableListState = {
  readonly status: 'n/a' | 'available' | 'loading' | 'err';
  readonly listId: EditableListId | undefined;
  readonly columns: ListViewColumns;
};

export type EligibleEditableListState = {
  readonly status: Exclude<EditableListState['status'], 'n/a'>;
  readonly listId: EditableListId;
  readonly columns: ListViewColumns;
};

export const isEditable = (state: EditableListState): state is EligibleEditableListState =>
  state.status !== 'n/a' && !!state.listId;

export const EditableListContext = createContext<EditableListState | undefined>(undefined);

const NO_CONTEXT_STATE: EditableListState = {
  status: 'n/a',
  listId: undefined,
  columns: {}
};

export const useEditableListData = () => {
  const context = useContext(EditableListContext);
  if (!context) {
    return NO_CONTEXT_STATE;
  }

  return context;
};

export function EditableListProvider<T extends unknown[]>({
  columnSetup,
  invocationArgs,
  changeableArgs,
  children
}: {
  columnSetup: ListViewColumnSetup<T>;
  invocationArgs: T;
  changeableArgs?: Partial<T>;
  children: React.ReactNode;
}) {
  const { listId, columns, status } = useEditableListInContext({
    columnSetup,
    invocationArgs,
    changeableArgs
  });

  return (
    <EditableListContext.Provider value={{ listId, columns, status }}>
      {children}
    </EditableListContext.Provider>
  );
}

const useEditableListInContext = <T extends unknown[]>({
  columnSetup: { listId, getColumns: getDefaultColumns },
  invocationArgs,
  changeableArgs
}: {
  columnSetup: ListViewColumnSetup<T>;
  invocationArgs: T;
  changeableArgs?: Partial<T>;
}): EditableListState => {
  const { enabled, columns: fetchedColumns, loading, error } = useEditableList(listId);

  /**
   * A list view's default column setup. Since column headings are translated,
   * an unstable tFunction provided via invocation arguments would result in
   * columns setup being recalculated on every render, so invocation arguments
   * are safely assumed to remain unchanged between renders unless explicitly
   * defined amongst changeable arguments.
   */
  const defaultColumns = useMemo(() => {
    return getDefaultColumns(...invocationArgs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDefaultColumns, changeableArgs]);

  /**
   * A list view's columns merged from the user's fetched list configuration
   * with the list view's default column setup. Should default columns contain
   * a column for which the user has no configuration, it is appended to the end
   * of the merged columns.
   */
  const columns: ListViewColumns = useMemo(() => {
    if (!fetchedColumns) {
      return Object.entries(defaultColumns).reduce((acc, [columnId, col]) => {
        if (col.showIf === false) {
          return acc;
        }

        acc[columnId] = {
          ...col,
          baseVisibility: col.baseVisibility ?? ColumnVisibility.SHOW,
          showIf: col.baseVisibility !== ColumnVisibility.HIDE
        };

        return acc;
      }, {} as ListViewColumns);
    }

    const cols = fetchedColumns.reduce((acc, col) => {
      const { id: columnId } = col;
      const defaultCol = defaultColumns[columnId];
      if (typeof defaultCol !== 'undefined' && defaultCol.showIf !== false) {
        const visibility = multiplex([
          ColumnVisibility.HIDE,
          [defaultCol.baseVisibility === ColumnVisibility.REQUIRE, ColumnVisibility.REQUIRE],
          [col.enabled, ColumnVisibility.SHOW]
        ]);

        acc[columnId] = {
          ...defaultCol,
          baseVisibility: visibility,
          showIf: visibility !== ColumnVisibility.HIDE
        };
      }

      return acc;
    }, {} as ListViewColumns);

    Object.entries(defaultColumns).forEach(([columnId, col]) => {
      if (col.showIf !== false && cols[columnId] === undefined) {
        cols[columnId] = {
          ...col,
          baseVisibility: col.baseVisibility ?? ColumnVisibility.SHOW,
          showIf: col.baseVisibility !== ColumnVisibility.HIDE
        } as EditableColumnConfig;
      }
    });

    return cols;
  }, [fetchedColumns, defaultColumns]);

  const status: EditableListState['status'] = multiplex([
    'n/a',
    [loading, 'loading'],
    [!!error, 'err'],
    [enabled, 'available']
  ]);

  return { listId, columns, status };
};
