import {
  deleteFeatureFlagMutation,
  featureFlagsQuery
} from 'hooks/featureFlags/featureFlagQueries';
import useCallBackendMutation from 'hooks/useCallBackend/useCallBackendMutation';

type DeleteFeatureFlagResponse = {
  deleteFeatureFlag: boolean;
};

const useDeleteFeatureFlag = () => {
  const { runMutation, data, loading, error } = useCallBackendMutation<DeleteFeatureFlagResponse>(
    deleteFeatureFlagMutation,
    [
      {
        query: featureFlagsQuery
      }
    ]
  );

  const deleteFeatureFlag = async (flagName: string) => {
    runMutation({
      variables: {
        name: flagName
      }
    });
  };

  return {
    deleteFeatureFlag,
    data,
    loading,
    error
  };
};

export default useDeleteFeatureFlag;
