import { getDefaultDateRange } from 'doings/datetime/getDefaultDateRange';
import { DateFormats } from 'doings/formatDatetime/DateFormats';
import formatDatetime from 'doings/formatDatetime/formatDatetime';
import useReadQueryParams from 'hooks/useQueryParams/useReadQueryParams';
import { useToggleStateWithQueryParameter } from 'hooks/useToggleState/useToggleState';

import { InteractionType } from './useActivityLog';

const verifyType = (type: string | undefined): type is InteractionType =>
  ['calls', 'chats'].includes(type ?? '');

export const useActivityLogFilters = (initialAllCompanies = false, isAdmin = false) => {
  const queryParameters = useReadQueryParams(['scope', 'search', 'from', 'to', 'type', 'allUsers']);
  const { scope, search, from, to, type, allUsers: allUsersFromParams } = queryParameters;
  const { defaultDateFromPlaceholder, defaultDateToPlaceholder } = getDefaultDateRange();

  const verifiedType = verifyType(type) ? type : undefined;

  const activityLogQueryParameters = {
    invoice: scope === 'invoice' ? search : undefined,
    subscription: scope === 'subscription' ? search : undefined,
    subject: scope === 'subject' ? search : undefined,
    createdDateFrom: formatDatetime(from, DateFormats.DATE),
    createdDateTo: formatDatetime(to, DateFormats.DATE),
    interactionType: verifiedType,
    allUsers: isAdmin && allUsersFromParams !== 'false' ? true : undefined
  };

  const [showFromAllCompanies, toggleAllCompanies] = useToggleStateWithQueryParameter(
    initialAllCompanies,
    'allCompanies'
  );

  const queryParametersAbsent =
    !(!!search || !!from || !!to || !!type) &&
    (isAdmin ? !!activityLogQueryParameters.allUsers : true);

  return {
    queryParameters: activityLogQueryParameters,
    queryParametersAbsent,
    defaultDateFromPlaceholder,
    defaultDateToPlaceholder,
    allCompanySearch: {
      showFromAllCompanies,
      toggleAllCompanies
    }
  };
};
