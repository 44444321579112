import getClassNames from 'classnames';
import { useSubmenuState } from 'contexts/SubmenuContext/SubmenuContext';
import { useTranslation } from 'react-i18next';

import styles from './SkipNavigationLink.module.scss';

/**
 * The skip link is an a11y practice of providing a shortcut for
 * keyboard and screen reader users to skip a web application's
 * navigation and go directly to the main content without manually
 * and repetitively going through all elements in the navigation,
 * which can take a while and annoy users.
 *
 * The link is only visible on keyboard focus, so sighted non-keyboard
 * users who rely neither on keyboard nor on screen readers will not
 * see it.
 *
 * While a standard a11y practice is to have the skip link at the
 * very top of the page, since the application shows important notices
 * as growl notifications before the top navigation, we will want the
 * user to respond or be aware of those notices, but still be able to
 * skip the bulk of the navigation.
 *
 * Allows the application to conform to WCAG success criterion 2.4.1
 * Bypass Blocks (Level A).
 */
export const SkipNavigationLink: React.FC = () => {
  const { t } = useTranslation();
  const { closeMenus } = useSubmenuState();
  return (
    <a
      href="#maincontent"
      className={getClassNames([styles.skipLink, styles.visibleToKeyboard])}
      onClick={closeMenus}
    >
      {t('aria.header.skipToContent')}
    </a>
  );
};
