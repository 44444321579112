import { IconButton } from 'common-components/IconButton/IconButton';
import { useTranslation } from 'react-i18next';
import { ProductNewsVote } from 'types/productNews';

import styles from './NewsDrawerRatingButton.module.scss';

const NewsDrawerRatingButton: React.FC<{
  active: boolean;
  type: ProductNewsVote;
  onClick: VoidFunction;
}> = ({ active, type, onClick }) => {
  const { t } = useTranslation();
  return (
    <IconButton
      data-testid={`news-drawer-rating-button-${type}`}
      aria-label={t(`newsDrawer.${type}`)}
      aria-pressed={active}
      className={[styles.ratingButton, { [styles.ratingButton_active]: active }]}
      name={type === 'upvote' ? 'like' : 'dislike'}
      size="md"
      onClick={onClick}
    />
  );
};

export default NewsDrawerRatingButton;
