import { devicesQuery } from 'hooks/devices/useDevices/devicesQuery';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { Device } from 'types/device';

export type UseDevicesResponse = {
  devices: {
    items: Device[];
    page: number;
    pages?: number;
  };
};

const getItems = (data?: UseDevicesResponse) => data?.devices?.items || [];

const useDevices = () => {
  const {
    data,
    error,
    loading,
    fetchMore: fetchMoreFromQuery
  } = useCallBackend<UseDevicesResponse>({
    query: devicesQuery,
    queryVariables: {
      page: 1
    }
  });

  const devices = getItems(data);
  const page = data?.devices?.page || 1;
  const pages = data?.devices?.pages || 1;

  return {
    devices: {
      items: devices,
      page,
      hasMoreResults: page < pages
    },
    error,
    loading,
    fetchMore: () =>
      fetchMoreFromQuery({
        variables: { page: page + 1 },
        updateQuery: (
          previousResult: UseDevicesResponse,
          { fetchMoreResult }: { fetchMoreResult?: UseDevicesResponse }
        ) => {
          const prevDevices = getItems(previousResult);
          const moreDevices = getItems(fetchMoreResult);
          const pageCountFromNewResponse = fetchMoreResult?.devices?.pages ?? 1;
          const newPageNumber = page + 1;

          return {
            devices: {
              ...previousResult.devices,
              items: [...prevDevices, ...moreDevices],
              page: newPageNumber,
              hasMoreResults: newPageNumber < pageCountFromNewResponse
            }
          };
        }
      })
  };
};

export default useDevices;
