import { BodyText, Div, Flex, Gutter } from '@gaiads/telia-react-component-library';
import { useTranslation } from 'react-i18next';

const ContractDates: React.FC<{
  billingBeginDate?: string;
  billingEndDate?: string;
}> = ({ billingBeginDate, billingEndDate }) => {
  const { t } = useTranslation();

  return (
    <Div data-testid="devices-contract-dates">
      {billingBeginDate && (
        <Flex>
          <BodyText fontWeight="bold" flexItem>
            {t('daas.details.contractStartDate')}
          </BodyText>

          <Gutter />

          <BodyText flexItem>{billingBeginDate}</BodyText>
        </Flex>
      )}

      {billingEndDate && (
        <Flex>
          <BodyText fontWeight="bold" flexItem>
            {t('daas.details.contractEndDate')}
          </BodyText>

          <Gutter />

          <BodyText flexItem>{billingEndDate}</BodyText>
        </Flex>
      )}
    </Div>
  );
};

export default ContractDates;
