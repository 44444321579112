import usePersistedSortableHeadings from 'hooks/useSortableHeadings/usePersistedSortableHeadings';
import useUserHasMultipleCompaniesWithGivenPermission from 'hooks/useUser/useUserHasMultipleCompaniesWithGivenPermission';
import { SortableColumns } from 'types/internetConnections';

import ThreeSixtyProductInventoryPage from './ThreeSixtyProductInventoryPage';
//import { SortOrder } from 'types/sort';

const ThreeSixtyProductInventory: React.FC = () => {
  const multipleCompanySearchEnabled = useUserHasMultipleCompaniesWithGivenPermission({
    anyPermission: ['ASSETS', 'ASSETS_VIEW']
  });

  const sortableColumns = Object.values(SortableColumns);
  const { sortState, sortClick, onSortChange, currentSort } = usePersistedSortableHeadings(
    sortableColumns,
    {
      //sort: SortableColumns.name,
      //order: SortOrder.ASC
    }
  );

  return (
    <ThreeSixtyProductInventoryPage
      multipleCompanySearchEnabled={multipleCompanySearchEnabled}
      sortState={sortState}
      sortClick={sortClick}
      onSortChange={onSortChange}
      currentSort={currentSort}
    />
  );
};

export default ThreeSixtyProductInventory;
