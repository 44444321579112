import { Gutter } from '@gaiads/telia-react-component-library';
import { Skeleton } from 'common-components';

const TransferSubscriptionDetailsSkeleton: React.FC = () => {
  return (
    <Skeleton.Panel>
      <Skeleton.TextRow />

      <Gutter size="xxs" />

      <Skeleton.TextRow />

      <Gutter size="xxs" />

      <Skeleton.TextRow />

      <Gutter size="xxs" />

      <Skeleton.TextRow />
    </Skeleton.Panel>
  );
};

export default TransferSubscriptionDetailsSkeleton;
