import { ApolloError } from '@apollo/client';
import isOneItemPaymentDevice from 'doings/isOneItemPaymentDevice/isOneItemPaymentDevice';
import { deviceQuery } from 'hooks/devices/useDevice/deviceQueries';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { get } from 'lodash/fp';
import { Device, DeviceResponse } from 'types/device';

const addInterpretedProperties = (device: DeviceResponse): Device => ({
  ...device,
  isOneItemPaymentDevice: isOneItemPaymentDevice(device)
});

const useDevice: (deviceId: string) => {
  device?: Device;
  error?: ApolloError;
  hasError: boolean;
  loading: boolean;
} = (deviceId: string) => {
  const { data, error, loading } = useCallBackend({
    query: deviceQuery,
    queryVariables: {
      deviceId
    }
  });

  const deviceData = get('getDevice', data);
  const device: Device | undefined = deviceData ? addInterpretedProperties(deviceData) : undefined;

  return { device, error, hasError: !!error, loading };
};

export default useDevice;
