import deaccent from 'doings/deaccent/deaccent';
import {
  ContentEntry,
  ContentEntryScore,
  ScoreType,
  ScoredContentEntry,
  SearchResultItem
} from 'types/contentSearch';

const EXACT_TITLE_MATCH_SCORE = 10;
const MAX_PARTIAL_MATCH_SCORE = 5;
const NORMALIZE_TERM_REGEX = /[\p{Punctuation}\p{Symbol}\s]/gu;

const MIN_SCORE_MATCH = 10;
const MIN_SCORE_PROXIMITY = 0;

export const mapFilteredResultItem = (item: ScoredContentEntry): SearchResultItem => ({
  title: item.title,
  url: item.target.url,
  pageType: `${item.target.targetPortal}-page`,
  scoreType: item.score.type
});

export const normalizeTerm = (term: string) => deaccent(term).replace(NORMALIZE_TERM_REGEX, '');

export const scoreContentEntry = ({
  entry,
  term
}: {
  entry: ContentEntry;
  term: string;
}): ScoredContentEntry => ({
  ...entry,
  score: evaluate(entry, term)
});

const evaluate = (item: ContentEntry, searchTerm: string): ContentEntryScore => {
  const title = normalizeTerm(item.title);
  const matchScore = evaluateMatch(item, title, searchTerm);
  if (matchScore) {
    return { type: matchScore.type, value: MIN_SCORE_MATCH + matchScore.value };
  }

  const proximityScore = evaluateProximity(item, title, searchTerm);
  if (proximityScore) {
    return { type: proximityScore.type, value: MIN_SCORE_PROXIMITY + proximityScore.value };
  }

  return { type: ScoreType.NO_MATCH, value: 0 };
};

/**
 * Evaluates a content entry for an exact or partial match.
 * Expects a normalised title and search term.
 *
 * @remarks
 * Scoring is as follows:
 * 1. An exactly matching title scores maximum points.
 * 2. A partially matching title scores less.
 * 3. A matching keyword scores points weighed according to its
 *    prominence: the first four keywords of an entry are given extra
 *    weight. This fact allows elevating keywords which users are
 *    likely to use when searching for a particular entry.
 */
const evaluateMatch = (
  item: ContentEntry,
  title: string,
  searchTerm: string
): ContentEntryScore | undefined => {
  if (title === searchTerm) {
    return { type: ScoreType.TITLE_EXACT, value: EXACT_TITLE_MATCH_SCORE };
  }

  if (title.includes(searchTerm)) {
    return { type: ScoreType.TITLE_PARTIAL, value: scorePartialMatch(item, 0) };
  }

  const keywordIndex = item.keywords.findIndex((keyword) => keyword.includes(searchTerm));
  if (keywordIndex > -1) {
    return { type: ScoreType.KEYWORD_MATCH, value: scorePartialMatch(item, keywordIndex) };
  }

  return undefined;
};

/**
 * Evaluates a content entry for a heuristics-based proximity match.
 * Expects a normalised title and search term.
 *
 * @remarks
 * Scoring is as follows:
 * 1. A fuzzily matching title scores more points the shorter the
 *    search term or the title. Note that these points are kept lower
 *    than match scores, giving typos less weight over more exact matches.
 * 2. A fuzzily matching keyword scores weighed points according to its
 *    prominence: the first four keywords of an entry are given extra
 *    weight. Note that these points are still lower than match scores.
 *
 * @privateRemarks
 * The score weights need to be low enough that exact and partial matches
 * for already low-scoring other Telia service links are given higher scores
 * on exact and partial matches than any proximity-based results. Close title
 * scores should avoid keyword weighs for the exact same reason.
 */
const evaluateProximity = (
  item: ContentEntry,
  title: string,
  searchTerm: string
): ContentEntryScore | undefined => {
  if (searchTerm.length >= FUZZY_SEARCH_TERM_LENGTH_THRESHOLD) {
    if (isClose(searchTerm, title)) {
      return { type: ScoreType.CLOSE_TITLE, value: scoreCloseTitle(title, searchTerm) };
    }

    const keywordIndex = item.keywords.findIndex((keyword) => isClose(searchTerm, keyword));
    if (keywordIndex > -1) {
      return { type: ScoreType.CLOSE_KEYWORD, value: scoreCloseKeyword(item, keywordIndex) };
    }
  }

  return undefined;
};

const FUZZY_SEARCH_TERM_LENGTH_THRESHOLD = 5;

const FUZZY_EVALUATION = {
  PROXIMITY_THRESHOLD: 10,
  PENALTY_TYPO: 8,
  PENALTY_DUPE: 2,
  PENALTY_DISTANCE: 3
};

const FUZZY_SCORING = {
  TITLE_BASE_LENGTH: 2,
  TITLE_MULT_MAX: 0.03,
  TITLE_MULT_MIN: 0.01,
  MULTIPLIER_KEYWORD: 0.05
};

const isClose = (searchTerm: string, target: string) => {
  let distance = 0;
  let prevIndex = -1;
  let prevChar = undefined;
  for (const char of searchTerm) {
    const index = target.indexOf(char, prevIndex + 1);
    if (index <= -1) {
      distance += prevChar === char ? FUZZY_EVALUATION.PENALTY_DUPE : FUZZY_EVALUATION.PENALTY_TYPO;
    } else if (prevIndex > -1 && index > 0 && target[index - 1] !== prevChar) {
      distance += index - prevIndex > 1 ? FUZZY_EVALUATION.PENALTY_DISTANCE : 0;
    }

    prevIndex = index;
    prevChar = char;
    if (distance > FUZZY_EVALUATION.PROXIMITY_THRESHOLD) {
      return false;
    }
  }

  return true;
};

const scorePartialMatch = (item: ContentEntry, index: number) => {
  const baseScore = Math.max(1, MAX_PARTIAL_MATCH_SCORE - index);
  if (item.keywordWeightRate) {
    return Math.min(EXACT_TITLE_MATCH_SCORE, baseScore * item.keywordWeightRate);
  }

  return baseScore;
};

const scoreCloseTitle = (title: string, searchTerm: string) => {
  const lengthWeight = FUZZY_SCORING.TITLE_BASE_LENGTH / (searchTerm.length * title.length);
  const finalWeight = Math.max(
    FUZZY_SCORING.TITLE_MULT_MIN,
    Math.min(FUZZY_SCORING.TITLE_MULT_MAX, lengthWeight)
  );
  return MAX_PARTIAL_MATCH_SCORE * finalWeight;
};

const scoreCloseKeyword = (item: ContentEntry, keywordIndex: number) => {
  return scorePartialMatch(item, keywordIndex) * FUZZY_SCORING.MULTIPLIER_KEYWORD;
};
