import { BreakpointSpecifier, useRemBreakpoint } from 'hooks/useBreakpoint/useRemBreakpoint';

/**
 * Applies both application zoom and root element font sizing (text-only zoom)
 * for rendering its content. Use in places where increasing the text size up
 * to 200% would otherwise render content unusable or obstructed. Breakpoints
 * match Gaia's breakpoints.
 *
 * Allows the application to conform to WCAG success criterion 1.4.4 Resize Text
 * (Level AA).
 *
 * @see `useRemBreakpoint`
 */
export const RemBreakpoint: React.FC<{ children: React.ReactNode } & BreakpointSpecifier> = ({
  narrowerThan,
  at,
  widerThan,
  children
}) => {
  const { isActive } = useRemBreakpoint({ narrowerThan, at, widerThan });
  if (!isActive) {
    return null;
  }

  return <>{children}</>;
};
