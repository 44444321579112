/**
 * Highlight animation duration multiplied by animation iteration
 * count from _highlight.scss.
 */
const HIGHLIGHT_TIMEOUT: Time.Milliseconds = 4500;
const POST_CSS_TRANSITION_DELAY: Time.Milliseconds = 10;

const SCROLL_OPTIONS: Record<'smooth' | 'snap', ScrollIntoViewOptions> = {
  smooth: {
    behavior: 'smooth',
    block: 'center',
    inline: 'center'
  },
  snap: {
    behavior: 'auto',
    block: 'nearest',
    inline: 'nearest'
  }
};

type ScrollVariant = keyof typeof SCROLL_OPTIONS;

export const scrollElementIntoView = <T extends HTMLElement>({
  elementRef,
  targetQuery = '',
  showHighlight = false,
  scrollVariant = 'smooth'
}: {
  elementRef: React.RefObject<T>;
  targetQuery?: string;
  showHighlight?: boolean;
  scrollVariant?: ScrollVariant;
}) => {
  const element = elementRef.current;
  const target = targetQuery ? element?.querySelector(targetQuery) : element;
  if (!target?.scrollIntoView) {
    return false;
  }

  setTimeout(() => {
    target.scrollIntoView(SCROLL_OPTIONS[scrollVariant]);
    if (showHighlight) {
      target.classList.add('highlight');
      const timeout = setTimeout(() => {
        target.dispatchEvent(new CustomEvent('removeHighlight'));
      }, HIGHLIGHT_TIMEOUT);

      const removeHighlight = () => {
        target.classList.remove('highlight');
        target.removeEventListener('removeHighlight', removeHighlight);
        target.removeEventListener('focus', removeHighlight);
        clearTimeout(timeout);
      };

      target.addEventListener('removeHighlight', removeHighlight);
      target.addEventListener('focus', removeHighlight);
    }
  }, POST_CSS_TRANSITION_DELAY);

  return true;
};

export const scrollElementIntoViewNextFrame = (
  args: Parameters<typeof scrollElementIntoView>[0]
) => {
  setTimeout(() => {
    scrollElementIntoView(args);
  }, 1);
};
