import { Gutter } from '@gaiads/telia-react-component-library';
import { ModalDialog, TeliaLink } from 'common-components';
import isDemoEnvironment from 'doings/isDemoEnvironment/isDemoEnvironment';
import { joinURLSearchParams } from 'doings/links/joinURLSearchParams/joinURLSearchParams';
import { Trans, useTranslation } from 'react-i18next';
import { BillingMethods } from 'types/billingGroups';

const BillingGroupMassChange: React.FC<{ isOpen: boolean; onClose: VoidFunction }> = ({
  isOpen,
  onClose
}) => {
  const { t } = useTranslation();
  const isDemoEnv = isDemoEnvironment();

  const getBillingTypeLabel = (type: string) =>
    t(`billingInformation.billingMethods.${type.toUpperCase()}`);

  const deliveryTypes = Object.values(BillingMethods)
    .map((type) => `${type.toUpperCase()}:${getBillingTypeLabel(type)}`)
    .join(',');

  const queryParams = joinURLSearchParams('?', {
    columns: [
      t('billingInformation.collectionGroup'),
      t('billingInformation.payerName'),
      t('billingInformation.newPayerName'),
      t('billingInformation.billingReferenceListView'),
      t('billingInformation.newBillingReferenceListView'),
      t('billingInformation.billingAddress'),
      t('billingInformation.newBillingAddress'),
      t('billingInformation.addressSupplement'),
      t('billingInformation.newAddressSupplement'),
      t('billingInformation.billingMethod'),
      t('billingInformation.newBillingMethod'),
      t('billingInformation.newBillingReceivingAddress'),
      t('billingInformation.newBillingReceivingBankorOperator')
    ].join(','),
    sheetTitle: t('invoices.billingGroup.massChange'),
    billingAccountDeliveryTypes: deliveryTypes
  });

  return (
    <ModalDialog
      isOpen={isOpen}
      title={t('invoices.billingGroup.list.massChangeModal.title')}
      onClose={onClose}
      closeButton={{
        label: t('common.closeButton.label'),
        onClick: onClose
      }}
    >
      <Trans
        i18nKey="invoices.billingGroup.list.massChangeModal.description"
        components={[
          <TeliaLink.Internal
            key="billing-accounts-mass-change-create-service-request-link--inline"
            variant="text"
            to="/service-requests/new/billing-info-mass-change"
            label={t('invoices.billingGroup.list.massChangeModal.descriptionServiceRequestLink')}
            data-testid="billing-accounts-mass-change-create-service-request-link--inline"
            enabledWhen={{ onePermission: 'SERVICE_REQUESTS' }}
          />
        ]}
      />

      <Gutter />

      <TeliaLink.Download
        label={t('invoices.billingGroup.list.massChangeModal.generateExcel')}
        to={`/api/billing-accounts/massChangeExcel${queryParams}`}
        iconLeft="download"
        data-testid="billing-accounts-mass-change-excel-link"
        disabled={isDemoEnv}
      />

      <TeliaLink.Internal
        key="billing-accounts-mass-change-create-service-request-link"
        variant="standalone"
        to="/service-requests/new/billing-info-mass-change"
        label={t('invoices.billingGroup.list.massChangeModal.createServiceRequest')}
        iconLeft="email"
        data-testid="billing-accounts-mass-change-create-service-request-link"
        enabledWhen={{ onePermission: 'SERVICE_REQUESTS' }}
      />
    </ModalDialog>
  );
};

export default BillingGroupMassChange;
