import { SectionDefinition } from 'B2XApp/Dashboard/DashboardNavigation/DashboardSections/sections/sectionDefinition';
import { DashboardSectionId } from 'B2XApp/Dashboard/sections';
import { LinkTarget, NavigationalLinks } from 'doings/getNavigationalLinks/getNavigationalLinks';

export const wholesaleServicesSection: SectionDefinition = {
  id: DashboardSectionId.WholesaleServices,
  icon: 'internet',
  titleTranslationKey: 'dashboard.sections.titles.WholesaleServices',
  getLinks(allLinks: NavigationalLinks) {
    return [
      allLinks[LinkTarget.WHOLESALE_SERVICES__ORDER_AND_DELIVERY_SYSTEM],
      allLinks[LinkTarget.WHOLESALE_SERVICES__EVENTS],
      allLinks[LinkTarget.WHOLESALE_SERVICES__REQUEST_FOR_QUOTE],
      allLinks[LinkTarget.WHOLESALE_SERVICES__ORDER],
      allLinks[LinkTarget.WHOLESALE_SERVICES__ORDER_FOR_CHANGE],
      allLinks[LinkTarget.WHOLESALE_SERVICES__ORDER_FOR_TERMINATION],
      allLinks[LinkTarget.WHOLESALE_SERVICES__YRITYSNETTI_ORDERS],
      allLinks[LinkTarget.WHOLESALE_SERVICES__PRODUCT_OFFERING]
    ];
  }
};
