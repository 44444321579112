import { useLocation } from 'react-router-dom';

const removeTrailingSlash = (path: string) => path.replace(/\/$/, '');

interface State {
  [key: string]: string | undefined;
}

interface SearchParamsWithState {
  state?: string;
  [key: string]: string | undefined;
}

export default <T = SearchParamsWithState>() => {
  const location = useLocation();
  const currentPathname = removeTrailingSlash(location.pathname);
  const searchParams = new URLSearchParams(location.search);
  const paramEntries = Array.from(searchParams.entries());
  const params = paramEntries.reduce<SearchParamsWithState | null>((acc, [key, value]) => {
    if (!acc) {
      acc = {};
    }

    acc[key] = value;
    return acc;
  }, null);

  const getState = () => {
    if (!params?.state) {
      return {};
    }

    try {
      return JSON.parse(window.atob(decodeURIComponent(params.state)));
    } catch (e) {
      return {};
    }
  };

  const getParamsWithoutState = () => {
    if (!params) {
      return {};
    }

    const { state, ...parameters } = params;
    return parameters;
  };

  const attachStateToPath = (path: string, state?: string) => {
    const stateParam = state ?? (params?.state as string);

    return `${path}?state=${encodeURIComponent(stateParam)}`;
  };

  const createPathWithState = (path?: string, value?: State) => {
    if (!path) {
      return path as string;
    }

    const parameters = value || getParamsWithoutState();

    if (!Object.keys(parameters).length) {
      return path;
    }

    const state = getState();
    const stateParam = window.btoa(JSON.stringify({ ...state, [currentPathname]: parameters }));
    return attachStateToPath(path, stateParam);
  };

  const preservePathState = (path?: string) => {
    if (!path || !params?.state) {
      return path as string;
    }

    return attachStateToPath(path);
  };

  const isStateEmpty = (state: State) => {
    if (!state || !Object.keys(state).length) {
      return true;
    }

    return false;
  };

  const preserveBackPathState = (path?: string, force?: boolean) => {
    if (!path) {
      return path as string;
    }

    const state = getState();
    const pathname = removeTrailingSlash(path);
    const pageState = state[pathname];

    if (isStateEmpty(state)) {
      return path;
    }

    if (!pageState && !force) {
      return path;
    }

    delete state[pathname];

    const queryParams = new URLSearchParams(pageState);

    if (!isStateEmpty(state)) {
      queryParams.append('state', window.btoa(JSON.stringify(state)));
    }

    return `${path}?${queryParams.toString()}`;
  };

  return {
    searchParams: params as T,
    createPathWithState,
    preservePathState,
    preserveBackPathState
  };
};
