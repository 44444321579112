import { DocumentNode } from 'graphql';

export enum ViewStatus {
  Initial = 'INITIAL',
  LoadingInitial = 'LOADING_INITIAL',
  Loading = 'LOADING',
  LoadingMore = 'LOADING_MORE',
  Loaded = 'LOADED',
  Error = 'ERROR',
  Empty = 'EMPTY'
}

export type StateInput = {
  loading: boolean;
  loadingMore: boolean;
  loaded: boolean;
  items: readonly unknown[];
  error: unknown;
  query: Record<string, unknown> | DocumentNode;
};

export default ({ loading, loadingMore, loaded, items, error, query }: StateInput) => {
  const hasItems = items && items.length > 0;
  const hasError = !!error;
  const hasQueryParameters = Object.values(query ?? {}).some((value) => value !== undefined);

  if (hasError) {
    return ViewStatus.Error;
  }
  if (!loaded && !loading) {
    return ViewStatus.Initial;
  }
  if (loadingMore) {
    return ViewStatus.LoadingMore;
  }
  if (loading) {
    return ViewStatus.Loading;
  }
  if (hasItems || hasQueryParameters) {
    return ViewStatus.Loaded;
  }

  return ViewStatus.Empty;
};
