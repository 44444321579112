import { BodyText, Gutter, Panel } from '@gaiads/telia-react-component-library';
import { ModalDialog, Notices, TileButton, TileButtonGrid } from 'common-components';
import { useUserData } from 'contexts/UserContext/UserContext';
import createCorporatePortalLink from 'doings/links/createCorporatePortalLink/createCorporatePortalLink';
import { mobile } from 'doings/track/analyticsEvents';
import { SubscriptionDetails } from 'hooks/mobileSubscriptions/useSubscriptionDetails/subscriptionDetailsQuery';
import useCustomModal from 'hooks/useCustomModal/useCustomModal';
import { useRouting } from 'hooks/useRouting/useRouting';
import useSearchParams from 'hooks/useSearchParams/useSearchParams';
import { Trans, useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { OngoingOwnershipChangeNotice } from '../SubscriptionDetails/SubscriptionNotices/OwnershipChangeNotice/OngoingOwnershipChangeNotice';
import { OngoingTerminatedOwnershipChangeNotice } from '../SubscriptionDetails/SubscriptionNotices/OwnershipChangeNotice/OngoingTerminatedOwnershipChangeNotice';

const COLS_IN_DESKTOP = 3;
const COLS_IN_MOBILE = 2;

const TransferSubscriptionOptions: React.FC<{
  subscription: SubscriptionDetails;
}> = ({ subscription }) => {
  const { t } = useTranslation();
  const { hasMultipleCompanies } = useUserData();

  const {
    phoneNumberHash,
    omsuBlockingServices,
    omsuToken,
    status: subscriptionStatus
  } = subscription;
  const isPreopened = subscriptionStatus === 'PREOPENED';
  const isDisabled = subscriptionStatus === 'DISABLED';
  const isSuspended = subscriptionStatus === 'SUSPENDED';
  const hasOmsuToken = !!omsuToken && ['ACTIVE', 'IN_REVIEW'].includes(omsuToken.tokenStatus);
  const transferOptionDisabled = isPreopened || isDisabled || isSuspended || hasOmsuToken;
  const { url } = useRouteMatch();
  const { preservePathState } = useSearchParams();
  const { goto } = useRouting();
  const transferToIndividualModal = useCustomModal('TRANSFER_TO_INDIVIDUAL_MODAL');

  const createTransferOptions = (target: string) =>
    omsuBlockingServices
      ? { onClick: transferToIndividualModal.openModal }
      : { to: preservePathState(target) };

  return (
    <>
      <Panel data-testid="transfer-link-panel">
        <Panel.Content>
          {hasOmsuToken && (
            <Notices data-testid="mobile-transfer-notices">
              {omsuToken.terminationDate ? (
                <OngoingTerminatedOwnershipChangeNotice
                  terminationDate={omsuToken.terminationDate}
                />
              ) : (
                <OngoingOwnershipChangeNotice />
              )}
            </Notices>
          )}

          <BodyText>{t('subscriptions.actions.transfer.text')}</BodyText>

          <Gutter size="sm" />

          <TileButtonGrid
            columns={{
              desktop: hasMultipleCompanies ? COLS_IN_DESKTOP : COLS_IN_MOBILE,
              mobile: COLS_IN_MOBILE
            }}
          >
            <TileButton
              data-testid="transfer-subscription-to-individual"
              iconName="end-user"
              label={t('subscriptions.actions.transfer.privateWithToken')}
              {...createTransferOptions(`${url}/private`)}
              whenClicked={mobile.openGenerateOwnershipChangeToken}
              disabled={transferOptionDisabled}
              enabledWhen={{
                onePermission: 'B2B_PORTAL',
                anyPermission: ['MOBILE_SUBSCRIPTIONS']
              }}
            />

            <TileButton
              data-testid="transfer-subscription-to-another-company"
              iconName="premises"
              label={t('subscriptions.actions.transfer.anotherCompany')}
              {...createTransferOptions(`${url}/company`)}
              whenClicked={mobile.transferOwnershipToOther}
              disabled={transferOptionDisabled}
              enabledWhen={{
                onePermission: 'B2B_PORTAL',
                anyPermission: ['MOBILE_SUBSCRIPTIONS']
              }}
            />

            {hasMultipleCompanies && (
              <TileButton
                data-testid="transfer-subscription-change-customer"
                iconName="org-chart"
                label={t('subscriptions.actions.transfer.internal')}
                to={createCorporatePortalLink('mobileChangeSubscriptionCustomer', {
                  msisdn: phoneNumberHash
                })}
                whenClicked={mobile.transferOwnershipInternal}
                disabled={transferOptionDisabled}
                enabledWhen={{
                  onePermission: 'B2B_PORTAL',
                  anyPermission: ['MOBILE_SUBSCRIPTIONS']
                }}
              />
            )}
          </TileButtonGrid>
        </Panel.Content>
      </Panel>

      <ModalDialog
        isOpen={transferToIndividualModal.isOpen}
        title={t('subscriptions.actions.transfer.alternateFlowModal.title')}
        onClose={transferToIndividualModal.closeModal}
        closeButton={{
          label: t('common.cancelButton.label'),
          onClick: transferToIndividualModal.closeModal
        }}
        acceptButton={{
          label: t('common.continueButton.label'),
          onClick: () =>
            goto({
              toCorporatePortal: 'mobileChangeOwnership',
              toCorporatePortalArgs: {
                msisdn: phoneNumberHash
              },
              to: '',
              useB2XLink: false
            })
        }}
      >
        <Trans
          i18nKey="subscriptions.actions.transfer.alternateFlowModal.description"
          components={[<BodyText key="transfer-subscription--alternate-flow--description" />]}
        />
      </ModalDialog>
    </>
  );
};

export default TransferSubscriptionOptions;
