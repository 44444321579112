import { Dropdown } from '@gaiads/telia-react-component-library';
import { OnSortChange } from 'hooks/useSortableHeadings/useSortableHeadings';
import { useTranslation } from 'react-i18next';
import { SortOrder, SortParameter } from 'types/sort';

type SortDropdownOptions = { label: string; value: string };

const MessageArchiveListSortDropdown: React.FC<{
  currentSort: SortParameter;
  onChange: OnSortChange;
}> = ({ currentSort, onChange }) => {
  const { t } = useTranslation();
  const currentValue = `${currentSort.sort},${currentSort.order}`;
  const options = [
    {
      label: t('messageArchive.list.sort.reportedDateAsc'),
      value: 'reportedDate,asc'
    },
    {
      label: t('messageArchive.list.sort.reportedDateDesc'),
      value: 'reportedDate,desc'
    },
    {
      label: t('messageArchive.list.sort.updatedDateAsc'),
      value: 'updatedDate,asc'
    },
    {
      label: t('messageArchive.list.sort.updatedDateDesc'),
      value: 'updatedDate,desc'
    },
    {
      label: t('messageArchive.list.sort.idAsc'),
      value: 'multibellaGui,asc'
    },
    {
      label: t('messageArchive.list.sort.idDesc'),
      value: 'multibellaGui,desc'
    }
  ] as SortDropdownOptions[];

  return (
    <Dropdown
      label={t('messageArchive.list.sort.order')}
      aria-label={t('messageArchive.list.sort.order')}
      options={options}
      onChange={(_event, { value }) => {
        const opts = value.split(',');
        onChange({
          field: opts[0],
          direction: opts[1] === 'asc' ? SortOrder.ASC : SortOrder.DESC
        });
      }}
      selectedOption={options.find(({ value }) => value === currentValue)}
    />
  );
};

export default MessageArchiveListSortDropdown;
