import { InlineNotification, TeliaLink } from 'common-components';
import {
  ContentText,
  InlineNotificationType,
  TitleText
} from 'common-components/InlineNotification/InlineNotification';
import Optional from 'common-components/Optional/Optional';
import { AnalyticsEvent } from 'doings/track/analyticsEvents';
import trackEvent from 'doings/track/trackEvent';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showInfoModal } from 'redux/slices/infoModalSlice';

export const MAX_CONTENT_LENGTH = 200;

/**
 * Wrapper around `InlineNotification` React component for rendering
 * inline notifications with extended functionality to track analytics and
 * expand the notification through `Read more` button which opens up a modal.
 */
const ExpandableInlineNotification: React.FC<{
  variant: InlineNotificationType;
  title?: TitleText;
  content: ContentText;
  readMoreLink?: string;
  whenClicked: AnalyticsEvent;
  onClose?: VoidFunction;
  'aria-label'?: string;
  'data-testid'?: string;
}> = ({
  variant,
  title,
  content,
  readMoreLink,
  whenClicked,
  onClose,
  'data-testid': testId,
  'aria-label': htmlAriaLabel
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onClickReadMore = () => {
    if (whenClicked) {
      trackEvent(whenClicked);
    }

    window.open(readMoreLink, '_blank');
  };

  const onClickShowMore = () => {
    dispatch(
      showInfoModal({
        title: title as string,
        text: content as string,
        link: readMoreLink,
        whenClicked
      })
    );
  };

  const renderTitle = (): TitleText => {
    return title as TitleText;
  };

  const renderReadMore = (isDirectLink?: boolean) =>
    isDirectLink ? (
      <TeliaLink.Click
        variant="text"
        label={t('notifications.readMore')}
        iconRight="external"
        onClick={onClickReadMore}
        track={whenClicked}
        data-testid={`${testId}--read-more`}
      />
    ) : (
      <TeliaLink.Click
        variant="text"
        label={t('common.showMore')}
        onClick={onClickShowMore}
        data-testid={`${testId}--show-more`}
      />
    );

  const renderContent = (): ContentText => {
    if (typeof content === 'string') {
      const hasReadMoreLink = !!readMoreLink;
      const hasLongContent = content.length > MAX_CONTENT_LENGTH;
      const showReadMore = hasLongContent || hasReadMoreLink;
      const isReadMoreDirectLink = !hasLongContent && hasReadMoreLink;

      return truncateText(content, MAX_CONTENT_LENGTH, showReadMore, () =>
        renderReadMore(isReadMoreDirectLink)
      );
    }

    return content;
  };

  return (
    <InlineNotification
      variant={variant}
      title={renderTitle()}
      content={renderContent()}
      onClose={onClose}
      aria-label={htmlAriaLabel}
      data-testid={testId}
    />
  );
};

export const truncateText = (
  text: string,
  maxLength: number,
  showReadMore?: boolean,
  renderReadMore?: () => React.ReactNode
): ContentText => {
  if (!showReadMore || !renderReadMore) {
    return text;
  }

  if (showReadMore) {
    return {
      jsx: (
        <>
          <Optional if={text.length > maxLength}>
            {text.slice(0, maxLength - 1)}&hellip;
            <br />
            {renderReadMore()}
          </Optional>

          <Optional if={text.length <= maxLength}>
            {text}
            <br />
            {renderReadMore()}
          </Optional>
        </>
      )
    };
  }

  return text;
};

export default ExpandableInlineNotification;
