/**
 * For most writers, the hyphen’s primary function is the formation of
 * certain compound terms. The hyphen is also used for word division.
 *
 * Use for hyphenation and word division only.
 */
export const HYPHEN = '-';

/**
 * An en dash is a midsize dash (longer than a hyphen but shorter than
 * an em dash) that is mostly used to show ranges in numbers and dates.
 * It can also be used for clarity in forming complex compound adjectives.
 * The en dash derives its name from the fact that it is meant to be the
 * same width as the letter N.
 *
 * Use for displaying an absent or empty value.
 */
export const EN_DASH = '–';

/**
 * The em dash is perhaps the most versatile punctuation mark. Depending
 * on the context, the em dash can take the place of commas, parentheses,
 * or colons—in each case to slightly different effect. The em dash
 * derives its name from the fact that it is meant to be the same width
 * as the letter M.
 */
export const EM_DASH = '—';
