import { DateFormats } from 'doings/formatDatetime';
import toDate from 'doings/formatDatetime/toDate';
import { sortCaseSensitive } from 'doings/sort/sort';
import { useOfflineLimit } from 'hooks/useOfflineLimit/useOfflineLimit';
import useReadQueryParams from 'hooks/useQueryParams/useReadQueryParams';
import usePersistedSortableHeadings from 'hooks/useSortableHeadings/usePersistedSortableHeadings';
import { useMemo } from 'react';
import { ProductNewsFeedback } from 'types/productNews';
import { SortOrder } from 'types/sort';

import { useProductNewsStatsFeedback } from './useProductNewsStatsFeedback';

const useProductNewsStatsFeedbackFilters = ({
  data,
  loading
}: ReturnType<typeof useProductNewsStatsFeedback>) => {
  const sortableColumns: (keyof ProductNewsFeedback)[] = ['updatedAt', 'userId', 'vote'];
  const { sortState, sortClick, doSort, currentSort } = usePersistedSortableHeadings(
    sortableColumns,
    { sort: 'updatedAt', order: SortOrder.DESC }
  );

  const queryParams = useReadQueryParams(['search', 'vote', 'from', 'to']);
  const filteredFeedback = useMemo(() => filter(data, queryParams), [data, queryParams]);

  const sortedFeedback = sortCaseSensitive(filteredFeedback, currentSort);
  const { limit, showMore } = useOfflineLimit([loading, sortedFeedback.length, sortState]);
  const limitedFeedback = sortedFeedback.slice(0, limit);

  return {
    feedbackCount: sortedFeedback.length,
    feedback: limitedFeedback,
    sortState,
    sortClick,
    doSort,
    currentSort,
    showMore
  };
};

const filter = (
  data: ProductNewsFeedback[],
  queryParameters: {
    search?: string;
    rating?: 'absent' | 'downvote' | 'upvote';
    from?: string;
    to?: string;
  }
) => {
  if (!data) {
    return [];
  }

  const filters = [ratingFilter, lastModifiedFilter, searchFilter]
    .map((applyFilter) => applyFilter(queryParameters))
    .filter((f) => f !== null);

  if (filters.length) {
    return data.reduce((acc, feedback) => {
      if (filters.every((filter) => filter(feedback))) {
        acc.push(feedback);
      }
      return acc;
    }, [] as ProductNewsFeedback[]);
  }

  return data;
};

const searchFilter = ({ search }: { search?: string }) => {
  if (!search) {
    return null;
  }

  const term = search.toLowerCase().trim();
  return ({ userId, feedback }: ProductNewsFeedback) =>
    String(userId) === term || feedback?.toLowerCase().includes(term);
};

const ratingFilter = ({ vote }: { vote?: 'absent' | 'downvote' | 'upvote' }) => {
  if (!vote) {
    return null;
  }

  if (vote === 'absent') {
    return ({ vote: feedbackVote }: ProductNewsFeedback) => !feedbackVote;
  }

  if (vote === 'downvote' || vote === 'upvote') {
    return ({ vote: feedbackVote }: ProductNewsFeedback) => feedbackVote === vote;
  }

  return null;
};

const lastModifiedFilter = ({ from, to }: { from?: string; to?: string }) => {
  if (!from || !to) {
    return null;
  }

  const fromDate = toDate(DateFormats.DATE_NATIONAL)(from);
  const tillDate = toDate(DateFormats.DATE_NATIONAL)(to);
  if (!fromDate || !tillDate) {
    return null;
  }

  return ({ updatedAt }: ProductNewsFeedback) => {
    const updated = new Date(Number(updatedAt));
    const truncated = new Date(updated.getFullYear(), updated.getMonth(), updated.getDate());
    return isNaN(truncated.getTime()) ? false : truncated >= fromDate && truncated <= tillDate;
  };
};

export { useProductNewsStatsFeedbackFilters };
