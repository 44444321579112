/**
 * This code has been forked from https://github.com/TeliaSoneraFinland/telia-component-library
 * And it has been modified: removed onMouseDown event.
 *
 * Reason: onMouseDown event used to disallow user selection.
 * Related ticket: https://jira.atlassian.teliacompany.net/browse/EAT-11207
 *
 * Further modifications include:
 * * Support for icon in block title
 * * Support for overriding block title's padding
 * * Support for tracking GA event
 * * Remove background colour support
 * * HTML button usage for improved a11y
 * * Custom block title when expanded
 */
import {
  CollapsibleBlock as CollapsibleBlockDS,
  Div,
  Flex,
  Icon,
  SupportedIconName
} from '@gaiads/telia-react-component-library';
import { PaddingProps } from '@gaiads/telia-react-component-library/build/types/components/Element/getPaddingClasses';
import getClassNames, { Argument } from 'classnames';
import { HeadingH4, HeadingH5 } from 'common-components/Heading/Heading';
import { AnalyticsEvent } from 'doings/track/analyticsEvents';
import trackEvent from 'doings/track/trackEvent';
import { useEffect, useReducer } from 'react';

import styles from './CollapsibleBlock.module.scss';

const style = {
  collapsibleBlock: 'telia-react-collapsibleBlock',
  collapsibleBlockList: 'telia-react-collapsibleBlockList',
  collapsibleBlock_header: 'telia-react-collapsibleBlock_header',
  collapsibleBlock_icon: 'telia-react-collapsibleBlock_icon',
  collapsibleBlock_label: 'telia-react-collapsibleBlock_label'
};

const CollapsibleBlock: React.FC<{
  blockIcon?: SupportedIconName;
  blockTitle: string;
  blockTitleExpanded?: string;
  largeTitle?: boolean;
  expanded?: boolean;
  children: NonNullable<React.ReactNode>;
  className?: Argument;
  padding?: PaddingProps;
  onClick?: VoidFunction;
  track?: AnalyticsEvent;
  chevronIconOverride?: SupportedIconName;
  tourStopDataAttributeForPanel?: string;
  tourStopDataAttributeForIcon?: string;
  'data-testid'?: string;
}> & {
  Content: typeof CollapsibleBlockDS.Content;
} = ({
  blockIcon,
  blockTitle,
  blockTitleExpanded = blockTitle,
  largeTitle = false,
  children,
  className,
  expanded: initiallyExpanded,
  padding = { size: 'xs' },
  onClick,
  track,
  chevronIconOverride,
  tourStopDataAttributeForPanel,
  tourStopDataAttributeForIcon,
  'data-testid': testId
}) => {
  const [{ expanded }, dispatch] = useReducer(
    (
      state: { expanded: boolean; expandedByUser: boolean },
      action: 'AUTO_SHOW' | 'AUTO_HIDE' | 'USER_TOGGLE'
    ) => {
      switch (action) {
        case 'AUTO_SHOW':
          return { ...state, expanded: true };
        case 'AUTO_HIDE':
          return { ...state, expanded: state.expandedByUser };
        case 'USER_TOGGLE':
          return { ...state, expanded: !state.expanded, expandedByUser: !state.expanded };
      }
    },
    { expanded: false, expandedByUser: false }
  );

  useEffect(() => {
    dispatch(initiallyExpanded ? 'AUTO_SHOW' : 'AUTO_HIDE');
  }, [initiallyExpanded]);

  const toggleExpanded = () => {
    if (track && !expanded) {
      trackEvent(track);
    }
    dispatch('USER_TOGGLE');
  };

  const BlockTitle = largeTitle ? HeadingH4 : HeadingH5;
  const label = expanded ? blockTitleExpanded : blockTitle;

  return (
    <Div
      data-tour-stop={tourStopDataAttributeForPanel}
      backgroundColor="white"
      className={[className, style.collapsibleBlock]}
      data-testid={testId ?? `collapsible-block`}
    >
      <button
        type="button"
        aria-label={label}
        className={getClassNames([style.collapsibleBlock_header, styles.blockHeader])}
        {...(onClick
          ? { onClick: onClick }
          : { onClick: toggleExpanded, 'aria-expanded': expanded })}
        data-testid="collapsible-block-button"
      >
        <Flex spaceBetween occupyHorizontalSpace centeredVertically padding={padding}>
          <Flex centeredVertically>
            {blockIcon && (
              <Icon className={styles.blockWithIcon_icon} name={blockIcon} size="md" block />
            )}

            <BlockTitle>{label}</BlockTitle>
          </Flex>

          <Icon
            data-tour-stop={tourStopDataAttributeForIcon}
            className={getClassNames(styles.blockTitleChevron, {
              [styles.blockTitleChevron__rotate]: !chevronIconOverride && expanded
            })}
            name={chevronIconOverride ?? 'chevron-down'}
            size="md"
          />
        </Flex>
      </button>

      {expanded && (
        <Div
          className={{ [styles.blockWithIcon_expanded]: !!blockIcon }}
          data-testid="collapsible-block-content"
        >
          {children}
        </Div>
      )}
    </Div>
  );
};

CollapsibleBlock.Content = CollapsibleBlockDS.Content;
Object.assign(CollapsibleBlock, CollapsibleBlockDS);

export default CollapsibleBlock;
