import useAsset from 'hooks/assets/useAsset/useAsset';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import CreateTicket from './CreateTicket';
import { CreateTicketPageSkeleton } from './CreateTicketPageSkeleton';

const CreateAssetTicket: React.FC = () => {
  const { t } = useTranslation();
  const { assetId } = useParams<{ assetId: string }>();
  const { asset, loading } = useAsset(assetId);

  if (loading) {
    return <CreateTicketPageSkeleton data-testid="create-ticket-page-skeleton" />;
  }

  const companyId = asset?.companyCode;
  const subscriptionId = asset?.displayId;
  const additionalInfoRows = asset
    ? [
        asset.name,
        asset.displayId || '',
        asset.address?.streetAddress || '',
        asset.address?.postalAddress || ''
      ].filter(Boolean)
    : [];

  return (
    <CreateTicket
      data-testid="create-ticket-page"
      caseOrigin={{ type: 'asset', companyId, subscriptionId }}
      additionalInfo={{ title: t('tickets.newTicket.relatedService'), rows: additionalInfoRows }}
      backToLabel={t('tickets.newTicket.backToSubscription')}
      backToPath={`/internet-connections/${assetId}`}
    />
  );
};

export default CreateAssetTicket;
