import { Gutter } from '@gaiads/telia-react-component-library';
import { TeliaLink } from 'common-components';
import { Flex } from 'core-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setIsModalOpen, setLanguage } from 'redux/slices/confirmationModalSlice';
import { RootState } from 'redux/store';

export type LanguageSelectorProps = {
  selectedLanguage: Language;
  selectLanguage: (language: Language) => void;
};

const languages: Language[] = ['fi', 'en', 'sv'];

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  selectedLanguage,
  selectLanguage
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const showConfirmationModal = useSelector(
    (state: RootState) => state.confirmationModal.showConfirmationModal
  );

  const onClick = (language: Language) => {
    if (showConfirmationModal) {
      dispatch(setIsModalOpen(true));
      dispatch(setLanguage(language));
    } else {
      selectLanguage(language);
    }
  };

  return (
    <Flex centeredVertically data-testid="language-selector">
      {languages.map((language, index) => (
        <Flex key={`language--${language}`}>
          <TeliaLink.Click
            data-testid={`language-change-button-${language}`}
            aria-label={t(`aria.header.changeLanguage.${language}`)}
            variant={
              selectedLanguage === language ? 'standalone-topbar-active' : 'standalone-topbar'
            }
            onClick={() => onClick(language)}
            label={language.toUpperCase()}
          />

          {languages.length !== index + 1 && <Gutter size="sm" />}
        </Flex>
      ))}
    </Flex>
  );
};

export default LanguageSelector;
