import { useUserData } from 'contexts/UserContext/UserContext';
import { useEffect } from 'react';

const TEN_MINUTES_IN_MILLISECONDS = 600000;

const SessionRefresh: React.FC = () => {
  const { sessionRefreshURL } = useUserData();

  useEffect(() => {
    setInterval(async () => {
      await fetch(sessionRefreshURL, {
        mode: 'no-cors',
        credentials: 'include'
      });
    }, TEN_MINUTES_IN_MILLISECONDS);
  }, [sessionRefreshURL]);

  return null;
};

export default SessionRefresh;
