import useSearchParams from 'hooks/useSearchParams/useSearchParams';
import { useTranslation } from 'react-i18next';

export enum StaticFormPrefillType {
  OtherProducts = 'other_products'
}

const useStaticSubjectPrefill = () => {
  const { searchParams } = useSearchParams<{ p: string }>();
  const { t } = useTranslation();

  return searchParams?.p === StaticFormPrefillType.OtherProducts
    ? t('modal.billingAccountType.links.otherProductsAndServices.title')
    : null;
};

export { useStaticSubjectPrefill };
