const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

export const getMonthString = (date: Date) => {
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const MONTHS_IN_YEAR = 12;

  const calculatedMonthDifference = currentMonth - month + MONTHS_IN_YEAR * (currentYear - year);

  if (calculatedMonthDifference === 0) {
    return 'thisMonth';
  }

  return calculatedMonthDifference === 1 ? 'lastMonth' : months[month - 1];
};
