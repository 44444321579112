import { multiplex } from 'doings/multiplex/multiplex';
import { BicCode } from 'types/collection-group';

const VALID_IBAN_LENGTH = 18;
const IBAN_BANK_CODE_START_INDEX = 4;
const IBAN_BANK_CODE_END_INDEX = 7;

export default (ibanOrOvtCode: string): BicCode | null => {
  const iban = ibanOrOvtCode.toUpperCase();
  if (iban.length !== VALID_IBAN_LENGTH || !iban.startsWith('FI')) {
    return null;
  }

  const POP_BANK_CODE_PATTERN = /^47[0-8]/;
  const SAASTO_BANK_CODE_PATTERN =
    /^(40[236-8]|41[0-24-9]|42[013-9]|43[0-25-9]|44\d|45[0-24-9]|46[0-4]|48[3-9]|49[0-356]|715)/;

  const bankCode = iban.substring(IBAN_BANK_CODE_START_INDEX, IBAN_BANK_CODE_END_INDEX);

  return multiplex([
    null,
    [bankCode === '405' || bankCode === '497', 'HELSFIHH'],
    [bankCode.startsWith('1') || bankCode.startsWith('2'), 'NDEAFIHH'],
    [bankCode.startsWith('5'), 'OKOYFIHH'],
    [bankCode.startsWith('6'), 'AABAFI22'],
    [bankCode.startsWith('8') || bankCode.startsWith('34'), 'DABAFIHH'],
    [bankCode.startsWith('31'), 'HANDFIHH'],
    [bankCode.startsWith('36') || bankCode.startsWith('39'), 'SBANFIHH'],
    [SAASTO_BANK_CODE_PATTERN.test(bankCode), 'ITELFIHH'],
    [POP_BANK_CODE_PATTERN.test(bankCode), 'POPFFI22']
  ]);
};
