import { BodyText, Gutter } from '@gaiads/telia-react-component-library';
import EditSubscriptionModal from 'B2XApp/Subscriptions/EditSubscription/EditSubscriptionModal';
import SubscriptionPackageInfo from 'B2XApp/Subscriptions/SubscriptionDetails/SubscriptionAsset/SubscriptionPackageInfo/SubscriptionPackageInfo';
import {
  ActionButtonGroup,
  Analytics,
  Button,
  DataPanel,
  Heading,
  ModalDialog
} from 'common-components';
import { EN_DASH } from 'doings/dash/dash';
import { createCorporatePortalLinkWithFragment } from 'doings/links/createCorporatePortalLink/createCorporatePortalLink';
import { mobile } from 'doings/track/analyticsEvents';
import {
  SubscriptionDetails,
  isDataPoolMember,
  isMultiSimMember
} from 'hooks/mobileSubscriptions/useSubscriptionDetails/subscriptionDetailsQuery';
import useCustomModal from 'hooks/useCustomModal/useCustomModal';
import { useTranslation } from 'react-i18next';

import MainSubscriptionReference from './MainSubscriptionReference';
import placeholder from './subscription-placeholder.svg';
import SubscriptionAssetInfo from './SubscriptionAssetInfo/SubscriptionAssetInfo';
import SubscriptionAssetSkeleton from './SubscriptionAssetSkeleton/SubscriptionAssetSkeleton';
import SubscriptionUserModal, {
  SUBSCRIPTION_USER_MODAL
} from './SubscriptionUserModal/SubscriptionUserModal';

const SUBSCRIPTION_EDIT = 'SUBSCRIPTION_EDIT';

const SubscriptionAsset: React.FC<{
  subscription?: SubscriptionDetails;
  loading?: boolean;
  disabled?: boolean;
}> = ({ subscription, loading, disabled = false }) => {
  const { t } = useTranslation();
  const userInfoModal = useCustomModal(SUBSCRIPTION_USER_MODAL);
  const editModal = useCustomModal(SUBSCRIPTION_EDIT);

  const isPreopened = subscription?.status === 'PREOPENED';
  const isDisabled = subscription?.status === 'DISABLED';
  const title = subscription ? [subscription.userName, subscription.phoneNumber] : undefined;
  const isSurfCard = subscription ? isDataPoolMember(subscription) : false;

  const phoneNumberHash = subscription?.phoneNumberHash ?? '';
  const otherProductsLink = createCorporatePortalLinkWithFragment('mobileDetails', 'user-details', {
    msisdn: phoneNumberHash
  });

  return (
    <>
      <DataPanel
        skeletonLoader={<SubscriptionAssetSkeleton />}
        loading={loading}
        title={title}
        imageSrc={placeholder}
        imageAlt={t('subscriptions.subscriptionDetails.subscriptionImagePlaceholder')}
        mainPanel
        data-testid="subscription-asset-data-panel"
      >
        {!!subscription && isDataPoolMember(subscription) && (
          <BodyText block margin={{ bottom: 'md' }} tagName="div">
            <MainSubscriptionReference
              data-testid="main-subscription-reference-data-pool"
              subscription={subscription}
              referenceType="POOL_PARENT"
              messageOnUnresolvedReference={t(
                'subscriptions.subscriptionDetails.couldNotLoadMainSubscription'
              )}
            />
          </BodyText>
        )}

        {!!subscription && isMultiSimMember(subscription) && (
          <BodyText block margin={{ bottom: 'md' }} tagName="div">
            <MainSubscriptionReference
              data-testid="main-subscription-reference-multi-sim"
              subscription={subscription}
              referenceType="MULTISIM_PARENT"
              messageOnUnresolvedReference={t(
                'subscriptions.subscriptionDetails.couldNotLoadMainSubscription'
              )}
            />
          </BodyText>
        )}

        <BodyText block margin={{ bottom: 'xs' }} tagName="div">
          {t('subscriptions.type')}
        </BodyText>

        <Heading.H4>{subscription?.type || EN_DASH}</Heading.H4>

        {!!subscription && (
          <>
            <Gutter />

            <SubscriptionPackageInfo
              packages={subscription.packages}
              hasDuettoBundle={subscription.hasDuettoBundle}
            />

            <Gutter size="xs" />

            <SubscriptionAssetInfo
              activeFrom={subscription?.activeFrom}
              owner={subscription?.owner}
              userName={subscription?.userName}
              additionalName={subscription?.user?.additionalInfo}
              isSurfCard={isSurfCard}
              loading={loading}
              openUserModal={userInfoModal.openModal}
              lastTimeInService={subscription.lastTimeInService}
            />
          </>
        )}

        <Gutter />

        {!disabled && (
          <ActionButtonGroup>
            <Analytics whenClicked={mobile.openEditSubscriptionModal}>
              <Button
                variant="primary"
                type="button"
                onClick={editModal.openModal}
                disabled={isPreopened || isDisabled}
                data-testid="edit-subscription-button"
              >
                {t('subscriptions.subscriptionDetails.editSubscription')}
              </Button>
            </Analytics>
          </ActionButtonGroup>
        )}
      </DataPanel>

      {subscription && (
        <SubscriptionUserModal
          isOpen={userInfoModal.isOpen}
          onCloseClick={userInfoModal.closeModal}
          subscription={subscription}
          isSurfCard={isSurfCard}
          editUserInformationUrl={otherProductsLink}
          disableEdit={disabled}
          data-testid="subscription-user-modal"
        />
      )}

      {subscription && (
        <ModalDialog
          title={t('subscriptions.actions.title')}
          isOpen={editModal.isOpen}
          onClose={editModal.closeModal}
          data-testid="edit-subscription-modal-dialog"
        >
          <EditSubscriptionModal
            phoneNumberHash={subscription.phoneNumberHash}
            canTerminate={subscription.termination}
            status={subscription.status}
            isSurfCard={isSurfCard}
            hasDuettoBundle={!!subscription.hasDuettoBundle}
          />
        </ModalDialog>
      )}
    </>
  );
};

export default SubscriptionAsset;
