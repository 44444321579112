import { BodyText, SupportedFontWeight } from '@gaiads/telia-react-component-library';
import { MarginProps } from '@gaiads/telia-react-component-library/build/types/components/Element/getMarginClasses';
import { PaddingProps } from '@gaiads/telia-react-component-library/build/types/components/Element/getPaddingClasses';
import { SupportedTagName } from '@gaiads/telia-react-component-library/build/types/components/Typography/Typography';

import styles from './BodyTextClickable.module.scss';

const BodyTextClickable: React.FC<{
  onClick: VoidFunction;
  margin?: MarginProps;
  padding?: PaddingProps;
  fontWeight?: SupportedFontWeight;
  tagName?: SupportedTagName;
  vocaLink?: boolean;
  'data-testid'?: string;
  children?: React.ReactNode;
}> = ({
  onClick,
  fontWeight = 'medium',
  tagName,
  vocaLink,
  'data-testid': testId,
  children,
  ...props
}) => {
  return (
    <BodyText
      {...props}
      data-testid={testId}
      className={{
        [styles.bodyTextClickable]: true,
        [styles.bodyTextClickable__inline]: tagName === 'span',
        [styles.bodyTextClickable__voca]: vocaLink
      }}
      tagName={tagName}
      fontWeight={fontWeight}
      color="purple500"
      hoverColor="purple400"
      clickableAppearance
      tabIndex={0}
      onClick={onClick}
      onKeyUp={(e: React.KeyboardEvent<HTMLDivElement>) => {
        e.key === 'Enter' && onClick();
      }}
    >
      {children}
    </BodyText>
  );
};

export default BodyTextClickable;
