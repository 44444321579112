import {
  BodyText,
  Flex,
  Icon,
  SupportedColor,
  SupportedIconName
} from '@gaiads/telia-react-component-library';
import { VisuallyHidden } from '@teliafi/fi-ds';
import getClassNames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './InlineNotificationLite.module.scss';

export type NotificationVariant = 'ok' | 'warning' | 'unavailable' | 'alert' | 'info';

type VariantOptions = {
  name: SupportedIconName;
  color: SupportedColor;
};

const variantOptions: { [K in NotificationVariant]: VariantOptions } = {
  ok: {
    name: 'check-circle',
    color: 'green500'
  },
  warning: {
    name: 'error',
    color: 'red600'
  },
  unavailable: {
    name: 'close-circle',
    color: 'orange500'
  },
  alert: {
    name: 'alert',
    color: 'red400'
  },
  info: {
    name: 'info',
    color: 'blue500'
  }
};

/**
 * A lightweight version of the Gaia component library's
 * `InlineNotification` which doesn't render a containing panel and
 * uses outlined icons instead of filled ones.
 *
 * @param variant Determines the notification's icon and its colour.
 * @param messageText The text to render.
 * @param compact Whether to render the notification with less padding.
 * @param unpadded Whether to render the notification without top padding
 */
const InlineNotificationLite: React.FC<{
  variant: NotificationVariant;
  messageText: string;
  compact?: boolean;
  unpadded?: boolean;
  'data-testid'?: string;
}> = ({ variant, messageText, compact = false, unpadded = false, 'data-testid': testId }) => {
  const { t } = useTranslation();
  const options = variantOptions[variant];
  const hiddenLabel = t(`aria.notification.liteVariant.${variant}`);

  return (
    <Flex
      data-testid={testId}
      className={getClassNames({
        [styles.flex]: true,
        [styles.flex_compact]: compact,
        [styles.flex_unpadded]: unpadded
      })}
    >
      <VisuallyHidden>{hiddenLabel}</VisuallyHidden>

      <Icon
        data-testid={`${testId}--icon`}
        className={getClassNames({
          [styles.icon]: true,
          [styles.icon_compact]: compact
        })}
        name={options.name}
        color={options.color}
        size="md"
        inlineBlock
      />

      <BodyText
        data-testid={`${testId}--text`}
        className={getClassNames({
          [styles.text]: true,
          [styles.text_compact]: compact
        })}
        inlineBlock
        tagName="span"
      >
        {messageText}
      </BodyText>
    </Flex>
  );
};

export default InlineNotificationLite;
