import { Div } from '@gaiads/telia-react-component-library';
import TextWithInlineHtml from 'common-components/TextWithInlineHtml/TextWithInlineHtml';

/**
 * Renders optional helper text with possible inline links for a form field.
 * If no helper text is provided, renders nothing instead.
 *
 * Mimics the styling of component library's input fields' helper texts, which
 * lack support for inline links and cut off text exceeding two lines. Hence
 * the presence of this component.
 *
 * @param helperText Description text with potential inline HTML mark-up.
 */
const FormFieldHelperText: React.FC<{
  helperText?: string;
  'data-testid'?: string;
}> = ({ helperText, 'data-testid': testId }) => {
  if (!helperText) {
    return null;
  }

  return (
    <Div color="gray500" margin={{ top: 'xs' }} data-testid={testId}>
      <TextWithInlineHtml text={helperText} size="sm" />
    </Div>
  );
};

export default FormFieldHelperText;
