import { gql } from '@apollo/client';
import { BodyText } from '@gaiads/telia-react-component-library';
import { ModalDialog } from 'common-components';
import createLinkWithPublicUrl from 'doings/links/createLinkWithPublicUrl/createLinkWithPublicUrl';
import useCallBackendLazy from 'hooks/useCallBackend/useCallBackendLazy';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import secondsToSessionExpiry from './secondsToSessionExpiry';

const TIMER_REFRESH_INTERVAL: Time.Milliseconds = 1000;
const UNHIDE_DIALOG_INTERVAL: Time.Milliseconds = 5000;

const SHOW_DIALOG_BEFORE_SESSION_EXPIRY_IN_SECS: Time.Seconds = 300;
const ONE_MINUTE_IN_SECS: Time.Seconds = 60;

export const userRefreshQuery = gql`
  query RefreshUserSession {
    user {
      email
    }
  }
`;

const SessionExpiryDialog: React.FC = () => {
  const ref = useRef<{ intervalId?: NodeJS.Timeout; timeoutId?: NodeJS.Timeout }>({});
  const { t } = useTranslation();
  const { executeQuery } = useCallBackendLazy({
    query: userRefreshQuery,
    fetchPolicy: 'no-cache'
  });

  const [seconds, setSeconds] = useState<number | undefined>();
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const doLogout = useCallback(
    () => window.location.replace(createLinkWithPublicUrl('/api/logout')),
    []
  );

  const terminateInterval = useCallback(() => {
    clearInterval(ref.current.intervalId as NodeJS.Timeout);
  }, [ref]);

  useEffect(() => {
    ref.current.intervalId = setInterval(() => {
      const secondsToExpiry = secondsToSessionExpiry();
      if (!!secondsToExpiry && secondsToExpiry < 0) {
        terminateInterval();
        doLogout();
      }

      setSeconds(secondsToExpiry);
    }, TIMER_REFRESH_INTERVAL);

    return terminateInterval;
  }, [ref, doLogout, terminateInterval]);

  useEffect(() => {
    if (isHidden) {
      ref.current.timeoutId = setTimeout(() => {
        setIsHidden(false);
      }, UNHIDE_DIALOG_INTERVAL);
    }
    const currentRef = ref.current;

    return () => clearTimeout(currentRef.timeoutId as NodeJS.Timeout);
  }, [isHidden, setIsHidden]);

  const continueSession = () => {
    executeQuery();
    setIsHidden(true);
  };

  const timeLeft = seconds || 0;

  return (
    <ModalDialog
      title={t('session.title')}
      acceptButton={{
        label: t('topNavigation.logOut'),
        onClick: doLogout,
        testIdSuffix: 'session-expiry--logout'
      }}
      closeButton={{
        label: t('common.continueButton.label'),
        onClick: continueSession,
        testIdSuffix: 'session-expiry--continue'
      }}
      onClose={continueSession}
      isOpen={!isHidden && !!seconds && seconds <= SHOW_DIALOG_BEFORE_SESSION_EXPIRY_IN_SECS}
      closeIsDefault
      hideCloseIcon
      disableOutsideClick
    >
      <BodyText>
        {timeLeft / ONE_MINUTE_IN_SECS >= 1
          ? t('session.expirationInMinutes', {
              minutes: `${Math.floor(timeLeft / ONE_MINUTE_IN_SECS) + 1}`
            })
          : t('session.expirationInSeconds', {
              seconds: `${Math.max(0, Math.floor(timeLeft % ONE_MINUTE_IN_SECS) + 1)}`
            })}
      </BodyText>
    </ModalDialog>
  );
};

export default SessionExpiryDialog;
