import { gql } from '@apollo/client';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';

/**
 * Note that `id` and `_id` fields are used by Apollo Client to normalise and
 * cache GraphQL objects by matching `__typename`, since Apollo Client flattens
 * all objects in its in-memory cache. If we use the same query in multiple
 * places with a cache in place, editing one entry in in the cache can
 * undesireably mutate others sharing the `__typename` and either `id` or `_id`
 * fields. Simplest solution: omit the `__typename` field from the queried
 * objects.
 *
 * See the below article regarding hard-to-debug Apollo GraphQL caching issues
 * caused by Apollo Client's cache normalisation behaviour:
 * https://kamranicus.com/graphql-apollo-object-caching/
 */
export const sectionItemsQuery = gql`
  query GetEditableSectionItems($section: String!) {
    editableSectionItems(section: $section) {
      id
      enabled
      __typename @skip(if: true)
    }
  }
`;

export type EditableSection = 'top-tiles' | 'quick-links' | 'other-services';

export type SectionItem = {
  id: string;
  enabled: boolean;
};

export type EditableSectionItem = SectionItem & {
  label: string;
  isNew?: boolean;
};

type QueriedSectionItems = {
  editableSectionItems: QueriedSectionItem[];
};

type QueriedSectionItem = {
  id: string;
  enabled: boolean;
};

const useEditableSectionItems = (section: EditableSection) => {
  const { data, error, loading } = useCallBackend<QueriedSectionItems>({
    query: sectionItemsQuery,
    queryVariables: {
      section
    },
    fetchPolicy: 'cache-first'
  });

  const items: SectionItem[] = data?.editableSectionItems ?? [];

  return {
    items,
    loading,
    error
  };
};

export default useEditableSectionItems;
