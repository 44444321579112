import { Breakpoints } from '@gaiads/telia-react-component-library/build/types/components/Breakpoint/Breakpoint';
import useBreakpoint from 'hooks/useBreakpoint/useBreakpoint';

const breakpointMap: { [key in Breakpoints]: number } = {
  xxxs: 0,
  xxs: 1,
  xs: 2,
  sm: 3,
  md: 4,
  lg: 5,
  xlg: 6,
  xxlg: 7
};

const useIsSmallerBreakpointActive = (breakpoint: Breakpoints) => {
  const activeBreakpoint = useBreakpoint();

  return breakpointMap[activeBreakpoint] < breakpointMap[breakpoint];
};

export default useIsSmallerBreakpointActive;
