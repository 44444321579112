import getTicketContactMethodOptions from 'B2XApp/Messages/Tickets/NewTicket/NewTicketForm/getTicketContactMethodOptions';
import validators from 'hooks/inputModels/validation/validators';
import { TFunction } from 'i18next';
import { TicketContactMethod } from 'types/ticket';
import * as Yup from 'yup';

export type CreateTicketFormValues = {
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  contactPhone: string;
  contactStreet?: string;
  contactPostalCode?: string;
  contactPostalOffice?: string;
  contactMethods: TicketContactMethod[];
  subject: string;
  company: string;
  description: string;
  productName: string;
};

export type FieldLengthLimits = {
  min: number;
  max: number;
};

export const getTicketFormValidationSchema = ({
  t,
  contactAddressFieldsShown
}: {
  t: TFunction;
  contactAddressFieldsShown: boolean;
}) => {
  const contactNameLimits: FieldLengthLimits = { min: 2, max: 50 };
  const contactEmailLimits: FieldLengthLimits = { min: 2, max: 100 };
  const contactPhoneLimits: FieldLengthLimits = { min: 2, max: 25 };

  const contactStreetLimits: FieldLengthLimits = { min: 2, max: 255 };
  const contactPostalOfficeLimits: FieldLengthLimits = { min: 2, max: 255 };

  const subjectLimits: FieldLengthLimits = { min: 2, max: 90 };

  const messages = {
    email: t('validators.invalidEmailAddress'),
    internationalMsisdn: t('validators.invalidInternationalMsisdn'),
    postalCode: t('validators.invalidNakPostalCode'),
    required: t('validators.valueIsRequired'),
    requiredOneOrMore: t('validators.valueIsRequiredOneOrMore')
  };

  return Yup.object().shape({
    contactEmail: Yup.array()
      .transform(function (value: string[], originalValue: string) {
        if (this.isType(value) && value !== null) {
          return value;
        }
        return originalValue.length
          ? originalValue.split(/\s*,\s*/).map((v) => (v.length ? v : undefined))
          : [];
      })
      .test({
        message: messages.required,
        test: (value: string[] = []) => value.length > 0 || value.some((v) => !!v)
      })
      .test({
        message: messages.requiredOneOrMore,
        test: (value: string[] = []) => value.length === 1 || value.some((v) => !!v)
      })
      .of(boundString(t, contactEmailLimits).email(messages.email)),
    contactFirstName: boundString(t, contactNameLimits).required(t(messages.required)),
    contactLastName: boundString(t, contactNameLimits).required(t(messages.required)),
    contactPhone: boundString(t, contactPhoneLimits)
      .test({
        message: messages.internationalMsisdn,
        test: (value?: string) => validators.internationalMsisdn.validate(value ?? '')
      })
      .required(t(messages.required)),
    contactStreet: contactAddressFieldsShown
      ? boundString(t, contactStreetLimits).required(t(messages.required))
      : Yup.string(),
    contactPostalCode: contactAddressFieldsShown
      ? Yup.string()
          .test({
            message: messages.postalCode,
            test: (value?: string) => validators.nakPostalCode.validate(value ?? '')
          })
          .required(t(messages.required))
      : Yup.string(),
    contactPostalOffice: contactAddressFieldsShown
      ? boundString(t, contactPostalOfficeLimits).required(t(messages.required))
      : Yup.string(),
    contactMethods: Yup.array().of(
      Yup.string().oneOf<TicketContactMethod>(
        getTicketContactMethodOptions(t).map(({ value }) => value)
      )
    ),
    company: Yup.string().required(messages.required),
    subject: boundString(t, subjectLimits).required(t(messages.required))
  });
};

const boundString = (t: TFunction, limits: FieldLengthLimits): Yup.StringSchema => {
  return Yup.string()
    .min(limits.min, t('validators.valueIsTooShort', { min: limits.min }))
    .max(limits.max, t('validators.valueIsTooLong', { max: limits.max }));
};
