import { PageWithFormLayout } from 'common-components';

const BillingGroupFormPageLayout: React.FC<{
  title: string;
  backLinkVisible?: boolean;
  loading?: boolean;
  children: React.ReactNode;
}> = ({ backLinkVisible, title, loading, children }) => {
  return (
    <PageWithFormLayout
      title={title}
      backLinkVisible={backLinkVisible}
      loading={loading}
      data-testid="billing-group-form-page-layout"
    >
      {children}
    </PageWithFormLayout>
  );
};

export default BillingGroupFormPageLayout;
