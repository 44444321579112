export type AvailableBank = {
  bic: BicCode;
  name: string;
};

export type AvailableOperator = {
  code: string;
  name: string;
};

export enum CollectionGroupDelivery {
  'PAPER' = 'PAPER',
  'EMAIL' = 'EMAIL',
  'EBILL' = 'EBILL',
  'NET' = 'NET',
  'EDI' = 'EDI',
  'PAPERLESS' = 'PAPERLESS',
  'DIVIDER' = 'DIVIDER',
  'Z40' = 'Z40',
  'Convergent' = 'Convergent',
  'JOINT' = 'JOINT',
  'DIRECT' = 'DIRECT',
  'N/A' = 'N/A'
}

export type CollectionGroupDeliveryType = keyof typeof CollectionGroupDelivery;

export type CollectionGroupDeliveryChangeability = 'CHANGEABLE' | 'READ_ONLY' | 'UNSUPPORTED';

type CollectionGroupType = {
  deliveryMethod: CollectionGroupDeliveryType;
  displayedDeliveryMethod: string;
  changeability: CollectionGroupDeliveryChangeability;
  switchableDeliveryMethods: CollectionGroupDeliveryType[];
};

type CollectionGroupAddress = {
  foreign: boolean;
  postalAddress: string;
  street: string;
  postalOffice: string;
  zip: string;
  country?: string;
};

type QueriedCollectionGroupLanguage = 'fi' | 'en' | 'sv' | null;

export type QueriedCollectionGroup = {
  address: CollectionGroupAddress;
  collectionGroup: string;
  customerCode: string;
  canCopy?: boolean;
  distributor?: string;
  email1?: string;
  email2?: string;
  netServiceId?: string;
  language: QueriedCollectionGroupLanguage;
  name?: string;
  phoneNumber?: string;
  reference?: string;
  addressSupplement?: string;
  type: CollectionGroupType;
  finvoice?: Finvoice;
};

/**
 *
 * The type below was introduced for future billing
 * group development purposes for large and public
 * companies that want to provide Finvoice 3.0 additional
 * information to their billing groups.
 *
 */
export type Finvoice = {
  usesCustomerSettings?: boolean;
  orderIdentifier?: string;
  buyerReferenceIdentifier?: string;
  agreementIdentifier?: string;
  accountDimensionText?: string;
  tenderReference?: string;
  proposedAccountText?: string;
  normalProposedAccountIdent?: string;
  projectReferenceIdentifier?: string;
  invoiceClassification?: string;
};

export type CollectionGroup = {
  invoicingLanguage: string;
  billingAddress: string;
} & QueriedCollectionGroup;

export type BicCode =
  | 'AABAFI22'
  | 'DABAFIHH'
  | 'HANDFIHH'
  | 'HELSFIHH'
  | 'ITELFIHH'
  | 'NDEAFIHH'
  | 'OKOYFIHH'
  | 'POPFFI22'
  | 'SBANFIHH';

export enum CollectionGroupHandlingType {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL'
}

export type CollectionGroupApiStatus =
  | 'initial'
  | 'persisting'
  | 'success'
  | 'partial-success'
  | 'failure';
