import { Button } from 'common-components/Button/Button';
import { useTranslation } from 'react-i18next';

export const ClearButton: React.FC<{
  clearFilters: VoidFunction;
}> = ({ clearFilters }) => {
  const { t } = useTranslation();
  return (
    <Button
      data-testid="remove-filters-button"
      size="sm"
      variant="tertiary-purple"
      onClick={clearFilters}
    >
      {t('common.clearFiltersButton.label')}
    </Button>
  );
};
