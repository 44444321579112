import { GridBag, Gutter } from '@gaiads/telia-react-component-library';
import { Breakpoint, Contact, ContactLink } from 'common-components';
import DetailsPage from 'common-components/DetailsPage/DetailsPage';
import { invoicing } from 'doings/track/analyticsEvents';
import { useTranslation } from 'react-i18next';
import { InvoiceDetailsType } from 'types/invoice';
import { ServiceRequestDynamicFormType } from 'types/serviceRequest';

import InvoiceBillingInfo from './InvoiceBillingInfo/InvoiceBillingInfo';
import InvoiceMainInfo from './InvoiceMainInfo/InvoiceMainInfo';
import InvoicePaymentStatusNotification from './InvoicePaymentStatusNotification';

const InvoiceDetailsPage: React.FC<{
  invoice?: InvoiceDetailsType;
  loading: boolean;
  hasError: boolean;
}> = ({ invoice, loading, hasError }) => {
  const { t } = useTranslation();
  const paymentStatus = invoice?.paymentStatus;

  const contactPanel = (
    <Contact isLoading={loading} data-testid="invoice-details-page-contact">
      <ContactLink.ServiceRequest
        uriParams={{ origin: 'invoice', key: invoice?.key }}
        analyticsEvent={invoicing.createServiceRequest}
        formType={ServiceRequestDynamicFormType.CHANGES_TO_BILLING_INFO}
        label={t('invoice.contact.supportRequest')}
      />

      {invoice?.canConvertToEInvoice && (
        <ContactLink.ServiceRequest
          uriParams={{ origin: 'invoice', key: invoice?.key }}
          analyticsEvent={invoicing.createServiceRequestForEInvoiceChange}
          formType={ServiceRequestDynamicFormType.CHANGES_TO_BILLING_INFO}
          label={t('invoice.contact.changeToElectronicInvoice')}
          iconName="invoice"
          testId="change-to-einvoice-link"
        />
      )}
    </Contact>
  );

  return (
    <DetailsPage
      data-testid={`invoice-details-page${hasError ? '-data-not-available' : ''}`}
      hasError={hasError}
      loading={loading}
      backButtonPath="/invoices/"
      headline={t('invoice.details.title')}
    >
      <InvoicePaymentStatusNotification
        paymentStatus={paymentStatus}
        data-testid="invoice-details-page-payment-status-notification"
      />

      <GridBag>
        <GridBag.Item md={6} sm={12}>
          <InvoiceMainInfo
            loading={loading}
            invoice={invoice}
            hasError={hasError}
            data-testid="invoice-details-page-main-info"
          />

          <Breakpoint widerThan="md">
            <Gutter size="sm" />

            {contactPanel}
          </Breakpoint>
        </GridBag.Item>

        <GridBag.Item md={6} sm={12}>
          <InvoiceBillingInfo
            loading={loading}
            invoice={invoice}
            hasError={hasError}
            data-testid="invoice-details-page-billing-info"
          />

          <Breakpoint narrowerThan="md">
            <Gutter size="sm" />

            {contactPanel}
          </Breakpoint>
        </GridBag.Item>
      </GridBag>
    </DetailsPage>
  );
};

export default InvoiceDetailsPage;
