import AccessDenied from 'B2XApp/Errors/AccessDenied/AccessDenied';
import NotFound from 'B2XApp/Errors/NotFound/NotFound';
import { FullPageLoader } from 'common-components';
import authorizeUser from 'doings/authorizeUser/authorizeUser';
import useValidateAccessByEmailList from 'hooks/accessByEmailList/useValidateAccessByEmailList/useValidateAccessByEmailList';
import { AuthorizationParams } from 'types/authorization';

const RouteAccessProtector: React.FC<{
  authParams?: AuthorizationParams;
  emailAccessList?: AccessControlGroup;
  children: React.ReactNode;
}> = ({ children, authParams, emailAccessList }) => {
  const { isUserInList, loading } = useValidateAccessByEmailList(emailAccessList);

  const isAuthorised = !authParams || authorizeUser(authParams);
  const isPageAccessible = isUserInList && isAuthorised;

  return (
    <>
      {loading && <FullPageLoader loading />}

      {!loading && isPageAccessible && children}

      {!loading &&
        !isPageAccessible &&
        (!emailAccessList ? (
          <AccessDenied data-testid="access-denied" />
        ) : (
          <NotFound data-testid="not-found" hideBreadcrumb />
        ))}
    </>
  );
};

export default RouteAccessProtector;
