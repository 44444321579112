import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface ConfirmationModalState {
  showConfirmationModal: boolean;
  isModalOpen: boolean;
  acceptUrl: string;
  changeToLanguage: Language | null;
}

const initialState: ConfirmationModalState = {
  showConfirmationModal: false,
  isModalOpen: false,
  acceptUrl: '',
  changeToLanguage: null
};

export const confirmationModalSlice = createSlice({
  name: 'confirmationModal',
  initialState,
  reducers: {
    setShowConfirmationModal: (state, action: PayloadAction<boolean>) => {
      state.showConfirmationModal = action.payload;
    },
    setIsModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isModalOpen = action.payload;
    },
    setAcceptUrl: (state, action: PayloadAction<string>) => {
      state.acceptUrl = action.payload;
    },
    setLanguage: (state, action: PayloadAction<Language | null>) => {
      state.changeToLanguage = action.payload;
    }
  }
});

export const { setShowConfirmationModal, setIsModalOpen, setAcceptUrl, setLanguage } =
  confirmationModalSlice.actions;

export default confirmationModalSlice.reducer;
