import { useCallback, useMemo, useRef, useState } from 'react';
import { DynamicForms } from 'types/dynamicForms';

import { check } from './DynamicForm/dynamicFormFieldCheck';
import { getDynamicFormMetainfoOverride } from './DynamicForm/getDynamicFormMetainfoOverride';
import DynamicFormMain, {
  DYNFORM_SELECTOR_CONTAINER_NAME,
  DynamicFormOnPresubmitObserver,
  DynamicFormOnSubmitObserver,
  DynamicFormState,
  DynamicFormSubmitResponse
} from './DynamicFormMain';

export type UseDynamicFormMainResult = {
  dynamicForm: JSX.Element;
  isDynamicFormValid: boolean;
  onDynamicFormPresubmitCallback: () => string[];
  onDynamicFormSubmitCallback: () => DynamicFormSubmitResponse;
  dynamicValues: Record<string, unknown>;
  appliedMetainfo: {
    prefillSubject: PrefillSubjectType;
    hiddenFields: string[];
    requiredFields: string[];
    relabelledFields: RelabelStaticField[];
    allowedAttachmentTypes: string[];
    isDeadEnd: boolean;
    contactFieldSettings: ContactFieldSettings | undefined;
  };
};

export type PrefillSubjectType =
  | { type: 'none' }
  | { type: 'exact'; value: string }
  | { type: 'field'; from: string };

export type RelabelStaticField = { name: string; label: string };

export type ContactFieldSettings = {
  info?: string;
  showAddressFields?: boolean;
};

type EligibleDynamicFormGroup =
  | 'service_requests'
  | 'service_requests_b2o'
  | 'service_requests_order_inquiry'
  | 'service_requests_billing_info_mass_change'
  | 'incident_tickets'
  | 'incident_tickets_b2o';

/**
 * Returns a self-contained dynamic form for the dynamic form group of
 * the specified name, the form's validity and a callback function to
 * extract the dynamic form's user-provided input.
 */
export default ({
  groupName,
  formStateRef,
  fixedValues
}: {
  /** The reference name of the underlying dynamic form group as defined in the dynamic form service. */
  groupName: EligibleDynamicFormGroup;
  /** State for rendering an initial form from stored data. */
  formStateRef: React.MutableRefObject<DynamicFormState | undefined>;
  /** A map of named fields to values that the end-user should not be able to change (of type INPUT or TEXTAREA). */
  fixedValues?: Record<string, string | undefined>;
}): UseDynamicFormMainResult => {
  const [dynamicFormValid, setDynamicFormValid] = useState(false);
  const [dynamicValues, setDynamicValues] = useState({});

  const [prefillSubject, setPrefillSubject] = useState<PrefillSubjectType>({ type: 'none' });
  const [requiredFields, setRequiredFields] = useState<string[]>([]);
  const [allowedAttachmentTypes, setAllowedAttachmentTypes] = useState<string[]>([]);
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  const [relabelledFields, setRelabelledFields] = useState<RelabelStaticField[]>([]);
  const [deadEnd, setDeadEnd] = useState(false);
  const [contactFieldSettings, setContactFieldSettings] = useState<ContactFieldSettings>();

  const onDynamicFormLoad = useCallback((form: DynamicForms.DynamicForm) => {
    const subjectField = form.fields.find(
      (field) => check.isValidatable(field) && field.name === form.metainfo?.prefillSubjectFrom
    );
    const metainfoSubjectOrNone: PrefillSubjectType = form.metainfo?.subject
      ? { type: 'exact', value: form.metainfo?.subject ?? '' }
      : { type: 'none' };

    setPrefillSubject(
      subjectField?.id ? { type: 'field', from: subjectField.id } : metainfoSubjectOrNone
    );
    setHiddenFields(form.metainfo?.hideStaticFields ?? []);
    setRequiredFields(form.metainfo?.requireStaticFields ?? []);
    setRelabelledFields(form.metainfo?.relabelStaticFields ?? []);
    setAllowedAttachmentTypes(form.metainfo?.allowedAttachmentTypes ?? []);
  }, []);

  const onDynamicFormValidate = useCallback(
    (isValid: boolean, form?: DynamicForms.DynamicForm, values: Record<string, unknown> = {}) => {
      setDynamicFormValid(isValid);
      setDynamicValues(values);

      if (form?.metainfo?.overrides) {
        const applicableOverride = getDynamicFormMetainfoOverride({ form, values });
        setHiddenFields(
          applicableOverride?.hideStaticFields ?? form.metainfo?.hideStaticFields ?? []
        );

        setRequiredFields(
          applicableOverride?.requireStaticFields ?? form.metainfo?.requireStaticFields ?? []
        );

        if (prefillSubject.type !== 'field' && applicableOverride?.subject) {
          setPrefillSubject({ type: 'exact', value: applicableOverride.subject });
        } else if (form.metainfo?.subject) {
          setPrefillSubject({ type: 'exact', value: form.metainfo?.subject });
        } else if (prefillSubject.type === 'exact') {
          setPrefillSubject({ type: 'none' });
        }

        setRelabelledFields(
          applicableOverride?.relabelStaticFields ?? form.metainfo?.relabelStaticFields ?? []
        );

        setDeadEnd(applicableOverride?.deadEnd ?? false);
        setContactFieldSettings(applicableOverride?.contactFields);
      }
    },
    [prefillSubject.type]
  );

  const onPresubmitCallback = useRef<() => string[]>(() => [DYNFORM_SELECTOR_CONTAINER_NAME]);
  const onSubmitCallback = useRef<() => DynamicFormSubmitResponse>(() => ({
    description: '',
    product: '',
    priority: undefined
  }));

  const onPresubmitObserver = useRef<DynamicFormOnPresubmitObserver>({
    onPresubmit: (callback: () => string[]) => {
      onPresubmitCallback.current = callback;
    }
  });

  const onSubmitObserver = useRef<DynamicFormOnSubmitObserver>({
    onSubmit: (callback: () => DynamicFormSubmitResponse) => {
      onSubmitCallback.current = callback;
    }
  });

  const dynamicForm = useMemo(
    () => (
      <DynamicFormMain
        groupName={groupName}
        register={{
          onPresubmit: onPresubmitObserver.current?.onPresubmit,
          onSubmit: onSubmitObserver.current?.onSubmit
        }}
        onLoad={onDynamicFormLoad}
        onValidate={onDynamicFormValidate}
        formStateRef={formStateRef}
        fixedValues={fixedValues}
      />
    ),
    [groupName, onDynamicFormLoad, onDynamicFormValidate, formStateRef, fixedValues]
  );

  return {
    dynamicForm,
    isDynamicFormValid: dynamicFormValid,
    onDynamicFormPresubmitCallback: onPresubmitCallback.current,
    onDynamicFormSubmitCallback: onSubmitCallback.current,
    dynamicValues,
    appliedMetainfo: {
      prefillSubject,
      hiddenFields,
      requiredFields,
      allowedAttachmentTypes,
      relabelledFields,
      isDeadEnd: deadEnd,
      contactFieldSettings
    }
  };
};
