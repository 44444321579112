/**
 * A simplistic approach for determining the first eligible value without
 * resorting to nested ternary operators. The first value in the specified
 * array of argument is fallen back to if none of the other values have
 * a truthy condition.
 *
 * Examples:
 * ```ts
 * // Returns 'default value'
 * multiplex(['default value', [false, '1st value'], [false, '2nd value']])
 *
 * // Returns '1st value'
 * multiplex(['default value', [true, '1st value'], [false, '2nd value']])
 *
 * // Returns '1st value'
 * multiplex(['default value', [true, '1st value'], [true, '2nd value']])
 *
 * // Returns '2nd value'
 * multiplex(['default value', [false, '1st value'], [true, '2nd value']])
 * ```
 *
 * @param args Array in format of `[defaultValue, [condition, value]...]`.
 * @returns The first value for a truthy value, or the default value otherwise.
 */
export const multiplex = <T>(args: [T, [boolean, T], ...[boolean, T][]]): T => {
  const el = args.find((a) => Array.isArray(a) && a[0]);
  return Array.isArray(el) ? (el[1] as T) : (args[0] as T);
};
