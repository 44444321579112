import { Flex, Loader } from '@gaiads/telia-react-component-library';
import { Icon, IconName } from '@teliafi/fi-ds';
import { Button } from 'common-components/Button/Button';
import { useTranslation } from 'react-i18next';

const variants = {
  showMore: {
    i18nKey: 'common.showMore',
    iconName: 'chevron-down' as IconName,
    loaderTestId: 'show-more-results-loader',
    iconTestId: 'show-more-results-icon'
  },
  showAll: {
    i18nKey: 'common.showAll',
    iconName: 'chevron-down' as IconName,
    loaderTestId: 'show-all-results-loader',
    iconTestId: 'show-all-results-icon'
  },
  showLess: {
    i18nKey: 'common.showLess',
    iconName: 'chevron-up' as IconName,
    loaderTestId: 'show-less-results-loader',
    iconTestId: 'show-less-results-icon'
  }
};

type Variant = keyof typeof variants;

type ShowMoreResultsProps = {
  loading: boolean;
  hasMoreResults: boolean;
  hasResults: boolean;
  fetchMore: VoidFunction;
  alignLeft?: boolean;
  variant?: Variant;
  'data-testid'?: string;
};

const ShowMoreResults: React.FC<ShowMoreResultsProps> = ({
  loading,
  hasMoreResults,
  hasResults,
  fetchMore,
  alignLeft,
  variant = 'showMore',
  'data-testid': testid
}) => {
  const { t } = useTranslation();
  if (!hasMoreResults || !hasResults) {
    return null;
  }

  const { i18nKey, iconName, loaderTestId, iconTestId } = variants[variant];

  return (
    <Flex centered={!alignLeft} data-testid={`${testid}-wrapper`}>
      {loading ? (
        <Loader data-testid={loaderTestId} size="md" margin={{ top: 'xxxs' }} />
      ) : (
        <Button
          type="button"
          variant="secondary"
          size="sm"
          onClick={fetchMore}
          data-testid={testid}
        >
          <Icon name={iconName} size="sm" data-testid={iconTestId} />

          {t(i18nKey)}
        </Button>
      )}
    </Flex>
  );
};

export default ShowMoreResults;
