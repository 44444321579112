import OrderProgressBar from 'B2XApp/Orders/OrderListComponents/OrderProgressBar';
import OrderStatus from 'B2XApp/Orders/OrderStatus/OrderStatusTag';
import { ListView, Memo } from 'common-components';
import { useEditableListData } from 'contexts/EditableListContext/EditableListContext';
import { EN_DASH } from 'doings/dash/dash';
import { multiplex } from 'doings/multiplex/multiplex';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { ColumnVisibility, ListViewColumnSetup } from 'types/listConfig';
import { OrderLineItem, OrderListItem } from 'types/orders';

import OrdersListViewLineItems from './OrdersListViewLineItems';

export const OrdersListViewColumnSetup: ListViewColumnSetup<[TFunction, boolean, boolean]> = {
  listId: 'orders',
  getColumns: (t: TFunction, showProgressIndicator: boolean, showCompanyByDefault: boolean) => ({
    created: {
      width: '0.75x',
      heading: t('orders.list.columns.createdDate'),
      testidSuffix: 'order-created-date',
      baseVisibility: ColumnVisibility.SHOW
    },
    orderName: {
      width: '2x',
      heading: t('orders.list.columns.orderName'),
      testidSuffix: 'order-name',
      baseVisibility: ColumnVisibility.REQUIRE
    },
    deliveryDate: {
      width: '0.75x',
      heading: t('orders.list.columns.deliveryDate'),
      tooltip: t('orders.list.deliveryTooltip'),
      testidSuffix: 'order-delivery-date',
      baseVisibility: ColumnVisibility.SHOW
    },
    additionalInfo: {
      width: '2x',
      heading: t('orders.list.columns.additionalInfo'),
      testidSuffix: 'order-address',
      baseVisibility: ColumnVisibility.HIDE
    },
    status: {
      heading: t('orders.list.columns.status'),
      testidSuffix: 'order-status',
      baseVisibility: ColumnVisibility.SHOW
    },
    progress: {
      heading: t('orders.list.columns.progress'),
      showIf: showProgressIndicator,
      testidSuffix: 'order-progress',
      baseVisibility: ColumnVisibility.SHOW
    },
    orderId: {
      heading: t('orders.list.columns.orderId'),
      testidSuffix: 'order-id',
      baseVisibility: ColumnVisibility.REQUIRE
    },
    company: {
      heading: t('common.company.label'),
      tooltip: t('common.company.tooltip'),
      testidSuffix: 'order-company',
      baseVisibility: showCompanyByDefault ? ColumnVisibility.SHOW : ColumnVisibility.HIDE
    }
  })
};

const OrdersListView: React.FC<{
  loading: boolean;
  orders: OrderListItem[];
  orderLineVisible: string | boolean;
  onToggleVisibleOrderLine: (id: string) => void;
  listRef?: React.RefObject<HTMLDivElement>;
}> = ({ loading, orders, orderLineVisible, onToggleVisibleOrderLine, listRef }) => {
  const { t } = useTranslation();
  const { columns } = useEditableListData();

  return (
    <ListView
      data-testid="orders-list"
      columns={columns}
      mobileLayout={{
        titleRow: { col: 'orderName' },
        rows: [
          { col: 'created' },
          { col: 'orderId' },
          { col: 'deliveryDate' },
          { col: 'additionalInfo' },
          { col: 'company' },
          { colsJoined: ['status', 'progress'], padding: 'xs' }
        ]
      }}
      hasExpandableItems
      loading={loading}
      listData={orders}
      listRef={listRef}
      noItemsNotice={t('orders.list.noItemsFound')}
    >
      {({
        createdDate,
        lineItems,
        status,
        displayId,
        id,
        completionPercentage,
        estimatedTimeOfCompletion,
        companyCode,
        companyName,
        isEcommerce,
        externalId
      }) => {
        const expanded = orderLineVisible === id;
        const sublistHeader = multiplex([
          lineItems[0]?.productName,
          [isEcommerce, t('orders.list.ecommerceOrder', { count: lineItems.length })],
          [lineItems.length > 1, t('orders.list.multipleServices', { count: lineItems.length })]
        ]);
        const sublistAddress = determineOrderAddress(t, lineItems);

        return (
          <Memo comparables={['data-expanded']}>
            <ListView.Row
              data-testid="orders-list-row-item"
              data-expanded={expanded}
              usesExpandableArrow
              onClick={() => onToggleVisibleOrderLine(id)}
              sublistProps={{
                component: (
                  <OrdersListViewLineItems
                    orderId={id}
                    orderlineData={lineItems}
                    isEcommerce={isEcommerce}
                  />
                ),
                visible: expanded
              }}
            >
              <ListView.RowShape
                cells={{
                  created: { data: createdDate, props: { dataTestId: 'order-created-date' } },
                  orderName: {
                    data: sublistHeader,
                    props: { dataTestId: 'order-name', wordWrap: 'ellipsis-two-lines' }
                  },
                  deliveryDate: {
                    data: estimatedTimeOfCompletion || EN_DASH,
                    props: { dataTestId: 'order-delivery-date' }
                  },
                  additionalInfo: {
                    data: sublistAddress || EN_DASH,
                    props: { dataTestId: 'order-address', wordWrap: 'ellipsis-two-lines' }
                  },
                  status: {
                    data: <OrderStatus status={status} />,
                    props: { dataTestId: `order-status-${status}` }
                  },
                  progress: {
                    data: <OrderProgressBar progress={completionPercentage ?? 0} />,
                    props: { dataTestId: 'order-progress' }
                  },
                  orderId: {
                    data: isEcommerce ? externalId : displayId,
                    props: { dataTestId: 'order-id', wordWrap: 'anywhere' }
                  },
                  company: {
                    data: companyCode || companyName,
                    props: {
                      dataTestId: 'order-company',
                      tooltip: `${companyCode} ${companyName}`,
                      wordWrap: 'ellipsis'
                    }
                  }
                }}
              />
            </ListView.Row>
          </Memo>
        );
      }}
    </ListView>
  );
};

export const determineOrderAddress = (t: TFunction, lineItems: OrderLineItem[]) => {
  const addresses = lineItems
    .filter(({ displayAddress }) => !!displayAddress)
    .map(({ displayAddress }) => displayAddress);

  const uniqueAddresses = new Set(addresses);
  const addressCount = uniqueAddresses.size;
  if (addressCount === 0) {
    return '';
  } else if (addressCount === 1) {
    return uniqueAddresses.values().next().value;
  } else {
    return t('orders.list.multipleAddresses', { count: addressCount });
  }
};

export default OrdersListView;
