import routeAccessGroups from 'B2XApp/appRouteAccessGroups';
import ContactUsPanel from 'B2XApp/ContactUs/ContactUsPanel';
import { TeliaLink } from 'common-components';
import { Divider } from 'core-components';
import getEnvironmentVariable from 'doings/getEnvironmentVariable/getEnvironmentVariable';
import { contactUs } from 'doings/track/analyticsEvents';
import { useTranslation } from 'react-i18next';

const RequestForQuotePanel: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ContactUsPanel
      title={t('contactUs.panel.title.requestForQuote')}
      titleIcon="handshake"
      content={t('contactUs.panel.text.requestForQuote')}
      data-tour-stop="contact-us-request-quote-panel"
      data-testid="contact-us-request-quote-panel"
    >
      <TeliaLink.External
        variant="standalone-spacious"
        to={getEnvironmentVariable('REACT_APP_LINK_TO_REQUEST_QUOTATION')}
        label={t('contactUs.panel.link.requestForQuote')}
        iconRight="external"
        track={contactUs.requestForQuotation}
        enabledWhen={routeAccessGroups.REQUEST_FOR_QUOTATION}
        data-testid="contact-us-request-quote-link"
        openInNewTab
      />

      <Divider />
    </ContactUsPanel>
  );
};

export default RequestForQuotePanel;
