import { MarginSpace } from '@gaiads/telia-react-component-library';
import Hero from 'B2XApp/Hero/Hero';
import NotificationsNews from 'B2XApp/Notifications/NotificationsNews';
import { useUserData } from 'contexts/UserContext/UserContext';
import { isWholesaleBusiness } from 'doings/isWholesaleBusiness/isWholesaleBusiness';
import { useTranslation } from 'react-i18next';

import styles from './Dashboard.module.scss';
import { DashboardNavigation } from './DashboardNavigation/DashboardNavigation';
import { DashboardTopTileNavigation } from './DashboardNavigation/DashboardTopTileNavigation';
import { DashboardNewUserTourNotice } from './DashboardNewUserTourNotice/DashboardNewUserTourNotice';
import DashboardOtherServices from './DashboardOtherServices/DashboardOtherServices';

const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const user = useUserData();
  const isB2BBusiness = !isWholesaleBusiness(user);

  return (
    <>
      <Hero
        data-testid="greeting-text"
        heading={t('dashboard.welcomeToPortal', {
          name: user.firstName || user.lastName || user.name
        })}
      />

      <MarginSpace className={styles.dashboard_container} noPaddingForSmallerThanSm>
        {isB2BBusiness && <DashboardNewUserTourNotice />}

        <NotificationsNews />

        {isB2BBusiness && <DashboardTopTileNavigation />}

        <DashboardNavigation />
      </MarginSpace>

      <MarginSpace className={styles.dashboard_otherServicesContainer}>
        <DashboardOtherServices />
      </MarginSpace>
    </>
  );
};

export default Dashboard;
