import {
  BodyText,
  Div,
  Icon,
  Link as LinkFromCL,
  SupportedBodyTextSize,
  SupportedIconName
} from '@gaiads/telia-react-component-library';
import getClassNames, { Argument } from 'classnames';
import NoPrivilegesTooltip from 'common-components/Authorize/NoPrivilegesTooltip/NoPrivilegesTooltip';
import { shouldDisableLink } from 'common-components/Link/Link';
import enableTextSelectionInLink from 'doings/events/enableTextSelectionInLink/enableTextSelectionInLink';
import isCorporatePortalOrUlmUrl from 'doings/isCorporatePortalOrUlmUrl/isCorporatePortalOrUlmUrl';
import isDemoEnvironment from 'doings/isDemoEnvironment/isDemoEnvironment';
import { multiplex } from 'doings/multiplex/multiplex';
import { noOp } from 'doings/noOp/noOp';
import React from 'react';
import { AuthorizationParams } from 'types/authorization';

import styles from './ExternalLink.module.scss';

const ExternalLink: React.FC<{
  to?: string;
  label?: string;
  suppress?: boolean;
  className?: string | Array<string> | Argument;
  color?: React.ComponentProps<typeof LinkFromCL>['color'];
  hoverColor?: React.ComponentProps<typeof LinkFromCL>['hoverColor'];
  fontWeight?: React.ComponentProps<typeof BodyText>['fontWeight'];
  block?: boolean;
  padding?: React.ComponentProps<typeof BodyText>['padding'];
  margin?: React.ComponentProps<typeof BodyText>['margin'];
  singleLine?: boolean;
  disabled?: boolean;
  enabledWhen?: AuthorizationParams;
  'data-testid'?: string;
  openInNewWindow?: boolean;
  allowTextSelection?: boolean;
  icon?: SupportedIconName;
  size?: SupportedBodyTextSize;
  noIcon?: boolean;
  onClick?: VoidFunction;
  tabIndex?: number;
  children?: React.ReactNode;
}> = ({
  to,
  label,
  suppress = false,
  className,
  fontWeight = 'medium',
  color = 'purple500',
  hoverColor = 'purple400',
  padding,
  margin = { size: 'zero' },
  block = false,
  singleLine = false,
  disabled = false,
  enabledWhen,
  'data-testid': dataTestid,
  openInNewWindow,
  allowTextSelection,
  icon,
  size = 'md',
  noIcon = false,
  onClick,
  tabIndex,
  children
}) => {
  const classes = Array.isArray(className) ? className : [className];
  const isDemoEnv = isDemoEnvironment();
  const { isDisabled, isUnauthorised } = shouldDisableLink(disabled, enabledWhen);
  const isB2BOrUlmUrl = isCorporatePortalOrUlmUrl(to);
  const shouldDisableUrl = isDisabled || (isDemoEnv && isB2BOrUlmUrl);
  const tagName = block ? 'div' : 'span';

  const renderLinkContent = () => {
    return React.Children.count(children) ? (
      children
    ) : (
      <BodyText
        className={styles.label}
        fontWeight={fontWeight}
        padding={padding}
        margin={margin}
        size={size}
        singleLine={singleLine}
        tagName={tagName}
        data-testid="external-link-content"
      >
        {icon && (
          <Icon
            className={styles.iconLeft}
            data-testid="link-icon-left"
            data-iconname={icon}
            name={icon}
            size="sm"
            margin={{ right: 'xs' }}
          />
        )}

        {label}

        {!isB2BOrUlmUrl && !noIcon && to !== '#' && !onClick && (
          <>
            &nbsp;
            <Icon
              data-testid="link-icon-right"
              data-iconname="external"
              className={styles.iconRight}
              name="external"
              color={!shouldDisableUrl && color === 'purple500' ? 'gray500' : undefined}
              size="xs"
            />
          </>
        )}
      </BodyText>
    );
  };

  if (shouldDisableUrl) {
    return (
      <NoPrivilegesTooltip
        i18nKey="common.tooltips.insufficientPermissions.forExternalLink"
        showTooltip={isUnauthorised}
        tooltipPlacement="top"
      >
        <Div
          data-testid={dataTestid}
          className={getClassNames(classes, { [styles.disabled]: true })}
          color="gray400"
          hoverColor="gray400"
          aria-disabled
        >
          {renderLinkContent()}
        </Div>
      </NoPrivilegesTooltip>
    );
  }

  const clickHandler = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (allowTextSelection) {
      enableTextSelectionInLink(e);
    }

    onClick?.();
  };

  // If the "link" has no real link, we need an explicit key down event
  // handler to ensure keyboard invocability; otherwise, resort to default
  // link behaviour, which is sufficient
  const keyHandler =
    onClick && !to
      ? (e: React.KeyboardEvent<HTMLElement>) => {
          e.key === 'Enter' && onClick();
        }
      : noOp;

  return (
    <LinkFromCL
      data-testid={dataTestid}
      className={getClassNames(classes, {
        [styles.block]: block,
        [styles.inline]: !block,
        [styles.selectable]: allowTextSelection
      })}
      to={multiplex([to, [suppress, '#']])}
      fontWeight={fontWeight}
      color={color}
      hoverColor={hoverColor}
      openInNewWindow={openInNewWindow === false ? false : to !== '#' && !isB2BOrUlmUrl}
      draggable={allowTextSelection ? false : undefined}
      onClick={clickHandler}
      onKeyUp={keyHandler}
      tabIndex={tabIndex}
      {...(!to ? { role: 'button' } : {})}
    >
      {renderLinkContent()}
    </LinkFromCL>
  );
};

export default ExternalLink;
