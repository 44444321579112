import { Div, Gutter } from '@gaiads/telia-react-component-library';
import { Icon } from '@teliafi/fi-ds';
import { DynamicFormState } from 'B2XApp/DynamicForms/DynamicFormMain';
import useDynamicFormMain from 'B2XApp/DynamicForms/useDynamicFormMain';
import { useStaticSubjectPrefill } from 'B2XApp/DynamicForms/useStaticSubjectPrefill';
import { TicketFileUploader } from 'B2XApp/Messages/common';
import { ServiceRequestFields } from 'B2XApp/Messages/ServiceRequests/NewServiceRequest/getServiceRequestFields';
import {
  ActionButtonGroup,
  Button,
  DataPanel,
  DetailsLoadingOverlay,
  Forms,
  Heading,
  InlineNotification,
  InlineNotificationLite
} from 'common-components';
import ModelDropdown from 'common-components/ModelDropdown/ModelDropdown';
import ModelMultiselect from 'common-components/ModelMultiselect/ModelMultiselect';
import ModelTextInput from 'common-components/ModelTextInput/ModelTextInput';
import { useUserData } from 'contexts/UserContext/UserContext';
import { multiplex } from 'doings/multiplex/multiplex';
import { scrollElementIntoView } from 'doings/scroll/scrollElementIntoView';
import { messages } from 'doings/track/analyticsEvents';
import trackEvent from 'doings/track/trackEvent';
import { SUPPORTED_FILE_TYPES } from 'doings/validateMessageAttachment/validateMessageAttachment';
import { CreateServiceRequestData } from 'hooks/serviceRequests/useCreateServiceRequest/useCreateServiceRequest';
import { useConfirmationModal } from 'hooks/useCustomModal/useConfirmationModal';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from 'types/product';

import { getChangedValue } from '../helpers';
import { CreateServiceRequestSavingStatus } from '../useCreateServiceRequestSteps';
import { ServiceRequestOrigin } from '../useServiceRequestOrigin';
import { OrderRecord, RelatedRecord } from '../useServiceRequestRelatedRecord';
import mapNewRequestDynamicProductId from './mapNewRequestDynamicProductId';
import useNewRequestForm from './useNewRequestForm';

type NewRequestFormProps = {
  createServiceRequest: (requestData: CreateServiceRequestData) => void;
  caseOrigin?: ServiceRequestOrigin['type'];
  products: Product[];
  requestFields: ServiceRequestFields;
  dynamicFormStateRef: React.MutableRefObject<DynamicFormState | undefined>;
  staticFormStateRef: React.MutableRefObject<CreateServiceRequestData | undefined>;
  saving: CreateServiceRequestSavingStatus;
  savingReset?: VoidFunction;
  activeGroupType: ActiveGroupType;
  relatedRecord?: RelatedRecord;
};

const NewRequestFormDynamic: React.FC<NewRequestFormProps> = ({
  createServiceRequest,
  caseOrigin,
  requestFields,
  products,
  dynamicFormStateRef,
  staticFormStateRef,
  saving,
  savingReset,
  activeGroupType,
  relatedRecord
}) => {
  const { t } = useTranslation();
  const { email, name, firstName, lastName, businessPhoneNumber } = useUserData();
  const { setConfirmationModal, onClick } = useConfirmationModal();
  const staticSubjectPrefill = useStaticSubjectPrefill();

  const {
    dynamicForm,
    isDynamicFormValid,
    onDynamicFormPresubmitCallback,
    onDynamicFormSubmitCallback,
    dynamicValues,
    appliedMetainfo: {
      prefillSubject,
      requiredFields: explicitlyRequiredFields,
      hiddenFields: hiddenStaticFields,
      relabelledFields: relabelledStaticFields,
      isDeadEnd,
      allowedAttachmentTypes
    }
  } = useDynamicFormMain({
    groupName: multiplex([
      'service_requests',
      [caseOrigin === 'corporate-product', 'service_requests'],
      [caseOrigin === 'order-inquiry', 'service_requests_order_inquiry'],
      [caseOrigin === 'billing-info-mass-change', 'service_requests_billing_info_mass_change'],
      [activeGroupType === 'b2b_b2o', 'service_requests_b2o']
    ]),
    formStateRef: dynamicFormStateRef
  });

  const dynamicContent = onDynamicFormSubmitCallback();
  const { isWorkOrder, productId } = mapNewRequestDynamicProductId({
    products,
    selectedProduct: dynamicContent.product
  });

  const {
    subscriptionIdInputModel,
    invoiceNumberInputModel,
    subjectInputModel,
    relatedCompanyInputModel,
    contactMethodsInputModel,
    attachmentsModel,
    isValid
  } = useNewRequestForm({
    fields: requestFields,
    initialData: staticFormStateRef.current,
    activeGroupType,
    allowedAttachmentTypes,
    t
  });

  useEffect(() => {
    const isContactMethodRequired = isWorkOrder;
    if (contactMethodsInputModel.required !== isContactMethodRequired) {
      contactMethodsInputModel.setRequired(isContactMethodRequired);
      contactMethodsInputModel.setValue(isContactMethodRequired ? 'email' : '');
    }
  }, [isWorkOrder, contactMethodsInputModel]);

  useEffect(() => {
    const isSubscriptionNumberRequired = explicitlyRequiredFields.includes('subscriptionNumber');
    if (subscriptionIdInputModel.required !== isSubscriptionNumberRequired) {
      subscriptionIdInputModel.setRequired(isSubscriptionNumberRequired);
    }
  }, [explicitlyRequiredFields, subscriptionIdInputModel]);

  useEffect(() => {
    const isInvoiceNumberRequired = explicitlyRequiredFields.includes('invoiceNumber');
    if (invoiceNumberInputModel.required !== isInvoiceNumberRequired) {
      invoiceNumberInputModel.setRequired(isInvoiceNumberRequired);
    }
  }, [explicitlyRequiredFields, invoiceNumberInputModel]);

  useEffect(() => {
    const isAttachmentRequired = explicitlyRequiredFields.includes('attachments');
    if (attachmentsModel.required !== isAttachmentRequired) {
      attachmentsModel.setRequired(isAttachmentRequired);
    }
  }, [explicitlyRequiredFields, attachmentsModel]);

  useEffect(() => {
    const isSubscriptionNumberHidden = hiddenStaticFields.includes('subscriptionNumber');
    if (subscriptionIdInputModel.hidden !== isSubscriptionNumberHidden) {
      subscriptionIdInputModel.setHidden(isSubscriptionNumberHidden);
    }
  }, [hiddenStaticFields, subscriptionIdInputModel]);

  useEffect(() => {
    const isInvoiceNumberHidden = hiddenStaticFields.includes('invoiceNumber');
    if (invoiceNumberInputModel.hidden !== isInvoiceNumberHidden) {
      invoiceNumberInputModel.setHidden(isInvoiceNumberHidden);
    }
  }, [hiddenStaticFields, invoiceNumberInputModel]);

  const getChangedValues = useCallback(
    () => ({
      company: getChangedValue(relatedCompanyInputModel),
      subject: getChangedValue(subjectInputModel),
      subscriptionNumber: getChangedValue(subscriptionIdInputModel),
      invoiceNumber: getChangedValue(invoiceNumberInputModel),
      contactMethods: getChangedValue(contactMethodsInputModel),
      attachments: attachmentsModel.attachments
    }),
    [
      relatedCompanyInputModel,
      subjectInputModel,
      subscriptionIdInputModel,
      invoiceNumberInputModel,
      contactMethodsInputModel,
      attachmentsModel
    ]
  );

  const formRef = useRef<HTMLDivElement>(null);
  const checkForm = () => {
    const invalidDynamicFormFields = onDynamicFormPresubmitCallback();
    const invalidFields: string[] = [...invalidDynamicFormFields];

    [
      { name: 'company', model: relatedCompanyInputModel },
      { name: 'subject', model: subjectInputModel },
      { name: 'subscriptionId', model: subscriptionIdInputModel },
      { name: 'invoiceId', model: invoiceNumberInputModel },
      { name: 'contactMethods', model: contactMethodsInputModel }
    ]
      .filter(({ model }) => !model.isValid)
      .forEach(({ name: fieldName, model }) => {
        model.setValue(model.value, true);
        invalidFields.push(fieldName);
      });

    if (!attachmentsModel.isValid) {
      attachmentsModel.setTouched(true);
      invalidFields.push('attachments');
    }

    if (invalidFields.length > 0) {
      const topmostField = invalidFields[0];
      scrollElementIntoView({
        elementRef: formRef,
        targetQuery: `[data-name="${topmostField}"] input, [name="${topmostField}"], [data-name="${topmostField}"]`,
        showHighlight: true
      });
    }
  };

  const overrideRecordRelatedRequestParams = useCallback(
    (request: CreateServiceRequestData) =>
      relatedRecord?.type === 'corporate-product'
        ? relatedRecord?.overrideRequestParams(request)
        : request,
    [relatedRecord]
  );

  const createRequest = () => {
    if (!(isValid && isDynamicFormValid)) {
      checkForm();
      return;
    }

    const requestData: CreateServiceRequestData = overrideRecordRelatedRequestParams({
      description: dynamicContent.description ?? '',
      requestedStartDate: '',
      name,
      firstName,
      lastName,
      email,
      phone: businessPhoneNumber,
      orderId: caseOrigin === 'order-inquiry' ? (relatedRecord as OrderRecord).id : '',
      productId,
      productCategory: dynamicContent.productCategory,
      isWorkOrder,
      metainfo: {
        claudia: {
          ...dynamicContent.state?.form.metainfo?.claudia,
          ...dynamicContent.metainfoOverride?.claudia
        }
      },
      subject: subjectInputModel.value,
      subscriptionNumber: !subscriptionIdInputModel.hidden ? subscriptionIdInputModel.value : '',
      invoiceNumber: !invoiceNumberInputModel.hidden ? invoiceNumberInputModel.value : '',
      company: relatedCompanyInputModel.value,
      contactMethods: contactMethodsInputModel.value,
      attachments: attachmentsModel.attachments
    });

    dynamicFormStateRef.current = dynamicContent.state;
    staticFormStateRef.current = requestData;
    trackEvent(messages.submitServiceRequest);
    createServiceRequest(requestData);
  };

  useEffect(() => {
    setConfirmationModal({ ...getChangedValues(), ...dynamicValues });
  }, [getChangedValues, setConfirmationModal, dynamicValues]);

  const prevPrefilledSubject = useRef('');
  useEffect(() => {
    if (prefillSubject.type === 'none') {
      if (staticSubjectPrefill && !prevPrefilledSubject.current) {
        subjectInputModel.setValue(staticSubjectPrefill);
        prevPrefilledSubject.current = staticSubjectPrefill;
      } else if (!staticSubjectPrefill && prevPrefilledSubject.current) {
        subjectInputModel.setValue('');
        prevPrefilledSubject.current = '';
      }
      return;
    }

    if (prefillSubject.type === 'field') {
      const subjectValue = (dynamicValues[prefillSubject.from] as string) ?? '';
      if (subjectInputModel.value === '' || subjectInputModel.value !== subjectValue) {
        const newSubject = subjectValue;
        subjectInputModel.setValue(newSubject);
        prevPrefilledSubject.current = newSubject;
      }
    } else if (prefillSubject.type === 'exact') {
      const newSubject = prefillSubject.value;
      subjectInputModel.setValue(newSubject);
      prevPrefilledSubject.current = newSubject;
    }
  }, [dynamicValues, prefillSubject, staticSubjectPrefill, subjectInputModel]);

  const isValidForm = !isDeadEnd && isValid && attachmentsModel.isValid && isDynamicFormValid;

  return (
    <DataPanel data-testid="form-new-request">
      <DetailsLoadingOverlay showLoader={saving === 'yes'}>
        <Div refElement={formRef}>
          <Forms.RequiredFieldsLabel margin={{ bottom: 'sm' }} float />

          {dynamicForm}

          <Gutter size="lg" />

          {!isDeadEnd && (
            <>
              <Heading.H4>{t('serviceRequests.newServiceRequest.detailsGroup')}</Heading.H4>

              <Gutter size="sm" />

              <ModelDropdown
                data-testid="company-input"
                label={t('serviceRequests.newServiceRequest.relatedCompany')}
                name="company"
                inputModel={relatedCompanyInputModel}
                disabled={relatedCompanyInputModel.disabled}
              />

              <Gutter size="sm" />

              <ModelTextInput
                data-testid="subject-input"
                label={t('serviceRequests.newServiceRequest.subject')}
                name="subject"
                inputModel={subjectInputModel}
                readOnly={prefillSubject.type !== 'none'}
              />

              {prefillSubject.type !== 'none' && (
                <InlineNotificationLite
                  compact
                  variant="info"
                  messageText={t('serviceRequests.newServiceRequest.subjectPrefilled')}
                />
              )}

              <Gutter size="sm" />

              {!subscriptionIdInputModel.hidden && (
                <>
                  <ModelTextInput
                    data-testid="subscription-id-input"
                    label={
                      relabelledStaticFields.find((field) => field.name === 'subscriptionNumber')
                        ?.label ??
                      (activeGroupType === 'b2b_b2o'
                        ? t('serviceRequests.details.salesportalId')
                        : t('serviceRequests.details.subscriptionNumber'))
                    }
                    name="subscriptionId"
                    inputModel={subscriptionIdInputModel}
                    disabled={subscriptionIdInputModel.disabled}
                  />

                  {activeGroupType === 'b2b_b2o' &&
                    !relabelledStaticFields.some(
                      (field) => field.name === 'subscriptionNumber'
                    ) && (
                      <Forms.FieldHelperText
                        data-testid="subscription-id-tooltip"
                        helperText={t('serviceRequests.details.salesportalIdTooltip')}
                      />
                    )}

                  <Gutter size="sm" />
                </>
              )}

              {!invoiceNumberInputModel.hidden && (
                <>
                  <ModelTextInput
                    data-testid="invoice-id-input"
                    label={
                      relabelledStaticFields.find((field) => field.name === 'invoiceNumber')
                        ?.label ?? t('serviceRequests.details.invoiceNumber')
                    }
                    name="invoiceId"
                    inputModel={invoiceNumberInputModel}
                    disabled={invoiceNumberInputModel.disabled}
                  />
                  <Gutter size="sm" />
                </>
              )}

              {isWorkOrder && (
                <>
                  <ModelMultiselect
                    data-name="contactMethods"
                    label={t('tickets.contactMethod.label')}
                    inputModel={contactMethodsInputModel}
                  />

                  <Gutter size="sm" />
                </>
              )}

              <TicketFileUploader
                supportedFileTypesForMessages={
                  allowedAttachmentTypes.length > 0 ? allowedAttachmentTypes : SUPPORTED_FILE_TYPES
                }
                withCustomFileTypes={allowedAttachmentTypes.length > 0}
                attachmentsModel={attachmentsModel}
              />

              {saving === 'failure' && (
                <>
                  <InlineNotification
                    variant="error"
                    title={t('serviceRequests.newServiceRequest.error.title')}
                    content={t('serviceRequests.newServiceRequest.error.description')}
                    onClose={savingReset}
                  />

                  <Gutter />
                </>
              )}
            </>
          )}

          <ActionButtonGroup>
            <Button
              data-testid="send-service-request"
              data-valid={isValidForm}
              disabled={isDeadEnd || saving === 'yes'}
              onClick={createRequest}
              variant="primary"
              type="submit"
              size="sm"
            >
              {t('serviceRequests.newServiceRequest.send')}

              <Icon
                name={isValidForm ? 'check-circle' : 'error'}
                size="md"
                data-testid="submit-state-icon"
              />
            </Button>

            <Button
              data-testid="cancel-service-request"
              disabled={saving === 'yes'}
              onClick={() => onClick('/service-requests')}
              variant="tertiary-purple"
              type="button"
              size="sm"
            >
              {t('common.cancelButton.label')}
            </Button>
          </ActionButtonGroup>
        </Div>
      </DetailsLoadingOverlay>
    </DataPanel>
  );
};

export default NewRequestFormDynamic;
