import { multiplex } from 'doings/multiplex/multiplex';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export type TextInputModel = {
  value: string;
  setValue: (value: string, touchExplicitly?: boolean) => void;
  required: boolean;
  setRequired: (value: boolean) => void;
  initialValue: string;
  showConfirmation: boolean;
  isValid: boolean;
  maxLength?: number;
  errorMessage?: string;
  disabled: boolean;
  setDisabled: (value: boolean) => void;
  hidden: boolean;
  setHidden: (value: boolean) => void;
};

const isValidField = (value: string, required: boolean, maxLength?: number) => {
  const isRequiredValid = !required || !!value;
  const isMaxLengthValid = !maxLength || value.length <= maxLength;
  return isRequiredValid && isMaxLengthValid;
};

export default ({
  initialValue = '',
  required: isRequired = false,
  maxLength: initialMaxLength = 0,
  disabled: isDisabled = false,
  hidden: isHidden = false,
  onChange
}: {
  initialValue?: string;
  required?: boolean;
  maxLength?: number;
  disabled?: boolean;
  hidden?: boolean;
  onChange?: (value: string) => void;
} = {}): TextInputModel => {
  const { t } = useTranslation();

  const [touched, setTouched] = useState<boolean>(false);
  const [value, setValue] = useState<string>(initialValue ?? '');
  const [maxLength] = useState<number>(initialMaxLength);
  const [required, setRequired] = useState<boolean>(isRequired);
  const [disabled, setDisabled] = useState<boolean>(isDisabled);
  const [hidden, setHidden] = useState<boolean>(isHidden);
  const isValid = isValidField(value, required, maxLength);
  const errorMessage = multiplex([
    '',
    [!!maxLength && value.length > maxLength, t('validators.valueIsTooLong', { max: maxLength })],
    [touched && required && !value, t('validators.valueIsRequired')]
  ]);

  return {
    value,
    setValue: (newValue: string, touchExplicitly?: boolean) => {
      if (value === newValue) {
        if (!touched && touchExplicitly) {
          setTouched(true);
        }
        return;
      }
      setValue(newValue);
      if (!touched) {
        setTouched(true);
      }
      if (onChange && isValidField(newValue, required, maxLength)) {
        onChange(newValue);
      }
    },
    required,
    setRequired,
    initialValue,
    isValid,
    showConfirmation: value.length > 0 && isValid,
    errorMessage,
    maxLength,
    disabled,
    setDisabled,
    hidden,
    setHidden
  };
};
