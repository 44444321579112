import getEnvironmentVariable from 'doings/getEnvironmentVariable/getEnvironmentVariable';
import { joinURLSearchParams } from 'doings/links/joinURLSearchParams/joinURLSearchParams';
import joinUrl from 'url-join';

export type Args = Record<string, string | boolean | undefined>;

const createCorporatePortalLink = (path: string, args?: Args, originatorParams?: Args) => {
  const targetUrl = joinUrl([getEnvironmentVariable('REACT_APP_CORPORATE_PORTAL_URL'), path]);
  const queryDelimiter = targetUrl.includes('?') ? '&' : '?';
  const queryParams = joinURLSearchParams(
    queryDelimiter,
    { originator: getOriginator(originatorParams), ...args },
    ['originator']
  );
  return `${targetUrl}${queryParams}`;
};

export const createCorporatePortalLinkWithFragment = (
  path: string,
  fragment: string,
  args?: Args,
  originatorParams?: Args
) => {
  return `${createCorporatePortalLink(path, args, originatorParams)}#${fragment}`;
};

const getOriginator = (originatorParams?: Args) => {
  const innatelySentParams = getSentParams();
  const parameterSeparator = innatelySentParams ? '&' : '?';
  return [
    window.location.pathname ?? encodeURIComponent('/'),
    encodeURIComponent(innatelySentParams),
    encodeURIComponent(originatorParams ? parameterSeparator : ''),
    encodeURIComponent(joinURLSearchParams('', originatorParams))
  ].join('');
};

const getSentParams = () => {
  const params = new URLSearchParams(window.location.search);
  params.delete('incoming');
  params.delete('selectedCompanyId');
  params.delete('section');
  const paramsString = params.toString();
  return paramsString ? `?${paramsString}` : '';
};

export default createCorporatePortalLink;
