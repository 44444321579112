import { gql } from '@apollo/client';
import useCallBackendMutation from 'hooks/useCallBackend/useCallBackendMutation';

import { queryActiveTours } from './useActiveTours';

type MarkTourAsSeenResult = { markTourAsSeen: boolean };

export const markTourAsSeen = gql`
  mutation MarkTourAsSeen($tourId: String!) {
    markTourAsSeen(tourId: $tourId)
  }
`;

export const useMarkTourAsSeenMutation = () => {
  const { runMutation, data, loading, error } = useCallBackendMutation<MarkTourAsSeenResult>(
    markTourAsSeen,
    (args) => {
      return args.data.markTourAsSeen ? [{ query: queryActiveTours }] : [];
    }
  );

  return { markTourAsSeen: runMutation, data, loading, error };
};
