import { InlineNotification } from 'common-components';
import formatDatetime from 'doings/formatDatetime/formatDatetime';

export const FixedTermContractNotice: React.FC<{ fixedTermContractEndDate: string }> = ({
  fixedTermContractEndDate
}) => (
  <InlineNotification
    title={{ key: 'subscriptions.subscriptionDetails.status.fixedTermContractTitle' }}
    content={{
      key: 'subscriptions.subscriptionDetails.status.fixedTermContractMessage',
      args: { fixedTermContractEndDate: formatDatetime(fixedTermContractEndDate) }
    }}
    variant="information"
    data-testid="fixed-term-contract-notification"
  />
);
