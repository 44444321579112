import useBillMillAccountForm, {
  UseBillMillAccountFormResult
} from 'B2XApp/Invoicing/BillingGroups/BillMillAccountForm/useBillMillAccountForm';
import useUpdateBillMillAccount from 'hooks/invoicing/billingGroups/useUpdateBillMillAccount/useUpdateBillMillAccount';
import { useConfirmationModal } from 'hooks/useCustomModal/useConfirmationModal';
import useSearchParams from 'hooks/useSearchParams/useSearchParams';
import { BillMillBillingAccount } from 'types/billMillAccount';
import { AvailableBank, AvailableOperator } from 'types/collection-group';

import useEditBillMillAccountSteps from './useEditBillMillAccountSteps';

export type EditBillMillAccountPrerequisites = {
  billMillAccount: BillMillBillingAccount;
  availableBanks: AvailableBank[];
  availableOperators: AvailableOperator[];
};

const useEditBillMillAccountForm = ({
  billMillAccount,
  availableBanks,
  availableOperators
}: EditBillMillAccountPrerequisites): UseBillMillAccountFormResult => {
  const { onClick } = useConfirmationModal();
  const { preserveBackPathState } = useSearchParams();

  const { updateBillMillAccount, updateBillMillAccountApiStatus } = useUpdateBillMillAccount(
    billMillAccount.accountId
  );

  const { step, setStep } = useEditBillMillAccountSteps({ updateBillMillAccountApiStatus });

  const { fields, finvoiceFields, isValid, marshallRequestData } = useBillMillAccountForm({
    billMillAccount,
    availableBanks,
    availableOperators,
    finvoiceAvailable: true
  });

  return {
    form: {
      step,
      setStep,
      fields,
      finvoiceFields,
      finvoiceAvailable: true,
      isValid,
      onSave: () => {
        if (isValid) {
          updateBillMillAccount({
            variables: {
              accountId: billMillAccount.accountId,
              requestData: marshallRequestData()
            }
          });
        }
      },
      onCancel: () => {
        onClick(preserveBackPathState(`/billing-accounts/${billMillAccount.accountId}`, true));
      }
    }
  };
};

export default useEditBillMillAccountForm;
