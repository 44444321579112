import { IndicatorStatusDefinition, StatusTag } from 'common-components';
import { CaseStatus } from 'types/companyCaseList';

export const messageStatusMap: { [K in CaseStatus]: IndicatorStatusDefinition } = {
  new: {
    i18nKey: 'messageArchive.status.new',
    variant: 'information'
  },
  inProgress: {
    i18nKey: 'messageArchive.status.inProgress',
    variant: 'information'
  },
  waitingForCustomer: {
    i18nKey: 'messageArchive.status.waitingForCustomer',
    variant: 'warning'
  },
  closed: {
    i18nKey: 'messageArchive.status.closed',
    variant: 'neutral',
    icon: 'close-circle'
  },
  cancelled: {
    i18nKey: 'messageArchive.status.cancelled',
    variant: 'neutral',
    icon: 'close-circle'
  }
};

const statusDefaultValue: IndicatorStatusDefinition = {
  i18nKey: 'messageArchive.status.other',
  variant: 'neutral',
  icon: 'question'
};

const MessageStatusTag: React.FC<{
  status: CaseStatus;
  bold?: boolean;
}> = ({ status, bold }) => (
  <StatusTag status={messageStatusMap[status] || statusDefaultValue} bold={bold} />
);

export default MessageStatusTag;
