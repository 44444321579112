import { Div, Flex, Gutter, MarginSpace } from '@gaiads/telia-react-component-library';
import useIsPathMatch from 'hooks/useIsPathMatch/useIsPathMatch';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationItem } from 'types/navigation';

import styles from './Subnavigation.module.scss';
import SubnavigationItem from './SubnavigationItem/SubnavigationItem';

const Subnavigation: React.FC<{
  navigationItems: NavigationItem[];
}> = ({ navigationItems }) => {
  const isPathMatch = useIsPathMatch();
  const { t } = useTranslation();

  return (
    <Div className={styles.subnavigation} backgroundColor="white" boxShadow="sm">
      <MarginSpace>
        <Flex occupyVerticalSpace centeredVertically>
          {navigationItems
            .filter(({ isAvailable = true }) => isAvailable)
            .map(({ id, labelI18nKey, isAvailable = true, trackForTopNavigation, ...rest }) => (
              <React.Fragment key={id}>
                <SubnavigationItem
                  itemId={id}
                  label={t(labelI18nKey)}
                  isPathMatch={isPathMatch}
                  analyticsEvent={trackForTopNavigation}
                  {...rest}
                />

                <Gutter />
              </React.Fragment>
            ))}
        </Flex>
      </MarginSpace>
    </Div>
  );
};

export default Subnavigation;
