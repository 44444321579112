import { pukCodeSearch } from 'doings/track/analyticsEvents';
import trackEvent from 'doings/track/trackEvent';
import subscriptionsPukCodeSearchQuery, {
  SubscriptionPukCodeSearchResponse
} from 'hooks/mobileSubscriptions/useSubscriptionPukCodeSearch/subscriptionsPukCodeSearchQuery';
import useApiStatus, { ViewStatus } from 'hooks/useApiStatus/useApiStatus';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import useDebounce from 'hooks/useDebounce/useDebounce';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SortParameter } from 'types/sort';

import mapSubscriptionPukCodeSearch from './mapSubscriptionPukCodeSearch';

export type SubscriptionsPukCodeQueryParameter = {
  msisdn?: string;
  userInfo?: string;
};

const DEBOUNCE_DELAY: Time.Milliseconds = 250;

const useSubscriptionPukCodeSearch = (
  sortParameter: SortParameter,
  query: SubscriptionsPukCodeQueryParameter,
  skip = false
) => {
  const debouncedQuery = useDebounce(query, DEBOUNCE_DELAY);
  const { data, error, loading, loadingMore, loaded } =
    useCallBackend<SubscriptionPukCodeSearchResponse>({
      query: subscriptionsPukCodeSearchQuery,
      queryVariables: {
        query: debouncedQuery,
        offset: 0,
        pageSize: 20,
        ...sortParameter
      },
      skip
    });

  const { t } = useTranslation();

  const emptyUsernamePlaceholder = t('subscriptions.noUserName');
  const receivedSubscriptions = data?.subscriptionsPukCodeSearch.items ?? [];
  const mappedSubscriptions = receivedSubscriptions.map(
    mapSubscriptionPukCodeSearch(emptyUsernamePlaceholder)
  );

  const apiStatus = useApiStatus({
    loading,
    error,
    loadingMore,
    loaded,
    items: mappedSubscriptions,
    query
  });

  React.useEffect(() => {
    if (!skip && !loading) {
      if (data?.subscriptionsPukCodeSearch?.total) {
        trackEvent(pukCodeSearch.subscriptionsFound);
      } else {
        trackEvent(pukCodeSearch.noResultsFound);
      }
    }
  }, [loading, data, skip]);

  return !skip
    ? {
        subscriptions: {
          total: data?.subscriptionsPukCodeSearch.total ?? 0,
          items: mappedSubscriptions
        },
        error,
        loading,
        apiStatus
      }
    : {
        subscriptions: {
          total: 0,
          items: []
        },
        error: undefined,
        loading: false,
        apiStatus: ViewStatus.Loaded
      };
};

export default useSubscriptionPukCodeSearch;
