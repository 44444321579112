import { useState } from 'react';
import { v4 as uuid } from 'uuid';

/**
 * Generates a sufficiently random identifier for components which
 * require an ID but don't necessarily need the ID passed or exposed
 * via props. Use for, e.g., input fields which require an ID for the
 * sole purpose of linking their appropriate label to the input field
 * for accessibility.
 *
 * Based on accessibility best practices, when a user clicks on a
 * field's label, the associated input field should be focused, only
 * possible if the label is sufficiently connected to its input field.
 *
 * Could possibly be replaced or substituted with React 18 `useId`
 * hook.
 *
 * @param prefix Text to prefix the generated ID with.
 * @returns A stable generated ID.
 */
export const useGenerateId = (prefix: string) => {
  const [generatedId] = useState(() => `${prefix}-${uuid()}`);
  return { generatedId };
};
