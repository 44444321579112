import { BodyText, Flex } from '@gaiads/telia-react-component-library';
import { IconButton } from 'common-components/IconButton/IconButton';
import copy from 'copy-to-clipboard';
import { useTimeoutedState } from 'hooks/useTimeoutedState/useTimeoutedState';
import { useTranslation } from 'react-i18next';

import styles from './CopyButton.module.scss';

const COPIED_TEXT_DISPLAY_TIME: Time.Milliseconds = 3000;

const CopyButton: React.FC<{ value: string; onCopy?: VoidFunction }> = ({ value, onCopy }) => {
  const [copied, setCopied] = useTimeoutedState<boolean>(false, COPIED_TEXT_DISPLAY_TIME);
  const { t } = useTranslation();

  const doCopy = (value: string) => {
    copy(value);
    setCopied(true);
    if (onCopy) {
      onCopy();
    }
  };

  return (
    <Flex>
      <IconButton
        data-testid={copied ? 'icon-copied' : 'icon-copy'}
        aria-label={t('common.copyButton.label')}
        className={[styles.copyButton, { [styles.copyButton__copied]: copied }]}
        name={copied ? 'check-circle' : 'copy'}
        size="sm"
        onClick={() => (copied ? {} : doCopy(value))}
      />

      {copied && (
        <BodyText
          tagName="span"
          className={styles.copiedLabel}
          size="xs"
          aria-live="polite"
          data-testid="text-copied"
        >
          {t('common.copyButton.copied')}
        </BodyText>
      )}
    </Flex>
  );
};

export default CopyButton;
