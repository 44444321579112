import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AnalyticsEvent } from 'doings/track/analyticsEvents';

export interface InfoModalState {
  isModalOpen: boolean;
  title?: string;
  text?: string;
  ctaText?: string;
  link?: string;
  whenClicked?: AnalyticsEvent;
}

const initialState: InfoModalState = {
  isModalOpen: false,
  title: undefined,
  text: undefined,
  link: undefined,
  whenClicked: undefined
};

interface ShowInfoModalPayload {
  text: string;
  title?: string;
  link?: string;
  ctaText?: string;
  whenClicked?: AnalyticsEvent;
}

export const infoModalSlice = createSlice({
  name: 'infoModal',
  initialState,
  reducers: {
    showInfoModal: (state, action: PayloadAction<ShowInfoModalPayload>) => {
      state.title = action.payload.title;
      state.text = action.payload.text;
      state.ctaText = action.payload.ctaText;
      state.link = action.payload.link;
      state.whenClicked = action.payload.whenClicked;
      state.isModalOpen = true;
    },
    hideInfoModal: (state) => {
      state.title = undefined;
      state.text = undefined;
      state.link = undefined;
      state.ctaText = undefined;
      state.whenClicked = undefined;
      state.isModalOpen = false;
    }
  }
});

export const { showInfoModal, hideInfoModal } = infoModalSlice.actions;

export default infoModalSlice.reducer;
