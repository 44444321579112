import { Gutter } from '@gaiads/telia-react-component-library';
import { Repeat, Skeleton } from 'common-components';

const MAX_NUMBER_OF_LOADERS = 3;

const DashboardNotificationsLoader: React.FC<{ loadingItems: number }> = ({ loadingItems }) => {
  return (
    <Repeat times={Math.min(MAX_NUMBER_OF_LOADERS, loadingItems)}>
      <Skeleton.TextRow />

      <Gutter size="xs" />
    </Repeat>
  );
};

export default DashboardNotificationsLoader;
