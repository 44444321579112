import { ApolloError, gql } from '@apollo/client';
import { multiplex } from 'doings/multiplex/multiplex';
import { billingGroupsQuery } from 'hooks/invoicing/billingGroups/useBillingGroups/useBillingGroups';
import useCallBackendMutation from 'hooks/useCallBackend/useCallBackendMutation';
import { CollectionGroupApiStatus, CollectionGroupHandlingType } from 'types/collection-group';

export const createCollectionGroupMutation = gql`
  mutation CreateBillingAccount($requestData: InputCollectionGroupRequest!) {
    createCollectionGroup(requestData: $requestData) {
      companyId
      collectionGroupId
      handlingType
    }
  }
`;

const isAutomaticHandling = (createCollectionGroup: CreateCollectionGroupResult) =>
  createCollectionGroup.handlingType === CollectionGroupHandlingType.Automatic;

const mapApiStatus = (
  loading: boolean,
  data?: { createCollectionGroup: CreateCollectionGroupResult },
  error?: ApolloError
): CollectionGroupApiStatus => {
  return multiplex([
    'initial',
    [!!loading, 'persisting'],
    [!!error, 'failure'],
    [!!data && isAutomaticHandling(data.createCollectionGroup), 'success'],
    [!!data && !isAutomaticHandling(data.createCollectionGroup), 'partial-success']
  ]);
};

interface CreateCollectionGroupResult {
  handlingType: CollectionGroupHandlingType;
  companyId: string;
  collectionGroupId: string;
}

interface UseCreateCollectionGroupResult {
  createCollectionGroup: CreateCollectionGroupResult;
}

export default () => {
  const { runMutation, loading, data, error } =
    useCallBackendMutation<UseCreateCollectionGroupResult>(createCollectionGroupMutation, [
      {
        query: billingGroupsQuery,
        variables: {
          requestParams: {}
        }
      }
    ]);

  return {
    createCollectionGroup: runMutation,
    createCollectionGroupApiStatus: mapApiStatus(loading, data, error),
    newCollectionGroupCompanyId: data?.createCollectionGroup?.companyId,
    newCollectionGroupId: data?.createCollectionGroup?.collectionGroupId
  };
};
