import { GridBag, Gutter } from '@gaiads/telia-react-component-library';
import { Heading } from 'common-components';
import ModelTextInput from 'common-components/ModelTextInput/ModelTextInput';
import { useTranslation } from 'react-i18next';

import { BillMillAccountFormFields } from './useBillMillAccountForm';

const BillMillAccountFormPayerInfo: React.FC<{
  fields: BillMillAccountFormFields;
  disallowMutation: boolean;
}> = ({ fields, disallowMutation }) => {
  const { t } = useTranslation();

  return (
    <>
      <Heading.H4>{t('invoices.billingGroup.form.payerInfo.title')}</Heading.H4>

      <Gutter size="sm" />

      <GridBag>
        <GridBag.Item md={12}>
          <ModelTextInput
            data-testid="billing-account-form__payer-name"
            label={t('invoices.billingGroup.form.payerInfo.payerNameLabel')}
            inputModel={fields.payerName}
            disabled={disallowMutation}
            hardMaxLength
          />
        </GridBag.Item>

        <GridBag.Item md={12}>
          <ModelTextInput
            data-testid="billing-account-form__reference"
            label={t('invoices.billingGroup.form.payerInfo.nameSpecifier')}
            inputModel={fields.reference}
            disabled={disallowMutation}
            hardMaxLength
          />
        </GridBag.Item>

        <GridBag.Item md={12}>
          <ModelTextInput
            data-testid="billing-account-form__address"
            label={t('invoices.billingGroup.form.payerInfo.streetAddressLabel')}
            inputModel={fields.address}
            disabled={disallowMutation}
            hardMaxLength
          />
        </GridBag.Item>

        <GridBag.Item md={12}>
          <ModelTextInput
            data-testid="billing-account-form__address-supplement"
            label={t('invoices.billingGroup.form.payerInfo.addressSupplementLabel')}
            inputModel={fields.addressSupplement}
            disabled={disallowMutation}
            hardMaxLength
          />
        </GridBag.Item>

        <GridBag.Item xs={12} sm={5} md={5}>
          <ModelTextInput
            data-testid="billing-account-form__address-zip"
            label={t('invoices.billingGroup.form.payerInfo.postalCodeLabel')}
            inputModel={fields.postalCode}
            disabled={disallowMutation}
            hardMaxLength
          />
        </GridBag.Item>

        <GridBag.Item xs={12} sm={7} md={7}>
          <ModelTextInput
            data-testid="billing-account-form__address-city"
            label={t('invoices.billingGroup.form.payerInfo.postalOfficeLabel')}
            inputModel={fields.postalOffice}
            disabled={disallowMutation}
            hardMaxLength
          />
        </GridBag.Item>
      </GridBag>
    </>
  );
};

export default BillMillAccountFormPayerInfo;
