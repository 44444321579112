import PendingChangesGuardModal from 'B2XApp/Modals/PendingChangesGuardModal/PendingChangesGuardModal';
import usePendingChangesGuard from 'B2XApp/Modals/PendingChangesGuardModal/usePendingChangesGuard';
import { InlineNotification, ModalDrawer } from 'common-components';
import { SortedChecklistItem } from 'common-components/SortableCheckboxes/SortableCheckboxesProvider';
import { dashboardLinks } from 'doings/track/analyticsEvents';
import trackEvent from 'doings/track/trackEvent';
import {
  EditableSection,
  EditableSectionItem
} from 'hooks/editableSections/useEditableSectionItems';
import useResetEditableSectionItems from 'hooks/editableSections/useResetEditableSectionItems';
import useUpdateEditableSectionItems from 'hooks/editableSections/useUpdateEditableSectionItems';
import { isEqual } from 'lodash/fp';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EditableLinksModalContent } from './EditableLinksModalContent';

export const TOP_TILES_MODAL = 'TOP_TILES_MODAL';
export const QUICK_LINKS_MODAL = 'QUICK_LINKS_MODAL';
export const OTHER_SERVICES_MODAL = 'OTHER_SERVICES_MODAL';

const TOP_TILE_COUNT = 4;

const SECTION_VARIANTS: Record<EditableSection, SectionVariant> = {
  'top-tiles': {
    requiredItems: TOP_TILE_COUNT,
    requiredItemsSelected: (items) =>
      items.reduce((acc, { enabled }) => (enabled ? ++acc : acc), 0) === TOP_TILE_COUNT
  },
  'quick-links': {
    requiredItems: false,
    requiredItemsSelected: () => true
  },
  'other-services': {
    requiredItems: false,
    requiredItemsSelected: () => true
  }
};

type SectionVariant = {
  requiredItems: number | false;
  requiredItemsSelected: (items: EditableSectionItem[]) => boolean;
};

export const EditableLinksModal: React.FC<{
  section: EditableSection;
  items: SortedChecklistItem[];
  isOpen: boolean;
  onCloseClick: VoidFunction;
}> = ({ section, items, isOpen, onCloseClick }) => {
  const { t } = useTranslation();
  const { hasChanges, showConfirmation, closeConfirmation, onClose } =
    usePendingChangesGuard(onCloseClick);
  const { updateSectionItems, updating } = useUpdateEditableSectionItems(section);
  const { resetSectionItems, resetting } = useResetEditableSectionItems(section);
  const [tempItems, setTempItems] = useState<SortedChecklistItem[]>(items);

  const variant = SECTION_VARIANTS[section];
  const requiredItems = variant.requiredItems;
  const requiredItemsSelected = variant.requiredItemsSelected(tempItems);

  useEffect(() => {
    hasChanges.current = !isEqual(items, tempItems);
  }, [hasChanges, items, tempItems]);

  const updateItems = () => {
    updateSectionItems(tempItems);
    onClose(false);
  };

  const resetItems = () => {
    resetSectionItems();
    onClose(false);
  };

  return (
    <>
      <ModalDrawer
        isOpen={isOpen}
        title={t('modal.editLinks.title')}
        onClose={onClose}
        data-testid="editable-links-modal"
        buttons={
          items.length
            ? {
                modalButtons: {
                  accept: {
                    label: t('modal.editLinks.button.save'),
                    onClick: () => {
                      trackEvent(dashboardLinks.saveCustomizedLinks);
                      updateItems();
                    },
                    disabled: updating || resetting || !requiredItemsSelected,
                    tooltip: {
                      label: t('modal.editLinks.tooltip.tooFewLinksSelected', {
                        count: TOP_TILE_COUNT
                      }),
                      trigger: !requiredItemsSelected
                    },
                    testIdSuffix: 'editable-links--save'
                  },
                  close: {
                    label: t('modal.editLinks.button.cancel'),
                    onClick: onClose,
                    disabled: updating || resetting,
                    testIdSuffix: 'editable-links--cancel'
                  },
                  auxiliary: {
                    label: t('modal.editLinks.button.restoreDefaults'),
                    onClick: () => {
                      trackEvent(dashboardLinks.resetCustomizedLinks);
                      resetItems();
                    },
                    disabled: updating || resetting,
                    testIdSuffix: 'editable-links--reset'
                  },
                  closeIsDefault: false
                }
              }
            : {
                modalButtons: {
                  close: {
                    label: t('common.closeButton.label'),
                    onClick: onClose
                  },
                  closeIsDefault: true
                }
              }
        }
      >
        {!items.length && (
          <InlineNotification
            variant="error"
            content={{ key: 'modal.editLinks.text.error' }}
            data-testid="edit-links-modal-error-text"
          />
        )}

        {!!items.length && (
          <EditableLinksModalContent
            section={section}
            items={items}
            setTempItems={setTempItems}
            requiredItems={requiredItems}
            busy={updating || resetting}
            data-testid="editable-links-modal-content"
          />
        )}
      </ModalDrawer>

      <PendingChangesGuardModal
        showConfirmation={showConfirmation}
        closeConfirmation={closeConfirmation}
        dismissAction={onCloseClick}
      />
    </>
  );
};
