import { Gutter } from '@gaiads/telia-react-component-library';
import { Button } from 'common-components/Button/Button';
import { useRef } from 'react';

import File from './File';
import styles from './FileUploader.module.scss';

const normalizeType = (type: string) => `.${type.toLowerCase()}`;
const typesToAttribute = (types: string[]) => types.map(normalizeType).join(',');

export type FileItemShape = {
  name: string;
  url?: string;
  onDelete?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>
  ) => void;
  loading?: boolean;
  message?: string;
};

const filesFromEvent = ({ target }: React.ChangeEvent<HTMLInputElement>) =>
  Array.from(target.files || []);

const limitItems = (list: File[], quota: number, used: number) => {
  const remainingQuota = quota - used;
  const end = remainingQuota >= 0 ? remainingQuota : 0;
  return list.slice(0, end);
};

type FileUploaderProps = {
  buttonText: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, files: FileItemShape[]) => void;
  validTypes: string[];
  maximumAmountOfFiles: number;
  files: FileItemShape[];
};

const FileUploader: React.FC<FileUploaderProps> = ({
  buttonText,
  onChange,
  files,
  maximumAmountOfFiles,
  validTypes = []
}) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const types = validTypes.length > 0 ? typesToAttribute(validTypes) : undefined;
  const allowMultipleFiles = maximumAmountOfFiles - files.length > 1;
  const maximumAmountOfFilesReached = files.length >= maximumAmountOfFiles;

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = filesFromEvent(event);
    const allowedFiles = limitItems(selectedFiles, maximumAmountOfFiles, files.length);

    onChange(event, allowedFiles);
    event.target.value = '';
  };

  return (
    <div>
      <input
        data-testid="file-uploader-hidden-input"
        className={styles.fileUploader_input__hidden}
        type="file"
        accept={types}
        ref={fileInput}
        onChange={changeHandler}
        multiple={allowMultipleFiles}
      />

      <Button
        data-testid="add-attachment-button"
        data-name="attachments"
        type="button"
        size="sm"
        variant="secondary"
        disabled={maximumAmountOfFilesReached}
        onClick={() => fileInput.current?.click()}
      >
        {buttonText}
      </Button>

      <Gutter />

      {files.map(({ name, url, onDelete, loading, message }, index: number) => (
        <File
          key={`${name}-${index}`}
          name={name}
          url={url}
          onDelete={onDelete}
          loading={loading}
          message={message}
        />
      ))}
    </div>
  );
};

export default FileUploader;
