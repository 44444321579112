import useReadQueryParams from 'hooks/useQueryParams/useReadQueryParams';
import useWriteQueryParams from 'hooks/useQueryParams/useWriteQueryParams';
import { useCallback, useState } from 'react';

/**
 * A convenience React hook for stateful boolean toggles.
 */
export function useToggleState(initialValue: boolean | (() => boolean)): [boolean, () => void] {
  const [value, setValue] = useState<boolean>(initialValue);

  const toggle = useCallback(() => {
    setValue(!value);
  }, [value, setValue]);

  return [value, toggle];
}

/**
 * A convenience React hook for stateful boolean toggles with state
 * representation in the page URL.
 */
export function useToggleStateWithQueryParameter(
  initialValue: boolean | (() => boolean),
  parameterName: string
): [boolean, () => void] {
  const queryParameterValue = useReadQueryParams([parameterName])[parameterName];

  const [toggleValue, toggleFunction] = useToggleState(
    !!queryParameterValue && queryParameterValue === 'false' ? false : initialValue
  );

  const { write: writeQueryParams } = useWriteQueryParams();

  const enhancedToggleFunction = useCallback(() => {
    const queryParams = {
      [parameterName]: toggleValue ? 'false' : 'true'
    };
    toggleFunction();
    writeQueryParams(queryParams);
  }, [writeQueryParams, toggleValue, toggleFunction, parameterName]);

  return [toggleValue, enhancedToggleFunction];
}
