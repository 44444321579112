import { ValidationCallbackFunction } from 'hooks/inputModels/useDropdownInputModel/useDropdownInputModel';
import { TextInputModel } from 'hooks/inputModels/useTextInputModel/useTextInputModel';
import useValidationHint from 'hooks/inputModels/useValidatableTextInputModel/useValidationHint';
import validate from 'hooks/inputModels/validation/validate';
import { useState } from 'react';
import { ValidationScheme } from 'types/formValidation';

export default ({
  initialValue = '',
  required: isRequired = false,
  disabled: isDisabled = false,
  showConfirmation = true,
  expecting = {
    a: undefined,
    oneOf: [],
    allOf: [],
    minLength: 0,
    maxLength: 0,
    customHint: undefined
  },
  onChange,
  onValidationStatusChanged
}: {
  initialValue?: string;
  required?: boolean;
  disabled?: boolean;
  showConfirmation?: boolean;
  expecting?: ValidationScheme;
  onChange?: (value: string) => void;
  onValidationStatusChanged?: ValidationCallbackFunction;
} = {}): TextInputModel => {
  const [value, setValue] = useState<string>(initialValue ?? '');
  const [required, setRequired] = useState<boolean>(isRequired);
  const [disabled, setDisabled] = useState<boolean>(isDisabled);
  const [hidden, setHidden] = useState<boolean>(false);

  const validationResult = validate({
    value,
    required,
    expecting
  });

  const validationMessage = useValidationHint(validationResult);
  const { isValid } = validationResult;

  return {
    initialValue,
    value,
    setValue: (value: string) => {
      setValue(value);
      const valid = validate({
        value,
        required,
        expecting
      }).isValid;

      if (onChange && valid) {
        onChange(value);
      }

      if (onValidationStatusChanged && valid !== isValid) {
        onValidationStatusChanged(valid);
      }
    },
    required,
    setRequired,
    disabled,
    setDisabled,
    hidden,
    setHidden,
    maxLength: expecting.maxLength,
    showConfirmation: value.length > 0 && isValid && showConfirmation,
    isValid,
    errorMessage: validationMessage
  };
};
