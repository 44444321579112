import { IndicatorStatusDefinition, StatusTag } from 'common-components';
import { ServiceRequestStatus, ServiceRequestStatusV1 } from 'types/serviceRequest';
import { TicketStatus as TicketStatusType } from 'types/ticket';

type TicketStatuses = ServiceRequestStatus | ServiceRequestStatusV1 | TicketStatusType;

export const ticketStatusMap: {
  [K in TicketStatuses]: IndicatorStatusDefinition;
} = {
  New: {
    i18nKey: 'tickets.status.new',
    variant: 'information'
  },
  Open: {
    i18nKey: 'tickets.status.open',
    variant: 'information'
  },
  Assigned: {
    i18nKey: 'tickets.status.assigned',
    variant: 'information'
  },
  'In Progress': {
    i18nKey: 'tickets.status.inProgress',
    variant: 'information'
  },
  'On Hold': {
    i18nKey: 'tickets.status.onHold',
    variant: 'information'
  },
  Pending: {
    i18nKey: 'tickets.status.pending',
    variant: 'warning'
  },
  'Pending customer': {
    i18nKey: 'tickets.status.waitingForInformation',
    variant: 'warning'
  },
  'Waiting for information': {
    i18nKey: 'tickets.status.waitingForInformation',
    variant: 'warning'
  },
  Resolved: {
    i18nKey: 'tickets.status.resolved',
    variant: 'success'
  },
  Cancelled: {
    i18nKey: 'tickets.status.cancelled',
    variant: 'neutral',
    icon: 'close-circle'
  },
  Completed: {
    i18nKey: 'tickets.status.completed',
    variant: 'success'
  },
  Closed: {
    i18nKey: 'tickets.status.closed',
    variant: 'neutral',
    icon: 'close-circle'
  },
  Rejected: {
    i18nKey: 'tickets.status.rejected',
    variant: 'neutral',
    icon: 'stop'
  }
};

const ticketStatusDefaultValue: IndicatorStatusDefinition = {
  i18nKey: 'tickets.status.other',
  variant: 'neutral',
  icon: 'question'
};

const TicketStatusTag: React.FC<{
  status: TicketStatuses;
}> = ({ status }) => <StatusTag status={ticketStatusMap[status] || ticketStatusDefaultValue} />;

export default TicketStatusTag;
