import { addDays, differenceInCalendarMonths, isSameDay, parse, setYear } from 'date-fns';
import { max, min } from 'lodash/fp';
import { Device } from 'types/device';

const dateFormat = 'yyyy-MM-dd';
const maxPercentage = 100;

const toPercentageInt = (number: number) => min([max([number * maxPercentage, 0]), maxPercentage]);

const getEndDate = (beginning: Date, billingEndDate: string) => {
  const end = parse(billingEndDate, dateFormat, 0);
  if (isSameDay(setYear(beginning, end.getFullYear()), end)) {
    return end;
  }
  return addDays(end, 1);
};

const getDateForContractProgressCalculation = (beginning: Date) => {
  const today = new Date();
  const todayOnBeginningYear = setYear(today, beginning.getFullYear());
  if (isSameDay(addDays(todayOnBeginningYear, 1), beginning)) {
    return addDays(today, 1);
  }
  return today;
};

export const calculateContractProgress: ({ billingBeginDate, billingEndDate }: Partial<Device>) => {
  contractLength: number;
  contractProgress: number;
} = ({ billingBeginDate, billingEndDate } = {}) => {
  if (!billingBeginDate || !billingEndDate) {
    return { contractLength: 0, contractProgress: 0 };
  }

  const beginning = parse(billingBeginDate, dateFormat, 0);
  const end = getEndDate(beginning, billingEndDate);
  const contractLength: number = differenceInCalendarMonths(end, beginning);
  const elapsedSinceBeginningOfContract = differenceInCalendarMonths(
    getDateForContractProgressCalculation(beginning),
    beginning
  );
  const contractProgress = toPercentageInt(elapsedSinceBeginningOfContract / contractLength) || 0;

  return { contractLength, contractProgress };
};
