import { isEqual } from 'lodash/fp';
import { useEffect, useReducer } from 'react';

const PAGE_SIZE = 20;

type OfflineLimit = {
  limit: number;
  deps: unknown[];
};

type OfflineLimitAction = { type: 'SHOW_MORE' } | { type: 'RESET'; deps: unknown[] };

/**
 * Provides an offline item limit for a list page which does not have online
 * pagination support (for example: billing accounts at time of writing).
 *
 * Rendering hundreds or thousands of list items can grind the browser to a near
 * halt for potentially several seconds, degrading customer experience; instead,
 * such a list page can be paginated offline without excess backend queries to
 * provide a similar experience as pages which support online pagination.
 *
 * Since changes like sorting and filtering change the items displayed on list
 * pages with online pagination, offline pagination will need to be provided
 * a dependency array of criteria which necessitate resetting the item limit,
 * e.g. sorting criteria, the list view's loading state or changes to its items.
 *
 * @param deps Any changes to the dependency array reset the number of shown items.
 * @param pageSize The items to show per offline page (default is 20 for list pages).
 * @returns The current item limit and a `showMore` function to increment it.
 */
export const useOfflineLimit = (
  deps: unknown[],
  pageSize = PAGE_SIZE
): { limit: number; showMore: VoidFunction } => {
  const [state, dispatch] = useReducer(
    (state: OfflineLimit, action: OfflineLimitAction): OfflineLimit => {
      switch (action.type) {
        case 'SHOW_MORE':
          return { ...state, limit: state.limit + pageSize };
        case 'RESET':
          return { ...state, limit: pageSize, deps: action.deps };
      }
    },
    { limit: pageSize, deps }
  );

  useEffect(() => {
    if (!isEqual(deps, state.deps)) {
      dispatch({ type: 'RESET', deps });
    }
  }, [deps, state.deps]);

  return { limit: state.limit, showMore: () => dispatch({ type: 'SHOW_MORE' }) };
};
