import { GridBag, Gutter } from '@gaiads/telia-react-component-library';
import { Contact, ContactLink } from 'common-components';
import DetailsPage from 'common-components/DetailsPage/DetailsPage';
import { mobile } from 'doings/track/analyticsEvents';
import {
  Balance,
  SubscriptionDetails,
  filterMultipleReferences,
  isDataPoolMaster,
  isDataPoolMember,
  isMultiSimMaster,
  isPotentialDataPoolMaster
} from 'hooks/mobileSubscriptions/useSubscriptionDetails/subscriptionDetailsQuery';
import {
  LoadingStatus,
  OwnershipChangeDetails
} from 'hooks/mobileSubscriptions/useSubscriptionDetails/useSubscriptionDetails';
import useRouteParams from 'hooks/useRouteParams/useRouteParams';
import { useTranslation } from 'react-i18next';
import { ServiceRequestDynamicFormType } from 'types/serviceRequest';

import SIMCard from './SIMCard/SIMCard';
import SubscriptionAsset from './SubscriptionAsset/SubscriptionAsset';
import SubscriptionMultiSimMembers from './SubscriptionMultiSimMembers/SubscriptionMultiSimMembers';
import SubscriptionNotices from './SubscriptionNotices/SubscriptionNotices';
import AdditionalSubscriptionServices from './SubscriptionServices/AdditionalSubscriptionServices';
import RestrictionServices from './SubscriptionServices/RestrictionServices';
import SubscriptionSurfCards from './SubscriptionSurfCards/SubscriptionSurfCards';
import SubscriptionUsage from './SubscriptionUsage/SubscriptionUsage';

const SubscriptionDetailsPage: React.FC<{
  subscription?: SubscriptionDetails;
  ownershipChange?: OwnershipChangeDetails;
  balance?: Balance;
  loadingStatus: LoadingStatus;
}> = ({ subscription, ownershipChange, balance, loadingStatus }) => {
  const loading = loadingStatus === 'loading';

  const { t } = useTranslation();
  const phoneNumberHash = subscription?.phoneNumberHash ?? '';
  const additionalServices = subscription?.additionalServices.map(({ code }) => code) || [];

  const params = useRouteParams(['section']);
  const isSectionExpanded = (section: string) => {
    return params?.section === section;
  };

  const isSurfCard = !!subscription && isDataPoolMember(subscription);

  return (
    <DetailsPage
      data-testid={`subscription-details-${loading ? 'loading' : 'loaded'}`}
      hasError={loadingStatus === 'error'}
      loading={loading}
      backButtonPath="/subscriptions/"
      headline={t(
        isSurfCard
          ? 'subscriptions.subscriptionDetails.surfCard'
          : 'subscriptions.subscriptionDetails.headline'
      )}
    >
      <SubscriptionNotices
        phoneNumberHash={phoneNumberHash}
        attributes={subscription?.attributes}
        additionalServices={additionalServices}
        status={subscription?.status}
        terminationDate={subscription?.terminationDate}
        ownershipChange={ownershipChange}
        fixedTermContractEndDate={subscription?.fixedTermContractEndDate}
        hasDuettoBundle={subscription?.hasDuettoBundle}
        activationDeadlineCode={subscription?.activationDeadlineCode}
        data-testid="subscription-details-subscription-notices"
      />

      <GridBag>
        <GridBag.Item md={6} sm={12}>
          <SubscriptionAsset
            subscription={subscription}
            loading={loading}
            disabled={!!ownershipChange}
          />

          <Gutter size="sm" />

          <AdditionalSubscriptionServices
            phoneNumberHash={phoneNumberHash}
            services={subscription?.additionalServices ?? []}
            status={subscription?.status}
            loading={loading}
            expanded={isSectionExpanded('services')}
            disabled={!!ownershipChange}
            data-testid="subscription-details-additional-services"
          />

          <Gutter size="sm" />

          <RestrictionServices
            phoneNumberHash={phoneNumberHash}
            services={subscription?.restrictionServices ?? []}
            status={subscription?.status}
            loading={loading}
            expanded={isSectionExpanded('restrictions')}
            disabled={isSurfCard || !!ownershipChange}
            data-testid="subscription-details-restriction-services"
          />

          <Gutter size="sm" />

          <SIMCard
            phoneNumberHash={phoneNumberHash}
            simCard={subscription?.simCard}
            status={subscription?.status}
            loading={loading}
            expanded={isSectionExpanded('simCard')}
            disabled={!!ownershipChange}
            data-testid="subscription-details-sim-card"
          />

          {subscription && isMultiSimMaster(subscription) && (
            <>
              <Gutter size="sm" />

              <SubscriptionMultiSimMembers
                phoneNumberHash={phoneNumberHash}
                members={filterMultipleReferences(subscription, 'MULTISIM_MEMBER')}
                additionalServices={additionalServices}
                status={subscription.status}
                loading={loading}
                expanded={isSectionExpanded('multiSim')}
                disabled={!!ownershipChange}
              />
            </>
          )}

          {subscription &&
            (isPotentialDataPoolMaster(subscription) || isDataPoolMaster(subscription)) && (
              <>
                <Gutter size="sm" />

                <SubscriptionSurfCards
                  phoneNumberHash={phoneNumberHash}
                  members={filterMultipleReferences(subscription, 'POOL_MEMBER')}
                  status={subscription.status}
                  loading={loading}
                  expanded={isSectionExpanded('surfCards')}
                  disabled={!!ownershipChange}
                />
              </>
            )}
        </GridBag.Item>

        <GridBag.Item md={6} sm={12}>
          <SubscriptionUsage
            balance={balance}
            phoneNumberHash={phoneNumberHash}
            status={subscription?.status}
            collectionGroup={subscription?.billing?.collectionGroup}
            loading={loading}
            allowBillingInfoEdit={!ownershipChange && !isSurfCard}
            data-testid="subscription-details-subscription-usage"
          />

          <Gutter size="sm" />

          <Contact isLoading={loading} data-testid="subscription-details-contact">
            <ContactLink.ServiceRequest
              uriParams={{ origin: 'subscription', key: phoneNumberHash }}
              analyticsEvent={mobile.createServiceRequest}
              formType={ServiceRequestDynamicFormType.CHANGES_TO_CURRENT_SERVICES}
              label={t('subscriptions.actions.contact.supportRequest')}
            />

            <ContactLink.FaultReport
              uriParams={{ origin: 'subscription', key: phoneNumberHash }}
              analyticsEvent={mobile.createTroubleTicket}
              label={t('subscriptions.actions.contact.fault')}
            />
          </Contact>
        </GridBag.Item>
      </GridBag>
    </DetailsPage>
  );
};

export default SubscriptionDetailsPage;
