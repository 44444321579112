import { BodyText } from '@gaiads/telia-react-component-library';
import { Heading as DSHeading } from '@teliafi/fi-ds';

import styles from './Heading.module.scss';

type HeadingProps = {
  'data-testid'?: string;
  id?: string;
  className?: string;
  children: React.ReactNode | string;
};

type HeadingTagType = Exclude<React.ComponentProps<typeof DSHeading>['tag'], 'h6'>;
type HeadingType = React.ComponentProps<typeof DSHeading>['variant'];

const tagVariant: Record<HeadingTagType, HeadingType> = {
  h1: 'title-400',
  h2: 'title-300',
  h3: 'title-200',
  h4: 'title-100',
  h5: 'subsection-100'
};

export const HeadingH1: React.FC<HeadingProps> = (props) => (
  <Heading tag="h1" {...props}>
    {props.children}
  </Heading>
);

export const HeadingH2: React.FC<HeadingProps> = (props) => (
  <Heading tag="h2" {...props}>
    {props.children}
  </Heading>
);

export const HeadingH3: React.FC<HeadingProps> = (props) => (
  <Heading tag="h3" {...props}>
    {props.children}
  </Heading>
);

export const HeadingH4: React.FC<HeadingProps> = (props) => (
  <Heading tag="h4" {...props}>
    {props.children}
  </Heading>
);

export const HeadingH5: React.FC<HeadingProps> = (props) => (
  <Heading tag="h5" {...props}>
    {props.children}
  </Heading>
);

export const HeadingPrice: React.FC<Omit<HeadingProps, 'className'>> = ({
  'data-testid': testId,
  children
}) => (
  <BodyText data-testid={testId} className={styles.heading__price}>
    {children}
  </BodyText>
);

const Heading: React.FC<{
  tag: HeadingTagType;
  'data-testid'?: string;
  className?: string;
  children: React.ReactNode | string;
}> = ({ tag, className, children, 'data-testid': testId }) => {
  return (
    <DSHeading tag={tag} variant={tagVariant[tag]} data-testid={testId} className={className}>
      {children}
    </DSHeading>
  );
};
