import { useEffect, useRef } from 'react';

export type TrackFn<T> = (newValue: T) => void;

export const useTrackOnStateChange = <T>(value: T | undefined, track: TrackFn<T>) => {
  const lastTrackedState = useRef<T>();

  useEffect(() => {
    if (typeof value !== 'undefined' && lastTrackedState.current !== value) {
      lastTrackedState.current = value;
      track(value);
    }
  }, [value, track]);
};
