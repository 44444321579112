import getClassNames from 'classnames';

import styles from './Divider.module.scss';

export type DividerProps = {
  light?: boolean;
  width?: number;
};

const Divider: React.FC<DividerProps> = ({ light, width }) => (
  <div
    data-testid="divider"
    style={{ width: width ? `${width}%` : '100%' }}
    className={getClassNames({ [styles.divider]: true, [styles.divider_light]: light })}
  />
);

export default Divider;
