import useOtherServices, {
  OtherServiceLink
} from 'B2XApp/Dashboard/DashboardOtherServices/useOtherServices';
import { useUserData } from 'contexts/UserContext/UserContext';
import { dashboardSearch, search } from 'doings/track/analyticsEvents';
import trackEvent from 'doings/track/trackEvent';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentEntry, SearchResultItem } from 'types/contentSearch';

import { mapFilteredResultItem, normalizeTerm, scoreContentEntry } from './contentSearchHelpers';
import { searchableContentQuery } from './searchableContentQuery';

const SEARCH_DELAY: Time.Milliseconds = 250;
const MAX_RESULTS_TO_RETURN = 10;
const OTHER_SERVICES_LINKS_WEIGHT_RATE = 0.1;

type SearchableContentResponse = {
  searchableContent: ContentEntry[];
};

const mapAndCombineEntries = (
  entries: ContentEntry[],
  otherServiceLinks: OtherServiceLink[]
): ContentEntry[] => {
  const otherServiceLinksById = otherServiceLinks.reduce((acc, link) => {
    acc[link.id] = mapOtherServicesLinkToContent(link);
    return acc;
  }, {} as Record<string, ContentEntry>);

  const acc: ContentEntry[] = [];
  entries.forEach((entry) => {
    if (entry.target.targetPortal !== 'external') {
      acc.push(entry);
    } else {
      const id = entry.target.url;
      const otherServiceEntry = otherServiceLinksById[id];
      if (otherServiceEntry) {
        acc.push({ ...otherServiceEntry, keywords: entry.keywords });
        delete otherServiceLinksById[id];
      }
    }
  });

  Object.values(otherServiceLinksById).forEach((entry) => {
    acc.push(entry);
  });

  return acc;
};

const mapOtherServicesLinkToContent = (link: OtherServiceLink): ContentEntry => ({
  title: link.label,
  keywords: [],
  keywordWeightRate: OTHER_SERVICES_LINKS_WEIGHT_RATE,
  target: {
    url: link.to as string,
    targetPortal: 'external'
  }
});

export default (searchTerm: string, forDashboard?: boolean) => {
  const { t } = useTranslation();
  const user = useUserData();
  const otherServiceLinks = useOtherServices({ t, user });
  const lastSearchBy = useRef('');

  const [combinedContent, setCombinedContent] = useState([] as ContentEntry[]);
  const { data } = useCallBackend<SearchableContentResponse>({
    query: searchableContentQuery,
    skip: !searchTerm
  });

  useEffect(() => {
    if (data?.searchableContent.length && combinedContent.length === 0) {
      setCombinedContent(mapAndCombineEntries(data.searchableContent, otherServiceLinks));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.searchableContent, combinedContent, user]);

  const eventBase = forDashboard ? dashboardSearch : search;
  const [results, setResults] = useState<SearchResultItem[]>();
  const [queryTerm, setQueryTerm] = useState<string>('');

  const filterResults = useCallback(
    (entries: ContentEntry[], term: string) => {
      if (entries) {
        const normalisedTerm = normalizeTerm(term);
        const filteredResults = entries
          .map((item) => scoreContentEntry({ entry: item, term: normalisedTerm }))
          .filter((item) => item.score.value > 0)
          .sort(({ score: a }, { score: b }) => b.value - a.value)
          .map(mapFilteredResultItem)
          .slice(0, MAX_RESULTS_TO_RETURN);

        if (filteredResults.length > 0) {
          trackEvent(eventBase.contentPagesFound);
        }

        lastSearchBy.current = term;
        setResults(filteredResults);
      }
    },
    [eventBase.contentPagesFound]
  );

  const emptyResults = useCallback(() => {
    setResults(undefined);
    if (searchTerm.length > 0) {
      trackEvent(eventBase.noResultsFound);
    }
  }, [eventBase.noResultsFound, searchTerm]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (combinedContent && searchTerm.length > 0) {
        filterResults(combinedContent, searchTerm);
        setQueryTerm(searchTerm);
      } else {
        emptyResults();
        setQueryTerm('');
      }
    }, SEARCH_DELAY);

    return () => clearTimeout(timeoutId);
  }, [combinedContent, emptyResults, filterResults, searchTerm]);

  return {
    results: results || [],
    dirty: lastSearchBy.current !== searchTerm,
    queryTerm
  };
};
