import { useFeatureFlagsData } from 'contexts/FeatureFlagContext/FeatureFlagContext';
import { mobile } from 'doings/track/analyticsEvents';
import { useRouting } from 'hooks/useRouting/useRouting';
import useSearchParams from 'hooks/useSearchParams/useSearchParams';

export const useBillingGroupsHelper = () => {
  const { hasFeature } = useFeatureFlagsData();
  const { preservePathState } = useSearchParams();
  const { goto } = useRouting();

  const goToEditBillingGroupPage = (collectionGroup: string, customerCode: string) =>
    goto({
      to: preservePathState(`/billing-accounts/${collectionGroup}/edit`),
      toCorporatePortal: 'billing/accounts/collectionGroup/edit',
      toCorporatePortalArgs: {
        collectionGroupId: collectionGroup,
        companyCode: customerCode,
        accountType: 'MOBILE'
      },
      useB2XLink: hasFeature('DEO-2425'),
      analyticsEvent: mobile.changeBillingAccount
    });

  const goToEditBillMillAccountPage = (accountId: string, customerCode: string) =>
    goto({
      to: preservePathState(`/billing-accounts/${accountId}/edit`),
      toCorporatePortal: 'billing/accounts/account/edit',
      toCorporatePortalArgs: {
        accountId: accountId,
        companyCode: customerCode,
        accountType: 'COMBINED'
      },
      useB2XLink: hasFeature('DEO-2425'),
      analyticsEvent: mobile.changeBillingAccount
    });

  const goToCopyBillingGroupPage = (collectionGroup: string, customerCode: string) =>
    goto({
      to: preservePathState(`/billing-accounts/${collectionGroup}/copy`),
      toCorporatePortal: 'billing/accounts/collectionGroup/new',
      toCorporatePortalArgs: {
        collectionGroupId: collectionGroup,
        companyCode: customerCode,
        accountType: 'MOBILE',
        isCopy: true
      },
      useB2XLink: hasFeature('DEO-2426'),
      analyticsEvent: mobile.copyBillingAccount
    });

  return {
    goToEditBillingGroupPage,
    goToEditBillMillAccountPage,
    goToCopyBillingGroupPage
  };
};
