import 'react-circular-progressbar/dist/styles.css';

import {
  BodyText,
  Div,
  Flex,
  GridBag,
  Gutter,
  Icon,
  Panel
} from '@gaiads/telia-react-component-library';
import { Heading, Skeleton, SmartTooltip } from 'common-components';
import useMobileNetworkQuality from 'hooks/useMobileNetworkQuality/useMobileNetworkQuality';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';

import styles from './MobileNetworkQuality.module.scss';
import MobileNetworkQualityLinks from './MobileNetworkQualityLinks';
import NetworkQualityItems from './NetworkQualityItems';

const MobileNetworkQualityPanel: React.FC = () => {
  const { mobileNetworkQuality, error, loading } = useMobileNetworkQuality();

  return (
    <Panel
      data-testid="dashboard-mobile-network-quality-panel"
      margin={{ bottom: 'sm' }}
      data-tour-stop="dashboard-mona"
    >
      <Panel.Content>
        <NetworkQualityTitle />

        <Gutter size="sm" />

        {loading && (
          <GridBag>
            <GridBag.Item xlg={6} lg={12}>
              <NetworkQualityMessageLoader />
            </GridBag.Item>

            <GridBag.Item xlg={6} lg={12}>
              <NetworkQualityItemLoaders />
            </GridBag.Item>
          </GridBag>
        )}

        {!loading && (error || !mobileNetworkQuality) && (
          <GridBag>
            <GridBag.Item xlg={12} lg={12}>
              <NetworkQualityMessage error={error} />
            </GridBag.Item>
          </GridBag>
        )}

        {!loading && !error && mobileNetworkQuality && (
          <GridBag>
            <GridBag.Item xlg={6} lg={12}>
              <NetworkQualityMessage error={false} />
            </GridBag.Item>

            <GridBag.Item xlg={6} lg={12}>
              <NetworkQualityItems networkQuality={mobileNetworkQuality} />
            </GridBag.Item>
          </GridBag>
        )}
      </Panel.Content>
    </Panel>
  );
};

const NetworkQualityTitle: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Flex centeredVertically data-testid="dashboard-mobile-network-quality-panel--title">
      <Icon name="pie-chart" size="md" />

      <Gutter size="xs" />

      <Heading.H4>
        {t('dashboard.mobileNetworkQuality.title')}

        <SmartTooltip
          tooltipContent={t('dashboard.mobileNetworkQuality.titleTooltip')}
          arrangement="top"
          wrapper="span"
        >
          <SmartTooltip.InfoIcon size="md" spacing="sm" />
        </SmartTooltip>
      </Heading.H4>
    </Flex>
  );
};

const NetworkQualityMessage: React.FC<{ error?: boolean }> = ({ error = false }) => {
  const { t } = useTranslation();

  return (
    <>
      <Flex
        occupyVerticalSpace={false}
        centeredVertically
        margin={{ top: 'xs' }}
        data-testid={`dashboard-mobile-network-quality-panel--${error ? 'error' : 'info'}-message`}
      >
        {error && <Icon flexItem name="error" size="md" color="red500" margin={{ right: 'sm' }} />}

        <BodyText flexItem>
          {error
            ? t('dashboard.mobileNetworkQuality.notAvailable')
            : t('dashboard.mobileNetworkQuality.information')}
        </BodyText>
      </Flex>

      <Gutter size="sm" />

      <MobileNetworkQualityLinks />
    </>
  );
};

const NetworkQualityMessageLoader: React.FC = () => (
  <Skeleton.TextRow data-testid="dashboard-mobile-network-quality-panel--message-loader" />
);

const NetworkQualityItemLoaders: React.FC = () => {
  return (
    <Flex centeredHorizontally data-testid="dashboard-mobile-network-quality-panel--item-loaders">
      <NetworkQualityItemLoader />

      <NetworkQualityItemLoader />

      <NetworkQualityItemLoader />
    </Flex>
  );
};

const NetworkQualityItemLoader: React.FC = () => {
  return (
    <Div
      flexItem
      className={styles.mobileNetworkQualityItem}
      data-testid="dashboard-mobile-network-quality-panel--item-loader"
    >
      <CircularProgressbarWithChildren
        value={0}
        styles={buildStyles({
          trailColor: 'transparent',
          pathColor: 'transparent'
        })}
      >
        <Skeleton.Image width="100%" height="100%" rounded />
      </CircularProgressbarWithChildren>
    </Div>
  );
};

export default MobileNetworkQualityPanel;
