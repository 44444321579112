import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import { SortOrder, SortParameter } from 'types/sort';

export const sortCaseSensitive = <T>(collection: T[], sortParameter: SortParameter): T[] => {
  return orderBy(collection, [sortParameter.sort || ''], [sortParameter.order || SortOrder.ASC]);
};

export const sortCaseInsensitive = <T>(collection: T[], sortParameter: SortParameter): T[] => {
  return orderBy(
    collection,
    (object: T) => {
      return get(object, sortParameter.sort || '')?.toLocaleUpperCase();
    },
    [sortParameter.order || SortOrder.ASC]
  );
};
