import lockBodyScroll from 'doings/lockBodyScroll/lockBodyScroll';
import { useCookieConsent } from 'hooks/useCookieConsent/useCookieConsent';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActivatedFocusTrap,
  hide3rdPartyDialog,
  mountFocusTrap,
  show3rdPartyDialog,
  unmountFocusTrap
} from 'redux/slices/activeFocusTrapsSlice';
import { RootState } from 'redux/store';
import { v4 as uuid } from 'uuid';

/**
 * Handles focus trap and HTML documention's body scroll lock state for modal
 * dialogues and drawers, ensuring that the topmost component properly receives
 * focus, and that the HTML document's body scrolling is reenabled when the last
 * element locking body scrolling unmounts. If there is a third party dialogue
 * visible, such as the OneTrust cookie consent dialogue, focus traps are paused
 * till the dialogue is hidden.
 *
 * @param descriptor
 * @returns Focus trap active state. Pass to `<FocusTrap active>` prop.
 */
export const useFocusTrap = (descriptor: Omit<ActivatedFocusTrap, 'id'>) => {
  const [id] = useState(() => uuid());
  const dispatch = useDispatch();
  const cookieConsent = useCookieConsent();
  const { activeFocusTrap, shouldLockBodyScroll } = useSelector(
    (state: RootState) => state.activeFocusTraps
  );

  useEffect(() => {
    if (cookieConsent.isBannerVisible()) {
      dispatch(show3rdPartyDialog());
      cookieConsent.onConsentChange(() => dispatch(hide3rdPartyDialog()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    dispatch(mountFocusTrap({ id, ...descriptor }));
    return () => {
      dispatch(unmountFocusTrap({ id, ...descriptor }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, descriptor.open]);

  useEffect(() => {
    lockBodyScroll(shouldLockBodyScroll);
    return () => {
      lockBodyScroll(shouldLockBodyScroll);
    };
  }, [shouldLockBodyScroll]);

  return {
    isFocusTrapActive: activeFocusTrap === id
  };
};
