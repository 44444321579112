import { InlineNotification, TeliaLink } from 'common-components';
import getEnvironmentVariable from 'doings/getEnvironmentVariable/getEnvironmentVariable';

export const DuettoBundleNotice: React.FC = () => (
  <InlineNotification
    title={{ key: 'subscriptions.subscriptionDetails.status.duettoBundleTitle' }}
    content={{
      key: 'subscriptions.subscriptionDetails.status.duettoBundleMessage',
      components: [
        <TeliaLink.Translatable.External
          key="link"
          variant="text"
          to={getEnvironmentVariable('REACT_APP_LINK_TO_TIN_SHOP')}
          iconRight="external"
        />
      ]
    }}
    variant="information"
    data-testid="duetto-bundle-notification"
  />
);
