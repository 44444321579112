import { Analytics } from 'common-components';
import ButtonLink from 'common-components/Link/ButtonLink/ButtonLink';
import { useUserData } from 'contexts/UserContext/UserContext';
import createCorporatePortalLink from 'doings/links/createCorporatePortalLink/createCorporatePortalLink';
import { mobile } from 'doings/track/analyticsEvents';
import useEcomMobileFlow from 'hooks/useEcomMobileFlow/useEcomMobileFlow';
import { useTranslation } from 'react-i18next';

export const NewSubscriptionButton: React.FC = () => {
  const { t } = useTranslation();
  const { showEcomMobileFlowModal } = useEcomMobileFlow();
  const user = useUserData();

  const requiredMobilePermissions: UserPermission[] = user.isFirstTimePurchaser
    ? ['MOBILE_SUBSCRIPTIONS', 'MOBILE_SUBSCRIPTIONS_VIEW']
    : ['MOBILE_SUBSCRIPTIONS'];

  return (
    <Analytics whenClicked={mobile.orderNewSubscription}>
      <ButtonLink
        text={t('subscriptions.newSubscription')}
        to={user.isFirstTimePurchaser ? undefined : createCorporatePortalLink('createEmployee')}
        fallbackClickAction={user.isFirstTimePurchaser ? showEcomMobileFlowModal : undefined}
        dataTestid="open-new-subscription"
        data-testid="open-new-subscription"
        enabledWhen={{
          onePermission: 'B2B_PORTAL',
          anyPermission: requiredMobilePermissions
        }}
      />
    </Analytics>
  );
};
