import Analytics from 'common-components/Analytics/Analytics';
import authorizeUser from 'doings/authorizeUser/authorizeUser';
import { AnalyticsEvent } from 'doings/track/analyticsEvents';
import React from 'react';
import { AuthorizationParams } from 'types/authorization';

import NoPrivilegesTooltip from './NoPrivilegesTooltip/NoPrivilegesTooltip';

/**
 * Authorises the logged-in user against the provided set of
 * authorisation parameters before rendering the specified component.
 * The component must have the `disabled` property.
 *
 * If the user has an insufficient set of permissions, the underlying
 * element is rendered with its `disabled` property overridden and set
 * to `true`, and a tooltip for helping the user identify the lack of
 * sufficient privileges. Otherwise, the element is rendered as is.
 */
const Authorize: React.FC<{
  analyticsEvent?: AnalyticsEvent;
  enabledWhen: AuthorizationParams;
  element: React.ReactElement & { disabled?: boolean };
}> = ({ analyticsEvent, enabledWhen, element }) => {
  const disable = !authorizeUser(enabledWhen);
  if (disable) {
    return (
      <NoPrivilegesTooltip
        i18nKey="common.tooltips.insufficientPermissions.forAction"
        showTooltip
        wrapChildren
        wrapper="none"
        fullWidthWhenWrapped={element.props.fullWidthWhenWrapped}
      >
        {React.cloneElement(element, { disabled: true })}
      </NoPrivilegesTooltip>
    );
  }

  return analyticsEvent ? (
    <Analytics data-testid="authorize-analytics" whenClicked={analyticsEvent}>
      {element}
    </Analytics>
  ) : (
    element
  );
};

export default Authorize;
