import validators from 'hooks/inputModels/validation/validators';
import { ValidationResult, ValidationScheme } from 'types/formValidation';

export default ({
  value,
  required = false,
  expecting
}: {
  value: string;
  required?: boolean;
  expecting: ValidationScheme;
}): ValidationResult => {
  const isAbsenceOk = !required && value.length === 0;
  const isPresent = value.length > 0;

  const { minLength, maxLength } = expecting;
  const isMinLengthOk = !minLength || value.length >= minLength;
  const isMaxLengthOk = !maxLength || value.length <= maxLength;
  const isLengthOk = isAbsenceOk || (isPresent && isMinLengthOk && isMaxLengthOk);
  const valid = validateValue(value, isLengthOk, expecting);
  const hasMaxLengthError = !isMaxLengthOk;
  const hasImplicitError = !valid && value.length > 0;

  const errorType = (function () {
    if (!isAbsenceOk && !isPresent) {
      return 'required';
    } else if (hasMaxLengthError) {
      return 'maxLength';
    } else if (hasImplicitError) {
      return 'implicit';
    } else {
      return undefined;
    }
  })();

  return {
    isValid: valid,
    errors: {
      expecting,
      errorType,
      maxLengthOverBy: hasMaxLengthError ? value.length - (expecting.maxLength || 0) : undefined
    }
  };
};

const validateValue = (value: string, isLengthOk: boolean, expecting: ValidationScheme) => {
  const { a, oneOf, allOf } = expecting;

  if (value.length > 0) {
    if (a) {
      return isLengthOk && validators[a].validate(value);
    } else if (oneOf?.length) {
      return isLengthOk && oneOf.some((a) => validators[a].validate(value));
    } else if (allOf?.length) {
      return isLengthOk && allOf.every((a) => validators[a].validate(value));
    }
  }

  return isLengthOk;
};
