import { gql } from '@apollo/client';

export const mobileNetworkQualityQuery = gql`
  query GetMobileNetworkServiceQuality {
    mobileNetworkServiceQuality {
      callQuality {
        callQuality
        callAttempts
        failedCalls
        droppedCalls
        callFailRate
        callDropRate
      }
      smsQuality {
        smsQuality
        smsAttempts
        failedSms
        smsFailRate
      }
      dataQuality {
        dataQuality
        totalDownloaded
        totalUploaded
        packetLossRate
      }
    }
  }
`;
