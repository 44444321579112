import { Page } from '@gaiads/telia-react-component-library';
import { InlineNotification, ListView, PageWithSubnavigation } from 'common-components';
import { useProductNewsStats } from 'hooks/productNews/useProductNewsStats';
import { useTranslation } from 'react-i18next';

import { NewsRatingsStatsPanel } from './NewsRatingsStatsPanel';

const NewsRatings: React.FC = () => {
  const { t } = useTranslation();
  const { data, loading, error } = useProductNewsStats();
  if (error) {
    return (
      <PageWithSubnavigation withoutCompanySelector>
        <Page.Row>
          <InlineNotification
            variant="error"
            title={{ key: 'productNewsRatings.list.errorTitle' }}
            content={{ key: 'productNewsRatings.list.errorMessage' }}
          />
        </Page.Row>
      </PageWithSubnavigation>
    );
  }

  return (
    <PageWithSubnavigation withoutCompanySelector>
      <Page.Row>
        <ListView
          listData={data}
          loading={loading}
          noItemsNotice={t('productNewsRatings.list.noItemsFound')}
          data-testid="product-news-list"
        >
          {(item) => {
            const { newsId } = item;
            return (
              <ListView.Row data-testid={`news-row-${newsId}`} linkTo={`/news-ratings/${newsId}`}>
                <ListView.Cell cellSize="1x">
                  <NewsRatingsStatsPanel item={item} />
                </ListView.Cell>
              </ListView.Row>
            );
          }}
        </ListView>
      </Page.Row>
    </PageWithSubnavigation>
  );
};
export { NewsRatings };
