import { ApolloError } from '@apollo/client';
import { Page } from '@gaiads/telia-react-component-library';
import { InternetConnectionsListServicesAndOfferingNotice } from 'B2XApp/InternetConnections/InternetConnectionsList/InternetConnectionsListServicesAndOfferingNotice';
import { PageWithSubnavigation } from 'common-components';
import EmptyListContent from 'common-components/EmptyListContent/EmptyListContent';
import ListHeader from 'common-components/ListHeader/ListHeader';
import ShowMoreResults from 'common-components/ShowMoreResults/ShowMoreResults';
import { EditableListProvider } from 'contexts/EditableListContext/EditableListContext';
import { AssetFilters } from 'hooks/assets/useAssets/useAssetsFilters';
import { ViewStatus } from 'hooks/useApiStatus/useApiStatus';
import { useOfflineLimit } from 'hooks/useOfflineLimit/useOfflineLimit';
import { OnSortChange, SortState } from 'hooks/useSortableHeadings/useSortableHeadings';
import { useTranslation } from 'react-i18next';
import { InternetConnectionsItem } from 'types/internetConnections';
import { SortParameter } from 'types/sort';

import InternetConnectionsListView, {
  InternetConnectionsListViewColumnSetup
} from './InternetConnectionsListView';
import { InternetConnectionsSortDropdown } from './InternetConnectionsSortDropdown';
import { ExcelDownloadLink } from './links/ExcelDownloadLink';

const InternetConnectionsListPage: React.FC<{
  assetFilters: AssetFilters;
  assets: { total: number; items?: InternetConnectionsItem[] };
  status: ViewStatus;
  error?: ApolloError;
  multipleCompanySearchEnabled: boolean;
  sortState: SortState;
  sortClick: (field: string) => void;
  onSortChange: OnSortChange;
  currentSort: SortParameter;
}> = ({
  assetFilters,
  assets,
  status,
  error,
  multipleCompanySearchEnabled,
  sortState,
  sortClick,
  onSortChange,
  currentSort
}) => {
  const { t } = useTranslation();
  const { queryParameters, queryParametersAbsent, allCompanySearch } = assetFilters;
  const scopeOptions = [
    {
      label: t('internetConnections.list.columns.displayId'),
      value: 'displayId',
      tooltip: t('internetConnections.searchOptions.displayIdInstruction')
    },
    {
      label: t('internetConnections.list.columns.installationAddress'),
      value: 'installationAddress'
    }
  ];

  const loading = [ViewStatus.LoadingInitial, ViewStatus.Initial].includes(status);
  const { limit, showMore } = useOfflineLimit([loading, assets.total]);
  const limitedAssets = (assets.items ?? []).slice(0, limit);

  return (
    <EditableListProvider
      columnSetup={InternetConnectionsListViewColumnSetup}
      invocationArgs={[t, sortState, sortClick, multipleCompanySearchEnabled]}
      changeableArgs={[undefined, sortState, undefined, undefined]}
    >
      <PageWithSubnavigation
        loading={loading}
        error={status === ViewStatus.Error ? error : undefined}
        emptyContentPlaceholder={
          queryParametersAbsent &&
          status === ViewStatus.Empty && (
            <EmptyListContent
              title={t('internetConnections.emptyList.title')}
              description={<InternetConnectionsListServicesAndOfferingNotice />}
              occupyHorizontalSpace
              data-testid="internet-connections-list-empty-content"
            />
          )
        }
        showSubnavigationForCurrentPath
      >
        <ListHeader
          withMultiCompanyFeature={multipleCompanySearchEnabled}
          multipleCompaniesCheckboxParams={{
            checked: allCompanySearch.showFromAllCompanies,
            label: t('internetConnections.searchAllLabel'),
            testId: 'internet-connections-search-all-companies',
            onChange: allCompanySearch.toggleAllCompanies
          }}
          searchScopeOptions={scopeOptions}
          sortDropdown={
            <InternetConnectionsSortDropdown
              currentSort={currentSort}
              onSortChange={onSortChange}
            />
          }
          excelDownloadLink={
            <ExcelDownloadLink
              queryParameters={queryParameters}
              sortParameter={currentSort}
              showFromAllCompanies={allCompanySearch.showFromAllCompanies}
              disabled={loading || assets.total === 0}
            />
          }
          infoNotice={<InternetConnectionsListServicesAndOfferingNotice />}
          labels={{
            searchInMobileView: t('internetConnections.searchLabel')
          }}
        >
          {t('internetConnections.list.numberOfConnections', {
            numberOfConnections: assets.total
          })}
        </ListHeader>

        <Page.Row responsiveFullWidth>
          <InternetConnectionsListView
            assets={limitedAssets}
            loading={status === ViewStatus.Loading}
          />
        </Page.Row>

        {status !== ViewStatus.Loading && (
          <Page.Row>
            <ShowMoreResults
              data-testid="show-more-assets"
              loading={status === ViewStatus.LoadingMore}
              hasMoreResults={limit < assets.total}
              hasResults={!!assets?.items?.length}
              fetchMore={showMore}
            />
          </Page.Row>
        )}
      </PageWithSubnavigation>
    </EditableListProvider>
  );
};

export default InternetConnectionsListPage;
