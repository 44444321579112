import { Dispatch, SetStateAction, useEffect, useState } from 'react';

const DEFAULT_TIMEOUT: Time.Milliseconds = 3000;

/**
 * Returns a stateful value and a function to update it. When set to
 * anything but the specified default value, it will be reset in the
 * specified number of milliseconds. Useful for any temporary notice,
 * which we might want to hide after displaying it for a certain
 * amount of time without requiring user interaction.
 *
 * @param defaultValue Initial value that the hook always defaults to.
 * @param timeoutInMillis Defaults to 3 seconds.
 */
export const useTimeoutedState = <T>(
  defaultValue: T,
  timeoutInMillis: Time.Milliseconds = DEFAULT_TIMEOUT
): [T, Dispatch<SetStateAction<T>>] => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    const timeout =
      value !== defaultValue
        ? setTimeout(() => {
            setValue(defaultValue);
          }, timeoutInMillis)
        : undefined;

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [defaultValue, timeoutInMillis, value, setValue]);

  return [value, setValue];
};
