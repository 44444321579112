import { createContext, useContext } from 'react';
import { DynamicForms } from 'types/dynamicForms';

type DynamicFormContextState = {
  formLanguage: Language;
  form: DynamicForms.DynamicForm;
  fixedValues: Record<string, string | undefined>;
};

export const DynamicFormContext = createContext<DynamicFormContextState | undefined>(undefined);

const useDynamicFormContext = () => {
  const context = useContext(DynamicFormContext);
  if (!context) {
    throw new Error('useDynamicFormContext must be used with DynamicFormContext');
  }

  return context;
};

export { useDynamicFormContext };
