import {
  BodyText,
  Checkbox,
  Div,
  Flex,
  Gutter,
  Input,
  Panel,
  TextArea
} from '@gaiads/telia-react-component-library';
import {
  ActionButtonGroup,
  Button,
  DetailsLoadingOverlay,
  InlineNotification,
  ModalDialog,
  NoPrivilegesTooltip,
  TextWithInlineHtml
} from 'common-components';
import useAddOrUpdateFeatureFlag from 'hooks/featureFlags/useManageFeatureFlags/useAddOrUpdateFeatureFlag';
import useDeleteFeatureFlag from 'hooks/featureFlags/useManageFeatureFlags/useDeleteFeatureFlag';
import useCustomModal from 'hooks/useCustomModal/useCustomModal';
import { useRouting } from 'hooks/useRouting/useRouting';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FeatureFlag } from 'types/featureFlags';
import { TEXT_FIELD_MAX_LENGTH } from 'types/form';

import FeatureFlagFormError from './FeatureFlagFormError';

type Id = {
  id?: number;
};

type Name = {
  name: string;
};

type Description = {
  description: string;
};

type Active = {
  active: boolean;
};

type OverrideActiveStatus = {
  overrideActiveStatus: boolean;
};

type OverrideList = {
  overrideList: string;
};

type FieldUpdate = Id | Name | Description | Active | OverrideActiveStatus | OverrideList;

type FeatureFlagFormData = Id & Name & Description & Active & OverrideActiveStatus & OverrideList;

const flagNamePattern = new RegExp(/^[a-z0-9-]+$/i);

const FeatureFlagForm: React.FC<{ flag?: FeatureFlag; persistable?: boolean }> = ({
  flag,
  persistable = true
}) => {
  const { t } = useTranslation();
  const {
    addOrUpdateFeatureFlag,
    data: addOrUpdateFlagResponse,
    loading: persisting
  } = useAddOrUpdateFeatureFlag();
  const { deleteFeatureFlag, data: deleteFlagResponse, loading: deleting } = useDeleteFeatureFlag();

  const { goto } = useRouting();
  const deleteFlagModal = useCustomModal('DELETE_FEATURE_FLAG');
  const isNewFlag = !flag?.id;

  const defaultForm = {
    id: flag?.id,
    name: flag?.name ?? '',
    description: flag?.description ?? '',
    active: flag?.rawActive || false,
    overrideActiveStatus: !!flag?.activeStatusOverride,
    overrideList: (flag?.activeStatusOverride ?? '').split(',').join(', ')
  };

  const [form, setForm] = useState<FeatureFlagFormData>(defaultForm);
  const [isFlagNameValid, setIsFlagNameValid] = useState<boolean>(flagNamePattern.test(form.name));

  const validateFlagName = (name: string) => setIsFlagNameValid(flagNamePattern.test(name));

  const updateForm = (updatedForm: FieldUpdate) => {
    setForm({ ...form, ...updatedForm });
  };

  const submitForm = async () => {
    await addOrUpdateFeatureFlag({
      id: form.id,
      name: form.name,
      description: form.description,
      active: form.active,
      activeStatusOverride: form.overrideActiveStatus ? form.overrideList : ''
    });
  };

  useEffect(() => {
    if (
      addOrUpdateFlagResponse !== undefined &&
      addOrUpdateFlagResponse.addOrUpdateFeatureFlag &&
      !persisting
    ) {
      goto({
        toCorporatePortal: '',
        toCorporatePortalArgs: {},
        useB2XLink: true,
        to: '/flags'
      });
    }
  }, [goto, addOrUpdateFlagResponse, persisting]);

  useEffect(() => {
    if (deleteFlagResponse !== undefined && deleteFlagResponse.deleteFeatureFlag && !deleting) {
      goto({
        toCorporatePortal: '',
        toCorporatePortalArgs: {},
        useB2XLink: true,
        to: '/flags'
      });
    }
  }, [goto, deleteFlagResponse, deleting, deleteFlagModal]);

  return (
    <>
      {addOrUpdateFlagResponse &&
        !addOrUpdateFlagResponse.addOrUpdateFeatureFlag &&
        !persisting && <FeatureFlagFormError />}

      <Panel padding={{ size: 'xs' }}>
        <Panel.Content>
          <DetailsLoadingOverlay showLoader={persisting}>
            <Input
              data-testid="feature-flag-name"
              maxLength={TEXT_FIELD_MAX_LENGTH}
              required
              label={t('featureFlags.form.name')}
              placeholder={t('featureFlags.form.name')}
              name="name"
              value={form.name}
              onChange={(_, value) => {
                validateFlagName(value);
                updateForm({ name: value });
              }}
              confirmed={isFlagNameValid}
              errorText={form.name && !isFlagNameValid ? t('featureFlags.form.invalidName') : ''}
              autocomplete="off"
            />

            <Gutter />

            <Input
              data-testid="feature-flag-description"
              maxLength={TEXT_FIELD_MAX_LENGTH}
              label={t('featureFlags.form.description')}
              placeholder={t('featureFlags.form.description')}
              value={form.description}
              name="description"
              onChange={(_, value) => updateForm({ description: value })}
              autocomplete="off"
            />

            <Gutter />

            <Checkbox
              data-testid="feature-flag-active"
              label={t('featureFlags.form.active')}
              checked={form.active}
              name="active"
              onChange={(_, value) => updateForm({ active: value })}
            />

            <Gutter />

            <Checkbox
              data-testid="feature-flag-override"
              label={t('featureFlags.form.override.checkbox')}
              checked={form.overrideActiveStatus}
              name="overrideActive"
              onChange={(_, value) => updateForm({ overrideActiveStatus: value })}
            />

            <Gutter />

            {form.overrideActiveStatus && (
              <>
                <TextArea
                  data-testid="feature-flag-override-list"
                  maxLength={TEXT_FIELD_MAX_LENGTH}
                  label={t(form.active ? 'featureFlags.disabledFor' : 'featureFlags.enabledFor')}
                  value={form.overrideList}
                  required
                  name="overrideList"
                  onChange={(_event, value: string) => updateForm({ overrideList: value })}
                />

                <Gutter size="xs" />

                <BodyText size="sm" color="gray500">
                  {t('featureFlags.form.override.tooltip')}
                </BodyText>
              </>
            )}

            <Gutter />

            <Flex pileVerticallyTo="xxs">
              <ActionButtonGroup>
                <Button
                  data-testid="feature-flag-save"
                  type="submit"
                  variant="primary"
                  name="saveFlag"
                  onClick={() => submitForm()}
                  disabled={
                    (form.overrideActiveStatus && !form.overrideList) ||
                    persisting ||
                    (isNewFlag && !persistable) ||
                    !isFlagNameValid
                  }
                >
                  {t('featureFlags.form.submit')}
                </Button>

                <Button
                  data-testid="feature-flag-cancel"
                  type="button"
                  variant="tertiary-purple"
                  onClick={() =>
                    goto({
                      toCorporatePortal: '',
                      toCorporatePortalArgs: {},
                      useB2XLink: true,
                      to: '/flags'
                    })
                  }
                  disabled={persisting}
                >
                  {t('common.cancelButton.label')}
                </Button>
              </ActionButtonGroup>

              <DeleteFeatureFlagButton
                flag={flag}
                persistable={persistable}
                onClick={deleteFlagModal.openModal}
              />
            </Flex>
          </DetailsLoadingOverlay>
        </Panel.Content>
      </Panel>

      {persistable && !!flag && (
        <ModalDialog
          title={t('featureFlags.form.delete.title')}
          onClose={deleteFlagModal.closeModal}
          isOpen={deleteFlagModal.isOpen}
          acceptButton={{
            label: t('featureFlags.form.delete.confirm'),
            onClick: () => deleteFeatureFlag(flag.name),
            isDestructive: true,
            disabled: deleting || !flag.name,
            testIdSuffix: 'delete-flag--confirm'
          }}
          closeButton={{
            label: t('common.cancelButton.label'),
            onClick: deleteFlagModal.closeModal,
            disabled: deleting,
            testIdSuffix: 'delete-flag--cancel'
          }}
          isNarrow
        >
          <DetailsLoadingOverlay
            showLoader={deleting}
            loadingText={t('featureFlags.form.delete.deleting')}
          >
            <TextWithInlineHtml
              text={t('featureFlags.form.delete.description', {
                featureFlagName: flag.name
              })}
            />

            {deleteFlagResponse !== undefined &&
              !deleteFlagResponse.deleteFeatureFlag &&
              !deleting && (
                <>
                  <Gutter />

                  <InlineNotification
                    variant="error"
                    content={{ key: 'featureFlags.form.delete.error' }}
                  />
                </>
              )}
          </DetailsLoadingOverlay>
        </ModalDialog>
      )}
    </>
  );
};

const DeleteFeatureFlagButton: React.FC<{
  flag?: FeatureFlag;
  persistable: boolean;
  onClick: VoidFunction;
}> = ({ flag, persistable, onClick }) => {
  const { t } = useTranslation();

  if (!flag) {
    return null;
  }

  const flagInUse = persistable && !!flag.isApplicable;
  const canDeleteFlag = !flagInUse && persistable;

  return (
    <>
      <Gutter size="sm" />

      <Div flexItem alignTextToRight>
        <NoPrivilegesTooltip
          i18nKey={
            flagInUse
              ? 'featureFlags.form.delete.flagInUse'
              : 'featureFlags.form.delete.nonPersistable'
          }
          showTooltip={!canDeleteFlag}
          tooltipPlacement="top"
          wrapChildren
        >
          <Button
            data-testid="feature-flag-delete"
            type="button"
            variant="destructive"
            name="deleteFlag"
            onClick={onClick}
            disabledOnDemo
            disabled={!canDeleteFlag}
          >
            {t('featureFlags.form.delete.button')}
          </Button>
        </NoPrivilegesTooltip>
      </Div>
    </>
  );
};

export default FeatureFlagForm;
