import { useUserData } from 'contexts/UserContext/UserContext';
import reloadWindow from 'doings/reloadWindow/reloadWindow';
import { common } from 'doings/track/analyticsEvents';
import trackEvent from 'doings/track/trackEvent';
import useChangeUserLanguage from 'hooks/useChangeUserLanguage/useChangeUserLanguage';

const analyticsEventMap = {
  en: common.changeLanguageToEn,
  fi: common.changeLanguageToFi,
  sv: common.changeLanguageToSv
};

const useUserLanguage = () => {
  const { language } = useUserData();
  const { changeUserLanguage } = useChangeUserLanguage();
  return {
    language,
    setLanguage: async (language: Language) => {
      trackEvent(analyticsEventMap[language]);
      await changeUserLanguage(language);
      reloadWindow();
    }
  };
};

export default useUserLanguage;
