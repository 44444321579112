import { List } from '@gaiads/telia-react-component-library';
import { Repeat, Skeleton } from 'common-components';

import sectionStyles from './DashboardSections/DashboardSection.module.scss';

const DashboardNavigationB2BQuickLinksSkeleton: React.FC = () => (
  <Repeat times={8}>
    <List.Item className={sectionStyles.listItem}>
      <Skeleton.TextRow width="90%" />
    </List.Item>
  </Repeat>
);

export default DashboardNavigationB2BQuickLinksSkeleton;
