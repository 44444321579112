import { Page } from '@gaiads/telia-react-component-library';
import { PageWithSubnavigation } from 'common-components';
import EmptyListContent from 'common-components/EmptyListContent/EmptyListContent';
import ListHeader from 'common-components/ListHeader/ListHeader';
import ShowMoreResults from 'common-components/ShowMoreResults/ShowMoreResults';
import { EditableListProvider } from 'contexts/EditableListContext/EditableListContext';
import { ViewStatus } from 'hooks/useApiStatus/useApiStatus';
import useInitialLoadStatus from 'hooks/useInitialLoadStatus/useInitialLoadStatus';
import { OnSortChange, SortState } from 'hooks/useSortableHeadings/useSortableHeadings';
import useThreeSixtyInventory from 'hooks/useThreeSixtyInventory/useThreeSixtyInventory';
import useThreeSixtyInventoryFilters from 'hooks/useThreeSixtyInventory/useThreeSixtyInventoryFilters';
import { useTranslation } from 'react-i18next';
import { SortParameter } from 'types/sort';

import { ExcelDownloadLink } from './links/ExcelDownloadLink';
import { ProductSortDropdown } from './ProductSortDropdown';
import ThreeSixtyProductInventoryListView, {
  ThreeSixtyProductInventoryListViewColumnSetup
} from './ThreeSixtyProductInventoryListView';

// TODO translations and tests

const ThreeSixtyProductInventoryPage: React.FC<{
  multipleCompanySearchEnabled: boolean;
  sortState: SortState;
  sortClick: (field: string) => void;
  onSortChange: OnSortChange;
  currentSort: SortParameter;
}> = ({ multipleCompanySearchEnabled, sortState, sortClick, onSortChange, currentSort }) => {
  const { t } = useTranslation();
  const productInventoryFilters = useThreeSixtyInventoryFilters(multipleCompanySearchEnabled);
  const { queryParameters, queryParametersAbsent, allCompanySearch } = productInventoryFilters;
  const scopeOptions = [
    {
      label: 'Tunniste',
      value: 'externalId',
      tooltip: ''
    },
    {
      label: 'Tuote',
      value: 'productType',
      tooltip: ''
    }
  ];

  const {
    data: products,
    total,
    status: currentStatus,
    error,
    fetchMore
  } = useThreeSixtyInventory(queryParameters, allCompanySearch.showFromAllCompanies);

  const status = useInitialLoadStatus(currentStatus);

  const loading = [ViewStatus.LoadingInitial, ViewStatus.Initial].includes(status);

  return (
    <EditableListProvider
      columnSetup={ThreeSixtyProductInventoryListViewColumnSetup}
      invocationArgs={[t, sortState, sortClick, multipleCompanySearchEnabled]}
      changeableArgs={[undefined, sortState, undefined, undefined]}
    >
      <PageWithSubnavigation
        loading={loading}
        error={status === ViewStatus.Error ? error : undefined}
        emptyContentPlaceholder={
          queryParametersAbsent &&
          status === ViewStatus.Empty && (
            <EmptyListContent
              title={t('internetConnections.emptyList.title')}
              occupyHorizontalSpace
              data-testid="internet-connections-list-empty-content"
            />
          )
        }
        showSubnavigationForCurrentPath
      >
        <ListHeader
          withMultiCompanyFeature={multipleCompanySearchEnabled}
          multipleCompaniesCheckboxParams={{
            checked: allCompanySearch.showFromAllCompanies,
            label: t('internetConnections.searchAllLabel'),
            testId: 'internet-connections-search-all-companies',
            onChange: allCompanySearch.toggleAllCompanies
          }}
          searchScopeOptions={scopeOptions}
          sortDropdown={
            <ProductSortDropdown currentSort={currentSort} onSortChange={onSortChange} />
          }
          excelDownloadLink={
            <ExcelDownloadLink
              queryParameters={queryParameters}
              sortParameter={currentSort}
              showFromAllCompanies={allCompanySearch.showFromAllCompanies}
              disabled={loading || total === 0}
            />
          }
          labels={{
            searchInMobileView: t('internetConnections.searchLabel')
          }}
        >
          {t('internetConnections.list.numberOfConnections', {
            numberOfConnections: total
          })}
        </ListHeader>

        <Page.Row responsiveFullWidth>
          <ThreeSixtyProductInventoryListView
            products={products}
            loading={status === ViewStatus.Loading}
          />
        </Page.Row>

        {status !== ViewStatus.Loading && (
          <Page.Row>
            <ShowMoreResults
              data-testid="show-more-products"
              loading={status === ViewStatus.LoadingMore}
              hasMoreResults={products.length < (total ?? 0)}
              hasResults={!!products.length}
              fetchMore={fetchMore}
            />
          </Page.Row>
        )}
      </PageWithSubnavigation>
    </EditableListProvider>
  );
};

export default ThreeSixtyProductInventoryPage;
