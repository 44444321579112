import { Div } from '@gaiads/telia-react-component-library';
import { Badge, Icon, IconName } from '@teliafi/fi-ds';
import getClassNames from 'classnames';
import {
  ActionButtonGroup,
  Button,
  Heading,
  ModalDialog,
  TextWithInlineHtmlExtended
} from 'common-components';
import { multiplex } from 'doings/multiplex/multiplex';
import FocusTrap from 'focus-trap-react';
import { useFocusTrap } from 'hooks/useFocusTrap/useFocusTrap';
import {
  CSSProperties,
  Dispatch,
  RefObject,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import styles from './Tour.module.scss';
import {
  DialogArrangement,
  DialogPlacement,
  DialogPositioning,
  TourAction,
  TourActionType,
  TourActiveGroupType,
  TourStopDescription
} from './tour.types';
import { useTourReducer } from './useTourReducer';

type TourGuideProps = {
  tourIds: string[];
};

type TourProps = {
  id: string;
  group: TourActiveGroupType;
  intro?: {
    title: string;
    description: string;
  };
  stops: TourStopDescription[];
  stopIndex: number;
  dispatch: Dispatch<TourAction>;
};

type TourIntroProps = {
  title: string;
  description: string;
  dispatch: Dispatch<TourAction>;
  root: HTMLDivElement;
};

type TourStopProps = {
  id: string;
  elementId: string;
  extraHighlightSelector?: string;
  dialogArrangement: DialogArrangement;
  title: string;
  description: string;
  icon?: IconName;
  tag?: 'new';
  pages?: { at: number; total: number };
  lastStop: boolean;
  dispatch: Dispatch<TourAction>;
  root: HTMLDivElement;
};

/**
 * The `TourGuide` takes in one or several tour IDs which are applicable on the
 * particular page the guide is placed on. The guide will ensure a user is only
 * walked through those tours the user has not seen and in the user's language.
 *
 * @privateRemarks
 * How `TourGuide` and other components relate:
 * ```
 * TourGuide (1) ○───────> (*) Tour (1) ○────────┬─> (1) TourIntro (1) ○─┐
 * :tourIds       renders      :id       renders │                       │  renders
 *                                               │     ModalDialog (1) <─┘
 *                                               │
 *                                               └─> (*) TourStop  (1) ○─┐  renders
 *                                                                       │     &
 *                                                      TourDialog (1) <─┘ positions
 * ```
 *
 * In order to embed the `TourGuide` on any page, a `div#tour-root` must exist
 * in the document, since the `TourDialog` and its backdrop(s) need to render
 * on top of other elements _except_ for modal dialogs like the session expiry
 * notice.
 *
 * Tours are skipped in automated tests, which inject the boolean variable
 * `window['__TF_B2X_AUTOMATION']` into the application.
 */
export const TourGuide: React.FC<TourGuideProps> = ({ tourIds }) => {
  const [state, dispatch] = useTourReducer({ tourIds });
  const { currentTour, currentTourStopAt: stopIndex } = state;
  if (!currentTour) {
    return null;
  }

  return (
    <Tour
      {...currentTour}
      stopIndex={stopIndex}
      dispatch={dispatch}
      key={`tour-${currentTour.id}`}
    />
  );
};

/**
 * An active tour. A tour starts off with an intro which is then followed by any
 * number of stops.
 */
export const Tour: React.FC<TourProps> = ({ intro, stops, stopIndex, dispatch }) => {
  const { t } = useTranslation();
  const translated = useCallback(
    (text: string) => (text.startsWith('i18n:') ? t(text.substring('i18n:'.length)) : text),
    [t]
  );
  const tourRoot = useMemo(() => document.querySelector('#tour-root') as HTMLDivElement, []);
  if (stopIndex < 0) {
    return (
      <TourIntro
        title={intro?.title ? translated(intro.title) : ''}
        description={intro?.description ? translated(intro.description) : ''}
        dispatch={dispatch}
        root={tourRoot}
        key="tour-intro"
      />
    );
  }

  const stop = stops[stopIndex];
  return (
    <TourStop
      {...stop}
      title={translated(stop.title)}
      description={translated(stop.description)}
      pages={{ at: stopIndex + 1, total: stops.length }}
      lastStop={stopIndex >= stops.length - 1}
      dispatch={dispatch}
      root={tourRoot}
      key={`tour-stop-${stop.id}`}
    />
  );
};

/**
 * The first step of any tour. Renders a dialog centred in the viewport which
 * asks for the user to start or skip the active tour.
 */
export const TourIntro: React.FC<TourIntroProps> = ({ title, description, dispatch, root }) => {
  const { t } = useTranslation();

  return createPortal(
    <div className={getClassNames([styles.backdrop, styles.backdrop_intro])}>
      <ModalDialog
        data-testid="tour-intro-dialog"
        title={title}
        acceptButton={{
          label: t('common.tour.flow.start'),
          testIdSuffix: 'tour-action-primary',
          onClick: () => {
            dispatch({ type: TourActionType.CONTINUE });
          }
        }}
        closeButton={{
          label: t('common.tour.flow.close'),
          testIdSuffix: 'tour-action-secondary',
          onClick: () => {
            dispatch({ type: TourActionType.SKIP });
          }
        }}
        onClose={() => {
          dispatch({ type: TourActionType.SKIP });
        }}
        disableOutsideClick
        hideCloseIcon
        isOpen
        isNarrow
      >
        <TextWithInlineHtmlExtended text={description} />
      </ModalDialog>
    </div>,
    root
  );
};

/**
 * The subsequent step of any tour. Renders a dialog either pointing to a particular
 * element (i.e. target) or centred to the viewport if a target is inapplicable. Asks
 * the user to continue/finish or skip the active tour.
 */
export const TourStop: React.FC<TourStopProps> = ({
  elementId,
  extraHighlightSelector,
  dialogArrangement,
  title,
  description,
  icon,
  tag,
  pages,
  lastStop,
  dispatch,
  root
}) => {
  const dialogRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<DialogPositioning>({
    x: '-9999px',
    y: '-9999px',
    arrangement: undefined,
    placement: undefined,
    clip: undefined
  });

  const documentBody = useMemo(() => document.querySelector('#application-body'), []);
  const target = elementId ? (document.querySelector(elementId) as HTMLElement) : null;

  const onRender = useCallback(() => {
    const dialog = dialogRef.current;
    setPosition(calculateDialogPosition({ target, dialog, dialogArrangement }));
  }, [target, dialogArrangement, setPosition]);

  useLayoutEffect(() => {
    window.addEventListener('resize', onRender);
    return () => {
      window.removeEventListener('resize', onRender);
    };
  }, [onRender]);

  // `window.addEventListener('resize')` doesn't sufficiently listen to changes to
  // element dimensions which can occur when DOM layout changes after a `TourDialog`
  // component is already mounted and rendered, so we need to use a `ResizeObserver`
  // in addition to a window resize listener.
  //
  // Using layout effect avoids an otherwise visible flicker when two subsequent
  // tour stops are positioned at the same place when switching to the next stop.
  useLayoutEffect(() => {
    if (!target) {
      return;
    }

    const onResize = new ResizeObserver(
      /* istanbul ignore next */
      () => onRender()
    );
    [target, documentBody].forEach((el) => {
      if (el) {
        onResize.observe(el);
      }
    });
    return () => {
      onResize.disconnect();
    };
  }, [target, documentBody, onRender]);

  return createPortal(
    <>
      {extraHighlightSelector && (
        <TourExtraHighlight container={target} subselector={extraHighlightSelector} />
      )}

      <TourDialog
        position={position}
        title={title}
        description={description}
        icon={icon}
        tag={tag}
        pages={pages}
        variant={lastStop ? 'last-stop' : 'stop'}
        dispatch={dispatch}
        onRender={onRender}
        refElement={dialogRef}
      />

      {/* This one captures pointer events through clip */}
      <div className={getClassNames([styles.backdrop, styles.backdrop_stopTransparency])}></div>

      {/* This one renders a clip for showcasing the element the tour is stopping at */}
      <div
        className={getClassNames([styles.backdrop, styles.backdrop_stopClip])}
        style={{
          width: `${document.body.scrollWidth}px`,
          height: `${document.body.scrollHeight}px`,
          clipPath: position.clip ?? 'unset'
        }}
      ></div>
    </>,
    root
  );
};

const DIALOG_SCROLL_THRESHOLD_MULTIPLIER = 5;

/**
 * A tour stop dialog. The dialog can either point to a particular calculated
 * position or be centred to the viewport.
 *
 * A tour stop calculates the dialog's position based on an applicable target element
 * explicitly and passes it to the dialog, which simply renders itself to an absolute
 * position in the document or centres itself to the viewport. Invokes the specified
 * `onRender` function when the dialog is mounted so that its dimensions could be used
 * for exact positioning.
 *
 * Once positioned, the dialog will be scrolled into view, so an end-user would not
 * need to figure out where the active tour stopped after proceeding with the tour.
 */
export const TourDialog: React.FC<{
  position: DialogPositioning;
  title: string;
  description: string;
  icon?: IconName;
  tag?: 'new';
  pages?: { at: number; total: number };
  variant: 'stop' | 'last-stop';
  dispatch: Dispatch<TourAction>;
  onRender: VoidFunction;
  refElement: RefObject<HTMLDivElement>;
}> = ({
  position,
  title,
  description,
  icon,
  tag,
  pages,
  variant,
  dispatch,
  onRender,
  refElement
}) => {
  const { t } = useTranslation();

  useLayoutEffect(() => {
    onRender();
  }, [onRender]);

  useLayoutEffect(() => {
    if (position?.placement) {
      const dialog = refElement.current;
      /* istanbul ignore next */
      if (!dialog) {
        return;
      }

      // Avoid scrolling the view if the dialog is already in the
      // viewport and has enough vertical space around it, reducing
      // visual noise from excessive scrolling.
      const dialogBounds = dialog.getBoundingClientRect();
      const spaceAbove = dialogBounds.top;
      const spaceBelow = window.innerHeight - dialogBounds.bottom;
      const scrollThreshold = Math.round(window.innerHeight / DIALOG_SCROLL_THRESHOLD_MULTIPLIER);
      if (Math.min(spaceAbove, spaceBelow) < scrollThreshold) {
        dialog.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      }
    }
  }, [position?.placement, refElement]);

  const { isFocusTrapActive } = useFocusTrap({
    open: true,
    zIndex: 401
  });

  const dialogContainerStyle = calculateTourStopDialogContainerStyle(position);

  return (
    <FocusTrap
      focusTrapOptions={{
        preventScroll: true,
        initialFocus: false,
        escapeDeactivates: false,
        allowOutsideClick: true
      }}
      active={isFocusTrapActive}
    >
      <div
        role="dialog"
        aria-modal="true"
        aria-label={title}
        data-testid="tour-stop-dialog-container"
        className={styles.dialogContainer}
        style={dialogContainerStyle}
        ref={refElement}
      >
        {position?.placement && (
          <div
            data-testid="tour-stop-arrow"
            className={getClassNames({
              [styles.arrow]: true,
              [styles.arrow_topStart]: position.placement === 'topStart',
              [styles.arrow_topCenter]: position.placement === 'topCenter',
              [styles.arrow_topEnd]: position.placement === 'topEnd',
              [styles.arrow_bottomStart]: position.placement === 'bottomStart',
              [styles.arrow_bottomCenter]: position.placement === 'bottomCenter',
              [styles.arrow_bottomEnd]: position.placement === 'bottomEnd'
            })}
          ></div>
        )}

        <div data-testid="tour-stop-dialog" className={styles.dialog}>
          {(tag || pages) && (
            <div
              data-testid="tour-stop-progress-container"
              className={styles.tourProgressContainer}
            >
              {tag === 'new' && (
                <Badge data-testid="tour-stop-new-feature" variant="attention" showIcon={false}>
                  {t('common.tour.tags.new')}
                </Badge>
              )}

              {pages && (
                <div data-testid="tour-stop-progress-indicator" className={styles.tourProgress}>
                  <span className={styles.tourProgressBefore}>
                    {pages.at > 1 && '•'.repeat(pages.at - 1)}
                  </span>
                  <span className={styles.tourProgressAt}>•</span>
                  <span className={styles.tourProgressAfter}>
                    {pages.at < pages.total && '•'.repeat(pages.total - pages.at)}
                  </span>
                </div>
              )}

              <div className={styles.tourProgressClear}></div>
            </div>
          )}

          {icon && (
            <div data-testid="tour-stop-icon" className={styles.stopIcon}>
              <Icon name={icon} size="md" />
            </div>
          )}

          <Heading.H4 data-testid="tour-stop-title" className={styles.stopTitle}>
            {title}
          </Heading.H4>

          <div data-testid="tour-stop-description" className={styles.stopDescription}>
            <TextWithInlineHtmlExtended text={description} />
          </div>

          <ActionButtonGroup data-testid="tour-stop-action-buttons">
            <Button
              data-testid="tour-action-primary"
              type="submit"
              variant="secondary"
              negative
              onClick={() =>
                dispatch({
                  type: variant === 'last-stop' ? TourActionType.FINISH : TourActionType.CONTINUE
                })
              }
            >
              {variant === 'last-stop' && (
                <Icon name="checkmark" size="sm" data-testid="tour-finish-icon" />
              )}

              {multiplex([
                t('common.tour.flow.start'),
                [variant === 'stop', t('common.tour.flow.next')],
                [variant === 'last-stop', t('common.tour.flow.finish')]
              ])}
            </Button>

            {variant !== 'last-stop' && (
              <Button
                data-testid="tour-action-secondary"
                className={styles.secondaryActionButton}
                type="button"
                variant="tertiary-purple"
                negative
                onClick={() =>
                  dispatch({
                    type: TourActionType.SKIP
                  })
                }
              >
                {t('common.tour.flow.skip')}
              </Button>
            )}
          </ActionButtonGroup>

          <Div data-testid="tour-stop-empty-focus" className={styles.stopEmptyFocus} tabIndex={0} />
        </div>
      </div>
    </FocusTrap>
  );
};

/**
 * Highlights a subelement of the current tour stop for an extra visual cue.
 */
const TourExtraHighlight: React.FC<{
  container: HTMLElement | null;
  subselector: string;
}> = ({ container, subselector }) => {
  const shouldRender = !!(container && subselector);
  const target = shouldRender ? (container.querySelector(subselector) as HTMLElement) : null;
  if (!target) {
    return null;
  }

  const bounds = target.getBoundingClientRect();
  return (
    <div
      className={styles.extraHighlight}
      style={{
        top: bounds.top + window.scrollY,
        left: bounds.left,
        width: bounds.width,
        height: bounds.height
      }}
    ></div>
  );
};

const MIN_DIALOG_WIDTH = 250;
const MAX_DIALOG_WIDTH = 600;

const DIALOG_CENTRED_X_POS = 'calc(50vw - min(50vw, 300px))';
const DIALOG_CENTRED_Y_POS = '50vh';
const DIALOG_EDGE_TO_ARROW = 32;

const DIALOG_Y = 16;
const DIALOG_ARRANGEMENT_POSITIONING = {
  top: {
    computeYPosition: (bounds: DOMRect) => bounds.top + window.scrollY - DIALOG_Y,
    startPlacement: 'topStart' as DialogPlacement,
    centerPlacement: 'topCenter' as DialogPlacement,
    endPlacement: 'topEnd' as DialogPlacement
  },
  bottom: {
    computeYPosition: (bounds: DOMRect) => bounds.bottom + window.scrollY + DIALOG_Y,
    startPlacement: 'bottomStart' as DialogPlacement,
    centerPlacement: 'bottomCenter' as DialogPlacement,
    endPlacement: 'bottomEnd' as DialogPlacement
  }
};

/**
 * Computes the position of the tour dialog so that it is arranged either
 * on the top or the bottom of the target element's bounding box based
 * on the desired arrangement, and makes efficient use of horizontal space.
 *
 * If there's enough room to centre the dialog horizontally given its possible
 * dimensions, it will be centred from on the target's midspot; otherwise, the
 * dialog will expand either to the left or to the right depending on available
 * outbound space. If there's also not enough outbound room, the dialog will be
 * centred horizontally based on the viewport. If a target element is inapplicable
 * (`null`), the dialog will be placed in the middle of the viewport without arrows
 * pointing towards a target element.
 *
 * The resulting position will also conveniently include a calculated clip, which
 * renders into a darkened frame around the target element when the target is
 * applicable. This clip helps bring the end-user's attention to the concrete
 * element a tour is stopping at.
 */
export function calculateDialogPosition({
  target,
  dialog,
  dialogArrangement: arrangement
}: {
  target: HTMLElement | null;
  dialog: HTMLDivElement | null;
  dialogArrangement: DialogArrangement;
}): DialogPositioning {
  if (!target || !dialog) {
    return {
      x: DIALOG_CENTRED_X_POS,
      y: DIALOG_CENTRED_Y_POS,
      arrangement: undefined,
      placement: undefined,
      clip: undefined
    };
  }

  const dialogWidth = Math.max(Math.min(dialog.offsetWidth, MAX_DIALOG_WIDTH), MIN_DIALOG_WIDTH);
  const dialogHalfWidth = dialogWidth >> 1;

  const targetHalfWidth = target.offsetWidth >> 1;
  const targetBounds = target.getBoundingClientRect();

  const viewWidth = window.innerWidth;
  const targetCentreSpaceLeft = targetBounds.left + targetHalfWidth;
  const targetCentreSpaceRight = viewWidth - targetCentreSpaceLeft;
  const outboundSpaceLeft = targetBounds.left;
  const outboundSpaceRight = viewWidth - targetBounds.right;

  const clip = calculateDialogTargetClip({ bounds: targetBounds });
  const positioning = DIALOG_ARRANGEMENT_POSITIONING[arrangement];
  const yPos = `${positioning.computeYPosition(targetBounds)}px`;

  if (targetCentreSpaceLeft > dialogHalfWidth && targetCentreSpaceRight > dialogHalfWidth) {
    // Position in the centre of the target
    return {
      x: `${targetCentreSpaceLeft - dialogHalfWidth}px`,
      y: yPos,
      arrangement,
      placement: positioning.centerPlacement,
      clip
    };
  } else if (
    outboundSpaceLeft > dialogWidth - targetHalfWidth - DIALOG_EDGE_TO_ARROW &&
    outboundSpaceRight > DIALOG_EDGE_TO_ARROW
  ) {
    // Position dialog at the end, expanding left if there's free space to the left
    return {
      x: `${targetBounds.left + targetHalfWidth - dialogWidth + DIALOG_EDGE_TO_ARROW}px`,
      y: yPos,
      arrangement,
      placement: positioning.endPlacement,
      clip
    };
  } else if (
    outboundSpaceRight > dialogWidth - targetHalfWidth - DIALOG_EDGE_TO_ARROW &&
    outboundSpaceLeft > DIALOG_EDGE_TO_ARROW
  ) {
    // Position dialog at the start, expanding right if there's free space to the right
    return {
      x: `${targetBounds.left + targetHalfWidth - DIALOG_EDGE_TO_ARROW}px`,
      y: yPos,
      arrangement,
      placement: positioning.startPlacement,
      clip
    };
  } else {
    // Position in the horizontal centre of the viewport
    return {
      x: `${(viewWidth - dialog.offsetWidth) >> 1}px`,
      y: yPos,
      arrangement,
      placement: positioning.centerPlacement,
      clip
    };
  }
}

/**
 * Calculates a clip for highlighting a target within a darkened frame with a
 * small padding around the target. The clip defines the frame and helps bring
 * the end-user's attention to the concrete element a tour is stopping at.
 *
 * @privateRemarks
 * The resulting clip is a polygon() CSS function with vertices calculated as
 * follows:
 * ```
 * (A)──────────────────────────(J)
 *  │████████████████████████████│
 *  │████████████████████████████│
 *  │████████████████████████████│
 *  │███(D)────────────────(E)███│
 *  │████│                  │████│
 *  │████│     Target       │████│
 *  │████│     element      │████│
 *  │████│                  │████│
 *  │███(G)────────────────(F)███│
 *  │████│███████████████████████│
 *  │████│███████████████████████│
 *  │████│███████████████████████│
 * (B)─(C/H)────────────────────(I)
 * ```
 * Edges CD and GH touch to close the polygon. To ensure that rounding in a
 * zoomed-in browser window does not end up with edges not touching one another
 * and rendering empty space between them, no diagonal edges are used.
 */
function calculateDialogTargetClip({ bounds }: { bounds: DOMRect }) {
  const clipPadding = 5;
  const c = {
    top: Math.min(bounds.top - clipPadding) + window.scrollY,
    right: Math.max(bounds.right + clipPadding) + window.scrollX,
    left: Math.min(bounds.left - clipPadding) + window.scrollX,
    bottom: Math.max(bounds.bottom + clipPadding) + window.scrollY
  };

  return `polygon(${[
    '0% 0%',
    '0% 100%',
    `${c.left}px 100%`,
    `${c.left}px ${c.top}px`,
    `${c.right}px ${c.top}px`,
    `${c.right}px ${c.bottom}px`,
    `${c.left}px ${c.bottom}px`,
    `${c.left}px 100%`,
    '100% 100%',
    '100% 0%'
  ].join(', ')})`;
}

export function calculateTourStopDialogContainerStyle(position: DialogPositioning): CSSProperties {
  return {
    position: position.arrangement ? 'absolute' : 'fixed',
    top: position.y,
    left: position.x,
    transform: multiplex([
      'translateY(-50%)',
      [position?.arrangement === 'top', 'translateY(-100%)'],
      [position?.arrangement === 'bottom', 'translateY(0%)']
    ])
  };
}
