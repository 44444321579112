import BillingGroupForm from 'B2XApp/Invoicing/BillingGroups/BillingGroupForm/BillingGroupForm';
import { BillingGroupFormData } from 'B2XApp/Invoicing/BillingGroups/BillingGroupForm/useBillingGroupForm';
import { TeliaLink } from 'common-components';
import ConfirmationPanelError from 'common-components/ConfirmationPanel/ConfirmationPanelError';
import ConfirmationPanelSuccess from 'common-components/ConfirmationPanel/ConfirmationPanelSuccess';
import { invoicing } from 'doings/track/analyticsEvents';
import useSearchParams from 'hooks/useSearchParams/useSearchParams';
import { Trans, useTranslation } from 'react-i18next';

import { EditBillingGroupPrerequisites } from './useEditBillingGroupForm';

const EditBillingGroupPageForm: React.FC<{
  form: BillingGroupFormData;
  prerequisites: EditBillingGroupPrerequisites;
}> = ({ form, prerequisites }) => {
  const { t } = useTranslation();
  const { preserveBackPathState } = useSearchParams();

  const { collectionGroup } = prerequisites;
  const collectionGroupId = collectionGroup.collectionGroup;
  const { step } = form;

  switch (step) {
    case 'unavailable':
    case 'prechecking':
    case 'precheck-failure':
      return (
        <ConfirmationPanelError
          title={t('invoices.billingGroup.form.edit.notification.unchangeableTitle', {
            collectionGroupId
          })}
          description={t('invoices.billingGroup.form.edit.notification.unchangeableDescription')}
          backToInitialStepOnClick={form.onCancel}
          backToLabel={t('invoices.billingGroup.form.edit.notification.backToBillingGroup')}
          data-testid="collection-group-edit__unavailable"
        />
      );

    case 'initial':
    case 'persisting':
    case 'failure':
      return (
        <BillingGroupForm
          collectionGroup={collectionGroup}
          form={form}
          failureNoticeOnPersisting={{
            title: t('invoices.billingGroup.form.edit.notification.failedToUpdateTitle'),
            content: t('invoices.billingGroup.form.edit.notification.failedToUpdateDescription')
          }}
          analyticsEvent={invoicing.updateCollectionGroup}
          data-testid="collection-group-edit__form"
          pageType="edit"
        />
      );

    case 'success':
      return (
        <ConfirmationPanelSuccess
          title={t('invoices.billingGroup.form.edit.notification.successTitle', {
            collectionGroupId
          })}
          description={t('invoices.billingGroup.form.edit.notification.successDescription')}
          backToPath={preserveBackPathState(`/billing-accounts/${collectionGroupId}`, true)}
          backToLabel={t('invoices.billingGroup.form.edit.notification.backToBillingGroup')}
          data-testid="collection-group-edit__success"
        />
      );

    case 'partial-success':
      return (
        <ConfirmationPanelSuccess
          title={t('invoices.billingGroup.form.edit.notification.successTitle', {
            collectionGroupId
          })}
          description={
            <Trans
              i18nKey="serviceRequests.newServiceRequest.successDescriptionWithLink.description"
              components={[
                <TeliaLink.Internal
                  key="link-to-service-requests-list"
                  variant="text"
                  to="/service-requests/"
                  label={t('serviceRequests.newServiceRequest.successDescriptionWithLink.link')}
                />
              ]}
            />
          }
          backToPath={preserveBackPathState(`/billing-accounts/${collectionGroupId}`, true)}
          backToLabel={t('invoices.billingGroup.form.edit.notification.backToBillingGroup')}
          data-testid="collection-group-edit__partial-success"
        />
      );
  }
};

export default EditBillingGroupPageForm;
