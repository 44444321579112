import ErrorPageLayout from 'B2XApp/Errors/Error/ErrorPageLayout/ErrorPageLayout';
import { TeliaLink } from 'common-components';
import { useTranslation } from 'react-i18next';

import pageNotFoundImg from './page-not-found.svg';

const NotFound: React.FC<{ hideBreadcrumb?: boolean }> = ({ hideBreadcrumb }) => {
  const { t } = useTranslation();
  return (
    <ErrorPageLayout
      hideBreadcrumb={hideBreadcrumb}
      imageSrc={pageNotFoundImg}
      headlineKey="errors.pageNotFound.headline"
      bodyKey="errors.pageNotFound.body"
      translatables={[
        <TeliaLink.Internal
          key="not-found-error"
          data-testid="home-page-suggestion-link"
          variant="text"
          to="/"
          label={t('errors.pageNotFound.frontPageLink')}
        />
      ]}
    />
  );
};

export default NotFound;
