import { GridBag, Gutter } from '@gaiads/telia-react-component-library';
import { Icon } from '@teliafi/fi-ds';
import { DynamicFormState } from 'B2XApp/DynamicForms/DynamicFormMain';
import useDynamicFormMain from 'B2XApp/DynamicForms/useDynamicFormMain';
import { TicketFileUploader, useTicketAttachments } from 'B2XApp/Messages/common';
import {
  ActionButtonGroup,
  Button,
  DataPanel,
  DetailsLoadingOverlay,
  Forms,
  Heading,
  InlineNotification
} from 'common-components';
import { useUserData } from 'contexts/UserContext/UserContext';
import getRelatedCompaniesForDropdown from 'doings/getRelatedCompaniesForDropdown/getRelatedCompaniesForDropdown';
import { scrollElementIntoView } from 'doings/scroll/scrollElementIntoView';
import { normaliseCsvString } from 'doings/string/normaliseCsvString';
import { messages } from 'doings/track/analyticsEvents';
import trackEvent from 'doings/track/trackEvent';
import { SUPPORTED_FILE_TYPES } from 'doings/validateMessageAttachment/validateMessageAttachment';
import { Form, Formik, FormikContextType, FormikErrors } from 'formik';
import { CreateTicketParameters } from 'hooks/tickets/useCreateTicket/useCreateTicket';
import { CreateMessageSavingStatus } from 'hooks/tickets/useCreateTicketSteps/useCreateTicketSteps';
import { useConfirmationModal } from 'hooks/useCustomModal/useConfirmationModal';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from 'types/product';

import getTicketContactMethodOptions from './getTicketContactMethodOptions';
import {
  CreateTicketFormValues,
  getTicketFormValidationSchema
} from './getTicketFormValidationSchema';

export type TicketOrigin = {
  type: 'asset' | 'subscription';
  companyId?: string;
  subscriptionId?: string;
};

interface CreateTicketFormProps {
  productList: Product[];
  onSubmit: (values: CreateTicketParameters) => void;
  dynamicFormStateRef: React.MutableRefObject<DynamicFormState | undefined>;
  staticFormStateRef: React.MutableRefObject<CreateTicketFormValues | undefined>;
  saving: CreateMessageSavingStatus;
  savingReset: VoidFunction;
  activeGroupType: ActiveGroupType;
  caseOrigin: TicketOrigin | undefined;
}

const VARIANTS = {
  B2B: {
    dynamicFormGroupName: 'incident_tickets' as const,
    contactGroupTitleKey: 'tickets.newTicket.contactGroup',
    contactGroupDescriptionKey: 'serviceRequests.newServiceRequest.contactInfoText',
    contactFirstNameKey: 'common.firstName.label',
    contactLastNameKey: 'common.familyName.label',
    emailInputType: 'input' as const
  },
  B2O: {
    dynamicFormGroupName: 'incident_tickets_b2o' as const,
    contactGroupTitleKey: 'tickets.newTicket.wholesale.contactGroup',
    contactGroupDescriptionKey: 'tickets.newTicket.wholesale.contactGroupDescription',
    contactFirstNameKey: 'tickets.newTicket.wholesale.reporterFirstName',
    contactLastNameKey: 'tickets.newTicket.wholesale.reporterLastName',
    emailInputType: 'csvInput' as const
  }
};

const NewTicketFormDynamic = ({
  caseOrigin,
  productList,
  onSubmit,
  dynamicFormStateRef,
  staticFormStateRef,
  saving,
  savingReset,
  activeGroupType
}: CreateTicketFormProps) => {
  const { t } = useTranslation();
  const valueChanges = useRef<Record<string, unknown>>({});
  const { setConfirmationModal, onClick } = useConfirmationModal();

  const b2bForm = ['b2b', 'b2b_sales_portal'].includes(activeGroupType);
  const variant = VARIANTS[b2bForm ? 'B2B' : 'B2O'];

  const { allCompanies, activeGroupId, email, businessPhoneNumber, firstName, lastName } =
    useUserData();
  const contactMethodOptions = getTicketContactMethodOptions(t);
  const fixedValues = useMemo(
    () => ({ subscriptionId: caseOrigin?.subscriptionId }),
    [caseOrigin?.subscriptionId]
  );

  const {
    dynamicForm,
    isDynamicFormValid,
    onDynamicFormPresubmitCallback,
    onDynamicFormSubmitCallback,
    dynamicValues,
    appliedMetainfo: { contactFieldSettings }
  } = useDynamicFormMain({
    groupName: variant.dynamicFormGroupName,
    formStateRef: dynamicFormStateRef,
    fixedValues
  });

  const company = allCompanies.find((company) => company.companyCode === caseOrigin?.companyId);
  const { selectedGroupId, availableCompanies } = getRelatedCompaniesForDropdown(
    {
      onePermission: 'INCIDENT_TICKETS',
      activeGroup: activeGroupType
    },
    company?.groupId || activeGroupId
  );

  const contactAddressFieldsShown = contactFieldSettings?.showAddressFields ?? false;
  const attachmentModel = useTicketAttachments();
  const validationSchema = useMemo(
    () => getTicketFormValidationSchema({ t, contactAddressFieldsShown }),
    [t, contactAddressFieldsShown]
  );

  const formRef = useRef<HTMLFormElement>(null);
  const checkForm = (staticFieldErrors: FormikErrors<CreateTicketFormValues>) => {
    const invalidDynamicFormFields = onDynamicFormPresubmitCallback();
    const invalidFields: string[] = [];
    (['company', 'subject'] as const)
      .filter((fieldName) => !!staticFieldErrors[fieldName])
      .forEach((fieldName) => invalidFields.push(fieldName));

    invalidFields.push(...invalidDynamicFormFields);
    const staticFields = contactAddressFieldsShown
      ? ([
          'contactFirstName',
          'contactLastName',
          'contactEmail',
          'contactPhone',
          'contactStreet',
          'contactPostalCode',
          'contactPostalOffice',
          'contactMethods'
        ] as const)
      : ([
          'contactFirstName',
          'contactLastName',
          'contactEmail',
          'contactPhone',
          'contactMethods'
        ] as const);

    staticFields
      .filter((fieldName) => !!staticFieldErrors[fieldName])
      .forEach((fieldName) => invalidFields.push(fieldName));

    if (invalidFields.length > 0) {
      const topmostField = invalidFields[0];
      scrollElementIntoView({
        elementRef: formRef,
        targetQuery: `[data-name="${topmostField}"] input, [name="${topmostField}"], [data-name="${topmostField}"]`,
        showHighlight: true
      });
    }
  };

  const submitForm = (args: CreateTicketFormValues) => {
    // Static field errors are checked before this function is ever invoked.
    // Dynamic form invokes both `checkForm` and `submitForm`, so mustn't continue on validation errors.
    if (!attachmentModel.isValid || !isDynamicFormValid) {
      return;
    }

    const dynamicContent = onDynamicFormSubmitCallback();
    const { product } = dynamicContent;
    const productId = product.substring(0, (product + '$').indexOf('$'));

    const requestData: CreateTicketParameters = {
      contactEmail: normaliseCsvString(args.contactEmail),
      contactFirstName: args.contactFirstName,
      contactLastName: args.contactLastName,
      contactPhone: args.contactPhone,
      contactMethods: args.contactMethods ?? [],
      incidentAddress: contactAddressFieldsShown
        ? {
            street: args.contactStreet,
            postalCode: args.contactPostalCode,
            postalOffice: args.contactPostalOffice
          }
        : {
            street: dynamicContent.incidentAddressStreet,
            postalCode: dynamicContent.incidentAddressPostalCode,
            postalOffice: dynamicContent.incidentAddressPostalOffice
          },
      subject: args.subject,
      description: dynamicContent.description,
      userFirstName: firstName ?? '',
      userLastName: lastName ?? '',
      userEmail: email,
      userPhone: businessPhoneNumber ?? '',
      subscriptionId: dynamicContent.subscriptionId ?? '',
      company: args.company ?? '',
      productId,
      priority: dynamicContent.priority,
      productName: productList.find((p) => p.contractOfferingId === productId)?.name ?? '',
      attachment: attachmentModel.attachments[0]
    };

    dynamicFormStateRef.current = dynamicContent.state;
    staticFormStateRef.current = args;
    trackEvent(messages.submitTroubleTicket);
    onSubmit(requestData);
  };

  const onFormChange = (
    e: React.FormEvent<HTMLFormElement>,
    initialValues: Record<string, unknown>
  ) => {
    const value = (e.target as HTMLInputElement).value;
    const name = (e.target as HTMLInputElement).name;
    valueChanges.current[name] = value !== initialValues[name] ? value : '';
    setConfirmationModal({
      ...valueChanges.current,
      ...dynamicValues,
      attachment: attachmentModel.attachments[0]?.name
    });
  };

  useEffect(() => {
    setConfirmationModal({
      ...valueChanges.current,
      ...dynamicValues,
      attachment: attachmentModel.attachments[0]?.name
    });
  }, [dynamicValues, attachmentModel, setConfirmationModal]);

  return (
    <DataPanel data-testid="form-new-request">
      <DetailsLoadingOverlay showLoader={saving === 'yes'}>
        <Formik
          data-testid="formik"
          validateOnChange
          validateOnBlur
          validateOnMount
          initialValues={
            staticFormStateRef.current ?? {
              company: selectedGroupId ?? '',
              subject: '',
              productName: '',
              description: '',
              contactFirstName: firstName,
              contactLastName: lastName,
              contactEmail: email,
              contactPhone: businessPhoneNumber,
              contactMethods: ['email']
            }
          }
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values: CreateTicketFormValues) => submitForm(values)}
        >
          {({
            isValid,
            errors: staticFieldErrors,
            initialValues
          }: FormikContextType<CreateTicketFormValues>) => (
            <Form
              onChange={(e: React.FormEvent<HTMLFormElement>) => onFormChange(e, initialValues)}
              ref={formRef}
              data-testid="form-new-request-form"
            >
              <Forms.RequiredFieldsLabel float />

              <Heading.H4>{t('tickets.newTicket.fields.detailsGroup')}</Heading.H4>

              <Gutter size="sm" />

              <GridBag>
                <GridBag.Item md={12}>
                  <Forms.Field
                    testId="company-input"
                    type="select"
                    options={availableCompanies}
                    required
                    disabled={availableCompanies.length <= 1 || !!caseOrigin?.companyId}
                    name="company"
                    label={t('tickets.newTicket.fields.relatedCompany')}
                    forceNoCombobox
                  />
                </GridBag.Item>

                <GridBag.Item md={12}>
                  <Forms.Field
                    testId="subject-input"
                    type="input"
                    required
                    name="subject"
                    label={t('tickets.newTicket.fields.subject')}
                  />
                </GridBag.Item>
              </GridBag>

              <Gutter size="md" />

              {dynamicForm}

              <Gutter size="xlg" />

              <Heading.H4>{t(variant.contactGroupTitleKey)}</Heading.H4>

              <Gutter size="sm" />

              <InlineNotification
                variant="information"
                content={{
                  html: contactFieldSettings?.info ?? t(variant.contactGroupDescriptionKey)
                }}
              />

              <Gutter size="md" />

              <GridBag>
                <GridBag.Item md={12}>
                  <Forms.Field
                    testId="first-name-input"
                    type="input"
                    required
                    name="contactFirstName"
                    label={t(variant.contactFirstNameKey)}
                  />
                </GridBag.Item>

                <GridBag.Item md={12}>
                  <Forms.Field
                    testId="last-name-input"
                    type="input"
                    required
                    name="contactLastName"
                    label={t(variant.contactLastNameKey)}
                  />
                </GridBag.Item>

                <GridBag.Item md={12}>
                  {variant.emailInputType === 'input' && (
                    <Forms.Field
                      testId="email-address-input"
                      type="input"
                      required
                      name="contactEmail"
                      label={t('common.email.label')}
                    />
                  )}

                  {variant.emailInputType === 'csvInput' && (
                    <Forms.Field
                      testId="email-address-input"
                      type="csvInput"
                      required
                      name="contactEmail"
                      label={t('common.email.label')}
                      helperText={t('common.email.multipleHelperText', { max: 10 })}
                      maxItems={10}
                    />
                  )}
                </GridBag.Item>

                <GridBag.Item md={12}>
                  <Forms.Field
                    testId="phone-number-input"
                    type="input"
                    required
                    name="contactPhone"
                    label={t('common.phone.label')}
                  />
                </GridBag.Item>

                {contactAddressFieldsShown && (
                  <GridBag.Item md={12}>
                    <Forms.Field
                      testId="contact-address-street-input"
                      type="input"
                      required
                      name="contactStreet"
                      label={t('tickets.newTicket.fields.contactStreet')}
                    />
                  </GridBag.Item>
                )}

                {contactAddressFieldsShown && (
                  <GridBag.Item xs={12} sm={5} md={5}>
                    <Forms.Field
                      testId="contact-address-postal-code-input"
                      type="input"
                      required
                      name="contactPostalCode"
                      label={t('tickets.newTicket.fields.contactPostalCode')}
                    />
                  </GridBag.Item>
                )}

                {contactAddressFieldsShown && (
                  <GridBag.Item xs={12} sm={7} md={7}>
                    <Forms.Field
                      testId="contact-address-postal-office-input"
                      type="input"
                      required
                      name="contactPostalOffice"
                      label={t('tickets.newTicket.fields.contactPostalOffice')}
                    />
                  </GridBag.Item>
                )}

                {activeGroupType !== 'b2b_b2o' && (
                  <GridBag.Item md={12}>
                    <Forms.Field
                      type="multiselect"
                      options={contactMethodOptions}
                      name="contactMethods"
                      label={t('tickets.contactMethod.label')}
                    />
                  </GridBag.Item>
                )}

                <GridBag.Item md={12}>
                  <TicketFileUploader
                    supportedFileTypesForMessages={SUPPORTED_FILE_TYPES}
                    attachmentsModel={attachmentModel}
                  />
                </GridBag.Item>
              </GridBag>

              {saving === 'failure' && (
                <>
                  <InlineNotification
                    variant="error"
                    title={t('tickets.newTicket.error.title')}
                    content={t('tickets.newTicket.error.description')}
                    onClose={savingReset}
                  />

                  <Gutter />
                </>
              )}

              <ActionButtonGroup>
                <Button
                  data-testid="create-ticket-button"
                  data-valid={isValid && attachmentModel.isValid && isDynamicFormValid}
                  disabled={saving === 'yes'}
                  onClick={() => checkForm(staticFieldErrors)}
                  variant="primary"
                  type="submit"
                  size="sm"
                >
                  {t('tickets.newTicket.send')}

                  <Icon
                    name={
                      isValid && attachmentModel.isValid && isDynamicFormValid
                        ? 'check-circle'
                        : 'error'
                    }
                    size="md"
                    data-testid="submit-state-icon"
                  />
                </Button>

                <Button
                  data-testid="cancel-ticket-button"
                  disabled={saving === 'yes'}
                  onClick={() => onClick('/tickets')}
                  variant="tertiary-purple"
                  type="button"
                  size="sm"
                >
                  {t('common.cancelButton.label')}
                </Button>
              </ActionButtonGroup>
            </Form>
          )}
        </Formik>
      </DetailsLoadingOverlay>
    </DataPanel>
  );
};

export default NewTicketFormDynamic;
