export default (lock: boolean) => {
  const bodyStyle = document.body.style;
  if (!bodyStyle) {
    return;
  }

  const locked = bodyStyle.overflowY === 'hidden';
  if (!locked && lock) {
    bodyStyle.paddingRight = `${window.innerWidth - document.documentElement.clientWidth}px`;
    bodyStyle.overflowY = 'hidden';
  } else if (locked && !lock) {
    bodyStyle.paddingRight = '';
    bodyStyle.overflowY = '';
  }
};
