import { gql } from '@apollo/client';
import {
  SubscriptionAttribute,
  SubscriptionStatus
} from 'hooks/mobileSubscriptions/useSubscriptionDetails/subscriptionDetailsQuery';

export default gql`
  query GetMobileSubscriptions(
    $query: SubscriptionsQuery
    $offset: Int!
    $pageSize: Int!
    $sort: String
    $order: String
  ) {
    subscriptions(offset: $offset, pageSize: $pageSize, query: $query, sort: $sort, order: $order) {
      total
      items {
        id
        phoneNumber
        phoneNumberHash
        attributes
        status
        type
        packages {
          feature
          voice
          sms
          data
        }
        user {
          displayName
        }
        attributes
        isInTransfer
        hasFixedTermContract
        hasDuettoBundle
      }
    }
  }
`;

export type QueriedSubscription = {
  id: string;
  phoneNumber: string;
  phoneNumberHash: string;
  attributes: SubscriptionAttribute[];
  status: SubscriptionStatus;
  type: string;
  packages: SubscriptionPackages;
  user: null | {
    displayName: string | null;
  };
  isInTransfer: boolean;
  hasFixedTermContract: boolean;
  hasDuettoBundle: boolean;
};

export type SubscriptionsResponse = {
  subscriptions: {
    total: number;
    items: QueriedSubscription[];
  };
};

export type SubscriptionPackages = {
  feature?: string;
  voice?: string;
  sms?: string;
  data?: string;
};
