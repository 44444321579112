import { OffHourCallSchedulingNotice } from 'B2XApp/Messages/common';
import { PageWithFormLayout } from 'common-components';
import { messages } from 'doings/track/analyticsEvents';
import { useTranslation } from 'react-i18next';

import ProductsNotice from './ProductsNotice';

const CreateTicketPageContainer: React.FC<{
  backLinkVisible?: boolean;
  backLinkTo?: string;
  loading?: boolean;
  children: React.ReactNode;
  additionalInfo?: React.ReactNode;
}> = ({ backLinkVisible, backLinkTo, children, additionalInfo, loading }) => {
  const { t } = useTranslation();

  return (
    <PageWithFormLayout
      data-testid="create-ticket-page"
      title={t('tickets.newTicket.title')}
      backLinkVisible={backLinkVisible}
      additionalInfo={additionalInfo}
      notification={[
        <OffHourCallSchedulingNotice key="offhour" trackOnLink={messages.bookCallFromNewTT} />,
        <ProductsNotice key="products" />
      ]}
      loading={loading}
      to={backLinkTo ?? '/tickets'}
    >
      {children}
    </PageWithFormLayout>
  );
};

export default CreateTicketPageContainer;
