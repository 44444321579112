import useAvailableBanks from 'hooks/invoicing/billingGroups/useAvailableBanks/useAvailableBanks';
import useAvailableOperators from 'hooks/invoicing/billingGroups/useAvailableOperators/useAvailableOperators';
import { LoadingStatus } from 'hooks/invoicing/billingGroups/useCollectionGroup/useCollectionGroup';

const mapStatus = (
  banks: ReturnType<typeof useAvailableBanks>,
  operators: ReturnType<typeof useAvailableOperators>
): LoadingStatus => {
  if (banks.error || operators.error) {
    return 'error';
  } else if (banks.loading || operators.loading) {
    return 'loading';
  } else {
    return 'loaded';
  }
};

/**
 * Retrieves information required to render a new billing account
 * form: available banks and operators for electronic invoices. If
 * any information cannot be  retrieved, fails gracefully by
 * returning an error status.
 *
 * The presence of a default collection group will later be retrieved
 * for the company that the user wants to create the billing account
 * for (a company with a default collection group cannot have a
 * billing account opened via self-service portals).
 */
const useNewBillingGroupData = () => {
  const banksResult = useAvailableBanks();
  const operatorsResult = useAvailableOperators();
  const status = mapStatus(banksResult, operatorsResult);

  return {
    status,
    availableBanks: banksResult.availableBanks,
    availableOperators: operatorsResult.availableOperators
  };
};

export default useNewBillingGroupData;
