import { noOp } from 'doings/noOp/noOp';
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';

const PX_BETWEEN_TOGGLE_BUTTON_AND_MENU = 4;

type OverflowMenuState = {
  openMenu: string;
  closeMenus: VoidFunction;
  toggleMenu: (id: string) => void;
  containerRef: React.RefObject<HTMLDivElement>;
  menuRef: React.RefObject<HTMLDivElement>;
};

export const OverflowMenuContext = createContext<OverflowMenuState | undefined>(undefined);

const OverflowMenuProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [openMenu, setOpenMenu] = useState<string>('');
  const containerRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const closeMenus = useCallback(() => {
    setOpenMenu('');
  }, [setOpenMenu]);

  const toggleMenu = useCallback(
    (id: string) => {
      setOpenMenu(openMenu === id ? '' : id);
    },
    [setOpenMenu, openMenu]
  );

  const onReposition = useCallback(() => {
    if (openMenu !== '') {
      const container = containerRef.current;
      /* istanbul ignore next */
      if (!container) {
        return;
      }

      const menu = menuRef.current;
      /* istanbul ignore next */
      if (!menu) {
        return;
      }

      const toggleContainer = container.querySelector('[data-overflow-menu-node="active"]');
      /* istanbul ignore next */
      if (!toggleContainer) {
        return;
      }

      const toggleDiv = toggleContainer as HTMLDivElement;
      const toggleY = toggleDiv.offsetTop + toggleDiv.offsetHeight;
      const toggleX = toggleDiv.offsetLeft + toggleDiv.offsetWidth;
      const menuWidth = menu.offsetWidth;

      menu.style.top = `${toggleY + PX_BETWEEN_TOGGLE_BUTTON_AND_MENU}px`;
      menu.style.left = `${Math.max(0, toggleX - menuWidth)}px`;
    }
  }, [openMenu, containerRef, menuRef]);

  useEffect(() => {
    onReposition();
  }, [onReposition]);

  const isMenuOpen = openMenu.length > 0;
  const onEscListener = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      setOpenMenu('');
    }
  }, []);

  const onOutsideClickListener = useCallback(
    (e: MouseEvent) => {
      const menu = menuRef.current;
      /* istanbul ignore next */
      if (!menu) {
        return;
      }

      if (e.target !== null && !menu.contains(e.target as Node)) {
        setOpenMenu('');
      }
    },
    [menuRef]
  );

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener('keyup', onEscListener);
      document.addEventListener('click', onOutsideClickListener);
      window.addEventListener('resize', onReposition);

      return () => {
        document.removeEventListener('keyup', onEscListener);
        document.removeEventListener('click', onOutsideClickListener);
        window.removeEventListener('resize', onReposition);
      };
    }

    return noOp;
  }, [isMenuOpen, onEscListener, onOutsideClickListener, onReposition]);

  return (
    <OverflowMenuContext.Provider
      value={{
        openMenu,
        closeMenus,
        toggleMenu,
        containerRef,
        menuRef
      }}
    >
      {children}
    </OverflowMenuContext.Provider>
  );
};

const useOverflowMenuState = () => {
  const context = useContext(OverflowMenuContext);
  if (context === undefined) {
    throw new Error('useOverflowMenuState must be used with OverflowMenuContext');
  }

  return context;
};

export { OverflowMenuProvider, useOverflowMenuState };
