import { Div, Panel } from '@gaiads/telia-react-component-library';
import {
  EditableLinksModal,
  QUICK_LINKS_MODAL
} from 'B2XApp/Modals/EditableLinksModal/EditableLinksModal';
import Banners from 'B2XApp/Notifications/Banners';
import getClassNames from 'classnames';
import { Optional } from 'common-components';
import { useFeatureFlagsData } from 'contexts/FeatureFlagContext/FeatureFlagContext';
import { useUserData } from 'contexts/UserContext/UserContext';
import getNavigationalLinks, {
  NavigationalLink,
  NavigationalLinks
} from 'doings/getNavigationalLinks/getNavigationalLinks';
import userHasPermission from 'doings/userHasPermission/userHasPermission';
import useEditableSectionItems, {
  SectionItem
} from 'hooks/editableSections/useEditableSectionItems';
import { useNotifications } from 'hooks/notifications/useNotifications/useNotifications';
import useCustomModal from 'hooks/useCustomModal/useCustomModal';
import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  canShowFeature,
  mobileNetworkQualityVisibilityConditions,
  notificationVisibilityConditions,
  pukCodeSearchVisibilityConditions
} from './DashboardNavigation';
import styles from './DashboardNavigation.module.scss';
import { DashboardNavigationB2BQuickLinks } from './DashboardNavigationB2BQuickLinks';
import DashboardNotifications from './DashboardNotifications/DashboardNotifications';
import DashboardSearchPanel from './DashboardSearch/DashboardSearchPanel';
import sectionStyles from './DashboardSections/DashboardSection.module.scss';
import MobileNetworkQualityPanel from './MobileNetworkQuality/MobileNetworkQualityPanel';

export const DashboardNavigationB2B: React.FC = () => {
  const user = useUserData();
  const { t } = useTranslation();
  const { hasFeature } = useFeatureFlagsData();
  const { banners, loaded: notificationsLoaded } = useNotifications();
  const canShowNotifications = canShowFeature(user, hasFeature, notificationVisibilityConditions);
  const canShowQuality = canShowFeature(user, hasFeature, mobileNetworkQualityVisibilityConditions);
  const canShowPukCodeSearch = canShowFeature(user, hasFeature, pukCodeSearchVisibilityConditions);
  const quickLinksModal = useCustomModal(QUICK_LINKS_MODAL);
  const { items, loading } = useEditableSectionItems('quick-links');
  const { navigationalLinks } = getNavigationalLinks(user);

  const { links, isEnabled, isNew, hasCustomLinks, defaultLinks } = useMemo(
    () =>
      determineDisplayedDashboardLinks({
        navigationalLinks,
        items,
        user,
        t
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );

  return (
    <>
      <Div className={styles.dashboardItems}>
        <Div className={styles.column}>
          <Div
            className={getClassNames([styles.dashboardItem, styles.dashboardItem_index_3])}
            data-testid="quick-links-section"
          >
            <Panel className={sectionStyles.panel} margin={{ bottom: 'sm' }}>
              <Panel.Content padding={{ size: 'xxs' }} className={sectionStyles.panel_content}>
                <DashboardNavigationB2BQuickLinks
                  links={hasCustomLinks ? links.filter(({ id }) => isEnabled(id)) : defaultLinks}
                  loading={loading}
                  openModal={quickLinksModal.openModal}
                />
              </Panel.Content>
            </Panel>
          </Div>

          <Optional if={canShowPukCodeSearch} data-testid="dashboard-search-optional">
            <Div
              className={getClassNames([styles.dashboardItem, styles.dashboardItem_index_4])}
              data-testid="dashboard-search"
            >
              <DashboardSearchPanel shouldScroll={notificationsLoaded} />
            </Div>
          </Optional>
        </Div>

        <Div className={styles.column}>
          <Optional if={canShowNotifications} data-testid="dashboard-notifications-optional">
            <Div
              className={getClassNames([styles.dashboardItem, styles.dashboardItem_index_1])}
              data-testid="dashboard-notifications"
            >
              <DashboardNotifications />
            </Div>
          </Optional>

          <Optional data={banners} data-testid="dashboard-promo-banners-optional">
            <Div
              className={getClassNames([styles.dashboardItem, styles.dashboardItem_index_2])}
              data-testid="dashboard-promo-banners"
            >
              <Banners banners={banners} />
            </Div>
          </Optional>

          <Optional if={canShowQuality} data-testid="dashboard-network-quality-optional">
            <Div
              className={getClassNames([styles.dashboardItem, styles.dashboardItem_index_5])}
              data-testid="dashboard-network-quality"
            >
              <MobileNetworkQualityPanel />
            </Div>
          </Optional>
        </Div>
      </Div>

      <EditableLinksModal
        section="quick-links"
        onCloseClick={quickLinksModal.closeModal}
        isOpen={quickLinksModal.isOpen}
        items={links.map((link) => ({
          id: link.id,
          enabled: isEnabled(link.id),
          label: t(link.textI18nKey),
          isNew: isNew(link.id)
        }))}
      />
    </>
  );
};

const determineDisplayedDashboardLinks = ({
  navigationalLinks,
  items,
  user,
  t
}: {
  navigationalLinks: NavigationalLinks;
  items: SectionItem[];
  user: User;
  t: TFunction;
}) => {
  const allDashboardLinks = Object.values(navigationalLinks).filter(
    (link) =>
      (!link.isB2OLink || user.activeGroupType === 'b2b_sales_portal') &&
      link.isAvailable &&
      link.isQuickLink
  );
  const sortedLinks = (links: NavigationalLink[]) =>
    links.toSorted((a, b) => t(a.textI18nKey).localeCompare(t(b.textI18nKey)));

  const defaultLinks = sortedLinks(allDashboardLinks.filter((link) => link.defaultQuickLink));
  const hasDefaultLinkById = (id: string) => defaultLinks.some((link) => link.id === id);
  const hasPermission = userHasPermission(user);
  const mapLinks = (items: NavigationalLink[]) =>
    items.map(
      (item) =>
        ({
          ...item,
          nestUnder: undefined,
          disabled: !hasPermission(item.visibleWhen)
        } as NavigationalLink)
    );

  const hasCustomLinks = !!items.length;
  if (!hasCustomLinks) {
    return {
      links: sortedLinks(mapLinks(allDashboardLinks)),
      isEnabled: (id: string) => hasDefaultLinkById(id),
      isNew: () => false,
      hasCustomLinks: false,
      defaultLinks
    };
  }

  const { enabledLinks, itemOrder } = items.reduce(
    (acc, item, index) => {
      if (item.enabled) {
        acc.enabledLinks.add(item.id);
      }
      acc.itemOrder.set(item.id, index);
      return acc;
    },
    { enabledLinks: new Set<string>(), itemOrder: new Map<string, number>() }
  );

  const isEnabled = (id: string) => enabledLinks.has(id);
  const isNew = (id: string) => !itemOrder.has(id);
  const links = mapLinks(allDashboardLinks).sort((a, b) => {
    const itemOrderA = itemOrder.get(a.id) ?? allDashboardLinks.length;
    const itemOrderB = itemOrder.get(b.id) ?? allDashboardLinks.length;
    const result = itemOrderA - itemOrderB;
    return result !== 0 ? result : t(a.textI18nKey).localeCompare(t(b.textI18nKey));
  });

  return { links, isEnabled, isNew, hasCustomLinks, defaultLinks };
};
