import Skeleton from 'common-components/Skeleton/Skeleton';
import { Div } from 'core-components';

import styles from './DataPanel.module.scss';

type DataPanelImageProps = {
  imageSrc?: string;
  imageLoading?: boolean;
  altText?: string;
  'data-testid'?: string;
};

const DataPanelImage: React.FC<DataPanelImageProps> = ({
  imageSrc,
  imageLoading,
  altText,
  'data-testid': testId
}) => {
  if (imageLoading) {
    return (
      <Div className={styles.dataPanelImage} data-testid={testId}>
        <Skeleton.Image width="90px" height="67px" display="inline-block" />
      </Div>
    );
  }

  if (!imageLoading && imageSrc) {
    return (
      <img className={styles.dataPanelImage} src={imageSrc} alt={altText} data-testid={testId} />
    );
  }

  return null;
};

export default DataPanelImage;
