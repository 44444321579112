export enum DashboardSectionId {
  BusinessServices = 'BusinessServices',
  Communication = 'Communication',
  Invoicing = 'Invoicing',
  Offering = 'Offering',
  OldCorporatePortal = 'OldCorporatePortal',
  OrdersAndRfqs = 'OrdersAndRfqs',
  QuickLinks = 'QuickLinks',
  Reports = 'Reports',
  SubscriptionsAndDevices = 'SubscriptionsAndDevices',
  TeliaAccountManagement = 'TeliaAccountManagement',
  WholesaleServices = 'WholesaleServices'
}
