import { Gutter, Image } from '@gaiads/telia-react-component-library';
import { Badge, Icon } from '@teliafi/fi-ds';
import getClassNames from 'classnames';
import {
  Analytics,
  Button,
  DataPanel,
  Heading,
  Optional,
  TextWithInlineHtml
} from 'common-components';
import ButtonLink from 'common-components/Link/ButtonLink/ButtonLink';
import { Div } from 'core-components';
import { AnalyticsEvent } from 'doings/track/analyticsEvents';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductNewsItem } from 'types/productNews';

import styles from './NewsDrawerContentCard.module.scss';
import NewsDrawerFeedbackForm from './NewsDrawerFeedback';
import NewsDrawerRating from './NewsDrawerRating';

const NewsDrawerContentCard: React.FC<{
  item: ProductNewsItem;
  seen: boolean;
  trackOnReadMore: AnalyticsEvent;
}> = ({ item, seen, trackOnReadMore }) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(seen);
  const unseen = !seen;
  return (
    <DataPanel
      key={item.id}
      shadow="zero"
      className={getClassNames(styles.card, styles.card__slideIn)}
      data-testid={`news-drawer-card-${collapsed ? 'collapsed' : 'expanded'}`}
    >
      <Optional if={unseen}>
        <Badge className={styles.card_badge} variant="attention" showIcon={false}>
          {t('newsDrawer.tags.new')}
        </Badge>

        <Heading.H4 className={getClassNames(styles.card_title, styles.card_title__unseen)}>
          {item.title}
        </Heading.H4>
      </Optional>

      <Optional if={seen}>
        <Badge className={styles.card_badge} variant="information" showIcon={false}>
          {t('newsDrawer.tags.seen')}
        </Badge>

        <Heading.H4 className={styles.card_title}>{item.title}</Heading.H4>
      </Optional>

      <Gutter size="sm" />

      {collapsed ? (
        <CardCollapsed item={item} expand={() => setCollapsed(false)} />
      ) : (
        <CardExpanded item={item} trackOnReadMore={trackOnReadMore} />
      )}
    </DataPanel>
  );
};

const CardCollapsed: React.FC<{ item: ProductNewsItem; expand: VoidFunction }> = ({
  item,
  expand
}) => {
  const { t } = useTranslation();
  return (
    <>
      <TextWithInlineHtml text={item.description} className={styles.card_clampedText} />

      <div className={styles.card_expandButton}>
        <Button variant="tertiary-purple" size="md" onClick={expand} data-testid="expand-button">
          <Icon name="chevron-down" size="sm" />

          {t('newsDrawer.expand')}
        </Button>
      </div>
    </>
  );
};

const CardExpanded: React.FC<{ item: ProductNewsItem; trackOnReadMore: AnalyticsEvent }> = ({
  item,
  trackOnReadMore
}) => {
  return (
    <>
      <TextWithInlineHtml text={item.description} />

      <Optional data={!!item.imageUrl}>
        <Image
          src={item.imageUrl as string}
          alt={`${item.title}-image`}
          className={styles.card_image}
        />
      </Optional>

      <Gutter size="sm" />

      <Div className={styles.card_ctaContainer}>
        <Optional if={!!item.buttonLabel && !!item.buttonLink}>
          <Analytics
            whenClicked={trackOnReadMore}
            ignoreDisabledChildren
            tagName="div"
            className={styles.card_ctaButton}
            data-testid="news-drawer-content-link-analytics"
          >
            <ButtonLink
              text={item.buttonLabel as string}
              to={item.buttonLink}
              iconRight="chevron-right"
              variant="primary"
              data-testid="news-drawer-content-link"
            />
          </Analytics>
        </Optional>

        <NewsDrawerRating productUpdateId={item.id} rating={item.rating} />
      </Div>

      <NewsDrawerFeedbackForm productUpdateId={item.id} feedbackGiven={item.feedbackGiven} />
    </>
  );
};

export default NewsDrawerContentCard;
