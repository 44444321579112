import getClassNames from 'classnames';
import getPileVerticallyToClasses, {
  PileVerticallyToProps
} from 'core-components/responsive/pileVerticallyTo/getPileVerticallyToClasses';
import getResponsiveHiddenClasses, {
  ResponsiveHiddenProps
} from 'core-components/responsive/responsiveHidden/getResponsiveHiddenClasses';
import getSpacingClasses, { SpacingProps } from 'core-components/spacing/getSpacingClasses';

import styles from './Flex.module.scss';

export type FlexSizeMultiplier = 1 | 2 | 3 | 4;

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const getFlexSizeMultipliers = (): FlexSizeMultiplier[] => [1, 2, 3, 4];

export type FlexProps = {
  flexItem?:
    | {
        sizeMultiplier?: FlexSizeMultiplier;
      }
    | boolean;

  occupyHorizontalSpace?: boolean;

  centered?: boolean;
  /** Center flex items horizontally */
  centeredHorizontally?: boolean;
  /** Center flex items vertically */
  centeredVertically?: boolean;
  /** Align flex items to top */
  alignTop?: boolean;
  /** Align flex items to bottom */
  alignBottom?: boolean;
  /** Align flex items vertically to baseline */
  alignBaseline?: boolean;
  /** Align flex items to right */
  alignRight?: boolean;
  /** Add space between flex items */
  spaceBetween?: boolean;
  /** Wrap flex items */
  wrapContent?: boolean;
  /** Set flex items height to 100% occupying possible space */
  occupyVerticalSpace?: boolean;
  /** Set every flex item to be same height */
  stretchToSameHeight?: boolean;
  /** Reverse flex items direction */
  reverseDirection?: boolean;
  /** Changes the flex direction from row to column */
  directionColumn?: boolean;
};

const Flex: React.FC<
  FlexProps &
    PileVerticallyToProps &
    ResponsiveHiddenProps &
    SpacingProps & {
      style?: React.CSSProperties;
      className?: string;
      fitContent?: boolean;
      'data-testid'?: string;
      'data-tour-stop'?: string;
      children?: React.ReactNode;
    }
> = ({
  children,
  centered,
  centeredHorizontally,
  centeredVertically,
  alignTop,
  alignBottom,
  alignBaseline,
  alignRight,
  spaceBetween,
  fitContent,
  wrapContent,
  pileVerticallyTo,
  occupyVerticalSpace,
  occupyHorizontalSpace,
  stretchToSameHeight,
  reverseDirection,
  directionColumn,
  flexItem,
  responsiveHidden,
  padding,
  margin,
  style,
  className,
  'data-testid': dataTestId,
  'data-tour-stop': dataTourStop
}) => {
  const getDirectionClasses = () => [
    directionColumn && !reverseDirection ? styles['container_directionColumn'] : undefined,
    directionColumn && reverseDirection ? styles['container_directionColumn_reverse'] : undefined,
    !directionColumn && !reverseDirection ? styles['container_directionRow'] : undefined,
    !directionColumn && reverseDirection ? styles['container_directionRow_reverse'] : undefined
  ];

  const getFlexItemClasses = () => [
    typeof flexItem === 'object' ? styles[`container_flexItem`] : undefined,
    typeof flexItem === 'object' && flexItem?.sizeMultiplier
      ? styles[`container_flexItem_${flexItem.sizeMultiplier}`]
      : undefined,
    flexItem ? styles[`container_flexItem`] : undefined
  ];

  const getAlignmentClasses = () => [
    centered ? styles['container_centered'] : undefined,
    centeredHorizontally ? styles['container_centeredHorizontally'] : undefined,
    centeredVertically ? styles['container_centeredVertically'] : undefined,
    alignTop ? styles['container_alignTop'] : undefined,
    alignBottom ? styles['container_alignBottom'] : undefined,
    alignBaseline ? styles['container_alignBaseline'] : undefined,
    alignRight ? styles['container_alignRight'] : undefined
  ];

  const classes = getClassNames([
    styles.container,
    className,
    ...getAlignmentClasses(),
    spaceBetween ? styles['container_spaceBetween'] : undefined,
    fitContent ? styles['container_fitContent'] : undefined,
    wrapContent ? styles['container_wrapContent'] : undefined,
    occupyVerticalSpace ? styles['container_occupyVerticalSpace'] : undefined,
    occupyHorizontalSpace ? styles['container_occupyHorizontalSpace'] : undefined,
    stretchToSameHeight ? styles['container_stretchToSameHeight'] : undefined,
    ...getDirectionClasses(),
    ...getFlexItemClasses(),
    ...getSpacingClasses({ padding, margin }).map((className) => styles[className]),
    ...getPileVerticallyToClasses({ pileVerticallyTo }).map((className) => styles[className]),
    ...getResponsiveHiddenClasses({ responsiveHidden }).map((className) => styles[className])
  ]);

  return (
    <div data-testid={dataTestId} data-tour-stop={dataTourStop} style={style} className={classes}>
      {children}
    </div>
  );
};

export default Flex;
