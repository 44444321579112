import { ApolloError, gql } from '@apollo/client';
import { mapCollectionGroup } from 'hooks/invoicing/billingGroups/useCollectionGroup/useCollectionGroup';
import useApiStatus, { ViewStatus } from 'hooks/useApiStatus/useApiStatus';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import useInitialLoadStatus from 'hooks/useInitialLoadStatus/useInitialLoadStatus';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { CollectionGroup, QueriedCollectionGroup } from 'types/collection-group';

export const billingGroupsQuery = gql`
  query GetBillingAccounts($requestParams: BillingGroupsRequest) {
    billingGroups(requestParams: $requestParams) {
      total
      items {
        collectionGroup
        customerCode
        name
        reference
        canCopy
        address {
          foreign
          street
          postalOffice
          zip
        }
        addressSupplement
        type {
          deliveryMethod
          changeability
        }
      }
    }
  }
`;

export interface UseBillingGroupsResult {
  billingGroups: {
    total: number;
    items: CollectionGroup[];
  };
  loading: boolean;
  error?: ApolloError;
  hasError: boolean;
  loadingInitial: boolean;
}

type BillingGroupQueryParams = {
  accountType?: string;
  billingMethod?: string;
  search?: string;
};

interface BillingGroupsResponse {
  billingGroups: {
    total: number;
    items: QueriedCollectionGroup[];
  };
}

const mapCollectionGroups = (
  t: TFunction,
  collectionGroups: QueriedCollectionGroup[]
): CollectionGroup[] => collectionGroups.map((group) => mapCollectionGroup(t, group));

const useBillingGroups = (queryParams: BillingGroupQueryParams): UseBillingGroupsResult => {
  const { t } = useTranslation();
  const { accountType, search, billingMethod } = queryParams;

  const { data, error, loading, loaded, loadingMore } = useCallBackend<BillingGroupsResponse>({
    query: billingGroupsQuery,
    queryVariables: {
      requestParams: {
        searchString: search,
        billingMethod,
        accountType
      }
    }
  });

  const loadingInitial =
    useInitialLoadStatus(
      useApiStatus({
        loading,
        loaded,
        loadingMore,
        error,
        items: data?.billingGroups?.items || [],
        query: {}
      })
    ) === ViewStatus.LoadingInitial;

  return {
    billingGroups: {
      total: data?.billingGroups.total || 0,
      items: mapCollectionGroups(t, data?.billingGroups.items || [])
    },
    loading,
    error,
    hasError: !!error,
    loadingInitial
  };
};

export default useBillingGroups;
