import { Flex, Input } from '@gaiads/telia-react-component-library';
import { IconButton } from 'common-components';
import { aiAssistant } from 'doings/track/analyticsEvents';
import trackEvent from 'doings/track/trackEvent';
import { useClearConversation } from 'hooks/askForHelp/useClearConversation';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  openAskForHelpDrawer,
  resetConversation,
  setLastQuestion
} from 'redux/slices/askForHelpSlice';
import { RootState } from 'redux/store';
import { EXTENDED_SEARCH_TEXT_MAX_LENGTH } from 'types/form';

import styles from './AskForHelpField.module.scss';

const AskForHelpField: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { clearConversation } = useClearConversation();

  const lastQuestion = useSelector((state: RootState) => state.askForHelpDrawer.lastQuestion);
  const [question, setQuestion] = useState<string>('');

  useEffect(() => {
    setQuestion(lastQuestion ?? '');
  }, [lastQuestion]);

  const openDrawer = useCallback(async () => {
    if (question) {
      trackEvent(aiAssistant.startConversationFromDashboard);
      dispatch(setLastQuestion(question));
      dispatch(openAskForHelpDrawer());
    }
  }, [question, dispatch]);

  const reset = useCallback(() => {
    if (question) {
      trackEvent(aiAssistant.clearConversationFromDashboard);
      dispatch(resetConversation());
      setQuestion('');
      clearConversation();
    }
  }, [question, dispatch, clearConversation]);

  return (
    <Flex centeredVertically className={styles.searchField}>
      <Input
        value={question}
        onChange={(_event, value) => setQuestion(value)}
        label={t('askForHelp.dashboard.label')}
        placeholder={t('askForHelp.dashboard.placeholder')}
        maxLength={EXTENDED_SEARCH_TEXT_MAX_LENGTH}
        onClick={openDrawer}
        // If we used key up, the drawer would be immediatelly reopened if it
        // was closed via pressing the Enter key on any of its close buttons
        onKeyDown={(event) => event.key === 'Enter' && openDrawer()}
        data-testid="ask-for-help--input-field"
        id="ask-for-help--input-field"
      />

      <IconButton
        className={[styles.searchIcon, { [styles.searchIcon_disabled]: !question }]}
        name={question ? 'close' : 'search'}
        size="sm"
        onClick={reset}
        focusable={!!question}
        aria-label={question ? t('aria.askForHelp.clear') : undefined}
        data-testid={`ask-for-help--input-field--${question ? 'clear' : 'search'}-icon`}
      />
    </Flex>
  );
};

export default AskForHelpField;
