import { SmartTooltip } from 'common-components';
import { Flex } from 'core-components';

export const ListViewTooltip: React.FC<{ content: string }> = ({ content }) => (
  <Flex centeredVertically margin={{ left: 'xs' }}>
    <SmartTooltip tooltipContent={content} wrapper="div">
      <SmartTooltip.InfoIcon size="sm" data-testid="list-view-tooltip-icon" />
    </SmartTooltip>
  </Flex>
);
