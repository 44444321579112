import { Div } from '@gaiads/telia-react-component-library';
import { Divider } from 'core-components';

const Spacer: React.FC<{ 'data-testid'?: string }> = ({ 'data-testid': testId }) => (
  <Div data-testid={testId} margin={{ top: 'xs', bottom: 'xs' }}>
    <Divider />
  </Div>
);

export default Spacer;
