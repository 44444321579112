import { BodyText, Flex, Gutter } from '@gaiads/telia-react-component-library';
import { Icon } from '@teliafi/fi-ds';
import getClassNames from 'classnames';
import { Analytics } from 'common-components';
import copy from 'copy-to-clipboard';
import { invoicing } from 'doings/track/analyticsEvents';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BarCode as BarCodeType } from 'types/invoice';

import styles from './BarCode.module.scss';

export const BAR_CODE_COPIED_NOTICE_TIMEOUT: Time.Milliseconds = 3000;

const BarCode: React.FC<{ barCode?: BarCodeType }> = ({ barCode }) => {
  const { t } = useTranslation();
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    const timeout = clicked
      ? setTimeout(() => {
          setClicked(false);
        }, BAR_CODE_COPIED_NOTICE_TIMEOUT)
      : undefined;

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [clicked]);

  if (clicked) {
    return (
      <Flex
        centeredVertically
        data-testid="invoice-barcode-copy"
        className={getClassNames(styles.barCode, styles.barCode__copied)}
      >
        <Icon data-testid="invoice-barcode-copied" name="check-circle" size="md" />

        <Gutter size="xs" />

        <BodyText color="black" aria-live="polite">
          {t('invoice.details.copiedToClipboard')}
        </BodyText>
      </Flex>
    );
  }

  const clickable = !!barCode;
  const onClick = clickable
    ? () => {
        copy(barCode);
        setClicked(true);
      }
    : undefined;

  return (
    <Analytics whenClicked={invoicing.copyBarCode}>
      <button
        data-testid="invoice-barcode-copy-button"
        className={getClassNames(styles.barCode, styles.barCode__copy)}
        tabIndex={clickable ? 0 : -1}
        onClick={onClick}
        disabled={!clickable}
      >
        <Icon name="copy" size="md" />

        <Gutter size="xs" />

        <BodyText>{t('invoice.details.copyBarCode')}</BodyText>
      </button>
    </Analytics>
  );
};

export default BarCode;
