import { Footer as CompLibFooter } from '@gaiads/telia-react-component-library';
import getClassNames from 'classnames';
import { footerLinks } from 'doings/track/analyticsEvents';
import trackEvent from 'doings/track/trackEvent';
import { useTranslation } from 'react-i18next';

import styles from './Footer.module.scss';

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <CompLibFooter
      data-testid="footer-links"
      className={getClassNames([styles.footer, styles.fixThoseBlockElementsInsideInlineElements])}
      links={[
        {
          title: t('footer.links.dataProtection'),
          to: 'https://www.telia.fi/tietosuoja-ja-tietoturva',
          onClick: () => trackEvent(footerLinks.dataProtection),
          openInNewWindow: true
        },
        {
          title: t('footer.links.termsOfUse'),
          to: 'https://www.telia.fi/kayttoehdot',
          onClick: () => trackEvent(footerLinks.termsOfUse),
          openInNewWindow: true
        },
        {
          title: t('footer.links.cookiePolicy'),
          to: 'https://www.telia.fi/tietosuoja-ja-tietoturva/evasteet',
          onClick: () => trackEvent(footerLinks.cookiePolicy),
          openInNewWindow: true
        },
        {
          title: t('footer.links.termsOfDelivery'),
          to: 'https://www.telia.fi/toimitusehdot-ja-palvelukuvaukset',
          onClick: () => trackEvent(footerLinks.termsOfDelivery),
          openInNewWindow: true
        }
      ]}
    />
  );
};

export default Footer;
