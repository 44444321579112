import { gql } from '@apollo/client';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { AvailableBank } from 'types/collection-group';

export const availableBanksQuery = gql`
  query GetAvailableBanks {
    availableBanks {
      items {
        bic
        name
      }
    }
  }
`;

interface AvailableBanksResponse {
  availableBanks: {
    items: AvailableBank[];
  };
}

const useAvailableBanks = () => {
  const { data, error, loading } = useCallBackend<AvailableBanksResponse>({
    query: availableBanksQuery
  });

  return {
    availableBanks: data?.availableBanks.items ?? [],
    loading,
    error
  };
};

export default useAvailableBanks;
