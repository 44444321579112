import { Authorize, Button } from 'common-components';
import { invoicing } from 'doings/track/analyticsEvents';
import useCustomModal from 'hooks/useCustomModal/useCustomModal';
import { useTranslation } from 'react-i18next';
import { InvoiceDetailsType } from 'types/invoice';

import InvoicePaymentModal, { INVOICE_PAYMENT_MODAL } from './InvoicePaymentModal';

const PayInvoiceButton: React.FC<{ invoice: InvoiceDetailsType }> = ({ invoice }) => {
  const { t } = useTranslation();
  const paymentModal = useCustomModal(INVOICE_PAYMENT_MODAL);

  return (
    <>
      <Authorize
        data-testid="invoice-pay-authorize"
        analyticsEvent={invoicing.payInvoice}
        enabledWhen={{ onePermission: 'BILLING_INVOICES' }}
        element={
          <Button
            data-testid="invoice-pay"
            disabled={invoice.status === 'closed'}
            size="md"
            variant="primary"
            onClick={() => paymentModal.openModal()}
          >
            {t('invoice.pay')}
          </Button>
        }
      />

      <InvoicePaymentModal
        invoice={invoice}
        isOpen={paymentModal.isOpen}
        onCloseClick={paymentModal.closeModal}
      />
    </>
  );
};

export default PayInvoiceButton;
