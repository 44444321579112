import { Div, Flex, Image, Page } from '@gaiads/telia-react-component-library';
import { Paragraph } from '@teliafi/fi-ds';
import { BreadcrumbWithCompanySelector, Heading, ListViewHeader } from 'common-components';
import useCurrentRoute from 'hooks/useCurrentRoute/useCurrentRoute';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import styles from './ErrorPageLayout.module.scss';

const ErrorPageLayout: React.FC<{
  hideBreadcrumb?: boolean;
  imageSrc: string;
  headlineKey: string;
  bodyKey: string;
  translatables: React.ReactElement[];
  isForbidden?: boolean;
  keepPageTitle?: boolean;
}> = ({
  hideBreadcrumb = false,
  imageSrc,
  headlineKey,
  bodyKey,
  translatables,
  isForbidden = false,
  keepPageTitle = false
}) => {
  const { t } = useTranslation();
  const showCompanySelector = useCurrentRoute()?.showCompanySelectorOnError;

  useEffect(() => {
    if (!keepPageTitle) {
      document.title = t(headlineKey) + ' | ' + t('breadcrumb.root');
    }
  });

  return (
    <Page data-testid="error-page" className={styles.errorPage}>
      {!isForbidden && (
        <Page.Row>
          <BreadcrumbWithCompanySelector
            withoutBreadcrumb={hideBreadcrumb}
            withoutCompanySelector={!showCompanySelector}
          />
        </Page.Row>
      )}

      {!isForbidden && showCompanySelector && (
        <Page.Row>
          <ListViewHeader />
        </Page.Row>
      )}

      <Page.Row className={styles.errorRow}>
        <Flex className={styles.container}>
          <Image className={styles.image} src={imageSrc} alt={t(headlineKey)} />

          <Div className={styles.content}>
            <Heading.H2 data-testid="error-page-headline">{t(headlineKey)}</Heading.H2>

            <Paragraph className={styles.contentText}>
              <Trans i18nKey={bodyKey} components={translatables} />
            </Paragraph>
          </Div>
        </Flex>
      </Page.Row>
    </Page>
  );
};

export default ErrorPageLayout;
