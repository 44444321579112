import { ContentSearchResultsInner } from 'B2XApp/TopNavigation/Navigation/ContentSearch/ContentSearchResults';
import { useDashboardSearchState } from 'contexts/SearchContext/DashboardSearchContext';
import useIsAskForHelpAvailable from 'hooks/askForHelp/useIsAskForHelpAvailable';

const DashboardContentSearchResults: React.FC = () => {
  const { searchFieldActive } = useDashboardSearchState();
  const { askForHelpAvailable } = useIsAskForHelpAvailable();
  const isSearchFieldOpen = askForHelpAvailable ? searchFieldActive : true;

  return <ContentSearchResultsInner isSearchFieldOpen={isSearchFieldOpen ?? false} forDashboard />;
};

export default DashboardContentSearchResults;
