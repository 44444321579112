import { DataPanel } from 'common-components';
import { Price } from 'common-components/Price/Price';
import { useTranslation } from 'react-i18next';
import { Device } from 'types/device';

import ContractInfoSkeleton from './ContractInfoSkeleton';
import ContractPeriod from './ContractPeriod/ContractPeriod';

const OneTimePrice: React.FC<{
  device: Device;
}> = ({ device: { priceWithoutVat, currency } }) => (
  <Price price={priceWithoutVat} currency={currency} data-testid="contract-info-price" />
);

const ContractInfo: React.FC<{
  device?: Device;
  loading: boolean;
  hasError?: boolean;
}> = ({ device, loading, hasError }) => {
  const { t } = useTranslation();

  return (
    <DataPanel
      data-testid={`device-details-payment-type-${
        device?.isOneItemPaymentDevice ? 'OTP' : 'contract'
      }`}
      skeletonLoader={<ContractInfoSkeleton />}
      loading={loading}
      hasError={hasError}
      title={
        device?.isOneItemPaymentDevice
          ? t('daas.details.paymentSingle')
          : t('daas.details.contractPeriod')
      }
      compactTitle={device?.isOneItemPaymentDevice}
    >
      {device && !device.isOneItemPaymentDevice && (
        <ContractPeriod data-testid="contract-info-contract-period" device={device} />
      )}

      {device?.isOneItemPaymentDevice && <OneTimePrice device={device} />}
    </DataPanel>
  );
};

export default ContractInfo;
