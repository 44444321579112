import { gql } from '@apollo/client';
import { sortCaseSensitive } from 'doings/sort/sort';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { Product } from 'types/product';
import { SortOrder } from 'types/sort';

export const productsQuery = gql`
  query GetAvailableProducts {
    products {
      id
      name
      productCode
      contractOfferingId
    }
  }
`;

type ProductsResult = {
  products: Product[];
};

export default () => {
  const { data, loading, error } = useCallBackend<ProductsResult>({
    query: productsQuery
  });

  return {
    data: data?.products
      ? sortCaseSensitive(data.products, { sort: 'name', order: SortOrder.ASC })
      : [],
    loading,
    error
  };
};
