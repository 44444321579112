import { Flex } from '@gaiads/telia-react-component-library';
import { useTranslation } from 'react-i18next';
import { MobileNetworkQuality } from 'types/mobileNetworkQuality';

import { NetworkQualityItem } from './NetworkQualityItem';

const NetworkQualityItems: React.FC<{
  networkQuality: MobileNetworkQuality.ServiceQualityInformation;
}> = ({ networkQuality }) => {
  const { t } = useTranslation();

  const callQuality = networkQuality.callQuality,
    smsQuality = networkQuality.smsQuality,
    dataQuality = networkQuality.dataQuality;

  return (
    <Flex centeredHorizontally>
      <NetworkQualityItem
        data-testid="mobile-service-quality-calls"
        label={t('dashboard.mobileNetworkQuality.calls.label')}
        quality={callQuality?.callQuality}
        details={
          callQuality && [
            `${callQuality.callAttempts} ${t('dashboard.mobileNetworkQuality.calls.total')}`,
            `${callQuality.failedCalls} ${t('dashboard.mobileNetworkQuality.calls.failed')} (${
              callQuality.callFailRate
            } %)`,
            `${callQuality.droppedCalls} ${t('dashboard.mobileNetworkQuality.calls.dropped')} (${
              callQuality.callDropRate
            } %)`
          ]
        }
      />

      <NetworkQualityItem
        data-testid="mobile-service-quality-sms"
        label={t('dashboard.mobileNetworkQuality.sms.label')}
        quality={smsQuality?.smsQuality}
        details={
          smsQuality && [
            `${smsQuality.smsAttempts} ${t('dashboard.mobileNetworkQuality.sms.total')}`,
            `${smsQuality.failedSms} ${t('dashboard.mobileNetworkQuality.sms.failed')} (${
              smsQuality.smsFailRate
            } %)`
          ]
        }
      />

      <NetworkQualityItem
        data-testid="mobile-service-quality-data"
        label={t('dashboard.mobileNetworkQuality.data.label')}
        quality={dataQuality?.dataQuality}
        details={
          dataQuality && [
            `${dataQuality.totalDownloaded} ${t(
              'dashboard.mobileNetworkQuality.data.totalDownload'
            )}`,
            `${dataQuality.totalUploaded} ${t('dashboard.mobileNetworkQuality.data.totalUpload')}`,
            `${dataQuality.packetLossRate} % ${t('dashboard.mobileNetworkQuality.data.packetLoss')}`
          ]
        }
      />
    </Flex>
  );
};

export default NetworkQualityItems;
