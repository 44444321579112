import { BodyText } from '@gaiads/telia-react-component-library';
import { ModalDialog } from 'common-components';
import { Trans, useTranslation } from 'react-i18next';

const PendingChangesGuardModal: React.FC<{
  showConfirmation: boolean;
  closeConfirmation: VoidFunction;
  dismissAction: VoidFunction;
}> = ({ showConfirmation, closeConfirmation, dismissAction }) => {
  const { t } = useTranslation();

  return (
    <ModalDialog
      data-testid="confirmation-modal"
      isOpen={showConfirmation}
      title={t('modal.dismissChanges.title')}
      acceptButton={{
        label: t('modal.dismissChanges.accept'),
        onClick: () => {
          dismissAction();
          closeConfirmation();
        },
        testIdSuffix: 'dismiss-changes'
      }}
      closeButton={{
        label: t('modal.dismissChanges.close'),
        onClick: closeConfirmation,
        testIdSuffix: 'continue-editing'
      }}
      onClose={closeConfirmation}
      isNarrow
      disableOutsideClick
    >
      <Trans
        i18nKey="modal.dismissChanges.description"
        components={[<BodyText key="dismiss-changes-modal--content" />]}
      />
    </ModalDialog>
  );
};

export default PendingChangesGuardModal;
