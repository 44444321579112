import { ApolloError, gql } from '@apollo/client';
import formatDatetime from 'doings/formatDatetime/formatDatetime';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { flow, get } from 'lodash/fp';
import { InvoiceDetailsType } from 'types/invoice';

export const invoiceQuery = gql`
  query GetInvoiceDetails($key: String) {
    invoice(key: $key) {
      id
      name
      types
      invoiceDate
      key
      dueDate
      lastPaymentDate
      totalAmount
      amountOpen
      status
      billingPeriod {
        start
        end
      }
      collectionGroup
      firstPayDate
      paymentPlanActive
      referenceNumber
      barCode
      customer {
        code
        name
        customerType
      }
      finvoice {
        accountDimensionText
        agreementIdentifier
        buyerReferenceIdentifier
        invoiceClassification
        normalProposedAccountIdent
        orderIdentifier
        projectReferenceIdentifier
        proposedAccountText
        tenderReference
        usesCustomerSettings
      }
      hasCallSpecification
      paymentStatus
      canConvertToEInvoice
    }
  }
`;

type UseInvoiceProps = {
  key: string;
};

type UseInvoiceResult = {
  invoice?: InvoiceDetailsType;
  error?: ApolloError;
  hasError: boolean;
  loading: boolean;
};

const mapInvoiceFields = ({
  billingPeriod,
  firstPayDate,
  invoiceDate,
  dueDate,
  ...rest
}: InvoiceDetailsType): InvoiceDetailsType => ({
  billingPeriod: billingPeriod
    ? {
        start: formatDatetime(billingPeriod.start),
        end: formatDatetime(billingPeriod.end)
      }
    : undefined,
  firstPayDate: formatDatetime(firstPayDate),
  invoiceDate: formatDatetime(invoiceDate),
  dueDate: formatDatetime(dueDate),
  ...rest
});

const mapInvoiceDetails = (data: InvoiceDetailsType) =>
  flow(get('invoice'), mapInvoiceFields)(data);

const useInvoice = ({ key }: UseInvoiceProps): UseInvoiceResult => {
  const { data, error, loading } = useCallBackend<InvoiceDetailsType>({
    query: invoiceQuery,
    queryVariables: {
      key
    }
  });

  return {
    invoice: data ? mapInvoiceDetails(data) : undefined,
    error,
    hasError: !!error,
    loading
  };
};

export default useInvoice;
