import { Div, Flex, MarginSpace, Page } from '@gaiads/telia-react-component-library';
import {
  BackButton,
  Heading,
  Layout,
  MobilePadding,
  Notices,
  RouteBreadcrumb,
  Skeleton
} from 'common-components';
import useSearchParams from 'hooks/useSearchParams/useSearchParams';
import { useTranslation } from 'react-i18next';

const PageWithFormLayout: React.FC<{
  title: string;
  backLinkVisible?: boolean;
  loading?: boolean;
  children: React.ReactNode;
  additionalInfo?: React.ReactNode;
  notification?: React.ReactNode | React.ReactNode[];
  'data-testid'?: string;
  to?: string;
}> = ({
  title,
  backLinkVisible,
  children,
  additionalInfo,
  notification,
  loading,
  'data-testid': testId,
  to = ''
}) => {
  const { t } = useTranslation();
  const { searchParams, preserveBackPathState } = useSearchParams();
  const backTo = searchParams?.originator || preserveBackPathState(to);

  return (
    <Page padding={{ vertical: 'lg' }} data-testid={testId}>
      <MarginSpace noPaddingForSmallerThanSm>
        <MobilePadding>
          <RouteBreadcrumb />

          {backLinkVisible && (
            <Flex margin={{ top: 'xlg' }}>
              <BackButton path={backTo} label={t('common.backButton.label')} guarded />
            </Flex>
          )}

          <Div margin={{ top: backLinkVisible ? 'md' : 'xlg', bottom: 'md' }}>
            {loading ? (
              <Skeleton.Title width="50%" data-testid="form-skeleton-title" />
            ) : (
              <Heading.H2>{title}</Heading.H2>
            )}
          </Div>

          <Notices data-testid="form-notices">{!loading && notification}</Notices>
        </MobilePadding>

        {additionalInfo ? (
          <Layout.TwoColumn additionalInfo={additionalInfo}>{children}</Layout.TwoColumn>
        ) : (
          <Layout.SingleColumn>{children}</Layout.SingleColumn>
        )}
      </MarginSpace>
    </Page>
  );
};

export default PageWithFormLayout;
