import BillingGroupPageError from 'B2XApp/Invoicing/BillingGroups/Common/BillingGroupPageError';
import BillingGroupPageLoading from 'B2XApp/Invoicing/BillingGroups/Common/BillingGroupPageLoading';
import { useParams } from 'react-router-dom';

import EditBillMillAccountPage from './EditBillMillAccountPage';
import useEditBillMillAccountData from './useEditBillMillAccountData';

interface EditBillMillAccountParams {
  accountId: string;
}

const EditBillMillAccount: React.FC = () => {
  const { accountId } = useParams<EditBillMillAccountParams>();
  const { status, account, availableBanks, availableOperators } =
    useEditBillMillAccountData(accountId);

  switch (status) {
    case 'loaded':
      if (account) {
        return (
          <EditBillMillAccountPage
            accountId={accountId}
            billMillAccount={account}
            availableBanks={availableBanks}
            availableOperators={availableOperators}
          />
        );
      } else {
        return <BillingGroupPageError collectionGroupId={accountId} pageType="edit" />;
      }
    case 'loading':
      return <BillingGroupPageLoading collectionGroupId={accountId} pageType="edit" />;
    case 'error':
      return <BillingGroupPageError collectionGroupId={accountId} pageType="edit" />;
  }
};

export default EditBillMillAccount;
