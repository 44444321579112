import useChildAssetBreadcrumbs from 'hooks/useChildAssetBreadcrumbs/useChildAssetBreadcrumbs';
import { ChildAsset } from 'types/internetConnections';

import BreadcrumbContainer from './BreadcrumbContainer/BreadcrumbContainer';

const AssetBreadcrumb: React.FC<{
  rootAssetId: string;
  childAssetId: string;
  rootAssetsChildAssets: ChildAsset[];
}> = ({ rootAssetId, childAssetId, rootAssetsChildAssets }) => (
  <BreadcrumbContainer
    items={useChildAssetBreadcrumbs(rootAssetId, childAssetId, rootAssetsChildAssets)}
  />
);

export default AssetBreadcrumb;
