import { IndicatorStatusDefinition, StatusTag } from 'common-components';
import { OrderStatus } from 'types/orders';

export const statusMap: { [K in OrderStatus]: IndicatorStatusDefinition } = {
  [OrderStatus.Activated]: {
    i18nKey: 'orders.statuses.activated',
    variant: 'success'
  },
  [OrderStatus.Cancelled]: {
    i18nKey: 'orders.statuses.cancelled',
    variant: 'neutral',
    icon: 'close-circle'
  },
  [OrderStatus.Complete]: {
    i18nKey: 'orders.statuses.complete',
    variant: 'success'
  },
  [OrderStatus.InProgress]: {
    i18nKey: 'orders.statuses.inProgress',
    variant: 'information'
  },
  [OrderStatus.Replaced]: {
    i18nKey: 'orders.statuses.replaced',
    variant: 'neutral',
    icon: 'close-circle'
  }
};

export const statusDefaultValue: IndicatorStatusDefinition = {
  i18nKey: 'orders.statuses.other',
  variant: 'neutral',
  icon: 'question'
};

const OrderStatusTag: React.FC<{
  status: OrderStatus;
}> = ({ status }) => <StatusTag status={statusMap[status] || statusDefaultValue} />;

export default OrderStatusTag;
