import { Div } from '@gaiads/telia-react-component-library';
import { Heading } from 'common-components';

const Question: React.FC<{
  question: string;
  firstQuestion?: boolean;
  'data-testid'?: string;
}> = ({ question, firstQuestion = false, 'data-testid': testId }) => {
  const QuestionComponent = firstQuestion ? Heading.H4 : Heading.H5;

  return (
    <Div padding={{ bottom: 'sm' }} data-testid={testId}>
      <QuestionComponent>{question}</QuestionComponent>
    </Div>
  );
};

export default Question;
