import { gql } from '@apollo/client';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';

export type Status = 'error' | 'loading' | 'loaded';

export const corporateProductDataQuery = gql`
  query GetCorporateProductData($productDeploymentId: String!) {
    corporateProductData(productDeploymentId: $productDeploymentId) {
      origin
      action
      companyCode
      subscriptionNumber
      productType
      productName
      preselections {
        type
        subtype
        srProduct
        srCategory
        srSubcategory
      }
    }
  }
`;

type CorporateProductDataResponse = {
  corporateProductData: {
    origin: string;
    action: 'TERMINATE' | 'MODIFY';
    companyCode: string;
    subscriptionNumber: string;
    productType: 'BROADBAND' | 'DNS' | 'FIXED_PHONE' | 'OTHER_BUSINESS_SERVICE';
    productName: string;
    preselections: {
      type: string;
      subtype: string | undefined;
      srProduct: string;
      srCategory: string;
      srSubcategory: string;
    };
  };
};

export default (productDeploymentId: string) => {
  const { data, error, loading } = useCallBackend<CorporateProductDataResponse>({
    query: corporateProductDataQuery,
    queryVariables: {
      productDeploymentId
    }
  });

  return {
    data: data?.corporateProductData,
    error,
    loading
  };
};
