import { toNationalShortDate } from 'doings/formatDatetime/formatDatetime';
import { OrderLineItem, OrderListItem } from 'types/orders';

export const mapOrderListItems = (orders: OrderListItem[]): OrderListItem[] =>
  orders.map(mapOrderListItem);

export const mapOrderListItem = (order: OrderListItem): OrderListItem => ({
  ...order,
  createdDate: toNationalShortDate(order.createdDate),
  estimatedTimeOfCompletion: toNationalShortDate(order.estimatedTimeOfCompletion),
  lineItems: order.lineItems.map(mapOrderLineItem)
});

const mapOrderLineItem = (lineItem: OrderLineItem): OrderLineItem => ({
  ...lineItem,
  estimatedTimeOfCompletion: toNationalShortDate(lineItem.estimatedTimeOfCompletion)
});
