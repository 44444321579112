import { useUserData } from 'contexts/UserContext/UserContext';
import userHasPermission from 'doings/userHasPermission/userHasPermission';
import { AuthorizationParams } from 'types/authorization';

/**
 * Makes sure that the logged-in user has a sufficient active group by
 * group type and a sufficient set of permissions.
 */
export default (params: AuthorizationParams): boolean => {
  const user = useUserData();

  return userHasPermission(user)(params);
};
