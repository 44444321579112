import loadTicketQuery from 'hooks/tickets/useTicket/loadTicketQuery';
import mapTicketDatetimes from 'hooks/tickets/useTicket/mapTicketDatetimes';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { Ticket } from 'types/ticket';

type TicketDetailsResponse = {
  ticket: Ticket;
};

const useTicket = (ticketId: string) => {
  const { data, error, loading } = useCallBackend<TicketDetailsResponse>({
    query: loadTicketQuery,
    queryVariables: {
      ticketId
    },
    fetchPolicy: 'network-only'
  });

  return {
    ticket: data ? mapTicketDatetimes(data?.ticket) : undefined,
    loading,
    error
  };
};

export default useTicket;
