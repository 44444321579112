import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface ActiveFocusTrapsState {
  activeTraps: ActivatedFocusTrap[];
  activeFocusTrap: string | null;
  shouldLockBodyScroll: boolean;
  showing3rdPartyDialog: boolean;
}

export interface ActivatedFocusTrap {
  /** Focus trap ID. */
  id: string;
  /** Whether the focus trap's associated dialogue or drawer is open and can activate the trap. */
  open: boolean;
  /** The associated dialogue's or drawer's Z-index. Must match CSS z-index. */
  zIndex: number;
  /** Whether the focus trap should lock the HTML document's body scrolling in place while active. */
  lockScroll?: boolean;
}

const initialState: ActiveFocusTrapsState = {
  activeTraps: [],
  activeFocusTrap: null,
  shouldLockBodyScroll: false,
  showing3rdPartyDialog: false
};

export const activeFocusTrapsSlice = createSlice({
  name: 'activeFocusTraps',
  initialState,
  reducers: {
    show3rdPartyDialog: (state) => {
      if (!state.showing3rdPartyDialog) {
        state.showing3rdPartyDialog = true;
        state.activeFocusTrap = determineActiveFocusTrap(state);
        state.shouldLockBodyScroll = determineBodyScrollLock(state);
      }
    },

    hide3rdPartyDialog: (state) => {
      if (state.showing3rdPartyDialog) {
        state.showing3rdPartyDialog = false;
        state.activeFocusTrap = determineActiveFocusTrap(state);
        state.shouldLockBodyScroll = determineBodyScrollLock(state);
      }
    },

    mountFocusTrap: (state, action: PayloadAction<ActivatedFocusTrap>) => {
      if (!action.payload.open) {
        return;
      }

      const index = findFocusTrapIndexById(state, action.payload.id);
      if (index < 0) {
        const zTarget = action.payload.zIndex;
        const insertionIndex = state.activeTraps.filter(({ zIndex }) => zIndex <= zTarget).length;
        state.activeTraps.splice(insertionIndex, 0, action.payload);
        state.activeFocusTrap = determineActiveFocusTrap(state);
        state.shouldLockBodyScroll = determineBodyScrollLock(state);
      }
    },

    unmountFocusTrap: (state, action: PayloadAction<ActivatedFocusTrap>) => {
      if (!action.payload.open) {
        return;
      }

      const index = findFocusTrapIndexById(state, action.payload.id);
      if (index >= 0) {
        state.activeTraps.splice(index, 1);
        state.activeFocusTrap = determineActiveFocusTrap(state);
        state.shouldLockBodyScroll = determineBodyScrollLock(state);
      }
    }
  }
});

const findFocusTrapIndexById = (state: ActiveFocusTrapsState, id: string) =>
  state.activeTraps.findIndex(({ id: focusTrapId }) => focusTrapId === id);

const determineActiveFocusTrap = (state: ActiveFocusTrapsState) =>
  !state.showing3rdPartyDialog && state.activeTraps.length > 0
    ? state.activeTraps[state.activeTraps.length - 1].id
    : null;

const determineBodyScrollLock = (state: ActiveFocusTrapsState) =>
  state.activeTraps.some(({ lockScroll }) => lockScroll);

export const { show3rdPartyDialog, hide3rdPartyDialog, mountFocusTrap, unmountFocusTrap } =
  activeFocusTrapsSlice.actions;

export default activeFocusTrapsSlice.reducer;
