import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface BillingAccountTypeState {
  isModalOpen: boolean;
}

const initialState: BillingAccountTypeState = {
  isModalOpen: false
};

export const billingAccountTypeModalSlice = createSlice({
  name: 'billingAccountTypeModal',
  initialState,
  reducers: {
    showBillingAccountTypeModal: (state, action: PayloadAction<boolean>) => {
      state.isModalOpen = action.payload;
    }
  }
});

export const { showBillingAccountTypeModal } = billingAccountTypeModalSlice.actions;

export default billingAccountTypeModalSlice.reducer;
