import { multiplex } from 'doings/multiplex/multiplex';
import { AnalyticsEvent, tours } from 'doings/track/analyticsEvents';
import { Dispatch, useReducer } from 'react';

import {
  TourAction,
  TourActionType,
  TourGuideStatus as TourReducerStatus,
  TourState
} from './tour.types';
import { useTours } from './useTours';

export const useTourReducer = ({
  tourIds
}: {
  tourIds: string[];
}): [TourState, Dispatch<TourAction>] => {
  const [state, dispatch] = useReducer(
    (state: TourState, action: TourAction): TourState => {
      switch (action.type) {
        case TourActionType.REGISTER_TOURS:
          return {
            ...state,
            status: TourReducerStatus.READY_TO_START,
            nextTours: action.tours,
            currentTour: undefined,
            currentTourStopAt: -1,
            navigateTo: undefined,
            startedAt: action.tours.length > 0 ? action.startedAt : undefined,
            markingSeen: undefined,
            tracking: []
          };

        case TourActionType.START: {
          const nextTour = state.nextTours[0];
          return {
            ...state,
            status: nextTour ? TourReducerStatus.STARTED : TourReducerStatus.REGISTERING,
            nextTours: state.nextTours.slice(1),
            currentTour: nextTour,
            currentTourStopAt: nextTour?.intro ? -1 : 0,
            navigateTo: nextTour?.intro ? undefined : nextTour?.stops[0].at,
            tracking: [
              multiplex([
                undefined,
                [nextTour && nextTour.intro === undefined, tours.tourPoppedUp],
                [nextTour && nextTour.intro !== undefined, tours.tourShownExplicitly]
              ])
            ].filter(Boolean) as AnalyticsEvent[]
          };
        }

        case TourActionType.CONTINUE:
          return {
            ...state,
            currentTourStopAt: state.currentTour?.stops[state.currentTourStopAt + 1].at
              ? state.currentTourStopAt
              : state.currentTourStopAt + 1,
            navigateTo: state.currentTour?.stops[state.currentTourStopAt + 1].at,
            tracking: [...state.tracking, tours.tourContinued]
          };

        case TourActionType.FINISH: {
          const nextTour = state.nextTours[0];
          return {
            ...state,
            nextTours: state.nextTours.slice(1),
            currentTour: nextTour,
            currentTourStopAt: nextTour?.intro ? -1 : 0,
            markingSeen: state.currentTour?.id,
            navigateTo: nextTour?.intro ? undefined : nextTour?.stops[0].at,
            tracking: [
              ...state.tracking,
              state.currentTour ? tours.tourFinished : undefined,
              multiplex([
                undefined,
                [nextTour && nextTour.intro === undefined, tours.tourPoppedUp],
                [nextTour && nextTour.intro !== undefined, tours.tourShownExplicitly]
              ])
            ].filter(Boolean) as AnalyticsEvent[]
          };
        }

        case TourActionType.SKIP: {
          const nextTour = state.nextTours[0];
          return {
            ...state,
            nextTours: state.nextTours.slice(1),
            currentTour: nextTour,
            currentTourStopAt: nextTour?.intro ? -1 : 0,
            markingSeen: state.currentTour?.id,
            navigateTo: nextTour?.intro ? undefined : nextTour?.stops[0].at,
            tracking: [
              ...state.tracking,
              state.currentTour ? tours.tourSkipped : undefined,
              multiplex([
                undefined,
                [nextTour && nextTour.intro === undefined, tours.tourPoppedUp],
                [nextTour && nextTour.intro !== undefined, tours.tourShownExplicitly]
              ])
            ].filter(Boolean) as AnalyticsEvent[]
          };
        }

        case TourActionType.NAVIGATED:
          return {
            ...state,
            currentTourStopAt: state.currentTourStopAt + 1,
            navigateTo: undefined
          };

        case TourActionType.MARKED_AS_SEEN:
          return { ...state, markingSeen: undefined };

        case TourActionType.GA_EVENTS_TRACKED:
          return { ...state, tracking: [] };

        case TourActionType.RESET:
          return { ...state, status: TourReducerStatus.REGISTERING, startedAt: undefined };
      }
    },
    {
      status: TourReducerStatus.REGISTERING,
      nextTours: [],
      currentTour: undefined,
      currentTourStopAt: -1,
      navigateTo: undefined,
      startedAt: undefined,
      markingSeen: undefined,
      tracking: []
    } as TourState
  );

  useTours(tourIds, state, dispatch);

  return [state, dispatch];
};
