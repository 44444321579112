import { BodyText } from '@gaiads/telia-react-component-library';
import { DeviceAdditionalService } from 'types/device';

// List has not been designed yet so we use an plain un ordered list for now.
const ServiceList: React.FC<{ services: DeviceAdditionalService[] }> = ({ services }) => (
  <ul data-testid="device-details-extra-services-list">
    {services.map(({ id, productName }) => (
      <li key={id}>
        <BodyText tagName="span">{productName}</BodyText>
      </li>
    ))}
  </ul>
);

export default ServiceList;
