import ErrorPageLayout from 'B2XApp/Errors/Error/ErrorPageLayout/ErrorPageLayout';
import { FullPageLoader, TeliaLink } from 'common-components';
import getEnvironmentVariable from 'doings/getEnvironmentVariable/getEnvironmentVariable';
import { urlWithBackToService } from 'doings/links/urlBackToService/urlBackToService';
import reloadWindow from 'doings/reloadWindow/reloadWindow';
import { errors } from 'doings/track/analyticsEvents';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import urlJoin from 'url-join';

import accessDeniedImg from './access-denied.svg';
import genericErrorImg from './something-wrong.svg';

type ErrorType = keyof typeof errorTypeMappings;

const ErrorPageContent: React.FC<{
  currentLocation: string;
  variant?: ErrorType;
  hideBreadcrumb?: boolean;
}> = ({ currentLocation, variant = 'generic', hideBreadcrumb = false }) => {
  const { ready } = useTranslation('', { useSuspense: false });
  const { goBack } = useHistory();
  const { image, headlineKey, bodyKey, getTranslatables } = errorTypeMappings[variant];

  return (
    <FullPageLoader loading={!ready}>
      <ErrorPageLayout
        hideBreadcrumb={hideBreadcrumb}
        isForbidden={variant === 'forbidden'}
        imageSrc={image}
        headlineKey={headlineKey}
        bodyKey={bodyKey}
        translatables={getTranslatables(goBack, currentLocation)}
      />
    </FullPageLoader>
  );
};

const errorTypeMappings = {
  generic: {
    image: genericErrorImg,
    headlineKey: 'errors.generic.headline',
    bodyKey: 'errors.generic.body',
    getTranslatables: () => [
      <TeliaLink.Translatable.Click
        key="generic-error"
        data-testid="page-reload-link"
        variant="text"
        onClick={reloadWindow}
      />
    ]
  },

  forbidden: {
    image: accessDeniedImg,
    headlineKey: 'errors.forbidden.headline',
    bodyKey: 'errors.forbidden.body',
    getTranslatables: (_goBack: VoidFunction, currentLocation: string) => {
      const ulmUrl: string = getEnvironmentVariable('REACT_APP_ULM_URL');
      const ulmLink = urlWithBackToService(urlJoin(ulmUrl, 'profile'), currentLocation);
      return [
        <TeliaLink.Translatable.External
          key="permission-error"
          data-testid="request-permission-link"
          variant="text"
          to={ulmLink}
        />
      ];
    }
  },

  replayFault: {
    image: genericErrorImg,
    headlineKey: 'errors.replayFault.headline',
    bodyKey: 'errors.replayFault.body',
    getTranslatables: (goBack: VoidFunction) => [
      <TeliaLink.Translatable.Click
        key="replay-error-back-link"
        data-testid="go-back-link"
        variant="text"
        onClick={goBack}
      />,
      <TeliaLink.Translatable.Internal
        key="replay-error-dashboard-link"
        data-testid="dashboard-link"
        to="/"
        variant="text"
      />
    ]
  },

  replayOutdated: {
    image: genericErrorImg,
    headlineKey: 'errors.replayOutdated.headline',
    bodyKey: 'errors.replayOutdated.body',
    getTranslatables: (goBack: VoidFunction) => [
      <TeliaLink.Translatable.Click
        key="replay-error-back-link"
        data-testid="go-back-link"
        variant="text"
        onClick={goBack}
      />,
      <TeliaLink.Translatable.Internal
        key="replay-error-dashboard-link"
        data-testid="dashboard-link"
        to="/"
        variant="text"
      />
    ]
  },

  userContextFault: {
    image: genericErrorImg,
    headlineKey: 'errors.userContextFault.headline',
    bodyKey: 'errors.userContextFault.body',
    getTranslatables: (goBack: VoidFunction) => [
      <TeliaLink.Translatable.Click
        key="user-context-error"
        data-testid="go-back-link"
        variant="text"
        onClick={goBack}
      />,
      <ReturnToTeliaLink key="user-context-error-return-to-telia" />
    ]
  }
};

const ReturnToTeliaLink: React.FC = () => {
  const { t, i18n } = useTranslation();
  const getUrl = () => {
    switch (i18n.language) {
      case 'en':
        return 'business';
      case 'fi':
        return 'yrityksille';
      case 'sv':
        return 'yrityksille/svenska';
      default:
        return 'yrityksille';
    }
  };

  return (
    <TeliaLink.External
      to={urlJoin([getEnvironmentVariable('REACT_APP_TELIA_URL'), getUrl()])}
      variant="text"
      label={t('errors.userContextFault.returnToTeliaLink')}
      track={errors.returnToTelia}
    />
  );
};

export default ErrorPageContent;
