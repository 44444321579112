import { Span } from '@gaiads/telia-react-component-library';
import { SmartTooltip } from 'common-components';
import { useTranslation } from 'react-i18next';

import styles from './TicketFileUploaderTooltip.module.scss';

export const TicketFileUploaderTooltip: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Span className={styles.tooltip}>
      <SmartTooltip
        arrangement="top"
        tooltipContent={t('tickets.replyToTicket.attachment.supportedFilesTooltip')}
        wrapper="span"
      >
        <SmartTooltip.InfoIcon size="sm" />
      </SmartTooltip>
    </Span>
  );
};
