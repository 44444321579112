import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface WatchTourTriggerState {
  readyTours: string[];
  triggeredTours: string[];
}

const initialState: WatchTourTriggerState = {
  readyTours: [],
  triggeredTours: []
};

export const watchedTourTriggersSlice = createSlice({
  name: 'watchedTourTriggers',
  initialState,
  reducers: {
    triggerTour: ({ readyTours, triggeredTours }, action: PayloadAction<string>) => {
      const eventName = action.payload;
      if (!readyTours.includes(eventName) && !triggeredTours.includes(eventName)) {
        readyTours.push(eventName);
      }
    },

    markTourTriggered: ({ readyTours, triggeredTours }, action: PayloadAction<string>) => {
      const eventName = action.payload;
      if (readyTours.includes(eventName) && !triggeredTours.includes(eventName)) {
        readyTours.splice(readyTours.indexOf(eventName), 1);
        triggeredTours.push(eventName);
      }
    }
  }
});

export const { triggerTour, markTourTriggered } = watchedTourTriggersSlice.actions;

export default watchedTourTriggersSlice.reducer;
