import { ListView } from 'common-components';
import OverflowMenuWrapper from 'common-components/OverflowMenu/OverflowMenuWrapper/OverflowMenuWrapper';
import { useEditableListData } from 'contexts/EditableListContext/EditableListContext';
import { EN_DASH } from 'doings/dash/dash';
import { SortState, getSort } from 'hooks/useSortableHeadings/useSortableHeadings';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { ColumnVisibility, ListViewColumnSetup } from 'types/listConfig';
import { SortableColumns, SubscriptionListItem } from 'types/subscription';

import SubscriptionQuicklinksMenu from './SubscriptionQuicklinksMenu/SubscriptionQuicklinksMenu';
import SubscriptionStatusTags from './SubscriptionStatusTags/SubscriptionStatusTags';

export const SubscriptionsListViewColumnSetup: ListViewColumnSetup<
  [TFunction, SortState, (field: string) => void]
> = {
  listId: 'mobileSubscriptions',
  getColumns: (t: TFunction, sortState: SortState, sortClick: (field: string) => void) => ({
    user: {
      heading: t('subscriptions.user'),
      testidSuffix: 'subscription-user',
      sort: getSort(sortState, sortClick, SortableColumns.userName),
      baseVisibility: ColumnVisibility.REQUIRE
    },
    phoneNumber: {
      width: '0.5x',
      heading: t('common.phone.label'),
      testidSuffix: 'phone-number',
      sort: getSort(sortState, sortClick, SortableColumns.id),
      baseVisibility: ColumnVisibility.REQUIRE
    },
    subscriptionType: {
      heading: t('subscriptions.type'),
      testidSuffix: 'subscription-type',
      sort: getSort(sortState, sortClick, SortableColumns.subscriptionTypeName),
      baseVisibility: ColumnVisibility.SHOW
    },
    subscriptionPackage: {
      heading: t('subscriptions.packages.feature.listLabel'),
      testidSuffix: 'subscription-package',
      baseVisibility: ColumnVisibility.SHOW
    },
    subscriptionStatus: {
      heading: t('subscriptions.status'),
      testidSuffix: 'subscription-status',
      baseVisibility: ColumnVisibility.SHOW
    }
  })
};

const SubscriptionsListView: React.FC<{
  subscriptions: SubscriptionListItem[];
  loading: boolean;
}> = ({ subscriptions, loading }) => {
  const { t } = useTranslation();
  const { columns } = useEditableListData();

  return (
    <OverflowMenuWrapper>
      <ListView
        data-testid={`subscriptions-list-${loading ? 'loading' : 'loaded'}`}
        columns={columns}
        mobileLayout={{
          titleRow: { col: 'user' },
          rows: [
            { col: 'phoneNumber' },
            { col: 'subscriptionType' },
            { col: 'subscriptionPackage' },
            { col: 'subscriptionStatus', noLabel: true, padding: 'xs' }
          ]
        }}
        loading={loading}
        listData={subscriptions}
        noItemsNotice={t('subscriptions.list.noItemsFound')}
        usesQuicklinks
      >
        {(subscription) => (
          <ListView.Row
            data-testid="subscription-list-row-item-loaded"
            data-quicklink-container={`subscription-${subscription.id}`}
            linkTo={`/subscriptions/${subscription.phoneNumberHash}`}
            quicklinksMenu={<SubscriptionQuicklinksMenu subscription={subscription} />}
          >
            <ListView.RowShape
              cells={{
                user: {
                  data: subscription.user,
                  props: { dataTestId: 'subscription-username-info', wordWrap: 'ellipsis' }
                },
                phoneNumber: {
                  data: subscription.phoneNumber,
                  props: { dataTestId: 'subscription-phone-number-info' }
                },
                subscriptionType: {
                  data: subscription.type,
                  props: { dataTestId: 'subscription-type-info' }
                },
                subscriptionPackage: {
                  data:
                    subscription.packages.feature ??
                    ([
                      subscription.packages.voice,
                      subscription.packages.sms,
                      subscription.packages.data
                    ]
                      .filter(Boolean)
                      .join(', ') ||
                      EN_DASH),
                  props: { dataTestId: 'subscription-package-info' }
                },
                subscriptionStatus: {
                  data: <SubscriptionStatusTags subscription={subscription} />,
                  props: { dataTestId: 'subscription-status-info' }
                }
              }}
            />
          </ListView.Row>
        )}
      </ListView>
    </OverflowMenuWrapper>
  );
};

export default SubscriptionsListView;
