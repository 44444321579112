import { SectionDefinition } from 'B2XApp/Dashboard/DashboardNavigation/DashboardSections/sections/sectionDefinition';
import { DashboardSectionId } from 'B2XApp/Dashboard/sections';
import { LinkTarget, NavigationalLinks } from 'doings/getNavigationalLinks/getNavigationalLinks';

export const reportsSection: SectionDefinition = {
  id: DashboardSectionId.Reports,
  icon: 'report',
  titleTranslationKey: 'dashboard.sections.titles.Reports',
  getLinks(allLinks: NavigationalLinks) {
    return [allLinks[LinkTarget.B2B_PORTAL_REPORTS]];
  }
};
