import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { FavoriteDevices } from 'types/device';

import { favoriteDevicesQuery } from './favoriteDevicesQuery';

export type UseFavoriteDevicesResponse = {
  favoriteDevices: FavoriteDevices;
};

const useFavoriteDevices = () => {
  const { loading, data, error } = useCallBackend<UseFavoriteDevicesResponse>({
    query: favoriteDevicesQuery
  });

  return {
    loading,
    favoriteDevices: data?.favoriteDevices,
    error
  };
};

export default useFavoriteDevices;
