import { DefinitionList } from 'common-components';
import usePileVerticallyToMany from 'hooks/usePileVerticallyToMany/usePileVerticallyToMany';
import { useTranslation } from 'react-i18next';
import { CollectionGroup } from 'types/collection-group';

const BillingGroupPayerInformation: React.FC<{
  collectionGroup: CollectionGroup;
}> = ({ collectionGroup: { customerCode, address, reference, name } }) => {
  const { t } = useTranslation();

  const pileVerticallyTo = usePileVerticallyToMany(['xxxs', 'xxs', 'md', 'lg']);

  return (
    <DefinitionList
      pileVerticallyTo={pileVerticallyTo}
      addDividers
      fiftyFifty
      data-testid="billing-group-payer-information"
    >
      {[
        {
          heading: 'billingInformation.company',
          values: [customerCode]
        },
        {
          heading: 'billingInformation.payerName',
          values: [name]
        },
        {
          heading: 'billingInformation.billingReference',
          values: [reference]
        },
        {
          heading: 'billingInformation.billingAddress',
          values: [address.street, address.postalAddress]
        }
      ].map(({ heading, values }) => (
        <DefinitionList.MultilineItem key={heading} heading={t(heading)} values={values} />
      ))}
    </DefinitionList>
  );
};

export default BillingGroupPayerInformation;
