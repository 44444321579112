import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { Div, Flex, Gutter, Image, Panel } from '@gaiads/telia-react-component-library';
import { Button, Icon } from '@teliafi/fi-ds';
import classNames from 'classnames';
import { Analytics, Heading, IconButton, Optional, TextWithInlineHtml } from 'common-components';
import { focusHtmlElementOnNextFrame } from 'doings/focus/focusHtmlElement';
import { pimcoreNotifications } from 'doings/track/analyticsEvents';
import { useGenerateId } from 'hooks/useGenerateId/useGenerateId';
import { TFunction } from 'i18next';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider, { Settings } from 'react-slick';
import { NotificationPromotion } from 'types/notifications';

import styles from './Banners.module.scss';

const Banners: React.FC<{ banners: NotificationPromotion[] }> = ({ banners }) => {
  const { t } = useTranslation();
  const { generatedId: id } = useGenerateId('banners');
  const ref = useRef<Slider>();
  const [playing, setPlaying] = useState(true);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const toggleAutoplay = () => {
    const slider = ref.current;
    if (!slider) {
      return;
    }

    playing ? slider.slickPause() : slider.slickPlay();
    setPlaying(!playing);
  };

  const onReadMore = (link: string) => window.open(link, '_blank');
  const hasMultipleSlides = banners.length > 1;
  const sliderSettings: Settings = useMemo(
    () => ({
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      accessibility: true,
      pauseOnFocus: true,
      pauseOnHover: true,
      pauseOnDotsHover: true,
      appendDots: renderTabs(id, activeSlideIndex),
      customPaging: renderTab(t, id, activeSlideIndex, ref),
      arrows: false,
      dots: banners.length > 1,
      dotsClass: styles.dotsContainer,
      focusOnSelect: false,
      fade: true
    }),
    [t, id, activeSlideIndex, banners.length]
  );

  return (
    <Panel
      data-testid="dashboard-news-notifications-panel"
      id={id}
      margin={{ bottom: 'sm' }}
      data-tour-stop="dashboard-news"
    >
      <Panel.Content className={styles.panelContent}>
        <Flex className={styles.content} centeredVertically>
          <Icon name="news" size="md" />

          <Gutter size="sm" />

          <Heading.H4>{t('dashboard.promotions.title')}</Heading.H4>
        </Flex>

        <Gutter size="sm" />

        <Div className={styles.promotions}>
          <Slider
            {...sliderSettings}
            className={styles.promotions_slider}
            ref={ref as React.LegacyRef<Slider>}
            beforeChange={(_, nextSlideIndex) => setActiveSlideIndex(nextSlideIndex)}
          >
            {banners.map(
              ({ title, message, imageUrl, hash, readMoreLink, readMoreLinkLabel }, index) => (
                <Div
                  key={hash}
                  id={`${id}-banner-${index}`}
                  className={classNames(styles.content, styles.promotion)}
                  role="tabpanel"
                  aria-labelledby={`${id}-banner-${index}-title`}
                >
                  <BannerImage
                    toggleAutoplay={toggleAutoplay}
                    playing={playing}
                    imageUrl={imageUrl}
                    title={title}
                    focusable={index === activeSlideIndex}
                    showAutoplayToggle={hasMultipleSlides}
                  />

                  <Gutter size="sm" />

                  <Heading.H4 id={`${id}-banner-${index}-title`}>{title}</Heading.H4>

                  <TextWithInlineHtml
                    className={classNames(styles.message, {
                      [styles.message__active]: index === activeSlideIndex
                    })}
                    text={message}
                  />

                  <Gutter size="sm" />

                  <Optional data={readMoreLink}>
                    {(readMoreLink: string) => (
                      <Analytics
                        whenClicked={pimcoreNotifications.newsBannerClicked}
                        className={classNames(styles.readMore, {
                          [styles.readMore__active]: index === activeSlideIndex
                        })}
                        data-testid="news-notifications-read-more-analytics"
                      >
                        <Button
                          data-testid="news-notifications-read-more"
                          className={styles.readMore_button}
                          onClick={() => onReadMore(readMoreLink)}
                          type="button"
                          variant="secondary"
                          size="sm"
                          tabIndex={index === activeSlideIndex ? 0 : -1}
                          aria-hidden={index !== activeSlideIndex}
                        >
                          {readMoreLinkLabel || t('notifications.readMore')}

                          <Icon className={styles.readMore_icon} name="chevron-right" size="xs" />
                        </Button>
                      </Analytics>
                    )}
                  </Optional>

                  <Optional if={hasMultipleSlides}>
                    <Gutter size="sm" />
                  </Optional>
                </Div>
              )
            )}
          </Slider>
        </Div>
      </Panel.Content>
    </Panel>
  );
};

const BannerImage: React.FC<{
  toggleAutoplay: VoidFunction;
  playing: boolean;
  imageUrl: string;
  focusable: boolean;
  showAutoplayToggle: boolean;
  title?: string;
}> = ({ toggleAutoplay, playing, imageUrl, focusable, title, showAutoplayToggle }) => {
  const { t } = useTranslation();
  return (
    <Flex className={styles.imageContainer}>
      <Optional if={showAutoplayToggle}>
        <IconButton
          data-testid="news-notifications-autoplay"
          aria-label={playing ? t('aria.banners.autoplayPause') : t('aria.banners.autoplayResume')}
          className={[styles.autoplay]}
          name={playing ? 'tv-pause' : 'play-filled'}
          size="sm"
          onClick={toggleAutoplay}
          focusable={focusable}
        />
      </Optional>

      <Image src={imageUrl} alt={`${title}-image`} className={styles.image} />
    </Flex>
  );
};

const renderTabs = (id: string, activeSlideIndex: number) => {
  return (dots: React.ReactNode) => (
    <div>
      <div id={`${id}-tabs`} role="tablist" className={styles.dots} data-testid={`${id}-tabs`}>
        {React.Children.map(dots, (dot, index) =>
          React.cloneElement((dot as React.ReactElement).props.children as React.ReactElement, {
            className: index === activeSlideIndex ? 'slick-active' : ''
          })
        )}
      </div>
    </div>
  );
};

const renderTab = (
  t: TFunction,
  id: string,
  activeSlideIndex: number,
  ref: React.MutableRefObject<Slider | undefined>
) => {
  return (index: number) => {
    return (
      <button
        type="button"
        role="tab"
        aria-label={t('aria.banners.showBanner', { bannerNumber: `${index + 1}` })}
        aria-controls={`${id}-banner-${index}`}
        aria-selected={index === activeSlideIndex}
        id={`${id}-tab-${index}`}
        data-testid={`${id}-tab-${index}`}
        tabIndex={index === activeSlideIndex ? 0 : -1}
        onKeyDown={onTabKeyDown(id, ref)}
      />
    );
  };
};

const onTabKeyDown = (
  id: string,
  ref: React.MutableRefObject<Slider | undefined>
): React.KeyboardEventHandler<HTMLButtonElement> => {
  return (e) => {
    switch (e.code) {
      case 'ArrowLeft':
        ref.current?.slickPrev();
        focusHtmlElementOnNextFrame(() =>
          document.getElementById(id)?.querySelector('.slick-active[role="tab"]')
        );
        break;
      case 'ArrowRight':
        ref.current?.slickNext();
        focusHtmlElementOnNextFrame(() =>
          document.getElementById(id)?.querySelector('.slick-active[role="tab"]')
        );
        break;
      case 'Enter':
        e.stopPropagation();
        e.preventDefault();
        break;
    }
  };
};

export default Banners;
