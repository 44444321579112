import { ModalDrawer } from 'common-components';
import { noOp } from 'doings/noOp/noOp';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditableColumns, EditableListId } from 'types/listConfig';

import PendingChangesGuardModal from '../PendingChangesGuardModal/PendingChangesGuardModal';
import usePendingChangesGuard from '../PendingChangesGuardModal/usePendingChangesGuard';
import { EditableListModalContent, Interactions } from './EditableListModalContent';
import { EditableListModalProvider } from './EditableListModalProvider';

export const EditableListModal: React.FC<{
  configurableListId: EditableListId;
  configurableColumns: EditableColumns;
  isOpen?: boolean;
  onClose: VoidFunction;
}> = ({ configurableListId, configurableColumns, isOpen = false, onClose }) => {
  const { t } = useTranslation();

  const {
    hasChanges,
    showConfirmation,
    closeConfirmation,
    onClose: onCloseGuarded
  } = usePendingChangesGuard(onClose);

  const [interactions, setInteractions] = useState<Interactions>({
    disabled: false,
    onAccept: noOp,
    onClose: noOp,
    onReset: noOp
  });

  if (!configurableColumns) {
    return null;
  }

  return (
    <>
      <ModalDrawer
        isOpen={isOpen}
        title={t('modal.editList.title')}
        buttons={{
          modalButtons: {
            accept: {
              label: t('modal.editList.button.save'),
              onClick: interactions.onAccept,
              disabled: interactions.disabled,
              testIdSuffix: 'editable-list--save'
            },
            close: {
              label: t('modal.editList.button.cancel'),
              onClick: interactions.onClose,
              disabled: interactions.disabled,
              testIdSuffix: 'editable-list--cancel'
            },
            auxiliary: {
              label: t('modal.editList.button.restoreDefaults'),
              onClick: interactions.onReset,
              disabled: interactions.disabled,
              testIdSuffix: 'editable-list--reset'
            }
          }
        }}
        onClose={onCloseGuarded}
        data-testid="editable-list-modal"
      >
        <EditableListModalProvider
          apiId={configurableListId}
          columns={configurableColumns}
          onClose={onCloseGuarded}
        >
          <EditableListModalContent
            hasChanges={hasChanges}
            setDrawerInteractions={setInteractions}
          />
        </EditableListModalProvider>
      </ModalDrawer>

      <PendingChangesGuardModal
        showConfirmation={showConfirmation}
        closeConfirmation={closeConfirmation}
        dismissAction={onClose}
      />
    </>
  );
};
