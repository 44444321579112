import { Flex, Gutter, Panel } from '@gaiads/telia-react-component-library';
import { Icon, IconName, Paragraph } from '@teliafi/fi-ds';
import { Heading } from 'common-components';
import { Div, Divider } from 'core-components';

const ContactUsPanel: React.FC<{
  title: string;
  titleIcon: IconName;
  content: string;
  'data-tour-stop'?: string;
  'data-testid'?: string;
  children?: React.ReactNode;
}> = ({
  title,
  titleIcon,
  content,
  'data-tour-stop': htmlDataTourStop,
  'data-testid': testId,
  children
}) => {
  return (
    <Panel data-tour-stop={htmlDataTourStop} data-testid={testId}>
      <Panel.Content>
        <Flex centeredVertically>
          <Icon size="md" name={titleIcon} />

          <Gutter size="sm" />

          <Heading.H4>{title}</Heading.H4>
        </Flex>

        <Div margin={{ top: 'md', bottom: 'sm' }}>
          <Paragraph data-testid="contact-us-panel--content">{content}</Paragraph>
        </Div>

        <Divider />

        {children}
      </Panel.Content>
    </Panel>
  );
};

export default ContactUsPanel;
