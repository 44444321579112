import { editBillingEnabledWhen } from 'B2XApp/Invoicing/BillingGroups/BillingGroupQuicklinksMenu/BillingGroupsHelper';
import { Authorize, Button } from 'common-components';
import { AnalyticsEvent } from 'doings/track/analyticsEvents';
import { useTranslation } from 'react-i18next';

const EditBillingInfoButton: React.FC<{
  onClick: VoidFunction;
  analyticsEvent: AnalyticsEvent;
}> = ({ onClick, analyticsEvent }) => {
  const { t } = useTranslation();

  return (
    <Authorize
      data-testid="edit-billing-information-authorize"
      analyticsEvent={analyticsEvent}
      enabledWhen={editBillingEnabledWhen}
      element={
        <Button
          data-testid="edit-billing-information"
          type="button"
          variant="primary"
          onClick={onClick}
          disabledOnDemo
        >
          {t('billingInformation.editBillingInformation')}
        </Button>
      }
    />
  );
};

export default EditBillingInfoButton;
