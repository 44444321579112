import { Dropdown } from '@gaiads/telia-react-component-library';
import { OptionListOption } from '@gaiads/telia-react-component-library/build/types/components/OptionList/OptionList';
import { OnSortChange } from 'hooks/useSortableHeadings/useSortableHeadings';
import { useTranslation } from 'react-i18next';
import { SortOrder, SortParameter } from 'types/sort';

const getSortField = (str: string): string => str.replace('Asc', '').replace('Desc', '');

const getSortDirection = (str: string): SortOrder => {
  const sortDirectionIndex = 3;
  const sortDirection = str.substring(str.length - sortDirectionIndex);

  return sortDirection === 'Asc' ? SortOrder.ASC : SortOrder.DESC;
};

const optionToSort = ({ value }: OptionListOption) => ({
  field: getSortField(value),
  direction: getSortDirection(value)
});

const fieldsToOptions = (fields: string[]): OptionListOption[] =>
  fields
    .reduce((options, field) => [...options, field + 'Asc', field + 'Desc'], [] as string[])
    .map((field) => ({
      label: field,
      value: field
    }));

const orderToString = (order?: SortOrder): string => (order === SortOrder.DESC ? 'Desc' : 'Asc');

const currentSortToString = (parameter: SortParameter): string =>
  parameter.sort + orderToString(parameter.order);

const SortDropdown: React.FC<{
  fields: string[];
  onChange: OnSortChange;
  currentSort: SortParameter;
  'data-testid'?: string;
}> = ({ fields, onChange, currentSort, 'data-testid': testId }) => {
  const { t } = useTranslation();
  const options = fieldsToOptions(fields).map(({ label, value }) => ({
    label: t(`subscriptions.list.sort.${label}`),
    value
  }));
  const currentSortString = currentSortToString(currentSort);

  return (
    <Dropdown
      data-testid={testId}
      label={t('subscriptions.list.sort.order')}
      aria-label={t('subscriptions.list.sort.order')}
      options={options}
      selectedOption={options.find(({ value }) => value === currentSortString)}
      onChange={(_event, option: OptionListOption) => onChange(optionToSort(option))}
    />
  );
};

export default SortDropdown;
