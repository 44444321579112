import { Div, Flex, Gutter, Image, RadioButton } from '@gaiads/telia-react-component-library';
import {
  DefinitionList,
  DetailsLoadingOverlay,
  InlineNotification,
  ModalDialog
} from 'common-components';
import isDemoEnvironment from 'doings/isDemoEnvironment/isDemoEnvironment';
import { AnalyticsEvent, invoicing } from 'doings/track/analyticsEvents';
import trackEvent from 'doings/track/trackEvent';
import useGetPaymentBanks from 'hooks/invoicing/invoices/useGetPaymentBanks/useGetPaymentBanks';
import usePayInvoice from 'hooks/invoicing/invoices/usePayInvoice/usePayInvoice';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceDetailsType } from 'types/invoice';

import bankLogos from './banklogos';
import styles from './InvoicePaymentModal.module.scss';

export const INVOICE_PAYMENT_MODAL = 'INVOICE_PAYMENT_MODAL';

const InvoicePaymentModal: React.FC<{
  invoice: InvoiceDetailsType;
  isOpen: boolean;
  onCloseClick: VoidFunction;
}> = ({ invoice, isOpen, onCloseClick }) => {
  const { t } = useTranslation();
  const { banks } = useGetPaymentBanks();
  const { payInvoice, loading: paymentInitializing } = usePayInvoice();
  const [selectedBank, setSelectedBank] = useState<string>('');
  const isDemoEnv = isDemoEnvironment();

  const payInvoiceHandler = () => {
    trackEvent({
      ...invoicing.payInvoiceInBank,
      bankId: selectedBank
    } as AnalyticsEvent);
    payInvoice({ invoiceKey: invoice.key, bankId: selectedBank });
  };

  return (
    <ModalDialog
      title={t('invoice.payment.chooseBank')}
      isOpen={isOpen}
      acceptButton={{
        label: t('invoice.payment.continue'),
        onClick: payInvoiceHandler,
        disabled: !selectedBank || paymentInitializing || isDemoEnv
      }}
      closeButton={{
        label: t('invoice.payment.close'),
        onClick: onCloseClick,
        disabled: paymentInitializing
      }}
      onClose={onCloseClick}
      isNarrow
      data-testid="invoice-payment-modal"
    >
      <DetailsLoadingOverlay showLoader={paymentInitializing}>
        <DefinitionList addDividers>
          {banks.map(({ bankName, bankId }) => (
            <DefinitionList.Item key={`bank-option-${bankId}`}>
              <Flex>
                <Div flexItem>
                  <RadioButton
                    disabled={paymentInitializing}
                    data-testid={`bank-option-${bankId}`}
                    name="invoicePaymentBank"
                    className={styles.bankRadioButton}
                    label={
                      <Flex occupyHorizontalSpace>
                        <Div flexItem>{bankName}</Div>

                        {Object.hasOwn(bankLogos, bankId) && (
                          <Image
                            src={bankLogos[bankId as keyof typeof bankLogos]}
                            alt=""
                            style={{ height: '1.5em', width: 'auto' }}
                          />
                        )}
                      </Flex>
                    }
                    value={bankId}
                    onChange={() => setSelectedBank(bankId)}
                    checked={selectedBank === bankId}
                  />
                </Div>
              </Flex>
            </DefinitionList.Item>
          ))}
        </DefinitionList>
      </DetailsLoadingOverlay>

      <Gutter />

      <InlineNotification variant="information" content={{ key: 'invoice.payment.disclaimer' }} />
    </ModalDialog>
  );
};

export default InvoicePaymentModal;
