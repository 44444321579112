import { OptionListOption } from '@gaiads/telia-react-component-library/build/types/components/OptionList/OptionList';
import isValidMultiselect from 'doings/isValidMultiselect/isValidMultiselect';
import useTextInputModel, {
  TextInputModel
} from 'hooks/inputModels/useTextInputModel/useTextInputModel';
import { useEffect } from 'react';
import { MultiselectField } from 'types/form';

export type ValidationCallbackFunction = (isValid: boolean) => void;

export type MultiselectInputModel = TextInputModel & {
  options: OptionListOption[];
  valueLabel: string;
};

export type UseMultiselectInputModelParams = {
  options: OptionListOption[];
  initialValue?: string;
  required?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  onChange?: (value: string) => void;
  onValidationStatusChanged?: ValidationCallbackFunction;
};

export default ({
  options,
  initialValue = '',
  required = false,
  disabled = false,
  hidden = false,
  onChange
}: UseMultiselectInputModelParams): MultiselectInputModel => {
  const inputModel = useTextInputModel({
    initialValue,
    required,
    disabled,
    onChange: (value: string) => {
      if (
        onChange &&
        (!required || isValidMultiselect({ values: value.split(','), options } as MultiselectField))
      ) {
        onChange(value);
      }
    }
  });

  const { value: currentValue } = inputModel;
  const isCurrentValueAvailableInOptions = isValidMultiselect({
    values: currentValue.split(','),
    options: options,
    required: true
  } as MultiselectField);

  useEffect(() => {
    if (currentValue && !isCurrentValueAvailableInOptions) {
      inputModel.setValue('');
    }
  }, [isCurrentValueAvailableInOptions, inputModel, currentValue]);

  return {
    ...inputModel,
    isValid: !inputModel.required || isCurrentValueAvailableInOptions,
    hidden,
    options,
    valueLabel: options.find(({ value }) => value === currentValue)?.label || ''
  };
};
