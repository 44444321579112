export enum PaymentReminderType {
  Mobile = 'MOBILE',
  BroadbandAndFixed = 'BROADBAND_AND_FIXED'
}

export enum PaymentReminderStatus {
  On = 'ON',
  Off = 'OFF',
  Unavailable = 'N/A'
}

type PaymentReminderErrorCode = 'SUBSCRIPTIONNUMBER_NOT_FOUND';

export interface PaymentReminder {
  type: PaymentReminderType;
  status: PaymentReminderStatus;
  email?: string;
  phoneNumber?: string;
}

export interface PaymentRemindersResponse {
  [PaymentReminderType.Mobile]: PaymentReminder;
  [PaymentReminderType.BroadbandAndFixed]: PaymentReminder;
}

export interface PaymentReminderErrorItem {
  code: PaymentReminderErrorCode;
  reference?: PaymentReminderType;
}
