import { RouteType } from 'B2XApp/appRoutes';
import useRoutes from 'hooks/useRoutes/useRoutes';
import { useRouteMatch } from 'react-router-dom';

/**
 * Determines application routes which match the current route.
 *
 * Any matching routes with non-unique route keys only retain the routes with
 * the most specific path, i.e. the path with the most forward slashes `/` in
 * its path, or the path which is defined first. This avoids rendering
 * breadcrumbs which contain multiple crumbs with matching paths that don't
 * exist from a user's point of view and are logically included in another crumb.
 *
 * Example path `/tickets/new/asset/:id` without route keys:
 *
 * ```none
 * My Telia for Business >  // path: '/'
 * Fault reports >          // path: '/tickets'
 * Submit a fault report >  // path: '/tickets/new', included in below crumb
 * Submit a fault report    // path: '/tickets/new/asset/:id'
 * ```
 *
 * Example path `/tickets/new/asset/:id` with route keys:
 *
 * ```none
 * My Telia for Business >  // path: '/'
 * Fault reports >          // path: '/tickets'
 * Submit a fault report    // path: '/tickets/new/asset/:id'
 * ```
 */
export default (): { matchingRoutes: RouteType[] } => {
  const match = useRouteMatch();
  const routes = useRoutes();
  if (!match) {
    return { matchingRoutes: [] };
  }

  const replaceVariablesWithMatchParamsAndRemoveSpecifiers = (path: string) =>
    Object.entries<string>(match.params)
      .reduce((str, [param, value]) => str.replace(`:${param}`, value), path)
      .replace(/\([^)]+?\)/g, '');

  const getRouteDetails = ({ path, ...rest }: RouteType): RouteType => ({
    ...rest,
    path: match.params ? replaceVariablesWithMatchParamsAndRemoveSpecifiers(path) : path
  });

  const matchingRoutes = routes
    .filter(({ path }) => match.path.includes(path))
    .map(getRouteDetails)
    .reduce((acc, route) => {
      const key = route.routeKey ?? route.path;
      const prevRoute = acc[key];
      acc[key] = !!prevRoute && pathLength(prevRoute) >= pathLength(route) ? prevRoute : route;
      return acc;
    }, {} as Record<string, RouteType>);

  return { matchingRoutes: Object.values(matchingRoutes) };
};

const pathLength = (route: RouteType) => {
  return (route.path.match(/\//g) ?? []).length;
};
