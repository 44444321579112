import { Div } from '@gaiads/telia-react-component-library';
import getClassNames, { Argument } from 'classnames';
import NoPrivilegesTooltip from 'common-components/Authorize/NoPrivilegesTooltip/NoPrivilegesTooltip';
import authorizeUser from 'doings/authorizeUser/authorizeUser';
import chainEvents from 'doings/events/chainEvents/chainEvents';
import enableTextSelectionInLink from 'doings/events/enableTextSelectionInLink/enableTextSelectionInLink';
import { noOp } from 'doings/noOp/noOp';
import { Location, LocationDescriptor } from 'history';
import { Link as LinkFromRouter } from 'react-router-dom';
import { AuthorizationParams } from 'types/authorization';

import styles from './Link.module.scss';

/**
 * Determines whether a link should be rendered in disabled state.
 * A link could be disabled explicitly e.g. because of an unavailable
 * back-end service or an invalid form, or implicitly e.g. because of
 * insufficient user permissions or a company type that should not
 * access a bit of functionality.
 *
 * @param disabled Disable the link explicitly.
 * @param enabledWhen Disable the link implicitly via authorisation.
 */
export const shouldDisableLink = (disabled: boolean, enabledWhen?: AuthorizationParams) => {
  const unauthorised = !!enabledWhen && !authorizeUser(enabledWhen);
  return {
    isDisabled: disabled || unauthorised,
    isUnauthorised: unauthorised
  };
};

const Link: React.FC<{
  to?: LocationDescriptor | ((location: Location) => LocationDescriptor);
  className?: string | Array<string> | Argument;
  block?: boolean;
  disabled?: boolean;
  enabledWhen?: AuthorizationParams;
  external?: boolean;
  'aria-label'?: string;
  'data-testid'?: string;
  tabIndex?: number;
  allowTextSelection?: boolean;
  children: React.ReactNode;
  openInNewTab?: boolean;
  onClick?: (e: React.MouseEvent | React.KeyboardEvent) => void;
}> = ({
  to = '',
  className = [],
  block = false,
  disabled = false,
  enabledWhen,
  'aria-label': ariaLabel,
  'data-testid': dataTestId,
  tabIndex,
  allowTextSelection,
  children,
  openInNewTab = false,
  onClick
}) => {
  const classes = Array.isArray(className) ? className : [className];
  const { isDisabled, isUnauthorised } = shouldDisableLink(disabled, enabledWhen);
  if (isDisabled) {
    return (
      <NoPrivilegesTooltip
        i18nKey="common.tooltips.insufficientPermissions.forTextLink"
        showTooltip={isUnauthorised}
      >
        <Div
          data-testid={dataTestId}
          className={getClassNames([classes, styles.link, styles.link__disabled])}
          aria-disabled
        >
          {children}
        </Div>
      </NoPrivilegesTooltip>
    );
  }

  const clickHandler = (e: React.MouseEvent) => {
    if (!to) {
      e.preventDefault();
    }

    if (allowTextSelection) {
      chainEvents([enableTextSelectionInLink, onClick ?? noOp])(e);
    } else {
      onClick?.(e);
    }
  };

  return (
    <LinkFromRouter
      aria-label={ariaLabel}
      data-testid={dataTestId}
      className={getClassNames([
        classes,
        styles.link,
        block ? styles.link_block : styles.link_inline,
        allowTextSelection ? styles.link_selectable : undefined
      ])}
      to={to}
      tabIndex={tabIndex}
      draggable={allowTextSelection ? false : undefined}
      onClick={clickHandler}
      target={openInNewTab ? '_blank' : ''}
    >
      {children}
    </LinkFromRouter>
  );
};

export default Link;
