import { Input, TextArea } from '@gaiads/telia-react-component-library';
import getClassNames from 'classnames';
import { TextFieldProps } from 'common-components/Forms/FormField/FormField';
import { noOp } from 'doings/noOp/noOp';
import { useField, useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import {
  PHONE_MAX_LENGTH,
  POSTAL_CODE_LENGTH,
  TEXT_AREA_MAX_LENGTH,
  TEXT_FIELD_MAX_LENGTH
} from 'types/form';

import FormFieldHelperText from './FormFieldHelperText';
import styles from './FormFieldInput.module.scss';

const VARIANTS = {
  default: {
    className: undefined,
    maxLength: TEXT_FIELD_MAX_LENGTH
  },
  phone: {
    className: undefined,
    maxLength: PHONE_MAX_LENGTH
  },
  postalCode: {
    className: styles.postalCode,
    maxLength: POSTAL_CODE_LENGTH
  }
};

export type FormFieldInputVariant = keyof typeof VARIANTS;

const FormFieldInput = ({
  type,
  name,
  label,
  helperText,
  placeholder,
  fixedValue,
  required,
  disabled,
  archetype = 'default',
  testId
}: TextFieldProps) => {
  const [field, meta] = useField<string>(name);
  const { setFieldValue } = useFormikContext();
  const variant = VARIANTS[archetype];
  const isFixed = typeof fixedValue !== 'undefined';

  useEffect(() => {
    if (isFixed) {
      setFieldValue(field.name, fixedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFixed, fixedValue]);

  return useMemo(
    () => (
      <>
        {type === 'input' && (
          <Input
            data-testid={testId}
            className={variant.className}
            maxLength={variant.maxLength}
            id={field.name}
            name={field.name}
            value={field.value}
            required={required}
            disabled={isFixed || disabled}
            onBlur={field.onBlur}
            onChange={
              isFixed
                ? noOp
                : (_event, value) => {
                    setFieldValue(field.name, value);
                  }
            }
            data-confirmed={!!field.value && !meta.error}
            confirmed={!!field.value && !meta.error}
            data-errortext={meta.touched ? meta.error : ''}
            errorText={meta.touched ? meta.error : ''}
            label={label}
            aria-label={`${label}${required ? ' *' : ''}`}
            placeholder={placeholder}
          />
        )}

        {type === 'textarea' && (
          <>
            <AccessibleLabel
              id={`${field.name}-label`}
              htmlFor={field.name}
              label={label}
              required={required}
              testId={testId}
            />

            <TextArea
              data-testid={testId}
              maxLength={TEXT_AREA_MAX_LENGTH}
              id={field.name}
              name={field.name}
              value={field.value}
              required={false}
              disabled={isFixed || disabled}
              onBlur={field.onBlur}
              onChange={
                isFixed
                  ? noOp
                  : (_event, value) => {
                      setFieldValue(field.name, value);
                    }
              }
              errorText={meta.touched ? meta.error : ''}
              label=""
              aria-labelledby={`${field.name}-label`}
              placeholder={placeholder}
            />
          </>
        )}

        <FormFieldHelperText helperText={helperText} />
      </>
    ),

    /*
     * Only check for changes to significant properties which can change
     * between form field renders. Dependencies like `field` and `setFieldValue`
     * change on every render, so we need to exclude these to avoid unnecessary
     * and unperformant rerenders.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, required, disabled, fixedValue, archetype, field.value, meta.touched, meta.error]
  );
};

/**
 * Textarea does not have an accessible label (does not react to click
 * events and does not properly label the associated field), so need to
 * hack one in for improved accessibility till Gaia form elements are
 * all replaced.
 */
const AccessibleLabel: React.FC<{
  id: string;
  htmlFor: string;
  required?: boolean;
  label: string;
  testId?: string;
}> = ({ id, htmlFor, required, label, testId }) => (
  <label
    data-testid={`${testId}--label`}
    className={getClassNames([
      'telia-react-element',
      'telia-react-element__block',
      'telia-react-element__fontWeight__normal',
      'telia-react-bodyText__size_sm',
      'telia-react-color__black',
      'telia-react-typography',
      'telia-react-typography__teliasans'
    ])}
    id={id}
    htmlFor={htmlFor}
  >
    {label}

    <span className={getClassNames(['telia-react-element', 'telia-react-input_required'])}>
      {required && ' *'}
    </span>
  </label>
);

export default FormFieldInput;
