import { BodyText, Flex } from '@gaiads/telia-react-component-library';
import { DataPanel } from 'common-components';

export interface TicketAdditionalInfo {
  title: string;
  rows: string[];
}

const AdditionalTicketInformation: React.FC<{
  additionalInfo: TicketAdditionalInfo;
}> = ({ additionalInfo }) => {
  return (
    <DataPanel title={additionalInfo.title}>
      <Flex directionColumn>
        {additionalInfo.rows.map((row, index) => (
          <BodyText key={index}>{row}</BodyText>
        ))}
      </Flex>
    </DataPanel>
  );
};

export default AdditionalTicketInformation;
