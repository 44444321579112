import { gql } from '@apollo/client';

export default gql`
  query GetFaultReportDetails($ticketId: ID!) {
    ticket(id: $ticketId) {
      id
      relatedOrganization {
        id
        role
        name
      }
      relatedIndividual {
        role
        givenName
        familyName
        fullName
        contactMedium {
          type
          medium {
            emailAddress
            number
            type
          }
        }
      }
      attachments {
        relatedNote
        name
        url
      }
      priority
      relatedEntity {
        id
        role
        name
      }
      status
      companyCode
      companyName
      serviceName
      productName
      subscription
      requestDate
      name
      note {
        id
        text
        type
        date
        source
        authorFirstName
        authorLastName
      }
      description
      creationDate
      resolutionDate
      lastUpdate
      ticketType
      externalId
    }
  }
`;
