import { noOp } from 'doings/noOp/noOp';
import React, { useCallback, useRef } from 'react';

const PX_BETWEEN_TOGGLE_BUTTON_AND_FILTER_DIALOG = 8;

export const usePositionDialog = (filtersVisible: boolean) => {
  const anchorRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const dialogRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const onReposition = useCallback(() => {
    const anchor = anchorRef.current;
    /* istanbul ignore next */
    if (!anchor) {
      return;
    }

    const dialog = dialogRef.current;
    /* istanbul ignore next */
    if (!dialog) {
      return;
    }

    if (filtersVisible) {
      const anchorY = anchor.offsetTop + anchor.offsetHeight;
      const anchorX = anchor.offsetLeft + anchor.offsetWidth;
      const menuWidth = dialog.offsetWidth;

      const top = anchorY + PX_BETWEEN_TOGGLE_BUTTON_AND_FILTER_DIALOG;
      const left = anchorX - (anchorX >= menuWidth ? menuWidth : anchor.offsetWidth);

      dialog.style.top = `${top}px`;
      dialog.style.left = `${left}px`;
    } else {
      dialog.style.top = '-9999px';
      dialog.style.left = '-9999px';
    }
  }, [filtersVisible, anchorRef, dialogRef]);

  React.useEffect(() => {
    onReposition();
  }, [filtersVisible, onReposition]);

  React.useEffect(() => {
    if (filtersVisible) {
      window.addEventListener('resize', onReposition);
      return () => {
        window.removeEventListener('resize', onReposition);
      };
    }

    return noOp;
  }, [filtersVisible, onReposition]);

  return {
    anchorRef,
    dialogRef
  };
};
