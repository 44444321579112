import { useFeatureFlagsData } from 'contexts/FeatureFlagContext/FeatureFlagContext';
import { useUserData } from 'contexts/UserContext/UserContext';
import getEnvironmentVariable from 'doings/getEnvironmentVariable/getEnvironmentVariable';
import { isWholesaleBusiness } from 'doings/isWholesaleBusiness/isWholesaleBusiness';
import { multiplex } from 'doings/multiplex/multiplex';
import { useEffect } from 'react';

import useLekaneToken from './useLekaneToken';

const useLekane = (): void => {
  const { hasFeature } = useFeatureFlagsData();
  const { token } = useLekaneToken();
  const user = useUserData();
  const isB2OCompany = isWholesaleBusiness(user);

  const elementExists = !!document.getElementById('dialogueScript');
  const shouldMount = !isB2OCompany && !!token && !elementExists;

  useEffect(() => {
    if (!shouldMount) {
      return;
    }

    const script = document.createElement('script');
    script.id = 'dialogueScript';
    script.type = 'text/javascript';
    script.innerHTML = `
      let talkNowEntityId = "TeliaFi";
      let talkNowHostName = "${getLekaneHost(hasFeature)}";
      let talkNowSSLPort = "443";
      let talkNowUseProxy = false;
      let proxyURL = "";
      let lekaneServerURL = "https://" + talkNowHostName + ":" + talkNowSSLPort;
     
      (function d() {
        let ds = document.createElement("script");
        ds.type = "text/javascript";
        ds.async = true;
        ds.src = lekaneServerURL + "/lekane/boot?ei=" + talkNowEntityId;
        let s = document.getElementById("dialogueScript");

        s.parentNode.insertBefore(ds, s);
      })()
      
      function dialogueMetadata() {
        return { "MdAuthJWT": "${token}" };
      }
    `;

    document.body.appendChild(script);
  }, [shouldMount, hasFeature, token]);
};

const EMBEDDED_CHATBOT_PAGES = ['/chatbot_test_private', '/omalomake'];

const getLekaneHost = (hasFeature: ReturnType<typeof useFeatureFlagsData>['hasFeature']) => {
  const href = window.location.href;
  const currentRoute = href.substring(href.lastIndexOf('/'));
  const isEmbeddedHostEnabled = hasFeature('EART-5769');
  const isEmbeddedRoute = EMBEDDED_CHATBOT_PAGES.includes(currentRoute || '');
  const hostNameVar = multiplex([
    'REACT_APP_LEKANE_HOST',
    [isEmbeddedHostEnabled && isEmbeddedRoute, 'REACT_APP_LEKANE_HOST_EMBEDDED']
  ]);
  return getEnvironmentVariable(hostNameVar);
};

export default useLekane;
