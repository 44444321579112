import { BreadcrumbProps } from 'common-components/Breadcrumb/BreadcrumbContainer/BreadcrumbContainer';
import useRouteBreadcrumbs from 'hooks/useRouteBreadcrumbs/useRouteBreadcrumbs';
import dropRight from 'lodash/dropRight';
import { ChildAsset } from 'types/internetConnections';

const getRelatedChildAssets = (
  childAssetId: string,
  childAssets: ChildAsset[],
  assets: ChildAsset[] = []
): ChildAsset[] => {
  const childAsset = childAssets.find((childAsset) => childAsset.id === childAssetId);

  return childAsset
    ? getRelatedChildAssets(childAsset.parentId, childAssets, [childAsset, ...assets])
    : assets;
};

const createChildAssetBreadcrumbs = (
  assetId: string,
  childAssetId: string,
  childAssets: ChildAsset[],
  routeBreadcrumbItems: BreadcrumbProps[]
) =>
  getRelatedChildAssets(childAssetId, childAssets).reduce<BreadcrumbProps[]>(
    (breadcrumbs, childAsset) => [
      ...breadcrumbs,
      {
        path: `/internet-connections/${assetId}/children/${childAsset.id}`,
        label: childAsset.name
      }
    ],
    dropRight(routeBreadcrumbItems, 1)
  );

const useChildAssetBreadcrumbs = (
  assetId: string,
  childAssetId: string,
  childAssets: ChildAsset[]
): BreadcrumbProps[] => {
  const routeBreadcrumbs = useRouteBreadcrumbs();

  return createChildAssetBreadcrumbs(assetId, childAssetId, childAssets, routeBreadcrumbs);
};

export default useChildAssetBreadcrumbs;
