import { BodyText, Div, Flex, Gutter } from '@gaiads/telia-react-component-library';
import { useTranslation } from 'react-i18next';

import LoadingErrorImage from './LoadingErrorImage/LoadingErrorImage';

const DetailsLoadingErrorPanel = () => {
  const { t } = useTranslation();

  return (
    <Flex centered directionColumn occupyVerticalSpace data-testid="data-load-error-panel">
      <Div block>
        <LoadingErrorImage />
      </Div>

      <Gutter />

      <BodyText
        data-testid="data-load-error-title"
        fontWeight="medium"
        block
        margin={{ bottom: 'xxs' }}
      >
        {t('dataLoadError.title')}
      </BodyText>

      <BodyText data-testid="data-load-error-description" block alignTextToCenter>
        {t('dataLoadError.description')}
      </BodyText>
    </Flex>
  );
};

export default DetailsLoadingErrorPanel;
