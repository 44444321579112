import { GrowlNotification } from '@gaiads/telia-react-component-library';
import styles from 'B2XApp/TopNotices/FixedBar.module.scss';
import { useUserData } from 'contexts/UserContext/UserContext';
import getEnvironmentVariable from 'doings/getEnvironmentVariable/getEnvironmentVariable';
import openUrl from 'doings/openUrl/openUrl';
import { notifications } from 'doings/track/analyticsEvents';
import trackEvent from 'doings/track/trackEvent';
import { useShouldShowExpiredContactsVerification } from 'hooks/useShouldShowExpiredContactsVerification/useShouldShowExpiredContactsVerification';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { B2XCookie } from 'types/clientCookie';
import { B2XSessionStorage } from 'types/sessionStorage';

const ExpiredContactsBar: React.FC = () => {
  const [isClosed, setIsClosed] = useState(false);
  const user = useUserData();
  const { t } = useTranslation();
  const showContactsVerification = useShouldShowExpiredContactsVerification(user);

  if (isClosed || !showContactsVerification) {
    return null;
  }

  const ulmUrl = getEnvironmentVariable('REACT_APP_ULM_URL');
  const ulmProfilePage = `${ulmUrl}/profile`;

  const onClick = () => {
    const currentHostname = window.location.hostname;
    const cookieLocation = currentHostname.includes('telia.fi') ? '.telia.fi' : currentHostname;
    document.cookie = `${B2XCookie.EXPIRED_CONTACTS_VERIFICATION}=true;max-age=86400000;domain=${cookieLocation};path=/;secure;SameSite=Strict;`;

    trackEvent(notifications.navigateToULMProfilePage);
    openUrl(ulmProfilePage);
  };

  const onClose = () => {
    sessionStorage.setItem(B2XSessionStorage.CLOSED_EXPIRED_CONTACTS_VERIFICATION, 'true');
    setIsClosed(true);
  };

  return (
    <GrowlNotification
      data-testid="expired-contact-information-notification-bar"
      className={styles.fixedBar}
      notification={t('notifications.expiredContacts')}
      variant="info"
      ctaText={t('notifications.verifyContacts')}
      ctaOnClick={onClick}
      closeOnClick={onClose}
    />
  );
};

export default ExpiredContactsBar;
