import { Divider, Flex, Gutter } from '@gaiads/telia-react-component-library';
import { Skeleton } from 'common-components';

const InvoiceMainInfoSkeleton: React.FC = () => {
  return (
    <Skeleton.Panel>
      <Flex directionColumn>
        <Skeleton.TextRow width="12%" />

        <Gutter size="sm" />

        <Skeleton.TextRow width="30%" />

        <Skeleton.TextRow width="30%" />

        <Gutter size="sm" />

        <Skeleton.TextRow width="30%" />

        <Gutter size="xlg" />

        <Flex spaceBetween alignBaseline>
          <Skeleton.TextRow width="10%" />

          <Skeleton.TextRow width="10%" />
        </Flex>

        <Gutter />

        <Divider />

        <Gutter size="lg" />

        <Skeleton.Button width="20%" />

        <Gutter />

        <Skeleton.TextRow width="10%" />
      </Flex>
    </Skeleton.Panel>
  );
};

export default InvoiceMainInfoSkeleton;
