import { BodyText, Gutter } from '@gaiads/telia-react-component-library';
import { RelatedRecord } from 'B2XApp/Messages/ServiceRequests/NewServiceRequest/useServiceRequestRelatedRecord';
import { DataPanel, DefinitionList, Repeat, Skeleton, TeliaLink } from 'common-components';
import { multiplex } from 'doings/multiplex/multiplex';
import { useConfirmationModal } from 'hooks/useCustomModal/useConfirmationModal';
import { useTranslation } from 'react-i18next';

const OriginatedFromPanel: React.FC<{
  relatedRecord?: RelatedRecord;
  loading: boolean;
  hasError: boolean;
}> = ({ relatedRecord, loading, hasError }) => {
  const { t } = useTranslation();
  const { onClick } = useConfirmationModal();
  const testId = multiplex([
    'originated-from-panel',
    [loading, 'originated-from-panel--loading'],
    [hasError, 'originated-from-panel--error']
  ]);

  return (
    <DataPanel
      data-testid={testId}
      title={t('serviceRequests.newServiceRequest.relatedAssetTitle')}
    >
      <OriginatedFromPanelContent loading={loading} hasError={hasError}>
        <DefinitionList>
          {relatedRecord?.relatedData.map((value: string, idx: number) => (
            <DefinitionList.Item key={idx}>{value}</DefinitionList.Item>
          ))}
        </DefinitionList>

        {relatedRecord && (
          <>
            <Gutter />

            <TeliaLink.Click
              data-testid="return-to-record-link"
              variant="standalone"
              onClick={() => onClick(relatedRecord.returnLinkPath)}
              label={t(relatedRecord.returnLinkLabel)}
            />
          </>
        )}
      </OriginatedFromPanelContent>
    </DataPanel>
  );
};

const OriginatedFromPanelContent: React.FC<{
  loading: boolean;
  hasError: boolean;
  children: React.ReactNode;
}> = ({ loading, hasError, children }) => {
  const { t } = useTranslation();

  if (loading) {
    return (
      <Repeat times={2}>
        <Skeleton.TextRow width="50%" />
      </Repeat>
    );
  }

  if (hasError) {
    return (
      <>
        <BodyText block>{t('dataLoadError.title')}</BodyText>

        <BodyText block>{t('dataLoadError.description')}</BodyText>
      </>
    );
  }

  return <>{children}</>;
};

export default OriginatedFromPanel;
