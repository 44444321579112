import { Icon } from '@teliafi/fi-ds';
import { Button } from 'common-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './MoreActionsDialogToggle.module.scss';

export const MoreActionsDialogToggle: React.FC<{
  onToggle: VoidFunction;
}> = ({ onToggle }) => {
  const { t } = useTranslation();

  return (
    <Button
      variant="secondary"
      onClick={(e) => {
        e.stopPropagation();
        onToggle();
      }}
      className={styles.button}
      data-testid="more-actions-dialog-toggle"
    >
      <Icon name="more-vertical" size="sm" />

      {t('common.moreActions.label')}
    </Button>
  );
};
