import RadioButtonGroup from 'common-components/RadioButtonGroup/RadioButtonGroup';
import { noOp } from 'doings/noOp/noOp';
import { useDateTypeInputModel } from 'hooks/inputModels/useDateTypeInputModel/useDateTypeInputModel';
import useWriteQueryParams from 'hooks/useQueryParams/useWriteQueryParams';
import React, { useState } from 'react';
import { DateTypeFilterDefinition, RegisterOnClearListenerFn } from 'types/listFilters';

export const DateTypeFilter: React.FC<{
  filter: DateTypeFilterDefinition;
  initialValue?: string;
  registerOnClearListener: RegisterOnClearListenerFn;
}> = ({ filter: radioButton, initialValue, registerOnClearListener }) => {
  const { write: writeQueryParams } = useWriteQueryParams();
  const [initialNormalizedValue] = useState(
    () =>
      radioButton.options.find((o) => o.value === initialValue)?.value ||
      radioButton.options[0].value
  );

  const input = useDateTypeInputModel({
    options: radioButton.options.map((option) => ({
      label: option.label,
      value: option.value
    })),
    initialValue: initialNormalizedValue,
    onChange: (value: string) =>
      writeQueryParams({
        [radioButton.param]: value
      })
  });

  React.useEffect(() => {
    registerOnClearListener(radioButton.param, () => {
      input.setValue(radioButton.options[0].value);
      return {
        [radioButton.param]: ''
      };
    });
    return noOp;
  }, [registerOnClearListener, radioButton.param, radioButton.options, input]);

  return (
    <RadioButtonGroup
      data-testid={radioButton['data-testid']}
      label={radioButton.label}
      inputModel={input}
    />
  );
};
