import { Flex, Loader } from '@gaiads/telia-react-component-library';
import LoadingErrorImage from 'common-components/DataPanel/DetailsLoadingErrorPanel/LoadingErrorImage/LoadingErrorImage';
import { useUserData } from 'contexts/UserContext/UserContext';
import useDynamicForm from 'hooks/dynamicForm/useForms/useDynamicForm';
import { ViewStatus } from 'hooks/useApiStatus/useApiStatus';
import React from 'react';

import DynamicForm, {
  DynamicFormObservers,
  DynamicFormOnLoadCallback,
  DynamicFormOnValidateCallback,
  DynamicFormState
} from './DynamicForm';
import { DynamicFormContext } from './DynamicFormContext';

const DynamicFormContainer: React.FC<{
  groupName: string;
  formName: string;
  formState?: DynamicFormState;
  fixedValues?: Record<string, string | undefined>;
  onLoad: DynamicFormOnLoadCallback;
  onValidate: DynamicFormOnValidateCallback;
  register?: DynamicFormObservers;
}> = ({ groupName, formName, formState, fixedValues = {}, onLoad, onValidate, register }) => {
  const { form, viewStatus } = useDynamicForm(groupName, formName);
  const { language: formLanguage } = useUserData();

  React.useEffect(() => {
    if (viewStatus === ViewStatus.Loaded && form) {
      onLoad(form);
    }
  }, [viewStatus, form, onLoad]);

  if (viewStatus === ViewStatus.Error) {
    return (
      <Flex centered data-testid="dynamic-form-error">
        <LoadingErrorImage />
      </Flex>
    );
  }

  if (viewStatus === ViewStatus.Loading) {
    return (
      <Flex centered data-testid="dynamic-form-loader">
        <Loader />
      </Flex>
    );
  }

  return (
    <>
      {form && (
        <DynamicFormContext.Provider value={{ formLanguage, form, fixedValues }}>
          <DynamicForm
            onValidate={onValidate}
            register={register}
            state={formState}
            data-testid="dynamic-form"
          />
        </DynamicFormContext.Provider>
      )}
    </>
  );
};

export default DynamicFormContainer;
