import { ThreeSixtyProductInventoryItemsResponse } from './useThreeSixtyInventory';

export default (
  previousResult: ThreeSixtyProductInventoryItemsResponse,
  { fetchMoreResult }: { fetchMoreResult?: ThreeSixtyProductInventoryItemsResponse }
): ThreeSixtyProductInventoryItemsResponse =>
  !fetchMoreResult
    ? previousResult
    : {
        threeSixtyProductInventory: {
          total: fetchMoreResult.threeSixtyProductInventory.total,
          products: [
            ...previousResult.threeSixtyProductInventory.products,
            ...fetchMoreResult.threeSixtyProductInventory.products
          ]
        }
      };
