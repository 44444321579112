import useIsSmallerBreakpointActive from 'hooks/useIsSmallerBreakpointActive/useIsSmallerBreakpointActive';
import { createContext, createRef, useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import { ContentSearchState, handleReposition } from './ContentSearchContext';

export const DashboardSearchContext = createContext<ContentSearchState | undefined>(undefined);

const DashboardSearchProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const isMobileLayout = useIsSmallerBreakpointActive('sm');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchFieldActive, setSearchFieldActive] = useState<boolean>(false);
  const isConversationOpen = useSelector((state: RootState) => state.askForHelpDrawer.isDrawerOpen);

  const searchFieldRef: React.RefObject<HTMLDivElement> = createRef();
  const resultsRef: React.RefObject<HTMLDivElement> = createRef();

  const onReposition = useCallback(() => {
    handleReposition(searchFieldRef, resultsRef, isMobileLayout);
  }, [isMobileLayout, searchFieldRef, resultsRef]);

  const onOutsideClickListener = useCallback(
    (e: MouseEvent) => {
      const searchField = searchFieldRef.current;
      const results = resultsRef.current;

      /* istanbul ignore next */
      if (!searchField || !results) {
        return;
      }

      if (
        e.target !== null &&
        !searchField.contains(e.target as Node) &&
        !results.contains(e.target as Node)
      ) {
        setSearchTerm('');
        setSearchFieldActive(false);
      }
    },
    [searchFieldRef, resultsRef]
  );

  const escListener = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      setSearchTerm('');
      setSearchFieldActive(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onReposition);
    document.addEventListener('keyup', escListener);
    document.addEventListener('click', onOutsideClickListener);

    return () => {
      window.removeEventListener('resize', onReposition);
      document.removeEventListener('keyup', escListener);
      document.removeEventListener('click', onOutsideClickListener);
    };
  }, [escListener, onOutsideClickListener, onReposition]);

  useEffect(() => {
    if (isConversationOpen) {
      setSearchFieldActive(false);
    }
  }, [isConversationOpen, setSearchFieldActive]);

  useEffect(() => {
    onReposition();
  }, [onReposition]);

  return (
    <DashboardSearchContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
        searchFieldRef: searchFieldRef,
        resultsRef: resultsRef,
        reposition: onReposition,
        searchFieldActive,
        setSearchFieldActive
      }}
    >
      {children}
    </DashboardSearchContext.Provider>
  );
};

const useDashboardSearchState = () => {
  const context = useContext(DashboardSearchContext);

  if (context === undefined) {
    throw new Error('useDashboardSearchState must be used with DashboardSearchContext');
  }

  return context;
};

export { DashboardSearchProvider, useDashboardSearchState };
