import { GridBag, Gutter } from '@gaiads/telia-react-component-library';
import { Skeleton } from 'common-components';

const TransferSubscriptionOptionsSkeleton: React.FC = () => {
  return (
    <Skeleton.Panel>
      <Skeleton.TextRow />

      <Gutter size="sm" />

      <GridBag>
        <GridBag.Item md={4}>
          <Skeleton.Image height="9rem" />
        </GridBag.Item>

        <GridBag.Item md={4}>
          <Skeleton.Image height="9rem" />
        </GridBag.Item>

        <GridBag.Item md={4}>
          <Skeleton.Image height="9rem" />
        </GridBag.Item>
      </GridBag>
    </Skeleton.Panel>
  );
};

export default TransferSubscriptionOptionsSkeleton;
