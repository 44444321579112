import isWordLetterAndNumbers from 'doings/isWordLetterAndNumbers/isWordLetterAndNumbers';
import useReadQueryParams from 'hooks/useQueryParams/useReadQueryParams';
import { ThreeSixtyProductInventoryQueryParameters } from 'hooks/useThreeSixtyInventory/useThreeSixtyInventory';
import { useToggleStateWithQueryParameter } from 'hooks/useToggleState/useToggleState';

export type ProductInventoryFilters = {
  queryParameters: ThreeSixtyProductInventoryQueryParameters;
  queryParametersAbsent: boolean;
  isValid: boolean;
  allCompanySearch: {
    showFromAllCompanies: boolean;
    toggleAllCompanies: VoidFunction;
  };
};

const validateSearchTerm = (scope?: string, search?: string) => {
  return scope === 'externalId' && !!search ? isWordLetterAndNumbers(search) : true;
};

export default (initialAllCompanies = false) => {
  const { scope, search, allCompanies } = useReadQueryParams(['scope', 'search', 'allCompanies']);
  const [showFromAllCompanies, toggleAllCompanies] = useToggleStateWithQueryParameter(
    initialAllCompanies,
    'allCompanies'
  );

  const queryParametersAbsent = !(!!search || !!allCompanies);

  return {
    queryParameters: {
      [scope ?? '']: search
    },
    allCompanySearch: {
      showFromAllCompanies,
      toggleAllCompanies
    },
    queryParametersAbsent,
    isValid: validateSearchTerm(scope, search)
  };
};
