import ErrorPageLayout from 'B2XApp/Errors/Error/ErrorPageLayout/ErrorPageLayout';
import pageNotFoundImg from 'B2XApp/Errors/NotFound/page-not-found.svg';
import { TeliaLink } from 'common-components';
import { useHistory } from 'react-router-dom';

const DataUnavailable: React.FC = () => {
  const { goBack } = useHistory();

  return (
    <ErrorPageLayout
      imageSrc={pageNotFoundImg}
      headlineKey="errors.dataUnavailable.headline"
      bodyKey="errors.dataUnavailable.body"
      translatables={[
        <TeliaLink.Translatable.Click
          key="data-unavailable-error"
          data-testid="go-back-suggestion-link"
          variant="text"
          onClick={goBack}
        />
      ]}
      keepPageTitle
    />
  );
};

export default DataUnavailable;
