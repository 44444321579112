import { Button as DSButton } from '@teliafi/fi-ds';
import isDemoEnvironment from 'doings/isDemoEnvironment/isDemoEnvironment';

type ButtonProps = React.ComponentProps<typeof DSButton> & {
  disabledOnDemo?: boolean;
};

export type ButtonVariantType = React.ComponentProps<typeof DSButton>['variant'];

export const Button: React.FC<ButtonProps> = ({ disabledOnDemo, ...props }) => {
  const isDemoEnv = isDemoEnvironment();
  const isDisabled = (disabledOnDemo && isDemoEnv) || props.disabled;
  return <DSButton {...props} onClick={props.onClick} disabled={isDisabled} />;
};
