import { multiplex } from 'doings/multiplex/multiplex';
import { dashboardNotifications } from 'doings/track/analyticsEvents';
import { useTranslation } from 'react-i18next';

const TOTAL_THRESHOLD = 100;
const TOTAL_MAX = '99+';

export default (overdueInvoices: number) => {
  const { t } = useTranslation();

  return {
    messagesMetainfo: {
      analyticsEvent: dashboardNotifications.messages,
      label: t('dashboard.notifications.archivedServiceRequestsWaitingForInformation'),
      errorLabel: t('dashboard.notifications.archivedServiceRequestsWaitingForInformationNA'),
      dataIdSuffix: 'messages',
      position: 1
    },

    serviceRequestsMetainfo: {
      analyticsEvent: dashboardNotifications.pendingServiceRequests,
      label: t('dashboard.notifications.pendingServiceRequests'),
      tooltip: t('dashboard.notifications.pendingServiceRequestsTooltip'),
      errorLabel: t('dashboard.notifications.pendingServiceRequestsNA'),
      dataIdSuffix: 'service-requests',
      position: 2
    },

    ticketsMetainfo: {
      analyticsEvent: dashboardNotifications.pendingTickets,
      label: t('dashboard.notifications.pendingTickets'),
      errorLabel: t('dashboard.notifications.pendingTicketsNA'),
      dataIdSuffix: 'tickets',
      position: 3
    },

    invoicesMetainfo: {
      analyticsEvent: dashboardNotifications.openInvoices,
      label: multiplex([
        t('dashboard.notifications.openInvoices'),
        [
          overdueInvoices >= TOTAL_THRESHOLD,
          t('dashboard.notifications.openDueInvoices', { totalOverdue: TOTAL_MAX })
        ],
        [
          overdueInvoices > 0,
          t('dashboard.notifications.openDueInvoices', { totalOverdue: overdueInvoices })
        ]
      ]),
      errorLabel: t('dashboard.notifications.openInvoicesNA'),
      dataIdSuffix: 'open-invoices',
      position: 4
    },

    customerSpecificMessagesMetainfo: {
      analyticsEvent: dashboardNotifications.customerSpecificMessages,
      label: t('dashboard.notifications.newCustomerSpecificMessages'),
      errorLabel: '',
      dataIdSuffix: 'customer-specific-messages',
      position: 5
    }
  };
};
