import { check } from 'B2XApp/DynamicForms/DynamicForm/dynamicFormFieldCheck';
import formatDatetime, { DateFormats } from 'doings/formatDatetime/formatDatetime';
import { TFunction } from 'i18next';
import { DynamicForms } from 'types/dynamicForms';

type Values = Record<string, Date | string | string[] | boolean | undefined>;
type Optional<T> = T | undefined;

type TiksuPriority = 'High' | 'Medium';
type Response = {
  description: string;
  product: string;
  subscriptionId?: Optional<string>;
  invoiceId?: Optional<string>;
  priority: Optional<TiksuPriority>;
  productCategory?: Optional<string>;
  incidentAddressStreet?: Optional<string>;
  incidentAddressPostalCode?: Optional<string>;
  incidentAddressPostalOffice?: Optional<string>;
};

/**
 * Marshalls a description for new service requests or fault reports based on
 * provided dynamic form state, and exposes significant selections (product,
 * Tiksu priority).
 *
 * @remarks
 * Omits infotext fields and the following named fields from a marshalled
 * description to avoid repeating information in an outgoing service request
 * or fault report exposed via significant selections:
 * * `product` — because its value is directly used for a separate field in
 *   outgoing requests and provides no additional info.
 * * `productOverride` — because its value overrides the `product` selection in
 *   outgoing requests. Although `subproduct` selections also override product
 *   selections, a subproduct is considered additional information that can be
 *   key for customer care to fulfill a particular request.
 *
 * @param t Translation function.
 * @param fields Filtered dynamic form fields.
 * @param values User-provided or user-selected values.
 */
export default (t: TFunction, fields: DynamicForms.Field[] = [], values: Values = {}): Response => {
  const outgoing = getOutgoingValues(fields, values);
  const sinkedValues = getSinkedValues(fields, values);
  const descriptionLines = fields.reduce((acc, field) => {
    if (!includeFieldInDescription(field)) {
      return acc;
    }

    if (check.isValidatable(field) && typeof sinkedValues[field.name] !== 'undefined') {
      return acc;
    }

    const value = resolveFieldValue(t, field, values, sinkedValues);
    if (check.isSubheader(field)) {
      acc.length && acc.push('');
      acc.push(field.label.toUpperCase());
    } else if (check.isCheckgroup(field)) {
      acc.push(`${sanitiseLabel(field.label)}:`);
      acc.push(`${value || '-'}`);
    } else if (check.isSink(field)) {
      const sanitisedLabel = field.label ? `${sanitiseLabel(field.label)}: ` : '';
      acc.push(`${sanitisedLabel}${value || '-'}`);
    } else {
      acc.push(`${sanitiseLabel(field.label)}: ${value || '-'}`);
    }

    return acc;
  }, [] as string[]);

  return {
    description: descriptionLines.map((line) => line.trimEnd()).join('\n'),
    product: outgoing.subproduct || outgoing.productOverride || outgoing.product,
    subscriptionId: outgoing.subscriptionId,
    invoiceId: outgoing.invoiceId,
    priority: sanitisePriority(outgoing.priority),
    productCategory: outgoing.productCategory,
    incidentAddressStreet: outgoing.incidentAddress.street,
    incidentAddressPostalCode: outgoing.incidentAddress.postalCode,
    incidentAddressPostalOffice: outgoing.incidentAddress.postalOffice
  };
};

const getOutgoingValues = (fields: DynamicForms.Field[], values: Values) => ({
  product: getSignificantDropdownValue(fields, values, 'product'),
  productOverride: getSignificantDropdownValue(fields, values, 'productOverride'),
  subproduct: getSignificantDropdownValue(fields, values, 'subproduct'),
  subscriptionId: getSignificantTextFieldValue(fields, values, 'subscriptionId'),
  invoiceId: getSignificantTextFieldValue(fields, values, 'invoiceId'),
  priority: getSignificantDropdownValue(fields, values, 'priority'),
  productCategory: getSignificantDropdownValue(fields, values, 'productCategory'),
  incidentAddress: {
    street: getSignificantTextFieldValue(fields, values, 'incidentAddressStreet'),
    postalCode: getSignificantTextFieldValue(fields, values, 'incidentAddressPostalCode'),
    postalOffice: getSignificantTextFieldValue(fields, values, 'incidentAddressPostalOffice')
  }
});

const getSignificantDropdownValue = (
  fields: DynamicForms.Field[],
  values: Values,
  fieldName: string
): string => {
  const field = fields.find(
    (field): field is DynamicForms.DropdownField =>
      check.isDropdown(field) && field.name === fieldName
  );

  return field?.options.find(({ value }) => value === values[field.id])?.value ?? '';
};

const getSignificantTextFieldValue = (
  fields: DynamicForms.Field[],
  values: Values,
  fieldName: string
): Optional<string> => {
  const field = fields.find(
    (field): field is DynamicForms.InputField | DynamicForms.TextareaField =>
      (check.isInput(field) || check.isTextarea(field)) && field.name === fieldName
  );

  return field && (values[field.id] as Optional<string>);
};

const SINKED_FIELDS_PATTERN = /{{(?<field>[^}]+?)}}/g;
const getSinkedValues = (fields: DynamicForms.Field[], values: Values): Record<string, string> => {
  return fields.reduce((acc, field) => {
    if (!check.isSink(field)) {
      return acc;
    }

    const fieldNamesToIds: Record<string, string> = fields.reduce((acc, field) => {
      if (check.isValidatable(field)) {
        acc[field.name] = field.id;
      }

      return acc;
    }, {} as Record<string, string>);

    let match: ReturnType<typeof SINKED_FIELDS_PATTERN.exec>;
    while ((match = SINKED_FIELDS_PATTERN.exec(field.sinkOutputFormat))) {
      const fieldName = match[1];
      const fieldId = fieldNamesToIds[fieldName];
      acc[fieldName] = fieldId ? String(values[fieldId] ?? '-') : '-';
    }

    return acc;
  }, {} as Record<string, string>);
};

const getSinkResult = (sinkOutputFormat: string, sinkedValues: Record<string, string>) => {
  return sinkOutputFormat.replace(SINKED_FIELDS_PATTERN, (_match, fieldName) => {
    return sinkedValues[fieldName];
  });
};

const includeFieldInDescription = (field: DynamicForms.Field) =>
  !isInformationalField(field) && !isOmittableDropdown(field) && !isOmittableTextField(field);

const isInformationalField = (field: DynamicForms.Field) => check.isInfotext(field);

const isOmittableDropdown = (field: DynamicForms.Field) =>
  check.isDropdown(field) && ['product', 'productOverride'].includes(field.name);

const isOmittableTextField = (field: DynamicForms.Field) =>
  (check.isInput(field) || check.isTextarea(field)) &&
  [
    'subscriptionId',
    'invoiceId',
    'incidentAddressStreet',
    'incidentAddressPostalCode',
    'incidentAddressPostalOffice'
  ].includes(field.name);

const resolveFieldValue = (
  t: TFunction,
  field: DynamicForms.Field,
  values: Values,
  sinkedValues: Record<string, string>
): Optional<string> => {
  if (check.isCheckbox(field)) {
    return resolveCheckboxField(field, values, t);
  }

  if (check.isCheckgroup(field)) {
    return resolveCheckboxGroupField(field, values, t);
  }

  if (check.isDate(field)) {
    return resolveDateField(field, values);
  }

  if (check.isDropdown(field)) {
    return resolveDropdownField(field, values);
  }

  if (check.isSink(field)) {
    return getSinkResult(field.sinkOutputFormat, sinkedValues);
  }

  return values[field.id] as Optional<string>;
};

const resolveCheckboxField = (field: DynamicForms.CheckboxField, values: Values, t: TFunction) => {
  const value = values[field.id] as Optional<boolean>;
  return value ? t('common.affirmation.yes') : t('common.affirmation.no');
};

const resolveCheckboxGroupField = (
  field: DynamicForms.CheckgroupField,
  values: Values,
  t: TFunction
) => {
  return field.options
    .map((o) => {
      const value = (values[field.id] as Optional<string[]>) || [];
      return `• ${o.label}: ${
        value.includes(o.id) ? t('common.affirmation.yes') : t('common.affirmation.no')
      }`;
    })
    .join('\n');
};

const resolveDateField = (field: DynamicForms.DateField, values: Values) => {
  const value = values[field.id] as Optional<Date>;
  if (field.dateWithMonthOnlySelection) {
    return formatDatetime(value, DateFormats.MONTH_NATIONAL);
  }

  const formattedDate = formatDatetime(value, DateFormats.DATE_NATIONAL);
  if (formattedDate !== '' && field.dateWithTimeSelection) {
    const hourValue = (values[`${field.id}-h`] as Optional<string>) || '00';
    const minValue = (values[`${field.id}-m`] as Optional<string>) || '00';
    return `${formattedDate} ${hourValue}:${minValue}`;
  }

  return formattedDate;
};

const resolveDropdownField = (field: DynamicForms.DropdownField, values: Values) => {
  if (field.multiple) {
    const value = (values[field.id] as Optional<unknown>) || [];
    if (Array.isArray(value)) {
      const sortedValuePartials = [...value].sort((a, b) => a.localeCompare(b));
      return sortedValuePartials.join(', ');
    }
  }

  if (['subproduct', 'priority'].includes(field.name)) {
    const optionValue = values[field.id] as Optional<string>;
    return field.options.find((o) => o.value === optionValue)?.label;
  }

  return values[field.id] as Optional<string>;
};

const HTML_TAGS_AND_TRAILING_FULL_STOP = /(<[^>]+?>)|(\.$)/g;
const sanitiseLabel = (unsanitisedText?: string) => {
  return unsanitisedText?.replace(HTML_TAGS_AND_TRAILING_FULL_STOP, '');
};

const sanitisePriority = (priority: string): Optional<TiksuPriority> => {
  if (['High', 'Medium'].includes(priority)) {
    return priority as TiksuPriority;
  }
  return undefined;
};
