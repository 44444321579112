import { ApolloError } from '@apollo/client';
import { GridBag, Gutter } from '@gaiads/telia-react-component-library';
import ErrorPage from 'B2XApp/Errors/Error/ErrorPage/ErrorPage';
import AssetDataPanel from 'B2XApp/InternetConnections/AssetDataPanel/AssetDataPanel';
import { Heading, TeliaLink } from 'common-components';
import AssetBreadcrumb from 'common-components/Breadcrumb/AssetBreadcrumb';
import DetailsPage from 'common-components/DetailsPage/DetailsPage';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { ChildAsset } from 'types/internetConnections';

const GrandChildLink: React.FC<{
  rootAssetId: string;
  grandChildAssetId: string;
  grandChildAssetName: string;
}> = ({ rootAssetId, grandChildAssetId, grandChildAssetName }) => (
  <TeliaLink.Internal
    variant="standalone"
    to={`/internet-connections/${rootAssetId}/children/${grandChildAssetId}`}
    label={grandChildAssetName}
    iconRight="chevron-right"
    key={grandChildAssetId}
    data-testid={`${grandChildAssetName}-link`}
  />
);

const ChildAssetDetailsPage: React.FC<{
  rootAssetId: string;
  rootAssetsChildAssets: ChildAsset[];
  childAssetId: string;
  childAsset?: ChildAsset;
  grandChildAssets: ChildAsset[];
  loading: boolean;
  error?: ApolloError;
}> = ({
  rootAssetId,
  childAssetId,
  rootAssetsChildAssets,
  childAsset,
  grandChildAssets,
  loading,
  error
}) => {
  const { t } = useTranslation();

  if (error) {
    return <ErrorPage error={error} />;
  }

  return (
    <DetailsPage
      data-testid="internet-connection-details-page"
      hasError={false}
      headline=""
      hideHeadline
      breadcrumb={
        <AssetBreadcrumb
          rootAssetId={rootAssetId}
          childAssetId={childAssetId}
          rootAssetsChildAssets={rootAssetsChildAssets}
        />
      }
    >
      <GridBag>
        <GridBag.Item md={6} sm={12}>
          <AssetDataPanel
            loading={loading}
            asset={childAsset}
            datapanelTestid={`asset-${childAsset?.id}`}
          >
            {grandChildAssets.length ? (
              <>
                <Gutter size="xlg" />

                <Heading.H5 key="additional-services-title">
                  {t('childAssetDetails.additionalServices')}
                </Heading.H5>
              </>
            ) : null}

            {grandChildAssets.map((grandChildAsset) => (
              <Fragment key={grandChildAsset.id}>
                <Gutter size="xs" />

                <GrandChildLink
                  rootAssetId={rootAssetId}
                  grandChildAssetId={grandChildAsset.id}
                  grandChildAssetName={grandChildAsset.name}
                />
              </Fragment>
            ))}
          </AssetDataPanel>
        </GridBag.Item>
      </GridBag>
    </DetailsPage>
  );
};

export default ChildAssetDetailsPage;
