import validators from 'hooks/inputModels/validation/validators';
import useDebounce from 'hooks/useDebounce/useDebounce';
import { useTranslation } from 'react-i18next';
import { ValidationResult } from 'types/formValidation';

export default (validationResult: ValidationResult): string => {
  const { t } = useTranslation();

  const { isValid, errors } = validationResult;
  const { expecting, errorType, explicitError } = errors;

  const hasMaxLengthError = errorType === 'maxLength';
  const maxLengthError = hasMaxLengthError
    ? t('validators.valueIsTooLong', { max: expecting.maxLength })
    : undefined;

  const hintToShowWithDelay = (function () {
    if (isValid || errorType !== 'implicit') {
      return undefined;
    } else if (expecting?.customHint) {
      return expecting.customHint;
    } else if (expecting?.a) {
      return t(validators[expecting.a].i18nKeyOnHint);
    } else {
      return t('validators.invalidValueFallback');
    }
  })();

  const defaultHintTimeout = 750;
  const hintTimeoutInMs = hintToShowWithDelay ? defaultHintTimeout : 0;
  const hint = useDebounce(hintToShowWithDelay || '', hintTimeoutInMs);

  return maxLengthError || explicitError || hint;
};
