import { gql } from '@apollo/client';
import getEnvironmentVariable from 'doings/getEnvironmentVariable/getEnvironmentVariable';
import useCallBackendMutation from 'hooks/useCallBackend/useCallBackendMutation';

export const payInvoiceMutation = gql`
  mutation PayInvoice($invoiceKey: String!, $bankId: String!) {
    payInvoice(invoiceKey: $invoiceKey, bankId: $bankId) {
      paymentOperationId
      paymentOperationValidation
      isMultibank
      bankId
    }
  }
`;

type PayInvoiceResponse = {
  payInvoice: {
    paymentOperationId: string;
    paymentOperationValidation: string;
    isMultibank: boolean;
    bankId: string;
  };
};

type InvoicePaymentDetails = {
  invoiceKey: string;
  bankId: string;
};

const usePayInvoice = () => {
  const { runMutation, loading } = useCallBackendMutation<PayInvoiceResponse>(
    payInvoiceMutation,
    undefined,
    { onCompleted: (data) => window.location.replace(createRedirectUrl(data.payInvoice)) }
  );

  const payInvoice = (paymentDetails: InvoicePaymentDetails) => {
    runMutation({
      variables: {
        invoiceKey: paymentDetails.invoiceKey,
        bankId: paymentDetails.bankId
      }
    });
  };

  const baseUrl = getEnvironmentVariable('REACT_APP_BANK_LINK_BASE_URL');

  const createRedirectUrl = (payInvoice: {
    paymentOperationId: string;
    paymentOperationValidation: string;
    isMultibank: boolean;
    bankId: string;
  }) => {
    const prefix = payInvoice.isMultibank ? `/multibank/direct/${payInvoice.bankId}` : '';

    return `${baseUrl}${prefix}/startPaymentConfirmation?paymentOperationId=${
      payInvoice.paymentOperationId
    }&paymentOperationValidation=${encodeURIComponent(payInvoice.paymentOperationValidation)}`;
  };

  return {
    payInvoice,
    loading
  };
};

export default usePayInvoice;
