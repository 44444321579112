import { addBusinessDays } from 'date-fns';
import isHoliday from 'doings/isHoliday/isHoliday';

import { toISODateString } from '../getFinnishHolidays/getFinnishHolidays';

export default (daysToAdd: number): string => {
  let currentDate = new Date();
  let remainingDaysToAdd = daysToAdd;

  while (remainingDaysToAdd > 0) {
    currentDate = addBusinessDays(currentDate, 1);
    if (!isHoliday(currentDate)) {
      remainingDaysToAdd -= 1;
    }
  }

  return toISODateString(currentDate);
};
