import useSubscriptionDetails from 'hooks/mobileSubscriptions/useSubscriptionDetails/useSubscriptionDetails';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import CreateTicket from './CreateTicket';
import { CreateTicketPageSkeleton } from './CreateTicketPageSkeleton';

const CreateSubscriptionTicket: React.FC = () => {
  const { t } = useTranslation();
  const { phoneNumberHash } = useParams<{ phoneNumberHash: string }>();
  const { subscription, status } = useSubscriptionDetails(phoneNumberHash);

  if (status === 'loading') {
    return <CreateTicketPageSkeleton data-testid="create-ticket-page-skeleton" />;
  }

  const companyId = subscription?.owner?.companyCode;
  const subscriptionId = subscription?.phoneNumber;
  const additionalInfoRows = subscription
    ? [subscription.type, subscription.userName, subscription.phoneNumber]
    : [];

  return (
    <CreateTicket
      data-testid="create-ticket-page"
      caseOrigin={{ type: 'subscription', companyId, subscriptionId }}
      additionalInfo={{ title: t('tickets.newTicket.relatedService'), rows: additionalInfoRows }}
      backToLabel={t('tickets.newTicket.backToSubscription')}
      backToPath={`/subscriptions/${phoneNumberHash}`}
    />
  );
};

export default CreateSubscriptionTicket;
