import { Flex, Gutter } from '@gaiads/telia-react-component-library';
import { OverflowMenuWrapper, TeliaLink } from 'common-components';
import MoreActionsDialog from 'common-components/ListViewActions/MoreActionsDialog/MoreActionsDialog';
import { MoreActionsDialogToggle } from 'common-components/ListViewActions/MoreActionsDialog/MoreActionsDialogToggle';
import { useOverflowMenuState } from 'contexts/OverflowMenuContext/OverflowMenuContext';
import { AnalyticsEvent } from 'doings/track/analyticsEvents';
import { AuthorizationParams } from 'types/authorization';

import styles from './ListViewActionButtons.module.scss';

export type MoreActionsLinkDescriptor = {
  key: string;
  to: string;
  toCorporatePortal?: string;
  useRouterLink?: boolean;
  label: string;
  tooltip?: string;
  enabledWhen: AuthorizationParams;
  track?: AnalyticsEvent;
  type: 'external' | 'internal' | 'portal' | 'click';
  onClick?: VoidFunction;
};

const ListViewActionButtons: React.FC<{
  mainActionButton: React.ReactElement;
  moreActionsLinks?: MoreActionsLinkDescriptor[];
}> = ({ mainActionButton, moreActionsLinks }) => {
  return (
    <OverflowMenuWrapper>
      <Flex pileVerticallyTo="xs">
        {mainActionButton}

        <Gutter size="sm" />

        <ListViewActionButtonsInner moreActionsLinks={moreActionsLinks} />
      </Flex>
    </OverflowMenuWrapper>
  );
};

const ListViewActionButtonsInner: React.FC<{
  moreActionsLinks?: MoreActionsLinkDescriptor[];
}> = ({ moreActionsLinks }) => {
  const { openMenu, toggleMenu, menuRef } = useOverflowMenuState();
  const isOpen = openMenu === 'more-actions';
  if (!moreActionsLinks) {
    return null;
  }

  return (
    <Flex className={styles.button}>
      <MoreActionsDialogToggle onToggle={() => toggleMenu('more-actions')} />

      <MoreActionsDialog
        isOpen={isOpen}
        refElement={menuRef}
        items={moreActionsLinks.map((link) => (
          <MoreActionsLink link={link} key={link.key} />
        ))}
      />
    </Flex>
  );
};

const MoreActionsLink: React.FC<{ link: MoreActionsLinkDescriptor }> = ({ link }) => {
  const {
    key,
    to,
    toCorporatePortal,
    useRouterLink = false,
    label,
    tooltip,
    enabledWhen,
    track,
    type,
    onClick
  } = link;

  if (type === 'portal') {
    if (!toCorporatePortal) {
      return null;
    }

    return (
      <TeliaLink.Portal
        data-testid={key}
        variant="standalone-action-menu"
        to={to}
        toCorporatePortal={toCorporatePortal}
        useRouterLink={useRouterLink}
        label={label}
        tooltip={tooltip}
        enabledWhen={enabledWhen}
        track={track}
      />
    );
  }

  if (type === 'click') {
    if (!onClick) {
      return null;
    }

    return (
      <TeliaLink.Click
        variant="standalone-action-menu"
        label={label}
        data-testid={key}
        tooltip={tooltip}
        enabledWhen={enabledWhen}
        track={track}
        onClick={onClick}
      />
    );
  }

  const MoreActionsLinkElement = type === 'external' ? TeliaLink.External : TeliaLink.Internal;
  return (
    <MoreActionsLinkElement
      data-testid={key}
      variant="standalone-action-menu"
      to={to}
      label={label}
      tooltip={tooltip}
      enabledWhen={enabledWhen}
      track={track}
    />
  );
};

export default ListViewActionButtons;
