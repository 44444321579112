export enum BillingMethods {
  'CONVERGENT' = 'CONVERGENT',
  'DIRECT' = 'DIRECT',
  'DIVIDER' = 'DIVIDER',
  'EBILL' = 'EBILL',
  'EDI' = 'EDI',
  'ELASKU' = 'ELASKU',
  'ELMA' = 'ELMA',
  'EMAIL' = 'EMAIL',
  'EPL' = 'EPL',
  'JOINT' = 'JOINT',
  'N/A' = 'N/A',
  'NET' = 'NET',
  'PAPER' = 'PAPER',
  'PAPERLESS' = 'PAPERLESS',
  'SUORAMAKSU' = 'SUORAMAKSU',
  'Z40' = 'Z40'
}
