import { Flex, Gutter } from '@gaiads/telia-react-component-library';
import { Skeleton } from 'common-components';

const RequestNewPlanSkeleton: React.FC = () => {
  return (
    <Skeleton.Panel>
      <Skeleton.Title width="40%" />

      <Gutter />

      <Skeleton.TextRow width="40%" />

      <Gutter size="xs" />

      <Skeleton.TextRow width="40%" />

      <Gutter size="lg" />

      <Skeleton.TextRow width="60%" />

      <Gutter size="lg" />

      <Skeleton.TextRow width="60%" />

      <Gutter />

      <Flex centeredVertically>
        <Skeleton.Button width="20%" />

        <Gutter />

        <Skeleton.Button width="20%" />
      </Flex>
    </Skeleton.Panel>
  );
};

export default RequestNewPlanSkeleton;
