import { gql } from '@apollo/client';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { ProductNewsFeedback } from 'types/productNews';

export const productNewsFeedbackByNewsQuery = gql`
  query GetProductNewsFeedbackByNews($newsId: String!) {
    productNewsFeedbackByNews(newsId: $newsId) {
      id
      userId
      vote
      feedback
      updatedAt
    }
  }
`;

interface QueryResponse {
  productNewsFeedbackByNews: ProductNewsFeedback[];
}

const useProductNewsStatsFeedback = ({ newsId }: { newsId: string }) => {
  const { data, error, loading } = useCallBackend<QueryResponse>({
    query: productNewsFeedbackByNewsQuery,
    queryVariables: { newsId },
    fetchPolicy: 'cache-first'
  });

  return {
    data: data?.productNewsFeedbackByNews || [],
    loading,
    error
  };
};

export { useProductNewsStatsFeedback };
