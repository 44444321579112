import { BillingGroupFormFinalSteps } from 'B2XApp/Invoicing/BillingGroups/BillingGroupForm/useBillingGroupForm';
import BillingGroupFormPageLayout from 'B2XApp/Invoicing/BillingGroups/Common/BillingGroupFormPageLayout';
import { useTranslation } from 'react-i18next';
import { AvailableBank, AvailableOperator, CollectionGroup } from 'types/collection-group';

import EditBillingGroupPageForm from './EditBillingGroupPageForm';
import useEditBillingGroupForm from './useEditBillingGroupForm';

const EditBillingGroupPage: React.FC<{
  collectionGroupId: string;
  collectionGroup: CollectionGroup;
  availableBanks: AvailableBank[];
  availableOperators: AvailableOperator[];
}> = ({ collectionGroupId, collectionGroup, availableBanks, availableOperators }) => {
  const { t } = useTranslation();
  const prerequisites = { collectionGroup, availableBanks, availableOperators };
  const { form } = useEditBillingGroupForm(prerequisites);
  const { step } = form;

  return (
    <BillingGroupFormPageLayout
      backLinkVisible={!BillingGroupFormFinalSteps.includes(step)}
      title={t('invoices.billingGroup.form.edit.title', { collectionGroupId })}
    >
      <EditBillingGroupPageForm form={form} prerequisites={prerequisites} />
    </BillingGroupFormPageLayout>
  );
};

export default EditBillingGroupPage;
