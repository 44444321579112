import { gql } from '@apollo/client';
import useCallBackendMutation from 'hooks/useCallBackend/useCallBackendMutation';
import { DynamicForms } from 'types/dynamicForms';

export const createServiceRequestMutation = gql`
  mutation CreateServiceRequest($requestData: CreateServiceRequestParameters, $groupId: String) {
    createServiceRequest(requestData: $requestData, groupId: $groupId) {
      code
      message
    }
  }
`;

export type CreateServiceRequestData = {
  subject: string;
  description: string;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  orderId?: string;
  productId?: string;
  productCategory?: string;
  isWorkOrder: boolean;
  invoiceNumber?: string;
  subscriptionNumber?: string;
  company?: string;
  requestedStartDate: string;
  contactMethods?: string;
  attachments?: File[];
  metainfo: { claudia: DynamicForms.DynamicFormMetainfoClaudiaField };
};

export type CreateServiceRequestResult = {
  code: number;
  message: string;
};

export default () => {
  const { runMutation, data, loading, error } = useCallBackendMutation<CreateServiceRequestResult>(
    createServiceRequestMutation
  );

  const createServiceRequest = async (requestData: CreateServiceRequestData) => {
    const { company: groupId, ...request } = requestData;
    runMutation({
      variables: {
        requestData: request,
        groupId: groupId
      }
    });
  };

  return {
    createServiceRequest,
    data,
    loading,
    error
  };
};
