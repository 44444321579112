import { GridBag, RadioButton } from '@gaiads/telia-react-component-library';
import { DateTypeInputModel } from 'hooks/inputModels/useDateTypeInputModel/useDateTypeInputModel';

type RadioButtonChangeHandler = (
  event: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLDivElement>,
  checked: boolean
) => void;

const GRID_LENGTH = 12;

const RadioButtonGroup: React.FC<{
  label: string;
  inputModel: DateTypeInputModel;
  'data-testid'?: string;
}> = ({ inputModel, 'data-testid': dataTestId }) => {
  const onChangeHandler: RadioButtonChangeHandler = (event, _checked) => {
    const newValue = (event.target as HTMLInputElement).value;
    inputModel.setValue(newValue);
  };

  return (
    <div data-testid={dataTestId}>
      <GridBag>
        {inputModel.options.map((item) => (
          <GridBag.Item
            md={GRID_LENGTH / inputModel.options.length}
            key={`radio-button-${item.value}`}
          >
            <RadioButton
              data-testid={`${dataTestId}-${item.value}`}
              checked={item.value === inputModel.value}
              name="dateTypeFilterSettings"
              label={item.label}
              value={item.value}
              onChange={onChangeHandler}
            />
          </GridBag.Item>
        ))}
      </GridBag>
    </div>
  );
};

export default RadioButtonGroup;
