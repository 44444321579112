import values from 'lodash/values';

import { DateFormat, DateFormats, DateTimeType } from './DateFormats';

export const identifyFormat = (date: string, type?: DateTimeType): DateFormat | undefined =>
  values(DateFormats)
    .filter((f) => typeof type === 'undefined' || f.type === type)
    .find((f) => f.match.test(date));

export const identifyDateFormat = (date: string) => identifyFormat(date, DateTimeType.DATE);

export const isIsoDateFormat = (date: string) => {
  if (!DateFormats.ISO_STRING.match.test(date)) {
    return false;
  }
  return new Date(date).toISOString() === date;
};
