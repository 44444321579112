import { InlineNotification } from 'common-components';
import formatDatetime from 'doings/formatDatetime/formatDatetime';

export const TerminationNotice: React.FC<{
  terminationDate: string;
}> = ({ terminationDate }) => (
  <InlineNotification
    title={{ key: 'subscriptions.subscriptionDetails.status.terminationTitle' }}
    content={{
      key: 'subscriptions.subscriptionDetails.status.terminationMessage',
      args: { terminationDate: formatDatetime(terminationDate) }
    }}
    variant="information"
    data-testid="termination-notification"
  />
);
