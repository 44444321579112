import useBillMillAccount from 'hooks/invoicing/billingGroups/useBillMillAccount/useBillMillAccount';
import { useParams } from 'react-router';

import BillMillAccountDetailsPage from './BillMillAccountDetailsPage';

interface BillMillAccountDetailsParams {
  accountId: string;
}

const BillMillAccountDetails: React.FC = () => {
  const { accountId } = useParams<BillMillAccountDetailsParams>();
  const { account, status } = useBillMillAccount(accountId);

  return <BillMillAccountDetailsPage account={account} status={status} />;
};

export default BillMillAccountDetails;
