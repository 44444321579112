import { Gutter } from '@gaiads/telia-react-component-library';
import { InlineNotification } from 'common-components';
import { useTranslation } from 'react-i18next';

const FeatureFlagFormError: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <InlineNotification variant="error" content={t('featureFlags.form.error')} />

      <Gutter />
    </>
  );
};

export default FeatureFlagFormError;
