import { ProgressBar } from 'common-components/ProgressBar/ProgressBar';
import { useTranslation } from 'react-i18next';

const ContractProgress: React.FC<{
  contractLength: number;
  contractProgress: number;
  billingBeginDate?: string;
  billingEndDate?: string;
}> = ({ contractLength, contractProgress, billingBeginDate, billingEndDate }) => {
  const { t } = useTranslation();

  return (
    <ProgressBar
      title={`${contractLength} ${t('common.date.shortForMonth')}`}
      unlimited={false}
      values={{ variant: 'fixed', pct: contractProgress }}
      labelLeft={<>{billingBeginDate}</>}
      labelRight={<>{billingEndDate}</>}
      data-testid="device-contact-progress-bar"
    />
  );
};

export default ContractProgress;
