import { Div, Flex } from '@gaiads/telia-react-component-library';
import { Heading } from 'common-components';
import Skeleton from 'common-components/Skeleton/Skeleton';

import DetailsPageHeadlineTag from './DetailsPageHeadlineTag';

const FULL_WIDTH = 1;
const HALF_WIDTH = 0.5;

const DetailsPageTitle: React.FC<{
  headline: string;
  headlineTag?: React.ReactNode;
  loading?: boolean;
  fullWidth?: boolean;
}> = ({ headline, headlineTag, loading = false, fullWidth = false }) => {
  return (
    <Div
      flexItem={{ sizeMultiplier: fullWidth ? FULL_WIDTH : HALF_WIDTH }}
      margin={{ bottom: 'md' }}
    >
      <Flex centeredVertically pileVerticallyTo="sm">
        {loading ? (
          <Skeleton.Title
            data-testid="details-page-headline-loading"
            width={fullWidth ? '50%' : undefined}
          />
        ) : (
          <>
            <Heading.H2 data-testid="details-page-headline-text">{headline}</Heading.H2>

            <DetailsPageHeadlineTag headlineTag={headlineTag} />
          </>
        )}
      </Flex>
    </Div>
  );
};
export default DetailsPageTitle;
