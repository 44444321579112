import { Flex, GridBag, Gutter } from '@gaiads/telia-react-component-library';
import { Skeleton } from 'common-components';

const BillingGroupFormSkeleton: React.FC = () => {
  return (
    <Skeleton.Panel>
      <Skeleton.Title width="40%" />

      <Gutter size="sm" />

      <GridBag noVerticalGutter>
        <GridBag.Item md={12}>
          <Skeleton.InputField />
        </GridBag.Item>

        <GridBag.Item md={12}>
          <Skeleton.InputField />
        </GridBag.Item>

        <GridBag.Item md={12}>
          <Skeleton.InputField />
        </GridBag.Item>

        <GridBag.Item xs={12} sm={5} md={5}>
          <Skeleton.InputField />
        </GridBag.Item>

        <GridBag.Item xs={12} sm={7} md={7}>
          <Skeleton.InputField />
        </GridBag.Item>
      </GridBag>

      <Gutter size="lg" />

      <Skeleton.Title width="40%" />

      <Gutter size="sm" />

      <GridBag noVerticalGutter>
        <GridBag.Item md={12}>
          <Skeleton.InputField />
        </GridBag.Item>

        <GridBag.Item md={12}>
          <Skeleton.InputField />
        </GridBag.Item>
      </GridBag>

      <Gutter size="lg" />

      <Skeleton.Title width="40%" />

      <Gutter size="sm" />

      <GridBag noVerticalGutter>
        <GridBag.Item md={12}>
          <Skeleton.InputField />
        </GridBag.Item>
      </GridBag>

      <Gutter size="lg" />

      <Skeleton.Title width="40%" />

      <Gutter size="sm" />

      <GridBag noVerticalGutter>
        <GridBag.Item md={12}>
          <Skeleton.InputField />
        </GridBag.Item>

        <GridBag.Item md={12}>
          <Skeleton.InputField />
        </GridBag.Item>

        <GridBag.Item md={12}>
          <Skeleton.InputField />
        </GridBag.Item>
      </GridBag>

      <Gutter size="lg" />

      <Flex centeredVertically>
        <Skeleton.Button width="20%" />

        <Gutter />

        <Skeleton.Button width="20%" />
      </Flex>
    </Skeleton.Panel>
  );
};

export default BillingGroupFormSkeleton;
