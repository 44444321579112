import { MarginSpace } from '@gaiads/telia-react-component-library';
import LanguageSelector, {
  LanguageSelectorProps
} from 'B2XApp/TopBar/LanguageSelector/LanguageSelector';
import { Div, Flex } from 'core-components';

import styles from './MobileNavigationItem.module.scss';

const MobileLanguageSelector: React.FC<LanguageSelectorProps> = (props) => (
  <Div className={styles.mobileNavigationItem} padding={{ vertical: 'xs' }}>
    <MarginSpace>
      <Flex className={styles.mobileNavigationItem_menuItem}>
        <LanguageSelector {...props} />
      </Flex>
    </MarginSpace>
  </Div>
);

export default MobileLanguageSelector;
