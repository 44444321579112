import { Gutter } from '@gaiads/telia-react-component-library';
import BalancePanel from 'B2XApp/Subscriptions/SubscriptionDetails/SubscriptionUsage/BalancePanel';
import { DataPanel, LinkGroup, Skeleton, TeliaLink } from 'common-components';
import createCorporatePortalLink, {
  createCorporatePortalLinkWithFragment
} from 'doings/links/createCorporatePortalLink/createCorporatePortalLink';
import { mobile } from 'doings/track/analyticsEvents';
import {
  Balance,
  SubscriptionStatus
} from 'hooks/mobileSubscriptions/useSubscriptionDetails/subscriptionDetailsQuery';
import { useTranslation } from 'react-i18next';

import { BalanceUnavailablePanel } from './BalanceUnavailablePanel';

interface SubscriptionUsageParams {
  phoneNumberHash?: string;
  status?: SubscriptionStatus;
  balance?: Balance;
  collectionGroup?: string | null;
  loading: boolean;
  allowBillingInfoEdit?: boolean;
}

const SubscriptionUsage: React.FC<SubscriptionUsageParams> = ({
  phoneNumberHash,
  status,
  balance,
  collectionGroup,
  loading,
  allowBillingInfoEdit = true
}) => {
  const { t } = useTranslation();
  const title = t('subscriptions.usage.title');
  const isPreopened = status === 'PREOPENED';
  const isDisabled = status === 'DISABLED';
  const isSuspended = status === 'SUSPENDED';
  if (isPreopened) {
    return (
      <DataPanel title={title}>
        <BalanceUnavailablePanel />
      </DataPanel>
    );
  }

  return (
    <DataPanel
      skeletonLoader={
        <Skeleton.Panel>
          <Skeleton.Title width="50%" />

          <Gutter />

          <Skeleton.TextRow width="60%" />
        </Skeleton.Panel>
      }
      title={title}
      loading={loading}
      hasError={!balance}
    >
      {balance && <BalancePanel balance={balance} />}

      <LinkGroup>
        {!!phoneNumberHash && (
          <LinkGroup.Link key="show-balance-info">
            <TeliaLink.External
              data-testid="balance-breakdown-external-link"
              variant="standalone"
              to={createCorporatePortalLink('mobileSubscriptionBalance', {
                msisdn: phoneNumberHash
              })}
              label={t('subscriptions.usage.balanceBreakdown')}
              iconRight="chevron-right"
              track={mobile.navigateToBalanceBreakdown}
              disabled={isPreopened || isSuspended || isDisabled}
              enabledWhen={{
                onePermission: 'B2B_PORTAL',
                anyPermission: ['MOBILE_SUBSCRIPTIONS']
              }}
            />
          </LinkGroup.Link>
        )}

        <LinkGroup.LineBreak />

        {!!collectionGroup && (
          <LinkGroup.Link key="show-billing-info">
            <TeliaLink.Internal
              data-testid="show-billing-information"
              variant="standalone"
              to={`/billing-accounts/${collectionGroup}`}
              label={t('subscriptions.usage.showBillingInformation')}
              iconRight="chevron-right"
              track={mobile.viewBillingAccount}
              enabledWhen={{
                anyPermission: ['BILLING', 'BILLING_VIEW']
              }}
            />
          </LinkGroup.Link>
        )}

        <LinkGroup.LineBreak />

        {allowBillingInfoEdit && (
          <LinkGroup.Link key="edit-billing-info">
            <TeliaLink.External
              data-testid="edit-billing-information"
              variant="standalone"
              to={createCorporatePortalLinkWithFragment('mobileDetails', 'billing-details', {
                msisdn: phoneNumberHash
              })}
              label={t('subscriptions.usage.editBillingInformation')}
              iconRight="chevron-right"
              track={mobile.changeBillingAccount}
              disabled={isPreopened || isSuspended || isDisabled}
              enabledWhen={{
                onePermission: 'B2B_PORTAL',
                anyPermission: ['MOBILE_SUBSCRIPTIONS']
              }}
            />
          </LinkGroup.Link>
        )}
      </LinkGroup>
    </DataPanel>
  );
};

export default SubscriptionUsage;
