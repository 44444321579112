import { ApolloError } from '@apollo/client';
import { Analytics, Optional } from 'common-components';
import ShowMoreResults from 'common-components/ShowMoreResults/ShowMoreResults';
import { modals } from 'doings/track/analyticsEvents';
import { useOfflineLimit } from 'hooks/useOfflineLimit/useOfflineLimit';
import { useTranslation } from 'react-i18next';
import { ProductNewsCategory as ActiveTab, ProductNewsItem } from 'types/productNews';

import styles from './NewsDrawerContent.module.scss';
import NewsDrawerContentCard from './NewsDrawerContentCard';
import { NewsDrawerContentSkeleton } from './NewsDrawerContentSkeleton';
import NewsDrawerNoDataMessage from './NewsDrawerNoDataMessage';

const TABS = {
  productUpdates: {
    noDataMessage: 'newsDrawer.noData.news',
    trackOnReadMore: modals.newsDrawerProductNewsClicked,
    trackOnShowMore: modals.newsDrawerShowMoreNewsClicked
  },
  productTips: {
    noDataMessage: 'newsDrawer.noData.tips',
    trackOnReadMore: modals.newsDrawerProductTipClicked,
    trackOnShowMore: modals.newsDrawerShowMoreTipsClicked
  }
} as const;

const PAGE_SIZE = 10;

const NewsDrawerContent: React.FC<{
  loading: boolean;
  error?: ApolloError;
  currentItems: ProductNewsItem[];
  initialItems: ProductNewsItem[];
  activeTab: ActiveTab;
}> = ({ loading, error, currentItems, initialItems, activeTab }) => {
  const { t } = useTranslation();
  const isEmpty = !loading && !error && !currentItems?.length;
  const { noDataMessage, trackOnReadMore, trackOnShowMore } = TABS[activeTab];

  const { limit, showMore } = useOfflineLimit([loading], PAGE_SIZE);
  const limitedItems = currentItems.slice(0, limit);

  return (
    <div
      id={activeTab}
      role="tabpanel"
      className={styles.content}
      data-testid="news-drawer-content"
    >
      <Optional if={loading}>
        <NewsDrawerContentSkeleton />
      </Optional>

      <Optional if={isEmpty}>
        <NewsDrawerNoDataMessage label={t(noDataMessage)} />
      </Optional>

      <Optional data={error}>
        <NewsDrawerNoDataMessage label={t('newsDrawer.error.title')} isError />
      </Optional>

      <Optional if={!loading && !error && !isEmpty}>
        <div className={styles.cards}>
          {limitedItems?.map((item) => (
            <NewsDrawerContentCard
              key={item.id}
              item={item}
              seen={!!initialItems.find((ii) => ii.id === item.id)?.seen}
              trackOnReadMore={trackOnReadMore}
            />
          ))}
        </div>

        <Optional if={limit < currentItems.length}>
          <Analytics whenClicked={trackOnShowMore} tagName="div">
            <ShowMoreResults
              data-testid="show-more-results"
              loading={false}
              hasMoreResults
              hasResults={currentItems.length > 0}
              fetchMore={showMore}
            />
          </Analytics>
        </Optional>
      </Optional>
    </div>
  );
};

export default NewsDrawerContent;
