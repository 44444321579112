import FI_Aktia from './aktia.png';
import FI_Aalandsbanken from './alands.png';
import FI_DanskeBankBusiness from './danske.png';
import FI_Handelsbanken from './handels.png';
import FI_NordeaBusiness from './nordea.png';
import FI_OmaSaastopankki from './omasaasto.png';
import OPBank from './osuus.png';
import FI_PopPankki from './pop.png';
import FI_Saastopankki from './saasto.png';
import FI_Spankki from './spankki.png';

const bankLogos = {
  FI_Aktia,
  FI_Aalandsbanken,
  FI_DanskeBankBusiness,
  FI_Handelsbanken,
  FI_NordeaBusiness,
  FI_OmaSaastopankki,
  OPBank,
  FI_PopPankki,
  FI_Saastopankki,
  FI_Spankki
};

export default bankLogos;
