import { Div } from '@gaiads/telia-react-component-library';
import { noOp } from 'doings/noOp/noOp';
import { Formik, FormikProps, FormikValues } from 'formik';
import useReadQueryParams from 'hooks/useQueryParams/useReadQueryParams';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DynamicForms } from 'types/dynamicForms';
import { AnySchema } from 'yup';

import { useDynamicFormContext } from './DynamicFormContext';
import DynamicFormField from './DynamicFormField';
import { check } from './dynamicFormFieldCheck';
import {
  filterFields,
  updateActiveCheckboxReferences,
  updateActiveCheckgroupOptionReferences,
  updateActiveDropdownOptionReferences
} from './dynamicFormFieldFilter';
import { getValidationSchema } from './dynamicFormValidation';
import { getDynamicFormMetainfoOverride } from './getDynamicFormMetainfoOverride';
import { getDynamicFormPrefills } from './getDynamicFormPrefills';
import getDynamicFormSubmitResponse from './getDynamicFormSubmitResponse';

export type DynamicFormOnLoadCallback = (form: DynamicForms.DynamicForm) => void;

export type DynamicFormOnValidateCallback = (
  isValid: boolean,
  form?: DynamicForms.DynamicForm,
  values?: Record<string, unknown>
) => void;

export type DynamicFormOnPresubmitObserver = {
  onPresubmit: (callback: () => string[]) => void;
};

export type DynamicFormOnSubmitObserver = {
  onSubmit: (callback: () => DynamicFormSubmitResponse) => void;
};

export type DynamicFormObservers = DynamicFormOnPresubmitObserver & DynamicFormOnSubmitObserver;

export type DynamicFormSubmitResponse = ReturnType<typeof getDynamicFormSubmitResponse> & {
  metainfoOverride?: DynamicForms.DynamicFormMetainfoOverride;
  state?: DynamicFormState;
};

export type DynamicFormState = {
  form: DynamicForms.DynamicForm;
  activeRefs: Set<string>;
  initialValues: ReturnType<typeof getDynamicFormPrefills>['prefillValues'];
};

const DynamicForm: React.FC<{
  state?: DynamicFormState;
  onValidate: DynamicFormOnValidateCallback;
  register?: DynamicFormObservers;
}> = ({ state, onValidate, register }) => {
  const { form } = useDynamicFormContext();
  const { d: deeplinkFromQueryParams } = useReadQueryParams(['d']);
  const { prefillValues, prefillActiveRefs } = useMemo(
    () => getDynamicFormPrefills({ form, deeplinkFromQueryParams }),
    // Calculate deeplink prefills only once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { t } = useTranslation();
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const fields = useRef(state?.form.fields ?? form.fields).current;
  const initialValues = state?.initialValues ?? prefillValues;

  const activeRefs = useRef(state?.activeRefs ?? prefillActiveRefs).current;
  const [activeRefChangeCount, setActiveRefChangeCount] = useState(0);
  const [formState, setFormState] = useState({
    filteredFields: [] as DynamicForms.Field[],
    validationSchema: undefined as AnySchema | undefined
  });

  /*
   * When checkbox or dropdown values change, active references are updated,
   * which may change the fields that are rendered and significant for
   * validation purposes. Filter fields and construct a new validation schema
   * synchronously right after the current render cycle, to avoid mid-render
   * changes to filtered fields.
   *
   * Note that since active references are stored in a `Set` for optimised
   * updates, with changes to a `Set` being invisible in the dependency array,
   * we have to provide a changing dependency instead (an increased active ref
   * change count), which indicates a change to active references.
   */
  useLayoutEffect(() => {
    const filteredFields = filterFields(formRef, fields, activeRefs);
    const validationSchema = getValidationSchema(filteredFields, t);
    setFormState({
      filteredFields,
      validationSchema
    });

    /*
     * The `t` hook never changes, expect in unit tests where it changes on
     every render cycle, so we can and have to exclude from the dependency
     array.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, activeRefs, activeRefChangeCount]);

  /*
   * When the validation schema changes, revalidate the form, as the layout
   * effect above doesn't immediatelly trigger Formik's validation callbacks
   * (Formik uses its ows layout effect in the background which needs to fire
   * first after filtering fields and creating a new validation schema).
   */
  useLayoutEffect(() => {
    if (formState.validationSchema) {
      formRef.current?.validateForm();
    }
  }, [formState.validationSchema]);

  /*
   * Changes to checkboxes and dropdowns need to update active references.
   * Note that we can reuse the callbacks for particular fields, removing
   * unnecessary checkbox and dropdown rerenders when the form is filtered
   * and some rendered fields have their visibility unchanged.
   */
  type CallbackFn<T = string | string[] | boolean> = (option: T) => void;
  type SupportedCallbackFn = CallbackFn<string> | CallbackFn<string[]> | CallbackFn<boolean>;
  const onChangeCallbacks = useRef(new Map<string, SupportedCallbackFn>()).current;
  const createOnChangeCallback = useCallback(
    (field: DynamicForms.Field): CallbackFn<any> | undefined => {
      if (onChangeCallbacks.has(field.id)) {
        return onChangeCallbacks.get(field.id);
      }

      switch (field.type) {
        case 'CHECKBOX': {
          const callback: CallbackFn<boolean> = (option) => {
            updateActiveCheckboxReferences(activeRefs, field, option);
            setActiveRefChangeCount((prev) => prev + 1);
          };

          onChangeCallbacks.set(field.id, callback);
          return callback;
        }

        case 'CHECKGROUP': {
          const callback: CallbackFn<string[]> = (option) => {
            updateActiveCheckgroupOptionReferences(activeRefs, field, option);
            setActiveRefChangeCount((prev) => prev + 1);
          };

          onChangeCallbacks.set(field.id, callback);
          return callback;
        }

        case 'DROPDOWN': {
          const callback: CallbackFn<string | string[]> = (option) => {
            updateActiveDropdownOptionReferences(activeRefs, field, option);
            setActiveRefChangeCount((prev) => prev + 1);
          };

          onChangeCallbacks.set(field.id, callback);
          return callback;
        }

        default: {
          return undefined;
        }
      }
    },
    [activeRefs, onChangeCallbacks]
  );

  /*
   * Memoised fields so we wouldn't re-render fields on every Formik update
   * cycle unless we have an updated set of filtered fields.
   */
  const renderedFields = useMemo(
    () =>
      formState.filteredFields
        .filter((f) => check.isVisible(f))
        .map((field: DynamicForms.Field) => (
          <Div key={`field-${field.id}`} margin={{ bottom: 'sm' }}>
            <DynamicFormField
              field={field}
              onChange={createOnChangeCallback(field)}
              data-testid={`dynamic-form-field-${field.id}`}
            />
          </Div>
        )),
    [formState.filteredFields, createOnChangeCallback]
  );

  /**
   * Validate the associated Yup schema and expose the validation result
   * via `onValidate`, so we could disable submitting a surrounding form
   * if this dynamic form is invalid, and re-enable submitting if valid.
   */
  const validate = useCallback(
    (values: Record<string, boolean | string | string[]>) => {
      try {
        (formState.validationSchema as AnySchema).validateSync(values, {
          abortEarly: false
        });
      } catch (error) {
        onValidate(false, form, values);
        if (error.name !== 'ValidationError') {
          throw error;
        }

        return error.inner.reduce(
          (errors: Record<string, string>, currentError: { path: string; message: string }) => {
            errors[currentError.path] = currentError.message;
            return errors;
          },
          {}
        );
      }

      onValidate(true, form, values);
      return {};
    },
    [formState.validationSchema, form, onValidate]
  );

  /**
   * Highlights all invalid fields and scrolls the user's view to the
   * topmost (first) invalid field. Returns invalid fields sorted by
   * field name in numerical order (e.g. ref1, ref2, ..., ref10, ...),
   * since numerically earlier fields are above later fields.
   */
  const handlePresubmit: () => string[] = useCallback(() => {
    /* istanbul ignore next */
    if (!formRef.current) {
      return [];
    }

    const form = formRef.current;
    const invalidFields = Object.keys(form.errors)
      .sort((a, b) => {
        const aNum = Number.parseInt(/\d+/.exec(a)?.[0] ?? '0');
        const bNum = Number.parseInt(/\d+/.exec(b)?.[0] ?? '0');
        const result = aNum - bNum;
        if (result !== 0) {
          return result;
        }

        return a.localeCompare(b);
      })
      .reduce((acc, key) => {
        acc.push(key);
        return acc;
      }, [] as string[]);
    form.setTouched(
      invalidFields.reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }, {} as Record<string, boolean>),
      true
    );

    return invalidFields;
  }, []);

  /**
   * Extract the dynamic form content on form submission from outside the
   * dynamic form. The dynamic form itself is never submitted directly, so we
   * need a mechanism to extract the dynamic form's user-input out of it.
   */
  const extractSubmitResponse: () => DynamicFormSubmitResponse = useCallback(() => {
    const values = formRef?.current?.values ?? {};
    return {
      ...getDynamicFormSubmitResponse(t, formState.filteredFields, values),
      metainfoOverride: getDynamicFormMetainfoOverride({ form, values }),
      state: {
        form,
        activeRefs,
        initialValues: values
      }
    };
  }, [formRef, formState, form, activeRefs, t]);

  /**
   * When we mount this dynamic form, we need to register an observer notified
   * on form submission so we could get the dynamic form's user-input out of it.
   */
  useEffect(() => {
    if (register) {
      register.onPresubmit(handlePresubmit);
      register.onSubmit(extractSubmitResponse);
    }
  }, [register, handlePresubmit, extractSubmitResponse]);

  return (
    <Div data-testid="dynamic-form-formik">
      <Formik
        innerRef={formRef}
        validateOnChange={true}
        validateOnBlur={true}
        validate={validate}
        initialValues={initialValues}
        onSubmit={noOp}
      >
        {() => {
          return renderedFields;
        }}
      </Formik>
    </Div>
  );
};

export default DynamicForm;
