import { SelectionButton } from '@gaiads/telia-react-component-library';
import { DynamicForms } from 'types/dynamicForms';

import styles from './DynamicFormSelectorButton.module.scss';

const DynamicFormSelectorButton: React.FC<{
  descriptor: DynamicForms.DynamicFormDescriptor;
  selected: boolean;
  disabled?: boolean;
  onClick: (value: string) => void;
}> = ({ descriptor, selected, disabled = false, onClick }) => {
  const testId = `dynamic-form-selector-button__${descriptor.name}`;
  return (
    <SelectionButton
      className={styles.twoLiner}
      occupyHorizontalSpace
      occupyVerticalSpace
      clickableAppearance
      id={testId}
      data-testid={testId}
      onClick={() => !selected && onClick(descriptor.name)}
      label={descriptor.label}
      fontWeight="medium"
      selected={selected}
      disabled={disabled}
    />
  );
};

export default DynamicFormSelectorButton;
