/**
 * Normalises a comma-separated value string by removing leading and trailing
 * whitespace and commas, and replacing multiple subsequent commas with a single
 * comma.
 *
 * @param input The input string to normalise.
 * @returns A normalised CSV string.
 */
export const normaliseCsvString = (input: string) => {
  return input
    .replace(/[\s,]*,[\s,]*/g, ',')
    .replace(/(^,|,$)/g, '')
    .trim();
};
