import { TeliaLink } from 'common-components';
import isDemoEnvironment from 'doings/isDemoEnvironment/isDemoEnvironment';
import { joinURLSearchParams } from 'doings/links/joinURLSearchParams/joinURLSearchParams';
import removeEmptyFields from 'doings/removeEmptyFields/removeEmptyFields';
import { UseReadQueryParamsResult } from 'hooks/useQueryParams/useReadQueryParams';
import { useTranslation } from 'react-i18next';
import { BillingMethods } from 'types/billingGroups';
import { SortParameter } from 'types/sort';

export type BillingGroupExcelDownloadLinkProps = {
  queryParameters: UseReadQueryParamsResult;
  disabled: boolean;
  sortParameter: SortParameter;
};

export const ExcelDownloadLink: React.FC<BillingGroupExcelDownloadLinkProps> = ({
  queryParameters,
  disabled,
  sortParameter
}) => {
  const { t } = useTranslation();
  const isDemoEnv = isDemoEnvironment();
  const { accountType, search: searchString, billingMethod } = queryParameters;
  const { sort, order } = sortParameter;

  const urlSearchParams = new URLSearchParams(
    removeEmptyFields({ searchString, billingMethod, accountType })
  );

  const getBillingTypeLabel = (type: string) =>
    t(`billingInformation.billingMethods.${type.toUpperCase()}`);

  const deliveryTypes = Object.values(BillingMethods)
    .map((type) => `${type.toUpperCase()}:${getBillingTypeLabel(type)}`)
    .join(',');

  const queryParams = joinURLSearchParams('?', {
    columns: [
      t('billingInformation.collectionGroup'),
      t('billingInformation.payerName'),
      t('billingInformation.billingReferenceListView'),
      t('billingInformation.billingAddress'),
      t('billingInformation.billingMethod')
    ].join(','),
    filter: urlSearchParams.toString(),
    sheetTitle: t('invoices.billingGroup.title'),
    billingAccountDeliveryTypes: deliveryTypes,
    sort,
    order
  });

  return (
    <TeliaLink.Download
      label={t('common.excelDownload.label')}
      to={`/api/billing-accounts/listAsExcel${queryParams}`}
      iconLeft="download"
      data-testid="billing-accounts-list-as-excel-link"
      disabled={isDemoEnv || disabled}
      downloadWithFetch
      useFileNameFromResponse
    />
  );
};
