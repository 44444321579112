import { Div, Span } from '@gaiads/telia-react-component-library';
import React from 'react';

export const TooltipTrigger = React.forwardRef<
  unknown,
  {
    children: NonNullable<React.ReactNode>;
    wrapper?: 'div' | 'span';
  }
>(({ children, wrapper, ...rest }, forwardedRef) => {
  const Tag = wrapper === 'div' ? Div : Span;
  return (
    <Tag refElement={forwardedRef} style={{ position: 'relative' }} {...rest}>
      {children}
    </Tag>
  );
});
