import { Icon, IconName } from '@teliafi/fi-ds';
import Analytics from 'common-components/Analytics/Analytics';
import ExternalLink from 'common-components/Link/ExternalLink/ExternalLink';
import Link from 'common-components/Link/Link';
import { SmartTooltip } from 'common-components/SmartTooltip/SmartTooltip';
import TextWithInlineHtml from 'common-components/TextWithInlineHtml/TextWithInlineHtml';
import { Div, Flex } from 'core-components';
import isCorporatePortalOrUlmUrl from 'doings/isCorporatePortalOrUlmUrl/isCorporatePortalOrUlmUrl';
import { AnalyticsEvent } from 'doings/track/analyticsEvents';
import { AuthorizationParams } from 'types/authorization';

import styles from './TileButton.module.scss';

export type TileButtonProps = {
  iconName: IconName;
  label: string;
  to?: string;
  whenClicked: AnalyticsEvent;
  disabled?: boolean;
  enabledWhen?: AuthorizationParams;
  tooltip?: string;
  showExternalIcon?: boolean;
  onClick?: VoidFunction;
  'data-testid': string;
};

export const TileButton: React.FC<TileButtonProps> = ({
  iconName,
  label,
  to,
  whenClicked,
  disabled = false,
  enabledWhen,
  tooltip,
  showExternalIcon = false,
  onClick,
  'data-testid': dataTestId
}) => {
  const internal = to?.startsWith('/');
  const LinkComponent = internal ? Link : ExternalLink;
  const linkType = internal ? 'internal' : 'external';
  return (
    <Div className={styles.tileButton} data-testid={dataTestId}>
      <Analytics whenClicked={whenClicked} inheritContainerHeight>
        <LinkComponent
          data-testid={`${dataTestId}-link-${linkType}`}
          className={styles.link}
          disabled={disabled}
          enabledWhen={enabledWhen}
          to={to}
          openInNewWindow={!internal && !isCorporatePortalOrUlmUrl(to)}
          onClick={onClick}
        >
          <Flex padding={{ size: 'xxs' }} centered directionColumn>
            <Icon className={styles.icon} name={iconName} size="lg" />

            <Flex className={styles.textContainer} centeredVertically>
              <TileButtonText
                dataTestId={`${dataTestId}-text`}
                label={label}
                showExternalIcon={showExternalIcon}
              />
            </Flex>
          </Flex>
        </LinkComponent>
      </Analytics>

      {tooltip && (
        <Div className={styles.tooltipOverlay} data-testid="tile-button-tooltip-overlay">
          <SmartTooltip tooltipContent={<TextWithInlineHtml text={tooltip} />} arrangement="top">
            <SmartTooltip.InfoIcon size="sm" />
          </SmartTooltip>
        </Div>
      )}
    </Div>
  );
};

const TileButtonText: React.FC<{
  label: string;
  showExternalIcon: boolean;
  dataTestId: string;
}> = ({ label, showExternalIcon, dataTestId }) => {
  if (!showExternalIcon) {
    return (
      <span data-testid={dataTestId} className={styles.text}>
        {label}
      </span>
    );
  }

  const lastSpacer = label.lastIndexOf(' ');
  if (lastSpacer <= 0) {
    return (
      <span data-testid={dataTestId} className={styles.text}>
        <span className={styles.textLastWord}>
          {label} <Icon className={styles.textLinkExternalIcon} name="external" size="sm" />
        </span>
      </span>
    );
  }

  const labelStart = label.substring(0, lastSpacer);
  const labelEnd = label.substring(lastSpacer);
  return (
    <span data-testid={dataTestId} className={styles.text}>
      <span>{labelStart}</span>{' '}
      <span className={styles.textLastWord}>
        {labelEnd} <Icon className={styles.textLinkExternalIcon} name="external" size="sm" />
      </span>
    </span>
  );
};
