import { GrowlNotification } from '@gaiads/telia-react-component-library';
import isValidUrl from 'doings/isValidUrl/isValidUrl';
import { openNewTab } from 'doings/openNewTab/openNewTab';
import { pimcoreNotifications } from 'doings/track/analyticsEvents';
import trackEvent from 'doings/track/trackEvent';
import { useCloseNotification } from 'hooks/notifications/useCloseNotification/useCloseNotification';
import { useNotifications } from 'hooks/notifications/useNotifications/useNotifications';
import { useTranslation } from 'react-i18next';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import styles from './NotificationsSystem.module.scss';

const NotificationsSystem = () => {
  const { notificationsSystem } = useNotifications();
  const { t } = useTranslation();
  const { closeNotification } = useCloseNotification();

  const notificationsFiltered = notificationsSystem.filter(({ readMoreLink }) => {
    if (readMoreLink) {
      return isValidUrl(readMoreLink);
    } else {
      return true;
    }
  });

  const onCloseClicked = (hash: string) => {
    trackEvent(pimcoreNotifications.systemNotificationClosed);
    closeNotification(hash);
  };

  const onClick = (readMoreLink?: string) => {
    trackEvent(pimcoreNotifications.systemNotificationClicked);
    openNewTab(readMoreLink);
  };

  return (
    <TransitionGroup>
      {notificationsFiltered.map(({ message, hash, variant, readMoreLink }) => (
        <CSSTransition
          key={hash}
          timeout={500}
          classNames={{
            enter: styles.systemNotification_enter,
            enterActive: styles.systemNotification_enterActive,
            exit: styles.systemNotification_exit,
            exitActive: styles.systemNotification_exitActive
          }}
        >
          <GrowlNotification
            className={styles.systemNotification}
            notification={message}
            variant={variant === 'information' ? 'info' : variant}
            closeOnClick={() => {
              onCloseClicked(hash);
            }}
            ctaText={readMoreLink ? t('notifications.readMore') : undefined}
            ctaOnClick={() => onClick(readMoreLink)}
          />
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
};

export default NotificationsSystem;
