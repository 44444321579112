import { Gutter } from '@gaiads/telia-react-component-library';
import { InlineNotification } from 'common-components';
import { useEffect } from 'react';

import { UpdatePaymentReminderNotice } from './useUpdatePaymentRemindersForm';

const elementId = 'payment-reminder-settings__update-notice';

const PaymentReminderSettingsUpdateNotice: React.FC<{
  noticeShown: boolean;
  notice: UpdatePaymentReminderNotice | undefined;
  onCloseNotification: VoidFunction;
}> = ({ noticeShown, notice, onCloseNotification }) => {
  useEffect(() => {
    const element = document.getElementById(elementId);
    if (element && noticeShown) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center'
      });
    }
  }, [noticeShown]);

  if (notice) {
    return (
      <>
        <InlineNotification
          title={{ key: notice.titleKey }}
          content={{ key: notice.messageKey }}
          variant={notice.variant}
          onClose={onCloseNotification}
          data-testid={elementId}
        />

        <Gutter />
      </>
    );
  }

  return null;
};

export default PaymentReminderSettingsUpdateNotice;
