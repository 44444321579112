import { BreakpointProvider } from '@gaiads/telia-react-component-library';
import { Breakpoints } from '@gaiads/telia-react-component-library/build/types/components/Breakpoint/Breakpoint';
import { FlexProps } from '@gaiads/telia-react-component-library/build/types/components/Flex/Flex';
import { Context, useContext } from 'react';

const breakpointToFlexAttribute = (
  breakpoint: Breakpoints
): FlexProps<'div'>['pileVerticallyTo'] => {
  switch (breakpoint) {
    case 'xxxs':
    case 'xxs':
      return 'xs';
    case 'xxlg':
      return 'lg';
  }

  return breakpoint;
};

export default (breakpoints?: Breakpoints[]): FlexProps<'div'>['pileVerticallyTo'] | undefined => {
  const breakpoint: Breakpoints = useContext(BreakpointProvider.Context as Context<Breakpoints>);

  const flexBreakpoint = breakpointToFlexAttribute(breakpoint);

  if (!breakpoints) {
    return flexBreakpoint;
  }

  return breakpoints.includes(breakpoint) ? flexBreakpoint : undefined;
};
