import { Div, Flex, Gutter, MarginSpace } from '@gaiads/telia-react-component-library';
import AskForHelpField from 'B2XApp/AskForHelp/AskForHelpField';
import { Heading } from 'common-components';
import ActiveCompanySelector from 'common-components/ActiveCompanySelector/ActiveCompanySelector';
import { useUserData } from 'contexts/UserContext/UserContext';
import useIsAskForHelpAvailable from 'hooks/askForHelp/useIsAskForHelpAvailable';

import styles from './Hero.module.scss';
import background from './Hero.webp';

const Hero: React.FC<{
  heading: string;
}> = ({ heading }) => {
  const { hasMultipleCompanies } = useUserData();
  const { askForHelpAvailable } = useIsAskForHelpAvailable();

  const hasSearchAndCompanyDropdown = askForHelpAvailable && hasMultipleCompanies;

  return (
    <Div
      data-testid="hero-background"
      className={styles.hero_backgroundImage}
      style={{ backgroundImage: `url(${background})` }}
    >
      <MarginSpace>
        <Div className={styles.hero}>
          <Flex
            className={styles.hero_content}
            centeredVertically
            pileVerticallyTo={hasSearchAndCompanyDropdown ? 'md' : 'sm'}
          >
            <Div flexItem={{ sizeMultiplier: 4 }} margin={{ top: 'sm' }}>
              <Heading.H1 data-testid="hero-heading" className={styles.hero_heading}>
                {heading}
              </Heading.H1>
            </Div>

            <Flex
              pileVerticallyTo="sm"
              flexItem={hasSearchAndCompanyDropdown ? { sizeMultiplier: 6 } : { sizeMultiplier: 3 }}
              margin={{ top: 'sm' }}
              alignRight
            >
              {askForHelpAvailable && (
                <Div flexItem className={styles.hero_askForHelpField}>
                  <AskForHelpField />
                </Div>
              )}

              {hasSearchAndCompanyDropdown && <Gutter />}

              {hasMultipleCompanies && (
                <Div flexItem className={styles.hero_activeCompanySelector}>
                  <ActiveCompanySelector lit />
                </Div>
              )}
            </Flex>
          </Flex>
        </Div>
      </MarginSpace>
    </Div>
  );
};

export default Hero;
