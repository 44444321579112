import { SubmenuProvider } from 'contexts/SubmenuContext/SubmenuContext';
import { useTranslation } from 'react-i18next';

import Backdrop from './Navigation/Backdrop';
import Navigation from './Navigation/Navigation';
import useNavigationItems from './Navigation/useNavigationItems';
import { SkipNavigationLink } from './SkipNavigationLink/SkipNavigationLink';
import styles from './TopNavigation.module.scss';

const TopNavigation: React.FC = () => {
  const navigationItems = useNavigationItems();
  const { t } = useTranslation();

  return (
    <SubmenuProvider>
      <nav
        id="top-navigation"
        className={styles.topNavigation}
        aria-label={t('aria.landmark.header')}
      >
        <SkipNavigationLink />

        <Navigation navigationItems={navigationItems} data-testid="top-navigation-navigation" />
      </nav>

      <Backdrop />
    </SubmenuProvider>
  );
};

export default TopNavigation;
