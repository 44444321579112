import { ActionButtonGroup, Authorize, Button } from 'common-components';
import { AnalyticsEvent } from 'doings/track/analyticsEvents';
import { useTranslation } from 'react-i18next';
import { AuthorizationParams } from 'types/authorization';

const BillingGroupFormButtons: React.FC<{
  dataTestIdPrefix: string;
  trackOnSave: AnalyticsEvent;
  saveEnabledWhen: AuthorizationParams;
  cancelEnabledWhen: AuthorizationParams;
  onSave: VoidFunction;
  onCancel: VoidFunction;
  canSave: boolean;
  canCancel: boolean;
}> = ({
  dataTestIdPrefix,
  trackOnSave,
  saveEnabledWhen,
  cancelEnabledWhen,
  onSave,
  onCancel,
  canSave,
  canCancel
}) => {
  const { t } = useTranslation();
  const testIdPrefix = `${dataTestIdPrefix}-form`;

  return (
    <ActionButtonGroup>
      <Authorize
        data-testid={`${testIdPrefix}__save-authorize`}
        analyticsEvent={trackOnSave}
        enabledWhen={saveEnabledWhen}
        element={
          <Button
            onClick={onSave}
            variant="primary"
            size="sm"
            disabled={!canSave}
            data-testid={`${testIdPrefix}__save`}
          >
            {t('invoices.billingGroup.form.save')}
          </Button>
        }
      />

      <Authorize
        data-testid={`${testIdPrefix}__cancel-authorize`}
        enabledWhen={cancelEnabledWhen}
        element={
          <Button
            onClick={onCancel}
            variant="tertiary-purple"
            size="sm"
            disabled={!canCancel}
            data-testid={`${testIdPrefix}__cancel`}
          >
            {t('common.cancelButton.label')}
          </Button>
        }
      />
    </ActionButtonGroup>
  );
};

export default BillingGroupFormButtons;
