import { Div, Flex, Gutter } from '@gaiads/telia-react-component-library';
import { Icon, Paragraph } from '@teliafi/fi-ds';
import ContactUsPanel from 'B2XApp/ContactUs/ContactUsPanel';
import { Button, Heading, TeliaLink } from 'common-components';
import { Divider } from 'core-components';
import getEnvironmentVariable from 'doings/getEnvironmentVariable/getEnvironmentVariable';
import openUrl from 'doings/openUrl/openUrl';
import { contactUs } from 'doings/track/analyticsEvents';
import trackEvent from 'doings/track/trackEvent';
import { useTranslation } from 'react-i18next';

const CustomerSupportPanel: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ContactUsPanel
      title={t('contactUs.panel.title.customerSupport')}
      titleIcon="support"
      content={t('contactUs.panel.text.customerSupport')}
      data-tour-stop="contact-us-customer-support-panel"
      data-testid="contact-us-customer-support-panel"
    >
      <TeliaLink.External
        variant="standalone-spacious"
        to={getEnvironmentVariable('REACT_APP_LINK_TO_CUSTOMER_SUPPORT_PAGES')}
        label={t('contactUs.panel.link.customerSupportPages')}
        iconRight="external"
        track={contactUs.customerSupportPages}
        data-testid="customer-support-pages-link"
      />

      <Divider />

      <Flex centeredVertically padding={{ top: 'md' }}>
        <Icon size="md" name="phone" />

        <Gutter size="sm" />

        <Heading.H4>{t('contactUs.panel.text.callSupport')}</Heading.H4>
      </Flex>

      <Div margin={{ top: 'md' }}>
        <Button
          type="button"
          variant="secondary"
          onClick={() => {
            trackEvent(contactUs.bookCall);
            openUrl(getEnvironmentVariable('REACT_APP_LINK_TO_BOOK_CUSTOMER_SUPPORT_CALL'), true);
          }}
          data-testid="book-a-call-button"
        >
          {t('contactUs.panel.link.bookCall')}

          <Icon name="external" size="xs" />
        </Button>
      </Div>

      <Div margin={{ top: 'sm' }}>
        <Paragraph>{t('contactUs.panel.text.customerSupportPhoneInfo')}</Paragraph>
      </Div>

      <Div margin={{ top: 'sm' }} color="purple700">
        <Heading.H3>{t('contactUs.panel.text.supportPhone')}</Heading.H3>
      </Div>

      <Div margin={{ top: 'sm' }}>
        <Paragraph>{t('contactUs.panel.text.callPriceInfo')}</Paragraph>
      </Div>

      <Div margin={{ top: 'sm' }}>
        <Paragraph>{t('contactUs.panel.text.callOpeningHours')}</Paragraph>
      </Div>
    </ContactUsPanel>
  );
};

export default CustomerSupportPanel;
