import { DATE_NATIONAL, DateFormats } from 'doings/formatDatetime/DateFormats';
import isFormat from 'doings/formatDatetime/isFormat';
import useCompanyCaseList from 'hooks/messageArchive/useCompanyCaseList/useCompanyCaseList';
import useReadQueryParams from 'hooks/useQueryParams/useReadQueryParams';
import { isCaseStatus } from 'types/companyCaseList';

import MessageArchiveListPage from './MessageArchiveListPage';

const isDateNational = isFormat<DATE_NATIONAL>(DateFormats.DATE_NATIONAL);

const MessageArchive: React.FC = () => {
  const queryParams = useReadQueryParams(['allCompanies', 'status', 'from', 'to', 'search']);
  const { allCompanies: allCompaniesFromQuery, status, from, to, search } = queryParams;

  const allCompanies = allCompaniesFromQuery !== 'false';
  const searchTerm = search ?? '';
  const { companyCases, sorting, fetchMore, apiState, error } = useCompanyCaseList({
    allCompanies,
    searchTerm,
    status: isCaseStatus(status) ? status : undefined,
    reportedDate: {
      fromDate: isDateNational(from) ? from : undefined,
      toDate: isDateNational(to) ? to : undefined
    }
  });

  return (
    <MessageArchiveListPage
      initialFilters={{ allCompanies }}
      queryParameters={queryParams}
      sort={sorting}
      companyCases={companyCases}
      fetchMore={fetchMore}
      apiState={apiState}
      error={error}
    />
  );
};

export default MessageArchive;
