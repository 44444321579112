import { Loader } from '@gaiads/telia-react-component-library';
import { Icon } from '@teliafi/fi-ds';
import { Button } from 'common-components';
import { useTranslation } from 'react-i18next';

import ActivityLogItemContent from './ActivityLogItemContent';
import styles from './ShowMoreActivities.module.scss';

const ShowMoreActivities: React.FC<{
  showMore: boolean;
  loading: boolean;
  hasMoreItems: boolean;
  fetchMore: VoidFunction;
  'data-testid'?: string;
}> = ({ showMore, loading, hasMoreItems, fetchMore }) => {
  const { t } = useTranslation();
  if (!hasMoreItems) {
    return null;
  }

  return (
    <>
      {loading ? (
        <Loader className={styles.loader} size="md" data-testid="show-more-activities-loader" />
      ) : (
        <ActivityLogItemContent showMore={showMore}>
          <Button
            className={styles.showMoreButton}
            type="button"
            variant="text"
            size="sm"
            onClick={fetchMore}
            data-testid="show-more-button"
          >
            {t('common.showMore')}

            <Icon name="chevron-down" size="sm" data-testid="show-more-results-icon" />
          </Button>
        </ActivityLogItemContent>
      )}
    </>
  );
};

export default ShowMoreActivities;
