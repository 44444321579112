import { Div, Icon } from '@gaiads/telia-react-component-library';

import styles from './ActivityLogPage.module.scss';

const Bullet: React.FC<{ large?: boolean; showMore?: boolean }> = ({ large, showMore }) => {
  return (
    <Div
      className={[
        styles.bullet,
        ...(large ? [styles.bullet_large] : []),
        ...(showMore ? [styles.bullet_last] : [])
      ]}
    >
      <Icon className="bulletIcon" name="dot" size={large ? 'md' : 'sm'} color="purple700" />
    </Div>
  );
};

export default Bullet;
