import { Div } from '@gaiads/telia-react-component-library';
import { MarginProps } from '@gaiads/telia-react-component-library/build/types/components/Element/getMarginClasses';
import { PaddingProps } from '@gaiads/telia-react-component-library/build/types/components/Element/getPaddingClasses';
import getClassNames from 'classnames';
import ExternalLink from 'common-components/Link/ExternalLink/ExternalLink';
import TextLink from 'common-components/Link/TextLink/TextLink';
import { useSubmenuState } from 'contexts/SubmenuContext/SubmenuContext';
import { AnalyticsEvent } from 'doings/track/analyticsEvents';
import { trackEventIfPresent } from 'doings/track/trackEvent';
import { GuardedEvent, useConfirmationGuard } from 'hooks/useCustomModal/useConfirmationGuard';
import { useTranslation } from 'react-i18next';

import styles from './NavigationMenuItem.module.scss';

const NavigationMenuItem: React.FC<{
  itemId: string;
  labelI18nKey: string;
  analyticsEvent?: AnalyticsEvent;
  to?: string;
  external?: boolean;
  padding?: PaddingProps;
  margin?: MarginProps;
  active?: boolean;
  onClick?: VoidFunction;
  disabled?: boolean;
  ['data-testid']?: string;
}> = ({
  itemId,
  labelI18nKey,
  to = '',
  analyticsEvent,
  external = false,
  padding = { size: 'sm' },
  margin = { size: 'zero' },
  active = false,
  onClick,
  disabled,
  'data-testid': testId
}) => {
  const { t } = useTranslation();
  const { setOpenMenuId, setMobileMenuOpen } = useSubmenuState();
  const { showConfirmationModal, onGuardedClick } = useConfirmationGuard();

  const closeOpenMenusAndTrackClick = (e: GuardedEvent) => {
    setOpenMenuId('');
    setMobileMenuOpen(false);
    trackEventIfPresent(analyticsEvent);
    if (typeof onClick === 'undefined') {
      onGuardedClick(e, to);
    }
  };

  const classNames = getClassNames({
    [styles.navigationMenuItem__active]: active
  });

  return external ? (
    <Div
      onClick={closeOpenMenusAndTrackClick}
      className={classNames}
      backgroundColor="white"
      data-testid={testId ?? 'navigation-menu-item'}
    >
      <ExternalLink
        data-testid={`navigation-menu-item-${itemId}`}
        to={to}
        label={t(labelI18nKey)}
        suppress={showConfirmationModal}
        padding={padding}
        margin={margin}
        color="black"
        hoverColor="purple500"
        fontWeight="normal"
        singleLine
        block
        onClick={onClick}
        disabled={disabled}
      />
    </Div>
  ) : (
    <Div onClick={closeOpenMenusAndTrackClick} backgroundColor="white">
      <TextLink
        data-testid={`navigation-menu-item-${itemId}`}
        className={classNames}
        to={to}
        label={t(labelI18nKey)}
        suppress={showConfirmationModal}
        padding={padding}
        margin={margin}
        color="black"
        hoverColor="purple500"
        fontWeight="normal"
        singleLine
        block
        onClick={onClick}
        disabled={disabled}
      />
    </Div>
  );
};

export default NavigationMenuItem;
