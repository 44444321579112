import { gql } from '@apollo/client';
import useApiStatus from 'hooks/useApiStatus/useApiStatus';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { CompanyCaseListTotalResult } from 'types/companyCaseList';

import { CompanyCaseListParams } from './useCompanyCaseList';

export const companyCaseListTotalQuery = gql`
  query GetCompanyCasesTotal($requestData: GetCompanyCaseListParams!) {
    companyCaseList(requestData: $requestData) {
      total
    }
  }
`;

export type FetchResult = {
  companyCaseList: CompanyCaseListTotalResult;
};

type UseCompanyCaseTotalProps = {
  initParams?: CompanyCaseListParams;
  skip?: boolean;
};

export const readProps = (props?: UseCompanyCaseTotalProps) => ({
  skip: props?.skip ?? false,
  initParams: props?.initParams ?? {}
});

const useCompanyCaseTotal = (props?: UseCompanyCaseTotalProps) => {
  const { initParams, skip } = readProps(props);
  const { data, error, loading, loaded } = useCallBackend<FetchResult>({
    query: companyCaseListTotalQuery,
    queryVariables: {
      requestData: {
        ...initParams
      }
    },
    fetchPolicy: 'cache-first',
    skip
  });

  return {
    error,
    total: data?.companyCaseList.total ?? 0,
    apiState: useApiStatus({
      loading,
      loadingMore: false,
      loaded,
      items: [],
      error,
      query: initParams
    })
  };
};

export default useCompanyCaseTotal;
