import {
  BillMillAccountFormStep,
  UseBillMillAccountStepsResult
} from 'B2XApp/Invoicing/BillingGroups/BillMillAccountForm/useBillMillAccountForm';
import { UpdateBillMillAccountApiStatus } from 'hooks/invoicing/billingGroups/useUpdateBillMillAccount/useUpdateBillMillAccount';
import { useEffect, useState } from 'react';

const useEditBillMillAccountSteps = ({
  updateBillMillAccountApiStatus
}: {
  updateBillMillAccountApiStatus: UpdateBillMillAccountApiStatus;
}): UseBillMillAccountStepsResult => {
  const [step, setStep] = useState<BillMillAccountFormStep>('initial');

  useEffect(() => {
    if (['persisting', 'success', 'failure'].includes(updateBillMillAccountApiStatus)) {
      setStep(updateBillMillAccountApiStatus);
    }
  }, [updateBillMillAccountApiStatus, setStep]);

  return {
    step,
    setStep
  };
};

export default useEditBillMillAccountSteps;
