import { BodyText, Gutter } from '@gaiads/telia-react-component-library';
import FinvoiceDetailsBlock from 'B2XApp/Invoicing/FinvoiceDetails/FinvoiceDetailsBlock';
import BarCode from 'B2XApp/Invoicing/Invoices/InvoiceDetails/BarCode/BarCode';
import DueDate from 'B2XApp/Invoicing/Invoices/InvoiceDetails/DueDate/DueDate';
import { DataPanel, DefinitionList, Heading, TeliaLink } from 'common-components';
import usePileVerticallyToMany from 'hooks/usePileVerticallyToMany/usePileVerticallyToMany';
import { useTranslation } from 'react-i18next';
import { InvoiceDetailsType } from 'types/invoice';

import InvoiceBillingInfoSkeleton from './InvoiceBillingInfoSkeleton';

const InvoiceBillingInfo: React.FC<{
  invoice?: InvoiceDetailsType;
  loading: boolean;
  hasError: boolean;
}> = ({ invoice, loading, hasError }) => {
  const { t } = useTranslation();
  const pileVerticallyTo = usePileVerticallyToMany(['xxxs', 'xxs', 'md', 'lg']);

  return (
    <DataPanel
      skeletonLoader={<InvoiceBillingInfoSkeleton />}
      loading={loading}
      hasError={hasError}
      data-testid="invoice-billing-info-data-panel"
    >
      {invoice && (
        <>
          <Heading.H4>{t('invoice.details.title')}</Heading.H4>

          <Gutter />

          <DefinitionList pileVerticallyTo={pileVerticallyTo} addDividers fiftyFifty>
            {!!invoice.billingPeriod && (
              <DefinitionList.Item heading={t('invoice.details.billingPeriod')}>
                <BodyText>
                  {invoice.billingPeriod.start} – {invoice.billingPeriod.end}
                </BodyText>
              </DefinitionList.Item>
            )}

            <DefinitionList.Item heading={t('invoice.details.invoiceDate')}>
              <BodyText>{invoice.invoiceDate}</BodyText>
            </DefinitionList.Item>

            <DefinitionList.Item heading={t('invoice.details.dueDate')}>
              <DueDate
                dueDate={invoice.dueDate}
                firstPayDate={invoice.firstPayDate}
                invoiceKey={invoice.key}
                paymentPlanActive={invoice.paymentPlanActive}
              />
            </DefinitionList.Item>

            <DefinitionList.Item heading={t('billingInformation.collectionGroup')}>
              {shouldLinkToBillingAccount(invoice.collectionGroup) ? (
                <TeliaLink.Internal
                  data-testid="link-to-billing-account"
                  variant="standalone"
                  to={`/billing-accounts/${invoice.collectionGroup}`}
                  label={invoice.collectionGroup}
                  enabledWhen={{
                    anyPermission: ['BILLING', 'BILLING_VIEW']
                  }}
                />
              ) : (
                <BodyText data-testid="invoice-billing-account">
                  {invoice?.collectionGroup}
                </BodyText>
              )}
            </DefinitionList.Item>

            <DefinitionList.Item heading={t('invoice.details.referenceNumber')}>
              <BodyText>{invoice.referenceNumber}</BodyText>
            </DefinitionList.Item>

            <DefinitionList.Item heading={t('invoice.details.barCode')}>
              <BarCode
                data-testid="invoice-billing-info-barcode"
                barCode={invoice.status !== 'closed' ? invoice.barCode : undefined}
              />
            </DefinitionList.Item>
          </DefinitionList>

          <Gutter size="lg" />

          <Heading.H4>{t('invoice.details.finvoice.title')}</Heading.H4>

          <Gutter size="sm" />

          <FinvoiceDetailsBlock
            data-testid="invoice-billing-info-finvoice-details-block"
            finvoice={invoice.finvoice}
          />
        </>
      )}
    </DataPanel>
  );
};

export default InvoiceBillingInfo;

const COLLECTION_GROUP_LENGTH = 4;
const BILLMILL_ACCOUNT_LENGTH = 7;

export const shouldLinkToBillingAccount = (collectionGroup: string) => {
  return (
    collectionGroup.length === COLLECTION_GROUP_LENGTH ||
    collectionGroup.length === BILLMILL_ACCOUNT_LENGTH
  );
};
