import { isWeekend } from 'date-fns';
import isHoliday from 'doings/isHoliday/isHoliday';
import validators from 'hooks/inputModels/validation/validators';
import { TFunction } from 'i18next';
import * as Yup from 'yup';

export type GenerateTokenFormValues = {
  additionalNumber1?: string;
  additionalNumber2?: string;
  subscriptionTerminationDate: string;
};

export type FieldLengthLimits = {
  min: number;
  max: number;
};

const createLengthBoundedSchema = (t: TFunction, limits: FieldLengthLimits): Yup.StringSchema => {
  return Yup.string()
    .min(limits.min, t('validators.valueIsTooShort', { min: limits.min }))
    .max(limits.max, t('validators.valueIsTooLong', { max: limits.max }));
};

const truncateDate = (date: Date): Date => {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

export const getOwnershipChangeFormValidationSchema = ({
  t,
  minTerminationDate,
  maxTerminationDate
}: {
  t: TFunction;
  minTerminationDate: Date;
  maxTerminationDate: Date;
}) => {
  const contactPhoneLimits: FieldLengthLimits = { min: 2, max: 25 };

  return Yup.object().shape({
    subscriptionTerminationDate: Yup.date()
      .required(t('validators.valueIsRequired'))
      .typeError(t('subscriptions.generateToken.invalidTerminationDate'))
      .min(truncateDate(minTerminationDate), t('validators.dateIsTooEarly'))
      .max(truncateDate(maxTerminationDate), t('validators.dateIsTooLate'))
      .test(
        'business day test',
        t('validators.dateIsNotBusinessDay'),
        (value?) => !value || !(isWeekend(value) || isHoliday(value))
      ),
    additionalNumber1: createLengthBoundedSchema(t, contactPhoneLimits)
      .test(
        'phone number test',
        t('validators.invalidMsisdn'),
        (value?) => !value || validators.msisdn.validate(value)
      )
      .optional(),
    additionalNumber2: createLengthBoundedSchema(t, contactPhoneLimits)
      .test(
        'phone number test',
        t('validators.invalidMsisdn'),
        (value?) => !value || validators.msisdn.validate(value)
      )
      .optional()
  });
};
