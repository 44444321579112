import { TeliaLink } from 'common-components';
import getEnvironmentVariable from 'doings/getEnvironmentVariable/getEnvironmentVariable';
import { devices } from 'doings/track/analyticsEvents';
import { useTranslation } from 'react-i18next';
import joinUrl from 'url-join';

const OrderNewDaasDeviceLink: React.FC = () => {
  const { t } = useTranslation();
  return (
    <TeliaLink.External
      data-testid="order-new-device"
      variant="standalone"
      to={joinUrl([getEnvironmentVariable('REACT_APP_TELIA_ECOM_URL'), 'yrityksille/kauppa'])}
      label={t('daas.links.newDevice')}
      iconRight="external"
      track={devices.orderNewDevice}
    />
  );
};

export default OrderNewDaasDeviceLink;
