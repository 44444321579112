import { Page } from '@gaiads/telia-react-component-library';
import { ListView, Memo, PageWithSubnavigation } from 'common-components';
import ListHeader from 'common-components/ListHeader/ListHeader';
import ShowMoreResults from 'common-components/ShowMoreResults/ShowMoreResults';
import { useProductNewsStats } from 'hooks/productNews/useProductNewsStats';
import { useProductNewsStatsFeedback } from 'hooks/productNews/useProductNewsStatsFeedback';
import { useProductNewsStatsFeedbackFilters } from 'hooks/productNews/useProductNewsStatsFeedbackFilters';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { NewsRatingsListView } from './NewsRatingsListView';
import { NewsRatingsSortDropdown } from './NewsRatingsSortDropdown';
import { NewsRatingsStatsPanel } from './NewsRatingsStatsPanel';

const NewsRatingsDetails: React.FC = () => {
  const { newsId } = useParams<{ newsId: string }>();
  const { t } = useTranslation();
  const { data: newsItems } = useProductNewsStats();
  const newsItem = newsItems?.find((item) => item.newsId === newsId);

  const { data, loading, error } = useProductNewsStatsFeedback({ newsId });
  const { feedbackCount, feedback, sortState, sortClick, doSort, currentSort, showMore } =
    useProductNewsStatsFeedbackFilters({ data, loading, error });

  return (
    <PageWithSubnavigation withoutCompanySelector>
      {newsItem && (
        <Page.Row>
          <ListView listData={[newsItem]}>
            {(newsItem) => (
              <ListView.Row hideArrow>
                <ListView.Cell cellSize="1x">
                  <NewsRatingsStatsPanel item={newsItem} />
                </ListView.Cell>
              </ListView.Row>
            )}
          </ListView>
        </Page.Row>
      )}

      <ListHeader
        dateRangeParams={{
          label: t('productNewsRatings.list.lastModified'),
          'data-testid': 'product-news-last-modified-filter'
        }}
        customFilterParams={[
          {
            param: 'vote',
            label: t('productNewsRatings.list.vote'),
            options: [
              {
                value: 'absent',
                label: t('productNewsRatings.votes.absent')
              },
              {
                value: 'upvote',
                label: t('productNewsRatings.votes.upvoted')
              },
              {
                value: 'downvote',
                label: t('productNewsRatings.votes.downvoted')
              }
            ],
            'data-testid': 'rating-filter-dropdown'
          }
        ]}
        labels={{
          searchInMobileView: t('productNewsRatings.list.searchFeedback'),
          sortInMobileView: t('productNewsRatings.list.sortFeedback')
        }}
        sortDropdown={
          <NewsRatingsSortDropdown
            data-testid="product-news-ratings-sort-dropdown"
            currentSort={currentSort}
            doSort={doSort}
          />
        }
      >
        {t('productNewsRatings.title', { numberOfRatings: feedbackCount })}
      </ListHeader>

      <Page.Row responsiveFullWidth>
        <Memo comparables={['loading', 'feedback', 'sortState']}>
          <NewsRatingsListView
            feedback={feedback}
            loading={loading}
            sortState={sortState}
            sortClick={sortClick}
          />
        </Memo>
      </Page.Row>

      {!loading && (
        <Page.Row>
          <ShowMoreResults
            data-testid="show-more-results"
            loading={false}
            hasMoreResults={feedback.length < feedbackCount}
            hasResults={feedbackCount > 0}
            fetchMore={showMore}
          />
        </Page.Row>
      )}
    </PageWithSubnavigation>
  );
};

export { NewsRatingsDetails };
