import { SearchDefinition, SearchOptionDefinition } from 'types/listFilters';

export const getSearchOptions = (
  searchScopeOptions?: SearchOptionDefinition[],
  searchActivationThreshold?: number | false
): SearchDefinition => {
  const scopedSearchOptions = searchScopeOptions && {
    scopeParam: 'scope',
    scopeOptions: searchScopeOptions,
    hasNoneOption: true,
    queryActivationThresholdLength: searchActivationThreshold || undefined
  };

  return {
    ...scopedSearchOptions,
    param: 'search'
  };
};
