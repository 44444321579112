import { useUserData } from 'contexts/UserContext/UserContext';
import userCompanyHasPermission from 'doings/userCompanyHasPermission/userCompanyHasPermission';
import { useMemo } from 'react';
import { AuthorizationParams } from 'types/authorization';

export default (params: Omit<AuthorizationParams, 'activeGroup'>) => {
  const { allCompanies } = useUserData();

  return useMemo(
    () => allCompanies.filter((c) => userCompanyHasPermission(c, params)).length > 1,
    [allCompanies, params]
  );
};
