import { Div, Flex, Gutter } from '@gaiads/telia-react-component-library';
import { Paragraph } from '@teliafi/fi-ds';
import { Message, TicketDetailsMessageSkeleton } from 'B2XApp/Messages/common';
import classNames from 'classnames';
import { DataPanel, Heading, Optional, TeliaLink, TextWithInlineHtml } from 'common-components';
import { parseISO } from 'date-fns';
import { formatDatetime, isIsoDateFormat } from 'doings/formatDatetime';
import { useTranslation } from 'react-i18next';

import agentIconSrc from './agentIcon.svg';
import { formatMessageDate } from './formatMessageDate';
import styles from './TicketDetailsMessage.module.scss';

const TicketDetailsMessage: React.FC<{
  loading: boolean;
  index: number;
  message: Message;
  showChatView: boolean;
}> = ({ message, loading, index, showChatView }) => {
  const { t } = useTranslation();
  const isAgent = message.source === 'Telia';
  const isResolution = message.isResolutionMessage;
  const { date, attachments, header, description } = message;

  return (
    <Div
      className={classNames(styles.card_container, {
        [styles.card_container_chat]: showChatView,
        [styles.card_customer_container]: showChatView && !isAgent
      })}
    >
      <Optional if={!isResolution}>
        <Flex alignRight={showChatView && !isAgent}>
          <Flex alignTop>
            <Optional if={showChatView && isAgent}>
              <img
                src={agentIconSrc}
                alt="Telia icon"
                aria-hidden="true"
                className={styles.agentIcon}
                data-testid={`ticket-details-agent-icon-${index}`}
              />
            </Optional>

            <Paragraph className={styles.meta} data-testid={`ticket-details-metadata-${index}`}>
              <Optional if={showChatView && !!message?.authorFirstName}>
                {message.authorFirstName}

                <Optional if={!isAgent}> {message.authorLastName}</Optional>

                <Optional if={isAgent}>, {t('tickets.details.fromTelia')}</Optional>
              </Optional>{' '}
              <Optional if={!!date}>
                {formatMessageDate(
                  isIsoDateFormat(date)
                    ? parseISO(date)
                    : new Date(formatDatetime(date, 'yyyy-MM-dd HH:mm'))
                )}
              </Optional>
            </Paragraph>
          </Flex>
        </Flex>
      </Optional>

      <DataPanel
        skeletonLoader={<TicketDetailsMessageSkeleton />}
        shadow="zero"
        loading={loading}
        className={classNames(styles.card, {
          [styles.card_customer]: showChatView && !isAgent,
          [styles.card_agent]: showChatView && isAgent
        })}
      >
        <Flex
          directionColumn
          forceWrap
          data-testid={`reply-with-message-${index}`}
          className={styles.message_container}
        >
          <Optional if={!!header}>
            <Heading.H3>{header}</Heading.H3>

            <Gutter size="sm" />
          </Optional>

          <TextWithInlineHtml
            className={styles.message_richText}
            text={description || t('tickets.details.noMessage')}
          />
        </Flex>

        <Optional data={attachments}>
          <Div margin={{ top: 'sm' }}>
            {attachments?.map((attachment) => (
              <TeliaLink.Download
                key={`attachment-${attachment.url}`}
                label={attachment.name}
                to={attachment.url}
                iconLeft="attachment"
                downloadWithFetch
              />
            ))}
          </Div>
        </Optional>
      </DataPanel>
    </Div>
  );
};

export default TicketDetailsMessage;
