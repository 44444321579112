import { TeliaLink } from 'common-components';
import isDemoEnvironment from 'doings/isDemoEnvironment/isDemoEnvironment';
import { joinURLSearchParams } from 'doings/links/joinURLSearchParams/joinURLSearchParams';
import removeEmptyFields from 'doings/removeEmptyFields/removeEmptyFields';
import { ThreeSixtyProductInventoryQueryParameters } from 'hooks/useThreeSixtyInventory/useThreeSixtyInventory';
import { useTranslation } from 'react-i18next';
import { SortParameter } from 'types/sort';

export type InternetConnectionExcelDownloadLinkProps = {
  queryParameters: ThreeSixtyProductInventoryQueryParameters;
  sortParameter: SortParameter;
  showFromAllCompanies: boolean;
  disabled: boolean;
};

export const ExcelDownloadLink: React.FC<InternetConnectionExcelDownloadLinkProps> = ({
  queryParameters,
  sortParameter,
  showFromAllCompanies,
  disabled
}) => {
  const { t } = useTranslation();
  const isDemoEnv = isDemoEnvironment();
  const { sort, order } = sortParameter;
  const urlSearchParams = new URLSearchParams(removeEmptyFields(queryParameters));

  const queryParams = joinURLSearchParams('?', {
    // TODO: Add translations
    columns: ['Tuote', 'Kategoria', 'Sopimus', 'Tunniste', 'Yritys'].join(','),
    filter: urlSearchParams.toString(),
    sort,
    order,
    showFromAllCompanies,
    // TODO: Add translations
    sheetTitle: 'DCC tuotteet'
  });

  return (
    <TeliaLink.Download
      label={t('common.excelDownload.label')}
      to={`/api/threeSixtyProductInventory/listAsExcel${queryParams}`}
      iconLeft="download"
      data-testid="three-sixty-product-inventory-list-as-excel-link"
      disabled={isDemoEnv || disabled}
      downloadWithFetch
      useFileNameFromResponse
    />
  );
};
