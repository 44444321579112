import { IndicatorStatusDefinition, StatusTag } from 'common-components';
import { SubscriptionStatus } from 'hooks/mobileSubscriptions/useSubscriptionDetails/subscriptionDetailsQuery';

export type DisplayableSubscriptionStatus = SubscriptionStatus | 'IN_TRANSFER' | 'FIXED_TERM';

export const subscriptionStatusMap: {
  [key in DisplayableSubscriptionStatus]: IndicatorStatusDefinition;
} = {
  PREOPENED: {
    i18nKey: 'subscriptions.list.status.PREOPENED',
    variant: 'information'
  },
  CLOSED: {
    i18nKey: 'subscriptions.list.status.CLOSED',
    variant: 'warning'
  },
  SUSPENDED: {
    i18nKey: 'subscriptions.list.status.SUSPENDED',
    variant: 'error'
  },
  ENABLED: {
    i18nKey: 'subscriptions.list.status.ENABLED',
    variant: 'success'
  },
  BAD_CREDIT: {
    i18nKey: 'subscriptions.list.status.BAD_CREDIT',
    variant: 'warning'
  },
  DISABLED: {
    i18nKey: 'subscriptions.list.status.DISABLED',
    variant: 'error'
  },
  IN_TRANSFER: {
    i18nKey: 'subscriptions.list.status.IN_TRANSFER',
    variant: 'information'
  },
  FIXED_TERM: {
    i18nKey: 'subscriptions.list.status.FIXED_TERM',
    variant: 'information'
  }
};

const SubscriptionStatusTag: React.FC<{ status: DisplayableSubscriptionStatus }> = ({ status }) => (
  <StatusTag status={subscriptionStatusMap[status]} />
);

export default SubscriptionStatusTag;
