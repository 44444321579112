import { OptionListOption } from '@gaiads/telia-react-component-library';

import { Validatable, WithValidator } from './formValidation';

type BaseField = {
  label: string;
  type: Fields;
  name: string;
  required: boolean;
  disabled: boolean;
};

export type TextField = {
  type: Fields.TEXT;
  value: string;
} & BaseField &
  WithValidator;

export type NumberField = {
  type: Fields.NUMBER;
  value: string;
} & BaseField &
  WithValidator;

export type DropdownField = {
  type: Fields.DROPDOWN;
  value: string;
  options: OptionListOption[];
} & BaseField &
  Validatable;

export type MultiselectField = {
  type: Fields.MULTISELECT;
  values: string[];
  options: OptionListOption[];
} & BaseField &
  Validatable;

export type Field = TextField | NumberField | DropdownField;

export type FieldIndex = {
  [name: string]: Field;
};

export type Fieldset = {
  title: string;
  fields: string[];
};

export enum Fields {
  DROPDOWN = 'DROPDOWN',
  MULTISELECT = 'MULTISELECT',
  TEXT = 'TEXT',
  NUMBER = 'NUMBER'
}

export type FormLayout = Fieldset[];

export type Form = {
  layout: FormLayout;
  fields: FieldIndex;
};

export enum Events {
  BLUR = 'BLUR',
  CHANGE = 'CHANGE'
}

export type Event = {
  event: Events;
  type: Fields;
  name: string;
  value: string;
};

export const TEXT_FIELD_MAX_LENGTH = 1000;
export const TEXT_AREA_MAX_LENGTH = 8000;
export const POSTAL_CODE_LENGTH = 5;

export const TEXT_MAX_LENGTH = 32000;
export const SEARCH_TEXT_MAX_LENGTH = 100;
export const EXTENDED_SEARCH_TEXT_MAX_LENGTH = 255;
export const PHONE_MAX_LENGTH = 25;
export const EMAIL_MAX_LENGTH = 50;

export type FormChangeEventHandler = (event: Event) => void;
