import { gql } from '@apollo/client';
import { FormattedExecutionResult } from 'graphql';
import useCallBackendMutation from 'hooks/useCallBackend/useCallBackendMutation';

export const sendServiceRequestMessageMutation = gql`
  mutation ReplyToServiceRequest($id: ID!, $text: String!, $attachments: [Upload]) {
    sendServiceRequestMessage(id: $id, text: $text, attachments: $attachments) {
      code
    }
  }
`;

export type SendServiceRequestMessageParameters = {
  id: string;
  text: string;
  attachments?: File[];
};

type SendServiceRequestMessageResponse = {
  id: string;
};

type SendServiceRequestMessage = (
  variables: SendServiceRequestMessageParameters
) => Promise<FormattedExecutionResult<SendServiceRequestMessageResponse>>;

export default () => {
  const { runMutation, data, loading, error } =
    useCallBackendMutation<SendServiceRequestMessageResponse>(sendServiceRequestMessageMutation);

  const sendServiceRequestMessage: SendServiceRequestMessage = (
    variables: SendServiceRequestMessageParameters
  ) => runMutation({ variables });

  return {
    sendServiceRequestMessage,
    data,
    loading,
    replySent: !!data && !loading && !error,
    hasError: !!error
  };
};
