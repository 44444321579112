import wrapEachComponentWithKey from 'doings/wrapEachComponentWithKey/wrapEachComponentWithKey';
import React, { ReactElement } from 'react';

const join = (elements: ReactElement[], divider: ReactElement): ReactElement[] =>
  wrapEachComponentWithKey(
    elements.reduce(
      (list, element, index) =>
        index === 0 ? [element] : [...list, React.cloneElement(divider), element],
      [] as ReactElement[]
    )
  );

export default join;
