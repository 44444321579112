import { ApolloError } from '@apollo/client';
import ErrorPageContent from 'B2XApp/Errors/Error/ErrorPage/ErrorPageContent';
import getEventAction from 'B2XApp/Errors/Error/getEventAction';
import isPermissionError from 'doings/isPermissionError/isPermissionError';
import { trackCustomEvent } from 'doings/track/trackEvent';
import { useEffect, useState } from 'react';

type DefaultErrorType = 'userContextFault' | 'generic' | 'replayOutdated' | 'replayFault';

const ErrorPage: React.FC<{
  error?: ApolloError;
  defaultType?: DefaultErrorType;
  hideBreadcrumb?: boolean;
}> = ({ error, defaultType = 'generic', hideBreadcrumb = false }) => {
  const [currentLocation] = useState(window.location.href);

  const isForbidden = isPermissionError(error);
  const action = `${isForbidden ? 'forbidden' : 'somethingWentWrong'}:${
    typeof error === 'undefined' ? 'no-error' : getEventAction(error)
  }`;

  useEffect(() => {
    trackCustomEvent('Error', `errorPage:${action}`);
  }, [action]);

  return (
    <ErrorPageContent
      currentLocation={currentLocation}
      variant={isForbidden ? 'forbidden' : defaultType}
      hideBreadcrumb={hideBreadcrumb}
    />
  );
};

export default ErrorPage;
