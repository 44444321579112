import useSubscriptionDetails from 'hooks/mobileSubscriptions/useSubscriptionDetails/useSubscriptionDetails';
import { useParams } from 'react-router';

import SubscriptionDetailsPage from './SubscriptionDetailsPage';

interface SubscriptionDetailsParams {
  phoneNumberHash: string;
}

const SubscriptionDetails: React.FC = () => {
  const { phoneNumberHash } = useParams<SubscriptionDetailsParams>();
  const { subscription, ownershipChange, balance, status } = useSubscriptionDetails(
    phoneNumberHash,
    { fetchBalance: true, fetchOmsu: true }
  );

  return (
    <SubscriptionDetailsPage
      subscription={subscription}
      ownershipChange={ownershipChange}
      balance={balance}
      loadingStatus={status}
      data-testid="subscription-details-page"
    />
  );
};

export default SubscriptionDetails;
