import makeArrayOf from 'doings/makeArrayOf/makeArrayOf';

const Empty: React.FC<{ children: React.ReactNode }> = ({ children }) => children;

export default (toBeWrappedComponents: React.ReactNode[], givenKeys: string[] = []) => {
  const keys =
    givenKeys.length === toBeWrappedComponents.length
      ? givenKeys
      : makeArrayOf((_value, index) => `${index}`, toBeWrappedComponents.length);

  return toBeWrappedComponents.map((component: React.ReactNode, index: number) => (
    <Empty key={keys[index]}>{component}</Empty>
  ));
};
