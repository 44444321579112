import { DynamicForms } from 'types/dynamicForms';

export const check = {
  isCheckbox: function (field: DynamicForms.Field): field is DynamicForms.CheckboxField {
    return field.type === 'CHECKBOX';
  },

  isCheckgroup: function (field: DynamicForms.Field): field is DynamicForms.CheckgroupField {
    return field.type === 'CHECKGROUP';
  },

  isDate: function (field: DynamicForms.Field): field is DynamicForms.DateField {
    return field.type === 'DATE';
  },

  isDropdown: function (field: DynamicForms.Field): field is DynamicForms.DropdownField {
    return field.type === 'DROPDOWN';
  },

  isInput: function (field: DynamicForms.Field): field is DynamicForms.InputField {
    return field.type === 'INPUT';
  },

  isInfotext: function (field: DynamicForms.Field): field is DynamicForms.InfotextField {
    return field.type === 'INFOTEXT';
  },

  isSink: function (field: DynamicForms.Field): field is DynamicForms.SinkField {
    return field.type === 'SINK';
  },

  isSubheader: function (field: DynamicForms.Field): field is DynamicForms.SubheaderField {
    return field.type === 'SUBHEADER';
  },

  isTextarea: function (field: DynamicForms.Field): field is DynamicForms.TextareaField {
    return field.type === 'TEXTAREA';
  },

  isValidatable: function (
    field: DynamicForms.Field
  ): field is
    | DynamicForms.CheckboxField
    | DynamicForms.CheckgroupField
    | DynamicForms.DateField
    | DynamicForms.DropdownField
    | DynamicForms.InputField
    | DynamicForms.TextareaField {
    return !['INFOTEXT', 'SINK', 'SUBHEADER'].includes(field.type);
  },

  isVisible: function (field: DynamicForms.Field) {
    return field.type !== 'SINK';
  }
};
