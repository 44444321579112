import useReadQueryParams from 'hooks/useQueryParams/useReadQueryParams';
import useScrollElementIntoView from 'hooks/useScrollElementIntoView/useScrollElementIntoView';
import { useLocation } from 'react-router';

export default <T extends HTMLElement>({
  elementRef,
  anchorName,
  shouldScroll,
  showHighlight
}: {
  elementRef: React.RefObject<T>;
  anchorName?: string;
  shouldScroll?: boolean;
  showHighlight?: boolean;
}) => {
  const { anchor } = useReadQueryParams(['anchor']);
  const { hash: fragment } = useLocation();

  const anchorFromFragment: boolean = !!fragment && fragment.substring(1) === anchorName;
  const hasAnchor: boolean = !!anchor && !!anchorName && anchor === anchorName;

  useScrollElementIntoView({
    elementRef,
    shouldScroll: shouldScroll && (hasAnchor || anchorFromFragment),
    queryParamsToClear: ['anchor'],
    showHighlight
  });
};
