import useCallBackend from 'hooks/useCallBackend/useCallBackend';

import { isSubscriptionInCompanyQuery } from './isSubscriptionInCompanyQuery';

type SubscriptionInCompanyResponse = {
  isSubscriptionInCompany: boolean;
};

export type SubscriptionInCompanyQueryParams = {
  subscriptionId?: string;
  companyCode?: string;
  skip?: boolean;
};

const useIsSubscriptionInCompany = (params: SubscriptionInCompanyQueryParams) => {
  const { data, loading } = useCallBackend<SubscriptionInCompanyResponse>({
    query: isSubscriptionInCompanyQuery,
    queryVariables: {
      subscriptionId: params.subscriptionId,
      companyCode: params.companyCode
    },
    skip: !params.subscriptionId || !params.companyCode || !!params.skip
  });

  return {
    subscriptionInCompany: data?.isSubscriptionInCompany,
    loading
  };
};

export default useIsSubscriptionInCompany;
