import { Div, GridBag, Gutter } from '@gaiads/telia-react-component-library';
import { Heading } from 'common-components';
import { useTranslation } from 'react-i18next';
import { DynamicForms } from 'types/dynamicForms';

import DynamicFormSelectorButton from './DynamicFormSelectorButton';

/**
 * The name of the selector container's DOM element for highlighting an absent
 * selector selection.
 *
 * A selector selection is mandatory for a dynamic form. For pages where the
 * selection needs to be made by the user explicitly like for new service
 * requests, the selector container needs to be invalidated to scroll the user's
 * view and attention to it.
 */
export const DYNFORM_SELECTOR_CONTAINER_NAME = 'dynamic-form-case-type';

const DynamicFormSelector: React.FC<{
  formDescriptors: DynamicForms.DynamicFormDescriptor[];
  selectedValue?: string;
  disableAlternatives?: boolean;
  onChange: (value: string) => void;
}> = ({ formDescriptors, selectedValue, disableAlternatives = false, onChange }) => {
  const { t } = useTranslation();

  return (
    <Div data-testid="dynamic-form-selector">
      <Heading.H4>{t('dynamicForm.selectCaseType')}</Heading.H4>

      <Gutter size="sm" />

      <div data-name={DYNFORM_SELECTOR_CONTAINER_NAME}>
        <GridBag>
          {formDescriptors.map((descriptor) => (
            <GridBag.Item key={descriptor.name} xlg={4} lg={6} md={4} sm={6} xs={12}>
              <DynamicFormSelectorButton
                descriptor={descriptor}
                selected={selectedValue === descriptor.name}
                disabled={disableAlternatives && selectedValue !== descriptor.name}
                onClick={onChange}
              />
            </GridBag.Item>
          ))}
        </GridBag>
      </div>
    </Div>
  );
};

export default DynamicFormSelector;
