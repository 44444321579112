import { Gutter } from '@gaiads/telia-react-component-library';
import { Price } from 'common-components/Price/Price';
import { calculateContractProgress } from 'doings/calculateContractProgress/calculateContractProgress';
import formatDatetime from 'doings/formatDatetime/formatDatetime';
import { useTranslation } from 'react-i18next';
import { Device } from 'types/device';

import ContractDates from './ContractDates/ContractDates';
import ContractProgress from './ContractProgress/ContractProgress';

const ContractPeriod: React.FC<{
  device: Device;
}> = ({ device }) => {
  const { t } = useTranslation();
  const { contractLength, contractProgress } = calculateContractProgress(device);

  const formattedBillingBeginDate = formatDatetime(device?.billingBeginDate);
  const formattedBillingEndDate = formatDatetime(device?.billingEndDate);

  return (
    <>
      {contractLength ? (
        <ContractProgress
          contractLength={contractLength}
          contractProgress={contractProgress}
          billingBeginDate={formattedBillingBeginDate}
          billingEndDate={formattedBillingEndDate}
        />
      ) : (
        <ContractDates
          billingBeginDate={formattedBillingBeginDate}
          billingEndDate={formattedBillingEndDate}
        />
      )}

      <Gutter />

      <Price
        label={t('daas.details.paymentMonthly')}
        price={device.pricePerMonthWithoutVat}
        currency={device.currency}
        data-testid="device-contract-period-price"
      />
    </>
  );
};

export default ContractPeriod;
