import routeAccessGroups from 'B2XApp/appRouteAccessGroups';
import { TeliaLink } from 'common-components';
import createCorporatePortalLink from 'doings/links/createCorporatePortalLink/createCorporatePortalLink';
import { assets } from 'doings/track/analyticsEvents';
import { useTranslation } from 'react-i18next';

export function OldPortalSubscriptionSearchLink() {
  const { t } = useTranslation();
  return (
    <TeliaLink.External
      data-testid="service-list-notification-link"
      variant="text"
      to={createCorporatePortalLink('search/subscriptionSearch')}
      label={t('internetConnections.list.servicesNotice.oldProductManagementLink')}
      track={assets.oldPortalSubscriptionSearchLink}
      enabledWhen={routeAccessGroups.OTHER_PRODUCTS}
    />
  );
}
