import { Div, Flex, Gutter } from '@gaiads/telia-react-component-library';
import { Icon } from '@teliafi/fi-ds';
import classNames from 'classnames';
import { Heading, TeliaLink } from 'common-components';
import { useUserData } from 'contexts/UserContext/UserContext';
import isDemoEnvironment from 'doings/isDemoEnvironment/isDemoEnvironment';
import { multiplex } from 'doings/multiplex/multiplex';
import { AnalyticsEvent, dashboardSearch, search } from 'doings/track/analyticsEvents';
import useEcomMobileFlow from 'hooks/useEcomMobileFlow/useEcomMobileFlow';
import { useTranslation } from 'react-i18next';
import { Article } from 'types/articles';

import styles from './ContentSearch.module.scss';

const ContentSearchResultItem: React.FC<{
  forDashboard?: boolean;
  category: string;
  articles: Article[];
  keyword: string;
  padBottom?: boolean;
}> = ({ forDashboard = false, category, articles, keyword, padBottom = false }) => {
  return (
    articles && (
      <div
        data-testid="content-search-category"
        lang={articles[0].articleType === 'article' ? 'fi' : undefined}
      >
        <CategoryTitle category={category} />

        <CategorizedArticleItems
          forDashboard={forDashboard}
          articles={articles}
          keyword={keyword}
        />

        {padBottom && <Gutter size="xs" />}
      </div>
    )
  );
};

const CategoryTitle: React.FC<{ category: string }> = ({ category }) => (
  <>{category && <Heading.H5>{category}</Heading.H5>}</>
);

const CategorizedArticleItems: React.FC<{
  articles: Article[];
  keyword: string;
  forDashboard?: boolean;
}> = ({ articles, keyword, forDashboard = false }) => (
  <>
    {articles.map((hit) => (
      <ArticleItem key={hit.id} article={hit} keyword={keyword} forDashboard={forDashboard} />
    ))}
  </>
);

const ArticleItem: React.FC<{ article: Article; keyword: string; forDashboard?: boolean }> = ({
  article,
  keyword,
  forDashboard = false
}) => {
  const user = useUserData();
  const isDisabled = isDemoEnvironment() && article.articleType === 'b2b-page';
  const shouldOpenEcommMobileFlow = user.isFirstTimePurchaser && article.url === '/createEmployee';
  const { showEcomMobileFlowModal } = useEcomMobileFlow();

  const eventBase = forDashboard ? dashboardSearch : search;
  const track = {
    ...(article.articleType === 'article'
      ? eventBase.supportArticleClicked
      : eventBase.contentPageClicked),
    resultTitle: article.title,
    searchKeyword: keyword
  } as AnalyticsEvent;

  return (
    <Div
      className={classNames(styles.articleSearchResultItem, {
        [styles.articleSearchResultItem__disabled]: isDisabled
      })}
      data-testid={`content-search-result-item-article-item-${article.title}`}
    >
      <Flex>
        <Icon className={styles.chevron} name="chevron-right" size="xs" />

        <Div flexItem occupyHorizontalSpace>
          <ArticleLink
            article={article}
            onClick={shouldOpenEcommMobileFlow ? showEcomMobileFlowModal : undefined}
            disabled={isDisabled}
            track={track}
          />
        </Div>
      </Flex>
    </Div>
  );
};

const ArticleLink: React.FC<{
  article: Article;
  onClick?: VoidFunction;
  disabled?: boolean;
  track?: AnalyticsEvent;
}> = ({ article, onClick, disabled, track }) => {
  const { t } = useTranslation();
  const articleTitle = article.title.split('|')[0].trim();
  const articleAuxiliaryLabel = multiplex([
    '',
    [!!article.displayCategory, article.displayCategory],
    [!article.fuzzy, t('topNavigation.articleSearch.matchAccurate')],
    [!!article.fuzzy, t('topNavigation.articleSearch.matchFuzzy')]
  ]);

  const testIdSuffix = articleTitle
    .trim()
    .toLowerCase()
    .replace(/["'<>]/g, '')
    .replace(/\s+/g, '_');

  const linkProps = {
    variant: 'standalone-content-search',
    label: articleTitle,
    labelAuxiliary: articleAuxiliaryLabel,
    disabled: disabled,
    track: track,
    'data-testid': `content-search-result-${testIdSuffix}`
  } as const;

  if (onClick) {
    return (
      <TeliaLink.Click
        {...linkProps}
        onClick={onClick}
        data-testid="content-search-result-item-click-link"
      />
    );
  }

  if (['article', 'external-page'].includes(article.articleType)) {
    return <TeliaLink.External {...linkProps} iconRight="external" to={article.url} openInNewTab />;
  }

  return (
    <TeliaLink.Portal
      {...linkProps}
      to={article.url}
      toCorporatePortal={article.url}
      useRouterLink={article.articleType === 'b2x-page'}
    />
  );
};

export default ContentSearchResultItem;
