import { Div, Flex } from '@gaiads/telia-react-component-library';
import styles from 'B2XApp/TopNavigation/Navigation/ContentSearch/ContentSearch.module.scss';
import ContentSearchField from 'B2XApp/TopNavigation/Navigation/ContentSearch/ContentSearchField';
import getClassNames from 'classnames';
import { IconButton } from 'common-components';
import { useContentSearchState } from 'contexts/SearchContext/ContentSearchContext';
import { useSubmenuState } from 'contexts/SubmenuContext/SubmenuContext';
import { search } from 'doings/track/analyticsEvents';
import trackEvent from 'doings/track/trackEvent';
import { useTranslation } from 'react-i18next';

const ContentSearch: React.FC = () => {
  const { t } = useTranslation();
  const { openMenuId, setOpenMenuId } = useSubmenuState();
  const { setSearchTerm, searchFieldRef } = useContentSearchState();
  const isSearchFieldOpen = openMenuId === 'content-search';
  const toggleSearch = (shouldToggle: boolean) => {
    if (!shouldToggle) {
      return;
    }

    if (!isSearchFieldOpen) {
      trackEvent(search.searchOpened);
    }

    setSearchTerm('');
    setOpenMenuId(isSearchFieldOpen ? '' : 'content-search');
  };

  return (
    <Div
      flexItem
      refElement={searchFieldRef}
      className={getClassNames([styles.articleSearchBar, isSearchFieldOpen ? styles.expanded : ''])}
    >
      <Flex centeredVertically alignRight>
        <IconButton
          data-testid="content-search-icon"
          data-tour-stop="header-content-search"
          aria-label={t('aria.header.searchToggle')}
          aria-expanded={isSearchFieldOpen}
          className={[styles.articleSearchIcon]}
          name="search"
          size="md"
          onClick={() => toggleSearch(!isSearchFieldOpen)}
          focusable={!isSearchFieldOpen}
        />

        {isSearchFieldOpen && (
          <>
            <ContentSearchField />

            <IconButton
              data-testid="content-search-close-icon"
              aria-label={t('common.closeButton.label')}
              className={[styles.articleSearchCloseButton]}
              name="close"
              size="md"
              onClick={() => {
                toggleSearch(isSearchFieldOpen);
              }}
              focusable={false}
            />
          </>
        )}
      </Flex>
    </Div>
  );
};

export default ContentSearch;
