import { Page } from '@gaiads/telia-react-component-library';
import { Heading, PageWithSubnavigation } from 'common-components';
import * as analyticsEvents from 'doings/track/analyticsEvents';
import { AnalyticsEvent } from 'doings/track/analyticsEvents';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AnalyticsEventsListView from './AnalyticsEventsListView';

export type DefinedEvent = Exclude<keyof typeof analyticsEvents, 'createAnalyticsEvent'>;

export type ListEvent = {
  key: DefinedEvent;
  label: string;
  events: AnalyticsEvent[];
};

const AnalyticsEvents: React.FC = () => {
  const { t } = useTranslation();
  const { createAnalyticsEvent, ...definedEvents } = analyticsEvents;

  const [expandedEvent, setExpandedEvent] = useState<'' | DefinedEvent>('');
  const onToggleExpandedEvent = useCallback(
    (eventKey: DefinedEvent) => {
      const visibleEvent = expandedEvent === eventKey ? '' : eventKey;
      setExpandedEvent(visibleEvent);
    },
    [setExpandedEvent, expandedEvent]
  );

  const listEvents = useMemo(
    () =>
      Object.keys(definedEvents).reduce((acc, event) => {
        const events = Object.values(definedEvents[event as DefinedEvent]);
        acc.push({
          key: event as DefinedEvent,
          label: events[0].eventLabel,
          events
        });
        return acc;
      }, [] as ListEvent[]),
    [definedEvents]
  );

  return (
    <PageWithSubnavigation withoutCompanySelector>
      <Page.Row>
        <Heading.H2>{t('analyticsEvents.title')}</Heading.H2>
      </Page.Row>

      <Page.Row responsiveFullWidth>
        <AnalyticsEventsListView
          events={listEvents}
          onToggleExpandedEvent={onToggleExpandedEvent}
          expandedEvent={expandedEvent}
        />
      </Page.Row>
    </PageWithSubnavigation>
  );
};

export default AnalyticsEvents;
