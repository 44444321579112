import { TextInputModel } from 'hooks/inputModels/useTextInputModel/useTextInputModel';
import useValidatableTextInputModel from 'hooks/inputModels/useValidatableTextInputModel/useValidatableTextInputModel';

export default ({
  initialValue = '',
  required: isRequired = false
}: {
  initialValue?: string;
  required?: boolean;
} = {}): TextInputModel => {
  return useValidatableTextInputModel({
    initialValue,
    required: isRequired,
    expecting: {
      a: 'msisdn',
      maxLength: 25
    }
  });
};
