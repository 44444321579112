import useBillingGroupForm, {
  UseBillingGroupFormResult
} from 'B2XApp/Invoicing/BillingGroups/BillingGroupForm/useBillingGroupForm';
import useUpdateCollectionGroup from 'hooks/invoicing/billingGroups/useUpdateCollectionGroup/useUpdateCollectionGroup';
import { useConfirmationModal } from 'hooks/useCustomModal/useConfirmationModal';
import useSearchParams from 'hooks/useSearchParams/useSearchParams';
import { AvailableBank, AvailableOperator, CollectionGroup } from 'types/collection-group';

import useEditBillingGroupSteps from './useEditBillingGroupSteps';

export type EditBillingGroupPrerequisites = {
  collectionGroup: CollectionGroup;
  availableBanks: AvailableBank[];
  availableOperators: AvailableOperator[];
};

const useEditBillingGroupForm = ({
  collectionGroup,
  availableBanks,
  availableOperators
}: EditBillingGroupPrerequisites): UseBillingGroupFormResult => {
  const { onClick } = useConfirmationModal();
  const { preserveBackPathState } = useSearchParams();

  const { updateCollectionGroupApiStatus: updateApiStatus, updateCollectionGroup } =
    useUpdateCollectionGroup(collectionGroup.collectionGroup);

  const { step, setStep } = useEditBillingGroupSteps({
    collectionGroup,
    updateApiStatus
  });

  const finvoiceAvailable = true;
  const billingTypeChangeAvailable = true;
  const companySelectionAvailable = false;
  const { fields, finvoiceFields, isValid, marshallRequestData } = useBillingGroupForm({
    collectionGroup,
    availableBanks,
    availableOperators,
    finvoiceAvailable,
    billingTypeChangeAvailable,
    companySelectionAvailable
  });

  return {
    form: {
      step,
      setStep,
      fields,
      finvoiceFields,
      finvoiceAvailable,
      isValid,
      onSave: () => {
        if (isValid) {
          updateCollectionGroup({
            variables: {
              collectionGroupId: collectionGroup.collectionGroup,
              requestData: marshallRequestData()
            }
          });
        }
      },
      onCancel: () => {
        onClick(
          preserveBackPathState(`/billing-accounts/${collectionGroup.collectionGroup}`, true)
        );
      }
    }
  };
};

export default useEditBillingGroupForm;
