import { Gutter } from '@gaiads/telia-react-component-library';
import { DynamicFormState } from 'B2XApp/DynamicForms/DynamicFormMain';
import ErrorPageLayout from 'B2XApp/Errors/Error/ErrorPageLayout/ErrorPageLayout';
import pageNotFoundImg from 'B2XApp/Errors/NotFound/page-not-found.svg';
import { AdditionalTicketInfoSkeleton, AdditionalTicketInformation } from 'B2XApp/Messages/common';
import { TeliaLink } from 'common-components';
import ConfirmationPanelSuccess from 'common-components/ConfirmationPanel/ConfirmationPanelSuccess';
import { useUserData } from 'contexts/UserContext/UserContext';
import useCreateServiceRequest, {
  CreateServiceRequestData
} from 'hooks/serviceRequests/useCreateServiceRequest/useCreateServiceRequest';
import useProducts from 'hooks/useProducts/useProducts';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import getServiceRequestFields from './getServiceRequestFields';
import NewRequestFormDynamic from './NewRequestForm/NewRequestFormDynamic';
import NewServiceRequestPage from './NewServiceRequestPage';
import NewServiceRequestSkeleton from './NewServiceRequestSkeleton';
import OriginatedFromPanel from './OriginatedFromPanel/OriginatedFromPanel';
import useCreateServiceRequestSteps from './useCreateServiceRequestSteps';
import useServiceRequestOrigin from './useServiceRequestOrigin';
import useServiceRequestRelatedRecord from './useServiceRequestRelatedRecord';

const NewServiceRequest: React.FC = () => {
  const { t } = useTranslation();

  const serviceRequestOrigin = useServiceRequestOrigin();
  const relatedRecord = useServiceRequestRelatedRecord(serviceRequestOrigin);
  const serviceRequestFields = getServiceRequestFields(serviceRequestOrigin, relatedRecord);
  const { data, loading, error, createServiceRequest } = useCreateServiceRequest();

  const { activeGroupType, email, businessPhoneNumber, name } = useUserData();
  const { data: products, loading: loadingProducts } = useProducts();
  const { step, goToInitialStep, savingStatus } = useCreateServiceRequestSteps({
    loadingProducts,
    relatedRecordType: relatedRecord?.type,
    loadingRecord: !!relatedRecord?.loading,
    creatingServiceRequest: loading,
    hasErrorLoadingAsset: !!relatedRecord?.hasError,
    hasErrorCreatingServiceRequest: !!error,
    created: !!data,
    initialStep: relatedRecord ? 'loading-data' : 'initial'
  });

  const dynamicFormStateRef = useRef<DynamicFormState | undefined>();
  const staticFormStateRef = useRef<CreateServiceRequestData | undefined>();

  const newRequestForm = (
    <NewRequestFormDynamic
      createServiceRequest={createServiceRequest}
      caseOrigin={serviceRequestOrigin?.type}
      products={products}
      requestFields={serviceRequestFields}
      dynamicFormStateRef={dynamicFormStateRef}
      staticFormStateRef={staticFormStateRef}
      saving={savingStatus}
      savingReset={goToInitialStep}
      activeGroupType={relatedRecord?.groupType ?? activeGroupType}
      relatedRecord={relatedRecord}
    />
  );

  switch (step) {
    case 'loading-data':
      return (
        <NewServiceRequestPage
          backLinkVisible
          backLinkTo={relatedRecord?.returnLinkPath}
          component={<NewServiceRequestSkeleton />}
          additionalInfo={<AdditionalTicketInfoSkeleton />}
          loading
        />
      );

    case 'error-loading-asset-for-corporate-product':
      return (
        <ErrorPageLayout
          imageSrc={pageNotFoundImg}
          headlineKey="serviceRequests.newServiceRequest.corporateProductNotFound.title"
          bodyKey="serviceRequests.newServiceRequest.corporateProductNotFound.description"
          translatables={[
            <TeliaLink.Translatable.Portal
              key="link"
              data-testid="old-product-amanagement-link"
              variant="text"
              to=""
              toCorporatePortal="search/subscriptionSearch"
              useRouterLink={false}
            />
          ]}
        />
      );

    case 'error-loading-asset':
    case 'initial':
    case 'creating-service-request':
    case 'error-creating-request':
      return (
        <NewServiceRequestPage
          backLinkVisible
          backLinkTo={relatedRecord?.returnLinkPath}
          component={newRequestForm}
          additionalInfo={
            <>
              {relatedRecord && (
                <>
                  <OriginatedFromPanel
                    relatedRecord={relatedRecord}
                    loading={!!relatedRecord?.loading}
                    hasError={!!relatedRecord?.hasError}
                  />

                  <Gutter size="sm" />
                </>
              )}

              <AdditionalTicketInformation
                additionalInfo={{
                  title: t('serviceRequests.newServiceRequest.contactGroup'),
                  rows: [name, email, businessPhoneNumber]
                }}
              />
            </>
          }
          loading={loadingProducts}
        />
      );

    case 'confirmed':
      return (
        <NewServiceRequestPage
          component={
            <ConfirmationPanelSuccess
              data-testid="send-service-request-success"
              title={t('serviceRequests.newServiceRequest.success')}
              description={t('serviceRequests.newServiceRequest.successDescription')}
              backToPath={relatedRecord?.returnLinkPath ?? '/service-requests'}
              backToLabel={
                relatedRecord?.returnLinkLabel ??
                t('serviceRequests.newServiceRequest.backToMessages')
              }
            />
          }
        />
      );
  }
};

export default NewServiceRequest;
