import { InlineNotification, TeliaLink } from 'common-components';
import createCorporatePortalLink from 'doings/links/createCorporatePortalLink/createCorporatePortalLink';

export const SuspendedNotice: React.FC<{
  phoneNumberHash: string;
}> = ({ phoneNumberHash }) => (
  <InlineNotification
    title={{ key: 'subscriptions.subscriptionDetails.status.suspendedTitle' }}
    content={{
      key: 'subscriptions.subscriptionDetails.status.suspendedMessage',
      components: [
        <TeliaLink.Translatable.External
          key="link"
          variant="standalone"
          to={createCorporatePortalLink('mobileSubscriptionTypeChange', {
            msisdn: phoneNumberHash
          })}
          enabledWhen={{
            onePermission: 'B2B_PORTAL',
            anyPermission: ['MOBILE_SUBSCRIPTIONS']
          }}
        />
      ]
    }}
    variant="information"
    data-testid="suspended-notification"
  />
);
