const finnishOvtCodeMinLength = 12;
const finnishIbanLength = 18;
const internationalIbanMinLength = 15;
const imeiWithChecksumLength = 15;

/**
 * Determines that the specified value is a valid IBAN based on the ISO 13616 standard.
 * Only implements a basic checksum for any provided international IBANs.
 *
 * @param iban The IBAN to check, e.g. FI0198765432101234.
 */
// prettier-ignore
const isValidIban = (iban: string) => {
  const ibanCheckValue1stPartStart = 4;
  const ibanCheckValue2ndPartLength = 4
  const asciiABC = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const asciiABCCharIndexOffset = 10;
  const checkValue = (iban.substring(ibanCheckValue1stPartStart) + iban.substring(0, ibanCheckValue2ndPartLength))
    .toUpperCase()
    .split('')
    .map((char) => asciiABC.indexOf(char) > -1 ? asciiABC.indexOf(char) + asciiABCCharIndexOffset : char)
    .join('');

  return mod97(checkValue) === 1;
};

/**
 * Calculates the modulo of an IBAN in numeric format.
 *
 * @param dividend An IBAN in numeric format, e.g. 98765432101234151801.
 */
// prettier-ignore
const mod97 = (dividend: string) => {
  const divisor = 97;
  const partLength = 8;
  let result = dividend;

  while (result.length > partLength) {
    const part = result.substring(0, partLength);
    result = (parseInt(part) % divisor) + result.substring(partLength);
  }

  return parseInt(result) % divisor;
};

/**
 * Determines that the specified value is a valid 15-digit IMEI number
 * by calculating a Luhn checksum for it. 16-digit IMEISV numbers do
 * not use a checksum, so should be considered valid.
 *
 * Payload sums and check digits for some valid IMEI numbers:
 *
 * * 121234561234567 — payload sum = 53, check digit => 7, (53 + 7) % 10 = 0.
 * * 341234561234561 — payload sum = 59, check digit => 1, (59 + 1) % 10 = 0.
 * * 351234561234568 — payload sum = 52, check digit => 8, (52 + 8) % 10 = 0.
 *
 * @param imei The IMEI in numeric format to check, e.g. 121234561234567.
 */
const isValidImeiWithChecksum = (imei: string) => {
  const imeiLastIndex = imei.length - 1;
  const imeiCheckDigit = parseInt(imei.charAt(imeiLastIndex), 10);
  const payload = imei.substring(0, imeiLastIndex);
  const payloadLastIndex = payload.length - 1;
  let sum = 0;

  /* eslint-disable @typescript-eslint/no-magic-numbers */
  for (let i = payloadLastIndex; i >= 0; i--) {
    const digit = parseInt(payload.charAt(i), 10);
    const isOdd = i % 2 !== 0;
    sum += isOdd ? (digit * 2) % 9 : digit;
  }

  return (sum + imeiCheckDigit) % 10 === 0;
  /* eslint-enable @typescript-eslint/no-magic-numbers */
};

/**
 * Checks that a provided string is a valid data type. Concrete rules
 * are provided by implementations. Refer to the implementations'
 * documentation for details.
 */
// prettier-ignore
export interface Validator {
  validate: (value: string) => boolean;
  i18nKeyOnHint: string;
}

/**
 * Checks that a provided string is a valid user, payer or additional
 * name in the mobile back-end system NAK. The aforementioned system
 * allows a finite set of symbols and alphanumeric characters in the
 * Finnish and Swedish alphabets. The max length is 30 characters.
 *
 * Some valid examples:
 *
 * * LOHIKÄÄRME LENNA
 * * LOHIKÄÄRME SANNA-LEENA
 */
// prettier-ignore
const NakNameValidator: Validator = {
  validate: (value: string) => /^(?![-/.&:_ ])[a-z0-9-/.&:_öäå ]{1,30}$/i.test(value),
  i18nKeyOnHint: 'validators.invalidNakName'
};

/**
 * Checks that a provided string is a valid Finnish postal code in
 * the mobile back-end system NAK. The length is exactly 5 digits.
 *
 * * 00001
 * * 99999
 */
// prettier-ignore
const NakPostalCodeValidator: Validator = {
  validate: (value: string) => /^\d{5}$/.test(value),
  i18nKeyOnHint: 'validators.invalidNakPostalCode'
};

/**
 * Checks that a provided string is a valid Finnish postal office in
 * the mobile back-end system NAK. The aforementioned system allows a
 * finite set of symbols and alphanumeric characters in the Finnish
 * and Swedish alphabets. The max length is 12 characters.
 *
 * * HELSINKI
 * * TELIA
 */
// prettier-ignore
const NakPostalOfficeValidator: Validator = {
  validate: (value: string) => /^(?![-/.&: ])[a-zäöå,.\s-]{1,12}$/i.test(value),
  i18nKeyOnHint: 'validators.invalidNakPostalOffice'
};

/**
 * Checks that a provided string is a valid Finnish street address in
 * the mobile back-end system NAK. The aforementioned system allows a
 * finite set of symbols and alphanumeric characters in the Finnish
 * and Swedish alphabets. The max length is 30 characters.
 *
 * * JOKIN KATU 123-3
 * * JOKIN KATU 123/3
 */
// prettier-ignore
const NakStreetAddressValidator: Validator = {
  validate: (value: string) => /^(?![-/.&: ])[0-9a-zäöå,.\s/\\-]{1,30}$/i.test(value),
  i18nKeyOnHint: 'validators.invalidNakStreetAddress'
};

/**
 * Checks that a provided string is an international bank account
 * number based on the ISO 13616. For Finnish IBANs, the exact format
 * is validated; for international IBANs, only the general format is
 * validated alongside the IBAN's checksum (i.e. we do not care about
 * a particular country's exact IBAN format other than Finland's).
 * The string must be between 15 and 32 characters in length.
 *
 * Some valid examples:
 *
 * * EE481226882456582976
 * * FI1212345678901234
 * * FI4250001510000023
 * * NO1596043914420
 * * LC22FCSX476144921763284313646952
 */
// prettier-ignore
const BillingInternationalIBANValidator: Validator = {
  validate: (value: string) => value.length >= internationalIbanMinLength && /^((fi\d{2}\d{14})|((?!fi)([a-z]{2})\d{2}[a-z0-9]{11,28}))$/i.test(value) && isValidIban(value),
  i18nKeyOnHint: 'validators.invalidInternationalIBAN'
};

/**
 * Checks that a provided string is an international bank account
 * number in Finnish format based on the ISO 13616 standard. The
 * string must be exactly 18 characters in length.
 *
 * Some valid examples:
 *
 * * FI1212345678901234
 * * FI4250001510000023
 */
// prettier-ignore
const BillingFinnishIBANValidator: Validator = {
  validate: (value: string) => value.length === finnishIbanLength && /^fi\d{2}\d{14}$/i.test(value) && isValidIban(value),
  i18nKeyOnHint: 'validators.invalidFinnishIBAN'
};

/**
 * Checks that a provided string is a Finnish OVT (EDI) code. An OVT
 * code comprises a 4-digit country code 0037, an 8-digit business ID
 * without the hyphen, and an optional unit code (department ID) of
 * 1–5 alphanumeric characters. The string must be between 12 and 17
 * characters in length.
 *
 * Some valid examples:
 *
 * * 003798990005
 * * 00379899000599991
 * * 003798990005TS001
 */
// prettier-ignore
const BillingFinnishOvtCodeValidator: Validator = {
  validate: (value: string) => value.length >= finnishOvtCodeMinLength && /^0037\d{8}[A-Z0-9]{0,5}$/i.test(value),
  i18nKeyOnHint: 'validators.invalidFinnishOvtCode'
};

/**
 * Checks that a provided string is an internal bank account number
 * in Finnish format based on the ISO 13616 standard, or a Finnish
 * OVT (EDI) code as an alternative. The string must be exactly 18
 * characters in length for a Finnish IBAN, or between 12 and 17
 * characters in length for an OVT code.
 *
 * Some valid Finnish IBAN examples:
 *
 * * FI1212345678901234
 * * FI4250001510000023
 *
 * Some valid Finnish OVT code examples:
 *
 * * 003798990005
 * * 00379899000599991
 */
// prettier-ignore
const BillingFinnishIBANOrOvtCodeValidator: Validator = {
  validate: (value: string) => BillingFinnishIBANValidator.validate(value) || BillingFinnishOvtCodeValidator.validate(value),
  i18nKeyOnHint: 'validators.invalidFinnishIBANOrOvtCode'
}

/**
 * Checks that a provided string is valid input for Finvoice 3.0
 * additional invoice details. The relevant backend systems allow a
 * lenient set of symbols, whitespace and alphanumeric characters in
 * the Finno-Scandic alphabets. The max length is 70 characters.
 *
 * Some valid examples:
 *
 * * Lohikäärme Lenna @ #AllThemTSDocExampleDragons
 * * Lohikäärme Sanna-Leena @ #AllThemTSDocExampleDragons
 * * L0h1k22rm6:S2nn2.L66n2 (h2xx0red); L0h1-2
 */
// prettier-ignore
const BillingFinvoiceLenientValueValidator: Validator = {
  validate: (value: string) => /^[0-9a-zõäöüå.\s-+=_/&@#,;:()]{1,70}$/i.test(value),
  i18nKeyOnHint: 'validators.invalidFinvoiceLenientValue'
};

/**
 * Checks that a provided string is a valid account proposal for
 * Finvoice 3.0 additional invoice details. The relevant backend
 * systems allow a narrow set of symbols and alphanumeric characters
 * in the Finno-Scandic alphabets. The max length is 4 characters.
 *
 * Some valid examples:
 *
 * * LL:1
 * * LL.1
 * * LL-1
 * * H2XD
 */
// prettier-ignore
const BillingFinvoiceAccountProposalValidator: Validator = {
  validate: (value: string) => /^[0-9a-zõäöüå.:-]{1,4}$/i.test(value),
  i18nKeyOnHint: 'validators.invalidFinvoiceAccountProposal'
};

/**
 * Checks that a provided string is an e-mail address. No limitations
 * are applied on the length.
 *
 * Some valid examples:
 *
 * * email\@domain\.fi
 */
// prettier-ignore
const EmailAddressValidator: Validator = {
  validate: (value: string) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([\d{1,3}.\d{1,3}.\d{1,3}.\d{1,3}])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/i.test(value),
  i18nKeyOnHint: 'validators.invalidEmailAddress'
};

/**
 * Checks that a provided string is a Multi Access Control (MAC)
 * address. MAC addresses comprise 12 hexidecimal digits, optionally
 * separated by colons, hyphens, full stops or spaces.
 *
 * Some valid examples:
 *
 * * FFFFFF000000
 * * FF FF FF 00 00 00
 * * FF:FF:FF:00:00:00
 * * FF-FF-FF-00-00-00
 * * FFF.FFF.000.000
 * * FFFF.FFFF.0000
 */
// prettier-ignore
const MacAddressValidator: Validator = {
  validate: (value: string) => /^(([\dA-F]{12})|([\dA-F]{2}[-.: ]){5}([\dA-F]{2})|([\dA-F]{3}[-.: ]){3}([\dA-F]{3})|([\dA-F]{4}[-.: ]){2}([\dA-F]{4}))$/i.test(value),
  i18nKeyOnHint: 'validators.invalidMacAddress'
};

/**
 * Checks that a provided string is a Finnish phone number in either
 * international or national format. Since phone numbers are
 * whitespace-insensitive, the validator is also such. No limitations
 * are applied on the length, although the phone number format
 * implies a subscriber number length of 5–12 digits.
 *
 * Some valid examples:
 *
 * * 040 123 4567
 * * 358 40 123 4567
 * * +358 40 123 4567
 * * 00358 40 123 4567
 */
// prettier-ignore
const MsisdnValidator: Validator = {
  validate: (value: string) => /^(0[1-9]|((00|\+|)358[1-9]))\d{5,12}$/.test(value.replace(/\s+/g, '')),
  i18nKeyOnHint: 'validators.invalidMsisdn'
};

/**
 * Checks that a provided string is an international phone number in
 * either international or national format. Since phone numbers are
 * whitespace-insensitive, the validator is also such. No limitations
 * are applied on the length, although the phone number format
 * implies a subscriber number length of 5–12 digits.
 *
 * Some valid examples:
 *
 * * 040 123 4567
 * * 358 40 123 4567
 * * +372 123 4567
 * * 00372 123 4567
 */
// prettier-ignore
const InternationalMsisdnValidator: Validator = {
  validate: (value: string) => /^((00|\+)?\d{3})?(\d{5,12})$/.test(value.replace(/\s+/g, '')),
  i18nKeyOnHint: 'validators.invalidInternationalMsisdn'
};

/**
 * Checks that a provided string is an international mobile equipment
 * identity number in either IMEI or IMEISV format. IMEI (IMEISV)
 * numbers comprise 15 digits (16 digits), optionally separated by
 * spaces or hyphens.
 *
 * Some valid examples:
 *
 * * 12 123456 123456 7
 * * 12 123456 123456 12
 * * 12-123456-123456-7
 * * 12-123456-123456-12
 * * 121234561234567
 * * 1212345612345612
 */
const ImeiValidator: Validator = {
  validate: (value: string) => {
    const imeiNumber = value.replace(/(\s+|-)/g, '');
    const regexResult = /^\d{15,16}$/.test(imeiNumber);
    return regexResult && imeiNumber.length === imeiWithChecksumLength
      ? isValidImeiWithChecksum(imeiNumber)
      : regexResult;
  },
  i18nKeyOnHint: 'validators.invalidImeiNumber'
};

/**
 * Checks that a provided string is a generic positive integer
 * greater than 0 (generic as in business context agnostic from a
 * validation perspective). Since the information can be sent to
 * back-ends which don't support scientific formats or thousands
 * separators, only allows the most basic numeric format. The integer
 * can be as large as 9,999,999,999 (without the thousands separators).
 *
 * Some valid examples:
 *
 * * 1
 * * 42
 * * 99999999
 * * +99999999
 */
// prettier-ignore
const PositiveIntegerValidator: Validator = {
  validate: (value: string) => /^\+?[1-9]\d{0,9}$/.test(value),
  i18nKeyOnHint: 'validators.invalidPositiveInteger'
};

/**
 * Checks that a provided string is a generic non-negative integer
 * equal to or greater than 0 (generic as in business context agnostic
 * from a validation perspective). Since the information can be sent
 * to back-ends which don't support scientific formats or thousands
 * separators, only allows the most basic numeric format. The integer
 * can be as large as 9,999,999,999 (without the thousands separators).
 *
 * Some valid examples:
 *
 * * 0
 * * 42
 * * 99999999
 * * +99999999
 */
// prettier-ignore
const NonnegativeIntegerValidator: Validator = {
  validate: (value: string) => /^\+?\d{1,10}$/.test(value),
  i18nKeyOnHint: 'validators.invalidNonnegativeInteger'
};

/**
 * Checks that a provided string is a wholesale customer system
 * identifier. Wholesale customer system reference numbers comprise
 * 3–28 alphanumeric characters and special symbols.
 *
 * Some valid examples:
 *
 * * CS-0000001 test
 */
// prettier-ignore
const WholesaleCustomerSystemRefValidator: Validator = {
  validate: (value: string) => /^[a-z0-9-/.&:_öäå ]{3,28}$/i.test(value),
  i18nKeyOnHint: 'validators.invalidWholesaleCustomerSystemRef'
};

/**
 * Checks that a provided string is a wholesale service identifier.
 * Wholesale service identifiers comprise a prefix of 3–5 letters and
 * a suffix of at least one digit. The suffix's length is applied a
 * very lenient length limitation of 15 digits, although known
 * wholesale products supported via B2X Portal have a maximum suffix
 * of 10 digits.
 *
 * Some valid examples:
 *
 * * SITE12345
 * * INN1234567
 * * IPTR0123456789
 * * BSETH0123456789
 */
// prettier-ignore
const WholesaleServiceIdValidator: Validator = {
  validate: (value: string) => /^[a-z]{3,5}\d{1,15}$/i.test(value),
  i18nKeyOnHint: 'validators.invalidWholesaleServiceId'
};

/**
 * Validator names for referring to concrete validators.
 */
export type ValueType =
  | 'nakName'
  | 'nakPostalCode'
  | 'nakPostalOffice'
  | 'nakStreetAddress'
  | 'billingInternationalIBAN'
  | 'billingFinnishIBAN'
  | 'billingFinnishIBANOrOvtCode'
  | 'billingFinnishOvtCode'
  | 'billingFinvoiceLenientValue'
  | 'billingFinvoiceAccountProposal'
  | 'emailAddress'
  | 'macAddress'
  | 'msisdn'
  | 'internationalMsisdn'
  | 'imei'
  | 'positiveInteger'
  | 'nonnegativeInteger'
  | 'wholesaleCustomerSystemRef'
  | 'wholesaleServiceId';

/**
 * All validator names matched to their corresponding validators.
 * Check the validators' documentation for a description on expected
 * input and valid examples.
 */
export default {
  nakName: NakNameValidator,
  nakPostalCode: NakPostalCodeValidator,
  nakPostalOffice: NakPostalOfficeValidator,
  nakStreetAddress: NakStreetAddressValidator,
  billingInternationalIBAN: BillingInternationalIBANValidator,
  billingFinnishIBAN: BillingFinnishIBANValidator,
  billingFinnishIBANOrOvtCode: BillingFinnishIBANOrOvtCodeValidator,
  billingFinnishOvtCode: BillingFinnishOvtCodeValidator,
  billingFinvoiceLenientValue: BillingFinvoiceLenientValueValidator,
  billingFinvoiceAccountProposal: BillingFinvoiceAccountProposalValidator,
  emailAddress: EmailAddressValidator,
  macAddress: MacAddressValidator,
  msisdn: MsisdnValidator,
  internationalMsisdn: InternationalMsisdnValidator,
  imei: ImeiValidator,
  positiveInteger: PositiveIntegerValidator,
  nonnegativeInteger: NonnegativeIntegerValidator,
  wholesaleCustomerSystemRef: WholesaleCustomerSystemRefValidator,
  wholesaleServiceId: WholesaleServiceIdValidator
} as { [K in ValueType]: Validator };
