import { gql } from '@apollo/client';
import { EditableSection, sectionItemsQuery } from 'hooks/editableSections/useEditableSectionItems';
import useCallBackendMutation from 'hooks/useCallBackend/useCallBackendMutation';

export const resetEditableSectionItemsMutation = gql`
  mutation ResetEditableSectionItems($section: String!) {
    resetSectionItems(section: $section)
  }
`;

type ResetEditableSectionItemsResponse = {
  resetEditableSectionItems: boolean;
};

const useResetEditableSectionItems = (section: EditableSection) => {
  const { runMutation, loading, error } = useCallBackendMutation<ResetEditableSectionItemsResponse>(
    resetEditableSectionItemsMutation,
    [
      {
        query: sectionItemsQuery,
        variables: {
          section
        }
      }
    ]
  );

  const resetSectionItems = () => {
    runMutation({
      variables: {
        section
      }
    });
  };

  return {
    resetSectionItems,
    resetting: loading,
    error
  };
};

export default useResetEditableSectionItems;
