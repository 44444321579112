import { BodyText, Checkbox, Div, Flex, Gutter, Page } from '@gaiads/telia-react-component-library';
import { Heading, ListView, TeliaLink } from 'common-components';
import PageWithSubnavigation from 'common-components/PageWithSubnavigation/PageWithSubnavigation';
import useFeatureFlags from 'hooks/featureFlags/useFeatureFlags/useFeatureFlags';
import useToggleFeatureFlag from 'hooks/featureFlags/useManageFeatureFlags/useToggleFeatureFlag';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const FeatureFlags: React.FC = () => {
  const { featureFlags, persistable } = useFeatureFlags();
  const { t } = useTranslation();
  const { toggleFeatureFlag, loading } = useToggleFeatureFlag();
  const [toggledFlag, setToggledFlag] = useState<string>('');

  const toggleFlag = (name: string) => {
    setToggledFlag(name);
    toggleFeatureFlag(name);
  };

  useEffect(() => {
    if (!loading) {
      setToggledFlag('');
    }
  }, [loading, toggledFlag]);

  return (
    <PageWithSubnavigation withoutCompanySelector data-testid="feature-flags-page">
      <Page.Row>
        <Heading.H2>{t('featureFlags.title')}</Heading.H2>

        <Gutter />

        <TeliaLink.Internal
          data-testid="feature-flags-add-new-flag-link"
          variant="standalone"
          to="/flags/new"
          label={t('featureFlags.new')}
          iconLeft="add"
          disabled={!persistable}
          disabledTooltipKey="featureFlags.nonPersistable"
        />
      </Page.Row>

      <Page.Row responsiveFullWidth>
        <ListView listData={featureFlags} data-testid="feature-flags-list">
          {({ rawActive, name, description, activeStatusOverride }) => (
            <ListView.Row linkTo={`/flags/edit/${name}`}>
              <ListView.Cell cellSize="1x">
                <Flex alignTop>
                  <Checkbox
                    inlineBlock
                    name={`featureflag-${name}`}
                    checked={rawActive}
                    onClick={(event) => event.stopPropagation()}
                    onChange={() => toggleFlag(name)}
                    loading={toggledFlag === name}
                  />

                  <Div
                    inlineBlock
                    padding={{ left: 'sm' }}
                    data-testid={`feature-flag-info-${name}`}
                  >
                    <BodyText
                      fontWeight={rawActive ? 'medium' : 'normal'}
                      data-testid="feature-flag-name"
                    >
                      {name}
                    </BodyText>

                    <BodyText
                      margin={{ top: 'xxs' }}
                      size="sm"
                      color="gray500"
                      data-testid="feature-flag-description"
                    >
                      {description}
                    </BodyText>

                    {!!activeStatusOverride && (
                      <Div margin={{ top: 'xxs' }} color="gray500">
                        <BodyText
                          size="sm"
                          fontWeight="medium"
                          padding={{ right: 'xxs' }}
                          tagName="span"
                          data-testid="feature-flag-active-status-override"
                        >
                          {t(rawActive ? 'featureFlags.disabledFor' : 'featureFlags.enabledFor')}
                        </BodyText>

                        <BodyText
                          data-testid="feature-flag-active-status-override-list"
                          size="sm"
                          tagName="span"
                        >
                          {activeStatusOverride.split(',').join(', ')}
                        </BodyText>
                      </Div>
                    )}
                  </Div>
                </Flex>
              </ListView.Cell>
            </ListView.Row>
          )}
        </ListView>
      </Page.Row>
    </PageWithSubnavigation>
  );
};

export default FeatureFlags;
