import { useRef, useState } from 'react';

const usePendingChangesGuard = (unguardedDismissalFunction?: VoidFunction) => {
  const hasChanges = useRef<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const onClose = (guarded = true) =>
    guarded && hasChanges.current ? setShowConfirmation(true) : unguardedDismissalFunction?.();

  return {
    hasChanges,
    showConfirmation,
    closeConfirmation: () => setShowConfirmation(false),
    onClose
  };
};

export default usePendingChangesGuard;
