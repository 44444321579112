import { useState } from 'react';

export type CheckboxModel = {
  checked: boolean;
  setChecked: (value: boolean) => void;
  isValid: boolean;
  value?: boolean;
  initialValue?: boolean;
  disabled?: boolean;
  required?: boolean;
};

export default ({
  initialValue = false,
  required = false,
  disabled = false
}: { initialValue?: boolean; required?: boolean; disabled?: boolean } = {}): CheckboxModel => {
  const [value, setValue] = useState<boolean>(initialValue);

  return {
    checked: value,
    setChecked: setValue,
    value,
    initialValue,
    disabled,
    required,
    isValid: required ? value : true
  };
};
