import { ApolloError } from '@apollo/client';
import { Page } from '@gaiads/telia-react-component-library';
import routeAccessGroups from 'B2XApp/appRouteAccessGroups';
import { ExcelDownloadLink } from 'B2XApp/Messages/MessageArchive/ExcelDownloadLink';
import { InlineNotification, Memo, PageWithSubnavigation, TeliaLink } from 'common-components';
import EmptyListContent from 'common-components/EmptyListContent/EmptyListContent';
import ListHeader from 'common-components/ListHeader/ListHeader';
import ShowMoreResults from 'common-components/ShowMoreResults/ShowMoreResults';
import { EditableListProvider } from 'contexts/EditableListContext/EditableListContext';
import { messages } from 'doings/track/analyticsEvents';
import useCompanyCaseListFilters from 'hooks/messageArchive/useCompanyCaseList/useCompanyCaseListFilters';
import { ViewStatus } from 'hooks/useApiStatus/useApiStatus';
import useInitialLoadStatus from 'hooks/useInitialLoadStatus/useInitialLoadStatus';
import { UseReadQueryParamsResult } from 'hooks/useQueryParams/useReadQueryParams';
import { OnSortChange, SortState } from 'hooks/useSortableHeadings/useSortableHeadings';
import useUserHasMultipleCompaniesWithGivenPermission from 'hooks/useUser/useUserHasMultipleCompaniesWithGivenPermission';
import { useTranslation } from 'react-i18next';
import { CompanyCaseListResult } from 'types/companyCaseList';
import { SortParameter } from 'types/sort';

import MessageArchiveListSortDropdown from './MessageArchiveListSortDropdown';
import MessageArchiveListView, {
  MessageArchiveListViewColumnSetup
} from './MessageArchiveListView';

type InitialFilters = {
  allCompanies?: boolean;
};

type SortProps = {
  sortClick: (field: string) => void;
  sortState: SortState;
  currentSort: SortParameter;
  onSortChange: OnSortChange;
};

const MessageArchiveListPage: React.FC<{
  companyCases?: CompanyCaseListResult;
  fetchMore: VoidFunction;
  apiState: ViewStatus;
  error?: ApolloError;
  sort: SortProps;
  initialFilters: InitialFilters;
  queryParameters: UseReadQueryParamsResult;
}> = ({
  companyCases,
  sort: { sortState, sortClick, currentSort, onSortChange },
  fetchMore,
  apiState,
  error,
  initialFilters: { allCompanies: initialAllCompanies },
  queryParameters
}) => {
  const { t } = useTranslation();
  const hasMultipleCompaniesWithMessages = useUserHasMultipleCompaniesWithGivenPermission({
    onePermission: 'MESSAGE_ARCHIVE'
  });

  const { total, paging, items, available } = companyCases ?? {
    total: 0,
    paging: { page: 0, pageSize: 0, pageCount: 0 },
    items: [],
    available: true
  };

  const filters = useCompanyCaseListFilters({ initialAllCompanies });
  const status = useInitialLoadStatus(apiState);

  return (
    <EditableListProvider
      columnSetup={MessageArchiveListViewColumnSetup}
      invocationArgs={[t, sortState, sortClick, hasMultipleCompaniesWithMessages]}
      changeableArgs={[undefined, sortState, undefined, undefined]}
    >
      <PageWithSubnavigation
        loading={[ViewStatus.Initial, ViewStatus.LoadingInitial].includes(status)}
        error={error}
        emptyContentPlaceholder={
          !available && (
            <EmptyListContent
              title={t('messageArchive.list.emptyList.title')}
              description={t('messageArchive.list.emptyList.description')}
            />
          )
        }
        showSubnavigationForCurrentPath
        data-testid="message-archive-list-page-with-subnavigation"
      >
        <ListHeader
          withMultiCompanyFeature={hasMultipleCompaniesWithMessages}
          multipleCompaniesCheckboxParams={{
            checked: filters.allCompanies,
            label: t('messageArchive.list.searchAllLabelArchive'),
            testId: `search-all-messages-checkbox${filters.allCompanies ? '-checked' : ''}`,
            onChange: filters.toggleAllCompanies
          }}
          dateRangeParams={{
            label: t('messageArchive.list.filters.reportedDate'),
            helperText: t('messageArchive.list.filters.reportedDateHelperText'),
            helperTooltip: t('messageArchive.list.filters.dateFilterDefaultRangeTooltip'),
            withDefaultDatePlaceholder: true
          }}
          statusFilterParams={{
            label: t('messageArchive.list.filters.status'),
            options: filters.statusOptions,
            'data-testid': 'status-filter'
          }}
          labels={{
            searchInMobileView: t('messageArchive.list.searchLabel'),
            sortInMobileView: t('messageArchive.list.sortLabel')
          }}
          sortDropdown={
            <MessageArchiveListSortDropdown currentSort={currentSort} onChange={onSortChange} />
          }
          infoNotice={
            <InlineNotification
              data-testid="message-archive-filter-inline-notification"
              variant="information"
              content={{
                key: 'messageArchive.list.omsuRequestsNotice',
                components: [
                  <TeliaLink.Translatable.Internal
                    variant="text"
                    to="service-requests"
                    enabledWhen={routeAccessGroups.SERVICE_REQUESTS}
                    track={messages.serviceRequestsPageFromOmsuNotice}
                    key="link"
                  />
                ]
              }}
            />
          }
          excelDownloadLink={
            <ExcelDownloadLink
              queryParameters={queryParameters}
              sortParameter={currentSort}
              disabled={status === ViewStatus.Loading || total === 0}
              totalItems={total}
            />
          }
        >
          {t('messageArchive.list.numberOfArchivedItems', {
            numberOfMessages: total
          })}
        </ListHeader>

        <Page.Row responsiveFullWidth>
          <Memo comparables={['loading', 'items']}>
            <MessageArchiveListView loading={status === ViewStatus.Loading} items={items} />
          </Memo>
        </Page.Row>

        <Page.Row>
          {status !== ViewStatus.Loading && (
            <ShowMoreResults
              data-testid="show-more-company-cases"
              loading={status === ViewStatus.LoadingMore}
              hasMoreResults={paging.page < paging.pageCount}
              hasResults={!!items.length}
              fetchMore={fetchMore}
            />
          )}
        </Page.Row>
      </PageWithSubnavigation>
    </EditableListProvider>
  );
};

export default MessageArchiveListPage;
