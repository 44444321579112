import { IconName } from '@teliafi/fi-ds';
import routeAccessGroups from 'B2XApp/appRouteAccessGroups';
import { DashboardSectionId } from 'B2XApp/Dashboard/sections';
import { useFeatureFlagsData } from 'contexts/FeatureFlagContext/FeatureFlagContext';
import getEnvironmentVariable from 'doings/getEnvironmentVariable/getEnvironmentVariable';
import isDemoEnvironment from 'doings/isDemoEnvironment/isDemoEnvironment';
import { isSmallBusiness } from 'doings/isSmallBusiness/isSmallBusiness';
import { isWholesaleBusiness } from 'doings/isWholesaleBusiness/isWholesaleBusiness';
import createCorporatePortalLink, {
  createCorporatePortalLinkWithFragment
} from 'doings/links/createCorporatePortalLink/createCorporatePortalLink';
import createULMPortalLink from 'doings/links/createULMPortalLink/createULMPortalLink';
import {
  AnalyticsEvent,
  dashboardLinks,
  dashboardTopTiles,
  topNavigationLinks
} from 'doings/track/analyticsEvents';
import userHasPermission from 'doings/userHasPermission/userHasPermission';
import useClearNewMessagesNotification from 'hooks/customerSpecificMessages/useClearNewMessagesNotification';
import useEcomMobileFlow from 'hooks/useEcomMobileFlow/useEcomMobileFlow';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showBillingInfoChangeModal } from 'redux/slices/billingInfoChangeSlice';
import { AuthorizationParams } from 'types/authorization';

export enum LinkTarget {
  PRODUCTS__MOBILE_SUBSCRIPTIONS,
  PRODUCTS__MOBILE_SUBSCRIPTIONS__CREATE_EMPLOYEE,
  PRODUCTS__MOBILE_SUBSCRIPTIONS__MASS_CHANGE,
  PRODUCTS__MOBILE_SUBSCRIPTIONS__OWNERSHIP_CHANGE,
  PRODUCTS__MOBILE_SUBSCRIPTIONS__ORDER_SIM_CARDS,
  PRODUCTS__INTERNET_SUBSCRIPTIONS,
  PRODUCTS__DEVICES,
  PRODUCTS__OTHER_PRODUCTS_AND_SERVICES,
  PRODUCTS__OTHER_PRODUCTS_AND_SERVICES__VIP,
  PRODUCTS__OTHER_PRODUCTS_AND_SERVICES__CID,
  PRODUCTS__OTHER_PRODUCTS_AND_SERVICES__TOUCHPOINT,
  PRODUCTS__OTHER_PRODUCTS_AND_SERVICES__SMART_CONNECT,
  BILLING__INVOICES,
  BILLING__PAYMENT_REMINDERS,
  BILLING__BILLING_ACCOUNTS,
  BILLING__BILLING_INFO_MASS_CHANGE,
  BILLING__BILLING_INFO_CHANGES,
  B2B_PORTAL_REPORTS,
  OFFERINGS__BUSINESS_OFFERING,
  ORDERS__B2B_ORDERS,
  MESSAGES__CONTACT_US,
  MESSAGES__SERVICE_REQUESTS,
  MESSAGES__SERVICE_REQUESTS__NEW,
  MESSAGES__TICKETS,
  MESSAGES__TICKETS__NEW,
  MESSAGES__MESSAGE_ARCHIVE,
  MESSAGES__CUSTOMER_SPECIFIC,
  MESSAGES__REQUEST_QUOTE,
  MESSAGES__ACTIVITY_LOG,
  ACCOUNT__COMPANY_INFO,
  ACCOUNT__COMPANY_USERS,
  ACCOUNT__SERVICE_ORDER_PERMISSIONS,
  B2B_PORTAL,
  MISC__DASHBOARD,
  MISC__B2C_INVOICES,
  MISC__TIN,
  WHOLESALE_SERVICES__ORDER_AND_DELIVERY_SYSTEM,
  WHOLESALE_SERVICES__EVENTS,
  WHOLESALE_SERVICES__REQUEST_FOR_QUOTE,
  WHOLESALE_SERVICES__ORDER,
  WHOLESALE_SERVICES__ORDER_FOR_CHANGE,
  WHOLESALE_SERVICES__ORDER_FOR_TERMINATION,
  WHOLESALE_SERVICES__YRITYSNETTI_ORDERS,
  WHOLESALE_SERVICES__PRODUCT_OFFERING
}

export type NavigationalLinks = { [key in LinkTarget]: NavigationalLink };

export type NavigationalLink = {
  id: string;
  textI18nKey: string;
  visibleWhen: AuthorizationParams;
  isAvailable: boolean;
  isQuickLink: boolean;
  external: boolean;
  openExternalLinkInSameTab?: boolean;
  to?: string;
  hiddenFromTopNavigation?: boolean;
  nestUnder?: string;
  tooltipI18nKey?: string;
  trackForDashboard: AnalyticsEvent;
  trackForTopNavigation?: AnalyticsEvent;
  trackForTopTiles?: AnalyticsEvent;
  onClick?: VoidFunction;
  disabled?: boolean;
  linksToB2B?: boolean;
  defaultTopTile?: boolean;
  defaultQuickLink?: boolean;
  topTileIcon?: IconName;
  isB2OLink?: boolean;
  availableInDemo?: boolean;
};

type LinkFunctions = {
  toB2BPortal: (path: string, sectionId?: DashboardSectionId) => string;
  toB2BPortalFragment: (path: string, fragment: string, sectionId?: DashboardSectionId) => string;
  toULM: (path: string, sectionId?: DashboardSectionId) => string;
};

const LINK_FUNCTIONS__FROM_SECTION: LinkFunctions = {
  toB2BPortal: (path, sectionId) =>
    createCorporatePortalLink(path, undefined, { section: sectionId }),
  toB2BPortalFragment: (path, fragment, sectionId) =>
    createCorporatePortalLinkWithFragment(path, fragment, undefined, { section: sectionId }),
  toULM: (path, sectionId) =>
    createULMPortalLink(path, { section: sectionId as DashboardSectionId })
};

const LINK_FUNCTIONS__FROM_ANYWHERE: LinkFunctions = {
  toB2BPortal: (path) => createCorporatePortalLink(path),
  toB2BPortalFragment: (path, fragment) => createCorporatePortalLinkWithFragment(path, fragment),
  toULM: (path) => createULMPortalLink(path)
};

const determineLinkFunctions = (hasOriginator?: boolean): LinkFunctions =>
  hasOriginator ? LINK_FUNCTIONS__FROM_SECTION : LINK_FUNCTIONS__FROM_ANYWHERE;

export default (user: User, hasOriginator?: boolean) => {
  const { hasFeature } = useFeatureFlagsData();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLargeBusiness = !isSmallBusiness(user);
  const isB2OBusiness = isWholesaleBusiness(user);
  const shouldLinkToB2BPortal =
    isLargeBusiness && userHasPermission(user)({ onePermission: 'B2B_PORTAL' });
  const shouldUseB2BPortalBilling = !hasFeature('EAT-46311') && shouldLinkToB2BPortal;
  const shouldUseNewWholesaleLinks = hasFeature('EART-5176');

  const linkFunctions = determineLinkFunctions(hasOriginator);
  const linkToB2BPortal = linkFunctions.toB2BPortal;
  const linkToB2BPortalWithFragment = linkFunctions.toB2BPortalFragment;
  const linkToULM = linkFunctions.toULM;

  const { showEcomMobileFlowModal } = useEcomMobileFlow({
    whenClicked: dashboardLinks.mobileSubscriptionsCreateEmployee
  });
  const { clearNewCustomerSpecificMessagesNotification } = useClearNewMessagesNotification();

  const getOpenSubscriptionPermissions = () => {
    const permissions = routeAccessGroups.B2B_QUICKLINKS_CREATE_EMPLOYEE;
    if (user.isFirstTimePurchaser) {
      if (!permissions.anyPermission) {
        permissions.anyPermission = [];
      }
      permissions.anyPermission.push('MOBILE_SUBSCRIPTIONS_VIEW');
    }

    return permissions;
  };

  const navigationalLinks: NavigationalLinks = {
    [LinkTarget.PRODUCTS__MOBILE_SUBSCRIPTIONS]: {
      to: shouldLinkToB2BPortal
        ? linkToB2BPortal('search/subscriptionSearch', DashboardSectionId.SubscriptionsAndDevices)
        : '/subscriptions',
      linksToB2B: shouldLinkToB2BPortal,
      textI18nKey: 'navigationalLink.title.mobileSubscriptions',
      id: 'link-to-mobile-subscriptions',
      visibleWhen: routeAccessGroups.MOBILE_SUBSCRIPTIONS,
      isAvailable: true,
      isQuickLink: true,
      external: shouldLinkToB2BPortal,
      trackForDashboard: dashboardLinks.mobileSubscriptions,
      trackForTopNavigation: topNavigationLinks.mobileSubscriptions,
      trackForTopTiles: dashboardTopTiles.mobileSubscriptions,
      defaultTopTile: true,
      topTileIcon: 'simcard',
      availableInDemo: true
    },

    [LinkTarget.PRODUCTS__MOBILE_SUBSCRIPTIONS__ORDER_SIM_CARDS]: {
      to: linkToB2BPortal('orderSimCards', DashboardSectionId.SubscriptionsAndDevices),
      textI18nKey: 'navigationalLink.title.orderSim',
      visibleWhen: routeAccessGroups.B2B_QUICKLINKS_MOBILE,
      id: 'link-to-order-sim-cards',
      isAvailable: !isB2OBusiness,
      isQuickLink: true,
      external: true,
      nestUnder: 'link-to-mobile-subscriptions',
      hiddenFromTopNavigation: true,
      trackForDashboard: dashboardLinks.mobileSubscriptionsOrderSimCard,
      defaultQuickLink: true
    },

    [LinkTarget.PRODUCTS__MOBILE_SUBSCRIPTIONS__CREATE_EMPLOYEE]: {
      to: user.isFirstTimePurchaser
        ? undefined
        : linkToB2BPortal('createEmployee', DashboardSectionId.SubscriptionsAndDevices),
      textI18nKey: 'navigationalLink.title.openSubscription',
      visibleWhen: getOpenSubscriptionPermissions(),
      id: 'link-to-open-subscription',
      isAvailable: true,
      isQuickLink: true,
      external: !user.isFirstTimePurchaser,
      nestUnder: 'link-to-mobile-subscriptions',
      hiddenFromTopNavigation: true,
      trackForDashboard: dashboardLinks.mobileSubscriptionsCreateEmployee,
      trackForTopTiles: dashboardTopTiles.mobileSubscriptionsCreateEmployee,
      defaultTopTile: true,
      topTileIcon: 'simcard',
      onClick: user.isFirstTimePurchaser ? showEcomMobileFlowModal : undefined
    },

    [LinkTarget.PRODUCTS__MOBILE_SUBSCRIPTIONS__MASS_CHANGE]: {
      to: linkToB2BPortal('massOperations/massChange', DashboardSectionId.SubscriptionsAndDevices),
      textI18nKey: 'navigationalLink.title.massChange',
      visibleWhen: routeAccessGroups.B2B_QUICKLINKS_MOBILE,
      id: 'link-to-mass-change',
      isAvailable: !isB2OBusiness,
      isQuickLink: true,
      external: true,
      nestUnder: 'link-to-mobile-subscriptions',
      hiddenFromTopNavigation: true,
      trackForDashboard: dashboardLinks.mobileSubscriptionsMassChange
    },

    [LinkTarget.PRODUCTS__MOBILE_SUBSCRIPTIONS__OWNERSHIP_CHANGE]: {
      to: linkToB2BPortal('ownershipChange', DashboardSectionId.SubscriptionsAndDevices),
      textI18nKey: 'navigationalLink.title.ownershipChange',
      visibleWhen: routeAccessGroups.B2B_QUICKLINKS_MOBILE,
      id: 'link-to-ownership-change',
      isAvailable: true,
      isQuickLink: true,
      external: true,
      nestUnder: 'link-to-mobile-subscriptions',
      hiddenFromTopNavigation: true,
      trackForDashboard: dashboardLinks.mobileSubscriptionsOwnershipChange,
      defaultQuickLink: true
    },

    [LinkTarget.PRODUCTS__INTERNET_SUBSCRIPTIONS]: {
      to: '/internet-connections',
      textI18nKey: 'navigationalLink.title.internetSubscriptions',
      visibleWhen: routeAccessGroups.ASSETS,
      id: 'link-to-internet-connections',
      isAvailable: true,
      isQuickLink: true,
      external: false,
      trackForDashboard: dashboardLinks.productsAssets,
      trackForTopNavigation: topNavigationLinks.productsAssets,
      trackForTopTiles: dashboardTopTiles.productsAssets,
      topTileIcon: 'services',
      availableInDemo: true
    },

    [LinkTarget.PRODUCTS__DEVICES]: {
      to: '/devices',
      textI18nKey: 'navigationalLink.title.devices',
      visibleWhen: routeAccessGroups.DEVICES,
      id: 'link-to-devices',
      isAvailable: !isB2OBusiness,
      isQuickLink: true,
      external: false,
      trackForDashboard: dashboardLinks.productsDevices,
      trackForTopNavigation: topNavigationLinks.productsDevices,
      availableInDemo: true
    },

    [LinkTarget.PRODUCTS__OTHER_PRODUCTS_AND_SERVICES]: {
      to: linkToB2BPortal('search/subscriptionSearch', DashboardSectionId.SubscriptionsAndDevices),
      textI18nKey: 'navigationalLink.title.otherProductsAndServices',
      visibleWhen: routeAccessGroups.OTHER_PRODUCTS,
      id: 'link-to-other-products-and-services',
      isAvailable: !isB2OBusiness,
      isQuickLink: true,
      external: true,
      tooltipI18nKey: 'navigationalLink.tooltip.otherProductsAndServices',
      trackForDashboard: dashboardLinks.productsOther,
      trackForTopNavigation: topNavigationLinks.productsOther,
      defaultQuickLink: true
    },

    [LinkTarget.PRODUCTS__OTHER_PRODUCTS_AND_SERVICES__VIP]: {
      to: linkToB2BPortalWithFragment(
        'search/subscriptionSearch',
        'vip',
        DashboardSectionId.SubscriptionsAndDevices
      ),
      textI18nKey: 'navigationalLink.title.vip',
      visibleWhen: routeAccessGroups.B2B_QUICKLINKS_COMMUNICATION_SERVICES,
      id: 'link-to-vip-subscriptions',
      isAvailable: !isB2OBusiness,
      isQuickLink: true,
      nestUnder: 'link-to-other-products-and-services',
      external: true,
      hiddenFromTopNavigation: true,
      trackForDashboard: dashboardLinks.productsOtherVIP
    },

    [LinkTarget.PRODUCTS__OTHER_PRODUCTS_AND_SERVICES__CID]: {
      to: linkToB2BPortalWithFragment(
        'search/subscriptionSearch',
        'cidnumber',
        DashboardSectionId.SubscriptionsAndDevices
      ),
      textI18nKey: 'navigationalLink.title.cid',
      visibleWhen: routeAccessGroups.B2B_QUICKLINKS_CID,
      id: 'link-to-cid-subscriptions',
      isAvailable: !isB2OBusiness,
      isQuickLink: true,
      nestUnder: 'link-to-other-products-and-services',
      external: true,
      hiddenFromTopNavigation: true,
      trackForDashboard: dashboardLinks.productsOtherCID
    },

    [LinkTarget.PRODUCTS__OTHER_PRODUCTS_AND_SERVICES__TOUCHPOINT]: {
      to: linkToB2BPortalWithFragment(
        'search/subscriptionSearch',
        'touchpoint',
        DashboardSectionId.SubscriptionsAndDevices
      ),
      textI18nKey: 'navigationalLink.title.touchpoint',
      visibleWhen: routeAccessGroups.B2B_QUICKLINKS_COMMUNICATION_SERVICES,
      id: 'link-to-touchpoint-subscriptions',
      isAvailable: !isB2OBusiness,
      isQuickLink: true,
      nestUnder: 'link-to-other-products-and-services',
      external: true,
      hiddenFromTopNavigation: true,
      trackForDashboard: dashboardLinks.productsOtherTouchpoint,
      defaultQuickLink: true
    },

    [LinkTarget.PRODUCTS__OTHER_PRODUCTS_AND_SERVICES__SMART_CONNECT]: {
      to: linkToB2BPortalWithFragment(
        'search/subscriptionSearch',
        'smartConnect', // TODO: replace with a real fragment after it's implemented on B2B side
        DashboardSectionId.SubscriptionsAndDevices
      ),
      textI18nKey: 'navigationalLink.title.smartConnect',
      visibleWhen: routeAccessGroups.B2B_QUICKLINKS_COMMUNICATION_SERVICES,
      id: 'link-to-smart-connect-subscriptions',
      isAvailable: !isB2OBusiness,
      isQuickLink: true,
      nestUnder: 'link-to-other-products-and-services',
      external: true,
      hiddenFromTopNavigation: true,
      trackForDashboard: dashboardLinks.productsOtherSmartConnect
    },

    [LinkTarget.BILLING__INVOICES]: {
      to: shouldUseB2BPortalBilling
        ? linkToB2BPortal('billing/invoices', DashboardSectionId.Invoicing)
        : '/invoices',
      linksToB2B: shouldUseB2BPortalBilling,
      textI18nKey: 'navigationalLink.title.invoices',
      visibleWhen: routeAccessGroups.BILLING_INVOICES,
      id: 'link-to-invoices',
      isAvailable: true,
      isQuickLink: true,
      external: shouldUseB2BPortalBilling,
      trackForDashboard: dashboardLinks.billingInvoices,
      trackForTopNavigation: topNavigationLinks.billingInvoices,
      trackForTopTiles: dashboardTopTiles.billingInvoices,
      topTileIcon: 'invoice',
      defaultTopTile: true,
      availableInDemo: true
    },

    [LinkTarget.BILLING__PAYMENT_REMINDERS]: {
      to: shouldUseB2BPortalBilling
        ? linkToB2BPortal('billing/reminders', DashboardSectionId.Invoicing)
        : '/invoice-payment-reminder',
      linksToB2B: shouldUseB2BPortalBilling,
      textI18nKey: 'navigationalLink.title.paymentReminders',
      visibleWhen: routeAccessGroups.BILLING,
      id: 'link-to-payment-reminders',
      isAvailable: true,
      isQuickLink: true,
      external: shouldUseB2BPortalBilling,
      trackForDashboard: dashboardLinks.billingPaymentReminders,
      trackForTopNavigation: topNavigationLinks.billingPaymentReminders
    },

    [LinkTarget.BILLING__BILLING_ACCOUNTS]: {
      to: shouldUseB2BPortalBilling
        ? linkToB2BPortal('billing/accounts', DashboardSectionId.Invoicing)
        : '/billing-accounts',
      linksToB2B: shouldUseB2BPortalBilling,
      textI18nKey: 'navigationalLink.title.billingAccounts',
      visibleWhen: routeAccessGroups.BILLING,
      id: 'link-to-billing-accounts',
      isAvailable: true,
      isQuickLink: true,
      external: shouldUseB2BPortalBilling,
      trackForDashboard: dashboardLinks.billingBillingAccounts,
      trackForTopNavigation: topNavigationLinks.billingBillingAccounts,
      trackForTopTiles: dashboardTopTiles.billingBillingAccounts,
      topTileIcon: 'invoice',
      defaultQuickLink: true,
      availableInDemo: true
    },

    [LinkTarget.BILLING__BILLING_INFO_MASS_CHANGE]: {
      onClick: () => dispatch(showBillingInfoChangeModal({ show: true, mass: true })),
      textI18nKey: 'navigationalLink.title.billingInfoMassChange',
      visibleWhen: routeAccessGroups.BILLING_INFO_CHANGES,
      id: 'link-to-billing-info-mass-change',
      isAvailable: true,
      isQuickLink: true,
      external: false,
      trackForDashboard: dashboardLinks.billingBillingInfoMassChange,
      trackForTopNavigation: topNavigationLinks.billingBillingInfoMassChange,
      availableInDemo: true
    },

    [LinkTarget.BILLING__BILLING_INFO_CHANGES]: {
      onClick: () => dispatch(showBillingInfoChangeModal({ show: true })),
      textI18nKey: 'navigationalLink.title.changeBillingInfo',
      visibleWhen: routeAccessGroups.BILLING_INFO_CHANGES,
      id: 'link-to-billing-info-changes',
      isAvailable: true,
      isQuickLink: true,
      defaultQuickLink: true,
      external: false,
      trackForDashboard: dashboardLinks.billingInfoChanges
    },

    [LinkTarget.B2B_PORTAL_REPORTS]: {
      to: linkToB2BPortal('reports', DashboardSectionId.Reports),
      textI18nKey: 'navigationalLink.title.reporting',
      visibleWhen: routeAccessGroups.REPORTS,
      id: 'link-to-reports',
      isAvailable: true,
      isQuickLink: true,
      external: true,
      trackForDashboard: dashboardLinks.b2bPortalReports,
      trackForTopTiles: dashboardTopTiles.b2bPortalReports,
      topTileIcon: 'report',
      defaultQuickLink: true
    },

    [LinkTarget.OFFERINGS__BUSINESS_OFFERING]: {
      to: getEnvironmentVariable(
        isLargeBusiness ? 'REACT_APP_LINK_TO_OFFERING_LARGE' : 'REACT_APP_LINK_TO_OFFERING_SOHO'
      ),
      textI18nKey: 'navigationalLink.title.offering',
      visibleWhen: routeAccessGroups.UNRESTRICTED,
      id: 'link-to-offering-page',
      isAvailable: true,
      isQuickLink: true,
      external: true,
      trackForDashboard: dashboardLinks.offering,
      hiddenFromTopNavigation: true,
      availableInDemo: true
    },

    [LinkTarget.ORDERS__B2B_ORDERS]: {
      to: '/orders',
      textI18nKey: 'navigationalLink.title.orders',
      visibleWhen: routeAccessGroups.B2B_ORDERS,
      id: 'link-to-orders',
      isAvailable: !isB2OBusiness,
      isQuickLink: true,
      external: false,
      trackForDashboard: dashboardLinks.wholesaleOrders,
      trackForTopNavigation: topNavigationLinks.wholesaleOrders,
      trackForTopTiles: dashboardTopTiles.wholesaleOrders,
      topTileIcon: 'delivery',
      defaultQuickLink: true,
      availableInDemo: true
    },

    [LinkTarget.MESSAGES__CONTACT_US]: {
      to: '/contact-us',
      textI18nKey: 'navigationalLink.title.contactUs',
      visibleWhen: routeAccessGroups.B2B_PAGES,
      id: 'link-to-contact-us',
      isAvailable: !isB2OBusiness,
      isQuickLink: true,
      external: false,
      trackForDashboard: dashboardLinks.contactUs,
      trackForTopNavigation: topNavigationLinks.contactUs,
      trackForTopTiles: dashboardTopTiles.contactUs,
      topTileIcon: 'support',
      defaultTopTile: true,
      availableInDemo: true
    },

    [LinkTarget.MESSAGES__SERVICE_REQUESTS]: {
      to: '/service-requests',
      textI18nKey: 'navigationalLink.title.serviceRequests',
      visibleWhen: routeAccessGroups.SERVICE_REQUESTS,
      id: 'link-to-service-requests',
      isAvailable: true,
      isQuickLink: true,
      external: false,
      trackForDashboard: dashboardLinks.messageServiceRequests,
      trackForTopNavigation: topNavigationLinks.messageServiceRequests,
      trackForTopTiles: dashboardTopTiles.messageServiceRequests,
      topTileIcon: 'repair',
      defaultQuickLink: true,
      availableInDemo: true
    },

    [LinkTarget.MESSAGES__SERVICE_REQUESTS__NEW]: {
      to: '/service-requests/new',
      textI18nKey: 'serviceRequests.emptyList.createNew',
      visibleWhen: routeAccessGroups.SERVICE_REQUESTS_NEW,
      id: 'link-to-new-service-request',
      isAvailable: true,
      isQuickLink: true,
      external: false,
      hiddenFromTopNavigation: true,
      trackForDashboard: dashboardLinks.messageCreateServiceRequest,
      availableInDemo: true
    },

    [LinkTarget.MESSAGES__TICKETS]: {
      to: '/tickets',
      textI18nKey: 'navigationalLink.title.troubleTickets',
      visibleWhen: routeAccessGroups.INCIDENT_TICKETS,
      id: 'link-to-trouble-tickets',
      isAvailable: true,
      isQuickLink: true,
      external: false,
      trackForDashboard: dashboardLinks.messageTickets,
      trackForTopNavigation: topNavigationLinks.messageTickets,
      trackForTopTiles: dashboardTopTiles.messageTickets,
      topTileIcon: 'error',
      defaultQuickLink: true,
      availableInDemo: true
    },

    [LinkTarget.MESSAGES__TICKETS__NEW]: {
      to: '/tickets/new',
      textI18nKey: 'tickets.createNew',
      visibleWhen: routeAccessGroups.INCIDENT_TICKETS_NEW,
      id: 'link-to-new-trouble-ticket',
      isAvailable: true,
      isQuickLink: true,
      external: false,
      hiddenFromTopNavigation: true,
      trackForDashboard: dashboardLinks.messageCreateTicket,
      availableInDemo: true
    },

    [LinkTarget.MESSAGES__MESSAGE_ARCHIVE]: {
      to: '/message-archive',
      textI18nKey: 'navigationalLink.title.serviceRequestsArchive',
      visibleWhen: routeAccessGroups.MESSAGE_ARCHIVE,
      id: 'link-to-message-archive',
      isAvailable: hasFeature('DEO-3122'),
      isQuickLink: true,
      external: false,
      trackForDashboard: dashboardLinks.messageArchive,
      trackForTopNavigation: topNavigationLinks.messageArchive,
      availableInDemo: true
    },

    [LinkTarget.MESSAGES__REQUEST_QUOTE]: {
      to: getEnvironmentVariable('REACT_APP_LINK_TO_REQUEST_QUOTATION'),
      textI18nKey: 'navigationalLink.title.requestForQuote',
      visibleWhen: routeAccessGroups.REQUEST_FOR_QUOTATION,
      id: 'link-to-request-for-quotation',
      isAvailable: true,
      isQuickLink: false,
      external: true,
      trackForDashboard: dashboardLinks.requestForQuotation,
      trackForTopNavigation: topNavigationLinks.requestForQuotation
    },

    [LinkTarget.MESSAGES__CUSTOMER_SPECIFIC]: {
      to: linkToB2BPortal(
        'messageCenter?show=customer-specific-messages',
        DashboardSectionId.Communication
      ),
      onClick: () => clearNewCustomerSpecificMessagesNotification({ skipRedirect: true }),
      textI18nKey: 'navigationalLink.title.customerSpecificMessages',
      visibleWhen: routeAccessGroups.CUSTOMER_SPECIFIC_MESSAGES,
      id: 'link-to-customer-specific-messages',
      isAvailable: true,
      isQuickLink: true,
      external: true,
      trackForDashboard: dashboardLinks.customerSpecificMessages,
      trackForTopNavigation: topNavigationLinks.customerSpecificMessages
    },

    [LinkTarget.MESSAGES__ACTIVITY_LOG]: {
      to: '/activity-log',
      textI18nKey: 'navigationalLink.title.activityLog',
      visibleWhen: routeAccessGroups.ACTIVITY_LOG,
      id: 'link-to-activity-log',
      isAvailable: true,
      isQuickLink: true,
      external: false,
      trackForDashboard: dashboardLinks.activityLog,
      trackForTopNavigation: topNavigationLinks.activityLog,
      availableInDemo: true
    },

    [LinkTarget.ACCOUNT__COMPANY_INFO]: {
      to: linkToULM(`/company/${user.activeGroupId}`, DashboardSectionId.TeliaAccountManagement),
      textI18nKey: 'navigationalLink.title.companyInfo',
      visibleWhen: routeAccessGroups.UNRESTRICTED,
      id: 'link-to-company-info',
      isAvailable: true,
      isQuickLink: true,
      external: true,
      trackForDashboard: dashboardLinks.accountCompanyInfo,
      defaultQuickLink: true
    },

    [LinkTarget.ACCOUNT__COMPANY_USERS]: {
      to: linkToULM(
        `/company/${user.activeGroupId}/users`,
        DashboardSectionId.TeliaAccountManagement
      ),
      textI18nKey: 'navigationalLink.title.companyUsersAndRoles',
      visibleWhen: routeAccessGroups.ADMIN,
      id: 'link-to-company-users',
      isAvailable: true,
      isQuickLink: true,
      external: true,
      trackForDashboard: dashboardLinks.accountCompanyUsers,
      defaultQuickLink: true
    },

    [LinkTarget.ACCOUNT__SERVICE_ORDER_PERMISSIONS]: {
      to: linkToB2BPortal('admin/orderPermission', DashboardSectionId.TeliaAccountManagement),
      textI18nKey: 'navigationalLink.title.serviceOrderPermissions',
      visibleWhen: routeAccessGroups.B2B_QUICKLINKS_ADMIN,
      id: 'link-to-order-permissions',
      isAvailable: true,
      isQuickLink: true,
      external: true,
      trackForDashboard: dashboardLinks.accountServiceOrderPermissions
    },

    [LinkTarget.B2B_PORTAL]: {
      to: linkToB2BPortal('/', DashboardSectionId.OldCorporatePortal),
      textI18nKey: 'navigationalLink.title.oldCorporatePortal',
      visibleWhen: routeAccessGroups.B2B_PORTAL,
      id: 'link-to-old-corporate-portal',
      isAvailable: true,
      isQuickLink: true,
      external: true,
      trackForDashboard: dashboardLinks.b2bPortal,
      defaultQuickLink: true
    },

    [LinkTarget.MISC__DASHBOARD]: {
      to: '/',
      id: 'link-to-dashboard',
      textI18nKey: 'navigationalLink.title.dashboard',
      visibleWhen: routeAccessGroups.UNRESTRICTED,
      isAvailable: true,
      isQuickLink: false,
      external: false,
      trackForDashboard: dashboardLinks.untracked,
      availableInDemo: true
    },

    [LinkTarget.MISC__B2C_INVOICES]: {
      to: getEnvironmentVariable('REACT_APP_LINK_TO_B2C_INVOICES'),
      id: 'link-to-b2c-invoices',
      textI18nKey: 'navigationalLink.title.b2cPortal',
      visibleWhen: routeAccessGroups.UNRESTRICTED,
      isAvailable: true,
      isQuickLink: false,
      external: true,
      trackForDashboard: dashboardLinks.untracked
    },

    [LinkTarget.MISC__TIN]: {
      to: getEnvironmentVariable('REACT_APP_LINK_TO_TIN_MY_NEBULA'),
      id: 'link-to-tin',
      textI18nKey: 'navigationalLink.title.tinPortal',
      visibleWhen: routeAccessGroups.UNRESTRICTED,
      isAvailable: true,
      isQuickLink: false,
      external: true,
      trackForDashboard: dashboardLinks.untracked
    },

    [LinkTarget.WHOLESALE_SERVICES__ORDER_AND_DELIVERY_SYSTEM]: {
      to: getEnvironmentVariable('REACT_APP_SALESPORTAL_URL'),
      textI18nKey: 'navigationalLink.title.wholesaleOrderAndDeliverySystem',
      visibleWhen: routeAccessGroups.SALESPORTAL,
      id: 'link-to-b2o-order-and-delivery-system',
      isAvailable: !shouldUseNewWholesaleLinks,
      isQuickLink: true,
      external: true,
      trackForDashboard: dashboardLinks.wholesaleOrderAndDeliverySystem,
      trackForTopNavigation: topNavigationLinks.wholesaleOrderAndDeliverySystem,
      isB2OLink: true
    },

    [LinkTarget.WHOLESALE_SERVICES__EVENTS]: {
      to: getEnvironmentVariable('REACT_APP_LINK_TO_B2O_EVENTS'),
      textI18nKey: 'navigationalLink.title.wholesaleEvents',
      visibleWhen: routeAccessGroups.B2O_EVENTS,
      id: 'link-to-b2o-events',
      isAvailable: shouldUseNewWholesaleLinks,
      isQuickLink: true,
      external: true,
      openExternalLinkInSameTab: true,
      trackForDashboard: dashboardLinks.wholesaleEvents,
      trackForTopNavigation: topNavigationLinks.wholesaleEvents,
      isB2OLink: true,
      defaultQuickLink: user.activeGroupType === 'b2b_sales_portal'
    },

    [LinkTarget.WHOLESALE_SERVICES__REQUEST_FOR_QUOTE]: {
      to: getEnvironmentVariable('REACT_APP_LINK_TO_B2O_REQUEST_FOR_QUOTE'),
      textI18nKey: 'navigationalLink.title.wholesaleRfq',
      visibleWhen: routeAccessGroups.B2O_REQUEST_FOR_QUOTE,
      id: 'link-to-b2o-request-for-quote',
      isAvailable: shouldUseNewWholesaleLinks,
      isQuickLink: true,
      external: true,
      openExternalLinkInSameTab: true,
      trackForDashboard: dashboardLinks.wholesaleRequestForQuote,
      trackForTopNavigation: topNavigationLinks.wholesaleRequestForQuote,
      isB2OLink: true,
      defaultQuickLink: user.activeGroupType === 'b2b_sales_portal'
    },

    [LinkTarget.WHOLESALE_SERVICES__ORDER]: {
      to: getEnvironmentVariable('REACT_APP_LINK_TO_B2O_ORDER'),
      textI18nKey: 'navigationalLink.title.wholesaleOrder',
      visibleWhen: routeAccessGroups.B2O_ORDERS_ORDER,
      id: 'link-to-b2o-order',
      isAvailable: shouldUseNewWholesaleLinks,
      isQuickLink: true,
      external: true,
      openExternalLinkInSameTab: true,
      trackForDashboard: dashboardLinks.wholesaleOrder,
      trackForTopNavigation: topNavigationLinks.wholesaleOrder,
      isB2OLink: true,
      defaultQuickLink: user.activeGroupType === 'b2b_sales_portal'
    },

    [LinkTarget.WHOLESALE_SERVICES__ORDER_FOR_CHANGE]: {
      to: getEnvironmentVariable('REACT_APP_LINK_TO_B2O_ORDER_FOR_CHANGE'),
      textI18nKey: 'navigationalLink.title.wholesaleOrderForChange',
      visibleWhen: routeAccessGroups.B2O_ORDERS_ORDER,
      id: 'link-to-b2o-order-for-change',
      isAvailable: shouldUseNewWholesaleLinks,
      isQuickLink: true,
      external: true,
      openExternalLinkInSameTab: true,
      trackForDashboard: dashboardLinks.wholesaleOrderForChange,
      trackForTopNavigation: topNavigationLinks.wholesaleOrderForChange,
      isB2OLink: true,
      defaultQuickLink: user.activeGroupType === 'b2b_sales_portal'
    },

    [LinkTarget.WHOLESALE_SERVICES__ORDER_FOR_TERMINATION]: {
      to: getEnvironmentVariable('REACT_APP_LINK_TO_B2O_ORDER_FOR_TERMINATION'),
      textI18nKey: 'navigationalLink.title.wholesaleOrderForTermination',
      visibleWhen: routeAccessGroups.B2O_ORDERS_ORDER,
      id: 'link-to-b2o-order-for-termination',
      isAvailable: shouldUseNewWholesaleLinks,
      isQuickLink: true,
      external: true,
      openExternalLinkInSameTab: true,
      trackForDashboard: dashboardLinks.wholesaleOrderForTermination,
      trackForTopNavigation: topNavigationLinks.wholesaleOrderForTermination,
      isB2OLink: true,
      defaultQuickLink: user.activeGroupType === 'b2b_sales_portal'
    },

    [LinkTarget.WHOLESALE_SERVICES__YRITYSNETTI_ORDERS]: {
      to: '/orders',
      textI18nKey: 'navigationalLink.title.wholesaleYritysnettiOrders',
      visibleWhen: routeAccessGroups.B2O_ORDERS,
      id: 'link-to-b2o-yritysnetti-orders',
      isAvailable: true,
      isQuickLink: true,
      external: false,
      trackForDashboard: dashboardLinks.wholesaleYritysnettiOrders,
      trackForTopNavigation: topNavigationLinks.wholesaleYritysnettiOrders,
      isB2OLink: true,
      availableInDemo: true
    },

    [LinkTarget.WHOLESALE_SERVICES__PRODUCT_OFFERING]: {
      to: t('topBar.wholesale.url'),
      textI18nKey: 'navigationalLink.title.wholesaleOffering',
      visibleWhen: routeAccessGroups.B2O_PAGES,
      id: 'link-to-b2o-offering',
      isAvailable: true,
      isQuickLink: true,
      external: true,
      trackForDashboard: dashboardLinks.wholesaleOffering,
      trackForTopNavigation: topNavigationLinks.wholesaleOffering,
      hiddenFromTopNavigation: true,
      isB2OLink: true,
      availableInDemo: true
    }
  };

  const getProdDemoEnvironmentLinks = (): NavigationalLinks => {
    return Array.from(
      Object.entries(navigationalLinks) as unknown as [LinkTarget, NavigationalLink][]
    ).reduce((acc, [key, value]) => {
      acc[key] = {
        ...value,
        disabled: !value.availableInDemo || value.linksToB2B
      };
      return acc;
    }, {} as NavigationalLinks);
  };

  return {
    navigationalLinks: isDemoEnvironment() ? getProdDemoEnvironmentLinks() : navigationalLinks
  };
};
