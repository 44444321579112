import { multiplex } from 'doings/multiplex/multiplex';
import { useEffect, useState } from 'react';

import { RelatedRecord } from './useServiceRequestRelatedRecord';

type CreateServiceRequestStep =
  | 'initial'
  | 'loading-data'
  | 'creating-service-request'
  | 'confirmed'
  | 'error-loading-asset'
  | 'error-loading-asset-for-corporate-product'
  | 'error-creating-request';

export type CreateServiceRequestSavingStatus = 'no' | 'yes' | 'failure';

export default ({
  loadingProducts = false,
  relatedRecordType,
  loadingRecord = false,
  creatingServiceRequest = false,
  hasErrorCreatingServiceRequest = false,
  hasErrorLoadingAsset = false,
  created = false,
  initialStep = 'loading-data'
}: {
  loadingProducts?: boolean;
  relatedRecordType?: RelatedRecord['type'];
  loadingRecord?: boolean;
  creatingServiceRequest?: boolean;
  hasErrorCreatingServiceRequest?: boolean;
  hasErrorLoadingAsset?: boolean;
  created?: boolean;
  initialStep?: CreateServiceRequestStep;
}) => {
  const [step, setStep] = useState<CreateServiceRequestStep>(initialStep);
  const resolvedStep: CreateServiceRequestStep = multiplex([
    'initial',
    [creatingServiceRequest, 'creating-service-request'],
    [loadingRecord || loadingProducts, 'loading-data'],
    [hasErrorCreatingServiceRequest, 'error-creating-request'],
    [hasErrorLoadingAsset, getAssetLoadingErrorStep(relatedRecordType)],
    [created, 'confirmed']
  ]);

  useEffect(() => {
    setStep(resolvedStep);
  }, [resolvedStep]);

  const savingStatus: CreateServiceRequestSavingStatus = multiplex([
    'no',
    [step === 'creating-service-request', 'yes'],
    [step === 'error-creating-request', 'failure']
  ]);

  return {
    step,
    goToInitialStep: () => setStep(initialStep),
    savingStatus
  };
};

const getAssetLoadingErrorStep = (relatedRecordType?: RelatedRecord['type']) => {
  return relatedRecordType === 'corporate-product'
    ? ('error-loading-asset-for-corporate-product' as const)
    : ('error-loading-asset' as const);
};
