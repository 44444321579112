import { Div, Flex, Gutter } from '@gaiads/telia-react-component-library';
import { Skeleton } from 'common-components';
import usePileVerticallyToMany from 'hooks/usePileVerticallyToMany/usePileVerticallyToMany';

const PukCodeDetailsSkeleton: React.FC = () => {
  const pileVerticallyTo = usePileVerticallyToMany(['xxxs', 'xxs', 'md']);

  return (
    <Div data-testid="puk-code-details-skeleton">
      <Skeleton.Title />

      <Gutter size="md" />

      <Flex pileVerticallyTo={pileVerticallyTo}>
        <Skeleton.Title />

        <Gutter size="sm" />

        <Skeleton.Title />
      </Flex>

      <Gutter size="md" />

      <Skeleton.Title />

      <Gutter size="sm" />
    </Div>
  );
};

export default PukCodeDetailsSkeleton;
