import { gql } from '@apollo/client';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';

const subscriptionTypesQuery = gql`
  query GetMobileSubscriptionTypes {
    subscriptionTypes {
      data
    }
  }
`;

type SubscriptionTypesQuery = {
  subscriptionTypes: {
    data: string[];
  };
};

export default () => {
  const { data } = useCallBackend<SubscriptionTypesQuery>({
    query: subscriptionTypesQuery
  });

  return data?.subscriptionTypes.data ?? [];
};
