import { Flex } from '@gaiads/telia-react-component-library';

import styles from './ActionButtonGroup.module.scss';

type PotentialButton = JSX.Element | false;

/**
 * Renders action buttons horizontally, side-by-side in desktop view,
 * and piled vertically in mobile view. Supports either one, two or three
 * buttons.
 */
const ActionButtonGroup: React.FC<{
  children:
    | PotentialButton
    | [PotentialButton, PotentialButton]
    | [PotentialButton, PotentialButton, PotentialButton];
  'data-testid'?: string;
}> = ({ children, 'data-testid': testId }) => {
  const arr: PotentialButton[] = Array.isArray(children)
    ? (children as PotentialButton[])
    : [children];
  return (
    <Flex
      centeredVertically
      pileVerticallyTo="xxs"
      data-testid={testId}
      occupyVerticalSpace={false}
    >
      {arr.filter(Boolean).map((button, index) => (
        <div className={styles.button} key={`button-${index}`}>
          {button}
        </div>
      ))}
    </Flex>
  );
};

export default ActionButtonGroup;
