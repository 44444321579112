import { multiplex } from 'doings/multiplex/multiplex';
import { SubscriptionStatus } from 'hooks/mobileSubscriptions/useSubscriptionDetails/subscriptionDetailsQuery';
import { useEffect, useState } from 'react';

export type GenerateChangeTokenStep =
  | 'token-already-exists'
  | 'token-generation-unavailable'
  | 'loading-subscription-details'
  | 'error-loading-subscription'
  | 'ready-to-generate-token'
  | 'generating-token'
  | 'token-generated'
  | 'error-generating-token';

const useGenerateOwnershipChangeTokenSteps = ({
  loadingSubscription = false,
  subscriptionStatus,
  hasErrorLoadingSubscription = false,
  hasExistingToken = false,
  generatingToken = false,
  hasErrorGeneratingToken = false,
  generated = false,
  hasDuettoBundle = false,
  initialStep = 'loading-subscription-details'
}: {
  loadingSubscription?: boolean;
  subscriptionStatus?: SubscriptionStatus;
  hasErrorLoadingSubscription?: boolean;
  hasExistingToken?: boolean;
  generatingToken?: boolean;
  hasErrorGeneratingToken?: boolean;
  generated?: boolean;
  hasDuettoBundle?: boolean;
  initialStep?: GenerateChangeTokenStep;
}) => {
  const [step, setStep] = useState<GenerateChangeTokenStep>(initialStep);

  useEffect(() => {
    const step: GenerateChangeTokenStep = multiplex([
      'ready-to-generate-token',
      [!generated && hasExistingToken, 'token-already-exists'],
      [loadingSubscription, 'loading-subscription-details'],
      [
        ['PREOPENED', 'SUSPENDED', 'DISABLED'].includes(subscriptionStatus ?? ''),
        'token-generation-unavailable'
      ],
      [hasDuettoBundle, 'token-generation-unavailable'],
      [hasErrorLoadingSubscription, 'error-loading-subscription'],
      [generatingToken, 'generating-token'],
      [generated, 'token-generated'],
      [hasErrorGeneratingToken, 'error-generating-token']
    ]);

    setStep(step);
  }, [
    hasExistingToken,
    loadingSubscription,
    subscriptionStatus,
    generatingToken,
    hasErrorGeneratingToken,
    hasErrorLoadingSubscription,
    generated,
    hasDuettoBundle
  ]);

  return {
    step
  };
};

export default useGenerateOwnershipChangeTokenSteps;
