import { subMonths } from 'date-fns';

const DEFAULT_DATE_MONTHS_BEFORE = 6;

export const getDefaultDateRange = (monthsBefore = DEFAULT_DATE_MONTHS_BEFORE) => {
  const today = new Date();
  const defaultDateFromPlaceholder = subMonths(today, monthsBefore);
  const defaultDateToPlaceholder = today;

  return { defaultDateFromPlaceholder, defaultDateToPlaceholder };
};
