import getRelatedCompaniesForDropdown from 'doings/getRelatedCompaniesForDropdown/getRelatedCompaniesForDropdown';
import useDropdownInputModel from 'hooks/inputModels/useDropdownInputModel/useDropdownInputModel';

export default (companySelectionAvailable: boolean) => {
  const { activeGroupId, availableCompanies } = getRelatedCompaniesForDropdown({
    onePermission: 'BILLING'
  });

  return {
    relatedCompany: useDropdownInputModel({
      options: companySelectionAvailable ? availableCompanies : [],
      initialValue: activeGroupId,
      required: companySelectionAvailable,
      excludeNoneSelected: true,
      disabled: !companySelectionAvailable || availableCompanies.length <= 1
    })
  };
};
