import { scrollElementIntoView } from 'doings/scroll/scrollElementIntoView';
import useWriteQueryParams, {
  UseWriteQueryParamsProps
} from 'hooks/useQueryParams/useWriteQueryParams';
import { useEffect, useState } from 'react';

const mapToEmptiedQueryParams = (queryParamsToClear: string[]): UseWriteQueryParamsProps =>
  queryParamsToClear.reduce((acc, value) => {
    acc[value] = undefined;
    return acc;
  }, {} as UseWriteQueryParamsProps);

/**
 * Scrolls an element into view by its current reference object.
 *
 * Can be provided query parameters to clear upon scroll, so if
 * a user navigates between a page containing the scrolled element
 * (like an accordion on the mobile subscription details' page),
 * and another application like B2B Portal which is passed a query
 * parameter to govern the scroll effect, then the scroll would not
 * be re-applied erroneously when the user returns to the page from
 * such the other application.
 */
export default <T extends HTMLElement>({
  elementRef,
  shouldScroll = false,
  queryParamsToClear = [],
  showHighlight = false
}: {
  elementRef: React.RefObject<T>;
  shouldScroll?: boolean;
  queryParamsToClear?: string[];
  showHighlight?: boolean;
}) => {
  const [hasScrolled, setHasScrolled] = useState<boolean>(false);
  const { write } = useWriteQueryParams();

  useEffect(() => {
    if (!shouldScroll || hasScrolled) {
      return;
    }

    if (scrollElementIntoView({ elementRef, showHighlight })) {
      setHasScrolled(true);
      write(mapToEmptiedQueryParams(queryParamsToClear));
    }
  }, [
    elementRef,
    shouldScroll,
    hasScrolled,
    setHasScrolled,
    write,
    queryParamsToClear,
    showHighlight
  ]);
};
