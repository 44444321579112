import { useUserData } from 'contexts/UserContext/UserContext';
import { TextInputModel } from 'hooks/inputModels/useTextInputModel/useTextInputModel';
import useValidatableTextInputModel from 'hooks/inputModels/useValidatableTextInputModel/useValidatableTextInputModel';

export type BillingGroupFormContactFields = {
  contactFirstName: TextInputModel;
  contactLastName: TextInputModel;
  contactEmail: TextInputModel;
  contactPhone: TextInputModel;
};

export default () => {
  const {
    email: contactEmail,
    firstName: contactFirstName,
    lastName: contactLastName,
    businessPhoneNumber: contactPhone
  } = useUserData();

  return {
    contactFirstName: useValidatableTextInputModel({
      initialValue: contactFirstName,
      required: true,
      expecting: {
        maxLength: 100
      }
    }),
    contactLastName: useValidatableTextInputModel({
      initialValue: contactLastName,
      required: true,
      expecting: {
        maxLength: 100
      }
    }),
    contactEmail: useValidatableTextInputModel({
      initialValue: contactEmail,
      required: true,
      expecting: {
        a: 'emailAddress',
        maxLength: 100
      }
    }),
    contactPhone: useValidatableTextInputModel({
      initialValue: contactPhone,
      required: true,
      expecting: {
        a: 'msisdn',
        maxLength: 25
      }
    })
  };
};
