import getClassNames from 'classnames';
import { Div } from 'core-components';
import React from 'react';

import styles from './DetailsPageNotices.module.scss';

const availableClassNames = getClassNames([styles.notification, styles.notification_visible]);
const unavailableClassNames = getClassNames([styles.notification, styles.notification_hidden]);

function childrenExist(children: React.ReactNode) {
  return React.Children.toArray(children).filter((element) => !!element).length > 0;
}

/**
 * Wraps multiple inline notifications in wrappers which hide its contents
 * in the lack of any notifications, and slides one in when at least
 * one is present.
 */
export const Notices: React.FC<{
  'data-testid': string;
  children: React.ReactNode | React.ReactNode[];
}> = ({ 'data-testid': dataTestid, children: notices }) => {
  const noticesArray = Array.isArray(notices) ? notices : [notices];
  return (
    <Div data-testid={dataTestid}>
      {noticesArray.map((notice, index) => (
        <NoticeWrapper key={`${dataTestid}-notice-${index}`} data-testid={dataTestid}>
          {notice && <>{notice}</>}
        </NoticeWrapper>
      ))}
    </Div>
  );
};

/**
 * Wraps an inline notification in a wrapper which hides its contents
 * in the lack of any notifications, and slides one in when at least
 * one is present.
 *
 * The wrapper must be present in DOM before its contents are rendered
 * for the sliding effect to kick in.
 */
const NoticeWrapper: React.FC<{ children: React.ReactNode; 'data-testid': string }> = ({
  children,
  'data-testid': testId
}) => {
  return (
    <Div
      className={childrenExist(children) ? availableClassNames : unavailableClassNames}
      data-testid={`${testId}-notice-wrapper`}
    >
      {children}
    </Div>
  );
};
