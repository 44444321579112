import { gql } from '@apollo/client';
import { multiplex } from 'doings/multiplex/multiplex';
import trackEvent from 'doings/track/trackEvent';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { Dispatch, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ApiStatus } from 'types/api';
import * as Http from 'types/http';

import {
  ReplayAction,
  ReplayActionType,
  useReplayHttpRequestReducer
} from './useReplayHttpRequestReducer';

interface ReplayEntry {
  replay: {
    request: ReplayableRequest;
  };
}

interface ReplayableRequest {
  method: 'GET' | 'HEAD' | 'POST';
  url: string;
  headers: { name: string; value: string }[];
  body: string;
}

const replayQuery = gql`
  query GetReplay {
    replay {
      request {
        method
        url
        headers {
          name
          value
        }
        body
      }
    }
  }
`;

export const useReplayHttpRequest = () => {
  const { replace } = useHistory();
  const query = useCallBackend<ReplayEntry>({
    query: replayQuery,
    fetchPolicy: 'no-cache'
  });

  const replayEntry = query.data?.replay;
  const queryStatus = multiplex([
    ApiStatus.Loading,
    [query.loading, ApiStatus.Loading],
    [!!query.error || replayEntry === null, ApiStatus.Error],
    [query.loaded && !!replayEntry, ApiStatus.Loaded]
  ]);

  const [state, dispatch] = useReplayHttpRequestReducer();

  useEffect(() => {
    if (state.status === 'idle') {
      dispatch({ type: ReplayActionType.RETRIEVE_REPLAY });
    } else if (state.status === 'retrievingReplay' && queryStatus === ApiStatus.Error) {
      dispatch({ type: ReplayActionType.RETRIEVE_REPLAY_FAILED });
    } else if (state.status === 'retrievingReplay' && queryStatus === ApiStatus.Loaded) {
      dispatch({ type: ReplayActionType.RETRIEVE_REPLAY_DONE });
    } else if (state.status === 'replayPending' && replayEntry?.request) {
      replay(replayEntry.request, dispatch);
      dispatch({ type: ReplayActionType.REPLAYING });
    } else if (state.status === 'replayed') {
      replace(state.redirectTo);
    }
  }, [dispatch, queryStatus, replace, replayEntry, state]);

  useEffect(() => {
    if (state.track) {
      trackEvent(state.track);
    }
  }, [state.track]);

  return { status: state.status, error: state.error };
};

const replay = (request: ReplayableRequest, dispatch: Dispatch<ReplayAction>) => {
  window
    .fetch(request.url, marshallReplayRequest(request))
    .then(async (response) => {
      if (response.status === Http.CustomResponse.StatusCode.Replayed) {
        const body = await response.json();
        if (body.redirectTo) {
          dispatch({ type: ReplayActionType.REPLAYING_DONE, redirectTo: body.redirectTo });
          return;
        }
      }

      dispatch({ type: ReplayActionType.REPLAYING_FAILED });
    })
    .catch(async () => {
      dispatch({ type: ReplayActionType.REPLAYING_FAILED });
    });
};

const marshallReplayRequest = (request: ReplayableRequest): RequestInit => ({
  method: request.method,
  headers: request.headers.reduce((acc, { name, value }) => {
    acc[name] = value;
    return acc;
  }, {} as Record<string, string>),
  body: request.body ? request.body : undefined,
  referrer: request.headers.find(({ name }) => name === Http.Header.referrer)?.value
});
