import { Input } from '@gaiads/telia-react-component-library';
import { TextInputModel } from 'hooks/inputModels/useTextInputModel/useTextInputModel';
import { useGenerateId } from 'hooks/useGenerateId/useGenerateId';
import { EventHandlers } from 'types/eventHandler';
import { TEXT_FIELD_MAX_LENGTH } from 'types/form';

const ModelTextInput: React.FC<{
  label: string;
  name?: string;
  inputModel: TextInputModel;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  hardMaxLength?: boolean;
  type?: 'text' | 'email' | 'tel';
  className?: string;
  onFocus?: EventHandlers.Focus;
  onBlur?: EventHandlers.Focus;
  'data-testid'?: string;
}> = ({
  label,
  name,
  inputModel,
  placeholder,
  disabled,
  readOnly,
  hardMaxLength,
  type = 'text',
  className,
  onFocus,
  onBlur,
  'data-testid': testId
}) => {
  const { generatedId } = useGenerateId('input');

  return (
    <Input
      className={className}
      data-testid={testId}
      id={generatedId}
      label={label}
      aria-label={label}
      name={name}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        inputModel.setValue(event.target.value)
      }
      onFocus={onFocus}
      onBlur={onBlur}
      type={type}
      maxLength={hardMaxLength ? inputModel.maxLength : TEXT_FIELD_MAX_LENGTH}
      value={inputModel.value}
      required={inputModel.required}
      data-required={inputModel.required}
      confirmed={inputModel.showConfirmation && inputModel.isValid}
      errorText={inputModel.errorMessage}
      placeholder={placeholder}
      disabled={disabled}
      readOnly={readOnly}
    />
  );
};

export default ModelTextInput;
