import { gql } from '@apollo/client';
import { useUserData } from 'contexts/UserContext/UserContext';
import useCallBackendMutation from 'hooks/useCallBackend/useCallBackendMutation';

import { productNewsQuery } from './useProductNews';

const mutation = gql`
  mutation updateProductNewsFeedback($input: UpdateProductNewsFeedbackInput!) {
    updateProductNewsFeedback(input: $input)
  }
`;

interface UpdateFeedbackInput {
  id: string;
  feedback: string;
}

const useProductNewsFeedback = () => {
  const user = useUserData();
  const { runMutation, error, loading } = useCallBackendMutation(mutation, [
    { query: productNewsQuery, variables: { lang: user.language } }
  ]);

  const sendFeedback = async ({ id, feedback }: UpdateFeedbackInput) => {
    await runMutation({ variables: { input: { id, feedback } } });
  };

  return { sendFeedback, loading, error };
};

export { useProductNewsFeedback };
