import { Flex, Panel } from '@gaiads/telia-react-component-library';
import styles from 'B2XApp/Dashboard/Dashboard.module.scss';
import {
  EditableLinksModal,
  TOP_TILES_MODAL
} from 'B2XApp/Modals/EditableLinksModal/EditableLinksModal';
import { IconButton, TileButton, TileButtonGrid } from 'common-components';
import { useUserData } from 'contexts/UserContext/UserContext';
import getNavigationalLinks, {
  NavigationalLink,
  NavigationalLinks
} from 'doings/getNavigationalLinks/getNavigationalLinks';
import { dashboardLinks } from 'doings/track/analyticsEvents';
import trackEvent from 'doings/track/trackEvent';
import userHasPermission from 'doings/userHasPermission/userHasPermission';
import useEditableSectionItems, {
  SectionItem
} from 'hooks/editableSections/useEditableSectionItems';
import useCustomModal from 'hooks/useCustomModal/useCustomModal';
import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const DISPLAYABLE_TILES = 4;

export const DashboardTopTileNavigation: React.FC = () => {
  const { t } = useTranslation();
  const user = useUserData();
  const { items, loading } = useEditableSectionItems('top-tiles');
  const { navigationalLinks } = getNavigationalLinks(user);

  const { displayedTiles, tiles, isEnabled, isNew } = useMemo(
    () => determineDisplayedTopTiles({ navigationalLinks, items, user, t }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );

  const topTilesModal = useCustomModal(TOP_TILES_MODAL);
  const openModal = () => {
    trackEvent(dashboardLinks.customizeTopTiles);
    topTilesModal.openModal();
  };

  return (
    <Panel margin={{ bottom: 'md' }} data-tour-stop="dashboard-tiles">
      <Panel.Content padding={{ top: 'zero', bottom: 'sm', horizontal: 'sm' }}>
        <Flex alignRight padding={{ top: 'xs', right: 'zero' }}>
          <IconButton
            data-testid="edit-top-tiles"
            data-tour-stop="dashboard-tiles-edit-button"
            aria-label={t('aria.dashboard.showTopTilesModal')}
            className={[styles.settingsIcon, styles.settingsIcon_topTiles]}
            name="settings"
            size="md"
            onClick={openModal}
          />
        </Flex>

        <TileButtonGrid
          columns={{ desktop: 4, mobile: 2 }}
          loading={loading}
          loadingTiles={DISPLAYABLE_TILES}
        >
          {displayedTiles.map((link) => (
            <TileButton
              key={link.id}
              iconName={link.topTileIcon || 'link'}
              label={t(link.textI18nKey)}
              to={link.to}
              whenClicked={link.trackForTopTiles ?? link.trackForDashboard}
              enabledWhen={link.visibleWhen}
              data-testid={`top-navigation-tile-${link.id}`}
              onClick={link.onClick}
            />
          ))}
        </TileButtonGrid>
      </Panel.Content>

      <EditableLinksModal
        section="top-tiles"
        onCloseClick={topTilesModal.closeModal}
        isOpen={topTilesModal.isOpen}
        items={tiles.map((link) => ({
          id: link.id,
          enabled: isEnabled(link.id),
          label: t(link.textI18nKey),
          isNew: isNew(link.id)
        }))}
      />
    </Panel>
  );
};

const determineDisplayedTopTiles = ({
  navigationalLinks,
  items,
  user,
  t
}: {
  navigationalLinks: NavigationalLinks;
  items: SectionItem[];
  user: User;
  t: TFunction;
}) => {
  const allTopTiles = Object.values(navigationalLinks).filter(
    (link) => !link.isB2OLink && link.topTileIcon && link.isAvailable
  );
  const defaultTiles = allTopTiles.filter(({ defaultTopTile }) => defaultTopTile);
  const hasDefaultTileById = (id: string) => defaultTiles.some((tile) => tile.id === id);
  const hasPermission = userHasPermission(user);
  const mapTiles = (items: NavigationalLink[]) =>
    items.map(
      (item) =>
        ({
          ...item,
          disabled: !hasPermission(item.visibleWhen)
        } as NavigationalLink)
    );

  const hasCustomTiles = !!items.length;
  if (!hasCustomTiles) {
    return {
      displayedTiles: defaultTiles,
      tiles: mapTiles(allTopTiles),
      isEnabled: (id: string) => hasDefaultTileById(id),
      isNew: () => false
    };
  }

  const { enabledTiles, itemOrder } = items.reduce(
    (acc, item, index) => {
      if (item.enabled) {
        acc.enabledTiles.add(item.id);
      }
      acc.itemOrder.set(item.id, index);
      return acc;
    },
    { enabledTiles: new Set<string>(), itemOrder: new Map<string, number>() }
  );

  const isEnabled = (id: string) => enabledTiles.has(id);
  const isNew = (id: string) => !itemOrder.has(id);
  const tiles = mapTiles(allTopTiles).sort((a, b) => {
    const itemOrderA = itemOrder.get(a.id) ?? allTopTiles.length;
    const itemOrderB = itemOrder.get(b.id) ?? allTopTiles.length;
    const result = itemOrderA - itemOrderB;
    return result !== 0 ? result : t(a.textI18nKey).localeCompare(t(b.textI18nKey));
  });

  const displayedTiles = tiles.filter((link) => isEnabled(link.id));

  return {
    displayedTiles: displayedTiles.length === DISPLAYABLE_TILES ? displayedTiles : defaultTiles,
    tiles,
    isEnabled,
    isNew
  };
};
