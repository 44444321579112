import getEnvironmentVariable from 'doings/getEnvironmentVariable/getEnvironmentVariable';

const isCorporatePortalOrUlmUrl = (url: string | undefined): boolean => {
  return (
    url !== undefined &&
    (url.startsWith(getEnvironmentVariable('REACT_APP_CORPORATE_PORTAL_URL')) ||
      url.startsWith(getEnvironmentVariable('REACT_APP_ULM_URL')))
  );
};

export default isCorporatePortalOrUlmUrl;
