import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface ToggleNewsDrawerPayload {
  show: boolean;
}

export interface SetNewsDrawerCountersPayload {
  unseenCounts: { news: number; tips: number };
}

export interface NewsDrawerState {
  isOpen: boolean;
  unseenCounts: { total: number; news: number; tips: number };
}

const initialState: NewsDrawerState = {
  isOpen: false,
  unseenCounts: { total: 0, news: 0, tips: 0 }
};

export const newsDrawerSlice = createSlice({
  name: 'newsDrawer',
  initialState,
  reducers: {
    toggleNewsDrawer: (state, action: PayloadAction<ToggleNewsDrawerPayload>) => {
      state.isOpen = action.payload.show;
    },
    setNewsDrawerCounters: (state, action: PayloadAction<SetNewsDrawerCountersPayload>) => {
      const { unseenCounts } = action.payload;
      state.unseenCounts = { ...unseenCounts, total: unseenCounts.news + unseenCounts.tips };
    }
  }
});

export const { toggleNewsDrawer, setNewsDrawerCounters } = newsDrawerSlice.actions;

export default newsDrawerSlice.reducer;
