import useWriteQueryParams, {
  UseWriteQueryParamsProps
} from 'hooks/useQueryParams/useWriteQueryParams';
import { useCallback, useRef } from 'react';
import { ClearListener, ClearListeners, RegisterOnClearListenerFn } from 'types/listFilters';

export const useClearListeners = () => {
  const clearListeners = useRef<ClearListeners>({});
  const registerOnClearListener: RegisterOnClearListenerFn = useCallback(
    (param: string, callbackFn: ClearListener) => {
      clearListeners.current[param] = callbackFn;
    },
    [clearListeners]
  );

  const { write: writeQueryParams } = useWriteQueryParams();
  const clearAllFilters = useCallback(() => {
    const params: UseWriteQueryParamsProps = {};
    Object.values(clearListeners.current).forEach((doClear) => {
      const result = doClear();
      Object.entries(result).forEach(([k, v]) => (params[k] = v));
    });
    writeQueryParams(params);
  }, [clearListeners, writeQueryParams]);

  const clearFilter = useCallback(
    (filter: string) => {
      const params: UseWriteQueryParamsProps = {};
      const doClear = clearListeners.current[filter];
      if (doClear) {
        const result = doClear();
        Object.entries(result).forEach(([k, v]) => {
          params[k] = v;
        });
        writeQueryParams(params);
      }
    },
    [clearListeners, writeQueryParams]
  );

  return { registerOnClearListener, clearAllFilters, clearFilter };
};
