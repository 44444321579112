import { noOp } from 'doings/noOp/noOp';
import { createContext, useContext, useState } from 'react';

type CSRFToken = string | null;

export type SetCSRFTokenFunction = (string: CSRFToken) => void;

const CSRFTokenContext = createContext<{
  csrfToken: CSRFToken;
  setCSRFToken: SetCSRFTokenFunction;
}>({ csrfToken: null, setCSRFToken: noOp });

export const CSRFTokenProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [csrfToken, setCSRFToken] = useState<CSRFToken>(null);

  return (
    <CSRFTokenContext.Provider
      value={{
        setCSRFToken,
        csrfToken
      }}
    >
      {children}
    </CSRFTokenContext.Provider>
  );
};

export const useCSRFTokenContext = () => useContext(CSRFTokenContext);
