import { ApolloError, gql } from '@apollo/client';
import { multiplex } from 'doings/multiplex/multiplex';
import { billingGroupsQuery } from 'hooks/invoicing/billingGroups/useBillingGroups/useBillingGroups';
import { collectionGroupQuery } from 'hooks/invoicing/billingGroups/useCollectionGroup/useCollectionGroup';
import useCallBackendMutation from 'hooks/useCallBackend/useCallBackendMutation';
import { CollectionGroupApiStatus, CollectionGroupHandlingType } from 'types/collection-group';

export const updateCollectionGroupMutation = gql`
  mutation UpdateBillingAccount(
    $collectionGroupId: String!
    $requestData: InputCollectionGroupUpdateRequest!
  ) {
    updateCollectionGroup(collectionGroupId: $collectionGroupId, requestData: $requestData) {
      handlingType
    }
  }
`;

const isAutomaticHandling = (updateCollectionGroup: UpdateCollectionGroupResult) =>
  updateCollectionGroup.handlingType === CollectionGroupHandlingType.Automatic;

const mapApiStatus = (
  loading: boolean,
  data?: { updateCollectionGroup: UpdateCollectionGroupResult },
  error?: ApolloError
): CollectionGroupApiStatus => {
  return multiplex([
    'initial',
    [!!loading, 'persisting'],
    [!!error, 'failure'],
    [!!data && isAutomaticHandling(data.updateCollectionGroup), 'success'],
    [!!data && !isAutomaticHandling(data.updateCollectionGroup), 'partial-success']
  ]);
};

interface UpdateCollectionGroupResult {
  handlingType: CollectionGroupHandlingType;
}

interface UseUpdateCollectionGroupResult {
  updateCollectionGroup: UpdateCollectionGroupResult;
}

export default (collectionGroupId: string) => {
  const { runMutation, loading, data, error } =
    useCallBackendMutation<UseUpdateCollectionGroupResult>(updateCollectionGroupMutation, [
      {
        query: billingGroupsQuery,
        variables: {
          requestParams: {}
        }
      },
      {
        query: collectionGroupQuery,
        variables: { collectionGroup: collectionGroupId }
      }
    ]);

  return {
    updateCollectionGroup: runMutation,
    updateCollectionGroupApiStatus: mapApiStatus(loading, data, error)
  };
};
