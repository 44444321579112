import { InlineNotification, InlineNotificationType } from 'common-components';
import { Div } from 'core-components';
import { PaymentStatus } from 'types/invoice';

const paymentStatuses: Record<PaymentStatus, InlineNotificationType> = {
  PAID: 'success',
  PAID_NO_UPDATE: 'success',
  FAILED: 'warning',
  INITIAL: 'information',
  CANCELLED: 'information'
};

const InvoicePaymentStatusNotification: React.FC<{ paymentStatus?: PaymentStatus }> = ({
  paymentStatus
}) => {
  if (!paymentStatus) {
    return null;
  }

  return (
    <Div margin={{ bottom: 'md' }}>
      <InlineNotification
        variant={paymentStatuses[paymentStatus]}
        content={{ key: `invoice.payment.notification.${paymentStatus}` }}
        data-testid={`invoice-${paymentStatus}-notification`}
      />
    </Div>
  );
};

export default InvoicePaymentStatusNotification;
