import { Checkbox, Div } from '@gaiads/telia-react-component-library';
import { FieldErrorText } from '@teliafi/fi-ds';
import getClassNames from 'classnames';
import { SmartTooltip, TextWithInlineHtml } from 'common-components';
import { CheckgroupFieldProps } from 'common-components/Forms/FormField/FormField';
import { useField, useFormikContext } from 'formik';
import { useMemo } from 'react';

const FormFieldCheckgroup = ({
  name,
  label,
  helperText,
  required,
  disabled,
  options,
  onChange,
  testId
}: CheckgroupFieldProps) => {
  const [field, meta] = useField<string[]>(name);
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const fieldValue = field.value ?? [];

  return useMemo(
    () => (
      <Div margin={{ vertical: 'sm' }} data-testid={testId} data-name={field.name}>
        <AccessibleLabel
          label={label}
          required={required}
          tooltip={helperText}
          errorText={meta.touched ? meta.error : undefined}
        />

        {options.map((o, index) => (
          <Checkbox
            key={o.id}
            margin={{ top: 'sm' }}
            data-testid={`${testId}-checkbox${index + 1}`}
            checked={fieldValue.includes(o.id)}
            name={field.name}
            label={<TextWithInlineHtml text={o.label} />}
            disabled={disabled}
            error={meta.touched && !!meta.error}
            onBlur={field.onBlur}
            onChange={(_event, checked: boolean) => {
              const newValue = Array.from(fieldValue);
              if (checked) {
                newValue.push(o.id);
              } else {
                newValue.splice(newValue.indexOf(o.id), 1);
              }
              setFieldValue(field.name, newValue, false);
              setFieldTouched(field.name, true, true);
              onChange?.(newValue);
            }}
          />
        ))}
      </Div>
    ),

    /*
     * Only check for changes to significant properties which can change
     * between form field renders. Dependencies like `field`, `onChange` and `setFieldValue`
     * change on every render, so we need to exclude these to avoid unnecessary
     * and unperformant rerenders.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, required, disabled, field.value, meta.touched, meta.error]
  );
};

const AccessibleLabel: React.FC<{
  required?: boolean;
  label: string;
  tooltip?: string;
  errorText?: string;
}> = ({ required, label, tooltip, errorText }) => (
  <label
    className={getClassNames([
      'telia-react-element',
      'telia-react-element__block',
      'telia-react-element__fontWeight__normal',
      'telia-react-bodyText__size_sm',
      'telia-react-color__black',
      'telia-react-typography',
      'telia-react-typography__teliasans'
    ])}
  >
    {label}

    <span className={getClassNames(['telia-react-element', 'telia-react-input_required'])}>
      {required && ' *'}
    </span>

    {tooltip && (
      <SmartTooltip tooltipContent={<TextWithInlineHtml size="sm" text={tooltip} />}>
        <SmartTooltip.InfoIcon size="md" spacing="xs" />
      </SmartTooltip>
    )}

    {errorText && <FieldErrorText>{errorText}</FieldErrorText>}
  </label>
);

export default FormFieldCheckgroup;
