import { Div } from '@gaiads/telia-react-component-library';
import { ActiveCompanySelector, RouteBreadcrumb } from 'common-components';
import { Flex } from 'core-components';
import useIsSmallerBreakpointActive from 'hooks/useIsSmallerBreakpointActive/useIsSmallerBreakpointActive';

const BreadcrumbWithCompanySelector: React.FC<{
  withoutBreadcrumb?: boolean;
  withoutCompanySelector?: boolean;
}> = ({ withoutBreadcrumb = false, withoutCompanySelector = false }) => {
  const isMobileView = useIsSmallerBreakpointActive('md');
  if (withoutBreadcrumb && withoutCompanySelector) {
    return null;
  }

  return (
    <Flex
      spaceBetween
      directionColumn={isMobileView}
      data-testid="breadcrumb-with-company-selector"
    >
      <Div flexItem={{ sizeMultiplier: 8 }} margin={{ bottom: isMobileView ? 'lg' : 'zero' }}>
        {!withoutBreadcrumb && <RouteBreadcrumb />}
      </Div>

      <Div flexItem={{ sizeMultiplier: 4 }}>
        {!withoutCompanySelector && <ActiveCompanySelector />}
      </Div>
    </Flex>
  );
};

export default BreadcrumbWithCompanySelector;
