import { useEffect, useState } from 'react';

/**
 * Returns the HTML element matching the specified selector.
 *
 * @privateRemarks
 * Ensures React Storybook behaviour is consistent with the application
 * when selecting a DOM-level element, since the DOM is not ready before a
 * story has finished its first render cycle, and thus selecting an element
 * from the HTML document will likely return `null`.
 *
 * The application itself will neither have this issue nor rerender
 * unnecessarily when it already retrieves an HTML element on first pass.
 */
export const useHtmlElement = (selector: string) => {
  const [element, setElement] = useState<Element | null>(() => document.querySelector(selector));

  useEffect(() => {
    setElement(document.querySelector(selector));
  }, [selector]);

  return element;
};
