import { PageWithFormLayout } from 'common-components';
import ConfirmationPanelSuccess from 'common-components/ConfirmationPanel/ConfirmationPanelSuccess';
import { multiplex } from 'doings/multiplex/multiplex';
import { useTranslation } from 'react-i18next';

import RequestNewPlan from './RequestNewPlan/RequestNewPlan';
import usePaymentPlanSteps from './usePaymentPlanSteps';

const InvoicePaymentPlan: React.FC = () => {
  const { t } = useTranslation();
  const {
    invoice,
    loadingInvoiceDetails,
    hasError,
    confirmedNewDueDate,
    step,
    setStep,
    applyPaymentPlan
  } = usePaymentPlanSteps();

  return (
    <PageWithFormLayout
      title={t('invoice.paymentPlan.title')}
      backLinkVisible={step !== 'confirmed'}
      loading={loadingInvoiceDetails}
    >
      {step !== 'confirmed' && (
        <RequestNewPlan
          invoice={invoice}
          loading={loadingInvoiceDetails}
          hasError={hasError}
          applyNewPlan={applyPaymentPlan}
          saving={multiplex(['no', [step === 'loading', 'yes'], [step === 'error', 'failure']])}
          savingReset={() => setStep('initial')}
        />
      )}

      {step === 'confirmed' && (
        <ConfirmationPanelSuccess
          data-testid="new-payment-plan-success"
          title={t('invoice.paymentPlan.success')}
          description={t('invoice.paymentPlan.successDescription', {
            newDueDate: confirmedNewDueDate
          })}
          backToPath={`/invoices/${invoice?.key || ''}`}
          backToLabel={t('invoice.paymentPlan.backToInvoice')}
        />
      )}
    </PageWithFormLayout>
  );
};

export default InvoicePaymentPlan;
