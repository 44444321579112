import { Dropdown } from '@gaiads/telia-react-component-library';
import { useTranslation } from 'react-i18next';
import { SortAction, SortOrder, SortParameter } from 'types/sort';

type SortDropdownOptions = { label: string; value: string };

const InvoiceListSortDropdown: React.FC<{
  currentSort: SortParameter;
  doSort: SortAction;
}> = ({ currentSort, doSort }) => {
  const { t } = useTranslation();

  const options = [
    {
      label: t('invoices.list.sort.invoiceDateAsc'),
      value: 'invoiceDate,asc'
    },
    {
      label: t('invoices.list.sort.invoiceDateDesc'),
      value: 'invoiceDate,desc'
    },
    {
      label: t('invoices.list.sort.invoiceDueDateAsc'),
      value: 'dueDate,asc'
    },
    {
      label: t('invoices.list.sort.invoiceDueDateDesc'),
      value: 'dueDate,desc'
    },
    {
      label: t('invoices.list.sort.idAsc'),
      value: 'id,asc'
    },
    {
      label: t('invoices.list.sort.idDesc'),
      value: 'id,desc'
    },
    {
      label: t('invoices.list.sort.invoiceStatusAsc'),
      value: 'status,asc'
    },
    {
      label: t('invoices.list.sort.invoiceStatusDesc'),
      value: 'status,desc'
    }
  ] as SortDropdownOptions[];

  return (
    <Dropdown
      label={t('invoices.list.sort.order')}
      aria-label={t('invoices.list.sort.order')}
      options={options}
      onChange={(_event, { value }) =>
        doSort(value.split(',')[0], value.split(',')[1] as SortOrder)
      }
      selectedOption={options.find(
        ({ value }) => value === `${currentSort.sort},${currentSort.order}`
      )}
    />
  );
};

export default InvoiceListSortDropdown;
