import { TeliaLink } from 'common-components';
import formatDatetime, { DateFormats } from 'doings/formatDatetime/formatDatetime';
import isDemoEnvironment from 'doings/isDemoEnvironment/isDemoEnvironment';
import { joinURLSearchParams } from 'doings/links/joinURLSearchParams/joinURLSearchParams';
import removeEmptyFields from 'doings/removeEmptyFields/removeEmptyFields';
import { getDefaultDateParams } from 'hooks/serviceRequests/useServiceRequests/getDefaultDateParams';
import { TicketsQueryParameters } from 'hooks/tickets/useTickets/useFetchTicketList';
import { useTranslation } from 'react-i18next';
import { EXCEL_MAX_ITEMS_VALUE } from 'types/excelDownload';

export type TicketsExcelDownloadLinkProps = {
  totalItems: number;
  disabled: boolean;
  queryParameters: TicketsQueryParameters;
  allCompanies: boolean;
};

export const ExcelDownloadLink: React.FC<TicketsExcelDownloadLinkProps> = ({
  totalItems,
  disabled,
  queryParameters,
  allCompanies
}) => {
  const { t } = useTranslation();
  const isDemoEnv = isDemoEnvironment();

  const dateParameters = queryParameters.dateParameters;
  const dateParams =
    !dateParameters?.dateFrom && !dateParameters?.dateTo
      ? getDefaultDateParams(dateParameters?.filterBy)
      : {
          dateFrom: formatDatetime(dateParameters.dateFrom, DateFormats.DATE),
          dateTo: formatDatetime(dateParameters.dateTo, DateFormats.DATE),
          filterBy: dateParameters.filterBy
        };

  const urlSearchParams = new URLSearchParams(
    removeEmptyFields({
      status: queryParameters.status,
      subscriptionId: queryParameters.subscriptionId,
      ticketId: queryParameters.ticketId,
      subject: queryParameters.subject,
      description: queryParameters.description,
      ...dateParams
    })
  );

  const queryParams = joinURLSearchParams('?', {
    columns: [
      t('tickets.list.columns.creationDate'),
      t('tickets.list.columns.modified'),
      t('tickets.list.columns.name'),
      t('tickets.list.columns.subscription'),
      t('tickets.list.columns.status'),
      t('tickets.list.columns.id'),
      t('common.companyCode.label'),
      t('common.company.label')
    ].join(','),
    filter: urlSearchParams.toString(),
    showFromAllCompanies: allCompanies,
    sheetTitle: t('tickets.title')
  });

  return (
    <TeliaLink.Download
      label={t('common.excelDownload.label')}
      to={`/api/troubleTickets/listAsExcel${queryParams}`}
      iconLeft="download"
      data-testid="fault-reports-list-as-excel-link"
      disabled={isDemoEnv || disabled || totalItems > EXCEL_MAX_ITEMS_VALUE}
      disabledTooltipKey={
        totalItems > EXCEL_MAX_ITEMS_VALUE ? 'common.excelDownload.tooManyItems' : undefined
      }
      downloadWithFetch
      useFileNameFromResponse
    />
  );
};
