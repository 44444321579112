import { DateFormat } from './DateFormats';

const toDate =
  (dateFormat: DateFormat) =>
  (input: unknown): Date | undefined =>
    typeof input === 'string' && dateFormat.match.test(input)
      ? dateFormat.parser(input)
      : undefined;

export default toDate;
