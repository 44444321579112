import queryString from 'query-string';
import { useLocation } from 'react-router';
import { B2XCookie } from 'types/clientCookie';

const useActiveCompanyCookie = (document: Document) => {
  const location = useLocation();
  const { selectedCompanyId } = queryString.parse(location.search);
  const currentHostname = document.location.hostname;
  const cookieLocation = currentHostname.includes('telia.fi') ? '.telia.fi' : currentHostname;
  if (selectedCompanyId) {
    document.cookie = `${B2XCookie.ACTIVE_COMPANY_ID}=${selectedCompanyId};max-age=86400000;domain=${cookieLocation};path=/;secure;SameSite=Strict;`;
  }
};

export default useActiveCompanyCookie;
