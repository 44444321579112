import { Loader } from '@gaiads/telia-react-component-library';
import { Breakpoint, Repeat } from 'common-components';
import { Flex } from 'core-components';

import ListView from '../ListView';
import { useListViewLayout } from '../ListViewLayout/ListViewLayoutProvider';

export type ListViewLoaderProps = {
  usesQuicklinks?: boolean;
  usesExpandableArrow?: boolean;
};

const SKELETON_ROW_COUNT = 3;

/**
 * Renders skeleton loaders on wider screens for a wide list view and
 * a loading spinner for on narrower screens for a mobile list view.
 *
 * Since list page filter settings are changed in an anchored dialog
 * on wider screens, and individual rows are rather fixed in size, we
 * can render skeleton loaders instead of a spinner for desktop view
 * to better convey the idea of loading data.
 *
 * Since list page filter settings are changed in a modal dialog on
 * narrower screens, and individual row sizes aren't fixed, it is
 * enough to have a spinner in the background instead of skeleton
 * loaders for mobile view.
 */
const ListViewLoader: React.FC<ListViewLoaderProps> = ({
  usesQuicklinks = false,
  usesExpandableArrow = false
}) => {
  return (
    <>
      <Breakpoint widerThan="lg">
        <Repeat times={SKELETON_ROW_COUNT}>
          <ListViewLoaderRowSkeleton
            usesQuicklinks={usesQuicklinks}
            usesExpandableArrow={usesExpandableArrow}
          />
        </Repeat>
      </Breakpoint>

      <Breakpoint narrowerThan="lg">
        <Flex centeredHorizontally>
          <Loader data-testid="list-view-loader" size="lg" />
        </Flex>
      </Breakpoint>
    </>
  );
};

const ListViewLoaderRowSkeleton: React.FC<ListViewLoaderProps> = ({
  usesQuicklinks = false,
  usesExpandableArrow = false
}) => {
  const { columnsInOrder } = useListViewLayout();
  return (
    <ListView.Row
      data-testid="list-row-loader-row"
      hideArrow
      usesQuicklinks={usesQuicklinks}
      usesExpandableArrow={usesExpandableArrow}
      loading
    >
      {columnsInOrder.length <= 0 && (
        <ListView.Cell cellSize="1x" loading>
          &nbsp;
        </ListView.Cell>
      )}

      {columnsInOrder.map((config) => (
        <ListView.Cell
          key={`list-row-loader-cell-${config.columnId}`}
          cellSize={config.width}
          loading
        >
          &nbsp;
        </ListView.Cell>
      ))}
    </ListView.Row>
  );
};

export default ListViewLoader;
