import useApiStatus from 'hooks/useApiStatus/useApiStatus';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { MobileNetworkQuality } from 'types/mobileNetworkQuality';

import { mobileNetworkQualityQuery } from './mobileNetworkQualityQuery';

const useMobileNetworkQuality = () => {
  const { data, error, loading } = useCallBackend<{
    mobileNetworkServiceQuality: MobileNetworkQuality.ServiceQualityInformation;
  }>({
    query: mobileNetworkQualityQuery
  });

  const apiState = useApiStatus({
    loading,
    loadingMore: false,
    loaded: !loading,
    items: [],
    error,
    query: {}
  });

  const qualityInformation = data?.mobileNetworkServiceQuality
    ? data.mobileNetworkServiceQuality
    : undefined;

  return {
    mobileNetworkQuality: qualityInformation,
    error: !loading && !qualityInformation,
    loading,
    apiState
  };
};

export default useMobileNetworkQuality;
