import { ApolloLink, NextLink, Operation } from '@apollo/client';

export const getExtendUrlLink = () => {
  return new ApolloLink((operation: Operation, forward: NextLink) => {
    const extension = operation.operationName ? `?op=${operation.operationName}` : '';
    if (extension) {
      operation.setContext((ctx: Record<string, unknown>) => ({
        uri: `${process.env.PUBLIC_URL}/api/graphql${extension}`,
        ...ctx
      }));
    }

    return forward(operation);
  });
};
