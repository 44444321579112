import { ListView } from 'common-components';
import { useEditableListData } from 'contexts/EditableListContext/EditableListContext';
import { SortState, getSort } from 'hooks/useSortableHeadings/useSortableHeadings';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Invoice } from 'types/invoice';
import { ColumnVisibility, ListViewColumnSetup } from 'types/listConfig';

import InvoiceStatusTag from './InvoiceStatus/InvoiceStatusTag';

export const InvoicesListViewColumnSetup: ListViewColumnSetup<
  [TFunction, SortState, (field: string) => void, boolean]
> = {
  listId: 'invoices',
  getColumns: (
    t: TFunction,
    sortState: SortState,
    sortClick: (field: string) => void,
    showCompanyByDefault: boolean
  ) => ({
    type: {
      width: '1.5x',
      heading: t('invoices.list.columns.type'),
      testidSuffix: 'type',
      baseVisibility: ColumnVisibility.SHOW
    },
    created: {
      heading: t('invoices.list.columns.invoiceDate'),
      testidSuffix: 'created-date',
      sort: getSort(sortState, sortClick, 'invoiceDate'),
      baseVisibility: ColumnVisibility.SHOW
    },
    dueDate: {
      heading: t('invoices.list.columns.dueDate'),
      testidSuffix: 'due-date',
      sort: getSort(sortState, sortClick, 'dueDate'),
      baseVisibility: ColumnVisibility.SHOW
    },
    invoiceNumber: {
      heading: t('invoices.list.columns.id'),
      sort: getSort(sortState, sortClick, 'id'),
      testidSuffix: 'id',
      baseVisibility: ColumnVisibility.REQUIRE
    },
    collectionGroup: {
      heading: t('invoices.list.columns.collectionGroup'),
      testidSuffix: 'collectionGroup',
      baseVisibility: ColumnVisibility.HIDE
    },
    referenceNumber: {
      heading: t('invoices.list.columns.referenceNumber'),
      testidSuffix: 'referenceNumber',
      baseVisibility: ColumnVisibility.HIDE
    },
    status: {
      heading: t('invoices.list.columns.status'),
      testidSuffix: 'status',
      sort: getSort(sortState, sortClick, 'status'),
      baseVisibility: ColumnVisibility.SHOW
    },
    total: {
      heading: t('invoices.list.columns.total'),
      baseVisibility: ColumnVisibility.REQUIRE
    },
    company: {
      width: '1.5x',
      heading: t('common.company.label'),
      tooltip: t('common.company.tooltip'),
      testidSuffix: 'company',
      baseVisibility: showCompanyByDefault ? ColumnVisibility.SHOW : ColumnVisibility.HIDE
    }
  })
};

const InvoicesListView: React.FC<{
  invoices: Invoice[];
  loading: boolean;
  dataTestId?: string;
}> = ({ invoices, loading }) => {
  const { t } = useTranslation();
  const { columns } = useEditableListData();

  return (
    <ListView
      data-testid="invoices-list"
      columns={columns}
      mobileLayout={{
        titleRow: { col: 'invoiceNumber' },
        rows: [
          { col: 'type' },
          { col: 'created' },
          { col: 'dueDate' },
          { col: 'total' },
          { col: 'collectionGroup' },
          { col: 'referenceNumber' },
          { col: 'company' },
          { col: 'status', noLabel: true, padding: 'xs' }
        ]
      }}
      loading={loading}
      listData={invoices}
      noItemsNotice={t('invoices.list.noItemsFound')}
    >
      {({
        id,
        key,
        collectionGroup,
        referenceNumber,
        type,
        invoiceDate,
        dueDate,
        totalAmount,
        formattedTotal,
        status,
        companyCode,
        companyName,
        highlightAs
      }) => (
        <ListView.Row
          data-testid="invoices-list-row-item"
          linkTo={`/invoices/${key}`}
          highlightLabel={highlightAs ? t(`invoices.list.highlight.${highlightAs}`) : undefined}
        >
          <ListView.RowShape
            cells={{
              type: { data: t(`invoices.types.${type}`), props: {} },
              created: { data: invoiceDate, props: {} },
              dueDate: { data: dueDate, props: {} },
              invoiceNumber: { data: id, props: { wordWrap: 'anywhere' } },
              collectionGroup: { data: collectionGroup, props: {} },
              referenceNumber: { data: referenceNumber, props: {} },
              status: {
                data: <InvoiceStatusTag status={status} total={totalAmount} />,
                props: { dataTestId: `invoice-list-status-${status?.toLowerCase()}` }
              },
              total: { data: formattedTotal, props: {} },
              company: {
                data: companyCode || companyName,
                props: { tooltip: `${companyCode} ${companyName}`, wordWrap: 'ellipsis' }
              }
            }}
          />
        </ListView.Row>
      )}
    </ListView>
  );
};

export default InvoicesListView;
