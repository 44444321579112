import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface BillingInfoChangePayload {
  show: boolean;
  mass?: boolean;
}

export interface BillingInfoChangeModalState {
  isModalOpen: boolean;
  isMassChange?: boolean;
}

const initialState: BillingInfoChangeModalState = {
  isModalOpen: false
};

export const billingInfoChangeModalSlice = createSlice({
  name: 'billingInfoChangeModal',
  initialState,
  reducers: {
    showBillingInfoChangeModal: (state, action: PayloadAction<BillingInfoChangePayload>) => {
      state.isMassChange = action.payload.mass;
      state.isModalOpen = action.payload.show;
    }
  }
});

export const { showBillingInfoChangeModal } = billingInfoChangeModalSlice.actions;

export default billingInfoChangeModalSlice.reducer;
