import { Loader } from '@gaiads/telia-react-component-library';
import { Icon, IconName } from '@teliafi/fi-ds';
import getClassNames from 'classnames';
import { NoPrivilegesTooltip } from 'common-components';
import Analytics from 'common-components/Analytics/Analytics';
import { shouldDisableLink } from 'common-components/Link/Link';
import { SmartTooltip } from 'common-components/SmartTooltip/SmartTooltip';
import isCorporatePortalOrUlmUrl from 'doings/isCorporatePortalOrUlmUrl/isCorporatePortalOrUlmUrl';
import isDemoEnvironment from 'doings/isDemoEnvironment/isDemoEnvironment';
import createCorporatePortalLink, {
  Args
} from 'doings/links/createCorporatePortalLink/createCorporatePortalLink';
import { noOp } from 'doings/noOp/noOp';
import { AnalyticsEvent } from 'doings/track/analyticsEvents';
import { Link as LinkFromRouter } from 'react-router-dom';
import { AuthorizationParams } from 'types/authorization';

import styles from './VocaLink.module.scss';

// Internal types
type LinkType = 'internal' | 'external' | 'click';
type LinkVariant =
  | 'text'
  | 'standalone'
  | 'standalone-spacious'
  | 'standalone-action-menu'
  | 'standalone-content-search'
  | 'standalone-download'
  | 'standalone-topbar'
  | 'standalone-topbar-active';

type LinkProps = {
  type: LinkType;
  to?: string;
  openInNewTab?: boolean;
  onClick?: (e: React.MouseEvent | React.KeyboardEvent) => void;
  variant: LinkVariant;
  label: string;
  labelAuxiliary?: string;
  tooltip?: string;
  iconLeft?: IconName;
  iconRight?: 'chevron-right' | 'external';
  loading?: boolean;
  disabled?: boolean;
  disabledTooltipKey?: string;
  enabledWhen?: AuthorizationParams;
  track?: AnalyticsEvent;
  unfocusable?: boolean;
  'aria-label'?: string;
  'data-testid'?: string;
  'data-tour-stop'?: string;
  'data-dd-action-name'?: string;
};

type GenericLinkProps = Omit<LinkProps, 'type' | 'to' | 'openInNewTab' | 'onClick'>;
type TranslatableLinkPropsFrom<T> = Omit<T, 'label'> & { children?: string };

// Exposed types
type ExposedInternalLinkProps = GenericLinkProps &
  Required<Pick<LinkProps, 'to'>> &
  Pick<LinkProps, 'openInNewTab'>;

type ExposedExternalLinkProps = GenericLinkProps &
  Required<Pick<LinkProps, 'to'>> &
  Pick<LinkProps, 'openInNewTab'>;

type ExposedPortalLinkProps = GenericLinkProps & {
  to: string;
  toCorporatePortal: string;
  toCorporatePortalArgs?: Args;
  useRouterLink: boolean;
};

type ExposedClickLinkProps = GenericLinkProps & Required<Pick<LinkProps, 'onClick'>>;

/**
 * A Telia link directing to a page within the portal.
 *
 * Substitutes `<TextLink .../>`.
 */
export const TeliaInternalLink: React.FC<ExposedInternalLinkProps> = (props) => (
  <TeliaLink type="internal" {...props} />
);

/**
 * A `TeliaInternalLink` within a translated context, where the link's
 * label is provided as a child string instead of via prop. The i18n
 * library's `Trans` component behaves like that for link placeholders,
 * e.g. "Text with <0>translated link</0>" would pass "translated link"
 * to the link component as a child string.
 */
export const TeliaInternalLinkTranslatable: React.FC<
  TranslatableLinkPropsFrom<ExposedInternalLinkProps>
> = ({ children = '???', ...props }) => <TeliaInternalLink label={children} {...props} />;

/**
 * A Telia link directing to a page outside of the portal.
 *
 * Providing an external link icon `iconRight="external"` will automatically
 * have the link open in a new tab or, if the external link icon is not
 * desired, instruct the link to explicitly open in a new tab by providing the
 * `openInNewTab` prop. Having both props is unnecessary.
 *
 * Links to B2B Portal or ULM should, although external, should be rendered
 * without an external icon and opened in the same browser tab as per business
 * requirements.
 *
 * Substitutes `<ExternalLink .../>`.
 */
export const TeliaExternalLink: React.FC<ExposedExternalLinkProps> = ({
  to,
  disabled,
  ...props
}) => (
  <TeliaLink
    type="external"
    to={to}
    disabled={disabled || (isDemoEnvironment() && isCorporatePortalOrUlmUrl(to))}
    {...props}
  />
);

/**
 * A `TeliaExternalLink` within a translated context, where the link's
 * label is provided as a child string instead of via prop. The i18n
 * library's `Trans` component behaves like that for link placeholders,
 * e.g. "Text with <0>translated link</0>" would pass "translated link"
 * to the link component as a child string.
 */
export const TeliaExternalLinkTranslatable: React.FC<
  TranslatableLinkPropsFrom<ExposedExternalLinkProps>
> = ({ children = '???', ...props }) => <TeliaExternalLink label={children} {...props} />;

/**
 * A Telia link directing to a page either inside B2X Portal or B2B Portal based
 * on a boolean condition, e.g. the active state of a feature flag.
 */
export const TeliaPortalLink: React.FC<ExposedPortalLinkProps> = ({
  to,
  toCorporatePortal,
  toCorporatePortalArgs,
  useRouterLink,
  disabled,
  ...props
}) =>
  useRouterLink ? (
    <TeliaLink type="internal" to={to} disabled={disabled} {...props} />
  ) : (
    <TeliaLink
      type="external"
      to={createCorporatePortalLink(toCorporatePortal, toCorporatePortalArgs)}
      disabled={disabled || isDemoEnvironment()}
      {...props}
    />
  );

/**
 * A `TeliaPortalLink` within a translated context, where the link's
 * label is provided as a child string instead of via prop. The i18n
 * library's `Trans` component behaves like that for link placeholders,
 * e.g. "Text with <0>translated link</0>" would pass "translated link"
 * to the link component as a child string.
 */
export const TeliaPortalLinkTranslatable: React.FC<
  TranslatableLinkPropsFrom<ExposedPortalLinkProps>
> = ({ children = '???', ...props }) => <TeliaPortalLink label={children} {...props} />;

/**
 * A Telia link-styled button acting solely upon the `onClick` event.
 *
 * Substitutes `<BodyTextClickable vocaLink .../>`.
 */
export const TeliaClickLink: React.FC<ExposedClickLinkProps> = (props) => (
  <TeliaLink type="click" {...props} />
);

/**
 * A `TeliaClickLink` within a translated context, where the link's
 * label is provided as a child string instead of via prop. The i18n
 * library's `Trans` component behaves like that for link placeholders,
 * e.g. "Text with <0>translated link</0>" would pass "translated link"
 * to the link component as a child string.
 */
export const TeliaClickLinkTranslatable: React.FC<
  TranslatableLinkPropsFrom<ExposedClickLinkProps>
> = ({ children = '???', ...props }) => <TeliaClickLink label={children} {...props} />;

/**
 * A link with Telia styling and variants:
 * * `text` — Narrow black links rendered inside text.
 * * `standalone` — Spacious purple links rendered on a separate line.
 *
 * Supports optional utility props:
 * * `disabled` — Disables the link explicitly.
 * * `disabledTooltipKey` — Renders a tooltip for an explicitly disabled link
 * on hover or click.
 * * `enabledWhen` — Disables the link implicitly if a user lacks sufficient
 * permissions for the current active company.
 * * `tooltip` — Renders a tooltip in an info icon inlined after the link.
 * * `track` — Tracks a Google Analytics' event when an enabled link is
 * navigated to or clicked.
 * * `data-testid` — Data attibute attached to the underlying `a` element for
 * unit and E2E testing.
 * * `data-tour-stop` — Data attibute attached to the underlying `a` element for
 * tour triggering and tour stop highlight purposes.
 * * `data-dd-action-name` — Data attribute attached to the underlying `a`
 * element which tracks an action of the given name in Datadog for a RUM-enabled
 * session.
 *
 * Current `TeliaLink` as of `@teliads/components:18.4.1` lacks support for
 * React router (meaning navigating via links always refreshes the browser),
 * disabled links and respective styling (cursor and standalone variant are
 * unaware of disabled state), and links with solely `onClick` events,
 * all provided by this component.
 */
const TeliaLink: React.FC<LinkProps> = ({
  type = 'internal',
  to = '',
  openInNewTab = type === 'external' && to !== '#' && !isCorporatePortalOrUlmUrl(to),
  onClick = noOp,
  variant,
  label,
  labelAuxiliary,
  tooltip,
  iconLeft,
  iconRight,
  loading = false,
  disabled = false,
  disabledTooltipKey,
  enabledWhen,
  track,
  unfocusable,
  ...rest
}) => {
  const Wrapper = ({ children }: { children: JSX.Element }) => (
    <span
      className={getClassNames({
        [styles.wrapper]: true,
        [styles.wrapper__text]: variant === 'text',
        [styles.wrapper__standalone]: variant === 'standalone',
        [styles.wrapper__standaloneActionMenu]: variant === 'standalone-action-menu',
        [styles.wrapper__standaloneContentSearch]: variant === 'standalone-content-search',
        [styles.wrapper__standaloneDownload]: variant === 'standalone-download',
        [styles.wrapper__standaloneSpacious]: variant === 'standalone-spacious',
        [styles.wrapper__standaloneTopBar]: variant === 'standalone-topbar',
        [styles.wrapper__standaloneTopBarActive]: variant === 'standalone-topbar-active'
      })}
      data-link-type={type}
      data-testid="link-wrapper"
    >
      {track ? (
        <Analytics
          whenClicked={track}
          inlineBlock={['text', 'standalone-action-menu'].includes(variant)}
        >
          {children}
        </Analytics>
      ) : (
        children
      )}

      <LinkTooltip />
    </span>
  );

  const LinkContent = ({
    isDisabled,
    isLoading
  }: {
    isDisabled?: boolean;
    isLoading?: boolean;
  }) => (
    <span
      className={getClassNames(styles.linkContent, {
        [styles.linkContent__disabled]: isDisabled,
        [styles.linkContent__loading]: isLoading
      })}
      data-testid="link-content"
    >
      {labelAuxiliary && <span className={styles.linkContent_auxiliary}>{labelAuxiliary}</span>}

      {iconLeft && (
        <Icon
          className={styles.linkContent_iconLeft}
          name={iconLeft}
          size="xs"
          data-icon-name={iconLeft}
          data-testid={`link-icon-${iconLeft}`}
        />
      )}

      <span className={styles.linkContent_label}>{label}</span>

      {iconRight && (
        <Icon
          className={styles.linkContent_iconRight}
          name={iconRight}
          size="xs"
          data-icon-name={iconRight}
          data-testid={`link-icon-${iconRight}`}
        />
      )}

      {isLoading && <Loader className={styles.linkContent_loader} size="sm" />}
    </span>
  );

  const LinkTooltip = () =>
    tooltip ? (
      <span className={styles.tooltip}>
        <SmartTooltip tooltipContent={tooltip} arrangement="top">
          <SmartTooltip.InfoIcon size="sm" />
        </SmartTooltip>
      </span>
    ) : null;

  const { isDisabled, isUnauthorised } = shouldDisableLink(disabled, enabledWhen);
  if (isDisabled) {
    return (
      <Wrapper>
        <NoPrivilegesTooltip
          i18nKey={
            !!disabledTooltipKey && !isUnauthorised
              ? disabledTooltipKey
              : determineNoPrivilegesTooltipKey(type, to)
          }
          showTooltip={!!disabledTooltipKey || isUnauthorised}
          tooltipPlacement="top"
          wrapChildren
          wrapper="span"
          inline
          inlineRightPadded={!!tooltip}
        >
          <span
            data-testid={rest['data-testid']}
            data-tour-stop={rest['data-tour-stop']}
            aria-disabled
            className={styles.link}
          >
            <LinkContent isDisabled />
          </span>
        </NoPrivilegesTooltip>
      </Wrapper>
    );
  }

  if (loading) {
    return (
      <Wrapper>
        <span
          data-testid={rest['data-testid']}
          data-tour-stop={rest['data-tour-stop']}
          aria-disabled
          className={styles.link}
        >
          <LinkContent isLoading />
        </span>
      </Wrapper>
    );
  }

  const linkBaseProps = {
    'aria-label': rest['aria-label'],
    'data-testid': rest['data-testid'],
    'data-tour-stop': rest['data-tour-stop'],
    'data-dd-action-name': rest['data-dd-action-name'],
    className: styles.link,
    tabIndex: unfocusable ? -1 : 0
  };

  switch (type) {
    case 'internal':
      return (
        <Wrapper>
          <LinkFromRouter
            {...linkBaseProps}
            to={to}
            target={openInNewTab ? '_blank' : ''}
            rel="noreferrer"
          >
            <LinkContent />
          </LinkFromRouter>
        </Wrapper>
      );

    case 'external':
      return (
        <Wrapper>
          <a {...linkBaseProps} href={to} target={openInNewTab ? '_blank' : ''} rel="noreferrer">
            <LinkContent />
          </a>
        </Wrapper>
      );

    case 'click':
      return (
        <Wrapper>
          <button {...linkBaseProps} onClick={onClick}>
            <LinkContent />
          </button>
        </Wrapper>
      );
  }
};

const determineNoPrivilegesTooltipKey = (type: LinkType, to: string) =>
  type === 'external' && !isCorporatePortalOrUlmUrl(to)
    ? 'common.tooltips.insufficientPermissions.forExternalLink'
    : 'common.tooltips.insufficientPermissions.forTextLink';
