import { DATE, DateFormats } from 'doings/formatDatetime/DateFormats';
import formatDatetime from 'doings/formatDatetime/formatDatetime';
import { useServiceRequestListFilter } from 'hooks/serviceRequests/useServiceRequestListFilter';
import useServiceRequests from 'hooks/serviceRequests/useServiceRequests/useServiceRequests';
import useReadQueryParams from 'hooks/useQueryParams/useReadQueryParams';
import useUserHasMultipleCompaniesWithGivenPermission from 'hooks/useUser/useUserHasMultipleCompaniesWithGivenPermission';
import { FilterBy, ServiceRequestSearchParameters } from 'types/serviceRequest';

import ServiceRequestsListPage from './ServiceRequestsListPage';

export interface TicketDateParameters {
  dateFrom?: DATE;
  dateTo?: DATE;
  filterBy?: string;
}

const throttleSearchParametersWithoutTerm = (params: ServiceRequestSearchParameters) =>
  !!params.term && params.term.length > 0 ? params : undefined;

const ServiceRequestsList: React.FC = () => {
  const multipleCompanySearchEnabled = useUserHasMultipleCompaniesWithGivenPermission({
    anyPermission: ['SERVICE_REQUESTS', 'SERVICE_REQUESTS_VIEW']
  });
  const queryParameters = useReadQueryParams([
    'scope',
    'search',
    'status',
    'dateType',
    'from',
    'to',
    'allCompanies'
  ]);
  const filters = useServiceRequestListFilter(multipleCompanySearchEnabled);

  const { serviceRequests, apiState, error, fetchMore } = useServiceRequests({
    status: queryParameters.status,
    searchParameters: throttleSearchParametersWithoutTerm({
      scope: queryParameters.scope,
      term: queryParameters.search
    }),
    dateParameters: {
      dateFrom: formatDatetime(queryParameters.from, DateFormats.DATE),
      dateTo: formatDatetime(queryParameters.to, DateFormats.DATE),
      filterBy: queryParameters.dateType as FilterBy
    },
    allCompanies: filters.allCompanySearch.showFromAllCompanies
  });

  return (
    <ServiceRequestsListPage
      serviceRequests={serviceRequests}
      queryParameters={queryParameters}
      filters={filters}
      apiState={apiState}
      error={error}
      fetchMore={fetchMore}
      multipleCompanySearchEnabled={multipleCompanySearchEnabled}
    />
  );
};

export default ServiceRequestsList;
