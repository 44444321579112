import { SupportedFontWeight } from '@gaiads/telia-react-component-library';
import { ListView } from 'common-components';
import { useEditableListData } from 'contexts/EditableListContext/EditableListContext';
import formatDatetime from 'doings/formatDatetime/formatDatetime';
import createCorporatePortalLink from 'doings/links/createCorporatePortalLink/createCorporatePortalLink';
import { SortState } from 'hooks/useSortableHeadings/useSortableHeadings';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { CaseSortField, CompanyCaseListItem } from 'types/companyCaseList';
import { ColumnVisibility, ListViewColumnSetup } from 'types/listConfig';

import MessageStatusTag from './MessageStatusTag';

export const MessageArchiveListViewColumnSetup: ListViewColumnSetup<
  [TFunction, SortState, (field: string) => void, boolean]
> = {
  listId: 'archivedServiceRequests',
  getColumns: (
    t: TFunction,
    sortState: SortState,
    sortClick: (field: string) => void,
    showCompanyByDefault: boolean
  ) => ({
    created: {
      width: '0.5x',
      heading: t('messageArchive.list.columns.reportedDate'),
      testidSuffix: 'created',
      sort: {
        direction: sortState[CaseSortField.REPORTED_DATE],
        onClick: () => sortClick(CaseSortField.REPORTED_DATE)
      },
      baseVisibility: ColumnVisibility.SHOW
    },
    updated: {
      width: '0.5x',
      heading: t('messageArchive.list.columns.updatedDate'),
      testidSuffix: 'updated',
      sort: {
        direction: sortState[CaseSortField.UPDATED_DATE],
        onClick: () => sortClick(CaseSortField.UPDATED_DATE)
      },
      baseVisibility: ColumnVisibility.SHOW
    },
    subject: {
      width: '1.5x',
      heading: t('messageArchive.list.columns.subject'),
      testidSuffix: 'subject',
      baseVisibility: ColumnVisibility.REQUIRE
    },
    status: {
      heading: t('messageArchive.list.columns.status'),
      testidSuffix: 'status',
      baseVisibility: ColumnVisibility.SHOW
    },
    id: {
      width: '0.5x',
      heading: t('messageArchive.list.columns.id'),
      testidSuffix: 'id',
      sort: {
        direction: sortState[CaseSortField.ID],
        onClick: () => sortClick(CaseSortField.ID)
      },
      baseVisibility: ColumnVisibility.REQUIRE
    },
    company: {
      heading: t('common.company.label'),
      tooltip: t('common.company.tooltip'),
      testidSuffix: 'company',
      baseVisibility: showCompanyByDefault ? ColumnVisibility.SHOW : ColumnVisibility.HIDE
    }
  })
};

const MessageArchiveListView: React.FC<{
  loading: boolean;
  items: CompanyCaseListItem[];
}> = ({ loading, items }) => {
  const { t } = useTranslation();
  const { columns } = useEditableListData();

  return (
    <ListView
      data-testid="company-cases-list"
      columns={columns}
      mobileLayout={{
        titleRow: { col: 'subject' },
        rows: [
          { col: 'created' },
          { col: 'updated' },
          { col: 'id' },
          { col: 'company' },
          { col: 'status', noLabel: true, padding: 'xs' }
        ]
      }}
      loading={loading}
      listData={items}
      noItemsNotice={t('messageArchive.list.noArchivedItemsFound')}
    >
      {({
        caseId: id,
        multibellaGui: caseNumber,
        reportedDate,
        updatedDate,
        title,
        status,
        unread,
        companyCode,
        companyName
      }) => {
        const weight: SupportedFontWeight = unread ? 'bold' : 'normal';
        return (
          <ListView.Row
            data-testid={`company-cases-list-row-item-${status}`}
            linkTo={createCorporatePortalLink(
              `messageCenter/show?messageId=${id}&messageType=CASE`
            )}
            external
            highlighted={unread}
          >
            <ListView.RowShape
              cells={{
                created: {
                  data: formatDatetime(reportedDate),
                  props: { fontWeight: weight }
                },
                updated: {
                  data: formatDatetime(updatedDate),
                  props: { fontWeight: weight }
                },
                subject: {
                  data: title,
                  props: { fontWeight: weight, wordWrap: 'break-word' }
                },
                status: {
                  data: <MessageStatusTag status={status} bold={unread} />,
                  props: {}
                },
                id: {
                  data: `${caseNumber}`,
                  props: { fontWeight: weight }
                },
                company: {
                  data: companyCode || companyName,
                  props: {
                    fontWeight: weight,
                    tooltip: `${companyCode} ${companyName}`,
                    wordWrap: 'ellipsis'
                  }
                }
              }}
            />
          </ListView.Row>
        );
      }}
    </ListView>
  );
};

export default MessageArchiveListView;
