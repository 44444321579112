import { debounce } from 'lodash/fp';
import { useEffect, useState } from 'react';

const useDebounce = <T>(value: T, delayInMillis: number) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const setValue = debounce(delayInMillis, setDebouncedValue);

  useEffect(() => {
    if (debouncedValue !== value) {
      setValue(value);
    }
    return () => setValue.cancel();
  }, [debouncedValue, value, setValue]);

  return debouncedValue;
};

export default useDebounce;
