import { gql } from '@apollo/client';

export const featureFlagsQuery = gql`
  query GetFeatureFlags {
    featureFlags {
      flags {
        id
        name
        active
        rawActive
        description
        activeStatusOverride
        isApplicable
      }
      persistable
    }
  }
`;

export const toggleFeatureFlagMutation = gql`
  mutation ToggleFeatureFlag($name: String!) {
    toggleFeatureFlag(name: $name)
  }
`;

export const addOrUpdateFeatureFlagMutation = gql`
  mutation PersistFeatureFlag($flagData: AddOrUpdateFeatureFlag) {
    addOrUpdateFeatureFlag(flagData: $flagData)
  }
`;

export const deleteFeatureFlagMutation = gql`
  mutation DeleteFeatureFlag($name: String!) {
    deleteFeatureFlag(name: $name)
  }
`;
