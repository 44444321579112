/**
 * IMPORTANT!
 * This file should be kept in sync with the same file in the API package.
 * Ideally we need a common library for this.
 */
import { format, parse } from 'date-fns';

export type DateFormatter = (date: Date) => string;
export type DateParser = (date: string) => Date;
export enum DateTimeType {
  DATE,
  DATE_TIME,
  MONTH,
  TIME
}

export type DateFormat = {
  readonly match: RegExp;
  readonly formatter: DateFormatter;
  readonly parser: DateParser;
  readonly type: DateTimeType;
};

export const formatters = {
  main: (pattern: string) => (date: Date) => format(date, pattern),
  ISOString: (date: Date) => date.toISOString()
};

export const parsers = {
  main: (pattern: string) => (date: string) => parse(date, pattern, new Date()),
  ISOString: (date: string) => new Date(date)
};

export type DATE_NATIONAL = string;
export type DATE = string;

/**
 * All application-supported date formats.
 */
type SupportedDateFormats = {
  /**
   * yyyy-MM-dd'T'HH:mm:ss.SSSxxxx
   *
   * @example
   * 2000-12-31'T'23:59:59.999+0200
   */
  readonly ISO8601: DateFormat;

  /**
   * yyyy-MM-dd'T'HH:mm:ssxxxx
   *
   * @example
   * 2000-12-31'T'23:59:59+02:00
   */
  readonly ISO8601Offset: DateFormat;

  /**
   * yyyy-MM-dd HH:mm:ss
   *
   * @example
   * 2000-12-31 23:59:59
   */
  readonly ISO9075: DateFormat;

  /**
   * yyyy-MM-ddTHH:mm:ss.sssZ
   *
   * @example
   * 2000-12-31T23:59:59.999Z
   */
  readonly ISO_STRING: DateFormat;

  /**
   * yyyy-MM-dd
   *
   * @example
   * 2000-01-31
   */
  readonly DATE: DateFormat;

  /**
   * yyyy-M-d
   *
   * @example
   * 2000-1-31
   */
  readonly DATE_SHORT: DateFormat;

  /**
   * dd.MM.yyyy
   *
   * @example
   * 31.01.2000
   */
  readonly DATE_NATIONAL: DateFormat;

  /**
   * d.M.yyyy
   *
   * @example
   * 31.1.2000
   */
  readonly DATE_NATIONAL_SHORT: DateFormat;

  /**
   * MM.yyyy
   *
   * @example
   * 01.2000
   */
  readonly MONTH_NATIONAL: DateFormat;

  /**
   * M.yyyy
   *
   * @example
   * 1.2000
   */
  readonly MONTH_NATIONAL_SHORT: DateFormat;

  /**
   * HH.mm
   *
   * @example
   * 03.37
   * 13.37
   */
  readonly TIME_NATIONAL: DateFormat;

  /**
   * H.mm
   *
   * @example
   * 3.37
   * 13.37
   */
  readonly TIME_NATIONAL_SHORT: DateFormat;

  /**
   * dd.MM.yyyy HH.mm
   *
   * @example
   * 01.10.2023 03.37
   * 13.12.2021 13.37
   */
  readonly DATE_TIME_NATIONAL: DateFormat;

  /**
   * d.M.yyyy H.mm
   *
   * @example
   * 1.10.2023 3.37
   * 13.9.2021 13.37
   */
  readonly DATE_TIME_NATIONAL_SHORT: DateFormat;
};

export const DateFormats: SupportedDateFormats = {
  ISO8601: {
    formatter: formatters.main("yyyy-MM-dd'T'HH:mm:ss.SSSxxxx"),
    parser: parsers.main("yyyy-MM-dd'T'HH:mm:ss.SSSxxxx"),
    match: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}\+\d{4}$/,
    type: DateTimeType.DATE_TIME
  },

  ISO8601Offset: {
    formatter: formatters.main("yyyy-MM-dd'T'HH:mm:ssxxx"),
    parser: parsers.main("yyyy-MM-dd'T'HH:mm:ssxxx"),
    match: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}$/,
    type: DateTimeType.DATE_TIME
  },

  ISO9075: {
    formatter: formatters.main('yyyy-MM-dd HH:mm:ss'),
    parser: parsers.main('yyyy-MM-dd HH:mm:ss'),
    match: /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/,
    type: DateTimeType.DATE_TIME
  },

  ISO_STRING: {
    formatter: formatters.ISOString,
    parser: parsers.ISOString,
    match: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/,
    type: DateTimeType.DATE_TIME
  },

  DATE: {
    formatter: formatters.main('yyyy-MM-dd'),
    parser: parsers.main('yyyy-MM-dd'),
    match: /^\d{4}-\d{2}-\d{2}$/,
    type: DateTimeType.DATE
  },

  DATE_SHORT: {
    formatter: formatters.main('yyyy-M-d'),
    parser: parsers.main('yyyy-M-d'),
    match: /^\d{4}-[1-9]\d?-[1-9]\d?$/,
    type: DateTimeType.DATE
  },

  DATE_NATIONAL: {
    formatter: formatters.main('dd.MM.yyyy'),
    parser: parsers.main('dd.MM.yyyy'),
    match: /^\d{1,2}\.\d{1,2}\.\d{4}$/,
    type: DateTimeType.DATE
  },

  DATE_NATIONAL_SHORT: {
    formatter: formatters.main('d.M.yyyy'),
    parser: parsers.main('d.M.yyyy'),
    match: /^[1-9]\d?\.[1-9]\d?\.\d{4}$/,
    type: DateTimeType.DATE
  },

  MONTH_NATIONAL: {
    formatter: formatters.main('MM.yyyy'),
    parser: parsers.main('MM.yyyy'),
    match: /^\d{2}\.\d{4}$/,
    type: DateTimeType.MONTH
  },

  MONTH_NATIONAL_SHORT: {
    formatter: formatters.main('M.yyyy'),
    parser: parsers.main('M.yyyy'),
    match: /^[1-9]\d?\.\d{4}$/,
    type: DateTimeType.MONTH
  },

  TIME_NATIONAL: {
    formatter: formatters.main('HH.mm'),
    parser: parsers.main('HH.mm'),
    match: /^[0-2]?\d\.[0-5]\d$/,
    type: DateTimeType.TIME
  },

  TIME_NATIONAL_SHORT: {
    formatter: formatters.main('H.mm'),
    parser: parsers.main('H.mm'),
    match: /^[1-2]?\d\.[0-5]\d$/,
    type: DateTimeType.TIME
  },

  DATE_TIME_NATIONAL: {
    formatter: formatters.main('dd.MM.yyyy HH.mm'),
    parser: parsers.main('dd.MM.yyyy HH.mm'),
    match: /^\d{1,2}\.\d{1,2}\.\d{4} [0-2]?\d\.[0-5]\d$/,
    type: DateTimeType.DATE_TIME
  },

  DATE_TIME_NATIONAL_SHORT: {
    formatter: formatters.main('d.M.yyyy H.mm'),
    parser: parsers.main('d.M.yyyy H.mm'),
    match: /^[1-9]\d?\.[1-9]\d?\.\d{4} [1-2]?\d\.[0-5]\d$/,
    type: DateTimeType.DATE_TIME
  }
};
