import { Div, Divider } from '@gaiads/telia-react-component-library';
import join from 'doings/join/join';
import React from 'react';

const DashboardNotificationsList: React.FC<{
  'data-testid'?: string;
  children: React.ReactNode;
}> = ({ 'data-testid': dataTestid, children }) => (
  <Div data-testid={dataTestid}>
    {join(
      React.Children.toArray(children).map((child) => child as React.ReactElement),
      <Divider width={100} />
    )}
  </Div>
);

export default DashboardNotificationsList;
