import { BodyText } from '@gaiads/telia-react-component-library';
import { DefinitionList, TeliaLink } from 'common-components';
import createCorporatePortalLink from 'doings/links/createCorporatePortalLink/createCorporatePortalLink';
import { mobile } from 'doings/track/analyticsEvents';
import {
  SubscriptionReference,
  SubscriptionStatus
} from 'hooks/mobileSubscriptions/useSubscriptionDetails/subscriptionDetailsQuery';
import { useTranslation } from 'react-i18next';

import SubscriptionAccordion from '../SubscriptionAccordion/SubscriptionAccordion';

const SubscriptionSurfCards: React.FC<{
  phoneNumberHash: string;
  members: SubscriptionReference[];
  status: SubscriptionStatus;
  loading: boolean;
  expanded: boolean;
  disabled?: boolean;
}> = ({ phoneNumberHash, members, status, loading, expanded, disabled = false }) => {
  const { t } = useTranslation();
  const hasSurfCards = members.length > 0;
  const corporatePortalUrl = createCorporatePortalLink(
    'mobileSurfCards',
    { msisdn: phoneNumberHash },
    { section: 'surfCards' }
  );

  return (
    <SubscriptionAccordion
      heading={{
        label: t('subscriptions.actions.surfCards.heading', { count: members?.length ?? 0 }),
        testId: 'surf-cards-accordion',
        trackOnExpand: mobile.expandSurfCards
      }}
      actionButton={{
        visible: !disabled,
        label: t('subscriptions.actions.surfCards.manage'),
        corporatePortalUrl,
        testId: 'action-surf-cards-manage',
        trackOnClick: mobile.manageSurfCards
      }}
      phoneNumberHash={phoneNumberHash}
      status={status}
      loading={loading}
      initiallyExpanded={expanded}
    >
      {!hasSurfCards && (
        <BodyText fontWeight="normal">
          {t('subscriptions.subscriptionDetails.noActiveSurfCards')}
        </BodyText>
      )}

      {hasSurfCards && (
        <>
          <BodyText fontWeight="bold" margin={{ bottom: 'xs' }}>
            {t('subscriptions.subscriptionDetails.activeSurfCards')}
          </BodyText>

          <DefinitionList>
            {members.map(({ resolved, phoneNumber, phoneNumberHash, displayName }) => (
              <DefinitionList.Item
                data-testid={`surf-card-${phoneNumber}`}
                key={`surf-card-${phoneNumber}`}
              >
                {resolved ? (
                  <TeliaLink.Internal
                    variant="standalone"
                    to={`/subscriptions/${phoneNumberHash}`}
                    label={`${phoneNumber} ${displayName || t('subscriptions.noUserName')}`}
                    iconRight="chevron-right"
                    track={mobile.viewSurfCardDetails}
                    data-testid={`show-surf-card-${phoneNumber}`}
                    data-dd-action-name="Show Surf card"
                  />
                ) : (
                  <TeliaLink.Internal
                    variant="standalone"
                    to=""
                    label={`${phoneNumber} ${t(
                      'subscriptions.subscriptionDetails.couldNotLoadSurfCard'
                    )}`}
                    data-testid={`missing-surf-card-${phoneNumber}`}
                    disabled
                  />
                )}
              </DefinitionList.Item>
            ))}
          </DefinitionList>
        </>
      )}
    </SubscriptionAccordion>
  );
};

export default SubscriptionSurfCards;
