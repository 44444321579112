import { GridBag, Gutter } from '@gaiads/telia-react-component-library';
import Skeleton from 'common-components/Skeleton/Skeleton';

const ContactSkeleton: React.FC = () => {
  return (
    <Skeleton.Panel data-testid="contact-skeleton-panel">
      <Skeleton.Title width="50%" />

      <Gutter />

      <GridBag>
        <GridBag.Item md={6}>
          <Skeleton.Image height="9rem" />
        </GridBag.Item>

        <GridBag.Item md={6}>
          <Skeleton.Image height="9rem" />
        </GridBag.Item>
      </GridBag>
    </Skeleton.Panel>
  );
};

export default ContactSkeleton;
