import useCheckboxModel, {
  CheckboxModel
} from 'hooks/inputModels/useCheckboxModel/useCheckboxModel';
import { TextInputModel } from 'hooks/inputModels/useTextInputModel/useTextInputModel';
import useValidatableTextInputModel from 'hooks/inputModels/useValidatableTextInputModel/useValidatableTextInputModel';
import useUpdatePaymentReminders, {
  UpdatePaymentReminderStatus
} from 'hooks/invoicing/paymentReminders/useUpdatePaymentReminders/useUpdatePaymentReminders';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  PaymentReminder,
  PaymentReminderErrorItem,
  PaymentReminderStatus,
  PaymentReminderType,
  PaymentRemindersResponse
} from 'types/paymentReminder';

export type UseUpdatePaymentRemindersFormResult = {
  form: UpdatePaymentRemindersForm;
};

export type UpdatePaymentRemindersForm = {
  step: UpdatePaymentReminderStatus;
  fields: UpdatePaymentReminderFormFieldGroups;
  isChangeDetected: boolean;
  isValid: boolean;
  isNotificationShown: boolean;
  notification: UpdatePaymentReminderNotice | undefined;
  onCloseNotification: VoidFunction;
  onSave: VoidFunction;
  onCancel: VoidFunction;
};

type UpdatePaymentReminderFormFieldGroups = {
  [PaymentReminderType.Mobile]: UpdatePaymentReminderFormFields;
  [PaymentReminderType.BroadbandAndFixed]: UpdatePaymentReminderFormFields;
};

export type UpdatePaymentReminderFormFields = {
  phoneCheckModel: CheckboxModel;
  phoneInputModel: TextInputModel;
  phoneInputError: CheckboxModel;
  emailCheckModel: CheckboxModel;
  emailInputModel: TextInputModel;
};

type UpdatePaymentReminderFormInitialValues = {
  phoneCheckModel: boolean;
  phoneInputModel: string;
  emailCheckModel: boolean;
  emailInputModel: string;
};

export type UpdatePaymentReminderNotice = {
  titleKey: string;
  messageKey: string;
  variant: 'success' | 'warning' | 'error';
};

type UpdatePaymentReminderNoticeOnCondition = {
  condition: (
    step: UpdatePaymentReminderStatus,
    shown: boolean,
    errors: PaymentReminderErrorItem[]
  ) => boolean;
  notice: UpdatePaymentReminderNotice | undefined;
};

const noticeI18nKeyPrefix = 'invoices.paymentReminder.notification';
const notices: UpdatePaymentReminderNoticeOnCondition[] = [
  {
    condition: (_step, shown, _errors) => !shown,
    notice: undefined
  },
  {
    condition: (step, _shown, _errors) => step === 'success',
    notice: {
      titleKey: `${noticeI18nKeyPrefix}.updateSuccessTitle`,
      messageKey: `${noticeI18nKeyPrefix}.updateSuccessMessage`,
      variant: 'success'
    }
  },
  {
    condition: (step, _shown, errors) =>
      step === 'partialSuccess' && errors.some(({ reference }) => reference === 'MOBILE'),
    notice: {
      titleKey: `${noticeI18nKeyPrefix}.updatePartialSuccessTitle`,
      messageKey: `${noticeI18nKeyPrefix}.updatePartialSuccessOnMobileFailureMessage`,
      variant: 'error'
    }
  },
  {
    condition: (step, _shown, errors) =>
      step === 'partialSuccess' &&
      errors.some(({ reference }) => reference === 'BROADBAND_AND_FIXED'),
    notice: {
      titleKey: `${noticeI18nKeyPrefix}.updatePartialSuccessTitle`,
      messageKey: `${noticeI18nKeyPrefix}.updatePartialSuccessOnFixedFailureMessage`,
      variant: 'error'
    }
  },
  {
    condition: (step, _shown, _updateData) => step === 'partialSuccess' || step === 'failure',
    notice: {
      titleKey: `${noticeI18nKeyPrefix}.updateFailureTitle`,
      messageKey: `${noticeI18nKeyPrefix}.updateFailureMessage`,
      variant: 'warning'
    }
  }
];

const checkValid = (
  fields: UpdatePaymentReminderFormFields,
  reminder: PaymentReminder
): boolean => {
  return reminder.status === PaymentReminderStatus.Unavailable
    ? true
    : (!fields.phoneCheckModel.checked || fields.phoneInputModel.isValid) &&
        (!fields.emailCheckModel.checked || fields.emailInputModel.isValid);
};

const checkPhoneOwnerError = (errors: PaymentReminderErrorItem[], ref: string): boolean => {
  return errors.some(
    ({ code, reference }) => code === 'SUBSCRIPTIONNUMBER_NOT_FOUND' && reference === ref
  );
};

const isChangeDetected = (
  {
    emailCheckModel: { checked: emailChecked },
    emailInputModel: { value: emailValue },
    phoneCheckModel: { checked: phoneCheck },
    phoneInputModel: { value: phoneValue }
  }: UpdatePaymentReminderFormFields,
  {
    emailCheckModel,
    emailInputModel,
    phoneCheckModel,
    phoneInputModel
  }: UpdatePaymentReminderFormInitialValues
) =>
  phoneCheck !== phoneCheckModel ||
  phoneValue !== phoneInputModel ||
  emailChecked !== emailCheckModel ||
  emailValue !== emailInputModel;

const updateInitialValues = (
  reminders: PaymentReminder[],
  ref: PaymentReminderType,
  fields: UpdatePaymentReminderFormFields,
  setInitial: Dispatch<SetStateAction<UpdatePaymentReminderFormInitialValues>>
): void => {
  if (reminders.some(({ type }) => type === ref)) {
    setInitial({
      phoneCheckModel: fields.phoneCheckModel.checked,
      phoneInputModel: fields.phoneInputModel.value,
      emailCheckModel: fields.emailCheckModel.checked,
      emailInputModel: fields.emailInputModel.value
    });
  }
};

const determineUpdatePaymentReminderNotice = (
  step: UpdatePaymentReminderStatus,
  shown: boolean,
  errors: PaymentReminderErrorItem[]
): UpdatePaymentReminderNotice | undefined => {
  return notices.find(({ condition }) => condition(step, shown, errors))?.notice;
};

const useUpdatePaymentRemindersForm = (
  {
    [PaymentReminderType.Mobile]: mobileReminder,
    [PaymentReminderType.BroadbandAndFixed]: fixedReminder
  }: PaymentRemindersResponse,
  companyId?: string
): UseUpdatePaymentRemindersFormResult => {
  const [step, setStep] = useState<UpdatePaymentReminderStatus>('initial');
  const {
    updatePaymentReminders,
    status: updateStatus,
    data: updateData
  } = useUpdatePaymentReminders();

  const [mobileInitialValues, setMobileInitialValues] =
    useState<UpdatePaymentReminderFormInitialValues>({
      phoneCheckModel:
        mobileReminder.status === PaymentReminderStatus.On && !!mobileReminder.phoneNumber,
      phoneInputModel: mobileReminder.phoneNumber || '',
      emailCheckModel: mobileReminder.status === PaymentReminderStatus.On && !!mobileReminder.email,
      emailInputModel: mobileReminder.email || ''
    });

  const [fixedInitialValues, setFixedInitialValues] =
    useState<UpdatePaymentReminderFormInitialValues>({
      phoneCheckModel:
        fixedReminder.status === PaymentReminderStatus.On && !!fixedReminder.phoneNumber,
      phoneInputModel: fixedReminder.phoneNumber || '',
      emailCheckModel: fixedReminder.status === PaymentReminderStatus.On && !!fixedReminder.email,
      emailInputModel: fixedReminder.email || ''
    });

  const mobileFields: UpdatePaymentReminderFormFields = {
    phoneCheckModel: useCheckboxModel({
      initialValue: mobileInitialValues.phoneCheckModel
    }),
    phoneInputModel: useValidatableTextInputModel({
      initialValue: mobileInitialValues.phoneInputModel,
      required: false,
      expecting: {
        a: 'msisdn',
        maxLength: 25
      }
    }),
    phoneInputError: useCheckboxModel({ initialValue: false }),
    emailCheckModel: useCheckboxModel({
      initialValue: mobileInitialValues.emailCheckModel
    }),
    emailInputModel: useValidatableTextInputModel({
      initialValue: mobileInitialValues.emailInputModel,
      required: false,
      expecting: {
        a: 'emailAddress',
        maxLength: 100
      }
    })
  };

  const fixedFields = {
    phoneCheckModel: useCheckboxModel({
      initialValue: fixedInitialValues.phoneCheckModel
    }),
    phoneInputModel: useValidatableTextInputModel({
      initialValue: fixedInitialValues.phoneInputModel,
      required: false,
      expecting: {
        a: 'msisdn',
        maxLength: 25
      }
    }),
    phoneInputError: useCheckboxModel({ initialValue: false }),
    emailCheckModel: useCheckboxModel({
      initialValue: fixedInitialValues.emailCheckModel
    }),
    emailInputModel: useValidatableTextInputModel({
      initialValue: fixedInitialValues.emailInputModel,
      required: false,
      expecting: {
        a: 'emailAddress',
        maxLength: 100
      }
    })
  };

  const fields = {
    [PaymentReminderType.Mobile]: mobileFields,
    [PaymentReminderType.BroadbandAndFixed]: fixedFields
  };

  const [notificationShown, setNotificationShown] = useState(false);

  useEffect(() => {
    mobileFields.phoneInputModel.setRequired(mobileFields.phoneCheckModel.checked);
  }, [mobileFields.phoneCheckModel.checked, mobileFields.phoneInputModel]);

  useEffect(() => {
    mobileFields.emailInputModel.setRequired(mobileFields.emailCheckModel.checked);
  }, [mobileFields.emailCheckModel.checked, mobileFields.emailInputModel]);

  useEffect(() => {
    fixedFields.phoneInputModel.setRequired(fixedFields.phoneCheckModel.checked);
  }, [fixedFields.phoneCheckModel.checked, fixedFields.phoneInputModel]);

  useEffect(() => {
    fixedFields.emailInputModel.setRequired(fixedFields.emailCheckModel.checked);
  }, [fixedFields.emailCheckModel.checked, fixedFields.emailInputModel]);

  useEffect(() => {
    setStep(updateStatus);
    setNotificationShown(['success', 'partialSuccess', 'failure'].includes(updateStatus));
  }, [updateStatus]);

  useEffect(() => {
    if (['partialSuccess', 'failure'].includes(updateStatus)) {
      const errors = updateData?.errors || [];
      mobileFields.phoneInputError.setChecked(
        checkPhoneOwnerError(errors, PaymentReminderType.Mobile)
      );
      fixedFields.phoneInputError.setChecked(
        checkPhoneOwnerError(errors, PaymentReminderType.BroadbandAndFixed)
      );
    }

    if (['success', 'partialSuccess'].includes(updateStatus)) {
      const reminders = updateData?.reminders || [];
      updateInitialValues(
        reminders,
        PaymentReminderType.Mobile,
        mobileFields,
        setMobileInitialValues
      );
      updateInitialValues(
        reminders,
        PaymentReminderType.BroadbandAndFixed,
        fixedFields,
        setFixedInitialValues
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus, updateData]);

  const isAvailable = (reminder: PaymentReminder) =>
    reminder.status !== PaymentReminderStatus.Unavailable;

  const mapReminder = (reminder: PaymentReminder, fields: UpdatePaymentReminderFormFields) => ({
    type: reminder.type,
    status:
      fields.phoneCheckModel.checked || fields.emailCheckModel.checked
        ? PaymentReminderStatus.On
        : PaymentReminderStatus.Off,
    subscriptionNumber: fields.phoneCheckModel.checked ? fields.phoneInputModel.value : undefined,
    emailAddress: fields.emailCheckModel.checked ? fields.emailInputModel.value : undefined
  });

  return {
    form: {
      step,
      fields,
      isChangeDetected:
        isChangeDetected(mobileFields, mobileInitialValues) ||
        isChangeDetected(fixedFields, fixedInitialValues),
      isValid: checkValid(mobileFields, mobileReminder) && checkValid(fixedFields, fixedReminder),
      isNotificationShown: notificationShown,
      notification: determineUpdatePaymentReminderNotice(
        step,
        notificationShown,
        updateData?.errors ? updateData.errors : []
      ),
      onCloseNotification: () => setNotificationShown(false),
      onSave: () => {
        updatePaymentReminders({
          variables: {
            requestData: {
              companyId,
              reminders: [
                ...(isAvailable(mobileReminder) ? [mapReminder(mobileReminder, mobileFields)] : []),
                ...(isAvailable(fixedReminder) ? [mapReminder(fixedReminder, fixedFields)] : [])
              ]
            }
          }
        });
      },
      onCancel: () => {
        mobileFields.phoneCheckModel.setChecked(mobileInitialValues.phoneCheckModel);
        mobileFields.phoneInputModel.setValue(mobileInitialValues.phoneInputModel);
        mobileFields.phoneInputError.setChecked(false);
        mobileFields.emailCheckModel.setChecked(mobileInitialValues.emailCheckModel);
        mobileFields.emailInputModel.setValue(mobileInitialValues.emailInputModel);

        fixedFields.phoneCheckModel.setChecked(fixedInitialValues.phoneCheckModel);
        fixedFields.phoneInputModel.setValue(fixedInitialValues.phoneInputModel);
        fixedFields.phoneInputError.setChecked(false);
        fixedFields.emailCheckModel.setChecked(fixedInitialValues.emailCheckModel);
        fixedFields.emailInputModel.setValue(fixedInitialValues.emailInputModel);
      }
    }
  };
};

export default useUpdatePaymentRemindersForm;
