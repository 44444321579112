import { ListView } from 'common-components';
import { useEditableListData } from 'contexts/EditableListContext/EditableListContext';
import { SortState } from 'hooks/useSortableHeadings/useSortableHeadings';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { ColumnVisibility, ListViewColumnSetup } from 'types/listConfig';
import { ThreeSixtyProductInventoryItem } from 'types/threeSixtyProductInventory';

export const ThreeSixtyProductInventoryListViewColumnSetup: ListViewColumnSetup<
  [TFunction, SortState, (field: string) => void, boolean]
> = {
  listId: 'threeSixtyProductInventory',
  getColumns: (_t: TFunction, _sortState: SortState, _sortClick: (field: string) => void) => ({
    name: {
      heading: 'Nimi',
      baseVisibility: ColumnVisibility.REQUIRE
    },
    category: {
      heading: 'Kategoria',
      baseVisibility: ColumnVisibility.SHOW
    },
    agreement: {
      heading: 'Sopimus',
      baseVisibility: ColumnVisibility.SHOW
    },
    externalId: {
      heading: 'Tunniste',
      baseVisibility: ColumnVisibility.REQUIRE
    },
    companyCode: {
      heading: 'Yritys',
      baseVisibility: ColumnVisibility.SHOW
    },
    productType: {
      heading: 'Tuote',
      baseVisibility: ColumnVisibility.SHOW
    },
    productSubType: {
      heading: 'Tuotealakategoria',
      baseVisibility: ColumnVisibility.HIDE
    },
    productFamily: {
      heading: 'Tuoteperhe',
      baseVisibility: ColumnVisibility.HIDE
    },
    place: {
      heading: 'Osoite',
      baseVisibility: ColumnVisibility.SHOW
    }
  })
};

const mapAddress = (address: ThreeSixtyProductInventoryItem['place']) => {
  const firstAddress = address?.[0];
  if (firstAddress) {
    const addressParts = [
      [firstAddress.streetName, firstAddress.streetNr].filter(Boolean).join(' '),
      [firstAddress.postcode, firstAddress.city].filter(Boolean).join(' '),
      firstAddress.country
    ].filter(Boolean);
    return addressParts.join(', ');
  }
  return '';
};

const ThreeSixtyProductInventoryListView: React.FC<{
  products: ThreeSixtyProductInventoryItem[];
  loading: boolean;
}> = ({ products, loading }) => {
  const { t } = useTranslation();
  const { columns } = useEditableListData();

  return (
    <ListView
      data-testid="three-sixty-product-inventory-list"
      columns={columns}
      mobileLayout={{
        titleRow: { col: 'name' },
        rows: [{ col: 'name' }]
      }}
      loading={loading}
      listData={products}
      noItemsNotice={t('internetConnections.list.noItemsFound')}
    >
      {({
        name,
        category,
        agreement,
        companyCode,
        externalId,
        productType,
        productSubType,
        productFamily,
        place
      }) => (
        <ListView.Row data-testid="internet-connections-list-row-item">
          <ListView.RowShape
            cells={{
              name: { data: name, props: {} },
              category: { data: category, props: {} },
              agreement: { data: agreement?.[0]?.name, props: {} },
              externalId: {
                data: externalId,
                props: {}
              },
              companyCode: {
                data: companyCode,
                props: {}
              },
              productType: { data: productType, props: {} },
              productSubType: { data: productSubType, props: {} },
              productFamily: { data: productFamily, props: {} },
              place: { data: mapAddress(place), props: {} }
            }}
          />
        </ListView.Row>
      )}
    </ListView>
  );
};

export default ThreeSixtyProductInventoryListView;
