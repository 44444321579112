import { gql } from '@apollo/client';
import { useUserData } from 'contexts/UserContext/UserContext';
import useApiStatus from 'hooks/useApiStatus/useApiStatus';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { DynamicForms } from 'types/dynamicForms';

export const dynamicFormQuery = gql`
  query GetDynamicForm($groupName: String!, $formName: String!, $language: Language!) {
    dynamicForm(groupName: $groupName, formName: $formName, language: $language) {
      name
      label
      infotext
      metainfo {
        prefillSubjectFrom
        subject
        hideStaticFields
        requireStaticFields
        relabelStaticFields {
          name
          label
        }
        claudia
        deeplinks {
          match
          fields {
            name
            value
          }
        }
        overrides {
          match
          claudia
          subject
          hideStaticFields
          requireStaticFields
          relabelStaticFields {
            name
            label
          }
          deadEnd
          contactFields {
            info
            showAddressFields
          }
        }
        allowedAttachmentTypes
      }
      fields {
        id
        type
        showWhen
        hideWhen
        ...dynamicFormCheckboxFieldData
        ...dynamicFormCheckgroupFieldData
        ...dynamicFormDateFieldData
        ...dynamicFormDropdownFieldData
        ...dynamicFormInfotextFieldData
        ...dynamicFormInputFieldData
        ...dynamicFormSinkFieldData
        ...dynamicFormSubheaderFieldData
        ...dynamicFormTextareaFieldData
      }
    }
  }

  fragment dynamicFormCheckboxFieldData on DynamicFormCheckboxField {
    label
    tooltip
    name
    mandatory
  }

  fragment dynamicFormCheckgroupFieldData on DynamicFormCheckgroupField {
    label
    tooltip
    name
    mandatory
    options {
      id
      label
      value
    }
  }

  fragment dynamicFormDateFieldData on DynamicFormDateField {
    label
    tooltip
    name
    mandatory
    dateRange {
      startDate
      endDate
    }
    dateWithMonthOnlySelection
    dateWithTimeSelection
    dateAllowsAllDays
  }

  fragment dynamicFormDropdownFieldData on DynamicFormDropdownField {
    label
    tooltip
    name
    mandatory
    options {
      id
      label
      value
    }
    multiple
  }

  fragment dynamicFormInfotextFieldData on DynamicFormInfotextField {
    label
  }

  fragment dynamicFormInputFieldData on DynamicFormInputField {
    label
    tooltip
    name
    mandatory
    format
  }

  fragment dynamicFormSinkFieldData on DynamicFormSinkField {
    label
    name
    sinkOutputFormat
  }

  fragment dynamicFormSubheaderFieldData on DynamicFormSubheaderField {
    label
    tooltip
  }

  fragment dynamicFormTextareaFieldData on DynamicFormTextareaField {
    label
    tooltip
    name
    mandatory
  }
`;

const useDynamicForm = (groupName: string, formName: string) => {
  const { language } = useUserData();
  const { data, error, loading } = useCallBackend<{
    dynamicForm: DynamicForms.DynamicForm;
  }>({
    query: dynamicFormQuery,
    queryVariables: {
      groupName,
      formName,
      language
    },
    fetchPolicy: 'network-only'
  });

  const items = data?.dynamicForm ? [data.dynamicForm] : [];
  const viewStatus = useApiStatus({
    loading,
    loadingMore: false,
    loaded: !loading,
    items,
    error,
    query: dynamicFormQuery
  });

  return {
    form: data?.dynamicForm,
    viewStatus
  };
};

export default useDynamicForm;
