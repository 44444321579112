import { AnalyticsEvent, createAnalyticsEvent } from './analyticsEvents';

declare global {
  interface Window {
    dataLayer: Array<FullAnalyticsEvent>;
  }
}

interface FullAnalyticsEvent extends AnalyticsEvent {
  location: string;
  event: 'businessPortalEvent';
  eventCategory: 'Self Service B2B';
}

/**
 * Custom analytics' properties tracked by business portal events.
 *
 * Custom analytics' properties accumulate in the data layer. Hence, when a
 * custom property is no longer necessary for an event and was tracked with the
 * previous event, it must be cleared from the data layer by setting it to
 * `null` with the next tracked event as per GTM's best practices. Otherwise,
 * next events are tracked as if such a property also applied to those
 * subsequent events.
 */
const trackedCustomProps: string[] = [];
const getAndPopTrackedCustomPropsForClearing = () => {
  if (trackedCustomProps.length) {
    const result = trackedCustomProps.reduce((acc, key) => {
      acc[key] = null;
      return acc;
    }, {} as Record<string, unknown>);

    trackedCustomProps.splice(0, trackedCustomProps.length);
    return result;
  }

  return [];
};

const trackEvent = (event: AnalyticsEvent): void => {
  window.dataLayer.push({
    ...getAndPopTrackedCustomPropsForClearing(),
    ...event,
    event: 'businessPortalEvent',
    eventCategory: 'Self Service B2B',
    location: window.location.pathname
  });
};

export const trackCustomEvent = (label: string, action: string) =>
  trackEvent(createAnalyticsEvent(label, action));

export const trackEventIfPresent = (event?: AnalyticsEvent): void => {
  if (event) {
    trackEvent(event);
  }
};

export const trackEventWithCustomProps = (
  event: AnalyticsEvent,
  props: Record<string, string | undefined>
): void => {
  trackEvent({
    ...event,
    ...props
  });
  trackedCustomProps.push(...Object.keys(props));
};

export default trackEvent;
