import { ListView } from 'common-components';
import { useEditableListData } from 'contexts/EditableListContext/EditableListContext';
import { SortState, getSort } from 'hooks/useSortableHeadings/useSortableHeadings';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { InternetConnectionsItem, SortableColumns } from 'types/internetConnections';
import { ColumnVisibility, ListViewColumnSetup } from 'types/listConfig';

export const InternetConnectionsListViewColumnSetup: ListViewColumnSetup<
  [TFunction, SortState, (field: string) => void, boolean]
> = {
  listId: 'assets',
  getColumns: (
    t: TFunction,
    sortState: SortState,
    sortClick: (field: string) => void,
    showCompanyByDefault: boolean
  ) => ({
    name: {
      heading: t('internetConnections.list.columns.name'),
      sort: getSort(sortState, sortClick, SortableColumns.name),
      baseVisibility: ColumnVisibility.REQUIRE
    },
    additionalInfo: {
      heading: t('internetConnections.list.columns.additionalInformation'),
      baseVisibility: ColumnVisibility.SHOW
    },
    country: {
      width: '0.5x',
      heading: t('internetConnections.list.columns.country'),
      sort: getSort(sortState, sortClick, SortableColumns.country),
      baseVisibility: ColumnVisibility.SHOW
    },
    displayId: {
      width: '0.75x',
      heading: t('internetConnections.list.columns.displayId'),
      sort: getSort(sortState, sortClick, SortableColumns.displayId),
      baseVisibility: ColumnVisibility.REQUIRE
    },
    company: {
      width: '0.5x',
      heading: t('common.company.label'),
      tooltip: t('common.company.tooltip'),
      baseVisibility: showCompanyByDefault ? ColumnVisibility.SHOW : ColumnVisibility.HIDE
    }
  })
};

const InternetConnectionsListView: React.FC<{
  assets: InternetConnectionsItem[];
  loading: boolean;
}> = ({ assets, loading }) => {
  const { t } = useTranslation();
  const { columns } = useEditableListData();

  return (
    <ListView
      data-testid="internet-connections-list"
      columns={columns}
      mobileLayout={{
        titleRow: { col: 'displayId' },
        rows: [{ col: 'name' }, { col: 'additionalInfo' }, { col: 'country' }, { col: 'company' }]
      }}
      loading={loading}
      listData={assets}
      noItemsNotice={t('internetConnections.list.noItemsFound')}
    >
      {({ id, name, displayId, address, isDNSAsset, domainName, companyCode, companyName }) => (
        <ListView.Row
          data-testid="internet-connections-list-row-item"
          linkTo={`/internet-connections/${id}`}
        >
          <ListView.RowShape
            cells={{
              name: { data: name, props: {} },
              additionalInfo: {
                data: isDNSAsset
                  ? domainName
                  : joinAddress(address.streetAddress, address.postalAddress),
                props: {}
              },
              country: { data: address.country, props: {} },
              displayId: { data: displayId, props: {} },
              company: {
                data: companyCode || companyName,
                props: { tooltip: `${companyCode} ${companyName}`, wordWrap: 'ellipsis' }
              }
            }}
          />
        </ListView.Row>
      )}
    </ListView>
  );
};

const joinAddress = (streetAddress: string, postalAddress: string) =>
  [streetAddress, postalAddress].filter(Boolean).join(', ');

export default InternetConnectionsListView;
