import {
  BodyText,
  Flex,
  ProductCard,
  SupportedTagColor
} from '@gaiads/telia-react-component-library';
import { Analytics } from 'common-components';
import ExternalLink from 'common-components/Link/ExternalLink/ExternalLink';
import { useUserData } from 'contexts/UserContext/UserContext';
import { formatFavoriteDevicePrice } from 'doings/formatFavoriteDevicePrice/formatFavoriteDevicePrice';
import { devices } from 'doings/track/analyticsEvents';
import { useTranslation } from 'react-i18next';
import { FavoriteDevicesProduct } from 'types/device';

import styles from './FavoriteDeviceTile.module.scss';

export const FavoriteDeviceTile: React.FC<{ product: FavoriteDevicesProduct }> = ({ product }) => {
  const { t } = useTranslation();
  const { language } = useUserData();
  const { imageUrl, id, url, hasPriceVariation, title, tag } = product;

  // If product does not have description, utilize min-height with this
  let alternativePriceText = <>{'\u00a0'}</>;

  const cheapestPrice = formatFavoriteDevicePrice(
    product.cheapestPrice,
    t('daas.favoriteDevices.month'),
    t('daas.favoriteDevices.shortForMonth')
  );
  const cheapestOneTimePayment = formatFavoriteDevicePrice(
    product.cheapestOneTimePrice,
    t('daas.favoriteDevices.month'),
    t('daas.favoriteDevices.shortForMonth')
  );

  if (hasPriceVariation) {
    alternativePriceText = (
      <span>
        <BodyText tagName="span" color="black" size="sm">
          {t('daas.favoriteDevices.direct')} {cheapestOneTimePayment.short.full}
        </BodyText>
        {cheapestOneTimePayment.short?.normalFull && (
          <BodyText tagName="span" color="gray700" size="sm">
            {'\u00a0 (' +
              t('daas.favoriteDevices.normal') +
              ' ' +
              cheapestOneTimePayment.short.normalFull +
              ')'}
          </BodyText>
        )}
      </span>
    );
  }

  const priceContent: Parameters<typeof ProductCard>[0]['priceContent'] = {
    lang: language,
    prefix: product.hasMultiplePrices ? t('daas.favoriteDevices.startingPrefix') : '\u00a0',
    value: cheapestPrice?.short?.amount,
    unit: cheapestPrice?.short?.unit,
    priceVariation: alternativePriceText,
    normalPrice: cheapestPrice?.short?.normalFull
      ? {
          prefix: '(' + t('daas.favoriteDevices.normal'),
          value: cheapestPrice.short.normalFull,
          postfix: ')'
        }
      : undefined
  };

  const wrapper = ({ children, ...props }: { children: React.ReactNode }) => (
    <Analytics whenClicked={devices.viewFavoriteDevice} tagName="div">
      <ExternalLink
        data-testid={`devices-view-favorite-device-${id}-wrapper`}
        block
        to={url}
        {...props}
      >
        {children}
      </ExternalLink>
    </Analytics>
  );

  return (
    <Flex
      occupyVerticalSpace
      className={styles.productCard}
      lang="fi"
      data-testid="devices-view-favorite-device-card-wrapper"
    >
      <ProductCard
        data-test-id="devices-view-favorite-device-card"
        titleText={title}
        priceContent={priceContent}
        tagText={tag?.text}
        tagColor={tag?.color as SupportedTagColor}
        imageSrc={imageUrl}
        wrapper={wrapper}
      />
    </Flex>
  );
};
