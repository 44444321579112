import Accordion from './Accordion/Accordion';
import ActionButtonGroup from './ActionButtonGroup/ActionButtonGroup';
import ActiveCompanySelector from './ActiveCompanySelector/ActiveCompanySelector';
import Analytics from './Analytics/Analytics';
import Authorize from './Authorize/Authorize';
import NoPrivilegesTooltip from './Authorize/NoPrivilegesTooltip/NoPrivilegesTooltip';
import BackButton from './BackButton/BackButton';
import BodyTextClickable from './BodyTextClickable/BodyTextClickable';
import BreadcrumbWithCompanySelector from './Breadcrumb/BreadcrumbWithCompanySelector';
import RouteBreadcrumb from './Breadcrumb/RouteBreadcrumb';
import { Breakpoint } from './Breakpoint/Breakpoint';
import { Button } from './Button/Button';
import CollapsibleBlock from './CollapsibleBlock/CollapsibleBlock';
import Contact from './Contact/Contact';
import { ContactLink } from './Contact/ContactLink';
import CopyButton from './CopyButton/CopyButton';
import DataPanel from './DataPanel/DataPanel';
import DetailsLoadingErrorPanel from './DataPanel/DetailsLoadingErrorPanel/DetailsLoadingErrorPanel';
import DetailsLoadingOverlay from './DataPanel/DetailsLoadingOverlay/DetailsLoadingOverlay';
import DefinitionList from './DefinitionList/DefinitionList';
import { Notices } from './DetailsPage/DetailsPageNotices';
import { DragAndDropProvider } from './DragAndDropProvider/DragAndDropProvider';
import ExpandableInlineNotification from './ExpandableInlineNotification/ExpandableInlineNotification';
import { FormField } from './Forms/FormField/FormField';
import FormFieldHelperText from './Forms/FormField/Inputs/FormFieldHelperText';
import RequiredFieldsLabel from './Forms/RequiredFieldsLabel/RequiredFieldsLabel';
import FullPageLoader from './FullPageLoader/FullPageLoader';
import {
  HeadingH1,
  HeadingH2,
  HeadingH3,
  HeadingH4,
  HeadingH5,
  HeadingPrice
} from './Heading/Heading';
import { IconButton } from './IconButton/IconButton';
import InlineNotification from './InlineNotification/InlineNotification';
import InlineNotificationLite from './InlineNotificationLite/InlineNotificationLite';
import SingleColumnLayout from './Layout/SingleColumnLayout';
import TwoColumnLayout from './Layout/TwoColumnLayout';
import DownloadLink from './Link/DownloadLink/DownloadLink';
import {
  TeliaClickLink,
  TeliaClickLinkTranslatable,
  TeliaExternalLink,
  TeliaExternalLinkTranslatable,
  TeliaInternalLink,
  TeliaInternalLinkTranslatable,
  TeliaPortalLink,
  TeliaPortalLinkTranslatable
} from './Link/VocaLink/VocaLink';
import { LinkGroup } from './LinkGroup/LinkGroup';
import ListView from './ListView/ListView';
import ListViewHeader from './ListViewHeader/ListViewHeader';
import LongTextWrapper from './LongTextWrapper/LongTextWrapper';
import Memo from './Memo/Memo';
import MobilePadding from './MobilePadding/MobilePadding';
import ModalButtons from './ModalButtons/ModalButtons';
import ModalDialog from './ModalDialog/ModalDialog';
import ModalDrawer from './ModalDrawer/ModalDrawer';
import Optional from './Optional/Optional';
import OverflowMenu from './OverflowMenu/OverflowMenu/OverflowMenu';
import OverflowMenuToggle from './OverflowMenu/OverflowMenuToggle/OverflowMenuToggle';
import OverflowMenuWrapper from './OverflowMenu/OverflowMenuWrapper/OverflowMenuWrapper';
import PageWithFormLayout from './PageWithFormLayout/PageWithFormLayout';
import PageWithSubnavigation from './PageWithSubnavigation/PageWithSubnavigation';
import { RemBreakpoint } from './RemBreakpoint/RemBreakpoint';
import { Repeat } from './Repeat/Repeat';
import Skeleton from './Skeleton/Skeleton';
import { SmartTooltip } from './SmartTooltip/SmartTooltip';
import { SortableCheckboxes } from './SortableCheckboxes';
import StatusTag from './StatusTag/StatusTag';
import Subnavigation from './Subnavigation/Subnavigation';
import TextWithInlineHtml from './TextWithInlineHtml/TextWithInlineHtml';
import { TextWithInlineHtmlExtended } from './TextWithInlineHtml/TextWithInlineHtmlExtended';
import { TileButton, TileButtonGrid } from './TileButtonGrid';

// Re-exported common types
export type { DefinitionListMultilineItemProps } from 'common-components/DefinitionList/DefinitionList';
export type { InlineNotificationType } from 'common-components/InlineNotification/InlineNotification';
export type { IndicatorStatusDefinition } from 'common-components/StatusTag/StatusTag';
export type { TileButtonProps } from './TileButtonGrid';

// Re-exported common components
const Forms = { Field: FormField, FieldHelperText: FormFieldHelperText, RequiredFieldsLabel };
const Layout = { SingleColumn: SingleColumnLayout, TwoColumn: TwoColumnLayout };
const TeliaLink = {
  Click: TeliaClickLink,
  Download: DownloadLink,
  External: TeliaExternalLink,
  Internal: TeliaInternalLink,
  Portal: TeliaPortalLink,
  Translatable: {
    Click: TeliaClickLinkTranslatable,
    External: TeliaExternalLinkTranslatable,
    Internal: TeliaInternalLinkTranslatable,
    Portal: TeliaPortalLinkTranslatable
  }
};

const Heading = {
  H1: HeadingH1,
  H2: HeadingH2,
  H3: HeadingH3,
  H4: HeadingH4,
  H5: HeadingH5,
  Price: HeadingPrice
};

export {
  Accordion,
  ActionButtonGroup,
  ActiveCompanySelector,
  Analytics,
  Authorize,
  BackButton,
  BodyTextClickable,
  BreadcrumbWithCompanySelector,
  Breakpoint,
  Button,
  CollapsibleBlock,
  Contact,
  ContactLink,
  CopyButton,
  DataPanel,
  DefinitionList,
  DetailsLoadingErrorPanel,
  DetailsLoadingOverlay,
  DragAndDropProvider,
  ExpandableInlineNotification,
  Forms,
  FullPageLoader,
  Heading,
  IconButton,
  InlineNotification,
  InlineNotificationLite,
  Layout,
  LinkGroup,
  ListView,
  ListViewHeader,
  LongTextWrapper,
  Memo,
  MobilePadding,
  ModalButtons,
  ModalDialog,
  ModalDrawer,
  NoPrivilegesTooltip,
  Notices,
  Optional,
  OverflowMenu,
  OverflowMenuToggle,
  OverflowMenuWrapper,
  PageWithFormLayout,
  PageWithSubnavigation,
  RemBreakpoint,
  Repeat,
  RouteBreadcrumb,
  Skeleton,
  SmartTooltip,
  SortableCheckboxes,
  StatusTag,
  Subnavigation,
  TeliaLink,
  TextWithInlineHtml,
  TextWithInlineHtmlExtended,
  TileButton,
  TileButtonGrid
};
