import { AuthorizationParams } from 'types/authorization';

const B2B_GROUP_TYPES = ['b2b', 'b2b_sales_portal'];

const hasGroupType = (
  companyGroupType: ActiveGroupType,
  expectedGroupType?: ActiveGroupType
): boolean => {
  return (
    !expectedGroupType ||
    companyGroupType === expectedGroupType ||
    (B2B_GROUP_TYPES.includes(expectedGroupType) && B2B_GROUP_TYPES.includes(companyGroupType))
  );
};

const hasOnePermission = (
  userPermissions: UserPermission[],
  permission?: UserPermission
): boolean => {
  return !permission || userPermissions.includes(permission);
};

const hasAnyPermission = (
  userPermissions: UserPermission[],
  permissions?: UserPermission[]
): boolean => {
  return !permissions || permissions.some((permission) => userPermissions.includes(permission));
};

const userCompanyHasPermission = (company: UserCompany, params?: AuthorizationParams) => {
  if (params === undefined) {
    return true;
  }

  return (
    hasGroupType(company.companyGroupType, params.activeGroup) &&
    hasOnePermission(company.permissions, params.onePermission) &&
    hasAnyPermission(company.permissions, params.anyPermission)
  );
};

export default userCompanyHasPermission;
