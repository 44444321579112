import { GrowlNotification } from '@gaiads/telia-react-component-library';
import styles from 'B2XApp/TopNotices/FixedBar.module.scss';
import { useFeatureFlagsData } from 'contexts/FeatureFlagContext/FeatureFlagContext';
import { useUserData } from 'contexts/UserContext/UserContext';
import { notifications } from 'doings/track/analyticsEvents';
import trackEvent from 'doings/track/trackEvent';
import userHasPermission from 'doings/userHasPermission/userHasPermission';
import useClearNewMessagesNotification from 'hooks/customerSpecificMessages/useClearNewMessagesNotification';
import useNewMessagesNotification from 'hooks/customerSpecificMessages/useNewMessagesNotification';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const CustomerSpecificMessagesBar: React.FC = () => {
  const { t } = useTranslation();
  const user = useUserData();
  const { hasFeature } = useFeatureFlagsData();
  const { clearNewCustomerSpecificMessagesNotification } = useClearNewMessagesNotification();
  const [isClosed, setIsClosed] = useState(false);

  const userHasPermissionToCustomerSpecificMessages = userHasPermission(user)({
    onePermission: 'B2B_PORTAL',
    anyPermission: ['ADMIN']
  });

  const { newCustomerSpecificMessages } = useNewMessagesNotification({
    skip: !hasFeature('EART-5391') || !userHasPermissionToCustomerSpecificMessages
  });

  if (isClosed || newCustomerSpecificMessages === 0) {
    return null;
  }

  return (
    <GrowlNotification
      data-testid="new-customer-specific-messages-notification-bar"
      className={styles.fixedBar}
      notification={t('notifications.newCustomerSpecificMessages', {
        count: newCustomerSpecificMessages
      })}
      variant="info"
      ctaText={t('notifications.readMore')}
      ctaOnClick={() => {
        clearNewCustomerSpecificMessagesNotification();
        trackEvent(notifications.navigateToCustomerSpecificMessages);
      }}
      closeOnClick={() => setIsClosed(true)}
    />
  );
};

export default CustomerSpecificMessagesBar;
