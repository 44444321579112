import { Gutter } from '@gaiads/telia-react-component-library';
import routeAccessGroups from 'B2XApp/appRouteAccessGroups';
import ContactUsPanel from 'B2XApp/ContactUs/ContactUsPanel';
import useGetDashboardNotificationRows from 'B2XApp/Dashboard/DashboardNavigation/DashboardNotifications/useGetDashboardNotificationRows';
import { Authorize, Button } from 'common-components';
import { contactUs } from 'doings/track/analyticsEvents';
import { useRouting } from 'hooks/useRouting/useRouting';
import { useTranslation } from 'react-i18next';

import ContactUsMessageLink from './ContactUsMessageLink';

const FaultReportsPanel: React.FC = () => {
  const { t } = useTranslation();
  const { goto } = useRouting();
  const tickets = useGetDashboardNotificationRows({ onlyContacts: true }).find(
    ({ dataIdSuffix }) => dataIdSuffix === 'tickets'
  );

  return (
    <ContactUsPanel
      title={t('contactUs.panel.title.faultReports')}
      titleIcon="flag"
      content={t('contactUs.panel.text.faultReports')}
      data-tour-stop="contact-us-fault-report-panel"
      data-testid="contact-us-fault-report-panel"
    >
      <ContactUsMessageLink
        data-testid="link-to-tickets"
        label={t('navigationalLink.title.troubleTickets')}
        target="/tickets"
        item={tickets}
        enabledWhen={routeAccessGroups.INCIDENT_TICKETS}
        analyticsEvent={contactUs.faultReportList}
      />

      <Gutter size="sm" />

      <Authorize
        enabledWhen={routeAccessGroups.INCIDENT_TICKETS_NEW}
        analyticsEvent={contactUs.faultReportSubmit}
        element={
          <Button
            data-testid="new-ticket-button"
            type="button"
            variant="primary"
            onClick={() =>
              goto({
                to: '/tickets/new'
              })
            }
          >
            {t('tickets.createNew')}
          </Button>
        }
      />
    </ContactUsPanel>
  );
};

export default FaultReportsPanel;
