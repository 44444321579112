import { useUserData } from 'contexts/UserContext/UserContext';
import getNavigationalLinks, {
  NavigationalLinks
} from 'doings/getNavigationalLinks/getNavigationalLinks';
import isDemoEnvironment from 'doings/isDemoEnvironment/isDemoEnvironment';
import { multiplex } from 'doings/multiplex/multiplex';
import userHasPermission from 'doings/userHasPermission/userHasPermission';
import { useMemo } from 'react';

import TextWithInlineHtml from './TextWithInlineHtml';

/**
 * Renders text with a single level of HTML mark-up, keeping the following tags
 * while stripping away all other HTML tags: `a`, `br`, `p`, `ol`, `ul`, `li`,
 * `i`, `b` and `strong`.
 *
 * Without providing explicit support, simply rendering text with HTML mark-up
 * would escape special HTML characters and render mark-up as text, which is
 * undesireable for texts intended to potentially store inline links or lists.
 *
 * The text can contain basic inline HTML as supported by the common component
 * `TextWithInlineHtml` component. Specially formatted link placeholders are
 * converted to inline links according to the user's available navigational
 * links.
 *
 * Link placeholder format:
 *
 * ```html
 * {{link:(?<linkId>[^#}"]+(?<anchor>#[^}"]+)?)}}Label{{link}}.
 * ```
 *
 * Examples:
 *
 * ```html
 * <ul>
 *   <li>Going {{link:link-to-dashboard}}places{{link}}.</li>
 *   <li>Going {{link:link-to-reports}}somewhere else{{link}}.</li>
 *   <li>Going {{link:link-which-is-unavailable}}to an unavailable place{{link}}.</li>
 *   <li>Going {{link:link-disabled-in-demo}}to a demo-disabled place in demo env{{link}}.</li>
 *   <li>Going {{link:https://www.telia.fi/yrityksille}}up{{link}}.</li>
 *   <li>Going {{link:https://www.telia.fi/yrityksille#anchor}}down{{link}}.</li>
 *   <li>Going {{link:http://www.telia.fi/yrityksille}}insecure is not supported{{link}}.</li>
 * </ul>
 * ```
 *
 * …convert to…
 *
 * ```html
 * <ul>
 *   <li>Going <a href="/yrityksille/yritysportaali/" target="_blank">places</a>.</li>
 *   <li>Going <a href="/mytelia/yritysportaali/reports/" target="_blank">somewhere else</a>.</li>
 *   <li>Going <a aria-disabled="true" tabIndex="-1">to an unavailable place</a>.</li>
 *   <li>Going <a aria-disabled="true" tabIndex="-1">to a demo-disabled place in demo env</a>.</li>
 *   <li>Going <a href="https://www.telia.fi/yrityksille" target="_blank">up</a>.</li>
 *   <li>Going <a href="https://www.telia.fi/yrityksille#anchor" target="_blank">down</a>.</li>
 *   <li>Going <a aria-disabled="true" tabIndex="-1">insecure is not supported</a>.</li>
 * </ul>
 * ```
 */
export const TextWithInlineHtmlExtended: React.FC<{ text: string }> = ({ text }) => {
  if (text.includes(LINK_PLACEHOLDER_PREFIX)) {
    return <TextWithInlineHtmlLinkified text={text} />;
  }

  return <TextWithInlineHtml text={text} />;
};

const TextWithInlineHtmlLinkified: React.FC<{ text: string }> = ({ text }) => {
  const user = useUserData();
  const checkUserPermissions = userHasPermission(user);
  const navLinks = getNavigationalLinks(user);
  const substitutedText = useMemo(
    () =>
      text.replace(LINK_PLACEHOLDER_REGEXP, (_match, linkId, anchor, linkLabel) => {
        return multiplex([
          () => {
            const navLink = matchNavigationalLinkById(navLinks.navigationalLinks, linkId);
            if (!navLink || !checkUserPermissions(navLink.visibleWhen)) {
              return `<a aria-disabled="true" tabIndex="-1">${linkLabel}</a>`;
            }

            const { to, linksToB2B, external } = navLink;
            const directsOutOfB2XPortal = linksToB2B || external;
            if (directsOutOfB2XPortal && isDemoEnvironment()) {
              return `<a aria-disabled="true" tabIndex="-1">${linkLabel}</a>`;
            }

            const href = directsOutOfB2XPortal ? to : `${process.env.PUBLIC_URL}${to}`;
            return `<a href="${href}${anchor ?? ''}" target="_blank">${linkLabel}</a>`;
          },
          [
            linkId.startsWith('https://'),
            () => {
              return isDemoEnvironment()
                ? `<a aria-disabled="true" tabIndex="-1">${linkLabel}</a>`
                : `<a href="${linkId}${anchor ?? ''}" target="_blank">${linkLabel}</a>`;
            }
          ],
          [
            linkId.startsWith('http://'),
            () => {
              return `<a aria-disabled="true" tabIndex="-1">${linkLabel}</a>`;
            }
          ]
        ])();
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [text]
  );

  return <TextWithInlineHtml text={substitutedText} />;
};

const matchNavigationalLinkById = (navigationalLinks: NavigationalLinks, linkId: string) =>
  Object.values(navigationalLinks).find((u) => u.id === linkId);

const LINK_PLACEHOLDER_PREFIX = '{{link:';
const LINK_PLACEHOLDER_REGEXP =
  /{{link:(?<linkId>[^#}"]+)(?<anchor>#[^}"]+)?}}(?<linkLabel>[^{]+){{link}}/g;
