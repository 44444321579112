import { gql } from '@apollo/client';
import { FormattedExecutionResult } from 'graphql';
import useCallBackendMutation from 'hooks/useCallBackend/useCallBackendMutation';

export const sendTicketMessageMutation = gql`
  mutation ReplyToFaultReport($id: ID!, $note: String!, $attachment: Upload) {
    sendMessage(id: $id, note: $note, attachment: $attachment) {
      id
    }
  }
`;

export type SendTicketMessageParameters = {
  id: string;
  note: string;
  attachment?: File;
};

export type SendTicketMessageResponse = {
  id: string;
};

type SendTicketMessage = (
  variables: SendTicketMessageParameters
) => Promise<FormattedExecutionResult<SendTicketMessageResponse>>;

export default () => {
  const { runMutation, data, loading, error } =
    useCallBackendMutation<SendTicketMessageResponse>(sendTicketMessageMutation);

  const sendTicketMessage: SendTicketMessage = (variables: SendTicketMessageParameters) =>
    runMutation({ variables });

  return {
    sendTicketMessage,
    data,
    loading,
    replySent: !!data && !loading && !error,
    hasError: !!error
  };
};
