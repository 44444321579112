import { Flex, Gutter, Panel, RadioButton } from '@gaiads/telia-react-component-library';
import { Icon } from '@teliafi/fi-ds';
import DashboardContentSearch from 'B2XApp/Dashboard/DashboardNavigation/DashboardSearch/DashboardContentSearch/DashboardContentSearch';
import DashboardContentSearchResults from 'B2XApp/Dashboard/DashboardNavigation/DashboardSearch/DashboardContentSearch/DashboardContentSearchResults';
import PukCodeSearchResults from 'B2XApp/Dashboard/DashboardNavigation/DashboardSearch/PukCodeSearch/PukCodeSearchResults';
import { Heading } from 'common-components';
import { DashboardSearchProvider } from 'contexts/SearchContext/DashboardSearchContext';
import useIsAskForHelpAvailable from 'hooks/askForHelp/useIsAskForHelpAvailable';
import useScrollToAnchor from 'hooks/useScrollToAnchor/useScrollToAnchor';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export type SearchType = 'pukCodes' | 'helpAndSupport';

const DashboardSearchPanel: React.FC<{
  shouldScroll?: boolean;
}> = ({ shouldScroll = false }) => {
  const { t } = useTranslation();
  const { askForHelpAvailable } = useIsAskForHelpAvailable();

  const [searchType, setSearchType] = useState<SearchType>('pukCodes');

  const ref: React.RefObject<HTMLDivElement> = React.useRef(null);
  useScrollToAnchor({
    elementRef: ref,
    anchorName: 'puk-code-search',
    shouldScroll,
    showHighlight: true
  });

  return (
    <Panel
      data-testid="dashboard-search-panel"
      data-tour-stop="dashboard-search"
      margin={{ bottom: 'sm' }}
      showOverflow
      refElement={ref}
    >
      <Panel.Content>
        <Flex centeredVertically data-testid="dashboard-search-title">
          <Icon name="search" />

          <Gutter size="sm" />

          <Heading.H4>
            {t(askForHelpAvailable ? 'dashboard.pukCodeSearch.title' : 'dashboard.search.title')}
          </Heading.H4>
        </Flex>

        {!askForHelpAvailable && (
          <Flex occupyHorizontalSpace data-testid="dashboard-search-options" margin={{ top: 'md' }}>
            <RadioButton
              data-testid="dashboard-search-option-puk"
              name="searchType"
              label={t('dashboard.search.pukCodes')}
              value="pukCodes"
              onChange={() => setSearchType('pukCodes')}
              checked={searchType === 'pukCodes'}
            />

            <Gutter size="md" />

            <RadioButton
              data-testid="dashboard-search-option-support"
              name="searchType"
              label={t('dashboard.search.helpAndSupport')}
              value="helpAndSupport"
              onChange={() => setSearchType('helpAndSupport')}
              checked={searchType === 'helpAndSupport'}
            />
          </Flex>
        )}

        <DashboardSearchProvider>
          <DashboardContentSearch searchType={searchType} data-testid="dashboard-search-field" />

          {searchType === 'pukCodes' && (
            <PukCodeSearchResults data-testid="dashboard-search-puk-results" />
          )}

          {!askForHelpAvailable && searchType === 'helpAndSupport' && (
            <DashboardContentSearchResults data-testid="dashboard-search-content-results" />
          )}
        </DashboardSearchProvider>
      </Panel.Content>
    </Panel>
  );
};

export default DashboardSearchPanel;
