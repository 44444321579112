import { TFunction } from 'i18next';
import { TicketContactMethod } from 'types/ticket';

export default (t: TFunction): { label: string; value: TicketContactMethod }[] => [
  {
    label: t('tickets.contactMethod.email'),
    value: 'email'
  },
  {
    label: t('tickets.contactMethod.phone'),
    value: 'phone'
  },
  {
    label: t('tickets.contactMethod.sms'),
    value: 'sms'
  }
];
