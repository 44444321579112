import { Icon } from '@teliafi/fi-ds';
import getClassNames from 'classnames';
import Memo from 'common-components/Memo/Memo';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import styles from './OverflowMenuToggle.module.scss';

export type OverflowMenuToggleProps = {
  children: React.ReactNode;
  menuId: string;
  menuContainer: Element | null;
  isOpen: boolean;
  closeMenus: VoidFunction;
  toggleMenu: (id: string) => void;
};

const OverflowMenuToggle: React.FC<OverflowMenuToggleProps> = ({
  children,
  menuId,
  menuContainer,
  isOpen,
  closeMenus,
  toggleMenu
}) => {
  useEffect(() => {
    if (isOpen && !menuContainer) {
      closeMenus();
    }
  }, [isOpen, menuContainer, closeMenus]);

  return (
    <Memo comparables={['data-overflow-menu-node']}>
      <div data-overflow-menu-node={isOpen ? 'active' : 'inactive'}>
        <OverflowMenuIcon
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            toggleMenu(menuId);
          }}
          open={isOpen}
        />

        {isOpen && menuContainer && ReactDOM.createPortal(children, menuContainer)}
      </div>
    </Memo>
  );
};

const OverflowMenuIcon: React.FC<{
  onClick: React.MouseEventHandler;
  open: boolean;
}> = ({ onClick, open }) => {
  const { t } = useTranslation();

  return (
    <button
      data-testid="overflow-menu-icon"
      data-dd-action-name="Quick link menu"
      aria-label={t('aria.list.overflowMenu.toggle')}
      aria-expanded={open}
      className={getClassNames(styles.toggle, { [styles.toggle_open]: open })}
      onClick={onClick}
    >
      <div className={styles.toggleZone}>
        <Icon name="more-vertical" size="md" />
      </div>
    </button>
  );
};

export default OverflowMenuToggle;
