export type SupportedSizes = 'md' | 'lg' | 'xl';

export type ResponsiveHiddenProps = {
  responsiveHidden?: {
    widerThan?: SupportedSizes;
    thinnerThan?: SupportedSizes;
  };
};

export const getResponsiveHiddenSupportedSizes = (): SupportedSizes[] => ['md', 'lg', 'xl'];

const getResponsiveHiddenClasses = ({ responsiveHidden }: ResponsiveHiddenProps): string[] => {
  return [
    responsiveHidden?.widerThan ? `widerThan_${responsiveHidden.widerThan}` : undefined,
    responsiveHidden?.thinnerThan ? `thinnerThan_${responsiveHidden.thinnerThan}` : undefined
  ].filter((spacing) => !!spacing) as string[];
};

export default getResponsiveHiddenClasses;
