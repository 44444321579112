import { SectionDefinition } from 'B2XApp/Dashboard/DashboardNavigation/DashboardSections/sections/sectionDefinition';

import { businessServicesSection } from './businessServicesSection';
import { communicationSection } from './communicationSection';
import { invoicingSection } from './invoicingSection';
import { offeringSection } from './offeringSection';
import { oldCorporatePortalSection } from './oldCorporatePortalSection';
import { ordersAndRfqsSection } from './ordersAndRfqsSection';
import { reportsSection } from './reportsSection';
import { subscriptionsAndDevicesSection } from './subscriptionsAndDevicesSection';
import { teliaAccountManagementSection } from './teliaAccountManagementSection';
import { wholesaleServicesSection } from './wholesaleServicesSection';

export const sections: readonly SectionDefinition[] = Object.freeze([
  businessServicesSection,
  communicationSection,
  invoicingSection,
  offeringSection,
  oldCorporatePortalSection,
  ordersAndRfqsSection,
  reportsSection,
  subscriptionsAndDevicesSection,
  teliaAccountManagementSection,
  wholesaleServicesSection
]);
