import { Dropdown, OptionListOption } from '@gaiads/telia-react-component-library';
import { OnSortChange } from 'hooks/useSortableHeadings/useSortableHeadings';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SortableColumns } from 'types/internetConnections';
import { SortOrder, SortParameter } from 'types/sort';

export const ProductSortDropdown: React.FC<{
  currentSort: SortParameter;
  onSortChange: OnSortChange;
}> = ({ currentSort, onSortChange }) => {
  const { t } = useTranslation();

  const options: OptionListOption[] = useMemo(
    () =>
      Object.values(SortableColumns).reduce((accumulator: OptionListOption[], option: string) => {
        return [
          ...accumulator,
          { label: t(`internetConnections.sort.${option}Asc`), value: `${option},asc` },
          { label: t(`internetConnections.sort.${option}Desc`), value: `${option},desc` }
        ];
      }, []),
    [t]
  );

  return (
    <Dropdown
      data-testid="internet-connections-list-sort-dropdown"
      label={t('internetConnections.sort.order')}
      aria-label={t('internetConnections.sort.order')}
      options={options}
      onChange={(_event, { value }) =>
        onSortChange({ field: value.split(',')[0], direction: value.split(',')[1] as SortOrder })
      }
      selectedOption={options.find(
        ({ value }) => value === `${currentSort.sort},${currentSort.order}`
      )}
    />
  );
};
