import FormFieldHelperText from 'common-components/Forms/FormField/Inputs/FormFieldHelperText';
import ModelDateFilter from 'common-components/ModelDateFilter/ModelDateFilter';
import { DateFormats } from 'doings/formatDatetime/DateFormats';
import isFormat from 'doings/formatDatetime/isFormat';
import toDate from 'doings/formatDatetime/toDate';
import toDateString from 'doings/formatDatetime/toDateString';
import { noOp } from 'doings/noOp/noOp';
import useDateInputModel from 'hooks/inputModels/useDateInputModel/useDateInputModel';
import useWriteQueryParams from 'hooks/useQueryParams/useWriteQueryParams';
import React, { useCallback, useState } from 'react';
import { DateFilterDefinition, RegisterOnClearListenerFn } from 'types/listFilters';

const isNationalFormat = isFormat(DateFormats.DATE_NATIONAL);
const toNationalDate = toDate(DateFormats.DATE_NATIONAL);
const toNationalDateInit = (initialValue?: string) => {
  if (isNationalFormat(initialValue)) {
    const date = toNationalDate(initialValue);
    return Number.isNaN(date?.getTime()) ? undefined : date;
  }

  return undefined;
};

const toNationalDateString = toDateString(DateFormats.DATE_NATIONAL.formatter);
export const bothOrNeitherPresent = (from?: Date, till?: Date) => !from === !till;

export const DateFilter: React.FC<{
  filter: DateFilterDefinition;
  initialFrom?: string;
  initialTill?: string;
  registerOnClearListener: RegisterOnClearListenerFn;
}> = ({ filter: date, initialFrom, initialTill, registerOnClearListener }) => {
  const { write: writeQueryParams } = useWriteQueryParams();
  const updateDateRange = useCallback(
    (from?: Date, till?: Date) => {
      if (bothOrNeitherPresent(from, till)) {
        writeQueryParams({
          [date.fromParam]: toNationalDateString(from),
          [date.tillParam]: toNationalDateString(till)
        });
      }
    },
    [writeQueryParams, date.fromParam, date.tillParam]
  );

  const [initialFromAsDate] = useState(() => toNationalDateInit(initialFrom));
  const dateFromInput = useDateInputModel({
    initialValue: initialFromAsDate,
    roundTo: 'START_OF_DAY',
    onChange: (value?: Date) => updateDateRange(value, dateTillInput.value)
  });

  const [initialTillAsDate] = useState(() => toNationalDateInit(initialTill));
  const dateTillInput = useDateInputModel({
    initialValue: initialTillAsDate,
    roundTo: 'END_OF_DAY',
    onChange: (value?: Date) => updateDateRange(dateFromInput.value, value)
  });

  React.useEffect(() => {
    registerOnClearListener(`${date.fromParam}:${date.tillParam}`, () => {
      dateFromInput.setValue(undefined);
      dateTillInput.setValue(undefined);
      return {
        [date.fromParam]: undefined,
        [date.tillParam]: undefined
      };
    });

    // Does nothing when unmounted, because this component becomes
    // unmounted alongside `ListFilters` which store the on clear
    // listeners.
    return noOp;
  }, [registerOnClearListener, date.fromParam, date.tillParam, dateFromInput, dateTillInput]);

  return (
    <>
      <ModelDateFilter
        data-testid={date['data-testid']}
        label={date.label}
        dateFromInputModel={dateFromInput}
        dateToInputModel={dateTillInput}
      />

      {date.helperText && (
        <FormFieldHelperText
          helperText={date.helperText}
          data-testid={`${date['data-testid']}-helper-text`}
        />
      )}
    </>
  );
};
