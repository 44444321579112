import { Div, Flex, Gutter, Icon } from '@gaiads/telia-react-component-library';
import { Paragraph } from '@teliafi/fi-ds';
import styles from 'B2XApp/TopNavigation/Navigation/ContentSearch/ContentSearch.module.scss';
import ContentSearchSectionTitle from 'B2XApp/TopNavigation/Navigation/ContentSearch/ContentSearchSectionTitle';
import { TeliaLink, TextWithInlineHtml } from 'common-components';
import { Divider } from 'core-components';
import { AnalyticsEvent, aiAssistant } from 'doings/track/analyticsEvents';
import { useClearConversation } from 'hooks/askForHelp/useClearConversation';
import useIsAskForHelpAvailable from 'hooks/askForHelp/useIsAskForHelpAvailable';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  openAskForHelpDrawer,
  resetConversation,
  setLastQuestion
} from 'redux/slices/askForHelpSlice';
import { RootState } from 'redux/store';

const ContentSearchConversation: React.FC<{ searchTerm: string }> = ({ searchTerm }) => {
  const { askForHelpAvailable } = useIsAskForHelpAvailable();
  const lastQuestion = useSelector((state: RootState) => state.askForHelpDrawer.lastQuestion);

  if (!askForHelpAvailable || (!lastQuestion && !searchTerm)) {
    return null;
  }

  return <ContentSearchConversationInner searchTerm={searchTerm} lastQuestion={lastQuestion} />;
};

const ContentSearchConversationInner: React.FC<{ searchTerm: string; lastQuestion?: string }> = ({
  searchTerm,
  lastQuestion
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { clearConversation } = useClearConversation();

  const resetOngoingConversation = () => {
    dispatch(resetConversation());
    clearConversation();
  };

  const openConversation = () => {
    dispatch(openAskForHelpDrawer());
  };

  const startNewConversation = () => {
    resetOngoingConversation();
    dispatch(setLastQuestion(searchTerm));
    dispatch(openAskForHelpDrawer());
  };

  return (
    <>
      {!!searchTerm && (
        <>
          <Gutter size="sm" />

          <Divider />

          <Gutter size="sm" />
        </>
      )}

      <ContentSearchSectionTitle section={t('topNavigation.askForHelp.conversation.title')} />

      {!!lastQuestion && (
        <>
          <TextWithInlineHtml
            text={t('topNavigation.askForHelp.conversation.ongoing', {
              question: lastQuestion
            })}
          />

          <Gutter size="xxs" />

          <ContentSearchConversationButton
            data-testid="top-navigation--ask-for-help--conversation--continue"
            title={t('topNavigation.askForHelp.conversation.continue')}
            onClick={openConversation}
            analyticsEvent={aiAssistant.continueConversationFromSearch}
          />

          <ContentSearchConversationButton
            data-testid="top-navigation--ask-for-help--conversation--reset"
            title={t('topNavigation.askForHelp.conversation.reset')}
            onClick={resetOngoingConversation}
            analyticsEvent={aiAssistant.clearConversationFromSearch}
          />
        </>
      )}

      {!!lastQuestion && !!searchTerm && <Gutter size="sm" />}

      {!!searchTerm && (
        <>
          <TextWithInlineHtml
            text={t('topNavigation.askForHelp.conversation.new', {
              question: searchTerm
            })}
          />

          {!!lastQuestion && (
            <Paragraph>{t('topNavigation.askForHelp.conversation.clearOngoing')}</Paragraph>
          )}

          <Gutter size="xxs" />

          <ContentSearchConversationButton
            data-testid="top-navigation--ask-for-help--conversation--start"
            title={t('topNavigation.askForHelp.conversation.start')}
            onClick={startNewConversation}
            analyticsEvent={aiAssistant.startConversationFromSearch}
          />
        </>
      )}
    </>
  );
};

const ContentSearchConversationButton: React.FC<{
  title: string;
  onClick: VoidFunction;
  analyticsEvent?: AnalyticsEvent;
  'data-testid': string;
}> = ({ title, onClick, analyticsEvent, 'data-testid': testId }) => {
  return (
    <Div className={styles.articleSearchResultItem}>
      <Flex>
        <Icon className={styles.chevron} name="chevron-right" size="sm" />

        <Div flexItem occupyHorizontalSpace>
          <TeliaLink.Click
            data-testid={testId}
            variant="standalone-content-search"
            label={title}
            onClick={onClick}
            track={analyticsEvent}
          />
        </Div>
      </Flex>
    </Div>
  );
};

export default ContentSearchConversation;
