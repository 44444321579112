import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

export const SortableCheckboxesContext = createContext<SortableCheckboxes | undefined>(undefined);

export type SortableCheckboxesProps = {
  readonly items: SortedChecklistItem[];
  readonly itemClassification?: 'item' | 'column' | 'link' | 'tile';
  readonly minEnabledItems: number | false;
  readonly maxEnabledItems: number | false;
  readonly onChange: (items: SortedChecklistItem[]) => void;
  readonly children: React.ReactNode;
};

export interface SortedChecklistItem {
  id: string;
  enabled: boolean;
  label: string;
  isNew?: boolean;
  isRequired?: boolean;
}

export type SortableCheckboxes = {
  readonly listItems: SortedChecklistItem[];
  readonly setListItems: Dispatch<SetStateAction<SortedChecklistItem[]>>;
  readonly minItemsEnabled: boolean;
  readonly maxItemsEnabled: boolean;
  readonly selectMoreItemsLabel: string;
};

const SELECT_MORE_ITEMS_I18N_KEYS_BY_ITEM_CLASS = {
  item: 'common.sortableCheckboxes.selectMore.items',
  column: 'common.sortableCheckboxes.selectMore.columns',
  link: 'common.sortableCheckboxes.selectMore.links',
  tile: 'common.sortableCheckboxes.selectMore.tiles'
};

const SortableCheckboxesProvider: React.FC<SortableCheckboxesProps> = ({
  items,
  minEnabledItems: minItems,
  maxEnabledItems: maxItems,
  itemClassification = 'item',
  onChange,
  children
}) => {
  const { t } = useTranslation();
  const [listItems, setListItems] = useState<SortedChecklistItem[]>(items);

  const enabledItems = useMemo(() => {
    return listItems.reduce((acc, { enabled }) => (enabled ? ++acc : acc), 0);
  }, [listItems]);

  const minItemsToEnable = useMemo(() => {
    return typeof minItems === 'number' ? Math.max(0, minItems - enabledItems) : 0;
  }, [enabledItems, minItems]);

  const maxItemsEnabled = useMemo(() => {
    return typeof maxItems === 'number' ? enabledItems >= maxItems : false;
  }, [enabledItems, maxItems]);

  const selectMoreItemsLabel = useMemo(
    () => t(SELECT_MORE_ITEMS_I18N_KEYS_BY_ITEM_CLASS[itemClassification], { n: minItemsToEnable }),
    [t, itemClassification, minItemsToEnable]
  );

  const output = useMemo(
    () => ({
      listItems,
      setListItems,
      minItemsEnabled: minItemsToEnable === 0,
      maxItemsEnabled,
      selectMoreItemsLabel
    }),
    [listItems, setListItems, minItemsToEnable, maxItemsEnabled, selectMoreItemsLabel]
  );

  useEffect(() => {
    onChange(listItems);
  }, [onChange, listItems]);

  return (
    <SortableCheckboxesContext.Provider value={output}>
      {children}
    </SortableCheckboxesContext.Provider>
  );
};

const useSortableCheckboxes = () => {
  const context = useContext(SortableCheckboxesContext);
  if (!context) {
    throw new Error('useSortableCheckboxes must be used with SortableCheckboxesContext');
  }

  return context;
};

export { SortableCheckboxesProvider, useSortableCheckboxes };
