import CreateTicket from 'B2XApp/Messages/Tickets/NewTicket/CreateTicket';
import { useUserData } from 'contexts/UserContext/UserContext';
import { useTranslation } from 'react-i18next';

const CreateTroubleTicket: React.FC = () => {
  const { t } = useTranslation();
  const { email, name, businessPhoneNumber } = useUserData();

  return (
    <CreateTicket
      data-testid="create-ticket-page"
      additionalInfo={{
        title: t('tickets.details.reporter'),
        rows: [name, email, businessPhoneNumber]
      }}
    />
  );
};

export default CreateTroubleTicket;
