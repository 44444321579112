import { List } from '@gaiads/telia-react-component-library';
import { Breakpoint } from 'common-components';

import ListViewCell from '../ListViewCell/ListViewCell';
import { useListViewLayout } from '../ListViewLayout/ListViewLayoutProvider';
import ListViewRowContent from '../ListViewRow/ListViewRowContent';

export const ListViewHeader: React.FC<{
  usesQuicklinks?: boolean;
  hasExpandableItems?: boolean;
}> = ({ usesQuicklinks, hasExpandableItems }) => {
  const { columnsInOrder, hasHeadings } = useListViewLayout();
  if (!hasHeadings) {
    return null;
  }

  return (
    <Breakpoint widerThan="lg">
      <List.Item data-testid="list-view-header-row">
        <ListViewRowContent
          transparent
          hideArrow
          padding={{ vertical: 'xs', horizontal: 'md' }}
          usesQuicklinks={usesQuicklinks}
          usesExpandableArrow={hasExpandableItems}
        >
          {columnsInOrder.map((config) => (
            <ListViewCell
              dataTestId={`list-header-${config.testidSuffix ?? config.columnId}`}
              key={`cell-${config.columnId}`}
              isHeading
              cellSize={config.width}
              sort={config.sort}
              tooltip={config.tooltip}
            >
              {config.heading || ''}
            </ListViewCell>
          ))}
        </ListViewRowContent>
      </List.Item>
    </Breakpoint>
  );
};
