import styles from './NewsDrawerTabCounter.module.scss';

const MAX_SHOWN_COUNT = 99;
const MAX_SHOWN_COUNT_LABEL = '99+';

const NewsDrawerTabCounter: React.FC<{ count: number; 'aria-label': string }> = ({
  count,
  'aria-label': htmlAriaLabel
}) => {
  if (count <= 0) {
    return null;
  }

  const countLabel = count > MAX_SHOWN_COUNT ? MAX_SHOWN_COUNT_LABEL : count;
  return (
    <span
      className={styles.counter}
      aria-label={htmlAriaLabel}
      data-testid="news-drawer-tab-counter"
    >
      {countLabel}
    </span>
  );
};

export default NewsDrawerTabCounter;
