import { Page } from '@gaiads/telia-react-component-library';
import { LinkGroup, ListViewHeader, PageWithSubnavigation } from 'common-components';
import EmptyListContent from 'common-components/EmptyListContent/EmptyListContent';
import ShowMoreResults from 'common-components/ShowMoreResults/ShowMoreResults';
import useDevices from 'hooks/devices/useDevices/useDevices';
import { Trans, useTranslation } from 'react-i18next';

import DevicesList from './DevicesList/DevicesList';
import { FavoriteDevices } from './FavoriteDevices/FavoriteDevices';
import ManageDevicesInTINLink from './ManageDevicesInTINLink/ManageDevicesInTINLink';
import ManageDevicesInMyNebulaLink from './MyNebulaLink/MyNebulaLink';
import OrderNewDaasDeviceLink from './OrderNewDaasDeviceLink/OrderNewDaasDeviceLink';

const Devices: React.FC = () => {
  const {
    devices: { items, hasMoreResults },
    loading,
    error,
    fetchMore
  } = useDevices();
  const { t } = useTranslation();
  const hasResults = !!items.length;

  return (
    <PageWithSubnavigation
      loading={!hasResults && loading}
      error={error}
      emptyContentPlaceholder={
        !hasResults && (
          <EmptyListContent
            title={t('daas.emptyList.title')}
            description={<Trans i18nKey={t('daas.emptyList.ingress')} />}
            withBackgroundImage={false}
            occupyHorizontalSpace
            footerContent={
              <>
                <DeviceManagementLinks />

                <FavoriteDevices />
              </>
            }
          />
        )
      }
      showSubnavigationForCurrentPath
      data-testid="devices-page-with-subnavigation"
    >
      <Page.Row>
        <ListViewHeader>
          <DeviceManagementLinks />
        </ListViewHeader>
      </Page.Row>

      <Page.Row responsiveFullWidth>
        <DevicesList data-testid="devices-list" devices={items} />
      </Page.Row>

      <Page.Row>
        <ShowMoreResults
          data-testid="show-more-devices"
          loading={hasResults && loading}
          hasMoreResults={hasMoreResults}
          hasResults={hasResults}
          fetchMore={fetchMore}
        />
      </Page.Row>
    </PageWithSubnavigation>
  );
};

const DeviceManagementLinks: React.FC = () => (
  <LinkGroup>
    <LinkGroup.Link>
      <OrderNewDaasDeviceLink />
    </LinkGroup.Link>

    <LinkGroup.LineBreak />

    <LinkGroup.Link>
      <ManageDevicesInTINLink />
    </LinkGroup.Link>

    <LinkGroup.LineBreak />

    <LinkGroup.Link>
      <ManageDevicesInMyNebulaLink />
    </LinkGroup.Link>
  </LinkGroup>
);

export default Devices;
