import usePersistedSortableHeadings from 'hooks/useSortableHeadings/usePersistedSortableHeadings';
import { SortState } from 'hooks/useSortableHeadings/useSortableHeadings';
import { MutableRefObject } from 'react';
import { CaseSortField } from 'types/companyCaseList';
import { SortOrder, SortParameter } from 'types/sort';

type SortEvent = { field: string; direction: SortOrder };

export type UseCompanyCaseListSortingResult = {
  sortState: SortState;
  sortClick: (field: string) => void;
  onSortChange: (event: SortEvent) => void;
  currentSort: SortParameter;
};

/**
 * For internal use by `useCompanyCaseList`.
 *
 * @param pageRef Mutable page reference reset on sorting criteria changes.
 */
const useCompanyCaseListSorting = (
  pageRef: MutableRefObject<number>
): UseCompanyCaseListSortingResult => {
  const sorting = usePersistedSortableHeadings(
    [CaseSortField.REPORTED_DATE, CaseSortField.UPDATED_DATE, CaseSortField.ID],
    {
      sort: CaseSortField.UPDATED_DATE,
      order: SortOrder.DESC
    }
  );

  return {
    sortState: sorting.sortState,
    sortClick: (field: string) => {
      pageRef.current = 1;
      sorting.sortClick(field);
    },
    onSortChange: (event: SortEvent) => {
      pageRef.current = 1;
      sorting.onSortChange(event);
    },
    currentSort: sorting.currentSort
  };
};

export default useCompanyCaseListSorting;
