import { useUserData } from 'contexts/UserContext/UserContext';
import trackEvent from 'doings/track/trackEvent';
import { useActiveToursQuery } from 'hooks/tours/useActiveTours';
import { useMarkTourAsSeenMutation } from 'hooks/tours/useMarkTourAsSeen';
import { useWatchedTours } from 'hooks/tours/useWatchableTours';
import useReadQueryParams from 'hooks/useQueryParams/useReadQueryParams';
import useWriteQueryParams from 'hooks/useQueryParams/useWriteQueryParams';
import { Dispatch, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { ApiStatus } from 'types/api';

import {
  TourAction,
  TourActionType,
  TourDescription,
  TourGuideStatus,
  TourState
} from './tour.types';
import tours from './tours.json';

/**
 * Retrieves and registers the user's unseen tours amongst tours with the
 * specified IDs. Starts tours and marks tours as seen when skipped or finished,
 * so previously seen (possibly skipped) tours would no longer pop up
 * automatically unless invoked explicitly via the `tour` query parameter.
 */
export const useTours = (tourIds: string[], state: TourState, dispatch: Dispatch<TourAction>) => {
  const userData = useUserData();
  const { activeTours, activeToursQueryStatus } = useActiveToursQuery();
  const { toursToShow } = useToursToShow({
    userData,
    tourIds,
    activeTours,
    activeToursQueryStatus
  });
  const { markTourAsSeen } = useMarkTourAsSeenMutation();
  const history = useHistory();
  const status = state.status;

  useEffect(() => {
    if (status === TourGuideStatus.REGISTERING && toursToShow.length > 0) {
      dispatch({
        type: TourActionType.REGISTER_TOURS,
        tours: toursToShow,
        startedAt: history.location.pathname
      });
    }
  }, [dispatch, history.location.pathname, status, toursToShow]);

  useEffect(() => {
    if (status === TourGuideStatus.READY_TO_START) {
      dispatch({ type: TourActionType.START });
    }
  }, [dispatch, status]);

  useEffect(() => {
    if (status === TourGuideStatus.STARTED && state.markingSeen) {
      markTourAsSeen({ variables: { tourId: state.markingSeen } });
      dispatch({ type: TourActionType.MARKED_AS_SEEN });
    }
  }, [dispatch, status, markTourAsSeen, state.markingSeen]);

  useEffect(() => {
    if (status === TourGuideStatus.STARTED && state.tracking.length > 0) {
      state.tracking.forEach((ev) => trackEvent(ev));
      dispatch({ type: TourActionType.GA_EVENTS_TRACKED });
    }
  }, [dispatch, status, state.tracking]);

  useEffect(() => {
    if (status === TourGuideStatus.STARTED && state.navigateTo) {
      history.replace(state.navigateTo);
      setTimeout(() => dispatch({ type: TourActionType.NAVIGATED }), 0);
    }
  }, [dispatch, status, state.navigateTo, history]);

  useEffect(() => {
    if (
      status === TourGuideStatus.STARTED &&
      !state.currentTour &&
      !state.markingSeen &&
      state.tracking.length <= 0
    ) {
      if (state.startedAt && history.location.pathname !== state.startedAt) {
        history.replace(state.startedAt);
      }
      dispatch({ type: TourActionType.RESET });
    }
  }, [
    dispatch,
    status,
    state.currentTour,
    state.markingSeen,
    state.startedAt,
    state.tracking.length,
    history
  ]);
};

const useToursToShow = ({
  userData,
  tourIds,
  activeTours,
  activeToursQueryStatus
}: {
  userData: User;
  tourIds: string[];
  activeTours?: string[];
  activeToursQueryStatus: ApiStatus;
}) => {
  const { tour: explicitTour } = useReadQueryParams(['tour']);
  const { write: writeQueryParams } = useWriteQueryParams();
  const { readyTours, popReadyTour } = useWatchedTours(tourIds);

  const loaded = [ApiStatus.Loaded, ApiStatus.Error].includes(activeToursQueryStatus);
  const toursToShow = useMemo(() => {
    if (loaded) {
      return determineToursToShow({
        activeTours,
        explicitTour,
        readyTours,
        tourIds,
        activeGroupType: userData.activeGroupType
      });
    }

    return [];
  }, [loaded, activeTours, explicitTour, readyTours, tourIds, userData.activeGroupType]);

  useEffect(() => {
    if (loaded && explicitTour) {
      writeQueryParams({ tour: undefined });
    }
  }, [loaded, explicitTour, writeQueryParams]);

  useEffect(() => {
    if (loaded && readyTours.length > 0 && tourIds.includes(readyTours[0])) {
      popReadyTour(readyTours[0]);
    }
  }, [loaded, readyTours, popReadyTour, tourIds]);

  return { toursToShow };
};

/**
 * Returns tours to show to the end-user based on the user's active
 * (i.e. unseen/unskipped) tours and any explicitly invoked tour.
 *
 * Note that:
 * 1. Tours amongst the specified `tourIds` can either be triggered
 *    automatically given an "automatic" trigger type when a user
 *    has not seen the tour, triggered somewhere else by application
 *    code given an "automatic-watched" trigger, or explicitly via
 *    query parameters given an "explicit" trigger type regardless of
 *    whether the user has seen a particular tour or not. Only tours
 *    amongst `tourIds` are ever shown; otherwise, a tour might not be
 *    applicable on the page it is being triggered.
 * 2. If active tours cannot be retrieved from the API, automatically
 *    triggered tours will be skipped in case the user might have
 *    already seen those tours. An explicit tour will still be shown
 *    regardless of active tours, as specified in note No. 1.
 * 3. If an explicit tour is triggered automatically and has yet to be
 *    shown to the user, it will be shown in order of the specified
 *    `tourIds` and considered an automatically triggered tour for
 *    display purposes.
 * 4. If an explicit tour is triggered explicitly, it will be shown
 *    after all other tours.
 * 5. An introduction is only shown for tours that are triggered
 *    explicitly. Automatically triggered tours _skip their intro_
 *    so that users would immediatelly be guided through important
 *    changes on a page without identifying the tour for an annoying
 *    pop-up displayed on page load.
 */
function determineToursToShow({
  activeTours,
  explicitTour,
  readyTours,
  tourIds,
  activeGroupType
}: {
  activeTours?: string[];
  explicitTour?: string;
  readyTours: string[];
  tourIds: string[];
  activeGroupType: ActiveGroupType;
}) {
  let keepIntroFor: string | undefined = undefined;
  const idsToShow = activeTours ? tourIds.filter((tourId) => activeTours.includes(tourId)) : [];
  if (explicitTour && tourIds.includes(explicitTour)) {
    keepIntroFor = explicitTour;
    if (!idsToShow.includes(explicitTour)) {
      idsToShow.push(explicitTour);
    }
  }

  const isKnownTour = (idToShow: string): idToShow is keyof typeof tours =>
    Object.hasOwn(tours, idToShow);

  return idsToShow.reduce((acc, idToShow) => {
    const tourById = isKnownTour(idToShow) ? (tours[idToShow] as TourDescription) : undefined;
    if (
      tourById &&
      ['any', activeGroupType].includes(tourById.group) &&
      (tourById.id === explicitTour ||
        tourById.trigger === 'automatic' ||
        (tourById.trigger === 'automatic-watched' && readyTours.includes(tourById.id)))
    ) {
      acc.push({ ...tourById, intro: keepIntroFor === tourById.id ? tourById.intro : undefined });
    }
    return acc;
  }, [] as TourDescription[]);
}
