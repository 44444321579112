import { BodyText } from '@gaiads/telia-react-component-library';
import { useTranslation } from 'react-i18next';

export const NetworkQualityTooltipContent: React.FC<{
  details?: string[];
}> = ({ details }) => {
  const { t } = useTranslation();

  return (
    <div role="tooltip">
      {details ? (
        details.map((entry, index) => (
          <BodyText key={`tooltip-entry-${btoa(entry + index)}`}>{entry}</BodyText>
        ))
      ) : (
        <BodyText>{t('dashboard.mobileNetworkQuality.notEnoughInformation')}</BodyText>
      )}
    </div>
  );
};
