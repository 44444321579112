import useBillingGroupForm, {
  UseBillingGroupFormResult
} from 'B2XApp/Invoicing/BillingGroups/BillingGroupForm/useBillingGroupForm';
import useCopyCollectionGroup from 'hooks/invoicing/billingGroups/useCopyCollectionGroup/useCopyCollectionGroup';
import { useConfirmationModal } from 'hooks/useCustomModal/useConfirmationModal';
import useSearchParams from 'hooks/useSearchParams/useSearchParams';
import { AvailableBank, AvailableOperator, CollectionGroup } from 'types/collection-group';

import useCopyBillingGroupSteps from './useCopyBillingGroupSteps';

export type CopyBillingGroupPrerequisites = {
  collectionGroup: CollectionGroup;
  availableBanks: AvailableBank[];
  availableOperators: AvailableOperator[];
};

const useCopyBillingGroupForm = ({
  collectionGroup,
  availableBanks,
  availableOperators
}: CopyBillingGroupPrerequisites): UseBillingGroupFormResult => {
  const { onClick } = useConfirmationModal();
  const { preserveBackPathState } = useSearchParams();

  const {
    newCollectionGroupId,
    copyCollectionGroupApiStatus: copyApiStatus,
    copyCollectionGroup
  } = useCopyCollectionGroup();

  const { step, setStep } = useCopyBillingGroupSteps({
    collectionGroup,
    copyApiStatus
  });

  const finvoiceAvailable = false;
  const billingTypeChangeAvailable = false;
  const companySelectionAvailable = false;
  const { fields, isValid, marshallRequestData } = useBillingGroupForm({
    collectionGroup,
    availableBanks,
    availableOperators,
    finvoiceAvailable,
    billingTypeChangeAvailable,
    companySelectionAvailable
  });

  return {
    form: {
      step,
      setStep,
      fields,
      finvoiceAvailable,
      isValid,
      isCopy: true,
      data: { newCollectionGroupId },
      onSave: () => {
        if (isValid) {
          copyCollectionGroup({
            variables: {
              collectionGroupId: collectionGroup.collectionGroup,
              requestData: marshallRequestData()
            }
          });
        }
      },
      onCancel: () => {
        onClick(
          preserveBackPathState(`/billing-accounts/${collectionGroup.collectionGroup}`, true)
        );
      }
    }
  };
};

export default useCopyBillingGroupForm;
