import {
  BodyText,
  Div,
  Flex,
  FlexProps,
  Gutter,
  List
} from '@gaiads/telia-react-component-library';
import getClassNames from 'classnames';
import { TeliaLink } from 'common-components';
import Skeleton from 'common-components/Skeleton/Skeleton';
import { EN_DASH } from 'doings/dash/dash';
import { multiplex } from 'doings/multiplex/multiplex';
import React from 'react';
import { TicketAttachment } from 'types/ticket';

import styles from './DefinitionList.module.scss';

const FIFTY_FIFTY_HEADING_SIZE = 2;
const TWO_TO_ONE_HEADING_SIZE = 4;

type DefinitionListDelegatedProps = {
  loading?: boolean;
  alignRight?: boolean;
  pileVerticallyTo?: FlexProps<'div'>['pileVerticallyTo'];
  directionColumn?: FlexProps<'div'>['directionColumn'];
  fiftyFifty?: boolean;
  twoToOne?: boolean;
};

type OverflowOption = {
  wordBreak?:
    | 'inherit'
    | 'normal'
    | 'break-word'
    | '-moz-initial'
    | 'initial'
    | 'revert'
    | 'unset'
    | 'break-all'
    | 'keep-all';
};

type DefinitionListProps = {
  children: React.ReactNode;
  addDividers?: boolean;
  leadingDivider?: boolean;
  trailingDivider?: boolean;
  'data-testid'?: string;
} & DefinitionListDelegatedProps;

type DefinitionListItemProps = {
  heading?: string;
  baseline?: boolean;
  'data-testid'?: string;
  children?: React.ReactNode;
} & DefinitionListDelegatedProps &
  OverflowOption;

type Values = Array<string | TicketAttachment | null | undefined>;

export type DefinitionListMultilineItemProps = {
  heading: string;
  baseline?: boolean;
  values: Values;
  type?: 'text' | 'attachment';
  'data-testid'?: string;
} & DefinitionListDelegatedProps &
  OverflowOption;

const hasDefinedValues = (array: Values) => array?.filter(Boolean).length;

/**
 * A list which can contain one or several instances of `DefinitionList.Item`
 * or `DefinitionList.MultilineItem`. Supports properties which are delegated to
 * its items; these delegated properties can also be individually defined on items
 * themselves if the properties shouldn't affect all items for a very good reason.
 *
 * @param children
 * @param addDividers If `true`, adds horizontal dividers between list items.
 * @param leadingDivider If `true`, adds horizontal divider before the first list item.
 * @param trailingDivider If `true`, adds horizontal divider after the last list item.
 * @param loading Styling property. Delegated to items.
 * @param alignRight Styling property. Delegated to items.
 * @param pileVerticallyTo Styling property. Delegated to items.
 * @param directionColumn Styling property. Delegated to items.
 * @param fiftyFifty Styling property. Delegated to items.
 * @param twoToOne Styling property. Delegated to items.
 * @param testId data-testid attribute for the component
 */
function DefinitionList({
  children,
  addDividers = false,
  leadingDivider = false,
  trailingDivider = false,
  loading = false,
  alignRight,
  pileVerticallyTo,
  directionColumn,
  fiftyFifty,
  twoToOne,
  'data-testid': testId
}: DefinitionListProps) {
  const filteredChildren = React.Children.toArray(children);
  const childPropOverrides = {
    loading,
    ...(alignRight ? { alignRight } : []),
    ...(pileVerticallyTo ? { pileVerticallyTo } : []),
    ...(directionColumn ? { directionColumn } : []),
    ...(fiftyFifty ? { fiftyFifty } : []),
    ...(twoToOne ? { twoToOne } : [])
  };

  return (
    <List
      data-testid={testId}
      className={getClassNames({
        [styles.definitionList]: true,
        [styles.definitionList_withDividers]: addDividers,
        [styles.definitionList_withLeadingDivider]: leadingDivider,
        [styles.definitionList_withTrailingDivider]: trailingDivider
      })}
    >
      {React.Children.map(filteredChildren, (child) =>
        React.cloneElement(child as React.ReactElement, childPropOverrides)
      )}
    </List>
  );
}

/**
 * A definition list item containing a single line of text or a custom component.
 */
const DefinitionListItem: React.FC<DefinitionListItemProps> = ({
  loading,
  heading,
  baseline = false,
  children,
  alignRight = false,
  pileVerticallyTo = 'xs',
  directionColumn = false,
  fiftyFifty = false,
  twoToOne = false,
  'data-testid': testId,
  wordBreak = 'normal'
}) => {
  const headingClasses = getClassNames({
    [styles.item_baseline]: baseline
  });

  const valueClasses = getClassNames({
    [styles.item_alignRight]: alignRight,
    [styles.item_verticalToXxxs]: pileVerticallyTo === 'xxxs',
    [styles.item_verticalToXxs]: pileVerticallyTo === 'xxs',
    [styles.item_verticalToXs]: pileVerticallyTo === 'xs',
    [styles.item_verticalToSm]: pileVerticallyTo === 'sm',
    [styles.item_verticalToMd]: pileVerticallyTo === 'md',
    [styles.item_verticalToLg]: pileVerticallyTo === 'lg',
    [styles.item_verticalToXlg]: pileVerticallyTo === 'xlg'
  });

  const headingSizeMultiplier = multiplex([
    1,
    [twoToOne, TWO_TO_ONE_HEADING_SIZE],
    [fiftyFifty, FIFTY_FIFTY_HEADING_SIZE]
  ]);

  return loading ? (
    <Flex
      className={styles.item}
      pileVerticallyTo={pileVerticallyTo}
      occupyVerticalSpace={false}
      tagName="li"
    >
      <Skeleton.TextRow
        width="100%"
        data-testid={testId ? `${testId}-item-loading` : 'item-loading'}
      />
    </Flex>
  ) : (
    <Flex
      className={styles.item}
      spaceBetween={alignRight}
      occupyVerticalSpace={false}
      pileVerticallyTo={pileVerticallyTo}
      directionColumn={directionColumn}
      tagName="li"
      data-testid={testId ? `${testId}-item` : 'item'}
    >
      {heading && (
        <>
          <Div
            className={headingClasses}
            style={{ wordBreak }}
            flexItem={{ sizeMultiplier: headingSizeMultiplier }}
            data-testid={testId ? `${testId}-item-heading` : 'item-heading'}
          >
            <BodyText fontWeight="bold" color="black">
              {heading}
            </BodyText>
          </Div>

          <Gutter size="xs" />
        </>
      )}

      <Div
        className={valueClasses}
        flexItem={{ sizeMultiplier: 2 }}
        data-testid={testId ? `${testId}-item-value` : 'item-value'}
      >
        {children}
      </Div>
    </Flex>
  );
};

/**
 * A definition list item containing possibly multiple lines of text.
 */
const DefinitionListMultilineItem: React.FC<DefinitionListMultilineItemProps> = ({
  loading,
  heading,
  baseline = false,
  values,
  type = 'text',
  alignRight = false,
  pileVerticallyTo,
  directionColumn,
  fiftyFifty = false,
  twoToOne = false,
  'data-testid': testId,
  wordBreak
}) => (
  <DefinitionListItem
    loading={loading}
    heading={heading}
    key={heading}
    baseline={baseline}
    alignRight={alignRight}
    pileVerticallyTo={pileVerticallyTo}
    directionColumn={directionColumn}
    fiftyFifty={fiftyFifty}
    twoToOne={twoToOne}
    wordBreak={wordBreak}
    data-testid={testId}
  >
    {hasDefinedValues(values) ? (
      values.map((value, index) => {
        if (type === 'attachment') {
          const attachment = value as TicketAttachment;
          return (
            <TeliaLink.Download
              key={`attachment-${index}`}
              label={attachment.name}
              to={attachment.url}
              iconLeft="attachment"
              downloadWithFetch
            />
          );
        }

        return (
          <BodyText
            data-testid={testId ? `${testId}-multiline-item-value` : 'multiline-item-value'}
            key={`multiline-item-value-${index}`}
          >
            {value}
          </BodyText>
        );
      })
    ) : (
      <BodyText key={heading}>{EN_DASH}</BodyText>
    )}
  </DefinitionListItem>
);

DefinitionList.Item = DefinitionListItem;
DefinitionList.MultilineItem = DefinitionListMultilineItem;

export default DefinitionList;
