import wrapEachComponentWithKey from 'doings/wrapEachComponentWithKey/wrapEachComponentWithKey';

/**
 * Repeats one or several static child components the specified number
 * of times.
 */
export const Repeat: React.FC<{ times: number; children: JSX.Element | JSX.Element[] }> = ({
  times,
  children
}) => {
  if (times <= 0) {
    return null;
  }

  return <>{wrapEachComponentWithKey(Array(times).fill(children))}</>;
};
