import { TeliaLink } from 'common-components';
import getEnvironmentVariable from 'doings/getEnvironmentVariable/getEnvironmentVariable';
import { AnalyticsEvent } from 'doings/track/analyticsEvents';

export const B2CInvoicesNotificationLink: React.FC<{
  children?: string;
  analyticsEvent: AnalyticsEvent;
}> = ({ children: label, analyticsEvent }) => (
  <TeliaLink.Translatable.External
    data-testid="b2c-invoices-notification-link"
    variant="text"
    to={getEnvironmentVariable('REACT_APP_LINK_TO_B2C_INVOICES')}
    iconRight="external"
    track={analyticsEvent}
  >
    {label}
  </TeliaLink.Translatable.External>
);
