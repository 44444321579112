import { DropdownMultiselect, OptionListOption } from '@gaiads/telia-react-component-library';
import { MultiselectFieldProps } from 'common-components/Forms/FormField/FormField';
import { useField, useFormikContext } from 'formik';
import { useMemo } from 'react';

import FormFieldHelperText from './FormFieldHelperText';
import styles from './FormFieldMultiselect.module.scss';

const determineFieldValue = (
  options: OptionListOption[],
  selectedOption: OptionListOption,
  fieldValue: string[]
) => {
  const fieldValueAsSet = new Set<string>(fieldValue);
  const selectedValue = selectedOption.value;
  if (fieldValueAsSet.has(selectedValue)) {
    fieldValueAsSet.delete(selectedValue);
  } else {
    fieldValueAsSet.add(selectedValue);
  }

  return options.reduce(
    (acc, option) => {
      if (fieldValueAsSet.has(option.value)) {
        acc.labels.push(option.label);
        acc.values.push(option.value);
      }

      return acc;
    },
    { labels: [] as string[], values: [] as string[] }
  );
};

const isChecked = (option: OptionListOption, fieldValue: string[]) =>
  fieldValue.includes(option.value);

const mapCheckedOptions = (options: OptionListOption[], fieldValue: string[]) =>
  options.map((option) => {
    return { ...option, checked: isChecked(option, fieldValue) };
  });

const stringifyCheckedOptions = (options: OptionListOption[], fieldValue: string[]) =>
  options
    .filter((option) => isChecked(option, fieldValue))
    .map((option) => option.label)
    .join(', ');

const FormFieldMultiselect = ({
  name,
  label,
  helperText,
  placeholder,
  required,
  disabled,
  options,
  onChange,
  trackOnChange,
  testId
}: MultiselectFieldProps) => {
  const [field, meta] = useField<string[]>(name);
  const { setFieldValue } = useFormikContext();
  const fieldValue = field.value ?? [];

  return useMemo(
    () => (
      <>
        <DropdownMultiselect
          data-testid={testId}
          className={styles.moveAlongNothingToSeeHere}
          label={label}
          onChange={(_event, option) => {
            const { values, labels } = determineFieldValue(options, option, fieldValue);
            setFieldValue(field.name, values);
            trackOnChange?.(`[${labels.toSorted((a, b) => a.localeCompare(b)).join(', ')}]`);
            onChange?.(values);
          }}
          onClear={() => {
            setFieldValue(field.name, []);
            trackOnChange?.('[]');
            onChange?.([]);
          }}
          options={mapCheckedOptions(options, fieldValue)}
          placeholder={
            fieldValue.length <= 0 ? placeholder : stringifyCheckedOptions(options, fieldValue)
          }
          required={required}
          errorText={meta.touched ? meta.error : ''}
          disabled={disabled}
        />

        <FormFieldHelperText helperText={helperText} />
      </>
    ),

    /*
     * Only check for changes to significant properties which can change
     * between form field renders. Dependencies like `field` and `setFieldValue`
     * change on every render, so we need to exclude these to avoid unnecessary
     * and unperformant rerenders.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, required, disabled, field.value, meta.touched, meta.error]
  );
};

export default FormFieldMultiselect;
