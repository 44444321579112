import useSendServiceRequestMessage from 'hooks/serviceRequests/useSendServiceRequestMessage/useSendServiceRequestMessage';
import useServiceRequest from 'hooks/serviceRequests/useServiceRequest/useServiceRequest';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import ServiceRequestDetailsPage from './ServiceRequestDetailsPage';

const ServiceRequestDetails: React.FC = () => {
  const { serviceRequestId } = useParams<{ serviceRequestId: string }>();
  const { serviceRequest, loading, error } = useServiceRequest(serviceRequestId);

  const [replyToTicketVisible, setReplyToTicketVisible] = useState(false);
  const [replySentMessageVisible, setReplySentMessageVisible] = useState(false);

  const {
    sendServiceRequestMessage,
    loading: replyLoading,
    replySent,
    hasError: hasReplyError
  } = useSendServiceRequestMessage();

  useEffect(() => {
    setReplySentMessageVisible(replySent);
  }, [replySent]);

  return (
    <ServiceRequestDetailsPage
      serviceRequest={serviceRequest}
      loading={!replyLoading && loading}
      hasError={!!error}
      replyToServiceRequest={{
        replyToTicketVisible,
        setReplyToTicketVisible,
        loading: replyLoading,
        hasError: hasReplyError,
        replySent: replySentMessageVisible,
        sendMessage: sendServiceRequestMessage,
        setReplySentMessageVisible
      }}
    />
  );
};

export default ServiceRequestDetails;
