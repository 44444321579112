import { Gutter } from '@gaiads/telia-react-component-library';
import routeAccessGroups from 'B2XApp/appRouteAccessGroups';
import ContactUsPanel from 'B2XApp/ContactUs/ContactUsPanel';
import useGetDashboardNotificationRows from 'B2XApp/Dashboard/DashboardNavigation/DashboardNotifications/useGetDashboardNotificationRows';
import { Authorize, Button } from 'common-components';
import { contactUs } from 'doings/track/analyticsEvents';
import { useRouting } from 'hooks/useRouting/useRouting';
import { useTranslation } from 'react-i18next';

import ContactUsMessageLink from './ContactUsMessageLink';

const ServiceRequestsPanel: React.FC = () => {
  const { t } = useTranslation();
  const { goto } = useRouting();
  const notificationRows = useGetDashboardNotificationRows({ onlyContacts: true });
  const serviceRequests = notificationRows.find(
    ({ dataIdSuffix }) => dataIdSuffix === 'service-requests'
  );
  const messages = notificationRows.find(({ dataIdSuffix }) => dataIdSuffix === 'messages');

  return (
    <ContactUsPanel
      title={t('contactUs.panel.title.serviceRequests')}
      titleIcon="email"
      content={t('contactUs.panel.text.serviceRequests')}
      data-tour-stop="contact-us-service-request-panel"
      data-testid="contact-us-service-request-panel"
    >
      <ContactUsMessageLink
        data-testid="link-to-service-requests"
        label={t('navigationalLink.title.serviceRequests')}
        target="/service-requests"
        item={serviceRequests}
        enabledWhen={routeAccessGroups.SERVICE_REQUESTS}
        analyticsEvent={contactUs.serviceRequestList}
      />

      <ContactUsMessageLink
        data-testid="link-to-messages"
        label={t('navigationalLink.title.serviceRequestsArchive')}
        target="/message-archive"
        item={messages}
        enabledWhen={routeAccessGroups.MESSAGE_ARCHIVE}
        analyticsEvent={contactUs.serviceRequestArchiveList}
      />

      <Gutter size="sm" />

      <Authorize
        enabledWhen={routeAccessGroups.SERVICE_REQUESTS_NEW}
        analyticsEvent={contactUs.serviceRequestSubmit}
        element={
          <Button
            data-testid="new-service-request-button"
            type="button"
            variant="primary"
            onClick={() =>
              goto({
                to: '/service-requests/new'
              })
            }
          >
            {t('serviceRequests.emptyList.createNew')}
          </Button>
        }
      />
    </ContactUsPanel>
  );
};

export default ServiceRequestsPanel;
