import { gql } from '@apollo/client';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';

export const newMessagesNotificationQuery = gql`
  query GetNewCustomerSpecificMessages {
    newCustomerSpecificMessagesNotification {
      messages
    }
  }
`;

interface NewCustomerSpecificMessagesResponse {
  newCustomerSpecificMessagesNotification: {
    messages: number;
  };
}

const useNewMessagesNotification = ({ skip }: { skip: boolean }) => {
  const { data, loading, error } = useCallBackend<NewCustomerSpecificMessagesResponse>({
    query: newMessagesNotificationQuery,
    skip
  });

  const messagesCount = data?.newCustomerSpecificMessagesNotification?.messages ?? 0;
  return {
    newCustomerSpecificMessages: messagesCount,
    available: !loading && !error && messagesCount > 0
  };
};

export default useNewMessagesNotification;
