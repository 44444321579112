import { gql } from '@apollo/client';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { ServiceRequestDetailsItem } from 'types/serviceRequest';

export const serviceRequestQuery = gql`
  query GetServiceRequestDetails($serviceRequestId: ID!) {
    serviceRequest(id: $serviceRequestId) {
      id
      caseNumber
      name
      status
      category
      description
      solutionDescription
      creationDate
      lastUpdate
      resolutionDate
      companyCode
      companyName
      companyGroupType
      subscriptionNumber
      productName
      invoiceNumber
      relatedIndividual {
        role
        givenName
        familyName
        fullName
        contactName
        contactMedium {
          type
          medium {
            emailAddress
            number
            type
          }
        }
      }
      note {
        id
        text
        date
        source
        authorFirstName
        authorLastName
      }
      attachments {
        url
        name
        relatedNote
        source
      }
    }
  }
`;

type ServiceRequestsDetailsResponse = {
  serviceRequest: ServiceRequestDetailsItem;
};

type UseServiceRequestReturnType = {
  serviceRequest?: ServiceRequestDetailsItem;
  error?: Error;
  loading: boolean;
};

const useServiceRequest = (serviceRequestId: string): UseServiceRequestReturnType => {
  const { data, error, loading } = useCallBackend<ServiceRequestsDetailsResponse>({
    query: serviceRequestQuery,
    queryVariables: {
      serviceRequestId
    },
    fetchPolicy: 'cache-and-network'
  });

  return {
    serviceRequest: data?.serviceRequest,
    error,
    loading
  };
};

export default useServiceRequest;
