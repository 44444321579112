import { BodyText } from '@gaiads/telia-react-component-library';
import { Paragraph } from '@teliafi/fi-ds';
import ErrorPage from 'B2XApp/Errors/Error/ErrorPage/ErrorPage';
import { DefinitionList, TeliaLink } from 'common-components';
import ConfirmationPanelLayout from 'common-components/ConfirmationPanel/ConfirmationPanelLayout';
import { Div } from 'core-components';
import { EN_DASH } from 'doings/dash/dash';
import createLinkWithPublicUrl from 'doings/links/createLinkWithPublicUrl/createLinkWithPublicUrl';
import { OwnershipChangeTokenDetails } from 'hooks/mobileSubscriptions/useOwnershipChange/ownershipChangeTokenQueries';
import useSearchParams from 'hooks/useSearchParams/useSearchParams';
import { Trans, useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { TokenMessageStep } from './GenerateOwnershipChangeToken';

type OmsuTokenMessage = {
  [key in TokenMessageStep]: {
    title: string;
    description: string;
  };
};

const OwnershipChangeTokenMessage: React.FC<{
  phoneNumberHash: string;
  step?: TokenMessageStep;
  token?: OwnershipChangeTokenDetails;
  tokenExpiryDate?: string;
  tokenTerminationDate?: string;
  subscriptionId?: string;
  subscriptionName?: string;
  subscriptionType?: string;
}> = ({
  phoneNumberHash,
  step,
  token,
  tokenExpiryDate,
  tokenTerminationDate,
  subscriptionId,
  subscriptionName,
  subscriptionType
}) => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { preserveBackPathState } = useSearchParams();

  if (!step) {
    return <ErrorPage data-testid="generate-ownership-change-token-error" />;
  }

  const translations: OmsuTokenMessage = {
    'token-already-exists': {
      title: 'subscriptions.generateToken.tokenAlreadyExists',
      description: tokenTerminationDate
        ? 'subscriptions.generateToken.tokenTerminatedDescription'
        : 'subscriptions.generateToken.tokenExistsDescription'
    },
    'token-generation-unavailable': {
      title: 'subscriptions.generateToken.tokenUnavailable',
      description: 'subscriptions.generateToken.tokenUnavailableDescription'
    },
    'token-generated': {
      title: 'subscriptions.generateToken.tokenGeneratedTitle',
      description: 'subscriptions.generateToken.tokenGeneratedDescription'
    }
  };

  const isTokenGenerated =
    step === 'token-generated' && !!token && !!subscriptionId && !!subscriptionName;
  const shouldLinkWithinB2XPortal = !url.endsWith('/transfer/private');

  return (
    <ConfirmationPanelLayout
      title={t(translations[step].title)}
      description={
        <Paragraph data-testid={`generate-ownership-change-${step}-info`}>
          <Trans
            i18nKey={translations[step].description}
            values={{ expiryDate: tokenExpiryDate, terminationDate: tokenTerminationDate }}
          />
        </Paragraph>
      }
      variant={isTokenGenerated ? 'success' : 'failure'}
      data-testid={`generate-ownership-change-${step}`}
    >
      {isTokenGenerated && (
        <Div margin={{ bottom: 'lg' }}>
          <DefinitionList fiftyFifty addDividers>
            <DefinitionList.Item
              heading={t('subscriptions.generateToken.phoneNumberToTransfer')}
              data-testid="generate-ownership-token-number-to-transfer"
            >
              <BodyText>{subscriptionId}</BodyText>
            </DefinitionList.Item>

            <DefinitionList.Item
              heading={t('subscriptions.generateToken.subscriptionType')}
              data-testid="generate-ownership-token-subscription-type"
            >
              <BodyText>{subscriptionType || EN_DASH}</BodyText>
            </DefinitionList.Item>

            <DefinitionList.Item
              heading={t('subscriptions.generateToken.updatesTo')}
              data-testid="generate-ownership-token-updates-to"
            >
              <BodyText>
                {[token?.additionalNumber1, token?.additionalNumber2].filter(Boolean).join(', ') ||
                  EN_DASH}
              </BodyText>
            </DefinitionList.Item>

            <DefinitionList.Item
              heading={t('subscriptions.generateToken.subscriptionTerminationDate')}
              data-testid="generate-ownership-token-termination-date"
            >
              <BodyText>{token?.terminationDate || EN_DASH}</BodyText>
            </DefinitionList.Item>
          </DefinitionList>
        </Div>
      )}

      <TeliaLink.Portal
        data-testid="back-to-subscription-button"
        variant="standalone"
        to={preserveBackPathState(`/subscriptions/${phoneNumberHash}`, true)}
        toCorporatePortal="search/subscriptionSearch"
        toCorporatePortalArgs={{ originator: createLinkWithPublicUrl('/') }}
        useRouterLink={shouldLinkWithinB2XPortal}
        label={
          shouldLinkWithinB2XPortal
            ? t('subscriptions.generateToken.backToSubscriptionButton')
            : t('common.backToListButton.label')
        }
      />
    </ConfirmationPanelLayout>
  );
};

export default OwnershipChangeTokenMessage;
