import { Div, Gutter } from '@gaiads/telia-react-component-library';
import { Skeleton } from 'common-components';

const TicketDetailsMessageSkeleton: React.FC = () => {
  return (
    <Skeleton.Panel>
      <Div flexItem>
        <Skeleton.TextRow width="60%" />

        <Gutter size="xs" />

        <Skeleton.TextRow width="60%" />

        <Gutter size="xs" />

        <Skeleton.TextRow width="60%" />
      </Div>
    </Skeleton.Panel>
  );
};

export default TicketDetailsMessageSkeleton;
