export type AssetAddress = {
  streetAddress: string;
  streetAddressSpecifier: string;
  floor: string;
  postalAddress: string;
  country: string;
};

export type InternetConnectionsItem = {
  id: string;
  name: string;
  displayId: string;
  address: AssetAddress;
  isDNSAsset: boolean;
  domainName?: string;
  companyCode?: string;
  companyName?: string;
};

export type ChildAsset = {
  id: string;
  name: string;
  parentId: string;
  displayId: string;
  status: string;
  activationDate: string | null;
  contractEndDate: string | null;
  payer: string | null;
  billingAccount: string | null;
  address: AssetAddress;
  attributes: Attribute[];
  isDNSAsset: boolean;
  domainName?: string;
  companyCode?: string;
  companyName?: string;
};

type Attribute = {
  label: string;
  value: string;
};

type AssetOrder = {
  projectId: string | null;
  groupBillingId: string | null;
  customerReferenceNumber: string | null;
};

export enum ProductType {
  BusinessInternet = 'businessInternet',
  SmartConnect = 'smartConnect'
}

export type InternetConnectionDetailsItem = InternetConnectionsItem & {
  assetType: 'b2b' | 'b2o';
  productId: string;
  isYritysnetti: boolean;
  status: string;
  activationDate: string | null;
  contractEndDate: string | null;
  terminationDate: string | null;
  recurringTotal: number;
  payer: string | null;
  billingAccount: string | null;
  childAssets: ChildAsset[];
  attributes: Attribute[];
  order: AssetOrder | null;
  productType?: ProductType;
};

export enum SortableColumns {
  name = 'name',
  country = 'country',
  displayId = 'displayId'
}
