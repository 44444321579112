import { TeliaLink } from 'common-components';
import getEnvironmentVariable from 'doings/getEnvironmentVariable/getEnvironmentVariable';
import { devices } from 'doings/track/analyticsEvents';
import { useTranslation } from 'react-i18next';

const ManageDevicesInMyNebulaLink: React.FC = () => {
  const { t } = useTranslation();
  return (
    <TeliaLink.External
      data-testid="manage-devices-at-my-nebula"
      variant="standalone"
      to={getEnvironmentVariable('REACT_APP_LINK_TO_TIN_MY_NEBULA')}
      label={t('daas.links.myNebula')}
      iconRight="external"
      track={devices.manageDevicesInMyNebula}
    />
  );
};

export default ManageDevicesInMyNebulaLink;
