import { Div, Gutter } from '@gaiads/telia-react-component-library';
import { Icon, IconName } from '@teliafi/fi-ds';
import { Flex } from 'core-components';
import { DateFormats } from 'doings/formatDatetime';
import { useTranslation } from 'react-i18next';
import { ProductNewsStats } from 'types/productNews';

import { NewsRatingsStatsMeter } from './NewsRatingsStatsMeter';
import styles from './NewsRatingsStatsPanel.module.scss';
import { NewsRatingsStatsTitle } from './NewsRatingsStatsTitle';

const NewsRatingsStatsPanel: React.FC<{ item: ProductNewsStats }> = ({
  item: { newsId, counters, period }
}) => {
  const { t } = useTranslation();
  const { total, upvotes, downvotes, comments } = counters;
  const votes = upvotes + downvotes;
  const average = toAverage(upvotes, votes);
  const averageFixed = toFixedDigits(average);

  return (
    <Flex className={styles.container} data-testid="news-ratings-stats-panel">
      <Div flexItem>
        <NewsRatingsStatsTitle newsId={newsId} />

        <Div margin={{ top: 'sm' }}>
          {DateFormats.DATE_NATIONAL.formatter(new Date(Number(period.start)))} –{' '}
          {DateFormats.DATE_NATIONAL.formatter(new Date(Number(period.end)))}
        </Div>

        <DataEntry
          label={t('productNewsRatings.stats.totalRatings', { count: total })}
          iconName="activity-level"
          data-testid="total-ratings-entry"
        />

        <Flex pileVerticallyTo="md">
          <DataEntry
            label={t('productNewsRatings.stats.totalVotes', { count: votes })}
            iconName="bar-chart"
            data-testid="total-votes-entry"
          />

          <DataEntry
            label={t('productNewsRatings.stats.upvotes', { count: upvotes })}
            iconName="like"
            data-testid="upvotes-entry"
          />

          <DataEntry
            label={t('productNewsRatings.stats.downvotes', { count: downvotes })}
            iconName="dislike"
            data-testid="downvotes-entry"
          />

          <DataEntry
            label={t('productNewsRatings.stats.average', { rating: averageFixed })}
            iconName="focus"
            data-testid="average-entry"
          />
        </Flex>

        <DataEntry
          label={t('productNewsRatings.stats.comments', { count: comments })}
          iconName="customer-dialogue"
          data-testid="comments-icon"
        />
      </Div>

      <NewsRatingsStatsMeter
        average={average}
        upvotes={upvotes}
        votes={votes}
        aria-label={t('productNewsRatings.stats.average', { rating: averageFixed })}
        label={`${averageFixed}%`}
      />
    </Flex>
  );
};

const DataEntry = ({
  label,
  iconName,
  'data-testid': dataTestId
}: {
  label: string;
  iconName: IconName;
  'data-testid': string;
}) => (
  <Flex centeredVertically margin={{ top: 'xs', right: 'md' }}>
    <Icon data-testid={dataTestId} name={iconName} size="sm" />

    <Gutter size="xs" />

    <span>{label}</span>
  </Flex>
);

const PERCENTILE = 100;
const toAverage = (upvotes: number, votes: number) => {
  return votes === 0 ? 0 : (upvotes / votes) * PERCENTILE;
};

const FRACTION_DIGITS = 1;
const toFixedDigits = (value: number) => value.toFixed(value >= PERCENTILE ? 0 : FRACTION_DIGITS);

export { NewsRatingsStatsPanel };
