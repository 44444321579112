import { Flex, Gutter, Loader } from '@gaiads/telia-react-component-library';
import LoadingErrorImage from 'common-components/DataPanel/DetailsLoadingErrorPanel/LoadingErrorImage/LoadingErrorImage';
import { useUserData } from 'contexts/UserContext/UserContext';
import { messages } from 'doings/track/analyticsEvents';
import { trackEventWithCustomProps } from 'doings/track/trackEvent';
import useDynamicFormDescriptors from 'hooks/dynamicForm/useForms/useDynamicFormDescriptors';
import { ViewStatus } from 'hooks/useApiStatus/useApiStatus';
import useReadQueryParams from 'hooks/useQueryParams/useReadQueryParams';
import { useTrackOnStateChange } from 'hooks/useTrackOnStateChange/useTrackOnStateChange';
import { useEffect, useState } from 'react';
import { DynamicForms } from 'types/dynamicForms';

import DynamicFormSelector from './DynamicFormSelector';

const DynamicFormSelectorContainer: React.FC<{
  groupName: string;
  onSelected: (value: string) => void;
  initialSelectedValue?: string;
}> = ({ groupName, onSelected, initialSelectedValue }) => {
  const { language } = useUserData();
  const { t: dynamicFormTypeFromQueryParams } = useReadQueryParams(['t']);
  const { viewStatus, formDescriptors: descriptors } = useDynamicFormDescriptors(groupName);
  const defaultPreselectedValue = descriptors.length === 1 ? descriptors[0].name : undefined;
  const preSelectedValue = findForm(
    descriptors,
    initialSelectedValue,
    (needle) => (name) => name === needle,
    defaultPreselectedValue
  );

  const shouldAutoSelect = !!dynamicFormTypeFromQueryParams;
  const formTypeFixed = dynamicFormTypeFromQueryParams?.endsWith('!');
  const formTypeFromQueryParams = dynamicFormTypeFromQueryParams?.replace('!', '');
  const [selectedValue, setSelectedValue] = useState(preSelectedValue);

  useTrackOnStateChange(selectedValue, (value) => {
    const descriptor = descriptors.find((d) => d.name === value);
    if (descriptor) {
      trackEventWithCustomProps(messages.dynamicFormSelectType, {
        'dynamicForm.language': language,
        'dynamicForm.id': descriptor.name,
        'dynamicForm.label': descriptor.label
      });
    }
  });

  useEffect(() => {
    if (preSelectedValue && !initialSelectedValue) {
      setSelectedValue(preSelectedValue);
      onSelected(preSelectedValue);
    }
  }, [preSelectedValue, initialSelectedValue, onSelected]);

  useEffect(() => {
    if (shouldAutoSelect && descriptors.length > 0 && !selectedValue) {
      const newValue = findForm(
        descriptors,
        formTypeFromQueryParams,
        (needle) => (name) => name.includes(needle),
        descriptors[0].name
      );

      setSelectedValue(newValue);
      onSelected(newValue);
    }
  }, [
    shouldAutoSelect,
    selectedValue,
    setSelectedValue,
    onSelected,
    descriptors,
    formTypeFromQueryParams
  ]);

  if (viewStatus === ViewStatus.Error) {
    return (
      <Flex centered data-testid="dynamic-form-selector-error">
        <LoadingErrorImage />
      </Flex>
    );
  }

  if (viewStatus === ViewStatus.Loading) {
    return (
      <Flex centered data-testid="dynamic-form-selector-loader">
        <Loader />
      </Flex>
    );
  }

  const onChange = (value: string) => {
    setSelectedValue(value);
    onSelected(value);
  };

  if (descriptors.length <= 1) {
    return null;
  }

  return (
    <>
      <DynamicFormSelector
        formDescriptors={descriptors}
        selectedValue={selectedValue}
        disableAlternatives={!!selectedValue && formTypeFixed}
        onChange={onChange}
      />

      <Gutter size="md" />
    </>
  );
};

const findForm = <T,>(
  formDescriptors: DynamicForms.DynamicFormDescriptor[],
  needle: string | undefined,
  predicate: (needle: string) => (name: string) => boolean,
  defaultName: T
) =>
  needle
    ? formDescriptors.map(({ name }) => name).find(predicate(needle)) || defaultName
    : defaultName;

export default DynamicFormSelectorContainer;
