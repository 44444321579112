import { BodyText, Div, Flex, Gutter } from '@gaiads/telia-react-component-library';
import { Icon, IconName } from '@teliafi/fi-ds';
import { Heading } from 'common-components';
import React from 'react';

import styles from './ConfirmationPanel.module.scss';

const variants = {
  failure: {
    containerClass: styles.confirmationPanel_leftBorder__failure,
    iconName: 'alert' as IconName,
    iconClass: styles.confirmationPanel_icon__failure
  },
  success: {
    containerClass: styles.confirmationPanel_leftBorder__success,
    iconName: 'check-circle' as IconName,
    iconClass: styles.confirmationPanel_icon__success
  },
  'partial-success': {
    containerClass: styles.confirmationPanel_leftBorder__partial,
    iconName: 'alert' as IconName,
    iconClass: styles.confirmationPanel_icon__partial
  }
};

type Variant = keyof typeof variants;

const ConfirmationPanelLayout: React.FC<{
  title: string;
  description: string | React.ReactElement;
  variant: Variant;
  'data-testid'?: string;
  children: React.ReactNode;
}> = ({ title, description, variant, 'data-testid': testId, children }) => {
  const { containerClass, iconName, iconClass } = variants[variant];
  return (
    <Div
      data-testid={testId}
      className={containerClass}
      borderRadius={{ size: 'sm' }}
      backgroundColor="white"
      padding={{ size: 'md' }}
      boxShadow="sm"
    >
      <Flex centeredVertically margin={{ bottom: 'sm' }}>
        <Icon name={iconName} className={iconClass} size="md" />

        <Gutter size="xs" />

        <Heading.H3>{title}</Heading.H3>
      </Flex>

      <Div>
        {React.isValidElement(description) ? description : <BodyText>{description}</BodyText>}

        <Gutter />

        {!!children && <Div>{children}</Div>}
      </Div>
    </Div>
  );
};

export default ConfirmationPanelLayout;
