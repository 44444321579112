import { Dropdown } from '@gaiads/telia-react-component-library';
import { SelectFieldProps } from 'common-components/Forms/FormField/FormField';
import { useField, useFormikContext } from 'formik';
import { useMemo } from 'react';

import FormFieldHelperText from './FormFieldHelperText';

const FormFieldSelect = ({
  name,
  label,
  helperText,
  placeholder,
  required,
  disabled,
  options,
  onChange,
  trackOnChange,
  testId
}: SelectFieldProps) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  return useMemo(
    () => (
      <>
        <Dropdown
          data-testid={testId}
          name={name}
          label={label}
          aria-label={`${label}${required ? ' *' : ''}`}
          onChange={(_event, option) => {
            const newValue = option.value;
            setFieldValue(field.name, newValue);
            trackOnChange?.(option.label);
            onChange?.(newValue);
          }}
          options={options}
          placeholder={placeholder}
          required={required}
          errorText={meta.touched ? meta.error : ''}
          selectedOption={options.find((option) => option.value === (field.value || ''))}
          disabled={disabled}
        />

        <FormFieldHelperText helperText={helperText} />
      </>
    ),

    /*
     * Only check for changes to significant properties which can change
     * between form field renders. Dependencies like `field` and `setFieldValue`
     * change on every render, so we need to exclude these to avoid unnecessary
     * and unperformant rerenders.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, required, disabled, onChange, field.value, meta.touched, meta.error]
  );
};

export default FormFieldSelect;
