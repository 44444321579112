import { Gutter } from '@gaiads/telia-react-component-library';
import { Repeat, Skeleton } from 'common-components';

const NewServiceRequestSkeleton: React.FC = () => (
  <Skeleton.Panel>
    <Skeleton.Title width="40%" />

    <Gutter size="sm" />

    <Repeat times={2}>
      <Skeleton.InputField />

      <Gutter size="xs" />
    </Repeat>

    <Skeleton.InputField />

    <Gutter />

    <Skeleton.Title width="40%" />

    <Gutter size="sm" />

    <Repeat times={5}>
      <Skeleton.InputField />

      <Gutter size="xs" />
    </Repeat>

    <Skeleton.TextRow width="40%" />

    <Gutter />

    <Skeleton.TextRow width="80%" />
  </Skeleton.Panel>
);

export default NewServiceRequestSkeleton;
