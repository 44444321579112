import { GridBag } from '@gaiads/telia-react-component-library';
import { FinvoiceFields } from 'B2XApp/Invoicing/BillingGroups/Common/useBillingAccountEditFinvoiceFields';
import { CollapsibleBlock } from 'common-components';
import ModelTextInput from 'common-components/ModelTextInput/ModelTextInput';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './BillingGroupFormFinvoiceBlock.module.scss';

const BillingGroupFormFinvoiceBlock: React.FC<{
  finvoice: FinvoiceFields;
  disallowMutation: boolean;
  ['data-testid']?: string;
}> = ({ finvoice, disallowMutation, 'data-testid': testId }) => {
  const { t } = useTranslation();

  const isAnyProvided = Object.values(finvoice).some((field) => !!field.value);
  const isAnyRequired = Object.values(finvoice).some((field) => field.required);

  const [expanded, setExpanded] = useState(isAnyRequired || isAnyProvided);
  const providedRef = useRef(isAnyProvided);

  useEffect(() => {
    providedRef.current = isAnyProvided;
  }, [isAnyProvided]);

  useEffect(() => {
    setExpanded(isAnyRequired || providedRef.current);
  }, [isAnyRequired]);

  return (
    <CollapsibleBlock
      blockTitle={t('invoices.billingGroup.form.finvoice.accordionTitle')}
      className={styles.block}
      expanded={expanded}
      data-testid={testId}
    >
      <CollapsibleBlock.Content padding={{ horizontal: 'zero', vertical: 'sm' }}>
        <GridBag>
          {[
            {
              testId: 'finvoice-order-id',
              field: finvoice.finvoiceOrderId,
              label: 'finvoice.orderIdLabel'
            },
            {
              testId: 'finvoice-buyer-reference',
              field: finvoice.finvoiceBuyerReference,
              label: 'finvoice.buyerReferenceLabel'
            },
            {
              testId: 'finvoice-agreement-id',
              field: finvoice.finvoiceAgreementId,
              label: 'finvoice.agreementIdLabel'
            },
            {
              testId: 'finvoice-account-dimension',
              field: finvoice.finvoiceAccountDimension,
              label: 'finvoice.accountDimensionLabel'
            },
            {
              testId: 'finvoice-tender-reference',
              field: finvoice.finvoiceTenderReference,
              label: 'finvoice.tenderReferenceLabel'
            },
            {
              testId: 'finvoice-cost-account',
              field: finvoice.finvoiceCostAccount,
              label: 'finvoice.costAccountLabel'
            },
            {
              testId: 'finvoice-account-proposal',
              field: finvoice.finvoiceAccountProposal,
              label: 'finvoice.accountProposalLabel'
            },
            {
              testId: 'finvoice-project-number',
              field: finvoice.finvoiceProjectNumber,
              label: 'finvoice.projectNumberLabel'
            }
          ].map((field) => (
            <GridBag.Item md={12} key={field.testId}>
              <ModelTextInput
                data-testid={`collection-group-form__${field.testId}`}
                label={t(`invoices.billingGroup.form.${field.label}`)}
                inputModel={field.field}
                disabled={disallowMutation}
                hardMaxLength
              />
            </GridBag.Item>
          ))}
        </GridBag>
      </CollapsibleBlock.Content>
    </CollapsibleBlock>
  );
};

export default BillingGroupFormFinvoiceBlock;
