import { Link as LinkFromCL } from '@gaiads/telia-react-component-library';
import {
  SupportedColor,
  SupportedFontWeight
} from '@gaiads/telia-react-component-library/build/types/components/Element/Element';
import { Argument } from 'classnames';
import Link from 'common-components/Link/Link';

const LinkToEndpoint: React.FC<{
  to: string;
  className?: Argument;
  color?: SupportedColor;
  hoverColor?: SupportedColor;
  fontWeight?: SupportedFontWeight;
  disabled?: boolean;
  children: React.ReactNode;
}> = ({
  to,
  className,
  color = 'purple500',
  hoverColor = 'purple400',
  fontWeight,
  disabled,
  children
}) => {
  return disabled ? (
    <Link to={`${process.env.PUBLIC_URL}${to}`} className={className} disabled>
      {children}
    </Link>
  ) : (
    <LinkFromCL
      to={`${process.env.PUBLIC_URL}${to}`}
      className={className}
      color={color}
      hoverColor={hoverColor}
      fontWeight={fontWeight}
    >
      {children}
    </LinkFromCL>
  );
};

export default LinkToEndpoint;
