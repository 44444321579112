import { TextInputModel } from 'hooks/inputModels/useTextInputModel/useTextInputModel';
import useValidatableTextInputModel from 'hooks/inputModels/useValidatableTextInputModel/useValidatableTextInputModel';

export type UseProductNewsFeedbackFormResult = {
  form: ProductNewsFeedbackFormData;
};

export type ProductNewsFeedbackFormData = {
  fields: ProductNewsFeedbackFormFields;
  isValid: boolean;
  data?: Record<string, string | number | undefined>;
  onSave: VoidFunction;
  onCancel: VoidFunction;
};

export type UseProductNewsFeedbackFormFieldsResult = {
  fields: ProductNewsFeedbackFormFields;
  isValid: boolean;
};

export type ProductNewsFeedbackFormFields = {
  feedback: TextInputModel;
};

const checkValid = (fields: ProductNewsFeedbackFormFields): boolean => {
  return fields.feedback.isValid;
};

const useProductNewsFeedbackForm = (): UseProductNewsFeedbackFormFieldsResult => {
  const fields: ProductNewsFeedbackFormFields = {
    feedback: useValidatableTextInputModel({
      initialValue: '',
      required: false,
      showConfirmation: false,
      expecting: {
        maxLength: 400
      }
    })
  };
  const isValid = checkValid(fields);

  return { fields, isValid };
};

export default useProductNewsFeedbackForm;
