import { useUserData } from 'contexts/UserContext/UserContext';
import { useParams } from 'react-router-dom';

type ExternalOrigin =
  | 'billing-info-mass-change'
  | 'corporate-product'
  | 'invoice'
  | 'internet-connection'
  | 'order-inquiry'
  | 'subscription'
  | undefined;

type ServiceRequestExternalOrigin = {
  type: ExternalOrigin;
  key: string;
};

type GroupType = 'b2b' | 'b2b-b2o';
type ServiceRequestDirectOrigin = {
  type: GroupType;
};

export type ServiceRequestOrigin = ServiceRequestExternalOrigin | ServiceRequestDirectOrigin;

export default (): ServiceRequestOrigin => {
  const { origin, key } = useParams<{
    origin: ExternalOrigin;
    key: string;
  }>();

  const { activeGroupType } = useUserData();
  if (origin) {
    return {
      type: origin,
      key
    };
  }

  return {
    type: ['b2b', 'b2b_sales_portal'].includes(activeGroupType) ? 'b2b' : 'b2b-b2o'
  };
};
