import { Div, Input } from '@gaiads/telia-react-component-library';
import { FieldErrorText } from '@teliafi/fi-ds';
import getClassNames from 'classnames';
import { CsvTextFieldProps } from 'common-components/Forms/FormField/FormField';
import { IconButton } from 'common-components/IconButton/IconButton';
import { SmartTooltip } from 'common-components/SmartTooltip/SmartTooltip';
import { Flex } from 'core-components';
import { FieldInputProps, useField, useFormikContext } from 'formik';
import { useGenerateId } from 'hooks/useGenerateId/useGenerateId';
import { useEffect, useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { TEXT_FIELD_MAX_LENGTH } from 'types/form';

import styles from './FormFieldCsvInput.module.scss';
import FormFieldHelperText from './FormFieldHelperText';

type CsvInputAction =
  | { type: 'ADD' }
  | { type: 'DELETE'; index: number }
  | { type: 'CHANGE'; index: number; value: string };

const FormFieldCsvInput = ({
  name,
  label,
  helperText,
  placeholder,
  required,
  disabled,
  maxItems,
  testId
}: CsvTextFieldProps) => {
  const { t } = useTranslation();
  const [field, meta] = useField<string>(name);
  const { generatedId: tooltipId } = useGenerateId('csv-input-tt');
  const [csvInputState, dispatch] = useCsvInputReducer({ maxItems, field });
  const errors = {
    hasGeneral: meta.error && !Array.isArray(meta.error),
    itemErrors: meta.error && Array.isArray(meta.error) ? (meta.error as string[]) : []
  };

  return useMemo(
    () => (
      <>
        <AccessibleLabel
          data-testid={`${field.name}-label`}
          id={`${field.name}-label`}
          htmlFor={`${field.name}-0`}
          label={label}
          required={required}
        />

        {csvInputState.map((val, index, arr) => (
          <Flex className={styles.entry} key={`item-${index}`}>
            <Div flexItem margin={{ right: 'sm' }}>
              <Input
                data-testid={`${testId}-${index}`}
                data-name={
                  // Give a name to the topmost erroneous field for scrolling and highlighting
                  (index === 0 && errors.hasGeneral) || errors.itemErrors[index] !== undefined
                    ? field.name
                    : undefined
                }
                className={styles.entry_csvInput}
                maxLength={TEXT_FIELD_MAX_LENGTH}
                id={`${field.name}-${index}`}
                value={val}
                required={required}
                disabled={disabled}
                onBlur={field.onBlur}
                onChange={(_event, value) => {
                  dispatch({ type: 'CHANGE', index, value: value.replace(/,/g, '') });
                }}
                confirmed={!!val && errors.itemErrors[index] === undefined}
                errorText={meta.touched ? errors.itemErrors[index] : ''}
                label=""
                aria-label={`${label}${index === 0 && required ? ' *' : ''} ${
                  index > 0 ? index + 1 : ''
                }`}
                placeholder={placeholder}
              />
            </Div>

            <div className={styles.entry_button}>
              {index === 0 && (
                <SmartTooltip
                  id={tooltipId}
                  tooltipContent={t('aria.form.multiItemInput.insertEntryTooltip', {
                    more: maxItems - csvInputState.length
                  })}
                  arrangement="top"
                  wrapper="div"
                  noClick
                >
                  <SmartTooltip.Trigger wrapper="div">
                    <IconButton
                      data-testid="insert-entry"
                      aria-label={t('aria.form.multiItemInput.insertEntry')}
                      aria-describedby={tooltipId}
                      name="add"
                      size="md"
                      disabled={arr.length >= maxItems || disabled}
                      focusable={!disabled}
                      onClick={() => {
                        dispatch({ type: 'ADD' });
                      }}
                    />
                  </SmartTooltip.Trigger>
                </SmartTooltip>
              )}

              {index > 0 && (
                <IconButton
                  data-testid="remove-entry"
                  aria-label={`${t('aria.form.multiItemInput.removeEntry')} ${index + 1}`}
                  name="stop"
                  size="md"
                  disabled={disabled}
                  focusable={!disabled}
                  onClick={() => {
                    dispatch({ type: 'DELETE', index });
                  }}
                />
              )}
            </div>
          </Flex>
        ))}

        {meta.touched && errors.hasGeneral && (
          <Div margin={{ top: 'xs' }}>
            <FieldErrorText>{meta.error}</FieldErrorText>
          </Div>
        )}

        <FormFieldHelperText helperText={helperText} />
      </>
    ),

    /*
     * Only check for changes to significant properties which can change
     * between form field renders. Dependencies like `field` and `setFieldValue`
     * change on every render, so we need to exclude these to avoid unnecessary
     * and unperformant rerenders.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, required, disabled, csvInputState, field.value, meta.touched, errors]
  );
};

const AccessibleLabel: React.FC<{
  'data-testid': string;
  id: string;
  htmlFor: string;
  required?: boolean;
  label: string;
}> = ({ 'data-testid': dataTestId, id, htmlFor, required, label }) => (
  <label
    data-testid={dataTestId}
    className={getClassNames([
      'telia-react-element',
      'telia-react-element__block',
      'telia-react-element__fontWeight__normal',
      'telia-react-bodyText__size_sm',
      'telia-react-color__black',
      'telia-react-typography',
      'telia-react-typography__teliasans'
    ])}
    id={id}
    htmlFor={htmlFor}
  >
    {label}

    <span className={getClassNames(['telia-react-element', 'telia-react-input_required'])}>
      {required && ' *'}
    </span>
  </label>
);

const useCsvInputReducer = ({
  maxItems,
  field
}: {
  maxItems: number;
  field: FieldInputProps<string>;
}) => {
  const { setFieldValue } = useFormikContext();
  const [csvInputState, dispatch] = useReducer(
    (state: string[], action: CsvInputAction) => {
      switch (action.type) {
        case 'ADD':
          return state.length < maxItems ? [...state, ''] : state;
        case 'DELETE': {
          if (state.length > 1) {
            const newState = [...state];
            newState.splice(action.index, 1);
            return newState;
          }
          return state;
        }
        case 'CHANGE':
          return state.map((value, index) => (index === action.index ? action.value : value));
      }
    },
    field.value,
    (originalValue = '') => originalValue.split(',')
  );

  useEffect(() => {
    const newFieldValue = csvInputState.map((val) => val.trim().replace(',', '')).join(',');
    if (field.value !== newFieldValue) {
      setFieldValue(field.name, newFieldValue, true);
    }
  }, [csvInputState, field.name, field.value, setFieldValue]);

  return [csvInputState, dispatch] as const;
};

export default FormFieldCsvInput;
