import { Div } from '@gaiads/telia-react-component-library';
import useIsSmallerBreakpointActive from 'hooks/useIsSmallerBreakpointActive/useIsSmallerBreakpointActive';

const MobilePadding: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const isMobileLayout = useIsSmallerBreakpointActive('sm');

  return <Div padding={isMobileLayout ? { horizontal: 'sm' } : undefined}>{children}</Div>;
};

export default MobilePadding;
