import { GridBag, Gutter } from '@gaiads/telia-react-component-library';
import BillMillAccountBillingInformation from 'B2XApp/Invoicing/BillingGroups/BillMillAccountInformation/BillMillAccountBillingInformation';
import BillMillAccountPayerInformation from 'B2XApp/Invoicing/BillingGroups/BillMillAccountInformation/BillMillAccountPayerInformation';
import BillingGroupInformationSkeleton from 'B2XApp/Invoicing/BillingGroups/Common/BillingGroupInformationSkeleton';
import EditBillingInfoButton from 'B2XApp/Invoicing/BillingGroups/Common/EditBillingInfoButton';
import { useBillingGroupsHelper } from 'B2XApp/Invoicing/BillingGroups/Common/useBillingGroupsHelper';
import { ActionButtonGroup, DataPanel } from 'common-components';
import DetailsPage from 'common-components/DetailsPage/DetailsPage';
import { EN_DASH } from 'doings/dash/dash';
import { invoicing } from 'doings/track/analyticsEvents';
import { LoadingStatus } from 'hooks/invoicing/billingGroups/useCollectionGroup/useCollectionGroup';
import { useTranslation } from 'react-i18next';
import { BillMillBillingAccount } from 'types/billMillAccount';

const BillMillAccountDetailsPage: React.FC<{
  account?: BillMillBillingAccount;
  status: LoadingStatus;
}> = ({ account, status }) => {
  const { t } = useTranslation();
  const { goToEditBillMillAccountPage } = useBillingGroupsHelper();

  return (
    <DetailsPage
      data-testid={`billMill-account-details-${status}`}
      hasError={status === 'error'}
      loading={status === 'loading'}
      headline={`${t('billingInformation.collectionGroup')}: ${account?.accountId || EN_DASH}`}
      backButtonPath="/billing-accounts/"
    >
      <GridBag>
        <GridBag.Item md={6} sm={12}>
          <DataPanel
            data-testid="billing-account-payer-information"
            skeletonLoader={<BillingGroupInformationSkeleton />}
            loading={status === 'loading'}
            title={t('billingInformation.payerInformation')}
            mainPanel
          >
            {account && (
              <>
                <BillMillAccountPayerInformation account={account} />

                <Gutter />

                <ActionButtonGroup>
                  <EditBillingInfoButton
                    onClick={() =>
                      goToEditBillMillAccountPage(account.accountId, account.customerCode)
                    }
                    analyticsEvent={invoicing.updateBillMillAccount}
                  />
                </ActionButtonGroup>
              </>
            )}
          </DataPanel>
        </GridBag.Item>

        <GridBag.Item md={6} sm={12}>
          <DataPanel
            data-testid="billing-account-billing-information"
            skeletonLoader={<BillingGroupInformationSkeleton />}
            loading={status === 'loading'}
            title={t('billingInformation.title')}
          >
            {account && <BillMillAccountBillingInformation account={account} />}
          </DataPanel>
        </GridBag.Item>
      </GridBag>
    </DetailsPage>
  );
};

export default BillMillAccountDetailsPage;
