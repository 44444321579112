import { Breakpoints, Breakpoint as GaiaBreakpoint } from '@gaiads/telia-react-component-library';

interface GaiaBreakpointProps {
  /** Show contents at specific breakpoint  */
  at?: Breakpoints;
  /** Show contents at and narrower than specified breakpoints */
  narrowerThan?: Breakpoints;
  /** Show contents at and wider than specified breakpoints */
  widerThan?: Breakpoints;
}

interface BreakpointProps extends GaiaBreakpointProps {
  children: React.ReactNode;
}

export const Breakpoint: React.FC<BreakpointProps> = ({ children, ...props }) => (
  <GaiaBreakpoint {...props}>{children}</GaiaBreakpoint>
);
