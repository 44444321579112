import { Gutter } from '@gaiads/telia-react-component-library';
import { Repeat, Skeleton } from 'common-components';

const OwnershipChangeSkeleton: React.FC = () => (
  <Skeleton.Panel>
    <Repeat times={2}>
      <Skeleton.TextRow />

      <Gutter size="sm" />
    </Repeat>

    <Repeat times={3}>
      <Skeleton.InputField />
    </Repeat>
  </Skeleton.Panel>
);

export default OwnershipChangeSkeleton;
