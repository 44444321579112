import { BodyText, Panel } from '@gaiads/telia-react-component-library';
import { TeliaLink } from 'common-components';
import { useTranslation } from 'react-i18next';

const DashboardOtherServicesNoLinks: React.FC<{ openModal: VoidFunction }> = ({ openModal }) => {
  const { t } = useTranslation();

  return (
    <Panel data-testid="no-other-services-notice">
      <Panel.Content>
        <BodyText tagName="div">
          {t('modal.editLinks.text.noOtherServices')}

          <TeliaLink.Click
            variant="standalone"
            label={t('modal.editLinks.text.addOtherServices')}
            onClick={openModal}
          />
        </BodyText>
      </Panel.Content>
    </Panel>
  );
};

export default DashboardOtherServicesNoLinks;
