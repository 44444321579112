import { gql } from '@apollo/client';
import { LoadingStatus } from 'hooks/invoicing/billingGroups/useCollectionGroup/useCollectionGroup';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { BillMillBillingAccount } from 'types/billMillAccount';

export const billMillAccountQuery = gql`
  query GetBillingAccountDetails($accountId: String!) {
    billMillAccount(accountId: $accountId) {
      accountId
      customerCode
      payer {
        name
        nameSupplement
        address {
          address
          addressSupplement
          postalCode
          postalOffice
        }
      }
      billing {
        invoicingType
        email
        email2
        iban
        bic
        ovt
        paymentMethod
        smsReminderMsisdn
        language
      }
      finvoice {
        accountDimensionText
        agreementIdentifier
        buyerReferenceIdentifier
        normalProposedAccountIdent
        orderIdentifier
        projectReferenceIdentifier
        proposedAccountText
        tenderReference
      }
    }
  }
`;

interface UseBillMillAccountResult {
  account?: BillMillBillingAccount;
  status: LoadingStatus;
}

interface BillMillResponse {
  billMillAccount: BillMillBillingAccount;
}

const useBillMillAccount = (accountId: string): UseBillMillAccountResult => {
  const { data, loading } = useCallBackend<BillMillResponse>({
    query: billMillAccountQuery,
    queryVariables: {
      accountId: accountId
    }
  });

  const account = data?.billMillAccount;
  const accountStatus = account ? 'loaded' : 'error';

  return {
    account,
    status: loading ? 'loading' : accountStatus
  };
};

export default useBillMillAccount;
