import { OptionListOption } from '@gaiads/telia-react-component-library';
import { TrackFn } from 'hooks/useTrackOnStateChange/useTrackOnStateChange';

import FormFieldCheckbox from './Inputs/FormFieldCheckbox';
import FormFieldCheckgroup from './Inputs/FormFieldCheckgroup';
import FormFieldCombobox from './Inputs/FormFieldCombobox';
import FormFieldCsvInput from './Inputs/FormFieldCsvInput';
import FormFieldDatePicker from './Inputs/FormFieldDatePicker';
import FormFieldInput, { FormFieldInputVariant } from './Inputs/FormFieldInput';
import FormFieldMultiselect from './Inputs/FormFieldMultiselect';
import FormFieldSelect from './Inputs/FormFieldSelect';

type FormFieldType =
  | 'input'
  | 'csvInput'
  | 'textarea'
  | 'checkbox'
  | 'checkgroup'
  | 'date'
  | 'select'
  | 'multiselect';

export interface DateRange {
  startDate?: string;
  endDate?: string;
}

export interface FormFieldProps {
  type?: FormFieldType;
  name: string;
  label: string;
  helperText?: string;
  required?: boolean;
  disabled?: boolean;
  testId?: string;
}

export interface TextFieldProps extends FormFieldProps {
  type: 'input' | 'textarea';
  archetype?: FormFieldInputVariant;
  placeholder?: string;
  fixedValue?: string;
}

export interface CsvTextFieldProps extends FormFieldProps {
  type: 'csvInput';
  placeholder?: string;
  maxItems: number;
}

export interface CheckboxFieldProps extends FormFieldProps {
  type: 'checkbox';
  onChange?: (isChecked: boolean) => void;
}

export interface CheckgroupFieldProps extends FormFieldProps {
  type: 'checkgroup';
  options: readonly { id: string; label: string }[];
  onChange?: (selections: string[]) => void;
}

export interface DateFieldProps extends FormFieldProps {
  type: 'date';
  dateRange?: DateRange;
  allowAllDays?: boolean;
  isDateDisabled?: (date: Date) => boolean;
  invalidDateErrorText?: string;
  monthOnlySelection?: boolean;
  timeSelection?: boolean;
}

export interface SelectFieldProps extends FormFieldProps {
  type: 'select';
  placeholder?: string;
  options: OptionListOption[];
  onChange?: (selection: string) => void;
  forceNoCombobox?: boolean;
  comboboxInputMaxLength?: number;
  comboboxInputMode?: 'text' | 'numeric';
  trackOnChange?: TrackFn<string>;
}

export interface MultiselectFieldProps extends FormFieldProps {
  type: 'multiselect';
  placeholder?: string;
  options: OptionListOption[];
  onChange?: (selections: string[]) => void;
  trackOnChange?: TrackFn<string>;
}

type FieldProps =
  | TextFieldProps
  | CsvTextFieldProps
  | CheckboxFieldProps
  | CheckgroupFieldProps
  | DateFieldProps
  | SelectFieldProps
  | MultiselectFieldProps;

const COMBOBOX_THRESHOLD = 5;
const showCombobox = (options: OptionListOption[]) => options.length > COMBOBOX_THRESHOLD;

export const FormField = (props: FieldProps) => {
  switch (props.type) {
    case 'input':
    case 'textarea':
      return <FormFieldInput {...props} />;

    case 'csvInput':
      return <FormFieldCsvInput {...props} />;

    case 'checkbox':
      return <FormFieldCheckbox {...props} />;

    case 'checkgroup':
      return <FormFieldCheckgroup {...props} />;

    case 'date':
      return <FormFieldDatePicker {...props} />;

    case 'select':
      return showCombobox(props.options) && !props.forceNoCombobox ? (
        <FormFieldCombobox {...props} />
      ) : (
        <FormFieldSelect {...props} />
      );

    case 'multiselect':
      return <FormFieldMultiselect {...props} />;
  }
};
