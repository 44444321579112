import { InlineNotification } from 'common-components';
import { useTranslation } from 'react-i18next';

export const PreopenedNotice: React.FC<{ activationDeadlineCode?: string }> = ({
  activationDeadlineCode
}) => {
  const { t } = useTranslation();
  return (
    <InlineNotification
      title={t('subscriptions.subscriptionDetails.status.preopenedTitle')}
      content={
        activationDeadlineCode
          ? t('subscriptions.subscriptionDetails.status.preopenedMessageWithDeadline', {
              activationDeadline: t(
                `subscriptions.subscriptionDetails.status.preopenedMessageDeadline.${activationDeadlineCode}`
              )
            })
          : t('subscriptions.subscriptionDetails.status.preopenedMessage')
      }
      variant="information"
      data-testid="preopened-notification"
    />
  );
};
