import { BodyText, Flex, Gutter } from '@gaiads/telia-react-component-library';
import { Paragraph } from '@teliafi/fi-ds';
import InvoiceMainInfoSkeleton from 'B2XApp/Invoicing/Invoices/InvoiceDetails/InvoiceMainInfo/InvoiceMainInfoSkeleton';
import PayInvoiceButton from 'B2XApp/Invoicing/Invoices/InvoiceDetails/InvoicePayment/PayInvoiceButton';
import InvoiceStatusTag from 'B2XApp/Invoicing/Invoices/InvoiceList/InvoiceStatus/InvoiceStatusTag';
import {
  CollapsibleBlock,
  DataPanel,
  DefinitionList,
  Heading,
  Optional,
  TeliaLink
} from 'common-components';
import { Price } from 'common-components/Price/Price';
import isDemoEnvironment from 'doings/isDemoEnvironment/isDemoEnvironment';
import { invoicing } from 'doings/track/analyticsEvents';
import { Trans, useTranslation } from 'react-i18next';
import { InvoiceDetailsType, InvoiceType } from 'types/invoice';

const getInvoiceTypesExcludingFirst = (invoiceTypes: InvoiceType[]): InvoiceType[] => {
  return invoiceTypes.length <= 1 ? [] : invoiceTypes.slice(1);
};

const InvoiceMainInfo: React.FC<{
  invoice?: InvoiceDetailsType;
  loading: boolean;
  hasError: boolean;
}> = ({ invoice, loading, hasError }) => {
  const { t } = useTranslation();
  const isDemoEnv = isDemoEnvironment();
  const isMobileInvoice = invoice?.types.includes('MOBILE');

  return (
    <DataPanel
      skeletonLoader={<InvoiceMainInfoSkeleton />}
      loading={loading}
      hasError={hasError}
      data-testid="invoice-main-info"
    >
      {invoice && (
        <Flex directionColumn data-testid="invoice-main-info">
          <InvoiceStatusTag status={invoice.status} total={invoice.totalAmount} />

          <Gutter size="sm" />

          <Heading.H3 data-testid={`invoice-${invoice.status}`}>
            {t(`invoices.types.${invoice.types[0]}`)}
          </Heading.H3>

          <Heading.H3 data-testid="invoice-number">{invoice?.id}</Heading.H3>

          <Gutter size="sm" />

          {getInvoiceTypesExcludingFirst(invoice.types).map((type: string) => (
            <BodyText data-testid={`invoice-type-${type}`} key={`invoice-type-${type}`} size="sm">
              {t(`invoices.types.${type}`)}
            </BodyText>
          ))}

          <Gutter size="xlg" />

          <DefinitionList addDividers fiftyFifty trailingDivider>
            <DefinitionList.Item heading={t('invoice.due')} baseline>
              <Price data-testid="invoice-due-amount" price={invoice.amountOpen} />
            </DefinitionList.Item>

            <DefinitionList.Item heading={t('invoice.total')}>
              <Price data-testid="invoice-total-amount" price={invoice.totalAmount} size="md" />
            </DefinitionList.Item>
          </DefinitionList>

          <Gutter size="lg" />

          <Optional if={invoice.customer.customerType !== 'OPERATOR'}>
            <PayInvoiceButton invoice={invoice} />

            <Gutter />

            <CollapsibleBlock
              data-testid="invoice-payment-information"
              blockTitle={t('invoice.paymentInformation.title')}
              blockIcon="info"
              padding={{ vertical: 'sm' }}
            >
              <CollapsibleBlock.Content padding={{ left: 'zero', bottom: 'md' }}>
                <Paragraph>
                  <Trans>{t('invoice.paymentInformation.content')}</Trans>
                </Paragraph>
              </CollapsibleBlock.Content>
            </CollapsibleBlock>

            <Gutter />
          </Optional>

          <Flex>
            <TeliaLink.Download
              label={t('invoice.download')}
              to={`/api/invoices/${invoice?.key}?businessId=${invoice?.customer.code}`}
              iconLeft="download"
              data-testid="invoice-digital-copy-link"
              disabled={isDemoEnv}
              track={invoicing.downloadInvoice}
            />
          </Flex>

          {!!invoice.hasCallSpecification && (
            <TeliaLink.Download
              label={t('invoice.downloadCallSpecificationAsPdf')}
              to={`/api/invoices/${invoice?.key}/callSpecificationAsPdf?businessId=${invoice?.customer.code}`}
              iconLeft="download"
              data-testid="invoice-call-specification-download-pdf"
              disabled={isDemoEnv}
              track={invoicing.downloadInvoiceCallSpecificationPdf}
            />
          )}

          {!!invoice.hasCallSpecification && isMobileInvoice && (
            <TeliaLink.Download
              label={t('invoice.downloadCallItemizationAsExcel')}
              to={`/api/invoices/${invoice?.key}/callSpecificationAsExcel?businessId=${invoice?.customer.code}`}
              iconLeft="download"
              data-testid="invoice-call-specification-download-excel"
              disabled={isDemoEnv}
              track={invoicing.downloadInvoiceCallSpecificationExcel}
            />
          )}

          {invoice.types.includes('MOBILE') && (
            <TeliaLink.Download
              label={t('invoice.excelSubscription.download')}
              to={`/api/invoices/${invoice?.key}/reportBySubscription?businessId=${invoice?.customer.code}`}
              iconLeft="download"
              data-testid="invoice-report-by-subscription-link"
              disabled={isDemoEnv}
              track={invoicing.downloadInvoiceBySubscription}
            />
          )}

          {invoice.types.includes('MOBILE') && (
            <TeliaLink.Download
              label={t('invoice.excelCostCentre.download')}
              to={`/api/invoices/${invoice?.key}/reportByCostCenter?businessId=${invoice?.customer.code}`}
              iconLeft="download"
              data-testid="invoice-report-by-cost-center-link"
              disabled={isDemoEnv}
              track={invoicing.downloadInvoiceByCostCenter}
            />
          )}
        </Flex>
      )}
    </DataPanel>
  );
};

export default InvoiceMainInfo;
