import ContactUsPage from 'B2XApp/ContactUs/ContactUsPage';
import BillMillAccountDetails from 'B2XApp/Invoicing/BillingGroups/BillMillAccountDetails/BillMillAccountDetails';
import EditBillMillAccount from 'B2XApp/Invoicing/BillingGroups/EditBillMillAccount/EditBillMillAccount';
import { AuthorizationParams } from 'types/authorization';

import ActivityLogPage from './ActivityLog/ActivityLogPage';
import AnalyticsEvents from './AnalyticsEvents/AnalyticsEvents';
import routeAccessGroups from './appRouteAccessGroups';
import ChatbotPage from './Chatbot/ChatbotPage';
import ChatbotTestPrivate from './Chatbot/ChatbotTestPrivate';
import Dashboard from './Dashboard/Dashboard';
import DeviceDetails from './Devices/DeviceDetails/DeviceDetails';
import Devices from './Devices/Devices';
import AccessDenied from './Errors/AccessDenied/AccessDenied';
import AddFeatureFlag from './FeatureFlags/AddFeatureFlag';
import EditFeatureFlag from './FeatureFlags/EditFeatureFlag';
import FeatureFlags from './FeatureFlags/FeatureFlags';
import ChildAssetDetails from './InternetConnections/ChildAssetDetails/ChildAssetDetails';
import InternetConnectionDetails from './InternetConnections/InternetConnectionDetails/InternetConnectionDetails';
import InternetConnections from './InternetConnections/InternetConnectionsList/InternetConnectionsList';
import BillingGroupDetails from './Invoicing/BillingGroups/BillingGroupDetails/BillingGroupDetails';
import BillingGroups from './Invoicing/BillingGroups/BillingGroups';
import CopyBillingGroup from './Invoicing/BillingGroups/CopyBillingGroup/CopyBillingGroup';
import EditBillingGroup from './Invoicing/BillingGroups/EditBillingGroup/EditBillingGroup';
import NewBillingGroup from './Invoicing/BillingGroups/NewBillingGroup/NewBillingGroup';
import InvoiceDetails from './Invoicing/Invoices/InvoiceDetails/InvoiceDetails';
import Invoices from './Invoicing/Invoices/InvoiceList/Invoices';
import InvoicePaymentPlan from './Invoicing/Invoices/InvoicePaymentPlan/InvoicePaymentPlan';
import PaymentReminders from './Invoicing/PaymentReminders/PaymentReminders';
import { MessageArchive } from './Messages/MessageArchive';
import {
  NewServiceRequest,
  ServiceRequestDetails,
  ServiceRequestsList
} from './Messages/ServiceRequests';
import {
  CreateAssetTicket,
  CreateSubscriptionTicket,
  CreateTroubleTicket,
  TicketDetails,
  Tickets
} from './Messages/Tickets';
import { NewsRatings } from './NewsFeed/NewsRatings/NewsRatings';
import { NewsRatingsDetails } from './NewsFeed/NewsRatings/NewsRatingsDetails';
import Orders from './Orders/OrderList/Orders';
import Replay from './Replay/Replay';
import {
  GenerateOwnershipChangeToken,
  SubscriptionDetails,
  Subscriptions,
  TransferSubscription,
  TransferSubscriptionViaDetails,
  TransferSubscriptionViaList
} from './Subscriptions';
import ThreeSixtyProductInventory from './ThreeSixtyProductInventory/ThreeSixtyProductInventory';
import Version from './Version/Version';

export type RouteType = {
  routeNameTranslationKey: string;
  path: string;
  Component: React.FC;
  isExact: boolean;
  featureFlags?: string[];
  accessibleWhen?: AuthorizationParams;
  showCompanySelectorOnError?: boolean;
  hideBreadcrumb?: boolean;
  emailAccessList?: AccessControlGroup;
  routeKey?: string;
};

const routes: RouteType[] = [
  {
    routeNameTranslationKey: 'breadcrumb.root',
    path: '/',
    Component: Dashboard,
    isExact: true
  },
  {
    routeNameTranslationKey: 'breadcrumb.internetConnections',
    path: '/internet-connections',
    Component: InternetConnections,
    isExact: true,
    accessibleWhen: routeAccessGroups.ASSETS,
    showCompanySelectorOnError: true
  },
  {
    routeNameTranslationKey: 'breadcrumb.internetConnectionDetails',
    path: '/internet-connections/:assetId',
    Component: InternetConnectionDetails,
    isExact: true,
    accessibleWhen: routeAccessGroups.ASSETS
  },
  {
    routeNameTranslationKey: 'breadcrumb.internetConnectionDetails',
    path: '/internet-connections/:assetId/children/:childAssetId',
    Component: ChildAssetDetails,
    isExact: true,
    accessibleWhen: routeAccessGroups.ASSETS
  },
  {
    routeNameTranslationKey: 'breadcrumb.subscriptions',
    path: '/subscriptions',
    Component: Subscriptions,
    isExact: true,
    accessibleWhen: routeAccessGroups.MOBILE_SUBSCRIPTIONS,
    showCompanySelectorOnError: true
  },
  {
    routeNameTranslationKey: 'breadcrumb.subscriptionDetails',
    path: '/subscriptions/:phoneNumberHash',
    Component: SubscriptionDetails,
    isExact: true,
    accessibleWhen: routeAccessGroups.MOBILE_SUBSCRIPTIONS,
    routeKey: 'mobile-subscription-details'
  },
  {
    routeNameTranslationKey: 'breadcrumb.subscriptionTransfer',
    path: '/subscriptions/:phoneNumberHash/transfer',
    Component: TransferSubscription,
    isExact: true,
    accessibleWhen: routeAccessGroups.MOBILE_SUBSCRIPTIONS,
    hideBreadcrumb: true
  },
  {
    routeNameTranslationKey: 'breadcrumb.subscriptionOwnershipChangeToken',
    path: '/subscriptions/:phoneNumberHash/transfer/private',
    Component: GenerateOwnershipChangeToken,
    isExact: true,
    accessibleWhen: routeAccessGroups.MOBILE_SUBSCRIPTIONS,
    hideBreadcrumb: true
  },
  {
    routeNameTranslationKey: 'breadcrumb.subscriptionOwnershipChangeToken',
    path: '/subscriptions/:phoneNumberHash/transfer/company',
    Component: GenerateOwnershipChangeToken,
    isExact: true,
    accessibleWhen: routeAccessGroups.MOBILE_SUBSCRIPTIONS,
    hideBreadcrumb: true
  },
  {
    routeNameTranslationKey: 'breadcrumb.subscriptionTransfer',
    path: '/subscriptions/:phoneNumberHash/direct-transfer',
    Component: TransferSubscriptionViaDetails,
    isExact: true,
    accessibleWhen: routeAccessGroups.MOBILE_SUBSCRIPTIONS
  },
  {
    routeNameTranslationKey: 'breadcrumb.subscriptionOwnershipChangeToken',
    path: '/subscriptions/:phoneNumberHash/direct-transfer/private',
    Component: GenerateOwnershipChangeToken,
    isExact: true,
    accessibleWhen: routeAccessGroups.MOBILE_SUBSCRIPTIONS
  },
  {
    routeNameTranslationKey: 'breadcrumb.subscriptionOwnershipChangeToken',
    path: '/subscriptions/:phoneNumberHash/direct-transfer/company',
    Component: GenerateOwnershipChangeToken,
    isExact: true,
    accessibleWhen: routeAccessGroups.MOBILE_SUBSCRIPTIONS
  },
  {
    routeNameTranslationKey: 'breadcrumb.subscriptionTransfer',
    path: '/subscriptions/:phoneNumberHash/list-transfer',
    Component: TransferSubscriptionViaList,
    isExact: true,
    accessibleWhen: routeAccessGroups.MOBILE_SUBSCRIPTIONS,
    routeKey: 'mobile-subscription-details'
  },
  {
    routeNameTranslationKey: 'breadcrumb.subscriptionOwnershipChangeToken',
    path: '/subscriptions/:phoneNumberHash/list-transfer/private',
    Component: GenerateOwnershipChangeToken,
    isExact: true,
    accessibleWhen: routeAccessGroups.MOBILE_SUBSCRIPTIONS
  },
  {
    routeNameTranslationKey: 'breadcrumb.subscriptionOwnershipChangeToken',
    path: '/subscriptions/:phoneNumberHash/list-transfer/company',
    Component: GenerateOwnershipChangeToken,
    isExact: true,
    accessibleWhen: routeAccessGroups.MOBILE_SUBSCRIPTIONS
  },
  {
    routeNameTranslationKey: 'breadcrumb.devices',
    path: '/devices',
    Component: Devices,
    isExact: true,
    accessibleWhen: routeAccessGroups.DEVICES,
    showCompanySelectorOnError: true
  },
  {
    routeNameTranslationKey: 'breadcrumb.deviceDetails',
    path: '/devices/:deviceId',
    Component: DeviceDetails,
    isExact: true,
    featureFlags: ['EART-4420'],
    accessibleWhen: routeAccessGroups.DEVICES
  },
  {
    routeNameTranslationKey: 'breadcrumb.contactUs',
    path: '/contact-us',
    Component: ContactUsPage,
    accessibleWhen: routeAccessGroups.B2B_PAGES,
    isExact: true
  },
  {
    routeNameTranslationKey: 'breadcrumb.serviceRequests',
    path: '/service-requests',
    Component: ServiceRequestsList,
    isExact: true,
    accessibleWhen: routeAccessGroups.SERVICE_REQUESTS,
    showCompanySelectorOnError: true
  },
  {
    routeNameTranslationKey: 'breadcrumb.newServiceRequest',
    path: '/service-requests/new',
    Component: NewServiceRequest,
    isExact: true,
    accessibleWhen: routeAccessGroups.SERVICE_REQUESTS_NEW,
    routeKey: 'new-service-request'
  },
  {
    routeNameTranslationKey: 'breadcrumb.newServiceRequest',
    path: '/service-requests/new/:origin(corporate-product)/:key',
    Component: NewServiceRequest,
    isExact: true,
    featureFlags: ['EART-5426'],
    accessibleWhen: routeAccessGroups.SERVICE_REQUESTS_NEW,
    routeKey: 'new-service-request'
  },
  {
    routeNameTranslationKey: 'breadcrumb.newServiceRequest',
    path: '/service-requests/new/:origin(order-inquiry)/:key',
    Component: NewServiceRequest,
    isExact: true,
    accessibleWhen: routeAccessGroups.ORDERS_INQUIRY,
    routeKey: 'new-service-request'
  },
  {
    routeNameTranslationKey: 'breadcrumb.newServiceRequest',
    path: '/service-requests/new/:origin(billing-info-mass-change)',
    Component: NewServiceRequest,
    isExact: true,
    accessibleWhen: routeAccessGroups.BILLING_INFO_MASS_CHANGE,
    routeKey: 'new-service-request'
  },
  {
    routeNameTranslationKey: 'breadcrumb.newServiceRequest',
    path: '/service-requests/new/:origin/:key',
    Component: NewServiceRequest,
    isExact: true,
    accessibleWhen: routeAccessGroups.SERVICE_REQUESTS_NEW,
    routeKey: 'new-service-request'
  },
  {
    routeNameTranslationKey: 'breadcrumb.serviceRequestDetails',
    path: '/service-requests/:ticketId(WOTSF.*)',
    Component: TicketDetails,
    isExact: true,
    accessibleWhen: routeAccessGroups.INCIDENT_TICKETS
  },
  {
    routeNameTranslationKey: 'breadcrumb.serviceRequestDetails',
    path: '/service-requests/:serviceRequestId',
    Component: ServiceRequestDetails,
    isExact: true,
    accessibleWhen: routeAccessGroups.SERVICE_REQUESTS
  },
  {
    routeNameTranslationKey: 'breadcrumb.troubleTickets',
    path: '/tickets',
    Component: Tickets,
    isExact: true,
    accessibleWhen: routeAccessGroups.INCIDENT_TICKETS,
    showCompanySelectorOnError: true
  },
  {
    routeNameTranslationKey: 'breadcrumb.newTicket',
    path: '/tickets/new',
    Component: CreateTroubleTicket,
    isExact: true,
    accessibleWhen: routeAccessGroups.INCIDENT_TICKETS_NEW,
    routeKey: 'new-ticket'
  },
  {
    routeNameTranslationKey: 'breadcrumb.newTicket',
    path: '/tickets/new/asset/:assetId',
    Component: CreateAssetTicket,
    isExact: true,
    accessibleWhen: routeAccessGroups.INCIDENT_TICKETS_NEW,
    routeKey: 'new-ticket'
  },
  {
    routeNameTranslationKey: 'breadcrumb.newTicket',
    path: '/tickets/new/subscription/:phoneNumberHash',
    Component: CreateSubscriptionTicket,
    isExact: true,
    accessibleWhen: routeAccessGroups.INCIDENT_TICKETS_NEW,
    routeKey: 'new-ticket'
  },
  {
    routeNameTranslationKey: 'breadcrumb.ticketDetails',
    path: '/tickets/:ticketId',
    Component: TicketDetails,
    isExact: true,
    accessibleWhen: routeAccessGroups.INCIDENT_TICKETS
  },
  {
    routeNameTranslationKey: 'breadcrumb.serviceRequestsArchive',
    path: '/message-archive',
    Component: MessageArchive,
    isExact: false,
    featureFlags: ['DEO-3122'],
    accessibleWhen: routeAccessGroups.MESSAGE_ARCHIVE,
    showCompanySelectorOnError: true
  },
  {
    routeNameTranslationKey: 'breadcrumb.activityLog',
    path: '/activity-log',
    Component: ActivityLogPage,
    accessibleWhen: routeAccessGroups.ACTIVITY_LOG,
    isExact: true
  },
  {
    routeNameTranslationKey: 'breadcrumb.invoices',
    path: '/invoices',
    Component: Invoices,
    isExact: true,
    accessibleWhen: routeAccessGroups.BILLING_INVOICES,
    showCompanySelectorOnError: true
  },
  {
    routeNameTranslationKey: 'breadcrumb.invoiceDetails',
    path: '/invoices/:key',
    Component: InvoiceDetails,
    isExact: true,
    accessibleWhen: routeAccessGroups.BILLING_INVOICES
  },
  {
    routeNameTranslationKey: 'breadcrumb.paymentPlan',
    path: '/invoices/:key/paymentplan',
    Component: InvoicePaymentPlan,
    isExact: true,
    accessibleWhen: routeAccessGroups.BILLING_INVOICES
  },
  {
    routeNameTranslationKey: 'breadcrumb.paymentReminderService',
    path: '/invoice-payment-reminder',
    Component: PaymentReminders,
    isExact: true,
    accessibleWhen: routeAccessGroups.BILLING
  },
  {
    routeNameTranslationKey: 'breadcrumb.billingGroups',
    path: '/billing-accounts',
    Component: BillingGroups,
    isExact: true,
    accessibleWhen: routeAccessGroups.BILLING,
    showCompanySelectorOnError: true
  },
  {
    routeNameTranslationKey: 'breadcrumb.newBillingGroup',
    path: '/billing-accounts/new',
    Component: NewBillingGroup,
    isExact: true,
    featureFlags: ['DEO-2424'],
    accessibleWhen: routeAccessGroups.BILLING
  },
  {
    routeNameTranslationKey: 'breadcrumb.billingGroupDetails',
    path: '/billing-accounts/:collectionGroup(\\d{4})',
    Component: BillingGroupDetails,
    isExact: true,
    accessibleWhen: routeAccessGroups.BILLING
  },
  {
    routeNameTranslationKey: 'breadcrumb.billingGroupEdit',
    path: '/billing-accounts/:collectionGroup(\\d{4})/edit',
    Component: EditBillingGroup,
    isExact: true,
    featureFlags: ['DEO-2425'],
    accessibleWhen: routeAccessGroups.BILLING
  },
  {
    routeNameTranslationKey: 'breadcrumb.billingGroupCopy',
    path: '/billing-accounts/:collectionGroup(\\d{4})/copy',
    Component: CopyBillingGroup,
    isExact: true,
    featureFlags: ['DEO-2426'],
    accessibleWhen: routeAccessGroups.BILLING
  },
  {
    routeNameTranslationKey: 'breadcrumb.billingGroupDetails',
    path: '/billing-accounts/:accountId(\\d{5,})',
    Component: BillMillAccountDetails,
    isExact: true,
    accessibleWhen: routeAccessGroups.BILLING
  },
  {
    routeNameTranslationKey: 'breadcrumb.billingGroupEdit',
    path: '/billing-accounts/:accountId(\\d{5,})/edit',
    Component: EditBillMillAccount,
    isExact: true,
    featureFlags: ['DEO-2425'],
    accessibleWhen: routeAccessGroups.BILLING
  },
  {
    routeNameTranslationKey: 'breadcrumb.orders',
    path: '/orders',
    Component: Orders,
    isExact: true,
    accessibleWhen: routeAccessGroups.ORDERS,
    showCompanySelectorOnError: true
  },
  {
    routeNameTranslationKey: 'breadcrumb.version',
    path: '/version',
    Component: Version,
    isExact: true,
    accessibleWhen: routeAccessGroups.ADMIN
  },
  {
    routeNameTranslationKey: 'breadcrumb.analyticsEvents',
    path: '/analytics-events',
    Component: AnalyticsEvents,
    isExact: true,
    accessibleWhen: routeAccessGroups.ADMIN,
    emailAccessList: 'EXTENDED_APP_INFO_USERS'
  },
  {
    routeNameTranslationKey: 'breadcrumb.featureFlags',
    path: '/flags',
    Component: FeatureFlags,
    isExact: true,
    accessibleWhen: routeAccessGroups.ADMIN,
    emailAccessList: 'FEATURE_FLAG_MANAGEMENT_USERS'
  },
  {
    routeNameTranslationKey: 'breadcrumb.addFeatureFlag',
    path: '/flags/new',
    Component: AddFeatureFlag,
    isExact: true,
    accessibleWhen: routeAccessGroups.ADMIN,
    emailAccessList: 'FEATURE_FLAG_MANAGEMENT_USERS'
  },
  {
    routeNameTranslationKey: 'breadcrumb.editFeatureFlag',
    path: '/flags/edit/:flagName',
    Component: EditFeatureFlag,
    isExact: true,
    accessibleWhen: routeAccessGroups.ADMIN,
    emailAccessList: 'FEATURE_FLAG_MANAGEMENT_USERS'
  },
  {
    routeNameTranslationKey: 'breadcrumb.newsRatings',
    path: '/news-ratings',
    Component: NewsRatings,
    isExact: true,
    accessibleWhen: routeAccessGroups.ADMIN,
    emailAccessList: 'PRODUCT_NEWS_MANAGEMENT_USERS'
  },
  {
    routeNameTranslationKey: 'breadcrumb.newsRatingsDetails',
    path: '/news-ratings/:newsId',
    Component: NewsRatingsDetails,
    isExact: true,
    accessibleWhen: routeAccessGroups.ADMIN,
    emailAccessList: 'PRODUCT_NEWS_MANAGEMENT_USERS'
  },
  {
    routeNameTranslationKey: 'breadcrumb.orders',
    path: '/three-sixty-product-inventory',
    Component: ThreeSixtyProductInventory,
    isExact: true,
    accessibleWhen: routeAccessGroups.ORDERS,
    showCompanySelectorOnError: true
  },
  {
    routeNameTranslationKey: 'breadcrumb.chatbot',
    path: '/omalomake',
    Component: ChatbotPage,
    isExact: true
  },
  {
    routeNameTranslationKey: 'breadcrumb.chatbot',
    path: '/chatbot_test_private',
    Component: ChatbotTestPrivate,
    isExact: true
  },
  {
    routeNameTranslationKey: 'breadcrumb.root',
    path: '/replay',
    Component: Replay,
    isExact: true
  },
  {
    routeNameTranslationKey: 'errors.forbidden.headline',
    path: '/access-denied',
    Component: AccessDenied,
    isExact: true
  }
];

export default routes;
