import { getDefaultDateRange } from 'doings/datetime/getDefaultDateRange';
import useWriteQueryParams from 'hooks/useQueryParams/useWriteQueryParams';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CaseStatuses } from 'types/companyCaseList';

type UseCompanyCaseListFiltersProps = {
  initialAllCompanies?: boolean;
};

/**
 * Creates filters for searching over or filtering Multibella cases using
 * supported filter criteria: all companies, search term, reported date,
 * case status. Exposes actions to update and reset supported filter criteria.
 */
const useCompanyCaseListFilters = ({ initialAllCompanies }: UseCompanyCaseListFiltersProps) => {
  const { t } = useTranslation();
  const [allCompanies, setAllCompanies] = useState<boolean>(initialAllCompanies ?? false);
  const toggleAllCompanies = useCallback(() => {
    setAllCompanies(!allCompanies);
  }, [allCompanies, setAllCompanies]);

  const { defaultDateFromPlaceholder, defaultDateToPlaceholder } = getDefaultDateRange();

  const statusOptions = CaseStatuses.map((status) => ({
    label: t(`messageArchive.status.${status}`),
    value: status
  }));

  const { write: writeQueryParams } = useWriteQueryParams();
  useEffect(() => {
    writeQueryParams({ allCompanies: allCompanies ? 'true' : 'false' });
  }, [writeQueryParams, allCompanies]);

  return {
    allCompanies,
    toggleAllCompanies,
    defaultDateFromPlaceholder,
    defaultDateToPlaceholder,
    statusOptions
  };
};

export default useCompanyCaseListFilters;
