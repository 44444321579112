import getClassNames from 'classnames';
import { AnalyticsEvent } from 'doings/track/analyticsEvents';
import trackEvent from 'doings/track/trackEvent';
import React from 'react';

import styles from './Analytics.module.scss';

const isAnyChildDisabled = (containerRef: React.RefObject<HTMLDivElement>): boolean => {
  const container = containerRef.current;
  /* istanbul ignore next */
  if (!container) {
    return false;
  }

  // match any disabled first and nth-level children
  return !!container.querySelector('[disabled], [aria-disabled="true"]');
};

/**
 * Sends out a Google analytics event when the user clicks or invokes
 * the `onClick` event on a directly nested component, but only if no
 * immediate or nested child is disabled. Otherwise, renders the child
 * as is.
 *
 * Note that if an analytics event should be triggered conditionally,
 * this component should only surround a target element if said
 * condition is fulfilled (e.g. if a disabled link shouldn't send out
 * its associated analytics event, the component shouldn't surround
 * the link). An alternative is to explicitly disable this component.
 *
 * Note that the component renders its own container. The property
 * `inheritContainerHeight` can be used to have the container inherit
 * the parent container's height explicitly, so a nested child could
 * take up more possible vertical space for visual and interaction
 * purposes, e.g. in grid cells. Use with care, as the property can
 * backfire in places other than grids.
 *
 * @param children The immediate child element whose click to track.
 * @param whenClicked The analytics event to push to the data layer on click.
 * @param disabled Whether the event should be explicitly left untracked.
 * @param inlineBlock Whether the component should be rendered inline or as a standalone block (default)
 * @param inheritContainerHeight Whether to inherit the parent container's height.
 * @param inheritContainerMaxWidth Whether to inherit the parent container's max width.
 */
const Analytics: React.FC<{
  children: JSX.Element;
  whenClicked: AnalyticsEvent;
  disabled?: boolean;
  ignoreDisabledChildren?: boolean;
  inlineBlock?: boolean;
  inheritContainerHeight?: boolean;
  inheritContainerMaxWidth?: boolean;
  className?: string;
  tagName?: 'div' | 'span';
  ['data-testid']?: string;
}> = ({
  children,
  whenClicked,
  disabled,
  ignoreDisabledChildren,
  inlineBlock,
  inheritContainerHeight,
  inheritContainerMaxWidth,
  className,
  tagName = 'span',
  'data-testid': dataTestid
}) => {
  const containerRef: React.RefObject<HTMLDivElement> = React.useRef(null);
  const containerClassName = getClassNames(
    {
      [styles.heightInheritedContainer]: inheritContainerHeight,
      [styles.maxWidthInheritedContainer]: inheritContainerMaxWidth,
      [styles.inlineBlock]: inlineBlock
    },
    className
  );

  const Tag = tagName;
  const child = React.Children.only(children);
  return (
    <Tag
      ref={containerRef}
      className={containerClassName}
      onClick={() => {
        if (!disabled && (ignoreDisabledChildren || !isAnyChildDisabled(containerRef))) {
          trackEvent(whenClicked);
        }
      }}
      data-testid={dataTestid}
    >
      {child}
    </Tag>
  );
};

export default Analytics;
