import { gql } from '@apollo/client';
import { DateFormats } from 'doings/formatDatetime/DateFormats';
import formatDatetime from 'doings/formatDatetime/formatDatetime';
import { getDefaultDateParams } from 'hooks/serviceRequests/useServiceRequests/getDefaultDateParams';
import mapTicketDates from 'hooks/tickets/useTicket/mapTicketDates';
import useApiStatus from 'hooks/useApiStatus/useApiStatus';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { FilterBy } from 'types/serviceRequest';
import { TicketListItem, TicketStatus } from 'types/ticket';

import getTicketsOnFetchMore from './getTicketsOnFetchMore';

enum Fields {
  creationDate = 'creationDate',
  lastUpdate = 'lastUpdate'
}

enum Order {
  ASC = 'asc',
  DESC = 'desc'
}

export type UseFetchTicketListProps = {
  skip: boolean;
  queryParameters: TicketsQueryParameters;
  allCompanies?: boolean;
};

export interface TicketsQueryParameters {
  status?: TicketStatus;
  dateParameters?: TicketsDateFilterParameters;
  subscriptionId?: string;
  ticketId?: string;
  subject?: string;
  description?: string;
  allCompanies?: boolean;
}

export interface TicketsDateFilterParameters {
  dateFrom?: Date;
  dateTo?: Date;
  filterBy?: FilterBy;
}

type UseFetchTicketListResponse = {
  tickets: {
    items?: TicketListItem[];
    total?: number;
  };
};

export const ticketsQuery = gql`
  query GetFaultReports(
    $offset: Int!
    $limit: Int!
    $status: String
    $dateParameters: TicketDateParameters
    $sort: SortParameters
    $subscriptionId: String
    $subject: String
    $description: String
    $ticketId: String
    $allCompanies: Boolean
  ) {
    tickets(
      offset: $offset
      limit: $limit
      status: $status
      dateParameters: $dateParameters
      sort: $sort
      subscriptionId: $subscriptionId
      ticketId: $ticketId
      subject: $subject
      description: $description
      allCompanies: $allCompanies
    ) {
      total
      items {
        id
        status
        name
        subscription
        creationDate
        lastUpdate
        companyCode
        companyName
      }
    }
  }
`;

const getFormattedDateFilters = (dateParameters: TicketsDateFilterParameters | undefined) =>
  dateParameters?.dateFrom && dateParameters?.dateTo
    ? {
        dateFrom: formatDatetime(dateParameters?.dateFrom, DateFormats.DATE),
        dateTo: formatDatetime(dateParameters?.dateTo, DateFormats.DATE),
        filterBy: dateParameters?.filterBy
      }
    : undefined;

const getMappedTickets = (data: UseFetchTicketListResponse): TicketListItem[] =>
  data.tickets?.items?.map(mapTicketDates) ?? [];

const useFetchTicketList = ({ skip, queryParameters, allCompanies }: UseFetchTicketListProps) => {
  const { status, dateParameters, subscriptionId, ticketId, subject, description } =
    queryParameters;
  const defaultDateParams = getDefaultDateParams(dateParameters?.filterBy);
  const hasDefaultParams = !dateParameters?.dateFrom && !dateParameters?.dateTo;
  const newDateParams = hasDefaultParams
    ? defaultDateParams
    : getFormattedDateFilters(dateParameters);

  const { data, error, loading, loaded, loadingMore, fetchMore } =
    useCallBackend<UseFetchTicketListResponse>({
      skip,
      query: ticketsQuery,
      queryVariables: {
        offset: 0,
        limit: 10,
        dateParameters: newDateParams,
        sort: {
          field: Fields.lastUpdate,
          order: Order.DESC
        },
        status,
        subscriptionId: subscriptionId || undefined,
        ticketId: ticketId || undefined,
        subject: subject || undefined,
        description: description || undefined,
        allCompanies
      },
      fetchPolicy: 'network-only'
    });

  return {
    error,
    tickets: data ? getMappedTickets(data) : [],
    total: data?.tickets?.total,
    fetchMore: () =>
      fetchMore({
        variables: { offset: data?.tickets?.items?.length },
        updateQuery: getTicketsOnFetchMore
      }),
    apiState: useApiStatus({
      loading,
      error,
      loadingMore,
      loaded,
      items: data?.tickets?.items ?? [],
      query: status ? { status } : {}
    })
  };
};

export default useFetchTicketList;
