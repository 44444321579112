import { BodyText } from '@gaiads/telia-react-component-library';
import { Analytics, BodyTextClickable, DefinitionList } from 'common-components';
import { EN_DASH } from 'doings/dash/dash';
import formatDatetime, { DateFormats } from 'doings/formatDatetime/formatDatetime';
import { mobile } from 'doings/track/analyticsEvents';
import { Owner } from 'hooks/mobileSubscriptions/useSubscriptionDetails/subscriptionDetailsQuery';
import { getOr } from 'lodash/fp';
import { useTranslation } from 'react-i18next';

const SubscriptionAssetInfo: React.FC<{
  activeFrom?: string;
  owner?: Owner | null;
  userName?: string;
  additionalName?: string | null;
  isSurfCard: boolean;
  lastTimeInService?: string;
  loading?: boolean;
  openUserModal: VoidFunction;
}> = ({
  activeFrom,
  owner,
  userName,
  additionalName,
  isSurfCard,
  lastTimeInService,
  loading,
  openUserModal
}) => {
  const { t } = useTranslation();

  return (
    <DefinitionList data-testid="subscription-asset-info" loading={loading} addDividers fiftyFifty>
      <DefinitionList.Item
        heading={t('subscriptions.subscriptionDetails.user')}
        data-testid="subscription-asset-info-user"
      >
        <Analytics whenClicked={mobile.viewUserInformation}>
          <BodyTextClickable
            onClick={openUserModal}
            tagName="div"
            data-testid="show-subscription-details-information"
            data-dd-action-name="Show subscription details"
            vocaLink
          >
            <BodyText block tagName="span">
              {userName}
            </BodyText>

            {!isSurfCard && (
              <BodyText block tagName="span">
                {additionalName}
              </BodyText>
            )}
          </BodyTextClickable>
        </Analytics>
      </DefinitionList.Item>

      <DefinitionList.MultilineItem
        data-testid="subscription-asset-info-owner"
        heading={t('subscriptions.subscriptionDetails.owner')}
        values={[getOr('', 'companyName', owner), getOr('', 'companyCode', owner)]}
      />

      <DefinitionList.Item
        heading={t('subscriptions.subscriptionDetails.opened')}
        data-testid="subscription-asset-info-active-from"
      >
        <BodyText data-testid="subscription-opened">
          {activeFrom ? formatDatetime(activeFrom) : EN_DASH}
        </BodyText>
      </DefinitionList.Item>

      {lastTimeInService && (
        <DefinitionList.Item
          heading={t('subscriptions.subscriptionDetails.lastTimeInService')}
          data-testid="subscription-asset-info-last-time-in-service"
        >
          <BodyText data-testid="subscription-last-time-in-service">
            {formatDatetime(lastTimeInService, DateFormats.DATE_TIME_NATIONAL)}
          </BodyText>
        </DefinitionList.Item>
      )}
    </DefinitionList>
  );
};

export default SubscriptionAssetInfo;
