import { Checkbox } from '@gaiads/telia-react-component-library';
import { TextWithInlineHtml } from 'common-components';
import { SmartTooltip } from 'common-components/SmartTooltip/SmartTooltip';
import { AnalyticsEvent } from 'doings/track/analyticsEvents';
import { trackEventIfPresent } from 'doings/track/trackEvent';
import { CheckboxModel } from 'hooks/inputModels/useCheckboxModel/useCheckboxModel';

const ModelCheckbox: React.FC<{
  label: string;
  name?: string;
  analyticsEvent?: AnalyticsEvent;
  disabled?: boolean;
  checkboxModel: CheckboxModel;
  helperText?: string;
  'data-testid'?: string;
}> = ({
  label,
  name,
  disabled,
  checkboxModel,
  analyticsEvent,
  helperText,
  'data-testid': testId
}) => {
  const onChange = () => {
    checkboxModel.setChecked(!checkboxModel.checked);
    trackEventIfPresent(analyticsEvent);
  };

  return (
    <Checkbox
      label={
        <>
          {`${label}${checkboxModel.required ? ' *' : ''}`}

          {helperText && (
            <SmartTooltip tooltipContent={<TextWithInlineHtml size="sm" text={helperText} />}>
              <SmartTooltip.InfoIcon size="md" spacing="xs" />
            </SmartTooltip>
          )}
        </>
      }
      name={name}
      checked={checkboxModel.checked}
      onChange={onChange}
      disabled={disabled}
      data-testid={testId}
      data-is-disabled={disabled}
    />
  );
};

export default ModelCheckbox;
