import { Gutter } from '@gaiads/telia-react-component-library';
import { Skeleton } from 'common-components';

const AdditionalTicketInfoSkeleton: React.FC = () => (
  <Skeleton.Panel>
    <Skeleton.TextRow width="60%" />

    <Gutter size="xs" />

    <Skeleton.TextRow width="60%" />

    <Gutter size="xs" />

    <Skeleton.TextRow width="60%" />
  </Skeleton.Panel>
);

export default AdditionalTicketInfoSkeleton;
