import { useLocation } from 'react-router';

type Params = {
  [key: string]: string;
};

export default (params: string[]): Params => {
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);

  return params.reduce((output, param) => {
    const value = urlParams.get(param);
    if (!value) {
      return output;
    }

    return {
      ...output,
      [param]: value
    };
  }, {} as Params);
};
