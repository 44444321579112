import { Backdrop as BackdropFromLib } from '@gaiads/telia-react-component-library';
import { useSubmenuState } from 'contexts/SubmenuContext/SubmenuContext';

const Backdrop: React.FC = () => {
  const { openMenuId, setOpenMenuId, isMobileMenuOpen, setMobileMenuOpen } = useSubmenuState();

  return (
    <BackdropFromLib
      isVisible={!!openMenuId || isMobileMenuOpen}
      onCloseClick={() => {
        setOpenMenuId('');
        setMobileMenuOpen(false);
      }}
    />
  );
};

export default Backdrop;
