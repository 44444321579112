import {
  SubscriptionAttribute,
  SubscriptionStatus
} from 'hooks/mobileSubscriptions/useSubscriptionDetails/subscriptionDetailsQuery';
import useApiStatus, { ViewStatus } from 'hooks/useApiStatus/useApiStatus';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { useTranslation } from 'react-i18next';
import { SortParameter } from 'types/sort';

import getSubscriptionsOnFetchMore from './getSubscriptionsOnFetchMore';
import mapSubscription from './mapSubscription';
import subscriptionsQuery, { SubscriptionsResponse } from './subscriptionsQuery';

type SubscriptionsQueryParameter = {
  subscriptionTypeName?: string;
  icc?: string;
  msisdn?: string;
  userName?: string;
  costCenter?: string;
  collectionGroup?: string;
};

const useSubscriptions = (
  sortParameter: SortParameter,
  query: SubscriptionsQueryParameter,
  skip = false
) => {
  const {
    data,
    error,
    loading,
    loadingMore,
    loaded,
    fetchMore: fetchMoreFromQuery
  } = useCallBackend<SubscriptionsResponse>({
    query: subscriptionsQuery,
    fetchPolicy: 'network-only',
    queryVariables: {
      query,
      offset: 0,
      pageSize: 20,
      ...sortParameter
    },
    skip
  });

  const { t } = useTranslation();

  const emptyUsernamePlaceholder = t('subscriptions.noUserName');

  const normalizeReceivedSubscription = mapSubscription(emptyUsernamePlaceholder);

  const receivedSubscriptions = data?.subscriptions.items ?? [];

  const mappedSubscriptions = receivedSubscriptions.map(normalizeReceivedSubscription);

  const subscriptionsOrPlaceholders =
    loading && mappedSubscriptions.length === 0
      ? [
          {
            id: '',
            attributes: [] as SubscriptionAttribute[],
            status: 'PREOPENED' as SubscriptionStatus,
            type: '',
            packages: {},
            phoneNumber: '',
            phoneNumberHash: '',
            user: '',
            isSurfCard: false
          },
          {
            id: '',
            attributes: [] as SubscriptionAttribute[],
            status: 'PREOPENED' as SubscriptionStatus,
            type: '',
            packages: {},
            phoneNumber: '',
            phoneNumberHash: '',
            user: '',
            isSurfCard: false
          },
          {
            id: '',
            attributes: [] as SubscriptionAttribute[],
            status: 'PREOPENED' as SubscriptionStatus,
            type: '',
            packages: {},
            phoneNumber: '',
            phoneNumberHash: '',
            user: '',
            isSurfCard: false
          }
        ]
      : mappedSubscriptions;

  const apiStatus = useApiStatus({
    loading,
    error,
    loadingMore,
    loaded,
    items: mappedSubscriptions,
    query
  });

  return !skip
    ? {
        subscriptions: {
          total: data?.subscriptions.total ?? 0,
          items: subscriptionsOrPlaceholders
        },
        error,
        loading,
        loadingMore,
        fetchMore: () =>
          fetchMoreFromQuery({
            variables: { offset: mappedSubscriptions.length },
            updateQuery: getSubscriptionsOnFetchMore
          }),
        hasMoreResults: data ? mappedSubscriptions.length < data.subscriptions.total : true,
        apiStatus
      }
    : {
        subscriptions: {
          total: 0,
          items: []
        },
        error: undefined,
        loading: false,
        loadingMore: false,
        fetchMore: () => Promise.resolve(),
        hasMoreResults: false,
        apiStatus: ViewStatus.Loaded
      };
};

export default useSubscriptions;
