import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { get } from 'lodash/fp';

import lekaneTokenQuery from './lekaneTokenQuery';

const useLekaneToken = () => {
  const { data, error, loading } = useCallBackend({
    query: lekaneTokenQuery
  });

  const token: string | undefined = get('lekaneToken', data);

  return { token, error, loading };
};

export default useLekaneToken;
