import { InlineNotification } from 'common-components';

export const CompletedOwnershipChangeNotice: React.FC = () => (
  <InlineNotification
    title={{ key: 'subscriptions.subscriptionDetails.status.ownershipChange.completed' }}
    content={{ key: 'subscriptions.subscriptionDetails.status.ownershipChange.completedMessage' }}
    variant="success"
    data-testid="ownership-change-completed-notification"
  />
);
