import { Div, Divider, Flex, Gutter } from '@gaiads/telia-react-component-library';
import { Button, Icon } from '@teliafi/fi-ds';
import {
  Message,
  ReplyForm,
  TicketDetailsBox,
  TicketDetailsTimeline,
  TicketStatusTag
} from 'B2XApp/Messages/common';
import {
  getAuthorFirstName,
  getAuthorLastName,
  getReporter,
  getResolverName,
  getTeliaAndCustomerAttachments,
  mapAttachments,
  mapIndividualsContact,
  mapInitialAttachments
} from 'B2XApp/Messages/Tickets/TicketDetailsPage/ticketDetailsHelpers';
import { Authorize, DefinitionListMultilineItemProps, MobilePadding } from 'common-components';
import DetailsPage from 'common-components/DetailsPage/DetailsPage';
import { useFeatureFlagsData } from 'contexts/FeatureFlagContext/FeatureFlagContext';
import formatDatetime, { DateFormats } from 'doings/formatDatetime/formatDatetime';
import { messages } from 'doings/track/analyticsEvents';
import { SendServiceRequestMessageParameters } from 'hooks/serviceRequests/useSendServiceRequestMessage/useSendServiceRequestMessage';
import { useTicketMessagesOrder } from 'hooks/tickets/useTicketMessagesOrder.ts/useTicketMessagesOrder';
import { TFunction } from 'i18next';
import { compact } from 'lodash/fp';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { ServiceRequestDetailsItem } from 'types/serviceRequest';
import { SortOrder } from 'types/sort';

import ticketDetailsStyles from '../../Tickets/TicketDetailsPage/TicketDetailsPage.module.scss';
import ServiceRequestNotices from './ServiceRequestNotices';

const getMessages = (
  serviceRequest: ServiceRequestDetailsItem,
  sortOrder: SortOrder
): Message[] => {
  const reporter = getReporter(serviceRequest);

  const initialMessage: Message = {
    header: serviceRequest.name,
    date: serviceRequest.creationDate,
    description: serviceRequest.description,
    source: 'Customer',
    authorFirstName: getAuthorFirstName(reporter),
    authorLastName: getAuthorLastName(reporter),
    attachments: mapInitialAttachments(serviceRequest)
  };

  const messages = serviceRequest.note.map<Message>((note) => ({
    date: note.date,
    description: note.text,
    source: note.source,
    authorFirstName: note.authorFirstName,
    authorLastName: note.authorLastName,
    attachments: mapAttachments(serviceRequest, note)
  }));

  const { resolutionDate, solutionDescription } = serviceRequest;

  const resolutionMessage =
    resolutionDate &&
    ({
      attachments: [],
      date: resolutionDate,
      description: solutionDescription,
      source: 'Telia',
      authorFirstName: getResolverName(serviceRequest)
    } as Message);

  const allMessages = resolutionMessage
    ? [resolutionMessage, ...messages, initialMessage]
    : [...messages, initialMessage];
  return sortOrder === SortOrder.ASC ? allMessages.toReversed() : allMessages;
};

interface GetDetailsArgs {
  serviceRequest: ServiceRequestDetailsItem | undefined;
  splitAttachments?: boolean;
  t: TFunction;
}

const getServiceRequestDetails = (serviceRequest: ServiceRequestDetailsItem, t: TFunction) =>
  compact([
    serviceRequest.id && {
      heading: 'serviceRequests.details.id',
      values: [serviceRequest.caseNumber]
    },
    serviceRequest.productName && {
      heading: 'serviceRequests.details.productName',
      values: [serviceRequest.productName]
    },
    serviceRequest.subscriptionNumber && {
      heading:
        serviceRequest.companyGroupType === 'b2b_b2o'
          ? 'serviceRequests.details.salesportalId'
          : 'serviceRequests.details.subscriptionNumber',
      values: [serviceRequest.subscriptionNumber]
    },
    serviceRequest.invoiceNumber && {
      heading: 'serviceRequests.details.invoiceNumber',
      values: [serviceRequest.invoiceNumber]
    },
    serviceRequest.creationDate && {
      heading: 'serviceRequests.details.creationDate',
      values: [formatDatetime(serviceRequest.creationDate, DateFormats.DATE_TIME_NATIONAL_SHORT)]
    },
    serviceRequest.lastUpdate && {
      heading: 'serviceRequests.details.updatedDate',
      values: [formatDatetime(serviceRequest.lastUpdate, DateFormats.DATE_TIME_NATIONAL_SHORT)]
    },
    serviceRequest.companyName && {
      heading: 'serviceRequests.details.company',
      values: [serviceRequest.companyCode, serviceRequest.companyName]
    },
    ...mapIndividualsContact(t, serviceRequest.relatedIndividual ?? [])
  ]);

const getServiceRequestAttachments = (serviceRequest: ServiceRequestDetailsItem, t: TFunction) => {
  const attachments = [];
  const { customerAttachments, teliaAttachments } = getTeliaAndCustomerAttachments(serviceRequest);
  if (customerAttachments.length) {
    attachments.push({
      heading: t('tickets.details.attachmentsFromYou'),
      type: 'attachment',
      values: customerAttachments
    });
  }

  if (teliaAttachments.length) {
    attachments.push({
      heading: t('tickets.details.attachmentsFromTelia'),
      type: 'attachment',
      values: teliaAttachments
    });
  }

  return attachments;
};

const getDetails = ({ serviceRequest, splitAttachments, t }: GetDetailsArgs) => {
  if (!serviceRequest) {
    return [];
  }

  const details: DefinitionListMultilineItemProps[] = getServiceRequestDetails(serviceRequest, t);
  const attachments = splitAttachments
    ? getServiceRequestAttachments(serviceRequest, t)
    : [
        {
          heading: t('tickets.allAttachments.title'),
          type: 'attachment',
          values: serviceRequest.attachments
        }
      ];

  return [...details, ...attachments] as DefinitionListMultilineItemProps[];
};

const getServiceRequestInfo = (serviceRequest?: ServiceRequestDetailsItem) =>
  serviceRequest && {
    idHeader: 'serviceRequests.details.id',
    status: serviceRequest.status,
    statusHeader: 'serviceRequests.details.status',
    title: serviceRequest.name
  };

export type ReplyToServiceRequestParams = {
  sendMessage: (variables: SendServiceRequestMessageParameters) => void;
  replyToTicketVisible: boolean;
  setReplyToTicketVisible: Dispatch<SetStateAction<boolean>>;
  setReplySentMessageVisible: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  hasError: boolean;
  replySent: boolean;
};

const ServiceRequestDetailsPage: React.FC<{
  serviceRequest?: ServiceRequestDetailsItem;
  loading: boolean;
  hasError: boolean;
  replyToServiceRequest: ReplyToServiceRequestParams;
}> = ({ loading, serviceRequest, hasError, replyToServiceRequest }) => {
  const { t } = useTranslation();
  const {
    order: messageOrder,
    isOrderAsc: isMessageOrderAsc,
    toggleOrder: toggleMessageOrder
  } = useTicketMessagesOrder();
  const {
    sendMessage,
    replyToTicketVisible,
    setReplyToTicketVisible,
    setReplySentMessageVisible,
    loading: replyLoading,
    hasError: hasReplyError,
    replySent
  } = replyToServiceRequest;

  const serviceRequestIsOpen = !['Closed', 'Cancelled'].includes(serviceRequest?.status ?? '');
  const canShowReplyForm = serviceRequest && replyToTicketVisible;
  const canShowReplyButton = !loading && serviceRequestIsOpen && !replyToTicketVisible;
  const serviceRequestInfo = getServiceRequestInfo(serviceRequest);
  const { hasFeature } = useFeatureFlagsData();
  const isChatViewEnabled = hasFeature('EAT-54396');

  // TODO: Right now the error state of loading SR is lost due to we having other hooks in the container level.
  // The loading error panel was inside main info component causing mis-alignment. Moved the logic to this level.
  // This means should show error when we have an error or we are not loading and there is also no SR available.
  return (
    <DetailsPage
      hasError={hasError || (!serviceRequest && !loading)}
      data-testid="service-request-details-page"
      loading={loading}
      headline={serviceRequestInfo?.title || ''}
      headlineTag={serviceRequestInfo && <TicketStatusTag status={serviceRequestInfo.status} />}
    >
      <MobilePadding>
        <ServiceRequestNotices status={serviceRequest?.status} />

        <Flex pileVerticallyTo="md">
          <Div flexItem={{ sizeMultiplier: 2 }}>
            {canShowReplyButton && (
              <Div>
                <Div className={ticketDetailsStyles.controls}>
                  <Authorize
                    enabledWhen={{ onePermission: 'SERVICE_REQUESTS' }}
                    element={
                      <Button
                        onClick={() => setReplyToTicketVisible(true)}
                        className={ticketDetailsStyles.reply_now_btn}
                        data-testid="service-request-details-reply-button"
                      >
                        <Icon name="plus" size="sm" />
                        {t('tickets.details.reply')}
                      </Button>
                    }
                  />

                  <Button
                    variant="text"
                    onClick={toggleMessageOrder}
                    className={ticketDetailsStyles.sorter}
                  >
                    <Icon name={`arrow-${isMessageOrderAsc ? 'down' : 'up'}`} size="sm" />
                    {t(`tickets.details.messageOrder${isMessageOrderAsc ? 'Asc' : 'Desc'}`)}
                  </Button>
                </Div>

                <Gutter />

                <Divider />
              </Div>
            )}

            {canShowReplyForm && (
              <ReplyForm
                maxNoteLength={4000}
                replyAnalyticsEvent={messages.commentServiceRequest}
                loading={replyLoading}
                error={hasReplyError}
                onReply={(note, attachments) =>
                  sendMessage({
                    id: serviceRequest.id,
                    text: note,
                    attachments
                  })
                }
                replySent={replySent}
                setShowReplySentMessage={setReplySentMessageVisible}
                setShowReplyForm={setReplyToTicketVisible}
              />
            )}

            {!hasError && (
              <TicketDetailsTimeline
                messages={serviceRequest ? getMessages(serviceRequest, messageOrder) : []}
                loading={loading}
                footer="serviceRequests.details.created"
                showChatView={isChatViewEnabled}
              />
            )}
          </Div>

          <Gutter />

          <Div flexItem={{ sizeMultiplier: 1 }}>
            {!hasError && (
              <TicketDetailsBox
                listItems={getDetails({ serviceRequest, splitAttachments: isChatViewEnabled, t })}
                loading={loading}
              />
            )}
          </Div>
        </Flex>
      </MobilePadding>

      <Gutter size="lg" />
    </DetailsPage>
  );
};

export default ServiceRequestDetailsPage;
