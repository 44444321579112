import { SectionDefinition } from 'B2XApp/Dashboard/DashboardNavigation/DashboardSections/sections/sectionDefinition';
import { DashboardSectionId } from 'B2XApp/Dashboard/sections';
import { LinkTarget, NavigationalLinks } from 'doings/getNavigationalLinks/getNavigationalLinks';

export const communicationSection: SectionDefinition = {
  id: DashboardSectionId.Communication,
  icon: 'email',
  titleTranslationKey: 'dashboard.sections.titles.Communication',
  getLinks(allLinks: NavigationalLinks) {
    return [
      allLinks[LinkTarget.MESSAGES__CONTACT_US],
      allLinks[LinkTarget.MESSAGES__SERVICE_REQUESTS],
      allLinks[LinkTarget.MESSAGES__TICKETS],
      allLinks[LinkTarget.MESSAGES__MESSAGE_ARCHIVE],
      allLinks[LinkTarget.MESSAGES__SERVICE_REQUESTS__NEW],
      allLinks[LinkTarget.MESSAGES__TICKETS__NEW],
      allLinks[LinkTarget.MESSAGES__REQUEST_QUOTE],
      allLinks[LinkTarget.MESSAGES__CUSTOMER_SPECIFIC],
      allLinks[LinkTarget.MESSAGES__ACTIVITY_LOG]
    ];
  }
};
