import { SectionDefinition } from 'B2XApp/Dashboard/DashboardNavigation/DashboardSections/sections/sectionDefinition';
import { DashboardSectionId } from 'B2XApp/Dashboard/sections';
import { LinkTarget, NavigationalLinks } from 'doings/getNavigationalLinks/getNavigationalLinks';

export const invoicingSection: SectionDefinition = {
  id: DashboardSectionId.Invoicing,
  icon: 'invoice',
  titleTranslationKey: 'dashboard.sections.titles.Invoicing',
  getLinks(allLinks: NavigationalLinks) {
    return [
      allLinks[LinkTarget.BILLING__INVOICES],
      allLinks[LinkTarget.BILLING__BILLING_ACCOUNTS],
      allLinks[LinkTarget.BILLING__PAYMENT_REMINDERS],
      allLinks[LinkTarget.BILLING__BILLING_INFO_MASS_CHANGE]
    ];
  }
};
