import { ApolloError } from '@apollo/client';
import { refreshMedalliaScript } from 'doings/medallia/medalliaUtils';
import { CookieConsentLanguage, useCookieConsent } from 'hooks/useCookieConsent/useCookieConsent';
import { LoginStatusType } from 'hooks/useUser/useUser';
import { i18n } from 'i18next';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const usePortalLanguage = (status: LoginStatusType, user?: User, error?: ApolloError) => {
  const { i18n } = useTranslation();
  const { changeLanguage: changeCookieConsentLanguage } = useCookieConsent();
  const userLanguage = status === 'logged in' && user?.language;
  const errorLanguage =
    status === 'not logged in' && (error?.graphQLErrors[0]?.extensions?.language as Language);

  useEffect(() => {
    updateLanguageToIfPresent(i18n, changeCookieConsentLanguage, userLanguage);
    updateLanguageToIfPresent(i18n, changeCookieConsentLanguage, errorLanguage);
  }, [userLanguage, errorLanguage, i18n, changeCookieConsentLanguage]);
};

const updateLanguageToIfPresent = (
  i18n: i18n,
  changeCookieConsentLanguage: (lang: CookieConsentLanguage) => void,
  targetLanguage?: Language | false
) => {
  if (targetLanguage) {
    i18n.changeLanguage(targetLanguage);
    document.documentElement.lang = targetLanguage;
    refreshMedalliaScript();
    changeCookieConsentLanguage(targetLanguage);
  }
};

export default usePortalLanguage;
