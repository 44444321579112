import { InlineNotification } from 'common-components';

import { FullProductOfferingLink } from './links/FullProductOfferingLink';
import { OldPortalSubscriptionSearchLink } from './links/OldPortalSubscriptionSearchLink';

export const InternetConnectionsListServicesAndOfferingNotice: React.FC = () => {
  return (
    <InlineNotification
      data-testid="services-list-notice"
      variant="information"
      content={{
        key: 'internetConnections.list.servicesNotice.title',
        components: [
          <OldPortalSubscriptionSearchLink key="old-portal-subscritpion-search-link" />,
          <FullProductOfferingLink key="full-product-offering-link" />
        ]
      }}
    />
  );
};
