import { Paragraph } from '@teliafi/fi-ds';

import styles from './ListViewMobileTitleRow.module.scss';

const ListViewMobileTitleRow: React.FC<{ children: React.ReactNode | string }> = ({ children }) => (
  <Paragraph className={styles.titleRow} variant="paragraph-100-medium">
    {children}
  </Paragraph>
);

export default ListViewMobileTitleRow;
