import { BodyText, Flex, Gutter, Icon } from '@gaiads/telia-react-component-library';
import SubscriptionAssetSkeleton from 'B2XApp/Subscriptions/SubscriptionDetails/SubscriptionAsset/SubscriptionAssetSkeleton/SubscriptionAssetSkeleton';
import { ActionButtonGroup, Button, DataPanel, DefinitionList } from 'common-components';
import formatDatetime from 'doings/formatDatetime/formatDatetime';
import { useRouting } from 'hooks/useRouting/useRouting';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { AssetAddress, ChildAsset, InternetConnectionDetailsItem } from 'types/internetConnections';

export const formatAssetAddress = (address: AssetAddress, t: TFunction) =>
  [
    [address.streetAddress, address.streetAddressSpecifier].filter(Boolean).join(' '),
    address.floor && t('internetConnectionDetails.floor', address),
    address.postalAddress,
    address.country
  ]
    .filter(Boolean)
    .join(', ');

const AssetDataPanel: React.FC<{
  asset: InternetConnectionDetailsItem | ChildAsset | undefined;
  loading: boolean;
  datapanelTestid?: string;
  children?: React.ReactNode;
}> = ({ asset, loading, children, datapanelTestid }) => {
  const { t } = useTranslation();
  const { goto } = useRouting();
  const assetAddress = asset && formatAssetAddress(asset.address, t);

  const fields = asset
    ? [
        { label: t('internetConnectionDetails.status'), value: asset.status },
        {
          label: t('internetConnectionDetails.productId'),
          value: asset.displayId
        },
        {
          label: t('common.company.label'),
          value: `${asset.companyCode ?? ''} ${asset.companyName ?? ''}`.trim()
        },
        {
          label: t('internetConnectionDetails.activationDate'),
          value: formatDatetime(asset.activationDate)
        },
        {
          label: t('internetConnectionDetails.contractStartDate'),
          value: formatDatetime(asset.activationDate)
        },
        {
          label: t('internetConnectionDetails.contractEndDate'),
          value: formatDatetime(asset.contractEndDate)
        },
        {
          label: t('internetConnectionDetails.terminationDate'),
          value: 'terminationDate' in asset && formatDatetime(asset.terminationDate)
        },
        {
          label: t('internetConnectionDetails.billingId'),
          value: 'order' in asset && asset.order?.groupBillingId
        },
        {
          label: t('internetConnectionDetails.projectId'),
          value: 'order' in asset && asset.order?.projectId
        },
        {
          label: t('internetConnectionDetails.customerReferenceNumber'),
          value: 'order' in asset && asset.order?.customerReferenceNumber
        },
        ...asset.attributes
      ].filter((field) => field.value)
    : [];

  return (
    <DataPanel
      skeletonLoader={<SubscriptionAssetSkeleton />}
      loading={loading}
      title={asset?.name}
      subtitle={assetAddress}
      data-testid={datapanelTestid}
      mainPanel
    >
      <DefinitionList fiftyFifty addDividers>
        {fields.map((field) => (
          <DefinitionList.Item heading={field.label} key={field.label}>
            {field.value === 'true' ? (
              <Flex centeredVertically>
                <Icon name="checkmark" size="sm" />
              </Flex>
            ) : (
              <BodyText>{field.value}</BodyText>
            )}
          </DefinitionList.Item>
        ))}
      </DefinitionList>

      {asset?.isDNSAsset && (
        <>
          <Gutter size="sm" />

          <ActionButtonGroup>
            <Button
              data-testid="manage-domain-service"
              type="button"
              variant="primary"
              onClick={() =>
                goto({
                  toCorporatePortal: 'domainManagement',
                  toCorporatePortalArgs: { zoneName: asset.domainName },
                  useB2XLink: false,
                  to: ''
                })
              }
            >
              {t('internetConnectionDetails.manageService')}
            </Button>
          </ActionButtonGroup>
        </>
      )}
      {children}
    </DataPanel>
  );
};

export default AssetDataPanel;
