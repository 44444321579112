import { useMemo } from 'react';
import { SubscriptionListItem } from 'types/subscription';

import SubscriptionStatusTag, {
  DisplayableSubscriptionStatus as Status
} from '../SubscriptionStatusTag/SubscriptionStatusTag';
import styles from './SubscriptionStatusTags.module.scss';

const SubscriptionStatusTags: React.FC<{ subscription: SubscriptionListItem }> = ({
  subscription
}) => {
  const { status, isInTransfer, hasFixedTermContract, hasDuettoBundle } = subscription;
  const statuses: Status[] = useMemo(
    () => [
      ...(!(status === 'ENABLED' && isInTransfer) ? [status] : []),
      ...(hasFixedTermContract || hasDuettoBundle ? ['FIXED_TERM' as Status] : []),
      ...(isInTransfer ? ['IN_TRANSFER' as Status] : [])
    ],
    [status, isInTransfer, hasFixedTermContract, hasDuettoBundle]
  );

  return (
    <div className={styles.statuses}>
      {statuses.map((status) => (
        <SubscriptionStatusTag key={status} status={status} />
      ))}
    </div>
  );
};

export default SubscriptionStatusTags;
