import { MaybeMasked, MutationOptions, useMutation } from '@apollo/client';
import { useCSRFTokenContext } from 'contexts/CSRFToken/CSRFToken';
import isLoginError from 'doings/isLoginError/isLoginError';
import { DocumentNode } from 'graphql';
import useAccessControlErrorHandler from 'hooks/useAccessControlErrorHandler/useAccessControlErrorHandler';

type RefetchQueries = MutationOptions['refetchQueries'];

export default <T>(
  mutation: DocumentNode,
  refetchQueries?: RefetchQueries,
  { onCompleted }: { onCompleted?: (data: MaybeMasked<NoInfer<T>>) => void } = {}
) => {
  const accessControlErrorHandler = useAccessControlErrorHandler();
  const { csrfToken } = useCSRFTokenContext();

  const [runMutation, { data, error, loading }] = useMutation<T>(mutation, {
    context: {
      headers: {
        'X-CSRF-TOKEN': csrfToken
      }
    },
    refetchQueries,
    awaitRefetchQueries: true,
    onError: accessControlErrorHandler,
    onCompleted
  });

  return {
    runMutation,
    data: data !== null ? data : undefined,
    error,
    loading: loading || isLoginError(error)
  };
};
