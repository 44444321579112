import {
  addOrUpdateFeatureFlagMutation,
  featureFlagsQuery
} from 'hooks/featureFlags/featureFlagQueries';
import useCallBackendMutation from 'hooks/useCallBackend/useCallBackendMutation';

type AddFeatureFlagResponse = {
  addOrUpdateFeatureFlag: boolean;
};

type AddOrUpdateFeatureFlag = {
  id?: number;
  name: string;
  description: string;
  active: boolean;
  activeStatusOverride: string;
};

const useAddOrUpdateFeatureFlag = () => {
  const { runMutation, data, loading, error } = useCallBackendMutation<AddFeatureFlagResponse>(
    addOrUpdateFeatureFlagMutation,
    [
      {
        query: featureFlagsQuery
      }
    ]
  );

  const addOrUpdateFeatureFlag = async (flagData: AddOrUpdateFeatureFlag) => {
    runMutation({
      variables: {
        flagData
      }
    });
  };

  return {
    addOrUpdateFeatureFlag,
    data,
    loading,
    error
  };
};

export default useAddOrUpdateFeatureFlag;
