import { ServiceRequestOrigin } from 'B2XApp/Messages/ServiceRequests/NewServiceRequest/useServiceRequestOrigin';
import { useUserData } from 'contexts/UserContext/UserContext';
import { EN_DASH } from 'doings/dash/dash';
import useAsset from 'hooks/assets/useAsset/useAsset';
import useCorporateProductData from 'hooks/corporateProductData/useCorporateProductData/useCorporateProductData';
import useInvoice from 'hooks/invoicing/invoices/useInvoice/useInvoice';
import useSubscriptionDetails from 'hooks/mobileSubscriptions/useSubscriptionDetails/useSubscriptionDetails';
import useOrder from 'hooks/orders/useOrder/useOrder';
import { CreateServiceRequestData } from 'hooks/serviceRequests/useCreateServiceRequest/useCreateServiceRequest';
import useWriteQueryParams from 'hooks/useQueryParams/useWriteQueryParams';
import { cloneDeep } from 'lodash/fp';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type BasicRelatedRecord = {
  type: 'corporate-product' | 'invoice' | 'internet-connection' | 'subscription' | 'order';
  relatedData: string[];
  loading: boolean;
  hasError: boolean;
  errorFallback?: JSX.Element;
  returnLinkPath: string;
  returnLinkLabel: string;
  groupId: string;
  groupType: ActiveGroupType;
};

export type CorporateProductRecord = {
  type: 'corporate-product';
  action: 'TERMINATE' | 'MODIFY';
  productId: string;
  productName: string;
  subscriptionNumber: string;
  overrideRequestParams: (request: CreateServiceRequestData) => CreateServiceRequestData;
} & BasicRelatedRecord;

export type InternetConnectionRecord = {
  type: 'internet-connection';
  productId: string;
  subscriptionNumber: string;
  assetType: string;
} & BasicRelatedRecord;

type SubscriptionRecord = {
  type: 'subscription';
  productId: string;
  subscriptionNumber: string;
} & BasicRelatedRecord;

type InvoiceRecord = {
  type: 'invoice';
  invoiceNumber: string;
} & BasicRelatedRecord;

export type OrderRecord = {
  id: string;
  type: 'order';
  orderNumber: string;
} & BasicRelatedRecord;

export type RelatedRecord =
  | CorporateProductRecord
  | InternetConnectionRecord
  | SubscriptionRecord
  | InvoiceRecord
  | OrderRecord;

export default (origin: ServiceRequestOrigin): RelatedRecord | undefined => {
  switch (origin.type) {
    case 'corporate-product':
      return useCorporateProductRecord(origin.key);
    case 'invoice':
      return useInvoicesRecord(origin.key);
    case 'internet-connection':
      return useAssetRecord(origin.key);
    case 'order-inquiry':
      return useOrderRecord(origin.key);
    case 'subscription':
      return useMobileSubscriptionsRecord(origin.key);
    default:
      return undefined;
  }
};

const useCorporateProductRecord = (productDeploymentId: string): CorporateProductRecord => {
  const { data, loading, error } = useCorporateProductData(productDeploymentId);
  const { t } = useTranslation();
  const { allCompanies, activeGroupType } = useUserData();
  const company = data
    ? allCompanies.find((company) => company.companyCode === data.companyCode)
    : undefined;

  const productName = data?.productName;
  const preselections = data?.preselections;
  const { write: writeQueryParams } = useWriteQueryParams();
  useEffect(() => {
    if (preselections) {
      writeQueryParams({ t: `${preselections.type}!`, d: preselections.subtype });
    }
  }, [writeQueryParams, preselections]);

  const overrideRequestParams = useCallback(
    (request: CreateServiceRequestData) => {
      if (!preselections) {
        return request;
      }

      const requestCopy = cloneDeep(request);
      requestCopy.productCategory = preselections.srProduct;
      requestCopy.metainfo.claudia.category = preselections.srCategory;
      requestCopy.metainfo.claudia.subcategory = preselections.srSubcategory;
      requestCopy.description = `${request.description}\n\n${t(
        'serviceRequests.newServiceRequest.corporateProductName'
      )}: ${productName || EN_DASH}`;
      return requestCopy;
    },
    [t, preselections, productName]
  );

  return {
    type: 'corporate-product',
    action: data?.action ?? 'MODIFY',
    subscriptionNumber: data?.subscriptionNumber ?? '',
    productId: productDeploymentId,
    productName: data?.productName ?? '',
    groupId: company?.groupId ?? '',
    groupType: company?.companyGroupType ?? activeGroupType,
    relatedData: data ? [data.productName, data.subscriptionNumber] : [],
    overrideRequestParams,
    loading,
    hasError: !!error,
    returnLinkPath: data?.origin ?? '',
    returnLinkLabel: t('serviceRequests.newServiceRequest.backToAsset')
  };
};

const useAssetRecord = (assetId: string): InternetConnectionRecord => {
  const { asset, loading, hasError } = useAsset(assetId);
  const { t } = useTranslation();
  const { allCompanies, activeGroupType } = useUserData();
  const company = allCompanies.find((company) => company.companyCode === asset?.companyCode);

  return {
    type: 'internet-connection',
    productId: asset?.productId || '',
    subscriptionNumber: asset?.displayId || '',
    groupId: company?.groupId || '',
    groupType: company?.companyGroupType || activeGroupType,
    relatedData: asset ? [asset.name, asset.displayId] : [],
    assetType: asset?.assetType || '',
    loading,
    hasError,
    returnLinkPath: `/internet-connections/${asset?.id}`,
    returnLinkLabel: t('serviceRequests.newServiceRequest.backToAsset')
  };
};

const useMobileSubscriptionsRecord = (subscriptionId: string): SubscriptionRecord => {
  const { subscription, status } = useSubscriptionDetails(subscriptionId);
  const { t } = useTranslation();
  const { allCompanies, activeGroupType } = useUserData();
  const company = allCompanies.find(
    (company) => company.companyCode === subscription?.owner?.companyCode
  );

  return {
    type: 'subscription',
    subscriptionNumber: subscription?.phoneNumber || '',
    productId: subscription?.productId || '',
    groupId: company?.groupId || '',
    groupType: company?.companyGroupType || activeGroupType,
    relatedData: subscription
      ? [subscription.type, subscription.userName, subscription.phoneNumber]
      : [],
    loading: status === 'loading',
    hasError: status === 'error',
    returnLinkPath: `/subscriptions/${subscription?.phoneNumberHash}`,
    returnLinkLabel: t('serviceRequests.newServiceRequest.backToAsset')
  };
};

const useInvoicesRecord = (id: string): InvoiceRecord => {
  const { invoice, loading, hasError } = useInvoice({ key: id });
  const { t } = useTranslation();
  const { allCompanies } = useUserData();
  const company = allCompanies.find((company) => company.companyCode === invoice?.customer?.code);

  return {
    type: 'invoice',
    invoiceNumber: invoice?.id || '',
    groupId: company?.groupId || '',
    groupType: company?.companyGroupType || 'b2b',
    relatedData: invoice ? [t(`invoices.types.${invoice.types[0]}`), invoice?.id] : [],
    loading: loading,
    hasError: hasError,
    returnLinkPath: `/invoices/${invoice?.key}`,
    returnLinkLabel: t('invoice.paymentPlan.backToInvoice')
  };
};

const useOrderRecord = (id: string): OrderRecord => {
  const { order, status } = useOrder(id);
  const { t } = useTranslation();
  const { allCompanies, activeGroupType } = useUserData();
  const company = allCompanies.find((company) => company.companyCode === order?.companyCode);

  return {
    id: order?.id || '',
    type: 'order',
    orderNumber: order?.displayId || '',
    groupId: company?.groupId || '',
    groupType: company?.companyGroupType || activeGroupType,
    relatedData: order
      ? [
          `Order #${order.displayId}`,
          ...order.lineItems
            .filter((item) => item.productName)
            .map((item) => '• ' + item.productName)
        ]
      : [],
    loading: status === 'loading',
    hasError: status === 'error',
    returnLinkPath: `/orders`,
    returnLinkLabel: t('serviceRequests.newServiceRequest.backToOrders')
  };
};
