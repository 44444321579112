import { Gutter } from '@gaiads/telia-react-component-library';
import { Icon } from '@teliafi/fi-ds';
import getClassNames from 'classnames';
import { Button } from 'common-components/Button/Button';
import { Flex } from 'core-components';
import useIsSmallerBreakpointActive from 'hooks/useIsSmallerBreakpointActive/useIsSmallerBreakpointActive';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import styles from './BackToTopButton.module.scss';

const scrollToTop = () => {
  const topNavigationElement = document.getElementById('top-navigation');
  if (topNavigationElement) {
    topNavigationElement.scrollIntoView({ behavior: 'smooth' });
  } else {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
};

const SHOW_BUTTON_AT_PIXELS = 960;

const BackToTopButton: React.FC = () => {
  const [showBackToTop, setShowBackToTop] = useState(false);

  const checkScrollPointAndButtonVisibility = () => {
    const documentScrollHeight = document.documentElement.scrollHeight;
    const scrollableHeight = documentScrollHeight - window.innerHeight;
    const showButtonAt = Math.max(window.innerHeight, SHOW_BUTTON_AT_PIXELS);

    setShowBackToTop(showButtonAt <= scrollableHeight && window.scrollY >= showButtonAt);
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollPointAndButtonVisibility);
    window.addEventListener('resize', checkScrollPointAndButtonVisibility);

    return () => {
      window.removeEventListener('scroll', checkScrollPointAndButtonVisibility);
      window.removeEventListener('resize', checkScrollPointAndButtonVisibility);
    };
  }, []);

  return ReactDOM.createPortal(
    <ResponsiveBackToTopButton showBackToTop={showBackToTop} />,
    document.body
  );
};

const ResponsiveBackToTopButton: React.FC<{ showBackToTop: boolean }> = ({ showBackToTop }) => {
  const { t } = useTranslation();
  const isMobileLayout = useIsSmallerBreakpointActive('md');

  return (
    <Button
      data-testid="back-to-top-button"
      aria-label={t('common.backToTopButton.label')}
      className={getClassNames(styles.backToTop, { [styles.backToTop_hidden]: !showBackToTop })}
      size="sm"
      iconOnly={isMobileLayout}
      onClick={scrollToTop}
    >
      <Flex centeredVertically>
        <Icon name="arrow-up" size="md" />

        {!isMobileLayout && <Gutter size="xs" />}

        {!isMobileLayout && t('common.backToTopButton.label')}
      </Flex>
    </Button>
  );
};

export default BackToTopButton;
