import useAsset from 'hooks/assets/useAsset/useAsset';
import { useParams } from 'react-router-dom';

import ChildAssetDetailsPage from './ChildAssetDetailsPage';

const ChildAssetDetails: React.FC = () => {
  const { assetId, childAssetId } = useParams<{
    assetId: string;
    childAssetId: string;
  }>();
  const { asset, loading, error } = useAsset(assetId);

  const childAsset = asset?.childAssets.find((childAsset) => childAsset.id === childAssetId);
  const childAssets = asset?.childAssets ?? [];
  const grandChildAssets = childAssets.filter((childAsset) => childAsset.parentId === childAssetId);

  return (
    <ChildAssetDetailsPage
      rootAssetId={assetId}
      rootAssetsChildAssets={childAssets}
      childAssetId={childAssetId}
      childAsset={childAsset}
      grandChildAssets={grandChildAssets}
      loading={loading}
      error={error}
      data-testid="child-asset-details"
    />
  );
};

export default ChildAssetDetails;
