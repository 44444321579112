import { BodyText, SupportedBodyTextSize } from '@gaiads/telia-react-component-library';
import getClassNames from 'classnames';

import styles from './TextWithInlineHtml.module.scss';

const UNSUPPORTED_HTML_TAG_PATTERN =
  /(<(?!(\/?(a|br|p|ol|ul|li|i|b|strong|em|u|strike)))[^>]*?>)/gi;
const TOKEN_PATTERN = /(<.[^>]*?>[^<]*?<\/.[^>]*?>|[^<]+)/gi;

const replaceNewlines = (text: string) => {
  if (!text || !/\n/.test(text)) {
    return text;
  }

  return text.replace(/\n/g, '<br>');
};

/**
 * Renders text with a single level of HTML mark-up, keeping the following tags
 * while stripping away all other HTML tags: `a`, `br`, `p`, `ol`, `ul`, `li`,
 * `i`, `b` and `strong`.
 *
 * Without providing explicit support, simply rendering text with HTML mark-up
 * would escape special HTML characters and render mark-up as text, which is
 * undesireable for texts intended to potentially store inline links or lists.
 *
 * @param text Text with potential inline HTML mark-up.
 * @param size A supported body text size.
 */
const TextWithInlineHtml: React.FC<{
  text: string;
  size?: SupportedBodyTextSize;
  className?: string;
}> = ({ text, size, className }) => {
  let sanitizedText = text.replace(UNSUPPORTED_HTML_TAG_PATTERN, '').trim();
  sanitizedText = replaceNewlines(sanitizedText);
  if (sanitizedText.match(TOKEN_PATTERN)) {
    return (
      <BodyText
        className={getClassNames([styles.inlineHtmlContainer, className])}
        size={size}
        tagName="div"
        dangerouslySetInnerHTML={{ __html: sanitizedText }}
        data-testid="text-with-inline-html"
      />
    );
  }

  return (
    <BodyText className={className} size={size} tagName="div" data-testid="text-with-inline-html">
      {sanitizedText}
    </BodyText>
  );
};

export default TextWithInlineHtml;
