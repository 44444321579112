import { statusMap as orderLineItemStatusMap } from 'B2XApp/Orders/OrderStatus/OrderLineItemStatusTag';
import { statusMap as orderStatusMap } from 'B2XApp/Orders/OrderStatus/OrderStatusTag';
import { TeliaLink } from 'common-components';
import isDemoEnvironment from 'doings/isDemoEnvironment/isDemoEnvironment';
import { joinURLSearchParams } from 'doings/links/joinURLSearchParams/joinURLSearchParams';
import { prepareQueryParameters } from 'hooks/orders/useOrders/useOrders';
import { OrderQueryParameters } from 'hooks/orders/useOrders/useOrdersFilters';
import { useTranslation } from 'react-i18next';
import { OrderLineItemStatus, OrderStatus } from 'types/orders';

export type OrderExcelDownloadLinkProps = {
  queryParameters: OrderQueryParameters;
  showFromAllCompanies: boolean;
  disabled: boolean;
};

export const ExcelDownloadLink: React.FC<OrderExcelDownloadLinkProps> = ({
  queryParameters,
  showFromAllCompanies,
  disabled
}) => {
  const { t } = useTranslation();
  const isDemoEnv = isDemoEnvironment();
  const urlSearchParams = new URLSearchParams(
    prepareQueryParameters(queryParameters, showFromAllCompanies)
  );

  const statusMap = { ...orderStatusMap, ...orderLineItemStatusMap };
  const statusSet = new Set([...Object.values(OrderLineItemStatus), ...Object.values(OrderStatus)]);
  const statuses = Array.from(statusSet)
    .map((status) => `${status}:${t(statusMap[status].i18nKey)}`)
    .join(',');

  const queryParams = joinURLSearchParams('?', {
    columns: [
      t('orders.list.columns.createdDate'),
      t('orders.list.columns.orderName'),
      t('orders.list.columns.deliveryDate'),
      t('orders.list.columns.additionalInfo'),
      t('orders.list.columns.status'),
      t('orders.list.columns.orderId'),
      t('common.companyCode.label'),
      t('common.company.label')
    ].join(','),
    filter: urlSearchParams.toString(),
    showFromAllCompanies,
    sheetTitle: t('orders.title'),
    orderMultipleAddresses: t('orders.list.multipleAddresses'),
    orderMultipleServices: t('orders.list.multipleServices'),
    orderStatuses: statuses,
    ecommerceOrder: t('orders.list.ecommerceOrder')
  });

  return (
    <TeliaLink.Download
      label={t('common.excelDownload.label')}
      to={`/api/orders/listAsExcel${queryParams}`}
      iconLeft="download"
      data-testid="orders-list-as-excel-link"
      disabled={isDemoEnv || disabled}
      downloadWithFetch
      useFileNameFromResponse
    />
  );
};
