import { Div, OptionListOption } from '@gaiads/telia-react-component-library';
import ModelDropdown from 'common-components/ModelDropdown/ModelDropdown';
import { useUserData } from 'contexts/UserContext/UserContext';
import openUrl from 'doings/openUrl/openUrl';
import { common } from 'doings/track/analyticsEvents';
import trackEvent from 'doings/track/trackEvent';
import userCompanyHasPermission from 'doings/userCompanyHasPermission/userCompanyHasPermission';
import useDropdownInputModel from 'hooks/inputModels/useDropdownInputModel/useDropdownInputModel';
import useCurrentRoute from 'hooks/useCurrentRoute/useCurrentRoute';
import { isEqual } from 'lodash/fp';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ActiveCompanySelector.module.scss';

type ActiveCompanySelectorProps = {
  lit?: boolean;
};

const changeActiveCompany = (activeGroupId: string, selectedGroupId: string) => {
  if (activeGroupId !== selectedGroupId) {
    const path = window.location.pathname;
    const search = new URLSearchParams(window.location.search);
    search.set('selectedCompanyId', selectedGroupId);
    trackEvent(common.changeActiveCompany);
    openUrl(`${path}?${search.toString()}`);
  }
};

/**
 * A dropdown that enables multi-company hierarchy users to select their active
 * company on the go. If the currently logged-in user is a single-company user,
 * the dropdown will not be rendered.
 *
 * Uses memoisation to only render the active company selector once on page
 * rerenders for the logged-in user as long as the input properties are intact,
 * since the dropdown options likewise remain intact.
 *
 * Ensures that the component is rerendered when the active group ID changes,
 * which can only happen in Storybook entries, where the mocked logged-in user
 * can be changed on the fly. Note that the described behaviour is only apparent
 * in the React Storybook and does not affect a running application.
 */
const ActiveCompanySelector: React.FC<ActiveCompanySelectorProps> = React.memo(
  (props) => {
    const { activeGroupId, allCompanies } = useUserData();
    if (allCompanies.length <= 1) {
      return null;
    }

    return <ActiveCompanySelectorInner {...props} key={`${activeGroupId}`} />;
  },

  (prevProps, nextProps) => isEqual(prevProps, nextProps)
);

const ActiveCompanySelectorInner: React.FC<ActiveCompanySelectorProps> = ({ lit }) => {
  const { t } = useTranslation();
  const { activeGroupId, allCompanies } = useUserData();
  const limitToCompaniesWith = useCurrentRoute()?.accessibleWhen;

  const options: OptionListOption[] = allCompanies
    .filter((c) => userCompanyHasPermission(c, limitToCompaniesWith))
    .map((c) => ({
      label: [c.companyName, c.companyCode].filter(Boolean).join(' – '),
      value: c.groupId
    }));

  const disabled = options.length <= 1;
  const initialValue = options.some((o) => o.value === activeGroupId) ? activeGroupId : '';

  const inputModel = useDropdownInputModel({
    options,
    initialValue,
    excludeNoneSelected: true,
    onChange: (value) => changeActiveCompany(activeGroupId ?? '', value),
    disabled
  });

  // User has no permissions to access current route with active company.
  // Since we're showing the access denied page, don't render the active
  // company selector on that page if we're dealing with a page with an
  // active company selector.
  if (initialValue === '') {
    return null;
  }

  return (
    <Div className={{ [styles.dropdown__lit]: lit }}>
      <ModelDropdown
        data-testid="active-company-selector"
        label={t('common.activeCompany.label')}
        inputModel={inputModel}
        disabled={disabled}
      />
    </Div>
  );
};

export default ActiveCompanySelector;
