import SubscriptionsLink from 'B2XApp/Subscriptions/SubscriptionsLink/SubscriptionsLink';
import { DataPanel, InlineNotification } from 'common-components';
import CompactList from 'common-components/CompactList/CompactList';
import { ViewStatus } from 'hooks/useApiStatus/useApiStatus';
import { useTranslation } from 'react-i18next';
import { SubscriptionListItem } from 'types/subscription';

import BillingGroupDetailsSubscriptionListSkeleton from './BillingGroupDetailsSubscriptionListSkeleton';

const MAX_SUBSCRIPTIONS_TO_DISPLAY = 10;

const BillingGroupDetailsSubscriptionList: React.FC<{
  collectionGroup?: string;
  subscriptions: SubscriptionListItem[];
  totalSubscriptions: number;
  status: ViewStatus;
  limit?: number;
}> = ({
  collectionGroup,
  subscriptions,
  totalSubscriptions,
  status,
  limit = MAX_SUBSCRIPTIONS_TO_DISPLAY
}) => {
  const { t } = useTranslation();

  const tooManySubscriptions = totalSubscriptions > limit;
  const isEmpty = totalSubscriptions <= 0;
  const isLoading = [
    ViewStatus.Initial,
    ViewStatus.LoadingInitial,
    ViewStatus.Loading,
    ViewStatus.LoadingMore
  ].includes(status);

  const showList = (!isEmpty && !tooManySubscriptions) || isLoading;
  const isError = status === ViewStatus.Error;

  if (showList) {
    return (
      <DataPanel
        padding={{ size: isError ? 'lg' : 'md' }}
        skeletonLoader={<BillingGroupDetailsSubscriptionListSkeleton />}
        hasError={isError}
        loading={isLoading}
        title={t('billingInformation.relatedSubscriptions')}
      >
        <CompactList
          data-testid="billing-group-details-subscriptions-list"
          headings={[
            { heading: t('subscriptions.user'), testId: 'billing-group-subscription-user' },
            { heading: t('common.phone.label'), testId: 'billing-group-phone-number' },
            { heading: t('subscriptions.type'), testId: 'billing-group-subscription-type' }
          ]}
          fullWidthDivider
        >
          {subscriptions.map(({ type, phoneNumber, phoneNumberHash, user }) => (
            <CompactList.Row
              key={`subscription-${phoneNumber}`}
              linkTo={`/subscriptions/${phoneNumberHash}`}
              data-testid={`billing-group-details-subscription-list-item-${phoneNumber}`}
            >
              <CompactList.Item title={t('subscriptions.user')} text={user} />

              <CompactList.Item title={t('common.phone.label')} text={phoneNumber} />

              <CompactList.Item title={t('subscriptions.type')} text={type} />
            </CompactList.Row>
          ))}
        </CompactList>
      </DataPanel>
    );
  }

  if (tooManySubscriptions) {
    return (
      <InlineNotification
        content={{
          key: 'invoices.billingGroup.details.tooManySubscriptionsInfoTitle',
          components: [
            <SubscriptionsLink
              key="billing-group-details-notification__subscriptions-link"
              data-testid="billing-group-details-notification__subscriptions-link"
              text={t('invoices.billingGroup.details.tooManySubscriptionsInfoDescription')}
              collectionGroup={collectionGroup ?? ''}
            />
          ]
        }}
        variant="information"
        data-testid="billing-group-details-notification__too-many-subscriptions"
      ></InlineNotification>
    );
  }

  return (
    <InlineNotification
      content={t('invoices.billingGroup.details.noSubscriptionsTitle')}
      variant="information"
      data-testid="billing-group-details-notification__no-subscriptions"
    />
  );
};

export default BillingGroupDetailsSubscriptionList;
