import { BodyText, Div, Gutter } from '@gaiads/telia-react-component-library';
import LoadingErrorImage from 'common-components/DataPanel/DetailsLoadingErrorPanel/LoadingErrorImage/LoadingErrorImage';
import { Flex } from 'core-components';
import { useTranslation } from 'react-i18next';

export const BalanceUnavailablePanel: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Flex centered directionColumn occupyVerticalSpace>
      <Div block>
        <LoadingErrorImage />
      </Div>

      <Gutter />

      <BodyText
        data-testid="balance-unavailable-title"
        fontWeight="medium"
        block
        margin={{ bottom: 'xxs' }}
      >
        {t('subscriptions.usage.preopenedTitle')}
      </BodyText>

      <BodyText data-testid="balance-unavailable-description" block alignTextToCenter>
        {t('subscriptions.usage.preopenedDescription')}
      </BodyText>
    </Flex>
  );
};
