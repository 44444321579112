import useAssets from 'hooks/assets/useAssets/useAssets';
import useAssetsFilters from 'hooks/assets/useAssets/useAssetsFilters';
import useInitialLoadStatus from 'hooks/useInitialLoadStatus/useInitialLoadStatus';
import usePersistedSortableHeadings from 'hooks/useSortableHeadings/usePersistedSortableHeadings';
import useUserHasMultipleCompaniesWithGivenPermission from 'hooks/useUser/useUserHasMultipleCompaniesWithGivenPermission';
import { SortableColumns } from 'types/internetConnections';
import { SortOrder } from 'types/sort';

import InternetConnectionsListPage from './InternetConnectionsListPage';

const InternetConnectionsList: React.FC = () => {
  const multipleCompanySearchEnabled = useUserHasMultipleCompaniesWithGivenPermission({
    anyPermission: ['ASSETS', 'ASSETS_VIEW']
  });

  const sortableColumns = Object.values(SortableColumns);
  const { sortState, sortClick, onSortChange, currentSort } = usePersistedSortableHeadings(
    sortableColumns,
    {
      sort: SortableColumns.name,
      order: SortOrder.ASC
    }
  );

  const assetsFilters = useAssetsFilters(multipleCompanySearchEnabled);
  const { assets, status, error } = useAssets(
    assetsFilters.queryParameters,
    currentSort,
    !assetsFilters.isValid,
    assetsFilters.allCompanySearch.showFromAllCompanies
  );

  const apiState = useInitialLoadStatus(status);

  return (
    <InternetConnectionsListPage
      multipleCompanySearchEnabled={multipleCompanySearchEnabled}
      assetFilters={assetsFilters}
      assets={assets}
      status={apiState}
      error={error}
      sortState={sortState}
      sortClick={sortClick}
      onSortChange={onSortChange}
      currentSort={currentSort}
    />
  );
};

export default InternetConnectionsList;
