import ErrorPage from 'B2XApp/Errors/Error/ErrorPage/ErrorPage';
import GenerateOwnershipChangeTokenPage from 'B2XApp/Subscriptions/GenerateOwnershipChangeToken/GenerateOwnershipChangeTokenPage';
import { EN_DASH } from 'doings/dash/dash';
import formatDatetime, { DateFormats } from 'doings/formatDatetime/formatDatetime';
import useCreateOwnershipChangeToken from 'hooks/mobileSubscriptions/useOwnershipChange/useCreateOwnershipChangeToken';
import useGenerateOwnershipChangeTokenSteps, {
  GenerateChangeTokenStep
} from 'hooks/mobileSubscriptions/useOwnershipChange/useGenerateOwnershipChangeTokenSteps';
import useSubscriptionDetails from 'hooks/mobileSubscriptions/useSubscriptionDetails/useSubscriptionDetails';
import { useState } from 'react';
import { useParams } from 'react-router';

import OwnershipChangeSkeleton from './OwnershipChangeSkeleton';
import OwnershipChangeTokenForm from './OwnershipChangeTokenForm';
import OwnershipChangeTokenMessage from './OwnershipChangeTokenMessage';

export type OmsuTokenFormData = {
  additionalNumber1?: string;
  additionalNumber2?: string;
  terminationDate?: string;
};

export type TokenMessageStep = Extract<
  GenerateChangeTokenStep,
  'token-already-exists' | 'token-generation-unavailable' | 'token-generated'
>;

const GenerateOwnershipChangeToken: React.FC = () => {
  const { phoneNumberHash } = useParams<{ phoneNumberHash: string }>();
  const {
    subscription: {
      id: subscriptionId,
      userName: subscriptionUsername,
      status: subscriptionStatus,
      omsuToken,
      omsuBlockingServices,
      type: subscriptionType,
      hasDuettoBundle
    } = {
      id: '',
      userName: ''
    },
    status: subscriptionLoadingStatus,
    error: subscriptionError
  } = useSubscriptionDetails(phoneNumberHash, { fetchOmsu: true });

  const [tokenForm, setTokenForm] = useState<OmsuTokenFormData>({});

  const { createOwnershipChangeToken, data, loading, error } =
    useCreateOwnershipChangeToken(subscriptionId);

  const token = data?.createOwnershipChangeToken;
  const hasExistingToken = error?.graphQLErrors[0]?.extensions?.code === 'TOKEN_EXISTS';

  const { step } = useGenerateOwnershipChangeTokenSteps({
    loadingSubscription: subscriptionLoadingStatus === 'loading',
    subscriptionStatus: subscriptionStatus,
    hasErrorLoadingSubscription: !!subscriptionError || subscriptionLoadingStatus === 'error',
    hasExistingToken: !!omsuToken,
    generatingToken: loading,
    hasErrorGeneratingToken: !!error,
    generated: !!token,
    hasDuettoBundle
  });

  switch (step) {
    case 'loading-subscription-details':
    case 'generating-token':
      return (
        <GenerateOwnershipChangeTokenPage
          backLinkVisible={step === 'loading-subscription-details'}
          loading={loading}
        >
          <OwnershipChangeSkeleton />
        </GenerateOwnershipChangeTokenPage>
      );

    case 'token-already-exists':
    case 'token-generation-unavailable':
    case 'token-generated':
      return (
        <GenerateOwnershipChangeTokenPage loading={loading}>
          <OwnershipChangeTokenMessage
            phoneNumberHash={phoneNumberHash}
            step={step}
            token={token}
            tokenExpiryDate={formatDatetime(omsuToken?.expiryDate, DateFormats.DATE_NATIONAL_SHORT)}
            tokenTerminationDate={formatDatetime(
              omsuToken?.terminationDate,
              DateFormats.DATE_NATIONAL_SHORT
            )}
            subscriptionId={subscriptionId}
            subscriptionName={subscriptionUsername}
            subscriptionType={subscriptionType}
          />
        </GenerateOwnershipChangeTokenPage>
      );

    case 'ready-to-generate-token':
    case 'error-generating-token':
      return (
        <GenerateOwnershipChangeTokenPage
          backLinkVisible={step === 'ready-to-generate-token'}
          loading={loading}
        >
          <OwnershipChangeTokenForm
            subscriptionId={subscriptionId}
            subscriptionName={subscriptionUsername}
            subscriptionType={subscriptionType || EN_DASH}
            phoneNumberHash={phoneNumberHash}
            hasBlockingServices={omsuBlockingServices || false}
            generateChangeToken={createOwnershipChangeToken}
            tokenForm={tokenForm}
            setTokenForm={setTokenForm}
            hasError={step === 'error-generating-token'}
            hasExistingToken={hasExistingToken}
          />
        </GenerateOwnershipChangeTokenPage>
      );

    case 'error-loading-subscription':
      return <ErrorPage error={subscriptionError} />;

    default:
      return <ErrorPage />;
  }
};

export default GenerateOwnershipChangeToken;
