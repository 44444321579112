import { OptionListOption } from '@gaiads/telia-react-component-library/build/types/components/OptionList/OptionList';
import isValidDropdown from 'doings/isValidDropdown/isValidDropdown';
import { TextInputModel } from 'hooks/inputModels/useTextInputModel/useTextInputModel';
import useValidatableTextInputModel from 'hooks/inputModels/useValidatableTextInputModel/useValidatableTextInputModel';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownField } from 'types/form';

export type DropdownInputModel = TextInputModel & {
  options: OptionListOption[];
  valueLabel: string;
};

export type ValidationCallbackFunction = (isValid: boolean) => void;
export type OnValidationStatusChanged = (fieldName: string, isValid: boolean) => void;

export type UseDropdownInputModelParams = {
  options: OptionListOption[];
  initialValue?: string;
  required?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  /** If true, will omit option "None selected" and show placeholder instead. */
  excludeNoneSelected?: boolean;
  onChange?: (value: string) => void;
  onValidationStatusChanged?: ValidationCallbackFunction;
};

export default ({
  options: optionsAvailable,
  initialValue = '',
  required = false,
  disabled = false,
  hidden = false,
  onChange,
  onValidationStatusChanged,
  excludeNoneSelected = false
}: UseDropdownInputModelParams): DropdownInputModel => {
  const { t } = useTranslation();
  const inputModel = useValidatableTextInputModel({
    initialValue,
    required,
    disabled,
    onChange: (value: string) => {
      if (onChange && (!required || isValidDropdown({ value, options } as DropdownField))) {
        onChange(value);
      }
    },
    onValidationStatusChanged
  });

  const { value: currentValue } = inputModel;
  const isCurrentValueAvailableInOptions = !!optionsAvailable.find(
    ({ value }) => value === currentValue
  );

  const options = createOptions({
    includeNoneSelected: !excludeNoneSelected && !required,
    optionsAvailable,
    optionNoneSelected: {
      label: t('common.dropdown.noneSelected'),
      value: ''
    }
  });

  useEffect(() => {
    if (currentValue && !isCurrentValueAvailableInOptions) {
      inputModel.setValue('');
    }
  }, [isCurrentValueAvailableInOptions, inputModel, currentValue]);

  return {
    ...inputModel,
    hidden,
    options,
    valueLabel: options.find(({ value }) => value === currentValue)?.label || ''
  };
};

const createOptions = ({
  includeNoneSelected,
  optionsAvailable,
  optionNoneSelected
}: {
  includeNoneSelected: boolean;
  optionsAvailable: OptionListOption[];
  optionNoneSelected: OptionListOption;
}) => {
  if (includeNoneSelected) {
    return [optionNoneSelected, ...optionsAvailable];
  }

  return optionsAvailable;
};
