import { Icon } from '@teliafi/fi-ds';
import { multiplex } from 'doings/multiplex/multiplex';
import { ProductNewsVote } from 'types/productNews';

const NewsRatingsVoteIcon: React.FC<{
  vote?: ProductNewsVote;
  size?: 'sm' | 'md';
  'aria-label'?: string;
}> = ({ vote, size = 'md', 'aria-label': htmlAriaLabel }) => {
  const iconName = multiplex([
    'minus' as const,
    [vote === 'upvote', 'like' as const],
    [vote === 'downvote', 'dislike' as const]
  ]);

  return (
    <Icon
      data-testid={`news-ratings-icon-${iconName}`}
      aria-label={htmlAriaLabel}
      name={iconName}
      size={size}
    />
  );
};

export { NewsRatingsVoteIcon };
