import { InlineNotification, TeliaLink } from 'common-components';
import getEnvironmentVariable from 'doings/getEnvironmentVariable/getEnvironmentVariable';
import { messages } from 'doings/track/analyticsEvents';

const TicketsListInfoNotice: React.FC = () => {
  return (
    <InlineNotification
      data-testid="ticket-list-notification"
      variant="information"
      content={{
        key: 'tickets.list.notifications.ticketVisibility',
        components: [
          <TeliaLink.Translatable.External
            key="my-nebula-portal-link"
            variant="text"
            to={getEnvironmentVariable('REACT_APP_LINK_TO_TIN_MY_NEBULA')}
            iconRight="external"
            track={messages.troubleTicketListOpenMyNebula}
          />,
          <TeliaLink.Translatable.External
            key="telia-cygate-pulsar-portal-link"
            variant="text"
            to={getEnvironmentVariable('REACT_APP_LINK_TO_TELIA_CYGATE')}
            iconRight="external"
            track={messages.troubleTicketListOpenPulsar}
          />
        ]
      }}
    />
  );
};

export default TicketsListInfoNotice;
