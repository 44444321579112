import { FieldErrorText } from '@teliafi/fi-ds';
import { TeliaLink } from 'common-components';
import createLinkWithPublicUrl from 'doings/links/createLinkWithPublicUrl/createLinkWithPublicUrl';
import { AnalyticsEvent } from 'doings/track/analyticsEvents';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthorizationParams } from 'types/authorization';

import downloadFileWithFetch from './downloadFileWithFetch';

const getHref = (to: string) => (to.startsWith('/') ? createLinkWithPublicUrl(to) : to);

/**
 * Renders a link to a file which the user can download. Mainly used when we
 * retrieve files via our own APIs, e.g. express APIs in our GraphQL layer.
 *
 * When providing a relative URL to a file with forward slash (/), public URL
 * is appended into the path, since our application expects to run in
 * /yrityksille/yritysportaali
 *
 * Giving a relative path without forward slash generates a link relative to
 * the current URL with the current route.
 */
const DownloadLink: React.FC<{
  to: string;
  label: string;
  block?: boolean;
  iconLeft: 'attachment' | 'download';
  disabled?: boolean;
  disabledTooltipKey?: string;
  enabledWhen?: AuthorizationParams;
  'data-testid'?: string;
  downloadWithFetch?: boolean;
  useFileNameFromResponse?: boolean;
  track?: AnalyticsEvent;
}> = ({
  to,
  label: text,
  block = false,
  iconLeft: icon,
  disabled = false,
  disabledTooltipKey,
  enabledWhen,
  'data-testid': dataTestId,
  downloadWithFetch = false,
  useFileNameFromResponse = false,
  track
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [showError, setShowError] = useState<boolean>();

  const fileDownloadHandler = useCallback(
    async (url: string, fileName: string, fileNameFromResponse: boolean) => {
      setShowError(false);
      setIsLoading(true);

      const isSuccess = await downloadFileWithFetch(url, fileName, fileNameFromResponse);
      if (!isSuccess) {
        setShowError(true);
      }

      setIsLoading(false);
    },
    []
  );

  const href = getHref(to);
  return (
    <div>
      {downloadWithFetch ? (
        <TeliaLink.Click
          data-testid={dataTestId}
          variant={block ? 'standalone-download' : 'standalone'}
          label={text}
          iconLeft={icon}
          onClick={() => fileDownloadHandler(href, text, useFileNameFromResponse)}
          track={track}
          loading={isLoading}
          disabled={disabled}
          disabledTooltipKey={disabledTooltipKey}
          enabledWhen={enabledWhen}
        />
      ) : (
        <TeliaLink.External
          data-testid={dataTestId}
          variant={block ? 'standalone-download' : 'standalone'}
          label={text}
          iconLeft={icon}
          to={href}
          openInNewTab={false}
          track={track}
          loading={isLoading}
          disabled={disabled}
          disabledTooltipKey={disabledTooltipKey}
          enabledWhen={enabledWhen}
        />
      )}

      {showError && !isLoading && (
        <FieldErrorText>{t('tickets.allAttachments.error')}</FieldErrorText>
      )}
    </div>
  );
};

export default DownloadLink;
