import { Div, Flex } from '@gaiads/telia-react-component-library';
import { DefinitionList, ModalDialog, TeliaLink } from 'common-components';
import { EN_DASH } from 'doings/dash/dash';
import { mobile } from 'doings/track/analyticsEvents';
import { SubscriptionDetails } from 'hooks/mobileSubscriptions/useSubscriptionDetails/subscriptionDetailsQuery';
import { useTranslation } from 'react-i18next';

export const SUBSCRIPTION_USER_MODAL = 'SUBSCRIPTION_USER_MODAL';

const SubscriptionUserModal: React.FC<{
  editUserInformationUrl: string;
  subscription: SubscriptionDetails;
  isOpen: boolean;
  isSurfCard: boolean;
  onCloseClick: VoidFunction;
  disableEdit?: boolean;
}> = ({
  editUserInformationUrl,
  subscription,
  isOpen,
  isSurfCard,
  onCloseClick,
  disableEdit = false
}) => {
  const { t } = useTranslation();
  const isPreopened = subscription?.status === 'PREOPENED';
  const isDisabled = subscription?.status === 'DISABLED';
  const nopaText = subscription.nopaCode
    ? t(`subscriptions.nopaCodes.${subscription.nopaCode}`)
    : EN_DASH;

  return (
    <ModalDialog
      title={t('subscriptions.subscriptionUserModal.title')}
      isOpen={isOpen}
      onClose={onCloseClick}
    >
      <DefinitionList
        data-testid="subscription-user-information"
        loading={!subscription}
        addDividers
        fiftyFifty
      >
        <DefinitionList.MultilineItem
          data-testid="subscription-user-information-name"
          heading={t('subscriptions.subscriptionUserModal.name')}
          values={
            !isSurfCard
              ? [subscription.userName, subscription.user?.additionalInfo]
              : [subscription.userName]
          }
        />
        <DefinitionList.MultilineItem
          data-testid="subscription-user-information-address"
          heading={t('subscriptions.subscriptionUserModal.address')}
          values={[subscription.user?.address.street, subscription.user?.address.postalAddress]}
        />
        <DefinitionList.MultilineItem
          data-testid="subscription-user-information-email"
          heading={t('subscriptions.subscriptionUserModal.emailAddress')}
          values={[subscription.user?.email?.toLocaleLowerCase()]}
        />
        <DefinitionList.MultilineItem
          data-testid="subscription-user-information-costcenter"
          heading={t('subscriptions.subscriptionUserModal.costCenter')}
          values={[subscription.billing?.costCenter]}
        />
        <DefinitionList.MultilineItem
          data-testid="subscription-user-information-nopa"
          heading={t('subscriptions.subscriptionUserModal.nopa')}
          values={[nopaText]}
        />
      </DefinitionList>

      {!disableEdit && (
        <Div margin={{ top: 'md' }}>
          <Flex centeredHorizontally>
            <TeliaLink.External
              data-testid="edit-user-information"
              variant="standalone"
              to={editUserInformationUrl}
              label={t('subscriptions.actions.userInformationChange.action')}
              track={mobile.changeUserInformation}
              disabled={isPreopened || isDisabled}
              enabledWhen={{
                onePermission: 'B2B_PORTAL',
                anyPermission: ['MOBILE_SUBSCRIPTIONS']
              }}
            />
          </Flex>
        </Div>
      )}
    </ModalDialog>
  );
};

export default SubscriptionUserModal;
