import createCorporatePortalLink, {
  Args
} from 'doings/links/createCorporatePortalLink/createCorporatePortalLink';
import openUrl from 'doings/openUrl/openUrl';
import { AnalyticsEvent } from 'doings/track/analyticsEvents';
import trackEvent from 'doings/track/trackEvent';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export const useRouting = () => {
  const history = useHistory();

  const goto = useCallback(
    ({
      to,
      toCorporatePortal = '',
      toCorporatePortalArgs = {},
      useB2XLink: useRouterLink = true,
      analyticsEvent
    }: {
      to: string;
      toCorporatePortal?: string;
      toCorporatePortalArgs?: Args;
      useB2XLink?: boolean;
      analyticsEvent?: AnalyticsEvent;
    }) => {
      analyticsEvent && trackEvent(analyticsEvent);

      if (useRouterLink) {
        history.push(to);
      } else {
        openUrl(createCorporatePortalLink(toCorporatePortal, toCorporatePortalArgs));
      }
    },
    [history]
  );

  return {
    /**
     * Goto considered harmful, except when it's not. Routes the user
     * to a B2X Portal or respective B2B Portal link. Used together
     * with feature flags or segment-specific routing.
     */
    goto
  };
};
