import { Gutter } from '@gaiads/telia-react-component-library';
import { Skeleton } from 'common-components';

const ContractInfoSkeleton: React.FC = () => {
  return (
    <Skeleton.Panel>
      <Skeleton.Title width="50%" />

      <Gutter />

      <Skeleton.Button width="100%" />

      <Gutter />

      <Skeleton.Title width="30%" />
    </Skeleton.Panel>
  );
};

export default ContractInfoSkeleton;
