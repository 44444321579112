import { ApolloError, gql } from '@apollo/client';
import { getDefaultDateRange } from 'doings/datetime/getDefaultDateRange';
import formatDatetime, { DateFormats } from 'doings/formatDatetime/formatDatetime';
import removeEmptyFields from 'doings/removeEmptyFields/removeEmptyFields';
import getInvoicesOnFetchMore, {
  InvoicesInResultsType
} from 'hooks/invoicing/invoices/useInvoices/getInvoicesOnFetchMore';
import {
  InvoiceFilterParameters as InvoiceFilterCriteria,
  InvoiceFilterParameters
} from 'hooks/invoicing/invoices/useInvoices/useInvoicesFilters/useInvoicesFilter';
import useApiStatus, { ViewStatus } from 'hooks/useApiStatus/useApiStatus';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { Invoice } from 'types/invoice';
import { SortParameter } from 'types/sort';

export const invoicesQuery = gql`
  query GetInvoices(
    $query: InvoicesQuery
    $offset: Int!
    $limit: Int!
    $sort: String
    $order: String
    $allCompanies: Boolean
  ) {
    invoices(
      query: $query
      offset: $offset
      limit: $limit
      sort: $sort
      order: $order
      allCompanies: $allCompanies
    ) {
      total
      items {
        id
        key
        collectionGroup
        referenceNumber
        type
        invoiceDate
        dueDate
        totalAmount
        status
        companyCode
        companyName
        highlightAs
      }
    }
  }
`;

const getInvoiceDetails = (invoice: Invoice): Invoice => {
  const {
    id,
    key,
    collectionGroup,
    referenceNumber,
    type,
    invoiceDate,
    dueDate,
    totalAmount,
    status,
    companyCode,
    companyName,
    highlightAs
  } = invoice;

  return {
    id,
    key,
    collectionGroup,
    referenceNumber,
    type,
    invoiceDate: formatDatetime(invoiceDate),
    dueDate: formatDatetime(dueDate),
    totalAmount,
    formattedTotal: new Intl.NumberFormat('fi', {
      style: 'currency',
      currency: 'EUR',
      useGrouping: false
    }).format(totalAmount),
    status,
    companyCode,
    companyName,
    highlightAs
  };
};

const DEFAULT_LIMIT = 10;

export const prepareQueryParameters = (filterCriteria: InvoiceFilterParameters) => {
  const { defaultDateFromPlaceholder, defaultDateToPlaceholder } = getDefaultDateRange();
  const scope = filterCriteria.scope;
  const search = filterCriteria.search;

  return removeEmptyFields({
    invoiceNumber: scope === 'invoiceNumber' ? search : '',
    userName: scope === 'userName' ? search : '',
    msisdn: scope === 'msisdn' ? search : '',
    collectionGroup: scope === 'collectionGroup' ? search : '',
    invoiceDateFrom: formatDatetime(
      filterCriteria.from ? filterCriteria.from : defaultDateFromPlaceholder,
      DateFormats.DATE_NATIONAL
    ),
    invoiceDateTo: formatDatetime(
      filterCriteria.to ? filterCriteria.to : defaultDateToPlaceholder,
      DateFormats.DATE_NATIONAL
    ),
    invoiceType: filterCriteria.type,
    status: filterCriteria.status
  });
};

const useInvoices = (
  sortParameter: SortParameter,
  filterCriteria: InvoiceFilterCriteria,
  allCompanies: boolean,
  limit = DEFAULT_LIMIT,
  skip = false
): {
  invoices: { total: number; items: Invoice[] };
  error?: ApolloError;
  loading: boolean;
  loadingMore: boolean;
  fetchMore: VoidFunction;
  hasMoreResults: boolean;
  apiState: ViewStatus;
} => {
  const queryParameters = prepareQueryParameters(filterCriteria);

  const {
    data,
    error,
    loading,
    loaded,
    loadingMore,
    fetchMore: fetchMoreFromQuery
  } = useCallBackend<InvoicesInResultsType>({
    query: invoicesQuery,
    queryVariables: {
      offset: 0,
      limit,
      query: queryParameters,
      allCompanies,
      ...sortParameter
    },
    skip
  });

  const mappedInvoices = data?.invoices.items?.map(getInvoiceDetails) ?? [];

  const apiState = useApiStatus({
    loading,
    error,
    loadingMore,
    loaded,
    items: mappedInvoices,
    query: queryParameters
  });

  return {
    invoices: {
      total: data ? data.invoices.total : mappedInvoices.length,
      items: mappedInvoices
    },
    error,
    loading,
    loadingMore,
    fetchMore: () =>
      fetchMoreFromQuery({
        variables: { offset: mappedInvoices.length },
        updateQuery: getInvoicesOnFetchMore
      }),
    hasMoreResults: data ? mappedInvoices.length < data.invoices.total : true,
    apiState
  };
};

export default useInvoices;
