import { BodyText } from '@gaiads/telia-react-component-library';
import { ModalDialog } from 'common-components';
import isCorporatePortalOrUlmUrl from 'doings/isCorporatePortalOrUlmUrl/isCorporatePortalOrUlmUrl';
import { modals } from 'doings/track/analyticsEvents';
import trackEvent from 'doings/track/trackEvent';
import useUserLanguage from 'hooks/useUserLanguage/useUserLanguage';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  setAcceptUrl,
  setIsModalOpen,
  setLanguage,
  setShowConfirmationModal
} from 'redux/slices/confirmationModalSlice';
import { RootState } from 'redux/store';

export const ConfirmationModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const isModalOpen = useSelector((state: RootState) => state.confirmationModal.isModalOpen);
  const acceptUrl = useSelector((state: RootState) => state.confirmationModal.acceptUrl);
  const changeToLanguage = useSelector(
    (state: RootState) => state.confirmationModal.changeToLanguage
  );
  const { setLanguage: selectLanguage } = useUserLanguage();

  const acceptModal = async () => {
    dispatch(setIsModalOpen(false));
    dispatch(setShowConfirmationModal(false));

    if (acceptUrl === ':back') {
      history.goBack();
      dispatch(setAcceptUrl(''));
    } else if (changeToLanguage) {
      await selectLanguage(changeToLanguage);
      dispatch(setLanguage(null));
    } else if (isCorporatePortalOrUlmUrl(acceptUrl) || acceptUrl.endsWith('logout')) {
      window.open(acceptUrl, '_self');
      dispatch(setAcceptUrl(''));
    } else if (acceptUrl.startsWith('https://') || acceptUrl.startsWith('//')) {
      window.open(acceptUrl, '_blank');
      dispatch(setAcceptUrl(''));
    } else {
      history.push(acceptUrl);
      dispatch(setAcceptUrl(''));
    }

    trackEvent(modals.confirmationLeavePage);
  };

  const closeModal = () => {
    if (changeToLanguage) {
      dispatch(setLanguage(null));
      dispatch(setAcceptUrl(''));
    }

    dispatch(setIsModalOpen(false));
    trackEvent(modals.confirmationStayOnPage);
  };

  return (
    <ModalDialog
      isOpen={isModalOpen}
      title={t('modal.leavePageConfirmation.title')}
      acceptButton={{
        label: t('modal.leavePageConfirmation.accept'),
        onClick: acceptModal,
        testIdSuffix: 'confirm'
      }}
      closeButton={{
        label: t('modal.leavePageConfirmation.close'),
        onClick: closeModal,
        testIdSuffix: 'dismiss'
      }}
      onClose={closeModal}
      isNarrow
      data-testid="confirmation-modal"
    >
      <Trans
        i18nKey="modal.leavePageConfirmation.description"
        components={[<BodyText key="leave-page-confirmation-message" />]}
      />
    </ModalDialog>
  );
};
