import { GridBag, GrowlNotification, Gutter } from '@gaiads/telia-react-component-library';
import AssetDataPanel from 'B2XApp/InternetConnections/AssetDataPanel/AssetDataPanel';
import SubscriptionAssetSkeleton from 'B2XApp/Subscriptions/SubscriptionDetails/SubscriptionAsset/SubscriptionAssetSkeleton/SubscriptionAssetSkeleton';
import { DataPanel, Heading, MobilePadding, TileButton, TileButtonGrid } from 'common-components';
import ContactSkeleton from 'common-components/Contact/ContactSkeleton/ContactSkeleton';
import DetailsPage from 'common-components/DetailsPage/DetailsPage';
import { Price } from 'common-components/Price/Price';
import formatDatetime from 'doings/formatDatetime/formatDatetime';
import getEnvironmentVariable from 'doings/getEnvironmentVariable/getEnvironmentVariable';
import { createCorporatePortalLinkWithFragment } from 'doings/links/createCorporatePortalLink/createCorporatePortalLink';
import getOriginatorPath from 'doings/links/getOriginatorPath/getOriginatorPath';
import { joinURLSearchParams } from 'doings/links/joinURLSearchParams/joinURLSearchParams';
import { assets } from 'doings/track/analyticsEvents';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InternetConnectionDetailsItem, ProductType } from 'types/internetConnections';
import { ServiceRequestDynamicFormType } from 'types/serviceRequest';

import ChildAssetItem from './ChildAssetItem';

const THREE_COLUMN_LAYOUT = 3;
const TWO_COLUMN_LAYOUT = 2;

const InternetConnectionDetailsPage: React.FC<{
  asset?: InternetConnectionDetailsItem;
  loading: boolean;
  hasError?: boolean;
}> = ({ asset, loading, hasError = false }) => {
  const { t } = useTranslation();

  const [terminationDateNotification, setTerminationDateNotification] = useState<
    string | undefined
  >(() =>
    asset?.terminationDate
      ? t('internetConnectionDetails.growl.terminationDate', {
          name: asset.name,
          terminationDate: formatDatetime(asset.terminationDate)
        })
      : undefined
  );

  const childAssets =
    asset?.childAssets.filter((childAsset) => childAsset.parentId === asset.id) ?? [];
  const isBusinessInternet = asset?.productType === ProductType.BusinessInternet;
  const isSmartConnect = asset?.productType === ProductType.SmartConnect;

  return (
    <>
      {!!terminationDateNotification && (
        <GrowlNotification
          data-testid="termination-growl-notification"
          notification={terminationDateNotification}
          position="fixed"
          icon="alert-filled"
          variant="warning"
          closeOnClick={() => setTerminationDateNotification(undefined)}
        />
      )}

      <DetailsPage
        data-testid="internet-connection-details-page"
        hasError={hasError}
        loading={loading}
        backButtonPath="/internet-connections/"
        headline={t('internetConnectionDetails.title')}
      >
        <GridBag>
          <GridBag.Item md={6} sm={12}>
            <AssetDataPanel asset={asset} loading={loading} />
          </GridBag.Item>

          <GridBag.Item md={6} sm={12}>
            <DataPanel
              title={t('common.pricing.fees.monthly')}
              compactTitle
              skeletonLoader={<SubscriptionAssetSkeleton />}
              loading={loading}
            >
              <Price price={asset?.recurringTotal} currency="€" />
            </DataPanel>

            <Gutter size="sm" />

            <DataPanel
              skeletonLoader={<ContactSkeleton />}
              loading={loading}
              title={t('internetConnectionDetails.contact.title')}
            >
              <TileButtonGrid
                columns={{
                  desktop: isBusinessInternet ? THREE_COLUMN_LAYOUT : TWO_COLUMN_LAYOUT,
                  mobile: TWO_COLUMN_LAYOUT
                }}
              >
                {!!asset && !isSmartConnect && (
                  <TileButton
                    data-testid="create-service-request-link"
                    iconName="document-edit"
                    label={t('internetConnectionDetails.contact.createServiceRequest')}
                    to={`/service-requests/new/internet-connection/${asset.id}${joinURLSearchParams(
                      '?',
                      {
                        t: ServiceRequestDynamicFormType.CHANGES_TO_CURRENT_SERVICES,
                        originator: getOriginatorPath()
                      }
                    )}`}
                    whenClicked={assets.createServiceRequest}
                    enabledWhen={{
                      onePermission: 'SERVICE_REQUESTS'
                    }}
                  />
                )}

                {asset?.assetType === 'b2b' && !isSmartConnect && (
                  <TileButton
                    data-testid="create-ticket-link"
                    iconName="error"
                    label={t('internetConnectionDetails.contact.createTicket')}
                    to={`/tickets/new/asset/${asset.id}${joinURLSearchParams('?', {
                      originator: getOriginatorPath()
                    })}`}
                    whenClicked={assets.createTroubleTicket}
                    enabledWhen={{
                      onePermission: 'INCIDENT_TICKETS'
                    }}
                  />
                )}

                {asset?.assetType === 'b2o' && !isSmartConnect && (
                  <TileButton
                    data-testid="surf-manager-link"
                    iconName="error"
                    label={t('internetConnectionDetails.contact.createTicket')}
                    to="https://surfmanager.telia.fi/surf/Login"
                    whenClicked={assets.createTroubleTicket}
                    enabledWhen={{
                      onePermission: 'INCIDENT_TICKETS'
                    }}
                    showExternalIcon
                  />
                )}

                {isBusinessInternet && (
                  <TileButton
                    data-testid="technical-subscription-specific-reporting"
                    iconName="flag"
                    label={t(
                      'internetConnectionDetails.contact.technicalSubscriptionSpecificReporting'
                    )}
                    to="https://surfmanager.telia.fi/surf/Login"
                    whenClicked={assets.openTechnicalReporting}
                    enabledWhen={{
                      onePermission: 'INCIDENT_TICKETS'
                    }}
                    tooltip={t('internetConnectionDetails.contact.surfManagerTooltip')}
                    showExternalIcon
                  />
                )}

                {isSmartConnect && (
                  <TileButton
                    data-testid="smart-connect-management"
                    iconName="simcard"
                    label={t('internetConnectionDetails.contact.smartConnectManagement')}
                    to={createCorporatePortalLinkWithFragment(
                      'search/subscriptionSearch',
                      'smartConnect' // TODO: replace with a real fragment after it's implemented on B2B side
                    )}
                    whenClicked={assets.oldPortalSmartConnectManagement}
                    tooltip={t('internetConnectionDetails.contact.smartConnectManagementTooltip')}
                  />
                )}

                {isSmartConnect && (
                  <TileButton
                    data-testid="smart-connect-admin"
                    iconName="user-admin"
                    label={t('internetConnectionDetails.contact.smartConnectAdmin')}
                    to={getEnvironmentVariable('REACT_APP_LINK_TO_SMART_CONNECT_ADMIN')}
                    whenClicked={assets.smartConnectAdmin}
                  />
                )}
              </TileButtonGrid>
            </DataPanel>
          </GridBag.Item>

          <GridBag.Item
            md={12}
            hidden={childAssets.length <= 0}
            margin={{ top: 'md' }}
            data-testid="child-assets-divider"
          >
            <MobilePadding>
              <Heading.H3>
                {t('internetConnectionDetails.additionalServicesAndProducts')}
              </Heading.H3>
            </MobilePadding>
          </GridBag.Item>

          {!!asset &&
            childAssets.map((childAsset) => (
              <GridBag.Item md={6} sm={12} key={childAsset.id}>
                <ChildAssetItem
                  rootAssetId={asset.id}
                  childAsset={childAsset}
                  data-testid="internet-connection-details-page-child-asset-item"
                />
              </GridBag.Item>
            ))}
        </GridBag>
      </DetailsPage>
    </>
  );
};

export default InternetConnectionDetailsPage;
