import { BodyText, Div, Gutter } from '@gaiads/telia-react-component-library';
import { Icon } from '@teliafi/fi-ds';
import { Button, ModalDialog, Optional } from 'common-components';
import trackEvent from 'doings/track/trackEvent';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { hideInfoModal } from 'redux/slices/infoModalSlice';
import { RootState } from 'redux/store';

import styles from './InfoModal.module.scss';

export const InfoModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isModalOpen = useSelector((state: RootState) => state.infoModal.isModalOpen);
  const title = useSelector((state: RootState) => state.infoModal.title);
  const text = useSelector((state: RootState) => state.infoModal.text);
  const ctaText = useSelector((state: RootState) => state.infoModal.ctaText);
  const link = useSelector((state: RootState) => state.infoModal.link);
  const whenClicked = useSelector((state: RootState) => state.infoModal.whenClicked);

  const closeModal = () => dispatch(hideInfoModal());

  const onReadMore = () => {
    if (whenClicked) {
      trackEvent(whenClicked);
    }

    window.open(link, '_blank');
    closeModal();
  };

  return (
    <ModalDialog
      isOpen={isModalOpen}
      title={title ?? ' '}
      onClose={closeModal}
      data-testid="info-modal"
    >
      <BodyText>{text}</BodyText>

      <Optional data={link}>
        <Gutter size="md" />

        <Div className={styles.buttons}>
          <Button
            type="button"
            variant="tertiary-purple"
            onClick={closeModal}
            size="sm"
            data-testid="info-modal-close-button"
          >
            {t('common.closeButton.label')}
          </Button>

          <Button
            type="button"
            variant="primary"
            onClick={onReadMore}
            size="sm"
            data-testid="info-modal-cta-button"
          >
            {ctaText || t('notifications.readMore')}

            <Icon name="chevron-right" size="sm" data-testid="read-more-icon" />
          </Button>
        </Div>
      </Optional>
    </ModalDialog>
  );
};
