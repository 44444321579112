import { SupportedFontWeight } from '@gaiads/telia-react-component-library';
import { Paragraph } from '@teliafi/fi-ds';
import { EN_DASH } from 'doings/dash/dash';

import styles from './ListViewMobileDataItem.module.scss';

const ListViewMobileDataItem: React.FC<{
  label: string;
  value: string;
  fontWeight?: SupportedFontWeight;
}> = ({ label, value, fontWeight }) => (
  <Paragraph className={styles.dataItem}>
    <span className={styles.dataItem_label}>{label}</span>

    <span className={styles.dataItem_value}>
      {fontWeight === 'bold' ? <b>{value || EN_DASH}</b> : value || EN_DASH}
    </span>
  </Paragraph>
);

export default ListViewMobileDataItem;
