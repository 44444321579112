import {
  BodyText,
  Dropdown,
  GridBag,
  Gutter,
  OptionListOption,
  Page
} from '@gaiads/telia-react-component-library';
import { DataPanel, Layout, PageWithSubnavigation } from 'common-components';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiStatus } from 'types/api';
import { PaymentRemindersResponse } from 'types/paymentReminder';

import PaymentReminderNotification from './PaymentReminderNotification/PaymentReminderNotification';
import PaymentReminderSettings from './PaymentReminderSettings/PaymentReminderSettings';
import PaymentReminderSettingsSkeleton from './PaymentReminderSettings/PaymentReminderSettingsSkeleton';

const PaymentRemindersPage: React.FC<{
  status: ApiStatus;
  available: boolean;
  reminders: PaymentRemindersResponse;
  refetch: VoidFunction;
  companyId: string | undefined;
  setCompanyId: Dispatch<SetStateAction<string | undefined>>;
  availableCompanies: OptionListOption[];
}> = ({ status, available, reminders, refetch, companyId, setCompanyId, availableCompanies }) => {
  const { t } = useTranslation();
  const changeCompany = (id: string) => {
    setCompanyId(id);
    refetch();
  };

  return (
    <PageWithSubnavigation showSubnavigationForCurrentPath withoutCompanySelector>
      <Page.Row responsiveFullWidth>
        <Layout.SingleColumn>
          <DataPanel
            title={t('invoices.paymentReminder.title')}
            loading={status === ApiStatus.Loading}
            mainPanel
          >
            <BodyText>{t('invoices.paymentReminder.description')}</BodyText>

            {availableCompanies.length > 1 && (
              <GridBag>
                <GridBag.Item md={9} sm={12}>
                  <Gutter />

                  <Dropdown
                    data-testid="invoice-payment-reminder--related-company"
                    label={t('invoices.paymentReminder.relatedCompany')}
                    aria-label={t('invoices.paymentReminder.relatedCompany')}
                    onChange={(_, option: OptionListOption) => changeCompany(option.value)}
                    options={availableCompanies}
                    selectedOption={availableCompanies.find((option) => option.value === companyId)}
                  />
                </GridBag.Item>
              </GridBag>
            )}

            {status !== ApiStatus.Loading && (
              <PaymentReminderNotification
                status={status}
                available={available}
                data-testid="payment-reminder-page-notification"
              />
            )}

            {status === ApiStatus.Loading && (
              <PaymentReminderSettingsSkeleton data-testid="payment-reminder-page-settings-skeleton" />
            )}

            {status === ApiStatus.Loaded && (
              <PaymentReminderSettings
                data-testid="payment-reminder-page-settings"
                available={available}
                reminders={reminders}
                companyId={companyId}
              />
            )}
          </DataPanel>
        </Layout.SingleColumn>
      </Page.Row>
    </PageWithSubnavigation>
  );
};

export default PaymentRemindersPage;
