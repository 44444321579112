import { isEqual } from 'lodash/fp';
import React from 'react';

const mapProps = (comparables: string[], children: React.ReactElement) => {
  const props = React.Children.only(children).props;
  return comparables.map((comparable) => props[comparable]);
};

/**
 * Memoises a nested child component. Use for pure functional components,
 * which render the same output given the same props, render often and
 * with the same props, and have a medium or big size.
 *
 * A memoised component is re-rendered only if any of its significant
 * properties change over two subsequent render cycles, which means that
 * the child component should not have any internal state which may
 * change its render output without the properties passed to it changing.
 *
 * Memoisation is a performance optimisation technique. As with any
 * optimisation, use it when necessary. If a component doesn't hurt
 * application performance when a user interacts with it, the component
 * needn't be memoised. When using memoisation, always test to make sure
 * it is applied correctly and an underlying component doesn't rerender
 * unnecessarily.
 *
 * Good candidates for this component include any and all list views,
 * which may see a performance improvement of a hundredfold (<0.2 ms
 * render cycles instead of 50+ ms) for larger lists whilst significant
 * properties remain unchanged.
 *
 * ```tsx
 * <Memo comparables={['items', 'prop2', 'prop3']}>
 *  <MyListViewComponent
 *    items={items}
 *    prop2={prop2}
 *    prop3={prop3}
 *    incomparableProp={incomparableProp} />
 * </Memo>
 * ```
 *
 * @param comparables An array of the child component's significant properties' names.
 * @param children A single component.
 */
const Memo: React.FC<{
  comparables: string[];
  children: React.ReactElement;
  debug?: boolean;
}> = React.memo(
  ({ children, debug }) => {
    if (debug) {
      const name = (React.Children.only(children).type as { name: string }).name;
      console.debug(`Rendering memoised component: ${name}`);
    }

    return <>{children}</>;
  },

  (prevProps, nextProps) =>
    isEqual(
      mapProps(prevProps.comparables, prevProps.children),
      mapProps(nextProps.comparables, nextProps.children)
    )
);

export default Memo;
