import { Flex, Gutter, Page } from '@gaiads/telia-react-component-library';
import { ListFilters } from 'common-components/ListFilters';
import ListViewHeader, {
  MultipleCompaniesCheckboxProps
} from 'common-components/ListViewHeader/ListViewHeader';
import { getFilterOptions } from 'doings/getFilterOptions/getFilterOptions';
import { getSearchOptions } from 'doings/getFilterOptions/getSearchOptions';
import useIsSmallerBreakpointActive from 'hooks/useIsSmallerBreakpointActive/useIsSmallerBreakpointActive';
import {
  DropdownOptionDefinition,
  LabelsDefinition,
  SearchOptionDefinition
} from 'types/listFilters';

import { ListTitle } from './ListTitle';

type ListHeaderProps = {
  withMultiCompanyFeature?: boolean;
  multipleCompaniesCheckboxParams?: MultipleCompaniesCheckboxProps;
  titleTestid?: string;
  searchScopeOptions?: SearchOptionDefinition[];
  withSearchActivationThreshold?: boolean;
  actionButtons?: React.ReactElement;
  dateRangeParams?: DateRangeParams;
  dateTypeParams?: FilterParams;
  statusFilterParams?: FilterParams;
  typeFilterParams?: TypeFilterParams;
  customFilterParams?: CustomFilterParam[];
  labels?: LabelsDefinition;
  sortDropdown?: React.ReactElement;
  excelDownloadLink?: React.ReactElement;
  infoNotice?: React.ReactElement;
  children: React.ReactNode;
};

export type DateRangeParams = {
  label: string;
  helperText?: string;
  helperTooltip?: string;
  withDefaultDatePlaceholder?: boolean;
  'data-testid'?: string;
};

export type FilterParams = {
  label: string;
  options: DropdownOptionDefinition[];
  'data-testid': string;
};

export type TypeFilterParams = FilterParams & {
  noNoneOption?: boolean;
};

export type CustomFilterParam = FilterParams & {
  param: string;
  noNoneOption?: boolean;
};

const ListHeader: React.FC<ListHeaderProps> = ({
  withMultiCompanyFeature,
  multipleCompaniesCheckboxParams,
  titleTestid,
  searchScopeOptions,
  withSearchActivationThreshold,
  actionButtons,
  dateRangeParams,
  dateTypeParams,
  statusFilterParams,
  typeFilterParams,
  customFilterParams,
  labels,
  sortDropdown,
  excelDownloadLink,
  infoNotice,
  children
}) => {
  const isMobileView = useIsSmallerBreakpointActive('md');
  const useSmallMobileMargin = !!multipleCompaniesCheckboxParams;
  const SEARCH_THRESHOLD = 3;

  const searchOptions = getSearchOptions(
    searchScopeOptions,
    withSearchActivationThreshold && SEARCH_THRESHOLD
  );

  const filterOptions = getFilterOptions({
    dateRangeParams,
    dateTypeParams,
    statusFilterParams,
    typeFilterParams,
    customFilterParams
  });

  return (
    <>
      <Page.Row margin={useSmallMobileMargin ? { bottom: isMobileView ? 'sm' : 'lg' } : undefined}>
        <ListViewHeader
          withMultipleCompanySupport={withMultiCompanyFeature}
          multipleCompaniesCheckbox={multipleCompaniesCheckboxParams}
        >
          {!isMobileView && <ListTitle dataTestid={titleTestid}>{children}</ListTitle>}
        </ListViewHeader>
      </Page.Row>

      <ListFilters
        search={searchOptions}
        filters={filterOptions}
        labels={labels ?? {}}
        sortDropdown={sortDropdown}
        excelDownloadLink={excelDownloadLink}
        infoNotice={infoNotice}
      >
        <Flex occupyHorizontalSpace pileVerticallyTo="sm">
          {isMobileView && (
            <ListTitle dataTestid={titleTestid}>
              {children}

              <Gutter size="md" />
            </ListTitle>
          )}

          {actionButtons}
        </Flex>
      </ListFilters>
    </>
  );
};

export default ListHeader;
