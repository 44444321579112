import { DashboardSearchContext } from 'contexts/SearchContext/DashboardSearchContext';
import { useSubmenuState } from 'contexts/SubmenuContext/SubmenuContext';
import { noOp } from 'doings/noOp/noOp';
import useIsSmallerBreakpointActive from 'hooks/useIsSmallerBreakpointActive/useIsSmallerBreakpointActive';
import {
  Dispatch,
  RefObject,
  SetStateAction,
  createContext,
  createRef,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

export type ContentSearchState = {
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  searchFieldRef: RefObject<HTMLDivElement>;
  resultsRef: RefObject<HTMLDivElement>;
  reposition: VoidFunction;
  searchFieldActive?: boolean;
  setSearchFieldActive?: Dispatch<SetStateAction<boolean>>;
};

export const ContentSearchContext = createContext<ContentSearchState | undefined>(undefined);

export const ContentSearchProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const isMobileLayout = useIsSmallerBreakpointActive('sm');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { openMenuId, setOpenMenuId, isMobileMenuOpen, setMobileMenuOpen } = useSubmenuState();
  const isConversationOpen = useSelector((state: RootState) => state.askForHelpDrawer.isDrawerOpen);

  const searchFieldRef: React.RefObject<HTMLDivElement> = createRef();
  const resultsRef: React.RefObject<HTMLDivElement> = createRef();
  const isSearchFieldOpen = openMenuId === 'content-search';

  const onReposition = useCallback(() => {
    if (isSearchFieldOpen) {
      handleReposition(searchFieldRef, resultsRef, isMobileLayout);
    }
  }, [isSearchFieldOpen, isMobileLayout, searchFieldRef, resultsRef]);

  const onOutsideClickListener = useCallback(
    (e: MouseEvent) => {
      if (isSearchFieldOpen) {
        const searchField = searchFieldRef.current;
        const results = resultsRef.current;

        /* istanbul ignore next */
        if (!searchField || !results) {
          return;
        }

        if (
          e.target !== null &&
          !searchField.contains(e.target as Node) &&
          !results.contains(e.target as Node)
        ) {
          setOpenMenuId('');
        }
      }
    },
    [isSearchFieldOpen, setOpenMenuId, searchFieldRef, resultsRef]
  );

  useEffect(() => {
    if (isSearchFieldOpen) {
      window.addEventListener('resize', onReposition);
      document.addEventListener('click', onOutsideClickListener);

      return () => {
        window.removeEventListener('resize', onReposition);
        document.removeEventListener('click', onOutsideClickListener);
      };
    }

    return noOp;
  }, [isSearchFieldOpen, onOutsideClickListener, onReposition]);

  useEffect(() => {
    if (isConversationOpen) {
      setOpenMenuId('');
    }
  }, [isConversationOpen, setOpenMenuId]);

  useEffect(() => {
    if (isSearchFieldOpen && isMobileMenuOpen) {
      setMobileMenuOpen(false);
    }
  }, [isSearchFieldOpen, isMobileMenuOpen, setMobileMenuOpen]);

  useEffect(() => {
    if (!isSearchFieldOpen && searchTerm !== '') {
      setSearchTerm('');
    }
  }, [isSearchFieldOpen, searchTerm, setSearchTerm]);

  useEffect(() => {
    onReposition();
  }, [onReposition]);

  return (
    <ContentSearchContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
        searchFieldRef: searchFieldRef,
        resultsRef: resultsRef,
        reposition: onReposition
      }}
    >
      {children}
    </ContentSearchContext.Provider>
  );
};

const searchFieldTopOffset = 4;
const searchFieldLeftOffsetForMobile = 8;

export const handleReposition = (
  searchFieldRef: React.RefObject<HTMLDivElement>,
  resultsRef: React.RefObject<HTMLDivElement>,
  isMobileLayout: boolean
) => {
  const searchField = searchFieldRef.current;
  /* istanbul ignore next */
  if (!searchField) {
    return;
  }

  const results = resultsRef.current;
  /* istanbul ignore next */
  if (!results) {
    return;
  }

  const searchDiv = searchField as HTMLDivElement;
  const resultsDiv = results as HTMLDivElement;
  const top = searchDiv.offsetTop + searchDiv.offsetHeight + searchFieldTopOffset;
  const left = Math.max(
    0,
    searchDiv.offsetLeft +
      searchDiv.offsetWidth -
      resultsDiv.offsetWidth -
      (isMobileLayout ? searchFieldLeftOffsetForMobile : 0)
  );

  results.style.top = `${top}px`;
  results.style.left = `${left}px`;
};

export const useContentSearchState = (forDashboard?: boolean) => {
  const context = useContext(forDashboard ? DashboardSearchContext : ContentSearchContext);

  if (context === undefined) {
    throw new Error('useContentSearchState must be used with ContentSearchContext');
  }

  return context;
};
