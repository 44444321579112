import { SortableCheckboxesList } from './SortableCheckboxesList';
import { SortableCheckboxesProps, SortableCheckboxesProvider } from './SortableCheckboxesProvider';

export const SortableCheckboxes: React.FC<Omit<SortableCheckboxesProps, 'children'>> = ({
  items,
  itemClassification,
  minEnabledItems,
  maxEnabledItems,
  onChange
}) => {
  return (
    <SortableCheckboxesProvider
      items={items}
      itemClassification={itemClassification}
      minEnabledItems={minEnabledItems}
      maxEnabledItems={maxEnabledItems}
      onChange={onChange}
    >
      <SortableCheckboxesList />
    </SortableCheckboxesProvider>
  );
};
