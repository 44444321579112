import {
  ApolloError,
  ErrorPolicy,
  NetworkStatus,
  QueryResult,
  WatchQueryFetchPolicy,
  useQuery
} from '@apollo/client';
import isLoginError from 'doings/isLoginError/isLoginError';
import { DocumentNode } from 'graphql';
import useAccessControlErrorHandler from 'hooks/useAccessControlErrorHandler/useAccessControlErrorHandler';

type QueryVariables = Record<string, unknown>;

type CallBackendArgs = {
  query: DocumentNode;
  queryVariables?: QueryVariables;
  errorPolicy?: ErrorPolicy;
  skip?: boolean;
  fetchPolicy?: WatchQueryFetchPolicy;
};

export type CallBackendStartPolling = (pollInterval: number) => void;
export type CallBackendStopPolling = () => void;

export type CallBackendResponse<T> = {
  data: T | undefined;
  error: ApolloError | undefined;
  loading: boolean;
  loadingMore: boolean;
  loaded: boolean;
  startPolling: CallBackendStartPolling;
  stopPolling: CallBackendStopPolling;
  fetchMore: QueryResult<T>['fetchMore'];
  refetch: QueryResult<T>['refetch'];
};

const useCallBackend = <T>({
  query,
  queryVariables,
  errorPolicy,
  skip,
  fetchPolicy = 'cache-first'
}: CallBackendArgs) => {
  const accessControlErrorHandler = useAccessControlErrorHandler();

  let redirectIsLoading = false;

  const { data, error, loading, fetchMore, networkStatus, startPolling, stopPolling, refetch } =
    useQuery<T>(query, {
      variables: queryVariables,
      errorPolicy,
      notifyOnNetworkStatusChange: true,
      onError: accessControlErrorHandler,
      skip,
      fetchPolicy
    });

  if (isLoginError(error)) {
    redirectIsLoading = true;
  }

  return {
    data,
    error,
    loading: redirectIsLoading || loading,
    loadingMore: networkStatus === NetworkStatus.fetchMore,
    loaded: data !== undefined || error !== undefined,
    startPolling,
    stopPolling,
    fetchMore,
    refetch
  };
};

export default useCallBackend;
