import { featureFlagsQuery } from 'hooks/featureFlags/featureFlagQueries';
import useApiStatus from 'hooks/useApiStatus/useApiStatus';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { FeatureFlag } from 'types/featureFlags';

type FeatureFlagsResponse = {
  featureFlags: {
    flags: FeatureFlag[] | null;
    persistable: boolean;
  };
};

const useFeatureFlags = () => {
  const { data, error, loading, refetch } = useCallBackend<FeatureFlagsResponse>({
    query: featureFlagsQuery
  });

  const items = data?.featureFlags?.flags ? data.featureFlags?.flags : [];
  const status = useApiStatus({
    loading,
    loadingMore: false,
    loaded: !loading,
    items,
    error,
    query: featureFlagsQuery
  });

  return {
    featureFlags: items,
    persistable: data?.featureFlags?.persistable,
    status,
    refetch
  };
};

export default useFeatureFlags;
