/**
 * Organises the specified array of items into two subarrays so that
 * items matching the specified predicate are collected into the first
 * subarray and mismatched items are collected into the second. An
 * empty or undefined array of items results in empty partitions.
 *
 * @param items An array of items to partition.
 * @param predicate The predicate for classifying items into the first partition.
 * @returns An array `[<matched items>[], <mismatched items>[]]`.
 */
export default <T>(items: T[] | undefined, predicate: (item: T) => boolean): [T[], T[]] =>
  (items ?? []).reduce(
    (result, item) => {
      result[predicate(item) ? 0 : 1].push(item);
      return result;
    },
    [[] as T[], [] as T[]]
  );
