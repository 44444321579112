import { gql } from '@apollo/client';
import useApiStatus from 'hooks/useApiStatus/useApiStatus';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { ServiceRequestStatus, ServiceRequestStatusV1 } from 'types/serviceRequest';

import { getDefaultDateParams } from './getDefaultDateParams';

export const serviceRequestsAggregateQuery = gql`
  query GetServiceRequestsTotal(
    $status: String
    $limit: Int!
    $offset: Int!
    $allCompanies: Boolean
    $dateParameters: ServiceRequestDateParameters
  ) {
    serviceRequests(
      status: $status
      limit: $limit
      offset: $offset
      allCompanies: $allCompanies
      dateParameters: $dateParameters
    ) {
      total
    }
  }
`;

type ServiceRequestsListResponse = {
  total: number;
};

const useServiceRequestsAggregate = ({
  skip,
  status
}: {
  skip?: boolean;
  status: ServiceRequestStatus | ServiceRequestStatusV1;
}) => {
  const { data, error, loading, loadingMore, loaded } = useCallBackend<{
    serviceRequests: ServiceRequestsListResponse;
  }>({
    query: serviceRequestsAggregateQuery,
    queryVariables: {
      status,
      dateParameters: getDefaultDateParams(),
      limit: 0,
      offset: 0,
      allCompanies: true
    },
    fetchPolicy: 'cache-first',
    skip
  });

  return {
    totalWaitingForInformation: data?.serviceRequests?.total ?? -1,
    error,
    apiState: useApiStatus({
      loading,
      loadingMore,
      loaded,
      items: [],
      error,
      query: {
        status,
        dateParameters: getDefaultDateParams()
      }
    })
  };
};

export default useServiceRequestsAggregate;
