import { TeliaLink } from 'common-components';
import isDemoEnvironment from 'doings/isDemoEnvironment/isDemoEnvironment';
import { joinURLSearchParams } from 'doings/links/joinURLSearchParams/joinURLSearchParams';
import removeEmptyFields from 'doings/removeEmptyFields/removeEmptyFields';
import { AssetsQueryParameters } from 'hooks/assets/useAssets/useAssets';
import { useTranslation } from 'react-i18next';
import { SortParameter } from 'types/sort';

export type InternetConnectionExcelDownloadLinkProps = {
  queryParameters: AssetsQueryParameters;
  sortParameter: SortParameter;
  showFromAllCompanies: boolean;
  disabled: boolean;
};

export const ExcelDownloadLink: React.FC<InternetConnectionExcelDownloadLinkProps> = ({
  queryParameters,
  sortParameter,
  showFromAllCompanies,
  disabled
}) => {
  const { t } = useTranslation();
  const isDemoEnv = isDemoEnvironment();
  const { sort, order } = sortParameter;
  const urlSearchParams = new URLSearchParams(removeEmptyFields(queryParameters));

  const queryParams = joinURLSearchParams('?', {
    columns: [
      t('internetConnections.list.columns.name'),
      t('internetConnections.list.columns.additionalInformation'),
      t('internetConnections.list.columns.country'),
      t('internetConnections.list.columns.displayId'),
      t('common.companyCode.label'),
      t('common.company.label')
    ].join(','),
    filter: urlSearchParams.toString(),
    sort,
    order,
    showFromAllCompanies,
    sheetTitle: t('internetConnections.title')
  });

  return (
    <TeliaLink.Download
      label={t('common.excelDownload.label')}
      to={`/api/assets/listAsExcel${queryParams}`}
      iconLeft="download"
      data-testid="assets-list-as-excel-link"
      disabled={isDemoEnv || disabled}
      downloadWithFetch
      useFileNameFromResponse
    />
  );
};
