import { DynamicForms } from 'types/dynamicForms';

import { check } from './dynamicFormFieldCheck';

type PrefillValues = Record<string, boolean | string | string[]>;

export const getDynamicFormPrefills = ({
  form,
  deeplinkFromQueryParams
}: {
  form: DynamicForms.DynamicForm;
  deeplinkFromQueryParams?: string;
}) => {
  const deeplink = form.metainfo?.deeplinks?.find((d) => d.match === deeplinkFromQueryParams);
  if (!deeplink?.fields) {
    return {
      prefillValues: {},
      prefillActiveRefs: new Set<string>()
    };
  }

  const prefillActiveRefs: Set<string> = new Set<string>();
  const prefillValues: PrefillValues = deeplink.fields.reduce((aggregatedValues, field) => {
    const fieldByName = form.fields.find((f) => isDeeplinkedField(aggregatedValues, field, f));
    if (fieldByName) {
      aggregatedValues[fieldByName.id] = field.value;
      prefillActiveRefs.add(fieldByName.id);
      if (check.isDropdown(fieldByName)) {
        const option = fieldByName.options.find((o) => o.value === field.value);
        if (option) {
          aggregatedValues[`${fieldByName.id}-input`] = option.label;
          aggregatedValues[option.id] = option.value;
          prefillActiveRefs.add(option.id);
        }
      }
    }
    return aggregatedValues;
  }, {} as PrefillValues);

  return { prefillValues, prefillActiveRefs };
};

/**
 * A dynamic form field is deeplinked if:
 *
 * * It is validatable.
 * * It is named and matches that of a deeplink's field — unnamed fields have
 *   automatically generated names that deeplinks cannot refer to.
 * * It is visible — several fields can share a name, but only a single field
 *   with a particular name may be visible at any point in time.
 */
const isDeeplinkedField = (
  aggregatedValues: PrefillValues,
  deeplinkField: { name: string; value: string },
  f: DynamicForms.Field
) =>
  check.isValidatable(f) &&
  f.name === deeplinkField.name &&
  !f.hideWhen?.some((h) => typeof aggregatedValues[h] !== 'undefined') &&
  (!f.showWhen || typeof aggregatedValues[f.showWhen] !== 'undefined');
