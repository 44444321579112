import { BodyText, GridBag, Gutter } from '@gaiads/telia-react-component-library';
import { Paragraph } from '@teliafi/fi-ds';
import ErrorPage from 'B2XApp/Errors/Error/ErrorPage/ErrorPage';
import {
  ActionButtonGroup,
  Analytics,
  Button,
  DataPanel,
  DefinitionList,
  Forms,
  InlineNotification,
  Notices
} from 'common-components';
import { Div } from 'core-components';
import { addDays, isWeekend } from 'date-fns';
import formatDatetime, { DateFormats } from 'doings/formatDatetime/formatDatetime';
import getLastBusinessDayBefore from 'doings/getBusinessDays/getLastBusinessDayBefore';
import getNextBusinessDayAfterDays from 'doings/getBusinessDays/getNextBusinessDayAfterDays';
import { toISODateString } from 'doings/getFinnishHolidays/getFinnishHolidays';
import isHoliday from 'doings/isHoliday/isHoliday';
import { mobile } from 'doings/track/analyticsEvents';
import { Form, Formik } from 'formik';
import { CreateOwnershipChangeTokenData } from 'hooks/mobileSubscriptions/useOwnershipChange/ownershipChangeTokenQueries';
import { useRouting } from 'hooks/useRouting/useRouting';
import useSearchParams from 'hooks/useSearchParams/useSearchParams';
import { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { OmsuTokenFormData } from './GenerateOwnershipChangeToken';
import {
  GenerateTokenFormValues,
  getOwnershipChangeFormValidationSchema
} from './getOwnershipChangeFormValidationSchema';
import styles from './OwnershipChangeTokenForm.module.scss';

const OwnershipChangeTokenForm: React.FC<{
  subscriptionId: string;
  subscriptionName: string;
  subscriptionType: string;
  phoneNumberHash: string;
  hasBlockingServices: boolean;
  generateChangeToken: (omsuTokenData: CreateOwnershipChangeTokenData) => void;
  hasError?: boolean;
  hasExistingToken?: boolean;
  tokenForm: OmsuTokenFormData;
  setTokenForm: (tokenForm: OmsuTokenFormData) => void;
}> = ({
  subscriptionId,
  phoneNumberHash,
  hasBlockingServices,
  generateChangeToken,
  hasError = false,
  hasExistingToken = false,
  tokenForm,
  setTokenForm,
  subscriptionName,
  subscriptionType
}) => {
  const { t } = useTranslation();
  const { goto } = useRouting();
  const { preserveBackPathState } = useSearchParams();
  const { path } = useRouteMatch();
  const MIN_BUSINESS_DAYS_INTERVAL = 4;
  const MAX_DAYS_INTERVAL = 90;

  const minTerminationDate = getNextBusinessDayAfterDays(MIN_BUSINESS_DAYS_INTERVAL);
  const maxTerminationDate = toISODateString(
    getLastBusinessDayBefore(addDays(new Date(), MAX_DAYS_INTERVAL))
  );
  const validationSchema = useMemo(
    () =>
      getOwnershipChangeFormValidationSchema({
        t,
        minTerminationDate: new Date(minTerminationDate),
        maxTerminationDate: new Date(maxTerminationDate)
      }),
    [t, minTerminationDate, maxTerminationDate]
  );

  const COL_WIDTH = 6;
  const MOBILE_COL_WIDTH = 12;

  const isPrivateFlow = path.endsWith('private');

  const submitForm = useCallback(
    ({
      additionalNumber1,
      additionalNumber2,
      subscriptionTerminationDate
    }: GenerateTokenFormValues) => {
      setTokenForm({
        additionalNumber1,
        additionalNumber2,
        terminationDate: subscriptionTerminationDate
      });

      generateChangeToken({
        mobileNumber: subscriptionId,
        additionalNumber1,
        additionalNumber2,
        terminationDate: formatDatetime(subscriptionTerminationDate, DateFormats.DATE)
      });
    },
    [generateChangeToken, setTokenForm, subscriptionId]
  );

  if (hasBlockingServices) {
    return <ErrorPage />;
  }

  const infoText = isPrivateFlow
    ? t('subscriptions.generateToken.infoPrivate')
    : t('subscriptions.generateToken.infoCompany');

  return (
    <>
      <Notices data-testid="form-generate-ownership-change-notices">
        {hasError && (
          <InlineNotification
            data-testid="form-generate-ownership-change-notification"
            variant="error"
            title={{ key: 'subscriptions.generateToken.tokenGenerationError' }}
            content={
              hasExistingToken ? { key: 'subscriptions.generateToken.tokenExistsDescription' } : ''
            }
          />
        )}

        {!isPrivateFlow && (
          <InlineNotification
            data-testid="form-generate-ownership-change-note"
            variant="information"
            content={{ key: 'subscriptions.generateToken.note' }}
          />
        )}
      </Notices>

      <DataPanel data-testid="form-generate-ownership-change">
        <GridBag>
          <GridBag.Item md={12} lg={12}>
            <Div margin={{ bottom: 'lg' }}>
              <Trans>{infoText}</Trans>
            </Div>
          </GridBag.Item>
        </GridBag>

        <Div margin={{ bottom: 'sm' }}>
          <DefinitionList fiftyFifty addDividers trailingDivider>
            <DefinitionList.Item heading={t('subscriptions.generateToken.phoneNumberToTransfer')}>
              <BodyText>{subscriptionId}</BodyText>
            </DefinitionList.Item>

            <DefinitionList.Item heading={t('subscriptions.generateToken.subscriptionType')}>
              <BodyText>{subscriptionType}</BodyText>
            </DefinitionList.Item>

            <DefinitionList.Item heading={t('subscriptions.generateToken.user')}>
              <BodyText>{subscriptionName}</BodyText>
            </DefinitionList.Item>
          </DefinitionList>
        </Div>

        <Formik
          data-testid="ownership-change-token-formik"
          validateOnChange={true}
          validateOnBlur={true}
          validateOnMount={true}
          initialValues={{
            additionalNumber1: tokenForm?.additionalNumber1,
            additionalNumber2: tokenForm?.additionalNumber2,
            subscriptionTerminationDate: tokenForm?.terminationDate || ''
          }}
          validationSchema={validationSchema}
          onSubmit={(values: GenerateTokenFormValues) => submitForm(values)}
        >
          {({ isValid }: { dirty: boolean; isValid: boolean }) => (
            <Form data-testid="ownership-change-token-form">
              <GridBag>
                <GridBag.Item sm={MOBILE_COL_WIDTH} className={styles.formColumn}>
                  <Forms.Field
                    name="subscriptionTerminationDate"
                    type="date"
                    dateRange={{ startDate: minTerminationDate, endDate: maxTerminationDate }}
                    isDateDisabled={(date: Date) => isWeekend(date) || isHoliday(date)}
                    testId="omsu-subscription-termination-date"
                    label={t('subscriptions.generateToken.terminationDate')}
                    required
                    invalidDateErrorText={t('subscriptions.generateToken.invalidTerminationDate')}
                  />
                </GridBag.Item>
              </GridBag>

              <Gutter size="lg" />

              <Paragraph>
                <Trans
                  i18nKey="subscriptions.generateToken.sendTokenSmsInfo"
                  values={{ mobileNumber: subscriptionId }}
                />
              </Paragraph>

              <GridBag>
                <GridBag.Item sm={MOBILE_COL_WIDTH} md={COL_WIDTH} className={styles.formColumn}>
                  <Forms.Field
                    testId="additional-number1-input"
                    type="input"
                    archetype="phone"
                    name="additionalNumber1"
                    label={t('subscriptions.generateToken.phoneNumber')}
                  />
                </GridBag.Item>

                <GridBag.Item sm={MOBILE_COL_WIDTH} md={COL_WIDTH} className={styles.formColumn}>
                  <Forms.Field
                    testId="additional-number2-input"
                    type="input"
                    archetype="phone"
                    name="additionalNumber2"
                    label={t('subscriptions.generateToken.phoneNumber')}
                  />
                </GridBag.Item>
              </GridBag>

              <Div margin={{ top: 'xlg' }}>
                <ActionButtonGroup>
                  <Analytics whenClicked={mobile.generateOwnershipChangeToken}>
                    <Button
                      data-testid="generate-token-button"
                      disabled={!isValid}
                      type="submit"
                      size="sm"
                      variant="primary"
                    >
                      {t('subscriptions.generateToken.generateButton')}
                    </Button>
                  </Analytics>

                  <Analytics whenClicked={mobile.cancelOwnershipTokenFlow}>
                    <Button
                      data-testid="cancel-mobile-generate-token-button"
                      onClick={() =>
                        goto({
                          toCorporatePortal: '',
                          toCorporatePortalArgs: {},
                          useB2XLink: true,
                          to: preserveBackPathState(`/subscriptions/${phoneNumberHash}`, true)
                        })
                      }
                      type="button"
                      size="sm"
                      variant="tertiary-purple"
                    >
                      {t('common.cancelButton.label')}
                    </Button>
                  </Analytics>
                </ActionButtonGroup>
              </Div>
            </Form>
          )}
        </Formik>
      </DataPanel>
    </>
  );
};

export default OwnershipChangeTokenForm;
