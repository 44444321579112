import { Div, Flex, Gutter } from '@gaiads/telia-react-component-library';
import useBreakpoint from 'hooks/useBreakpoint/useBreakpoint';

const NARROWER = 0.4;
const WIDER = 0.6;

const mobileLayoutBreakpoints = ['xxxs', 'xxs', 'xs', 'sm'];

const TwoColumnLayout: React.FC<{
  children: React.ReactNode;
  additionalInfo: React.ReactNode;
}> = ({ children, additionalInfo }) => {
  const breakpoint = useBreakpoint();
  const reverseComponents = mobileLayoutBreakpoints.includes(breakpoint);

  return (
    <Flex
      pileVerticallyTo="md"
      reverseDirection={!reverseComponents}
      data-testid="two-column-layout"
    >
      <Div flexItem={{ sizeMultiplier: NARROWER }}>{additionalInfo}</Div>

      <Gutter size="sm" />

      <Div flexItem={{ sizeMultiplier: WIDER }}>{children}</Div>
    </Flex>
  );
};

export default TwoColumnLayout;
