import { TicketStatusTag } from 'B2XApp/Messages/common';
import { ListView } from 'common-components';
import { useEditableListData } from 'contexts/EditableListContext/EditableListContext';
import { EN_DASH } from 'doings/dash/dash';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { ColumnVisibility, ListViewColumnSetup } from 'types/listConfig';
import { TicketListItem } from 'types/ticket';

export const TicketsListViewColumnSetup: ListViewColumnSetup<[TFunction, boolean]> = {
  listId: 'faultReports',
  getColumns: (t: TFunction, showCompanyByDefault: boolean) => ({
    created: {
      width: '0.75x',
      heading: t('tickets.list.columns.creationDate'),
      testidSuffix: 'created',
      baseVisibility: ColumnVisibility.SHOW
    },
    updated: {
      width: '0.75x',
      heading: t('tickets.list.columns.modified'),
      testidSuffix: 'updated',
      baseVisibility: ColumnVisibility.SHOW
    },
    subject: {
      width: '2x',
      heading: t('tickets.list.columns.name'),
      testidSuffix: 'subject',
      baseVisibility: ColumnVisibility.REQUIRE
    },
    subscription: {
      heading: t('tickets.list.columns.subscription'),
      testidSuffix: 'subscription',
      baseVisibility: ColumnVisibility.SHOW
    },
    status: {
      heading: t('tickets.list.columns.status'),
      testidSuffix: 'status',
      baseVisibility: ColumnVisibility.SHOW
    },
    id: {
      heading: t('tickets.list.columns.id'),
      testidSuffix: 'id',
      baseVisibility: ColumnVisibility.REQUIRE
    },
    company: {
      heading: t('common.company.label'),
      tooltip: t('common.company.tooltip'),
      testidSuffix: 'company',
      baseVisibility: showCompanyByDefault ? ColumnVisibility.SHOW : ColumnVisibility.HIDE
    }
  })
};

const TicketsListView: React.FC<{
  tickets: TicketListItem[];
  loading?: boolean;
}> = ({ tickets, loading }) => {
  const { t } = useTranslation();
  const { columns } = useEditableListData();

  return (
    <ListView
      data-testid="tickets-list"
      columns={columns}
      mobileLayout={{
        titleRow: { col: 'subject' },
        rows: [
          { col: 'created' },
          { col: 'updated' },
          { col: 'id' },
          { col: 'subscription' },
          { col: 'company' },
          { col: 'status', noLabel: true, padding: 'xs' }
        ]
      }}
      loading={loading}
      listData={tickets}
      noItemsNotice={t('tickets.list.noItemsFound')}
    >
      {({ id, status, name, creationDate, lastUpdate, subscription, companyCode, companyName }) => (
        <ListView.Row data-testid={`tickets-list-row-item-${status}`} linkTo={`/tickets/${id}`}>
          <ListView.RowShape
            cells={{
              created: {
                data: creationDate,
                props: {}
              },
              updated: {
                data: lastUpdate,
                props: {}
              },
              subject: {
                data: name,
                props: { wordWrap: 'break-word' }
              },
              subscription: {
                data: subscription || EN_DASH,
                props: { wordWrap: 'anywhere' }
              },
              status: {
                data: <TicketStatusTag status={status} />,
                props: {}
              },
              id: {
                data: id,
                props: { wordWrap: 'anywhere' }
              },
              company: {
                data: companyCode || companyName,
                props: { tooltip: `${companyCode} ${companyName}`, wordWrap: 'ellipsis' }
              }
            }}
          />
        </ListView.Row>
      )}
    </ListView>
  );
};

export default TicketsListView;
