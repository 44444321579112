import { Div, Flex } from '@gaiads/telia-react-component-library';
import { Breakpoint } from 'common-components';
import React from 'react';

import ListView from '../ListView';
import { CellProps } from '../ListViewCell/ListViewCell';
import {
  OutputColumns,
  OutputMobileLayoutConfig,
  OutputMobileRow,
  useListViewLayout
} from '../ListViewLayout/ListViewLayoutProvider';
import MobileLayoutDataItem from '../ListViewMobileDataItem/ListViewMobileDataItem';
import MobileLayoutTitleRow from '../ListViewMobileTitleRow.tsx/ListViewMobileTitleRow';

interface ListViewRowShapeProps {
  cells: Record<string, { data: React.ReactNode; props?: CellProps }>;
  commonProps?: CellProps;
}

const ListViewRowShape: React.FC<ListViewRowShapeProps> = ({ cells, commonProps }) => {
  const { columnsInOrder, mobileLayout } = useListViewLayout();
  if (!mobileLayout) {
    return <ShapeWide columnsInOrder={columnsInOrder} cells={cells} commonProps={commonProps} />;
  }

  return (
    <>
      <Breakpoint widerThan="lg">
        <ShapeWide columnsInOrder={columnsInOrder} cells={cells} commonProps={commonProps} />
      </Breakpoint>

      <Breakpoint narrowerThan="lg">
        <ShapeNarrow mobileLayout={mobileLayout} cells={cells} />
      </Breakpoint>
    </>
  );
};

const ShapeWide: React.FC<{
  columnsInOrder: OutputColumns;
  cells: ListViewRowShapeProps['cells'];
  commonProps?: CellProps;
}> = ({ columnsInOrder, cells, commonProps }) => (
  <>
    {columnsInOrder.map(({ columnId, width }) => {
      const cell = cells[columnId] || { data: <>&nbsp;</>, props: {} };
      return (
        <ListView.Cell key={`cell-${columnId}`} {...commonProps} cellSize={width} {...cell.props}>
          {cell.data}
        </ListView.Cell>
      );
    })}
  </>
);

const ShapeNarrow: React.FC<{
  mobileLayout: OutputMobileLayoutConfig;
  cells: ListViewRowShapeProps['cells'];
}> = ({ mobileLayout, cells }) => (
  <>
    {mobileLayout.titleRow.col && (
      <MobileLayoutTitleRow>{cells[mobileLayout.titleRow.col.columnId].data}</MobileLayoutTitleRow>
    )}

    {mobileLayout.rows.map((row, index) => (
      <ShapeNarrowSubrow mobileRowLayout={row} cells={cells} key={`row-${index}`} />
    ))}
  </>
);

const ShapeNarrowSubrow: React.FC<{
  mobileRowLayout: OutputMobileRow;
  cells: ListViewRowShapeProps['cells'];
}> = ({ mobileRowLayout: { col, colsJoined, padding }, cells }) => {
  if (col) {
    const cell = cells[col.columnId];
    if (!cell) {
      return null;
    }

    return (
      <Flex centeredVertically padding={{ top: padding ?? 'zero' }}>
        {typeof cell.data === 'string' ? (
          <MobileLayoutDataItem
            key={`cell-${col.columnId}`}
            label={col.heading || ''}
            value={cell.props?.tooltip ? `${cell.props.tooltip}` : cell.data}
          />
        ) : (
          <React.Fragment key={`cell-${col.columnId}`}>{cell.data}</React.Fragment>
        )}
      </Flex>
    );
  }

  if (colsJoined) {
    if (colsJoined.every((col) => typeof cells[col.columnId] === 'undefined')) {
      return null;
    }

    return (
      <Flex centeredVertically padding={{ top: padding ?? 'zero' }}>
        {colsJoined.map((col, index, arr) => {
          const cell = cells[col.columnId];
          if (!cell) {
            return null;
          }

          return (
            <Div
              key={`cell-${col.columnId}`}
              padding={{ left: index > 0 ? 'sm' : 'zero' }}
              flexItem={index === arr.length - 1}
            >
              {cell.data}
            </Div>
          );
        })}
      </Flex>
    );
  }

  throw new Error('Either col or colsJoined must be provided');
};

export default ListViewRowShape;
