export type SupportedSizes = 'zero' | 'xxxs' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xlg' | 'xxlg';

export const getSpacingSupportedSize = (): SupportedSizes[] => [
  'zero',
  'xxxs',
  'xxs',
  'xs',
  'sm',
  'md',
  'lg',
  'xlg',
  'xxlg'
];

export type SpacingKeys = 'size' | 'vertical' | 'horizontal' | 'left' | 'right' | 'top' | 'bottom';

export const getSpacingKeys = (): SpacingKeys[] => [
  'size',
  'vertical',
  'horizontal',
  'left',
  'right',
  'top',
  'bottom'
];

export type SpacingProps = {
  padding?: { [key in SpacingKeys]?: SupportedSizes };
  margin?: { [key in SpacingKeys]?: SupportedSizes };
};

const getSpacingClasses = ({ padding, margin }: SpacingProps): string[] => {
  return [
    padding?.size ? `padding_${padding.size}_size` : undefined,
    padding?.vertical ? `padding_${padding.vertical}_vertical` : undefined,
    padding?.horizontal ? `padding_${padding.horizontal}_horizontal` : undefined,
    padding?.left ? `padding_${padding.left}_left` : undefined,
    padding?.right ? `padding_${padding.right}_right` : undefined,
    padding?.top ? `padding_${padding.top}_top` : undefined,
    padding?.bottom ? `padding_${padding.bottom}_bottom` : undefined,

    margin?.size ? `margin_${margin.size}_size` : undefined,
    margin?.vertical ? `margin_${margin.vertical}_vertical` : undefined,
    margin?.horizontal ? `margin_${margin.horizontal}_horizontal` : undefined,
    margin?.left ? `margin_${margin.left}_left` : undefined,
    margin?.right ? `margin_${margin.right}_right` : undefined,
    margin?.top ? `margin_${margin.top}_top` : undefined,
    margin?.bottom ? `margin_${margin.bottom}_bottom` : undefined
  ].filter((spacing) => !!spacing) as string[];
};

export default getSpacingClasses;
