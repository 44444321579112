import { Gutter } from '@gaiads/telia-react-component-library';
import FinvoiceDetailsBlock from 'B2XApp/Invoicing/FinvoiceDetails/FinvoiceDetailsBlock';
import { DefinitionList, Heading } from 'common-components';
import { EN_DASH } from 'doings/dash/dash';
import usePileVerticallyToMany from 'hooks/usePileVerticallyToMany/usePileVerticallyToMany';
import { useTranslation } from 'react-i18next';
import { BillMillBillingAccount } from 'types/billMillAccount';

const BillMillAccountBillingInformation: React.FC<{
  account: BillMillBillingAccount;
}> = ({ account: { billing, finvoice } }) => {
  const { t } = useTranslation();
  const pileVerticallyTo = usePileVerticallyToMany(['xxxs', 'xxs', 'md', 'lg']);
  const invoiceType = billing.invoicingType;

  const suoramaksuPaymentMethod =
    billing.paymentMethod === 'EMAIL'
      ? t('billingInformation.billingMethods.EMAIL')
      : t('billingInformation.billingMethods.PAPER');

  return (
    <>
      <DefinitionList
        pileVerticallyTo={pileVerticallyTo}
        addDividers
        fiftyFifty
        data-testid="billmill-account-billing-information"
      >
        {[
          {
            available: invoiceType !== 'SUORAMAKSU',
            heading: 'billingInformation.billingMethod',
            values: [t(`billingInformation.billingMethods.${invoiceType}`)]
          },
          {
            available: invoiceType === 'SUORAMAKSU',
            heading: 'billingInformation.billingMethod',
            values: [
              `${t('billingInformation.billingMethods.SUORAMAKSU')} (${suoramaksuPaymentMethod})`
            ]
          },
          {
            available: invoiceType === 'SUORAMAKSU' && billing.paymentMethod === 'EMAIL',
            heading: 'billingInformation.email1',
            values: [billing.email]
          },
          {
            available: invoiceType === 'SUORAMAKSU' && billing.paymentMethod === 'EMAIL',
            heading: 'billingInformation.email2',
            values: [billing.email2]
          },
          {
            available: invoiceType === 'EMAIL',
            heading: 'billingInformation.email1',
            values: [billing.email]
          },
          {
            available: invoiceType === 'EMAIL',
            heading: 'billingInformation.email2',
            values: [billing.email2]
          },
          {
            available: invoiceType === 'ELASKU',
            heading: 'billingInformation.ebillIbanOrOvt',
            values: [billing.iban]
          },
          {
            available: invoiceType === 'ELASKU',
            heading: 'billingInformation.ebillBic',
            values: [billing.bic]
          },
          {
            available: invoiceType === 'ELMA',
            heading: 'billingInformation.netOvt',
            values: [billing.ovt]
          },
          {
            available: invoiceType === 'ELMA',
            heading: 'billingInformation.netOperator',
            values: [billing.operator]
          },
          {
            heading: 'billingInformation.language',
            values: [billing.language ? t(`languages.${billing.language}`) : '']
          },
          {
            heading: 'billingInformation.smsInvoice',
            values: [billing.smsReminderMsisdn ?? EN_DASH]
          }
        ]
          .filter(({ available }) => available !== false)
          .map(({ heading, values }) => (
            <DefinitionList.MultilineItem key={heading} heading={t(heading)} values={values} />
          ))}
      </DefinitionList>

      {invoiceType !== 'EMAIL' && (
        <>
          <Gutter size="lg" />

          <Heading.H4>{t('billingInformation.finvoice.title')}</Heading.H4>

          <Gutter size="sm" />

          <FinvoiceDetailsBlock finvoice={finvoice} />
        </>
      )}
    </>
  );
};

export default BillMillAccountBillingInformation;
