import i18n from 'i18next';
import ICU from 'i18next-icu';
import { initReactI18next } from 'react-i18next';

import en from './translation/en.json';
import fi from './translation/fi.json';
import sv from './translation/sv.json';

i18n
  .use(ICU)
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    supportedLngs: ['fi', 'en', 'sv'],
    initImmediate: true,
    resources: {
      fi: {
        translation: fi
      },
      en: {
        translation: en
      },
      sv: {
        translation: sv
      }
    },
    debug: false,
    react: {
      bindI18n: 'languageChanged'
    }
  });
