import { Checkbox } from '@gaiads/telia-react-component-library';
import { CheckboxFieldProps } from 'common-components/Forms/FormField/FormField';
import { SmartTooltip } from 'common-components/SmartTooltip/SmartTooltip';
import TextWithInlineHtml from 'common-components/TextWithInlineHtml/TextWithInlineHtml';
import { useField, useFormikContext } from 'formik';
import { useMemo } from 'react';

const FormFieldCheckbox = ({
  name,
  label,
  helperText,
  required,
  disabled,
  onChange,
  testId
}: CheckboxFieldProps) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  return useMemo(
    () => (
      <Checkbox
        data-testid={testId}
        checked={field.value}
        name={field.name}
        label={
          <>
            <TextWithInlineHtml text={`${label}${required ? ' *' : ''}`} />

            {helperText && (
              <SmartTooltip tooltipContent={<TextWithInlineHtml size="sm" text={helperText} />}>
                <SmartTooltip.InfoIcon size="md" spacing="xs" />
              </SmartTooltip>
            )}
          </>
        }
        disabled={disabled}
        error={meta.touched && !!meta.error}
        description={meta.touched ? meta.error : ''}
        onBlur={field.onBlur}
        onChange={(_event, checked: boolean) => {
          setFieldValue(field.name, checked);
          onChange?.(checked);
        }}
      />
    ),

    /*
     * Only check for changes to significant properties which can change
     * between form field renders. Dependencies like `field`, `onChange` and `setFieldValue`
     * change on every render, so we need to exclude these to avoid unnecessary
     * and unperformant rerenders.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, required, disabled, field.value, meta.touched, meta.error]
  );
};

export default FormFieldCheckbox;
