export enum OrderStatus {
  Activated = 'Activated',
  Cancelled = 'Cancelled',
  Complete = 'Complete',
  InProgress = 'In Progress',
  Replaced = 'Replaced'
}

export enum OrderLineItemStatus {
  Activated = 'Activated',
  Cancelled = 'Cancelled',
  InProgress = 'In Progress',
  Pending = 'Pending',
  Superseded = 'Superseded',
  Terminated = 'Terminated'
}

export const FilterableOrderStatus = [
  OrderStatus.InProgress,
  OrderStatus.Activated,
  OrderStatus.Complete,
  OrderStatus.Replaced,
  OrderStatus.Cancelled
];

export interface OrderListItem {
  id: string;
  displayId: string;
  companyCode?: string;
  companyName?: string;
  createdDate: string;
  status: OrderStatus;
  estimatedTimeOfCompletion?: string;
  completionPercentage?: number;
  lineItems: OrderLineItem[];
  isEcommerce: boolean;
  externalId: string;
}

export interface OrderLineItem {
  productName: string;
  status: OrderLineItemStatus;
  estimatedTimeOfCompletion?: string;
  completionPercentage?: number;
  displayAddress?: string;
}
