import getClassNames from 'classnames';
import NoPrivilegesTooltip from 'common-components/Authorize/NoPrivilegesTooltip/NoPrivilegesTooltip';
import { useOverflowMenuState } from 'contexts/OverflowMenuContext/OverflowMenuContext';
import authorizeUser from 'doings/authorizeUser/authorizeUser';
import preventEventPropagation from 'doings/events/preventEventPropagation/preventEventPropagation';
import { useTranslation } from 'react-i18next';
import { AuthorizationParams } from 'types/authorization';

import styles from './OverflowMenu.module.scss';

type ClickEvent =
  | React.MouseEvent<HTMLDivElement, MouseEvent>
  | React.KeyboardEvent<HTMLDivElement>;

/**
 * A dropdown menu which open on a toggle icon. Interactible both
 * via mouse and keyboard.
 */
const OverflowMenu = ({
  'aria-label': htmlAriaLabel,
  children
}: {
  'aria-label'?: string;
  children: React.ReactNode;
}) => {
  const { t } = useTranslation();
  const { menuRef } = useOverflowMenuState();

  return (
    <div
      ref={menuRef}
      className={styles.menu}
      data-testid="overflow-menu"
      role="menu"
      aria-label={htmlAriaLabel ?? t('aria.list.overflowMenu.title')}
    >
      {children}
    </div>
  );
};

/**
 * A dropdown menu option. Interactible both via mouse and keyboard.
 * All mouse and keyboard events on overflow menu items stop immediate
 * propagation, so that the overflow menu could be rendered as part of
 * a list view, the rows which are interactible links themselves, to
 * avoid invoking a link's default behaviour.
 */
OverflowMenu.Item = ({
  label,
  onClick,
  enabledWhen,
  dataTestid,
  disabled
}: {
  label: string;
  onClick: (e: ClickEvent) => void;
  enabledWhen?: AuthorizationParams;
  dataTestid: string;
  disabled?: boolean;
}) => {
  if (enabledWhen && !authorizeUser(enabledWhen)) {
    return (
      <div
        data-testid={dataTestid}
        aria-disabled
        role="menuitem"
        className={getClassNames([styles.menuItem, styles.menuItem_disabled])}
        onClick={preventEventPropagation}
        onKeyUp={preventEventPropagation}
      >
        <NoPrivilegesTooltip
          i18nKey="common.tooltips.insufficientPermissions.forTextLink"
          showTooltip={true}
          tooltipPlacement="bottom"
          wrapper="div"
        >
          {label}
        </NoPrivilegesTooltip>
      </div>
    );
  }

  return (
    <div
      data-testid={dataTestid}
      role="menuitem"
      className={getClassNames(styles.menuItem, { [styles.menuItem_disabled]: disabled })}
      tabIndex={0}
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        preventEventPropagation(e);
        !disabled && onClick(e);
      }}
      onKeyUp={(e: React.KeyboardEvent<HTMLDivElement>) => {
        preventEventPropagation(e);
        !disabled && e.key === 'Enter' && onClick(e);
      }}
    >
      {label}
    </div>
  );
};

export default OverflowMenu;
