import { Flex } from '@gaiads/telia-react-component-library';
import { DataPanel, TileButtonGrid } from 'common-components';
import { useTranslation } from 'react-i18next';

import ContactSkeleton from './ContactSkeleton/ContactSkeleton';

const TWO_COLUMN_LAYOUT = 2;

const Contact: React.FC<{
  isLoading?: boolean;
  children: (JSX.Element | boolean | undefined)[];
}> = ({ isLoading, children }) => {
  const { t } = useTranslation();

  return (
    <DataPanel
      skeletonLoader={<ContactSkeleton />}
      loading={isLoading}
      title={t('subscriptions.actions.contact.title')}
    >
      <Flex pileVerticallyTo="md" data-testid="contact-link-container">
        <TileButtonGrid columns={{ desktop: TWO_COLUMN_LAYOUT, mobile: TWO_COLUMN_LAYOUT }}>
          {children}
        </TileButtonGrid>
      </Flex>
    </DataPanel>
  );
};

export default Contact;
