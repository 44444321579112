// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Data = any;
type ChildrenFunction = (data: string) => React.ReactNode;

const renderChildren = (children: React.ReactNode | ChildrenFunction, data?: Data) => {
  return children instanceof Function ? children(data) : children;
};

const Optional: React.FC<{
  children: React.ReactNode | ChildrenFunction;
  if?: boolean;
  data?: Data;
}> = ({ if: shouldRender, data, children }) => {
  const dataExists = Array.isArray(data) ? !!data.length : data;
  if (!shouldRender && !dataExists) {
    return null;
  }

  return renderChildren(children, data);
};

export default Optional;
