import getClassNames from 'classnames';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';

import styles from './NewsRatingsStatsMeter.module.scss';

const NewsRatingsStatsMeter: React.FC<{
  average: number;
  upvotes: number;
  votes: number;
  'aria-label': string;
  label: string;
}> = ({ average, upvotes, votes, 'aria-label': ariaLabel, label }) => {
  const meter = getVariant(average, votes);
  if (!meter) {
    return null;
  }

  return (
    <div
      data-testid="rating-meter"
      className={styles.meter}
      role="meter"
      aria-valuemin={0}
      aria-valuenow={upvotes}
      aria-valuemax={votes}
      aria-label={ariaLabel}
    >
      <CircularProgressbarWithChildren className={meter.barClasses} value={average}>
        <span className={meter.textClasses} aria-hidden>
          {label}
        </span>
      </CircularProgressbarWithChildren>
    </div>
  );
};

const POSITIVE_THRESHOLD_PERCENT = 50;
const getVariant = (average: number, votes: number) => {
  if (votes === 0) {
    return null;
  }

  return average >= POSITIVE_THRESHOLD_PERCENT
    ? {
        barClasses: getClassNames([styles.meter_bar, styles.meter_bar__positive]),
        textClasses: getClassNames([styles.meter_text, styles.meter_text__positive])
      }
    : {
        barClasses: getClassNames([styles.meter_bar, styles.meter_bar__negative]),
        textClasses: getClassNames([styles.meter_text, styles.meter_text__negative])
      };
};

export { NewsRatingsStatsMeter };
