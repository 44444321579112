import { gql } from '@apollo/client';

export const devicesQuery = gql`
  query GetDevices($page: Int!) {
    devices(page: $page, pageSize: 20) {
      page
      pages
      items {
        id
        productName
        deviceUser
      }
    }
  }
`;
