import useReadQueryParams from 'hooks/useQueryParams/useReadQueryParams';
import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownOptionDefinition } from 'types/listFilters';

export type UseBillingGroupFilterResult = ReturnType<typeof useBillingGroupListFilter>;

const getAccountTypeOptions = (t: TFunction): DropdownOptionDefinition[] => [
  { value: '', label: t('billingGroups.filter.accountType.all') },
  { value: 'mobile', label: t('billingGroups.filter.accountType.mobile') },
  { value: 'other', label: t('billingGroups.filter.accountType.other') }
];

const getBillingMethodOptions = (t: TFunction): DropdownOptionDefinition[] => [
  { value: '', label: t('billingGroups.filter.billingMethod.all') },
  { value: 'paper', label: t('billingGroups.filter.billingMethod.paper') },
  { value: 'email', label: t('billingGroups.filter.billingMethod.email') },
  { value: 'ebill', label: t('billingGroups.filter.billingMethod.eInvoiceForCompanies') },
  { value: 'operator', label: t('billingGroups.filter.billingMethod.eInvoiceToOperatorChannel') }
];

export const useBillingGroupListFilter = () => {
  const { t } = useTranslation();

  const accountTypeOptions = useMemo(() => getAccountTypeOptions(t), [t]);
  const billingMethodOptions = useMemo(() => getBillingMethodOptions(t), [t]);

  const queryParameters = useReadQueryParams(['accountType', 'billingMethod', 'search']);

  const { accountType, billingMethod, search } = queryParameters;
  const queryParametersAbsent = !accountType && !search && !billingMethod;

  return {
    accountTypeOptions,
    billingMethodOptions,
    queryParameters,
    queryParametersAbsent
  };
};
