import { OptionListOption } from '@gaiads/telia-react-component-library';
import {
  Forms,
  Heading,
  InlineNotification,
  SmartTooltip,
  TextWithInlineHtml
} from 'common-components';
import { messages } from 'doings/track/analyticsEvents';
import { trackEventWithCustomProps } from 'doings/track/trackEvent';
import { TrackFn } from 'hooks/useTrackOnStateChange/useTrackOnStateChange';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { DynamicForms } from 'types/dynamicForms';

import { useDynamicFormContext } from './DynamicFormContext';
import styles from './DynamicFormField.module.scss';

const mapDropdownOptions = (
  t: TFunction,
  field: DynamicForms.DropdownField
): OptionListOption[] => {
  const options: OptionListOption[] = [];
  if (!field.mandatory && !field.multiple) {
    options.push({
      label: t('common.dropdown.noneSelected'),
      value: ''
    });
  }

  field.options.forEach((option) =>
    options.push({
      label: option.label,
      value: option.value
    })
  );

  return options;
};

const mapDropdownPlaceholder = (
  t: TFunction,
  field: DynamicForms.DropdownField
): string | undefined => {
  if (field.multiple) {
    return field.mandatory
      ? t('common.dropdown.selectOneOrMultiple')
      : t('common.dropdown.noneSelected');
  }

  return field.mandatory ? t('common.dropdown.selectOne') : undefined;
};

export const mapDropdownTrackFn = (
  formLanguage: Language,
  formId: string,
  formLabel: string,
  field: DynamicForms.DropdownField
): TrackFn<string> | undefined => {
  if (field.name.startsWith('_')) {
    return undefined;
  }

  const optionProp = field.multiple ? 'dropdownOptions' : 'dropdownOption';
  return (newValue) =>
    trackEventWithCustomProps(messages.dynamicFormSelectOption, {
      'dynamicForm.language': formLanguage,
      'dynamicForm.label': formLabel,
      'dynamicForm.id': formId,
      'dynamicForm.dropdownName': field.name,
      'dynamicForm.dropdownLabel': field.label,
      [`dynamicForm.${optionProp}`]: newValue
    });
};

const DynamicFormField: React.FC<{
  field: DynamicForms.Field;
  onChange?: (value: string | string[] | boolean) => void;
}> = ({ field, onChange }) => {
  const { t } = useTranslation();
  const { formLanguage, form, fixedValues } = useDynamicFormContext();

  switch (field.type) {
    case 'CHECKBOX':
      return (
        <Forms.Field
          required={field.mandatory}
          key={field.id}
          name={field.id}
          label={field.label}
          helperText={field.tooltip}
          type="checkbox"
          onChange={onChange}
          testId={`dynamic-${field.id}-checkbox`}
        />
      );

    case 'CHECKGROUP':
      return (
        <Forms.Field
          required={field.mandatory}
          key={field.id}
          name={field.id}
          label={field.label}
          helperText={field.tooltip}
          type="checkgroup"
          onChange={onChange}
          options={field.options}
          testId={`dynamic-${field.id}-checkgroup`}
        />
      );

    case 'DATE':
      return (
        <Forms.Field
          required={field.mandatory}
          key={field.id}
          name={field.id}
          label={field.label}
          helperText={field.tooltip}
          type="date"
          dateRange={field.dateRange}
          allowAllDays={field.dateAllowsAllDays}
          monthOnlySelection={field.dateWithMonthOnlySelection}
          timeSelection={field.dateWithTimeSelection}
          testId={`dynamic-${field.id}-date`}
        />
      );

    case 'DROPDOWN':
      return (
        <Forms.Field
          required={field.mandatory}
          key={field.id}
          name={field.id}
          label={field.label ?? t('common.dropdown.selectValue')}
          helperText={field.tooltip}
          type={!field.multiple ? 'select' : 'multiselect'}
          onChange={onChange}
          options={mapDropdownOptions(t, field)}
          placeholder={mapDropdownPlaceholder(t, field)}
          trackOnChange={mapDropdownTrackFn(formLanguage, form.name, form.label, field)}
          testId={`dynamic-${field.id}-dropdown`}
        />
      );

    /*
     * Renders an inline notification for a dynamic form infotext.
     *
     * The infotext can contain basic inline HTML and specially formatted link
     * placeholders converted to inline links according to the user's available
     * navigational links as supported by the common component
     * `TextWithInlineHtmlExtended`.
     */
    case 'INFOTEXT':
      return (
        <InlineNotification
          variant="information"
          content={{ extendedHtml: field.label }}
          data-testid={`dynamic-${field.id}-notice`}
        />
      );

    case 'INPUT':
      return (
        <Forms.Field
          required={field.mandatory}
          key={field.id}
          name={field.id}
          label={field.label}
          helperText={field.tooltip}
          fixedValue={fixedValues[field.name]}
          type="input"
          archetype={field.format === 'POSTAL_CODE' ? 'postalCode' : 'default'}
          testId={`dynamic-${field.id}-input`}
        />
      );

    /**
     * Renders no visible output.
     */
    case 'SINK':
      return null;

    case 'SUBHEADER':
      return (
        <Heading.H5 className={styles.subheader} data-testid={`dynamic-${field.id}-subheader`}>
          {field.label}

          {field.tooltip && (
            <SmartTooltip tooltipContent={<TextWithInlineHtml size="sm" text={field.tooltip} />}>
              <SmartTooltip.InfoIcon size="md" spacing="sm" />
            </SmartTooltip>
          )}
        </Heading.H5>
      );

    case 'TEXTAREA':
      return (
        <Forms.Field
          required={field.mandatory}
          key={field.id}
          name={field.id}
          label={field.label}
          helperText={field.tooltip}
          fixedValue={fixedValues[field.name]}
          type="textarea"
          testId={`dynamic-${field.id}-textarea`}
        />
      );
  }
};

export default DynamicFormField;
