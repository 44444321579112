import { BodyText, Div } from '@gaiads/telia-react-component-library';
import { CopyButton, SmartTooltip } from 'common-components';
import { Flex } from 'core-components';
import { useTranslation } from 'react-i18next';

import styles from './PukCode.module.scss';

const PukCode: React.FC<{
  title: string;
  code: string;
  tooltipKey?: string;
  onCopy?: VoidFunction;
  'data-testid': string;
}> = ({ title, code, tooltipKey, onCopy, 'data-testid': testId }) => {
  const { t } = useTranslation();

  return (
    <Div flexItem data-testid={testId}>
      <BodyText fontWeight="medium" inlineBlock tagName="div" data-testid="puk-code-title">
        <Flex centeredVertically>
          {title}

          {!!tooltipKey && (
            <SmartTooltip tooltipContent={t(tooltipKey)} arrangement="top" wrapper="span">
              <SmartTooltip.InfoIcon size="sm" spacing="sm" data-testid="puk-code-tooltip" />
            </SmartTooltip>
          )}
        </Flex>
      </BodyText>

      <Flex margin={{ top: 'xs' }} occupyVerticalSpace={false}>
        <BodyText
          className={styles.pukCode}
          margin={{ right: 'xs' }}
          tagName="div"
          data-testid="puk-code"
        >
          {code}
        </BodyText>

        <CopyButton value={code} onCopy={onCopy} data-testid="copy-button" />
      </Flex>
    </Div>
  );
};

export default PukCode;
