import { Gutter } from '@gaiads/telia-react-component-library';
import { Skeleton } from 'common-components';

const BillingGroupDetailsSubscriptionListSkeleton: React.FC = () => {
  return (
    <Skeleton.Panel>
      <Skeleton.Title width="50%" />

      <Gutter size="xs" />

      <Skeleton.TextRow />

      <Gutter size="xs" />

      <Skeleton.TextRow />

      <Gutter size="xs" />

      <Skeleton.TextRow />

      <Gutter size="xs" />

      <Skeleton.TextRow />
    </Skeleton.Panel>
  );
};

export default BillingGroupDetailsSubscriptionListSkeleton;
