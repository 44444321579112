import { InlineNotification } from 'common-components';

export const DisabledNotice: React.FC = () => (
  <InlineNotification
    title={{ key: 'subscriptions.subscriptionDetails.status.disabledTitle' }}
    content={{ key: 'subscriptions.subscriptionDetails.status.disabledMessage' }}
    variant="information"
    data-testid="disabled-notification"
  />
);
