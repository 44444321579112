import { IconName } from '@teliafi/fi-ds';
import { AnalyticsEvent } from 'doings/track/analyticsEvents';

export type TourActiveGroupType = ActiveGroupType | 'any';
export type TourTrigger = 'automatic' | 'automatic-watched' | 'explicit';

export interface TourDescription {
  id: string;
  group: TourActiveGroupType;
  trigger: TourTrigger;
  intro?: {
    title: string;
    description: string;
  };
  stops: TourStopDescription[];
}

export interface TourStopDescription {
  id: string;
  elementId: string;
  extraHighlightSelector?: string;
  dialogArrangement: DialogArrangement;
  title: string;
  description: string;
  icon?: IconName;
  tag?: 'new';
  at?: string;
}

export enum TourActionType {
  REGISTER_TOURS,
  START,
  CONTINUE,
  FINISH,
  SKIP,
  NAVIGATED,
  MARKED_AS_SEEN,
  GA_EVENTS_TRACKED,
  RESET
}

export type TourAction =
  | {
      type: Exclude<TourActionType, TourActionType.REGISTER_TOURS>;
    }
  | {
      type: TourActionType.REGISTER_TOURS;
      tours: TourDescription[];
      startedAt: string;
    };

export enum TourGuideStatus {
  REGISTERING,
  READY_TO_START,
  STARTED
}

export interface TourState {
  status: TourGuideStatus;
  nextTours: TourDescription[];
  currentTour: TourDescription | undefined;
  currentTourStopAt: number;
  markingSeen: string | undefined;
  navigateTo: string | undefined;
  startedAt: string | undefined;
  tracking: AnalyticsEvent[];
}

export type DialogArrangement = 'top' | 'bottom';

export type DialogPlacement =
  | 'topStart'
  | 'topCenter'
  | 'topEnd'
  | 'bottomStart'
  | 'bottomCenter'
  | 'bottomEnd';

export type DialogPositioning = {
  x: string;
  y: string;
  arrangement: DialogArrangement | undefined;
  placement: DialogPlacement | undefined;
  clip: string | undefined;
};
