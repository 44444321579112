import { Div, Flex, GridBag, Gutter, MarginSpace } from '@gaiads/telia-react-component-library';
import { BackButton, Heading, MobilePadding, RouteBreadcrumb, Skeleton } from 'common-components';
import { useTranslation } from 'react-i18next';
import { Device } from 'types/device';

import AdditionalServices from './AdditionalServices/AdditionalServices';
import ContractInfo from './ContractInfo/ContractInfo';
import MainInfo from './MainInfo/MainInfo';

const DeviceDetailsPage: React.FC<{
  device?: Device;
  loading: boolean;
  hasError: boolean;
}> = ({ device, loading, hasError }) => {
  const { t } = useTranslation();

  return (
    <Div data-testid="device-details" padding={{ vertical: 'lg' }}>
      <MarginSpace noPaddingForSmallerThanSm>
        <MobilePadding>
          <RouteBreadcrumb data-testid="device-details-breadcrumb" />

          <Flex margin={{ top: 'xlg', bottom: 'md' }}>
            <BackButton
              data-testid="device-details-back-button"
              label={t('common.backButton.label')}
            />
          </Flex>

          <Div margin={{ bottom: 'md' }}>
            {loading ? (
              <Skeleton.Title width="50%" />
            ) : (
              <Heading.H2>{t('daas.details.title')}</Heading.H2>
            )}
          </Div>
        </MobilePadding>

        <GridBag>
          <GridBag.Item md={6} sm={12}>
            <Div data-testid="device-details-name-panel">
              <MainInfo
                data-testid="device-details-main-info"
                device={device}
                loading={loading}
                hasError={hasError}
              />
            </Div>

            <Gutter size="sm" />

            <AdditionalServices device={device} loading={loading} />
          </GridBag.Item>

          <GridBag.Item md={6} sm={12}>
            <ContractInfo
              data-testid="device-details-contract-info"
              device={device}
              loading={loading}
              hasError={hasError}
            />
          </GridBag.Item>
        </GridBag>
      </MarginSpace>
    </Div>
  );
};

export default DeviceDetailsPage;
