import { ApolloError } from '@apollo/client';

const isMissingActiveCompanyError = (errorObject?: ApolloError): boolean => {
  const errors = errorObject?.graphQLErrors ?? [];

  return errors.some(
    (error) =>
      error.extensions?.code === 'FORBIDDEN' && error.extensions?.redirectToCompanySelection
  );
};

export default isMissingActiveCompanyError;
