import { differenceInMonths } from 'date-fns';

type Params = {
  dates: Date | Date[];
  difference: number;
};

export const datesAreOlderThanTodayInMonths = ({ dates, difference }: Params) => {
  const today = new Date();

  if (Array.isArray(dates)) {
    return dates.some((date) => differenceInMonths(today, date) >= difference);
  }
  return differenceInMonths(today, dates) >= difference;
};
