export type CompanyCaseListTotalResult = {
  total: number;
};

export type CompanyCaseListResult = {
  paging: CompanyCaseListPaging;
  items: CompanyCaseListItem[];
  available: boolean;
} & CompanyCaseListTotalResult;

export type CompanyCaseListPaging = {
  page: number;
  pageSize: number;
  pageCount: number;
};

export type CompanyCaseListItem = {
  caseId: number;
  multibellaGui: number;
  reportedDate: string;
  updatedDate: string;
  title: string;
  status: CaseStatus;
  caseTypeId: string;
  closedDate: string | null;
  reporter: string;
  unread: boolean;
  companyCode: string;
  companyName?: string;
};

export type CaseStatus = 'new' | 'inProgress' | 'waitingForCustomer' | 'closed' | 'cancelled';

export const CaseStatuses: CaseStatus[] = [
  'new',
  'inProgress',
  'waitingForCustomer',
  'closed',
  'cancelled'
];

export const isCaseStatus = (input: any): input is CaseStatus =>
  typeof input === 'string' && CaseStatuses.includes(input as CaseStatus);

export enum CaseSortField {
  REPORTED_DATE = 'reportedDate',
  UPDATED_DATE = 'updatedDate',
  ID = 'multibellaGui'
}
