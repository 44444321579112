import { gql } from '@apollo/client';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { AvailableOperator } from 'types/collection-group';

export const availableOperatorsQuery = gql`
  query GetAvailableOperators {
    availableOperators {
      items {
        code
        name
      }
    }
  }
`;

interface AvailableOperatorsResponse {
  availableOperators: {
    items: AvailableOperator[];
  };
}

const useAvailableOperators = () => {
  const { data, error, loading } = useCallBackend<AvailableOperatorsResponse>({
    query: availableOperatorsQuery
  });

  return {
    availableOperators: data?.availableOperators.items ?? [],
    loading,
    error
  };
};

export default useAvailableOperators;
