import BreadcrumbItem from 'common-components/Breadcrumb/BreadcrumbItem/BreadcrumbItem';
import Link from 'common-components/Link/Link';
import { useConfirmationGuard } from 'hooks/useCustomModal/useConfirmationGuard';
import useSearchParams from 'hooks/useSearchParams/useSearchParams';
import React from 'react';

import styles from './BreadcrumbContainer.module.scss';

export type BreadcrumbProps = {
  label: string;
  path: string;
  hideBreadcrumb?: boolean;
};

const BreadcrumbContainer: React.FC<{ items: BreadcrumbProps[] }> = ({ items }) => {
  const { onGuardedClick } = useConfirmationGuard();
  const { preserveBackPathState } = useSearchParams();
  if (items.length <= 1) {
    return null;
  }

  if (items[items.length - 1].hideBreadcrumb) {
    return (
      <div
        data-testid="breadcrumb-container-hidden-breadcrumb"
        className={styles.absentBreadcrumb}
      ></div>
    );
  }

  return (
    <div className={styles.breadcrumbContainer} data-testid="breadcrumb-container">
      {items.map(({ path, label }, index) => {
        const isLastBreadcrumbItem = index === items.length - 1;
        const isFirstBreadcrumbItem = index === 0;
        const to = isFirstBreadcrumbItem ? path : preserveBackPathState(path, true);

        return (
          <React.Fragment key={path}>
            {isLastBreadcrumbItem ? (
              <BreadcrumbItem label={label} />
            ) : (
              <Link to={to} tabIndex={0} onClick={(e) => onGuardedClick(e, path)}>
                <BreadcrumbItem label={label} home={isFirstBreadcrumbItem} showArrow />
              </Link>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default BreadcrumbContainer;
