import ErrorBoundary from 'B2XApp/ErrorBoundary/ErrorBoundary';
import NotFound from 'B2XApp/Errors/NotFound/NotFound';
import RouteAccessProtector from 'B2XApp/RouteAccessProtector/RouteAccessProtector';
import RouteBasedPageTitle from 'B2XApp/RouteBasedPageTitle/RouteBasedPageTitle';
import useRoutes from 'hooks/useRoutes/useRoutes';
import { Route, Switch } from 'react-router-dom';

const B2XAppRoutes: React.FC = () => {
  const routes = useRoutes();

  return (
    <Switch>
      {routes.map(({ path, Component, isExact, accessibleWhen, emailAccessList }) => (
        <Route path={path} exact={isExact} key={path}>
          <RouteAccessProtector authParams={accessibleWhen} emailAccessList={emailAccessList}>
            <RouteBasedPageTitle>
              <ErrorBoundary>
                <Component />
              </ErrorBoundary>
            </RouteBasedPageTitle>
          </RouteAccessProtector>
        </Route>
      ))}

      <Route data-testid="not-found-route">
        <NotFound data-testid="app-routes-not-found" />
      </Route>
    </Switch>
  );
};

export default B2XAppRoutes;
