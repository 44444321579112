import useReadQueryParams from 'hooks/useQueryParams/useReadQueryParams';
import { useToggleStateWithQueryParameter } from 'hooks/useToggleState/useToggleState';
import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownOptionDefinition, SearchOptionDefinition } from 'types/listFilters';

export type InvoiceSearchParameter = {
  invoiceNumber?: string;
  userName?: string;
  msisdn?: string;
  collectionGroup?: string;
};

export type InvoiceFilterParameters = {
  scope?: string;
  search?: string;
  status?: string;
  type?: string;
  from?: string;
  to?: string;
};

const getSearchFieldOptions = (t: TFunction): SearchOptionDefinition[] =>
  ['invoiceNumber', 'userName', 'msisdn', 'collectionGroup'].map((value) => ({
    value,
    label: t(`invoices.searchOptions.${value}`)
  }));

export const getInvoiceStatusOptions = (t: TFunction): DropdownOptionDefinition[] =>
  ['open', 'closed'].map((value) => ({
    value,
    label: t(`invoices.status.${value}`)
  }));

export const getInvoiceTypeOptions = (t: TFunction): DropdownOptionDefinition[] =>
  sortByLabelAsc(
    ['INVOICE_DIVIDER', 'BROADBAND', 'MOBILE', 'SERVICE_PROVIDER', 'FIXED_LINE'].map((value) => ({
      value,
      label: t(`invoices.types.${value}`)
    }))
  );

const sortByLabelAsc = (
  items: { value: string; label: string }[]
): { value: string; label: string }[] => [...items].sort((a, b) => a.label.localeCompare(b.label));

export default (initialAllCompanies: boolean) => {
  const { t } = useTranslation();
  const searchFieldOptions = useMemo(() => getSearchFieldOptions(t), [t]);
  const invoiceTypeOptions = useMemo(() => getInvoiceTypeOptions(t), [t]);
  const invoiceStatusOptions = useMemo(() => getInvoiceStatusOptions(t), [t]);

  const queryParameters: InvoiceFilterParameters = useReadQueryParams([
    'scope',
    'search',
    'status',
    'type',
    'from',
    'to',
    'allCompanies'
  ]);

  const { search, status, type, from, to } = queryParameters;
  const queryParametersAbsent = !(!!search || !!status || !!type || !!from || !!to);

  const [showFromAllCompanies, toggleAllCompanies] = useToggleStateWithQueryParameter(
    initialAllCompanies,
    'allCompanies'
  );

  return {
    queryParameters,
    queryParametersAbsent,
    searchFieldOptions,
    invoiceTypeOptions,
    invoiceStatusOptions,
    allCompanySearch: {
      showFromAllCompanies,
      toggleAllCompanies
    }
  };
};
