import { Page } from '@gaiads/telia-react-component-library';
import { PageWithSubnavigation } from 'common-components';
import EmptyListContent from 'common-components/EmptyListContent/EmptyListContent';
import ListHeader from 'common-components/ListHeader/ListHeader';
import ShowMoreResults from 'common-components/ShowMoreResults/ShowMoreResults';
import { EditableListProvider } from 'contexts/EditableListContext/EditableListContext';
import removeEmptyFields from 'doings/removeEmptyFields/removeEmptyFields';
import { useSubscriptionFilters } from 'hooks/mobileSubscriptions/useSubscriptionFilters/useSubscriptionFilters';
import useSubscriptions from 'hooks/mobileSubscriptions/useSubscriptions/useSubscriptions';
import { ViewStatus } from 'hooks/useApiStatus/useApiStatus';
import useInitialLoadStatus from 'hooks/useInitialLoadStatus/useInitialLoadStatus';
import usePersistedSortableHeadings from 'hooks/useSortableHeadings/usePersistedSortableHeadings';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'types/sort';
import { SortableColumns } from 'types/subscription';

import { ExcelDownloadLink } from './ExcelDownloadLink/ExcelDownloadLink';
import { NewSubscriptionButton } from './NewSubscriptionButton/NewSubscriptionButton';
import SortDropdown from './SubscriptionsList/SortDropdown/SortDropdown';
import SubscriptionsListView, {
  SubscriptionsListViewColumnSetup
} from './SubscriptionsList/SubscriptionsListView';
import SubscriptionsListActionButtons from './SubscriptionsListActionButtons/SubscriptionsListActionButtons';

const Subscriptions: React.FC = () => {
  const { t } = useTranslation();
  const {
    searchFieldOptions,
    subscriptionTypeOptions,
    queryParameters,
    queryParametersAbsent,
    searchTermValid
  } = useSubscriptionFilters();

  const sortableColumns = [
    SortableColumns.userName,
    SortableColumns.id,
    SortableColumns.subscriptionTypeName
  ];

  const { sortState, sortClick, onSortChange, currentSort } = usePersistedSortableHeadings(
    sortableColumns,
    {
      sort: SortableColumns.userName,
      order: SortOrder.ASC
    }
  );

  const {
    subscriptions: { total, items },
    error,
    fetchMore,
    hasMoreResults,
    apiStatus: viewStatus
  } = useSubscriptions(
    currentSort,
    removeEmptyFields({
      [queryParameters.scope ?? '']: queryParameters.search,
      subscriptionTypeName: queryParameters.type
    }),
    !searchTermValid
  );

  const apiStatus = useInitialLoadStatus(viewStatus);

  return (
    <EditableListProvider
      columnSetup={SubscriptionsListViewColumnSetup}
      invocationArgs={[t, sortState, sortClick]}
      changeableArgs={[undefined, sortState, undefined]}
    >
      <PageWithSubnavigation
        loading={apiStatus === ViewStatus.LoadingInitial || apiStatus === ViewStatus.Initial}
        error={apiStatus === ViewStatus.Error ? error : undefined}
        emptyContentPlaceholder={
          apiStatus === ViewStatus.Empty &&
          queryParametersAbsent && (
            <EmptyListContent
              title={t('subscriptions.list.noSubscriptionsHeading')}
              description={t('subscriptions.list.manageSubscriptions')}
              footerContent={<NewSubscriptionButton />}
            />
          )
        }
        showSubnavigationForCurrentPath
      >
        <ListHeader
          titleTestid="subscriptions-count"
          searchScopeOptions={searchFieldOptions}
          actionButtons={<SubscriptionsListActionButtons />}
          typeFilterParams={{
            label: t('subscriptions.filterByType'),
            options: subscriptionTypeOptions,
            'data-testid': 'subscription-types-dropdown'
          }}
          labels={{
            searchInMobileView: t('subscriptions.searchSubscriptions'),
            sortInMobileView: t('subscriptions.sortSubscriptions')
          }}
          sortDropdown={
            <SortDropdown
              data-testid="subscriptions-list-sort-dropdown"
              fields={sortableColumns}
              currentSort={currentSort}
              onChange={onSortChange}
            />
          }
          excelDownloadLink={
            <ExcelDownloadLink
              queryParameters={queryParameters}
              sortParameter={currentSort}
              disabled={
                items.length === 0 ||
                apiStatus === ViewStatus.Loading ||
                apiStatus === ViewStatus.LoadingInitial
              }
            />
          }
        >
          {t('subscriptions.numberOfSubscriptions', {
            numberOfSubscriptions: total
          })}
        </ListHeader>

        <Page.Row responsiveFullWidth>
          <SubscriptionsListView subscriptions={items} loading={apiStatus === ViewStatus.Loading} />
        </Page.Row>

        {apiStatus !== ViewStatus.Loading && (
          <Page.Row>
            <ShowMoreResults
              loading={apiStatus === ViewStatus.LoadingMore}
              data-testid="show-more-subscriptions"
              hasMoreResults={hasMoreResults}
              hasResults={!!items.length}
              fetchMore={fetchMore}
            />
          </Page.Row>
        )}
      </PageWithSubnavigation>
    </EditableListProvider>
  );
};

export default Subscriptions;
