import { TeliaLink } from 'common-components';
import isDemoEnvironment from 'doings/isDemoEnvironment/isDemoEnvironment';
import { joinURLSearchParams } from 'doings/links/joinURLSearchParams/joinURLSearchParams';
import { prepareQueryParameters } from 'hooks/invoicing/invoices/useInvoices/useInvoices';
import { InvoiceFilterParameters } from 'hooks/invoicing/invoices/useInvoices/useInvoicesFilters/useInvoicesFilter';
import { useTranslation } from 'react-i18next';
import { SortOrder, SortParameter } from 'types/sort';

export type InvoiceExcelDownloadLinkProps = {
  sortParameters: SortParameter;
  queryParameters: InvoiceFilterParameters;
  showFromAllCompanies: boolean;
  disabled: boolean;
};

export const ExcelDownloadLink: React.FC<InvoiceExcelDownloadLinkProps> = ({
  sortParameters,
  queryParameters,
  showFromAllCompanies,
  disabled
}) => {
  const { t } = useTranslation();
  const excelDownloadDisabled = isDemoEnvironment() || disabled;
  const filter = new URLSearchParams(prepareQueryParameters(queryParameters)).toString();

  const invoiceListMeta = {
    types: {
      BM_REWRITTEN: t('invoices.types.BM_REWRITTEN'),
      BROADBAND: t('invoices.types.BROADBAND'),
      COMBINED: t('invoices.types.COMBINED'),
      CORPORATE_SOLUTIONS: t('invoices.types.CORPORATE_SOLUTIONS'),
      FIXED_LINE: t('invoices.types.FIXED_LINE'),
      INVOICE_DIVIDER: t('invoices.types.INVOICE_DIVIDER'),
      MOBILE: t('invoices.types.MOBILE'),
      MS_PAPER_INVOICE: t('invoices.types.MS_PAPER_INVOICE'),
      MULTIBUYER: t('invoices.types.MULTIBUYER'),
      PAPERLESS: t('invoices.types.PAPERLESS'),
      SERVICE_PROVIDER: t('invoices.types.SERVICE_PROVIDER')
    },
    statuses: {
      closed: t('invoices.status.closed'),
      open: t('invoices.status.open'),
      unknown: t('invoices.status.other'),
      overdue: t('invoices.status.overdue'),
      upcoming: t('invoices.status.upcoming')
    },
    sorting: {
      sort: sortParameters.sort ?? 'invoiceDate',
      order: sortParameters.order ?? SortOrder.DESC
    },
    showFromAllCompanies
  };

  const queryParams = joinURLSearchParams('?', {
    columns: [
      t('invoices.list.columns.type'),
      t('invoices.list.columns.invoiceDate'),
      t('invoices.list.columns.dueDate'),
      t('invoices.list.columns.id'),
      t('invoices.list.columns.collectionGroup'),
      t('invoices.list.columns.referenceNumber'),
      t('invoices.list.columns.status'),
      `${t('invoices.list.columns.total')} €`,
      t('common.companyCode.label'),
      t('common.company.label')
    ]
      .map((column) => column.replace(/\u00AD/g, ''))
      .join(','),
    filter,
    invoicesMeta: btoa(encodeURIComponent(JSON.stringify(invoiceListMeta))),
    sheetTitle: t('invoices.title')
  });

  return (
    <TeliaLink.Download
      label={t('common.excelDownload.label')}
      to={`/api/invoices/listAsExcel${queryParams}`}
      iconLeft="download"
      data-testid="invoice-list-as-excel-link"
      disabled={excelDownloadDisabled}
      downloadWithFetch
      useFileNameFromResponse
    />
  );
};
