import { gql } from '@apollo/client';
import {
  EditableSection,
  SectionItem,
  sectionItemsQuery
} from 'hooks/editableSections/useEditableSectionItems';
import useCallBackendMutation from 'hooks/useCallBackend/useCallBackendMutation';

export const updateEditableSectionItemsMutation = gql`
  mutation UpdateEditableSectionItems($section: String!, $updateData: UpdateData!) {
    updateSectionItems(section: $section, updateData: $updateData)
  }
`;

type UpdateEditableSectionItemsResponse = {
  updateEditableSectionItems: boolean;
};

const useUpdateEditableSectionItems = (section: EditableSection) => {
  const { runMutation, loading, error } =
    useCallBackendMutation<UpdateEditableSectionItemsResponse>(updateEditableSectionItemsMutation, [
      {
        query: sectionItemsQuery,
        variables: {
          section
        }
      }
    ]);

  const updateSectionItems = (items: SectionItem[]) => {
    runMutation({
      variables: {
        section,
        updateData: {
          items: items.map((item) => ({
            id: item.id,
            enabled: item.enabled ?? false
          }))
        }
      }
    });
  };

  return {
    updateSectionItems,
    updating: loading,
    error
  };
};

export default useUpdateEditableSectionItems;
