import { List } from '@gaiads/telia-react-component-library';
import wrapEachComponentWithKey from 'doings/wrapEachComponentWithKey/wrapEachComponentWithKey';

import BackToTopButton from './BackToTopButton/BackToTopButton';
import ListViewCell from './ListViewCell/ListViewCell';
import ListViewDivider from './ListViewDivider/ListViewDivider';
import { ListViewHeader } from './ListViewHeader/ListViewHeader';
import {
  ListViewLayoutProps,
  ListViewLayoutProvider
} from './ListViewLayout/ListViewLayoutProvider';
import ListViewLoader from './ListViewLoader/ListViewLoader';
import ListViewNoItemsNotice from './ListViewNoItemsNotice/ListViewNoItemsNotice';
import ListViewRow from './ListViewRow/ListViewRow';
import ListViewRowShape from './ListViewRowShape/ListViewRowShape';

type ListViewProps<T> = {
  listRef?: React.RefObject<HTMLDivElement>;
  columns?: ListViewLayoutProps['columns'];
  mobileLayout?: ListViewLayoutProps['mobileLayout'];
  listData: readonly T[];
  loading?: boolean;
  usesQuicklinks?: boolean;
  hasExpandableItems?: boolean;
  noItemsNotice?: string;
  children: (props: T) => React.ReactNode;
  'data-testid'?: string;
};

const renderRows = <T,>(children: (props: T) => React.ReactNode, listData: readonly T[]) =>
  wrapEachComponentWithKey(listData.map(children));

function ListView<T>({
  listRef,
  columns = {},
  mobileLayout,
  listData,
  loading = false,
  usesQuicklinks = false,
  hasExpandableItems,
  noItemsNotice,
  children,
  'data-testid': dataTestid
}: Readonly<ListViewProps<T>>) {
  if (!loading && listData.length <= 0 && !!noItemsNotice) {
    return <ListViewNoItemsNotice message={noItemsNotice} />;
  }

  return (
    <List refElement={listRef} data-testid={dataTestid} data-tour-stop="list-view">
      <ListViewLayoutProvider columns={columns} mobileLayout={mobileLayout}>
        {columns && (
          <ListViewHeader hasExpandableItems={hasExpandableItems} usesQuicklinks={usesQuicklinks} />
        )}

        {loading ? (
          <ListViewLoader
            data-testid="list-view-loader"
            usesQuicklinks={usesQuicklinks}
            usesExpandableArrow={hasExpandableItems}
          />
        ) : (
          <>
            {renderRows<T>(children, listData)}

            <BackToTopButton />
          </>
        )}
      </ListViewLayoutProvider>
    </List>
  );
}

ListView.Divider = ListViewDivider;
ListView.Row = ListViewRow;
ListView.RowShape = ListViewRowShape;
ListView.Cell = ListViewCell;
ListView.NoItemsNotice = ListViewNoItemsNotice;

export default ListView;
