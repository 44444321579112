import formatDatetime, { DateFormats } from 'doings/formatDatetime/formatDatetime';
import { DateFields } from 'types/ticket';

export const mapTicketDatetimes = <T>(data: T & DateFields): T & DateFields => ({
  ...data,
  creationDate: formatDatetime(data.creationDate, DateFormats.DATE_TIME_NATIONAL_SHORT),
  lastUpdate: formatDatetime(data.lastUpdate, DateFormats.DATE_TIME_NATIONAL_SHORT)
});

export default mapTicketDatetimes;
