import { BodyText, Div, Flex, Icon, List } from '@gaiads/telia-react-component-library';
import { DashboardSectionLink } from 'B2XApp/Dashboard/DashboardNavigation/DashboardSections/DashboardSectionLink';
import { CollapsibleBlock } from 'common-components';
import { NavigationalLink } from 'doings/getNavigationalLinks/getNavigationalLinks';
import isCorporatePortalOrUlmUrl from 'doings/isCorporatePortalOrUlmUrl/isCorporatePortalOrUlmUrl';
import { useTranslation } from 'react-i18next';

import styles from './DashboardSection.module.scss';
import { UserSectionDefinition } from './sections/sectionDefinition';

const isNested = (link: NavigationalLink, section: UserSectionDefinition) =>
  !!link.nestUnder && !!section.links.some((presentLink) => presentLink.id === link.nestUnder);

export const DashboardSectionCollapsible = ({ section }: { section: UserSectionDefinition }) => {
  const { t } = useTranslation();

  return (
    <CollapsibleBlock
      blockIcon={section.icon}
      blockTitle={t(section.titleTranslationKey)}
      largeTitle
      className={styles.collapsibleBlock}
      expanded={section.expanded}
      padding={{ size: 'sm' }}
    >
      <CollapsibleBlock.Content padding={{ size: 'sm', top: 'xxxs' }}>
        <List>
          {section.links.map((link) => (
            <List.Item key={`dashboard-section-link--${link.id}`} className={styles.listItem}>
              <DashboardSectionLink
                sectionLink={link}
                isNested={isNested(link, section)}
                data-testid={`section-${link.id}`}
              >
                <Flex centeredVertically>
                  <BodyText className={styles.listItem_linkText} tagName="div">
                    {t(link.textI18nKey)}

                    {link.external && !isCorporatePortalOrUlmUrl(link.to) && (
                      <Div
                        style={{
                          display: 'inline-block',
                          verticalAlign: 'bottom'
                        }}
                        margin={{ left: 'xs' }}
                      >
                        <Icon
                          color="purple500"
                          name="external"
                          size="sm"
                          className={styles.listItem_linkIcon}
                        />
                      </Div>
                    )}
                  </BodyText>
                </Flex>
              </DashboardSectionLink>
            </List.Item>
          ))}
        </List>
      </CollapsibleBlock.Content>
    </CollapsibleBlock>
  );
};
