import { gql } from '@apollo/client';
import useCallBackendMutation from 'hooks/useCallBackend/useCallBackendMutation';
import { EditableListId } from 'types/listConfig';

import { EditableList, EditableListColumn, editableListQuery } from './useEditableList';

export const updateEditableListMutation = gql`
  mutation UpdateEditableList($listId: EditableListId!, $updateData: UpdateListConfiguration!) {
    updateListConfiguration(listId: $listId, configuration: $updateData) {
      id
      columns {
        id
        enabled
        __typename @skip(if: true)
      }
    }
  }
`;

type UpdateEditableListResponse = {
  updateListConfiguration: EditableList;
};

const useUpdateEditableList = (listId: EditableListId) => {
  const { runMutation, loading, error } = useCallBackendMutation<UpdateEditableListResponse>(
    updateEditableListMutation,
    [{ query: editableListQuery, variables: { listId } }]
  );

  const updateEditableList = (columns: EditableListColumn[]) => {
    runMutation({
      variables: {
        listId,
        updateData: {
          columns: columns.map(({ id, enabled = false }) => ({ id, enabled }))
        }
      }
    });
  };

  return {
    updateEditableList,
    updating: loading,
    error
  };
};

export default useUpdateEditableList;
