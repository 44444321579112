import { BodyText, Div, GridBag } from '@gaiads/telia-react-component-library';
import { Heading, TeliaLink } from 'common-components';
import getEnvironmentVariable from 'doings/getEnvironmentVariable/getEnvironmentVariable';
import { devices } from 'doings/track/analyticsEvents';
import useFavoriteDevices from 'hooks/devices/useFavoriteDevices/useFavoriteDevices';
import { useTranslation } from 'react-i18next';
import { FavoriteDevicesProduct } from 'types/device';

import { FavoriteDevicesSkeleton } from './FavoriteDevicesSkeleton';
import { FavoriteDeviceTile } from './FavoriteDeviceTile';

const MAX_NUMBER_OF_SHOWN_DEVICES = 4;

export const FavoriteDevices: React.FC = () => {
  const { t } = useTranslation();
  const { loading, favoriteDevices, error } = useFavoriteDevices();

  if (loading) {
    return <FavoriteDevicesSkeleton />;
  }

  if (error || !favoriteDevices?.products.length) {
    return null;
  }

  const shownProducts: FavoriteDevicesProduct[] = favoriteDevices?.products?.slice(
    0,
    MAX_NUMBER_OF_SHOWN_DEVICES
  );

  return (
    <>
      <Div margin={{ bottom: 'xlg' }} />

      <Heading.H2>{t('daas.favoriteDevices.title')}</Heading.H2>

      <BodyText tagName="div" margin={{ top: 'xs', bottom: 'md' }} block>
        {t('daas.favoriteDevices.description')}
      </BodyText>

      <Div margin={{ bottom: 'sm' }}>
        <GridBag>
          {shownProducts.map((product) => (
            <GridBag.Item key={product.id} xxxs={6} md={3} xxlg={2}>
              <FavoriteDeviceTile product={product} />
            </GridBag.Item>
          ))}
        </GridBag>
      </Div>

      <TeliaLink.External
        data-testid="devices-view-favorite-devices"
        variant="standalone"
        to={getEnvironmentVariable('REACT_APP_LINK_TO_ECOMMERCE')}
        iconRight="external"
        label={t('daas.favoriteDevices.link')}
        track={devices.viewFavoriteDevices}
      />
    </>
  );
};
