import { Icon, Paragraph } from '@teliafi/fi-ds';
import getClassNames from 'classnames';
import { Heading } from 'common-components';
import Link from 'common-components/Link/Link';
import { Div, Divider, Flex } from 'core-components';
import { EN_DASH } from 'doings/dash/dash';
import join from 'doings/join/join';
import React from 'react';

import styles from './CompactList.module.scss';

type CompactListHeading = {
  heading: string;
  testId: string;
};

type CompactListProps = {
  'data-testid'?: string;
  title?: string;
  headings: CompactListHeading[];
  fullWidthDivider?: boolean;
  children: React.ReactNode;
};

type CompactListRowProps = {
  linkTo?: string;
  children: React.ReactNode;
  'data-testid'?: string;
};

type CompactListItemProps = {
  title: string;
  text?: string;
  'data-testid'?: string;
};

type CompactListComponent = React.FC<CompactListProps> & {
  Item: React.FC<CompactListItemProps>;
  Row: React.FC<CompactListRowProps>;
};

const CompactList: CompactListComponent = ({
  'data-testid': dataTestid,
  title,
  headings,
  children,
  fullWidthDivider = false
}) => {
  const fullWidth = 100;
  const defaultWidth = 95;
  const dividerWidth = fullWidthDivider ? fullWidth : defaultWidth;

  return (
    <Div data-testid={dataTestid}>
      {!!title && (
        <Div padding={{ bottom: 'md' }}>
          <Heading.H4>{title}</Heading.H4>
        </Div>
      )}

      <Flex centeredVertically responsiveHidden={{ thinnerThan: 'lg' }} padding={{ size: 'xs' }}>
        {headings.map((heading) => (
          <Flex flexItem={{ sizeMultiplier: 1 }} key={heading.testId}>
            <Paragraph data-testid={heading.testId}>
              <b>{heading.heading}</b>
            </Paragraph>
          </Flex>
        ))}

        <Flex centeredVertically alignRight>
          <Icon name="chevron-right" size="sm" className={styles.invisibleIcon} />
        </Flex>
      </Flex>

      {join(
        React.Children.toArray(children).map((child) => child as React.ReactElement),
        <Flex centeredHorizontally>
          <Divider width={dividerWidth} />
        </Flex>
      )}
    </Div>
  );
};

const WithLink: React.FC<{ linkTo?: string; children: React.ReactElement }> = ({
  linkTo,
  children
}) =>
  linkTo ? (
    <Link className={styles.row} to={linkTo} allowTextSelection>
      {children}
    </Link>
  ) : (
    children
  );

const CompactListRow: React.FC<CompactListRowProps> = ({
  linkTo,
  children,
  'data-testid': testId
}) => {
  return (
    <Div data-testid={testId}>
      <WithLink linkTo={linkTo}>
        <Flex centeredVertically padding={{ size: 'xs' }}>
          <Flex occupyHorizontalSpace pileVerticallyTo="md">
            {children}
          </Flex>

          <Flex centeredVertically alignRight>
            <Icon
              className={getClassNames({
                [styles.icon]: true,
                [styles.icon_invisible]: !linkTo
              })}
              name="chevron-right"
              size="sm"
              data-testid="compact-list-view-row-arrow-right"
            />
          </Flex>
        </Flex>
      </WithLink>
    </Div>
  );
};

const CompactListItem: React.FC<CompactListItemProps> = ({
  title,
  text,
  'data-testid': testId
}) => (
  <>
    <Flex
      flexItem={{ sizeMultiplier: 1 }}
      directionColumn
      responsiveHidden={{ widerThan: 'lg' }}
      padding={{ top: 'xxs', bottom: 'xxs' }}
      data-testid={testId}
    >
      <Paragraph>
        <b>{title}</b>
      </Paragraph>

      <Paragraph>{text || EN_DASH}</Paragraph>
    </Flex>

    <Flex flexItem={{ sizeMultiplier: 1 }} responsiveHidden={{ thinnerThan: 'lg' }}>
      <Div margin={{ right: 'xs' }}>
        <Paragraph>{text || EN_DASH}</Paragraph>
      </Div>
    </Flex>
  </>
);

CompactList.Item = CompactListItem;
CompactList.Row = CompactListRow;

export default CompactList;
