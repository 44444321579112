import { BodyText } from '@gaiads/telia-react-component-library';
import { DefinitionList } from 'common-components';
import { EN_DASH } from 'doings/dash/dash';
import formatPrice from 'doings/formatPrice/formatPrice';
import {
  PackageArea,
  PackageData,
  PackageMeasure,
  Packages
} from 'hooks/mobileSubscriptions/useSubscriptionDetails/subscriptionDetailsQuery';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

const I18N_KEYS = {
  AREA_DOM: 'subscriptions.packages.area.domestic',
  AREA_RLH: 'subscriptions.packages.area.roamlikehome',
  PAYG: 'subscriptions.packages.size.payg',
  UNLIMITED_CAPACITY: 'subscriptions.packages.size.unlimited',
  UNLIMITED_SPEED: 'subscriptions.packages.data.speed.unlimited',
  UNLIMITED_SPEED_DOWNLINK: 'subscriptions.packages.data.speed.unlimitedDownlink',
  UNLIMITED_SPEED_UPLINK: 'subscriptions.packages.data.speed.unlimitedUplink',
  PRICE_MONTHLY: 'subscriptions.packages.packagePricePerMonth'
};

const supplied = function <T>(input: T | undefined): T {
  return input as unknown as T;
};

const measureAreaToText = (t: TFunction, area?: PackageArea): string => {
  switch (area) {
    case 'DOM':
      return ` (${t(I18N_KEYS.AREA_DOM)})`;
    case 'DOM_EU':
      return ` (${t(I18N_KEYS.AREA_RLH)})`;
    default:
      return '';
  }
};

const measureToText = (
  t: TFunction,
  unlimitedI18nKey: string,
  measure?: PackageMeasure
): string => {
  if (!measure) {
    return EN_DASH;
  }

  const areaText = measureAreaToText(t, measure.area);
  if (measure.billingType === 'PAYG') {
    return `${t(I18N_KEYS.PAYG)}${areaText}`;
  }

  return `${measure.unlimited ? t(unlimitedI18nKey) : measure.text}${areaText}`;
};

const capacityToText = (t: TFunction, measure?: PackageMeasure) => {
  return measureToText(t, I18N_KEYS.UNLIMITED_CAPACITY, measure);
};

const speedToText = (t: TFunction, dataPackage: PackageData): JSX.Element | string => {
  const { uplinkSpeed: uplink, speed: downlink } = dataPackage;
  if (!uplink) {
    return measureToText(t, I18N_KEYS.UNLIMITED_SPEED, downlink);
  }

  return (
    <>
      {`${measureToText(t, I18N_KEYS.UNLIMITED_SPEED_DOWNLINK, downlink)} ↓`}
      <br />
      {`${measureToText(t, I18N_KEYS.UNLIMITED_SPEED_UPLINK, uplink)} ↑`}
    </>
  );
};

const priceToText = (t: TFunction, monthlyFeeVAT0: number): string => {
  return t(I18N_KEYS.PRICE_MONTHLY, {
    price: `${formatPrice(monthlyFeeVAT0)}`
  });
};

const notApplicableText = (t: TFunction) => t('subscriptions.packages.notApplicable');

const SubscriptionPackageInfo: React.FC<{
  packages: Packages;
  hasDuettoBundle?: boolean;
}> = ({ packages, hasDuettoBundle }) => {
  const { t } = useTranslation();
  const { feature, voice, sms, data, prices } = packages;
  return (
    <DefinitionList
      data-testid="subscription-package-info"
      pileVerticallyTo="xs"
      addDividers
      fiftyFifty
      trailingDivider
    >
      {[
        {
          testId: 'package-feature',
          labelKey: 'subscriptions.packages.feature.label',
          show: !!feature,
          supplier: () => supplied(feature).name
        },
        {
          testId: 'package-voice-absent',
          labelKey: 'subscriptions.packages.voice.label',
          show: !voice,
          supplier: () => notApplicableText(t)
        },
        {
          testId: 'package-voice',
          labelKey: 'subscriptions.packages.voice.label',
          show: !!voice,
          supplier: () => capacityToText(t, supplied(voice).capacity)
        },
        {
          testId: 'package-sms-absent',
          labelKey: 'subscriptions.packages.sms.label',
          show: !sms,
          supplier: () => notApplicableText(t)
        },
        {
          testId: 'package-sms',
          labelKey: 'subscriptions.packages.sms.label',
          show: !!sms,
          supplier: () => capacityToText(t, supplied(sms).capacity)
        },
        {
          testId: 'package-data-absent',
          labelKey: 'subscriptions.packages.data.capacityDomestic',
          show: !data,
          supplier: () => notApplicableText(t)
        },
        {
          testId: 'package-data-capacity-domestic',
          labelKey: 'subscriptions.packages.data.capacityDomestic',
          show: !!data,
          supplier: () => capacityToText(t, supplied(data).capacity)
        },
        {
          testId: 'package-data-capacity-in-the-eu',
          labelKey: 'subscriptions.packages.data.capacityInTheEU',
          show: !!data?.capacityEU,
          supplier: () => capacityToText(t, supplied(data).capacityEU)
        },
        {
          testId: 'package-data-speed',
          labelKey: 'subscriptions.packages.data.speed.label',
          show: !!data?.speed,
          supplier: () => speedToText(t, supplied(data))
        },
        {
          testId: 'package-price-unavailable',
          labelKey: 'subscriptions.packages.packagePrice',
          show: prices && hasDuettoBundle,
          supplier: () => notApplicableText(t)
        },
        {
          testId: 'package-price',
          labelKey: 'subscriptions.packages.packagePrice',
          show: !!prices && !hasDuettoBundle,
          supplier: () => priceToText(t, supplied(prices).monthlyVAT0)
        }
      ]
        .filter((item) => item.show)
        .map(({ testId, labelKey, supplier }) => (
          <DefinitionList.Item data-testid={testId} heading={t(labelKey)} key={testId}>
            <BodyText tagName="span">{supplier()}</BodyText>
          </DefinitionList.Item>
        ))}
    </DefinitionList>
  );
};

export default SubscriptionPackageInfo;
