import { BodyText, Flex, Icon } from '@gaiads/telia-react-component-library';
import { EN_DASH } from 'doings/dash/dash';
import { pukCodeSearch } from 'doings/track/analyticsEvents';
import trackEvent from 'doings/track/trackEvent';
import { SubscriptionPukCodeSearchItem } from 'types/subscription';

import styles from './PukCodeSearch.module.scss';

const PukCodeSearchResultItem: React.FC<{
  item: SubscriptionPukCodeSearchItem;
  searchForItem: (value: string) => void;
}> = ({ item, searchForItem }) => (
  <button
    type="button"
    className={styles.pukCodeSearchResultItem}
    onClick={() => {
      trackEvent(pukCodeSearch.pukCodeSearchSelectResult);
      searchForItem(item.phoneNumber);
    }}
  >
    <Flex alignBaseline>
      <Icon className={styles.chevron} name="chevron-right" size="sm" />

      <BodyText
        data-testid={`puk-code-search-result-${item.phoneNumberHash}`}
        className={styles.pukCodeResultItem}
      >
        {item.phoneNumber} {EN_DASH} {item.name}
      </BodyText>
    </Flex>
  </button>
);

export default PukCodeSearchResultItem;
