import { Gutter } from '@gaiads/telia-react-component-library';
import { Accordion, ActionButtonGroup, Authorize, Button, Skeleton } from 'common-components';
import openUrl from 'doings/openUrl/openUrl';
import { AnalyticsEvent } from 'doings/track/analyticsEvents';
import { SubscriptionStatus } from 'hooks/mobileSubscriptions/useSubscriptionDetails/subscriptionDetailsQuery';
import useScrollElementIntoView from 'hooks/useScrollElementIntoView/useScrollElementIntoView';
import { useRef } from 'react';

const SubscriptionAccordion: React.FC<{
  children: NonNullable<React.ReactNode>;
  heading: {
    label: string;
    testId: string;
    trackOnExpand: AnalyticsEvent;
  };
  actionButton: {
    visible: boolean;
    label: string;
    corporatePortalUrl: string;
    testId: string;
    trackOnClick: AnalyticsEvent;
  };
  loading: boolean;
  phoneNumberHash?: string;
  status?: SubscriptionStatus;
  initiallyExpanded: boolean;
}> = ({
  children,
  heading,
  actionButton: link,
  phoneNumberHash,
  status,
  loading,
  initiallyExpanded
}) => {
  const isPreopened = status === 'PREOPENED';
  const isDisabled = status === 'DISABLED';
  const isSuspended = status === 'SUSPENDED';
  const accordionRef: React.RefObject<HTMLDivElement> = useRef(null);

  useScrollElementIntoView({
    elementRef: accordionRef,
    shouldScroll: !loading && initiallyExpanded,
    queryParamsToClear: ['section']
  });

  if (loading || !phoneNumberHash || !status) {
    return <Skeleton.Accordion />;
  }

  return (
    <Accordion
      data-testid={heading.testId}
      heading={heading.label}
      analyticsEvent={heading.trackOnExpand}
      accordionRef={accordionRef}
      initiallyExpanded={initiallyExpanded}
    >
      {children}

      {link.visible && <Gutter />}

      {link.visible && (
        <ActionButtonGroup>
          <Authorize
            analyticsEvent={link.trackOnClick}
            enabledWhen={{
              onePermission: 'B2B_PORTAL',
              anyPermission: ['MOBILE_SUBSCRIPTIONS']
            }}
            element={
              <Button
                data-testid={link.testId}
                variant="secondary"
                type="button"
                onClick={() => openUrl(link.corporatePortalUrl)}
                disabled={isPreopened || isSuspended || isDisabled}
                disabledOnDemo
              >
                {link.label}
              </Button>
            }
          />
        </ActionButtonGroup>
      )}
    </Accordion>
  );
};

export default SubscriptionAccordion;
