import { Div, Flex, Logo, MarginSpace } from '@gaiads/telia-react-component-library';
import TopBar from 'B2XApp/TopBar/TopBar';
import { IconButton, Optional, RemBreakpoint } from 'common-components';
import Link from 'common-components/Link/Link';
import { ContentSearchProvider } from 'contexts/SearchContext/ContentSearchContext';
import { useSubmenuState } from 'contexts/SubmenuContext/SubmenuContext';
import { useUserData } from 'contexts/UserContext/UserContext';
import { useRemBreakpoint } from 'hooks/useBreakpoint/useRemBreakpoint';
import { GuardedEvent, useConfirmationGuard } from 'hooks/useCustomModal/useConfirmationGuard';
import useIsPathMatch from 'hooks/useIsPathMatch/useIsPathMatch';
import useUserLanguage from 'hooks/useUserLanguage/useUserLanguage';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ContentSearch from './ContentSearch/ContentSearch';
import ContentSearchResults from './ContentSearch/ContentSearchResults';
import InternalPagesMenu from './InternalPagesMenu/InternalPagesMenu';
import MobileNavigation from './MobileNavigation/MobileNavigation';
import styles from './Navigation.module.scss';
import NavigationMenu from './NavigationMenu/NavigationMenu';
import NavigationMenuItem from './NavigationMenu/NavigationMenuItem/NavigationMenuItem';
import NewsDrawerBell from './NewsDrawerBell/NewsDrawerBell';
import { MenuItemShape } from './useNavigationItems';
import UserDropdown from './UserDropdown/UserDropdown';

const Navigation: React.FC<{
  navigationItems: MenuItemShape[];
}> = ({ navigationItems }) => {
  const { t } = useTranslation();
  const { accesses } = useUserData();
  const { language, setLanguage } = useUserLanguage();
  const { isMobileMenuOpen, openMenuId, closeMenus, toggleMenu } = useSubmenuState();
  const isPathMatch = useIsPathMatch();
  const hamburgerMenuBreakpoint = 'md';
  const { isActive: isMobileLayout } = useRemBreakpoint({ narrowerThan: 'sm' });
  const isSearchOpen = openMenuId === 'content-search';
  const isMobileSearch = isSearchOpen && isMobileLayout;
  const { onGuardedClick } = useConfirmationGuard();

  const handleClick = (e: GuardedEvent) => {
    closeMenus();
    onGuardedClick(e, '/');
  };

  return (
    <Div className={styles.navigation}>
      <MarginSpace noPaddingForSmallerThanSm={isMobileSearch}>
        <RemBreakpoint widerThan={hamburgerMenuBreakpoint}>
          <TopBar selectedLanguage={language} selectLanguage={setLanguage} />
        </RemBreakpoint>

        <Flex className={styles.navigation_menuBar} spaceBetween centeredVertically>
          <Div className={styles.navigation_logoLinkContainer} hidden={isMobileSearch}>
            <Link
              data-testid="telia-logo-container"
              aria-label={t('aria.header.logo.linkToDashboard')}
              to="/"
              className={styles.navigation_logoLink}
              onClick={handleClick}
              tabIndex={0}
            >
              <Logo size="lg" aria-label={t('aria.header.logo.altText')} />
            </Link>
          </Div>

          <Flex padding={{ top: 'xs' }} centeredVertically hidden={isMobileSearch}>
            <RemBreakpoint widerThan={hamburgerMenuBreakpoint}>
              <ItemContainer
                hidden={isSearchOpen}
                navigationItems={navigationItems}
                isPathMatch={isPathMatch}
              />
            </RemBreakpoint>
          </Flex>

          <Flex alignRight centeredVertically occupyHorizontalSpace role="search">
            <ContentSearchProvider>
              <ContentSearch />

              <ContentSearchResults />
            </ContentSearchProvider>
          </Flex>

          <Div hidden={isMobileSearch}>
            <NewsDrawerBell />
          </Div>

          <Optional if={accesses.length > 0}>
            <Div hidden={isMobileSearch}>
              <InternalPagesMenu />
            </Div>
          </Optional>

          <Div hidden={isMobileSearch}>
            <Flex centeredVertically>
              <UserDropdown />

              <RemBreakpoint narrowerThan={hamburgerMenuBreakpoint} at={hamburgerMenuBreakpoint}>
                <IconButton
                  data-testid="open-mobile-menu"
                  aria-label={t('aria.header.mobileNavMenuToggle')}
                  aria-expanded={isMobileMenuOpen}
                  className={[styles.navigation_hamburgerMenu]}
                  name={isMobileMenuOpen ? 'close' : 'menu'}
                  size="md"
                  onClick={toggleMenu}
                />
              </RemBreakpoint>
            </Flex>
          </Div>
        </Flex>

        <RemBreakpoint narrowerThan={hamburgerMenuBreakpoint} at={hamburgerMenuBreakpoint}>
          {isMobileMenuOpen && (
            <MobileNavigation
              navigationItems={navigationItems}
              selectedLanguage={language}
              selectLanguage={setLanguage}
            />
          )}
        </RemBreakpoint>
      </MarginSpace>
    </Div>
  );
};

const ItemContainer: React.FC<{
  navigationItems: MenuItemShape[];
  isPathMatch: (paths: string[]) => boolean;
  hidden: boolean;
}> = ({ navigationItems, isPathMatch, hidden }) => (
  <Flex hidden={hidden} pileVerticallyTo="md" centeredVertically>
    {navigationItems.map(
      ({
        id,
        labelI18nKey,
        to,
        menuItems,
        additionalActiveMatches,
        external,
        onClick,
        disabled
      }) => {
        const linkTo = to ?? '';
        const isActive = isPathMatch([...additionalActiveMatches, linkTo].filter(Boolean));
        return (
          <React.Fragment key={id}>
            {menuItems && Boolean(menuItems.length) ? (
              <NavigationMenu
                itemId={id}
                labelI18nKey={labelI18nKey}
                navigationItems={menuItems}
                active={isActive}
                data-testid="navigation-menu"
              />
            ) : (
              <Div padding={{ horizontal: 'sm', bottom: 'sm' }}>
                <NavigationMenuItem
                  itemId={id}
                  labelI18nKey={labelI18nKey}
                  active={isActive}
                  to={linkTo}
                  external={external}
                  onClick={onClick}
                  disabled={disabled}
                  data-testid="navigation-menu-item"
                />
              </Div>
            )}
          </React.Fragment>
        );
      }
    )}
  </Flex>
);

export default Navigation;
