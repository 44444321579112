import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAcceptUrl, setIsModalOpen } from 'redux/slices/confirmationModalSlice';
import { RootState } from 'redux/store';

export type GuardedEvent = React.MouseEvent | React.KeyboardEvent;

/**
 * Guards a navigational link with a confirmation modal to prevent a
 * user from accidentally or uninternationally navigating away from
 * a (partially) filled form.
 */
export const useConfirmationGuard = () => {
  const dispatch = useDispatch();
  const showConfirmationModal = useSelector(
    (state: RootState) => state.confirmationModal.showConfirmationModal
  );

  const onGuardedClick = useCallback(
    (e: GuardedEvent, acceptUrl: string) => {
      if (showConfirmationModal) {
        dispatch(setIsModalOpen(true));
        dispatch(setAcceptUrl(acceptUrl));
        e.preventDefault();
        e.stopPropagation();
        return true;
      }

      return false;
    },
    [dispatch, showConfirmationModal]
  );

  return { showConfirmationModal, onGuardedClick };
};
