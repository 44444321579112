import { BillingGroupPageType } from 'B2XApp/Invoicing/BillingGroups/BillingGroupPage';
import { useTranslation } from 'react-i18next';

import BillingGroupFormPageLayout from './BillingGroupFormPageLayout';
import BillingGroupFormSkeleton from './BillingGroupFormSkeleton';

const BillingGroupPageLoading: React.FC<{
  collectionGroupId?: string;
  pageType: BillingGroupPageType;
}> = ({ collectionGroupId, pageType }) => {
  const { t } = useTranslation();

  return (
    <BillingGroupFormPageLayout
      title={t(`invoices.billingGroup.form.${pageType}.title`, { collectionGroupId })}
      backLinkVisible
      loading
      data-testid="billing-group-form-page-loader"
    >
      <BillingGroupFormSkeleton data-testid="billing-group-form-page-skeleton" />
    </BillingGroupFormPageLayout>
  );
};

export default BillingGroupPageLoading;
