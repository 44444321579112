import getClassNames from 'classnames';
import { multiplex } from 'doings/multiplex/multiplex';
import React from 'react';
import { createPortal } from 'react-dom';

import styles from './Tooltip.module.scss';

export type TooltipArrangement = 'top' | 'bottom';

/*
 * A basic tooltip implementation post React 18 migration to cover
 * B2X Portal's needs without redesigning tooltip usage throughout
 * the application.
 */
export const Tooltip: React.FC<{
  tooltipContent: NonNullable<React.ReactElement>;
  arrangement: TooltipArrangement;
  clickable: boolean;
  children: NonNullable<React.ReactNode>;
}> = ({ tooltipContent, arrangement, clickable, children }) => {
  const [visible, setVisible] = React.useState(false);
  const focused = React.useRef(false);
  const pointer = React.useRef(false);
  const mouseLeaveTimerId = React.useRef<NodeJS.Timeout>();

  const containerRef = React.useRef<HTMLElement>(null);
  const popupRef = React.useRef<HTMLDivElement>(null);

  const onContainerClickToggle = React.useCallback(
    (e: React.SyntheticEvent) => {
      if (clickable) {
        setVisible(!visible);
        e.stopPropagation();
        e.preventDefault();
      }
    },
    [clickable, visible]
  );

  const onContainerFocusShow = React.useCallback(
    (_e: React.MouseEvent) => {
      if (!pointer.current) {
        focused.current = true;
        setVisible(true);
      }
    },
    [setVisible]
  );

  const onContainerBlurHide = React.useCallback(
    (_e: React.MouseEvent) => {
      if (!pointer.current) {
        focused.current = false;
        setVisible(false);
      }
    },
    [setVisible]
  );

  const onContainerMouseEnterShow = React.useCallback(
    (_e: React.MouseEvent) => {
      setVisible(true);
      if (mouseLeaveTimerId.current) {
        clearTimeout(mouseLeaveTimerId.current);
      }
    },
    [setVisible]
  );

  const onContainerMouseLeaveHideAfterDelay = React.useCallback((_e: React.MouseEvent) => {
    if (!focused.current) {
      mouseLeaveTimerId.current = setTimeout(() => {
        setVisible(false);
      }, MOUSE_LEAVE_DELAY_IN_MS);
    }
  }, []);

  const onContainerMouseDownMarkPointerEvent = React.useCallback((_e: React.MouseEvent) => {
    pointer.current = true;
  }, []);

  const onContainerMouseUpUnmarkPointerEvent = React.useCallback((_e: React.MouseEvent) => {
    pointer.current = false;
  }, []);

  const onPopupMouseEnter = React.useCallback((_e: React.MouseEvent) => {
    if (mouseLeaveTimerId.current) {
      clearTimeout(mouseLeaveTimerId.current);
    }
  }, []);

  const onPopupMouseLeave = React.useCallback(
    (e: React.MouseEvent) => {
      if (!focused.current) {
        onContainerMouseLeaveHideAfterDelay(e);
      }
    },
    [onContainerMouseLeaveHideAfterDelay]
  );

  const [pos, setPos] = React.useState<PopupAttributes>(DEFAULT_POPUP_ATTRIBUTES);
  const onRender = React.useCallback(() => {
    if (!containerRef.current || !popupRef.current) {
      return;
    }

    const container = containerRef.current;
    const popup = popupRef.current;
    const newPos = positionPopup({
      container,
      popup,
      arrangement
    });

    setPos(newPos);
  }, [arrangement]);

  React.useLayoutEffect(() => {
    if (!visible) {
      setPos(DEFAULT_POPUP_ATTRIBUTES);
    }
  }, [visible]);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', onRender);
    return () => {
      window.removeEventListener('resize', onRender);
    };
  }, [onRender]);

  React.useEffect(() => {
    return () => {
      if (mouseLeaveTimerId.current) {
        clearTimeout(mouseLeaveTimerId.current);
      }
    };
  }, []);

  const documentBody = React.useMemo(() => document.querySelector('#application-body'), []);
  const container = containerRef.current;

  React.useLayoutEffect(() => {
    if (!container) {
      return;
    }

    const onResize = new ResizeObserver(
      /* istanbul ignore next */
      () => onRender()
    );
    [container, documentBody].forEach((el) => {
      if (el) {
        onResize.observe(el);
      }
    });
    return () => {
      onResize.disconnect();
    };
  }, [container, documentBody, onRender]);

  const triggers = {
    onClick: onContainerClickToggle,
    onMouseEnter: onContainerMouseEnterShow,
    onMouseLeave: onContainerMouseLeaveHideAfterDelay,
    onMouseDown: onContainerMouseDownMarkPointerEvent,
    onMouseUp: onContainerMouseUpUnmarkPointerEvent,
    onFocus: onContainerFocusShow,
    onBlur: onContainerBlurHide
  };

  return (
    <>
      <TooltipTarget triggers={triggers} containerRef={containerRef}>
        {children}
      </TooltipTarget>

      <TooltipPortal
        visible={visible}
        popupRef={popupRef}
        pos={pos}
        onRender={onRender}
        onMouseEnter={onPopupMouseEnter}
        onMouseLeave={onPopupMouseLeave}
      >
        {tooltipContent}
      </TooltipPortal>
    </>
  );
};

const TooltipTarget: React.FC<{
  children: NonNullable<React.ReactNode>;
  triggers: Record<string, React.EventHandler<React.SyntheticEvent>>;
  containerRef: React.ForwardedRef<HTMLElement>;
}> = ({ children, triggers, containerRef }) => {
  if (!React.isValidElement(children) || React.Children.count(children) > 1) {
    return children;
  }

  const props = { ...triggers, ref: containerRef };
  return React.cloneElement(children, props);
};

const TooltipPortal: React.FC<{
  children: NonNullable<React.ReactNode>;
  visible?: boolean;
  onRender: VoidFunction;
  onMouseEnter: (e: React.MouseEvent) => void;
  onMouseLeave: (e: React.MouseEvent) => void;
  pos: ReturnType<typeof positionPopup>;
  popupRef: React.ForwardedRef<HTMLDivElement>;
}> = ({ children, visible, onRender, onMouseEnter, onMouseLeave, pos, popupRef }) => {
  React.useLayoutEffect(() => {
    onRender();
  }, [onRender, visible]);

  if (!visible) {
    return null;
  }

  const root = document.getElementsByTagName('body')[0];
  return createPortal(
    <div
      role="tooltip"
      className={styles.tooltip_popup}
      style={{ top: pos.y, left: pos.x, transform: pos.transform }}
      ref={popupRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-test-placement={pos.placement}
    >
      {children}

      <div
        className={getClassNames(styles.tooltip_arrow, ARROW_VARIANTS[pos.placement])}
        style={{ left: pos.arrowOffset }}
      ></div>
    </div>,
    root
  );
};

type PopupAttributes = {
  x: string;
  y: string;
  placement: TooltipPlacement;
  arrowOffset?: string;
  transform: string;
};

const DEFAULT_POPUP_ATTRIBUTES: PopupAttributes = {
  x: '-9999px',
  y: '-9999px',
  placement: 'topCenter',
  transform: 'unset'
};

const ARROW_VARIANTS = {
  topStart: styles.tooltip_arrow__topStart,
  topOffcenter: styles.tooltip_arrow__topStart,
  topCenter: styles.tooltip_arrow__topCenter,
  topEnd: styles.tooltip_arrow__topEnd,
  bottomStart: styles.tooltip_arrow__bottomStart,
  bottomOffcenter: styles.tooltip_arrow__bottomStart,
  bottomCenter: styles.tooltip_arrow__bottomCenter,
  bottomEnd: styles.tooltip_arrow__bottomEnd
} as const;

type TooltipPlacement = keyof typeof ARROW_VARIANTS;

const MOUSE_LEAVE_DELAY_IN_MS = 200;
const POPUP_EDGE = 32;
const POPUP_Y = 18;
const POP_ARRANGEMENT_POSITIONING = {
  top: {
    computeYPosition: (bounds: DOMRect) => bounds.top + window.scrollY - POPUP_Y,
    startPlacement: 'topStart',
    centerPlacement: 'topCenter',
    offcenterPlacement: 'topOffcenter',
    endPlacement: 'topEnd'
  },
  bottom: {
    computeYPosition: (bounds: DOMRect) => bounds.bottom + window.scrollY + POPUP_Y,
    startPlacement: 'bottomStart',
    centerPlacement: 'bottomCenter',
    offcenterPlacement: 'bottomOffcenter',
    endPlacement: 'bottomEnd'
  }
} as const;

const positionPopup = ({
  container,
  popup,
  arrangement
}: {
  container: HTMLElement;
  popup: HTMLDivElement;
  arrangement: TooltipArrangement;
}): PopupAttributes => {
  const popupWidth = popup.offsetWidth;
  const popupHalfWidth = popupWidth >> 1;

  const targetHalfWidth = container.offsetWidth >> 1;
  const targetBounds = container.getBoundingClientRect();

  const viewWidth = window.innerWidth;
  const targetCentreSpaceLeft = targetBounds.left + targetHalfWidth;
  const targetCentreSpaceRight = viewWidth - targetCentreSpaceLeft;
  const outboundSpaceLeft = targetBounds.left;
  const outboundSpaceRight = viewWidth - targetBounds.right;

  const positioning = POP_ARRANGEMENT_POSITIONING[arrangement];
  const yPosInPx = positioning.computeYPosition(targetBounds);
  const yPos = `${yPosInPx}px`;
  const transform = multiplex([
    'unset',
    [arrangement === 'top', 'translateY(-100%)'],
    [arrangement === 'bottom', 'translateY(0%)']
  ]);

  if (targetCentreSpaceLeft > popupHalfWidth && targetCentreSpaceRight > popupHalfWidth) {
    return {
      x: `${targetCentreSpaceLeft - popupHalfWidth}px`,
      y: yPos,
      placement: positioning.centerPlacement,
      transform
    };
  } else if (
    outboundSpaceLeft > popupWidth - targetHalfWidth - POPUP_EDGE &&
    outboundSpaceRight <= popupHalfWidth + POPUP_EDGE
  ) {
    return {
      x: `${targetBounds.left + targetHalfWidth - popupWidth + POPUP_EDGE}px`,
      y: yPos,
      placement: positioning.endPlacement,
      transform
    };
  } else if (
    outboundSpaceRight > popupWidth - targetHalfWidth - POPUP_EDGE &&
    outboundSpaceLeft <= popupHalfWidth + POPUP_EDGE
  ) {
    return {
      x: `${targetBounds.left + targetHalfWidth - POPUP_EDGE}px`,
      y: yPos,
      placement: positioning.startPlacement,
      transform
    };
  } else {
    const x = (viewWidth >> 1) - popupHalfWidth;
    const xArrow = targetBounds.left - x - 1;
    return {
      x: `${x}px`,
      y: yPos,
      placement: positioning.offcenterPlacement,
      arrowOffset: `${xArrow}px`,
      transform
    };
  }
};
