import { BodyText, Checkbox, Flex, Gutter, Icon } from '@gaiads/telia-react-component-library';
import {
  ActionButtonGroup,
  Analytics,
  Button,
  DataPanel,
  DefinitionList,
  DetailsLoadingOverlay,
  InlineNotification,
  TeliaLink
} from 'common-components';
import ModelTextInput from 'common-components/ModelTextInput/ModelTextInput';
import { useUserData } from 'contexts/UserContext/UserContext';
import { invoicing } from 'doings/track/analyticsEvents';
import usePhoneNumberInputModel from 'hooks/inputModels/usePhoneNumberInputModel/usePhoneNumberInputModel';
import { ApplyPaymentPlan } from 'hooks/invoicing/invoices/useApplyNewPaymentPlan/useApplyNewPaymentPlan';
import { useRouting } from 'hooks/useRouting/useRouting';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceDetailsType } from 'types/invoice';

import RequestNewPlanSkeleton from './RequestNewPlanSkeleton';

const RequestNewPlan: React.FC<{
  invoice: InvoiceDetailsType | undefined;
  loading: boolean;
  hasError: boolean;
  applyNewPlan: ApplyPaymentPlan;
  saving: 'no' | 'yes' | 'failure';
  savingReset: VoidFunction;
}> = ({ invoice, loading, hasError, applyNewPlan, saving, savingReset }) => {
  const { t } = useTranslation();
  const { goto } = useRouting();
  const { businessPhoneNumber } = useUserData();
  const [requestReminder, setRequestReminder] = useState(false);
  const phoneNumber = usePhoneNumberInputModel({
    initialValue: businessPhoneNumber,
    required: true
  });

  return (
    <DataPanel
      data-testid="apply-for-extension-modal"
      loading={loading}
      hasError={hasError}
      title={t('invoice.paymentPlan.invoice', { id: invoice?.id })}
      skeletonLoader={<RequestNewPlanSkeleton />}
      mainPanel
    >
      <DetailsLoadingOverlay showLoader={saving === 'yes'}>
        <DefinitionList addDividers alignRight>
          <DefinitionList.Item heading={t('invoice.paymentPlan.oldDueDate')}>
            <BodyText>{invoice?.dueDate}</BodyText>
          </DefinitionList.Item>

          <DefinitionList.Item heading={t('invoice.paymentPlan.newDueDate')}>
            <BodyText>{invoice?.firstPayDate}</BodyText>
          </DefinitionList.Item>
        </DefinitionList>

        <Gutter size="lg" />

        <Checkbox
          data-testid="request-reminder-checkbox"
          label={t('invoice.paymentPlan.requestReminder')}
          checked={requestReminder}
          onChange={() => setRequestReminder(!requestReminder)}
        />

        {requestReminder && (
          <Flex padding={{ top: 'sm' }}>
            <Icon name="checkmark" size="md" color="transparent" />

            <Gutter size="xs" />

            <ModelTextInput
              data-testid="phone-number-input"
              label={t('common.phone.label')}
              inputModel={phoneNumber}
            />
          </Flex>
        )}

        <Gutter size="lg" />

        <InlineNotification
          variant="information"
          content={{
            key: 'invoice.paymentPlan.info',
            components: [
              <TeliaLink.External
                key="link-to-payment-plan-terms-and-conditions"
                to="https://www.telia.fi/yrityksille/asiakastuki/laskut-ja-maksaminen"
                variant="text"
                label={t('invoice.paymentPlan.infoLink')}
                iconRight="external"
              />
            ]
          }}
          data-testid="payment-plan-info"
        />

        <Gutter />

        {saving === 'failure' && (
          <>
            <InlineNotification
              variant="error"
              title={{ key: 'invoice.paymentPlan.errorTitle' }}
              content={{ key: 'invoice.paymentPlan.errorDescription' }}
              onClose={savingReset}
              data-testid="failure-notice"
            />

            <Gutter />
          </>
        )}

        <ActionButtonGroup>
          <Analytics whenClicked={invoicing.acceptNewPaymentPlan}>
            <Button
              data-testid="accept-new-plan"
              variant="primary"
              size="sm"
              disabled={
                !invoice?.firstPayDate ||
                (requestReminder && !phoneNumber.isValid) ||
                saving === 'yes'
              }
              onClick={() =>
                applyNewPlan({
                  variables: {
                    key: invoice?.key,
                    notificationTo: phoneNumber.value.replace(/\s+/g, '')
                  }
                })
              }
            >
              {t('invoice.paymentPlan.accept')}
            </Button>
          </Analytics>

          <Button
            data-testid="cancel-new-plan-link"
            disabled={saving === 'yes'}
            onClick={() =>
              goto({
                to: `/invoices/${invoice?.key}`,
                toCorporatePortal: '',
                toCorporatePortalArgs: {},
                useB2XLink: true
              })
            }
            variant="tertiary-purple"
            type="button"
            size="sm"
          >
            {t('common.cancelButton.label')}
          </Button>
        </ActionButtonGroup>
      </DetailsLoadingOverlay>
    </DataPanel>
  );
};

export default RequestNewPlan;
