import { TeliaLink } from 'common-components';
import getEnvironmentVariable from 'doings/getEnvironmentVariable/getEnvironmentVariable';
import { devices } from 'doings/track/analyticsEvents';
import { useTranslation } from 'react-i18next';

const ManageDevicesInTINLink: React.FC = () => {
  const { t } = useTranslation();
  return (
    <TeliaLink.External
      data-testid="manage-devices-at-tin-shop"
      variant="standalone"
      to={getEnvironmentVariable('REACT_APP_LINK_TO_TIN_SHOP')}
      label={t('daas.links.tinShop')}
      iconRight="external"
      track={devices.manageDevicesInTINShop}
    />
  );
};

export default ManageDevicesInTINLink;
