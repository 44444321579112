import { Flex } from '@gaiads/telia-react-component-library';
import { TicketDetailsMessage, TicketDetailsTimelineSkeleton } from 'B2XApp/Messages/common';
import { NoteSource } from 'types/ticket';

export type MessageAttachment = {
  name: string;
  url: string;
};

export type Message = {
  header?: string;
  description?: string;
  date: string;
  attachments: MessageAttachment[];
  source?: NoteSource;
  authorFirstName?: string;
  authorLastName?: string;
  isResolutionMessage?: boolean;
};

const TicketDetailsTimeline: React.FC<{
  footer: string;
  messages: Message[];
  showChatView: boolean;
  loading: boolean;
}> = ({ loading, messages, showChatView }) => {
  if (loading) {
    return <TicketDetailsTimelineSkeleton />;
  }

  return (
    <Flex directionColumn occupyVerticalSpace={false}>
      {messages.map((message, index) => (
        <TicketDetailsMessage
          key={index}
          index={index}
          message={message}
          showChatView={showChatView}
          loading={loading}
        />
      ))}
    </Flex>
  );
};

export default TicketDetailsTimeline;
