import '@teliads/components/dist/telia-component-library/telia-component-library.css';
import 'core-js/stable';
import 'react-app-polyfill/ie11';
import 'regenerator-runtime/runtime';

import './index.scss';
import './i18n';

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from 'redux/store';

import B2XApp from './B2XApp/B2XApp';

const container = document.getElementById('root');
const root = createRoot(container as Element);
root.render(
  <StrictMode>
    <Provider store={store}>
      <B2XApp />
    </Provider>
  </StrictMode>
);
