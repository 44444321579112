import { DropdownMultiselect, OptionListOption } from '@gaiads/telia-react-component-library';
import styles from 'common-components/Forms/FormField/Inputs/FormFieldMultiselect.module.scss';
import { DropdownInputModel } from 'hooks/inputModels/useDropdownInputModel/useDropdownInputModel';
import { useState } from 'react';
import { EventHandlers } from 'types/eventHandler';

const determineFieldValue = (
  options: OptionListOption[],
  selectedOption: OptionListOption,
  fieldValue: string[]
) => {
  const fieldValueAsSet = new Set<string>(fieldValue);
  const selectedValue = selectedOption.value;
  if (fieldValueAsSet.has(selectedValue)) {
    fieldValueAsSet.delete(selectedValue);
  } else {
    fieldValueAsSet.add(selectedValue);
  }

  return options.map((option) => option.value).filter((value) => fieldValueAsSet.has(value));
};

const isChecked = (option: OptionListOption, fieldValue: string[]) =>
  fieldValue.includes(option.value);

const mapCheckedOptions = (options: OptionListOption[], fieldValue: string[]) =>
  options.map((option) => {
    return { ...option, checked: isChecked(option, fieldValue) };
  });

const stringifyCheckedOptions = (options: OptionListOption[], fieldValue: string[]) =>
  options
    .filter((option) => isChecked(option, fieldValue))
    .map((option) => option.label)
    .join(', ');

const isValueSelected = (fieldValue: string[]) =>
  fieldValue.length <= 1 && fieldValue.every((value) => value === '');

const ModelMultiselect: React.FC<{
  label: string;
  inputModel: DropdownInputModel;
  placeholder?: string;
  disabled?: boolean;
  onFocus?: EventHandlers.Focus;
  onBlur?: EventHandlers.Focus;
  'data-testid'?: string;
  'data-name'?: string;
}> = ({
  label,
  inputModel,
  placeholder,
  disabled,
  onFocus,
  onBlur,
  'data-testid': testId,
  'data-name': dataName
}) => {
  const [fieldValue, setFieldValue] = useState<string[]>(inputModel.initialValue.split(','));

  const updateValue = (values: string[]) => {
    setFieldValue(values);
    inputModel.setValue(values.join(','));
  };

  return (
    <DropdownMultiselect
      data-testid={testId}
      data-name={dataName}
      className={styles.moveAlongNothingToSeeHere}
      label={label}
      onChange={(_event, option) => {
        updateValue(determineFieldValue(inputModel.options, option, fieldValue));
      }}
      onFocus={onFocus}
      onBlur={onBlur}
      onClear={() => {
        updateValue([]);
      }}
      options={mapCheckedOptions(inputModel.options, fieldValue)}
      placeholder={
        isValueSelected(fieldValue)
          ? placeholder
          : stringifyCheckedOptions(inputModel.options, fieldValue)
      }
      required={inputModel.required}
      errorText={inputModel.errorMessage}
      disabled={disabled}
    />
  );
};

export default ModelMultiselect;
