import { Icon } from '@teliafi/fi-ds';
import { Button } from 'common-components/Button/Button';
import isCorporatePortalOrUlmUrl from 'doings/isCorporatePortalOrUlmUrl/isCorporatePortalOrUlmUrl';
import isDemoEnvironment from 'doings/isDemoEnvironment/isDemoEnvironment';
import { AuthorizationParams } from 'types/authorization';

import ExternalLink from '../ExternalLink/ExternalLink';
import Link, { shouldDisableLink } from '../Link';
import styles from './ButtonLink.module.scss';

const ButtonLink: React.FC<{
  to?: string;
  text: string;
  iconRight?: 'chevron-right' | 'external';
  variant?: React.ComponentProps<typeof Button>['variant'];
  fallbackClickAction?: VoidFunction;
  disabled?: boolean;
  enabledWhen?: AuthorizationParams;
  dataTestid?: string;
}> = ({
  to,
  text,
  iconRight,
  variant = 'primary',
  fallbackClickAction,
  disabled = false,
  enabledWhen,
  dataTestid
}) => {
  const { isDisabled } = shouldDisableLink(disabled, enabledWhen);
  const isB2BOrUlmUrl = isCorporatePortalOrUlmUrl(to);
  const isDemoEnv = isDemoEnvironment();
  const shouldDisableUrl = isDisabled || (isDemoEnv && isB2BOrUlmUrl);
  const isExternalLink = to?.startsWith('https');

  if (isExternalLink) {
    return (
      <ExternalLink
        className={styles.buttonLink}
        to={to}
        onClick={fallbackClickAction}
        data-testid={dataTestid}
        disabled={shouldDisableUrl}
        enabledWhen={enabledWhen}
        block
      >
        <Button
          disabled={shouldDisableUrl}
          variant={variant}
          className={styles.button}
          tabIndex={-1}
        >
          {text}

          {iconRight && <Icon className={styles.buttonLink_icon} name={iconRight} size="xs" />}
        </Button>
      </ExternalLink>
    );
  }

  return (
    <Link
      className={styles.buttonLink}
      to={to}
      onClick={fallbackClickAction}
      data-testid={dataTestid}
      disabled={shouldDisableUrl}
      enabledWhen={enabledWhen}
      block
    >
      <Button disabled={shouldDisableUrl} variant={variant} className={styles.button} tabIndex={-1}>
        {text}
      </Button>
    </Link>
  );
};

export default ButtonLink;
