import { Gutter } from '@gaiads/telia-react-component-library';
import { Paragraph } from '@teliafi/fi-ds';
import {
  DetailsLoadingOverlay,
  InlineNotification,
  Notices,
  SortableCheckboxes
} from 'common-components';
import { SortedChecklistItem } from 'common-components/SortableCheckboxes/SortableCheckboxesProvider';
import { Div } from 'core-components';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useEditableListModal } from './EditableListModalProvider';

const MAX_ENABLABLE_ITEMS = 7;

export type Interactions = {
  disabled: boolean;
  onAccept: VoidFunction;
  onClose: VoidFunction;
  onReset: VoidFunction;
};

export const EditableListModalContent: React.FC<{
  readonly hasChanges: React.MutableRefObject<boolean>;
  readonly setDrawerInteractions: (interactions: Interactions) => void;
}> = ({ hasChanges, setDrawerInteractions }) => {
  const { t } = useTranslation();
  const { items, itemCount, busy, actions } = useEditableListModal();
  const [tempItems, setTempItems] = useState<SortedChecklistItem[]>(items);

  useEffect(() => {
    hasChanges.current = !isEqual(items, tempItems);
  }, [hasChanges, items, tempItems]);

  useEffect(() => {
    setDrawerInteractions({
      disabled: busy,
      onAccept: () => {
        actions.updateColumns(tempItems);
      },
      onClose: actions.close,
      onReset: actions.resetColumns
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDrawerInteractions, busy, tempItems]);

  return (
    <>
      <Paragraph data-testid="list-view-config-modal-general-description">
        {t('modal.editList.description.general')}
      </Paragraph>

      {itemCount > MAX_ENABLABLE_ITEMS && (
        <Div margin={{ top: 'xs' }}>
          <Paragraph data-testid="list-view-config-modal-max-items-description">
            {t('modal.editList.description.maxItems')}
          </Paragraph>
        </Div>
      )}

      <Gutter size="md" />

      <DetailsLoadingOverlay showLoader={busy}>
        <SortableCheckboxes
          items={tempItems}
          itemClassification="column"
          minEnabledItems={1}
          maxEnabledItems={MAX_ENABLABLE_ITEMS}
          onChange={setTempItems}
        />
      </DetailsLoadingOverlay>

      {!!actions.errorMessage && <Gutter />}

      <Notices data-testid="editable-list-notices">
        {actions.errorMessage === 'update' && (
          <InlineNotification
            variant="error"
            title={{ key: 'modal.editList.errors.updateFailureTitle' }}
            content={{ key: 'modal.editList.errors.updateFailureDescription' }}
            data-testid="editable-list-update-failure-notice"
          />
        )}

        {actions.errorMessage === 'reset' && (
          <InlineNotification
            variant="error"
            title={{ key: 'modal.editList.errors.resetFailureTitle' }}
            content={{ key: 'modal.editList.errors.resetFailureDescription' }}
            data-testid="editable-list-reset-failure-notice"
          />
        )}
      </Notices>
    </>
  );
};
