import { TileButton, TileButtonGrid } from 'common-components';
import createCorporatePortalLink, {
  createCorporatePortalLinkWithFragment
} from 'doings/links/createCorporatePortalLink/createCorporatePortalLink';
import { mobile } from 'doings/track/analyticsEvents';
import { SubscriptionStatus } from 'hooks/mobileSubscriptions/useSubscriptionDetails/subscriptionDetailsQuery';
import useSearchParams from 'hooks/useSearchParams/useSearchParams';
import { useTranslation } from 'react-i18next';

const SubsTypeChangeLink: React.FC<{ phoneNumberHash: string; disabled: boolean }> = ({
  phoneNumberHash,
  disabled
}) => {
  const { t } = useTranslation();
  return (
    <TileButton
      disabled={disabled}
      data-testid="mobile-subscription-type-change"
      iconName="reverse"
      label={t('subscriptions.actions.mobileSubscriptionTypeChange.action')}
      to={createCorporatePortalLink('mobileSubscriptionTypeChange', {
        msisdn: phoneNumberHash
      })}
      whenClicked={mobile.updateSubscription}
      enabledWhen={{
        onePermission: 'B2B_PORTAL',
        anyPermission: ['MOBILE_SUBSCRIPTIONS']
      }}
    />
  );
};

const ChangeUserInformationLink: React.FC<{ phoneNumberHash: string }> = ({ phoneNumberHash }) => {
  const { t } = useTranslation();
  return (
    <TileButton
      data-testid="edit-user-information"
      iconName="user-admin"
      label={t('subscriptions.actions.userInformationChange.action')}
      to={createCorporatePortalLinkWithFragment('mobileDetails', 'user-details', {
        msisdn: phoneNumberHash
      })}
      whenClicked={mobile.changeUserInformation}
      enabledWhen={{
        onePermission: 'B2B_PORTAL',
        anyPermission: ['MOBILE_SUBSCRIPTIONS']
      }}
    />
  );
};

const CloseSubscriptionLink: React.FC<{
  phoneNumberHash: string;
  disabled: boolean;
  isClosed: boolean;
}> = ({ phoneNumberHash, disabled, isClosed }) => {
  const { t } = useTranslation();

  return !isClosed ? (
    <TileButton
      data-testid="mobileCloseTemporarily"
      iconName="lock"
      label={t('subscriptions.actions.mobileCloseTemporarily.action')}
      to={createCorporatePortalLink('mobileCloseTemporarily', {
        msisdn: phoneNumberHash
      })}
      whenClicked={mobile.closeSubscription}
      disabled={disabled}
      enabledWhen={{
        onePermission: 'B2B_PORTAL',
        anyPermission: ['MOBILE_SUBSCRIPTIONS']
      }}
    />
  ) : (
    <TileButton
      data-testid="mobileOpenClosedSubscription"
      iconName="lock-open"
      label={t('subscriptions.actions.mobileOpenClosedSubscription.action')}
      to={createCorporatePortalLink('mobileOpen', {
        msisdn: phoneNumberHash
      })}
      whenClicked={mobile.openClosedSubscription}
      enabledWhen={{
        onePermission: 'B2B_PORTAL',
        anyPermission: ['MOBILE_SUBSCRIPTIONS']
      }}
    />
  );
};

const ChangeOwnershipLink: React.FC<{ phoneNumberHash: string; disabled: boolean }> = ({
  phoneNumberHash,
  disabled
}) => {
  const { t } = useTranslation();
  const { preservePathState } = useSearchParams();

  return (
    <TileButton
      data-testid="mobileChangeOwnership"
      iconName="key"
      label={t('subscriptions.actions.mobileChangeOwnership.action')}
      to={preservePathState(`/subscriptions/${phoneNumberHash}/direct-transfer`)}
      whenClicked={mobile.transferOwnership}
      disabled={disabled}
      enabledWhen={{
        onePermission: 'B2B_PORTAL',
        anyPermission: ['MOBILE_SUBSCRIPTIONS']
      }}
    />
  );
};

const TerminateLink: React.FC<{ phoneNumberHash: string; canTerminate: boolean }> = ({
  phoneNumberHash,
  canTerminate
}) => {
  const { t } = useTranslation();

  return (
    <TileButton
      data-testid="mobileTerminate"
      iconName="stop"
      label={t('subscriptions.actions.mobileTerminate.action')}
      to={createCorporatePortalLink('mobileTerminate', {
        msisdn: phoneNumberHash
      })}
      whenClicked={mobile.terminateSubscription}
      disabled={!canTerminate}
      enabledWhen={{
        onePermission: 'B2B_PORTAL',
        anyPermission: ['MOBILE_SUBSCRIPTIONS']
      }}
    />
  );
};

const EditSubscriptionModal: React.FC<{
  phoneNumberHash: string;
  canTerminate: boolean;
  status: SubscriptionStatus;
  isSurfCard: boolean;
  hasDuettoBundle: boolean;
}> = ({ phoneNumberHash, canTerminate, status, isSurfCard, hasDuettoBundle }) => {
  const isClosed = status === 'CLOSED';
  const isSuspended = status === 'SUSPENDED';

  return (
    <TileButtonGrid columns={{ desktop: 3, mobile: 2 }}>
      {!isSurfCard && (
        <SubsTypeChangeLink phoneNumberHash={phoneNumberHash} disabled={hasDuettoBundle} />
      )}

      <ChangeUserInformationLink phoneNumberHash={phoneNumberHash} />

      {!isSurfCard && (
        <ChangeOwnershipLink
          phoneNumberHash={phoneNumberHash}
          disabled={isSuspended || hasDuettoBundle}
        />
      )}

      <CloseSubscriptionLink
        phoneNumberHash={phoneNumberHash}
        disabled={isSuspended}
        isClosed={isClosed}
      />

      <TerminateLink
        phoneNumberHash={phoneNumberHash}
        canTerminate={canTerminate && !hasDuettoBundle}
      />
    </TileButtonGrid>
  );
};

export default EditSubscriptionModal;
