import { Div, Flex, Page } from '@gaiads/telia-react-component-library';
import { MarginProps } from '@gaiads/telia-react-component-library/build/types/components/Element/getMarginClasses';
import FilterDialogToggle from 'common-components/ListFilters/FilterDialogToggle/FilterDialogToggle';
import Memo from 'common-components/Memo/Memo';
import { isEditable, useEditableListData } from 'contexts/EditableListContext/EditableListContext';
import formatDatetime, { DateFormats } from 'doings/formatDatetime/formatDatetime';
import toDateString from 'doings/formatDatetime/toDateString';
import useIsSmallerBreakpointActive from 'hooks/useIsSmallerBreakpointActive/useIsSmallerBreakpointActive';
import useReadQueryParams from 'hooks/useQueryParams/useReadQueryParams';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActiveFilter,
  DateFilterDefinition,
  DateTypeFilterDefinition,
  DropdownFilterDefinition,
  FilterDefinition,
  LabelsDefinition,
  SearchDefinition
} from 'types/listFilters';

import { ActiveFilters } from './ActiveFilters/ActiveFilters';
import { DateFilter } from './DateFilter/DateFilter';
import { DateTypeFilter } from './DateTypeFilter/DateTypeFilter';
import { DropdownFilter } from './DropdownFilter/DropdownFilter';
import { EditDialog } from './EditDialog/EditDialog';
import { EditDialogToggle } from './EditDialogToggle/EditDialogToggle';
import FilterDialog from './FilterDialog/FilterDialog';
import styles from './ListFilters.module.scss';
import { Searchfield } from './Searchfield/Searchfield';
import { useClearListeners } from './useClearListeners';
import { usePositionDialog } from './usePositionDialog';

type ListFilterProps = {
  search?: SearchDefinition;
  filters: Array<FilterDefinition>;
  labels: LabelsDefinition;
  sortDropdown?: JSX.Element;
  excelDownloadLink?: React.ReactElement;
  infoNotice?: React.ReactElement;
  children?: JSX.Element | JSX.Element[];
  'data-testid'?: string;
};

type QueryParams = ReturnType<typeof useReadQueryParams>;

export const ListFilters: React.FC<ListFilterProps> = ({
  search,
  filters,
  labels,
  sortDropdown,
  excelDownloadLink,
  infoNotice,
  children,
  'data-testid': dataTestid
}) => {
  const { t } = useTranslation();
  const readQueryParamNames = useMemo(() => collectParamNames(search, filters), [search, filters]);
  const readQueryParams: QueryParams = useReadQueryParams(readQueryParamNames);
  const queryParams: QueryParams = cleanQueryParams(search, filters, readQueryParams);

  const [filtersVisible, setFiltersVisible] = useState<boolean>(false);
  const isFiltersDisabledBySearchScope = isFiltersDisabled(search, queryParams);
  const isAnyFilterActive = isAnySignificantFilterSet(filters, queryParams);
  const isAnyFilterImplicit = isAnyFilterImplicitSet(filters);
  const appliedFilters = collectAppliedFilters(filters, queryParams);
  const isSearchActive = isSearchApplied(search, queryParams);

  const isMobileView = useIsSmallerBreakpointActive('md');
  const isResponsiveListView = useIsSmallerBreakpointActive('lg');

  const hasFilters = filters.length > 0;
  const hideSearch = !filtersVisible && isMobileView;
  const hideFilters = !hasFilters || isFiltersDisabledBySearchScope;

  const { anchorRef: toggleButtonRef, dialogRef } = usePositionDialog(filtersVisible);
  const { registerOnClearListener, clearAllFilters, clearFilter } = useClearListeners();
  const showFilters = (isAnyFilterActive || isAnyFilterImplicit) && !isFiltersDisabledBySearchScope;
  const showSortDropdown = sortDropdown && isResponsiveListView;

  const listConfigData = useEditableListData();
  const listConfig = isEditable(listConfigData) ? listConfigData : undefined;

  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const hasAdditionalActions = isEditable(listConfigData) || !!excelDownloadLink;

  return (
    <div data-testid={dataTestid}>
      <Page.Row className={styles.pageRow} data-testid="list-searchfield-row">
        <Flex centeredVertically spaceBetween pileVerticallyTo="sm" alignTop>
          <Flex style={{ flexGrow: 1 }} spaceBetween pileVerticallyTo="xxxs">
            {children && (
              <Flex flexItem alignTop spaceBetween margin={{ bottom: 'sm', right: 'md' }}>
                {children}
              </Flex>
            )}

            {isMobileView && (
              <FilterDialogToggle
                toggle={() => setFiltersVisible(!filtersVisible)}
                hasSearch={!!search}
                selected={isAnyFilterActive || isSearchActive}
                disabled={false}
                refElement={toggleButtonRef}
              />
            )}
          </Flex>

          <Flex pileVerticallyTo="sm" hidden={hideSearch} alignTop>
            {!isMobileView && hasFilters && (
              <FilterDialogToggle
                toggle={() => setFiltersVisible(!filtersVisible)}
                hasSearch={!!search}
                selected={isAnyFilterActive}
                disabled={isFiltersDisabledBySearchScope}
                refElement={toggleButtonRef}
              />
            )}

            {!!search && !isMobileView && (
              <Div margin={{ left: 'sm' }}>
                <Memo comparables={['search']}>
                  <Searchfield
                    search={search}
                    initialScope={search.scopeParam ? queryParams[search.scopeParam] : ''}
                    initialTerm={queryParams[search.param]}
                  />
                </Memo>
              </Div>
            )}
          </Flex>
        </Flex>
      </Page.Row>

      <FilterDialog
        data-testid="filter-settings-dialog"
        title={t('common.filterSettings.label')}
        isOpen={filtersVisible}
        acceptButtonText={t('common.filterSettings.done')}
        acceptButtonDisabled={false}
        acceptButtonDataTestid="filter-settings-apply"
        onAcceptClick={() => {
          setFiltersVisible(false);
        }}
        closeButtonText={t('common.filterSettings.clear')}
        closeButtonDisabled={!isAnyFilterActive}
        closeButtonDataTestid="filter-settings-clear"
        onCloseClick={() => {
          setFiltersVisible(false);
          clearAllFilters();
        }}
        refElement={dialogRef}
      >
        {!!search && isMobileView && (
          <Div data-testid="list-searchfield-row" margin={{ bottom: 'sm' }}>
            <Memo comparables={['search']}>
              <Searchfield
                search={search}
                initialScope={search.scopeParam ? queryParams[search.scopeParam] : ''}
                initialTerm={queryParams[search.param]}
                label={labels.searchInMobileView}
              />
            </Memo>
          </Div>
        )}

        <Div hidden={hideFilters} data-testid="list-filters-row">
          <Flex pileVerticallyTo="sm" alignRight centeredVertically directionColumn>
            {
              // eslint-disable-next-line
              filters.map((filter, index) => {
                const topMargin: MarginProps = { top: index > 0 ? 'sm' : 'zero' };
                switch (filter.type) {
                  case 'DATE_RANGE':
                    return (
                      <Div
                        key={`filter-${filter['data-testid']}-${index}`}
                        margin={topMargin}
                        style={{ width: '100%' }}
                      >
                        <Memo comparables={['filter', 'initialValues']}>
                          <DateFilter
                            filter={filter}
                            initialFrom={queryParams[filter.fromParam]}
                            initialTill={queryParams[filter.tillParam]}
                            registerOnClearListener={registerOnClearListener}
                          />
                        </Memo>
                      </Div>
                    );

                  case 'DROPDOWN':
                    return (
                      <Div
                        key={`filter-${filter['data-testid']}-${index}`}
                        margin={topMargin}
                        style={{ width: '100%' }}
                      >
                        <Memo comparables={['filter', 'initialValue']}>
                          <DropdownFilter
                            filter={filter}
                            initialValue={queryParams[filter.param]}
                            registerOnClearListener={registerOnClearListener}
                          />
                        </Memo>
                      </Div>
                    );

                  case 'DATE_TYPE':
                    return (
                      <Div
                        key={`filter-${filter['data-testid']}-${index}`}
                        margin={topMargin}
                        style={{ width: '100%' }}
                      >
                        <Memo comparables={['filter', 'initialValue']}>
                          <DateTypeFilter
                            filter={filter}
                            initialValue={queryParams[filter.param]}
                            registerOnClearListener={registerOnClearListener}
                          />
                        </Memo>
                      </Div>
                    );
                }
              })
            }
          </Flex>
        </Div>
      </FilterDialog>

      {isMobileView && showFilters && (
        <Page.Row className={styles.pageRow} data-testid="list-active-filters-row">
          <ActiveFilters
            activeFilters={appliedFilters}
            clearAllFilters={clearAllFilters}
            clearFilter={clearFilter}
            toggleFilterDialog={() => setFiltersVisible(true)}
          />
        </Page.Row>
      )}

      {isMobileView && hasAdditionalActions && (
        <Page.Row className={styles.pageRow} alignTextToRight>
          {listConfig && (
            <EditDialogToggle listConfig={listConfig} onOpen={() => setEditModalVisible(true)} />
          )}

          {excelDownloadLink}
        </Page.Row>
      )}

      {!isMobileView && (showFilters || hasAdditionalActions) && (
        <Page.Row className={styles.pageRow} data-testid="list-active-filters-row">
          <Flex spaceBetween centeredVertically className={styles.filterWrapper}>
            <ActiveFilters
              activeFilters={appliedFilters}
              clearAllFilters={clearAllFilters}
              clearFilter={clearFilter}
              toggleFilterDialog={() => setFiltersVisible(true)}
            />

            <Flex style={{ gap: '1.6rem' }}>
              {listConfig && (
                <EditDialogToggle
                  listConfig={listConfig}
                  onOpen={() => setEditModalVisible(true)}
                />
              )}

              {excelDownloadLink}
            </Flex>
          </Flex>
        </Page.Row>
      )}

      {listConfig && (
        <EditDialog
          listConfig={listConfig}
          isOpen={editModalVisible}
          onClose={() => setEditModalVisible(false)}
        />
      )}

      {!!infoNotice && (
        <Page.Row className={styles.pageRow} responsiveFullWidth data-testid="list-info-notice-row">
          {infoNotice}
        </Page.Row>
      )}

      {!!sortDropdown && (
        <Page.Row className={styles.pageRow} shown={showSortDropdown} data-testid="list-sorter-row">
          {sortDropdown}
        </Page.Row>
      )}
    </div>
  );
};

const collectParamNames = (
  search: SearchDefinition | undefined,
  filters: Array<FilterDefinition>
) => {
  const params: string[] = [];
  if (search) {
    params.push(search.param);
    if (search.scopeParam) {
      params.push(search.scopeParam);
    }
  }

  filters.forEach((f) => {
    if (f.type === 'DROPDOWN' || f.type === 'DATE_TYPE') {
      params.push(f.param);
    } else {
      params.push(f.fromParam);
      params.push(f.tillParam);
    }
  });

  return params;
};

const cleanQueryParams = (
  search: SearchDefinition | undefined,
  filters: Array<FilterDefinition>,
  queryParams: QueryParams
): QueryParams => {
  const cleanedQueryParams: QueryParams = {};
  const collect = (paramName?: string) => {
    if (paramName && typeof queryParams[paramName] !== 'undefined') {
      cleanedQueryParams[paramName] = queryParams[paramName];
    }
  };

  if (search) {
    collect(search.param);
    collect(search.scopeParam);
  }

  filters.forEach((f) => {
    switch (f.type) {
      case 'DATE_RANGE': {
        collect(f.fromParam);
        collect(f.tillParam);
        break;
      }
      case 'DATE_TYPE':
      case 'DROPDOWN': {
        const param = queryParams[f.param];
        if (f.options.some((o) => o.value === param)) {
          collect(f.param);
        }
        break;
      }
    }
  });

  return cleanedQueryParams;
};

const isSearchApplied = (
  search: SearchDefinition | undefined,
  queryParams: QueryParams
): boolean => {
  if (search) {
    const { param, queryActivationThresholdLength = 0, scopeParam, scopeOptions } = search;
    const hasSufficientTerm: boolean =
      (queryParams[param] || '').length > queryActivationThresholdLength;
    const hasSufficientScope: boolean =
      !(scopeParam && scopeOptions) ||
      scopeOptions.some((o) => o.value === queryParams[scopeParam]);
    return hasSufficientTerm && hasSufficientScope;
  }

  return false;
};

const isFiltersDisabled = (search: SearchDefinition | undefined, queryParams: QueryParams) => {
  if (!!search && !!search.scopeParam && !!search.scopeOptions) {
    const { scopeParam, scopeOptions } = search;
    const scope = queryParams[scopeParam];
    return (
      (scopeOptions.find((o) => o.value === scope)?.disableFilters ?? false) &&
      !!queryParams[search.param]
    );
  }

  return false;
};

const isAnySignificantFilterSet = (filters: Array<FilterDefinition>, queryParams: QueryParams) => {
  return filters.some((f) => {
    switch (f.type) {
      case 'DROPDOWN':
        return (
          !!queryParams[f.param] && (f.hasNoneOption || queryParams[f.param] !== f.options[0].value)
        );
      case 'DATE_TYPE':
        return false;
      case 'DATE_RANGE':
        return !!queryParams[f.fromParam] || !!queryParams[f.tillParam];
      /* istanbul ignore next */
      default:
        return false;
    }
  });
};

const isAnyFilterImplicitSet = (filters: Array<FilterDefinition>) => {
  return filters.some((f) => {
    return f.type === 'DATE_RANGE' ? f.fromPlaceholder || f.tillPlaceholder : false;
  });
};

const collectAppliedFilters = (
  filters: Array<FilterDefinition>,
  queryParams: QueryParams
): Array<ActiveFilter> => {
  const result: Array<ActiveFilter> = [];
  filters.forEach((f) => {
    switch (f.type) {
      case 'DATE_RANGE':
        result.push(mapActiveDateFromFilterFromFilterDefinition(f, queryParams));
        result.push(mapActiveDateTillFilterFromFilterDefinition(f, queryParams));
        break;
      case 'DATE_TYPE':
        result.push(mapActiveDateTypeFilterFromFilterDefinition(f, queryParams));
        break;
      case 'DROPDOWN':
        result.push(mapActiveDropdownFilterFromFilterDefinition(f, queryParams));
        break;
    }
  });
  return result.filter(({ value }) => !!value);
};

const toNationalDateString = toDateString(DateFormats.DATE_NATIONAL.formatter);

const mapActiveDateFromFilterFromFilterDefinition = (
  filter: DateFilterDefinition,
  queryParams: QueryParams
): ActiveFilter => {
  const defaultValue = filter.fromPlaceholder && toNationalDateString(filter.fromPlaceholder);
  const currentValue = queryParams[filter.fromParam] ?? (defaultValue || '');
  return {
    param: filter.fromParam,
    paramType: 'DATE_FROM',
    value: currentValue,
    label: formatDatetime(currentValue, DateFormats.DATE_NATIONAL_SHORT),
    inputElementLabel: filter.label,
    implicit: !queryParams[filter.fromParam],
    implicitTooltip: filter.helperTooltip
  };
};

const mapActiveDateTillFilterFromFilterDefinition = (
  filter: DateFilterDefinition,
  queryParams: QueryParams
): ActiveFilter => {
  const defaultValue = filter.tillPlaceholder && toNationalDateString(filter.tillPlaceholder);
  const currentValue = queryParams[filter.tillParam] ?? (defaultValue || '');
  return {
    param: filter.tillParam,
    paramType: 'DATE_TILL',
    value: currentValue,
    label: formatDatetime(currentValue, DateFormats.DATE_NATIONAL_SHORT),
    inputElementLabel: filter.label,
    implicit: !queryParams[filter.tillParam],
    implicitTooltip: filter.helperTooltip
  };
};

const mapActiveDateTypeFilterFromFilterDefinition = (
  filter: DateTypeFilterDefinition,
  queryParams: QueryParams
): ActiveFilter => {
  const defaultOption = filter.options[0];
  const currentValue = queryParams[filter.param] ?? defaultOption.value;
  return {
    param: filter.param,
    paramType: 'DATE_TYPE',
    value: currentValue,
    label: filter.options.find((o) => o.value === currentValue)?.label ?? defaultOption.label,
    inputElementLabel: filter.label,
    implicit: defaultOption.value === currentValue
  };
};

const mapActiveDropdownFilterFromFilterDefinition = (
  filter: DropdownFilterDefinition,
  queryParams: QueryParams
): ActiveFilter => {
  const defaultOption = filter.hasNoneOption ? { value: '', label: '' } : filter.options[0];
  const currentValue = queryParams[filter.param] ?? defaultOption.value;
  return {
    param: filter.param,
    paramType: 'DROPDOWN',
    value: currentValue,
    label: filter.options.find((o) => o.value === currentValue)?.label ?? defaultOption.label,
    inputElementLabel: filter.label,
    implicit: defaultOption.value === currentValue
  };
};
