import { Div, Input } from '@gaiads/telia-react-component-library';
import { ModalDrawer } from 'common-components';
import { aiAssistant } from 'doings/track/analyticsEvents';
import trackEvent from 'doings/track/trackEvent';
import useAskForHelp from 'hooks/askForHelp/useAskForHelp';
import { useClearConversation } from 'hooks/askForHelp/useClearConversation';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeAskForHelpDrawer,
  resetConversation,
  setConversationHistory,
  setLastQuestion
} from 'redux/slices/askForHelpSlice';
import { RootState } from 'redux/store';
import { EXTENDED_SEARCH_TEXT_MAX_LENGTH } from 'types/form';

import AskForHelpConversation from './AskForHelpConversation/AskForHelpConversation';
import AskForHelpConversationNotice from './AskForHelpConversation/AskForHelpConversationNotice';

const SEARCH_FIELD_FOCUS_DELAY: Time.Milliseconds = 100;

const AskForHelpDrawer: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const inputRef = React.useRef<HTMLInputElement>(null);

  const { askForHelp, response, loading, hasError } = useAskForHelp();
  const { clearConversation } = useClearConversation();

  const isDrawerOpen = useSelector((state: RootState) => state.askForHelpDrawer.isDrawerOpen);
  const lastQuestion = useSelector((state: RootState) => state.askForHelpDrawer.lastQuestion);
  const history = useSelector((state: RootState) => state.askForHelpDrawer.history);

  const [currentQuestion, setCurrentQuestion] = useState<string | undefined>();

  const focusInputField = () => {
    if (inputRef.current) {
      inputRef.current.focus({ preventScroll: true });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      focusInputField();
    }, SEARCH_FIELD_FOCUS_DELAY);
  }, [isDrawerOpen]);

  useEffect(() => {
    if (isDrawerOpen && !history?.length && !!lastQuestion && !loading) {
      askForHelp(lastQuestion);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDrawerOpen]);

  useEffect(() => {
    if (!loading) {
      focusInputField();
      if (!hasError && response) {
        dispatch(setConversationHistory(response));
        setCurrentQuestion('');
      }
    }
  }, [loading, hasError, response, dispatch]);

  const sendQuestion = () => {
    if (currentQuestion?.trim()) {
      dispatch(setLastQuestion(currentQuestion));
      askForHelp(currentQuestion);
    }
  };

  const clearChat = () => {
    trackEvent(aiAssistant.clearConversationFromDrawer);
    dispatch(resetConversation());
    clearConversation();
    setCurrentQuestion('');
    focusInputField();
  };

  const closeDrawer = () => {
    setCurrentQuestion('');
    dispatch(closeAskForHelpDrawer());
  };

  return (
    <ModalDrawer
      title={t('askForHelp.drawer.title')}
      tag={{ labelKey: 'askForHelp.drawer.betaTag', variant: 'beta' }}
      buttons={{
        modalButtons: {
          accept: {
            label: t('askForHelp.drawer.clearConversation'),
            disabled: loading,
            testIdSuffix: 'ask-for-help--drawer-clear',
            onClick: clearChat
          },
          close: {
            label: t('common.closeButton.label'),
            testIdSuffix: 'ask-for-help--drawer-close',
            onClick: closeDrawer
          },
          closeIsDefault: true
        }
      }}
      onClose={closeDrawer}
      isOpen={isDrawerOpen}
    >
      <Div>
        <AskForHelpConversation
          lastQuestion={lastQuestion}
          currentQuestion={currentQuestion}
          history={history}
          answerLoading={loading}
          answerLoadingFailed={!loading && hasError}
        />

        <Div margin={{ right: 'lg' }}>
          <Input
            inputRef={inputRef}
            data-testid="ask-for-help--drawer--input-field"
            id="ask-for-help--drawer--input-field"
            disabled={loading}
            value={currentQuestion}
            onChange={(_event, value) => setCurrentQuestion(value)}
            label={t('askForHelp.drawer.askSomething')}
            placeholder={t('askForHelp.drawer.placeholder')}
            maxLength={EXTENDED_SEARCH_TEXT_MAX_LENGTH}
            onKeyUp={(event) => {
              event.key === 'Enter' && sendQuestion();
            }}
          />

          <AskForHelpConversationNotice variant="answerDisclaimer" />
        </Div>
      </Div>
    </ModalDrawer>
  );
};

export default AskForHelpDrawer;
