import { FileItemShape } from 'common-components/FileUploader/FileUploader';
import validateMessageAttachment from 'doings/validateMessageAttachment/validateMessageAttachment';
import { useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Attachment = FileItemShape & {
  file: File;
};

type AttachmentActions = { type: 'create'; files: Attachment[] } | { type: 'delete'; id: string };

export type TicketAttachments = {
  attachments: File[];
  fileItems: Attachment[];
  attachFiles: (_event: React.ChangeEvent<HTMLInputElement>, files: FileItemShape[]) => void;
  isValid: boolean;
  required: boolean;
  setRequired: (value: boolean) => void;
  touched: boolean;
  setTouched: (value: boolean) => void;
};

const reducer = (state: Attachment[], action: AttachmentActions) => {
  switch (action.type) {
    case 'create':
      return [...state, ...action.files];
    case 'delete':
      return state.filter(({ name }) => name !== action.id);
  }
};

export default (attachments?: File[], allowedAttachmentTypes?: string[]): TicketAttachments => {
  const { t } = useTranslation();
  const [touched, setTouched] = useState<boolean>(false);
  const [required, setRequired] = useState<boolean>(false);

  const fileNameExists = (newFile: File): boolean =>
    fileItems.some(({ file }: Attachment) => newFile.name === file.name);

  const mapFileItemToAttachment = (file: File) => ({
    name: file.name,
    onDelete: () => dispatch({ type: 'delete', id: file.name }),
    file,
    message: validateMessageAttachment(t, file, allowedAttachmentTypes)
  });

  const setAttachments = (attachments: File[]) =>
    attachments.filter((file) => !fileNameExists(file)).map(mapFileItemToAttachment);

  const [fileItems, dispatch] = useReducer(
    reducer,
    attachments?.map(mapFileItemToAttachment) ?? []
  );

  const attachFiles = (_event: React.ChangeEvent<HTMLInputElement>, files: FileItemShape[]) => {
    if (!touched) {
      setTouched(true);
    }
    dispatch({ type: 'create', files: setAttachments(files as File[]) });
  };

  return {
    attachments: fileItems.map(({ file }) => file),
    fileItems,
    attachFiles,
    isValid: fileItems.length > 0 ? !fileItems.some(({ message }) => message) : !required,
    required,
    setRequired,
    touched,
    setTouched
  };
};
