import { Gutter } from '@gaiads/telia-react-component-library';
import { BillingGroupPageType } from 'B2XApp/Invoicing/BillingGroups/BillingGroupPage';
import BillingGroupFormButtons from 'B2XApp/Invoicing/BillingGroups/Common/BillingGroupFormButtons';
import BillingGroupFormFinvoiceBlock from 'B2XApp/Invoicing/BillingGroups/Common/BillingGroupFormFinvoiceBlock/BillingGroupFormFinvoiceBlock';
import {
  DataPanel,
  DetailsLoadingOverlay,
  Forms,
  Heading,
  InlineNotification
} from 'common-components';
import ModelDropdown from 'common-components/ModelDropdown/ModelDropdown';
import ModelTextInput from 'common-components/ModelTextInput/ModelTextInput';
import { useUserData } from 'contexts/UserContext/UserContext';
import { Div } from 'core-components';
import getCompanyCodeByGroupId from 'doings/getCompanyCodeByGroupId/getCompanyCodeByGroupId';
import { AnalyticsEvent } from 'doings/track/analyticsEvents';
import { TextInputModel } from 'hooks/inputModels/useTextInputModel/useTextInputModel';
import useIsSubscriptionInCompany from 'hooks/mobileSubscriptions/useIsSubscriptionInCompany/useIsSubscriptionInCompany';
import { useConfirmationModal } from 'hooks/useCustomModal/useConfirmationModal';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CollectionGroup } from 'types/collection-group';

import BillingGroupFormContactInfo from '../Common/BillingGroupFormContactInfo';
import BillingGroupFormBillingInfo from './BillingGroupFormBillingInfo';
import BillingGroupFormPayerInfo from './BillingGroupFormPayerInfo';
import { BillingGroupFormData } from './useBillingGroupForm';

export const setRequiredFields = (fields: TextInputModel[], noneRequired: boolean) => {
  const hasSomeValues = fields.some((field) => !!field.value);
  fields.forEach((field) => {
    field.setRequired(noneRequired ? false : !!field.value || !hasSomeValues);
  });
};

const BillingGroupForm: React.FC<{
  form: BillingGroupFormData;
  failureNoticeOnPersisting: { title: string; content: string };
  analyticsEvent: AnalyticsEvent;
  collectionGroup?: CollectionGroup;
  pageType: BillingGroupPageType;
  'data-testid'?: string;
}> = ({
  form,
  failureNoticeOnPersisting,
  analyticsEvent,
  collectionGroup,
  pageType,
  'data-testid': testId
}) => {
  const { t } = useTranslation();
  const disallowMutation = ['unavailable', 'precheck-failure', 'persisting'].includes(form.step);
  const { setConfirmationModal, isChanged } = useConfirmationModal();
  const canSave =
    ['initial', 'failure'].includes(form.step) && form.isValid && (form.isCopy || isChanged);
  const canCancel = !['precheck-failure', 'persisting'].includes(form.step);
  const billingType = form.fields.billingType.value;
  const hasForeignAddress = collectionGroup?.address?.foreign;
  const selectedOperator = form.fields.netOperator.value.toUpperCase();

  const smsInvoicePhonenumberField = form.fields.smsInvoicePhoneNumber;
  const { activeGroupId, allCompanies } = useUserData();

  const { subscriptionInCompany, loading: validatingSmsInvoiceMsisdn } = useIsSubscriptionInCompany(
    {
      subscriptionId: smsInvoicePhonenumberField.value,
      companyCode: getCompanyCodeByGroupId({
        groupId: form.fields.relatedCompany.value || activeGroupId,
        allCompanies
      }),
      skip: !!smsInvoicePhonenumberField.value && !smsInvoicePhonenumberField.isValid
    }
  );

  const finvoiceFields = form.finvoiceFields;
  const hasFinvoiceFields = !!finvoiceFields;
  const requiresFinvoice = billingType === 'NET' && selectedOperator === 'PEPPOL';

  useEffect(() => {
    if (hasFinvoiceFields) {
      setRequiredFields(
        [finvoiceFields?.finvoiceOrderId, finvoiceFields?.finvoiceBuyerReference],
        !requiresFinvoice
      );
    }
  }, [
    hasFinvoiceFields,
    finvoiceFields?.finvoiceOrderId,
    finvoiceFields?.finvoiceBuyerReference,
    requiresFinvoice
  ]);

  useEffect(() => {
    const values = Object.entries(form.fields).reduce((object, [key, value]) => {
      if (value.value !== value.initialValue) {
        object[key] = String(value.value);
      }
      return object;
    }, {} as Record<string, string>);
    setConfirmationModal(values);
  }, [form.fields, setConfirmationModal]);

  return (
    <DataPanel data-testid={testId}>
      <DetailsLoadingOverlay showLoader={form.step === 'persisting'}>
        <Forms.RequiredFieldsLabel float />

        <Heading.H4>{t('invoices.billingGroup.form.payerInfo.title')}</Heading.H4>

        {form.fields.relatedCompany.options.length > 0 && (
          <Div margin={{ top: 'sm' }}>
            <ModelDropdown
              data-testid="collection-group-form__related-company"
              label={t('common.company.label')}
              name="related-company"
              inputModel={form.fields.relatedCompany}
              disabled={form.fields.relatedCompany.disabled}
            />
          </Div>
        )}

        {form.step === 'precheck-failure' && (
          <Div margin={{ top: 'sm' }}>
            <InlineNotification
              variant="error"
              title={form.precheckNotice?.title ?? ''}
              content={form.precheckNotice?.content ?? ''}
              data-testid="collection-group-form__precheck-notice"
            />
          </Div>
        )}

        <Gutter size="sm" />

        <DetailsLoadingOverlay showLoader={form.step === 'prechecking'}>
          <BillingGroupFormPayerInfo
            fields={form.fields}
            disallowMutation={disallowMutation}
            hasForeignAddress={hasForeignAddress}
          />

          <Gutter size="xlg" />

          <BillingGroupFormBillingInfo
            fields={form.fields}
            disallowMutation={disallowMutation}
            billingType={billingType}
            deliveryMethod={collectionGroup?.type?.deliveryMethod}
            selectedOperator={selectedOperator}
            isEditing={pageType === 'edit'}
          />

          <Gutter size="xlg" />

          <Heading.H4>{t('invoices.billingGroup.form.smsInvoice.title')}</Heading.H4>

          <Gutter size="sm" />

          <ModelTextInput
            data-testid="collection-group-form__sms-invoice"
            label={t('invoices.billingGroup.form.smsInvoice.smsInvoiceLabel')}
            placeholder={t('invoices.billingGroup.form.smsInvoice.smsInvoicePlaceholder')}
            inputModel={{
              ...smsInvoicePhonenumberField,
              isValid: !validatingSmsInvoiceMsisdn && form.fields.smsInvoicePhoneNumber.isValid,
              errorMessage:
                !smsInvoicePhonenumberField.errorMessage &&
                !validatingSmsInvoiceMsisdn &&
                subscriptionInCompany === false
                  ? t('invoices.billingGroup.form.smsInvoice.notInCompany')
                  : smsInvoicePhonenumberField.errorMessage
            }}
            disabled={disallowMutation}
            hardMaxLength
            type="tel"
          />

          {form.finvoiceAvailable && finvoiceFields && billingType !== 'EMAIL' && (
            <>
              <Gutter size="xlg" />

              <Heading.H4>{t('invoices.billingGroup.form.finvoice.title')}</Heading.H4>

              <Gutter size="sm" />

              <BillingGroupFormFinvoiceBlock
                finvoice={finvoiceFields}
                disallowMutation={disallowMutation}
                data-testid="collection-group-form__finvoice-block"
              />
            </>
          )}

          <Gutter size="xlg" />

          <BillingGroupFormContactInfo fields={form.fields} disallowMutation={disallowMutation} />

          <Gutter size="xlg" />

          {form.step === 'failure' && (
            <>
              <InlineNotification
                variant="error"
                title={failureNoticeOnPersisting.title}
                content={failureNoticeOnPersisting.content}
                data-testid="collection-group-form__failure-notice"
              />

              <Gutter />
            </>
          )}

          <BillingGroupFormButtons
            dataTestIdPrefix="collection-group"
            trackOnSave={analyticsEvent}
            saveEnabledWhen={{ onePermission: 'BILLING' }}
            cancelEnabledWhen={{ onePermission: 'BILLING' }}
            onSave={form.onSave}
            onCancel={form.onCancel}
            canSave={canSave && form.step !== 'persisting'}
            canCancel={canCancel && form.step !== 'persisting'}
          />
        </DetailsLoadingOverlay>
      </DetailsLoadingOverlay>
    </DataPanel>
  );
};

export default BillingGroupForm;
