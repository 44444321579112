import { TFunction } from 'i18next';
import last from 'lodash/last';

/**
 * 5,242,880 bytes = 5 MB.
 */
export const MAX_ATTACHMENT_SIZE_IN_BYTES = 5242880;
export const SUPPORTED_FILE_TYPES = [
  '7z',
  'bmp',
  'csv',
  'doc',
  'docm',
  'docx',
  'eml',
  'gif',
  'jpeg',
  'jpg',
  'mp3',
  'pcap',
  'pdf',
  'png',
  'ppt',
  'pptx',
  'rtf',
  'txt',
  'wav',
  'xls',
  'xlsm',
  'xlsx',
  'xltm',
  'xml',
  'zip'
];

export default (
  t: TFunction,
  attachment: File,
  allowedAttachmentTypes?: string[]
): string | undefined => {
  if (attachment.size > MAX_ATTACHMENT_SIZE_IN_BYTES) {
    return t('tickets.replyToTicket.attachment.errorFileSize');
  }

  const supportedFileTypes = allowedAttachmentTypes?.length
    ? allowedAttachmentTypes
    : SUPPORTED_FILE_TYPES;
  const attachmentType = last(attachment.name.split('.')) || '';

  if (!supportedFileTypes.includes(attachmentType.toLocaleLowerCase())) {
    return t('tickets.replyToTicket.attachment.errorFileType');
  }

  return undefined;
};
