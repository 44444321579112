import { BodyText, Div, Flex, Icon } from '@gaiads/telia-react-component-library';
import { useSubmenuState } from 'contexts/SubmenuContext/SubmenuContext';
import { useTranslation } from 'react-i18next';
import { AuthorizationParams } from 'types/authorization';
import { NavigationItem } from 'types/navigation';

import styles from './NavigationMenu.module.scss';
import NavigationMenuItem from './NavigationMenuItem/NavigationMenuItem';

const NavigationMenu: React.FC<{
  itemId: string;
  labelI18nKey: string;
  enabledWhen?: AuthorizationParams;
  navigationItems: NavigationItem[];
  ['data-testid']?: string;
  active?: boolean;
}> = ({ itemId, labelI18nKey, navigationItems, 'data-testid': testId, active = false }) => {
  const { t } = useTranslation();
  const { openMenuId, setOpenMenuId } = useSubmenuState();

  const isMenuOpen = openMenuId === itemId;
  const menuIdToOpenOrClose = isMenuOpen ? '' : itemId;

  return (
    <Div className={styles.navigationMenu} data-testid={testId ?? 'navigation-menu'}>
      <Flex
        className={styles.navigationMenu_menuButtonContainer}
        data-testid={`navigation-menu-${itemId}`}
        onClick={() => setOpenMenuId(menuIdToOpenOrClose)}
        padding={{ horizontal: 'xs', bottom: 'xxs' }}
        margin={{ bottom: 'xxs' }}
        centeredVertically
        clickableAppearance
        tabIndex={0}
        onKeyUp={(e: React.KeyboardEvent<HTMLDivElement>) => {
          e.key === 'Enter' && setOpenMenuId(menuIdToOpenOrClose);
        }}
        aria-label={t(labelI18nKey)}
        aria-expanded={isMenuOpen}
        role="button"
      >
        <BodyText
          className={{
            [styles.navigationMenu_menuButton]: true,
            [styles.navigationMenu_menuButton__active]: active
          }}
          singleLine
        >
          {t(labelI18nKey)}
        </BodyText>

        <Icon name={isMenuOpen ? 'chevron-up' : 'chevron-down'} size="sm" aria-hidden />
      </Flex>

      {isMenuOpen && (
        <Div
          data-testid={`navigation-items-container-${itemId}`}
          className={styles.navigationMenu_itemContainer}
          backgroundColor="white"
          boxShadow="sm"
        >
          {navigationItems.map(({ id, labelI18nKey, to, trackForTopNavigation, ...rest }) => (
            <Div key={id} className={styles.navigationMenu_itemContainer_item}>
              <NavigationMenuItem
                data-testid={`navigation-menu-item-${id}`}
                itemId={id}
                to={to}
                analyticsEvent={trackForTopNavigation}
                labelI18nKey={labelI18nKey}
                {...rest}
              />
            </Div>
          ))}
        </Div>
      )}
    </Div>
  );
};

export default NavigationMenu;
