import { Div, Loader } from '@gaiads/telia-react-component-library';
import getClassNames from 'classnames';
import { Trans } from 'react-i18next';

import styles from './DetailsLoadingOverlay.module.scss';

/**
 * Renders a transparent overlay with a centered loading spinner on
 * top of it when active. An inactive overlay renders nothing. Use
 * in dynamic forms, tables or other places where the user's input
 * invokes backend calls, the results of which can change the nested
 * component's layout.
 *
 * A loading text can be provided to be displayed beneath the loading
 * spinner. This spinner is displayed after a short delay, so as to
 * provide relevance only when the corresponding action is taking at
 * least some time. Abstained if absent. Keep the loading text short
 * and on point.
 *
 * @param children Nodes to overlay.
 * @param showLoader Whether the overlay should be active. Defaults to `false`.
 * @param loadingText Optional text to display beneath loading spinner.
 */
const DetailsLoadingOverlay: React.FC<{
  children: React.ReactNode;
  showLoader?: boolean;
  loadingText?: string;
}> = ({ children, showLoader, loadingText }) => {
  return (
    <Div className={styles.loadable}>
      <Div
        className={getClassNames([
          styles.loaderOverlay,
          showLoader ? styles.loaderOverlay_active : styles.loaderOverlay_inactive
        ])}
        data-testid="loader-overlay"
      ></Div>

      {showLoader && (
        <Div className={styles.loaderContainer} data-testid="loader-container">
          <Loader size="lg" data-testid="loader" />

          <LoadingText text={loadingText} />
        </Div>
      )}

      {children}
    </Div>
  );
};

const LoadingText: React.FC<{ text?: string }> = ({ text }) => {
  if (!text) {
    return null;
  }

  return (
    <Div className={styles.textContainer} data-testid="loading-text">
      <Div className={styles.textContainerContent}>
        <Trans>{text}</Trans>
      </Div>
    </Div>
  );
};

export default DetailsLoadingOverlay;
