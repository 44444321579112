/**
 * Converts a phrase to proper case mid-sentence. Use for converting
 * phrases which are then used within an existing sentence, such as
 * "Subscription number" → [Search by] "subscription number".
 *
 * Words consisting only upper case letters (such as many accronyms)
 * retain their proper case. Considers spaces, hyphens and apostrophes
 * as word separators for accronym case retaining purposes. Hence a
 * phrase like "Phone or SIM card number" becomes → [Search by] "phone
 * or SIM card number".
 *
 * Assumes no proper nouns other than accronyms. If a phrase with
 * proper nouns needs text case conversion to proper case mid-sentece,
 * separate copytexts should be preferred instead of relying on
 * programmatic conversion. Otherwise, a phrase like "Telia M2M
 * subscription" would invalidly be converted to [Mobile subscriptions
 * of type] "telia M2M subscription".
 *
 * Proper conversion examples in alphabetic order:
 * * Card → card
 * * ID → ID
 * * Kort → kort
 * * MSISDN, ICC or SIM card number → MSISDN, ICC or SIM card number
 * * Phone or SIM card number → phone or SIM card number
 * * SIM card number → SIM card number
 * * SIM-kortin numero → SIM-kortin numero
 * * Subscription number → subscription number
 *
 * @param phrase The phrase to convert.
 * @returns A mid-sentence phrase in proper-case.
 */
export const toProperCaseMidSentence = (phrase: string) => {
  const wordsAndSeparators = phrase.trim().match(/[^ \-']+[ \-']?/gi);
  return wordsAndSeparators
    ?.map((w) => (w.toLocaleUpperCase() === w ? w : w.toLocaleLowerCase()))
    .join('');
};
