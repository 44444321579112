import { SubscriptionListItem } from 'types/subscription';

import isSurfCard from './isSurfCard';
import { QueriedSubscription } from './subscriptionsQuery';

const mapSubscription =
  (emptyUsernamePlaceholder: string) =>
  (subscription: QueriedSubscription): SubscriptionListItem => ({
    ...subscription,
    user: subscription.user?.displayName ?? emptyUsernamePlaceholder,
    isSurfCard: isSurfCard(subscription)
  });

export default mapSubscription;
