import InvoiceDetailsPage from 'B2XApp/Invoicing/Invoices/InvoiceDetails/InvoiceDetailsPage';
import useInvoice from 'hooks/invoicing/invoices/useInvoice/useInvoice';
import { useParams } from 'react-router';

interface InvoiceDetailsParams {
  key: string;
}

const InvoiceDetails: React.FC = () => {
  const { key } = useParams<InvoiceDetailsParams>();
  const { invoice, loading, hasError } = useInvoice({ key });

  return (
    <InvoiceDetailsPage
      invoice={invoice}
      loading={loading}
      hasError={hasError}
      data-testid="invoice-details-page"
    />
  );
};

export default InvoiceDetails;
