import routes, { RouteType as Route } from 'B2XApp/appRoutes';
import { IconButton, OverflowMenu, OverflowMenuWrapper } from 'common-components';
import { useOverflowMenuState } from 'contexts/OverflowMenuContext/OverflowMenuContext';
import { useSubmenuState } from 'contexts/SubmenuContext/SubmenuContext';
import { useUserData } from 'contexts/UserContext/UserContext';
import { multiplex } from 'doings/multiplex/multiplex';
import userHasPermission from 'doings/userHasPermission/userHasPermission';
import { useConfirmationGuard } from 'hooks/useCustomModal/useConfirmationGuard';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import styles from './InternalPagesMenu.module.scss';

const InternalPagesMenu: React.FC = () => {
  return (
    <OverflowMenuWrapper>
      <InternalPagesMenuInner />
    </OverflowMenuWrapper>
  );
};

const InternalPagesMenuInner: React.FC = () => {
  const { t } = useTranslation();
  const { closeMenus } = useSubmenuState();
  const { openMenu, toggleMenu } = useOverflowMenuState();
  const isOpen = openMenu === 'internal-pages';
  const interactedWith = useRef(false);

  return (
    <div className={styles.container}>
      <IconButton
        name={isOpen ? 'lock-open' : 'lock'}
        aria-label={t('aria.header.internalPages')}
        aria-expanded={isOpen}
        className={[
          styles.icon,
          multiplex([
            null,
            [isOpen, styles.icon__open],
            [interactedWith.current, styles.icon__close]
          ])
        ]}
        size="md"
        data-testid="internal-pages-menu"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          closeMenus();
          toggleMenu('internal-pages');
          interactedWith.current = true;
        }}
      />

      {isOpen && <InternalPagesOverflowMenu />}
    </div>
  );
};

const InternalPagesOverflowMenu: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const items = useItems();
  const { onGuardedClick } = useConfirmationGuard();
  const { toggleMenu } = useOverflowMenuState();

  return (
    <div
      data-overflow-menu-node="active"
      data-quicklink-container="internal-pages-menu"
      className={styles.menuAnchor}
    >
      <OverflowMenu aria-label={t('aria.header.internalPages')}>
        {items.map((item) => (
          <OverflowMenu.Item
            key={item.key}
            dataTestid={`internal-pages-menu-item-${item.key}`}
            label={t(item.labelI18nKey)}
            onClick={(e) => {
              if (!onGuardedClick(e, item.link)) {
                history.push(item.link);
                toggleMenu('internal-pages');
              }
            }}
          />
        ))}
      </OverflowMenu>
    </div>
  );
};

interface Item {
  key: string;
  link: string;
  labelI18nKey: string;
}

const useItems = (): Item[] => {
  const user = useUserData();
  const hasPermission = userHasPermission(user);
  const { accesses } = user;

  return [
    {
      key: 'analytics-events',
      link: '/analytics-events',
      labelI18nKey: 'topNavigation.internalPages.quicklinks.analyticsEvents'
    },
    {
      key: 'chatbot',
      link: '/chatbot_test_private',
      labelI18nKey: 'topNavigation.internalPages.quicklinks.chatbotTest'
    },
    {
      key: 'chatbot-custom-form',
      link: '/omalomake',
      labelI18nKey: 'topNavigation.internalPages.quicklinks.chatbotCustomForm'
    },
    {
      key: 'flags',
      link: '/flags',
      labelI18nKey: 'topNavigation.internalPages.quicklinks.featureFlags'
    },
    {
      key: 'news-ratings',
      link: '/news-ratings',
      labelI18nKey: 'topNavigation.internalPages.quicklinks.newsRatings'
    },
    {
      key: 'version',
      link: '/version',
      labelI18nKey: 'topNavigation.internalPages.quicklinks.version'
    }
  ].reduce(
    (acc, item) =>
      mapInternalPages(acc, item, [
        (route: Route) => hasExplicitAccessTo(route, accesses),
        (route: Route) => hasPermissionTo(route, hasPermission)
      ]),
    [] as Item[]
  );
};

const mapInternalPages = (acc: Item[], item: Item, checks: Array<(route: Route) => boolean>) => {
  const route = routes.find((route) => route.path === item.link);
  if (route && checks.every((check) => check(route))) {
    acc.push(item);
  }

  return acc;
};

const hasExplicitAccessTo = (route: Route, accesses: AccessControlGroup[]) =>
  !route.emailAccessList || accesses.includes(route.emailAccessList);

const hasPermissionTo = (route: Route, hasPermission: ReturnType<typeof userHasPermission>) =>
  !route.accessibleWhen || hasPermission(route.accessibleWhen);

export default InternalPagesMenu;
