import { BodyText, Div, GridBag, MarginSpace } from '@gaiads/telia-react-component-library';
import {
  BreadcrumbWithCompanySelector,
  Heading,
  InlineNotification,
  ListViewHeader
} from 'common-components';
import { ContentText } from 'common-components/InlineNotification/InlineNotification';
import { useUserData } from 'contexts/UserContext/UserContext';

import styles from './EmptyListContent.module.scss';
import pebble from './pebble-ripple.svg';

const HALF_GRID = 6;
const FULL_GRID = 12;

type EmptyListContentProps = {
  title: string;
  description?: React.ReactNode;
  disclaimer?: ContentText;
  footerContent?: React.ReactElement;
  'data-testid'?: string;
  withBackgroundImage?: boolean;
  occupyHorizontalSpace?: boolean;
};

const EmptyListContent: React.FC<EmptyListContentProps> = ({
  title,
  description = '',
  disclaimer,
  footerContent,
  'data-testid': testId,
  withBackgroundImage = true,
  occupyHorizontalSpace = false
}) => {
  const { hasMultipleCompanies } = useUserData();
  const width = occupyHorizontalSpace ? FULL_GRID : HALF_GRID;
  const contentClass = styles.content;
  const contentCentredClass = {
    [styles.content__verticallyCentered]: !hasMultipleCompanies
  };

  return (
    <Div
      padding={{ vertical: 'lg' }}
      className={contentClass}
      style={withBackgroundImage ? { backgroundImage: `url(${pebble})` } : {}}
      data-testid={testId}
    >
      <MarginSpace>
        <Div padding={{ bottom: hasMultipleCompanies ? 'zero' : 'lg' }}>
          <BreadcrumbWithCompanySelector withoutCompanySelector={!hasMultipleCompanies} />
        </Div>

        <ListViewHeader />

        <Div
          className={contentCentredClass}
          margin={{ vertical: 'xlg' }}
          data-testid="empty-list-content"
        >
          <GridBag>
            <GridBag.Item sm={12} md={width} data-testid="empty-list-content--inner">
              <Heading.H2>{title}</Heading.H2>

              <BodyText tagName="div" margin={{ top: 'md' }} block>
                {description}
              </BodyText>

              {disclaimer && (
                <Div margin={{ top: 'md' }}>
                  <InlineNotification
                    data-testid="empty-list-disclaimer"
                    variant="information"
                    content={disclaimer}
                  />
                </Div>
              )}

              {footerContent && <Div margin={{ top: 'md' }}>{footerContent}</Div>}
            </GridBag.Item>
          </GridBag>
        </Div>
      </MarginSpace>
    </Div>
  );
};

export default EmptyListContent;
