import { flatMap } from 'lodash/fp';

const flattenParents = (node: Element): Element[] =>
  node.parentElement ? [node.parentElement, ...flattenParents(node.parentElement)] : [];

const classListToArray = ({ classList }: { classList: DOMTokenList }) => Array.from(classList);

export default (onCloseClick: (event: React.MouseEvent) => void, containerClassName: string) =>
  (event: React.MouseEvent): void => {
    const parents = flattenParents(event.target as Element);
    const classes = flatMap(classListToArray, parents);
    const parentIsContainer = classes.some((cls) => cls === containerClassName);
    if (!parentIsContainer) {
      onCloseClick(event);
    }
  };
