import { GridBag, Gutter } from '@gaiads/telia-react-component-library';
import BillingGroupBillingInformation from 'B2XApp/Invoicing/BillingGroups/BillingGroupInformation/BillingGroupBillingInformation';
import BillingGroupPayerInformation from 'B2XApp/Invoicing/BillingGroups/BillingGroupInformation/BillingGroupPayerInformation';
import BillingGroupInformationSkeleton from 'B2XApp/Invoicing/BillingGroups/Common/BillingGroupInformationSkeleton';
import CopyBillingGroupButton from 'B2XApp/Invoicing/BillingGroups/Common/CopyBillingGroupButton';
import EditBillingInfoButton from 'B2XApp/Invoicing/BillingGroups/Common/EditBillingInfoButton';
import { useBillingGroupsHelper } from 'B2XApp/Invoicing/BillingGroups/Common/useBillingGroupsHelper';
import { ActionButtonGroup, DataPanel } from 'common-components';
import DetailsPage from 'common-components/DetailsPage/DetailsPage';
import { EN_DASH } from 'doings/dash/dash';
import { invoicing, mobile } from 'doings/track/analyticsEvents';
import { LoadingStatus } from 'hooks/invoicing/billingGroups/useCollectionGroup/useCollectionGroup';
import { ViewStatus } from 'hooks/useApiStatus/useApiStatus';
import { useTranslation } from 'react-i18next';
import { CollectionGroup } from 'types/collection-group';
import { SubscriptionListItem } from 'types/subscription';

import BillingGroupDetailsSubscriptionList from './BillingGroupDetailsSubscriptionList';

const BillingGroupDetailsPage: React.FC<{
  collectionGroup?: CollectionGroup;
  subscriptions: SubscriptionListItem[];
  totalSubscriptions: number;
  groupStatus: LoadingStatus;
  subscriptionStatus: ViewStatus;
}> = ({ collectionGroup, subscriptions, totalSubscriptions, groupStatus, subscriptionStatus }) => {
  const { t } = useTranslation();
  const { goToEditBillingGroupPage, goToCopyBillingGroupPage } = useBillingGroupsHelper();

  return (
    <DetailsPage
      data-testid={`billing-group-details-${groupStatus}`}
      hasError={groupStatus === 'error'}
      loading={groupStatus === 'loading'}
      backButtonPath="/billing-accounts/"
      headline={`${t('billingInformation.collectionGroup')}: ${
        collectionGroup?.collectionGroup || EN_DASH
      }`}
    >
      <GridBag>
        <GridBag.Item md={6} sm={12}>
          <DataPanel
            data-testid="billing-group-payer-information"
            skeletonLoader={<BillingGroupInformationSkeleton />}
            loading={groupStatus === 'loading'}
            title={t('billingInformation.payerInformation')}
            mainPanel
          >
            {collectionGroup && (
              <>
                <BillingGroupPayerInformation collectionGroup={collectionGroup} />

                <Gutter />

                <ActionButtonGroup>
                  <EditBillingInfoButton
                    onClick={() =>
                      goToEditBillingGroupPage(
                        collectionGroup?.collectionGroup,
                        collectionGroup?.customerCode
                      )
                    }
                    analyticsEvent={invoicing.updateCollectionGroup}
                  />

                  <CopyBillingGroupButton
                    onClick={() =>
                      goToCopyBillingGroupPage(
                        collectionGroup?.collectionGroup,
                        collectionGroup?.customerCode
                      )
                    }
                    analyticsEvent={mobile.copyBillingAccount}
                    canCopy={collectionGroup?.canCopy}
                  />
                </ActionButtonGroup>
              </>
            )}
          </DataPanel>
        </GridBag.Item>

        <GridBag.Item md={6} sm={12}>
          <DataPanel
            data-testid="billing-group-billing-information"
            skeletonLoader={<BillingGroupInformationSkeleton />}
            loading={groupStatus === 'loading'}
            title={t('billingInformation.title')}
          >
            {collectionGroup && (
              <BillingGroupBillingInformation collectionGroup={collectionGroup} />
            )}
          </DataPanel>
        </GridBag.Item>
      </GridBag>

      <Gutter size="sm" />

      <GridBag>
        <GridBag.Item lg={12} md={12}>
          <BillingGroupDetailsSubscriptionList
            collectionGroup={collectionGroup?.collectionGroup}
            subscriptions={subscriptions}
            totalSubscriptions={totalSubscriptions}
            status={subscriptionStatus}
          />
        </GridBag.Item>
      </GridBag>
    </DetailsPage>
  );
};

export default BillingGroupDetailsPage;
