import { BodyText, Gutter } from '@gaiads/telia-react-component-library';
import { Paragraph } from '@teliafi/fi-ds';
import { Price } from 'common-components/Price/Price';
import { Divider, Flex } from 'core-components';
import formatDatetime from 'doings/formatDatetime/formatDatetime';
import formatPrice from 'doings/formatPrice/formatPrice';
import { Balance } from 'hooks/mobileSubscriptions/useSubscriptionDetails/subscriptionDetailsQuery';
import { useTranslation } from 'react-i18next';

import BalanceDetailRow from './BalanceDetailRow';

const BalancePanel: React.FC<{ balance: Balance }> = ({
  balance: {
    domesticDataUsed,
    domesticDataCapacity,
    domesticDataUnlimited,
    domesticDataUnit,
    euDataUsed,
    euDataCapacity,
    euDataUnlimited,
    euDataUnit,
    speakDataUsed,
    speakDataCapacity,
    speakDataUnlimited,
    smsPrice,
    grandBalance,
    monthlyPayment,
    billingStartDate,
    billingEndDate
  }
}) => {
  const { t } = useTranslation();
  const formattedBillingStartDate = formatDatetime(billingStartDate);
  const formattedBillingEndDate = formatDatetime(billingEndDate);

  return (
    <>
      <BalanceDetailRow
        data-testid="subscription-usage-domestic"
        used={domesticDataUsed}
        capacity={domesticDataCapacity}
        unlimited={domesticDataUnlimited}
        usageLabel="subscriptions.usage.data"
        usageDescription="subscriptions.usage.dataArea"
        units={domesticDataUnit}
      />

      <BalanceDetailRow
        data-testid="subscription-usage-eu"
        used={euDataUsed}
        capacity={euDataCapacity}
        unlimited={euDataUnlimited}
        usageLabel="subscriptions.usage.euData"
        usageDescription="subscriptions.usage.euDataArea"
        units={euDataUnit}
      />

      <BalanceDetailRow
        data-testid="subscription-usage-voice"
        used={speakDataUsed}
        capacity={speakDataCapacity}
        unlimited={speakDataUnlimited}
        usageLabel="subscriptions.usage.speakData"
        units="min"
      />

      <div>
        <Paragraph variant="paragraph-100">
          <b>{t('subscriptions.usage.messages')}</b>
        </Paragraph>

        <Gutter size="xs" />

        <Paragraph variant="paragraph-100">
          <b style={{ fontWeight: '500' }}>{formatPrice(Number(smsPrice))} €</b>
        </Paragraph>
      </div>

      <Gutter size="sm" />

      <Divider light />

      <Gutter size="xs" />

      <Flex spaceBetween alignBottom pileVerticallyTo="sm">
        <Paragraph variant="paragraph-100">
          <b>{t('subscriptions.usage.priceDetails')}</b>
        </Paragraph>

        <Gutter size="xs" />

        <Flex>
          <Price
            label={t('subscriptions.usage.monthlyPrice')}
            price={Number(monthlyPayment)}
            currency="€"
          />

          <Gutter size="md" />

          <Price
            label={t('subscriptions.usage.balance')}
            price={Number(grandBalance)}
            currency="€"
          />
        </Flex>
      </Flex>

      <Gutter size="xs" />

      <Divider light />

      <Gutter size="xs" />

      <BodyText margin={{ top: 'xxs' }}>
        {t('subscriptions.usage.usageForBillingPeriod', {
          billingStartDate: formattedBillingStartDate,
          billingEndDate: formattedBillingEndDate
        })}
      </BodyText>

      <Gutter size="md" />
    </>
  );
};

export default BalancePanel;
