import { ApolloLink, NextLink, Operation } from '@apollo/client';

export const getAllowMocks = (searchString: string) => searchString.includes('mock=true');

// allowMocks is deliberately places at the root scope
// this ensures that mocks are enabled or disabled for
// the entire browsing session until the next page reload
export const allowMocks = getAllowMocks(window.location.search);

export const toggleMocksOff = () => {
  window.location.search = '';
};

export const getMockLink = () => {
  return new ApolloLink((operation: Operation, forward: NextLink) => {
    if (allowMocks) {
      operation.setContext(({ headers }: { headers: Record<string, string> }) => ({
        headers: {
          mock: 'true',
          ...headers
        }
      }));
    }

    return forward(operation);
  });
};
