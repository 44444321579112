import { InlineNotification, TeliaLink } from 'common-components';
import createCorporatePortalLink from 'doings/links/createCorporatePortalLink/createCorporatePortalLink';

export const ClosedNotice: React.FC<{
  phoneNumberHash: string;
}> = ({ phoneNumberHash }) => (
  <InlineNotification
    title={{ key: 'subscriptions.subscriptionDetails.status.closedTitle' }}
    content={{
      key: 'subscriptions.subscriptionDetails.status.closedMessage',
      components: [
        <TeliaLink.Translatable.External
          key="link"
          variant="standalone"
          to={createCorporatePortalLink('mobileOpen', { msisdn: phoneNumberHash })}
          enabledWhen={{
            onePermission: 'B2B_PORTAL',
            anyPermission: ['MOBILE_SUBSCRIPTIONS']
          }}
        />
      ]
    }}
    variant="information"
    data-testid="closed-notification"
  />
);
