import { Div, SupportedColor } from '@gaiads/telia-react-component-library';
import { BorderRadiusProps } from '@gaiads/telia-react-component-library/build/types/components/Element/getBorderRadiusClasses';
import { multiplex } from 'doings/multiplex/multiplex';

import styles from './Skeleton.module.scss';

type SkeletonSubComponents = {
  Accordion: React.FC<SubcomponentProps>;
  Panel: React.FC<PanelSubcomponentProps>;
  InputField: React.FC<SubcomponentProps>;
  Title: React.FC<SubcomponentProps>;
  TextCell: React.FC<SubcomponentProps>;
  TextRow: React.FC<SubcomponentProps>;
  Button: React.FC<SubcomponentProps>;
  Image: React.FC<ImageSubcomponentProps>;
};

interface SubcomponentProps {
  'data-testid'?: string;
  width?: string;
}

interface ImageSubcomponentProps extends SubcomponentProps {
  width?: string;
  height?: string;
  display?: string;
  rounded?: boolean;
  squared?: boolean;
}

interface PanelSubcomponentProps {
  children?: React.ReactNode;
  backgroundColor?: SupportedColor;
  'data-testid'?: string;
}

const Skeleton: React.FC<{ children?: React.ReactNode }> & SkeletonSubComponents = ({
  children
}) => <Div data-testid="skeleton-container">{children}</Div>;

const SkeletonAccordion: React.FC<SubcomponentProps> = ({
  'data-testid': testId,
  width = '30%'
}) => (
  <Div
    data-testid={testId}
    className={styles.skeleton__accordion}
    padding={{ size: 'md' }}
    borderRadius={{ size: 'md' }}
    backgroundColor="white"
    boxShadow="sm"
    hideOverflow
  >
    <Skeleton.Title width={width} data-testid={`${testId}-title`} />
  </Div>
);

const SkeletonPanel: React.FC<PanelSubcomponentProps> = ({
  children,
  backgroundColor = 'white',
  'data-testid': testId
}) => (
  <Div
    data-testid={testId}
    className={styles.skeleton__panel}
    padding={{ size: 'md' }}
    borderRadius={{ size: 'md' }}
    boxShadow="sm"
    backgroundColor={backgroundColor}
  >
    <Skeleton>{children}</Skeleton>
  </Div>
);

const SkeletonBox: React.FC<{
  'data-testid'?: string;
  className?: string;
  borderRadiusSize?: string;
  style?: React.CSSProperties;
}> = ({ 'data-testid': testId, className, borderRadiusSize = 'sm', style }) => (
  <Div
    data-testid={testId}
    borderRadius={{ size: borderRadiusSize } as BorderRadiusProps}
    className={[styles.skeleton__box, className]}
    style={style}
  />
);

const SkeletonInputField: React.FC<SubcomponentProps> = ({
  'data-testid': testId,
  width = '100%'
}) => (
  <SkeletonBox data-testid={testId} className={styles.skeleton__input_field} style={{ width }} />
);

const SkeletonTextCell: React.FC<SubcomponentProps> = ({
  'data-testid': testId,
  width = '100%'
}) => <SkeletonBox data-testid={testId} className={styles.skeleton__text_cell} style={{ width }} />;

const SkeletonTextRow: React.FC<SubcomponentProps> = ({
  'data-testid': testId,
  width = '100%'
}) => <SkeletonBox data-testid={testId} className={styles.skeleton__text_row} style={{ width }} />;

const SkeletonTitle: React.FC<SubcomponentProps> = ({ 'data-testid': testId, width = '100%' }) => (
  <SkeletonBox data-testid={testId} className={styles.skeleton__heading} style={{ width }} />
);

const SkeletonButton: React.FC<SubcomponentProps> = ({ 'data-testid': testId, width = '100%' }) => (
  <SkeletonBox
    data-testid={testId}
    className={styles.skeleton__button}
    borderRadiusSize="lg"
    style={{ width }}
  />
);

const SkeletonImage: React.FC<ImageSubcomponentProps> = ({
  'data-testid': testId,
  width = '100%',
  height = '100%',
  display = 'block',
  rounded = false,
  squared = false
}) => (
  <SkeletonBox
    data-testid={testId}
    borderRadiusSize={multiplex(['lg', [rounded, 'rounded'], [squared, 'md']])}
    style={{ width, height, display }}
  />
);

Skeleton.Panel = SkeletonPanel;
Skeleton.Accordion = SkeletonAccordion;
Skeleton.InputField = SkeletonInputField;
Skeleton.TextCell = SkeletonTextCell;
Skeleton.TextRow = SkeletonTextRow;
Skeleton.Title = SkeletonTitle;
Skeleton.Button = SkeletonButton;
Skeleton.Image = SkeletonImage;

export default Skeleton;
