import { check } from 'B2XApp/DynamicForms/DynamicForm/dynamicFormFieldCheck';
import { isAfter, isBefore, isWeekend, parseISO, toDate } from 'date-fns';
import validators, { Validator } from 'hooks/inputModels/validation/validators';
import { TFunction } from 'i18next';
import { DynamicForms } from 'types/dynamicForms';
import * as Yup from 'yup';

const MAX_INPUT_FIELD_LENGTH = 400;
const MAX_TEXTAREA_LENGTH = 4000;

const requireIfMandatory = <T extends Yup.AnySchema>(
  schema: T,
  t: TFunction,
  field:
    | DynamicForms.CheckboxField
    | DynamicForms.CheckgroupField
    | DynamicForms.DateField
    | DynamicForms.DropdownField
    | DynamicForms.InputField
    | DynamicForms.TextareaField
): T => {
  return field.mandatory ? schema.required(t('validators.valueIsRequired')) : schema;
};

const truncateDate = (date: Date): Date => {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

const emptyValidator: Validator = {
  validate: () => true,
  i18nKeyOnHint: ''
};

const formatToValidator = (format: DynamicForms.FieldFormat): Validator => {
  switch (format) {
    case 'EMAIL_ADDRESS':
      return validators.emailAddress;
    case 'FINNISH_EDI_CODE':
      return validators.billingFinnishOvtCode;
    case 'FINNISH_IBAN':
      return validators.billingFinnishIBAN;
    case 'FINNISH_IBAN_OR_EDI_CODE':
      return validators.billingFinnishIBANOrOvtCode;
    case 'FINNISH_PHONE':
      return validators.msisdn;
    case 'IMEI':
      return validators.imei;
    case 'INTERNATIONAL_PHONE':
      return validators.internationalMsisdn;
    case 'MAC_ADDRESS':
      return validators.macAddress;
    case 'POSTAL_CODE':
      return validators.nakPostalCode;
    case 'TELIA_B2O_CUSTOMER_SYSTEM_REF':
      return validators.wholesaleCustomerSystemRef;
    case 'TELIA_B2O_SERVICE_ID':
      return validators.wholesaleServiceId;
    default:
      return emptyValidator;
  }
};

const fieldTypeToValidation: Record<
  DynamicForms.FieldType,
  (t: TFunction, field: any) => Yup.AnySchema
> = {
  CHECKBOX: (t, field: DynamicForms.CheckboxField) => {
    return field.mandatory
      ? Yup.boolean()
          .required(t('validators.valueIsRequired'))
          .oneOf([true], t('validators.valueIsRequired'))
      : Yup.boolean();
  },

  CHECKGROUP: (t, field: DynamicForms.CheckgroupField) => {
    return field.mandatory ? Yup.array().min(1, t('validators.requireCheckboxes')) : Yup.array();
  },

  DATE: (t, field: DynamicForms.DateField) => {
    return requireIfMandatory(Yup.date(), t, field)
      .test('start date test', t('validators.dateIsTooEarly'), (value) => {
        return (
          !value ||
          !(
            field.dateRange?.startDate &&
            isBefore(
              truncateDate(toDate(value)),
              truncateDate(parseISO(field.dateRange?.startDate))
            )
          )
        );
      })
      .test('end date test', t('validators.dateIsTooLate'), (value) => {
        return (
          !value ||
          !(
            field.dateRange?.endDate &&
            isAfter(truncateDate(toDate(value)), truncateDate(parseISO(field.dateRange?.endDate)))
          )
        );
      })
      .test('business day test', t('validators.dateIsNotBusinessDay'), (value) => {
        return !value || !(!field.dateAllowsAllDays && isWeekend(value));
      });
  },

  DROPDOWN: (t, field: DynamicForms.DropdownField) => {
    const options: string[] = field.options.map((option) => option.value);
    if (!field.mandatory) {
      options.push('');
    }

    return field.multiple
      ? requireIfMandatory(Yup.array(), t, field).of(
          Yup.string().oneOf(options, t('validators.invalidOptions'))
        )
      : requireIfMandatory(Yup.string(), t, field).oneOf(options, t('validators.invalidOption'));
  },

  INPUT: (t, field: DynamicForms.InputField) => {
    const schema = requireIfMandatory(Yup.string(), t, field);
    if (field.format === undefined || field.format === null) {
      return schema.max(
        MAX_INPUT_FIELD_LENGTH,
        t('validators.valueIsTooLong', { max: MAX_INPUT_FIELD_LENGTH })
      );
    }

    const validator = formatToValidator(field.format);
    return schema.test(field.format, t(validator.i18nKeyOnHint), (value) =>
      value ? validator.validate(value) : true
    );
  },

  TEXTAREA: (t, field: DynamicForms.TextareaField) => {
    return requireIfMandatory(Yup.string(), t, field).max(
      MAX_TEXTAREA_LENGTH,
      t('validators.valueIsTooLong', { max: MAX_TEXTAREA_LENGTH })
    );
  },

  INFOTEXT: () => {
    return Yup.string().notRequired();
  },

  SINK: () => {
    return Yup.string().notRequired();
  },

  SUBHEADER: () => {
    return Yup.string().notRequired();
  }
};

const datetimeFieldValidationSchema = (
  t: TFunction,
  to: number,
  parentDateField: DynamicForms.DateField
) => {
  const options: string[] = [];
  if (!parentDateField.mandatory) {
    options.push('');
  }

  for (let i = 0; i < to; i++) {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const opt = i < 10 ? `0${i}` : `${i}`;
    options.push(opt);
  }

  return requireIfMandatory(Yup.string(), t, parentDateField).oneOf(
    options,
    t('validators.invalidOption')
  );
};

const datetimeFieldHourValidationSchema = (
  t: TFunction,
  parentDateField: DynamicForms.DateField
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
) => datetimeFieldValidationSchema(t, 24, parentDateField);

const datetimeFieldMinuteValidationSchema = (
  t: TFunction,
  parentDateField: DynamicForms.DateField
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
) => datetimeFieldValidationSchema(t, 60, parentDateField);

/**
 * Creates a Yup validation schema based on the specified dynamic form
 * fields with internationalised validation messages.
 *
 * For all fields, the schema element uses its ID, as in `${field.id}`.
 * For date type fields with a time picker, two additional fields are
 * derived and registered based off of the date field: `${field.id}-h`
 * for hours and `${field.id}-m` for minutes. The same fields are made
 * available in the UI via `FormFieldDatePicker`.
 */
export const getValidationSchema = (
  filteredFields: DynamicForms.Field[],
  t: TFunction
): Yup.AnySchema => {
  return Yup.object().shape(
    filteredFields.reduce((schema: Yup.ObjectShape, field) => {
      if (!check.isValidatable(field)) {
        return schema;
      }

      schema[field.id] = fieldTypeToValidation[field.type](t, field);
      if (check.isDate(field) && field.dateWithTimeSelection) {
        schema[`${field.id}-h`] = datetimeFieldHourValidationSchema(t, field);
        schema[`${field.id}-m`] = datetimeFieldMinuteValidationSchema(t, field);
      }

      return schema;
    }, {} as Yup.ObjectShape)
  );
};
