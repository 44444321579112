import { Flex } from '@gaiads/telia-react-component-library';
import { TicketDetailsBoxSkeleton } from 'B2XApp/Messages/common';
import { DataPanel, DefinitionList, DefinitionListMultilineItemProps } from 'common-components';
import usePileVerticallyToMany from 'hooks/usePileVerticallyToMany/usePileVerticallyToMany';
import { useTranslation } from 'react-i18next';

const TicketDetailsBox: React.FC<{
  listItems: DefinitionListMultilineItemProps[];
  loading: boolean;
}> = ({ loading, listItems }) => {
  const { t } = useTranslation();
  const pileVerticallyTo = usePileVerticallyToMany(['xxxs', 'xxs', 'xs', 'sm', 'lg', 'xlg']);
  if (listItems.length === 0 && !loading) {
    return null;
  }

  return (
    <DataPanel
      skeletonLoader={<TicketDetailsBoxSkeleton />}
      loading={loading}
      title={t('tickets.details.cardTitle')}
    >
      <Flex directionColumn occupyVerticalSpace={false}>
        <DefinitionList
          loading={loading}
          addDividers
          fiftyFifty
          pileVerticallyTo={pileVerticallyTo}
        >
          {listItems.map((item) => (
            <DefinitionList.MultilineItem
              key={item.heading}
              heading={t(item.heading)}
              values={item.values}
              type={item.type}
            />
          ))}
        </DefinitionList>
      </Flex>
    </DataPanel>
  );
};

export default TicketDetailsBox;
