import { Panel } from '@gaiads/telia-react-component-library';
import { DefinitionList } from 'common-components';
import { SubscriptionDetails } from 'hooks/mobileSubscriptions/useSubscriptionDetails/subscriptionDetailsQuery';
import { useTranslation } from 'react-i18next';

const TransferSubscriptionDetails: React.FC<{ subscription: SubscriptionDetails }> = ({
  subscription
}) => {
  const { t } = useTranslation();
  const { userName, phoneNumber, type, owner } = subscription;

  return (
    <Panel data-testid="ownership-details-panel">
      <Panel.Content>
        <DefinitionList addDividers fiftyFifty>
          <DefinitionList.MultilineItem heading={t('subscriptions.user')} values={[userName]} />

          <DefinitionList.MultilineItem
            heading={t('subscriptions.phoneNumber')}
            values={[phoneNumber]}
          />

          <DefinitionList.MultilineItem
            heading={t('subscriptions.actions.transfer.subscriptionType')}
            values={[type]}
          />

          <DefinitionList.MultilineItem
            heading={t('subscriptions.actions.transfer.owner')}
            values={[owner?.companyName, owner?.companyCode]}
          />
        </DefinitionList>
      </Panel.Content>
    </Panel>
  );
};

export default TransferSubscriptionDetails;
