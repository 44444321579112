import { Div, MarginSpace } from '@gaiads/telia-react-component-library';
import DataUnavailable from 'B2XApp/Errors/DataUnavailable/DataUnavailable';
import BackButton from 'common-components/BackButton/BackButton';
import RouteBreadcrumb from 'common-components/Breadcrumb/RouteBreadcrumb';
import MobilePadding from 'common-components/MobilePadding/MobilePadding';
import { Flex } from 'core-components';
import useSearchParams from 'hooks/useSearchParams/useSearchParams';
import { useTranslation } from 'react-i18next';

import DetailsPageHeadlineSupplement from './DetailsPageHeadlineSupplement';
import DetailsPageTitle from './DetailsPageTitle';

const DetailsPage: React.FC<{
  headline: string;
  headlineTag?: React.ReactNode;
  headlineSupplement?: React.ReactNode;
  hideHeadline?: boolean;
  loading?: boolean;
  hasError: boolean;
  backButtonLabel?: string;
  backButtonPath?: string;
  breadcrumb?: React.ReactNode;
  'data-testid'?: string;
  children: React.ReactNode;
}> = ({
  headline,
  headlineTag,
  headlineSupplement,
  hideHeadline,
  loading,
  hasError,
  backButtonLabel,
  backButtonPath,
  breadcrumb,
  'data-testid': dataTestid,
  children
}) => {
  const { t } = useTranslation();
  const { preserveBackPathState } = useSearchParams();
  const backTo = preserveBackPathState(backButtonPath);

  if (hasError) {
    return <DataUnavailable data-testid="details-page-data-unavailable" />;
  }

  return (
    <Div data-testid={dataTestid} padding={{ vertical: 'lg' }} occupyVerticalSpace={true}>
      <MarginSpace noPaddingForSmallerThanSm>
        <MobilePadding>
          {breadcrumb ?? <RouteBreadcrumb data-testid="details-page-route-breadcrumb" />}

          <Div data-testid="details-page-headline">
            <Div margin={{ top: 'xlg', bottom: 'md' }}>
              <BackButton
                label={
                  backButtonLabel ??
                  t(backTo ? 'common.backToListButton.label' : 'common.backButton.label')
                }
                path={backTo}
                data-testid="details-page-back-button"
              />
            </Div>

            {!hideHeadline && (
              <Flex centeredVertically pileVerticallyTo="lg" occupyHorizontalSpace>
                <DetailsPageTitle
                  headline={headline}
                  headlineTag={headlineTag}
                  loading={loading}
                  fullWidth={!headlineSupplement}
                  data-testid="details-page-title"
                />

                {!loading && (
                  <DetailsPageHeadlineSupplement
                    headlineSupplement={headlineSupplement}
                    data-testid="details-page-headline-supplement"
                  />
                )}
              </Flex>
            )}
          </Div>
        </MobilePadding>

        {children}
      </MarginSpace>
    </Div>
  );
};

export default DetailsPage;
