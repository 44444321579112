import { Product } from 'types/product';

type DynamicFormProductValue = {
  sr?: string;
  wo?: string;
};

/**
 * Determines the selected dynamic form product's associated product
 * code and/or Tiksu work order identifier. A Claudia product code
 * is mapped to a Claudia offering ID for service requests, whilst a
 * Tiksu work order identifier is sent as is, so the service request
 * API would know to create a Tiksu work order instead of a Claudia
 * service request.
 *
 * A selected product has either of the following formats, from which
 * the product code and work order identifier are parsed:
 * * `<sr:Claudia product code; wo:Tiksu work order ID>`
 * * `<sr:Claudia product code>`
 *
 * If neither format is matched, falls back to considering the
 * entirety of the specified selected product to be a Claudia
 * product code.
 *
 * @param selectedProduct The selected dynamic form product.
 * @returns Parsed service request and/or work order specific identifiers.
 */
const parseDynamicFormProductValue = (selectedProduct: string): DynamicFormProductValue => {
  if (selectedProduct.startsWith('<') && selectedProduct.endsWith('>')) {
    const trimmed = selectedProduct.substring(1, selectedProduct.indexOf('>')).trim();

    const result: DynamicFormProductValue = {};
    trimmed.split(/\s*;\s*/).forEach((element) => {
      const [key, value] = element.split(/:\s*/);
      if (isProductValue(key)) {
        result[key] = value;
      }
    });

    return result;
  }

  return {
    sr: selectedProduct
  };
};

const isProductValue = (key: string): key is keyof DynamicFormProductValue =>
  ['sr', 'wo'].includes(key);

/**
 * Maps a selected dynamic form product code or descriptor to either
 * an environment-specific Claudia offering ID for service requests,
 * or environment-agnostic Tiksu offering ID for work orders. Tiksu
 * offering IDs take precedence over Claudia offering IDs.
 *
 * If no product code is selected, a user-filled dynamic form doesn't
 * have a product association, and `undefined` is returned instead.
 */
export default ({
  products,
  selectedProduct
}: {
  products: Product[];
  selectedProduct?: string;
}): {
  isWorkOrder: boolean;
  productId: string | undefined;
} => {
  if (!selectedProduct) {
    return {
      isWorkOrder: false,
      productId: undefined
    };
  }

  const value = parseDynamicFormProductValue(selectedProduct);
  if (value.wo) {
    return {
      isWorkOrder: true,
      productId: value.wo
    };
  }

  return {
    isWorkOrder: false,
    productId: products.find((p) => p.productCode === value.sr)?.id
  };
};
