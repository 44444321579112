import { BreadcrumbProps } from 'common-components/Breadcrumb/BreadcrumbContainer/BreadcrumbContainer';
import useRouteBreadcrumbs from 'hooks/useRouteBreadcrumbs/useRouteBreadcrumbs';
import { useEffect } from 'react';

const getPageTitle = (breadCrumbs: BreadcrumbProps[]) => {
  const rootLabel = breadCrumbs[0].label;
  if (breadCrumbs.length === 1) {
    return rootLabel;
  } else {
    return breadCrumbs[breadCrumbs.length - 1].label + ' | ' + rootLabel;
  }
};

const RouteBasedPageTitle: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const breadCrumbs = useRouteBreadcrumbs();

  useEffect(() => {
    document.title = getPageTitle(breadCrumbs);
  }, [breadCrumbs]);

  return <>{children}</>;
};

export default RouteBasedPageTitle;
