import { gql } from '@apollo/client';
import { useUserData } from 'contexts/UserContext/UserContext';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNewsDrawerCounters } from 'redux/slices/newsDrawerSlice';
import { RootState } from 'redux/store';
import { ProductTip, ProductUpdate } from 'types/productNews';

export const productNewsQuery = gql`
  query GetProductNews($lang: String!) {
    productUpdates(lang: $lang) {
      id
      title
      description
      imageUrl
      buttonLink
      buttonLabel
      rating
      feedbackGiven
      seen
    }
    productTips(lang: $lang) {
      id
      title
      description
      imageUrl
      buttonLink
      buttonLabel
      rating
      feedbackGiven
      seen
    }
  }
`;

interface QueryResponse {
  productUpdates: ProductUpdate[];
  productTips: ProductTip[];
}

const useProductNews = () => {
  const dispatch = useDispatch();
  const user = useUserData();
  const { data, error, loading } = useCallBackend<QueryResponse>({
    query: productNewsQuery,
    queryVariables: { lang: user.language }
  });

  const productNews = data?.productUpdates || [];
  const productTips = data?.productTips || [];

  const allNewsCount = productNews.length;
  const allTipsCount = productTips.length;

  const prevUnseenCount = useSelector((state: RootState) => state.newsDrawer.unseenCounts.total);
  const unseenNewsCount = productNews.filter((item) => !item.seen).length;
  const unseenTipsCount = productTips.filter((item) => !item.seen).length;
  const unseenCount = unseenNewsCount + unseenTipsCount;

  useEffect(() => {
    if (prevUnseenCount !== unseenCount) {
      dispatch(
        setNewsDrawerCounters({
          unseenCounts: { news: unseenNewsCount, tips: unseenTipsCount }
        })
      );
    }
  }, [
    dispatch,
    prevUnseenCount,
    unseenCount,
    unseenTipsCount,
    unseenNewsCount,
    allNewsCount,
    allTipsCount
  ]);

  // Necessary to keep track of the new items after the query gets refetched when marking those as seen
  const initialDataRef = useRef<QueryResponse | null>();
  if (!initialDataRef.current && (allNewsCount > 0 || allTipsCount > 0)) {
    initialDataRef.current = { productUpdates: productNews, productTips };
  }

  return {
    news: { productUpdates: productNews, productTips },
    initialDataRef,
    loading,
    error
  };
};

export { useProductNews };
