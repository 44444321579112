import { modals } from 'doings/track/analyticsEvents';
import trackEvent from 'doings/track/trackEvent';
import { useEffect, useState } from 'react';
import { ProductNewsCategory as ActiveTab, ProductNewsItem } from 'types/productNews';

import { useProductNews } from './useProductNews';
import { useMarkProductNewsAsSeen } from './useSeenProductNews';

type News = ReturnType<typeof useProductNews>['news'];

interface UseSeenProductNewsTab {
  activeTab: ActiveTab;
  news: News;
  isOpen: boolean;
}

const useSeenProductNewsTab = ({ activeTab, news, isOpen }: UseSeenProductNewsTab) => {
  const { markProductNewsAsSeen } = useMarkProductNewsAsSeen();
  const [markedTabs, setMarkedTabs] = useState<Record<ActiveTab, boolean>>({
    productUpdates: false,
    productTips: false
  });

  const markTab = (news: ProductNewsItem[], activeTab: ActiveTab) => {
    const ids = news?.filter((item) => !item.seen)?.map(({ id }) => id);
    markProductNewsAsSeen(ids);
    setMarkedTabs({ ...markedTabs, [activeTab]: true });
  };

  useEffect(() => {
    if (!isOpen || markedTabs[activeTab]) {
      return;
    }

    const activeTabNews = news[activeTab];
    if (activeTabNews?.length) {
      markTab(activeTabNews, activeTab);
      if (activeTab === 'productUpdates') {
        trackEvent(modals.newsDrawerUnseenNewsView);
      } else if (activeTab === 'productTips') {
        trackEvent(modals.newsDrawerUnseenTipsView);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, news, isOpen, markedTabs]);
};

export { useSeenProductNewsTab };
