import { noOp } from 'doings/noOp/noOp';
import { RefObject, useEffect, useRef } from 'react';

/**
 * _Here be accessibility dragons munching away at the deprecated Gaia component
 * library._
 *
 * Disallows keyboard navigation into a `DatePicker` or `DatePickerWithRange`'s
 * overlay, because a user can already enter a date manually. This works around
 * focus-related usability bugs caused by Gaia's components' usage of the
 * underlying date picker library, allowing the end-user to tab to the element
 * following the date picker without getting stuck in a potential keyboard trap.
 *
 * As a result, a user can [TAB] to the subsequent element after the date picker
 * or the next date range picker subfield without remaining trapped on the
 * currently focused field.
 *
 * @remarks
 * Needs to be connected and properly disconnected for memory management. Call
 * `connect` when a day picker is opened, and `disconnect` when it is closed.
 * For a `DatePickerWithRange`, need to invoke the hook twice: once for the from
 * day picker and once for the to day pickers.
 *
 * @privateRemarks
 * To fix the keyboard trap, we need to disable a Gaia `DatePicker` or
 * `DatePickerWithRange` component's day picker's _tab indices_ both:
 * 1. When the day picker is opened.
 * 2. Whenever the date picker's DOM changes — which occurs whenever the month
 *    selection changes. This can be achieved using a `MutationObserver`
 *    supported in all widespread browsers since late 2014.
 *
 * In both cases, DOM elements with a tab index of 0 are inserted into the day
 * picker, creating a focus trap and causing accessibility issues with the
 * listed Gaia components.
 *
 * Falls back to doing nothing should the browser not support `MutationObserver`.
 *
 * @param containerRef - A reference to an immediate wrapper around the date picker.
 */
export const useGaiaDatePickerKeyboardA11yFix = (containerRef: RefObject<HTMLDivElement>) => {
  const domMutationObserver = useRef<MutationObserver | null>(null);

  useEffect(() => {
    const observer = domMutationObserver.current;
    return () => {
      observer?.disconnect();
    };
  }, []);

  // Browser fallback: if `MutationObserver` is unsupported, do nothing
  if (!window.MutationObserver) {
    return { connect: noOp, disconnect: noOp };
  }

  return {
    connect: () => {
      domMutationObserver.current?.disconnect();
      domMutationObserver.current = disableKeyboardNavigationIntoDatePickerOverlay(containerRef);
    },
    disconnect: () => {
      domMutationObserver.current?.disconnect();
    }
  };
};

export const disableKeyboardNavigationIntoDatePickerOverlay = (
  containerRef: RefObject<HTMLDivElement>
) => {
  /* istanbul ignore next */
  if (!containerRef.current) {
    return null;
  }

  disableOverlayKeyboardNavigation(containerRef);
  return observeDOM(containerRef.current, (mutations) => {
    if (mutations.some((record) => containsAddedDayPickerDOM(record))) {
      disableOverlayKeyboardNavigation(containerRef);
    }
  });
};

const disableOverlayKeyboardNavigation = (containerRef: RefObject<HTMLElement>) => {
  const wr = ".DayPickerInput-OverlayWrapper, .DayPickerInput-OverlayWrapper [tabindex='0']";
  containerRef.current?.querySelectorAll(wr).forEach((el) => el.setAttribute('tabindex', '-1'));
};

const observeDOM = function (container: Node, callback: MutationCallback) {
  const mutationObserver = new MutationObserver(callback);
  mutationObserver.observe(container, { childList: true, subtree: true });
  return mutationObserver;
};

const containsAddedDayPickerDOM = (record: MutationRecord) =>
  record.addedNodes.length > 0 && some(record.addedNodes, (node) => isDayPickerNode(node));

const some = <T>(iterable: Iterable<T>, predicate: (item: T) => boolean) => {
  const iterator = iterable[Symbol.iterator]();
  let result;
  while (!(result = iterator.next()).done) {
    if (predicate(result.value)) {
      return true;
    }
  }
  return false;
};

const isDayPickerNode = (node: Node) =>
  node.nodeType === Node.ELEMENT_NODE &&
  node.nodeName === 'DIV' &&
  ['DayPickerInput-OverlayWrapper', 'DayPicker-Week', 'DayPicker-Day'].some((className) =>
    (node as HTMLDivElement).classList.contains(className)
  );
