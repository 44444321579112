import { Div, GridBag, Gutter } from '@gaiads/telia-react-component-library';
import { Skeleton } from 'common-components';

const SKELETON_PRODUCT_CARD_COUNT = 4;

export const FavoriteDevicesSkeleton: React.FC = () => (
  <div data-testid="favorite-devices-skeleton">
    <Div margin={{ bottom: 'xlg' }} />

    <Skeleton.Title width="40%" />

    <Gutter />

    <Skeleton.TextRow width="50%" />

    <Gutter size="lg" />

    <GridBag>
      {Array.from({ length: SKELETON_PRODUCT_CARD_COUNT }).map((i) => (
        <GridBag.Item key={`image-${i}`} xxxs={6} md={3} xxlg={2}>
          <Skeleton.Panel>
            <Skeleton.Image width="100%" height="280px" />
          </Skeleton.Panel>
        </GridBag.Item>
      ))}
    </GridBag>

    <Gutter size="lg" />

    <Skeleton.TextRow width="15%" />
  </div>
);
