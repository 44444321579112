import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { markTourTriggered, triggerTour } from 'redux/slices/watchedTourTriggersSlice';
import { RootState } from 'redux/store';

export const useTriggerWatchableTour = (event: { condition: boolean; tourName: string }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (event.condition) {
      dispatch(triggerTour(event.tourName));
    }
  }, [dispatch, event]);
};

export const useWatchedTours = (tourIds: string[]) => {
  const dispatch = useDispatch();

  const { readyTours } = useSelector((state: RootState) => state.watchedTourTriggers);

  const popReadyTour = useCallback(
    (tourName: string) => {
      dispatch(markTourTriggered(tourName));
    },
    [dispatch]
  );

  return {
    readyTours: readyTours.filter((tourId) => tourIds.includes(tourId)),
    popReadyTour
  };
};
