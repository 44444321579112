import { BodyText, Gutter } from '@gaiads/telia-react-component-library';
import { formatAssetAddress } from 'B2XApp/InternetConnections/AssetDataPanel/AssetDataPanel';
import { Button, DataPanel, DefinitionList } from 'common-components';
import Link from 'common-components/Link/Link';
import formatDatetime from 'doings/formatDatetime/formatDatetime';
import useSearchParams from 'hooks/useSearchParams/useSearchParams';
import { useTranslation } from 'react-i18next';
import { ChildAsset } from 'types/internetConnections';

const ChildAssetItem: React.FC<{
  rootAssetId: string;
  childAsset: ChildAsset;
}> = ({ rootAssetId, childAsset }) => {
  const { t } = useTranslation();
  const { preservePathState } = useSearchParams();

  const formattedAddress = formatAssetAddress(childAsset.address, t);
  const formattedActivationDate = formatDatetime(childAsset.activationDate);

  const showGutterBeforeMoreInformation = !!formattedAddress || !!formattedActivationDate;

  return (
    <DataPanel title={childAsset.name}>
      <DefinitionList addDividers fiftyFifty>
        {!!formattedAddress && (
          <DefinitionList.Item
            heading={t('internetConnectionDetails.address')}
            data-testid="child-asset-item-address"
          >
            <BodyText>{formattedAddress}</BodyText>
          </DefinitionList.Item>
        )}

        {!!formattedActivationDate && (
          <DefinitionList.Item
            heading={t('internetConnectionDetails.activationDate')}
            data-testid="child-asset-item-activation-date"
          >
            <BodyText>{formattedActivationDate}</BodyText>
          </DefinitionList.Item>
        )}
      </DefinitionList>

      {showGutterBeforeMoreInformation && <Gutter size="xlg" />}

      <Link
        to={preservePathState(`/internet-connections/${rootAssetId}/children/${childAsset.id}`)}
        tabIndex={-1}
      >
        <Button variant="secondary" type="button" data-testid="more-information-button">
          {t('internetConnectionDetails.moreInformation')}
        </Button>
      </Link>
    </DataPanel>
  );
};

export default ChildAssetItem;
