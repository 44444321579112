import { gql } from '@apollo/client';
import useApiStatus from 'hooks/useApiStatus/useApiStatus';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { ThreeSixtyProductInventoryItem } from 'types/threeSixtyProductInventory';

import getThreeSixtyInventoryProductsOnFetchMore from './getThreeSixtyInventoryProductsOnFetchMore';

// TODO: add tests!
export const threeSixtyInventoryQuery = gql`
  query GetAvailableProducts(
    $query: ThreeSixtyProductInventoryQuery
    $offset: Int!
    $limit: Int!
    $allCompanies: Boolean
  ) {
    threeSixtyProductInventory(
      query: $query
      offset: $offset
      limit: $limit
      allCompanies: $allCompanies
    ) {
      total
      products {
        id
        externalId
        companyCode
        href
        name
        category
        agreement {
          id
          agreementItemId
          name
        }
        productType
        productSubType
        productFamily
        place {
          postcode
          city
          country
          streetName
          streetNr
        }
      }
    }
  }
`;

export type ThreeSixtyProductInventoryItemsResponse = {
  threeSixtyProductInventory: {
    total: number;
    products: ThreeSixtyProductInventoryItem[];
  };
};

export type ThreeSixtyProductInventoryQueryParameters = {
  externalId?: string;
  productType?: string;
};

export default (
  queryParameters: ThreeSixtyProductInventoryQueryParameters,
  allCompanies: boolean
) => {
  const { externalId, productType } = queryParameters;

  const {
    data,
    loading,
    error,
    loadingMore,
    loaded,
    fetchMore: fetchMoreFromQuery
  } = useCallBackend<ThreeSixtyProductInventoryItemsResponse>({
    query: threeSixtyInventoryQuery,
    queryVariables: {
      offset: 0,
      limit: 100,
      allCompanies,
      query: {
        externalId,
        productType
      }
    }
  });

  const status = useApiStatus({
    loading,
    loadingMore,
    loaded,
    error,
    items: data?.threeSixtyProductInventory.products ?? [],
    query: queryParameters
  });

  const products = data?.threeSixtyProductInventory.products ?? [];

  return {
    total: data?.threeSixtyProductInventory.total,
    data: products,
    status,
    loading,
    error,
    fetchMore: () =>
      fetchMoreFromQuery({
        variables: { offset: products?.length },
        updateQuery: getThreeSixtyInventoryProductsOnFetchMore
      })
  };
};
