import { ApolloLink, NextLink, Operation, RequestHandler } from '@apollo/client';
import { SetCSRFTokenFunction } from 'contexts/CSRFToken/CSRFToken';

const csrfTokenHeader = 'X-CSRF-TOKEN';

const csrfHandler = (setCSRFToken: SetCSRFTokenFunction): RequestHandler => {
  return (operation: Operation, forward: NextLink) => {
    return forward(operation).map((response) => {
      const context = operation.getContext();
      const {
        response: { headers }
      } = context;

      if (headers) {
        const csrfToken = headers.get(csrfTokenHeader);
        if (csrfToken) {
          setCSRFToken(csrfToken);
        }
      }
      return response;
    });
  };
};

export const getCSRFTokenLink = (setCSRFToken: SetCSRFTokenFunction) => {
  return new ApolloLink(csrfHandler(setCSRFToken));
};
