export type AnalyticsEvent = {
  event: string;
  eventCategory: string;
  eventLabel: string;
  eventAction: string;
};

export const createAnalyticsEvent: (eventLabel: string, eventAction: string) => AnalyticsEvent = (
  eventLabel: string,
  eventAction: string
) => ({
  event: 'businessPortalEvent',
  eventCategory: 'Self Service B2B',
  eventLabel,
  eventAction
});

const createEvents = <
  T extends Record<string, string>,
  U extends { [P in keyof T]: AnalyticsEvent }
>(
  eventLabel: string,
  events: T
): U => {
  return Object.entries(events).reduce((acc, [key, eventAction]) => {
    acc[key as keyof T] = createAnalyticsEvent(eventLabel, eventAction) as U[keyof T];
    return acc;
  }, {} as U);
};

const login = createEvents('User login', {
  insufficientPermission: 'login:insufficientPermission'
});

const userMenu = createEvents('User menu', {
  profile: 'user:profile',
  companies: 'user:companies',
  changeCompany: 'user:changeCompany',
  logout: 'user:logout'
});

const userSession = createEvents('User session', {
  loginSuccessful: 'User login successful',
  activeCompanyChanged: 'Active company changed',
  sessionContinued: 'User session continued'
});

const common = createEvents('Common', {
  changeActiveCompany: 'common:changeActiveCompany',
  changeLanguageToEn: 'common:changeLanguageToEn',
  changeLanguageToFi: 'common:changeLanguageToFi',
  changeLanguageToSv: 'common:changeLanguageToSv'
});

const topNavigationLinks = createEvents('Top navigation', {
  mobileSubscriptions: 'topNavigation:navigateToMobileSubscriptions',
  productsAssets: 'topNavigation:navigateToAssets',
  productsDevices: 'topNavigation:navigateToDevices',
  productsOther: 'topNavigation:navigateToB2BPortal360View',
  wholesaleOrders: 'topNavigation:navigateToWholesaleOrders',
  wholesaleSalesportalOrders: 'topNavigation:navigateToWholesaleSalesPortalOrders',
  wholesaleOtherOrders: 'topNavigation:navigateToWholesaleOtherOrders',
  wholesaleOrderAndDeliverySystem: 'topNavigation:navigateToWholesaleOrderAndDeliverySystem',
  wholesaleYritysnettiOrders: 'topNavigation:navigateToWholesaleYritysnettiOrders',
  wholesaleOffering: 'topNavigation:navigateToWholesaleOffering',
  messageServiceRequests: 'topNavigation:navigateToServiceRequests',
  messageTickets: 'topNavigation:navigateToTroubleTickets',
  messageArchive: 'topNavigation:navigateToMessageArchive',
  customerSpecificMessages: 'topNavigation:navigateToCustomerSpecificMessages',
  billingInvoices: 'topNavigation:navigateToInvoices',
  billingPaymentReminders: 'topNavigation:navigateToPaymentReminders',
  billingBillingAccounts: 'topNavigation:navigateToBillingAccounts',
  billingBillingInfoMassChange: 'topNavigation:navigateToBillingInfoMassChange',
  activityLog: 'topNavigation:navigateToActivityLog',
  contactUs: 'topNavigation:contactUs',
  wholesaleEvents: 'topNavigation:wholesaleEvents',
  wholesaleRequestForQuote: 'topNavigation:wholesaleRequestForQuote',
  wholesaleOrder: 'topNavigation:wholesaleOrder',
  wholesaleOrderForChange: 'topNavigation:wholesaleOrderForChange',
  wholesaleOrderForTermination: 'topNavigation:wholesaleOrderForTermination',
  requestForQuotation: 'topNavigation:requestForQuotation'
});

const footerLinks = createEvents('Footer', {
  cookiePolicy: 'footer:navigateToCookiePolicy',
  dataProtection: 'footer:navigateToDataProtectionAndSecurity',
  termsOfDelivery: 'footer:navigateToTermsOfDelivery',
  termsOfUse: 'footer:navigateToTermsOfUse'
});

const dashboardTopTiles = createEvents('Dashboard top tiles', {
  mobileSubscriptions: 'topTiles:navigateToMobileSubscriptions',
  mobileSubscriptionsCreateEmployee: 'topTiles:navigateToCreateEmployeeFlow',
  productsAssets: 'topTiles:navigateToAssets',
  wholesaleOrders: 'topTiles:navigateToWholesaleOrders',
  messageServiceRequests: 'topTiles:navigateToServiceRequests',
  messageTickets: 'topTiles:navigateToTroubleTickets',
  billingInvoices: 'topTiles:navigateToInvoices',
  billingBillingAccounts: 'topTiles:navigateToBillingAccounts',
  b2bPortalReports: 'topTiles:navigateToB2BPortalReports',
  contactUs: 'topTiles:navigateToContactUs'
});

const dashboardLinks = createEvents('Dashboard links', {
  untracked: 'dashboard:untracked',
  presentationTour: 'dashboard:showPresentationTour',
  mobileSubscriptions: 'dashboard:navigateToMobileSubscriptions',
  mobileSubscriptionsOrderSimCard: 'dashboard:navigateToOrderSimCards',
  mobileSubscriptionsCreateEmployee: 'dashboard:navigateToCreateEmployeeFlow',
  mobileSubscriptionsMassChange: 'dashboard:navigateToMassChange',
  mobileSubscriptionsOwnershipChange: 'dashboard:navigateToOwnershipChange',
  productsAssets: 'dashboard:navigateToAssets',
  productsDevices: 'dashboard:navigateToDevices',
  productsOther: 'dashboard:navigateToB2BPortal360View',
  productsOtherCID: 'dashboard:navigateToB2BPortal360ViewCIDSection',
  productsOtherVIP: 'dashboard:navigateToB2BPortal360ViewVIPSection',
  productsOtherTouchpoint: 'dashboard:navigateToB2BPortal360ViewTouchpointSection',
  productsOtherSmartConnect: 'dashboard:navigateToB2BPortal360ViewSmartConnectSection',
  offering: 'dashboard:navigateToFullProductOffering',
  wholesaleOrders: 'dashboard:navigateToWholesaleOrders',
  wholesaleSalesportalOrders: 'dashboard:navigateToWholesaleSalesPortalOrders',
  wholesaleOrderAndDeliverySystem: 'dashboard:navigateToWholesaleOrderAndDeliverySystem',
  wholesaleYritysnettiOrders: 'dashboard:navigateToWholesaleYritysnettiOrders',
  wholesaleOtherOrders: 'dashboard:navigateToWholesaleOtherOrders',
  wholesaleOffering: 'dashboard:navigateToWholesaleOffering',
  messageServiceRequests: 'dashboard:navigateToServiceRequests',
  messageCreateServiceRequest: 'dashboard:navigateToCreateServiceRequest',
  messageTickets: 'dashboard:navigateToTroubleTickets',
  messageCreateTicket: 'dashboard:navigateToCreateTroubleTicket',
  messageArchive: 'dashboard:navigateToMessageArchive',
  customerSpecificMessages: 'dashboard:navigateToCustomerSpecificMessages',
  accountCompanyInfo: 'dashboard:navigateToCompanyInfo',
  accountCompanyUsers: 'dashboard:navigateToCompanyUsers',
  accountServiceOrderPermissions: 'dashboard:navigateToServiceOrderPermissions',
  billingInvoices: 'dashboard:navigateToInvoices',
  billingPaymentReminders: 'dashboard:navigateToPaymentReminders',
  billingBillingAccounts: 'dashboard:navigateToBillingAccounts',
  billingBillingInfoMassChange: 'dashboard:navigateToBillingInfoMassChange',
  billingInfoChanges: 'dashboard:navigateToBillingInfoChanges',
  b2bPortal: 'dashboard:navigateToB2BPortal',
  b2bPortalReports: 'dashboard:navigateToB2BPortalReports',
  activityLog: 'dashboard:navigateToActivityLog',
  customizeTopTiles: 'dashboard:openTopTilesCustomization',
  customizeQuickLinks: 'dashboard:openQuickLinksCustomization',
  customizeOtherServices: 'dashboard:openOtherServicesCustomization',
  saveCustomizedLinks: 'dashboard:updateCustomLinks',
  resetCustomizedLinks: 'dashboard:restoreLinksToDefault',
  contactUs: 'dashboard:contactUs',
  wholesaleEvents: 'dashboard:wholesaleEvents',
  wholesaleRequestForQuote: 'dashboard:wholesaleRequestForQuote',
  wholesaleOrder: 'dashboard:wholesaleOrder',
  wholesaleOrderForChange: 'dashboard:wholesaleOrderForChange',
  wholesaleOrderForTermination: 'dashboard:wholesaleOrderForTermination',
  requestForQuotation: 'dashboard:requestForQuotation'
});

const dashboardNotifications = createEvents('Dashboard notifications', {
  pendingServiceRequests: 'dashboard:goToPendingServiceRequestsViaNotification',
  pendingTickets: 'dashboard:goToPendingTicketsViaNotification',
  openInvoices: 'dashboard:goToOpenInvoicesViaNotification',
  messages: 'dashboard:goToMessagesViaNotification',
  customerSpecificMessages: 'dashboard:goToCustomerSpecificMessagesViaNotification'
});

const otherServices = createEvents('Other Telia services', {
  cidManager: 'dashboard:openCidManager',
  colocationPortal: 'dashboard:openColocationPortal',
  cygateServicePortal: 'dashboard:openCygateServicePortal',
  developerPortal: 'dashboard:openDeveloperPortal',
  disturbance: 'dashboard:openDisturbance',
  disturbanceNotificationsSettings: 'dashboard:openDisturbanceNotificationsSettings',
  ecommerce: 'dashboard:openEcommerce',
  iotPortal: 'dashboard:iotPortal',
  iotStatusPortal: 'dashboard:iotStatusPortal',
  managedIotConnectivityPortal: 'dashboard:managedIotConnectivityPortal',
  mncReports: 'dashboard:openMncReports',
  mobileCompanyNetwork: 'dashboard:openMobileCompanyNetwork',
  mobileNetworkDisturbances: 'dashboard:openMobileNetworkDisturbances',
  mobileNetworkCoverageMap: 'dashboard:openMobileNetworkCoverageMap',
  mobileNetworkDisturbancesMap: 'dashboard:openMobileNetworkDisturbancesMap',
  mobileNetworkDisturbanceNotificationSettings:
    'dashboard:openMobileNetworkDisturbanceNotificationSettings',
  myNebula: 'dashboard:openMyNebula',
  salesPortal: 'dashboard:openTeliaSalesPortal',
  sdWan: 'dashboard:openSDWan',
  secureNet: 'dashboard:openSecureNet',
  smartConnect: 'dashboard:openSmartConnect',
  surfManager: 'dashboard:openSurfManager',
  support: 'dashboard:openCustomerSupport',
  teliaSign: 'dashboard:openTeliaSign',
  tinShop: 'dashboard:openTinShop',
  vipPortal: 'dashboard:openVipPortal',
  vipPlusAdminPortal: 'dashboard:openVipPlusAdminPortal',
  showHiddenOtherServices: 'dashboard:showHiddenOtherServices'
});

const search = createEvents('Help and support search', {
  searchOpened: 'topNavigation:search:searchOpened',
  supportArticlesFound: 'topNavigation:search:supportArticlesFound',
  contentPagesFound: 'topNavigation:search:contentPagesFound',
  suggestionsFound: 'topNavigation:search:suggestionsFound',
  supportArticleClicked: 'topNavigation:search:supportArticleClicked',
  contentPageClicked: 'topNavigation:search:contentPageClicked',
  suggestionClicked: 'topNavigation:search:suggestionClicked',
  noResultsFound: 'topNavigation:search:noResultsFound'
});

const dashboardSearch = createEvents('Dashboard help and support search', {
  supportArticlesFound: 'dashboard:search:supportArticlesFound',
  contentPagesFound: 'dashboard:search:contentPagesFound',
  suggestionsFound: 'dashboard:search:suggestionsFound',
  supportArticleClicked: 'dashboard:search:supportArticleClicked',
  contentPageClicked: 'dashboard:search:contentPageClicked',
  suggestionClicked: 'dashboard:search:suggestionClicked',
  noResultsFound: 'dashboard:search:noResultsFound'
});

const pukCodeSearch = createEvents('Dashboard PUK code search', {
  subscriptionsFound: 'dashboard:pukCodeSearch:subscriptionsFound',
  noResultsFound: 'dashboard:pukCodeSearch:noResultsFound',
  pukCodeSearchSelectResult: 'dashboard:pukCodeSearch:selectResult',
  pukCodeSearchCopyPuk1: 'dashboard:pukCodeSearch:copyPuk1',
  pukCodeSearchCopyPuk2: 'dashboard:pukCodeSearch:copyPuk2',
  pukCodeSearchViewSubscription: 'dashboard:pukCodeSearch:viewSubscription'
});

const aiAssistant = createEvents('AI assistant', {
  startConversationFromDashboard: 'aiAssistant:startConversationFromDashboard',
  clearConversationFromDashboard: 'aiAssistant:clearConversationFromDashboard',
  startConversationFromSearch: 'aiAssistant:startConversationFromSearch',
  continueConversationFromSearch: 'aiAssistant:continueConversationFromSearch',
  clearConversationFromSearch: 'aiAssistant:clearConversationFromSearch',
  clearConversationFromDrawer: 'aiAssistant:clearConversationFromDrawer'
});

const pimcoreNotifications = createEvents('Pimcore', {
  newsNotificationClicked: 'pimcore:readNewsNotification',
  newsNotificationClosed: 'pimcore:closeNewsNotification',
  newsBannerClicked: 'pimcore:readNewsBanner',
  systemNotificationClicked: 'pimcore:readSystemNotification',
  systemNotificationClosed: 'pimcore:closeSystemNotification'
});

const tours = createEvents('Tours', {
  tourContinued: 'tours:continueTour',
  tourFinished: 'tours:finishTour',
  tourSkipped: 'tours:skipTour',
  tourShownExplicitly: 'tours:showTourExplicitly',
  tourPoppedUp: 'tours:popUpTour'
});

const mobile = createEvents('Mobile subscriptions', {
  orderNewSubscription: 'mobile:orderNewSubscription',
  openEditSubscriptionModal: 'mobile:openEditSubscriptionModal',
  updateSubscription: 'mobile:updateSubscription',
  transferOwnership: 'mobile:transferOwnership',
  transferOwnershipToOther: 'mobile:transferOwnershipToOther',
  transferOwnershipInternal: 'mobile:transferOwnershipInternal',
  closeSubscription: 'mobile:closeSubscription',
  openClosedSubscription: 'mobile:openClosedSubscription',
  openGenerateOwnershipChangeToken: 'mobile:openGenerateOwnershipChangeToken',
  generateOwnershipChangeToken: 'mobile:generateOwnershipChangeToken',
  cancelOwnershipTokenFlow: 'mobile.cancelOwnershipTokenFlow',
  removeBlockingOmsuService: 'mobile:removeBlockingOmsuService',
  usePreviousOwnershipChangeFlow: 'mobile:usePreviousOwnershipChangeFlow',
  terminateSubscription: 'mobile:terminateSubscription',
  viewAdditionalServices: 'mobile:viewAdditionalServices',
  changeAdditionalServices: 'mobile:changeAdditionalServices',
  viewRestrictions: 'mobile:viewRestrictions',
  changeRestrictions: 'mobile:changeRestrictions',
  expandSIMCard: 'mobile:expandSimCard',
  orderSIM: 'mobile:orderSIM',
  expandMultiSimMembers: 'mobile:expandMultiSimMembers',
  manageMultiSimMembers: 'mobile:manageMultiSimMembers',
  viewMultiSimDetails: 'mobile:viewMultiSimDetails',
  viewMultiSimParentDetails: 'mobile:viewMultiSimParentDetails',
  expandSurfCards: 'mobile:expandSurfCards',
  manageSurfCards: 'mobile:manageSurfCards',
  viewSurfCardDetails: 'mobile:viewSurfCardDetails',
  viewSurfCardParentDetails: 'mobile:viewSurfCardParentDetails',
  viewUserInformation: 'mobile:viewUserInformation',
  changeUserInformation: 'mobile:changeUserInformation',
  viewBillingAccount: 'mobile:viewBillingAccount',
  changeBillingAccount: 'mobile:changeBillingAccount',
  copyBillingAccount: 'mobile:copyBillingAccount',
  createServiceRequest: 'mobile:createServiceRequest',
  createTroubleTicket: 'mobile:createTroubleTicket',
  navigateToBalanceBreakdown: 'mobile:navigateToBalanceBreakdown',
  navigateToMassChange: 'mobile:navigateToMassChange',
  navigateToOwnershipChange: 'mobile:navigateToOwnershipChange',
  navigateToOrderSimCard: 'mobile:navigateToOrderSimCard',
  navigateToServiceOrderPermissions: 'mobile:navigateToServiceOrderPermissions',
  navigateToMobileSubsReport: 'mobile:navigateToMobileSubsReport'
});

const assets = createEvents('Assets', {
  createServiceRequest: 'asset:createServiceRequest',
  createTroubleTicket: 'asset:createTroubleTicket',
  fullProductOfferingLargeBusinessLink: 'asset:fullProductOfferingLargeBusinessLink',
  fullProductOfferingSmallBusinessLink: 'asset:fullProductOfferingSmallBusinessLink',
  oldPortalSubscriptionSearchLink: 'asset:oldPortalSubscriptionSearchLink',
  openTechnicalReporting: 'asset:openTechnicalSubscriptionSpecificReporting',
  oldPortalSmartConnectManagement: 'asset:oldPortalSmartConnectManagementLink',
  smartConnectAdmin: 'asset:smartConnectAdminLink'
});

const devices = createEvents('Devices', {
  viewAdditionalServices: 'devices:viewAdditionalServices',
  orderNewDevice: 'devices:orderNewDevice',
  manageDevicesInTINShop: 'devices:manageDevicesInTINShop',
  manageDevicesInMyNebula: 'devices:manageDevicesInMyNebula',
  manageDeviceDetailsInMyNebula: 'devices:manageDeviceDetailsInMyNebula',
  viewFavoriteDevices: 'devices:viewFavoriteDevicesInEcom',
  viewFavoriteDevice: 'devices:viewFavoriteDeviceInEcom'
});

const ordersAndRFQs = createEvents('Orders', {
  visitTeliaCygate: 'order:visitTeliaCygate',
  visitTinService: 'order:visitTinService',
  inquireAboutOrder: 'order:inquireAboutOrder'
});

const invoicing = createEvents('Invoicing', {
  downloadInvoice: 'invoicing:downloadInvoice',
  downloadInvoiceBySubscription: 'invoicing:downloadInvoiceBySubscription',
  downloadInvoiceByCostCenter: 'invoicing:downloadInvoiceByCostCenter',
  downloadInvoiceCallSpecificationPdf: 'invoicing:downloadInvoiceCallSpecificationPdf',
  downloadInvoiceCallSpecificationExcel: 'invoicing:downloadInvoiceCallSpecificationExcel',
  payInvoice: 'invoicing:payInvoiceLink',
  payInvoiceInBank: 'invoicing:payInvoiceWithBankLink',
  applyPaymentPlan: 'invoicing:applyPaymentPlan',
  acceptNewPaymentPlan: 'invoicing:acceptNewPaymentPlan',
  createServiceRequest: 'invoicing:createServiceRequest',
  createServiceRequestForEInvoiceChange: 'invoicing:createServiceRequestForEInvoiceChange',
  copyBarCode: 'invoicing:copyBarCode',
  toggleSmsReminder: 'invoicing:togglePaymentReminderBySms',
  toggleEmailReminder: 'invoicing:togglePaymentReminderByEmail',
  updatePaymentReminders: 'invoicing:updatePaymentReminders',
  resetPaymentReminders: 'invoicing:resetPaymentReminders',
  createCollectionGroup: 'invoicing:createCollectionGroup',
  updateCollectionGroup: 'invoicing:updateCollectionGroup',
  copyCollectionGroup: 'invoicing:copyCollectionGroup',
  updateBillMillAccount: 'invoicing:updateBillMillAccount',
  visitB2CInvoices: 'invoicing:visitB2CInvoices',
  visitTinService: 'invoicing:visitTinService',
  changeCompanyBillingInfo: 'invoicing:changeCompanyBillingInfo',
  changeBillingAccounts: 'invoicing:changeBillingAccounts',
  changeSubscriptionBillingInfo: 'invoicing:changeSubscriptionBillingInfo',
  changeMobileSubscriptionsBillingInfo: 'invoicing:changeMobileSubscriptionsBillingInfo',
  changeBillingInfoWithExcel: 'invoicing:changeBillingInfoWithExcel',
  openCreateCollectionGroup: 'invoicing:openCreateCollectionGroup',
  openCreateOtherBillingAccount: 'invoicing:openCreateBillingAccountForOtherProducts'
});

const contactUs = createEvents('Contact us', {
  serviceRequestList: 'contactUs:navigateToServiceRequests',
  serviceRequestArchiveList: 'contactUs:navigateToOldPortalServiceRequests',
  serviceRequestSubmit: 'contactUs:navigateToSubmitServiceRequestPage',
  faultReportList: 'contactUs:navigateToFaultReports',
  faultReportSubmit: 'contactUs:navigateToSubmitFaultReportPage',
  customerSpecificMessages: 'contactUs:navigateToCustomerSpecificMessages',
  chatsAndCallsStartChat: 'contactUs:startChat',
  chatsAndCallsPage: 'contactUs:navigateToChatAndCallHistoryPage',
  customerSupportPages: 'contactUs:navigateToCustomerSupportPages',
  bookCall: 'contactUs:bookAFreeCallTime',
  requestForQuotation: 'contactUs:requestForQuotation'
});

const messages = createEvents('Messages', {
  createServiceRequest: 'message:createServiceRequest',
  createTroubleTicket: 'message:createTroubleTicket',
  submitServiceRequest: 'message:submitServiceRequest',
  submitTroubleTicket: 'message:submitTroubleTicket',
  commentTroubleTicket: 'message:commentTroubleTicket',
  commentServiceRequest: 'message:commentServiceRequest',
  bookCallFromNewSR: 'message:bookAFreeCallTimeFromNewSR',
  bookCallFromNewTT: 'message:bookAFreeCallTimeFromNewTT',
  dynamicFormSelectType: 'message:dynamicFormSelectType',
  dynamicFormSelectOption: 'message:dynamicFormSelectOption',
  serviceRequestsPageFromOmsuNotice: 'message:navigateToServiceRequestsFromOmsuNotice',
  troubleTicketListOpenMyNebula: 'message:navigateToMyNebulaFromTroubleTicketList',
  troubleTicketListOpenPulsar: 'message:navigateToCygatePulsarFromTroubleTicketList',
  newTicketOpenMyNebula: 'message:navigateToMyNebulaFromNewTroubleTicket',
  newTicketOpenPulsar: 'message:navigateToCygatePulsarFromNewTroubleTicket'
});

const errors = createEvents('Errors', {
  returnToTelia: 'error:returnToTelia'
});

const modals = createEvents('Modals', {
  confirmationLeavePage: 'confirmationModal:leavePage',
  confirmationStayOnPage: 'confirmationModal:stayOnPage',
  listConfigSave: 'listConfigurationModal:saveSettingsSucceeded',
  listConfigSaveSuccess: 'listConfigurationModal:saveSettingsSuccess',
  listConfigSaveFailure: 'listConfigurationModal:saveSettingsFailure',
  listConfigReset: 'listConfigurationModal:resetSettingsToDefault',
  listConfigResetSuccess: 'listConfigurationModal:resetSettingsToDefaultSuccess',
  listConfigResetFailure: 'listConfigurationModal:resetSettingsToDefaultFailure',
  newsDrawerUnseenNewsView: 'newsDrawer:viewUnseenNews',
  newsDrawerUnseenTipsView: 'newsDrawer:viewUnseenTips',
  newsDrawerShowMoreNewsClicked: 'newsDrawer:showOlderNews',
  newsDrawerShowMoreTipsClicked: 'newsDrawer:showOlderTips',
  newsDrawerProductNewsClicked: 'newsDrawer:learnMoreAboutProductUpdate',
  newsDrawerProductTipClicked: 'newsDrawer:learnMoreAboutProductTip'
});

const notifications = createEvents('Notifications', {
  navigateToULMProfilePage: 'notification:navigateToULMProfilePage',
  navigateToCustomerSpecificMessages: 'notification:navigateToCustomerSpecificMessages'
});

const reauthAndReplayFlow = createEvents('Reauth and replay flow', {
  replayEntryRetrievalStarted: 'replayFlow:replayEntryRetrievalStarted',
  replayEntryRetrievalSuccess: 'replayFlow:replayEntryRetrievalSuccess',
  replayEntryRetrievalFailure: 'replayFlow:replayEntryRetrievalFailure',
  replaySuccess: 'replayFlow:replaySuccess',
  replayFailure: 'replayFlow:replayFailure'
});

export {
  aiAssistant,
  assets,
  common,
  contactUs,
  dashboardLinks,
  dashboardNotifications,
  dashboardSearch,
  dashboardTopTiles,
  devices,
  errors,
  footerLinks,
  invoicing,
  login,
  messages,
  mobile,
  modals,
  notifications,
  ordersAndRFQs,
  otherServices,
  pimcoreNotifications,
  pukCodeSearch,
  reauthAndReplayFlow,
  search,
  topNavigationLinks,
  tours,
  userMenu,
  userSession
};
