import { gql } from '@apollo/client';

export const createOwnershipChangeTokenMutation = gql`
  mutation CreateOwnershipChangeToken($omsuTokenData: OmsuTokenData) {
    createOwnershipChangeToken(omsuTokenData: $omsuTokenData) {
      status
      mobileNumber
      additionalNumber1
      additionalNumber2
      expiryDate
      terminationDate
    }
  }
`;

export type CreateOwnershipChangeTokenData = {
  mobileNumber: string;
  additionalNumber1?: string;
  additionalNumber2?: string;
  terminationDate: string;
};

export type TokenCreationResponse = {
  createOwnershipChangeToken: OwnershipChangeTokenDetails;
};

export type OwnershipChangeTokenDetails = {
  status: string;
  mobileNumber: string;
  additionalNumber1?: string;
  additionalNumber2?: string;
  expiryDate: string;
  terminationDate: string;
};
