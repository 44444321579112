import { Paragraph } from '@teliafi/fi-ds';
import getClassNames from 'classnames';
import { SmartTooltip } from 'common-components';
import { Flex } from 'core-components';

import styles from './ProgressBar.module.scss';

type Values =
  | {
      variant: 'calculated';
      current: number;
      max: number;
    }
  | {
      variant: 'fixed';
      pct: number;
    };

const MAX_PERCENTAGE = 100;
const FRACTION_DIGITS = 2;

export const ProgressBar: React.FC<{
  title: string;
  titleTooltip?: string;
  unlimited: boolean;
  unlimitedLabel?: string;
  values: Values;
  labelLeft: React.ReactNode;
  labelRight: React.ReactNode;
}> = ({ title, titleTooltip, unlimited, unlimitedLabel, values, labelLeft, labelRight }) => {
  const valuePct =
    values.variant === 'fixed' ? values.pct : (values.current / values.max) * MAX_PERCENTAGE;
  const width = Number(Math.max(0, Math.min(MAX_PERCENTAGE, valuePct))).toFixed(FRACTION_DIGITS);

  return (
    <div data-testid="progress-bar">
      <Flex className={styles.title} centeredVertically>
        <span className={styles.title_text} data-testid="progress-bar--title">
          {title}
        </span>

        {titleTooltip && (
          <SmartTooltip
            tooltipContent={titleTooltip}
            arrangement="top"
            data-testid="progress-bar--title-tooltip"
          >
            <SmartTooltip.InfoIcon size="sm" data-testid="progress-bar--title-tooltip-icon" />
          </SmartTooltip>
        )}
      </Flex>

      {!unlimited && (
        <div className={styles.progressBar_background}>
          <div
            className={getClassNames({
              [styles.progressBar_bar]: true,
              [styles.progressBar_bar__animated]: true
            })}
            style={{ width: `${width}%` }}
            data-testid="progress-bar--bar"
          ></div>
        </div>
      )}

      <Flex className={styles.labels} spaceBetween>
        <Paragraph variant="paragraph-100" data-testid="progress-bar--label-left">
          {labelLeft}
        </Paragraph>

        <Paragraph variant="paragraph-100" data-testid="progress-bar--label-right">
          {!unlimited ? labelRight : unlimitedLabel}
        </Paragraph>
      </Flex>
    </div>
  );
};
