import { gql } from '@apollo/client';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { Notifications } from 'types/notifications';

export const notificationsQuery = gql`
  query GetNotifications {
    notifications
  }
`;

export const emptyNotifications: Notifications = {
  notificationsSystem: [],
  notificationsNews: [],
  notificationsPromotions: []
};

export const useNotifications = () => {
  const { data, error, loaded } = useCallBackend<{
    notifications: Notifications;
  }>({
    query: notificationsQuery,
    fetchPolicy: 'cache-and-network'
  });

  const { notificationsSystem, notificationsNews, notificationsPromotions } =
    !error && data?.notifications ? data.notifications : emptyNotifications;

  return {
    banners: notificationsPromotions,
    notificationsNews,
    notificationsSystem,
    loaded
  };
};
