import { Div } from '@gaiads/telia-react-component-library';
import getClassNames from 'classnames';
import { SmartTooltip } from 'common-components';
import { useTranslation } from 'react-i18next';

import styles from './NoPrivilegesTooltip.module.scss';

/**
 * Renders a both hoverable and clickable tooltip for helping the user
 * identify one's lack of sufficient privileges for a button or link
 * disabled because of authorisation.
 *
 * Since buttons and links can be disabled for any arbitrary reasons,
 * providing information on the lack of privileges makes it simpler
 * for the user to understand that they require additional permissions
 * to access a piece of functionality.
 *
 * @param children Nodes to surround in the tooltip.
 * @param i18nKey The tooltip's translation key.
 * @param showTooltip Whether the tooltip should be shown at all. If
 * `false`, the component's children are rendered as is without a
 * surrounding tooltip. Useful in certain cases where we might not
 * want to render the tooltip conditionally, so we provide a boolean
 * property defining its presence instead.
 * @param tooltipPlacement In which position should the tooltip appear.
 * @param wrapChildren Whether additional mark-up should be rendered
 * around the children to make the tooltip clickable in both desktop
 * and mobile devices. Certain elements like disabled buttons don't
 * propagate click events, and hence such a click event needs to be
 * captured before it is invoked on the disabled element; otherwise,
 * the click event disappears.
 * @param fullWidthWhenWrapped Whether wrapped child elements of the tooltip
 * should occupy all of the available horizontal space within their
 * container element.
 */
const NoPrivilegesTooltip: React.FC<{
  children: React.ReactNode;
  i18nKey: string;
  showTooltip: boolean;
  tooltipPlacement?: 'top' | 'bottom';
  wrapChildren?: boolean;
  wrapper?: React.ComponentProps<typeof SmartTooltip>['wrapper'];
  fullWidthWhenWrapped?: boolean;
  inline?: boolean;
  inlineRightPadded?: boolean;
}> = ({
  children,
  i18nKey,
  showTooltip,
  tooltipPlacement = 'bottom',
  wrapChildren = false,
  wrapper,
  fullWidthWhenWrapped = false,
  inline = false,
  inlineRightPadded = false
}) => {
  const { t } = useTranslation();
  if (!showTooltip) {
    return children as JSX.Element;
  }

  const renderWrappedChildren = () => {
    return (
      <Div
        className={getClassNames({
          [styles.wrapper]: true,
          [styles.wrapper__inline]: inline,
          [styles.wrapper__inline_rightPadded]: inlineRightPadded
        })}
        occupyHorizontalSpace={fullWidthWhenWrapped}
        data-testid="no-privileges-tooltip-wrapper"
      >
        <Div className={styles.wrappedChild} occupyHorizontalSpace={fullWidthWhenWrapped}>
          {children}
        </Div>
      </Div>
    );
  };

  const renderChildren = () => {
    return children as JSX.Element;
  };

  const tagName = wrapper === 'div' ? 'div' : 'span';
  const Wrapper = tagName;
  return (
    <SmartTooltip tooltipContent={t(i18nKey)} arrangement={tooltipPlacement} wrapper={tagName}>
      <Wrapper>{wrapChildren ? renderWrappedChildren() : renderChildren()}</Wrapper>
    </SmartTooltip>
  );
};

export default NoPrivilegesTooltip;
