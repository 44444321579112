import { BodyText, Div } from '@gaiads/telia-react-component-library';
import { useDashboardSearchState } from 'contexts/SearchContext/DashboardSearchContext';
import removeEmptyFields from 'doings/removeEmptyFields/removeEmptyFields';
import useSubscriptionPukCodeSearch from 'hooks/mobileSubscriptions/useSubscriptionPukCodeSearch/useSubscriptionPukCodeSearch';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'types/sort';
import { SortableColumns, SubscriptionPukCodeSearchItem } from 'types/subscription';

import PukCodeDetails from './PukCodeDetails';
import styles from './PukCodeSearch.module.scss';
import PukCodeSearchResultItem from './PukCodeSearchResultItem';

const MIN_SEARCH_STRING_LENGTH = 4;

const PukCodeSearchResults: React.FC = () => {
  const { t } = useTranslation();
  const { searchTerm, setSearchTerm, resultsRef, reposition } = useDashboardSearchState();
  const shouldDisplayResults = searchTerm.length > 0;

  const getQueryField = useCallback(() => {
    return /^[\d\s+]*$/.test(searchTerm) ? 'msisdn' : 'userInfo';
  }, [searchTerm]);

  const shouldSkipRequest = useCallback(() => {
    return !searchTerm || searchTerm.length < MIN_SEARCH_STRING_LENGTH;
  }, [searchTerm]);

  const queryParam = useMemo(
    () => removeEmptyFields({ [getQueryField()]: searchTerm }),
    [searchTerm, getQueryField]
  );

  const {
    subscriptions: { items },
    loading
  } = useSubscriptionPukCodeSearch(
    { sort: SortableColumns.id, order: SortOrder.ASC },
    queryParam,
    shouldSkipRequest()
  );

  useEffect(() => {
    reposition();
  }, [reposition, shouldDisplayResults]);

  if (!shouldDisplayResults || searchTerm.length < MIN_SEARCH_STRING_LENGTH) {
    return null;
  }

  const hasNoResults = !loading && items.length === 0 && !shouldSkipRequest();
  const hasOneResult = !loading && items.length === 1;
  const hasMultipleResults = !loading && items.length > 1;

  return (
    <Div refElement={resultsRef} className={styles.pukCodeSearchResults}>
      {(loading || hasNoResults) && (
        <BodyText data-testid={`puk-code-search-${loading ? 'loading' : 'no-results'}`}>
          {t(
            loading
              ? 'dashboard.pukCodeSearch.list.pleaseWait'
              : 'dashboard.pukCodeSearch.list.noMatches'
          )}
        </BodyText>
      )}

      {hasMultipleResults &&
        items.map((item: SubscriptionPukCodeSearchItem) => (
          <PukCodeSearchResultItem
            key={item.phoneNumberHash}
            item={item}
            searchForItem={setSearchTerm}
          />
        ))}

      {hasOneResult && <PukCodeDetails msisdnHash={items[0].phoneNumberHash} />}
    </Div>
  );
};

export default PukCodeSearchResults;
