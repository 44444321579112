import { ApolloError } from '@apollo/client';
import { Page } from '@gaiads/telia-react-component-library';
import { TinServiceNotificationLink } from 'B2XApp/Invoicing/Invoices/InvoiceList/InvoiceNotices/TinServiceNotificationLink';
import { InlineNotification, Memo, PageWithSubnavigation } from 'common-components';
import EmptyListContent from 'common-components/EmptyListContent/EmptyListContent';
import ListHeader from 'common-components/ListHeader/ListHeader';
import ShowMoreResults from 'common-components/ShowMoreResults/ShowMoreResults';
import { EditableListProvider } from 'contexts/EditableListContext/EditableListContext';
import { useFeatureFlagsData } from 'contexts/FeatureFlagContext/FeatureFlagContext';
import { scrollElementIntoViewNextFrame } from 'doings/scroll/scrollElementIntoView';
import { ordersAndRFQs } from 'doings/track/analyticsEvents';
import { OrderFilters } from 'hooks/orders/useOrders/useOrdersFilters';
import { ViewStatus } from 'hooks/useApiStatus/useApiStatus';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderListItem } from 'types/orders';

import { ExcelDownloadLink } from '../OrderListComponents/ExcelDownloadLink';
import OrdersListView, { OrdersListViewColumnSetup } from './OrdersListView';
import { TeliaCygateLink } from './TeliaCygateLink';

const OrdersListPage: React.FC<{
  orderFilters: OrderFilters;
  multipleCompanySearch: boolean;
  orders: { total: number; items: OrderListItem[] };
  hasMoreResults: boolean;
  fetchMore: VoidFunction;
  status: ViewStatus;
  error?: ApolloError;
}> = ({
  orderFilters,
  orders,
  multipleCompanySearch,
  hasMoreResults,
  fetchMore,
  status,
  error
}) => {
  const { t } = useTranslation();
  const { queryParametersAbsent, searchFieldOptions, statusFilterOptions, allCompanySearch } =
    orderFilters;
  const [orderLineVisible, setOrderLineVisible] = useState<boolean | string>(false);
  const listRef = useRef<HTMLDivElement>(null);

  const { hasFeature } = useFeatureFlagsData();
  const showProgressIndicator = hasFeature('EART-4308');
  const loading = status === ViewStatus.Loading;

  const onToggleVisibleOrderLine = useCallback(
    (id: string) => {
      const visibleOrderLine = orderLineVisible === id ? false : id;
      setOrderLineVisible(visibleOrderLine);
      scrollElementIntoViewNextFrame({
        elementRef: listRef,
        targetQuery: '[data-expanded="true"]',
        scrollVariant: 'snap'
      });
    },
    [setOrderLineVisible, orderLineVisible]
  );

  return (
    <EditableListProvider
      columnSetup={OrdersListViewColumnSetup}
      invocationArgs={[t, showProgressIndicator, multipleCompanySearch]}
      changeableArgs={[undefined, undefined, multipleCompanySearch]}
    >
      <PageWithSubnavigation
        loading={status === ViewStatus.LoadingInitial || status === ViewStatus.Initial}
        error={status === ViewStatus.Error ? error : undefined}
        emptyContentPlaceholder={
          queryParametersAbsent &&
          status === ViewStatus.Empty && (
            <EmptyListContent
              title={t('orders.list.noOrdersHeading')}
              description={t('orders.list.noOrdersDescription')}
            />
          )
        }
        showSubnavigationForCurrentPath
        data-testid="orders-list-page-with-subnavigation"
      >
        <ListHeader
          withMultiCompanyFeature={multipleCompanySearch}
          multipleCompaniesCheckboxParams={{
            checked: allCompanySearch.showFromAllCompanies,
            label: t('orders.searchAllLabel'),
            testId: 'orders-search-all-companies',
            onChange: allCompanySearch.toggleAllCompanies
          }}
          searchScopeOptions={searchFieldOptions}
          withSearchActivationThreshold
          dateRangeParams={{
            label: t('orders.dateFiltersLabel')
          }}
          statusFilterParams={{
            label: t('orders.statusFilterLabel'),
            options: statusFilterOptions,
            'data-testid': 'orders-status-filter-field'
          }}
          labels={{
            searchInMobileView: t('orders.searchLabel')
          }}
          excelDownloadLink={
            <ExcelDownloadLink
              queryParameters={orderFilters.queryParameters}
              showFromAllCompanies={orderFilters.allCompanySearch.showFromAllCompanies}
              disabled={orders.total === 0 || loading}
            />
          }
          infoNotice={
            <InlineNotification
              data-testid="orders-filter-inline-notification"
              variant="information"
              content={{
                key: 'orders.notification',
                components: [
                  <TinServiceNotificationLink
                    key="link-to-tin-service"
                    analyticsEvent={ordersAndRFQs.visitTinService}
                  />,
                  <TeliaCygateLink key="link-to-telia-cygate" />
                ]
              }}
            />
          }
          data-testid="orders-list-page-list-header"
        >
          {t('orders.list.numberOfOrders', {
            numberOfOrders: orders.total
          })}
        </ListHeader>

        <Page.Row responsiveFullWidth>
          <Memo comparables={['loading', 'orders', 'orderLineVisible']}>
            <OrdersListView
              orders={orders.items}
              loading={loading}
              orderLineVisible={orderLineVisible}
              onToggleVisibleOrderLine={onToggleVisibleOrderLine}
              listRef={listRef}
              data-testid="orders-list-page-orders-list-view"
            />
          </Memo>
        </Page.Row>

        {!loading && (
          <Page.Row>
            <ShowMoreResults
              data-testid="show-more-orders"
              loading={status === ViewStatus.LoadingMore}
              hasMoreResults={hasMoreResults}
              hasResults={!!orders.items.length}
              fetchMore={fetchMore}
            />
          </Page.Row>
        )}
      </PageWithSubnavigation>
    </EditableListProvider>
  );
};

export default OrdersListPage;
