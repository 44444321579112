import { OffHourCallSchedulingNotice } from 'B2XApp/Messages/common';
import { PageWithFormLayout } from 'common-components';
import pushChatEvent from 'doings/chat/pushChatEvent';
import { messages } from 'doings/track/analyticsEvents';
import useSearchParams from 'hooks/useSearchParams/useSearchParams';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const NewServiceRequestPage: React.FC<{
  backLinkVisible?: boolean;
  backLinkTo?: string;
  loading?: boolean;
  component: React.ReactNode;
  additionalInfo?: React.ReactNode;
}> = ({ backLinkVisible, backLinkTo, component, additionalInfo, loading }) => {
  const { t } = useTranslation();
  const { searchParams } = useSearchParams();

  const openChatForNewServiceRequest = useCallback(() => {
    if (!loading) {
      pushChatEvent('teePalvelupyynto');
    }
  }, [loading]);

  useEffect(() => {
    openChatForNewServiceRequest();
  }, [openChatForNewServiceRequest]);

  return (
    <PageWithFormLayout
      title={t('serviceRequests.newServiceRequest.title')}
      backLinkVisible={backLinkVisible}
      additionalInfo={additionalInfo}
      notification={<OffHourCallSchedulingNotice trackOnLink={messages.bookCallFromNewSR} />}
      data-testid="create-new-service-request-form"
      loading={loading}
      to={searchParams?.originator || backLinkTo || '/service-requests'}
    >
      {component}
    </PageWithFormLayout>
  );
};

export default NewServiceRequestPage;
