export default (event?: string) => {
  if (!event) {
    return;
  }

  const trackingApi = window['DIALOGUE']?.tracking;
  if (trackingApi) {
    trackingApi.reportEvent(event);
  }
};
