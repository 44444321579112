import { InlineNotificationLite } from 'common-components';
import { NotificationVariant } from 'common-components/InlineNotificationLite/InlineNotificationLite';
import { useTranslation } from 'react-i18next';

const conversationNoticeVariant: Record<ConversationNoticeVariant, NoticeVariant> = {
  answerLoading: {
    testId: 'ask-for-help--conversation--info-notice',
    variant: 'info',
    messageKey: 'askForHelp.drawer.answerLoading'
  },
  answerUnavailable: {
    testId: 'ask-for-help--conversation--unavailable-notice',
    variant: 'unavailable',
    messageKey: 'askForHelp.drawer.answerFailed'
  },
  answerDisclaimer: {
    testId: 'ask-for-help--conversation--disclaimer',
    variant: 'info',
    messageKey: 'askForHelp.drawer.answerDisclaimer'
  }
};

type ConversationNoticeVariant = 'answerLoading' | 'answerUnavailable' | 'answerDisclaimer';

type NoticeVariant = {
  testId: string;
  variant: NotificationVariant;
  messageKey: string;
};

const AskForHelpConversationNotice: React.FC<{
  variant: ConversationNoticeVariant;
}> = ({ variant }) => {
  const { t } = useTranslation();
  const { testId, variant: noticeVariant, messageKey } = conversationNoticeVariant[variant];

  return (
    <InlineNotificationLite
      data-testid={testId}
      variant={noticeVariant}
      messageText={t(messageKey)}
    />
  );
};

export default AskForHelpConversationNotice;
