import { Combobox, OptionListOption } from '@gaiads/telia-react-component-library';
import { SelectFieldProps } from 'common-components/Forms/FormField/FormField';
import { useField, useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import { SEARCH_TEXT_MAX_LENGTH } from 'types/form';

import FormFieldHelperText from './FormFieldHelperText';

const findOptionByValue = (options: OptionListOption[], value?: string) => {
  return options.find((option) => option.value === value);
};

const findOptionByLabel = (options: OptionListOption[], inputTerm?: string) => {
  const term = inputTerm?.trim().toLocaleLowerCase();
  return !term ? undefined : options.find(({ label }) => label.toLocaleLowerCase() === term);
};

const filterShownOptions = (
  options: OptionListOption[],
  inputTerm?: string
): OptionListOption[] => {
  const term = inputTerm?.trim().toLocaleLowerCase();
  const hasOption = !!findOptionByLabel(options, term);
  const filteredOptions =
    hasOption || !term
      ? options
      : options.filter((option) => option.label.toLowerCase().includes(term));

  return filteredOptions.length > 0 ? filteredOptions : options;
};

const FormFieldCombobox = ({
  name,
  label,
  helperText,
  placeholder,
  required,
  disabled,
  comboboxInputMaxLength,
  comboboxInputMode,
  options,
  onChange,
  trackOnChange,
  testId
}: SelectFieldProps) => {
  const [optionField, optionMeta] = useField<string>(name);
  const [inputField] = useField<string>(`${name}-input`);

  const { setFieldValue, setFieldTouched } = useFormikContext();
  const filteredOptions = useMemo(
    () => filterShownOptions(options, inputField.value),
    [options, inputField.value]
  );

  useEffect(() => {
    const activeOption = findOptionByValue(options, optionField.value ?? '');
    setFieldValue(optionField.name, activeOption?.value);
    setFieldValue(inputField.name, activeOption?.label);
    // Set default "None selected" option for optional fields or
    // select any preselected option only during first render.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(
    () => (
      <>
        <Combobox
          data-testid={testId}
          name={name}
          type="text"
          label={label}
          aria-label={`${label}${required ? ' *' : ''}`}
          onBlurCapture={(_event) => {
            setFieldTouched(optionField.name, true, true);
          }}
          onChange={(_event, value) => {
            const newOption = findOptionByLabel(options, value)?.value;
            setFieldTouched(optionField.name, optionMeta.touched);
            setFieldValue(inputField.name, value);
            setFieldValue(optionField.name, newOption ?? '');
            trackOnChange && newOption && trackOnChange(value);
            onChange?.(newOption ?? '');
          }}
          value={inputField.value}
          options={filteredOptions}
          placeholder={placeholder}
          required={required}
          errorText={optionMeta.touched ? optionMeta.error : ''}
          disabled={disabled}
          maxLength={comboboxInputMaxLength || SEARCH_TEXT_MAX_LENGTH}
          inputMode={comboboxInputMode}
        />

        <FormFieldHelperText helperText={helperText} />
      </>
    ),

    /*
     * Only check for changes to significant properties which can change
     * between form field renders. Dependencies like `field` and `setFieldValue`
     * change on every render, so we need to exclude these to avoid unnecessary
     * and unperformant rerenders.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      name,
      required,
      disabled,
      onChange,
      inputField.value,
      optionField.value,
      optionMeta.touched,
      optionMeta.error
    ]
  );
};

export default FormFieldCombobox;
