import { Memo, OverflowMenu, OverflowMenuToggle } from 'common-components';
import { useOverflowMenuState } from 'contexts/OverflowMenuContext/OverflowMenuContext';
import { useUserData } from 'contexts/UserContext/UserContext';
import isDemoEnvironment from 'doings/isDemoEnvironment/isDemoEnvironment';
import createCorporatePortalLink from 'doings/links/createCorporatePortalLink/createCorporatePortalLink';
import openUrl from 'doings/openUrl/openUrl';
import { AnalyticsEvent, mobile } from 'doings/track/analyticsEvents';
import trackEvent from 'doings/track/trackEvent';
import { History } from 'history';
import {
  SubscriptionAttribute,
  SubscriptionStatus
} from 'hooks/mobileSubscriptions/useSubscriptionDetails/subscriptionDetailsQuery';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AuthorizationParams } from 'types/authorization';
import { SubscriptionListItem } from 'types/subscription';

const goto = (history: History, path: string, msisdn: string, analyticsEvent?: AnalyticsEvent) => {
  analyticsEvent && trackEvent(analyticsEvent);
  path.startsWith('/') ? history.push(path) : openUrl(createCorporatePortalLink(path, { msisdn }));
};

interface QuicklinkDescriptor {
  key: string;
  labelI18nKey: string;
  onClick: (history: History, msisdn: string) => void;
  multipleCompanies?: boolean;
  enabledWhen: AuthorizationParams;
  supportedStatuses: Set<SubscriptionStatus>;
  isSurfCard: boolean;
  supportedAttribute?: SubscriptionAttribute;
  isInTransfer: boolean;
  hasDuettoBundle: boolean;
  disabled?: boolean;
}

const isDemoEnv = isDemoEnvironment();

const MOBILE_SUBSCRIPTION_READONLY_PERMISSION: AuthorizationParams = {
  anyPermission: ['MOBILE_SUBSCRIPTIONS', 'MOBILE_SUBSCRIPTIONS_VIEW']
};

const MOBILE_SUBSCRIPTION_WRITE_PERMISSION: AuthorizationParams = {
  onePermission: 'B2B_PORTAL',
  anyPermission: ['MOBILE_SUBSCRIPTIONS']
};

const ITEMS: QuicklinkDescriptor[] = [
  {
    key: 'view',
    labelI18nKey: 'subscriptions.list.quicklinks.viewSubscription',
    enabledWhen: MOBILE_SUBSCRIPTION_READONLY_PERMISSION,
    onClick: (history: History, msisdn: string) =>
      goto(history, `/subscriptions/${msisdn}`, msisdn),
    supportedStatuses: new Set<SubscriptionStatus>([
      'PREOPENED',
      'CLOSED',
      'SUSPENDED',
      'ENABLED',
      'BAD_CREDIT',
      'DISABLED'
    ]),
    isSurfCard: true,
    isInTransfer: true,
    hasDuettoBundle: true
  },
  {
    key: 'viewPukCodes',
    labelI18nKey: 'subscriptions.list.quicklinks.viewPukCodes',
    enabledWhen: MOBILE_SUBSCRIPTION_READONLY_PERMISSION,
    onClick: (history: History, msisdn: string) =>
      goto(history, `/subscriptions/${msisdn}?section=simCard`, msisdn),
    supportedStatuses: new Set<SubscriptionStatus>([
      'CLOSED',
      'SUSPENDED',
      'ENABLED',
      'BAD_CREDIT',
      'DISABLED'
    ]),
    isSurfCard: true,
    isInTransfer: true,
    hasDuettoBundle: true
  },
  {
    key: 'subscriptionTypeChange',
    labelI18nKey: 'subscriptions.list.quicklinks.changePackageOrSubstype',
    enabledWhen: MOBILE_SUBSCRIPTION_WRITE_PERMISSION,
    onClick: (history: History, msisdn: string) =>
      goto(history, 'mobileSubscriptionTypeChange', msisdn, mobile.updateSubscription),
    supportedStatuses: new Set<SubscriptionStatus>(['CLOSED', 'SUSPENDED', 'ENABLED']),
    isSurfCard: false,
    isInTransfer: false,
    hasDuettoBundle: false,
    disabled: isDemoEnv
  },
  {
    key: 'simCardChange',
    labelI18nKey: 'subscriptions.list.quicklinks.changeSimCard',
    enabledWhen: MOBILE_SUBSCRIPTION_WRITE_PERMISSION,
    onClick: (history: History, msisdn: string) =>
      goto(history, 'mobileChangeSIMCard', msisdn, mobile.orderSIM),
    supportedStatuses: new Set<SubscriptionStatus>(['CLOSED', 'ENABLED']),
    isSurfCard: true,
    isInTransfer: false,
    hasDuettoBundle: true,
    disabled: isDemoEnv
  },
  {
    key: 'userInfoChange',
    labelI18nKey: 'subscriptions.list.quicklinks.changeUserInfo',
    enabledWhen: MOBILE_SUBSCRIPTION_WRITE_PERMISSION,
    onClick: (history: History, msisdn: string) =>
      goto(history, 'mobileDetails', msisdn, mobile.changeUserInformation),
    supportedStatuses: new Set<SubscriptionStatus>(['CLOSED', 'SUSPENDED', 'ENABLED']),
    isSurfCard: true,
    isInTransfer: false,
    hasDuettoBundle: true,
    disabled: isDemoEnv
  },
  {
    key: 'billingInfoChange',
    labelI18nKey: 'subscriptions.list.quicklinks.changeBillingInfo',
    enabledWhen: MOBILE_SUBSCRIPTION_WRITE_PERMISSION,
    onClick: (history: History, msisdn: string) =>
      goto(history, 'mobileDetails', msisdn, mobile.changeBillingAccount),
    supportedStatuses: new Set<SubscriptionStatus>(['CLOSED', 'ENABLED']),
    isSurfCard: false,
    isInTransfer: false,
    hasDuettoBundle: true,
    disabled: isDemoEnv
  },
  {
    key: 'additionalServicesChange',
    labelI18nKey: 'subscriptions.list.quicklinks.manageAdditionalServices',
    enabledWhen: MOBILE_SUBSCRIPTION_WRITE_PERMISSION,
    onClick: (history: History, msisdn: string) =>
      goto(history, 'mobileSubscriptionServices', msisdn, mobile.changeAdditionalServices),
    supportedStatuses: new Set<SubscriptionStatus>(['CLOSED', 'ENABLED']),
    isSurfCard: true,
    isInTransfer: false,
    hasDuettoBundle: true,
    disabled: isDemoEnv
  },
  {
    key: 'restrictionServicesChange',
    labelI18nKey: 'subscriptions.list.quicklinks.manageRestrictions',
    enabledWhen: MOBILE_SUBSCRIPTION_WRITE_PERMISSION,
    onClick: (history: History, msisdn: string) =>
      goto(history, 'mobileSubscriptionRestrictions', msisdn, mobile.changeRestrictions),
    supportedStatuses: new Set<SubscriptionStatus>(['CLOSED', 'ENABLED']),
    isSurfCard: false,
    isInTransfer: false,
    hasDuettoBundle: true,
    disabled: isDemoEnv
  },
  {
    key: 'surfCardsChange',
    labelI18nKey: 'subscriptions.list.quicklinks.manageSurfCards',
    enabledWhen: MOBILE_SUBSCRIPTION_WRITE_PERMISSION,
    onClick: (history: History, msisdn: string) =>
      goto(history, 'mobileSurfCards', msisdn, mobile.manageSurfCards),
    supportedStatuses: new Set<SubscriptionStatus>(['CLOSED', 'ENABLED']),
    supportedAttribute: 'POTENTIAL_EXTRA_SIM_MASTER',
    isSurfCard: false,
    isInTransfer: false,
    hasDuettoBundle: false,
    disabled: isDemoEnv
  },
  {
    key: 'ownershipChange',
    labelI18nKey: 'subscriptions.list.quicklinks.changeOwnership',
    enabledWhen: MOBILE_SUBSCRIPTION_WRITE_PERMISSION,
    onClick: (history: History, msisdn: string) =>
      goto(history, `/subscriptions/${msisdn}/list-transfer`, msisdn, mobile.transferOwnership),
    supportedStatuses: new Set<SubscriptionStatus>(['CLOSED', 'ENABLED']),
    isSurfCard: false,
    isInTransfer: false,
    hasDuettoBundle: false,
    disabled: isDemoEnv
  }
];

const filterItem = (
  item: QuicklinkDescriptor,
  subscription: SubscriptionListItem,
  hasMultipleCompanies: boolean
) =>
  (!subscription.isSurfCard || item.isSurfCard) &&
  (!subscription.isInTransfer || item.isInTransfer) &&
  (!subscription.hasDuettoBundle || item.hasDuettoBundle) &&
  item.supportedStatuses.has(subscription.status) &&
  (!item.supportedAttribute || subscription.attributes.includes(item.supportedAttribute)) &&
  (!item.multipleCompanies || hasMultipleCompanies);

const SubscriptionQuicklinksMenu: React.FC<{ subscription: SubscriptionListItem }> = ({
  subscription
}) => {
  const { openMenu, closeMenus, toggleMenu } = useOverflowMenuState();
  const isOpen = openMenu === subscription.id;

  return (
    <Memo comparables={['isOpen']}>
      <SubscriptionQuicklinksMenuInner
        subscription={subscription}
        isOpen={isOpen}
        closeMenus={closeMenus}
        toggleMenu={toggleMenu}
      />
    </Memo>
  );
};

const SubscriptionQuicklinksMenuInner: React.FC<{
  subscription: SubscriptionListItem;
  isOpen: boolean;
  closeMenus: VoidFunction;
  toggleMenu: (id: string) => void;
}> = ({ subscription, isOpen, closeMenus, toggleMenu }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { hasMultipleCompanies } = useUserData();
  const menuContainer = document.querySelector(
    `[data-quicklink-container="subscription-${subscription.id}"] > a`
  );

  return (
    <OverflowMenuToggle
      menuId={subscription.id}
      menuContainer={menuContainer}
      isOpen={isOpen}
      closeMenus={closeMenus}
      toggleMenu={toggleMenu}
    >
      {isOpen && (
        <OverflowMenu>
          {ITEMS.filter((item) => filterItem(item, subscription, hasMultipleCompanies)).map(
            (item) => (
              <OverflowMenu.Item
                key={item.key}
                dataTestid={`overflow-menu-item-${item.key}`}
                label={t(item.labelI18nKey)}
                enabledWhen={item.enabledWhen}
                disabled={item.disabled}
                onClick={() => item.onClick(history, subscription.phoneNumberHash)}
              />
            )
          )}
        </OverflowMenu>
      )}
    </OverflowMenuToggle>
  );
};

export default SubscriptionQuicklinksMenu;
