import { InlineNotification } from 'common-components';

export const LegacyMultiSimMemberNotice: React.FC = () => (
  <InlineNotification
    title={{ key: 'subscriptions.subscriptionDetails.status.multiSimMemberTitleLegacy' }}
    content={{ key: 'subscriptions.subscriptionDetails.status.multiSimMemberMessage' }}
    variant="information"
    icon="devices"
    data-testid="legacy-multi-sim-member-notification"
  />
);

export const YhteysMultiSimMemberNotice: React.FC = () => (
  <InlineNotification
    title={{ key: 'subscriptions.subscriptionDetails.status.multiSimMemberTitleYhteys' }}
    content={{ key: 'subscriptions.subscriptionDetails.status.multiSimMemberMessage' }}
    variant="information"
    icon="devices"
    data-testid="yhteys-multi-sim-member-notification"
  />
);
