import { InlineNotification, TeliaLink } from 'common-components';
import { isWeekend } from 'date-fns';
import getEnvironmentVariable from 'doings/getEnvironmentVariable/getEnvironmentVariable';
import isHoliday from 'doings/isHoliday/isHoliday';
import { AnalyticsEvent } from 'doings/track/analyticsEvents';
import { useMemo } from 'react';

const OffHourCallSchedulingNotice: React.FC<{ trackOnLink: AnalyticsEvent }> = ({
  trackOnLink
}) => {
  const { offHour } = useMemo(() => {
    const now = new Date();
    return { offHour: isOffHour(now) };
  }, []);

  if (!offHour) {
    return null;
  }

  return (
    <InlineNotification
      title={{ key: 'serviceRequests.newServiceRequest.callSchedulingNotice.title' }}
      content={{
        key: 'serviceRequests.newServiceRequest.callSchedulingNotice.description',
        components: [
          <TeliaLink.Translatable.External
            key="link"
            variant="text"
            to={getEnvironmentVariable('REACT_APP_LINK_TO_OFF_HOUR_CALL_SCHEDULING')}
            track={trackOnLink}
          />
        ]
      }}
      variant="information"
      icon="support"
      data-testid="schedule-call-notice"
    />
  );
};

const START_OF_OFFICE_HOURS = 7;
const END_OF_OFFICE_HOURS = 16;

/**
 * Determines whether it is currently off-hour, in contrast to office
 * hours.
 *
 * Office hours are determined using these rules:
 * * Office hours are on weekdays from Monday through Friday.
 * * Office hours range from 07:00–16:00.
 * * Office hours are unavailable on Finnish holidays.
 */
const isOffHour = (instance: Date) => {
  const hourOfDay = instance.getHours();
  return (
    isWeekend(instance) ||
    hourOfDay < START_OF_OFFICE_HOURS ||
    hourOfDay >= END_OF_OFFICE_HOURS ||
    isHoliday(instance)
  );
};

export default OffHourCallSchedulingNotice;
