import { Div } from '@gaiads/telia-react-component-library';

import styles from './ProgressBar.module.scss';

type Values =
  | {
      variant: 'calculated';
      current: number;
      max: number;
    }
  | {
      variant: 'fixed';
      pct: number;
    };

const MAX_PERCENTAGE = 100;
const FRACTION_DIGITS = 2;

export const ProgressBarWithoutLabel: React.FC<{
  values: Values;
  padding?: React.ComponentProps<typeof Div>['padding'];
}> = ({ values, padding }) => {
  const valuePct =
    values.variant === 'fixed' ? values.pct : (values.current / values.max) * MAX_PERCENTAGE;
  const width = Number(Math.max(0, Math.min(MAX_PERCENTAGE, valuePct))).toFixed(FRACTION_DIGITS);

  return (
    <Div flexItem padding={padding} data-testid="progress-bar-without-label">
      <div className={styles.progressBar_background}>
        <div
          className={styles.progressBar_bar}
          style={{ width: `${width}%` }}
          data-testid="progress-bar-without-label--bar"
        ></div>
      </div>
    </Div>
  );
};
