import { ApolloError, gql } from '@apollo/client';
import isLoginError from 'doings/isLoginError/isLoginError';
import isMissingActiveCompanyError from 'doings/isMissingActiveCompanyError/isMissingActiveCompanyError';
import { updateMedalliaData } from 'doings/medallia/medalliaUtils';
import { trackUserSessionEvents } from 'doings/track/trackUserSessionEvents';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import usePortalLanguage from 'hooks/usePortalLanguage/usePortalLanguage';
import { useEffect } from 'react';

const userQuery = gql`
  query GetUser {
    user {
      ulmUserId
      ulmUserIdForBC
      email
      language
      name
      firstName
      lastName
      businessPhoneNumber
      impersonatedBy
      activeGroupId
      activeGroupType
      activeCompanyAidaId
      activeCompanyName
      activeCompanyClass
      activeCompanySalesChannel
      salesOrganization
      businessSegment
      isSmallBusiness
      isSNSCompany
      isFirstTimePurchaser
      accesses
      permissions
      hasMultipleCompanies
      allCompanies {
        groupId
        companyCode
        companyName
        permissions
        companyGroupType
      }
      sessionRefreshURL
      verificationTimestamps {
        email
        businessPhone
        groupContactTypes
      }
      hasAMCodes
    }
  }
`;

export type LoginStatusType = 'pending' | 'logged in' | 'not logged in';

export type UserResponse = {
  user: User;
};

const getUserStatus = (
  loading: boolean,
  data?: UserResponse,
  error?: ApolloError
): LoginStatusType => {
  if (error && (isLoginError(error) || isMissingActiveCompanyError(error))) {
    return 'pending';
  } else if (error) {
    return 'not logged in';
  } else if (data) {
    return 'logged in';
  } else if (loading) {
    return 'pending';
  }

  return 'not logged in';
};

const useUser = () => {
  const { data, error, loading } = useCallBackend<UserResponse>({
    query: userQuery
  });

  const status = getUserStatus(loading, data, error);
  const user = data?.user;
  usePortalLanguage(status, user, error);

  useEffect(() => {
    trackUserSessionEvents(status, user);
    provideBasicCompanyInfoForMedallia(status, user);
  }, [status, user]);

  return {
    user: data?.user,
    error,
    loading,
    status
  };
};

const provideBasicCompanyInfoForMedallia = (status: LoginStatusType, user?: User) => {
  if (status === 'logged in' && user) {
    updateMedalliaData({
      businessSegment: user.businessSegment,
      companyID: user.activeCompanyAidaId,
      ulmUserID: String(user.ulmUserId),
      salesSubSegment: user.activeCompanyClass,
      salesChannel: user.activeCompanySalesChannel,
      responsibleOrg: user.salesOrganization,
      channel: 'B2X portal',
      surveySource: 'B2X portal'
    });
  }
};

export default useUser;
