import { ApolloError, gql } from '@apollo/client';
import { multiplex } from 'doings/multiplex/multiplex';
import { billingGroupsQuery } from 'hooks/invoicing/billingGroups/useBillingGroups/useBillingGroups';
import useCallBackendMutation from 'hooks/useCallBackend/useCallBackendMutation';

export const billMillAccountQuery = gql`
  query UpdateBillingAccount($accountId: String!) {
    billMillAccount(accountId: $accountId) {
      accountId
      customerCode
      payer {
        name
        nameSupplement
        address {
          address
          addressSupplement
          postalCode
          postalOffice
        }
      }
      billing {
        invoicingType
        email
        email2
        iban
        bic
        ovt
        paymentMethod
        smsReminderMsisdn
        language
      }
      finvoice {
        accountDimensionText
        agreementIdentifier
        buyerReferenceIdentifier
        normalProposedAccountIdent
        orderIdentifier
        projectReferenceIdentifier
        proposedAccountText
        tenderReference
      }
      subscriptions {
        userName
        subscriptionId
        subscriptionType
        address
      }
    }
  }
`;

export const updateBillMillAccountMutation = gql`
  mutation ($accountId: String!, $requestData: InputBillMillAccountUpdateRequest!) {
    updateBillMillAccount(accountId: $accountId, requestData: $requestData) {
      data
    }
  }
`;

const mapApiStatus = (
  loading: boolean,
  data?: { updateBillMillAccount: UpdateBillMillAccountResult },
  error?: ApolloError
): UpdateBillMillAccountApiStatus => {
  return multiplex([
    'initial',
    [!!loading, 'persisting'],
    [!!error, 'failure'],
    [!!data, 'success']
  ]);
};

export type UpdateBillMillAccountApiStatus = 'initial' | 'persisting' | 'success' | 'failure';

interface UpdateBillMillAccountResult {
  id?: string;
  data: string;
}

interface UseUpdateBillMillAccountResult {
  updateBillMillAccount: UpdateBillMillAccountResult;
}

export default (accountId: string) => {
  const { runMutation, loading, data, error } =
    useCallBackendMutation<UseUpdateBillMillAccountResult>(updateBillMillAccountMutation, [
      {
        query: billingGroupsQuery,
        variables: {
          requestParams: {}
        }
      },
      {
        query: billMillAccountQuery,
        variables: { accountId: accountId }
      }
    ]);

  return {
    updateBillMillAccount: runMutation,
    updateBillMillAccountApiStatus: mapApiStatus(loading, data, error)
  };
};
