import { Div, Span } from '@gaiads/telia-react-component-library';
import { Paragraph } from '@teliafi/fi-ds';
import React from 'react';

import styles from './SmartTooltip.module.scss';
import { Tooltip, TooltipArrangement } from './Tooltip/Tooltip';
import { TooltipIcon } from './TooltipIcon/TooltipIcon';
import { TooltipTrigger } from './TooltipTrigger/TooltipTrigger';

/**
 * A tooltip, the placement of which is determined by its position on the
 * horizontal axis of the window, making sure there's enough space to render
 * the tooltip without it overflowing the window's left or right boundaries.
 *
 * @remarks
 * A maximum width is applied to the smart tooltip's content implicitly to
 * avoid the tooltip being rendered across the entirety of the viewport.
 *
 * @privateRemarks
 * The implicit maximum width contrasts using the legacy Gaia component
 * library's `Tooltip` directly, the content of which must specify an explicit
 * max width via prop or CSS class to avoid spanning a long-enough tooltip
 * across the screen.
 *
 * This component is mocked in `setupTests.tsx` to limit the scope of
 * unrelated unit tests.
 */
export const SmartTooltip: React.FC<{
  children: NonNullable<React.ReactNode>;
  tooltipContent: React.ReactElement | string | undefined;
  id?: string;
  arrangement?: TooltipArrangement;
  wrapper?: 'div' | 'span' | 'none';
  noClick?: boolean;
  'data-testid'?: string;
}> & {
  Trigger: React.FC<React.ComponentPropsWithRef<typeof TooltipTrigger>>;
  InfoIcon: React.FC<React.ComponentPropsWithRef<typeof TooltipIcon>>;
} = ({
  children,
  tooltipContent: tt,
  id,
  arrangement = 'bottom',
  wrapper = 'span',
  noClick = false
}) => {
  if (!tt) {
    return children;
  }

  const unwrapped = wrapper === 'none';
  const tooltip = (
    <Tooltip
      arrangement={arrangement}
      clickable={!noClick}
      tooltipContent={
        <Div id={id} className={styles.tooltipContent} data-testid="smart-tooltip-content">
          {typeof tt === 'string' ? <Paragraph>{tt}</Paragraph> : tt}
        </Div>
      }
    >
      {children}
    </Tooltip>
  );

  if (unwrapped) {
    return tooltip;
  }

  const Wrapper = wrapper === 'div' ? Div : Span;
  return (
    <Wrapper
      className={styles.tooltipContainer}
      data-testid="smart-tooltip"
      data-test-arrangement={arrangement}
    >
      {tooltip}
    </Wrapper>
  );
};

/** Wrap the smart tooltip around a custom component. */
SmartTooltip.Trigger = TooltipTrigger as unknown as typeof SmartTooltip.Trigger;
/** Use a smart tooltip triggered via a tooltip icon. */
SmartTooltip.InfoIcon = TooltipIcon as unknown as typeof SmartTooltip.InfoIcon;
