import { Paragraph } from '@teliafi/fi-ds';
import { Heading, Skeleton } from 'common-components';
import { useProductNewsTitle } from 'hooks/productNews/useProductNewsTitle';
import { useTranslation } from 'react-i18next';

import styles from './NewsRatingsStatsTitle.module.scss';

const NewsRatingsStatsTitle: React.FC<{ newsId: string }> = ({ newsId }) => {
  const { t } = useTranslation();
  const { title, loading, error } = useProductNewsTitle({ newsId });
  if (loading) {
    return <Skeleton.TextRow width="80%" data-testid="title-loader" />;
  }

  return (
    <>
      {!title && !error && (
        <Heading.H4 className={styles.titleAbsent}>
          {t('productNewsRatings.stats.titleAbsent')}
        </Heading.H4>
      )}

      {!title && error && (
        <Heading.H4 className={styles.titleError}>
          {t('productNewsRatings.stats.titleError')}
        </Heading.H4>
      )}

      {title && <Heading.H4>{title}</Heading.H4>}

      <Paragraph className={styles.subtitle} variant="additional-100" aria-hidden>
        {newsId}
      </Paragraph>
    </>
  );
};

export { NewsRatingsStatsTitle };
