import { Analytics } from 'common-components';
import ButtonLink from 'common-components/Link/ButtonLink/ButtonLink';
import { messages } from 'doings/track/analyticsEvents';
import { useTranslation } from 'react-i18next';

export const NewServiceRequestButton = () => {
  const { t } = useTranslation();

  return (
    <Analytics whenClicked={messages.createServiceRequest} tagName="div">
      <ButtonLink
        text={t('serviceRequests.newServiceRequest.title')}
        to="/service-requests/new"
        data-testid="link-to-create-service-request"
        enabledWhen={{ onePermission: 'SERVICE_REQUESTS' }}
      />
    </Analytics>
  );
};
