import { BodyText, Div, SupportedBodyTextSize } from '@gaiads/telia-react-component-library';
import { SupportedFontWeight } from '@gaiads/telia-react-component-library/build/types/components/Element/Element';
import { MarginProps } from '@gaiads/telia-react-component-library/build/types/components/Element/getMarginClasses';
import { PaddingProps } from '@gaiads/telia-react-component-library/build/types/components/Element/getPaddingClasses';
import { SupportedTextColor } from '@gaiads/telia-react-component-library/build/types/components/Typography/Typography';
import getClassNames, { Argument } from 'classnames';
import NoPrivilegesTooltip from 'common-components/Authorize/NoPrivilegesTooltip/NoPrivilegesTooltip';
import Link, { shouldDisableLink } from 'common-components/Link/Link';
import { multiplex } from 'doings/multiplex/multiplex';
import { AuthorizationParams } from 'types/authorization';

import styles from './TextLink.module.scss';

type TextLinkProps = {
  to?: string;
  label: string;
  suppress?: boolean;
  className?: string | Array<string> | Argument;
  size?: SupportedBodyTextSize;
  color?: SupportedTextColor;
  hoverColor?: SupportedTextColor;
  fontWeight?: SupportedFontWeight;
  block?: boolean;
  padding?: PaddingProps;
  margin?: MarginProps;
  singleLine?: boolean;
  disabled?: boolean;
  enabledWhen?: AuthorizationParams;
  'data-testid'?: string;
  tabIndex?: number;
  openInNewTab?: boolean;
  onClick?: VoidFunction;
};

const TextLink: React.FC<TextLinkProps> = ({
  to,
  label,
  suppress = false,
  className,
  size = 'md',
  color = 'purple500',
  hoverColor = 'purple400',
  fontWeight = 'medium',
  padding,
  margin = { size: 'zero' },
  block = false,
  singleLine = false,
  disabled = false,
  enabledWhen,
  'data-testid': dataTestId,
  tabIndex,
  openInNewTab = false,
  onClick
}) => {
  const { isDisabled, isUnauthorised } = shouldDisableLink(disabled, enabledWhen);
  if (isDisabled) {
    return (
      <NoPrivilegesTooltip
        i18nKey="common.tooltips.insufficientPermissions.forTextLink"
        showTooltip={isUnauthorised}
        tooltipPlacement="top"
      >
        <Div
          data-testid={dataTestId}
          className={block ? styles.block : styles.inline}
          aria-disabled
        >
          <BodyText
            data-testid="text-link-content"
            className={getClassNames([className, styles.disabled])}
            block={block}
            padding={padding}
            margin={margin}
            fontWeight={fontWeight}
            size={size}
            color="gray400"
            hoverColor="gray400"
            singleLine={singleLine}
            tagName="span"
          >
            {label}
          </BodyText>
        </Div>
      </NoPrivilegesTooltip>
    );
  }

  return (
    <Link
      className={getClassNames([className, block ? styles.block : styles.inline])}
      to={multiplex([to, [suppress, '#']])}
      tabIndex={tabIndex}
      openInNewTab={openInNewTab}
      data-testid={dataTestId}
      onClick={onClick}
    >
      <BodyText
        data-testid="text-link-content"
        fontWeight={fontWeight}
        padding={padding}
        margin={margin}
        size={size}
        color={color}
        hoverColor={hoverColor}
        singleLine={singleLine}
        tagName="span"
      >
        {label}
      </BodyText>
    </Link>
  );
};

export default TextLink;
