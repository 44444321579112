import { identifyFormat } from 'doings/formatDatetime/identifyFormat';

import { DateFormat, DateFormats, DateFormatter, formatters } from './DateFormats';

export type DateTime = Date | string | null;

/**
 * Formats a provided date-time to a string of the specified format.
 *
 * @param datetime The date-time to format.
 * @param outputFormat The desired date-time format. Can be a `DateFormat` object or a string like 'yyyy-MM-dd' (check date-fns/format docs for more examples). Defaults to `DateFormats.DATE_NATIONAL`.
 */
const formatDatetime = (datetime?: DateTime, outputFormat?: DateFormat | string): string => {
  if (datetime) {
    const parsed = datetime instanceof Date ? datetime : parseDateString(datetime);

    if (parsed) {
      return getDateFormatter(outputFormat)(parsed);
    }
  }

  return '';
};

export const toNationalShortDate = (datetime?: DateTime): string =>
  formatDatetime(datetime, DateFormats.DATE_NATIONAL_SHORT);

const parseDateString = (datetime: string): Date | undefined => {
  const format = identifyFormat(datetime);

  if (format) {
    return format.parser(datetime);
  }

  return undefined;
};

const getDateFormatter: (format?: DateFormat | string) => DateFormatter = (
  format: DateFormat | string = DateFormats.DATE_NATIONAL_SHORT
) => {
  if (typeof format === 'string') {
    return formatters.main(format);
  }

  return format.formatter;
};

export { DateFormats };

export default formatDatetime;
