import { Dropdown, OptionListOption } from '@gaiads/telia-react-component-library';
import { useTranslation } from 'react-i18next';
import { SortAction, SortOrder, SortParameter } from 'types/sort';

const NewsRatingsSortDropdown: React.FC<{
  currentSort: SortParameter;
  doSort: SortAction;
  'data-testid'?: string;
}> = ({ currentSort, doSort, 'data-testid': testId }) => {
  const { t } = useTranslation();
  const options = [
    {
      label: `${t('productNewsRatings.list.lastModified')} ↑`,
      value: 'updatedAt,asc'
    },
    {
      label: `${t('productNewsRatings.list.lastModified')} ↓`,
      value: 'updatedAt,desc'
    },
    {
      label: `${t('productNewsRatings.list.user')} ↑`,
      value: 'userId,asc'
    },
    {
      label: `${t('productNewsRatings.list.user')} ↓`,
      value: 'userId,desc'
    },
    {
      label: `${t('productNewsRatings.list.vote')} ↑`,
      value: 'vote,asc'
    },
    {
      label: `${t('productNewsRatings.list.vote')} ↓`,
      value: 'vote,desc'
    }
  ] as OptionListOption[];

  return (
    <Dropdown
      data-testid={testId}
      label={t('productNewsRatings.list.sortOrder')}
      aria-label={t('productNewsRatings.list.sortOrder')}
      options={options}
      onChange={(_event, { value }) =>
        doSort(value.split(',')[0], value.split(',')[1] as SortOrder)
      }
      selectedOption={options.find(
        ({ value }) => value === `${currentSort.sort},${currentSort.order}`
      )}
    />
  );
};

export { NewsRatingsSortDropdown };
