import { TeliaLink } from 'common-components';
import { useUserData } from 'contexts/UserContext/UserContext';
import { isSmallBusiness } from 'doings/isSmallBusiness/isSmallBusiness';
import userHasPermission from 'doings/userHasPermission/userHasPermission';

const SubscriptionsLink: React.FC<{
  collectionGroup: string;
  text: string;
}> = ({ collectionGroup, text }) => {
  const user = useUserData();
  const isLargeBusiness = !isSmallBusiness(user);
  const shouldLinkToB2BPortal =
    isLargeBusiness && userHasPermission(user)({ onePermission: 'B2B_PORTAL' });

  return (
    <TeliaLink.Portal
      data-testid="subscriptions-link"
      variant="standalone"
      to={`/subscriptions?scope=collectionGroup&search=${collectionGroup}`}
      toCorporatePortal="search/subscriptionSearch"
      useRouterLink={!shouldLinkToB2BPortal}
      label={text}
    />
  );
};
export default SubscriptionsLink;
