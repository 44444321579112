import { ApolloError } from '@apollo/client';
import { Page } from '@gaiads/telia-react-component-library';
import { ExcelDownloadLink } from 'B2XApp/Messages/Tickets/TicketsList/ExcelDownloadLink';
import { Memo, PageWithSubnavigation } from 'common-components';
import EmptyListContent from 'common-components/EmptyListContent/EmptyListContent';
import ListHeader from 'common-components/ListHeader/ListHeader';
import ShowMoreResults from 'common-components/ShowMoreResults/ShowMoreResults';
import { EditableListProvider } from 'contexts/EditableListContext/EditableListContext';
import { TicketsFilters } from 'hooks/tickets/useTicketsFilters/useTicketsFilters';
import { ViewStatus } from 'hooks/useApiStatus/useApiStatus';
import { useTranslation } from 'react-i18next';
import { TicketListItem } from 'types/ticket';

import { NewTicketButton } from '../NewTicketButton/NewTicketButton';
import TicketsListInfoNotice from './TicketsListInfoNotice';
import TicketsListView, { TicketsListViewColumnSetup } from './TicketsListView';

const TicketsList: React.FC<{
  ticketsFilter: TicketsFilters;
  tickets: { total: number; items: TicketListItem[] };
  status: ViewStatus;
  error?: ApolloError;
  fetchMore?: VoidFunction;
  multipleCompanySearchEnabled: boolean;
}> = ({
  ticketsFilter,
  tickets: { items, total },
  status,
  error,
  fetchMore,
  multipleCompanySearchEnabled
}) => {
  const { t } = useTranslation();

  const {
    dateTypeOptions,
    searchFieldOptions,
    statusOptions,
    queryParametersAbsent,
    queryParameters,
    allCompanySearch
  } = ticketsFilter;

  const hasResults = !!items.length || !!error;

  return (
    <EditableListProvider
      columnSetup={TicketsListViewColumnSetup}
      invocationArgs={[t, multipleCompanySearchEnabled]}
      changeableArgs={[undefined, multipleCompanySearchEnabled]}
    >
      <PageWithSubnavigation
        loading={status === ViewStatus.LoadingInitial}
        error={!!error && queryParametersAbsent ? error : undefined}
        emptyContentPlaceholder={
          !hasResults &&
          queryParametersAbsent &&
          status === ViewStatus.Empty && (
            <EmptyListContent
              title={t('tickets.emptyList.title')}
              description={t('tickets.emptyList.description')}
              footerContent={<NewTicketButton />}
            />
          )
        }
        showSubnavigationForCurrentPath
        data-testId="tickets-list-page-with-subnavigation"
      >
        <ListHeader
          withMultiCompanyFeature={multipleCompanySearchEnabled}
          multipleCompaniesCheckboxParams={{
            checked: allCompanySearch.showFromAllCompanies,
            label: t('tickets.list.searchAllLabel'),
            testId: 'tt-search-all-companies',
            onChange: allCompanySearch.toggleAllCompanies
          }}
          titleTestid="tickets-count"
          searchScopeOptions={searchFieldOptions}
          withSearchActivationThreshold
          actionButtons={<NewTicketButton />}
          dateRangeParams={{
            label: t('tickets.list.filters.dateRange'),
            helperText: t('common.filterSettings.dateHelperText'),
            helperTooltip: t('tickets.list.filters.dateFilterDefaultRangeTooltip'),
            withDefaultDatePlaceholder: true
          }}
          dateTypeParams={{
            label: t('tickets.list.filters.dateRangeType'),
            options: dateTypeOptions,
            'data-testid': 'ticket-date-type-filter'
          }}
          statusFilterParams={{
            label: t('tickets.list.filters.status'),
            options: statusOptions,
            'data-testid': 'ticket-status-dropdown'
          }}
          labels={{
            searchInMobileView: t('tickets.list.searchLabel')
          }}
          excelDownloadLink={
            <ExcelDownloadLink
              queryParameters={queryParameters}
              disabled={status === ViewStatus.Loading || items.length === 0}
              totalItems={total}
              allCompanies={allCompanySearch.showFromAllCompanies}
            />
          }
          infoNotice={<TicketsListInfoNotice />}
          data-testid="tickets-list-header"
        >
          {t('tickets.list.numberOfTickets', {
            numberOfTickets: total
          })}
        </ListHeader>

        <Page.Row responsiveFullWidth>
          <Memo comparables={['loading', 'tickets']}>
            <TicketsListView
              loading={status === ViewStatus.Loading}
              tickets={items}
              data-testid="tickets-list-view"
            />
          </Memo>
        </Page.Row>

        {fetchMore && status !== ViewStatus.Loading && (
          <Page.Row>
            <ShowMoreResults
              data-testid="show-more-tickets"
              loading={status === ViewStatus.LoadingMore}
              hasMoreResults={items.length < (total || 0)}
              hasResults={hasResults}
              fetchMore={fetchMore}
            />
          </Page.Row>
        )}
      </PageWithSubnavigation>
    </EditableListProvider>
  );
};

export default TicketsList;
