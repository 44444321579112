import hasLength from 'doings/hasLength/hasLength';
import isIntegeric from 'doings/isIntegeric/isIntegeric';
import useSubscriptionTypes from 'hooks/mobileSubscriptions/useSubscriptionTypes/useSubscriptionTypes';
import useReadQueryParams from 'hooks/useQueryParams/useReadQueryParams';
import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownOptionDefinition, SearchOptionDefinition } from 'types/listFilters';

const getSearchFieldOptions = (t: TFunction): SearchOptionDefinition[] => [
  { value: 'userInfo', label: t('subscriptions.searchOptions.userName') },
  {
    value: 'icc',
    label: t('subscriptions.searchOptions.icc'),
    tooltip: t('subscriptions.searchOptions.iccInstruction')
  },
  { value: 'msisdn', label: t('subscriptions.searchOptions.msisdn') },
  { value: 'costCenter', label: t('subscriptions.searchOptions.costCenter') },
  {
    value: 'collectionGroup',
    label: t('subscriptions.searchOptions.collectionGroup')
  }
];

const getSubscriptionTypeOptions = (types: string[]): DropdownOptionDefinition[] =>
  types.map((value) => ({
    value,
    label: value
  }));

const ICC_NUMBER_LENGTH__SHORT = 12;
const ICC_NUMBER_LENGTH__PREFIXED = 19;
const ICC_NUMBER_LENGTH = [ICC_NUMBER_LENGTH__SHORT, ICC_NUMBER_LENGTH__PREFIXED];

const validateSearchTerm = (scope?: string, search?: string) => {
  if (scope === 'icc' && !!search) {
    return [isIntegeric, hasLength(ICC_NUMBER_LENGTH)].every((predicate) => predicate(search));
  }

  return true;
};

export type UseSubscriptionFiltersResult = ReturnType<typeof useSubscriptionFilters>;

export const useSubscriptionFilters = () => {
  const { t } = useTranslation();
  const searchFieldOptions = useMemo(() => getSearchFieldOptions(t), [t]);
  const subscriptionTypes = useSubscriptionTypes();
  const subscriptionTypeOptions = getSubscriptionTypeOptions(subscriptionTypes);

  const queryParameters = useReadQueryParams(['scope', 'search', 'type']);

  const { scope, search, type } = queryParameters;
  const queryParametersAbsent = !(!!search || !!type);

  const searchTermValid = validateSearchTerm(scope, search);
  return {
    searchFieldOptions,
    subscriptionTypeOptions,
    queryParameters,
    queryParametersAbsent,
    searchTermValid
  };
};
