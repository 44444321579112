import { BodyText } from '@gaiads/telia-react-component-library';
import { DefinitionList, TextWithInlineHtml } from 'common-components';
import { Div } from 'core-components';
import formatDatetime, { DateFormats } from 'doings/formatDatetime/formatDatetime';
import { useTranslation } from 'react-i18next';
import { ActivityLogEntry } from 'types/activityLog';

const ActivityLogItemDetails: React.FC<{ entry: ActivityLogEntry }> = ({ entry }) => {
  const { t } = useTranslation();

  return (
    <>
      <Div margin={entry.transcript ? { bottom: 'xs' } : { bottom: 'md' }}>
        <DefinitionList
          addDividers
          alignRight
          leadingDivider
          trailingDivider={!!entry.transcript}
          data-testid="activity-log-item-details"
        >
          {entry.baseType === 'call' && entry.type && (
            <DefinitionList.Item heading={t('activityLog.item.details.type')}>
              <BodyText>{entry.type}</BodyText>
            </DefinitionList.Item>
          )}

          {entry.creationDate && (
            <DefinitionList.Item heading={t('activityLog.item.details.time')}>
              <BodyText>
                {formatDatetime(entry.creationDate, DateFormats.TIME_NATIONAL_SHORT)}
              </BodyText>
            </DefinitionList.Item>
          )}

          {entry.invoiceNumber && (
            <DefinitionList.Item heading={t('activityLog.item.details.invoice')}>
              <BodyText>{entry.invoiceNumber}</BodyText>
            </DefinitionList.Item>
          )}

          {entry.subscriptionNumber && (
            <DefinitionList.Item heading={t('activityLog.item.details.subscription')}>
              <BodyText>{entry.subscriptionNumber}</BodyText>
            </DefinitionList.Item>
          )}
        </DefinitionList>
      </Div>

      {entry.baseType === 'chat' ? <ChatDetails entry={entry} /> : null}
    </>
  );
};

const ChatDetails: React.FC<{ entry: ActivityLogEntry }> = ({ entry }) => {
  const { t } = useTranslation();

  return (
    <>
      <BodyText margin={{ bottom: 'sm' }} fontWeight="medium">
        {t('activityLog.item.details.transcript')}
      </BodyText>

      <Div padding={{ bottom: 'md' }}>
        {entry.transcript && <TextWithInlineHtml text={entry.transcript} />}
      </Div>
    </>
  );
};

export default ActivityLogItemDetails;
