import { BodyText, Div, Flex, Icon, MarginSpace } from '@gaiads/telia-react-component-library';
import NavigationMenuItem from 'B2XApp/TopNavigation/Navigation/NavigationMenu/NavigationMenuItem/NavigationMenuItem';
import useIsPathMatch from 'hooks/useIsPathMatch/useIsPathMatch';
import { useTranslation } from 'react-i18next';
import { NavigationItem } from 'types/navigation';

import styles from './MobileNavigationItem.module.scss';

const MobileNavigationItem: React.FC<{
  id: string;
  labelI18nKey: string;
  isMenu: boolean;
  setOpenMenuId: VoidFunction;
  isCurrentMenuOpen: boolean;
  itemProps: {
    to?: string;
    external?: boolean;
  };
  menuProps: {
    menuItems?: NavigationItem[];
  };
}> = ({ id, labelI18nKey, isMenu, setOpenMenuId, isCurrentMenuOpen, itemProps, menuProps }) => {
  const isPathMatch = useIsPathMatch();
  const { t } = useTranslation();

  return (
    <>
      {isMenu ? (
        <Div
          className={styles.mobileNavigationItem}
          data-testid={`navigation-menu-${id}`}
          onClick={setOpenMenuId}
        >
          <MarginSpace>
            <Flex
              className={styles.mobileNavigationItem_menuItem}
              padding={{ vertical: 'sm' }}
              clickableAppearance
              spaceBetween
              tabIndex={0}
              onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
                e.key === 'Enter' && setOpenMenuId();
              }}
              aria-label={t(labelI18nKey)}
              aria-expanded={isCurrentMenuOpen}
              role="button"
            >
              <BodyText>{t(labelI18nKey)}</BodyText>

              <Icon
                name={isCurrentMenuOpen ? 'chevron-up' : 'chevron-down'}
                size="md"
                aria-hidden
              />
            </Flex>

            {isCurrentMenuOpen &&
              menuProps.menuItems?.map(
                ({ id, labelI18nKey, to, trackForTopNavigation, ...rest }, index, items) => (
                  <Div key={id}>
                    <NavigationMenuItem
                      itemId={id}
                      data-testid={`navigation-menu-item-${id}`}
                      labelI18nKey={labelI18nKey}
                      to={to}
                      active={isPathMatch([to ?? ''])}
                      analyticsEvent={trackForTopNavigation}
                      margin={{
                        size: 'zero',
                        bottom: index === items.length - 1 ? 'xs' : 'zero'
                      }}
                      padding={{ vertical: 'sm', horizontal: 'sm' }}
                      {...rest}
                    />
                  </Div>
                )
              )}
          </MarginSpace>
        </Div>
      ) : (
        <Div padding={{ vertical: 'sm' }}>
          <NavigationMenuItem
            itemId={id}
            data-testid={`navigation-menu-item-${id}`}
            to={itemProps.to}
            labelI18nKey={labelI18nKey}
            external={itemProps.external}
            active={isPathMatch([itemProps.to ?? ''])}
            padding={{ size: 'zero' }}
          />
        </Div>
      )}
    </>
  );
};

export default MobileNavigationItem;
