import { GridBag, Gutter } from '@gaiads/telia-react-component-library';
import { Repeat, Skeleton } from 'common-components';

const MainInfoSkeleton: React.FC = () => {
  return (
    <Skeleton.Panel>
      <GridBag>
        <GridBag.Item sm={8} xs={12}>
          <Skeleton.Title width="100%" />

          <Gutter />

          <Repeat times={4}>
            <Skeleton.TextRow width="100%" />

            <Gutter size="xs" />
          </Repeat>

          <Skeleton.TextRow width="100%" />

          <Gutter size="sm" />

          <Skeleton.TextRow width="60%" />
        </GridBag.Item>

        <GridBag.Item md={4} xs={0} alignTextToRight>
          <Skeleton.Image width="90px" height="67px" display="inline-block" />
        </GridBag.Item>
      </GridBag>
    </Skeleton.Panel>
  );
};

export default MainInfoSkeleton;
