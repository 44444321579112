import { Div, Flex, Gutter, TextArea } from '@gaiads/telia-react-component-library';
import { TicketFileUploader, useTicketAttachments } from 'B2XApp/Messages/common';
import {
  ActionButtonGroup,
  Analytics,
  Button,
  DataPanel,
  DetailsLoadingOverlay,
  Heading,
  InlineNotification
} from 'common-components';
import ConfirmationPanelSuccess from 'common-components/ConfirmationPanel/ConfirmationPanelSuccess';
import { AnalyticsEvent } from 'doings/track/analyticsEvents';
import { SUPPORTED_FILE_TYPES } from 'doings/validateMessageAttachment/validateMessageAttachment';
import useScrollElementIntoView from 'hooks/useScrollElementIntoView/useScrollElementIntoView';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TEXT_MAX_LENGTH } from 'types/form';

const ReplyForm: React.FC<{
  setShowReplyForm: (showReplyForm: boolean) => void;
  setShowReplySentMessage: (showReplyForm: boolean) => void;
  onReply: (note: string, attachments?: File[]) => void;
  replySent: boolean;
  loading: boolean;
  loadingText?: string;
  error: boolean;
  maxNoteLength: number;
  replyAnalyticsEvent: AnalyticsEvent;
}> = ({
  setShowReplyForm,
  setShowReplySentMessage,
  replySent,
  loading,
  loadingText,
  error,
  onReply,
  maxNoteLength,
  replyAnalyticsEvent
}) => {
  const replyFormRef: React.RefObject<HTMLDivElement> = useRef(null);

  const { t } = useTranslation();
  const [noteError, setNoteError] = useState<string | undefined>(undefined);
  const [note, setNote] = useState('');

  const replyAttachmentsModel = useTicketAttachments();

  const updateNote = (text: string) => {
    setNoteError(
      text.length > maxNoteLength
        ? t('tickets.replyToTicket.tooLongMessage', { maxNoteLength })
        : undefined
    );
    setNote(text);
  };

  useScrollElementIntoView({
    elementRef: replyFormRef,
    shouldScroll: !loading && !error
  });

  if (replySent) {
    return (
      <Div>
        <ConfirmationPanelSuccess
          data-testid="reply-to-ticket-success"
          title={t('tickets.replyToTicket.replySent.title')}
          description={t('tickets.replyToTicket.replySent.description')}
        >
          <Button
            data-testid="close-reply-sent-message"
            variant="secondary"
            size="sm"
            onClick={() => {
              setShowReplySentMessage(false);
              setShowReplyForm(false);
            }}
          >
            {t('tickets.replyToTicket.replySent.dismissButton')}
          </Button>
        </ConfirmationPanelSuccess>
      </Div>
    );
  }

  return (
    <Div data-testid="messages-reply-form">
      <DataPanel>
        <DetailsLoadingOverlay
          showLoader={loading}
          loadingText={loadingText}
          data-testid="reply-to-ticket-loading-overlay"
        >
          <div ref={replyFormRef}>
            <Flex directionColumn>
              <Heading.H3>{t('tickets.newMessage')}</Heading.H3>

              <Gutter size="sm" />

              <TextArea
                data-testid="reply-to-ticket-text-area"
                value={note}
                maxLength={TEXT_MAX_LENGTH}
                label=""
                placeholder={t('tickets.replyToTicket.textAreaPlaceholder')}
                onChange={(_event, value) => updateNote(value)}
                errorText={noteError}
                disabled={loading}
                autoFocus={true}
              />

              <Gutter size="sm" />

              <TicketFileUploader
                supportedFileTypesForMessages={SUPPORTED_FILE_TYPES}
                attachmentsModel={replyAttachmentsModel}
                replyToTicket
                data-testid="reply-to-ticket-file-uploader"
              />

              {error && (
                <InlineNotification
                  data-testid="reply-to-ticket-error"
                  title={t('tickets.replyToTicket.error.title')}
                  content={t('tickets.replyToTicket.error.text')}
                  variant="error"
                />
              )}

              <Gutter size="sm" />

              <ActionButtonGroup>
                <Analytics whenClicked={replyAnalyticsEvent}>
                  <Button
                    data-testid="reply-to-ticket-button"
                    variant="primary"
                    size="sm"
                    disabled={!note || loading || !!noteError || !replyAttachmentsModel.isValid}
                    onClick={() => onReply(note, replyAttachmentsModel.attachments)}
                  >
                    {t('tickets.replyToTicket.sendMessage')}
                  </Button>
                </Analytics>

                <Button
                  data-testid="cancel-reply-to-ticket-button"
                  variant="tertiary-purple"
                  size="sm"
                  onClick={() => setShowReplyForm(false)}
                >
                  {t('common.cancelButton.label')}
                </Button>
              </ActionButtonGroup>
            </Flex>
          </div>
        </DetailsLoadingOverlay>
      </DataPanel>
    </Div>
  );
};

export default ReplyForm;
