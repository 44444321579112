import { Gutter } from '@gaiads/telia-react-component-library';
import { Skeleton } from 'common-components';

const TicketDetailsTimelineSkeleton: React.FC = () => {
  return (
    <Skeleton.Panel>
      <Skeleton.TextRow width="60%" />

      <Gutter size="xs" />

      <Skeleton.Title width="100%" />

      <Gutter size="xs" />

      <Skeleton.TextRow width="60%" />
    </Skeleton.Panel>
  );
};

export default TicketDetailsTimelineSkeleton;
