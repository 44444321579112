import { Message } from 'B2XApp/Messages/common';
import { DefinitionListMultilineItemProps } from 'common-components';
import formatDatetime, { DateFormats } from 'doings/formatDatetime/formatDatetime';
import { TFunction } from 'i18next';
import { getOr, uniq } from 'lodash/fp';
import {
  Attachment,
  ServiceRequestDetailsItem,
  Note as ServiceRequestNote
} from 'types/serviceRequest';
import { SortOrder } from 'types/sort';
import {
  ContactMedium,
  Medium,
  RelatedIndividual,
  Ticket,
  TicketAttachment,
  Note as TicketNote
} from 'types/ticket';

export enum ContactMediumType {
  Email = 'Email',
  TelephoneNumber = 'TelephoneNumber',
  Sms = 'sms'
}

export enum PhoneNumberType {
  Mobile = 'mobile'
}

export enum RelatedIndividualRole {
  Reporter = 'reporter',
  Contact = 'contact'
}

interface PartitionedAttachments {
  teliaAttachments: TicketAttachment[];
  customerAttachments: TicketAttachment[];
}

const hasSource = (attachment: TicketAttachment): attachment is Attachment =>
  'source' in attachment;

const partitionAttachmentByRelatedNote = (
  acc: PartitionedAttachments,
  attachment: TicketAttachment,
  note: TicketNote | ServiceRequestNote | undefined
) => {
  if (!note || note.source !== 'Telia') {
    acc.customerAttachments.push(attachment);
    return;
  }

  acc.teliaAttachments.push(attachment);
};

const partitionAttachmentBySource = (acc: PartitionedAttachments, attachment: TicketAttachment) => {
  if (!hasSource(attachment) || attachment.source !== 'Telia') {
    acc.customerAttachments.push(attachment);
    return;
  }

  acc.teliaAttachments.push(attachment);
};

export const getTeliaAndCustomerAttachments = (
  resource: Ticket | ServiceRequestDetailsItem
): PartitionedAttachments => {
  if (!resource.attachments?.length) {
    return { teliaAttachments: [], customerAttachments: [] };
  }

  return resource.attachments.reduce<PartitionedAttachments>(
    (acc, attachment) => {
      if (attachment.relatedNote) {
        const note = resource.note?.find((note) => note.id === attachment.relatedNote);
        partitionAttachmentByRelatedNote(acc, attachment, note);
      } else {
        partitionAttachmentBySource(acc, attachment);
      }

      return acc;
    },
    {
      teliaAttachments: [],
      customerAttachments: []
    }
  );
};

interface GetTicketDetailsArgs {
  ticket: Ticket;
  splitAttachments?: boolean;
  t: TFunction;
}

export const getTicketDetails = ({
  ticket,
  splitAttachments,
  t
}: GetTicketDetailsArgs): DefinitionListMultilineItemProps[] => {
  const individualsData: DefinitionListMultilineItemProps[] = mapIndividualsData(
    t,
    ticket.relatedIndividual
  );

  const ticketDetails: DefinitionListMultilineItemProps[] = [
    {
      heading: t('tickets.details.id'),
      values: [ticket.id]
    },
    {
      heading: t('tickets.details.ticketCreated'),
      values: [formatDatetime(ticket.creationDate, DateFormats.DATE_TIME_NATIONAL_SHORT)]
    },
    {
      heading: t('tickets.details.ticketModified'),
      values: [formatDatetime(ticket.lastUpdate, DateFormats.DATE_TIME_NATIONAL_SHORT)]
    },
    {
      heading: t('tickets.details.relatedService'),
      values: [ticket.serviceName, ticket.productName].filter(Boolean)
    },
    {
      heading: t('tickets.details.subscription'),
      values: [ticket.subscription]
    },
    {
      heading: t('tickets.details.company'),
      values: [ticket.companyCode, ticket.companyName]
    }
  ];

  const attachments: DefinitionListMultilineItemProps[] = [];

  if (splitAttachments) {
    const { customerAttachments, teliaAttachments } = getTeliaAndCustomerAttachments(ticket);
    if (customerAttachments.length) {
      attachments.push({
        heading: t('tickets.details.attachmentsFromYou'),
        type: 'attachment',
        values: customerAttachments
      });
    }

    if (teliaAttachments.length) {
      attachments.push({
        heading: t('tickets.details.attachmentsFromTelia'),
        type: 'attachment',
        values: teliaAttachments
      });
    }
  } else {
    attachments.push({
      heading: t('tickets.allAttachments.title'),
      type: 'attachment',
      values: ticket.attachments
    });
  }

  return [...ticketDetails, ...individualsData, ...attachments];
};

export const mapIndividualsData = (t: TFunction, relatedIndividuals: RelatedIndividual[]) => [
  ...mapIndividualsReporter(t, relatedIndividuals),
  ...mapIndividualsContact(t, relatedIndividuals)
];

export const mapIndividualsReporter = (t: TFunction, relatedIndividuals: RelatedIndividual[]) =>
  relatedIndividuals.length > 0
    ? [
        {
          heading: t('tickets.details.reporter'),
          values: getContactDetailsForRole(relatedIndividuals, RelatedIndividualRole.Reporter)
        }
      ]
    : [];

export const mapIndividualsContact = (t: TFunction, relatedIndividuals: RelatedIndividual[]) =>
  relatedIndividuals.length > 0
    ? [
        {
          heading: t('tickets.details.contact'),
          values: getContactDetailsForRole(relatedIndividuals, RelatedIndividualRole.Contact)
        }
      ]
    : [];

const getContactDetailsForRole = (
  relatedIndividuals: RelatedIndividual[],
  role: RelatedIndividualRole
) => {
  const relatedIndividual = getIndividualByRole(relatedIndividuals, role);
  if (!relatedIndividual) {
    return [];
  }

  const contactDetails = [
    getName(relatedIndividual),
    ...(relatedIndividual.contactMedium ?? []).flatMap(mapContactMedium)
  ].filter((e) => !!e);

  return uniq(contactDetails);
};

const getIndividualByRole = (individuals: RelatedIndividual[], role: RelatedIndividualRole) =>
  individuals.find((relatedIndividual: RelatedIndividual) => relatedIndividual.role === role);

const getName = ({ contactName, fullName = '', givenName, familyName }: RelatedIndividual) => {
  if (givenName && familyName) {
    return `${givenName} ${familyName}`;
  }

  return contactName ?? fullName;
};

export const mapContactMedium = (medium: ContactMedium | null): string | string[] => {
  if (medium) {
    return medium.type === ContactMediumType.Email
      ? getOr('', 'medium.emailAddress', medium)
          .split(/\s*?,\s*?/g)
          .filter(Boolean)
      : getOr('', 'medium.number', medium);
  }

  return '';
};

export const mediumHasValue = (medium: Medium): boolean =>
  Object.values(medium).some((value: string | undefined) => !!value);

export const getReporter = (resource: Ticket | ServiceRequestDetailsItem) => {
  const relatedIndividual = resource?.relatedIndividual;

  if (!relatedIndividual) {
    return undefined;
  }

  const reporter = getIndividualByRole(relatedIndividual, RelatedIndividualRole.Reporter);

  if (reporter) {
    return reporter;
  }

  return getIndividualByRole(relatedIndividual, RelatedIndividualRole.Contact);
};

export const getAuthorFirstName = (relatedIndividual?: RelatedIndividual) => {
  return (
    relatedIndividual?.givenName ||
    relatedIndividual?.contactName ||
    relatedIndividual?.fullName ||
    ''
  );
};

export const getAuthorLastName = (relatedIndividual?: RelatedIndividual) => {
  return relatedIndividual?.givenName ? relatedIndividual.familyName : '';
};

export const getResolverName = (resource: Ticket | ServiceRequestDetailsItem) => {
  return resource.relatedIndividual?.find(
    (relatedIndividual) => relatedIndividual.role === 'resolver'
  )?.givenName;
};

export const mapAttachments = (
  resource: Ticket | ServiceRequestDetailsItem,
  note: TicketNote | ServiceRequestNote
) => {
  return resource.attachments?.filter((attachment) => attachment.relatedNote === note.id);
};

export const mapInitialAttachments = (resource: Ticket | ServiceRequestDetailsItem) => {
  return (
    resource.attachments?.filter((attachment) => {
      return (
        !attachment.relatedNote ||
        !resource.note?.find((note) => note.id === attachment.relatedNote)
      );
    }) || []
  );
};

export const ticketToMessages = (ticket: Ticket, sortOrder: SortOrder, t: TFunction) => {
  const reporter = getReporter(ticket);

  const userMessage: Message = {
    header: ticket.name,
    date: ticket.requestDate,
    description: ticket.description,
    source: 'Customer',
    authorFirstName: getAuthorFirstName(reporter),
    authorLastName: getAuthorLastName(reporter),
    attachments: mapInitialAttachments(ticket)
  };

  const messages =
    ticket.note?.map<Message>((note) => ({
      attachments: mapAttachments(ticket, note),
      ...(encodeType(note.type) === 'customer_resolution'
        ? {
            header: t('tickets.details.noteTypes.customer_resolution'),
            isResolutionMessage: true
          }
        : { header: '' }),
      description: note.text,
      date: note.date ?? '',
      source: note.source,
      authorFirstName: note.authorFirstName,
      authorLastName: note.authorLastName
    })) ?? [];

  const allMessages = [...messages, userMessage];
  return sortOrder === SortOrder.ASC ? allMessages.toReversed() : allMessages;
};

const encodeType = (str: string) => str.toLowerCase().replace(' ', '_');

export const getTicketInfo = (ticket?: Ticket) =>
  ticket && {
    title: ticket.name,
    id: ticket.id,
    idHeader: 'tickets.details.id',
    status: ticket.status,
    statusHeader: 'tickets.details.status'
  };
