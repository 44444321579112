import { AuthorizationParams } from 'types/authorization';

type RouteGroupName =
  | 'ADMIN'
  | 'BILLING'
  | 'BILLING_INVOICES'
  | 'BILLING_INFO_MASS_CHANGE'
  | 'BILLING_INFO_CHANGES'
  | 'SERVICE_REQUESTS'
  | 'SERVICE_REQUESTS_NEW'
  | 'INCIDENT_TICKETS'
  | 'INCIDENT_TICKETS_NEW'
  | 'MESSAGE_ARCHIVE'
  | 'REQUEST_FOR_QUOTATION'
  | 'ACTIVITY_LOG'
  | 'CUSTOMER_SPECIFIC_MESSAGES'
  | 'DEVICES'
  | 'MOBILE_SUBSCRIPTIONS'
  | 'ASSETS'
  | 'OTHER_PRODUCTS'
  | 'REPORTS'
  | 'B2B_PORTAL'
  | 'B2B_QUICKLINKS_ADMIN'
  | 'B2B_QUICKLINKS_CREATE_EMPLOYEE'
  | 'B2B_QUICKLINKS_MOBILE'
  | 'B2B_QUICKLINKS_REPORTS'
  | 'B2B_QUICKLINKS_CID'
  | 'B2B_QUICKLINKS_COMMUNICATION_SERVICES'
  | 'ORDERS'
  | 'ORDERS_INQUIRY'
  | 'B2O_ORDERS'
  | 'B2O_ORDERS_ORDER'
  | 'B2B_ORDERS'
  | 'SALESPORTAL'
  | 'UNRESTRICTED'
  | 'B2B_PAGES'
  | 'B2O_PAGES'
  | 'B2O_EVENTS'
  | 'B2O_REQUEST_FOR_QUOTE';

const routeAccessGroups: {
  [key in RouteGroupName]: AuthorizationParams;
} = {
  ADMIN: {
    onePermission: 'ADMIN'
  },
  BILLING: {
    anyPermission: ['BILLING', 'BILLING_VIEW']
  },
  BILLING_INVOICES: {
    anyPermission: ['BILLING_INVOICES', 'BILLING_INVOICES_VIEW']
  },
  BILLING_INFO_MASS_CHANGE: {
    onePermission: 'SERVICE_REQUESTS',
    anyPermission: ['BILLING']
  },
  BILLING_INFO_CHANGES: {
    anyPermission: ['BILLING', 'SERVICE_REQUESTS', 'MOBILE_SUBSCRIPTIONS']
  },
  SERVICE_REQUESTS: {
    anyPermission: ['SERVICE_REQUESTS', 'SERVICE_REQUESTS_VIEW']
  },
  SERVICE_REQUESTS_NEW: {
    onePermission: 'SERVICE_REQUESTS'
  },
  INCIDENT_TICKETS: {
    anyPermission: ['INCIDENT_TICKETS', 'INCIDENT_TICKETS_VIEW']
  },
  INCIDENT_TICKETS_NEW: {
    onePermission: 'INCIDENT_TICKETS'
  },
  REQUEST_FOR_QUOTATION: {
    anyPermission: ['INCIDENT_TICKETS', 'SERVICE_REQUESTS', 'B2B_ORDERS']
  },
  MESSAGE_ARCHIVE: {
    onePermission: 'MESSAGE_ARCHIVE'
  },
  ACTIVITY_LOG: {
    anyPermission: ['ACTIVITY_LOG_ADMIN', 'ACTIVITY_LOG']
  },
  CUSTOMER_SPECIFIC_MESSAGES: {
    activeGroup: 'b2b',
    onePermission: 'B2B_PORTAL',
    anyPermission: ['ADMIN']
  },
  DEVICES: {
    anyPermission: ['DEVICES', 'DEVICES_VIEW']
  },
  MOBILE_SUBSCRIPTIONS: {
    anyPermission: ['MOBILE_SUBSCRIPTIONS', 'MOBILE_SUBSCRIPTIONS_VIEW']
  },
  ASSETS: {
    anyPermission: ['ASSETS', 'ASSETS_VIEW']
  },
  OTHER_PRODUCTS: {
    onePermission: 'B2B_PORTAL',
    anyPermission: ['OTHER_PRODUCTS', 'OTHER_PRODUCTS_VIEW']
  },
  REPORTS: {
    onePermission: 'B2B_PORTAL',
    anyPermission: ['REPORTS']
  },
  B2B_PORTAL: {
    onePermission: 'B2B_PORTAL'
  },
  B2B_QUICKLINKS_ADMIN: {
    onePermission: 'B2B_PORTAL',
    anyPermission: ['ADMIN']
  },
  B2B_QUICKLINKS_CREATE_EMPLOYEE: {
    onePermission: 'B2B_PORTAL',
    anyPermission: [
      'MOBILE_SUBSCRIPTIONS',
      'BROADBAND_SUBSCRIPTIONS',
      'CID_SUBSCRIPTIONS',
      'DATACOM_SERVICES'
    ]
  },
  B2B_QUICKLINKS_MOBILE: {
    onePermission: 'B2B_PORTAL',
    anyPermission: ['MOBILE_SUBSCRIPTIONS']
  },
  B2B_QUICKLINKS_REPORTS: {
    onePermission: 'B2B_PORTAL',
    anyPermission: ['REPORTS']
  },
  B2B_QUICKLINKS_CID: {
    onePermission: 'B2B_PORTAL',
    anyPermission: ['CID_SUBSCRIPTIONS']
  },
  B2B_QUICKLINKS_COMMUNICATION_SERVICES: {
    onePermission: 'B2B_PORTAL',
    anyPermission: ['COMMUNICATION_SERVICES']
  },
  ORDERS: {
    anyPermission: ['B2B_ORDERS', 'B2B_ORDERS_VIEW', 'B2O_ORDERS', 'B2O_ORDERS_VIEW']
  },
  ORDERS_INQUIRY: {
    onePermission: 'SERVICE_REQUESTS',
    anyPermission: ['B2B_ORDERS', 'B2B_ORDERS_VIEW', 'B2O_ORDERS', 'B2O_ORDERS_VIEW']
  },
  B2B_ORDERS: {
    anyPermission: ['B2B_ORDERS', 'B2B_ORDERS_VIEW']
  },
  B2O_ORDERS: {
    anyPermission: ['B2O_ORDERS', 'B2O_ORDERS_VIEW']
  },
  B2O_ORDERS_ORDER: {
    anyPermission: ['B2O_ORDERS']
  },
  SALESPORTAL: {
    onePermission: 'B2O_SHOP'
  },
  B2O_PAGES: {
    onePermission: 'B2O_PAGES'
  },
  B2O_EVENTS: {
    onePermission: 'B2O_EVENTS'
  },
  B2O_REQUEST_FOR_QUOTE: {
    onePermission: 'B2O_REQUEST_FOR_QUOTE'
  },
  B2B_PAGES: {
    onePermission: 'B2B_PAGES'
  },
  UNRESTRICTED: {}
};

export default routeAccessGroups;
