import { InlineNotification } from 'common-components';
import { invoicing } from 'doings/track/analyticsEvents';

import { B2CInvoicesNotificationLink } from './B2CInvoicesNotificationLink';
import { TinServiceNotificationLink } from './TinServiceNotificationLink';

export const InvoiceListInfoNotices: React.FC = () => (
  <InlineNotification
    data-testid="other-invoices-notification"
    variant="information"
    content={{
      key: 'invoices.notifications.otherInvoices',
      components: [
        <TinServiceNotificationLink
          key="link-to-tin-invoices"
          analyticsEvent={invoicing.visitTinService}
        />,
        <B2CInvoicesNotificationLink
          key="link-to-b2c-invoices"
          analyticsEvent={invoicing.visitB2CInvoices}
        />
      ]
    }}
  />
);
