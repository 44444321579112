import { ApolloError } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';

import accessControlErrorHandler, {
  AccessControlHandlerUrlDetails
} from './accessControlErrorHandler';

const useAccessControlErrorHandler = () => {
  const { pathname } = useLocation();
  const { replace } = useHistory();

  return (error: ApolloError) => {
    return accessControlErrorHandler(error, replace, {
      pathname,
      currentLocation: window.location.href,
      queryString: window.location.search || undefined,
      urlFragment: window.location.hash || undefined
    } as AccessControlHandlerUrlDetails);
  };
};

export default useAccessControlErrorHandler;
