import { Div, Flex } from '@gaiads/telia-react-component-library';
import getClassNames from 'classnames';
import { Heading, IconButton, ModalButtons, Optional } from 'common-components';
import { AcceptButtonProps, ModalButtonProps } from 'common-components/ModalButtons/ModalButtons';
import FocusTrap from 'focus-trap-react';
import { useFocusTrap } from 'hooks/useFocusTrap/useFocusTrap';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ModalDialog.module.scss';
import onClick from './onClick';

export interface ModalDialogProps {
  isOpen?: boolean;
  title: string;
  acceptButton?: AcceptButtonProps;
  closeButton?: ModalButtonProps;
  closeIsDefault?: boolean;
  onClose: VoidFunction;
  hideCloseIcon?: boolean;
  disableOutsideClick?: boolean;
  isNarrow?: boolean;
  'data-testid'?: string;
  children: React.ReactNode;
}

const ModalDialog: React.FC<ModalDialogProps> = ({
  isOpen = false,
  title,
  acceptButton,
  closeButton,
  closeIsDefault = false,
  onClose,
  hideCloseIcon = false,
  disableOutsideClick = false,
  isNarrow = false,
  'data-testid': testId,
  children
}) => {
  const { t } = useTranslation();
  const { isFocusTrapActive } = useFocusTrap({
    lockScroll: true,
    open: isOpen,
    zIndex: 700
  });

  const onClickHandler = disableOutsideClick
    ? undefined
    : onClick(onClose, styles.modalDialog_container);

  useEffect(() => {
    document.addEventListener('keyup', closeKeyHandler);
    return () => {
      document.removeEventListener('keyup', closeKeyHandler);
    };
  });

  function closeKeyHandler({ key }: { key: string }) {
    if (key === 'Escape' && isFocusTrapActive && onClose) {
      onClose();
    }
  }

  return (
    <div data-testid="modal-main-container" onClick={onClickHandler}>
      {isOpen && (
        <Div className={styles.modalDialog}>
          <FocusTrap active={isFocusTrapActive} focusTrapOptions={{ allowOutsideClick: true }}>
            <div
              role="dialog"
              aria-modal="true"
              aria-label={title}
              className={styles.modalDialog_panel}
            >
              <Flex centered className={styles.modalDialog_panelFlex}>
                <Div
                  data-testid={testId}
                  borderRadius={{ size: 'lg' }}
                  boxShadow="lg"
                  padding={{ bottom: 'md', top: hideCloseIcon ? 'md' : 'sm' }}
                  className={getClassNames([
                    styles.modalDialog_container,
                    isNarrow ? styles.modalDialog_container_narrow : ''
                  ])}
                  flexItem={{ sizeMultiplier: 1 }}
                >
                  <div className={styles.modalDialog_hiddenFocus} tabIndex={0}></div>

                  <Optional if={!hideCloseIcon}>
                    <Flex padding={{ right: 'sm' }} alignRight>
                      <IconButton
                        name="close"
                        size="md"
                        aria-label={t('common.closeButton.label')}
                        focusable={!closeButton}
                        data-dd-action-name="Modal close"
                        data-testid="modal-close-icon"
                        onClick={onClose}
                      />
                    </Flex>
                  </Optional>

                  <Div>
                    <Flex spaceBetween padding={{ horizontal: 'lg', bottom: 'lg' }} alignBaseline>
                      <Heading.H3>{title}</Heading.H3>
                    </Flex>

                    {children && (
                      <Div
                        padding={{ horizontal: 'lg', bottom: 'sm' }}
                        className={styles.modalDialog_container_content}
                      >
                        {children}
                      </Div>
                    )}

                    <ModalButtons
                      accept={acceptButton}
                      close={closeButton}
                      closeIsDefault={closeIsDefault}
                    />
                  </Div>
                </Div>
              </Flex>
            </div>
          </FocusTrap>
        </Div>
      )}
    </div>
  );
};

export default ModalDialog;
