import { Div, Flex, Gutter } from '@gaiads/telia-react-component-library';
import { ActionButtonGroup, Button, SmartTooltip } from 'common-components';
import { ButtonVariantType } from 'common-components/Button/Button';
import useIsSmallerBreakpointActive from 'hooks/useIsSmallerBreakpointActive/useIsSmallerBreakpointActive';

import styles from './ModalButtons.module.scss';

export type ModalButtonProps = {
  label: string;
  testIdSuffix?: string;
  onClick: VoidFunction;
  disabled?: boolean;
  tooltip?: {
    label: string;
    trigger: boolean;
  };
};

export type AcceptButtonProps = ModalButtonProps & {
  isDestructive?: boolean;
};

const ModalButtons: React.FC<{
  accept?: AcceptButtonProps;
  close?: ModalButtonProps;
  auxiliary?: ModalButtonProps;
  closeIsDefault?: boolean;
  forDrawer?: boolean;
}> = ({ accept, close, auxiliary, closeIsDefault, forDrawer }) => {
  const modalButtonAltLayout = useIsSmallerBreakpointActive('sm');

  const getTestId = ({ suffix, defaultSuffix }: { suffix?: string; defaultSuffix: string }) =>
    `modal-button--${suffix ?? defaultSuffix}`;

  const closeButton = close?.label && (
    <ModalButton
      key={getTestId({ suffix: close?.testIdSuffix, defaultSuffix: 'close' })}
      data-testid={getTestId({ suffix: close?.testIdSuffix, defaultSuffix: 'close' })}
      data-type="close-button"
      button={close}
      actionName="Modal close"
      variant={closeIsDefault ? 'primary' : 'secondary'}
    />
  );

  const acceptButton = accept?.label && (
    <ModalButton
      key={getTestId({ suffix: accept?.testIdSuffix, defaultSuffix: 'accept' })}
      data-testid={getTestId({ suffix: accept?.testIdSuffix, defaultSuffix: 'accept' })}
      data-type="accept-button"
      button={accept}
      actionName="Modal accept"
      variant={closeIsDefault ? 'secondary' : 'primary'}
      isDestructive={accept?.isDestructive}
    />
  );

  const auxiliaryButton = auxiliary?.label && (
    <ModalButton
      key={getTestId({ suffix: auxiliary?.testIdSuffix, defaultSuffix: 'auxiliary' })}
      data-testid={getTestId({ suffix: auxiliary?.testIdSuffix, defaultSuffix: 'auxiliary' })}
      data-type="auxiliary-button"
      button={auxiliary}
      actionName="Modal auxiliary"
      variant="tertiary-purple"
    />
  );

  if (!acceptButton && !closeButton) {
    return null;
  }

  return (
    <Flex
      padding={{ top: 'md', horizontal: forDrawer ? 'zero' : 'lg' }}
      pileVerticallyTo="xxs"
      alignRight={!forDrawer}
      className={forDrawer ? styles.drawer_buttons : undefined}
    >
      <ActionButtonGroup>
        {closeIsDefault
          ? [!!closeButton && closeButton, !!acceptButton && acceptButton]
          : [!!acceptButton && acceptButton, !!closeButton && closeButton]}
      </ActionButtonGroup>

      {!!auxiliaryButton && modalButtonAltLayout && <Gutter size="sm" />}

      {!!auxiliaryButton && (
        <Div flexItem alignTextToRight data-testid="modal-auxiliary-button-container">
          {auxiliaryButton}
        </Div>
      )}
    </Flex>
  );
};

const ModalButton: React.FC<{
  button: ModalButtonProps | AcceptButtonProps;
  actionName: string;
  variant: ButtonVariantType;
  isDestructive?: boolean;
  'data-testid': string;
}> = ({ button, actionName, variant, isDestructive = false, 'data-testid': testId }) => {
  const modalButton = (
    <Button
      data-testid={testId}
      data-dd-action-name={actionName}
      size="sm"
      type="button"
      onClick={button.onClick}
      variant={isDestructive ? 'destructive' : variant}
      disabled={button.disabled}
    >
      {button.label}
    </Button>
  );

  return button.tooltip?.trigger ? (
    <SmartTooltip
      data-testid="edit-links-modal--save--tooltip"
      tooltipContent={button.tooltip.label}
      arrangement="top"
      wrapper="div"
    >
      <SmartTooltip.Trigger wrapper="div">{modalButton}</SmartTooltip.Trigger>
    </SmartTooltip>
  ) : (
    <>{modalButton}</>
  );
};

export default ModalButtons;
