import { ApolloError, gql } from '@apollo/client';
import { multiplex } from 'doings/multiplex/multiplex';
import { paymentRemindersQuery } from 'hooks/invoicing/paymentReminders/usePaymentReminders/usePaymentReminders';
import useCallBackendMutation from 'hooks/useCallBackend/useCallBackendMutation';
import { PaymentReminder, PaymentReminderErrorItem } from 'types/paymentReminder';

export const updatePaymentRemindersMutation = gql`
  mutation UpdatePaymentReminders($requestData: UpdatePaymentRemindersRequest!) {
    updatePaymentReminders(requestData: $requestData) {
      status
      reminders {
        type
        status
        emailAddress
        subscriptionNumber
      }
      errors {
        code
        reference
      }
    }
  }
`;

const mapApiStatus = (
  loading: boolean,
  data?: UpdatePaymentRemindersResult,
  error?: ApolloError
): UpdatePaymentReminderStatus => {
  if (data?.status) {
    return data?.status;
  }

  return multiplex(['initial', [!!loading, 'persisting'], [!!error, 'failure']]);
};

type UpdatePaymentRemindersApiResponseStatus = 'success' | 'partialSuccess' | 'failure';

export type UpdatePaymentReminderStatus =
  | 'initial'
  | 'persisting'
  | 'success'
  | 'partialSuccess'
  | 'failure';

interface UpdatePaymentRemindersResult {
  status: UpdatePaymentRemindersApiResponseStatus;
  reminders?: PaymentReminder[];
  errors?: PaymentReminderErrorItem[];
}

interface UseUpdatePaymentRemindersResult {
  updatePaymentReminders: UpdatePaymentRemindersResult;
}

export default () => {
  const { runMutation, loading, data, error } =
    useCallBackendMutation<UseUpdatePaymentRemindersResult>(updatePaymentRemindersMutation, [
      { query: paymentRemindersQuery }
    ]);

  return {
    updatePaymentReminders: runMutation,
    status: mapApiStatus(loading, data?.updatePaymentReminders, error),
    data: data?.updatePaymentReminders
  };
};
