import { Div, Flex } from '@gaiads/telia-react-component-library';
import styles from 'B2XApp/ContactUs/ContactUs.module.scss';
import { DashboardNotificationRowItem } from 'B2XApp/Dashboard/DashboardNavigation/DashboardNotifications/useGetDashboardNotificationRows';
import { Skeleton, TeliaLink } from 'common-components';
import { Divider } from 'core-components';
import { AnalyticsEvent } from 'doings/track/analyticsEvents';
import { ViewStatus } from 'hooks/useApiStatus/useApiStatus';
import { AuthorizationParams } from 'types/authorization';

const ContactUsMessageLink: React.FC<{
  label: string;
  target?: string;
  onClick?: VoidFunction;
  enabledWhen: AuthorizationParams;
  item?: DashboardNotificationRowItem;
  analyticsEvent?: AnalyticsEvent;
  'data-testid'?: string;
}> = ({
  label,
  target = '',
  onClick,
  enabledWhen,
  item,
  analyticsEvent,

  'data-testid': testId
}) => {
  if (!onClick && !target) {
    return null;
  }

  const hasPendingItems = (item?.total || -1) > 0;
  const loading = item ? item.loadingState === ViewStatus.Loading : false;
  if (loading) {
    return (
      <Flex margin={{ vertical: 'sm' }} alignBaseline>
        <Skeleton.TextRow data-testid="skeleton-text-row" />
      </Flex>
    );
  }

  const Link = item?.navigationLink.external ? TeliaLink.External : TeliaLink.Internal;

  return (
    <>
      <Flex centeredVertically data-testid={`${testId}--container`}>
        {hasPendingItems && (
          <Div
            data-testid="pending-items-count"
            className={styles.itemsCount}
            margin={{ vertical: 'xs' }}
          >
            {item?.total}
          </Div>
        )}

        <Div occupyHorizontalSpace>
          {onClick ? (
            <TeliaLink.Click
              variant="standalone-spacious"
              label={label}
              enabledWhen={enabledWhen}
              onClick={onClick}
              data-testid={testId}
            />
          ) : (
            <Link
              variant="standalone-spacious"
              label={label}
              to={item && hasPendingItems ? item.navigationLink.to : target}
              enabledWhen={enabledWhen}
              track={analyticsEvent}
              data-testid={testId}
            />
          )}
        </Div>
      </Flex>

      <Divider />
    </>
  );
};

export default ContactUsMessageLink;
