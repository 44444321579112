import { FormattedPrice, Period } from 'types/device';
/*
Price formatting code is mostly copied from Ecom app
as it is currently implemented in the client side 
and cannot be exposed through their API
*/

export function formatFavoriteDevicePrice<P = unknown>(
  price: P,
  monthDisplayName: string,
  monthShortDisplayName: string
): FormattedPrice<P> {
  if (!price) {
    price = {
      duration: 0,
      amount: 0,
      price: 0,
      interval: 0,
      period: 'NONE',
      taxRate: 0,
      currency: 'EUR'
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any;
  }

  const locale = 'fi';

  const {
    duration,
    amount: amountInput,
    price: priceInput,
    interval: intervalInput,
    period: periodInput,
    taxRate,
    currency,
    normalPrice
  } =
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    price as any; // PriceData;

  const amount = amountInput || priceInput || 0;
  const interval = intervalInput || periodInput;

  const shortAmount = formatShortPriceAmount(locale, amount);
  const normalShortAmount = normalPrice
    ? formatShortPriceAmount(locale, normalPrice as unknown as number)
    : undefined;
  const fullAmount = formatFullPriceAmount(locale, amount);
  const normalFullAmount = normalPrice ? formatFullPriceAmount(locale, normalPrice) : undefined;
  const formattedTaxRate = formatTaxRate(locale, taxRate);
  const formattedDuration = String(duration || 0);
  const currencySymbol = formatCurrencySymbol(locale, currency);
  const hasInterval = interval && interval !== 'NONE';
  const shortInterval = formatShortInterval(monthShortDisplayName, interval);
  const fullInterval = formatFullInterval(monthDisplayName, interval);

  const shortUnit = hasInterval ? `${currencySymbol}/${shortInterval}` : currencySymbol;
  const fullUnit = hasInterval ? `${currencySymbol}/${fullInterval}` : currencySymbol;

  const shortFull = `${shortAmount}\u00A0${shortUnit}`;
  const fullFull = `${fullAmount} ${fullUnit}`;

  const normalShortFull = normalShortAmount ? `${normalShortAmount}\u00A0${shortUnit}` : undefined;

  const normalFullFull = normalFullAmount ? `${normalFullAmount} ${fullUnit}` : undefined;

  return {
    input: price,
    isValid: isValidPrice(price as unknown as { currency: unknown }),
    short: {
      currency: currencySymbol,
      taxRate: formattedTaxRate,
      amount: shortAmount,
      normalAmount: normalShortAmount, // Regular price with no offers or discounts
      unit: shortUnit, // String ´currency per timespan´
      ...(hasInterval && {
        interval: shortInterval,
        unitSpecifier: shortInterval
      }),
      full: shortFull,
      normalFull: normalShortFull,
      duration: formattedDuration
    },
    full: {
      currency: currencySymbol,
      taxRate: formattedTaxRate,
      amount: fullAmount,
      normalAmount: normalFullAmount, // Regular price with no offers or discounts
      unit: fullUnit, // String ´currency per timespan´
      ...(hasInterval && {
        interval: shortInterval,
        unitSpecifier: shortInterval
      }),
      full: fullFull,
      normalFull: normalFullFull,
      duration: formattedDuration
    }
  };
}

const cldrData = {
  fi: {
    numbers: {
      currencies: {
        EUR: {
          displayName: 'euro',
          'displayName-count-one': 'euro',
          'displayName-count-other': 'euroa',
          symbol: '€',
          'symbol-alt-narrow': '€'
        }
      }
    }
  }
};

type FiLocale = 'fi';
type FiCurrency = 'EUR';

function formatFullPriceAmount(locale: FiLocale, amount: number): string {
  const fullAmountFormatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 2
  });
  return fullAmountFormatter.format(amount);
}

function formatShortPriceAmount(locale: FiLocale, amount: number): string {
  const fullPriceAmount = formatFullPriceAmount(locale, amount);
  return fullPriceAmount.replace(/[,.]00$/, '');
}

function formatShortInterval(monthShortDisplayName: string, interval?: Period): string {
  if (!interval || interval.toLowerCase() !== 'month') {
    return '';
  }

  return monthShortDisplayName;
}

function formatFullInterval(monthDisplayName: string, interval?: Period): string {
  if (!interval || interval.toLowerCase() !== 'month') {
    return '';
  }

  return monthDisplayName;
}

function formatTaxRate(locale: FiLocale, taxRate: number): string {
  const taxRateFormatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0
  });
  return taxRateFormatter.format(taxRate);
}

function formatCurrencySymbol(locale: FiLocale, currency: FiCurrency): string {
  if (!currency || !cldrData[locale as 'fi']) {
    return '';
  }

  const description = cldrData[locale as 'fi']['numbers']['currencies'][currency as 'EUR'];
  return description?.symbol;
}

function isValidPrice(price: { currency: unknown }): boolean {
  const { currency } = price;
  return Boolean(currency);
}
