import { BodyText, GridBag, Gutter } from '@gaiads/telia-react-component-library';
import { Heading } from 'common-components';
import ModelTextInput from 'common-components/ModelTextInput/ModelTextInput';
import { useTranslation } from 'react-i18next';

import { BillingGroupFormContactFields } from './useBillingAccountEditContactFields';

const BillingGroupFormContactInfo: React.FC<{
  fields: BillingGroupFormContactFields;
  disallowMutation: boolean;
}> = ({ fields, disallowMutation }) => {
  const { t } = useTranslation();

  return (
    <>
      <Heading.H4>{t('invoices.billingGroup.form.contact.title')}</Heading.H4>

      <Gutter size="sm" />

      <BodyText>{t('invoices.billingGroup.form.contact.description')}</BodyText>

      <Gutter size="sm" />

      <GridBag>
        <GridBag.Item md={12}>
          <ModelTextInput
            data-testid="name-input"
            label={t('common.firstName.label')}
            inputModel={fields.contactFirstName}
            disabled={disallowMutation}
          />
        </GridBag.Item>

        <GridBag.Item md={12}>
          <ModelTextInput
            data-testid="name-input"
            label={t('common.familyName.label')}
            inputModel={fields.contactLastName}
            disabled={disallowMutation}
          />
        </GridBag.Item>

        <GridBag.Item md={12}>
          <ModelTextInput
            data-testid="email-input"
            label={t('common.email.label')}
            inputModel={fields.contactEmail}
            disabled={disallowMutation}
          />
        </GridBag.Item>

        <GridBag.Item md={12}>
          <ModelTextInput
            data-testid="contact-phone-input"
            label={t('common.phone.label')}
            placeholder={t('common.phone.placeholder')}
            inputModel={fields.contactPhone}
            disabled={disallowMutation}
          />
        </GridBag.Item>
      </GridBag>
    </>
  );
};

export default BillingGroupFormContactInfo;
