import { Gutter } from '@gaiads/telia-react-component-library';
import { ExpandableInlineNotification, Optional } from 'common-components';
import { pimcoreNotifications } from 'doings/track/analyticsEvents';
import trackEvent from 'doings/track/trackEvent';
import { useCloseNotification } from 'hooks/notifications/useCloseNotification/useCloseNotification';
import { useNotifications } from 'hooks/notifications/useNotifications/useNotifications';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import styles from './NotificationsNews.module.scss';

const animationClassnames = {
  enter: styles.newsNotification_enter,
  enterActive: styles.newsNotification_enterActive,
  exit: styles.newsNotification_exit,
  exitActive: styles.newsNotification_exitActive
};

const NotificationsNews: React.FC = () => {
  const { notificationsNews } = useNotifications();
  const { closeNotification } = useCloseNotification();

  const onCloseClicked = (hash: string) => {
    trackEvent(pimcoreNotifications.newsNotificationClosed);
    closeNotification(hash);
  };

  return (
    <TransitionGroup>
      {notificationsNews.map(({ hash, variant, title, message, readMoreLink }, index) => (
        <CSSTransition key={hash} timeout={500} classNames={animationClassnames}>
          <>
            <ExpandableInlineNotification
              key={hash}
              variant={variant}
              title={title}
              content={message}
              readMoreLink={readMoreLink}
              whenClicked={pimcoreNotifications.newsNotificationClicked}
              onClose={() => onCloseClicked(hash)}
              aria-label={title}
              data-testid={`expandable-inline-notification-${hash}`}
            />

            <Optional if={notificationsNews.length - 1 !== index}>
              <Gutter size="sm" />
            </Optional>
          </>
        </CSSTransition>
      ))}

      {notificationsNews.length > 0 && (
        <CSSTransition key="gutter" timeout={500}>
          <Gutter size="sm" />
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};

export default NotificationsNews;
