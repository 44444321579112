import routeAccessGroups from 'B2XApp/appRouteAccessGroups';
import ContactUsPanel from 'B2XApp/ContactUs/ContactUsPanel';
import { TeliaLink } from 'common-components';
import { Divider } from 'core-components';
import openChat from 'doings/chat/openChat';
import isDemoEnvironment from 'doings/isDemoEnvironment/isDemoEnvironment';
import { contactUs } from 'doings/track/analyticsEvents';
import { useTranslation } from 'react-i18next';

const CallsAndChatPanel: React.FC = () => {
  const { t } = useTranslation();
  const isDemoEnv = isDemoEnvironment();

  return (
    <ContactUsPanel
      title={t('contactUs.panel.title.callsAndChat')}
      titleIcon="chat"
      content={t('contactUs.panel.text.callsAndChat')}
      data-tour-stop="contact-us-chat-and-calls-panel"
      data-testid="contact-us-chat-and-calls-panel"
    >
      <TeliaLink.Click
        data-testid="open-chat-window"
        variant="standalone-spacious"
        label={t('navigationalLink.title.openChat')}
        onClick={openChat}
        track={contactUs.chatsAndCallsStartChat}
        disabled={isDemoEnv}
      />

      <Divider />

      <TeliaLink.Internal
        variant="standalone-spacious"
        to="/activity-log"
        label={t('navigationalLink.title.activityLog')}
        enabledWhen={routeAccessGroups.ACTIVITY_LOG}
        track={contactUs.chatsAndCallsPage}
      />

      <Divider />
    </ContactUsPanel>
  );
};

export default CallsAndChatPanel;
