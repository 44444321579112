import { createContext, useContext, useMemo } from 'react';
import { ColumnVisibility } from 'types/listConfig';
import { SortDirection } from 'types/sort';

export const ListViewLayoutContext = createContext<ListViewLayout | undefined>(undefined);

export type ListViewLayoutProps = {
  /** Named columns → configuration. */
  readonly columns: Record<string, ColumnConfig>;
  /** Layout configuration for narrower screens. */
  readonly mobileLayout?: MobileLayoutConfig;
  readonly children: React.ReactNode;
};

type ColumnConfig = {
  /** Column's relative width multiplier. Defaults to 1x. */
  readonly width?: ColumnWidth;
  /** Column's heading label. */
  readonly heading: string;
  /** Column's heading tooltip. */
  readonly tooltip?: string;
  /** Column's visibility. */
  readonly showIf?: boolean;
  /** Column's configurable visibility for an editable list view. Available as long as the column is not explicitly hidden. */
  readonly baseVisibility?: ColumnVisibility;
  /** Heading's sort criterion. */
  readonly sort?: Sort;
  /** Heading's `data-testid` suffix. */
  readonly testidSuffix?: string;
};

type MobileLayoutConfig = {
  /** Data to display in a title.  */
  readonly titleRow: MobileTitle;
  /** Data to display after title in single or joined data field. */
  readonly rows: MobileRow[];
};

type MobileTitle = {
  /** Named column. */
  readonly col: string;
};

type MobileRow = {
  /** Named column. */
  readonly col?: string;
  /** Named columns (2…*). */
  readonly colsJoined?: string[];
  /** Padding to render above row. */
  readonly padding?: 'xxs' | 'xs';
  /** Whether to omit the row's label. */
  readonly noLabel?: boolean;
};

export type ListViewLayout = {
  readonly columnsInOrder: OutputColumns;
  readonly mobileLayout?: OutputMobileLayoutConfig;
  readonly hasHeadings: boolean;
};

export type OutputColumns = OutputColumnConfig[];

type OutputColumnConfig = { columnId: string; width: ColumnWidth } & Omit<ColumnConfig, 'showIf'>;

export type ColumnWidth = '2x' | '1.5x' | '1x' | '0.75x' | '0.5x';

export type Sort = {
  direction: SortDirection;
  onClick: VoidFunction;
};

export type WordWrap = 'anywhere' | 'break-word' | 'ellipsis' | 'ellipsis-two-lines' | 'normal';

export type OutputMobileLayoutConfig = {
  titleRow: OutputMobileTitle;
  rows: OutputMobileRow[];
};

type OutputMobileTitle = {
  col?: OutputColumnConfig;
};

export type OutputMobileRow = {
  col?: OutputColumnConfig;
  colsJoined?: OutputColumnConfig[];
  padding?: 'xxs' | 'xs';
  noLabel?: boolean;
};

const ListViewLayoutProvider: React.FC<ListViewLayoutProps> = ({
  columns,
  mobileLayout,
  children
}) => {
  const columnsInOrder: OutputColumnConfig[] = useMemo(
    () =>
      Object.entries(columns)
        .filter(([_, config]) => typeof config.showIf === 'undefined' || config.showIf)
        .map(([columnId, config]) => ({ columnId, width: '1x', ...config })),
    [columns]
  );

  const hasHeadings = columnsInOrder.some((column) => column.heading);

  const shownColumnsById = columnsInOrder.reduce((acc, column) => {
    acc.set(column.columnId, column);
    return acc;
  }, new Map<string, OutputColumnConfig>());

  const finalMobileLayout: OutputMobileLayoutConfig | undefined = mobileLayout
    ? {
        titleRow: { col: shownColumnsById.get(mobileLayout.titleRow.col) },
        rows: mobileLayout.rows
          .map(({ col, colsJoined, padding, noLabel }, index) => {
            if (!(col || colsJoined?.length)) {
              throw new Error(`Row ${index + 1}: Either col or colsJoined must be provided`);
            }

            return {
              col: col ? shownColumnsById.get(col) : undefined,
              colsJoined: colsJoined?.reduce((acc, colJoined) => {
                const configuredCol = shownColumnsById.get(colJoined);
                if (configuredCol) {
                  acc.push(configuredCol);
                }
                return acc;
              }, [] as OutputColumnConfig[]),
              padding,
              noLabel
            };
          })
          .filter(({ col, colsJoined }) => col || colsJoined?.length)
      }
    : undefined;

  return (
    <ListViewLayoutContext.Provider
      value={{
        columnsInOrder,
        mobileLayout: finalMobileLayout,
        hasHeadings
      }}
    >
      {children}
    </ListViewLayoutContext.Provider>
  );
};

const useListViewLayout = () => {
  const context = useContext(ListViewLayoutContext);
  if (!context) {
    throw new Error('useListViewLayout must be used with ListViewLayoutContext');
  }

  return context;
};

export { ListViewLayoutProvider, useListViewLayout };
