import { deviceImageUrlQuery } from 'hooks/devices/useDevice/deviceQueries';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';

type GetDeviceImageUrlResponse = {
  deviceImageUrl?: string;
};

const useDeviceImageUrl: (productCode?: string) => {
  deviceImageUrl?: string;
  hasError: boolean;
  loading: boolean;
} = (productCode?: string) => {
  const { data, error, loading } = useCallBackend<GetDeviceImageUrlResponse>({
    query: deviceImageUrlQuery,
    queryVariables: {
      productCode
    }
  });

  return { deviceImageUrl: data?.deviceImageUrl, hasError: !!error, loading };
};

export default useDeviceImageUrl;
