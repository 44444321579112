import { InlineNotificationLite } from 'common-components';
import { useTranslation } from 'react-i18next';

const NoPukCodesMessage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <InlineNotificationLite
      variant="warning"
      messageText={t('subscriptions.subscriptionDetails.pukCodesNotAvailable')}
      data-testid="puk-code-not-available"
    />
  );
};

export default NoPukCodesMessage;
