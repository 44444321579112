import { DatePicker, GridBag, OptionListOption } from '@gaiads/telia-react-component-library';
import { DateFieldProps } from 'common-components/Forms/FormField/FormField';
import { parseISO } from 'date-fns';
import formatDatetime, { DateFormats } from 'doings/formatDatetime/formatDatetime';
import { multiplex } from 'doings/multiplex/multiplex';
import { noOp } from 'doings/noOp/noOp';
import { FieldInputProps, useField, useFormikContext } from 'formik';
import { useGaiaDatePickerKeyboardA11yFix } from 'hooks/gaiaUX';
import { RefObject, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import FormFieldCombobox from './FormFieldCombobox';
import styles from './FormFieldDatePicker.module.scss';
import FormFieldHelperText from './FormFieldHelperText';

const VARIANTS = {
  DATE: {
    datePickerClassName: styles.datePicker,
    placeholderFormat: DateFormats.DATE_NATIONAL_SHORT,
    valueDisplayFormat: 'D.M.YYYY',
    onCaptionClick: noOp
  },
  DATETIME: {
    datePickerClassName: styles.datetimePicker,
    placeholderFormat: DateFormats.DATE_NATIONAL_SHORT,
    valueDisplayFormat: 'D.M.YYYY',
    onCaptionClick: noOp
  },
  MONTH_ONLY: {
    datePickerClassName: styles.monthPicker,
    placeholderFormat: DateFormats.MONTH_NATIONAL_SHORT,
    valueDisplayFormat: 'M.YYYY',
    onCaptionClick: (
      month: Date,
      field: FieldInputProps<Date>,
      setFieldValue: (field: string, value: Date) => void,
      refElement: RefObject<HTMLInputElement>
    ) => {
      setFieldValue(field.name, month);
      refElement.current?.blur();
    }
  }
};

const DAY_PICKER_DAY_OF_WEEK__SUNDAY = 0;
const DAY_PICKER_DAY_OF_WEEK__MONDAY = 1;
const DAY_PICKER_DAY_OF_WEEK__SATURDAY = 6;

const DATETIME_LAST_HOUR = 23;
const DATETIME_LAST_MINUTE = 59;
const DATETIME_SINGLE_DIGIT_THRESHOLD = 10;

const buildTimeOptions = (limit: number) => {
  const opts: OptionListOption[] = Array(limit + 1);
  for (let i = 0; i <= limit; i++) {
    const opt = i < DATETIME_SINGLE_DIGIT_THRESHOLD ? `0${i}` : `${i}`;
    opts[i] = {
      label: opt,
      value: opt
    };
  }
  return opts;
};

const DATETIME_HOUR_OPTIONS = buildTimeOptions(DATETIME_LAST_HOUR);
const DATETIME_MINUTE_OPTIONS = buildTimeOptions(DATETIME_LAST_MINUTE);

const FormFieldDatePicker = ({
  name,
  label,
  helperText,
  invalidDateErrorText,
  required,
  disabled,
  dateRange,
  allowAllDays,
  isDateDisabled,
  monthOnlySelection = false,
  timeSelection = false,
  testId
}: DateFieldProps) => {
  const [field, meta] = useField<Date>(name);
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const dayPickerFix = useGaiaDatePickerKeyboardA11yFix(containerRef);
  const { t, i18n } = useTranslation();

  const startDate = useMemo(
    () => (dateRange?.startDate ? parseISO(dateRange.startDate) : undefined),
    [dateRange]
  );

  const endDate = useMemo(
    () => (dateRange?.endDate ? parseISO(dateRange.endDate) : undefined),
    [dateRange]
  );

  const variant = useMemo(
    () =>
      multiplex([
        VARIANTS.DATE,
        [monthOnlySelection, VARIANTS.MONTH_ONLY],
        [timeSelection, VARIANTS.DATETIME]
      ]),
    [monthOnlySelection, timeSelection]
  );

  const disabledDays = useMemo(() => {
    return [
      {
        daysOfWeek: !allowAllDays
          ? [DAY_PICKER_DAY_OF_WEEK__SUNDAY, DAY_PICKER_DAY_OF_WEEK__SATURDAY]
          : [],
        before: startDate ?? undefined,
        after: endDate ?? undefined
      },
      isDateDisabled
    ];
  }, [allowAllDays, startDate, endDate, isDateDisabled]);

  const datetimeHourOptions = useMemo(() => {
    return timeSelection ? DATETIME_HOUR_OPTIONS : [];
  }, [timeSelection]);

  const datetimeMinuteOptions = useMemo(() => {
    return timeSelection ? DATETIME_MINUTE_OPTIONS : [];
  }, [timeSelection]);

  const errorText = useMemo(
    () => (meta.error ? invalidDateErrorText ?? meta.error : ''),
    [invalidDateErrorText, meta.error]
  );

  return useMemo(
    () => (
      <>
        <GridBag>
          <GridBag.Item sm={12} md={6}>
            <div data-testid={testId} data-name={name} ref={containerRef}>
              <DatePicker
                className={variant.datePickerClassName}
                dayPickerProps={{
                  disabledDays,
                  firstDayOfWeek: DAY_PICKER_DAY_OF_WEEK__MONDAY,
                  fromMonth: startDate,
                  toMonth: endDate,
                  onCaptionClick: (month) => {
                    variant.onCaptionClick(month, field, setFieldValue, inputRef);
                  }
                }}
                id={field.name}
                data-testid="form-field-date-picker"
                label={label}
                aria-label={label}
                value={field.value ? field.value : undefined}
                required={required}
                disabled={disabled}
                onBlur={field.onBlur}
                onChange={(value: Date) => {
                  setFieldTouched(field.name, true);
                  setFieldValue(field.name, value);
                }}
                onDayPickerShow={() => {
                  dayPickerFix.connect();
                }}
                onDayPickerHide={() => {
                  dayPickerFix.disconnect();
                }}
                errorText={meta.touched ? errorText : ''}
                placeholder={formatDatetime(new Date(), variant.placeholderFormat)}
                locale={i18n.language}
                format={variant.valueDisplayFormat}
                inputRef={inputRef}
              />
            </div>
          </GridBag.Item>

          {timeSelection && (
            <GridBag.Item sm={6} md={3} key="time-picker-h">
              <FormFieldCombobox
                type="select"
                testId={`${testId}-hour`}
                data-testid="form-field-date-picker-combobox-hour"
                name={`${name}-h`}
                options={datetimeHourOptions}
                label={t('common.form.fields.timeHour')}
                aria-label={t('common.form.fields.timeHour')}
                required={required}
                disabled={disabled}
                placeholder={`00–${DATETIME_LAST_HOUR}`}
                comboboxInputMaxLength={2}
                comboboxInputMode="numeric"
              />
            </GridBag.Item>
          )}

          {timeSelection && (
            <GridBag.Item sm={6} md={3} key="time-picker-m">
              <FormFieldCombobox
                type="select"
                testId={`${testId}-min`}
                data-testid="form-field-date-picker-combobox-minute"
                name={`${name}-m`}
                options={datetimeMinuteOptions}
                label={t('common.form.fields.timeMinute')}
                aria-label={t('common.form.fields.timeMinute')}
                required={required}
                disabled={disabled}
                placeholder={`00–${DATETIME_LAST_MINUTE}`}
                comboboxInputMaxLength={2}
                comboboxInputMode="numeric"
              />
            </GridBag.Item>
          )}
        </GridBag>

        <FormFieldHelperText helperText={helperText} />
      </>
    ),

    /*
     * Only check for changes to significant properties which can change
     * between form field renders. Dependencies like `field` and `setFieldValue`
     * change on every render, so we need to exclude these to avoid unnecessary
     * and unperformant rerenders.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, required, disabled, field.value, meta.touched, meta.error, disabledDays, timeSelection]
  );
};

export default FormFieldDatePicker;
