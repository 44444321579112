import { Div, Gutter } from '@gaiads/telia-react-component-library';
import { Paragraph } from '@teliafi/fi-ds';
import { DetailsLoadingOverlay, SortableCheckboxes } from 'common-components';
import { SortedChecklistItem } from 'common-components/SortableCheckboxes/SortableCheckboxesProvider';
import { EditableSection } from 'hooks/editableSections/useEditableSectionItems';
import { useTranslation } from 'react-i18next';

export const EditableLinksModalContent: React.FC<{
  section: EditableSection;
  items: SortedChecklistItem[];
  setTempItems: (items: SortedChecklistItem[]) => void;
  requiredItems: number | false;
  busy: boolean;
}> = ({ items, section, setTempItems, requiredItems, busy }) => {
  const { t } = useTranslation();

  return (
    <>
      <Paragraph data-testid={`edit-links-modal--label--${section}`}>
        {t('modal.editLinks.text.selectItems')}
      </Paragraph>

      {section === 'top-tiles' && (
        <Div margin={{ top: 'xs' }}>
          <Paragraph data-testid="edit-links-modal--info--top-tiles">
            {t('modal.editLinks.text.itemCount', { count: requiredItems })}
          </Paragraph>
        </Div>
      )}

      <Gutter size="md" />

      <DetailsLoadingOverlay showLoader={busy}>
        <SortableCheckboxes
          items={items}
          itemClassification="tile"
          minEnabledItems={requiredItems}
          maxEnabledItems={requiredItems}
          onChange={setTempItems}
          data-testid="edit-links-modal-sortable-checkboxes"
        />
      </DetailsLoadingOverlay>

      <Gutter />
    </>
  );
};
