import getClassNames from 'classnames';
import { IconButton, Optional } from 'common-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toggleNewsDrawer } from 'redux/slices/newsDrawerSlice';
import { RootState } from 'redux/store';

import styles from './NewsDrawerBell.module.scss';

const MAX_SHOWN_COUNT = 99;
const MAX_SHOWN_COUNT_LABEL = '99+';

const NewsDrawerBell: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const unseenCount = useSelector((state: RootState) => state.newsDrawer.unseenCounts.total);
  const unseenCountLabel = unseenCount > MAX_SHOWN_COUNT ? MAX_SHOWN_COUNT_LABEL : `${unseenCount}`;

  const notificationCounterId = 'news-drawer-bell-notification-counter';

  return (
    <div className={styles.newsBell_container}>
      <IconButton
        name="alarm-on"
        aria-label={t('aria.header.newsFeed')}
        aria-describedby={notificationCounterId}
        aria-expanded={false}
        size="md"
        data-testid="open-news-drawer"
        className={[styles.newsBell_bell]}
        onClick={() => dispatch(toggleNewsDrawer({ show: true }))}
      />

      <Optional if={unseenCount > 0}>
        <span
          className={getClassNames(styles.newsBell_bubble, {
            [styles.newsBell_bubble__large]: unseenCountLabel.length > 1,
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            [styles.newsBell_bubble__xlarge]: unseenCountLabel.length > 2
          })}
        >
          <span
            data-testid={notificationCounterId}
            id={notificationCounterId}
            aria-label={t('aria.header.unseenNewsFeedItems', { count: unseenCountLabel })}
            aria-live="polite"
          >
            {unseenCountLabel}
          </span>
        </span>
      </Optional>
    </div>
  );
};

export default NewsDrawerBell;
