import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AskForHelpHistory } from 'hooks/askForHelp/useAskForHelp';

export interface AskForHelpDrawerState {
  isDrawerOpen: boolean;
  lastQuestion?: string;
  history?: AskForHelpHistory[];
}

const initialState: AskForHelpDrawerState = {
  isDrawerOpen: false,
  lastQuestion: '',
  history: []
};

export const askForHelpDrawerSlice = createSlice({
  name: 'askForHelpDrawer',
  initialState,
  reducers: {
    setLastQuestion: (state, action: PayloadAction<string>) => {
      state.lastQuestion = action.payload.trim();
    },
    setConversationHistory: (state, action: PayloadAction<AskForHelpHistory[]>) => {
      state.history = action.payload;
      state.lastQuestion =
        action.payload
          ?.slice()
          .reverse()
          .find(({ role }) => role === 'user')
          ?.content.trim() ?? '';
    },
    resetConversation: (state) => {
      state.lastQuestion = '';
      state.history = [];
    },
    openAskForHelpDrawer: (state) => {
      if (!!state.lastQuestion?.trim() && !state.isDrawerOpen) {
        state.isDrawerOpen = true;
      }
    },
    closeAskForHelpDrawer: (state) => {
      state.isDrawerOpen = false;
    }
  }
});

export const {
  setLastQuestion,
  setConversationHistory,
  resetConversation,
  openAskForHelpDrawer,
  closeAskForHelpDrawer
} = askForHelpDrawerSlice.actions;

export default askForHelpDrawerSlice.reducer;
