import BillingGroupForm from 'B2XApp/Invoicing/BillingGroups/BillingGroupForm/BillingGroupForm';
import { BillingGroupFormData } from 'B2XApp/Invoicing/BillingGroups/BillingGroupForm/useBillingGroupForm';
import { TeliaLink } from 'common-components';
import ConfirmationPanelSuccess from 'common-components/ConfirmationPanel/ConfirmationPanelSuccess';
import { invoicing } from 'doings/track/analyticsEvents';
import useSearchParams from 'hooks/useSearchParams/useSearchParams';
import { Trans, useTranslation } from 'react-i18next';

const NewBillingGroupPageForm: React.FC<{
  form: BillingGroupFormData;
}> = ({ form }) => {
  const { t } = useTranslation();
  const { preserveBackPathState } = useSearchParams();
  const { step } = form;

  switch (step) {
    case 'unavailable':
    case 'initial':
    case 'prechecking':
    case 'precheck-failure':
    case 'persisting':
    case 'failure':
      return (
        <BillingGroupForm
          form={form}
          failureNoticeOnPersisting={{
            title: t('invoices.billingGroup.form.creation.notification.failureTitle'),
            content: t('invoices.billingGroup.form.creation.notification.failureDescription')
          }}
          analyticsEvent={invoicing.createCollectionGroup}
          data-testid="collection-group-creation__form"
          pageType="creation"
        />
      );

    case 'success':
      return (
        <ConfirmationPanelSuccess
          title={t('invoices.billingGroup.form.creation.notification.successTitle')}
          description={
            <Trans
              i18nKey={t(
                'invoices.billingGroup.form.creation.notification.successDescriptionWithLink.description',
                {
                  companyId: form.data?.newCollectionGroupCompanyId,
                  collectionGroupId: form.data?.newCollectionGroupId
                }
              )}
              components={[
                <TeliaLink.Internal
                  key="link-to-billing-accounts-list"
                  variant="text"
                  to={preserveBackPathState('/billing-accounts/', true)}
                  label={t(
                    'invoices.billingGroup.form.creation.notification.successDescriptionWithLink.link'
                  )}
                />
              ]}
            />
          }
          backToPath={preserveBackPathState('/billing-accounts/', true)}
          backToLabel={t('invoices.billingGroup.form.creation.notification.backToBillingGroups')}
          data-testid="collection-group-creation__success"
        />
      );

    case 'partial-success':
      return (
        <ConfirmationPanelSuccess
          title={t('invoices.billingGroup.form.creation.notification.successTitle')}
          description={
            <Trans
              i18nKey="serviceRequests.newServiceRequest.successDescriptionWithLink.description"
              components={[
                <TeliaLink.Internal
                  key="link-to-service-request-list"
                  variant="text"
                  to="/service-requests/"
                  label={t('serviceRequests.newServiceRequest.successDescriptionWithLink.link')}
                />
              ]}
            />
          }
          backToPath={preserveBackPathState('/billing-accounts/', true)}
          backToLabel={t('invoices.billingGroup.form.creation.notification.backToBillingGroups')}
          data-testid="collection-group-creation__partial-success"
        />
      );
  }
};

export default NewBillingGroupPageForm;
