import { InlineNotification, TeliaLink } from 'common-components';
import getEnvironmentVariable from 'doings/getEnvironmentVariable/getEnvironmentVariable';
import { messages } from 'doings/track/analyticsEvents';

const ProductsNotice: React.FC = () => {
  return (
    <InlineNotification
      content={{
        key: 'tickets.newTicket.productsNotice',
        components: [
          <TeliaLink.Translatable.External
            key="my-nebula-portal-link"
            variant="text"
            to={getEnvironmentVariable('REACT_APP_LINK_TO_TIN_MY_NEBULA')}
            track={messages.newTicketOpenMyNebula}
            iconRight="external"
          />,
          <TeliaLink.Translatable.External
            key="telia-cygate-pulsar-portal-link"
            variant="text"
            to={getEnvironmentVariable('REACT_APP_LINK_TO_TELIA_CYGATE')}
            track={messages.newTicketOpenPulsar}
            iconRight="external"
          />
        ]
      }}
      variant="information"
      icon="repair"
      data-testid="new-trouble-ticket-products-notice"
    />
  );
};

export default ProductsNotice;
