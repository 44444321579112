import { BodyText, Div, Flex, Gutter } from '@gaiads/telia-react-component-library';
import PukCodeDetailsSkeleton from 'B2XApp/Dashboard/DashboardNavigation/DashboardSearch/PukCodeSearch/PukCodeDetailsSkeleton';
import NoPukCodesMessage from 'B2XApp/Subscriptions/SubscriptionDetails/NoPukCodesMessage/NoPukCodesMessage';
import PukCode from 'B2XApp/Subscriptions/SubscriptionDetails/PukCode/PukCode';
import { TeliaLink } from 'common-components';
import { useUserData } from 'contexts/UserContext/UserContext';
import { EN_DASH } from 'doings/dash/dash';
import { isSmallBusiness } from 'doings/isSmallBusiness/isSmallBusiness';
import { pukCodeSearch } from 'doings/track/analyticsEvents';
import trackEvent from 'doings/track/trackEvent';
import userHasPermission from 'doings/userHasPermission/userHasPermission';
import useSubscriptionDetails from 'hooks/mobileSubscriptions/useSubscriptionDetails/useSubscriptionDetails';
import usePileVerticallyToMany from 'hooks/usePileVerticallyToMany/usePileVerticallyToMany';
import { useTranslation } from 'react-i18next';

type MaybePukCodes = { puk1?: string; puk2?: string } | undefined | null;
type PukCodes = { puk1: string; puk2: string };

const hasPukCodes = (pukCodes: MaybePukCodes): pukCodes is PukCodes =>
  !!pukCodes && !!pukCodes.puk1 && !!pukCodes.puk2;

const PukCodeDetails: React.FC<{ msisdnHash: string }> = ({ msisdnHash }) => {
  const { t } = useTranslation();
  const pileVerticallyTo = usePileVerticallyToMany(['xxxs', 'xxs', 'md']);
  const { subscription, status } = useSubscriptionDetails(msisdnHash, { fetchOnlyPukCodes: true });
  const pukCodes = subscription?.simCard?.pukCodes;

  if (status === 'loading') {
    return <PukCodeDetailsSkeleton />;
  }

  if (!subscription) {
    return <NoPukCodesMessage />;
  }

  return (
    <>
      <Div data-testid="subscription-user-information" margin={{ bottom: 'md' }}>
        <BodyText fontWeight="medium" flexItem>
          {t('subscriptions.subscriptionDetails.headline')}
        </BodyText>

        <Gutter size="xs" />

        <BodyText flexItem>
          {subscription.phoneNumber} {EN_DASH} {subscription.userName}
        </BodyText>
      </Div>

      {hasPukCodes(pukCodes) && (
        <Flex pileVerticallyTo={pileVerticallyTo}>
          <PukCode
            title={t('subscriptions.subscriptionDetails.puk1')}
            code={pukCodes.puk1}
            data-testid="sim-card-puk-1"
            onCopy={() => {
              trackEvent(pukCodeSearch.pukCodeSearchCopyPuk1);
            }}
          />

          <Gutter size="sm" />

          <PukCode
            title={t('subscriptions.subscriptionDetails.puk2')}
            code={pukCodes.puk2}
            data-testid="sim-card-puk-2"
            tooltipKey="subscriptions.subscriptionDetails.puk2Tooltip"
            onCopy={() => {
              trackEvent(pukCodeSearch.pukCodeSearchCopyPuk2);
            }}
          />
        </Flex>
      )}

      {(!hasPukCodes(pukCodes) || status === 'error') && <NoPukCodesMessage />}

      <Div margin={{ top: 'md' }}>
        <MobileSubscriptionDetailsLink msisdnHash={msisdnHash} />
      </Div>
    </>
  );
};

const MobileSubscriptionDetailsLink: React.FC<{ msisdnHash: string }> = ({ msisdnHash }) => {
  const { t } = useTranslation();
  const user = useUserData();
  const shouldLinkToB2BPortal =
    !isSmallBusiness(user) && userHasPermission(user)({ onePermission: 'B2B_PORTAL' });

  return (
    <TeliaLink.Portal
      data-testid="dashboard-puk-code-search-link-to-subs"
      variant="standalone"
      to={`/subscriptions/${msisdnHash}`}
      toCorporatePortal="mobileDetails"
      toCorporatePortalArgs={{ msisdn: msisdnHash }}
      useRouterLink={!shouldLinkToB2BPortal}
      label={t('dashboard.pukCodeSearch.openSubscription')}
      track={pukCodeSearch.pukCodeSearchViewSubscription}
    />
  );
};

export default PukCodeDetails;
