import useReadQueryParams from 'hooks/useQueryParams/useReadQueryParams';
import useWriteQueryParams from 'hooks/useQueryParams/useWriteQueryParams';
import useSortableHeadings from 'hooks/useSortableHeadings/useSortableHeadings';
import { useEffect } from 'react';
import { SortOrder, SortParameter } from 'types/sort';

const directions = [SortOrder.ASC, SortOrder.DESC];

const parseSortFromQueryParam = (sort?: string) => {
  if (sort) {
    const splitPos = sort.lastIndexOf('.');
    return [sort.substring(0, splitPos), sort.substring(splitPos + 1)];
  }

  return [];
};

const convertSortToQueryParam = (isDefault: boolean, sort?: string, order?: SortOrder) => ({
  sort: !isDefault ? `${sort}.${order}` : undefined
});

export default (columns: string[], initialSort: SortParameter) => {
  const { sort: defaultColumn, order: defaultDirection } = initialSort;
  const { sort: sortFromQueryParams } = useReadQueryParams(['sort']);
  const [sortBy, sortDir] = parseSortFromQueryParam(sortFromQueryParams);

  const sortableHeadings = useSortableHeadings(columns, {
    sort: columns.find((col) => col === sortBy) ?? defaultColumn,
    order: directions.find((dir) => dir === sortDir) ?? defaultDirection
  });

  const { sort, order } = sortableHeadings.currentSort;
  const isDefault = sort === defaultColumn && order === defaultDirection;

  const { write: writeQueryParams } = useWriteQueryParams();
  useEffect(() => {
    if (!!sortFromQueryParams || !isDefault) {
      writeQueryParams(convertSortToQueryParam(isDefault, sort, order));
    }
  }, [writeQueryParams, sortFromQueryParams, isDefault, sort, order]);

  return sortableHeadings;
};
