import { IconName } from '@teliafi/fi-ds';
import { TileButton } from 'common-components/TileButtonGrid';
import getOriginatorPath from 'doings/links/getOriginatorPath/getOriginatorPath';
import { joinURLSearchParams } from 'doings/links/joinURLSearchParams/joinURLSearchParams';
import { AnalyticsEvent } from 'doings/track/analyticsEvents';
import { AuthorizationParams } from 'types/authorization';
import { ServiceRequestDynamicFormType } from 'types/serviceRequest';

type UriParams = { origin: string; key?: string };

type ContactPanelLinkProps = {
  uriParams?: UriParams;
  iconName: IconName;
  label: string;
  analyticsEvent: AnalyticsEvent;
  enabledWhen?: AuthorizationParams;
  formType?: ServiceRequestDynamicFormType;
  to: string;
  testId: string;
};

type ContactPanelServiceRequestLinkProps = Omit<
  ContactPanelLinkProps,
  'to' | 'iconName' | 'enabledWhen' | 'testId'
> & { iconName?: IconName; testId?: string };

type ContactPanelFaultReportLinkProps = Omit<
  ContactPanelLinkProps,
  'formType' | 'to' | 'iconName' | 'enabledWhen' | 'testId'
> & { iconName?: IconName; testId?: string };

type ContactLinkTargetProps = {
  path: string;
  uriParams?: UriParams;
  searchParams: {
    t?: ServiceRequestDynamicFormType;
    originator: string;
  };
};

const buildUriParams = (uriParams?: UriParams) =>
  uriParams?.key ? `${uriParams.origin}/${uriParams.key}` : '';

const buildLinkTarget = (props: ContactLinkTargetProps) => {
  const uriParams = buildUriParams(props.uriParams);
  const searchParams = joinURLSearchParams('?', props.searchParams);

  return `${props.path}${uriParams}${searchParams}`;
};

export const ContactPanelServiceRequestLink: React.FC<ContactPanelServiceRequestLinkProps> = ({
  iconName = 'document-edit',
  testId = 'create-service-request-link',
  uriParams,
  formType,
  ...props
}) => {
  return (
    <ContactPanelLink
      testId={testId}
      to={buildLinkTarget({
        path: '/service-requests/new/',
        uriParams,
        searchParams: {
          t: formType,
          originator: getOriginatorPath()
        }
      })}
      iconName={iconName}
      enabledWhen={{ onePermission: 'SERVICE_REQUESTS' }}
      {...props}
    />
  );
};

export const ContactPanelFaultReportLink: React.FC<ContactPanelFaultReportLinkProps> = ({
  iconName = 'error',
  testId = 'create-ticket-link',
  uriParams,
  ...props
}) => {
  return (
    <ContactPanelLink
      testId={testId}
      to={buildLinkTarget({
        path: '/tickets/new/',
        uriParams,
        searchParams: {
          originator: getOriginatorPath()
        }
      })}
      iconName={iconName}
      enabledWhen={{ onePermission: 'INCIDENT_TICKETS' }}
      {...props}
    />
  );
};

const ContactPanelLink: React.FC<ContactPanelLinkProps> = ({
  testId,
  to,
  iconName,
  label,
  analyticsEvent,
  enabledWhen
}) => {
  return (
    <TileButton
      data-testid={testId}
      iconName={iconName}
      label={label}
      to={to}
      whenClicked={analyticsEvent}
      enabledWhen={enabledWhen}
    />
  );
};

export const ContactLink = {
  ServiceRequest: ContactPanelServiceRequestLink,
  FaultReport: ContactPanelFaultReportLink
};
