import { QueriedSubscriptionPukCodeSearch } from 'hooks/mobileSubscriptions/useSubscriptionPukCodeSearch/subscriptionsPukCodeSearchQuery';
import { SubscriptionPukCodeSearchItem } from 'types/subscription';

const mapSubscriptionPukCodeSearch =
  (emptyUsernamePlaceholder: string) =>
  (subscription: QueriedSubscriptionPukCodeSearch): SubscriptionPukCodeSearchItem => ({
    ...subscription,
    name: subscription.name || emptyUsernamePlaceholder
  });

export default mapSubscriptionPukCodeSearch;
