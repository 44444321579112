import { BreadcrumbProps } from 'common-components/Breadcrumb/BreadcrumbContainer/BreadcrumbContainer';
import useRoutesMatchingCurrentRoute from 'hooks/useRoutesMatchingCurrentRoute/useRoutesMatchingCurrentRoute';
import { useTranslation } from 'react-i18next';

const useRouteBreadcrumbs = (): BreadcrumbProps[] => {
  const { t } = useTranslation();
  const { matchingRoutes } = useRoutesMatchingCurrentRoute();

  return matchingRoutes.map((route) => ({
    label: t(route.routeNameTranslationKey),
    path: route.path,
    hideBreadcrumb: route.hideBreadcrumb
  }));
};

export default useRouteBreadcrumbs;
