import { gql } from '@apollo/client';

export const favoriteDevicesQuery = gql`
  query GetFavouriteDevices {
    favoriteDevices {
      title
      products {
        id
        title
        imageUrl
        url
        cheapestPrice {
          amount
          currency
          taxRate
          period
          duration
          normalPrice
        }
        cheapestOneTimePrice {
          amount
          currency
          taxRate
          normalPrice
        }
        hasMultiplePrices
        hasPriceVariation
        tag {
          text
          color
        }
      }
    }
  }
`;
