import { ListViewLayoutProps } from 'common-components/ListView/ListViewLayout/ListViewLayoutProvider';

export const editableListIds = [
  'archivedServiceRequests',
  'assets',
  'billingAccounts',
  'faultReports',
  'invoices',
  'mobileSubscriptions',
  'orders',
  'serviceRequests',
  'threeSixtyProductInventory'
] as const;
export type EditableListId = (typeof editableListIds)[number];

export type ListViewColumns = ListViewLayoutProps['columns'];
export type ListViewColumnSetup<T extends unknown[]> = {
  listId: EditableListId;
  getColumns: (...args: T) => ListViewColumns;
};

export type EditableColumns = Record<string, EditableColumnConfig>;
export type EditableColumnConfig = {
  readonly heading: string;
  readonly baseVisibility?: ColumnVisibility;
};

export enum ColumnVisibility {
  REQUIRE,
  SHOW,
  HIDE
}
