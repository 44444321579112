import { Checkbox, Div, Flex, Title } from '@gaiads/telia-react-component-library';
import { isThisYear } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { ActivityLogEntry } from 'types/activityLog';

import ActivityLogItemContent from './ActivityLogItemContent';
import styles from './ActivityLogPage.module.scss';
import { getMonthString } from './getMonthString';

const EntrySeparatorLabel: React.FC<{
  entry: ActivityLogEntry;
  allExpanded?: boolean;
  onExpandAll?: VoidFunction;
  showExpandAllCheckbox?: boolean;
}> = ({ entry, allExpanded = false, onExpandAll, showExpandAllCheckbox = false }) => {
  const { t } = useTranslation();

  const date = new Date(entry.creationDate);
  const month = getMonthString(date);
  const monthName = t(`activityLog.item.month.${month}`);
  const dateLabel =
    month === 'thisMonth' || month === 'lastMonth' || isThisYear(date)
      ? monthName
      : `${monthName} ${date.getFullYear()}`;

  return (
    <ActivityLogItemContent title>
      <Flex occupyHorizontalSpace alignBaseline>
        <Title
          tagName="h2"
          flexItem
          color="gray800"
          size="sm"
          margin={{ bottom: 'md' }}
          className={styles.activityLogItem_titleText}
        >
          {dateLabel}
        </Title>

        {showExpandAllCheckbox && !!onExpandAll && (
          <Div flexItem inlineBlock alignTextToRight>
            <Checkbox
              label={t('activityLog.toggleLabel')}
              onChange={onExpandAll}
              checked={allExpanded}
              data-testid={`expand-all-checkbox${allExpanded ? '-checked' : ''}`}
            />
          </Div>
        )}
      </Flex>
    </ActivityLogItemContent>
  );
};

export default EntrySeparatorLabel;
