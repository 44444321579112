import { Div, GridBag, Gutter, Input, Page } from '@gaiads/telia-react-component-library';
import { Heading } from 'common-components';
import ModelCheckbox from 'common-components/ModelCheckbox/ModelCheckbox';
import authorizeUser from 'doings/authorizeUser/authorizeUser';
import { invoicing } from 'doings/track/analyticsEvents';
import { UpdatePaymentReminderStatus } from 'hooks/invoicing/paymentReminders/useUpdatePaymentReminders/useUpdatePaymentReminders';
import { useTranslation } from 'react-i18next';
import { EMAIL_MAX_LENGTH, PHONE_MAX_LENGTH } from 'types/form';
import { PaymentReminder, PaymentReminderStatus } from 'types/paymentReminder';

import { UpdatePaymentReminderFormFields } from './useUpdatePaymentRemindersForm';

const PaymentReminderSetting: React.FC<{
  reminder: PaymentReminder;
  formStep: UpdatePaymentReminderStatus;
  fields: UpdatePaymentReminderFormFields;
}> = ({ reminder: { type, status }, formStep, fields }) => {
  const { t } = useTranslation();
  const isDisabled = status === PaymentReminderStatus.Unavailable;
  const disallowMutation =
    isDisabled || formStep === 'persisting' || !authorizeUser({ onePermission: 'BILLING' });

  const { checked: phoneToggled } = fields.phoneCheckModel;

  const {
    value: providedPhone,
    setValue: setProvidedPhone,
    isValid: isValidPhone,
    required: isRequiredPhone,
    errorMessage: phoneErrorText
  } = fields.phoneInputModel;

  const { checked: phoneErrorToggled, setChecked: setPhoneErrorToggled } = fields.phoneInputError;

  const { checked: emailToggled } = fields.emailCheckModel;

  const {
    value: providedEmail,
    setValue: setProvidedEmail,
    isValid: isValidEmail,
    required: isRequiredEmail,
    errorMessage: emailErrorText
  } = fields.emailInputModel;

  const onChangeProvidedPhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneErrorToggled(false);
    setProvidedPhone(event.target.value);
  };

  const onChangeProvidedEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProvidedEmail(event.target.value);
  };

  return (
    <Div padding={{ top: 'lg' }} data-testid={`payment-reminder__${type}-setting`}>
      <Heading.H4>{t(`invoices.paymentReminder.type.${type}`)}</Heading.H4>

      <Gutter size="md" />

      <ModelCheckbox
        label={t('invoices.paymentReminder.phone.label')}
        analyticsEvent={invoicing.toggleSmsReminder}
        checkboxModel={fields.phoneCheckModel}
        disabled={disallowMutation}
        data-testid={`payment-reminder__${type}-phone-check`}
      />

      {!isDisabled && phoneToggled && (
        <Page.Row padding={{ top: 'sm', left: 'lg', bottom: 'xs' }} margin={{ bottom: 'zero' }}>
          <GridBag noHorizontalGutter>
            <GridBag.Item md={9} sm={12}>
              <Input
                errorText={
                  phoneErrorToggled
                    ? t('invoices.paymentReminder.phone.ownerError')
                    : phoneErrorText
                }
                label={t('common.phone.label')}
                onChange={onChangeProvidedPhone}
                type="tel"
                maxLength={PHONE_MAX_LENGTH}
                required={isRequiredPhone}
                value={providedPhone}
                confirmed={isValidPhone}
                placeholder={t('common.phone.placeholder')}
                disabled={disallowMutation}
                data-testid={`payment-reminder__${type}-phone-field`}
              />
            </GridBag.Item>
          </GridBag>
        </Page.Row>
      )}

      <Gutter size="md" />

      <ModelCheckbox
        label={t('invoices.paymentReminder.email.label')}
        analyticsEvent={invoicing.toggleEmailReminder}
        checkboxModel={fields.emailCheckModel}
        disabled={disallowMutation}
        data-testid={`payment-reminder__${type}-email-check`}
      />

      {!isDisabled && emailToggled && (
        <Page.Row padding={{ top: 'sm', left: 'lg', bottom: 'xs' }} margin={{ bottom: 'zero' }}>
          <GridBag noHorizontalGutter>
            <GridBag.Item md={9} sm={12}>
              <Input
                errorText={emailErrorText}
                label={t('invoices.paymentReminder.email.input')}
                onChange={onChangeProvidedEmail}
                type="email"
                maxLength={EMAIL_MAX_LENGTH}
                required={isRequiredEmail}
                value={providedEmail}
                confirmed={isValidEmail}
                disabled={disallowMutation}
                data-testid={`payment-reminder__${type}-email-field`}
              />
            </GridBag.Item>
          </GridBag>
        </Page.Row>
      )}

      <Gutter size="sm" />
    </Div>
  );
};

export default PaymentReminderSetting;
