import { Icon } from '@teliafi/fi-ds';
import getClassNames from 'classnames';
import { SmartTooltip } from 'common-components';
import { Flex } from 'core-components';

import styles from './DashboardNotificationRowContent.module.scss';

const TOTAL_THRESHOLD = 100;
const TOTAL_MAX = '99+';

const variants = {
  link: {
    containerClasses: getClassNames([styles.row, styles.row__link]),
    innerClasses: getClassNames([styles.count, styles.count__link])
  },
  error: {
    containerClasses: getClassNames([styles.row, styles.row__error]),
    innerClasses: getClassNames([styles.count, styles.count__error])
  }
};

const DashboardNotificationRowContent: React.FC<{
  total?: number;
  label: string;
  tooltip?: string;
  variant: 'link' | 'error';
}> = ({ total = 0, label, tooltip, variant = 'link' }) => {
  const { containerClasses, innerClasses } = variants[variant];
  return (
    <Flex className={containerClasses}>
      <div className={innerClasses} data-testid="notification-item-count">
        {variant === 'link' && <>{total < TOTAL_THRESHOLD ? total : TOTAL_MAX}</>}

        {variant === 'error' && (
          <Icon
            className={styles.errorIcon}
            name="error"
            size="sm"
            data-testid="notification-item-error-icon"
          />
        )}
      </div>

      <div
        className={getClassNames(styles.label, {
          [styles.label__link]: variant === 'link',
          [styles.label__error]: variant === 'error'
        })}
        aria-disabled={variant === 'error'}
      >
        {label} {tooltip && <DashboardNotificationTooltip tooltip={tooltip} />}
      </div>
    </Flex>
  );
};

const DashboardNotificationTooltip: React.FC<{ tooltip: string }> = ({ tooltip }) => {
  return (
    <span className={styles.tooltipWrapper}>
      <SmartTooltip tooltipContent={tooltip} arrangement="top" wrapper="span">
        <SmartTooltip.InfoIcon size="md" />
      </SmartTooltip>
    </span>
  );
};

export default DashboardNotificationRowContent;
