import { BodyText } from '@gaiads/telia-react-component-library';
import { DefinitionList, TeliaLink } from 'common-components';
import createCorporatePortalLink from 'doings/links/createCorporatePortalLink/createCorporatePortalLink';
import { mobile } from 'doings/track/analyticsEvents';
import {
  SubscriptionReference,
  SubscriptionStatus
} from 'hooks/mobileSubscriptions/useSubscriptionDetails/subscriptionDetailsQuery';
import { useTranslation } from 'react-i18next';

import SubscriptionAccordion from '../SubscriptionAccordion/SubscriptionAccordion';

const SubscriptionMultiSimMembers: React.FC<{
  phoneNumberHash: string;
  members: SubscriptionReference[];
  additionalServices: string[];
  status: SubscriptionStatus;
  loading: boolean;
  expanded: boolean;
  disabled?: boolean;
}> = ({
  phoneNumberHash,
  members,
  additionalServices,
  status,
  loading,
  expanded,
  disabled = false
}) => {
  const { t } = useTranslation();
  const isLegacy = additionalServices?.includes('NAB');
  const buttonLabel = isLegacy
    ? t('subscriptions.actions.multiSim.manageLegacy')
    : t('subscriptions.actions.multiSim.manageYhteys');
  const anchor = isLegacy ? 'MULTI_SIM' : 'FUTURE_MULTI_SIM_MASTER';
  const corporatePortalUrl = createCorporatePortalLink(
    'mobileSubscriptionServices',
    { msisdn: phoneNumberHash, anchor },
    { section: 'multiSim' }
  );

  return (
    <SubscriptionAccordion
      heading={{
        label: t('subscriptions.actions.multiSim.heading', { count: members?.length ?? 0 }),
        testId: 'multi-sim-members-accordion',
        trackOnExpand: mobile.expandMultiSimMembers
      }}
      actionButton={{
        visible: !disabled,
        label: buttonLabel,
        corporatePortalUrl,
        testId: 'action-multi-sim-manage',
        trackOnClick: mobile.manageMultiSimMembers
      }}
      phoneNumberHash={phoneNumberHash}
      status={status}
      loading={loading}
      initiallyExpanded={expanded}
    >
      <BodyText fontWeight="bold" margin={{ bottom: 'xs' }}>
        {t('subscriptions.subscriptionDetails.activeMultiSimMembers')}
      </BodyText>

      <DefinitionList>
        {members.map(({ resolved, phoneNumber, phoneNumberHash, displayName }) => (
          <DefinitionList.Item
            data-testid={`multi-sim-member-${phoneNumber}`}
            key={`multi-sim-member-${phoneNumber}`}
          >
            {resolved ? (
              <TeliaLink.Internal
                variant="standalone"
                to={`/subscriptions/${phoneNumberHash}`}
                label={`${phoneNumber} ${displayName || t('subscriptions.noUserName')}`}
                iconRight="chevron-right"
                track={mobile.viewMultiSimDetails}
                data-testid={`show-multi-sim-member-${phoneNumber}`}
                data-dd-action-name="Show parallel subscription"
              />
            ) : (
              <TeliaLink.Internal
                variant="standalone"
                to="#"
                label={`${phoneNumber} ${t(
                  'subscriptions.subscriptionDetails.couldNotLoadParallelSubscription'
                )}`}
                data-testid={`missing-multi-sim-member-${phoneNumber}`}
                disabled
              />
            )}
          </DefinitionList.Item>
        ))}
      </DefinitionList>
    </SubscriptionAccordion>
  );
};

export default SubscriptionMultiSimMembers;
