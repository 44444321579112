import formatDatetime from 'doings/formatDatetime/formatDatetime';
import useApplyNewPaymentPlan from 'hooks/invoicing/invoices/useApplyNewPaymentPlan/useApplyNewPaymentPlan';
import useInvoice from 'hooks/invoicing/invoices/useInvoice/useInvoice';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

type ApplyPaymentPlanSteps = 'loading' | 'initial' | 'confirmed' | 'error';

export default () => {
  const { key } = useParams<{ key: string }>();
  const {
    invoice,
    loading: loadingInvoiceDetails,
    hasError
  } = useInvoice({
    key
  });

  const [step, setStep] = useState<ApplyPaymentPlanSteps>('initial');
  const { applyPaymentPlan, data, loading, error } = useApplyNewPaymentPlan(key);

  useEffect(() => {
    if (loading) {
      setStep('loading');
    } else if (error) {
      setStep('error');
    } else if (data) {
      setStep('confirmed');
    }
  }, [data, error, loading]);

  const firstPayDate = data?.applyNewPaymentPlan?.firstPaymentDate;
  const confirmedNewDueDate = formatDatetime(firstPayDate);

  return {
    invoice,
    confirmedNewDueDate,
    loadingInvoiceDetails,
    hasError,
    step,
    setStep,
    applyPaymentPlan
  };
};
