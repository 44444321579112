import { useHtmlElement } from 'hooks/useHtmlElement/useHtmlElement';
import { useCallback, useLayoutEffect, useState } from 'react';

const REM_IN_PX_AT_100_PERCENT_ZOOM = 10;

export const BREAKPOINTS_IN_PX = {
  xxxs: { min: 0, max: 375 },
  xxs: { min: 376, max: 480 },
  xs: { min: 481, max: 600 },
  sm: { min: 601, max: 840 },
  md: { min: 841, max: 1080 },
  lg: { min: 1081, max: 1440 },
  xlg: { min: 1441, max: 1920 },
  xxlg: { min: 1921, max: Number.MAX_SAFE_INTEGER }
} as const;

export type Breakpoint = keyof typeof BREAKPOINTS_IN_PX;

export type BreakpointSpecifier = {
  /** Exclusive. Renders when document is narrower than specified breakpoint's min width. */
  narrowerThan?: Breakpoint;
  /** Inclusive. Renders when document is at specified breakpoint. */
  at?: Breakpoint;
  /** Exclusive. Renders when document is wider than specified breakpoint's max width. */
  widerThan?: Breakpoint;
};

/**
 * In contrast to `useBreakpoint`, this hook considers both application zoom
 * and root element font sizing (text-only zoom) for determining whether a
 * particular breakpoint is active. Use in places where increasing the text size
 * up to 200% would otherwise render content unusable or obstructed. Breakpoints
 * match Gaia's breakpoints.
 *
 * Allows the application to conform to WCAG success criterion 1.4.4 Resize Text
 * (Level AA).
 *
 * @see `RemBreakpoint`
 */
export const useRemBreakpoint = (specifier: BreakpointSpecifier) => {
  const detector = useHtmlElement('#text-resize-detector');
  const [active, setActive] = useState<boolean>(() => calculateActiveState(specifier).active);

  const determineBreakpointActive = useCallback(() => {
    window.requestAnimationFrame(() => {
      const activeState = calculateActiveState(specifier);
      setActive(activeState.active);
    });
  }, [specifier]);

  useLayoutEffect(() => {
    if (!detector) {
      window.addEventListener('resize', determineBreakpointActive);
      return () => {
        window.removeEventListener('resize', determineBreakpointActive);
      };
    }

    const onResize = new ResizeObserver(() => determineBreakpointActive());
    onResize.observe(detector);
    window.addEventListener('resize', determineBreakpointActive);
    return () => {
      onResize.disconnect();
      window.removeEventListener('resize', determineBreakpointActive);
    };
  }, [detector, determineBreakpointActive]);

  return { isActive: active };
};

const calculateActiveState = ({ narrowerThan, at, widerThan }: BreakpointSpecifier) => {
  const activeBreakpoint = calculateActiveBreakpoint();
  const minWidth = BREAKPOINTS_IN_PX[activeBreakpoint].min;
  const maxWidth = BREAKPOINTS_IN_PX[activeBreakpoint].max;

  const isNarrower = narrowerThan ? maxWidth < BREAKPOINTS_IN_PX[narrowerThan].min : false;
  const isAt = at ? activeBreakpoint === at : false;
  const isWider = widerThan ? minWidth > BREAKPOINTS_IN_PX[widerThan].max : false;
  const active = isNarrower || isAt || isWider;
  return { activeBreakpoint, active };
};

const calculateActiveBreakpoint = (): Breakpoint => {
  const width = calculateEffectiveDocumentWidth();
  return (Object.entries(BREAKPOINTS_IN_PX).find(
    ([_, { min, max }]) => width >= min && width <= max
  ) || ['xxxs'])[0] as Breakpoint;
};

const calculateEffectiveDocumentWidth = () => {
  const remInPixels = calculateRemInPixels();
  const docWidth = document.documentElement.offsetWidth;
  return Math.ceil((docWidth / remInPixels) * REM_IN_PX_AT_100_PERCENT_ZOOM);
};

const calculateRemInPixels = () => {
  return parseFloat(getComputedStyle(document.documentElement).fontSize);
};
