import InlineNotificationLite from 'common-components/InlineNotificationLite/InlineNotificationLite';
import {
  SubscriptionDetails,
  SubscriptionReferenceType,
  filterReference
} from 'hooks/mobileSubscriptions/useSubscriptionDetails/subscriptionDetailsQuery';

import SubscriptionReferenceLink from './SubscriptionReferenceLink/SubscriptionReferenceLink';

const MainSubscriptionReference: React.FC<{
  subscription: SubscriptionDetails;
  referenceType: SubscriptionReferenceType;
  messageOnUnresolvedReference: string;
}> = ({ subscription, referenceType, messageOnUnresolvedReference }) => {
  const main = filterReference(subscription, referenceType);
  return main?.resolved ? (
    <SubscriptionReferenceLink data-testid="subscription-title" subscriptionReference={main} />
  ) : (
    <InlineNotificationLite
      data-testid="missing-parent-warning"
      variant="warning"
      messageText={messageOnUnresolvedReference}
    />
  );
};

export default MainSubscriptionReference;
