import { Icon, IconName } from '@teliafi/fi-ds';
import classNames from 'classnames';

import styles from './NewsDrawerTab.module.scss';

const NewsDrawerTab: React.FC<{
  isActive: boolean;
  iconName: IconName;
  label: string;
  onClick: VoidFunction;
  'aria-controls': string;
  'data-testid'?: string;
  children?: React.ReactNode;
}> = ({
  isActive,
  iconName,
  label,
  onClick,
  'aria-controls': ariaControls,
  'data-testid': testId,
  children
}) => (
  <button
    aria-controls={ariaControls}
    aria-selected={isActive}
    role="tab"
    color="black"
    onClick={onClick}
    className={classNames(styles.button, {
      [styles.button_active]: isActive
    })}
    data-testid={testId}
  >
    <Icon name={iconName} size="lg" />

    <span className={styles.label}>
      {label}

      {children}
    </span>
  </button>
);

export default NewsDrawerTab;
