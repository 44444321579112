import { Div } from '@gaiads/telia-react-component-library';
import { NotificationNavigationLink } from 'B2XApp/Dashboard/DashboardNavigation/DashboardNotifications/useGetDashboardNotificationRows';
import { Analytics } from 'common-components';
import ExternalLink from 'common-components/Link/ExternalLink/ExternalLink';
import InternalLink from 'common-components/Link/Link';
import { AnalyticsEvent } from 'doings/track/analyticsEvents';
import { useMemo } from 'react';

import DashboardNotificationRowContent from './DashboardNotificationRowContent';

const renderRowContent = ({
  analyticsEvent,
  total,
  label,
  tooltip,
  navigationLink,
  testId
}: {
  analyticsEvent: AnalyticsEvent;
  total: number;
  label: string;
  tooltip?: string;
  navigationLink: NotificationNavigationLink;
  testId?: string;
}) => {
  const { to, external, onClick } = navigationLink;
  const Link = external ? ExternalLink : InternalLink;
  return (
    <Analytics whenClicked={analyticsEvent} tagName="div">
      <Link data-testid={testId} to={to} onClick={onClick} block>
        <DashboardNotificationRowContent
          total={total}
          label={label}
          tooltip={tooltip}
          variant="link"
        />
      </Link>
    </Analytics>
  );
};

const DashboardNotificationRow: React.FC<{
  'data-testid'?: string;
  analyticsEvent: AnalyticsEvent;
  total: number;
  label: string;
  tooltip?: string;
  errorLabel: string;
  navigationLink: NotificationNavigationLink;
  isError: boolean;
}> = ({
  'data-testid': testId,
  analyticsEvent,
  total,
  label,
  tooltip,
  errorLabel,
  navigationLink,
  isError
}) => {
  return useMemo(
    () => (
      <Div padding={{ vertical: 'xxs' }}>
        {!isError &&
          renderRowContent({ analyticsEvent, total, label, tooltip, navigationLink, testId })}

        {isError && <DashboardNotificationRowContent label={errorLabel} variant="error" />}
      </Div>
    ),

    /*
     * Since dashboard rows depend on independent async queries, we
     * only need to rerender on API status and basic row information
     * changes. Without memoisation, we would render an already
     * rendered row again when an unrelated query resolves, over and
     * over again.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isError, total, label, errorLabel]
  );
};

export default DashboardNotificationRow;
