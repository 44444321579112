import { RouteType } from 'B2XApp/appRoutes';
import useRoutesMatchingCurrentRoute from 'hooks/useRoutesMatchingCurrentRoute/useRoutesMatchingCurrentRoute';

const useCurrentRoute = (): RouteType | undefined => {
  const { matchingRoutes } = useRoutesMatchingCurrentRoute();

  return matchingRoutes.pop();
};

export default useCurrentRoute;
