import { gql } from '@apollo/client';

export const activityLogQuery = gql`
  query GetChatAndCallHistory(
    $offset: Int!
    $limit: Int!
    $interactionType: InteractionType
    $invoice: String
    $subscription: String
    $subject: String
    $dateFrom: String
    $dateTo: String
    $allCompanies: Boolean
    $allUsers: Boolean
  ) {
    activityLog(
      offset: $offset
      limit: $limit
      interactionType: $interactionType
      invoice: $invoice
      subscription: $subscription
      subject: $subject
      dateFrom: $dateFrom
      dateTo: $dateTo
      allCompanies: $allCompanies
      allUsers: $allUsers
    ) {
      total
      interactions {
        id
        creationDate
        baseType
        description
        companyName
        reason
        type
        subscriptionNumber
        invoiceNumber
        transcript
        contactName
      }
    }
  }
`;
