import { ApolloError, gql } from '@apollo/client';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { ApiStatus } from 'types/api';

export const defaultCollectionGroupQuery = gql`
  query GetDefaultBillingAccount($companyId: String!) {
    defaultCollectionGroup(companyId: $companyId) {
      defaultCollectionGroupPresent
    }
  }
`;

const mapApiStatus = (loading: boolean, data?: Response, error?: ApolloError): ApiStatus => {
  if (loading) {
    return ApiStatus.Loading;
  } else if (error || !data) {
    return ApiStatus.Error;
  }

  return ApiStatus.Loaded;
};

interface Response {
  defaultCollectionGroup: {
    defaultCollectionGroupPresent: boolean;
  };
}

interface UseDefaultCollectionGroupResult {
  status: ApiStatus;
  companyId: string;
  defaultCollectionGroupPresent: boolean;
}

export default ({ companyId }: { companyId: string }): UseDefaultCollectionGroupResult => {
  const { loading, data, error } = useCallBackend<Response>({
    query: defaultCollectionGroupQuery,
    queryVariables: {
      companyId
    }
  });

  return {
    status: mapApiStatus(loading, data, error),
    companyId,
    defaultCollectionGroupPresent:
      data?.defaultCollectionGroup?.defaultCollectionGroupPresent || false
  };
};
