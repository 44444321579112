import {
  Div,
  Gutter,
  SupportedBoxShadow,
  SupportedColor
} from '@gaiads/telia-react-component-library';
import classNames from 'classnames';
import { Heading } from 'common-components';

import styles from './DataPanel.module.scss';
import DataPanelImage from './DataPanelImage';
import DataPanelImageClearFloat from './DataPanelImageClearFloat';
import DetailsLoadingErrorPanel from './DetailsLoadingErrorPanel/DetailsLoadingErrorPanel';

type Title = string | Array<string | undefined> | React.ReactNode;

const makeArray = (title?: Title) => {
  if (Array.isArray(title)) {
    return title.filter(Boolean);
  }

  if (title) {
    return [title];
  }

  return [];
};

const DataPanel: React.FC<{
  title?: Title;
  subtitle?: string;
  compactTitle?: boolean;
  skeletonLoader?: React.ReactElement;
  loading?: boolean;
  hasError?: boolean;
  children: React.ReactNode;
  imageSrc?: string;
  imageSrcLoading?: boolean;
  imageAlt?: string;
  clearFloatAfterImage?: boolean;
  backgroundColor?: SupportedColor;
  padding?: React.ComponentProps<typeof Div>['padding'];
  shadow?: SupportedBoxShadow;
  'data-testid'?: string;
  fullHeight?: boolean;
  className?: string;
  mainPanel?: boolean;
}> = ({
  title,
  subtitle,
  compactTitle = false,
  skeletonLoader,
  loading = false,
  hasError,
  children,
  imageSrc,
  imageSrcLoading,
  imageAlt,
  clearFloatAfterImage = false,
  backgroundColor = 'white',
  padding = { size: 'md' },
  shadow = 'sm',
  'data-testid': testId,
  fullHeight,
  className,
  mainPanel
}) => {
  const titles = makeArray(title);
  if (loading && skeletonLoader) {
    return skeletonLoader;
  }

  return (
    <Div
      data-testid={testId}
      className={classNames([
        {
          [styles.dataPanel__fullWidth]: true,
          [styles.dataPanel__fullHeight]: !!fullHeight
        },
        className
      ])}
      padding={padding}
      margin={{ horizontal: 'zero' }}
      borderRadius={{ size: 'md' }}
      boxShadow={shadow}
      backgroundColor={backgroundColor}
    >
      {!hasError && (
        <>
          <DataPanelImage
            imageSrc={imageSrc}
            imageLoading={imageSrcLoading}
            altText={imageAlt || 'image'}
            data-testid="data-panel-image"
          />

          {!!titles.length && (
            <>
              {titles.map((title, index) => (
                <DataPanelHeading
                  mainPanel={mainPanel}
                  title={title}
                  testId={testId}
                  key={`heading-${index}`}
                />
              ))}

              {subtitle && (
                <Heading.H5
                  data-testid={testId ? `${testId}-sub-heading` : undefined}
                  className={styles.subtitle}
                >
                  {subtitle}
                </Heading.H5>
              )}

              <Gutter
                data-testid={`data-panel-title-spacer-${compactTitle ? 'xs' : 'sm'}`}
                size={compactTitle ? 'xs' : 'sm'}
              />
            </>
          )}

          {clearFloatAfterImage && <DataPanelImageClearFloat />}
        </>
      )}

      <Div data-testid={testId ? `${testId}-contents` : undefined}>
        {hasError ? <DetailsLoadingErrorPanel /> : children}
      </Div>
    </Div>
  );
};

const DataPanelHeading: React.FC<{ mainPanel?: boolean; testId?: string; title: Title }> = ({
  mainPanel = false,
  testId,
  title
}) => {
  const TitleHeading = mainPanel ? Heading.H3 : Heading.H4;
  return (
    <TitleHeading data-testid={testId ? `${testId}-heading` : undefined}>{title}</TitleHeading>
  );
};

export default DataPanel;
