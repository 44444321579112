import { Badge } from '@teliafi/fi-ds';
import { Flex } from 'core-components';
import { useTranslation } from 'react-i18next';

import styles from './SortableCheckboxesListItemLabel.module.scss';

export const SortableCheckboxesListItemLabel: React.FC<{
  label: string;
  indicateRequiredItem?: boolean;
  indicateNewItem?: boolean;
}> = ({ label, indicateRequiredItem = false, indicateNewItem = false }) => {
  const { t } = useTranslation();

  return (
    <Flex occupyHorizontalSpace>
      {label}

      {indicateRequiredItem ? ' *' : ''}

      {indicateNewItem && (
        <Badge className={styles.itemBadge} variant="attention" showIcon={false}>
          {t('common.sortableCheckboxes.status.new')}
        </Badge>
      )}
    </Flex>
  );
};
