import { GridBag, Gutter, Page } from '@gaiads/telia-react-component-library';
import { Heading, PageWithSubnavigation } from 'common-components';
import { Div } from 'core-components';
import { useTranslation } from 'react-i18next';

import styles from './ContactUs.module.scss';
import CallsAndChatPanel from './ContactUsPanels/CallsAndChatPanel';
import CustomerSpecificMessagesPanel from './ContactUsPanels/CustomerSpecificMessagesPanel';
import CustomerSupportPanel from './ContactUsPanels/CustomerSupportPanel';
import FaultReportsPanel from './ContactUsPanels/FaultReportsPanel';
import RequestForQuotePanel from './ContactUsPanels/RequestForQuotePanel';
import ServiceRequestsPanel from './ContactUsPanels/ServiceRequestsPanel';
import ContactUsSearch from './ContactUsSearch';

const ContactUsPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PageWithSubnavigation withoutCompanySelector showSubnavigationForCurrentPath>
      <Page.Row margin={{ bottom: 'md' }}>
        <Heading.H2>{t('contactUs.title')}</Heading.H2>
      </Page.Row>

      <Page.Row margin={{ bottom: 'xxs' }}>
        <Div className={styles.searchBar}>
          <ContactUsSearch />
        </Div>
      </Page.Row>

      <Page.Row>
        <GridBag>
          <GridBag.Item lg={4} md={12}>
            <ServiceRequestsPanel />

            <Gutter size="sm" />

            <RequestForQuotePanel />

            <Gutter size="sm" />

            <FaultReportsPanel />
          </GridBag.Item>

          <GridBag.Item lg={4} md={12}>
            <CallsAndChatPanel />

            <Gutter size="sm" />

            <CustomerSpecificMessagesPanel />
          </GridBag.Item>

          <GridBag.Item lg={4} md={12}>
            <CustomerSupportPanel />
          </GridBag.Item>
        </GridBag>
      </Page.Row>
    </PageWithSubnavigation>
  );
};

export default ContactUsPage;
