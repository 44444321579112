export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc'
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
  DEFAULT = 'default'
}

export type SortParameter = {
  sort?: string;
  order?: SortOrder;
};

export type SortAction = (field: string, order: SortOrder) => void;

export type Sort = {
  direction: SortDirection;
  onClick: VoidFunction;
};
