import ErrorPageContent from 'B2XApp/Errors/Error/ErrorPage/ErrorPageContent';
import { useLocation } from 'react-router-dom';

type LocationState = {
  referrer?: string;
};

const AccessDenied: React.FC = () => {
  const { state } = useLocation<LocationState | undefined>();

  return <ErrorPageContent currentLocation={state?.referrer || '/'} variant="forbidden" />;
};

export default AccessDenied;
