import { messageStatusMap } from 'B2XApp/Messages/MessageArchive/MessageStatusTag';
import { TeliaLink } from 'common-components';
import isDemoEnvironment from 'doings/isDemoEnvironment/isDemoEnvironment';
import { joinURLSearchParams } from 'doings/links/joinURLSearchParams/joinURLSearchParams';
import removeEmptyFields from 'doings/removeEmptyFields/removeEmptyFields';
import { UseReadQueryParamsResult } from 'hooks/useQueryParams/useReadQueryParams';
import { useTranslation } from 'react-i18next';
import { CaseStatuses } from 'types/companyCaseList';
import { EXCEL_MAX_ITEMS_VALUE } from 'types/excelDownload';
import { SortParameter } from 'types/sort';

export type ArchivedServiceRequestExcelDownloadLinkProps = {
  totalItems: number;
  disabled: boolean;
  queryParameters: UseReadQueryParamsResult;
  sortParameter: SortParameter;
};

export const ExcelDownloadLink: React.FC<ArchivedServiceRequestExcelDownloadLinkProps> = ({
  totalItems,
  disabled,
  queryParameters,
  sortParameter
}) => {
  const { t } = useTranslation();
  const isDemoEnv = isDemoEnvironment();
  const { sort, order } = sortParameter;

  const urlSearchParams = new URLSearchParams(
    removeEmptyFields({
      status: queryParameters.status,
      from: queryParameters.from,
      to: queryParameters.to,
      search: queryParameters.search
    })
  );

  const statuses = Array.from(CaseStatuses)
    .map((status) => `${status}:${t(messageStatusMap[status].i18nKey)}`)
    .join(',');

  const queryParams = joinURLSearchParams('?', {
    columns: [
      t('messageArchive.list.columns.reportedDate'),
      t('messageArchive.list.columns.updatedDate'),
      t('messageArchive.list.columns.subject'),
      t('messageArchive.list.columns.status'),
      t('messageArchive.list.columns.id'),
      t('common.companyCode.label'),
      t('common.company.label')
    ].join(','),
    filter: urlSearchParams.toString(),
    sort,
    order,
    messageStatuses: statuses,
    showFromAllCompanies: queryParameters.allCompanies === 'true',
    sheetTitle: t('messageArchive.title')
  });

  return (
    <TeliaLink.Download
      label={t('common.excelDownload.label')}
      to={`/api/messageArchive/listAsExcel${queryParams}`}
      iconLeft="download"
      data-testid="message-archive-list-as-excel-link"
      disabled={isDemoEnv || disabled || totalItems > EXCEL_MAX_ITEMS_VALUE}
      disabledTooltipKey={
        totalItems > EXCEL_MAX_ITEMS_VALUE ? 'common.excelDownload.tooManyItems' : undefined
      }
      downloadWithFetch
      useFileNameFromResponse
    />
  );
};
