import { Dropdown, OptionListOption } from '@gaiads/telia-react-component-library';
import { DropdownInputModel } from 'hooks/inputModels/useDropdownInputModel/useDropdownInputModel';
import { useTranslation } from 'react-i18next';
import { EventHandlers } from 'types/eventHandler';

const ModelDropdown: React.FC<{
  label: string;
  name?: string;
  inputModel: DropdownInputModel;
  placeholder?: string;
  disabled?: boolean;
  onFocus?: EventHandlers.Focus;
  onBlur?: EventHandlers.Focus;
  'data-testid'?: string;
}> = ({
  label,
  name,
  inputModel,
  placeholder,
  disabled,
  onFocus,
  onBlur,
  'data-testid': dataTestId
}) => {
  const { t } = useTranslation();
  const defaultPlaceholder = inputModel.required ? t('common.dropdown.selectValue') : '';

  return (
    <Dropdown
      data-testid={dataTestId}
      label={label}
      aria-label={label}
      name={name}
      onChange={(_event, option: OptionListOption) => inputModel.setValue(option.value)}
      onFocus={onFocus}
      onBlur={onBlur}
      options={inputModel.options}
      placeholder={placeholder || defaultPlaceholder}
      required={inputModel.required}
      errorText={inputModel.errorMessage}
      selectedOption={inputModel.options.find((option) => option.value === inputModel.value)}
      disabled={disabled}
    />
  );
};

export default ModelDropdown;
