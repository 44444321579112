import {
  BillingGroupFormStep,
  UseBillingGroupStepsResult
} from 'B2XApp/Invoicing/BillingGroups/BillingGroupForm/useBillingGroupForm';
import useDefaultCollectionGroup from 'hooks/invoicing/billingGroups/useDefaultCollectionGroup/useDefaultCollectionGroup';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiStatus } from 'types/api';
import { CollectionGroupApiStatus } from 'types/collection-group';

type UseNewBillingGroupStepsResult = UseBillingGroupStepsResult & {
  precheckNotice: { title: string; content: string };
};

const PRECHECK_FAILURE_NOTICES = {
  inapplicable: {
    title: '',
    content: ''
  },
  defaultUnknown: {
    title: 'invoices.billingGroup.form.creation.notification.defaultUnknownTitle',
    content: 'invoices.billingGroup.form.creation.notification.defaultUnknownDescription'
  },
  defaultExists: {
    title: 'invoices.billingGroup.form.creation.notification.defaultExistsTitle',
    content: 'invoices.billingGroup.form.creation.notification.defaultExistsDescription'
  }
};

const useNewBillingGroupSteps = ({
  defaultCollectionGroupQueryResult,
  createApiStatus
}: {
  defaultCollectionGroupQueryResult: ReturnType<typeof useDefaultCollectionGroup>;
  createApiStatus: CollectionGroupApiStatus;
}): UseNewBillingGroupStepsResult => {
  const { t } = useTranslation();
  const [step, setStep] = useState<BillingGroupFormStep>('prechecking');
  const defaultCoguCheck = useRef({ inProgress: false, company: '' });
  const precheckNotice = useRef(PRECHECK_FAILURE_NOTICES.inapplicable);

  useEffect(() => {
    const {
      defaultCollectionGroupPresent: defaultCoguPresent,
      companyId: defaultCoguQueryCompany,
      status: defaultCoguApiStatus
    } = defaultCollectionGroupQueryResult;

    if (defaultCoguApiStatus !== ApiStatus.Loaded) {
      switch (defaultCoguApiStatus) {
        case ApiStatus.Loading:
          defaultCoguCheck.current = { inProgress: true, company: defaultCoguQueryCompany };
          precheckNotice.current = PRECHECK_FAILURE_NOTICES.inapplicable;
          setStep('prechecking');
          return;

        case ApiStatus.Error:
          defaultCoguCheck.current = { inProgress: false, company: defaultCoguQueryCompany };
          precheckNotice.current = PRECHECK_FAILURE_NOTICES.defaultUnknown;
          setStep('precheck-failure');
          return;
      }
    }

    const { inProgress, company } = defaultCoguCheck.current;
    if (inProgress || company !== defaultCoguQueryCompany) {
      if (defaultCoguPresent) {
        defaultCoguCheck.current = { inProgress: false, company: defaultCoguQueryCompany };
        precheckNotice.current = PRECHECK_FAILURE_NOTICES.defaultExists;
        setStep('precheck-failure');
        return;
      } else {
        defaultCoguCheck.current = { inProgress: false, company: defaultCoguQueryCompany };
        precheckNotice.current = PRECHECK_FAILURE_NOTICES.inapplicable;
        setStep('initial');
        return;
      }
    }

    if (['persisting', 'success', 'partial-success', 'failure'].includes(createApiStatus)) {
      setStep(createApiStatus);
    }
  }, [createApiStatus, defaultCollectionGroupQueryResult]);

  const { title: precheckTitleKey, content: precheckContentKey } = precheckNotice.current;
  return {
    step,
    setStep,
    precheckNotice: {
      title: t(precheckTitleKey),
      content: t(precheckContentKey)
    }
  };
};

export default useNewBillingGroupSteps;
