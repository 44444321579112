import { TeliaLink } from 'common-components';
import getEnvironmentVariable from 'doings/getEnvironmentVariable/getEnvironmentVariable';
import { ordersAndRFQs } from 'doings/track/analyticsEvents';

export const TeliaCygateLink: React.FC<{ children?: string }> = ({ children: label }) => (
  <TeliaLink.Translatable.External
    data-testid="telia-cygate-link"
    variant="text"
    to={getEnvironmentVariable('REACT_APP_LINK_TO_TELIA_CYGATE')}
    iconRight="external"
    track={ordersAndRFQs.visitTeliaCygate}
  >
    {label}
  </TeliaLink.Translatable.External>
);
