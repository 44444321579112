import { useFeatureFlagsData } from 'contexts/FeatureFlagContext/FeatureFlagContext';
import { useUserData } from 'contexts/UserContext/UserContext';
import { isSmallBusiness } from 'doings/isSmallBusiness/isSmallBusiness';
import createCorporatePortalLink from 'doings/links/createCorporatePortalLink/createCorporatePortalLink';
import { AnalyticsEvent } from 'doings/track/analyticsEvents';
import userHasPermission from 'doings/userHasPermission/userHasPermission';
import useClearNewMessagesNotification from 'hooks/customerSpecificMessages/useClearNewMessagesNotification';
import useNewMessagesNotification from 'hooks/customerSpecificMessages/useNewMessagesNotification';
import useInvoices from 'hooks/invoicing/invoices/useInvoices/useInvoices';
import useCompanyCaseTotal from 'hooks/messageArchive/useCompanyCaseList/useCompanyCaseTotal';
import useServiceRequestsAggregate from 'hooks/serviceRequests/useServiceRequests/useServiceRequestsAggregate';
import useTicketsAggregate from 'hooks/tickets/useTickets/useTicketsAggregate';
import { ViewStatus } from 'hooks/useApiStatus/useApiStatus';
import { AuthorizationParams } from 'types/authorization';
import { Invoice } from 'types/invoice';
import { SortOrder } from 'types/sort';

import useGetDashboardNotificationsMetainfo from './useGetDashboardNotificationsMetainfo';

const MAX_INVOICES_TO_QUERY = 100;

const getDueDatePastTotal = (invoices: Invoice[]) =>
  invoices.filter(({ status }) => status === 'overdue').length;

const billingPermission: AuthorizationParams = {
  anyPermission: ['BILLING_INVOICES', 'BILLING_INVOICES_VIEW']
};

const serviceRequestPermission: AuthorizationParams = {
  anyPermission: ['SERVICE_REQUESTS', 'SERVICE_REQUESTS_VIEW']
};

const ticketsPermission: AuthorizationParams = {
  anyPermission: ['INCIDENT_TICKETS', 'INCIDENT_TICKETS_VIEW']
};

const messagesPermission: AuthorizationParams = {
  anyPermission: ['MESSAGE_ARCHIVE']
};

const customerSpecificMessagesPermission: AuthorizationParams = {
  onePermission: 'B2B_PORTAL',
  anyPermission: ['ADMIN']
};

export type NotificationNavigationLink = {
  to: string;
  external?: boolean;
  onClick?: VoidFunction;
};

export type DashboardNotificationRowItem = {
  analyticsEvent: AnalyticsEvent;
  total: number;
  loadingState: ViewStatus;
  navigationLink: NotificationNavigationLink;
  label: string;
  tooltip?: string;
  errorLabel: string;
  dataIdSuffix: string;
  position: number;
};

type UseGetDashboardNotificationRowsInput = {
  onlyContacts?: boolean;
};

export default (params?: UseGetDashboardNotificationRowsInput): DashboardNotificationRowItem[] => {
  const user = useUserData();
  const { hasFeature } = useFeatureFlagsData();

  const checkPermission = userHasPermission(user);
  const userHasPermissionToBilling = checkPermission(billingPermission);
  const userHasPermissionToSRs = checkPermission(serviceRequestPermission);
  const userHasPermissionToTickets = checkPermission(ticketsPermission);
  const userHasPermissionToMessages = hasFeature('DEO-3122') && checkPermission(messagesPermission);
  const userHasPermissionToCustomerSpecificMessages =
    hasFeature('EART-5391') && checkPermission(customerSpecificMessagesPermission);

  const shouldUseB2BPortalBilling =
    !hasFeature('EAT-46311') &&
    !isSmallBusiness(user) &&
    checkPermission({ onePermission: 'B2B_PORTAL' });

  const pendingServiceRequestStatus = 'Waiting for information';

  const {
    invoices: { items: invoices, total: openInvoicesTotal },
    apiState: invoicesLoadingState
  } = useInvoices(
    { order: SortOrder.ASC, sort: 'dueDate' },
    { status: 'OPEN' },
    hasFeature('EART-1404'),
    MAX_INVOICES_TO_QUERY,
    !userHasPermissionToBilling || !!params?.onlyContacts
  );

  const {
    totalWaitingForInformation: serviceRequestsWaitingForInformation,
    apiState: serviceRequestsLoadingState
  } = useServiceRequestsAggregate({
    skip: !userHasPermissionToSRs,
    status: pendingServiceRequestStatus
  });

  const {
    totalWaitingForInformation: ticketsWaitingForInformation,
    apiState: ticketsLoadingState
  } = useTicketsAggregate({
    skip: !userHasPermissionToTickets
  });

  const { clearNewCustomerSpecificMessagesNotification } = useClearNewMessagesNotification();
  const { newCustomerSpecificMessages, available: newCustomerSpecificMessagesAvailable } =
    useNewMessagesNotification({
      skip: !userHasPermissionToCustomerSpecificMessages
    });

  const { apiState: messagesLoadingState, total } = useCompanyCaseTotal({
    initParams: {
      status: 'waitingForCustomer',
      allCompanies: true,
      searchTerm: ''
    },
    skip: !userHasPermissionToMessages
  });

  const metainfo = useGetDashboardNotificationsMetainfo(getDueDatePastTotal(invoices));

  const insertIf = (condition: boolean, item: DashboardNotificationRowItem) =>
    condition ? [item] : [];

  return [
    ...insertIf(userHasPermissionToMessages, {
      total: total,
      loadingState: messagesLoadingState,
      navigationLink: {
        to: '/message-archive?status=waitingForCustomer'
      },
      ...metainfo.messagesMetainfo
    }),

    ...insertIf(userHasPermissionToSRs, {
      total: serviceRequestsWaitingForInformation,
      loadingState: serviceRequestsLoadingState,
      navigationLink: {
        to: `/service-requests?status=${pendingServiceRequestStatus}`
      },
      ...metainfo.serviceRequestsMetainfo
    }),

    ...insertIf(userHasPermissionToTickets, {
      total: ticketsWaitingForInformation,
      loadingState: ticketsLoadingState,
      navigationLink: {
        to: '/tickets?status=Pending'
      },
      ...metainfo.ticketsMetainfo
    }),

    ...insertIf(userHasPermissionToBilling && !params?.onlyContacts, {
      total: openInvoicesTotal,
      loadingState: invoicesLoadingState,
      navigationLink: {
        to: shouldUseB2BPortalBilling
          ? createCorporatePortalLink('billing/invoices')
          : '/invoices?status=open&sort=invoiceDate.asc',
        external: shouldUseB2BPortalBilling
      },
      ...metainfo.invoicesMetainfo
    }),

    ...insertIf(
      userHasPermissionToCustomerSpecificMessages && newCustomerSpecificMessagesAvailable,
      {
        total: newCustomerSpecificMessages,
        loadingState: ViewStatus.Loaded,
        navigationLink: {
          to: createCorporatePortalLink('messageCenter', { show: 'customer-specific-messages' }),
          external: true,
          onClick: () => clearNewCustomerSpecificMessagesNotification({ skipRedirect: true })
        },
        ...metainfo.customerSpecificMessagesMetainfo
      }
    )
  ];
};
