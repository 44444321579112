import { ApolloError, gql } from '@apollo/client';
import { multiplex } from 'doings/multiplex/multiplex';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { CollectionGroup, QueriedCollectionGroup } from 'types/collection-group';

export const collectionGroupQuery = gql`
  query GetBillingAccountDetails($collectionGroup: String!) {
    collectionGroup(collectionGroup: $collectionGroup) {
      collectionGroup
      canCopy
      name
      language
      email1
      email2
      distributor
      netServiceId
      phoneNumber
      reference
      customerCode
      address {
        foreign
        street
        postalOffice
        postalAddress
        zip
      }
      type {
        changeability
        deliveryMethod
        switchableDeliveryMethods
      }
      finvoice {
        accountDimensionText
        agreementIdentifier
        buyerReferenceIdentifier
        invoiceClassification
        normalProposedAccountIdent
        orderIdentifier
        projectReferenceIdentifier
        proposedAccountText
        tenderReference
        usesCustomerSettings
      }
    }
  }
`;

export type LoadingStatus = 'loading' | 'loaded' | 'error';

export interface UseCollectionGroupResult {
  item?: CollectionGroup;
  status: LoadingStatus;
  error?: ApolloError;
  hasError: boolean;
}

interface CollectionGroupResponse {
  collectionGroup: CollectionGroup;
}

export const mapCollectionGroupAddress = (collectionGroup: QueriedCollectionGroup) => {
  if (!collectionGroup.address) {
    return '';
  }

  const { foreign, street = '', zip = '', postalOffice = '' } = collectionGroup.address;
  return foreign ? street : `${street}, ${zip} ${postalOffice}`;
};

export const mapCollectionGroup = (
  t: TFunction,
  collectionGroup: QueriedCollectionGroup
): CollectionGroup => ({
  ...collectionGroup,
  invoicingLanguage: collectionGroup.language ? t(`languages.${collectionGroup.language}`) : '',
  billingAddress: mapCollectionGroupAddress(collectionGroup),
  name: collectionGroup.name ?? '',
  type: {
    ...collectionGroup.type,
    deliveryMethod: collectionGroup.type?.deliveryMethod || 'N/A',
    displayedDeliveryMethod: collectionGroup.type?.deliveryMethod
      ? t(`billingInformation.billingMethods.${collectionGroup?.type.deliveryMethod}`)
      : 'N/A'
  }
});

const useCollectionGroup = ({
  collectionGroup
}: {
  collectionGroup: string;
}): UseCollectionGroupResult => {
  const { t } = useTranslation();
  const { data, loading, error } = useCallBackend<CollectionGroupResponse>({
    query: collectionGroupQuery,
    queryVariables: {
      collectionGroup
    }
  });

  return {
    item: data?.collectionGroup && mapCollectionGroup(t, data?.collectionGroup),
    status: multiplex(['error', [loading, 'loading'], [!!data?.collectionGroup, 'loaded']]),
    error,
    hasError: !!error
  };
};

export default useCollectionGroup;
