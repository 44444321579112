export default async (url: string, fileName: string, useFileNameFromResponse?: boolean) => {
  const response = await fetch(url);
  if (!response.ok) {
    return false;
  }

  const downloadedFileName = useFileNameFromResponse
    ? response.headers
        .get('Content-Disposition')
        ?.split(';')?.[1]
        .split('=')[1]
        .replace(/['"]/g, '')
    : fileName;
  const fileBlob = await response.blob();
  const fileUrl = window.URL.createObjectURL(fileBlob);

  const a = document.createElement('a');
  a.hidden = true;
  a.href = fileUrl;
  a.download = downloadedFileName ?? fileName;

  document.body.appendChild(a);

  a.click();
  a.remove();

  window.URL.revokeObjectURL(fileUrl);

  return true;
};
