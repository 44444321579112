import { ApolloError } from '@apollo/client';
import { Page } from '@gaiads/telia-react-component-library';
import ErrorPage from 'B2XApp/Errors/Error/ErrorPage/ErrorPage';
import { BreadcrumbWithCompanySelector, FullPageLoader, Subnavigation } from 'common-components';
import { useUserData } from 'contexts/UserContext/UserContext';
import useIsSmallerBreakpointActive from 'hooks/useIsSmallerBreakpointActive/useIsSmallerBreakpointActive';
import useSubnavigationItems from 'hooks/useSubnavigationItems/useSubnavigationItems';

import styles from './PageWithSubnavigation.module.scss';

const getRenderType = (
  error?: ApolloError,
  errorCondition?: boolean,
  loading?: boolean,
  emptyContentPlaceholder?: React.ReactElement | false
): 'error' | 'errorCondition' | 'emptyContentPlaceholder' | 'normal' => {
  if (errorCondition) {
    return 'errorCondition';
  } else if (error) {
    return 'error';
  } else if (!loading && emptyContentPlaceholder) {
    return 'emptyContentPlaceholder';
  }
  return 'normal';
};

const PageWithSubnavigation: React.FC<{
  loading?: boolean;
  showSubnavigationForCurrentPath?: boolean;
  error?: ApolloError;
  errorCondition?: boolean;
  emptyContentPlaceholder?: React.ReactElement | false;
  withoutCompanySelector?: boolean;
  children: React.ReactNode;
}> = ({
  loading = false,
  showSubnavigationForCurrentPath = false,
  error,
  errorCondition,
  emptyContentPlaceholder,
  children,
  withoutCompanySelector = false
}) => {
  const renderType = getRenderType(error, errorCondition, loading, emptyContentPlaceholder);

  const subnavigationItems = useSubnavigationItems();
  const isMobileView = useIsSmallerBreakpointActive('md');
  const { allCompanies } = useUserData();
  const showCompanySelector = !withoutCompanySelector && allCompanies.length > 1;

  return (
    <>
      {showSubnavigationForCurrentPath && subnavigationItems && (
        <div data-testid="subnavigation-wrapper">
          <Subnavigation navigationItems={subnavigationItems} />
        </div>
      )}

      <FullPageLoader loading={loading}>
        {renderType === 'error' && <ErrorPage error={error} />}

        {renderType === 'errorCondition' && <ErrorPage />}

        {renderType === 'emptyContentPlaceholder' && emptyContentPlaceholder}

        {renderType === 'normal' && (
          <Page
            className={styles.page}
            padding={{ vertical: 'lg' }}
            data-testid="page-with-subnavigation--page"
          >
            <Page.Row margin={{ bottom: showCompanySelector || isMobileView ? 'zero' : 'lg' }}>
              <BreadcrumbWithCompanySelector withoutCompanySelector={withoutCompanySelector} />
            </Page.Row>

            {children}
          </Page>
        )}
      </FullPageLoader>
    </>
  );
};

export default PageWithSubnavigation;
