import { OtherServiceLink } from 'B2XApp/Dashboard/DashboardOtherServices/useOtherServices';
import { TileButton, TileButtonGrid } from 'common-components';
import { Flex } from 'core-components';
import partitionBy from 'doings/partitionBy/partitionBy';
import { otherServices } from 'doings/track/analyticsEvents';
import { useTranslation } from 'react-i18next';

const COLS_IN_DESKTOP = 4;
const COLS_IN_MOBILE = 2;

const DashboardOtherServicesLinks: React.FC<{
  links: OtherServiceLink[];
  displayedWhen: (item: OtherServiceLink) => boolean;
  showAll: boolean;
  setShowAll: (value: boolean) => void;
  loading: boolean;
}> = ({ links, displayedWhen, showAll, setShowAll, loading }) => {
  const { t } = useTranslation();
  const [displayedLinks, hiddenLinks] = partitionBy(links, displayedWhen);
  const hasHiddenLinks = hiddenLinks.length > 0;

  const mapLinksToTiles = (links: OtherServiceLink[]) =>
    links.map((props) => (
      <TileButton key={`service-tile-${props.id}`} {...props} data-testid={props.id} />
    ));

  const hiddenLinksOrShowAll = showAll
    ? mapLinksToTiles(hiddenLinks)
    : [
        <TileButton
          key="service-tile-show-all"
          iconName="more-horizontal"
          label={t('modal.editLinks.text.showAll', { count: hiddenLinks.length })}
          whenClicked={otherServices.showHiddenOtherServices}
          data-testid="other-services--show-all"
          onClick={() => setShowAll(true)}
        />
      ];

  return (
    <Flex centeredHorizontally>
      <TileButtonGrid
        columns={{ desktop: COLS_IN_DESKTOP, mobile: COLS_IN_MOBILE }}
        loading={loading}
        loadingTiles={8}
      >
        {[...mapLinksToTiles(displayedLinks), ...(hasHiddenLinks ? hiddenLinksOrShowAll : [])]}
      </TileButtonGrid>
    </Flex>
  );
};

export default DashboardOtherServicesLinks;
