import { ModalDrawer } from 'common-components';
import { scrollTo } from 'doings/scroll/scrollTo';
import { useProductNews } from 'hooks/productNews/useProductNews';
import { useSeenProductNewsTab } from 'hooks/productNews/useSeenProductNewsTab';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toggleNewsDrawer } from 'redux/slices/newsDrawerSlice';
import { RootState } from 'redux/store';
import { ProductNewsCategory as ActiveTab } from 'types/productNews';

import NewsDrawerContent from './NewsDrawerContent';
import NewsDrawerTabs from './NewsDrawerTabs';

const NewsDrawer: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isOpen = useSelector((state: RootState) => state.newsDrawer.isOpen);
  const { news, initialDataRef, loading, error } = useProductNews();
  const [activeTab, setActiveTab] = useState<ActiveTab>('productUpdates');
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isOpen) {
      initialDataRef.current = null;
    }
  }, [isOpen, initialDataRef]);

  useSeenProductNewsTab({ activeTab, news, isOpen });

  const onTabChange = useCallback((activeTab: ActiveTab) => {
    setActiveTab(activeTab);
    scrollTo(contentRef.current, { top: 0, behavior: 'instant' });
  }, []);

  const onClose = useCallback(() => {
    dispatch(toggleNewsDrawer({ show: false }));
  }, [dispatch]);

  return (
    <ModalDrawer
      isOpen={isOpen}
      title={t('newsDrawer.title')}
      data-testid="news-drawer"
      onClose={onClose}
      contentRef={contentRef}
      buttons={{ component: <NewsDrawerTabs activeTab={activeTab} onChange={onTabChange} /> }}
    >
      <NewsDrawerContent
        key={activeTab}
        loading={loading}
        error={error}
        initialItems={initialDataRef.current?.[activeTab] ?? []}
        currentItems={news[activeTab]}
        activeTab={activeTab}
      />
    </ModalDrawer>
  );
};

export default NewsDrawer;
