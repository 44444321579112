import { gql } from '@apollo/client';

export const deviceQuery = gql`
  query GetDeviceDetails($deviceId: String!) {
    getDevice(id: $deviceId) {
      id
      orderCreationTime
      productCode
      productName
      currency
      imeiSerialNumber
      companyName
      paymentOption
      deviceUser
      priceWithoutVat
      pricePerMonthWithoutVat
      billingBeginDate
      billingEndDate
      additionalServices {
        id
        productName
      }
    }
  }
`;

export const deviceImageUrlQuery = gql`
  query ($productCode: String) {
    getDeviceImageUrl(productCode: $productCode)
  }
`;
