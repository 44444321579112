import { gql } from '@apollo/client';
import useCallBackendMutation from 'hooks/useCallBackend/useCallBackendMutation';

export const changeUserLanguageMutation = gql`
  mutation ($language: Language!) {
    changeUserLanguage(language: $language)
  }
`;

const useChangeUserLanguage = () => {
  const { runMutation, data, error, loading } = useCallBackendMutation(changeUserLanguageMutation);

  return {
    changeUserLanguage: (language: Language) =>
      runMutation({
        variables: {
          language
        }
      }),
    data,
    error,
    loading
  };
};

export default useChangeUserLanguage;
