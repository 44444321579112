import { BodyText } from '@gaiads/telia-react-component-library';
import { MarginProps } from '@gaiads/telia-react-component-library/build/types/components/Element/getMarginClasses';
import getClassNames from 'classnames';
import styles from 'common-components/Forms/RequiredFieldsLabel/RequiredFieldsLabel.module.scss';
import { useTranslation } from 'react-i18next';

const RequiredFieldsLabel: React.FC<{ margin?: MarginProps; float?: boolean }> = ({
  margin,
  float
}) => {
  const { t } = useTranslation();

  return (
    <BodyText
      data-testid="required-fields-label"
      tagName="div"
      className={getClassNames({
        [styles.requiredFieldsLabel]: true,
        [styles.requiredFieldsLabel_float]: float
      })}
      margin={margin}
    >
      {t('common.form.requiredFields')}
    </BodyText>
  );
};

export default RequiredFieldsLabel;
