import { DefinitionList } from 'common-components';
import usePileVerticallyToMany from 'hooks/usePileVerticallyToMany/usePileVerticallyToMany';
import { useTranslation } from 'react-i18next';
import { Finvoice } from 'types/collection-group';

type FinvoiceDetailsProps = {
  finvoice?: Omit<Finvoice, 'usesCustomerSettings' | 'invoiceClassification'>;
};

const FinvoiceDetails: React.FC<FinvoiceDetailsProps> = ({ finvoice }) => {
  const { t } = useTranslation();
  const pileVerticallyTo = usePileVerticallyToMany(['xxxs', 'xxs', 'md', 'lg']);
  if (!finvoice) {
    return null;
  }

  return (
    <DefinitionList
      data-testid="finvoice-details"
      pileVerticallyTo={pileVerticallyTo}
      addDividers
      fiftyFifty
    >
      {(
        [
          'orderIdentifier',
          'buyerReferenceIdentifier',
          'agreementIdentifier',
          'accountDimensionText',
          'tenderReference',
          'proposedAccountText',
          'normalProposedAccountIdent',
          'projectReferenceIdentifier'
        ] as const
      ).map((finvoiceField) => (
        <DefinitionList.MultilineItem
          key={finvoiceField}
          heading={t(`billingInformation.finvoice.${finvoiceField}`)}
          values={[finvoice[finvoiceField]]}
          data-testid={`finvoice-details-${finvoiceField}`}
        />
      ))}
    </DefinitionList>
  );
};

export default FinvoiceDetails;
