import { Panel } from '@gaiads/telia-react-component-library';
import routeAccessGroups from 'B2XApp/appRouteAccessGroups';
import OrderProgressBar from 'B2XApp/Orders/OrderListComponents/OrderProgressBar';
import OrderLineItemStatusTag from 'B2XApp/Orders/OrderStatus/OrderLineItemStatusTag';
import { Breakpoint, ListView, TeliaLink } from 'common-components';
import ShowMoreResults from 'common-components/ShowMoreResults/ShowMoreResults';
import { EN_DASH } from 'doings/dash/dash';
import join from 'doings/join/join';
import getOriginatorPath from 'doings/links/getOriginatorPath/getOriginatorPath';
import { joinURLSearchParams } from 'doings/links/joinURLSearchParams/joinURLSearchParams';
import { ordersAndRFQs } from 'doings/track/analyticsEvents';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderLineItem } from 'types/orders';

import { EcommerceCompletedOrderStatusInfo } from './EcommerceCompletedOrderStatusInfo';

export const INITIAL_LINES_VISIBLE = 10;

const OrdersListViewLineItems: React.FC<{
  orderId: string;
  orderlineData: OrderLineItem[];
  isEcommerce: boolean;
  initialLinesVisible?: number;
}> = ({ orderId, orderlineData, isEcommerce, initialLinesVisible = INITIAL_LINES_VISIBLE }) => {
  const { t } = useTranslation();
  const [linesVisible, setLinesVisible] = useState(initialLinesVisible);
  const hasMultipleOrders = orderlineData.length > 1;
  const onToggleLinesVisible = () => {
    setLinesVisible(
      linesVisible === initialLinesVisible ? orderlineData.length : initialLinesVisible
    );
  };

  return (
    <Panel
      tagName={'li' as 'div'}
      margin={{ bottom: 'xs' }}
      padding={{ vertical: 'xs' }}
      backgroundColor="gray200"
    >
      {isEcommerce && (
        <ListView.Row hideArrow listType="sublist" useChildPadding>
          <ListView.Cell>
            <EcommerceCompletedOrderStatusInfo />
          </ListView.Cell>
        </ListView.Row>
      )}

      {hasMultipleOrders && (
        <Breakpoint widerThan="lg">
          <ListView.Row hideArrow listType="sublist">
            <ListView.RowShape
              cells={{
                orderName: {
                  data: t('orders.list.columns.serviceName')
                },
                deliveryDate: {
                  data: t('orders.list.columns.deliveryDate'),
                  props: { tooltip: t('orders.list.deliveryTooltip') }
                },
                additionalInfo: {
                  data: t('orders.list.columns.additionalInfo')
                },
                status: {
                  data: t('orders.list.columns.status')
                },
                progress: {
                  data: t('orders.list.columns.progress')
                }
              }}
              commonProps={{ isHeading: true }}
            />
          </ListView.Row>

          <ListView.Divider />
        </Breakpoint>
      )}

      {hasMultipleOrders &&
        join(
          orderlineData.slice(0, linesVisible).map((content, index) => {
            const testId = `order-line-item-${index}`;
            return (
              <ListView.Row key={testId} hideArrow listType="sublist" useChildPadding>
                <ListView.RowShape
                  cells={{
                    orderName: {
                      data: content.productName,
                      props: {
                        dataTestId: `${testId}--name`,
                        wordWrap: 'ellipsis-two-lines'
                      }
                    },
                    deliveryDate: {
                      data: content.estimatedTimeOfCompletion || EN_DASH,
                      props: { dataTestId: `${testId}--delivery-date` }
                    },
                    additionalInfo: {
                      data: content.displayAddress || EN_DASH,
                      props: { dataTestId: 'order-address', wordWrap: 'ellipsis-two-lines' }
                    },
                    status: {
                      data: <OrderLineItemStatusTag status={content.status} />,
                      props: { dataTestId: `${testId}--status-${content.status}` }
                    },
                    progress: {
                      data: <OrderProgressBar progress={content.completionPercentage ?? 0} />,
                      props: { dataTestId: `${testId}--progress` }
                    }
                  }}
                />
              </ListView.Row>
            );
          }),
          <ListView.Divider />
        )}

      {orderlineData.length > initialLinesVisible && (
        <>
          <ListView.Divider />

          <ListView.Row hideArrow listType="sublist" useChildPadding>
            <ListView.RowShape
              cells={{
                orderName: {
                  data: (
                    <ShowMoreResults
                      loading={false}
                      hasMoreResults
                      hasResults
                      alignLeft
                      variant={linesVisible > initialLinesVisible ? 'showLess' : 'showAll'}
                      fetchMore={onToggleLinesVisible}
                      data-testid="order-show-more-button"
                    />
                  ),
                  props: {}
                }
              }}
            />
          </ListView.Row>
        </>
      )}

      {!isEcommerce && (
        <>
          {hasMultipleOrders && <ListView.Divider />}

          <ListView.Row hideArrow listType="sublist">
            <Breakpoint widerThan="lg">
              <ListView.RowShape
                cells={{
                  orderName: {
                    data: (
                      <TeliaLink.Internal
                        variant="standalone"
                        to={`/service-requests/new/order-inquiry/${orderId}${joinURLSearchParams(
                          '?',
                          {
                            originator: getOriginatorPath()
                          }
                        )}`}
                        iconRight="chevron-right"
                        label={t('orders.list.inquireAboutOrder')}
                        enabledWhen={routeAccessGroups.ORDERS_INQUIRY}
                        track={ordersAndRFQs.inquireAboutOrder}
                      />
                    ),
                    props: {}
                  }
                }}
              />
            </Breakpoint>

            <Breakpoint narrowerThan="lg">
              <TeliaLink.Internal
                variant="standalone"
                to={`/service-requests/new/order-inquiry/${orderId}${joinURLSearchParams('?', {
                  originator: getOriginatorPath()
                })}`}
                iconRight="chevron-right"
                label={t('orders.list.inquireAboutOrder')}
                enabledWhen={routeAccessGroups.ORDERS_INQUIRY}
                track={ordersAndRFQs.inquireAboutOrder}
              />
            </Breakpoint>
          </ListView.Row>
        </>
      )}
    </Panel>
  );
};

export default OrdersListViewLineItems;
