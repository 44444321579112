import { Gutter } from '@gaiads/telia-react-component-library';
import FinvoiceDetailsBlock from 'B2XApp/Invoicing/FinvoiceDetails/FinvoiceDetailsBlock';
import { DefinitionList, Heading } from 'common-components';
import usePileVerticallyToMany from 'hooks/usePileVerticallyToMany/usePileVerticallyToMany';
import { useTranslation } from 'react-i18next';
import { CollectionGroup } from 'types/collection-group';

const BillingGroupBillingInformation: React.FC<{
  collectionGroup: CollectionGroup;
}> = ({
  collectionGroup: {
    type,
    email1,
    email2,
    distributor,
    netServiceId,
    phoneNumber,
    invoicingLanguage,
    finvoice
  }
}) => {
  const { t } = useTranslation();
  const pileVerticallyTo = usePileVerticallyToMany(['xxxs', 'xxs', 'md', 'lg']);
  const deliveryMethod = type.deliveryMethod;

  return (
    <>
      <DefinitionList
        pileVerticallyTo={pileVerticallyTo}
        addDividers
        fiftyFifty
        data-testid="billing-group-billing-information"
      >
        {[
          {
            heading: 'billingInformation.language',
            values: [invoicingLanguage]
          },
          {
            heading: 'billingInformation.billingMethod',
            values: [type?.displayedDeliveryMethod ?? '']
          },
          {
            available: deliveryMethod === 'EMAIL',
            heading: 'billingInformation.email1',
            values: [email1]
          },
          {
            available: deliveryMethod === 'EMAIL',
            heading: 'billingInformation.email2',
            values: [email2]
          },
          {
            available: deliveryMethod === 'EBILL',
            heading: 'billingInformation.ebillIbanOrOvt',
            values: [netServiceId]
          },
          {
            available: deliveryMethod === 'EBILL',
            heading: 'billingInformation.ebillBic',
            values: [distributor]
          },
          {
            available: deliveryMethod === 'NET',
            heading: 'billingInformation.netOvt',
            values: [netServiceId]
          },
          {
            available: deliveryMethod === 'NET',
            heading: 'billingInformation.netOperator',
            values: [distributor]
          },
          {
            heading: 'billingInformation.smsInvoice',
            values: [phoneNumber]
          }
        ]
          .filter(({ available }) => available !== false)
          .map(({ heading, values }) => (
            <DefinitionList.MultilineItem key={heading} heading={t(heading)} values={values} />
          ))}
      </DefinitionList>

      {deliveryMethod !== 'EMAIL' && (
        <>
          <Gutter size="lg" />

          <Heading.H4>{t('billingInformation.finvoice.title')}</Heading.H4>

          <Gutter size="sm" />

          <FinvoiceDetailsBlock finvoice={finvoice} />
        </>
      )}
    </>
  );
};

export default BillingGroupBillingInformation;
