import useBillingAccountEditContactFields, {
  BillingGroupFormContactFields
} from 'B2XApp/Invoicing/BillingGroups/Common/useBillingAccountEditContactFields';
import useBillingAccountEditFinvoiceFields, {
  FinvoiceFields
} from 'B2XApp/Invoicing/BillingGroups/Common/useBillingAccountEditFinvoiceFields';
import { useUserData } from 'contexts/UserContext/UserContext';
import deriveBicCodeFromIban from 'doings/deriveBicCodeFromIban/deriveBicCodeFromIban';
import getCompanyCodeByGroupId from 'doings/getCompanyCodeByGroupId/getCompanyCodeByGroupId';
import useCheckboxModel, {
  CheckboxModel
} from 'hooks/inputModels/useCheckboxModel/useCheckboxModel';
import useDropdownInputModel, {
  DropdownInputModel
} from 'hooks/inputModels/useDropdownInputModel/useDropdownInputModel';
import { TextInputModel } from 'hooks/inputModels/useTextInputModel/useTextInputModel';
import useValidatableTextInputModel from 'hooks/inputModels/useValidatableTextInputModel/useValidatableTextInputModel';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AvailableBank,
  AvailableOperator,
  CollectionGroup,
  CollectionGroupDeliveryType
} from 'types/collection-group';

import useBillingAccountEditCompanyDropdown from '../Common/useBillingAccountEditCompanyDropdown';

export type BillingGroupFormStep =
  | 'unavailable'
  | 'prechecking'
  | 'precheck-failure'
  | 'initial'
  | 'persisting'
  | 'success'
  | 'partial-success'
  | 'failure';

export const BillingGroupFormFinalSteps: BillingGroupFormStep[] = ['success', 'partial-success'];

export type UseBillingGroupStepsResult = {
  step: BillingGroupFormStep;
  setStep: Dispatch<SetStateAction<BillingGroupFormStep>>;
};

export type UseBillingGroupFormResult = {
  form: BillingGroupFormData;
};

export type BillingGroupFormData = {
  step: BillingGroupFormStep;
  setStep: Dispatch<SetStateAction<BillingGroupFormStep>>;
  fields: BillingGroupFormFields;
  finvoiceFields?: FinvoiceFields;
  finvoiceAvailable: boolean;
  isValid: boolean;
  isCopy?: boolean;
  data?: Record<string, string | number | undefined>;
  precheckNotice?: { title: string; content: string };
  onSave: VoidFunction;
  onCancel: VoidFunction;
};

export type UseBillingGroupFormFieldsResult = {
  companySelectionAvailable: boolean;
  fields: BillingGroupFormFields;
  finvoiceAvailable: boolean;
  finvoiceFields?: FinvoiceFields;
  isValid: boolean;
  marshallRequestData: () => ReturnType<typeof marshallRequestData>;
};

export type BillingGroupFormFields = {
  relatedCompany: DropdownInputModel;
  payerName: TextInputModel;
  reference: TextInputModel;
  isForeignAddress: CheckboxModel;
  domesticStreetAddress: TextInputModel;
  domesticPostalCode: TextInputModel;
  domesticPostalOffice: TextInputModel;
  foreignCountry: TextInputModel;
  foreignStreetAddress: TextInputModel;
  foreignPostalCode: TextInputModel;
  foreignPostalOffice: TextInputModel;
  billingLanguage: DropdownInputModel;
  billingType: DropdownInputModel;
  email1: TextInputModel;
  email2: TextInputModel;
  ebillIbanOrOvtCode: TextInputModel;
  ebillBicCode: DropdownInputModel;
  hasEbillAgreement: CheckboxModel;
  netOvtCode: TextInputModel;
  netOperator: DropdownInputModel;
  smsInvoicePhoneNumber: TextInputModel;
  finvoiceOrderId: TextInputModel;
  finvoiceBuyerReference: TextInputModel;
  finvoiceAgreementId: TextInputModel;
  finvoiceAccountDimension: TextInputModel;
  finvoiceTenderReference: TextInputModel;
  finvoiceCostAccount: TextInputModel;
  finvoiceAccountProposal: TextInputModel;
  finvoiceProjectNumber: TextInputModel;
} & BillingGroupFormContactFields;

const checkValid = (fields: BillingGroupFormFields): boolean => {
  return (
    validateBillingFields(fields) &&
    validateAddressFields(fields) &&
    allFieldsValid([
      fields.payerName,
      fields.reference,
      fields.billingLanguage,
      fields.billingType,
      fields.smsInvoicePhoneNumber,
      fields.contactFirstName,
      fields.contactLastName,
      fields.contactEmail,
      fields.contactPhone,
      fields.finvoiceOrderId,
      fields.finvoiceBuyerReference,
      fields.finvoiceAgreementId,
      fields.finvoiceAccountDimension,
      fields.finvoiceTenderReference,
      fields.finvoiceCostAccount,
      fields.finvoiceAccountProposal,
      fields.finvoiceProjectNumber
    ])
  );
};

const validateBillingFields = (fields: BillingGroupFormFields) => {
  const billingType = fields.billingType.value;

  return (
    (billingType !== 'EMAIL' || fields.email1.isValid) &&
    (billingType !== 'EMAIL' || fields.email2.isValid) &&
    (billingType !== 'EBILL' || fields.ebillIbanOrOvtCode.isValid) &&
    (billingType !== 'EBILL' || fields.ebillBicCode.isValid) &&
    (billingType !== 'EBILL' || fields.hasEbillAgreement.isValid) &&
    (billingType !== 'NET' || fields.netOvtCode.isValid) &&
    (billingType !== 'NET' || fields.netOperator.isValid)
  );
};

const validateAddressFields = (fields: BillingGroupFormFields) => {
  const isForeignAddress = fields.isForeignAddress.value;
  return isForeignAddress
    ? allFieldsValid([
        fields.foreignCountry,
        fields.foreignStreetAddress,
        fields.foreignPostalCode,
        fields.foreignPostalOffice
      ])
    : allFieldsValid([
        fields.domesticStreetAddress,
        fields.domesticPostalCode,
        fields.domesticPostalOffice
      ]);
};

const allFieldsValid = (fields: TextInputModel[]) => fields.every((field) => field.isValid);

const marshallRequestData = ({
  contactLanguage,
  fields,
  finvoiceAvailable,
  billingTypeChangeAvailable,
  companySelectionAvailable,
  allCompanies,
  shouldSendOperatorValue
}: {
  contactLanguage: string;
  fields: BillingGroupFormFields;
  finvoiceAvailable: boolean;
  billingTypeChangeAvailable: boolean;
  companySelectionAvailable: boolean;
  allCompanies: UserCompany[];
  shouldSendOperatorValue?: boolean;
}) => {
  const billingType = fields.billingType.value;
  const isForeignAddress = fields.isForeignAddress.value;
  const street = isForeignAddress
    ? fields.foreignStreetAddress.value
    : fields.domesticStreetAddress.value;
  const zip = isForeignAddress ? fields.foreignPostalCode.value : fields.domesticPostalCode.value;
  const postalOffice = isForeignAddress
    ? fields.foreignPostalOffice.value
    : fields.domesticPostalOffice.value;

  return {
    ...(companySelectionAvailable
      ? {
          relatedCompany: getCompanyCodeByGroupId({
            groupId: fields.relatedCompany.value,
            allCompanies
          })
        }
      : {}),
    name: fields.payerName.value,
    reference: fields.reference.value,
    address: {
      foreign: isForeignAddress,
      country: fields.foreignCountry.value,
      street,
      zip,
      postalOffice
    },
    language: fields.billingLanguage.value,
    ...(billingTypeChangeAvailable
      ? {
          billingAccountType: billingType,
          ...(billingType === 'EMAIL'
            ? {
                email1: fields.email1.value,
                email2: fields.email2.value
              }
            : {}),
          ...(billingType === 'EBILL'
            ? {
                netServiceId: fields.ebillIbanOrOvtCode.value,
                distributor: fields.ebillBicCode.value
              }
            : {}),
          ...(billingType === 'NET'
            ? {
                netServiceId: fields.netOvtCode.value,
                distributor: shouldSendOperatorValue ? fields.netOperator.value : undefined
              }
            : {})
        }
      : {}),
    phoneNumber: fields.smsInvoicePhoneNumber.value,
    changeRequestContact: {
      firstName: fields.contactFirstName.value,
      lastName: fields.contactLastName.value,
      phone: fields.contactPhone.value,
      email: fields.contactEmail.value,
      language: contactLanguage.toUpperCase()
    },
    ...(finvoiceAvailable
      ? {
          finvoice: {
            orderIdentifier: fields.finvoiceOrderId.value,
            buyerReferenceIdentifier: fields.finvoiceBuyerReference.value,
            agreementIdentifier: fields.finvoiceAgreementId.value,
            accountDimensionText: fields.finvoiceAccountDimension.value,
            tenderReference: fields.finvoiceTenderReference.value,
            proposedAccountText: fields.finvoiceCostAccount.value,
            normalProposedAccountIdent: fields.finvoiceAccountProposal.value,
            projectReferenceIdentifier: fields.finvoiceProjectNumber.value
          }
        }
      : {})
  };
};

const useBillingGroupForm = ({
  collectionGroup,
  availableBanks,
  availableOperators,
  finvoiceAvailable,
  billingTypeChangeAvailable,
  companySelectionAvailable
}: {
  collectionGroup?: CollectionGroup;
  availableBanks: AvailableBank[];
  availableOperators: AvailableOperator[];
  finvoiceAvailable: boolean;
  billingTypeChangeAvailable: boolean;
  companySelectionAvailable: boolean;
}): UseBillingGroupFormFieldsResult => {
  const { t } = useTranslation();

  const { allCompanies, language: contactLanguage } = useUserData();

  const billingType: CollectionGroupDeliveryType | undefined = collectionGroup?.type.deliveryMethod;
  const isOperatorBillingType = billingType === 'NET';
  const billingTypes: CollectionGroupDeliveryType[] = collectionGroup
    ? [collectionGroup.type.deliveryMethod, ...collectionGroup.type.switchableDeliveryMethods]
    : ['EMAIL', 'EBILL', 'NET'];
  const isForeignAddress = collectionGroup?.address?.foreign ?? false;

  const fields: BillingGroupFormFields = {
    ...useBillingAccountEditCompanyDropdown(companySelectionAvailable),
    payerName: useValidatableTextInputModel({
      initialValue: collectionGroup?.name,
      required: false,
      expecting: {
        a: 'nakName',
        maxLength: 30
      }
    }),
    reference: useValidatableTextInputModel({
      initialValue: collectionGroup?.reference,
      required: false,
      expecting: {
        a: 'nakName',
        maxLength: 30
      }
    }),
    isForeignAddress: useCheckboxModel({
      initialValue: isForeignAddress,
      required: false
    }),
    domesticStreetAddress: useValidatableTextInputModel({
      initialValue: isForeignAddress ? '' : collectionGroup?.address.street,
      required: false,
      expecting: {
        a: 'nakStreetAddress',
        maxLength: 30
      }
    }),
    domesticPostalCode: useValidatableTextInputModel({
      initialValue: collectionGroup?.address.zip,
      required: false,
      expecting: {
        a: 'nakPostalCode',
        maxLength: 5
      }
    }),
    domesticPostalOffice: useValidatableTextInputModel({
      initialValue: collectionGroup?.address.postalOffice,
      required: false,
      expecting: {
        a: 'nakPostalOffice',
        maxLength: 12
      }
    }),
    foreignCountry: useValidatableTextInputModel({
      initialValue: collectionGroup?.address.country,
      required: false,
      expecting: {
        maxLength: 30
      }
    }),
    foreignStreetAddress: useValidatableTextInputModel({
      initialValue: isForeignAddress ? collectionGroup?.address.street : '',
      required: false,
      expecting: {
        maxLength: 100
      }
    }),
    foreignPostalCode: useValidatableTextInputModel({
      initialValue: '',
      required: false,
      expecting: {
        maxLength: 10
      }
    }),
    foreignPostalOffice: useValidatableTextInputModel({
      initialValue: '',
      required: false,
      expecting: {
        maxLength: 30
      }
    }),
    billingLanguage: useDropdownInputModel({
      initialValue: (collectionGroup?.language ?? contactLanguage).toUpperCase(),
      required: true,
      options: [
        {
          label: t('languages.fi'),
          value: 'FI'
        },
        {
          label: t('languages.sv'),
          value: 'SV'
        },
        {
          label: t('languages.en'),
          value: 'EN'
        }
      ]
    }),
    billingType: useDropdownInputModel({
      initialValue: collectionGroup?.type.deliveryMethod,
      required: true,
      disabled: !billingTypeChangeAvailable,
      options: [
        ...billingTypes.map((type) => ({
          label: t(`invoices.billingGroup.billingType.${type}`),
          value: type
        }))
      ]
    }),
    email1: useValidatableTextInputModel({
      initialValue: billingType === 'EMAIL' ? collectionGroup?.email1 : '',
      required: true,
      disabled: !billingTypeChangeAvailable,
      expecting: {
        a: 'emailAddress',
        maxLength: 50
      }
    }),
    email2: useValidatableTextInputModel({
      initialValue: billingType === 'EMAIL' ? collectionGroup?.email2 : '',
      required: false,
      disabled: !billingTypeChangeAvailable,
      expecting: {
        a: 'emailAddress',
        maxLength: 50
      }
    }),
    ebillIbanOrOvtCode: useValidatableTextInputModel({
      initialValue: billingType === 'EBILL' ? collectionGroup?.netServiceId : '',
      required: true,
      disabled: !billingTypeChangeAvailable,
      expecting: {
        a: 'billingFinnishIBANOrOvtCode',
        maxLength: 18
      }
    }),
    ebillBicCode: useDropdownInputModel({
      initialValue: billingType === 'EBILL' ? collectionGroup?.distributor : '',
      required: true,
      disabled: !billingTypeChangeAvailable,
      options: availableBanks.map((bank) => ({
        label: `${bank.name} (${bank.bic})`,
        value: bank.bic
      }))
    }),
    hasEbillAgreement: useCheckboxModel({
      initialValue: billingType === 'EBILL',
      required: billingType !== 'EBILL',
      disabled: !billingTypeChangeAvailable
    }),
    netOvtCode: useValidatableTextInputModel({
      initialValue: billingType === 'NET' ? collectionGroup?.netServiceId : '',
      required: true,
      disabled: !billingTypeChangeAvailable,
      expecting: {
        a: 'billingFinnishOvtCode',
        maxLength: 17
      }
    }),
    netOperator: useDropdownInputModel({
      initialValue: billingType === 'NET' ? collectionGroup?.distributor : '',
      required: !isOperatorBillingType,
      disabled: !billingTypeChangeAvailable,
      hidden: isOperatorBillingType,
      options: availableOperators.map((operator) => ({
        label: `${operator.name} (${operator.code})`,
        value: operator.name
      }))
    }),
    smsInvoicePhoneNumber: useValidatableTextInputModel({
      initialValue: collectionGroup?.phoneNumber,
      required: false,
      expecting: {
        a: 'msisdn',
        maxLength: 25
      }
    }),
    ...useBillingAccountEditContactFields(),
    ...useBillingAccountEditFinvoiceFields(collectionGroup?.finvoice)
  };

  /*
   * The back-end requires a payer name, street address and a postal
   * code if any of the three fields have been provided a value. The
   * back-end can derive the postal office from a provided postal
   * code, so does not require a postal office.
   *
   * If none of the mentioned fields are provided, the back-end sets
   * the values automatically to the company's name and address info
   * accordingly.
   */

  const {
    payerName,
    domesticStreetAddress,
    domesticPostalCode,
    foreignCountry,
    foreignStreetAddress,
    foreignPostalCode,
    foreignPostalOffice
  } = fields;
  const payerFields = [payerName, domesticStreetAddress, domesticPostalCode];
  const payerRequired = payerFields.some((field) => !!field.value);
  const requiredPayerParamHasChanged = payerFields.some(
    (field) => field.required !== payerRequired
  );
  useEffect(() => {
    if (requiredPayerParamHasChanged) {
      payerFields.forEach((field) => field.setRequired(payerRequired));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payerRequired]);

  const foreignAddressFields = isForeignAddress
    ? [foreignStreetAddress]
    : [foreignCountry, foreignStreetAddress, foreignPostalCode, foreignPostalOffice];
  const isForeignAddressSelected = fields.isForeignAddress.checked;

  useEffect(() => {
    foreignAddressFields.forEach((field) => field.setRequired(isForeignAddressSelected));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isForeignAddressSelected]);

  /*
   * Derive the bank from the provided IBAN for an e-bill collection
   * group when possible, to easen user input. An OVT code does not
   * provide bank information.
   */
  useEffect(() => {
    const bicCode = deriveBicCodeFromIban(fields.ebillIbanOrOvtCode.value);
    if (bicCode !== null) {
      fields.ebillBicCode.setValue(bicCode);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.ebillIbanOrOvtCode.value]);

  const isValid = checkValid(fields);

  return {
    companySelectionAvailable,
    fields,
    finvoiceAvailable,
    finvoiceFields: {
      finvoiceOrderId: fields.finvoiceOrderId,
      finvoiceBuyerReference: fields.finvoiceBuyerReference,
      finvoiceAgreementId: fields.finvoiceAgreementId,
      finvoiceAccountDimension: fields.finvoiceAccountDimension,
      finvoiceTenderReference: fields.finvoiceTenderReference,
      finvoiceCostAccount: fields.finvoiceCostAccount,
      finvoiceAccountProposal: fields.finvoiceAccountProposal,
      finvoiceProjectNumber: fields.finvoiceProjectNumber
    },
    isValid,
    marshallRequestData: () => {
      return marshallRequestData({
        contactLanguage,
        fields,
        finvoiceAvailable,
        billingTypeChangeAvailable,
        companySelectionAvailable,
        allCompanies,
        shouldSendOperatorValue: !isOperatorBillingType
      });
    }
  };
};

export default useBillingGroupForm;
