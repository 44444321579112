import { BreakpointProvider } from '@gaiads/telia-react-component-library';
import AskForHelpDrawer from 'B2XApp/AskForHelp/AskForHelpDrawer/AskForHelpDrawer';
import NewsDrawer from 'B2XApp/NewsFeed/NewsDrawer/NewsDrawer';
import { ModalPortal } from 'common-components/ModalPortal/ModalPortal';
import ScrollToTop from 'common-components/ScrollToTop/ScrollToTop';
import ApiProvider from 'contexts/ApiProvider/ApiProvider';
import { allowMocks, toggleMocksOff } from 'contexts/ApiProvider/getMockLink';
import AskForHelpProvider from 'contexts/AskForHelpProvider/AskForHelpProvider';
import { CSRFTokenProvider } from 'contexts/CSRFToken/CSRFToken';
import { FeatureFlagProvider } from 'contexts/FeatureFlagContext/FeatureFlagContext';
import { UserProvider } from 'contexts/UserContext/UserContext';
import { BrowserRouter } from 'react-router-dom';

import B2XAppRoutes from './B2XAppRoutes';
import ErrorBoundary from './ErrorBoundary/ErrorBoundary';
import Footer from './Footer/Footer';
import FooterContainer from './Footer/FooterContainer';
import LekaneChat from './LekaneChat/LekaneChat';
import { BillingAccountTypeModal } from './Modals/BillingAccountTypeModal';
import { BillingInfoChangeModal } from './Modals/BillingInfoChangeModal';
import { ConfirmationModal } from './Modals/ConfirmationModal';
import { InfoModal } from './Modals/InfoModal';
import NotificationsSystem from './Notifications/NotificationsSystem';
import SessionExpiryDialog from './SessionExpiryDialog/SessionExpiryDialog';
import SessionRefresh from './SessionRefresh/SessionRefresh';
import TopNavigation from './TopNavigation/TopNavigation';
import CustomerSpecificMessagesBar from './TopNotices/CustomerSpecificMessagesBar/CustomerSpecificMessagesBar';
import DemoEnvironmentBar from './TopNotices/DemoEnvironmentBar/DemoEnvironmentBar';
import ExpiredContactsBar from './TopNotices/ExpiredContactsBar/ExpiredContactsBar';
import FeaturesUnavailableBar from './TopNotices/FeaturesUnavailableBar/FeaturesUnavailableBar';
import ImpersonationBar from './TopNotices/ImpersonationBar/ImpersonationBar';
import MockedApiBar from './TopNotices/MockedApiBar/MockedApiBar';
import SNSCustomerBar from './TopNotices/SNSCustomerBar/SNSCustomerBar';
import { TourGuide } from './Tour';

const B2XApp: React.FC = () => {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <CSRFTokenProvider>
        <ApiProvider>
          <UserProvider>
            <FeatureFlagProvider>
              <AskForHelpProvider>
                <FooterContainer>
                  <BreakpointProvider>
                    <ModalPortal>
                      <AskForHelpDrawer />

                      <NewsDrawer />

                      <InfoModal />

                      <ConfirmationModal />

                      <BillingAccountTypeModal />

                      <BillingInfoChangeModal />

                      <SessionExpiryDialog />

                      <SessionRefresh />
                    </ModalPortal>

                    <ScrollToTop />

                    <DemoEnvironmentBar />

                    <SNSCustomerBar />

                    <ImpersonationBar />

                    <FeaturesUnavailableBar />

                    <ExpiredContactsBar />

                    <MockedApiBar allowMocks={allowMocks} toggleMocksOff={toggleMocksOff} />

                    <NotificationsSystem />

                    <CustomerSpecificMessagesBar />

                    <LekaneChat />

                    <TourGuide
                      data-testid="b2x-app-tour-guide"
                      tourIds={['dashboard-20230906', 'new-user-welcome', 'list-customisation']}
                    />

                    <TopNavigation />

                    <div id="maincontent" data-testid="main-content">
                      <ErrorBoundary>
                        <B2XAppRoutes />
                      </ErrorBoundary>
                    </div>
                  </BreakpointProvider>

                  <Footer />
                </FooterContainer>
              </AskForHelpProvider>
            </FeatureFlagProvider>
          </UserProvider>
        </ApiProvider>
      </CSRFTokenProvider>
    </BrowserRouter>
  );
};

export default B2XApp;
