import { Gutter } from '@gaiads/telia-react-component-library';
import { ActionButtonGroup, Authorize, Button, DetailsLoadingOverlay } from 'common-components';
import { invoicing } from 'doings/track/analyticsEvents';
import { useTranslation } from 'react-i18next';
import { PaymentReminderType, PaymentRemindersResponse } from 'types/paymentReminder';

import PaymentReminderSetting from './PaymentReminderSetting';
import PaymentReminderSettingsUpdateNotice from './PaymentReminderSettingsUpdateNotice';
import useUpdatePaymentRemindersForm from './useUpdatePaymentRemindersForm';

const PaymentReminderSettings: React.FC<{
  available: boolean;
  reminders: PaymentRemindersResponse;
  companyId?: string;
}> = ({ available, reminders, companyId }) => {
  const { t } = useTranslation();
  const mobileReminder = reminders[PaymentReminderType.Mobile];
  const fixedReminder = reminders[PaymentReminderType.BroadbandAndFixed];
  const { form } = useUpdatePaymentRemindersForm(reminders, companyId);

  const canSave: boolean = available && form.step !== 'persisting' && form.isValid;
  const canCancel: boolean = available && form.step !== 'persisting' && form.isChangeDetected;

  return (
    <DetailsLoadingOverlay showLoader={form.step === 'persisting'}>
      <PaymentReminderSetting
        reminder={mobileReminder}
        formStep={form.step}
        fields={form.fields[mobileReminder.type]}
        data-testid="payment-reminder-setting__mobile"
      />

      <PaymentReminderSetting
        reminder={fixedReminder}
        formStep={form.step}
        fields={form.fields[fixedReminder.type]}
        data-testid="payment-reminder-setting__fixed"
      />

      <Gutter />

      <PaymentReminderSettingsUpdateNotice
        noticeShown={form.isNotificationShown}
        notice={form.notification}
        onCloseNotification={form.onCloseNotification}
        data-testid="payment-reminder-settings__update-notice"
      />

      <ActionButtonGroup>
        <Authorize
          data-testid="payment-reminder__save-authorize"
          analyticsEvent={invoicing.updatePaymentReminders}
          enabledWhen={{ onePermission: 'BILLING' }}
          element={
            <Button
              onClick={form.onSave}
              variant="primary"
              size="sm"
              disabled={!canSave}
              data-testid="payment-reminder__save"
            >
              {t('invoices.paymentReminder.save')}
            </Button>
          }
        />

        <Authorize
          data-testid="payment-reminder__cancel-authorize"
          analyticsEvent={invoicing.resetPaymentReminders}
          enabledWhen={{ onePermission: 'BILLING' }}
          element={
            <Button
              onClick={form.onCancel}
              variant="tertiary-purple"
              size="sm"
              disabled={!canCancel}
              data-testid="payment-reminder__cancel"
            >
              {t('common.cancelButton.label')}
            </Button>
          }
        />
      </ActionButtonGroup>
    </DetailsLoadingOverlay>
  );
};

export default PaymentReminderSettings;
