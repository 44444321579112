import { GridBag, Gutter } from '@gaiads/telia-react-component-library';
import { Forms, Heading, InlineNotification } from 'common-components';
import ModelCheckbox from 'common-components/ModelCheckbox/ModelCheckbox';
import ModelDropdown from 'common-components/ModelDropdown/ModelDropdown';
import ModelTextInput from 'common-components/ModelTextInput/ModelTextInput';
import { useTranslation } from 'react-i18next';
import { CollectionGroupDeliveryType } from 'types/collection-group';

import { BillingGroupFormFields } from './useBillingGroupForm';

const BillingGroupFormBillingInfo: React.FC<{
  fields: BillingGroupFormFields;
  disallowMutation: boolean;
  billingType: string;
  deliveryMethod?: CollectionGroupDeliveryType;
  selectedOperator: string;
  isEditing: boolean;
}> = ({ fields, disallowMutation, billingType, deliveryMethod, selectedOperator, isEditing }) => {
  const { i18n, t } = useTranslation();

  const selectedOperatorNoticeKey = `invoices.billingGroup.form.billingInfo.operatorNotice.${selectedOperator}`;

  return (
    <>
      <Heading.H4>{t('invoices.billingGroup.form.billingInfo.title')}</Heading.H4>

      <Gutter size="sm" />

      <GridBag>
        <GridBag.Item sm={12} md={12} key="billingLanguage">
          <ModelDropdown
            label={t('invoices.billingGroup.form.billingInfo.languageLabel')}
            inputModel={fields.billingLanguage}
            placeholder={t('invoices.billingGroup.form.billingInfo.languagePlaceholder')}
            disabled={disallowMutation}
            data-testid="collection-group-form__billing-language"
          />
        </GridBag.Item>

        <GridBag.Item sm={12} md={12} key="billingType">
          <ModelDropdown
            label={t('invoices.billingGroup.form.billingInfo.billingTypeLabel')}
            inputModel={fields.billingType}
            placeholder={t('invoices.billingGroup.form.billingInfo.billingTypePlaceholder')}
            disabled={disallowMutation || fields.billingType.disabled}
            data-testid="collection-group-form__billing-type"
          />
        </GridBag.Item>

        {[
          // prettier-ignore
          ...billingType === 'EMAIL' ? [
          <GridBag.Item sm={12} md={12} key="email1">
            <ModelTextInput
              data-testid="collection-group-form__EMAIL-email-1"
              label={t('invoices.billingGroup.form.billingInfo.email1Label')}
              inputModel={fields.email1}
              disabled={disallowMutation || fields.email1.disabled}
              hardMaxLength
              type="email"
            />
          </GridBag.Item>,
          <GridBag.Item sm={12} md={12} key="email2">
            <ModelTextInput
              data-testid="collection-group-form__EMAIL-email-2"
              label={t('invoices.billingGroup.form.billingInfo.email2Label')}
              inputModel={fields.email2}
              disabled={disallowMutation || fields.email2.disabled}
              hardMaxLength
              type="email"
            />

            <Forms.FieldHelperText
              helperText={t('invoices.billingGroup.form.billingInfo.email2AdditionalInfo')}
            />
          </GridBag.Item>] : [],

          ...(billingType === 'EBILL'
            ? [
                <GridBag.Item sm={12} md={12} key="ebillIbanOrOvt">
                  <ModelTextInput
                    data-testid="collection-group-form__EBILL-iban-or-ovt"
                    label={t('invoices.billingGroup.form.billingInfo.ibanOrOvtLabel')}
                    placeholder={t('invoices.billingGroup.form.billingInfo.ibanOrOvtPlaceholder')}
                    inputModel={fields.ebillIbanOrOvtCode}
                    disabled={disallowMutation || fields.ebillIbanOrOvtCode.disabled}
                    hardMaxLength
                  />
                </GridBag.Item>,

                <GridBag.Item sm={12} md={12} key="ebillBicCode">
                  <ModelDropdown
                    label={t('invoices.billingGroup.form.billingInfo.bicCodeLabel')}
                    inputModel={fields.ebillBicCode}
                    placeholder={t('invoices.billingGroup.form.billingInfo.bicCodePlaceholder')}
                    disabled={disallowMutation || fields.ebillBicCode.disabled}
                    data-testid="collection-group-form__EBILL-bic-code"
                  />
                </GridBag.Item>
              ]
            : []),

          ...(billingType === 'EBILL' && deliveryMethod !== 'EBILL'
            ? [
                <GridBag.Item sm={12} md={12} key="hasEbillAgreement">
                  <ModelCheckbox
                    label={t('invoices.billingGroup.form.billingInfo.hasEbillAgreement')}
                    helperText={t(
                      'invoices.billingGroup.form.billingInfo.hasEbillAgreementTooltip'
                    )}
                    checkboxModel={fields.hasEbillAgreement}
                    disabled={disallowMutation || fields.hasEbillAgreement.disabled}
                    data-testid="collection-group-form__EBILL-has-ebill-agreement"
                  />
                </GridBag.Item>
              ]
            : []),

          // prettier-ignore
          ...billingType === 'NET' ? [
          <GridBag.Item sm={12} md={12} key="netOvt">
            <ModelTextInput
              data-testid="collection-group-form__NET-ovt"
              label={t('invoices.billingGroup.form.billingInfo.ovtLabel')}
              placeholder={t('invoices.billingGroup.form.billingInfo.ovtPlaceholder')}
              inputModel={fields.netOvtCode}
              disabled={disallowMutation  || fields.netOvtCode.disabled}
              hardMaxLength
            />
          </GridBag.Item>] : [],

          // prettier-ignore
          ...billingType === 'NET' && !fields.netOperator.hidden ? [
          <GridBag.Item sm={12} md={12} key="netOperator">
            <ModelDropdown
              label={t('invoices.billingGroup.form.billingInfo.operatorLabel')}
              inputModel={fields.netOperator}
              placeholder={t('invoices.billingGroup.form.billingInfo.operatorPlaceholder')}
              disabled={disallowMutation || fields.netOperator.disabled}
              data-testid="collection-group-form__NET-operator"
            />
          </GridBag.Item>] : [],

          // prettier-ignore
          ...isEditing && billingType === 'NET' &&  i18n.exists(selectedOperatorNoticeKey) ? [
          <GridBag.Item sm={12} md={12} key="netOperatorNotice">
            <InlineNotification
              variant='information'
              content={{html: t(selectedOperatorNoticeKey)}}
              data-testid="collection-group-form__NET-operator-notice"
            />
          </GridBag.Item>] : []
        ]}
      </GridBag>
    </>
  );
};

export default BillingGroupFormBillingInfo;
