import { Notices } from 'common-components';
import {
  SubscriptionAttribute,
  SubscriptionStatus
} from 'hooks/mobileSubscriptions/useSubscriptionDetails/subscriptionDetailsQuery';
import { OwnershipChangeDetails } from 'hooks/mobileSubscriptions/useSubscriptionDetails/useSubscriptionDetails';

import { DuettoBundleNotice } from './DuettoBundleNotice/DuettoBundleNotice';
import { FixedTermContractNotice } from './FixedTermContractNotice/FixedTermContractNotice';
import {
  LegacyMultiSimMemberNotice,
  YhteysMultiSimMemberNotice
} from './MultiSimMemberNotice/MultiSimMemberNotice';
import { CompletedOwnershipChangeNotice } from './OwnershipChangeNotice/CompletedOwnershipChangeNotice';
import { OngoingOwnershipChangeNotice } from './OwnershipChangeNotice/OngoingOwnershipChangeNotice';
import { OngoingTerminatedOwnershipChangeNotice } from './OwnershipChangeNotice/OngoingTerminatedOwnershipChangeNotice';
import { ClosedNotice, DisabledNotice, PreopenedNotice, SuspendedNotice } from './StatusNotice';
import { TerminationNotice } from './TerminationNotice/TerminationNotice';

const SubscriptionNotices: React.FC<{
  phoneNumberHash: string;
  attributes?: SubscriptionAttribute[];
  additionalServices?: string[];
  status?: SubscriptionStatus;
  terminationDate?: string;
  ownershipChange?: OwnershipChangeDetails;
  fixedTermContractEndDate?: string;
  activationDeadlineCode?: string;
  hasDuettoBundle?: boolean;
}> = ({
  phoneNumberHash,
  attributes,
  additionalServices,
  status,
  terminationDate,
  ownershipChange: omsu,
  fixedTermContractEndDate,
  activationDeadlineCode,
  hasDuettoBundle
}) => {
  return (
    <Notices data-testid="subscription-notices">
      {!!omsu && omsu.completed && <CompletedOwnershipChangeNotice />}

      {!!omsu && !omsu.completed && omsu.tokenTerminationDate && (
        <OngoingTerminatedOwnershipChangeNotice terminationDate={omsu.tokenTerminationDate} />
      )}

      {!!omsu && !omsu.completed && !omsu.tokenTerminationDate && <OngoingOwnershipChangeNotice />}

      {!!hasDuettoBundle && <DuettoBundleNotice />}

      {!!fixedTermContractEndDate && (
        <FixedTermContractNotice fixedTermContractEndDate={fixedTermContractEndDate} />
      )}

      {attributes?.includes('MULTI_SIM_MEMBER') &&
        !!additionalServices &&
        additionalServices.includes('NAA') && <LegacyMultiSimMemberNotice />}

      {attributes?.includes('MULTI_SIM_MEMBER') &&
        !!additionalServices &&
        !additionalServices.includes('NAA') && <YhteysMultiSimMemberNotice />}

      {!!terminationDate && <TerminationNotice terminationDate={terminationDate} />}

      {status === 'PREOPENED' && (
        <PreopenedNotice activationDeadlineCode={activationDeadlineCode} />
      )}

      {status === 'CLOSED' && <ClosedNotice phoneNumberHash={phoneNumberHash} />}

      {status === 'SUSPENDED' && <SuspendedNotice phoneNumberHash={phoneNumberHash} />}

      {status === 'DISABLED' && <DisabledNotice />}
    </Notices>
  );
};

export default SubscriptionNotices;
