import { ApolloError } from '@apollo/client';
import { Page } from '@gaiads/telia-react-component-library';
import { ExcelDownloadLink } from 'B2XApp/Messages/ServiceRequests/ServiceRequestsList/ExcelDownloadLink';
import { Memo, PageWithSubnavigation } from 'common-components';
import EmptyListContent from 'common-components/EmptyListContent/EmptyListContent';
import ListHeader from 'common-components/ListHeader/ListHeader';
import ShowMoreResults from 'common-components/ShowMoreResults/ShowMoreResults';
import { EditableListProvider } from 'contexts/EditableListContext/EditableListContext';
import { UseServiceRequestsFiltersResult } from 'hooks/serviceRequests/useServiceRequestListFilter';
import { ViewStatus } from 'hooks/useApiStatus/useApiStatus';
import useInitialLoadStatus from 'hooks/useInitialLoadStatus/useInitialLoadStatus';
import { UseReadQueryParamsResult } from 'hooks/useQueryParams/useReadQueryParams';
import { useTranslation } from 'react-i18next';
import { ServiceRequestsListItem } from 'types/serviceRequest';

import { NewServiceRequestButton } from '../NewServiceRequestButton/NewServiceRequestButton';
import ServiceRequestsListView, {
  ServiceRequestsListViewColumnSetup
} from './ServiceRequestsListView';

export type ServiceRequestsListResult = {
  total: number;
  items?: readonly ServiceRequestsListItem[];
};

const ServiceRequestsListPage: React.FC<{
  serviceRequests: ServiceRequestsListResult;
  filters: UseServiceRequestsFiltersResult;
  queryParameters: UseReadQueryParamsResult;
  apiState: ViewStatus;
  error?: ApolloError;
  fetchMore: VoidFunction;
  multipleCompanySearchEnabled: boolean;
}> = ({
  serviceRequests: { items, total },
  filters,
  queryParameters,
  apiState,
  error,
  fetchMore,
  multipleCompanySearchEnabled
}) => {
  const { t } = useTranslation();
  const hasResults = !!items?.length || !!error;
  const status = useInitialLoadStatus(apiState);
  const loading = [ViewStatus.Initial, ViewStatus.LoadingInitial].includes(status);
  const { searchFieldOptions, dateTypeOptions, statusOptions, allCompanySearch } = filters;

  return (
    <EditableListProvider
      columnSetup={ServiceRequestsListViewColumnSetup}
      invocationArgs={[t, multipleCompanySearchEnabled]}
      changeableArgs={[undefined, multipleCompanySearchEnabled]}
    >
      <PageWithSubnavigation
        loading={loading}
        error={error}
        emptyContentPlaceholder={
          status === ViewStatus.Empty && (
            <EmptyListContent
              title={t('serviceRequests.emptyList.title')}
              description={t('serviceRequests.emptyList.description')}
              footerContent={<NewServiceRequestButton />}
            />
          )
        }
        showSubnavigationForCurrentPath
      >
        <ListHeader
          withMultiCompanyFeature={multipleCompanySearchEnabled}
          multipleCompaniesCheckboxParams={{
            checked: allCompanySearch.showFromAllCompanies,
            label: t('serviceRequests.list.searchAllLabel'),
            testId: 'sr-search-all-companies',
            onChange: allCompanySearch.toggleAllCompanies
          }}
          titleTestid="service-requests-count"
          searchScopeOptions={searchFieldOptions}
          withSearchActivationThreshold
          actionButtons={<NewServiceRequestButton />}
          dateRangeParams={{
            label: t('tickets.list.filters.dateRange'),
            helperText: t('common.filterSettings.dateHelperText'),
            helperTooltip: t('serviceRequests.list.dateFilterDefaultRangeTooltip'),
            withDefaultDatePlaceholder: true
          }}
          dateTypeParams={{
            label: t('tickets.list.filters.dateRangeType'),
            options: dateTypeOptions,
            'data-testid': 'service-requests-date-type-filter'
          }}
          statusFilterParams={{
            label: t('tickets.list.filters.status'),
            options: statusOptions,
            'data-testid': 'service-requests-status-dropdown'
          }}
          labels={{
            searchInMobileView: t('serviceRequests.list.searchLabel')
          }}
          excelDownloadLink={
            <ExcelDownloadLink
              totalItems={total}
              queryParameters={queryParameters}
              disabled={loading || total === 0}
              allCompanies={allCompanySearch.showFromAllCompanies}
            />
          }
        >
          {t('serviceRequests.numberOfServiceRequests', {
            numberOfServiceRequests: total
          })}
        </ListHeader>

        <Page.Row responsiveFullWidth>
          <Memo comparables={['loading', 'serviceRequests']}>
            <ServiceRequestsListView
              loading={status === ViewStatus.Loading}
              serviceRequests={items}
            />
          </Memo>
        </Page.Row>

        {fetchMore && status !== ViewStatus.Loading && (
          <Page.Row>
            <ShowMoreResults
              data-testid="show-more-tickets"
              loading={status === ViewStatus.LoadingMore}
              hasMoreResults={(items?.length ?? 0) < total}
              hasResults={hasResults}
              fetchMore={fetchMore}
            />
          </Page.Row>
        )}
      </PageWithSubnavigation>
    </EditableListProvider>
  );
};

export default ServiceRequestsListPage;
