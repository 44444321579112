import FeatureFlagForm from 'B2XApp/FeatureFlags/FeatureFlagForm';
import { PageWithFormLayout } from 'common-components';
import useFeatureFlags from 'hooks/featureFlags/useFeatureFlags/useFeatureFlags';
import { useTranslation } from 'react-i18next';

const AddFeatureFlag: React.FC = () => {
  const { t } = useTranslation();
  const { persistable } = useFeatureFlags();

  return (
    <PageWithFormLayout title={t('featureFlags.form.titleAdd')}>
      <FeatureFlagForm persistable={persistable} />
    </PageWithFormLayout>
  );
};

export default AddFeatureFlag;
