import { Gutter, Icon } from '@gaiads/telia-react-component-library';
import { Paragraph } from '@teliafi/fi-ds';
import { Optional } from 'common-components';

import styles from './NewsDrawerNoDataMessage.module.scss';

const NewsDrawerNoDataMessage: React.FC<{
  label: string;
  isError?: boolean;
}> = ({ label, isError }) => (
  <div className={styles.noData} data-testid="news-drawer-content-no-data">
    <Optional data={!isError}>
      <Icon
        name="news"
        size="xlg"
        className={styles.noData_icon}
        data-testid="news-drawer-no-data-image"
      />
    </Optional>

    <Optional data={isError}>
      <Icon
        name="error"
        size="xlg"
        className={styles.noData_icon}
        data-testid="news-drawer-error-image"
      />
    </Optional>

    <Gutter size="sm" />

    <Paragraph className={styles.noData_label}>{label}</Paragraph>
  </div>
);

export default NewsDrawerNoDataMessage;
