import { Panel } from '@gaiads/telia-react-component-library';
import { CollapsibleBlock } from 'common-components';
import { AnalyticsEvent } from 'doings/track/analyticsEvents';

import styles from './Accordion.module.scss';

const Accordion: React.FC<{
  className?: string;
  heading: string;
  analyticsEvent?: AnalyticsEvent;
  accordionRef?: React.RefObject<HTMLDivElement>;
  initiallyExpanded?: boolean;
  'data-testid'?: string;
  children: NonNullable<React.ReactNode>;
}> = ({
  className,
  heading,
  analyticsEvent,
  accordionRef,
  initiallyExpanded = false,
  'data-testid': testId,
  children
}) => {
  return (
    <Panel className={styles.panel} refElement={accordionRef}>
      <Panel.Content padding={{ size: 'zero' }}>
        <CollapsibleBlock
          className={{
            className,
            [styles.accordion]: true
          }}
          data-testid={testId}
          blockTitle={heading}
          largeTitle
          expanded={initiallyExpanded}
          track={analyticsEvent}
          padding={{ size: 'md' }}
        >
          <CollapsibleBlock.Content padding={{ size: 'md', top: 'xxxs' }}>
            {children}
          </CollapsibleBlock.Content>
        </CollapsibleBlock>
      </Panel.Content>
    </Panel>
  );
};

export default Accordion;
