import { ApolloError, ApolloQueryResult } from '@apollo/client';
import formatDatetime, { DateFormats } from 'doings/formatDatetime/formatDatetime';
import { activityLogQuery } from 'hooks/activityLog/activityLogQuery';
import getActivityLogOnFetchMore from 'hooks/activityLog/getActivityLogOnFetchMore';
import useApiStatus, { ViewStatus } from 'hooks/useApiStatus/useApiStatus';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { ActivityLogEntry, ActivityLogResponse } from 'types/activityLog';

type UseActivityLogResponse = {
  total: number | undefined;
  activityLogEntries: ActivityLogEntry[];
  error: ApolloError | undefined;
  fetchMore: () => Promise<ApolloQueryResult<ActivityLogResponse | undefined>>;
  apiState: ViewStatus;
};

export type InteractionType = 'calls' | 'chats';

type ActivityLogQueryParameters = {
  interactionType?: InteractionType;
  invoice?: string;
  subscription?: string;
  subject?: string;
  createdDateFrom?: string;
  createdDateTo?: string;
  allUsers?: boolean;
};

const dateOrDefault = (dateString: string | undefined, defaultDate: Date) =>
  dateString || formatDatetime(defaultDate, DateFormats.DATE);

const useActivityLog = (
  queryParameters: ActivityLogQueryParameters,
  defaultDateFromPlaceholder: Date,
  defaultDateToPlaceholder: Date,
  allCompanies?: boolean
): UseActivityLogResponse => {
  const {
    interactionType,
    invoice,
    subscription,
    subject,
    createdDateFrom,
    createdDateTo,
    allUsers
  } = queryParameters;

  const {
    data,
    error,
    loading,
    loaded,
    loadingMore,
    fetchMore: fetchMoreFromQuery
  } = useCallBackend<ActivityLogResponse>({
    query: activityLogQuery,
    queryVariables: {
      offset: 0,
      limit: 5,
      interactionType,
      invoice,
      subscription,
      subject,
      dateFrom: dateOrDefault(createdDateFrom, defaultDateFromPlaceholder),
      dateTo: dateOrDefault(createdDateTo, defaultDateToPlaceholder),
      allCompanies,
      allUsers
    }
  });

  const activityLogItems = data?.activityLog.interactions;

  return {
    total: data?.activityLog.total,
    activityLogEntries: activityLogItems || [],
    error,
    fetchMore: () =>
      fetchMoreFromQuery({
        variables: { offset: activityLogItems?.length },
        updateQuery: getActivityLogOnFetchMore
      }),
    apiState: useApiStatus({
      loading,
      error,
      loaded,
      loadingMore,
      items: activityLogItems ?? [],
      query: activityLogQuery
    })
  };
};

export default useActivityLog;
