import { getSectionDefinition } from 'B2XApp/Dashboard/DashboardNavigation/DashboardSections/sections/getSectionDefinition';
import { DashboardSectionId } from 'B2XApp/Dashboard/sections';
import { useUserData } from 'contexts/UserContext/UserContext';
import getNavigationalLinks, {
  NavigationalLink
} from 'doings/getNavigationalLinks/getNavigationalLinks';
import { NavigationItem } from 'types/navigation';

export interface MenuItemShape {
  id: string;
  labelI18nKey: string;
  additionalActiveMatches: string[];
  to?: string;
  menuItems?: NavigationItem[];
  external?: boolean;
  onClick?: VoidFunction;
  disabled?: boolean;
  visibleFor?: ActiveGroupType[];
}

const mapLinksToMenuItems = ({ textI18nKey, ...rest }: NavigationalLink): NavigationItem => ({
  labelI18nKey: textI18nKey,
  ...rest
});

const navigationItems = (
  getMenuItems: (section: DashboardSectionId) => NavigationItem[]
): MenuItemShape[] => [
  {
    id: 'wholesale-services',
    labelI18nKey: 'topNavigation.sections.titles.WholesaleServices',
    additionalActiveMatches: ['/orders'],
    menuItems: getMenuItems(DashboardSectionId.WholesaleServices),
    visibleFor: ['b2b_b2o']
  },
  {
    id: 'business-services',
    labelI18nKey: 'topNavigation.sections.titles.BusinessServices',
    additionalActiveMatches: ['/subscriptions', '/internet-connections'],
    menuItems: getMenuItems(DashboardSectionId.BusinessServices),
    visibleFor: ['b2b_b2o']
  },
  {
    id: 'subscriptions-and-devices',
    labelI18nKey: 'topNavigation.sections.titles.SubscriptionsAndDevices',
    additionalActiveMatches: ['/subscriptions', '/internet-connections', '/devices'],
    menuItems: getMenuItems(DashboardSectionId.SubscriptionsAndDevices),
    visibleFor: ['b2b', 'b2b_sales_portal']
  },
  {
    id: 'communication',
    labelI18nKey: 'topNavigation.sections.titles.Communication',
    additionalActiveMatches: [
      '/contact-us',
      '/service-requests',
      '/tickets',
      '/message-archive',
      '/activity-log'
    ],
    menuItems: getMenuItems(DashboardSectionId.Communication)
  },
  {
    id: 'orders-and-rfqs',
    labelI18nKey: 'topNavigation.sections.titles.OrdersAndRfqs',
    additionalActiveMatches: ['/rfqs', '/orders'],
    menuItems: getMenuItems(DashboardSectionId.OrdersAndRfqs),
    visibleFor: ['b2b', 'b2b_sales_portal']
  },
  {
    id: 'invoicing',
    labelI18nKey: 'topNavigation.sections.titles.Invoicing',
    additionalActiveMatches: ['/invoices', '/invoice-payment-reminder', '/billing-accounts'],
    menuItems: getMenuItems(DashboardSectionId.Invoicing)
  }
];

export default (): MenuItemShape[] => {
  const user = useUserData();
  const { navigationalLinks: allLinks } = getNavigationalLinks(user);
  const getMenuItems = (section: DashboardSectionId) => {
    return (getSectionDefinition(section)?.getLinks(allLinks, user) || [])
      .filter(({ hiddenFromTopNavigation }) => !hiddenFromTopNavigation)
      .map(mapLinksToMenuItems);
  };

  return navigationItems(getMenuItems)
    .filter(({ visibleFor }) => (visibleFor ? visibleFor.includes(user.activeGroupType) : true))
    .filter(({ to, menuItems }) => !!to || !!menuItems?.length);
};
