import { Div } from '@gaiads/telia-react-component-library';
import { Flex } from 'core-components';

import styles from './LinkGroup.module.scss';

const LinkGroup: React.FC<{ children: React.ReactNode }> & {
  Link: typeof LinkGroupLink;
  LineBreak: typeof LinkGroupLineBreak;
} = ({ children }) => {
  return (
    <Flex data-testid="link-group" pileVerticallyTo="md" wrapContent occupyVerticalSpace={false}>
      {children}
    </Flex>
  );
};

const LinkGroupLink: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <Div data-testid="link-group--link" className={styles.link}>
      {children}
    </Div>
  );
};

const LinkGroupLineBreak: React.FC = () => {
  return <div data-testid="link-group--line-break" className={styles.lineBreak}></div>;
};

LinkGroup.Link = LinkGroupLink;
LinkGroup.LineBreak = LinkGroupLineBreak;

export { LinkGroup };
