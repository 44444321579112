import {
  BodyText,
  Div,
  Flex,
  Gutter,
  Icon,
  Link,
  Loader
} from '@gaiads/telia-react-component-library';

import styles from './File.module.scss';

type FileProps = {
  name: string;
  url?: string;
  loading?: boolean;
  message?: string;
  onDelete?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>
  ) => void;
};

const File: React.FC<FileProps> = ({ name, url, onDelete, loading = false, message = '' }) => {
  const showDownload = !loading && url;
  const showError = !loading && !!message;

  return (
    <Div borderRadius={true} data-testid="file-item">
      <Div
        borderRadius={{ size: 'sm' }}
        backgroundColor="gray100"
        padding={{ horizontal: 'sm', vertical: 'sm' }}
      >
        <Flex centeredVertically>
          <FileIcon loading={loading} showError={showError} />

          <BodyText tagName="span" padding={{ horizontal: 'xs' }} flexItem>
            {name}
          </BodyText>

          {showDownload && (
            <Link to={url}>
              <Icon name="download" size="sm" clickableAppearance />
            </Link>
          )}

          {onDelete && (
            <>
              <Gutter size="sm" />

              <Icon
                data-testid="delete-icon"
                className={styles.delete}
                name="close-circle"
                color="gray400"
                size="sm"
                clickableAppearance
                hoverColor="purple500"
                tabIndex={0}
                onClick={onDelete}
                onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
                  e.key === 'Enter' && onDelete(e);
                }}
              />
            </>
          )}
        </Flex>
      </Div>

      <BodyText
        tagName="span"
        size="sm"
        padding={{ horizontal: 'xs', vertical: 'xs' }}
        block
        color="red600"
      >
        {showError ? message : ''}
      </BodyText>
    </Div>
  );
};

const FileIcon: React.FC<{ loading: boolean; showError: boolean }> = ({ loading, showError }) => {
  if (loading) {
    return <Loader size="sm" />;
  }

  if (showError) {
    return <Icon name="error-filled" color="red600" size="sm" />;
  }

  return <Icon name="doc" color="gray400" size="sm" />;
};

export default File;
