import { Div, Flex, Gutter } from '@gaiads/telia-react-component-library';
import { Button, Heading } from 'common-components';
import { noOp } from 'doings/noOp/noOp';
import FocusTrap from 'focus-trap-react';
import { useFocusTrap } from 'hooks/useFocusTrap/useFocusTrap';
import { useCallback, useEffect } from 'react';

import styles from './FilterDialog.module.scss';

export interface FilterDialogProps {
  isOpen: boolean;
  title: string;
  acceptButtonText: string;
  acceptButtonDisabled: boolean;
  acceptButtonDataTestid: string;
  onAcceptClick: VoidFunction;
  closeButtonText: string;
  closeButtonDisabled: boolean;
  closeButtonDataTestid: string;
  onCloseClick: VoidFunction;
  refElement: React.RefObject<HTMLDivElement>;
  'data-testid': string;
  children?: React.ReactNode;
}

const FilterDialog: React.FC<FilterDialogProps> = ({
  isOpen,
  title,
  acceptButtonText,
  acceptButtonDisabled,
  acceptButtonDataTestid,
  onAcceptClick,
  closeButtonText,
  closeButtonDisabled,
  closeButtonDataTestid,
  onCloseClick,
  refElement,
  'data-testid': testId,
  children
}) => {
  const { isFocusTrapActive } = useFocusTrap({
    open: isOpen,
    zIndex: 699
  });

  const onEscListener = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape' && isFocusTrapActive) {
        onAcceptClick();
      }
    },
    [onAcceptClick, isFocusTrapActive]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keyup', onEscListener);
      return () => {
        document.removeEventListener('keyup', onEscListener);
      };
    }

    return noOp;
  }, [isOpen, onEscListener]);

  return (
    <Div className={styles.modalDialog} hidden={!isOpen} data-testid="modal-dialog-wrapper">
      <FocusTrap
        active={isFocusTrapActive}
        focusTrapOptions={{
          preventScroll: true,
          fallbackFocus: '[data-focusable]',
          allowOutsideClick: true
        }}
        data-testid="modal-dialog-wrapper-focus-trap"
      >
        <div>
          <Div
            className={styles.modalDialogBackdrop}
            onClick={onAcceptClick}
            data-testid="modal-dialog-backdrop"
          ></Div>

          <Div
            className={styles.modalDialog_panel}
            refElement={refElement}
            data-testid="modal-dialog-panel"
          >
            <Flex centeredHorizontally className={styles.modalDialog_panelFlex}>
              <Div
                data-testid={testId}
                borderRadius={{ size: 'lg' }}
                boxShadow="md"
                padding={{ bottom: 'md', top: 'sm' }}
                className={styles.modalDialog_container}
              >
                <Div
                  className={styles.modalDialog_hiddenFocus}
                  tabIndex={0}
                  data-focusable="true"
                />

                <Div>
                  <Flex spaceBetween padding={{ horizontal: 'md', bottom: 'md' }} alignBaseline>
                    <Heading.H3>{title}</Heading.H3>
                  </Flex>

                  <Div
                    padding={{ horizontal: 'md', bottom: 'md' }}
                    className={styles.modalDialog_container_content}
                  >
                    {children}
                  </Div>

                  <Flex padding={{ horizontal: 'md' }} pileVerticallyTo="xxxs">
                    <div
                      data-dd-action-name="Filter settings apply"
                      data-testid={acceptButtonDataTestid}
                    >
                      <Button
                        size="md"
                        onClick={onAcceptClick}
                        variant="primary"
                        disabled={acceptButtonDisabled}
                      >
                        {acceptButtonText}
                      </Button>
                    </div>

                    <Gutter size="sm" />

                    <div
                      data-dd-action-name="Filter settings clear"
                      data-testid={closeButtonDataTestid}
                    >
                      <Button
                        size="md"
                        onClick={onCloseClick}
                        variant="tertiary-purple"
                        disabled={closeButtonDisabled}
                      >
                        {closeButtonText}
                      </Button>
                    </div>
                  </Flex>
                </Div>
              </Div>
            </Flex>
          </Div>
        </div>
      </FocusTrap>
    </Div>
  );
};

export default FilterDialog;
