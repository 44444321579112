import { GrowlNotification } from '@gaiads/telia-react-component-library';
import styles from 'B2XApp/TopNotices/FixedBar.module.scss';
import isDemoEnvironment from 'doings/isDemoEnvironment/isDemoEnvironment';
import { useTranslation } from 'react-i18next';

const DemoEnvironmentBar: React.FC = () => {
  const { t } = useTranslation();
  if (!isDemoEnvironment()) {
    return null;
  }

  return (
    <GrowlNotification
      data-testid="demo-environment-notification-bar"
      className={styles.fixedBar}
      notification={t('notifications.demoEnvironment')}
      variant="error"
    />
  );
};

export default DemoEnvironmentBar;
