import { gql } from '@apollo/client';
import { useUserData } from 'contexts/UserContext/UserContext';
import useApiStatus from 'hooks/useApiStatus/useApiStatus';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';
import { DynamicForms } from 'types/dynamicForms';

export const dynamicFormDescriptorsQuery = gql`
  query GetAvailableDynamicForms($groupName: String!, $language: Language!) {
    dynamicFormDescriptors(groupName: $groupName, language: $language) {
      name
      label
      infotext
      position
    }
  }
`;

const useDynamicFormDescriptors = (groupName: string) => {
  const { language } = useUserData();
  const { data, error, loading } = useCallBackend<{
    dynamicFormDescriptors: DynamicForms.DynamicFormDescriptor[];
  }>({
    query: dynamicFormDescriptorsQuery,
    queryVariables: {
      groupName,
      language
    }
  });

  const mappedDescriptors = data?.dynamicFormDescriptors ?? [];

  const viewStatus = useApiStatus({
    loading,
    loadingMore: false,
    loaded: !loading,
    items: mappedDescriptors,
    error,
    query: dynamicFormDescriptorsQuery
  });

  return {
    formDescriptors: mappedDescriptors,
    viewStatus
  };
};

export default useDynamicFormDescriptors;
