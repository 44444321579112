import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  setAcceptUrl,
  setIsModalOpen,
  setShowConfirmationModal
} from 'redux/slices/confirmationModalSlice';
import { RootState } from 'redux/store';

export const useConfirmationModal = () => {
  const dispatch = useDispatch();
  const { goBack } = useHistory();
  const shouldCleanUp = useRef(false);
  const formHasValue = useRef(false);

  const showConfirmationModal = useSelector(
    (state: RootState) => state.confirmationModal.showConfirmationModal
  );

  const openModal = (acceptUrl: string) => {
    dispatch(setIsModalOpen(true));
    dispatch(setAcceptUrl(acceptUrl));
  };

  const onClick = (acceptUrl: string) => (showConfirmationModal ? openModal(acceptUrl) : goBack());

  const setConfirmationModal = (formData: Record<string, string | File[]>) => {
    formHasValue.current = Object.values(formData).some((v) => v?.length > 0);
    if (formHasValue.current !== showConfirmationModal) {
      dispatch(setShowConfirmationModal(formHasValue.current));
      shouldCleanUp.current = true;
    }
  };

  /*
   * Don't show the confirmation modal when the form using this hook unmounts.
   * This can occur with the browser back and forward buttons, which bypass the
   * confirmation modal.
   *
   * When a link or another component which never sets the confirmation modal
   * is unmounted, refrain from cleaning up, since a (partially) filled form
   * might still be present on the page.
   */
  useEffect(() => {
    return () => {
      if (shouldCleanUp.current) {
        dispatch(setShowConfirmationModal(false));
      }
    };
  }, [dispatch]);

  return { setConfirmationModal, onClick, isChanged: formHasValue.current };
};
