import { gql } from '@apollo/client';
import { FormattedExecutionResult } from 'graphql';
import useCallBackendMutation from 'hooks/useCallBackend/useCallBackendMutation';
import { TicketContactMethod, TicketIncidentAddress } from 'types/ticket';

export const createTicketMutation = gql`
  mutation CreateFaultReport(
    $contactFirstName: String!
    $contactLastName: String!
    $contactEmail: String!
    $contactPhone: String!
    $contactMethods: [String]
    $incidentAddress: CreateTicketIncidentAddress!
    $subject: String!
    $description: String!
    $subscriptionId: String!
    $userFirstName: String!
    $userLastName: String!
    $userEmail: String!
    $userPhone: String
    $productName: String!
    $productId: String!
    $priority: String
    $company: String!
    $attachment: Upload
  ) {
    createTicket(
      contactFirstName: $contactFirstName
      contactLastName: $contactLastName
      contactEmail: $contactEmail
      contactPhone: $contactPhone
      contactMethods: $contactMethods
      incidentAddress: $incidentAddress
      subject: $subject
      description: $description
      subscriptionId: $subscriptionId
      userFirstName: $userFirstName
      userLastName: $userLastName
      userEmail: $userEmail
      userPhone: $userPhone
      productName: $productName
      productId: $productId
      priority: $priority
      company: $company
      attachment: $attachment
    ) {
      id
    }
  }
`;

export type CreateTicketParameters = {
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  contactPhone: string;
  contactMethods: TicketContactMethod[];
  incidentAddress: TicketIncidentAddress;
  subject: string;
  description: string;
  subscriptionId: string;
  userFirstName: string;
  userLastName: string;
  userEmail: string;
  userPhone?: string;
  productName: string;
  productId: string;
  priority: 'High' | 'Medium' | undefined;
  company: string;
  attachment: File;
};

export type CreateTicketResponse = {
  id: string;
};

export type CreateTicket = (
  variables: CreateTicketParameters
) => Promise<FormattedExecutionResult<CreateTicketResponse>>;

export default () => {
  const { runMutation, data, loading, error } =
    useCallBackendMutation<CreateTicketResponse>(createTicketMutation);

  const createTicket: CreateTicket = (variables: CreateTicketParameters) =>
    runMutation({ variables });

  return {
    createTicket,
    data,
    loading,
    error
  };
};
