import { Flex } from 'core-components';

import LanguageSelector from './LanguageSelector/LanguageSelector';
import TopBarLinks from './TopBarLinks/TopBarLinks';

type TopBarProps = {
  hideLanguageSelection?: boolean;
  selectedLanguage: Language;
  selectLanguage: (language: Language) => void;
};

const TopBar: React.FC<TopBarProps> = ({
  hideLanguageSelection,
  selectedLanguage,
  selectLanguage
}) => {
  return (
    <Flex spaceBetween alignBaseline>
      <TopBarLinks />

      {!hideLanguageSelection && (
        <LanguageSelector selectedLanguage={selectedLanguage} selectLanguage={selectLanguage} />
      )}
    </Flex>
  );
};

export default TopBar;
