import { NoteSource, RelatedIndividual } from './ticket';

export interface ServiceRequestSearchParameters {
  scope?: string;
  term?: string;
}

export type FilterBy = 'updated' | 'created';

export interface ServiceRequestDateParameters {
  dateFrom?: string;
  dateTo?: string;
  filterBy?: FilterBy;
}

export type Note = {
  id: string;
  text: string;
  date: string;
  source: NoteSource;
  authorFirstName: string;
  authorLastName: string;
};

export type Attachment = {
  url: string;
  name: string;
  relatedNote?: string;
  source?: AttachmentSource;
};

type AttachmentSource = 'Telia' | 'Customer';

export type ServiceRequestDetailsItem = {
  id: string;
  caseNumber: string;
  name: string;
  description: string;
  solutionDescription: string;
  category: string;
  status: ServiceRequestStatus | ServiceRequestStatusV1;
  creationDate: string;
  lastUpdate: string;
  resolutionDate: string | null;
  companyCode?: string;
  companyName: string;
  companyGroupType: ActiveGroupType;
  subscriptionNumber: string;
  productName: string;
  invoiceNumber: string;
  note: Note[];
  attachments: Attachment[];
  relatedIndividual?: RelatedIndividual[];
};

export type ServiceRequestsListResponse = {
  total: number;
  items: ServiceRequestsListItem[];
};

export type ServiceRequestsInResults = {
  serviceRequests: ServiceRequestsListResponse;
};

export type FetchMoreResults = {
  fetchMoreResult?: ServiceRequestsInResults;
};

export type ServiceRequestsListItem = {
  readonly id: string;
  readonly caseNumber: string;
  readonly name: string;
  readonly subscription?: string;
  readonly status: ServiceRequestStatus | ServiceRequestStatusV1;
  readonly creationDate: string;
  readonly lastUpdate: string;
  readonly companyCode?: string;
  readonly companyName?: string;
};

export type ServiceRequestStatusV1 =
  | 'New'
  | 'In Progress'
  | 'On Hold'
  | 'Pending customer'
  | 'Waiting for information'
  | 'Resolved'
  | 'Closed'
  | 'Cancelled'
  | 'Rejected';

export type ServiceRequestStatus =
  | 'Open'
  | 'In Progress'
  | 'Waiting for information'
  | 'Resolved'
  | 'Closed'
  | 'Cancelled';

export enum ServiceRequestDynamicFormType {
  OTHER_REQUESTS = 'other_requests',
  CHANGES_TO_CURRENT_SERVICES = 'changes_to_product_or_service',
  CHANGES_TO_COMPANY_INFO = 'company_info',
  CHANGES_TO_BILLING_INFO = 'billing_info',
  ORDER_REPORTS = 'order_reports'
}
