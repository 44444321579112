import {
  CustomFilterParam,
  DateRangeParams,
  FilterParams,
  TypeFilterParams
} from 'common-components/ListHeader/ListHeader';
import { getDefaultDateRange } from 'doings/datetime/getDefaultDateRange';
import {
  DateFilterDefinition,
  DateTypeFilterDefinition,
  DropdownFilterDefinition,
  FilterDefinition
} from 'types/listFilters';

type FilterOptionsProps = {
  dateRangeParams?: DateRangeParams;
  dateTypeParams?: FilterParams;
  statusFilterParams?: FilterParams;
  typeFilterParams?: TypeFilterParams;
  customFilterParams?: CustomFilterParam[];
};

export const getFilterOptions = ({
  dateRangeParams,
  dateTypeParams,
  statusFilterParams,
  typeFilterParams,
  customFilterParams
}: FilterOptionsProps): FilterDefinition[] => {
  const filterOptions: FilterDefinition[] = [];
  const { defaultDateFromPlaceholder, defaultDateToPlaceholder } = getDefaultDateRange();

  if (dateRangeParams) {
    const { label, helperText, helperTooltip, withDefaultDatePlaceholder } = dateRangeParams;

    filterOptions.push({
      type: 'DATE_RANGE',
      label: label,
      fromParam: 'from',
      tillParam: 'to',
      fromPlaceholder: withDefaultDatePlaceholder ? defaultDateFromPlaceholder : undefined,
      tillPlaceholder: withDefaultDatePlaceholder ? defaultDateToPlaceholder : undefined,
      helperText: helperText,
      helperTooltip: helperTooltip,
      'data-testid': dateRangeParams['data-testid'] || 'date-filter'
    } as DateFilterDefinition);
  }

  if (dateTypeParams) {
    const { label, options } = dateTypeParams;

    filterOptions.push({
      type: 'DATE_TYPE',
      label: label,
      param: 'dateType',
      options: options,
      'data-testid': dateTypeParams['data-testid']
    } as DateTypeFilterDefinition);
  }

  if (statusFilterParams) {
    const { label, options } = statusFilterParams;

    filterOptions.push({
      type: 'DROPDOWN',
      label: label,
      param: 'status',
      options: options,
      hasNoneOption: true,
      'data-testid': statusFilterParams['data-testid']
    } as DropdownFilterDefinition);
  }

  if (typeFilterParams) {
    const { label, options, noNoneOption } = typeFilterParams;

    filterOptions.push({
      type: 'DROPDOWN',
      label: label,
      param: 'type',
      options: options,
      hasNoneOption: !noNoneOption,
      'data-testid': typeFilterParams['data-testid']
    } as DropdownFilterDefinition);
  }

  if (customFilterParams) {
    customFilterParams.forEach((paramObject) => {
      const { label, param, options, noNoneOption } = paramObject;

      filterOptions.push({
        type: 'DROPDOWN',
        label: label,
        param: param,
        options: options,
        hasNoneOption: !noNoneOption,
        'data-testid': paramObject['data-testid']
      });
    });
  }

  return filterOptions;
};
