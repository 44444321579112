import { gql } from '@apollo/client';
import useCallBackend from 'hooks/useCallBackend/useCallBackend';

export const isUserInListQuery = gql`
  query CheckUser($listName: String) {
    isUserInList(listName: $listName)
  }
`;

type AccessByEmailListResponse = {
  isUserInList: boolean;
};

const useValidateAccessByEmailList = (listName?: AccessControlGroup) => {
  const { data, loading } = useCallBackend<AccessByEmailListResponse>({
    query: isUserInListQuery,
    queryVariables: {
      listName
    },
    skip: !listName
  });

  const result = listName ? data?.isUserInList ?? false : true;

  return {
    isUserInList: result,
    loading
  };
};

export default useValidateAccessByEmailList;
